import React from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ShipmentValue } from 'operations/models/Shipment';
import LinkTarrif from './LinkTarrif';
import { CHARGE_TYPE_ACCOUNT } from './constants';
import {
  FREIGHT_CHARGE_TAG,
  ENUM_BUY_SELL_STATUS_TYPE,
} from 'operations/modules/shipment/constants';
import { observer } from 'mobx-react-lite';
import { Space, Tooltip, Tag } from '@shipmnts/pixel-hub';
import { WarningOutlined } from '@shipmnts/pixel-hub';
import { doc_status_map } from '../Accounts/components/renderers';

interface ItemCellRendererParams extends ICellRendererParams {
  shipment: ShipmentValue;
  linkTarrifEditable: boolean;
  showBillTags: boolean;
}

export const ItemCellRenderer = observer(function ItemCellRenderer(
  props: ItemCellRendererParams
): JSX.Element {
  const shipment: ShipmentValue = props.shipment;
  const showBillTags = props.showBillTags;
  const updateLinkTariff = (id: string | null | undefined) => {
    props.data.updateProductRating(id);
  };
  const {
    sell_status,
    buy_status,
  }: {
    sell_status: ENUM_BUY_SELL_STATUS_TYPE;
    buy_status: ENUM_BUY_SELL_STATUS_TYPE;
  } = props.data;

  let showMarginWarning = false;

  if (
    props.data?.total_sell_amount &&
    props.data?.total_buy_amount &&
    props.data?.total_sell_amount < props.data?.total_buy_amount
  ) {
    showMarginWarning = true;
  }
  const isRowPinned = props.node.isRowPinned();
  const editable =
    !props.node.isRowPinned() &&
    props.linkTarrifEditable &&
    props.data?.charge_type !== CHARGE_TYPE_ACCOUNT &&
    props.data?.tag === FREIGHT_CHARGE_TAG;

  return (
    <div style={{ width: '100%', display: 'flex' }}>
      {showBillTags && !isRowPinned && (
        <>
          <Tooltip title={`Sell : ${doc_status_map[sell_status]?.display_name}`}>
            <Tag
              color={doc_status_map[sell_status]?.backgroundColor}
              style={{
                color: doc_status_map[sell_status]?.color,
                alignSelf: 'center',
                borderColor: doc_status_map[sell_status]?.borderColor,
              }}
            >
              S
            </Tag>
          </Tooltip>
          <Tooltip title={`Buy : ${doc_status_map[buy_status]?.display_name}`}>
            <Tag
              color={doc_status_map[buy_status]?.backgroundColor}
              style={{
                color: doc_status_map[buy_status]?.color,
                alignSelf: 'center',
                borderColor: doc_status_map[buy_status]?.borderColor,
              }}
            >
              B
            </Tag>
          </Tooltip>
        </>
      )}
      <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{props.value}</div>
      <Space size={4} style={{ float: 'right', marginLeft: 'auto' }}>
        {editable && (
          <div>
            <LinkTarrif data={props.data} shipment={shipment} updateLinkTariff={updateLinkTariff} />
          </div>
        )}
        {showMarginWarning && (
          <Tooltip title="Sell Amount is less than buy amount">
            <WarningOutlined style={{ color: 'red' }} />
          </Tooltip>
        )}
      </Space>
    </div>
  );
});
