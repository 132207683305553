import React from 'react';
import { getDateTimeFromUnix, Card } from '@shipmnts/pixel-hub';
import { FlightDataType } from './FsuTrackingTab';
interface FsuDepartureCardProps {
  flightDetail: FlightDataType;
}

const FlightCard: React.FC<FsuDepartureCardProps> = ({ flightDetail }) => {
  if (!flightDetail) return null;

  return (
    <Card
      size="small"
      style={{
        maxWidth: '100%',
        borderRadius: '4px',
        margin: '4px 4px 0px 0px',
        fontSize: '12px',
        height: 'auto',
      }}
      headStyle={{
        borderBottom: 'none',
      }}
      bodyStyle={{
        padding: '10px',
        marginBottom: '0px',
      }}
    >
      <div style={{ display: 'flex', width: '100%' }}>
        <div style={{ width: '30%', color: 'gray' }}>Flight #</div>
        <div style={{ width: '70%', textAlign: 'left' }}>{flightDetail.flight_number}</div>
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <div style={{ width: '30%', color: 'gray' }}>Dept Date</div>
        <div
          style={{
            width: '70%',
            textAlign: 'left',
            color: flightDetail.arrival_date_type === 'A' ? '#0a4fca' : undefined,
          }}
        >
          {flightDetail.departure_date_type === 'A' ? ' (A) ' : ' (E) '}
          {flightDetail?.departure_date && getDateTimeFromUnix(flightDetail?.departure_date)}
        </div>
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <div style={{ width: '30%', color: 'gray' }}>Arr Date</div>
        <div
          style={{
            width: '70%',
            textAlign: 'left',
            color: flightDetail.arrival_date_type === 'A' ? '#0a4fca' : undefined,
          }}
        >
          {flightDetail.arrival_date_type === 'A' ? ' (A) ' : ' (E) '}
          {flightDetail?.arrival_date && getDateTimeFromUnix(flightDetail?.arrival_date)}
        </div>
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <div style={{ width: '70%' }}>
          <span style={{ color: 'gray', paddingRight: '5px' }}>Gross Wt (Kgs)</span>
          <span>{flightDetail.gross_weight}</span>
        </div>
        <div style={{ width: '30%' }}>
          <span style={{ color: 'gray', paddingRight: '5px' }}>Pcs </span>
          <span>{flightDetail.no_of_pcs}</span>
        </div>
      </div>
    </Card>
  );
};

export default FlightCard;
