import AwbStockDelete from 'operations/modules/stockManagement/AwbStockDelete';
import AwbStockUnreserved from 'operations/modules/stockManagement/AwbStockUnreserved';
import AwbStockUpdate from 'operations/modules/stockManagement/AwbStockUpdate';
import ReserveStock from 'operations/modules/stockManagement/ReserveStock';
import SplitStock from 'operations/modules/stockManagement/SplitStock';
import { StockOrder } from 'operations/modules/stockManagement/stockManagementModel';
import { SessionDataValue } from 'operations/models/SessionData';
// import { EmailProps } from 'operations/modules/shipment/components/Tracking/ChangeEvent/EventEmailDrawer'

import { PerformAction } from '../models';
import { EmailProps } from 'operations/components/ChangeEvent/EventEmailDrawer';

interface StockOrderActionType {
  stock_order: StockOrder;
  stock_order_item_id: number;
  awb_number: string;
  setEmailProps: (emailProps: EmailProps) => void;
  setVisible: (visible: boolean) => void;
  sessionData: SessionDataValue;
  extraProps?: any;
  navigate: any;
}

export const updateStockOrderRenderer = (payload: StockOrderActionType): PerformAction => {
  const { stock_order } = payload;
  return {
    actionParams: {
      stockOrder: stock_order,
    },
    component: AwbStockUpdate,
  };
};

export const splitStockOrderRenderer = (payload: StockOrderActionType): PerformAction => {
  const { stock_order } = payload;
  return {
    actionParams: {
      stock_order_id: stock_order.id,
      items: stock_order.stock_order_items,
    },
    component: SplitStock,
  };
};

export const deleteStockOrderRenderer = (payload: StockOrderActionType): PerformAction => {
  const { stock_order_item_id, awb_number } = payload;
  return {
    actionParams: {
      id: stock_order_item_id,
      stock_order_items_awb_number: awb_number,
    },
    component: AwbStockDelete,
  };
};

export const unreserveStockOrderRenderer = (payload: StockOrderActionType): PerformAction => {
  const { stock_order_item_id, awb_number } = payload;
  return {
    actionParams: {
      id: stock_order_item_id,
      stock_order_items_awb_number: awb_number,
    },
    component: AwbStockUnreserved,
  };
};

export const reserveStockOrderRenderer = (payload: StockOrderActionType): PerformAction => {
  return {
    actionParams: {},
    component: ReserveStock,
  };
};
