import { types, Instance, SnapshotIn, SnapshotOut, IAnyModelType } from 'mobx-state-tree';

import Company from './Company';
import Location from './Location';

const Address = types.model({
  id: types.identifier,
  name: types.maybe(types.maybeNull(types.string)),
  company_id: types.maybe(types.string),
  entity_type: types.maybe(types.maybeNull(types.string)),
  city: types.maybe(types.maybeNull(Location)),
  city_name: types.maybe(types.maybeNull(types.string)),
  state_code: types.maybe(types.maybeNull(types.string)),
  state_name: types.maybe(types.maybeNull(types.string)),
  country_code: types.maybe(types.maybeNull(types.string)),
  country_name: types.maybe(types.maybeNull(types.string)),
  print_address: types.maybe(types.string),
  is_disabled: types.maybe(types.boolean),
  is_internal_address: types.maybe(types.boolean),
  company: types.maybe(types.late((): IAnyModelType => Company)),
  accounts_address_link: types.maybe(types.maybeNull(types.string)),
  address_line_1: types.maybe(types.maybeNull(types.string)),
  address_line_2: types.maybe(types.maybeNull(types.string)),
  postal_code: types.maybe(types.maybeNull(types.string)),
  attn: types.maybe(types.maybeNull(types.string)),
  state_tax_code: types.maybe(types.maybeNull(types.string)),
});

export type AddressValue = Instance<typeof Address>;
export type AddressIn = SnapshotIn<typeof Address>;
export type AddressOut = SnapshotOut<typeof Address>;

export default Address;
