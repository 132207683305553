/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Modal, Row, Col, message, Tag, Form } from '@shipmnts/pixel-hub';
import { CompanySearch } from 'common';

import { UPDATE_STOCK_ORDER_ITEM } from 'operations/graphql/StockOrder';
import { LocationValue } from 'operations/models/Location';

import { StockOrder } from './stockManagementModel';
import { GlobalSearch } from '@shipmnts/pixel-hub';

interface AwbStockUpdateProps {
  stockOrder: StockOrder;
  visible?: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
}

export default function AwbStockUpdate(props: AwbStockUpdateProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { stockOrder, visible, onClose, onSuccess } = props;
  const [
    updateStockOrder,
    { data: updateStockOrderData, loading: updateStockOrderLoading, error: updateStockOrderError },
  ] = useMutation(UPDATE_STOCK_ORDER_ITEM);

  useEffect(() => {
    if (updateStockOrderLoading) {
      setLoading(true);
      return;
    }
    if (updateStockOrderError) {
      message.error(updateStockOrderError.message);
      return;
    }
    if (updateStockOrderData?.update_stock_order) {
      setLoading(false);
      message.success('succesfuly updated stock order');
      if (onClose) onClose();
      if (onSuccess) onSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStockOrderData, updateStockOrderLoading, updateStockOrderError, onSuccess]);

  const handleUpdate = () => {
    const id = stockOrder.id;
    const supplier_company_id = form.getFieldValue('supplier_company_id').id;
    const billing_branch_id = form.getFieldValue('billing_branch_id').id;
    const portIds = (form.getFieldValue('ports') || []).map((port: LocationValue) => port.id);
    const stock_order = {
      ports: stockOrder.ports.map((p: LocationValue) => p.id).concat(portIds),
      billing_branch_id: parseInt(billing_branch_id),
      supplier_company_id,
    };
    updateStockOrder({ variables: { id, stock_order } });
  };

  if (!stockOrder) {
    return null;
  }

  return (
    <Modal
      title={`Edit Stock Order #${stockOrder?.id} for ${stockOrder?.airline.name}`}
      open={visible}
      onCancel={onClose}
      okText="Update Stock Order"
      onOk={handleUpdate}
      confirmLoading={loading}
    >
      <Form
        initialValues={{
          supplier_company_id: stockOrder?.supplier_company,
          billing_branch_id: stockOrder?.billing_branch,
        }}
        form={form}
        name="update_stock"
        layout="vertical"
      >
        <Row style={{ marginBottom: 10 }}>
          <Col span={6}>
            <b>Selected Ports: </b>
          </Col>
          <Col>
            {stockOrder &&
              stockOrder.ports.map((currPort: LocationValue) => (
                <Tag key={currPort.id}>{`${currPort.name} ${
                  currPort.iata_code ? `(${currPort.iata_code})` : ''
                }`}</Tag>
              ))}
          </Col>
        </Row>
        <Row>
          <Form.Item label="New Ports" name="ports" style={{ width: '100%' }}>
            <GlobalSearch
              doc_type="Global::Location"
              extraProps={{ excludeValue: stockOrder?.ports }}
              selectMode="multiple"
            />
          </Form.Item>
        </Row>
        <Row>
          <Form.Item label="Supplier" name="supplier_company_id" style={{ width: '100%' }} required>
            <CompanySearch searchProps={{ is_vendor: true }} selectMode={undefined} />
          </Form.Item>
        </Row>
        <Row>
          <Form.Item style={{ width: '100%' }} required label="CSR Branch" name="billing_branch_id">
            <GlobalSearch doc_type="Network::BranchAccount" selectMode={undefined} />
          </Form.Item>
        </Row>
      </Form>
    </Modal>
  );
}
