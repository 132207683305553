/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useApplicationContentContext, useSession } from 'common';
import { Row, Select, Col, Input, SelectProps } from '@shipmnts/pixel-hub';
import { uniq as _uniq } from 'lodash';

interface phoneNumberType {
  dialCode?: string;
  number?: string;
}

interface phoneNumberInputSearch {
  disabled?: boolean;
  value?: phoneNumberType;
  onChange?: (value: phoneNumberType | undefined) => void;
  defaultValueDialCode?: string;
  selectProps?: SelectProps;
}

function PhoneNumberInput(props: phoneNumberInputSearch) {
  const { disabled, value, onChange, defaultValueDialCode } = props;

  const { config_data } = useApplicationContentContext();
  const sessionData = useSession();
  const defaultValue =
    defaultValueDialCode || sessionData?.company_account?.country_of_incorporation;
  const list_of_county_dial_code = config_data?.list_of_county_dial_code;
  const defaultDialCode = list_of_county_dial_code?.[defaultValue || '']?.country_dial_code;
  const [dialCode, setDialCode] = useState(value?.dialCode);
  const [number, setNumber] = useState(value?.number);

  useEffect(() => {
    onChange &&
      onChange({
        ...value,
        number: number || '',
      });
  }, [number]);

  useEffect(() => {
    onChange &&
      onChange({
        ...value,
        dialCode: dialCode || defaultDialCode || '',
      });
  }, [dialCode]);

  const onDialCodeChange = (newDialCode: string) => {
    setDialCode(newDialCode);
  };
  const onNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newNumber = e.target.value;
    setNumber(newNumber);
  };

  //Default value handling
  useEffect(() => {
    if (defaultDialCode && !dialCode) setDialCode(defaultDialCode);
  }, [defaultDialCode, dialCode, setDialCode]);

  if (!list_of_county_dial_code) return <span>Error while loading phone codes of countries</span>;

  const uniqCountryCode = _uniq(
    Object.keys(list_of_county_dial_code).map(
      (country_code: string) => list_of_county_dial_code[country_code].country_dial_code
    )
  );

  return (
    <>
      <Row>
        <Col span={6}>
          <Select
            showSearch
            value={value?.dialCode}
            disabled={disabled}
            onChange={onDialCodeChange}
            filterOption={(input, option: any) => option?.value?.includes(input)}
            style={{ width: '100%' }}
            options={(uniqCountryCode || []).map((country_code: string) => {
              return {
                value: country_code,
                label: country_code,
              };
            })}
            {...props.selectProps}
          />
        </Col>
        <Col span={18}>
          <Input
            value={value?.number}
            onChange={onNumberChange}
            style={{ width: '100%' }}
            disabled={disabled}
            placeholder="Enter Contact Number"
          />
        </Col>
      </Row>
    </>
  );
}

export default PhoneNumberInput;
