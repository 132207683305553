import React, { useEffect } from 'react';
import { EventType } from '../../types';
import { useMutation } from '@apollo/client';
import { UPDATE_TRACKING_EVENT } from '../../graphql/queries';
import { ExclamationCircleOutlined } from '@shipmnts/pixel-hub';
import { Modal, message } from '@shipmnts/pixel-hub';

type Props = {
  event: EventType;
  onSuccess?: () => void;
  children: (props: { loading: boolean; callClear: () => void }) => React.JSX.Element;
};

export default function ClearEvent({ event, children, onSuccess }: Props) {
  const [updateEvent, { data, loading }] = useMutation(UPDATE_TRACKING_EVENT);

  const callClear = () => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      title: 'Are you sure?',
      content: 'This will clear the event date',
      onOk() {
        updateEvent({
          variables: {
            ids: [event.id],
            input: {
              actual_date: null,
              event_data: {},
            },
          },
        });
      },
    });
  };

  useEffect(() => {
    if (data && data.update_tracking_event) {
      message.success('Successfully cleared the event');
      if (onSuccess) onSuccess();
    }
  }, [data, onSuccess]);

  return <>{children({ loading, callClear })}</>;
}
