import React, { useEffect, useState, useRef } from 'react';
import {
  Button,
  Drawer,
  Checkbox,
  Input,
  InputNumber,
  Select,
  Row,
  Form,
} from '@shipmnts/pixel-hub';
import {
  TEMPERATURE_UNIT_CELSIUS,
  TEMPERATURE_UNIT_FAHRENHEIT,
  AIRFLOW_UNITS,
  AIRFLOW_UNIT_CBM_PER_HR,
  containerSettingsMap,
  DIMENSION_CMS,
} from 'operations/baseConstants';
import { DrawerFooter } from '@shipmnts/pixel-hub';
import { ContainerSettingValue } from 'operations/models/ContainerRequest';

const { Option } = Select;

interface ContainerSettingsProps {
  value?: ContainerSettingValue;
  onChange?: (value: ContainerSettingValue) => void;
  container_type_code?: string;
  disabled?: boolean;
}

const renderInputWithUnit = (
  key: string,
  unit_key: string,
  label: string,
  options: Array<{ key: string; name: string }>,
  required?: boolean
) => {
  return (
    <Input.Group compact style={{ width: '100%' }}>
      <Form.Item
        required={required}
        rules={[{ required: required }]}
        name={key}
        style={{ width: '70%' }}
      >
        <InputNumber style={{ width: '100%' }} placeholder={label} />
      </Form.Item>
      <Form.Item name={unit_key} style={{ width: '30%' }}>
        <Select>
          {options.map((unit) => (
            <Option key={unit.key} value={unit.key}>
              {unit.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Input.Group>
  );
};

const renderPercentField = (key: string, label: string, addonAfter: string, required?: boolean) => {
  return (
    <Input.Group style={{ width: '100%' }} compact>
      <Form.Item
        style={{ width: '70%' }}
        name={key}
        required={required}
        rules={[{ required: required }]}
      >
        <InputNumber style={{ width: '100%' }} min={0} max={100} placeholder={label} />
      </Form.Item>
      <Select value={addonAfter} disabled style={{ width: '30%' }}>
        <Option key={addonAfter} value={addonAfter}>
          {addonAfter}
        </Option>
      </Select>
    </Input.Group>
  );
};

const ContainerSettings = React.memo(function ContainerSettings(
  props: ContainerSettingsProps
): JSX.Element {
  const { value } = props;
  const { disabled } = props;
  const [form] = Form.useForm();
  const didMountRef = useRef(false);
  const { onChange, container_type_code } = props;
  const [openDrawer, setOpenDrawer] = useState(false);
  const is_reefer = Object.keys(containerSettingsMap.reefer).includes(container_type_code || '');
  const is_out_of_dimension = Object.keys(containerSettingsMap.out_of_dimension).includes(
    container_type_code || ''
  );

  let title = 'Add Container Settings';
  if (is_reefer) {
    if (value && value.is_active_reefer) {
      title = `Active ${
        value.temperature !== undefined && value.temperature !== null ? value.temperature : ''
      } ${value.temperature_unit || ''}`;
    } else {
      title = 'Add Reefer Settings';
    }
  } else if (is_out_of_dimension) {
    if (value && (value.length || value.width || value.height)) {
      title = `${value.length || ''}x${value.width || ''}x${value.height || ''} ${
        value.lbh_unit || ''
      }`;
    } else {
      title = 'Add Out Of Dimension Settings';
    }
  }

  useEffect(() => {
    // only reset on update... not on initial mount
    if (didMountRef.current && onChange) {
      if (is_reefer)
        onChange({
          is_active_reefer: true,
          ventilation_requested: true,
          humidity_control_requested: true,
          temperature_unit: TEMPERATURE_UNIT_CELSIUS,
          air_flow_unit: AIRFLOW_UNIT_CBM_PER_HR,
          controlled_atmosphere_requested: false,
          genset_requested: false,
        } as ContainerSettingValue);
      else if (is_out_of_dimension) onChange({ lbh_unit: DIMENSION_CMS } as ContainerSettingValue);
      else onChange({} as ContainerSettingValue);
    }
    didMountRef.current = true;
    // onChange changes every render... to avoid infinite
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [is_out_of_dimension, is_reefer]);

  const lbh_dimensions = [{ key: DIMENSION_CMS, name: DIMENSION_CMS }];

  const closeDrawer = () => {
    form.resetFields();
    setOpenDrawer(false);
  };

  return (
    <div>
      <Button
        disabled={(!is_reefer && !is_out_of_dimension) || disabled}
        size="small"
        style={{ width: '164px', whiteSpace: 'nowrap', overflow: 'hidden' }}
        onClick={() => setOpenDrawer(true)}
      >
        {title}
      </Button>
      {openDrawer && (
        <Drawer
          title={`Container Settings`}
          width="40%"
          onClose={closeDrawer}
          open={openDrawer}
          footer={
            <DrawerFooter saveText="Save Settings" onClose={closeDrawer} onSave={form.submit} />
          }
        >
          <Form
            name="container_settings"
            form={form}
            scrollToFirstError
            onFinish={(values) => {
              if (onChange) onChange(values as ContainerSettingValue);
              setOpenDrawer(false);
            }}
            layout="vertical"
            initialValues={value}
            onValuesChange={(changedValues, allValues) => {
              const ventilation_requested_fields = {
                air_flow: undefined,
                air_flow_unit: undefined,
              };
              const humidity_control_requested_fields = { relative_humidity_percent: undefined };
              const controlled_atmosphere_requested_fields = {
                oxygen_level_percent: undefined,
                nitrogen_level_percent: undefined,
                carbon_dioxide_level_percent: undefined,
              };
              const is_active_reefer_fields = {
                ventilation_requested: undefined,
                humidity_control_requested: undefined,
                controlled_atmosphere_requested: undefined,
                genset_requested: undefined,
                ...ventilation_requested_fields,
                ...humidity_control_requested_fields,
                ...controlled_atmosphere_requested_fields,
              };
              if (Object.keys(changedValues).includes('is_active_reefer')) {
                if (changedValues.is_active_reefer)
                  form.setFieldsValue({ temperature_unit: TEMPERATURE_UNIT_CELSIUS });
                else form.setFieldsValue(is_active_reefer_fields);
              }
              if (Object.keys(changedValues).includes('ventilation_requested')) {
                if (changedValues.ventilation_requested)
                  form.setFieldsValue({ air_flow_unit: AIRFLOW_UNIT_CBM_PER_HR });
                else form.setFieldsValue(ventilation_requested_fields);
              }
              if (
                Object.keys(changedValues).includes('humidity_control_requested') &&
                !changedValues.humidity_control_requested
              ) {
                form.setFieldsValue(humidity_control_requested_fields);
              }
              if (
                Object.keys(changedValues).includes('controlled_atmosphere_requested') &&
                !changedValues.controlled_atmosphere_requested
              ) {
                form.setFieldsValue(controlled_atmosphere_requested_fields);
              }
            }}
          >
            {is_reefer && (
              <>
                <Form.Item name="is_active_reefer" valuePropName="checked">
                  <Checkbox>Operating Reefer Container</Checkbox>
                </Form.Item>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => {
                    return (
                      getFieldValue('is_active_reefer') && (
                        <>
                          <Row>
                            {renderInputWithUnit(
                              'temperature',
                              'temperature_unit',
                              'Temperature',
                              [
                                { key: TEMPERATURE_UNIT_CELSIUS, name: TEMPERATURE_UNIT_CELSIUS },
                                {
                                  key: TEMPERATURE_UNIT_FAHRENHEIT,
                                  name: TEMPERATURE_UNIT_FAHRENHEIT,
                                },
                              ],
                              true
                            )}
                          </Row>
                          <Row>
                            <Form.Item name="ventilation_requested" valuePropName="checked">
                              <Checkbox>Ventilation Requested</Checkbox>
                            </Form.Item>
                            {getFieldValue('ventilation_requested') &&
                              renderInputWithUnit(
                                'air_flow',
                                'air_flow_unit',
                                'Air flow',
                                AIRFLOW_UNITS,
                                true
                              )}
                          </Row>
                          <Row>
                            <Form.Item name="humidity_control_requested" valuePropName="checked">
                              <Checkbox>Humidity Control Requested</Checkbox>
                            </Form.Item>
                            {getFieldValue('humidity_control_requested') &&
                              renderPercentField(
                                'relative_humidity_percent',
                                'Humidity percent',
                                '%',
                                true
                              )}
                          </Row>
                          <Row>
                            <Form.Item
                              name="controlled_atmosphere_requested"
                              valuePropName="checked"
                            >
                              <Checkbox>Controlled Atmosphere Requested</Checkbox>
                            </Form.Item>
                            {getFieldValue('controlled_atmosphere_requested') && (
                              <>
                                {renderPercentField(
                                  'oxygen_level_percent',
                                  'Oxygen percent',
                                  'O2 %'
                                )}
                                {renderPercentField(
                                  'nitrogen_level_percent',
                                  'Nitrogen percent',
                                  'N %'
                                )}
                                {renderPercentField(
                                  'carbon_dioxide_level_percent',
                                  'Carbon Dioxide percent',
                                  'CO2 %'
                                )}
                              </>
                            )}
                          </Row>
                          <Row>
                            <Form.Item name="genset_requested" valuePropName="checked">
                              <Checkbox>Genset Requested</Checkbox>
                            </Form.Item>
                          </Row>
                        </>
                      )
                    );
                  }}
                </Form.Item>
              </>
            )}
            {is_out_of_dimension && (
              <>
                {renderInputWithUnit('length', 'lbh_unit', 'Length', lbh_dimensions)}
                {renderInputWithUnit('width', 'lbh_unit', 'Width', lbh_dimensions)}
                {renderInputWithUnit('height', 'lbh_unit', 'Height', lbh_dimensions)}
              </>
            )}
          </Form>
        </Drawer>
      )}
    </div>
  );
});

export default ContainerSettings;
