import { useMutation } from '@apollo/client';
import { message } from '@shipmnts/pixel-hub';
import { UPSERT_TEMPLATE } from 'network/graphql/template';
import { TemplateValue } from 'network/models/Template';
import React, { useEffect } from 'react';

export default function TemplateActions(props: any) {
  const { template, payload, onSuccess } = props;

  const [upsertTemplate, { data: upsertData, error: upsertError }] = useMutation(UPSERT_TEMPLATE);

  useEffect(() => {
    if (upsertData && !upsertError) {
      message.success('Template saved successfully.');
      if (onSuccess) {
        onSuccess();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upsertData, upsertError]);

  useEffect(() => {
    if (template && template.id && template.id !== 'new') {
      upsertTemplate({
        variables: {
          template: {
            ...getUpsertTemplatePayload(template),
            id: template.id,
            ...payload,
          },
        },
      });
    }
  }, [payload, template, upsertTemplate]);

  const getUpsertTemplatePayload = (values: TemplateValue) => {
    return {
      content: values?.content,
      template_type: values?.template_type,
      name: values?.name,
      is_disabled: values?.is_disabled,
      id: values.id === 'new' ? undefined : values.id,
      resource_type: values?.resource_type,
    };
  };

  return <></>;
}
