import {
  Checkbox,
  Col,
  Form,
  FormInstance,
  InfoCircleOutlined,
  Input,
  Radio,
  Row,
  Select,
  Tooltip,
  DatePicker,
  GlobalSearch,
} from '@shipmnts/pixel-hub';
import { AddressCompanySearch, useSession } from 'common';
import { hasPermission, CustomIcon } from '@shipmnts/pixel-hub';
import { RadioOptionProps } from 'operations/commonTypeDefs';
import { ShipmentValue } from 'operations/models/Shipment';
import { FREIGHT_TYPES } from 'operations/modules/shipment/components/ShipmentForm/constants';
import { PERMISSION_CREDIT_CONTROL_WRITE } from 'operations/modules/actionHelper/ShipmentActions/shipmentActionHelper';
import {
  ROW_GUTTER,
  TRADE_TYPES,
  BOOKING_THROUGH_TYPES,
  BUSINESS_TYPE_SUBAGENT,
  BOOKING_THROUGH_OVERSEAS_AGENT,
  INCOTERMS,
} from 'operations/modules/shipment/constants';
import { LOAD_TYPE_OPTIONS, TypeFreightType } from 'operations/utils/constants';
import React from 'react';
import {
  CREDIT_CONTROL_FEATURE,
  LOAD_TYPE_FCL,
  SALES_PERSON_DISABLED_FEATURE,
  STUFFING_LOCATION_TYPE_FACTORY,
} from 'operations/baseConstants';
import { priorityOptions } from 'operations/modules/shipment/constants';
import dayjs from 'dayjs';
interface BasicDetailsProps {
  form: FormInstance;
  hide?: boolean;
  shipmentType?: string;
  disableHash?: any;
  shipment?: ShipmentValue;
  isClearance?: boolean;
}
const { Option } = Select;
export default function BasicDetails(props: BasicDetailsProps) {
  const { form, shipment, hide, shipmentType } = props;
  const sessionData = useSession();
  const { permissions } = sessionData;
  const businessType = Form.useWatch('business_type', form);
  const load_type = Form.useWatch('load_type', form);
  const editable = !!shipment ? shipment?.canEdit() : true;

  const isCustomerDisabled =
    sessionData.isFeatureEnabled(CREDIT_CONTROL_FEATURE) &&
    !hasPermission(permissions, {
      name: PERMISSION_CREDIT_CONTROL_WRITE,
      docType: 'Network::Company',
    });
  const sales_person_disabled = sessionData.isFeatureEnabled(SALES_PERSON_DISABLED_FEATURE);
  return (
    <Row gutter={ROW_GUTTER}>
      <Col span={6}>
        <Form.Item rules={[{ required: !hide }]} name="freight_type" label="Freight Type">
          <Radio.Group disabled={true} optionType="button">
            {FREIGHT_TYPES.map((option: RadioOptionProps, index: number) => (
              <Radio
                key={index}
                value={option.key}
                disabled={true}
                onChange={(val) => {
                  form.setFieldValue(
                    'load_type',
                    LOAD_TYPE_OPTIONS[val.target.value || 'air'][0].value
                  );
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                  <>
                    {option.name === 'Air' && (
                      <CustomIcon height={24} icon="FlightTakeoff" width={24} />
                    )}
                    {option.name === 'Ocean' && (
                      <CustomIcon height={24} icon="ShipIconNew" width={24} />
                    )}
                    {option.name === 'Road' && (
                      <CustomIcon height={24} icon="TruckIcon" width={24} />
                    )}
                  </>
                  {option.name}
                </div>
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item rules={[{ required: !hide }]} name="trade_type" label="Trade Type">
          <Radio.Group optionType="button" disabled={true}>
            {TRADE_TYPES.map((option: RadioOptionProps, index: number) => (
              <Radio key={index} value={option.key}>
                {option.name}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item noStyle dependencies={['freight_type']}>
          {({ getFieldValue }) => {
            const freightType: TypeFreightType = getFieldValue('freight_type');
            const initialValue = LOAD_TYPE_OPTIONS[freightType || 'air'][0].value;
            return (
              <Form.Item
                name="load_type"
                label="Load Type"
                rules={[{ required: true, message: 'Please select load type' }]}
                tooltip={{
                  title: 'Breakbulk load type will be supported soon',
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Radio.Group
                  defaultValue={initialValue}
                  options={LOAD_TYPE_OPTIONS[freightType || 'air']}
                  onChange={(val) => {
                    const stuffingLocationType = form.getFieldValue('stuffing_location_type');
                    if (stuffingLocationType === STUFFING_LOCATION_TYPE_FACTORY) {
                      form.setFieldValue('stuffing_location_type', null);
                    }
                  }}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item
          rules={[{ required: true }]}
          label="Business Booked Through"
          name="business_type"
        >
          <Radio.Group>
            {BOOKING_THROUGH_TYPES.map((option: RadioOptionProps, index: number) => (
              <Radio key={index} value={option.key}>
                {option.name}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item rules={[{ required: !hide }]} name={'involved_branch'} label="Branch">
          <GlobalSearch
            doc_type="Network::BranchAccount"
            extraProps={{
              options: sessionData?.branch_accounts,
            }}
          />
        </Form.Item>
      </Col>
      {shipmentType && (
        <Col span={6}>
          <Form.Item name="job_date" rules={[{ required: !hide }]} label="Job Date">
            <DatePicker style={{ width: '100%' }} maxDate={dayjs()} />
          </Form.Item>
        </Col>
      )}
      <Col span={6}>
        <Form.Item name="customer" required rules={[{ required: true }]} label="Customer">
          <AddressCompanySearch
            customerSearchProps={{
              trigger: 'create_shipment',
            }}
            companySearchProps={{ disabled: isCustomerDisabled && !!shipment }}
            addressSearchProps={{
              searchProps: { entity_type: 'billing' },
              showAddBranchAction: true,
            }}
            onChange={(value: any) => {
              if (
                value?.party_company?.sales_partner?.country_of_incorporation &&
                sessionData?.company_account?.country_of_incorporation
              ) {
                if (
                  value.party_company.sales_partner.country_of_incorporation ===
                  sessionData.company_account.country_of_incorporation
                ) {
                  form?.setFieldValue('business_type', 'subagent');
                } else {
                  form?.setFieldValue('business_type', 'agent_nomination');
                }
                form?.setFieldValue(
                  [
                    'party',
                    `${businessType === BUSINESS_TYPE_SUBAGENT ? 'subagent' : 'overseas_agent'}`,
                  ],
                  {
                    party_company: value.party_company.sales_partner,
                  }
                );
              }
            }}
          />
        </Form.Item>
      </Col>
      {[BUSINESS_TYPE_SUBAGENT, BOOKING_THROUGH_OVERSEAS_AGENT].includes(businessType) && (
        <Col span={6}>
          <Form.Item
            rules={[{ required: !hide }]}
            name={[
              'party',
              `${businessType === BUSINESS_TYPE_SUBAGENT ? 'subagent' : 'overseas_agent'}`,
            ]}
            label={`${businessType === BUSINESS_TYPE_SUBAGENT ? 'Subagent' : 'Overseas Agent'}`}
          >
            <AddressCompanySearch />
          </Form.Item>
        </Col>
      )}
      <Col span={6}>
        <Form.Item
          name="sales_agent"
          label={
            <>
              <div style={{ paddingRight: '5px' }}>Sales Person</div>
              {sales_person_disabled && (
                <Tooltip
                  title={
                    'Sales Person is automatically fetched from the customer master. If this is empty, kindly ask the credit controller of your branch to map the sales person against the customer'
                  }
                >
                  <InfoCircleOutlined />
                </Tooltip>
              )}
            </>
          }
        >
          <GlobalSearch
            doc_type="Network::SalesPerson"
            disabled={sales_person_disabled || !editable}
            extraProps={{ placeholder: 'Name of the Sales Person' }}
          />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item name="priority" label="Priority">
          <Select disabled={false} placeholder="Select the priority of this order">
            {priorityOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item name="incoterms" label="Incoterms">
          <Select
            showSearch
            filterOption={(input, option) => {
              return `${option?.children || ''}`.toLowerCase().indexOf(input?.toLowerCase()) >= 0;
            }}
            allowClear
          >
            {INCOTERMS.map((opt: { key: string; display_name: string }) => (
              <Select.Option key={opt.key} value={opt.key}>
                {opt.display_name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item
          noStyle
          dependencies={[['is_external_quotation_number'], ['customer'], ['involved_branch']]}
        >
          {({ getFieldValue }) => {
            const is_external_quotation_number = getFieldValue('is_external_quotation_number');
            const customer = getFieldValue('customer');
            const branch_id = getFieldValue('involved_branch')?.id;
            const freightType = getFieldValue('freight_type');
            const tradeType = getFieldValue('trade_type');
            const loadType = getFieldValue('load_type');
            if (is_external_quotation_number)
              return (
                <Form.Item name="quotation_number" label="Quotation Ref">
                  <Input placeholder="Enter quotation number" />
                </Form.Item>
              );
            return (
              <Form.Item name="inquiry_option" label="Quotation Ref">
                <GlobalSearch
                  doc_type="SalesHub::InquiryOption"
                  // Passing key, because we want to reset select options on customer change
                  key={customer?.party_company?.id}
                  disabled={!customer || !branch_id}
                  searchProps={{
                    customer_company_id: customer?.party_company?.id,
                    involved_branch_id: branch_id,
                    freight_type: freightType,
                    trade_type: tradeType,
                    load_type: loadType,
                  }}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item name="is_external_quotation_number" valuePropName="checked">
          <Checkbox>External Quotation #</Checkbox>
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item name="purchase_order_number" label="Customer's Ref #">
          <Input placeholder="Enter Customer's Ref #" />
        </Form.Item>
      </Col>
      {!shipmentType && load_type === LOAD_TYPE_FCL && (
        <Col span={6}>
          <Form.Item name="preferred_carriers" label="Preferred Carriers">
            <GlobalSearch
              doc_type="Global::Carrier"
              searchProps={{ carrier_type: ['ocean', 'nvocc', 'coloader'] }}
              selectMode="multiple"
            />
          </Form.Item>
        </Col>
      )}
      <Col span={6}>
        <Form.Item name="other_job_number" label="Other Job #">
          <Input placeholder="Other Job #" />
        </Form.Item>
      </Col>
    </Row>
  );
}
