import React, { useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { get as _get } from 'lodash';
import {
  DOCUMENT_STATUS_EXECUTED,
  DOCUMENT_STATUS_GIVEN_TO_CUSTOMER,
  FREIGHT_TERMS,
  MBL_RELEASE_TYPE_ARRAY,
  MBL_TAG,
  SHIPMENT_DOCUMENT_TYPE_MASTER,
} from '../../constants';
import {
  BL_TYPE_ARRAY,
  DOCUMENT_TYPE_MASTER,
  SHIPMENT_TYPE_DIRECT,
  RELEASE_TYPE_RFS,
} from 'operations/modules/shipment/constants';
import { useApplicationContentContext, useSession } from 'common';
import {
  Col,
  Drawer,
  Input,
  Row,
  Spin,
  Radio,
  Typography,
  Checkbox,
  Button,
  message,
  Skeleton,
  Form,
  UploadedDocuments,
  documentsStore,
  CreditLimitCheckWrapper,
  CreditLimitCheckContext,
  DatePicker,
} from '@shipmnts/pixel-hub';
import { useApolloClient, useMutation } from '@apollo/client';
import ShipmentPartyDetails from 'operations/modules/shipment/components/ShipmentPartyDetails';
import { MBL_RELEASE } from 'operations/graphql/shipmentDocument';
import ShipmentContainerTable from './ShipmentContainerTable';
import { dayjs } from '@shipmnts/pixel-hub';
import { ShipmentDocumentValue } from 'operations/models/ShipmentDocument';
import { ShipmentValue } from 'operations/models/Shipment';
import { LOAD_TYPE_FCL } from 'operations/baseConstants';
import { useEmailDrawerContext } from 'common';
import {
  fetchShipmentDocumentParents,
  getFetchDocumentFunction,
  getNoPermissionTags,
} from 'operations/modules/helpers';
import BLReleaseToCustomerWrapper from './BLReleaseToCustomer';
interface MBLReleaseDrawerProps {
  shipment: ShipmentValue;
  visible?: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
  isEdit?: boolean;
}

export default function MBLReleaseWrapper(props: MBLReleaseDrawerProps) {
  const sessionData = useSession();
  return (
    <CreditLimitCheckWrapper sessionData={sessionData}>
      <MBLReleaseDrawer {...props} />
    </CreditLimitCheckWrapper>
  );
}

function MBLReleaseDrawer(props: MBLReleaseDrawerProps) {
  const [form] = Form.useForm();
  const { shipment, onClose, visible, onSuccess, isEdit } = props;
  const [validating, setValidating] = useState<boolean>(false);
  const [markAsGivenToCustomer, setMarkAsGivenToCustomer] = useState<boolean>(false);
  const [shipmentDocument, setShipmentDocument] = useState<ShipmentDocumentValue | undefined>();
  const [showGivenToCustomer, setShowGivenToCustomer] = useState(false);
  const documentType = DOCUMENT_TYPE_MASTER;
  const [receivedForShipment, setReceivedForShipment] = useState(false);
  const { runCreditRules } = useContext(CreditLimitCheckContext);
  const { setVisible, setEmailProps } = useEmailDrawerContext();
  const user = useSession();
  const client = useApolloClient();
  const { config_data } = useApplicationContentContext();

  const [releaseMbl, { data, loading, error }] = useMutation(MBL_RELEASE);

  const resource_ids = useMemo(() => {
    if (!shipment) return;
    return [shipment.getShipmentDocument(SHIPMENT_DOCUMENT_TYPE_MASTER).id];
  }, [shipment]);
  const fetchDocumentParents = useMemo(() => {
    if (!shipment) return;
    return fetchShipmentDocumentParents(shipment);
  }, [shipment]);
  const companies_roles_mapping = shipment.getAllPartiesRolesMapping(user);
  useEffect(() => {
    if (error) {
      message.error(error.message);
      return;
    }
    if (data?.mbl_release) {
      if (isEdit) {
        message.success('Document Edited Successfully!');
      } else {
        message.success('Document Released Successfully!');
      }
      if (markAsGivenToCustomer && resource_ids) {
        setShowGivenToCustomer(true);
      } else {
        if (onSuccess) onSuccess();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, loading, data, markAsGivenToCustomer, resource_ids, fetchDocumentParents]);

  useEffect(() => {
    const shipmentDoc = _get(shipment, 'shipment_documents', []).find(
      (doc: ShipmentDocumentValue) => doc.document_type === SHIPMENT_DOCUMENT_TYPE_MASTER
    );
    setShipmentDocument(shipmentDoc);
    form.setFieldsValue({
      master: {
        shipment_document: {
          shipment_number: shipmentDoc?.shipment_number,
          shipment_date:
            shipmentDoc && shipmentDoc?.shipment_date
              ? dayjs(shipmentDoc?.shipment_date * 1000)
              : undefined,
          bl_type: shipmentDoc?.bl_type || BL_TYPE_ARRAY[0].key,
          release_type: shipmentDoc?.release_type || MBL_RELEASE_TYPE_ARRAY[0].key,
          charge_terms: {
            freight_terms: shipmentDoc?.charge_terms?.freight_terms || FREIGHT_TERMS[0].key,
            other_charges_terms:
              shipmentDoc?.charge_terms?.other_charges_terms || FREIGHT_TERMS[0].key,
          },
        },
        party: shipment?.shipment_parties?.reduce((hash: any, p: any) => {
          hash[p.name] = p;
          return hash;
        }, {}),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipment, form]);

  const [documentsStoreValue, dispatch] = useReducer(documentsStore, {
    uploadingDocuments: [],
    errors: {},
    documents: [],
  });

  const documentInitialValues = {
    shipper_on_document: shipmentDocument?.shipper_on_document,
    consignee_on_document: shipmentDocument?.consignee_on_document,
  };
  const { permissions } = useSession();
  const tagsNoPermission: string[] = getNoPermissionTags(permissions);

  const required = isEdit ? false : true;
  return (
    <>
      {showGivenToCustomer && (
        <BLReleaseToCustomerWrapper
          shipmentDocument={_get(shipment, 'shipment_documents', []).find(
            (doc: ShipmentDocumentValue) => doc.document_type === SHIPMENT_DOCUMENT_TYPE_MASTER
          )}
          shipment={shipment}
          nextStatus={DOCUMENT_STATUS_GIVEN_TO_CUSTOMER}
          onClose={onClose}
          onSuccess={(sendEmail: boolean | undefined) => {
            if (sendEmail) {
              resource_ids &&
                setEmailProps({
                  title: 'Send MBL to Customer',
                  action_name: 'mbl_final_release',
                  resource_ids: resource_ids,
                  fetchDocumentParents: fetchShipmentDocumentParents(shipment),
                  companies_roles_mapping: companies_roles_mapping,
                  fetchDocuments: client
                    ? getFetchDocumentFunction(shipment, client, user, config_data)
                    : undefined,
                });
              setVisible(true);
            }
          }}
        />
      )}
      <Drawer
        className="drawer-auto-height"
        title={isEdit ? 'Edit MBL' : 'Enter MBL Release Details'}
        width={940}
        onClose={onClose}
        open={visible}
      >
        {!shipmentDocument && <Skeleton active />}
        {shipmentDocument && (
          <Form
            form={form}
            layout="vertical"
            onFinish={async (values: any) => {
              if (!shipmentDocument) {
                setValidating(false);
                return;
              }
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              const doc = documentsStoreValue?.documents?.find((doc: any) =>
                (doc.tags || []).includes(MBL_TAG)
              );
              if (!isEdit && !doc) {
                message.error('Master bill of lading uploading is mandatory');
                setValidating(false);
                return;
              }
              let passed = true;
              if (markAsGivenToCustomer) {
                passed = await runCreditRules({
                  actor_id: shipmentDocument.id,
                  trigger: 'bl_release',
                  actor_type: 'ShipmentDocument',
                  customer_name: shipment.customer_company?.registered_name || '',
                });
              }

              if (values.master.shipment_document.shipment_date) {
                values.master.shipment_document.shipment_date =
                  values.master.shipment_document.shipment_date.unix();
              }
              if (values.master.shipment_document.document_status_event_date) {
                values.master.shipment_document.document_status_event_date =
                  values.master.shipment_document.document_status_event_date.unix();
              }

              const shipmentDoc = values?.master?.shipment_document;
              if (shipmentDoc?.destination_agent_same_as_consignee) {
                delete shipmentDoc.destination_agent_same_as_consignee;
              }
              if (shipmentDoc?.origin_agent_same_as_shipper) {
                delete shipmentDoc.origin_agent_same_as_shipper;
              }
              if (passed) {
                values.master.shipment.consignee_party_name = values.master.consignee_party_name;
                //  else {
                if (isEdit) {
                  releaseMbl({
                    variables: {
                      id: shipmentDocument?.shipment_id,
                      shipment_document: values?.master?.shipment_document,
                      shipment: values?.master?.shipment,
                    },
                  });
                } else {
                  values.master.shipment_document.document_status = DOCUMENT_STATUS_EXECUTED;
                  releaseMbl({
                    variables: {
                      id: shipmentDocument?.shipment_id,
                      shipment_document: values?.master?.shipment_document,
                      shipment: values?.master?.shipment,
                      cargo_details: values?.containers_details?.map((c: any) => ({
                        shipment_container_id: c?.shipment_container_id,
                        gross_weight: c?.gross_weight,
                        net_weight: c?.net_weight,
                      })),
                      parties: Object.keys(values?.master?.party || {}).map((p: any) => ({
                        name: p,
                        party_address_id: values?.master?.party[p]?.party_address?.id,
                        party_company_id: values?.master?.party[p]?.party_company?.id,
                        party_details: values?.master?.party[p]?.party_details,
                      })),
                    },
                  });
                }
                // }
              } else {
                setValidating(false);
              }
            }}
            initialValues={{
              master: {
                shipment_document: {
                  bl_type: BL_TYPE_ARRAY[0].key,
                  release_type: MBL_RELEASE_TYPE_ARRAY[0].key,
                  charge_terms: {
                    freight_terms: FREIGHT_TERMS[0].key,
                    other_charges_terms: FREIGHT_TERMS[0].key,
                  },
                  destination_agent_same_as_consignee: true,
                  origin_agent_same_as_shipper: true,
                  document_status_event_date: dayjs(),
                },
                shipment: {
                  shipped_on_board_date:
                    shipment?.shipped_on_board_date && dayjs.unix(shipment?.shipped_on_board_date),
                  received_for_shipment_by_shipping_line_date:
                    shipment?.received_for_shipment_by_shipping_line_date &&
                    dayjs.unix(shipment?.received_for_shipment_by_shipping_line_date),
                },
                consignee_party_name: shipment?.consignee_party_name,
              },
            }}
          >
            <Row style={{ paddingBottom: '35px' }} gutter={15}>
              <Col span={10}>
                <Form.Item
                  name={[documentType, 'shipment_document', 'shipment_number']}
                  required={required}
                  rules={[{ required: required }]}
                  label={'MBL Number'}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  name={[documentType, 'shipment_document', 'shipment_date']}
                  required={required}
                  rules={[{ required: required }]}
                  label={'MBL Date'}
                >
                  <DatePicker style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ paddingBottom: '35px' }} gutter={15}>
              <Col span={10}>
                <Form.Item
                  name={[documentType, 'shipment_document', 'document_status_event_date']}
                  required={required}
                  rules={[{ required: required }]}
                  label={'Release Date'}
                >
                  <DatePicker style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginBottom: '15px' }} gutter={15}>
              <Col span={10}>
                <Form.Item
                  name={[documentType, 'shipment_document', 'bl_type']}
                  required={required}
                  rules={[{ required: required }]}
                  label={'B/L Type'}
                >
                  <Radio.Group
                    style={{ width: '100%' }}
                    options={BL_TYPE_ARRAY.map((item) => ({
                      value: item.key,
                      label: item.name,
                    }))}
                  ></Radio.Group>
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  name={[documentType, 'shipment_document', 'release_type']}
                  required={required}
                  rules={[{ required: required }]}
                  label={'Release Type'}
                >
                  <Radio.Group
                    style={{ width: '100%' }}
                    onChange={(prop) => {
                      setReceivedForShipment(prop.target.value === RELEASE_TYPE_RFS);
                    }}
                    options={MBL_RELEASE_TYPE_ARRAY.map((item) => ({
                      value: item.key,
                      label: item.name,
                    }))}
                  ></Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginBottom: '15px' }} gutter={15}>
              <Col span={10}>
                <Form.Item
                  name={[documentType, 'shipment', 'shipped_on_board_date']}
                  required={required}
                  rules={[{ required: required }]}
                  label="Shipped On Board Date"
                >
                  <DatePicker style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  name={[documentType, 'shipment', 'received_for_shipment_by_shipping_line_date']}
                  required={isEdit ? false : receivedForShipment}
                  label="RFS Date"
                >
                  <DatePicker style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginBottom: '15px' }} gutter={15}>
              <Col span={10}>
                <Form.Item
                  name={[documentType, 'shipment_document', 'charge_terms', 'freight_terms']}
                  label="Freight Terms"
                >
                  <Radio.Group
                    style={{ width: '100%' }}
                    options={FREIGHT_TERMS.map((item) => ({
                      value: item.key,
                      label: item.name,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  name={[documentType, 'shipment_document', 'charge_terms', 'other_charges_terms']}
                  label="Other charges terms"
                >
                  <Radio.Group
                    style={{ width: '100%' }}
                    options={FREIGHT_TERMS.map((item) => ({
                      value: item.key,
                      label: item.name,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col span={10}></Col>
            </Row>
            {!isEdit && (
              <>
                {shipment &&
                  shipment.shipment_type &&
                  shipment.shipment_type !== SHIPMENT_TYPE_DIRECT &&
                  shipment.id && (
                    <Row style={{ marginBottom: '15px' }} gutter={15}>
                      <Col span={20}>
                        {shipment?.freight_type &&
                          shipment?.shipment_type &&
                          shipment?.trade_type && (
                            <ShipmentPartyDetails
                              form={form}
                              documentType={documentType}
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              //@ts-ignore
                              freightType={shipment.freight_type}
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              //@ts-ignore
                              shipmentType={shipment.shipment_type}
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              //@ts-ignore
                              tradeType={shipment.trade_type}
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              //@ts-ignore
                              documentInitialValues={documentInitialValues}
                            />
                          )}
                      </Col>
                    </Row>
                  )}
                {shipment &&
                  shipment.shipment_type === SHIPMENT_TYPE_DIRECT &&
                  shipment.load_type === LOAD_TYPE_FCL && (
                    <div style={{ marginBottom: '40px' }}>
                      <ShipmentContainerTable
                        form={form}
                        shipment_id={shipment.id}
                        noContainerMessage={`You have not added any container. Can not release MBL!`}
                      />
                    </div>
                  )}
                <Row style={{ marginBottom: '44px' }}>
                  <div style={{ paddingBottom: '10px' }}>
                    <Typography.Text type="danger">* </Typography.Text>
                    <Typography.Text>
                      Upload MBL with <strong>Master bill of lading</strong> tag
                    </Typography.Text>
                  </div>
                  <div style={{ width: '100%', height: '100%', paddingBottom: '10px' }}>
                    {shipment?.id ? (
                      <>
                        <UploadedDocuments
                          sessionData={user}
                          parent_id={shipment?.id}
                          parent_type={'shipment'}
                          docgen_url={process.env.DOCGEN_URL || ''}
                          tagsNoPermission={tagsNoPermission}
                          reducer={[documentsStoreValue, dispatch]}
                          initialUploadDocumentTags={[MBL_TAG]}
                        />
                      </>
                    ) : (
                      <Spin />
                    )}
                  </div>
                </Row>
              </>
            )}

            <div
              style={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e8e8e8',
                padding: '10px 16px',
                textAlign: 'right',
                left: 0,
                background: '#fff',
                borderRadius: '0 0 4px 4px',
              }}
            >
              {!isEdit && shipment?.shipment_type === SHIPMENT_TYPE_DIRECT && (
                <Checkbox
                  checked={markAsGivenToCustomer}
                  style={{ marginRight: '16px' }}
                  onChange={(e) => setMarkAsGivenToCustomer(e.target.checked)}
                >
                  {'Mark as Given to Customer & Send Notifications'}
                </Checkbox>
              )}
              <Button className="margin-right-sm" onClick={onClose}>
                Cancel
              </Button>
              <Button
                type="primary"
                onClick={form.submit}
                className="margin-right-sm"
                loading={validating}
              >
                {isEdit ? 'Save MBL' : 'Release MBL'}
              </Button>
            </div>
          </Form>
        )}
      </Drawer>
    </>
  );
}
