import CreateUpdateManifest from 'operations/modules/shipment/components/DetailLayout/Manifests/CreateUpdateManifest';
import DeleteShipmentManifest from 'operations/modules/shipment/components/DetailLayout/Manifests/DeleteManifest';
import EnableDisableAutoTracking from 'operations/modules/shipment/components/DetailLayout/Manifests/EnableDisableAutoTracking';

export const enableAutoTrackingActionRenderer = ({ shipmentManifest }: any) => {
  return {
    actionParams: {
      shipmentManifest: shipmentManifest,
      isVisible: true,
      type: 'ENABLE',
    },
    component: EnableDisableAutoTracking,
  };
};
export const disableAutoTrackingActionRenderer = ({ shipmentManifest }: any) => {
  return {
    actionParams: {
      shipmentManifest: shipmentManifest,
      isVisible: true,
      type: 'DISABLE',
    },
    component: EnableDisableAutoTracking,
  };
};
export const editShipmentManifestActionRenderer = ({ shipmentManifest }: any) => {
  return {
    actionParams: {
      shipmentManifest: shipmentManifest,
      open: true,
      type: 'UPDATE',
    },
    component: CreateUpdateManifest,
  };
};
export const addShipmentManifestActionRenderer: any = ({ props }: any) => {
  return {
    actionParams: {
      open: true,
      type: 'CREATE',
    },
    component: CreateUpdateManifest,
  };
};

export const deleteShipmentManifestActionRenderer: any = ({ shipmentManifest }: any) => {
  return {
    actionParams: {
      isVisible: true,
      shipmentManifest: shipmentManifest,
    },
    component: DeleteShipmentManifest,
  };
};
