import React, { useEffect, useRef, useState } from 'react';
import RateExplorerFilter from './RateExplorerFilter';
import Inquiry, { InquiryValue } from 'sales_hub/models/inquiry';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_RATE_EXPLORER_DATA } from 'sales_hub/graphql/inquiry';
import { ExclamationCircleFilled, FormTable, Modal } from '@shipmnts/pixel-hub';
import { baseHelper } from 'common';
import { FETCH_EVENT_AND_MILESTONES } from 'common/components/Tracking/graphql/queries';
import {
  FREIGHT_TYPE_OCEAN,
  CREATE_BLANK_ACTION,
  CREATE_COPY_ACTION,
  PERMISSION_BUY_ESTIMATE,
  PERMISSION_SELL_ESTIMATE,
} from 'sales_hub/utils/constants';
import { useQuery } from '@apollo/client';
import { useSession } from 'common';
import { Button, Drawer, Form, message, Skeleton, Checkbox } from '@shipmnts/pixel-hub';
import { useInquiryDetail } from '../InquiryDetail/InquiryDetailLayout';
import { UPDATE_TRACKING_EVENT } from 'common/components/Tracking/graphql/queries';
import { convertDateToUnix } from '../InquiryLostLayout';
import { get } from 'lodash';
import { UPSERT_INQUIRY_OPTION } from 'sales_hub/graphql/inquiryOption';
import { pick, omitBy as _omitBy, isNil } from 'lodash';
import { ShipmentEstimateValue } from 'sales_hub/models/ShipmentEstimate';
import { DRAFT_STATUS, QuotationWithInquiryValue } from 'sales_hub/models/quotation';
import InquiryOptionDrawer, {
  getUpsertInquiryOptionsPayload,
  getInquiryOptionPayload as _getInquiryOptionPayload,
} from 'sales_hub/components/InquiryOption/InquiryOptionDrawer';
import { FormInstance } from '@shipmnts/pixel-hub';
import {
  getChargeableWeight,
  getNewEstimates,
  getQuoteTableDate,
  getRateExplorerColumn,
  getRateExplorerFromInquiry,
} from 'sales_hub/utils/utils';
import InquiryOptionForm from 'sales_hub/components/InquiryOption/InquiryOptionForm';
import { RateExplorerData } from './RateExplorer.type';
import { FILTERS } from 'sales_hub/utils/RateExplorerFiltersMapping';
import RateTable from './RateTable';
import { hasPermission, dayjs } from '@shipmnts/pixel-hub';

const { getCommodityTypeFromCargo } = baseHelper;
interface RateExplorerProp {
  inquiry: InquiryValue;
  allowedActions?: [string];
  onClose?: () => void;
  onSuccess?: () => void;
  addEstimateAction?: (newEstimate: ShipmentEstimateValue[]) => void;
  activeFilter: Record<string, any>;
}

const QUOTE_FIELDS = [
  'port_of_loading',
  'port_of_discharge',
  'carrier',
  'booking_vendor',
  'vendor_rate_agreement_number',
  'quote_currency',
  'exchange_rate',
  'terms_and_cond',
  'terms_and_cond_desc',
  'carrier_product',
];

const getInquiryOptionPayload = (
  rateExploreData: RateExplorerData,
  copyBuy: boolean,
  copySell: boolean,
  inquiry: InquiryValue,
  filterForm?: FormInstance
) => {
  const PCR = filterForm?.getFieldValue('place_of_carrier_receipt');
  const PCD = filterForm?.getFieldValue('place_of_carrier_delivery');
  const obj = {
    inquiry,
    ...pick(rateExploreData, QUOTE_FIELDS),
    place_of_carrier_receipt: PCR ? rateExploreData?.place_of_carrier_receipt : undefined,
    place_of_carrier_delivery: PCD ? rateExploreData?.place_of_carrier_delivery : undefined,
    id: undefined,
    quotation_number: undefined,
    status: DRAFT_STATUS,
    chargeable_weight: getChargeableWeight(inquiry),
    shipment_estimates: getNewEstimates(rateExploreData?.shipment_estimates, copyBuy, copySell),
  };
  return obj;
};

export default function RateExplorer(props: RateExplorerProp) {
  const { inquiry, onClose, onSuccess } = props;

  const [form] = Form.useForm();
  const [filterForm] = Form.useForm();
  const [upsertInquiryOption, { data: upsertData, error: upsertError, loading: upsertLoading }] =
    useMutation(UPSERT_INQUIRY_OPTION);

  const sessionData = useSession();

  const hasBuyPermission = hasPermission(sessionData?.permissions, {
    AND: [{ name: PERMISSION_BUY_ESTIMATE, docType: 'NewAccounting::ShipmentEstimate' }],
  });
  const hasSellPermission = hasPermission(sessionData?.permissions, {
    AND: [{ name: PERMISSION_SELL_ESTIMATE, docType: 'NewAccounting::ShipmentEstimate' }],
  });
  const [open, setOpen] = useState(false);
  const [copyBuy, setCopyBuy] = useState(hasBuyPermission);
  const [copySell, setCopySell] = useState(hasSellPermission);
  const [activeAction, setActiveAction] = useState<string>();
  const [selectedRow, updateSelectedRow] = useState<string[]>([]);
  const [rate, setRate] = useState<RateExplorerData | undefined>();
  const { inquiry: newInquiry, inquiry_global_id } = useInquiryDetail();
  const [isChecked, setIsChecked] = useState(
    newInquiry?.last_action_status === 'Pricing shared with Sales'
  );
  const [updateEvent] = useMutation(UPDATE_TRACKING_EVENT);
  const [getRateExplorerData, { data, loading: loadingData }] =
    useLazyQuery(GET_RATE_EXPLORER_DATA);
  const [current, setCurrent] = useState(0);
  const [createdInquiryOption, setCreatedInquiryOption] = useState<
    Record<string, QuotationWithInquiryValue>
  >({});

  const next = () => {
    setCurrent(current + 1);
  };

  const resource_obj = getRateExplorerFromInquiry(inquiry);

  const columns = getRateExplorerColumn(
    data?.get_rate_explorer_data,
    resource_obj,
    sessionData,
    setRate
  );

  const rateExplorerDataMap: Record<string, RateExplorerData> = {};
  (data?.get_rate_explorer_data || []).forEach((rateExplorerData: RateExplorerData) => {
    if (rateExplorerData?.id) rateExplorerDataMap[rateExplorerData?.id] = rateExplorerData;
  });
  const steps = selectedRow.map((key: string) => {
    const rateData = rateExplorerDataMap[key];

    return {
      id: rateData?.id,
      resource_number: rateData?.resource_number,
      inquiryOption: getInquiryOptionPayload(rateData, copyBuy, copySell, inquiry, filterForm),
    };
  });

  useEffect(() => {
    if (upsertData && !upsertError) {
      const newInquiryOption = _getInquiryOptionPayload(
        upsertData?.upsert_inquiry_option
      ) as QuotationWithInquiryValue;

      setCreatedInquiryOption((prev) => {
        prev[current] = newInquiryOption;
        return prev;
      });
      message.success('Quotation  Created Sucessfully');

      if (activeAction === CREATE_COPY_ACTION) {
        if (onClose) onClose();
        if (onSuccess) onSuccess();
        setOpen(false);
      } else {
        next();
      }
      setActiveAction(undefined);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upsertData, upsertError]);

  useEffect(() => {
    if (data) updateSelectedRow([]);
  }, [data]);

  const drawerButtons = [];

  if (current === steps.length - 1)
    drawerButtons.push(
      <Button
        type="primary"
        loading={upsertLoading}
        onClick={() => {
          setActiveAction(CREATE_COPY_ACTION);
          form.submit();
        }}
      >
        Finish
      </Button>
    );

  const inquiryOption: any = createdInquiryOption?.[current] || steps[current]?.inquiryOption;
  const today = dayjs.unix(Date.now() / 1000);
  const intialFilter = _omitBy(
    {
      place_of_carrier_receipt:
        inquiry.freight_type === FREIGHT_TYPE_OCEAN ? inquiry.place_of_carrier_receipt : undefined,
      place_of_carrier_delivery:
        inquiry.freight_type === FREIGHT_TYPE_OCEAN ? inquiry.place_of_carrier_delivery : undefined,
      port_of_loading: inquiry.origin,
      port_of_discharge: inquiry.destination,
      carrier: inquiry.preferred_carriers,
      incoterms: inquiry.incoterms || undefined,
      services: inquiry.services,
      load_type: [inquiry.load_type],
      cargo_properties: getCommodityTypeFromCargo(
        inquiry?.cargos?.map((ele) => ele.cargo_properties)
      ),
      destination_custom_clearance: inquiry.destination_custom_clearance_location,
      origin_custom_clearance: inquiry.origin_custom_clearance_location,
      all_customer: false,
      created_at: [today.subtract(45, 'days'), today],
      container_type_code:
        inquiry.freight_type === FREIGHT_TYPE_OCEAN
          ? (inquiry.container_requests || []).map((ele) => ele.container_type_code)
          : undefined,
    },
    isNil
  );

  const defaultFilter = {
    freight_type: inquiry.freight_type,
    trade_type: inquiry.trade_type,
    inquiry_number: ['!=', inquiry.inquiry_number],
  };

  const estimatesRef = useRef<{
    getValue: () => ShipmentEstimateValue[];
    clearEstimates: () => void;
    upsertEstiamtes: (estimates: ShipmentEstimateValue[]) => void;
  }>();

  useEffect(() => {
    if (!activeAction) {
      if (inquiryOption) {
        const values = _getInquiryOptionPayload(inquiryOption);
        form.setFieldsValue({
          ...values,
          shipment_estimates: values?.shipment_estimates?.map((estimate: any) => {
            return { ...estimate, supplier_company_id: null, supplier_company: null };
          }),
        });
        if (!inquiryOption.terms_and_cond) form.resetFields(['terms_and_cond']); // resting for autofill default
      }
      if (inquiryOption?.shipment_estimates && estimatesRef?.current) {
        estimatesRef.current?.upsertEstiamtes(
          inquiryOption?.shipment_estimates?.map((estimate: any) => {
            return { ...estimate, supplier_company_id: null, supplier_company: null };
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inquiryOption]);

  if (current < steps.length - 1)
    drawerButtons.push(
      <Button
        type="primary"
        loading={upsertLoading}
        onClick={() => {
          form.submit();
        }}
      >
        {`${!!inquiryOption.id ? 'Edit' : 'Create'} & Next(${current + 1}/${steps.length})`}
      </Button>
    );

  if (current > 0)
    drawerButtons.push(
      <Button
        style={{ marginLeft: '10px' }}
        type="primary"
        disabled={upsertLoading}
        onClick={() => {
          setCurrent(current - 1);
        }}
      >
        Previous
      </Button>
    );

  const { confirm } = Modal;
  const showConfirm = () => {
    confirm({
      title: 'Do you Want to Close?',
      okText: 'Yes',
      icon: <ExclamationCircleFilled />,
      onOk() {
        setOpen(false);
      },
    });
  };

  const { data: mileStoneData } = useQuery(FETCH_EVENT_AND_MILESTONES, {
    variables: {
      ids: Array.isArray(inquiry_global_id)
        ? inquiry_global_id?.map((i) => parseInt(i))
        : [parseInt(inquiry_global_id || '')],
      reference_type: 'SalesHub::InquiryGlobal',
      workflow_type: 'main',
    },
  });
  if (current === steps.length - 1)
    drawerButtons.push(
      <Checkbox
        style={{ marginLeft: '10px' }}
        checked={isChecked}
        disabled={newInquiry?.last_action_status !== 'Pricing Pending'}
        onChange={(e) => setIsChecked(e.target.checked)}
      >
        Mark as Pricing shared with sales
      </Checkbox>
    );
  return (
    <Drawer
      width={'90%'}
      open={true}
      placement="right"
      title={`Rate Explorer ${inquiry.inquiry_number}`}
      footer={[
        <div key="actions" style={{ textAlign: 'right' }}>
          {hasBuyPermission && (
            <Checkbox checked={copyBuy} onClick={() => setCopyBuy(!copyBuy)}>
              Copy Buy
            </Checkbox>
          )}
          {hasSellPermission && (
            <Checkbox checked={copySell} onClick={() => setCopySell(!copySell)}>
              Copy Sell
            </Checkbox>
          )}
          <Button
            key="blank_quote"
            style={{ border: '1px solid #0a4fca', color: '#0a4fca' }}
            loading={upsertLoading}
            onClick={() => {
              setActiveAction(CREATE_BLANK_ACTION);
            }}
          >
            Create Blank Quote
          </Button>

          <Button
            style={{ marginLeft: '3px' }}
            type="primary"
            key="copy_quote"
            disabled={selectedRow.length === 0}
            onClick={() => {
              setOpen(true);
            }}
          >
            Copy & Create Quote Options
          </Button>
        </div>,
      ]}
      onClose={() => {
        if (onClose) onClose();
      }}
    >
      <RateExplorerFilter
        activeFilter={FILTERS}
        form={filterForm}
        getRateExplorerData={getRateExplorerData}
        resource={resource_obj}
        intialFormValue={intialFilter}
        defaultFilter={defaultFilter}
      />
      {!loadingData && (
        <FormTable
          rowData={getQuoteTableDate(data?.get_rate_explorer_data)}
          columns={columns}
          height={'50vh'}
          reportName="inquiry_options"
          checkbox_always_visible={false}
          showCheckBoxOnHeader
          rowSelection="multiple"
          onSelectionChanged={(params: any) => {
            const selectedRows =
              params.api?.getSelectedRows()?.map((ele: RateExplorerData) => ele.id) || [];
            updateSelectedRow(selectedRows);
          }}
          reportConfig={{
            rowMultiSelectWithClick: true,
            defaultColDef: {
              suppressMovable: true,
            },
          }}
        />
      )}

      {!!rate && <RateTable onClose={() => setRate(undefined)} rate={rate} />}

      {activeAction === CREATE_BLANK_ACTION && (
        <InquiryOptionDrawer
          isVisible={true}
          setIsVisible={(visible: boolean) => {
            if (visible) setActiveAction(CREATE_BLANK_ACTION);
            else setActiveAction(undefined);
          }}
          onSuccess={() => {
            if (onClose) onClose();
            if (onSuccess) onSuccess();
            setOpen(false);
          }}
          inquiry={inquiry}
        />
      )}
      {loadingData && <Skeleton paragraph={{ rows: 10 }} />}
      <Drawer
        width={'80%'}
        title={
          inquiryOption?.id
            ? `Edit ${inquiryOption?.quotation_number}`
            : `Copying from ${steps[current]?.resource_number}`
        }
        placement="right"
        onClose={() => {
          setCurrent(0);
          showConfirm();
        }}
        open={open}
        footer={drawerButtons}
      >
        <>
          {inquiryOption && (
            <InquiryOptionForm
              inquiry={Inquiry.create(inquiryOption.inquiry)}
              inquiryOption={_getInquiryOptionPayload({
                ...inquiryOption,
                shipment_estimates: inquiryOption?.shipment_estimates?.map((estimate: any) => {
                  return { ...estimate, supplier_company_id: null, supplier_company: null };
                }),
              })}
              form={form}
              estimatesRef={estimatesRef}
              onFinish={(formValues: any) => {
                const upsertPayload = getUpsertInquiryOptionsPayload(
                  formValues,
                  estimatesRef,
                  inquiryOption,
                  undefined
                );
                const events = get(mileStoneData, 'fetch_tracking_events', []);
                const eventsToBeEdited = events.filter(
                  (o: { name: string }) => o.name === 'Pricing shared with Sales'
                );
                if (isChecked && newInquiry?.last_action_status === 'Pricing Pending') {
                  updateEvent({
                    variables: {
                      ids: [parseInt(eventsToBeEdited[0]?.id)],
                      input: {
                        actual_date: convertDateToUnix(new Date().toISOString()),
                        event_data: {},
                      },
                    },
                  });
                }
                upsertInquiryOption({
                  variables: {
                    inquiry_id: inquiryOption?.inquiry?.id,
                    inquiry_option: upsertPayload,
                  },
                });
              }}
            />
          )}
        </>
      </Drawer>
    </Drawer>
  );
}
