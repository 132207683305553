import { Column } from 'common/models/Report';
import { Button, Modal, FormTable } from '@shipmnts/pixel-hub';
import React from 'react';
import { ShipmentEstimateValue } from 'sales_hub/models/ShipmentEstimate';
import { RateExplorerData } from './RateExplorer.type';

interface RateTableProps {
  rate: RateExplorerData | undefined;
  onClose: () => void;
}

function RateTable(props: RateTableProps) {
  const { rate, onClose } = props;
  const shipmentEstimates = rate?.shipment_estimates;
  const footer = [
    <Button key="ok" onClick={onClose}>
      Ok
    </Button>,
  ];
  const columnDefs: Column[] = [
    {
      headerName: 'Item Name',
      field: 'item',
      colId: 'item',
      minWidth: 180,
      resizable: true,
    },

    {
      headerName: 'Basis',
      field: 'uom',
      colId: 'uom',
      minWidth: 100,
      resizable: true,
    },
    {
      headerName: 'QTY',
      field: 'quantity',
      colId: 'quantity',
      minWidth: 100,
      resizable: true,
    },
    {
      headerName: 'Sell Rate',
      field: 'sell_rate',
      colId: 'sell_rate',
      columnType: 'Currency',
      resizable: true,
      minWidth: 100,
      aggFunc: undefined,
      cellRenderer: 'CurrencyTypeRenderer',
      cellRendererParams: (params: any) => ({
        precision: 4,
        currency: params.data.sell_currency,
        showCurrency: true,
      }),
    },
    {
      headerName: 'Total Income',
      field: 'total_sell_amount',
      colId: 'total_sell_amount',
      columnType: 'Currency',
      minWidth: 100,
      resizable: true,
      aggFunc: undefined,
      cellRenderer: 'CurrencyTypeRenderer',
      cellRendererParams: (params: any) => ({
        precision: 4,
        showCurrency: true,
      }),
    },
    {
      headerName: 'Buy Rate',
      field: 'buy_rate',
      colId: 'buy_rate',
      columnType: 'Currency',
      minWidth: 100,
      resizable: true,
      aggFunc: undefined,
      cellRenderer: 'CurrencyTypeRenderer',
      cellRendererParams: (params: any) => ({
        precision: 4,
        currency: params.data.buy_currency,
        showCurrency: true,
      }),
    },
    {
      headerName: 'Total Expense',
      field: 'total_buy_amount',
      colId: 'total_buy_amount',
      columnType: 'Currency',
      minWidth: 100,
      aggFunc: undefined,
      cellRenderer: 'CurrencyTypeRenderer',
      cellRendererParams: (params: any) => ({
        precision: 4,
        showCurrency: true,
      }),
    },
  ];

  return (
    <Modal
      title={`Rates - ${rate?.resource_number}`}
      open={true}
      onCancel={() => onClose()}
      width={'65%'}
      footer={footer}
    >
      <FormTable
        reportName="rate_table"
        columns={columnDefs}
        rowData={(shipmentEstimates || []).map((est: ShipmentEstimateValue) => {
          return {
            item: est.item,
            quantity: est.quantity,
            uom: est.equipment_type ? est.uom + '/' + est.equipment_type : est.uom,
            buy_rate: est.buy_rate,
            sell_rate: est.sell_rate,
            buy_currency: est.buy_currency,
            sell_currency: est.sell_currency,
            total_buy_amount: est.total_buy_amount,
            total_sell_amount: est.total_sell_amount,
          };
        })}
        reportConfig={{}}
      />
    </Modal>
  );
}

export default RateTable;
