import { Form, Row, Col, InputNumber, DatePicker } from '@shipmnts/pixel-hub';
import { ROW_GUTTER } from 'operations/modules/shipment/constants';
import React from 'react';

export const OceanLastFreeDays = () => {
  return (
    <>
      <Row gutter={ROW_GUTTER}>
        <Col span={6}>
          <Form.Item name="lfd_at_carrier" label="Last free date at Carrier">
            <DatePicker showTime style={{ width: '100%' }} format="DD-MM-YYYY HH-mm" />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item name="lfd_at_empty_return" label="Last free date at Empty return">
            <DatePicker showTime style={{ width: '100%' }} format="DD-MM-YYYY HH-mm" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="lfd_at_pocd" label="Last free date at POCD">
            <DatePicker showTime style={{ width: '100%' }} format="DD-MM-YYYY HH-mm" />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item name="lfd_at_pod" label="Last free date at POD">
            <DatePicker showTime style={{ width: '100%' }} format="DD-MM-YYYY HH-mm" />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item name="destination_carrier_free_days" label="Destination Carrier Free Days">
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="destination_port_free_days" label="Destination Port Free Days">
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item name="origin_carrier_free_days" label="Origin Carrier Free Days">
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
