import React, { Fragment, useEffect, useState } from 'react';
import {
  BaseTable,
  StringWithToolTip,
  NumberTypeRenderer,
  AccountsLinkRender,
} from '@shipmnts/pixel-hub';
import { Column } from 'operations/models/Report';
import { useSession } from 'common';
import { useShipmentDetail } from '../ShipmentDetailLayout';
import { erpnextApiHelper } from 'network';
import { DocumentRenderer, PaymentStatusRenderer } from './components/renderers';
import { SessionDataValue } from 'operations/models/SessionData';
import { Skeleton } from '@shipmnts/pixel-hub';
import { GridReadyEvent } from '@ag-grid-community/core';
import { ActionRendererDetailReport } from 'operations';

function AccountsTab() {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const session: SessionDataValue = useSession();
  const [errorMessage, setErrorMessage] = useState('');
  const default_currency = session?.company_account?.default_currency;
  const { shipment } = useShipmentDetail();
  const columnDefs: { [key: string]: Column } = {
    id: {
      headerName: 'ID',
      field: 'voucher_no',
      colId: 'voucher_no',
      hide: true,
      getQuickFilterText: () => '',
      valueGetter: ({ data }) => `${data.doctype}_${data.voucher_no}`,
      suppressColumnsToolPanel: true,
    },
    doctype: {
      headerName: 'docType',
      field: 'doctype',
      colId: 'doctype',
      rowGroup: true,
      hide: true,
      getQuickFilterText: () => '',
      suppressColumnsToolPanel: true,
    },
    supplier_invoice_no: {
      headerName: 'Supplier Invoice #',
      field: 'supplier_invoice_no',
      colId: 'supplier_invoice_no',
      columnType: 'String',
      valueGetter: (params: any) => params.data?.bill_no,
      cellRendererParams: {
        id_field: 'voucher_no',
        doc_type_field: 'doctype',
        doc_status_field: 'payment_status',
      },
      cellRenderer: 'accounts_link_render',
      width: 150,
    },
    status: {
      headerName: 'Status',
      field: 'status',
      colId: 'status',
      width: 130,
      cellRenderer: 'document_status_renderer',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: ['Draft', 'Submit', 'Cancelled'],
      },
    },
    posting_date: {
      headerName: 'Posting Date',
      field: 'posting_date',
      colId: 'posting_date',
      columnType: 'Date',
      width: 120,
      filter: false,
    },
    party: {
      headerName: 'Customer/Supplier',
      field: 'voucher_party',
      colId: 'voucher_party',
      columnType: 'String',
      width: 200,
      cellRenderer: 'string_with_tool_tip',
    },
    mbl_hbl: {
      headerName: shipment?.freight_type === 'air' ? 'AWB #' : 'BL #',
      field: 'shipment_number',
      colId: 'shipment_number',
      columnType: 'String',
      width: 150,
    },
    voucher_amt: {
      headerName: 'Voucher Amount',
      field: 'voucher_amount',
      colId: 'voucher_amount',
      columnType: 'Currency',
      width: 180,
      aggFunc: undefined,
      cellRendererParams: {
        currency_column: 'voucher_currency',
      },
    },
    voucher_conversion_rate: {
      headerName: 'Ex. Rate',
      field: 'conversion_rate',
      colId: 'conversion_rate',
      width: 180,
      aggFunc: undefined,
      columnType: 'Float',
    },
    nature: {
      headerName: 'Nature',
      field: 'nature',
      colId: 'nature',
      columnType: 'String',
      width: 80,
    },
    income: {
      headerName: default_currency ? `Income (${default_currency})` : 'Income',
      field: 'income',
      colId: 'income',
      width: 180,
      columnType: 'Float',
    },
    expense: {
      headerName: default_currency ? `Expense (${default_currency})` : 'Expense',
      field: 'expense',
      colId: 'expense',
      width: 180,
      columnType: 'Float',
    },
    margin: {
      headerName: default_currency ? `Margin (${default_currency})` : 'Margin',
      field: 'margin',
      colId: 'margin',
      columnType: 'Float',
      cellRenderer: 'number_type_renderer',
      cellRendererParams: {
        positive_class: 'success-color',
        negative_class: 'error-color',
      },
      width: 180,
      pinned: 'right',
    },
    outstanding_amt: {
      headerName: default_currency ? `Outstanding (${default_currency})` : 'Outstanding',
      field: 'balance_outstanding',
      colId: 'balance_outstanding',
      columnType: 'Float',
      width: 180,
      aggFunc: undefined,
    },
    net_outstanding_amount: {
      headerName: 'Net Outstanding',
      field: 'net_outstanding_amount',
      colId: 'net_outstanding_amount',
      columnType: 'Currency',
      cellRendererParams: {
        currency_column: 'voucher_currency',
      },
      aggFunc: undefined,
      width: 180,
    },
    due_date: {
      headerName: 'Due Date',
      field: 'due_date',
      colId: 'due_date',
      columnType: 'Date',
      width: 120,
      filter: false,
    },
    payment_status: {
      headerName: 'Payment Status',
      field: 'payment_status',
      colId: 'payment_status',
      width: 130,
      cellRenderer: 'payment_status_renderer',
      pinned: 'right',
    },
    taxes: {
      headerName: default_currency ? `Taxes (${default_currency})` : 'Taxes',
      field: 'taxes',
      colId: 'taxes',
      aggFunc: 'sum',
      columnType: 'Currency',
      width: 130,
      filter: 'agSetColumnFilter',
      cellRendererParams: {
        currency: default_currency,
      },
    },
    base_taxes_and_charges_deducted: {
      headerName: default_currency ? `TDS Amount (${default_currency})` : 'TDS Amount',
      field: 'tds_amount',
      colId: 'tds_amount',
      aggFunc: 'sum',
      columnType: 'Currency',
      width: 130,
    },
    actions: {
      headerName: 'Actions',
      suppressMovable: true,
      suppressNavigable: true,
      colId: 'actions',
      field: 'actions',
      valueGetter: 'data',
      cellRenderer: 'ActionRendererDetailReport',
      cellRendererParams: {
        doc_type_id: 'NewAccounting::Invoices',
      },
      minWidth: 55,
      pinned: 'right',
      headerComponent: 'StaticHeaderComponent',
    },
  };

  const getInvoices = async (jobNumbers: any[]) => {
    try {
      const method = 'shipmnts.controllers.invoice.get_shipment_accounting';
      if (!jobNumbers) throw new Error('invalid params');
      const url = `/api/method/${method}`;
      const response: any = await erpnextApiHelper.erpNextAxiosCall({
        action: 'get',
        url,
        params: {
          params: {
            job_numbers: jobNumbers,
          },
        },
      });
      if (response) {
        setData(Object.values(response.response?.data?.message || {}).flat());
        setLoading(false);
      }
    } catch (error: any) {
      setErrorMessage(error.message);
      setLoading(false);
      console.error(error);
    }
  };

  const setDefaultFilters = (grid: GridReadyEvent) => {
    const filter = {
      status: {
        type: 'set',
        values: ['Draft', 'Submit'],
      },
    };
    grid.api.setFilterModel(filter);
    grid.api.onFilterChanged();
  };

  useEffect(() => {
    // if (shipment?.job_number) {
    const jobNumbers = [shipment?.job_number || shipment?.shipment_booking_number];
    if (shipment?.house_shipments?.length) {
      shipment?.house_shipments?.forEach((house_shipment: any) => {
        jobNumbers.push(house_shipment?.job_number || house_shipment?.shipment_booking_number);
      });
    }
    getInvoices(jobNumbers);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipment]);

  const components = {
    accounts_link_render: AccountsLinkRender,
    document_status_renderer: DocumentRenderer,
    payment_status_renderer: PaymentStatusRenderer,
    string_with_tool_tip: StringWithToolTip,
    number_type_renderer: NumberTypeRenderer,
    ActionRendererDetailReport,
  };

  if (loading) {
    return <Skeleton paragraph={{ rows: 4 }} loading={loading} active />;
  }

  return (
    <Fragment>
      <div style={{ paddingTop: '0px' }}>
        {!errorMessage && (
          <BaseTable
            reportName={'accounts_tab'}
            columns={Object.values(columnDefs)}
            rowData={data}
            onGridReady={setDefaultFilters}
            reportConfig={{
              groupDefaultExpanded: 1,
              components: components,
              autoGroupColumnDef: {
                headerName: 'Voucher #',
                pinned: 'left',
                field: 'voucher_no',
                valueGetter: (params: any) => params.data?.voucher_no,
                cellRendererParams: {
                  id_field: 'voucher_no',
                  doc_type_field: 'doctype',
                  doc_status_field: 'payment_status',
                  innerRenderer: 'accounts_link_render',
                },
                cellRenderer: 'agGroupCellRenderer',
                minWidth: 250,
              },
            }}
          />
        )}
        {errorMessage && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {errorMessage}
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default AccountsTab;
