import React, { useState, useRef, useEffect, useCallback } from 'react';
import { GridOptions, ColumnRowGroupChangedEvent, GridApi } from '@ag-grid-community/core';
import { useApolloClient, ApolloClient } from '@apollo/client';
import { pickBy as _pickBy } from 'lodash';
import { Variables } from 'operations/models/Report';
import { DEFAULT_DATE_FORMAT, DatePicker, Dayjs, dayjsGenerateConfig } from '@shipmnts/pixel-hub';
import {
  oto_dashboard_columns_mapping,
  OTODashboardQuantityType,
  quantity_fields,
} from './OTOReportFields';
import { VoyageScheduleSearch } from 'common';
import { getColumnFiltersinGraphqlFormat, change_level, formatOverlayNoRowsTemplate } from 'common';
import { OTO_DASHBOARD_REPORT } from 'operations/modules/reports/graphql/otoReport';
import {
  renderUniqCommodity,
  renderLinkCell,
  getContainerTemperature,
  getContainerSetting,
} from 'operations/modules/reports/oldCellRenderers';
import { OceanTransportOrderValue } from 'operations/models/OceanTransportOrder';
import { VesselValue } from 'operations/models/Vessel';
import { LocationValue } from 'operations/models/Location';
import { CompanyValue } from 'operations/models/Company';
import { ContainerSettingValue } from 'operations/models/ContainerRequest';
import { set as _set } from 'lodash';
import { renderDate } from 'common';
import { Form, ClientSideReport } from '@shipmnts/pixel-hub';
import { RangeValueType } from 'rc-picker/lib/PickerInput/RangePicker';
// import SummaryCard, { CardType } from '../SummaryCard';
const { RangePicker } = DatePicker;
const PAGE_SIZE = 3000;
const groupColId = 'ag-Grid-AutoColumn';
const ALLOCATION_PENDING_TEXT = 'Allocation Pending';

interface OTODashboardData extends OceanTransportOrderValue {
  vessel?: VesselValue;
  voyage_number?: string;
  port_of_loading?: LocationValue;
  port_of_discharge?: LocationValue;
  place_of_carrier_receipt?: LocationValue;
  estimated_time_of_departure?: number;
  customer_companies?: Array<CompanyValue>;
  container_type?: string;
  container_type_code?: string;
  container_settings?: ContainerSettingValue;
  quantity_requested?: number;
  quantity_received?: number;
  quantity_confirmed?: number;
  quantity_expired?: number;
  quantity_allocated?: number;
  quantity_allocation_pending?: number;
  quantity_picked_up?: number;
  quantity_pick_up_pending?: number;
  quantity_origin_port_gate_in_pending?: number;
  quantity_origin_port_gated_in?: number;
  quantity_loading_pending?: number;
  quantity_loaded_on_vessel?: number;
  quantity_shutout?: number;
  quantity_offloaded?: number;
  quantity_gate_pass_pending?: number;
  quantity_gate_pass_confirmed?: number;
}

type OTODateFieldType = 'estimated_time_of_departure' | 'booking_date';

const renderOTODates = (
  row: OTODashboardData,
  dateFields: OTODateFieldType[],
  dateFormat: string
) => {
  return dateFields.reduce(
    (dateObject: { [key: string]: string | undefined }, field: OTODateFieldType) => {
      const dateValue = row[field] as number;
      _set(dateObject, field, renderDate(dateValue, dateFormat));
      return dateObject;
    },
    {}
  );
};

const components = {
  renderLinkCell: renderLinkCell,
};

// const cards: { [key: string]: CardType } = {
//   carrier_booking_status: {
//     title: (
//       <div>
//         Carrier Booking Status{' '}
//         <Typography.Text type="secondary">( Container counts )</Typography.Text>
//       </div>
//     ),
//     children: [
//       { key: 'quantity_requested', title: 'Requested', minWidth: 100 },
//       { key: 'quantity_confirmed', title: 'Confirmed', minWidth: 100 },
//       { key: 'quantity_expired', title: 'Expired', minWidth: 100 },
//       { key: 'quantity_allocation_pending', title: 'Allocation Pending', minWidth: 100 },
//       { key: 'quantity_allocated', title: 'Allocated', minWidth: 100 },
//     ],
//   },
//   container_operations_status: {
//     title: 'Container Ops',
//     children: [
//       {
//         key: 'quantity_pick_up_pending',
//         title: 'Pickup pending',
//         suffix_key: 'quantity_allocated',
//         minWidth: 150,
//       },
//       {
//         key: 'quantity_origin_port_gate_in_pending',
//         title: 'Gate in pending',
//         suffix_key: 'quantity_picked_up',
//         minWidth: 150,
//       },
//       {
//         key: 'quantity_loading_pending',
//         title: 'Loading pending',
//         suffix_key: 'quantity_origin_port_gated_in',
//         minWidth: 150,
//       },
//     ],
//   },
// };

export const getTotalRow = (
  otoData: OTODashboardData[]
  // setTotalQuantities: (object: Record<OTODashboardQuantityType, number>) => void
) => {
  const total_quantity_obj = quantity_fields.reduce(
    (total_obj: Record<string, number>, field: string) => {
      total_obj[field] = 0;
      return total_obj;
    },
    {}
  );

  otoData.forEach((oto: OTODashboardData) => {
    quantity_fields.forEach((field: OTODashboardQuantityType) => {
      total_quantity_obj[field] += oto?.[field] || 0;
    });
  });
  // setTotalQuantities(total_quantity_obj);
  return {
    ...total_quantity_obj,
  };
};

const fetchOTODashboardData = async (variables: Variables, client: ApolloClient<object>) => {
  const { data, errors } = await client.query({
    query: OTO_DASHBOARD_REPORT,
    variables,
    fetchPolicy: 'no-cache',
  });
  if (errors) console.error(errors);
  return {
    data: data?.ocean_transport_order_dashboard_report?.data,
    total: data?.ocean_transport_order_dashboard_report?.total,
    error: errors ? true : false,
  };
};

const renderOTODashboardData = (otoData?: OTODashboardData[]) => {
  return (otoData || []).map((row) => {
    const vessel_voyage = `${row?.vessel?.name || ''} | ${row?.voyage_number || ''}`;
    const temperature = getContainerTemperature(row.container_type_code, row.container_settings);
    const commodity = renderUniqCommodity(row.cargos || []);
    return {
      ...row,
      customer_company: {
        ...row?.customer_companies?.[0],
        registered_name: row?.customer_companies?.[0]?.registered_name || ALLOCATION_PENDING_TEXT,
      },
      created_by: `${row?.created_by?.first_name || ''} ${row?.created_by?.last_name || ''}`,
      commodity: commodity || 'N/A',
      temperature: temperature || 'N/A',
      vessel_voyage: vessel_voyage || 'N/A',
      container_settings: getContainerSetting({
        container_type_code: row.container_type_code,
        container_settings: row.container_settings,
      }),
      oto_id: row?.booking_number ? `${row?.booking_number}` : `ID: ${row?.id || ''}`,
      ...renderOTODates(row, ['estimated_time_of_departure', 'booking_date'], DEFAULT_DATE_FORMAT),
    };
  });
};

const required_filter_overlay = 'Please Enter Either ETD or Vessel Filter To Run This Report';

const OTODashboard = React.memo(function OTODashboard(props: { report_name: string }): JSX.Element {
  const { report_name } = props;
  const columns = oto_dashboard_columns_mapping[report_name];
  const [form] = Form.useForm();
  const [otoData, setOTOData] = useState<Array<any>>([]);
  const gridRef = useRef<GridOptions>();

  const client = useApolloClient();
  const [etdRange, setEtdRange] = useState<RangeValueType<Dayjs>>([
    dayjsGenerateConfig.getNow(),
    dayjsGenerateConfig.getNow().add(14, 'day'),
  ]);
  // const [totalQuantities, setTotalQuantities] = useState<
  //   Record<OTODashboardQuantityType, number> | undefined
  // >();
  const [overlayNoRowsTemplate, setOverlayNoRowsTemplate] =
    useState<string>(required_filter_overlay);
  // const [loading, setLoading] = useState<boolean>(false);

  const updateTotalRow = useCallback(() => {
    const rowData: Array<any> = [];
    gridRef?.current?.api?.forEachNodeAfterFilter((node) => {
      if (!node.group) rowData.push(node.data);
    });

    const totalRow = getTotalRow(rowData);
    gridRef?.current?.api?.setPinnedBottomRowData([totalRow]);
  }, []);

  const changeLevels = useCallback((numberOfRowGroups: number) => {
    if (gridRef?.current?.api) change_level(numberOfRowGroups - 1, gridRef.current.api);
  }, []);

  const handleRowGroupChange = useCallback(
    (event: ColumnRowGroupChangedEvent) => {
      changeLevels(event.columns?.length || 0);
    },
    [changeLevels]
  );

  const onGridReady = useCallback(
    (api: GridApi | null | undefined) => {
      if (!api) return;
      const rowGroups = (api.getColumnDefs() || []).filter((col) => {
        if ('rowGroup' in col) return col.rowGroup;
        return false;
      }).length;
      changeLevels(rowGroups);
    },
    [changeLevels]
  );

  const updateGridData = useCallback(
    (data: any) => {
      if (!gridRef?.current) return;
      gridRef?.current?.api?.setRowData(data);
      updateTotalRow();
      onGridReady(gridRef?.current?.api);
      setTimeout(() => {
        gridRef?.current?.columnApi?.autoSizeColumn(groupColId);
        gridRef?.current?.columnApi?.autoSizeAllColumns();
      }, 500);
    },
    [updateTotalRow, onGridReady]
  );

  useEffect(() => {
    updateGridData(otoData);
  }, [updateGridData, otoData]);

  const updateDataSource = useCallback(() => {
    form
      .validateFields()
      .then(async (values) => {
        const valuesPresent = _pickBy(values, (value) => {
          if (!value) return false;
          if (Array.isArray(value)) {
            return value.length > 0;
          }
          return true;
        });
        if (Object.keys(valuesPresent).length === 0) {
          setOverlayNoRowsTemplate(required_filter_overlay);
          setOTOData([]);
          gridRef?.current?.api?.showNoRowsOverlay();
          return;
        }
        gridRef?.current?.api?.showLoadingOverlay();
        // setLoading(true);
        const variables = {
          limit: PAGE_SIZE,
          offset: 0,
          filters: [
            ...getColumnFiltersinGraphqlFormat({
              columnFilterValues: valuesPresent,
            }),
          ],
        } as Variables;
        const { data, error } = await fetchOTODashboardData(variables, client);
        gridRef?.current?.api?.hideOverlay();
        // setLoading(false);
        setOverlayNoRowsTemplate('No vendor bookings found');
        if (error) {
          gridRef?.current?.api?.showNoRowsOverlay();
        }
        setOTOData(renderOTODashboardData(data));
      })
      .catch((error) => console.log(error));
  }, [client, form]);

  const disabledEtdDate = useCallback(
    (current: Dayjs) => {
      if (!etdRange) {
        return false;
      }
      const tooLate = etdRange[0] && current.diff(etdRange[0], 'day') > 30;
      const tooEarly = etdRange[1] && etdRange[1].diff(current, 'day') > 30;
      return Boolean(tooEarly || tooLate);
    },
    [etdRange]
  );

  const externalFilters = [
    {
      label: 'ETD',
      component: RangePicker,
      name: 'estimated_time_of_departure',
      componentProps: {
        style: { width: '100%' },
        disabledDate: disabledEtdDate,
        value: etdRange,
        onCalendarChange: setEtdRange,
      },
    },
    {
      label: 'Vessel / Voyage',
      component: VoyageScheduleSearch,
      name: 'voyage_schedule_id',
      componentProps: {
        selectMode: 'multiple',
        style: { width: '100%' },
      },
    },
  ];

  return (
    <ClientSideReport
      gridRef={gridRef}
      rowData={otoData || []}
      report_name={report_name}
      columns={columns}
      reportConfig={{
        overlayNoRowsTemplate: formatOverlayNoRowsTemplate(overlayNoRowsTemplate),
        components: components,
        getRowId: (params: any) => params.data.group_key,
        // autoGroupColumnDef: {
        //   headerName: 'Group',
        //   field: 'oto_id',
        //   filter: 'agTextColumnFilter',
        //   minWidth: 100,
        //   maxWidth: 400,
        //   cellRenderer: 'agGroupCellRenderer',
        //   cellRendererParams: {
        //     suppressCount: true,
        //     innerRenderer: 'renderLinkCell',
        //     redirectToBase: `/view/booking_order`,
        //     idField: 'id',
        //   },
        //   cellClassRules: {
        //     'highlight-na': function (params: CellClassParams) {
        //       return params?.value === ALLOCATION_PENDING_TEXT;
        //     },
        //   },
        //   pinned: 'left',
        //   tooltipValueGetter: (params) => {
        //     return params.value;
        //   },
        // },
        onColumnRowGroupChanged: handleRowGroupChange,
        onFilterChanged: () => updateTotalRow(),
      }}
      onGridReady={onGridReady}
      externalFilters={externalFilters}
      updateDataSource={updateDataSource}
      form={form}
      // summarySection={
      //   <SummaryCard totalQuantities={totalQuantities} loading={loading} cards={cards} />
      // }
      disableFilterCaching={['estimated_time_of_departure']}
      initialExternalFilterValue={{
        estimated_time_of_departure: [
          dayjsGenerateConfig.getNow(),
          dayjsGenerateConfig.getNow().add(14, 'day'),
        ],
      }}
    />
  );
});

export default OTODashboard;
