import convert, { Unit } from 'convert-units';

export const DIMENSION_METER = 'm';
export const DIMENSION_FEET = 'ft';
export const DIMENSION_INCH = 'inches';
export const DIMENSION_MILLI_METER = 'mm';
export const DIMENSION_CENTI_METER = 'cms';
export const DIMENSION_KILOGRAM = 'kg';
export const DIMENSION_POUND = 'lb';
export const DIMENSION_CBM = 'cbm';
export const DIMENSION_CFT = 'cft';

interface unitDetailsInput {
  volume: number;
  volumeUnit: Unit;
  factor: number;
  weightUnit: Unit;
}

export function getConversionRateForCubicMeters(unit?: string) {
  switch (unit) {
    case DIMENSION_METER:
      return 1;
    case DIMENSION_FEET:
      return 35.315;
    case DIMENSION_INCH:
      return 61000;
    case DIMENSION_MILLI_METER:
      return 1000000000;
    case DIMENSION_CENTI_METER:
      return 1000000;
    default:
      return 1;
  }
}

export function getConvertUnitsConstant(unit: string): Unit {
  switch (unit) {
    case DIMENSION_CBM:
      return 'm3';
    case DIMENSION_CFT:
      return 'ft3';
    case DIMENSION_CENTI_METER:
      return 'cm';
    case DIMENSION_INCH:
      return 'in';
    case DIMENSION_KILOGRAM:
    case 'kgs':
      return 'kg';
    case DIMENSION_POUND:
    case 'lbs':
      return 'lb';
    case 'mts':
      return 'mt';
    default:
      return unit as Unit;
  }
}

export function calculateVolumetricWeight(unitDetails: unitDetailsInput) {
  const { volume, volumeUnit, factor = 6000, weightUnit } = unitDetails;
  const baseVolume = convert(volume).from(getConvertUnitsConstant(volumeUnit)).to('m3');
  const volumeWeightInKg = (Math.pow(10, 6) * baseVolume) / factor;
  if (weightUnit) {
    return convert(volumeWeightInKg).from('kg').to(getConvertUnitsConstant(weightUnit));
  }
  return volumeWeightInKg;
}

export function convertNumber(number: number, from: Unit, to: Unit) {
  return convert(number).from(getConvertUnitsConstant(from)).to(getConvertUnitsConstant(to));
}
