import React, { useEffect, useMemo, useState } from 'react';
import { PlusOutlined, EditOutlined, DeleteOutlined, FolderViewOutlined } from '@ant-design/icons';
import { omit as _omit, truncate as _truncate } from 'lodash';
import { Card } from 'common';
import { useCompanyView } from '../CompanyView';
import { Button, Modal, message } from '@shipmnts/pixel-hub';
import { RegulatoryDetailsValue } from 'common/models/RegulatoryDetails';
import { useMutation } from '@apollo/client';
import { UPSERT_COMPANY } from 'network/graphql/company';
import { SectionEditorModal } from '../SectionEditorModal';
import { REGULATORY_DETAIL_OPTIONS } from 'network/constants';
import RegulatoryDetailsForm from 'network/components/RegulatoryDetailsForm';

interface RegulatoryDetailsDestroyValue extends RegulatoryDetailsValue {
  _destroy?: boolean;
}

export const RegulatoryDetailsCard = () => {
  // states & context
  const { company, accounts_data, setCompanyAccountsData, onCompanyUpdate } = useCompanyView();
  const [editMode, setEditMode] = useState(false);
  const [initialValues, setInitialValues] = useState<any>({});
  const [data, setData] = useState<RegulatoryDetailsValue[]>([]);

  // mutation
  const [upsertCompany, { data: companyData, error }] = useMutation(UPSERT_COMPANY);

  // effects
  useEffect(() => {
    const newData = filterTypename(company?.regulatory_details_data);
    setData(newData);
  }, [company?.regulatory_details_data]);

  // when a detail is added or deleted
  useEffect(() => {
    if (!error && companyData?.upsert_company) {
      const newData = filterTypename(companyData?.upsert_company?.company?.regulatory_details_data);
      setData(newData);
      if (onCompanyUpdate) onCompanyUpdate(companyData?.upsert_company?.company);
      message.success('Regulatory details saved successfully!');
    }
  }, [companyData, error, onCompanyUpdate]);

  const currentOpts = data.map((row: RegulatoryDetailsDestroyValue) => row.key);

  // filtering the options to show
  const options = useMemo(
    () =>
      REGULATORY_DETAIL_OPTIONS.filter(
        (option) => !currentOpts.includes(option.value) || initialValues?.key === option?.value
      ),
    [currentOpts, initialValues]
  );

  // update is_msme flag on MSME_# field added
  const setIsMsmeTrue = () => {
    if (setCompanyAccountsData && accounts_data) {
      accounts_data.is_msme = 1;
      setCompanyAccountsData((prevData) => ({
        ...prevData,
        accounts_data,
      }));
    }
  };
  // update is_msme flag on MSME_# field deleted
  const setIsMsmeFalse = () => {
    if (setCompanyAccountsData && accounts_data) {
      accounts_data.is_msme = 0;
      setCompanyAccountsData((prevData) => ({
        ...prevData,
        accounts_data,
      }));
    }
  };

  // fixed style
  const headingStyle = { display: 'block', opacity: 0.6, fontSize: '12px', fontWeight: '600' };

  // filtering out __typename from data
  function filterTypename(data: RegulatoryDetailsValue[] = []) {
    const newData = (data || []).map((d: RegulatoryDetailsValue) => {
      let filteredData: RegulatoryDetailsValue = d;
      filteredData = {
        ...filteredData,
        attachment: _omit(filteredData?.attachment, '__typename'),
      };
      return _omit(filteredData, '__typename');
    });
    return newData;
  }

  // edit regulatory data
  const editRegulatoryDetails = () => {
    if (company) {
      data.forEach((row: RegulatoryDetailsDestroyValue) => {
        if (!row?.attachment?.file_name) row.attachment = undefined;
      });
      upsertCompany({
        variables: {
          company: {
            id: company.id,
            is_msme: accounts_data?.is_msme,
            regulatory_details_data: data,
          },
        },
      });
    }
  };

  return (
    <Card
      style={{ width: '100%', marginInline: 'auto' }}
      header={{ title: 'Regulatory Details' }}
      Action={
        <Button
          disabled={company?.is_disabled}
          onClick={() => {
            if (!!!company?.is_disabled) {
              setInitialValues({});
              setEditMode(true);
            }
          }}
          size="small"
          icon={<PlusOutlined />}
        >
          {'Add Details'}
        </Button>
      }
    >
      <>
        {data && data.length > 0 ? (
          <div
            style={{
              display: 'grid',
              width: '100%',
              marginTop: '2em',
            }}
          >
            {data?.map((row, idx) => {
              return (
                <div
                  key={row?.id}
                  style={{
                    display: 'grid',
                    gridAutoFlow: 'column',
                    gridTemplateColumns: '10% 20% 25% 25%',
                    borderBottom: idx !== data.length - 1 ? '1px solid rgba(0, 0, 0, .1)' : '',
                    padding: '1em',
                    gap: '1em',
                  }}
                >
                  <span style={{ fontWeight: '500', wordWrap: 'break-word' }}>{idx + 1}</span>
                  <section style={{ wordWrap: 'break-word' }}>
                    <span style={headingStyle}>Reference Body</span>
                    <span>{row?.key?.split('_').join(' ') || '-'}</span>
                  </section>
                  <section style={{ wordWrap: 'break-word', alignSelf: 'start' }}>
                    <span style={headingStyle}>Reference Value</span>
                    <span>{row?.value || '-'}</span>
                  </section>
                  <section style={{ wordWrap: 'break-word', alignSelf: 'start' }}>
                    <span style={headingStyle}>Attachment</span>
                    {row?.attachment?.file_name ? (
                      <Button
                        type="link"
                        icon={<FolderViewOutlined />}
                        onClick={() =>
                          window.open(row?.attachment?.document_url || '', '_blank')?.focus()
                        }
                        size="small"
                        style={{ padding: 0 }}
                      >
                        {_truncate(row?.attachment?.file_name, { length: 20 })}
                      </Button>
                    ) : (
                      '-'
                    )}
                  </section>

                  <section style={{ textAlign: 'right', alignSelf: 'center' }}>
                    <Button
                      icon={<EditOutlined />}
                      style={{ color: '#4E89FF' }}
                      type="text"
                      onClick={() => {
                        if (!!!company?.is_disabled) {
                          setInitialValues({
                            id: row?.id,
                            key: row?.key,
                            value: row?.value,
                            attachment: row?.attachment,
                          });
                          setEditMode(true);
                        }
                      }}
                    />
                    <Button
                      icon={<DeleteOutlined />}
                      disabled={company?.is_disabled}
                      type="text"
                      danger
                      onClick={() => {
                        Modal.confirm({
                          title: 'Are you sure you want to delete this detail?',
                          content: 'This action cannot be undone.',
                          okText: 'Yes',
                          okType: 'danger',
                          cancelText: 'No',
                          onOk: async () => {
                            // filter if unsaved row in backend else add _destroy:true
                            const updatedRowData: RegulatoryDetailsDestroyValue[] = [];
                            data.forEach((_row: RegulatoryDetailsDestroyValue, idx) => {
                              if (_row?.key === row?.key) {
                                if (_row.id) {
                                  _row._destroy = true;
                                  updatedRowData.push(_row);
                                }
                              } else {
                                updatedRowData.push(_row);
                              }
                            });
                            // set is_msme flag to no id deleted details is MSME_#
                            if (row?.key === 'MSME_#') setIsMsmeFalse();
                            setData(updatedRowData);
                            editRegulatoryDetails();
                          },
                        });
                      }}
                    />
                  </section>
                </div>
              );
            })}
          </div>
        ) : (
          <div
            style={{ textAlign: 'center', padding: '1em', fontSize: '16px', opacity: '0.4' }}
          >{`No Data Available.`}</div>
        )}
        {editMode && company && (
          <SectionEditorModal
            title="Regulatory Details"
            show={editMode}
            setShow={setEditMode}
            initialValues={initialValues}
            onSubmit={(values) => {
              let details: RegulatoryDetailsValue[];
              const index = data.findIndex((item) => item?.id === values?.id);
              if (index !== -1) {
                details = [...data.slice(0, index), values, ...data.slice(index + 1)];
              } else {
                details = [...data, values];
              }

              // update payload
              details = details.map((d) => ({
                ...d,
                attachment: d?.attachment?.file_name ? d?.attachment : undefined,
              }));

              if (values.key === 'MSME_#') setIsMsmeTrue();

              // mutation to update company
              upsertCompany({
                variables: {
                  company: {
                    id: company.id,
                    is_msme: accounts_data?.is_msme,
                    regulatory_details_data: details,
                  },
                },
              });

              // close modal
              setEditMode(false);
            }}
          >
            {({ setLoading }) => {
              return <RegulatoryDetailsForm options={options} setLoading={setLoading} />;
            }}
          </SectionEditorModal>
        )}
      </>
    </Card>
  );
};
