import { ALLOWED_DOCUMENT_STATUS_MAPPING_ON_CREATE } from 'operations/modules/reports/constants';
import {
  TRADE_TYPE_IMPORT,
  TRADE_TYPE_EXPORT,
  DOCUMENT_TYPE_MASTER,
  DOCUMENT_TYPE_HOUSE,
  SERVICE_TYPE_ORIGIN,
  BOOKING_THROUGH_OVERSEAS_AGENT,
  SERVICE_TYPE_DESTINATION,
  SERVICE_TYPE_FREIGHT,
  SELF_CONSOLIDATION_KEY,
} from 'operations/modules/shipment/constants';
import {
  ResourceDataValue,
  CompanyMappingMessageValue,
  ChargeMappingMessageValue,
  ShipmentPartyFieldValue,
  DestinationAddressValue,
  ShipmentDocumentFieldValue,
  ServiceFieldValue,
} from 'operations/modules/message/messageResourceDataModel';
import { AttachmentValue } from 'operations/models/Message';
import { SessionDataValue } from 'operations/models/SessionData';
import { Dayjs, dayjsGenerateConfig } from '@shipmnts/pixel-hub';
import { ShipmentDocumentFormValue } from '../components/MessageShipmentDocumentDetails';
import { ShipmentPartyValue } from 'operations/models/ShipmentParty';
import { CompanyValue } from 'operations/models/Company';
import { PartyValue } from 'operations/modules/reports/components/CreateShipmentFormContent';
import { SalesPersonValue } from 'common/models/SalesPerson';
export interface Item {
  name: string;
  item_code: string;
  shipment_tags?: string;
  stock_uom?: string;
}
export interface CreateShipmentFromDataValue {
  id?: string;
  job_date?: Dayjs;
  trade_type?: string;
  business_type?: string;
  consol_type?: string;
  involved_branch_id?: string;
  customer?: PartyValue;
  sales_agent_id?: number;
  sales_agent?: SalesPersonValue;
  party?: { [key: string]: PartyValue };
  companies_mappings?: { [key: string]: PartyValue };
  charge_mapping?: { [key: string]: Item };
  shipment_documents?: ShipmentDocumentFormValue[];
  selected_attachment_ids?: string[];
  uploaded_documents?: { [key: string]: { tags?: string[] } };
  finance_party?: string;
  lfd_at_carrier?: Dayjs;
  lfd_at_empty_return?: Dayjs;
  lfd_at_pocd?: Dayjs;
  lfd_at_pod?: Dayjs;
  destination_carrier_free_days?: number;
  destination_port_free_days?: number;
  origin_carrier_free_days?: number;
}

export const getShipmentIntialValue = (
  shipment: ResourceDataValue,
  sessionData: SessionDataValue,
  senderCompany?: CompanyValue | null,
  initialValue?: CreateShipmentFromDataValue,
  masterShipmentInitialValue?: CreateShipmentFromDataValue,
  companies_mappings?: CompanyMappingMessageValue[],
  tenant_mappings?: ChargeMappingMessageValue[],
  companyMappingFormValues?: { [key: string]: ShipmentPartyValue },
  attachments?: AttachmentValue[]
) => {
  const trade_type =
    shipment.trade_type?.value === TRADE_TYPE_EXPORT ? TRADE_TYPE_IMPORT : TRADE_TYPE_EXPORT;
  const companiesMappingsInitialValue = companyMappingInitialValue(
    shipment.shipment_parties,
    companies_mappings,
    companyMappingFormValues
  );
  const chargeMappingsInitialValue = chargeMappingInitialValue(tenant_mappings);
  const involved_branch_id = getInvolvedBranchId(sessionData);
  const business_type = BOOKING_THROUGH_OVERSEAS_AGENT;
  const party = shipmentPartyInitialValue(
    shipment.shipment_parties,
    sessionData,
    business_type,
    senderCompany
  );
  const data = {
    id: shipment.id?.value,
    job_date: masterShipmentInitialValue?.job_date || dayjsGenerateConfig.getNow(),
    consol_type: shipment.consol_type?.value,
    business_type,
    trade_type,
    involved_branch_id,
    party,
    services: getServicesInitialValue(shipment.services?.value || {}, party),
    customer:
      masterShipmentInitialValue?.consol_type !== SELF_CONSOLIDATION_KEY
        ? masterShipmentInitialValue?.customer
        : undefined,
    companies_mappings: companiesMappingsInitialValue,
    charge_mapping: chargeMappingsInitialValue,
    uploaded_documents: (attachments || []).reduce(
      (obj: { [key: string]: { [key: string]: string[] } }, attachment: AttachmentValue) => {
        if (attachment.document_identifier && attachment.tags)
          obj[attachment.document_identifier] = { tags: attachment.tags };
        return obj;
      },
      {}
    ),
    ...shipmentDocumentInitialValue(shipment.shipment_documents, shipment.freight_type?.value),
    ...initialValue,
  };
  return data;
};

//TODO: Implement last selected involvedBranchId caching
export const getInvolvedBranchId = (sessionData: SessionDataValue) => {
  if ((sessionData?.branch_accounts || []).length === 1) {
    return sessionData?.branch_accounts?.[0]?.id;
  }
  return null;
};

export const getServicesInitialValue = (
  services: ServiceFieldValue,
  party: { [key: string]: PartyValue }
) => {
  return Object.keys(services).reduce((serviceMap: ServiceFieldValue, serviceType: string) => {
    if ([SERVICE_TYPE_ORIGIN, SERVICE_TYPE_DESTINATION].includes(serviceType)) {
      serviceMap[serviceType] = Object.keys(services[serviceType]).reduce(
        (acc: { [key: string]: boolean }, service) => {
          acc[service] =
            serviceType === SERVICE_TYPE_ORIGIN
              ? false
              : !!party[`${serviceType}_${service}_agent`];
          return acc;
        },
        {}
      ) as { [key: string]: boolean };
    } else {
      serviceMap[serviceType] =
        serviceType === SERVICE_TYPE_FREIGHT ? services[serviceType] : false;
    }
    return serviceMap;
  }, {});
};

export const shipmentDocumentInitialValue = (
  shipment_documents: ShipmentDocumentFieldValue[],
  freight_type: string | null
) => {
  const allowedStatus = freight_type ? ALLOWED_DOCUMENT_STATUS_MAPPING_ON_CREATE[freight_type] : [];
  const value = (shipment_documents || [])
    .filter((sd) =>
      [DOCUMENT_TYPE_MASTER, DOCUMENT_TYPE_HOUSE].includes(sd?.document_type?.value || '')
    )
    .map((sd) => {
      return {
        document_type: sd?.document_type?.value,
        document_status: allowedStatus.includes(sd?.document_status?.value || '')
          ? sd?.document_status?.value
          : allowedStatus[0],
        bl_type: sd?.bl_type?.value,
      };
    });
  return { shipment_documents: value };
};

export const chargeMappingInitialValue = (charge_mapping?: ChargeMappingMessageValue[]) => {
  const chargeMapInitValue: { [key: string]: string } = {};
  (charge_mapping || []).forEach((charge: ChargeMappingMessageValue) => {
    chargeMapInitValue[charge.source_item] = charge.destination_item;
  });
  return chargeMapInitValue;
};
export const companyMappingInitialValue = (
  shipment_parties: ShipmentPartyFieldValue[],
  companies_mappings?: CompanyMappingMessageValue[],
  companyMappingFormValues?: { [key: string]: ShipmentPartyValue }
) => {
  const filteredParties = (shipment_parties || []).filter(
    (p) => p?.party_company_id?.value && p?.party_address_id?.value
  );
  const companies_mappings_hash = (companies_mappings || []).reduce(
    (acc: { [key: string]: DestinationAddressValue }, cm) => {
      acc[cm.source_address_id] = cm.destination_address;
      return acc;
    },
    {}
  );
  return filteredParties.reduce(
    (acc: { [key: string]: PartyValue }, p: ShipmentPartyFieldValue) => {
      if (p.party_address_id?.value && companies_mappings_hash[p.party_address_id?.value]) {
        const destination_address = companies_mappings_hash[p.party_address_id?.value];
        acc[p.party_address_id?.value] = {
          party_company: {
            id: destination_address?.company?.id,
            registered_name: destination_address?.company?.registered_name,
          },
          party_address: {
            id: destination_address?.id,
            name: destination_address?.name,
            print_address: destination_address?.print_address,
          },
        } as PartyValue;
      } else if (
        p.party_address_id?.value &&
        companyMappingFormValues?.[p.party_address_id?.value]
      ) {
        acc[p.party_address_id?.value] = companyMappingFormValues[
          p.party_address_id?.value
        ] as PartyValue;
      }
      return acc;
    },
    {}
  );
};

const shipmentPartyInitialValue = (
  shipmentParties: ShipmentPartyFieldValue[],
  sessionData: SessionDataValue,
  businessType: string,
  senderCompany?: CompanyValue | null
) => {
  const { company_account, branch_accounts } = sessionData;
  const parties = (shipmentParties || []).reduce((acc: { [key: string]: PartyValue }, p) => {
    if (p.name?.value && p.party_address_id?.value && p.party_company_id?.is_receiver) {
      acc[p.name?.value] = {
        name: p.name?.value,
        party_company: {
          id: company_account?.default_company?.id,
          registered_name: company_account?.default_company?.registered_name,
        },
        party_address: {
          id: branch_accounts?.[0]?.default_address?.id,
          name: branch_accounts?.[0]?.default_address?.name,
          print_address: branch_accounts?.[0]?.default_address?.print_address,
        },
      } as PartyValue;
    }
    return acc;
  }, {});
  if (businessType === BOOKING_THROUGH_OVERSEAS_AGENT && senderCompany) {
    parties['overseas_agent'] = {
      name: 'overseas_agent',
      party_company: senderCompany,
    } as PartyValue;
  }
  return parties;
};
