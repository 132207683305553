import React, { useMemo, useState } from 'react';
import { Row, Col, Radio, Select, Form, TextArea, DatePicker } from '@shipmnts/pixel-hub';
import { FormInstance } from '@shipmnts/pixel-hub';
import {
  ROW_GUTTER,
  DocumentType,
  DOCUMENT_TYPE_HOUSE,
  FreightType,
  TradeType,
  BL_TYPE_ARRAY,
  BL_TYPE_EXPRESS,
  CHARGE_TERMS,
  CONSIGNEE_TYPE_TO_ORDER,
  CONSIGNEE_TYPE_TO_ORDER_BANK,
  ShipmentType,
  SHIPMENT_TYPE_DIRECT,
  SHIPMENT_TYPE_BACK_TO_BACK,
  FREIGHT_TYPE_OCEAN,
  TRADE_TYPE_IMPORT,
  BL_TYPE_E_BL,
  RELEASE_TYPE_ARRAY,
  SHIPPER_OPTIONS,
  CONSIGNEE_OPTIONS_BACK_TO_BACK_AND_CONSOL,
  CONSIGNEE_OPTIONS_DIRECT,
  BUSINESS_TYPE_SUBAGENT,
  DOCUMENT_TYPE_MASTER,
  TRADE_TYPE_EXPORT,
} from 'operations/modules/shipment/constants';
import { LABEL_JSON } from 'operations/modules/shipment/helpers/labelKeyHelper';
import ShipmentNumber from './ShipmentNumber';
import { RadioOptionProps } from 'operations/commonTypeDefs';
import { CarrierValue } from 'operations/models/Carrier';
import {
  ALLOWED_DOCUMENT_STATUS_MAPPING_ON_CREATE,
  DOCUMENT_STATUS_DRAFT_RECEIVED,
  document_status_to_display_name_mapping,
} from 'operations/modules/reports/constants';
import {
  DOCUMENT_STATUS_RECEIVED_AT_DESTINATION,
  DOCUMENT_STATUS_SURRENDERED_AT_ORIGIN,
} from 'operations/modules/reports/constants';
interface ShipmentDocumentDetailsProps {
  form: FormInstance;
  shipmentType: ShipmentType;
  documentType: DocumentType;
  freightType: FreightType;
  tradeType: TradeType;
  carrier?: CarrierValue;
  disableShipmentNumber?: boolean;
}

const ShipmentDocumentDetailsNew = React.memo(function ShipmentDocumentDetails(
  props: ShipmentDocumentDetailsProps
): JSX.Element {
  const {
    shipmentType,
    documentType,
    freightType,
    tradeType,
    form,
    carrier,
    disableShipmentNumber = false,
  } = props;
  const consginee_to_order_keys = [CONSIGNEE_TYPE_TO_ORDER.key, CONSIGNEE_TYPE_TO_ORDER_BANK.key];
  const [documentStatus, setDocumentStatus] = useState('');
  const consignee_options =
    shipmentType === SHIPMENT_TYPE_DIRECT
      ? CONSIGNEE_OPTIONS_DIRECT
      : documentType === DOCUMENT_TYPE_HOUSE
      ? CONSIGNEE_OPTIONS_DIRECT
      : CONSIGNEE_OPTIONS_BACK_TO_BACK_AND_CONSOL;
  const isSubAgentBusiness = (form: FormInstance) =>
    [BUSINESS_TYPE_SUBAGENT].includes(form.getFieldValue('business_type'));
  const isConsigneeMandatory = useMemo(() => {
    return (
      (tradeType === TRADE_TYPE_IMPORT || documentType === DOCUMENT_TYPE_HOUSE) &&
      freightType !== FREIGHT_TYPE_OCEAN &&
      isSubAgentBusiness(form)
    );
  }, [documentType, form, freightType, tradeType]);

  return (
    <Col>
      <Row gutter={ROW_GUTTER}>
        <Col span={8}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues[documentType]?.shipment_document?.consignee_on_document !==
                currentValues[documentType]?.shipment_document?.consignee_on_document ||
              prevValues?.consignee_party_name !== currentValues?.consignee_party_name
            }
          >
            {({ getFieldValue }) => {
              const consignee_on_document = getFieldValue([
                documentType,
                'shipment_document',
                'consignee_on_document',
              ]);
              const consignee_party_name = getFieldValue('consignee_party_name');
              const disabledKeys = [BL_TYPE_EXPRESS, BL_TYPE_E_BL];
              return (
                <Form.Item
                  required
                  rules={[{ required: true }]}
                  label="BL Type"
                  name={[documentType, 'shipment_document', 'bl_type']}
                >
                  <Radio.Group>
                    {BL_TYPE_ARRAY.map((option: RadioOptionProps, index: number) => (
                      <Radio
                        key={index}
                        value={option.key}
                        disabled={
                          (consginee_to_order_keys.includes(consignee_on_document) ||
                            consginee_to_order_keys.includes(consignee_party_name)) &&
                          disabledKeys.includes(option.key)
                        }
                      >
                        {option.name}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item
            required
            rules={[{ required: true }]}
            label="Release Type"
            name={[documentType, 'shipment_document', 'release_type']}
          >
            <Radio.Group>
              {RELEASE_TYPE_ARRAY.map((option: RadioOptionProps, index: number) => (
                <Radio key={index} value={option.key}>
                  {option.name}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={ROW_GUTTER}>
        {!disableShipmentNumber && freightType === FREIGHT_TYPE_OCEAN && (
          <Col span={8}>
            <ShipmentNumber
              form={form}
              carrier={carrier}
              label={LABEL_JSON.shipment_number[documentType][freightType]}
              documentType={documentType}
            />
          </Col>
        )}

        {!disableShipmentNumber && freightType === FREIGHT_TYPE_OCEAN && (
          <>
            <Col span={8}>
              <Form.Item
                name={[documentType, 'shipment_document', 'shipment_date']}
                label={LABEL_JSON.document_date[documentType][freightType]}
              >
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={LABEL_JSON.document_status[documentType][freightType]}
                name={[documentType, 'shipment_document', 'document_status']}
              >
                <Select
                  style={{ width: '100%' }}
                  onChange={setDocumentStatus}
                  disabled={tradeType === TRADE_TYPE_EXPORT ? true : false}
                >
                  {(tradeType === TRADE_TYPE_IMPORT
                    ? [
                        ...ALLOWED_DOCUMENT_STATUS_MAPPING_ON_CREATE[freightType],
                        DOCUMENT_STATUS_DRAFT_RECEIVED,
                      ]
                    : ALLOWED_DOCUMENT_STATUS_MAPPING_ON_CREATE[freightType]
                  ).map((option: string, index: number) => (
                    <Select.Option key={index} value={option}>
                      {document_status_to_display_name_mapping[option]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {[
              DOCUMENT_STATUS_RECEIVED_AT_DESTINATION,
              DOCUMENT_STATUS_SURRENDERED_AT_ORIGIN,
            ].includes(documentStatus) && (
              <Col span={8}>
                <Form.Item
                  required
                  rules={[{ required: true }]}
                  name={[documentType, 'shipment_document', 'document_status_event_date']}
                  label={
                    documentStatus === DOCUMENT_STATUS_SURRENDERED_AT_ORIGIN
                      ? 'Surrendered At Origin Date'
                      : 'Received At Destination Date'
                  }
                >
                  <DatePicker style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            )}
          </>
        )}
      </Row>

      <Row gutter={ROW_GUTTER}>
        <Col span={8}>
          <Form.Item
            required
            rules={[{ required: true }]}
            label="Freight Charge Terms"
            name={[documentType, 'shipment_document', 'charge_terms', 'freight_terms']}
          >
            <Radio.Group>
              {CHARGE_TERMS.map((option: RadioOptionProps, index: number) => (
                <Radio key={index} value={option.key}>
                  {option.name}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            required
            rules={[{ required: true }]}
            label="Other Charge Terms"
            name={[documentType, 'shipment_document', 'charge_terms', 'other_charges_terms']}
          >
            <Radio.Group>
              {CHARGE_TERMS.map((option: RadioOptionProps, index: number) => (
                <Radio key={index} value={option.key}>
                  {option.name}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        {shipmentType === SHIPMENT_TYPE_BACK_TO_BACK && documentType === DOCUMENT_TYPE_MASTER && (
          <Col span={8}>
            <Form.Item
              label={LABEL_JSON.shipper_name[documentType][freightType]}
              name={[documentType, 'shipment_document', 'shipper_on_document']}
            >
              <Radio.Group defaultValue={'origin_agent'}>
                {SHIPPER_OPTIONS.map((option: RadioOptionProps, index: number) => (
                  <Radio key={index} value={option.key}>
                    {option.name}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
          </Col>
        )}
        {shipmentType === SHIPMENT_TYPE_BACK_TO_BACK && (
          <Col span={8}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues[documentType]?.shipment_document?.bl_type !==
                currentValues[documentType]?.shipment_document?.bl_type
              }
            >
              {({ getFieldValue }) => {
                const bl_type = getFieldValue([documentType, 'shipment_document', 'bl_type']);
                const restricted_bl_types = [BL_TYPE_EXPRESS, BL_TYPE_E_BL];
                return (
                  <Form.Item
                    label={LABEL_JSON.consignee_name[documentType][freightType]}
                    name={[documentType, 'shipment_document', 'consignee_on_document']}
                  >
                    <Radio.Group defaultValue={'destination_agent'}>
                      {consignee_options.map((option: RadioOptionProps, index: number) => (
                        <Radio
                          key={index}
                          value={option.key}
                          disabled={
                            consginee_to_order_keys.includes(option.key) &&
                            restricted_bl_types.includes(bl_type)
                          }
                        >
                          {option.name}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        )}
        {shipmentType === SHIPMENT_TYPE_DIRECT && (
          <Col span={8}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues[documentType]?.shipment_document?.bl_type !==
                currentValues[documentType]?.shipment_document?.bl_type
              }
            >
              {({ getFieldValue }) => {
                const bl_type = getFieldValue([documentType, 'shipment_document', 'bl_type']);
                const restricted_bl_types = [BL_TYPE_EXPRESS, BL_TYPE_E_BL];
                return (
                  <Form.Item
                    label={LABEL_JSON.consignee_name[documentType][freightType]}
                    name="consignee_party_name"
                  >
                    <Radio.Group defaultValue={'consignee'}>
                      {consignee_options.map((option: RadioOptionProps, index: number) => (
                        <Radio
                          key={index}
                          value={option.key}
                          disabled={
                            consginee_to_order_keys.includes(option.key) &&
                            restricted_bl_types.includes(bl_type)
                          }
                        >
                          {option.name}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        )}
        <Col span={8}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues?.consignee_party_name !== currentValues?.consignee_party_name
            }
          >
            {({ getFieldValue }) => {
              const consignee_party_name = getFieldValue('consignee_party_name');
              const shouldToOrderPartyRender =
                consginee_to_order_keys.includes(consignee_party_name);
              if (!shouldToOrderPartyRender) return null;
              return (
                <Form.Item
                  required={isConsigneeMandatory}
                  rules={[{ required: isConsigneeMandatory }]}
                  name={['party', consignee_party_name, 'party_details']}
                  label={LABEL_JSON[consignee_party_name][documentType][freightType]}
                >
                  <TextArea rows={2} />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
      </Row>
    </Col>
  );
});

export default ShipmentDocumentDetailsNew;
