import { CustomIcon, getDateFromUnix } from '@shipmnts/pixel-hub';
import {
  TRANSACTION_TYPE_ASN,
  TRANSACTION_TYPE_GDN,
  TRANSACTION_TYPE_GIO,
  TRANSACTION_TYPE_GRN,
  WarehouseTransactionValue,
} from 'operations/models/WarehouseTransaction';
import { collapsibleCard } from 'operations/modules/shipment/components/DetailLayout/Common/common';
import React from 'react';

interface WMSReceiptDetailsProps {
  warehouseTransaction: WarehouseTransactionValue;
}

function WMSReceiptDetails(props: WMSReceiptDetailsProps) {
  const { warehouseTransaction } = props;
  const receiptDetail: any = [];
  if (
    warehouseTransaction?.type === TRANSACTION_TYPE_ASN ||
    warehouseTransaction?.type === TRANSACTION_TYPE_GRN
  ) {
    receiptDetail.push({
      'Received From Transporter': warehouseTransaction?.received_transporter?.registered_name,
    });
    receiptDetail.push({
      'Received By': warehouseTransaction.received_by
        ? [warehouseTransaction.received_by.first_name, warehouseTransaction.received_by.last_name]
            .filter(Boolean)
            .join(' ')
        : '',
    });
    receiptDetail.push({
      'Received At': warehouseTransaction?.received_at
        ? getDateFromUnix(warehouseTransaction?.received_at)
        : null,
    });
  }
  if (
    warehouseTransaction?.type === TRANSACTION_TYPE_GIO ||
    warehouseTransaction?.type === TRANSACTION_TYPE_GDN
  ) {
    receiptDetail.push({
      'Delivered to Transporter': warehouseTransaction?.delivered_transporter?.registered_name,
    });
    receiptDetail.push({
      'Delivered By': warehouseTransaction.delivered_by
        ? [
            warehouseTransaction.delivered_by.first_name,
            warehouseTransaction.delivered_by.last_name,
          ]
            .filter(Boolean)
            .join(' ')
        : '',
    });
    receiptDetail.push({
      'Delivered At': warehouseTransaction?.delivered_at
        ? getDateFromUnix(warehouseTransaction?.delivered_at)
        : null,
    });
  }
  receiptDetail.push({
    'Vehicle #': warehouseTransaction?.vehicle_number,
  });
  receiptDetail.push({
    'Driver Detail': warehouseTransaction?.driver_name,
  });
  receiptDetail.push({
    'Delivery Ref#': warehouseTransaction?.delivery_ref_code,
  });
  return (
    <>
      {collapsibleCard({
        icon: <CustomIcon icon="Info" />,
        header:
          warehouseTransaction?.type === TRANSACTION_TYPE_GRN
            ? 'Receipt Details'
            : 'Delivery Details',
        data: receiptDetail,
      })}
    </>
  );
}

export default WMSReceiptDetails;
