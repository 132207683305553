import React from 'react';

export const emptyTextLocaleForTable = (message) => {
  if (message) {
    return { emptyText: <div style={{ color: 'red' }}>{message}</div> };
  }
  return {};
};

export const defaultErrorMessage = (status, resource) => {
  if (status === 401) {
    return 'Unauthorized Access. Please contact administrator.';
  } else if (status === 403) {
    return `Insufficient permission${
      resource ? ` for ${resource}` : ''
    }. Please contact administrator.`;
  } else {
    return `${resource} could not be fetched. Please try again.`;
  }
};
