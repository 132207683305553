import React from 'react';
import { Progress, Typography, Col, Tooltip } from '@shipmnts/pixel-hub';
import { WarningOutlined } from '@shipmnts/pixel-hub';
import _sumBy from 'lodash/sumBy';
import { ShipmentValue } from 'operations/models/Shipment';

const { Title, Text } = Typography;

const colorMapping: { [key: string]: string } = {
  '<50': '#FF8B71',
  '<75': '#FFC671',
  '<100': '#35986B',
  '100': '#F74E57',
};

const calcPercent = (val: number, maxVal: number) => {
  if (maxVal <= 0) return 0;
  return Math.min(Math.round((val * 100) / maxVal), 100);
};

const renderProgressWithDescription = (
  val: number,
  maxVal: number,
  label: string,
  unit: string
) => {
  const percent = calcPercent(val, maxVal);
  let color: string;
  if (percent < 50) color = '<50';
  else if (percent < 75) color = '<75';
  else if (percent < 100) color = '<100';
  else color = '100';
  color = colorMapping[color];

  let tooltip: JSX.Element = <></>;
  if (percent >= 100) {
    const title =
      val === maxVal ? `Fully utilised` : `Exceeds by ${Math.round(val - maxVal)} ${unit}`;
    tooltip = (
      <Tooltip title={title}>
        <WarningOutlined style={{ color: '#F5222D' }} />
      </Tooltip>
    );
  }
  return (
    <>
      <Col>
        <Progress
          type="circle"
          percent={percent}
          format={(percent) => `${percent}%`}
          width={100}
          status={'normal'}
          strokeColor={color}
        />
      </Col>
      <Col>
        <div>
          <Title style={{ fontWeight: 'bold', fontSize: '18px', width: 'max-content' }} level={4}>
            {label} ({unit})
          </Title>
          <div style={{ display: 'flex', alignItems: 'center', columnGap: '8px' }}>
            <Text style={{ fontSize: '24px' }}>
              {val} / {maxVal}
            </Text>
            <span>{tooltip}</span>
          </div>
        </div>
      </Col>
    </>
  );
};

interface OceanLclUtilisationProgressProps {
  houseShipments: Array<ShipmentValue>;
  maximumWeight: number;
  maximumVolume: number;
}

const OceanLclUtilisationProgress = React.memo(function OceanLclUtilisationProgress(
  props: OceanLclUtilisationProgressProps
): JSX.Element {
  const { houseShipments, maximumWeight, maximumVolume } = props;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        columnGap: '14px',
        position: 'sticky',
        top: '-24px',
        paddingTop: '8px',
        paddingBottom: '8px',
        zIndex: 1000,
        backgroundColor: 'white',
        backgroundClip: 'padding-box',
        boxShadow: '0px 10px 6px -6px #f0f0f0',
      }}
    >
      {renderProgressWithDescription(
        _sumBy(houseShipments, (hs) => hs.gross_volume || 0),
        maximumVolume,
        'Volume',
        'cbm'
      )}
      {renderProgressWithDescription(
        _sumBy(houseShipments, (hs) => hs.gross_weight || 0),
        maximumWeight,
        'Weight',
        'kgs'
      )}
    </div>
  );
});

export default OceanLclUtilisationProgress;
