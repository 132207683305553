import { gql } from '@apollo/client';
import { userContactFields } from './user';

export const salesPersonFields = gql`
  fragment salesPersonFields on SalesPersonObjectType {
    id
    name
    employee
    enable
    user_contact {
      ...userContactFields
    }
  }
  ${userContactFields}
`;

export const FETCH_SALES_PERSON = gql`
  query fetch_sales_person($id: ID!) {
    fetch_sales_person(id: $id) {
      id
      name
      employee
      enable
    }
  }
`;
