import { gql } from '@apollo/client';

export const CREATE_MANIFEST = gql`
  mutation create_shipment_manifest(
    $shipment_id: ID!
    $shipment_manifest: ShipmentManifestInputType!
  ) {
    create_shipment_manifest(shipment_id: $shipment_id, shipment_manifest: $shipment_manifest) {
      id
    }
  }
`;

export const FETCH_SHIPMENT_MANIFEST = gql`
  query fetch_shipment_manifests($id: ID!) {
    shipment(id: $id) {
      id
      shipment_manifests {
        id
        document_number
        document_type
        trade_type
        manifest_number
        estimated_time_of_arrival
        line_number
        subline_number
        inward_date
        manifest_date
        location_type
        country
        location {
          id
          name
          customs_code
          country_code
          state_code
          iata_code
          unlocode
          type
        }
        tracking_statuses
        shipment {
          freight_type
        }
      }
    }
  }
`;

export const DELETE_SHIPMENT_MANIFEST = gql`
  mutation delete_shipment_manifest($id: ID!) {
    delete_shipment_manifest(id: $id) {
      message
    }
  }
`;
