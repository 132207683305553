import { FilterType, Variables } from 'operations/models/Report';
import { ApolloClient } from '@apollo/client';
import { BookingRequestValue } from 'operations/models/BookingRequest';
import {
  getCarriageWiseRoutingLegs,
  ROUTING_TYPE_MAIN_CARRIAGE,
} from 'operations/models/RoutingLeg';
import { STATUS_ALL_REPORTS, STATUS_PRE_ALERT_PENDING } from 'operations/modules/reports/constants';
import { CONTAINER_REPORT } from 'operations/modules/reports/graphql/containerReport';
import { OceanTransportOrderValue } from 'operations/models/OceanTransportOrder';

const PAGE_SIZE = 100;

const all_container_status = [STATUS_ALL_REPORTS, STATUS_PRE_ALERT_PENDING];

const fetchContainerData = async (
  variables: Variables,
  client: ApolloClient<object>,
  doc_status: string
) => {
  const doc_status_booleans = all_container_status.reduce(
    (result: { [key: string]: boolean }, status: string) => {
      result[status] = status === doc_status;
      return result;
    },
    {}
  );
  const { data, errors } = await client.query({
    query: CONTAINER_REPORT,
    variables: {
      ...variables,
      ...doc_status_booleans,
    },
    fetchPolicy: 'network-only',
  });
  if (errors) console.error(errors);
  return {
    data: data?.shipment_containers_report?.data,
    total: data?.shipment_containers_report?.total,
    error: errors ? true : false,
  };
};

const fetchContainerReportData = async function (
  client: ApolloClient<object>,
  filters: FilterType[],
  status: string
) {
  if (filters.length === 0) return [];
  const variables = {
    limit: PAGE_SIZE,
    offset: 0,
    filters: [...filters],
  } as Variables;
  const { data } = await fetchContainerData(variables, client, status);
  return data;
};

export const fetchContainersByIds = async function (
  ids: string[],
  status: string,
  client: ApolloClient<object>
) {
  if (ids.length > 0) {
    const filters = [
      {
        col: 'report_status',
        condition: {
          filter_type: 'text',
          type: 'equals',
          value: status,
        },
      },
      {
        col: 'id',
        condition: {
          filter_type: 'text',
          type: 'in',
          values: ids.map((id) => id.toString()),
        },
      },
    ];
    const data = await fetchContainerReportData(client, filters, status);
    return data;
  }
};

export const fetchBRMatchingContainers = async function (
  bookingRequest: BookingRequestValue,
  status: string,
  client: ApolloClient<object>
) {
  const oto = bookingRequest?.ocean_transport_orders[0];
  const carriageWiseRoutingLegs = getCarriageWiseRoutingLegs(oto?.routing_legs);
  const mcrl = carriageWiseRoutingLegs[ROUTING_TYPE_MAIN_CARRIAGE];
  const filters = [
    {
      col: 'report_status',
      condition: {
        filter_type: 'text',
        type: 'equals',
        value: status,
      },
    },
    {
      col: 'customer_company_id',
      condition: {
        filter_type: 'text',
        type: 'equals',
        value: bookingRequest?.sell_collaborations?.[0]?.customer_company?.id,
      },
    },
    {
      col: 'global_carrier_id',
      condition: {
        filter_type: 'text',
        type: 'equals',
        value: bookingRequest?.preferred_carriers?.[0]?.id,
      },
    },
    {
      col: 'vessel_id',
      condition: {
        filter_type: 'text',
        type: 'equals',
        value: mcrl?.[0].vessel?.imo,
      },
    },
    {
      col: 'voyage_number',
      condition: {
        filter_type: 'text',
        type: 'equals',
        value: mcrl?.[0].voyage_number,
      },
    },
    {
      col: 'port_of_loading_id',
      condition: {
        filter_type: 'text',
        type: 'equals',
        value: mcrl?.[0].origin?.location?.id,
      },
    },
    {
      col: 'port_of_discharge_id',
      condition: {
        filter_type: 'text',
        type: 'equals',
        value: mcrl?.[mcrl.length - 1].destination?.location?.id,
      },
    },
  ] as FilterType[];

  const data = await fetchContainerReportData(client, filters, status);
  return data;
};

export const fetchOTOMatchingContainers = async function (
  oto: OceanTransportOrderValue,
  status: string,
  client: ApolloClient<object>
) {
  if (!oto) return [];
  const mcrl = oto.carriageWiseRoutingLegs[ROUTING_TYPE_MAIN_CARRIAGE];
  const filters = [
    {
      col: 'report_status',
      condition: {
        filter_type: 'text',
        type: 'equals',
        value: status,
      },
    },
    {
      col: 'global_carrier_id',
      condition: {
        filter_type: 'text',
        type: 'equals',
        value: oto?.global_carrier?.id,
      },
    },
    {
      col: 'vessel_id',
      condition: {
        filter_type: 'text',
        type: 'equals',
        value: mcrl?.[0].vessel?.imo,
      },
    },
    {
      col: 'voyage_number',
      condition: {
        filter_type: 'text',
        type: 'equals',
        value: mcrl?.[0].voyage_number,
      },
    },
    {
      col: 'port_of_loading_id',
      condition: {
        filter_type: 'text',
        type: 'equals',
        value: mcrl?.[0].origin?.location?.id,
      },
    },
    {
      col: 'port_of_discharge_id',
      condition: {
        filter_type: 'text',
        type: 'equals',
        value: mcrl?.[mcrl.length - 1].destination?.location?.id,
      },
    },
  ] as FilterType[];

  const data = await fetchContainerReportData(client, filters, status);
  return data;
};
