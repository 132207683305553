import { gql } from '@apollo/client';
import { containerCargoDetailsFields } from 'operations/modules/booking/graphql/cargo';
import { shipmentContainerFields } from 'operations/modules/booking/graphql/shipmentContainer';

export const UPDATE_SHIPMENT_CONTAINER = gql`
  mutation update_shipment_container($id: ID!, $shipment_container: ShipmentContainerInputType!) {
    update_shipment_container(id: $id, shipment_container: $shipment_container) {
      ...shipmentContainerFields
    }
  }
  ${shipmentContainerFields}
`;

export const SHIPMENT = gql`
  query shipment($id: ID!) {
    shipment(id: $id) {
      id
      final_place_of_delivery {
        id
        name
        country_name
      }
      job_number
      load_type
      freight_type
      shipment_type
      house_shipments {
        id
        final_place_of_delivery {
          id
          name
          country_name
        }
        load_type
        job_number
        shipment_type
        shipment_documents {
          id
          document_type
          document_status
          document_id
          shipment_number
          bl_type
        }
      }
      shipment_documents {
        id
        document_type
        document_status
        document_id
        shipment_number
        bl_type
      }
      shipment_parties {
        id
        name
        party_address {
          id
          name
        }
        party_company {
          id
          registered_name
          global_company_account {
            id
          }
        }
        party_details
        party_referenceable_id
        party_referenceable_type
      }
      customer_company {
        id
        registered_name
        global_company_account {
          id
        }
      }
      priority
      color_code
    }
  }
`;

export const CREATE_SHIPMENT_MESSAGE = gql`
  mutation create_shipment_message(
    $receiver_company_account_id: ID!
    $shipment_id: ID!
    $attachments: [MessageAttachmentInputType!]
    $remarks: String
    $message_type: String
  ) {
    create_shipment_message(
      receiver_company_account_id: $receiver_company_account_id
      shipment_id: $shipment_id
      attachments: $attachments
      remarks: $remarks
      message_type: $message_type
    ) {
      id
    }
  }
`;

export const UPDATE_SHIPMENT_FIELDS = gql`
  mutation update_shipment($id: ID!, $shipment: UpdateShipmentInput!) {
    update_shipment(id: $id, shipment: $shipment) {
      id
    }
  }
`;

export const UPDATE_HOUSE_SHIPMENT_FIELDS = gql`
  mutation update_house_shipment($id: ID!, $shipment: UpdateHouseShipmentInput!) {
    update_house_shipment(id: $id, shipment: $shipment) {
      id
    }
  }
`;

export const GET_SUBSCRIPTIONS = gql`
  query get_subscriptions {
    get_subscriptions(app_type: container_tracking) {
      app {
        label
        name
        description
        app_type
      }
      plan_type
      subscription_data
    }
  }
`;

export const DELETE_CONTAINER = gql`
  mutation delete_shipment_container($id: ID!) {
    delete_shipment_container(id: $id) {
      message
    }
  }
`;

export const DELETE_BR_CONTAINER = gql`
  mutation delete_br_container($id: ID!) {
    delete_br_container(id: $id) {
      message
    }
  }
`;

export const CREATE_CONTAINER = gql`
  mutation create_shipment_container($shipment_container: AddNewContainerInputType!) {
    create_shipment_container(shipment_container: $shipment_container) {
      ...shipmentContainerFields
    }
  }
  ${shipmentContainerFields}
`;

export const BULK_UPDATE_CONTAINER_FIELDS = gql`
  mutation bulk_update_container_fields($containers: [ShipmentContainerInputType!]) {
    bulk_update_container_fields(containers: $containers) {
      id
    }
  }
`;

export const UPDATE_CONTAINER_CARGO_DETAIL = gql`
  mutation update_container_cargo_detail(
    $id: ID!
    $container_cargo_detail: ContainerCargoDetailInputType!
  ) {
    update_container_cargo_detail(id: $id, container_cargo_detail: $container_cargo_detail) {
      ...containerCargoDetailsFields
    }
  }
  ${containerCargoDetailsFields}
`;
