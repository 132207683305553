import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { SHIPMENT_INVOICE_ACTIONS } from '../graphql/productOrder';
import { Modal, message } from '@shipmnts/pixel-hub';
import { useLocation } from 'wouter';
import { CommercialInvoiceValue } from 'operations/models/CommercialInvoice';

interface UpdateCommercialInvoiceStatusProp {
  id: number;
  status: string;
  commercialInvoice: CommercialInvoiceValue;
  onSuccess?: () => void;
  onClose?: () => void;
}

const UpdateCommercialInvoiceStatus = React.memo(function UpdateCommercialInvoiceStatus(
  props: UpdateCommercialInvoiceStatusProp
) {
  const { status, id, commercialInvoice, onSuccess, onClose } = props;
  const { 1: navigate } = useLocation();

  const [updateStatus, { data, loading }] = useMutation(SHIPMENT_INVOICE_ACTIONS, {
    fetchPolicy: 'no-cache',
  });
  let linkMessage = '';
  if (
    commercialInvoice?.containers_commercial_invoices?.length ||
    !!commercialInvoice?.shipment_custom_detail
  ) {
    linkMessage = `This action will delink the invoice which might be linked to any container or custom references.`;
  }
  useEffect(() => {
    Modal.confirm({
      title: 'Confirm',
      content: `${linkMessage} Are you sure you want to ${status} this commercial invoice?`,
      okText: 'Ok',
      okType: 'danger',
      onOk: () => {
        updateStatus({
          variables: {
            id: id,
            action: status,
          },
        });
      },
      cancelText: 'Cancel',
      onCancel: () => {
        if (onClose) onClose();
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, status, updateStatus]);

  useEffect(() => {
    if (data?.shipment_invoice_actions?.status) {
      message.success(`Successfully ${status} Commercial Invoice!!`);
      navigate(`~/form/commercial_invoice/${data?.shipment_invoice_actions?.id}`);
      if (onSuccess) onSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, status, onSuccess]);

  if (loading) {
    return <div>loading...</div>;
  }

  return <></>;
});

export default UpdateCommercialInvoiceStatus;
