import * as Sentry from '@sentry/react';

export function initializeSentry() {
  console.log('initializeSentry');
  if (process.env.REAL_ENV === 'production') {
    Sentry.init({
      dsn: 'https://778b128c5b3241f6972505dbd3441d09@o98011.ingest.sentry.io/1894596',
      environment: process.env.REAL_ENV,
      integrations: [],
      tracesSampleRate: 0.2,
      ignoreErrors: ['ResizeObserver loop limit exceeded'],
    });
  }
}

export function setSentryScope(user) {
  if (process.env.REAL_ENV === 'production') {
    Sentry.setUser({
      email: user.email,
      id: user.id,
      username: user.username,
    });
  }
}
