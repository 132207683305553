import { CarrierValue } from 'operations/models/Carrier';
import { ShipmentValue } from 'operations/models/Shipment';
import {
  getCargoPayloadWithExistingCargo,
  getContainerFromContainerQty,
  getRoutingLegsPayload,
  getShipmentInvoicePayload,
  shipmentCustomDetailPayload,
} from '../ShipmentForm/helpers';
import { pick as _pick, omit as _omit } from 'lodash';
import { Dayjs } from '@shipmnts/pixel-hub';
import { SHIPMENT_TYPE_CONSOL } from '../../constants';
import {
  getAllocationPayloadNew,
  getNewOtoPayload,
} from 'operations/modules/reports/helpers/allocationHelper';
import { convertDateToUnix } from 'operations/modules/reports/components/ShipmentReports/ShipmentEditFieldAction';
import { transformPartyDetails } from 'operations/models/ShipmentParty';
import { FREIGHT_TYPE_AIR } from 'operations/utils/constants';

export const getGenericPayloadValue = (
  values: any,
  shipment?: ShipmentValue | undefined | null
) => {
  const party = values?.party;
  if (
    values?.consignee_party_name === 'to_order' ||
    values?.consignee_party_name === 'to_order_bank'
  ) {
    party[values?.consignee_party_name] = {
      ...party[values?.consignee_party_name],
      id: shipment?.shipment_parties?.find((p: any) => p.name === values?.consignee_party_name)?.id,
    };
  }
  const payload = {
    ...values,
    sales_agent_id: values?.sales_agent?.id,
    ocean_transport_order: getNewOtoPayload(values),
    allocate_ocean_transport_orders: getAllocationPayloadNew(
      values.allocation_ocean_transport_order
    ),
    involved_branch_id: values?.involved_branch?.id,
    consol_type: 'agent',
    preferred_carrier_ids: (values?.preferred_carriers || []).map((pc: CarrierValue) => pc.id),
    split_from_id: values?.split_from_id,
    consignee_party_name: values?.consignee_party_name || 'consignee',
    customer_company_id: values?.customer?.party_company?.id,
    customer_address_id: values?.customer?.party_address?.id,
    inquiry_option_id: values?.inquiry_option?.id,
    carrier_id: values.carrier?.id || values?.ocean_transport_order?.carrier?.id,
    ocean_vessel_id: values.ocean_vessel?.imo,
    cargos: getCargoPayloadWithExistingCargo(values, shipment),
    routing_legs: getRoutingLegsPayload(values, shipment),
    routing_nodes: Object.values(values.routing_details?.routing_nodes || {}).map((rn: any) => {
      const { location, address, company, terminal, id, _id, tags } = rn;
      return {
        id: id || null,
        _id: _id || null,
        location_id: location?.id || null,
        terminal_id: terminal?.id || null,
        address_id: address?.id || null,
        company_id: company?.id || null,
        tags: tags || null,
      };
    }),
    shipment_parties: transformPartyDetails(Object.assign({}, party)),
    shipment_invoices: getShipmentInvoicePayload(values, shipment),
    shipment_containers: values.shipment_containers
      ? (values.shipment_containers || [])
          .filter((c: { container_number: string; pickup_date: Dayjs }) => c.container_number)
          .map((c: { container_number: string; pickup_date: Dayjs }) => ({
            ..._pick(c, [
              'id',
              'is_non_iso_container',
              'container_number',
              'carrier_seal_number',
              'cargo_gross_weight',
              'verified_gross_mass',
              'cargo_net_weight',
              'commercial_invoice_number',
              'purchase_order_number',
              'container_type',
              'container_type_code',
              'remarks',
              'is_shipper_owned',
            ]),
          }))
      : undefined,
    shipment_custom_details: shipmentCustomDetailPayload(values, shipment),
    linked_shipment_id: values?.linked_to_shipment?.id,
    shipment_type: values.shipment_type,
    shipment_estimates: getShipmentEstimatePay(values?.shipment_estimates, values),
  };

  if (values?.shipment_container_quantity && values?.shipment_container_quantity.length > 0) {
    payload.shipment_containers = getContainerFromContainerQty(values);
  }
  const omitFields = [
    'routing_details',
    'ocean_vessel',
    'carrier',
    'party',
    'customer',
    'pickup_containers',
    'import_custom_details',
    'shipping_bill_details',
    'linked_to_shipment',
    'inquiry_option',
    'estimated_time_of_departure',
    'estimated_time_of_arrival',
    'shipment_container_quantity',
    'master',
    'house',
    'id',
    'preferred_carriers',
    'ocean_shipment_booking',
    'sales_agent',
    'allocation_ocean_transport_order',
    'booking_number',
    'booking_date',
    'contract_number',
    'booking_type',
    'vendor',
    'surveyor',
    'voyage_schedule_id',
    'sales_person',
    'booking_party',
    'si_cutoff_date',
    'empty_pickup_location',
    'empty_return_location',
    'gate_open_date',
    'gate_close_date',
    'valid_till_date',
    'doc_cutoff_date',
    'origin_demurrage_free_days',
    'origin_detention_free_days',
    'destination_demurrage_free_days',
    'destination_detention_free_days',
    'confirmed_booking',
    'terms_and_condition',
    'terms_and_condition_description',
    'stuffing_location',
    'destuffing_location',
    'pickup_location',
    'delivery_location',
    'stuffing_type',
    'destuffing_type',
    'involved_branch',
    'seller_same_as_shipper',
    'buyer_same_as_consignee',
    'consignee_same_as_customer',
    'shipper_same_as_customer',
    'service_type',
    'movement_location',
    'pol_buffer_location',
    'pod_buffer_location',
    'type',
  ];
  if (payload.shipment_type !== SHIPMENT_TYPE_CONSOL) {
    omitFields.push('consol_type');
  }
  return _omit(payload, omitFields);
};

const getShipmentEstimatePay = (estimatePayloads: any[], values: any) => {
  return (estimatePayloads || []).map((item) => {
    return {
      item: item.item || null,
      buy_branch_id: item.buy_branch_id || null,
      supplier_company_id: item.supplier_company_id || null,
      buy_rate: item.buy_rate || 0,
      buy_exchange_rate: item.buy_exchange_rate || 1,
      buy_terms: item.buy_terms || null,
      buy_currency: item.buy_currency || null,
      uom: item.uom || null,
      equipment_type: item.equipment_type || null,
      equipment_name: item.equipment_name || null,
      quantity: item.quantity || 0,
      sell_rate: item.sell_rate || 0,
      sell_exchange_rate: item.sell_exchange_rate || 1,
      sell_terms: item.sell_terms || null,
      sell_currency: item.sell_currency || null,
      remarks: item.remarks || null,
      printable_item_name: item.item || null, // Assuming this is the item name for printing
      tag: item.tag || null, // Convert the tag if it's part of an enum
    };
  });
};

export const getMasterDocumentPayload = (values: any) => {
  if (values.master?.shipment_document) {
    let master_shipment_number = values?.master?.shipment_document?.shipment_number;
    if (
      values?.freight_type === FREIGHT_TYPE_AIR &&
      values?.carrier?.iata_awb_prefix &&
      master_shipment_number
    ) {
      master_shipment_number = `${values?.carrier?.iata_awb_prefix}${master_shipment_number}`;
    }
    return {
      ..._omit(values.master?.shipment_document, ['shipment_date', 'document_status_event_date']),
      shipment_number: master_shipment_number,
      shipment_date: convertDateToUnix(values.master?.shipment_document?.shipment_date),
      document_status_event_date: values.master?.shipment_document?.document_status_event_date
        ? convertDateToUnix(values.master?.shipment_document?.document_status_event_date)
        : undefined,
    };
  }
  return undefined;
};

export const getHouseDocumentPayload = (values: any) => {
  if (values.house?.shipment_document) {
    return {
      ..._omit(values.house?.shipment_document, ['shipment_date', 'document_status_event_date']),
      shipment_date: convertDateToUnix(values.house?.shipment_document?.shipment_date),
      document_status_event_date: values.house?.shipment_document?.document_status_event_date
        ? convertDateToUnix(values.house?.shipment_document?.document_status_event_date)
        : undefined,
    };
  }
  return undefined;
};
