import React from 'react';
import { Modal, Row, Col, Collapse, message } from '@shipmnts/pixel-hub';
import { ArrowRightOutlined, WarningOutlined } from '@shipmnts/pixel-hub';
import {
  uniq as _uniq,
  differenceBy as _differenceBy,
  compact as _compact,
  groupBy as _groupBy,
} from 'lodash';
import { ContainersForValidation } from 'operations/modules/reports/components/ContainerReports/ContainerActions/ContainerBulkActions';
import {
  VALIDATE_CONTAINERS_FOR_MERGE,
  VALIDATE_SHIPMENT_CREATION,
  SPLIT_UPDATE_OTO_WARNING,
} from 'operations/modules/booking/graphql/oceanTransportOrder';
import { ContainerReportData } from 'operations/modules/reports/reportModels';
import { CompanyValue } from 'operations/models/Company';
import { CompanyRoleObject } from 'operations/models/BookingRequest';
import { OTOReportData } from 'operations/modules/reports/components/OTOReports/OTOReports';
import { ApolloClient } from '@apollo/client';
import {
  ContainerRequestValue,
  ContainerSettingValue,
  ContainerSettingType,
} from 'operations/models/ContainerRequest';
import { ShipmentType } from 'operations/modules/shipment/constants';
import { SplitBookingFormValue } from 'operations/modules/oto/SplitAndCreateBooking';
import { ExecutionResult } from 'graphql';

const { Panel } = Collapse;

export const showErrorMessages = (errorMessages: string[], title: string) => {
  Modal.error({
    title,
    content: (
      <div style={{ marginTop: '16px' }}>
        {errorMessages.map((error: string, index: number) => (
          <Row key={index} gutter={16} style={{ flexFlow: 'unset' }}>
            <Col span={1}>-</Col>
            <Col span={23}>
              <p>{error}</p>
            </Col>
          </Row>
        ))}
      </div>
    ),
  });
};

export interface ErrorMessages {
  messages: string[];
  resource_name?: string;
  resource_type?: string;
  sub_group?: string;
}

interface ModalErrorMessages {
  header: string;
  description?: {
    sub_group?: string;
    messages: string[];
  }[];
}

export const showMergeErrorMessage = (message: ErrorMessages[], title: string) => {
  const errorMessages: ModalErrorMessages[] = message
    .filter((msg) => !msg.resource_name)
    .map((msg) => ({ header: msg.messages[0] }));

  const groupByBookingNumber = _groupBy(
    message.filter((msg) => Boolean(msg.resource_name)),
    'resource_name'
  );

  Object.keys(groupByBookingNumber).forEach((key: string) => {
    errorMessages.push({
      header: `${groupByBookingNumber[key][0]?.resource_type || ''} # ${key}`,
      description: groupByBookingNumber[key].map((msg) => ({
        sub_group: msg.sub_group,
        messages: msg.messages,
      })),
    });
  });

  const totalMessages: number[] = [];
  errorMessages.forEach((errorMsg: ModalErrorMessages, index: number) => {
    if (errorMsg.description) totalMessages.push(index);
  });
  Modal.error({
    title,
    width: '600px',
    content: (
      <Collapse
        bordered
        expandIcon={() => <ArrowRightOutlined />}
        style={{ marginTop: '24px' }}
        activeKey={totalMessages}
      >
        {errorMessages.map((errorMessage: ModalErrorMessages, index: number) => (
          <Panel key={index} header={errorMessage.header}>
            {errorMessage.description && (
              <div>
                {errorMessage.description.map((description) => {
                  const preFix = description?.sub_group ? `${description.sub_group} : ` : '';
                  return (
                    <Row key={index} gutter={16}>
                      <Col span={1}>-</Col>
                      <Col span={23}>
                        {preFix} {description.messages.join(', ')}
                      </Col>
                    </Row>
                  );
                })}
              </div>
            )}
          </Panel>
        ))}
      </Collapse>
    ),
  });
};

export const validateContainersBeforeMerge = async (
  containers: ContainersForValidation[],
  client: ApolloClient<object>,
  setLoading?: (value: boolean) => void
) => {
  if (setLoading) setLoading(true);
  const shipment_container_ids = containers.map((sc) => sc.id);
  const response = await client.mutate({
    mutation: VALIDATE_CONTAINERS_FOR_MERGE,
    variables: { shipment_container_ids },
  });

  const { data, errors } = response as ExecutionResult;

  let errorMessages: any[] = [];

  if (
    data?.validate_containers_for_merge?.messages &&
    data.validate_containers_for_merge.messages.length > 0 &&
    !errors
  ) {
    errorMessages = data.validate_containers_for_merge.messages;
  } else if (errors) {
    errorMessages = [{ messages: errors.map((err: { message: string }) => err.message) }];
  }

  if (setLoading) setLoading(false);

  if (errorMessages.length > 0) {
    showMergeErrorMessage(errorMessages, 'Can Not Merge Booking');
    return false;
  }
  return true;
};

export const areDifferentBookingsSelected = (containers: ContainerReportData[], title: string) => {
  let differentBookingsFound = false;
  const firstBooking = containers[0].ocean_transport_order_id;
  containers.forEach((sc: ContainerReportData) => {
    if (sc.ocean_transport_order_id !== firstBooking) differentBookingsFound = true;
  });
  if (differentBookingsFound) {
    Modal.error({
      title,
      content: 'Selected containers are of different bookings',
    });
  }
  return differentBookingsFound;
};

export const getShipmentCreationValidationDetails = async (
  containers: ContainerReportData[],
  shipment_type: ShipmentType,
  client: ApolloClient<object>
) => {
  const shipment_container_ids = containers.map((sc) => sc.id);
  const response = await client.mutate({
    mutation: VALIDATE_SHIPMENT_CREATION,
    variables: { shipment_container_ids, shipment_type },
  });

  const { data, errors } = response as ExecutionResult;
  let errorMessages: any[] = [];

  if (
    data?.validate_shipment_creation?.messages &&
    data.validate_shipment_creation.messages.length > 0 &&
    !errors
  ) {
    errorMessages = data.validate_shipment_creation.messages;
  } else if (errors) {
    errorMessages = errors.map((err: { message: string }) => err.message);
  }
  if (errorMessages.length > 0) {
    return {
      errors: errorMessages,
    };
  }
  return data?.validate_shipment_creation;
};

export const validateContainerSameCustomerBooking = (
  containers: ContainerReportData[],
  errorMessageTitle: string,
  setLoading?: (value: boolean) => void
) => {
  if (setLoading) setLoading(true);
  const differentBookingsFound = areDifferentBookingsSelected(containers, errorMessageTitle);
  let differentCustomersFound = false;
  if (!differentBookingsFound) {
    differentCustomersFound = validateContainerSameCustomer(containers, errorMessageTitle);
  }
  if (setLoading) setLoading(false);
  return !differentBookingsFound && !differentCustomersFound;
};

export const validateContainerSameCustomer = (
  containers: ContainerReportData[],
  errorMessageTitle: string,
  setLoading?: (value: boolean) => void
) => {
  if (setLoading) setLoading(true);
  let differentCustomersFound = false;
  const firstContainerCustomer = containers[0].customer_company?.id;

  differentCustomersFound = containers.reduce(
    (isCustomerDifferent: boolean, container: ContainerReportData) => {
      return isCustomerDifferent || container.customer_company.id !== firstContainerCustomer;
    },
    false
  );

  if (differentCustomersFound) {
    Modal.error({
      title: errorMessageTitle,
      content: 'Selected containers are of different customers.',
    });
  }
  if (setLoading) setLoading(false);
  return differentCustomersFound;
};

const CARRIER_MISMATCH = 'Have mismatch in shipping line';
const POL_MISMATCH = 'Have mismatch in port of loading';
const POD_MISMATCH = 'Have mismatch in port of discharge';
const VESSEL_MISMATCH = 'Have mismatch in vessel';
const VOYAGE_NUMBER_MISMATCH = 'Have mismatch in voyage';
const COMMODITY_MISMATCH = 'Have mismatch in commodities';
const CONTAINER_SETTINGS_MISMATCH = 'Have mismatch in container settings';

const validateContainerSettings = (
  container: ContainerReportData,
  container_requests: ContainerRequestValue[]
) => {
  const warnings: string[] = [];
  let containerTypeMismatch = false;

  const container_types_in_cr = container_requests.map((cr) => cr.container_type_code);
  if (!container_types_in_cr.includes(container.container_type_code)) {
    containerTypeMismatch = true;
    return { warnings, containerTypeMismatch };
  }

  const otoContainerSetting = container_requests.find(
    (cr) => cr.container_type_code === container.container_type_code
  )?.container_settings;

  if (!container.container_settings && !otoContainerSetting) {
    return { warnings, containerTypeMismatch };
  } else if (!container.container_settings || !otoContainerSetting) {
    warnings.push(CONTAINER_SETTINGS_MISMATCH);
    return { warnings, containerTypeMismatch };
  } else {
    const containerSetting: ContainerSettingValue = container.container_settings;
    const fields: ContainerSettingType[] = [
      'is_active_reefer',
      'ventilation_requested',
      'humidity_control_requested',
      'controlled_atmosphere_requested',
      'genset_requested',
    ];

    fields.forEach((field) => {
      if (Boolean(containerSetting[field]) !== Boolean(otoContainerSetting[field])) {
        warnings.push(CONTAINER_SETTINGS_MISMATCH);
      }
    });

    if (
      containerSetting.is_active_reefer &&
      (containerSetting.temperature !== otoContainerSetting.temperature ||
        containerSetting.temperature_unit !== otoContainerSetting.temperature_unit)
    )
      warnings.push(CONTAINER_SETTINGS_MISMATCH);

    if (
      containerSetting.ventilation_requested &&
      (containerSetting.air_flow !== otoContainerSetting.air_flow ||
        containerSetting.air_flow_unit !== otoContainerSetting.air_flow_unit)
    )
      warnings.push(CONTAINER_SETTINGS_MISMATCH);

    if (
      containerSetting.humidity_control_requested &&
      containerSetting.relative_humidity_percent !== otoContainerSetting.relative_humidity_percent
    )
      warnings.push(CONTAINER_SETTINGS_MISMATCH);

    if (
      containerSetting.controlled_atmosphere_requested &&
      (containerSetting.oxygen_level_percent !== otoContainerSetting.oxygen_level_percent ||
        containerSetting.nitrogen_level_percent !== otoContainerSetting.nitrogen_level_percent ||
        containerSetting.carbon_dioxide_level_percent !==
          otoContainerSetting.carbon_dioxide_level_percent)
    )
      warnings.push(CONTAINER_SETTINGS_MISMATCH);

    return { warnings: _uniq(warnings), containerTypeMismatch };
  }
};

export const validateBeforeShiftContainers = (
  containers: ContainerReportData[],
  oto: OTOReportData
) => {
  const warnings: string[] = [];
  let containerTypeMismatch = false;

  containers.forEach((container) => {
    const response = validateContainerSettings(container, oto?.container_requests || []);
    containerTypeMismatch = response.containerTypeMismatch || containerTypeMismatch;
    if (!warnings.includes(CONTAINER_SETTINGS_MISMATCH) && response.warnings.length > 0)
      warnings.push(warnings[0]);

    if (
      !warnings.includes(COMMODITY_MISMATCH) &&
      _differenceBy(container.booking_cargos, oto.cargos, 'commodity.id').length > 0
    ) {
      warnings.push(COMMODITY_MISMATCH);
    }

    if (
      !warnings.includes(CARRIER_MISMATCH) &&
      container.global_carrier.id !== oto?.global_carrier?.id
    )
      warnings.push(CARRIER_MISMATCH);

    if (!warnings.includes(VESSEL_MISMATCH) && container.vessel.imo !== oto?.vessel.imo)
      warnings.push(VESSEL_MISMATCH);

    if (!warnings.includes(VOYAGE_NUMBER_MISMATCH) && container.voyage_number !== oto.voyage_number)
      warnings.push(VOYAGE_NUMBER_MISMATCH);

    if (!warnings.includes(POL_MISMATCH) && container.port_of_loading.id !== oto.port_of_loading.id)
      warnings.push(POL_MISMATCH);
    if (
      !warnings.includes(POD_MISMATCH) &&
      container.port_of_discharge.id !== oto.port_of_discharge.id
    )
      warnings.push(POD_MISMATCH);
  });
  const response = { warnings: _compact(warnings), containerTypeMismatch };
  return response;
};

export const getAllCompaniesRolesMapping = (
  container: ContainerReportData,
  default_company: CompanyValue
): CompanyRoleObject[] => {
  const companies_roles_mapping: CompanyRoleObject[] = [];
  if (container && container?.sell_collaborations) {
    companies_roles_mapping.push({
      id: default_company?.id,
      registered_name: default_company?.registered_name,
      address_id: container.sell_collaborations[0]?.vendor_address?.id,
      role: 'Your Company',
    });
  }
  if (container?.customer_company) {
    companies_roles_mapping.push({
      id: container.customer_company.id,
      registered_name: container.customer_company?.registered_name,
      address_id: container.customer_address?.id,
      role: 'Customer',
    });
  }
  return companies_roles_mapping;
};

export const fetchSplitUpdateOTOWarnings = async (
  client: ApolloClient<object>,
  payload: {
    ocean_transport_order?: Partial<SplitBookingFormValue>;
    action: 'update_booking' | 'split_booking' | 'shift_container';
    shipment_container_ids?: string[];
  },
  setFetchingWarnings: (fetching: boolean) => void,
  onSuccess: () => void
) => {
  setFetchingWarnings(true);
  const response = await client.mutate({
    mutation: SPLIT_UPDATE_OTO_WARNING,
    variables: payload,
  });
  const { data, errors } = response as ExecutionResult;
  setFetchingWarnings(false);
  if (data?.split_update_oto_warning?.warnings?.length > 0) {
    const warnings = data?.split_update_oto_warning?.warnings;
    Modal.confirm({
      title: 'Please confirm following messages before proceeding',
      content: (
        <ul>
          {warnings.map((warning: string, index: number) => (
            <li style={{ marginTop: '10px' }} key={index}>
              {warning}
            </li>
          ))}
        </ul>
      ),
      cancelText: 'Cancel',
      okText: 'Confirm & proceed',
      onOk: onSuccess,
      icon: <WarningOutlined />,
      width: 700,
      onCancel: () => {
        return false;
      },
    });
  } else if (errors) {
    message.error('Something went wrong. Try again');
  } else {
    onSuccess();
  }
};
