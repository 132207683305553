import React, { useEffect } from 'react';
import {
  Button,
  Input,
  message,
  Modal,
  Form,
  dayjs,
  Row,
  Typography,
  UploadedDocuments,
  Spin,
} from '@shipmnts/pixel-hub';
import { UPDATE_SHIPMENT_DOCUMENT_STATUS } from 'operations/graphql/shipmentDocument';
import { useMutation } from '@apollo/client';
import { DOCUMENT_TYPE_HBL } from 'operations/constants';
import { DOCUMENT_STATUS_POD_RECEIVED, PROOF_OF_DELIVERY_TAG } from '../../constants';
import { ShipmentDocumentValue } from 'operations/models/ShipmentDocument';
import { ShipmentValue } from 'operations/models/Shipment';
import { useSession } from 'common';
import { getNoPermissionTags } from 'operations/modules/helpers';

interface ProofOfDeliveryReceivedProps {
  documentType: string;
  shipmentDocument: ShipmentDocumentValue;
  shipment: ShipmentValue;
  visible?: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
}
export default function ProofOfDeliveryReceived(props: ProofOfDeliveryReceivedProps) {
  const { documentType, shipmentDocument, visible, onClose, onSuccess, shipment } = props;
  const user = useSession();

  const [form] = Form.useForm();

  const prevStatusMap: { [key: string]: string } = {
    [DOCUMENT_TYPE_HBL]: DOCUMENT_STATUS_POD_RECEIVED,
  };

  const [updateDocumentStatus, { loading: updateDocumentStatusLoading, data, error }] = useMutation(
    UPDATE_SHIPMENT_DOCUMENT_STATUS
  );

  useEffect(() => {
    if (updateDocumentStatusLoading) {
      return;
    }
    if (error) {
      if (onClose) onClose();
      message.error(error.message);
    }
    if (data?.update_shipment_document_status) {
      message.success('Updated successfully');
      if (onSuccess) onSuccess();
      if (onClose) onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDocumentStatusLoading, data, error, onSuccess]);

  const { permissions } = useSession();
  const tagsNoPermission: string[] = getNoPermissionTags(permissions);

  return (
    <Modal
      open={visible}
      title="Proof Of Delivery Received"
      footer={[
        <Button
          key="cancel"
          disabled={updateDocumentStatusLoading}
          onClick={() => {
            if (onClose) onClose();
          }}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={updateDocumentStatusLoading}
          onClick={form.submit}
        >
          POD Received
        </Button>,
      ]}
      onCancel={() => {
        if (onClose) onClose();
      }}
    >
      <Form
        form={form}
        initialValues={{ signed_by: [user.first_name, user?.last_name].join(' ') }}
        onFinish={(values: any) => {
          const { signed_by } = values;
          const nextstatus = prevStatusMap[documentType];
          const document_status_event_date = dayjs().unix();
          updateDocumentStatus({
            variables: {
              id: shipmentDocument?.id,
              document_status: nextstatus,
              document_status_event_date: document_status_event_date,
              ...(signed_by
                ? { document_status_event_data: JSON.stringify({ signed_by: signed_by }) }
                : {}),
            },
          });
        }}
        layout="vertical"
      >
        <Form.Item name="signed_by" label="Signed By" rules={[{ required: true }]} required>
          <Input style={{ width: '100%' }} />
        </Form.Item>
        <Row>
          <div>
            <Typography.Text>
              Upload POD with <strong>Proof Of Delivery</strong> tag
            </Typography.Text>
          </div>
          <div style={{ width: '100%', height: '100%', paddingBottom: '10px' }}>
            {shipment?.id ? (
              <>
                <UploadedDocuments
                  sessionData={user}
                  parent_id={shipment?.id}
                  parent_type={'shipment'}
                  deleteDisabledTags={[]}
                  docgen_url={process.env.DOCGEN_URL || ''}
                  tagsNoPermission={tagsNoPermission}
                  initialUploadDocumentTags={[PROOF_OF_DELIVERY_TAG]}
                />
              </>
            ) : (
              <Spin />
            )}
          </div>
        </Row>
      </Form>
    </Modal>
  );
}
