import { PerformAction } from '../models';
import { SessionDataValue } from 'operations/models/SessionData';
// import PaymentsTable from 'operations/modules/shipment/components/DetailLayout/Accounts/components/PaymentsTable'
import { lazy } from 'react';
const PaymentsTable = lazy(
  () =>
    import('operations/modules/shipment/components/DetailLayout/Accounts/components/PaymentsTable')
);

export type VoucherType = {
  voucher_no: string;
  due_date: string;
  posting_date: string;
  status: string;
  shipment_number: string;
  expense: number;
  balance_outstanding: number;
  voucher_amount: number;
  party: string;
  doctype: string;
  nature: string;
  income: number;
  is_print_disabled: true;
  default_print_template: string;
};
interface AccountsActionType {
  voucher: VoucherType;
  sessionData: SessionDataValue;
}

export const renderShowPaymentsAction = (payload: AccountsActionType): PerformAction => {
  const { voucher } = payload;
  return {
    actionParams: {
      voucher,
    },
    component: PaymentsTable,
  };
};
