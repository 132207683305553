import React from 'react';
import { Timeline, dayjs } from '@shipmnts/pixel-hub';
import { sortBy as _sortBy, capitalize as _capitalize } from 'lodash';
import {
  CREDIT_STATUS_APPROVED,
  CREDIT_STATUS_ON_HOLD,
  CREDIT_STATUS_EVENT_DISPLAY,
  CREDIT_STATUS_ON_AUTO_HOLD,
} from 'operations/modules/shipment/constants';
import {
  IdcardOutlined,
  FileAddOutlined,
  FileDoneOutlined,
  DatabaseOutlined,
  DeleteOutlined,
  FileExclamationOutlined,
  CheckCircleOutlined,
  ExceptionOutlined,
  FlagOutlined,
  RocketFilled,
  GlobalOutlined,
  WarningFilled,
  EditOutlined,
  CloseCircleOutlined,
} from '@shipmnts/pixel-hub';
import {
  DOCUMENT_STATUS_BL_AUDITED,
  DOCUMENT_STATUS_DRAFT_RECEIVED,
  DOCUMENT_STATUS_FIRST_DRAFT_SHARED,
  DOCUMENT_STATUS_POD_RECEIVED,
  DOCUMENT_STATUS_RECEIVED_BY_SHIPPER,
  DOCUMENT_STATUS_SI_FILED,
} from 'operations/modules/reports/constants';
const CLASS_TIMELINE_TILE = 'timeline_tile';
const CLASS_EXCEPTION_TIMELINE = 'exception_timeline';
const CLASS_ACTIVE_TIMELINE = 'active_timeline';
const CLASS_TIMELINE_DESCRIPTION = 'timeline_description';
const CLASS_TIMELINE_APROVE = 'timeline-approved';
const CLASS_TIMELINE_DANGER = 'timeline-danger';
const changeEventsMapper: any = {
  approved_by_airline: {
    icon: <RocketFilled />,
    label: 'Accepted By Airline',
  },
  sent_to_airline: {
    icon: <GlobalOutlined />,
    label: 'Sent to Airline',
  },
  rejected_by_airline: {
    icon: <WarningFilled />,
    label: 'Rejected By Airline',
  },
  amend: {
    icon: <EditOutlined />,
    label: 'Amend',
  },
  validated: {
    icon: <FileDoneOutlined />,
    label: 'Validated',
  },
  validation_failed: {
    icon: <CloseCircleOutlined />,
    label: 'Validation Failed',
  },
  create_draft: {
    icon: <FileAddOutlined />,
    label: 'Draft Created',
  },
  draft_approve: {
    icon: <FileDoneOutlined />,
    label: 'First Draft Approved by Customer/Agent',
  },
  execute: {
    icon: <DatabaseOutlined />,
    label: 'Released',
  },
  delete: {
    icon: <DeleteOutlined />,
    label: 'Deleted',
  },
  cancel: {
    icon: <FileExclamationOutlined />,
    label: 'Cancelled',
  },
  given_to_customer: {
    icon: <CheckCircleOutlined />,
    label: 'Given To Customer',
  },
  surrender_at_origin: {
    icon: <FileDoneOutlined />,
    label: 'Surrendered At Origin',
  },
  receive_at_destination: {
    icon: <FileDoneOutlined />,
    label: 'Received At Destination',
  },
  handover_set_printed: {
    icon: <DatabaseOutlined />,
    label: 'Handover Set Printed',
  },
  unrelease_document: {
    icon: <ExceptionOutlined />,
    label: 'Reopened',
    className: CLASS_EXCEPTION_TIMELINE,
  },
  reopen_for_customer: {
    icon: <ExceptionOutlined />,
    label: 'Reopened',
    className: CLASS_EXCEPTION_TIMELINE,
  },
  [DOCUMENT_STATUS_SI_FILED]: {
    icon: <FileAddOutlined />,
    label: 'SI Filed',
  },
  [DOCUMENT_STATUS_BL_AUDITED]: {
    icon: <FileAddOutlined />,
    label: 'BL Audit',
  },
  [DOCUMENT_STATUS_FIRST_DRAFT_SHARED]: {
    icon: <FileAddOutlined />,
    label: 'First Draft Shared With Customer',
  },
  [DOCUMENT_STATUS_DRAFT_RECEIVED]: {
    icon: <FileDoneOutlined />,
    label: 'First Draft Received From Carrier',
  },
  [DOCUMENT_STATUS_RECEIVED_BY_SHIPPER]: {
    label: 'Received by Shipper',
    icon: <FlagOutlined />,
  },
  [DOCUMENT_STATUS_POD_RECEIVED]: {
    label: 'Proof of Delivery Received',
    icon: <FileDoneOutlined />,
  },
  [CREDIT_STATUS_APPROVED]: {
    icon: <CheckCircleOutlined />,
    className: CLASS_TIMELINE_APROVE,
  },
  [CREDIT_STATUS_ON_HOLD]: {
    icon: <FileExclamationOutlined />,
    className: CLASS_TIMELINE_DANGER,
  },
  [CREDIT_STATUS_ON_AUTO_HOLD]: {
    icon: <FileExclamationOutlined />,
    className: CLASS_TIMELINE_DANGER,
  },

  reopen_for_edit: {
    icon: <ExceptionOutlined />,
    label: 'Reopened For Edit',
    className: CLASS_EXCEPTION_TIMELINE,
  },
};

const ExceptionTimelineStyle = {
  color: '#c23934',
};
const ActiveTimeLineStyle = {
  color: '#0a4fca',
  fontWeight: 'bold',
};
const timeLineDescriptionStyle = {
  color: '#8c8c8c',
};

const timeLineTitleStyle = {
  color: '#000',
};

export interface ShipmentDocumentEvents {
  id?: string;
  event_by?: { [key: string]: string };
  event_date?: any;
  name: string;
  event_data?: any;
  event_type?: string;
}

const ClassToStyleMapper: { [key: string]: any } = {
  active_timeline: ActiveTimeLineStyle,
  exception_timeline: ExceptionTimelineStyle,
  timeline_description: timeLineDescriptionStyle,
  timeline_tile: timeLineTitleStyle,
};

const ChangeEventsTimeline = React.memo(function ChangeEventsTimeline(props: {
  change_events: ShipmentDocumentEvents[];
}) {
  const { change_events } = props;
  const events = _sortBy(change_events, 'event_date');
  return (
    <div
      style={{
        minWidth: '300px',
        height: '500px',
        padding: '10px 0 0 10px',
        marginBottom: '40px',
        overflowY: 'auto',
      }}
      className="change-event-timeline"
    >
      <Timeline>
        {events.map((event: ShipmentDocumentEvents, index) => {
          const eventJson = changeEventsMapper[event.name];
          let eventClass: string = eventJson?.className || CLASS_TIMELINE_TILE;
          if (index === change_events.length - 1 && eventClass !== CLASS_EXCEPTION_TIMELINE)
            eventClass = CLASS_ACTIVE_TIMELINE;
          return (
            <Timeline.Item
              key={index}
              style={{ lineHeight: 0.3, color: '#000' }}
              dot={eventJson?.icon}
            >
              <p style={ClassToStyleMapper[eventClass]}>
                {eventJson?.label ||
                  CREDIT_STATUS_EVENT_DISPLAY?.[event?.name]?.label ||
                  event.name ||
                  ''}
              </p>
              <p style={ClassToStyleMapper[CLASS_TIMELINE_DESCRIPTION]}>
                <IdcardOutlined className="margin-right-xs" style={{ marginRight: '2px' }} />
                {event?.event_by?.first_name || ''} {event?.event_by?.last_name || ''}
              </p>
              {event?.event_data &&
                Object.entries(event?.event_data).map((ele: any, index) => (
                  <p
                    style={{
                      ...ClassToStyleMapper[CLASS_TIMELINE_DESCRIPTION],
                      lineHeight: '16px',
                    }}
                    key={index}
                  >
                    <b>{_capitalize(ele[0]?.replace(/_/g, ' ')) || ''}</b> :{' '}
                    {_capitalize(ele[1]?.replace(/_/g, ' ') || '')}{' '}
                  </p>
                ))}

              <p style={ClassToStyleMapper[CLASS_TIMELINE_DESCRIPTION]}>
                {dayjs.unix(event?.event_date).format('DD-MMM-YYYY, HH:mm')}
              </p>
            </Timeline.Item>
          );
        })}
      </Timeline>
    </div>
  );
});

export default ChangeEventsTimeline;
