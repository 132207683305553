import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_SHIPMENT_CUSTOM_DETAIL } from 'operations/graphql/shipmentCustomDetail';
import { ShipmentValue } from 'operations/models/Shipment';
import { ShipmentCustomDetailValue } from 'operations/models/ShipmentCustomDetail';
import { Button, message, Space, Dropdown, MenuProps } from '@shipmnts/pixel-hub';
import {
  TradeType,
  TRADE_TYPE_EXPORT,
  TRADE_TYPE_IMPORT,
  ACCOUNTING_STATUS_CLOSED,
} from 'operations/modules/shipment/constants';
import { DownOutlined } from '@shipmnts/pixel-hub';
import {
  SHIPMENT_STATUS_BACK_TO_TOWN,
  SHIPMENT_STATUS_CANCELLED,
} from 'operations/modules/reports/constants';
import ReuploadUploadSbBeFile from './ReuploadUploadSbBeFile';
import { startCase as _startCase } from 'lodash';

type MenuItem = Required<MenuProps>['items'][number];

const getCustomDetailPayload = (shipment: ShipmentValue, trade_type: TradeType) => {
  let country;
  if (trade_type === TRADE_TYPE_EXPORT) {
    country = (shipment.place_of_carrier_receipt || shipment.port_of_loading)?.country_code;
  } else if (trade_type === TRADE_TYPE_IMPORT) {
    country = (shipment.place_of_carrier_delivery || shipment.port_of_discharge)?.country_code;
  }
  return {
    shipment_custom_detail: {
      trade_type: trade_type,
      country: country,
    },
    shipment_id: shipment.id,
  };
};

export default function CreatetShipmentCustomDetail(props: {
  shipment: ShipmentValue;
  setActiveShipmentCustomDetail: (value: ShipmentCustomDetailValue) => void;
  onSuccess: () => void;
  tradeType: TradeType;
}) {
  const { shipment, setActiveShipmentCustomDetail, onSuccess, tradeType } = props;
  const [createShipmentCustomDetail, { loading }] = useMutation(CREATE_SHIPMENT_CUSTOM_DETAIL);
  const [sbBeFileDrawer, setSbBeFileDrawer] = useState<boolean>(false);

  const onCreate = async () => {
    const variables = getCustomDetailPayload(shipment, tradeType);

    createShipmentCustomDetail({
      variables: variables,
    })
      .then((res) => {
        message.success('Custom Detail added successfully!');
        setActiveShipmentCustomDetail(res.data.create_shipment_custom_detail);
        if (onSuccess) onSuccess();
      })
      .catch((err) => {
        message.error('Error Occured while adding custom detail.');
      });
  };

  let isDisabled = false;
  if (
    shipment.status === SHIPMENT_STATUS_CANCELLED ||
    shipment.status === SHIPMENT_STATUS_BACK_TO_TOWN ||
    shipment.accounting_status === ACCOUNTING_STATUS_CLOSED
  ) {
    isDisabled = true;
  }

  const getItems = () => {
    const items: MenuItem[] = [];

    items.push({
      label: `Add ${_startCase(tradeType)} Customs Details`,
      key: 'create',
      onClick: () => onCreate(),
    });
    if (
      (shipment.port_of_loading?.country_code === 'IN' && tradeType === TRADE_TYPE_EXPORT) ||
      (shipment.port_of_discharge?.country_code === 'IN' && tradeType === TRADE_TYPE_IMPORT)
    ) {
      items.push({
        label: `Add ${
          tradeType.charAt(0).toUpperCase() + tradeType.slice(1)
        } Custom Details via .sb/.be file`,
        key: 'upload_via_sb_be',
        onClick: () => {
          setSbBeFileDrawer(true);
        },
      });
    }

    return items;
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
      <ReuploadUploadSbBeFile
        title={'Upload .sb/.be File'}
        api={'create'}
        shipment={shipment}
        onClose={() => {
          setSbBeFileDrawer(false);
        }}
        onSuccess={() => {
          setSbBeFileDrawer(false);
          onSuccess();
        }}
        isVisible={sbBeFileDrawer}
      />
      <Dropdown
        menu={{ items: getItems() }}
        disabled={isDisabled}
        overlayStyle={{ margin: '3px', textTransform: 'capitalize' }}
      >
        <Button
          disabled={isDisabled}
          loading={loading}
          type="primary"
          style={{ marginRight: '22px' }}
        >
          <Space>
            Create
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    </div>
  );
}
