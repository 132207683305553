function closePrint(e: any) {
  const container = e.target;
  document.body.removeChild(container.__container__);
  if (container.src) window.URL.revokeObjectURL(container.src);
}

function setPrint(e: any) {
  const container = e.target;
  container.contentWindow.__container__ = container;
  container.contentWindow.src = container.src;
  container.contentWindow.onbeforeunload = closePrint;
  container.contentWindow.onafterprint = closePrint;
  container.contentWindow.focus(); // Required for IE
  container.contentWindow.print();
}

function printPage(sURL: string, file_name: string) {
  const oHiddFrame = document.createElement('iframe');
  oHiddFrame.onload = setPrint;
  oHiddFrame.style.visibility = 'hidden';
  oHiddFrame.style.position = 'fixed';
  oHiddFrame.style.right = '0';
  oHiddFrame.style.bottom = '0';
  oHiddFrame.src = sURL;
  oHiddFrame.title = file_name;
  document.body.appendChild(oHiddFrame);
}

export async function openPrintWindow(props: { html: string; file_name?: string }) {
  const { html, file_name = 'print' } = props;
  try {
    const blob = new Blob([html], {
      type: 'text/html',
    });
    const blobURL = window.URL.createObjectURL(blob);
    printPage(blobURL, file_name);
    return { error: false };
  } catch (error) {
    console.error(error);
    return { error };
  }
}
