/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree';

export const CARRIER_TYPE_AIR = 'air';
export const CARRIER_TYPE_OCEAN = 'ocean';
export const CARRIER_TYPE_NVOCC = 'nvocc';
export const CARRIER_TYPE_BONDED_CARRIER = 'bonded_carrier';
export const CARRIER_TYPE_COLOADER = 'coloader';

export declare type CarrierType =
  | 'air'
  | 'ocean'
  | 'nvocc'
  | 'bonded_carrier'
  | 'coloader'
  | 'road';

export interface CarrierMetaInfo {
  [key: string]: {
    container_type_code: string;
    maximum_cargo_weight: number;
    maximum_cargo_volume: number;
  };
}

const Carrier = types.model({
  id: types.identifier,
  name: types.string,
  carrier_type: types.maybe(
    types.union(
      types.literal(CARRIER_TYPE_AIR),
      types.literal(CARRIER_TYPE_OCEAN),
      types.literal(CARRIER_TYPE_NVOCC),
      types.literal(CARRIER_TYPE_BONDED_CARRIER),
      types.literal(CARRIER_TYPE_COLOADER)
    )
  ),
  standard_alpha_carrier_code: types.maybe(types.maybeNull(types.string)),
  iata_carrier_code: types.maybe(types.maybeNull(types.string)),
  iata_awb_prefix: types.maybe(types.maybeNull(types.string)),
  meta_information: types.maybe(types.maybeNull(types.frozen<CarrierMetaInfo>())),
});

export interface CarrierValue extends Instance<typeof Carrier> {}
export interface CarrierIn extends SnapshotIn<typeof Carrier> {}
export interface CarrierOut extends SnapshotOut<typeof Carrier> {}

export default Carrier;
