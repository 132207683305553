import React from 'react';
import { Row, Col, Checkbox, Input, Form } from '@shipmnts/pixel-hub';
import { AddressCompanySearch, useSession } from 'common';
import { BranchAccountValue } from 'operations/models/BranchAccount';
import { GlobalSearch } from '@shipmnts/pixel-hub';

const ROW_GUTTER = 16;

const BookingRequestBasicDetails = React.memo(function BookingRequestBasicDetails(props: {
  load_type: string;
  showPreferredCarriers?: boolean;
  colSpan: number;
}): JSX.Element {
  const { showPreferredCarriers, colSpan, load_type } = props;
  const session = useSession();
  return (
    <Row gutter={ROW_GUTTER}>
      <Col span={colSpan}>
        <Form.Item
          required
          rules={[{ required: true }]}
          name={['ocean_transport_order', 'customer']}
          label="Customer"
        >
          <AddressCompanySearch
            customerSearchProps={{
              trigger: 'create_booking',
            }}
            addressSearchProps={{
              searchProps: { entity_type: 'billing' },
            }}
          />
        </Form.Item>
      </Col>
      <Col span={colSpan}>
        <Form.Item name={['party', 'shipper']} label="Shipper">
          <AddressCompanySearch />
        </Form.Item>
      </Col>
      <Col span={colSpan}>
        <Form.Item name={['party', 'consignee']} label="Consignee">
          <AddressCompanySearch />
        </Form.Item>
      </Col>
      <Col span={colSpan}>
        <Form.Item
          required
          rules={[{ required: true }]}
          name={['ocean_transport_order', 'vendor', 'party_address']}
          label="Booking Branch"
        >
          <GlobalSearch
            doc_type="Network::Address"
            searchProps={{
              company_id: session.company_account.default_company.id,
            }}
          />
        </Form.Item>
        <Form.Item name="high_priority" className="custom-checkbox" valuePropName="checked">
          <Checkbox>Hot Shipment</Checkbox>
        </Form.Item>
      </Col>
      <Col span={colSpan}>
        <Form.Item
          required
          rules={[{ required: true }]}
          name={['ocean_transport_order', 'sales_person']}
          label="Sales Person"
        >
          <GlobalSearch doc_type="Network::SalesPerson" />
        </Form.Item>
      </Col>

      {showPreferredCarriers && (
        <Col span={colSpan}>
          <Form.Item name="preferred_carriers" label="Preferred Carriers">
            <GlobalSearch
              doc_type="Global::Carrier"
              searchProps={{ carrier_type: ['ocean', 'nvocc', 'coloader'] }}
              selectMode="multiple"
            />
          </Form.Item>
        </Col>
      )}
      <Col span={colSpan}>
        <Form.Item
          noStyle
          dependencies={[
            ['is_external_quotation_number'],
            ['ocean_transport_order', 'customer'],
            ['ocean_transport_order', 'vendor', 'party_address'],
          ]}
        >
          {({ getFieldValue }) => {
            const is_external_quotation_number = getFieldValue('is_external_quotation_number');
            const customer = getFieldValue(['ocean_transport_order', 'customer']);
            const branch_address = getFieldValue([
              'ocean_transport_order',
              'vendor',
              'party_address',
            ]);
            const branch_id = session.branch_accounts?.find(
              (ba: BranchAccountValue) => ba?.default_address?.id === branch_address?.id
            )?.id;
            if (is_external_quotation_number)
              return (
                <Form.Item name="quotation_number" label="Quotation Ref">
                  <Input placeholder="Enter quotation number" />
                </Form.Item>
              );
            return (
              <Form.Item name="inquiry_option" label="Quotation Ref">
                <GlobalSearch
                  doc_type="SalesHub::InquiryOption"
                  // Passing key, because we want to reset select options on customer change
                  key={customer?.party_company?.id}
                  disabled={!customer || !branch_id}
                  searchProps={{
                    customer_company_id: customer?.party_company?.id,
                    involved_branch_id: branch_id,
                    freight_type: 'ocean',
                    trade_type: 'export',
                    load_type,
                  }}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item name="is_external_quotation_number" valuePropName="checked">
          <Checkbox>External Quotation #</Checkbox>
        </Form.Item>
      </Col>
      <Col span={colSpan}>
        <Form.Item name="incoterm" label="Incoterms">
          <GlobalSearch doc_type="Global::Incoterms" />
        </Form.Item>
      </Col>
    </Row>
  );
});

export default BookingRequestBasicDetails;
