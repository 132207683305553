import { ICellRendererParams } from '@ag-grid-community/core';
import React, { useState } from 'react';
import { useShipmentDetail } from '../ShipmentDetailLayout';
import EditContainerDetails from './EditContainer/EditContainerDetails';

interface LinkedHousesRendererType extends ICellRendererParams {
  refetch: () => void;
}

const LinkedHousesRenderer = (props: LinkedHousesRendererType) => {
  const { refetch } = props;
  const [visibleLinkedShipment, setVisibleLinkedShipment] = useState(false);
  const { shipment } = useShipmentDetail();

  if (!Array.isArray(props.value)) return <span />;

  return (
    <>
      <div className="underlined-link" onClick={() => setVisibleLinkedShipment(true)}>
        {`${props.value.length - 1} linked houses`}
      </div>
      {visibleLinkedShipment && shipment?.house_shipments && (
        <EditContainerDetails
          onClose={() => {
            setVisibleLinkedShipment(false);
          }}
          onSuccess={() => {
            setVisibleLinkedShipment(false);
            refetch();
          }}
          container_id={props.data.id}
        />
      )}
    </>
  );
};

export default LinkedHousesRenderer;
