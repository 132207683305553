import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import { Button, Drawer, Typography } from '@shipmnts/pixel-hub';
import ChangeEventsTimeline from '../AttachedHouse/ChangeEventTimeline';
import { ShipmentDocumentEvents } from '../AttachedHouse/ChangeEventTimeline';
import { ShipmentValue } from 'operations/models/Shipment';
import {
  ACCOUNTING_CURRENT_STATUS_PENDING,
  ACCOUNTING_CURRENT_STATUS_CLOSED,
  ACCOUNTING_CURRENT_STATUS_BILLING_CLOSED,
  ACCOUNTING_NEW_STATUS_BILLING_COMPLETED,
  ACCOUNTING_NEW_STATUS_BILLING_REOPEN,
  ACCOUNTING_NEW_STATUS_JOB_CLOSED,
  ACCOUNTING_NEW_STATUS_JOB_REOPEN,
  ACCOUNTING_NEW_STATUS_PENDING,
} from 'operations/modules/shipment/constants';
import Spinner from 'src/components/Spinner';
const { Text } = Typography;

export const FETCH_FINANCE_EVENTS = gql`
  query shipment($id: ID!) {
    shipment(id: $id) {
      id
      finance_events {
        id
        event_by {
          id
          first_name
          last_name
        }
        event_date
        name
        event_data
        event_type
      }
    }
  }
`;

interface ShowFinanceTrackingProps {
  shipment: ShipmentValue;
  onClose: () => void;
}

const newAccountStatus: { [key: string]: { [key: string]: string } } = {
  [ACCOUNTING_CURRENT_STATUS_BILLING_CLOSED]: {
    [ACCOUNTING_CURRENT_STATUS_PENDING]: ACCOUNTING_NEW_STATUS_BILLING_COMPLETED,
    [ACCOUNTING_CURRENT_STATUS_CLOSED]: ACCOUNTING_NEW_STATUS_JOB_REOPEN,
    [ACCOUNTING_CURRENT_STATUS_BILLING_CLOSED]: ACCOUNTING_NEW_STATUS_BILLING_COMPLETED,
    '': ACCOUNTING_NEW_STATUS_BILLING_COMPLETED,
  },
  [ACCOUNTING_CURRENT_STATUS_CLOSED]: {
    [ACCOUNTING_CURRENT_STATUS_PENDING]: ACCOUNTING_NEW_STATUS_JOB_CLOSED,
    [ACCOUNTING_CURRENT_STATUS_BILLING_CLOSED]: ACCOUNTING_NEW_STATUS_JOB_CLOSED,
    [ACCOUNTING_CURRENT_STATUS_CLOSED]: ACCOUNTING_NEW_STATUS_JOB_CLOSED,
    '': ACCOUNTING_NEW_STATUS_JOB_CLOSED,
  },
  [ACCOUNTING_CURRENT_STATUS_PENDING]: {
    [ACCOUNTING_CURRENT_STATUS_BILLING_CLOSED]: ACCOUNTING_NEW_STATUS_BILLING_REOPEN,
    [ACCOUNTING_CURRENT_STATUS_PENDING]: ACCOUNTING_CURRENT_STATUS_PENDING,
    '': ACCOUNTING_NEW_STATUS_PENDING,
  },
};
const modifyAccountingStatus = (events: ShipmentDocumentEvents[]) => {
  let modifiedEvents = [...events];
  modifiedEvents = modifiedEvents.sort((a, b) => {
    return a.event_date - b.event_date;
  });
  const acc_status: string[] = modifiedEvents.map((event, index) => {
    return newAccountStatus[event.name][index === 0 ? '' : modifiedEvents[index - 1].name];
  });
  return modifiedEvents.map((event, index) => ({ ...event, name: acc_status[index] }));
};

const ShipmentFinanceStatusTimeLine = React.memo(function ShipmentFinanceStatusTimeLine(
  props: ShowFinanceTrackingProps
) {
  const { onClose, shipment } = props;
  const [events, setEvents] = useState<ShipmentDocumentEvents[]>([]);

  const { error, loading, data, refetch } = useQuery(FETCH_FINANCE_EVENTS, {
    variables: { id: shipment.id },
  });

  useEffect(() => {
    if (data?.shipment?.finance_events) {
      const events = modifyAccountingStatus(data?.shipment?.finance_events);
      setEvents(events);
    }
  }, [data]);

  if (!data && error) {
    return (
      <div className="align-center">
        Unable to fetch timeline. &nbsp;
        <Button type="link" onClick={() => refetch()}>
          Retry
        </Button>
      </div>
    );
  }

  if (loading)
    return (
      <Drawer open={true}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Spinner size="small" tip="Fetching Timeline" />;
        </div>
      </Drawer>
    );

  if (events && events.length > 0)
    return (
      <Drawer title={'Finance Timeline'} className="bl-status-drawer" open={true} onClose={onClose}>
        <ChangeEventsTimeline change_events={events} />
      </Drawer>
    );
  return (
    <Drawer open={true} onClose={onClose}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Text style={{ textAlign: 'center' }} type="danger">
          No Events to Display
        </Text>{' '}
      </div>
    </Drawer>
  );
});

export default ShipmentFinanceStatusTimeLine;
