import React, { useState } from 'react';
import { Table, Select, Typography, Form, DOCUMENT_TAGS } from '@shipmnts/pixel-hub';
import { AttachmentValue } from 'operations/models/Message';
import { SHIPMENT_TYPE_CONSOL } from 'operations/modules/shipment/constants';
const { Link } = Typography;
const { OptGroup, Option } = Select;

const DOC_TAGS: { [key: string]: Array<string> } = DOCUMENT_TAGS;

const renderName = (text: string, record: AttachmentValue) => {
  return (
    <Link href={record?.document_url || ''} target="_blank">
      {text}
    </Link>
  );
};

const renderTags = (text: string, record: AttachmentValue, index: number) => {
  return (
    <Form.Item name={['uploaded_documents', record.document_identifier || index, 'tags']}>
      <Select style={{ width: '100%' }} mode="multiple">
        {Object.keys(DOC_TAGS).map((grp_key) => {
          const doc_tags = DOC_TAGS[grp_key];
          return (
            <OptGroup key={grp_key} label={grp_key}>
              {doc_tags.map((tag) => (
                <Option key={tag} value={tag}>
                  {tag}
                </Option>
              ))}
            </OptGroup>
          );
        })}
      </Select>
    </Form.Item>
  );
};

const columns = [
  {
    title: 'Name',
    dataIndex: 'file_name',
    width: '40%',
    render: renderName,
  },
  {
    title: 'Tags',
    dataIndex: 'tags',
    width: '60%',
    render: renderTags,
  },
];

const MessageShipmentUploadedDocumentList = React.memo(
  function MessageShipmentUploadedDocumentList(props: {
    attachments: AttachmentValue[];
    onChange?: (value: React.Key[]) => void;
    value?: string[];
    shipmentType: string;
    shipmentId: string;
  }): JSX.Element {
    const { attachments, value, onChange, shipmentType, shipmentId } = props;
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(value || []);

    if (!attachments || attachments.length === 0) return <span />;

    const getFilteredAttachments = () => {
      return attachments.filter((a) => {
        if (shipmentType === SHIPMENT_TYPE_CONSOL && a.parents && a.parents.length > 1) {
          return false;
        }
        return (a.parents || []).some((d) => String(d.parent_id) === String(shipmentId));
      });
    };

    const rowSelection = {
      selectedRowKeys,
      onChange: (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys);
        if (onChange) {
          onChange(selectedRowKeys);
        }
      },
    };

    return (
      <Table
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        columns={columns}
        dataSource={getFilteredAttachments()}
        pagination={false}
        className="no-margin-form-item "
        size="small"
        rowKey="document_identifier"
      />
    );
  }
);

export default MessageShipmentUploadedDocumentList;
