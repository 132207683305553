import React, { useState } from 'react';
import {
  Descriptions,
  Table,
  Card,
  Button,
  Typography,
  Form,
  EditModeCard,
} from '@shipmnts/pixel-hub';
import CustomerLedgerDetailsForm from '../CustomerLedgerDetailsForm';
import { WithPermission } from '@shipmnts/pixel-hub';
import { CompanyValue, CompanyAccountsData } from 'network/models/Company';
import SectionEditFormWrapper from './SectionEditFormWrapper';
import { pick as _pick, omit as _omit } from 'lodash';
import { ERPNextConfigData } from 'network/utils/ErpNextConfigDataWrapper';
import { CompanyViewSectionProps } from '../CompanyView';
import { PlusOutlined } from '@shipmnts/pixel-hub';
import { PERMISSION_CUSTOMER_CREATE_EDIT } from 'network/permissions';
import { isCustomer } from 'common';

const { Text } = Typography;

interface CustomerLedgerDetailsProps extends CompanyViewSectionProps {
  erpnextConfigData: ERPNextConfigData;
}

export const getFormInitialValues = (
  company: CompanyValue,
  companyAccountsData?: CompanyAccountsData
) => {
  const receivable_accounts = (companyAccountsData?.receivable_accounts || []).map((st) =>
    _omit(st, '__typename')
  );
  const formPayload = {
    ..._pick(company, ['id', 'company_type']),
    ..._pick(companyAccountsData, [
      'receivable_tax_withholding_category',
      'default_currency',
      'is_msme',
    ]),
    is_msme: companyAccountsData?.is_msme || 0,
    receivable_accounts,
  };
  return formPayload;
};

const CustomerLedgerDetails = React.memo(function CustomerLedgerDetails(
  props: CustomerLedgerDetailsProps
): JSX.Element {
  const [form] = Form.useForm();
  const { company, companyAccountsData, erpnextConfigData, onCompanyUpdate } = props;
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const initialValues = getFormInitialValues(company, companyAccountsData);
  if (!isCustomer(company?.company_type)) {
    return (
      <WithPermission
        permission={{ name: PERMISSION_CUSTOMER_CREATE_EDIT, docType: 'Network::Company' }}
      >
        <Card
          title="Customer Ledger Details"
          key="customer_ledger_details"
          size="small"
          extra={
            showForm ? (
              [
                <Button
                  key="cancel"
                  onClick={() => {
                    setShowForm(false);
                  }}
                >
                  Cancel
                </Button>,
                <Button
                  loading={loading}
                  key="save"
                  onClick={form.submit}
                  type="primary"
                  style={{ marginLeft: '12px' }}
                >
                  Create
                </Button>,
              ]
            ) : (
              <Button
                onClick={() => {
                  setShowForm(true);
                  form.setFieldsValue({
                    id: company.id,
                    is_customer: true,
                    is_lead: true,
                  });
                }}
                icon={<PlusOutlined />}
                type="primary"
                disabled={company.is_disabled}
              >
                Create Customer Ledger
              </Button>
            )
          }
        >
          {showForm ? (
            <SectionEditFormWrapper
              setEditMode={setShowForm}
              form={form}
              setLoading={setLoading}
              onSuccess={onCompanyUpdate}
              initialValues={initialValues}
              company={company}
            >
              <Form.Item noStyle name="is_customer">
                <span />
              </Form.Item>
              <CustomerLedgerDetailsForm company={company} erpnextConfigData={erpnextConfigData} />
            </SectionEditFormWrapper>
          ) : (
            <Text type="secondary">Customer Ledger not yet created</Text>
          )}
        </Card>
      </WithPermission>
    );
  }
  return (
    <EditModeCard
      onEdit={() => form.setFieldsValue(initialValues)}
      editDisabled={company.is_disabled}
      onSave={form.submit}
      cardKey="customer_ledger_details"
      title="Customer Ledger Details"
      editPermission={{ name: PERMISSION_CUSTOMER_CREATE_EDIT, docType: 'Network::Company' }}
    >
      {({
        editMode,
        setEditMode,
        setLoading,
      }: {
        editMode: any;
        setEditMode: any;
        setLoading: any;
      }) => {
        if (editMode)
          return (
            <SectionEditFormWrapper
              setEditMode={setEditMode}
              form={form}
              setLoading={setLoading}
              onSuccess={onCompanyUpdate}
              initialValues={initialValues}
              company={company}
            >
              <CustomerLedgerDetailsForm company={company} erpnextConfigData={erpnextConfigData} />
            </SectionEditFormWrapper>
          );
        return (
          <>
            <Descriptions
              className="no-padding-description"
              column={{ xs: 2, sm: 4, md: 4, lg: 4 }}
              size="small"
              layout="vertical"
              colon={false}
            >
              <Descriptions.Item label="Billing Currency">
                {companyAccountsData?.default_currency || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Tax Withholding Category">
                {companyAccountsData?.receivable_tax_withholding_category || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Is MSME">
                {companyAccountsData?.is_msme ? 'Yes' : 'No'}
              </Descriptions.Item>
            </Descriptions>
            {companyAccountsData?.receivable_accounts &&
              companyAccountsData?.receivable_accounts.length > 0 && (
                <Table
                  title={() => <Text type="secondary">Non-standard receivable accounts</Text>}
                  size="small"
                  dataSource={companyAccountsData?.receivable_accounts}
                  pagination={false}
                  rowKey="company"
                  columns={[
                    {
                      title: 'Company',
                      dataIndex: 'company',
                      key: 'company',
                    },
                    {
                      title: 'Account',
                      dataIndex: 'account',
                      key: 'account',
                    },
                  ]}
                />
              )}
          </>
        );
      }}
    </EditModeCard>
  );
});

export default CustomerLedgerDetails;
