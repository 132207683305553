import { Column } from 'operations/models/Report';
import React, { useState } from 'react';
import { BaseTable, Button, RenderLinkByDocType } from '@shipmnts/pixel-hub';
import ProductPropertyDrawer from '../ProductPropertyDrawer';
import { WarehouseTransactionValue } from 'operations/models/WarehouseTransaction';
import {
  DimensionsWithUOM,
  QtyUomTypeRenderer,
} from 'common/report_manager/components/Renderer/cellRenderer';
import { TRANSACTION_STATUS_COLOR_HASH, TRANSACTION_STATUS_DISPLAY_HASH } from '../helpers';

interface LinkedTransactionTabProps {
  warehouseTransaction: WarehouseTransactionValue;
}
const LinkedTransactionTab = (props: LinkedTransactionTabProps) => {
  const { warehouseTransaction } = props;
  const [showProductPropertyDrawer, setShowProductPropertyDrawer] = useState<boolean>(false);
  const [productPropertyParams, setProductPropertyParams] = useState<any>({});
  const tableData: any[] = [];

  if (warehouseTransaction) {
    warehouseTransaction?.warehouse_transaction_products?.forEach((element: any) => {
      element?.linked_products?.forEach((product: any) => {
        tableData.push({
          ...product,
          id: product?.warehouse_transaction?.id,
          transaction_number: product?.warehouse_transaction?.transaction_number,
          transaction_date: product?.warehouse_transaction?.transaction_date,
          status: product?.warehouse_transaction?.status,
        });
      });
    });
  }

  const openPropertyDrawer = (params: any) => {
    setProductPropertyParams(params);
    setShowProductPropertyDrawer(true);
  };

  const columns: Column[] = [
    {
      headerName: 'Transaction #',
      field: 'transaction_number',
      colId: 'transaction_number',
      suppressColumnsToolPanel: true,
      cellRenderer: (params: any) => {
        if (params.node.isRowPinned()) return <></>;
        return (
          <RenderLinkByDocType
            key={params?.node?.data?.id}
            doc_type_id={'Wms::WarehouseTransaction'}
            id={params?.node?.data?.id}
            value={params?.node?.data?.transaction_number}
            extra_fields={{}}
            colDef={undefined}
          />
        );
      },
      maxWidth: 150,
      editable: false,
    },
    {
      headerName: 'Transaction Date',
      field: 'transaction_date',
      colId: 'transaction_date',
      columnType: 'Date',
      cellEditor: 'DateEditor',
      editable: false,
    },
    {
      headerName: 'Status',
      field: 'status',
      colId: 'status',
      columnType: 'String',
      editable: false,
      pinned: 'right',
      cellRenderer: 'StatusRenderer',
      cellRendererParams: {
        filter_options: {
          colors: TRANSACTION_STATUS_COLOR_HASH,
          display_hash: TRANSACTION_STATUS_DISPLAY_HASH,
        },
      },
    },
    {
      headerName: 'Product Name',
      field: 'product_name',
      colId: 'product_name',
      cellEditor: 'SearchDocTypeEditor',
      editable: false,
      maxWidth: 300,
    },
    {
      headerName: 'Cust. Product Code',
      field: 'product_code',
      colId: 'product_code',
      columnType: 'String',
      editable: false,
    },
    {
      headerName: 'Location',
      field: 'warehouse_unit',
      colId: 'warehouse_unit',
      editable: false,
      valueFormatter: (params: any) => params.value?.node_name,
      maxWidth: 300,
      filter: 'agSetColumnFilter',
      keyCreator: (params: any) => params.value?.node_name,
      filterParams: {
        convertValuesToStrings: true,
      },
    },
    {
      headerName: 'Packaging Type',
      field: 'packing_type',
      colId: 'packing_type',
      columnType: 'String',
      editable: false,
    },
    {
      headerName: 'Qty',
      field: 'qty',
      colId: 'qty',
      columnType: 'Float',
      cellEditor: 'FloatEditor',
      editable: false,
    },
    {
      headerName: 'Dimensions (L X B X H)',
      field: 'dimensions',
      colId: 'dimensions',
      columnType: 'String',
      editable: false,
      cellRenderer: (params: any) => {
        if (params.node.isRowPinned()) return <></>;
        return (
          <DimensionsWithUOM
            length={params?.data?.length}
            breadth={params?.data?.breadth}
            height={params?.data?.height}
            dimension_unit={params?.data?.dimension_unit}
          />
        );
      },
      hide: true,
    },
    {
      headerName: 'Gross Vol',
      field: 'gross_volume',
      colId: 'gross_volume',
      columnType: 'Float',
      cellEditor: 'FloatEditor',
      editable: false,
      cellRenderer: (params: any) => {
        if (params.node.isRowPinned()) return <></>;
        return <QtyUomTypeRenderer uom={'CBM'} qty={params?.data?.gross_volume} />;
      },
    },
    {
      headerName: 'Gross Weight',
      field: 'gross_weight',
      colId: 'gross_weight',
      columnType: 'Float',
      cellEditor: 'FloatEditor',
      editable: false,
      cellRenderer: (params: any) => {
        if (params.node.isRowPinned()) return <></>;
        return (
          <QtyUomTypeRenderer
            uom={params?.data?.weight_unit || 'kgs'}
            qty={params?.data?.gross_weight}
          />
        );
      },
    },
    {
      headerName: 'Vol. Weight',
      field: 'volumetric_weight',
      colId: 'volumetric_weight',
      columnType: 'Float',
      editable: false,
      cellRenderer: (params: any) => {
        return <QtyUomTypeRenderer uom={'kgs'} qty={params?.data?.volumetric_weight} />;
      },
    },
    {
      headerName: 'Ch. Weight',
      field: 'chargeable_weight',
      colId: 'chargeable_weight',
      columnType: 'Float',
      editable: false,
      cellRenderer: (params: any) => {
        return <QtyUomTypeRenderer uom={'kgs'} qty={params?.data?.chargeable_weight} />;
      },
    },
    {
      headerName: 'Batch #',
      field: 'batch_number',
      colId: 'batch_number',
      columnType: 'String',
      editable: false,
      hide: true,
    },
    {
      headerName: 'Serial #',
      field: 'serial_number',
      colId: 'serial_number',
      columnType: 'String',
      editable: false,
    },
    {
      headerName: 'Properties',
      field: 'product_property',
      colId: 'product_property',
      cellRenderer: (params: any) => {
        if (params.node.isRowPinned()) return <></>;
        return <Button onClick={() => openPropertyDrawer(params)}>Show Property</Button>;
      },
      editable: false,
      hide: true,
    },
    {
      headerName: 'Invoice #',
      field: 'invoice_number',
      colId: 'String',
      columnType: 'String',
      editable: false,
    },
    {
      headerName: 'Invoice Date',
      field: 'invoice_date',
      colId: 'invoice_date',
      columnType: 'Date',
      cellEditor: 'DateEditor',
      editable: false,
    },
    {
      headerName: 'Container #',
      field: 'container_number',
      colId: 'container_number',
      columnType: 'String',
      editable: false,
      hide: true,
    },

    {
      headerName: 'Customs Ref #',
      field: 'customs_ref_code',
      colId: 'customs_ref_code',
      columnType: 'String',
      editable: false,
      hide: true,
    },

    {
      headerName: 'Lot #',
      field: 'lot_number',
      colId: 'lot_number',
      columnType: 'String',
      editable: false,
      hide: true,
    },
  ];
  return (
    <>
      <ProductPropertyDrawer
        key={showProductPropertyDrawer}
        setShowProductPropertyDrawer={setShowProductPropertyDrawer}
        showProductPropertyDrawer={showProductPropertyDrawer}
        params={productPropertyParams}
        editable={false}
      />

      <BaseTable
        rowData={tableData || []}
        reportName="linked_transactions"
        height={250}
        columns={columns}
        reportConfig={{
          suppressCellFocus: false,
          suppressLastEmptyLineOnPaste: true,
          rowHeight: 40,
          stopEditingWhenCellsLoseFocus: true,
          defaultColDef: {
            resizable: true,
          },
          animateRows: true,
        }}
      />
    </>
  );
};

export default LinkedTransactionTab;
