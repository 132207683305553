import { startCase as _startCase, set as _set } from 'lodash';
import { BookingRequestValue } from 'operations/models/BookingRequest';
import { CompanyValue } from 'operations/models/Company';
import { LocationValue } from 'operations/models/Location';
import { VesselValue } from 'operations/models/Vessel';
import { UserAccountValue } from 'operations/models/UserAccount';
import { DEFAULT_DATE_FORMAT, DEFAULT_DATE_TIME_FORMAT } from '@shipmnts/pixel-hub';
import { ShipmentContainerValue } from 'operations/models/ShipmentContainer';
import {
  renderCommodity,
  renderContainerQuantityAndType,
  renderPreferredCarriers,
  calculateStatusWiseContainers,
  renderContainerTemperature,
  renderRecentOtoStatus,
  renderCargoWtAndPackages,
} from 'operations/modules/reports/oldCellRenderers';
import { ShipmentDocumentValue } from 'operations/models/ShipmentDocument';
import { renderDate } from 'common';
import { LOAD_TYPE_FCL } from 'operations/baseConstants';
import { SalesPersonValue } from 'common/models/SalesPerson';

export interface BookingRequestReportData extends BookingRequestValue {
  vessel: VesselValue;
  voyage_number: string;
  requested_at?: number;
  requested_since?: string;
  sales_person: SalesPersonValue;
  shipment_containers: Array<ShipmentContainerValue>;
  transporter_companies: Array<CompanyValue>;
  shipper_company?: CompanyValue;
  customer_company: CompanyValue;
  port_of_loading: LocationValue;
  port_of_discharge: LocationValue;
  recent_valid_till_date?: number;
  estimated_time_of_departure?: number;
  estimated_time_of_arrival?: number;
  master_shipment?: { id: string; shipment_document_master: ShipmentDocumentValue };
  gate_open_date?: number;
  gate_close_date?: number;
  si_cutoff_date?: number;
  cancelled_by?: string;
  cancellation_marked_by?: UserAccountValue;
}

type BrDateFieldType =
  | 'requested_at'
  | 'estimated_time_of_departure'
  | 'estimated_time_of_arrival'
  | 'recent_valid_till_date'
  | 'gate_open_date'
  | 'gate_close_date'
  | 'si_cutoff_date';

const dateFields: BrDateFieldType[] = [
  'requested_at',
  'estimated_time_of_departure',
  'estimated_time_of_arrival',
  'recent_valid_till_date',
  'si_cutoff_date',
];
const dateTimeFields: BrDateFieldType[] = ['gate_open_date', 'gate_close_date'];

const renderBookingRequestDates = (
  row: BookingRequestReportData,
  dateFields: BrDateFieldType[],
  dateFormat: string
) => {
  return dateFields.reduce(
    (dateObject: { [key: string]: string | undefined }, field: BrDateFieldType) => {
      const dateValue = row[field] as number;
      _set(dateObject, field, renderDate(dateValue, dateFormat));
      return dateObject;
    },
    {}
  );
};

const getSumOfAttribute = (arr: Array<Record<string, any>>, field: string) => {
  let sum = 0;
  arr.map((x) => (sum += x[field] || 0));
  return sum;
};

export const renderBookingRequestData = (row: BookingRequestReportData) => ({
  ...row,
  preferred_carriers: renderPreferredCarriers(row.preferred_carriers),
  shipping_line: row.ocean_transport_orders[0]?.global_carrier?.name || '',
  commodity: renderCommodity(row.cargos || []),
  requested_quantity_and_type:
    row.load_type === LOAD_TYPE_FCL
      ? renderContainerQuantityAndType(row.container_requests || [], 'quantity')
      : renderCargoWtAndPackages(row.cargos),
  allocated_quantity_and_type: renderContainerQuantityAndType(
    row.container_requests || [],
    'quantity_fulfilled'
  ),
  pickedup_quantity_and_type: renderContainerQuantityAndType(
    row.container_requests || [],
    'quantity_picked_up'
  ),
  origin_port_gated_in_quantity_and_type: renderContainerQuantityAndType(
    row.container_requests || [],
    'quantity_origin_port_gated_in'
  ),
  allocation_pending_quantity_and_type: renderContainerQuantityAndType(
    row.container_requests || [],
    'quantity_unfulfilled'
  ),
  loaded_on_vessel_quantity_and_type: renderContainerQuantityAndType(
    row.container_requests,
    'quantity_loaded_on_vessel'
  ),
  vessel_voyage: `${row?.vessel?.name || ''} - ${row.voyage_number || ''}`,
  created_by: `${row?.created_by?.first_name || ''} ${row?.created_by?.last_name || ''}`,
  cancelled_by: _startCase(row?.cancelled_by),
  cancellation_marked_by: `${row?.cancellation_marked_by?.first_name || ''} ${
    row?.cancellation_marked_by?.last_name || ''
  }`,
  status_text: `${row.status} ${row.requested_since || ''}`,
  pickup_pending_quantity_and_type: calculateStatusWiseContainers(
    row.shipment_containers || [],
    'pickup_pending'
  ),
  container_settings: renderContainerTemperature(row.container_requests),
  transporter_names: (row.transporter_companies || [])
    .map((tr) => {
      return tr.registered_name;
    })
    .join(', '),
  shipper: row?.shipper_company?.registered_name,
  recent_oto_status: renderRecentOtoStatus(row.ocean_transport_orders),
  allocated_booking_numbers: row.ocean_transport_orders
    .map((oto) => oto.booking_number)
    .filter(Boolean)
    .join(', '),
  ...renderBookingRequestDates(row, dateFields, DEFAULT_DATE_FORMAT),
  ...renderBookingRequestDates(row, dateTimeFields, DEFAULT_DATE_TIME_FORMAT),
  carting_gross_weight: `${getSumOfAttribute(row.cargos || [], 'gross_weight')} ${
    row.cargos[0]?.weight_unit
  }`,
  dg_indexing_date: renderDate(
    row.ocean_transport_orders[0]?.dg_indexing_date,
    DEFAULT_DATE_FORMAT
  ),
  all_booking_dates: row.ocean_transport_orders
    .filter((oto) => Boolean(oto.booking_date))
    .map((oto) => renderDate(oto.booking_date, DEFAULT_DATE_FORMAT))
    .join(', '),
  container_numbers: (row?.shipment_containers || [])
    .filter((sc) => Boolean(sc.container_number))
    .map((sc) => sc.container_number)
    .join(', '),
  mbl_number: row?.master_shipment?.shipment_document_master?.shipment_number,
});
