import { erpNextAxiosCall } from './utils/erpnextApiHelper';

const orFilters = (name) => {
  let filters = [];
  if (name) {
    filters.push(` ["Item", "item_code", "like", "%${name}%"] `);
    filters.push(` ["Item", "item_name", "like", "%${name}%"] `);
  }
  return `[ ${filters.join(',')} ]`;
};

export const searchItemListByNameApi = async (
  name,
  subdomain,
  filters = [],
  options = {
    limit_start: 0,
    limit_page_length: 10,
  }
) => {
  try {
    if (!subdomain) throw new Error('subdomain required');
    const url = `${subdomain}/api/method/shipmnts.controllers.network_company.get_items_with_taxes`;
    const or_filters = orFilters(name);
    filters.push([`["Item","disabled","=","0"]`]);
    filters = `[${filters.join(',')}]`;
    const response = await erpNextAxiosCall({
      action: 'get',
      url,
      params: { filters, or_filters, ...options },
    });
    return response;
  } catch (errors) {
    console.error(errors);
    return { errors };
  }
};
