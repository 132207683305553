import { ICellRendererParams } from '@ag-grid-community/core';
import { ShipmentContainerValue } from 'operations/models/ShipmentContainer';
import React from 'react';
import { startCase as _startCase } from 'lodash';

const ContainerTypeAndSettingsRenderer = (props: ICellRendererParams) => {
  if (!props.data) return <></>;
  const settings = titleDescriptions(props.data);
  const text = settings
    .filter((o) => o.value)
    .map((i) => `${i.label}: ${i.value}`)
    .join(', ');
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-around',
      }}
    >
      <div style={{ lineHeight: 'initial' }}>{props.value}</div>
      {text !== '' && <div style={{ fontSize: '10px', lineHeight: 'initial' }}>{text}</div>}
    </div>
  );
};

export default ContainerTypeAndSettingsRenderer;
const titleDescriptions = (container: ShipmentContainerValue) => [
  {
    label: 'Temperature',
    value: container?.container_settings?.temperature
      ? container?.container_settings?.temperature +
        ' ' +
        container?.container_settings?.temperature_unit
      : null,
  },
  {
    label: 'Air Flow',
    value: container?.container_settings?.air_flow
      ? container?.container_settings?.air_flow +
        ' ' +
        _startCase(container?.container_settings?.air_flow_unit || '')
      : null,
  },
  {
    label: 'Humidity',
    value: container?.container_settings?.relative_humidity_percent
      ? `${container?.container_settings?.relative_humidity_percent} %`
      : null,
  },
  {
    label: 'Vent',
    value: container?.container_settings?.vent_setting,
  },
];
