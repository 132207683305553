import React, { useEffect, useContext, useState } from 'react';
import { Button, Result } from '@shipmnts/pixel-hub';
import { gql, useLazyQuery } from '@apollo/client';
import { useAuth0, sessionKey } from './Auth0';
import { setErpNextAxiosDefaults } from './utils/axiosDefaults';
import SessionData from 'common/models/SessionData';
import NavbarContextWrapper from 'common/NavbarContextWrapper';
import { SessionContextProvider } from './utils/SessionContext';
import Spinner from 'src/components/Spinner';

export const companyAccountFeaturesFields = gql`
  fragment companyAccountFeaturesFields on CompanyAccountFeaturesObjectType {
    stock_management
    enforce_credit_control
    allow_house_summary_screen
    consol_helper
    inventory_management
    state_code_needed
    use_booking_request
    sales_person_disabled
  }
`;

const GET_USER_PROFILE = gql`
  query user_profile {
    user_profile {
      id
      first_name
      last_name
      name
      email
      user_level
      user_contact_id
      role {
        id
        role_name
        permissions {
          id
          permission_name
          types
          doc_type
        }
      }
      tenant_id
      branch_accounts {
        id
        name
        entity_type
        default_address {
          id
          name
          city {
            id
            name
          }
          city_name
          company_id
          print_address
          state_name
          state_code
          country_name
          country_code
        }
        address_types
        city {
          name
          code
        }
        state {
          name
          code
        }
        country {
          name
          code
        }
        company_account_id
        postal_code
        print_address
        erp_cost_center_id
      }
      company_account {
        country_of_incorporation
        id
        registered_name
        display_name
        default_currency
        business_type
        subscriptions {
          id
          app {
            label
            name
            description
            app_type
          }
          plan_type
          subscription_data
        }
        default_company {
          id
          business_type
          country_of_incorporation
          registered_name
          logo
          letter_head
          regulatory_details_data {
            id
            key
            value
          }
          company_group
          company_identification_number
        }
        primary_business
        business_verticals {
          id
          name
          fields
        }
        features {
          ...companyAccountFeaturesFields
        }
        agent_iata_code
        is_iata_agent
        subdomain
      }
    }
  }
  ${companyAccountFeaturesFields}
`;

const initializeApp = ({ session, getTokenSilently, logout }) => {
  setErpNextAxiosDefaults(getTokenSilently, logout, session?.company_account?.subdomain);
};

const NavbarContext = React.createContext();
export const useNavbarContext = () => useContext(NavbarContext);

const ApplicationLayout = React.memo(function ApplicationLayout(props) {
  const { session, children } = props;

  const [getUserProfile, { loading, data, error, refetch }] = useLazyQuery(GET_USER_PROFILE, {
    fetchPolicy: 'no-cache',
  });
  const [getUserProfileCopy, { data: dataCopy }] = useLazyQuery(GET_USER_PROFILE, {
    fetchPolicy: 'no-cache',
  });
  const { getTokenSilently, logout } = useAuth0();

  const [sessionData, setSessionData] = useState(undefined);

  useEffect(() => {
    if (!!session) {
      initializeApp({
        session,
        getTokenSilently,
        logout,
      });
      setSessionData(SessionData.create(session));
    } else {
      getUserProfile();
    }
    // Refresh user data every 32 minutes
    const interval = setInterval(getUserProfileCopy, 32 * 60 * 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const session_data = data?.user_profile;
    if (session_data) {
      initializeApp({
        session: session_data,
        getTokenSilently,
        logout,
      });
      setSessionData(SessionData.create(session_data));
      if (!session) {
        const new_session = { ...session_data, expires_at: Date.now() + 60 * 60 * 1000 };
        localStorage.setItem(sessionKey, JSON.stringify(new_session));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    const session_data = dataCopy?.user_profile;
    if (session_data) {
      initializeApp({
        session: session_data,
        getTokenSilently,
        logout,
      });
      setSessionData(SessionData.create(session_data));
      const new_session = { ...session_data, expires_at: Date.now() + 60 * 60 * 1000 };
      localStorage.setItem(sessionKey, JSON.stringify(new_session));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCopy]);

  if (error)
    return (
      <Result
        status="500"
        title="Unable to fetch company information"
        subTitle="Sorry, something went wrong when fetching your basic details. Either the servers have caught cold or there is some problem with your internet."
        extra={[
          <Button key="retry" type="primary" onClick={() => refetch()}>
            Retry
          </Button>,
          <Button key="logout" type="primary" onClick={() => logout({})}>
            Logout
          </Button>,
        ]}
      />
    );

  if (loading || !sessionData)
    return (
      <div className="centered fullscreen">
        <Spinner tip="Fetching your basic information..." />
      </div>
    );

  return (
    sessionData && (
      <SessionContextProvider session={sessionData}>
        <NavbarContextWrapper>{children}</NavbarContextWrapper>
      </SessionContextProvider>
    )
  );
});

export default ApplicationLayout;
