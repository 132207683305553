import { useMutation } from '@apollo/client';
import { message, Modal, Form, dayjs, DatePicker } from '@shipmnts/pixel-hub';
import React, { useEffect } from 'react';
import { UPDATE_FREIGHT_CONTRACT } from './graphql/freightContract';
import { get as _get } from 'lodash';
import { FIELD_VALID_TILL } from './constant';
interface ExpireContractValidityModalProps {
  visible: boolean;
  freight_contract: any;
  onClose: () => void;
}
export default function ExpireContractValidityModal(props: ExpireContractValidityModalProps) {
  const { onClose, freight_contract } = props;
  const [form] = Form.useForm();
  const [fcUpdate, { data, loading, error }] = useMutation(UPDATE_FREIGHT_CONTRACT);

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
    if (data?.update_freight_contract) {
      message.success('Validity Updated Successfully');
    }
  }, [data, loading, error]);

  return (
    <Modal
      open={true}
      destroyOnClose
      onOk={form.submit}
      okText="Submit"
      onCancel={onClose}
      title="Contract Expires on"
      width="316px"
    >
      <Form
        form={form}
        onFinish={(values) => {
          const { id } = freight_contract;
          fcUpdate({
            variables: {
              id,
              freight_contract: {
                [FIELD_VALID_TILL]: values[FIELD_VALID_TILL].endOf('day').unix(),
              },
            },
          });
        }}
        initialValues={{ valid_till: dayjs(_get(freight_contract, FIELD_VALID_TILL)) }}
        layout="vertical"
      >
        <Form.Item required name={FIELD_VALID_TILL} label={'Valid Till'}>
          <DatePicker style={{ width: '100%' }} format={'DD-MM-YYYY'} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
