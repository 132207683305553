import React, { useEffect } from 'react';
import { ShipmentValue } from 'operations/models/Shipment';
import {
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
  Drawer,
  InputNumber,
  convertDatesTounix,
  DatePicker,
} from '@shipmnts/pixel-hub';
import {
  ShipmentContainerValue,
  validateContainerNumber,
} from 'operations/models/ShipmentContainer';
import { DrawerFooter } from '@shipmnts/pixel-hub';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_CONTAINER } from './graphql';
import { containerTypes, containerTypesFlatArray } from 'operations/baseConstants';
import { RuleObject } from '@shipmnts/pixel-hub';
import { get, omit, pick } from 'lodash';
import {
  SHIPMENT_TYPE_BACK_TO_BACK,
  SHIPMENT_TYPE_DIRECT,
  SHIPMENT_TYPE_HOUSE,
} from 'operations/modules/shipment/constants';
import { errorMessageHandlerGraphQLString } from 'common';
import { FETCH_SHIPMENT_CONTAINERS } from 'operations/modules/booking/graphql/shipmentContainer';
import LinkedShipments from './EditContainer/LinkedShipments';
import { disableFutureDate, dayjs } from '@shipmnts/pixel-hub';
const { Option } = Select;

type Props = {
  shipment: ShipmentValue;
  onClose: () => void;
  onSuccess?: () => void;
};

const getShipmentContainerPayload = (values: any) => {
  return {
    container_cargo_details: values.container_cargo_details.map((ele: any) =>
      omit(ele, 'is_disabled')
    ),
    container_tare_weight: Number(parseFloat(values.container_tare_weight)?.toFixed(3)),
    verified_gross_mass: Number(parseFloat(values.verified_gross_mass)?.toFixed(3)),
    commercial_invoice_number: values.commercial_invoice_number,
    container_number: values.container_number,
    container_type: containerTypes[values.container_type_code],
    container_type_code: values.container_type_code,
    is_non_iso_container: values.is_non_iso_container,
    purchase_order_number: values.purchase_order_number,
    remarks: values.remarks,
    ...convertDatesTounix(
      {
        empty_picked_up_date: values.empty_picked_up_date,
      },
      ['empty_picked_up_date']
    ),
  };
};

const AddNewContainer = (props: Props) => {
  const { onClose, shipment, onSuccess } = props;
  const { id, master_shipment_id, shipment_type } = shipment;
  const [form] = Form.useForm();

  //Queries/Mutations
  const [createContainer, { data, loading, error }] = useMutation(CREATE_CONTAINER);
  const { data: containersData } = useQuery(FETCH_SHIPMENT_CONTAINERS, {
    variables: { shipment_ids: [id] },
  });

  const _containers = get(containersData, 'fetch_shipment_containers', []);

  const getInitialCargoDetails = () => {
    let container_cargo_detail: any[] = [];
    const default_cargo_details = {
      gross_volume: parseFloat('0'),
      gross_weight: parseFloat('0'),
      net_weight: parseFloat('0'),
      total_number_of_packages: parseFloat('0'),
    };
    let cargoDetails = pick(shipment, [
      'gross_volume',
      'gross_weight',
      'net_weight',
      'total_number_of_packages',
    ]);
    const isAlreadyPresent = _containers.some((sc: ShipmentContainerValue) => {
      return (sc.container_cargo_details || []).some((ccd) => ccd.shipment_id === id);
    });
    // FIX ME
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    if (isAlreadyPresent) cargoDetails = {};
    if ([SHIPMENT_TYPE_DIRECT, SHIPMENT_TYPE_BACK_TO_BACK].includes(shipment_type || '')) {
      container_cargo_detail = [{ shipment_id: id, ...default_cargo_details, ...cargoDetails }];
    } else if (SHIPMENT_TYPE_HOUSE === shipment_type) {
      //HBL Consol
      container_cargo_detail = [{ shipment_id: id, ...default_cargo_details, ...cargoDetails }];
      if (master_shipment_id) {
        container_cargo_detail.push({ shipment_id: master_shipment_id, ...default_cargo_details });
      }
    } else {
      container_cargo_detail.push({ shipment_id: id, ...default_cargo_details });
    }
    return container_cargo_detail;
  };
  const getAllShipment = () => {
    return [shipment, ...(shipment?.house_shipments || [])];
  };
  const init_cargo_details = getInitialCargoDetails();
  const all_shipments = getAllShipment();

  const addContainerHandler = (values: any) => {
    const container = getShipmentContainerPayload(values);
    createContainer({
      variables: {
        shipment_container: container,
      },
    });
  };

  useEffect(() => {
    if (error) {
      console.error(error);
      message.error(errorMessageHandlerGraphQLString(error));
    }
    if (data && data.create_shipment_container) {
      message.success('Container added successfully');
      if (onSuccess) onSuccess();
      onClose();
    }
  }, [error, data, onSuccess, onClose]);

  return (
    <Drawer
      key="create_container"
      title={<div style={{ display: 'flex', alignItems: 'center' }}>Create New Container</div>}
      width={'80%'}
      open={true}
      onClose={onClose}
      footer={
        <DrawerFooter
          onClose={onClose}
          saveText="Add Container"
          closeText="Close"
          loading={loading}
          saveButtonHTMLType={'submit'}
          sendEmail={false}
          onSave={form.submit}
        />
      }
    >
      <Form
        form={form}
        onFinish={(values: any) => {
          addContainerHandler(values);
        }}
        layout={'vertical'}
        initialValues={{
          container_cargo_details: init_cargo_details,
        }}
      >
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              name="empty_picked_up_date"
              label="Picked Up Date"
              initialValue={dayjs()}
              rules={[{ required: true, message: 'Picked Up Date is required' }]}
            >
              <DatePicker
                disabledDate={disableFutureDate}
                showTime
                format="DD-MM-YYYY HH-mm"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues?.is_non_iso_container !== currentValues?.is_non_iso_container
              }
            >
              {({ getFieldValue, validateFields }) => {
                const is_non_iso_container = getFieldValue(['is_non_iso_container']);
                const rules = is_non_iso_container
                  ? [{ required: true }]
                  : [
                      {
                        validator: (rule: RuleObject, value: string) => {
                          let error: string | boolean = false;
                          try {
                            value && validateContainerNumber(value);
                          } catch (e) {
                            if (e instanceof Error) error = e.message;
                          }
                          if (!error) {
                            return Promise.resolve();
                          }
                          return Promise.reject(error);
                        },
                      },
                      { required: true },
                    ];
                validateFields([['container_number']]);
                return (
                  <Form.Item
                    required
                    name={['container_number']}
                    label="Container Number"
                    rules={rules}
                  >
                    <Input placeholder="Container number" />
                  </Form.Item>
                );
              }}
            </Form.Item>
            <Form.Item name={['is_non_iso_container']} valuePropName="checked">
              <Checkbox>Non ISO container</Checkbox>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              required={true}
              name="container_type_code"
              label="Container Type"
              rules={[{ required: true, message: 'Container type is required' }]}
            >
              <Select>
                {containerTypesFlatArray.map((c: any) => (
                  <Option key={c.value} value={c.value}>
                    {c.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="verified_gross_mass" label="VGM Weight / Container">
              <InputNumber
                placeholder="VGM Weight"
                addonAfter={'kg'}
                style={{ width: '100%' }}
                precision={3}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item name="container_tare_weight" label="Tare Weight / Container">
              <InputNumber
                placeholder="Tare Weight"
                addonAfter={'kg'}
                style={{ width: '100%' }}
                precision={3}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="commercial_invoice_number" label="Commercial Invoice #">
              <Input placeholder="Commercial Invoice #" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="purchase_order_number" label="Purchase Order #">
              <Input placeholder="Purchase Order #" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="remarks" label="Remarks">
              <Input placeholder="Remarks" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.List name={['container_cargo_details']}>
              {(fields, { add, remove }) => {
                return (
                  <LinkedShipments
                    shipments={all_shipments}
                    form={form}
                    fields={fields}
                    removeRow={remove}
                    addRow={add}
                  />
                );
              }}
            </Form.List>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};

export default AddNewContainer;
