import { AddCollaborators } from '@shipmnts/pixel-hub';
import { PerformAction } from '../models';
import { TicketForm } from 'service_management';

export const editTicketAction = (payload: any): PerformAction => {
  const { ticket } = payload;

  return {
    actionParams: {
      edit: true,
      ticket_id: ticket.id,
      visible: true,
      setVisible: (visible: boolean) => {
        //to do
      },
    },
    component: TicketForm,
  };
};

export const shareTicketAction = (payload: any): PerformAction => {
  const { ticket } = payload;
  return {
    actionParams: {
      id: ticket.ticket_number,
      referenceId: ticket.id,
      referenceType: 'ServiceManagement::Ticket::Ticket',
    },
    component: AddCollaborators,
  };
};
