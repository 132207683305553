export const statusMap: { [key: string]: string } = {
  not_saved: 'Not Saved',
  draft: 'Draft',
  submitted: 'Submitted',
  cancelled: 'Cancelled',
};
// TODO: not saved
export const classStatusMap: { [key: string]: string } = {
  not_saved: 'status_color_gray',
  draft: 'status_color_secondary_blue',
  submitted: 'status_color_green',
  cancelled: 'status_color_red',
};

export const COMMERICIAL_INVOICE_DRAFT_STATUS = 'draft';
export const COMMERICIAL_INVOICE_SUBMIT_STATUS = 'submitted';
export const COMMERICIAL_INVOICE_CANCELLED_STATUS = 'cancelled';

export const COMMERICIAL_INVOICE_COLOR_HASH = {
  [COMMERICIAL_INVOICE_DRAFT_STATUS]: 'secondary_blue',
  [COMMERICIAL_INVOICE_SUBMIT_STATUS]: 'green',
  [COMMERICIAL_INVOICE_CANCELLED_STATUS]: 'red',
};

export const COMMERICIAL_INVOICE_DISPLAY_HASH = {
  [COMMERICIAL_INVOICE_DRAFT_STATUS]: 'Draft',
  [COMMERICIAL_INVOICE_SUBMIT_STATUS]: 'Submitted',
  [COMMERICIAL_INVOICE_CANCELLED_STATUS]: 'Cancelled',
};
