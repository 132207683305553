import { gql } from '@apollo/client';
import { salesPersonFields } from 'operations/graphql/salesPerson';
import { addressFields } from 'operations/graphql/shipmentEstimate';

export const regulatoryDetailFields = gql`
  fragment regulatoryDetailFields on RegulatoryDetailsObjectType {
    id
    key
    value
    valid_till
    attachment {
      document_identifier
      document_url
      content_type
      file_size
      file_name
    }
  }
`;

export const contactsFields = gql`
  fragment contactsFields on UserContactObjectType {
    id
    department
    email
    pretty_display
    first_name
    last_name
    mobile_number
    dial_code
    title
    is_internal
    is_disabled
  }
`;

export const companyFields = gql`
  fragment companyFields on CompanyObjectType {
    id
    status
    created_at
    updated_at
    registered_name
    country_of_incorporation
    company_type
    company_stage
    created_by {
      id
      first_name
      last_name
    }
    company_group
    entity_type
    logo
    is_disabled
    remarks
    company_identification_number
    tax_registration_number
    tax_deduction_id
    receivable_credit_control_type
    lead_owner {
      ...salesPersonFields
    }
    domain
    addresses {
      ...addressFields
    }
    teams {
      id
      role
      branch_accounts {
        id
        name
      }
      user_contact {
        id
        first_name
        last_name
        name
      }
      sales_person {
        ...salesPersonFields
      }
      reference_id
      reference_type
    }
    contacts {
      ...contactsFields
    }
    sales_partner {
      id
      registered_name
    }
    regulatory_details_data {
      ...regulatoryDetailFields
    }
    connection_request {
      id
      status
      from_company_account {
        id
      }
      to_company_account {
        id
      }
    }
  }
  ${addressFields}
  ${salesPersonFields}
  ${contactsFields}
  ${regulatoryDetailFields}
`;

export const FETCH_COMPANY = gql`
  query company($id: ID!) {
    company(id: $id) {
      ...companyFields
    }
    ${companyFields}
  }
`;
