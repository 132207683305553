import React from 'react';
import { ShipmentCustomDetailValue } from 'operations/models/ShipmentCustomDetail';
import { Action } from '../models';
import {
  performShipmentCustomsEvent,
  renderShipmentCustomsDeteleAction,
  renderEditShipmentCustomsAction,
  renderReUploadFlatFile,
} from './shipmentCustomDetailActionRenderer';
import { PRIMARY_TYPE, SECONDARY_TYPE, NEGATIVE_TYPE } from '../constants';
import { ReconciliationOutlined, EditOutlined } from '@shipmnts/pixel-hub';
import { TrackingEventValue } from 'operations/models/TrackingEvent';
import {
  EVENT_CUSTOMS_RELEASED,
  EVENT_IN_EXPORT_CUSTOMS_RELEASED,
  EVENT_IN_IMPORT_CUSTOMS_RELEASED,
} from 'operations/constants';
import {
  SHIPMENT_STATUS_CANCELLED,
  SHIPMENT_STATUS_BACK_TO_TOWN,
} from 'operations/modules/reports/constants';
import { ACCOUNTING_STATUS_CLOSED, FREIGHT_TYPE_ROAD } from 'operations/modules/shipment/constants';
// TODO: add action wise icons
export const getShipmentCustomsNextEvents = (
  shipmentCustomDetail: ShipmentCustomDetailValue
): Action[] => {
  const { next_actions } = shipmentCustomDetail;
  const actions: Action[] = [];
  (next_actions || []).forEach((action: string) => {
    actions.push({
      key: action,
      type: PRIMARY_TYPE,
      displayComponent: action,
      icon: <ReconciliationOutlined />,
      isEnable: true,
      performAction: performShipmentCustomsEvent,
      description: action,
      extraProps: { event_name: action, event_type: 'UPDATE' },
    });
  });
  return actions;
};

export const getShipmentCustomsDeleteAction = (
  shipmentCustomDetail: ShipmentCustomDetailValue
): Action => {
  let crEventNotExist = true;
  (shipmentCustomDetail?.tracking_events || []).forEach((event: TrackingEventValue) => {
    //Todo: Fix this harcoding
    if (
      [
        EVENT_IN_IMPORT_CUSTOMS_RELEASED,
        EVENT_IN_EXPORT_CUSTOMS_RELEASED,
        EVENT_CUSTOMS_RELEASED,
      ].includes(event.name) &&
      event.actual_date
    ) {
      crEventNotExist = false;
    }
  });
  return {
    key: 'delete_custom_detail',
    type: NEGATIVE_TYPE,
    displayComponent: 'Delete Custom Detail',
    icon: <ReconciliationOutlined />,
    isEnable: crEventNotExist,
    performAction: renderShipmentCustomsDeteleAction,
    description: 'Delete Custom Detail',
  };
};

export const getEditShipmentCustomsAction = (
  shipmentCustomDetail: ShipmentCustomDetailValue
): Action => {
  return {
    key: 'edit_custom_detail',
    type: SECONDARY_TYPE,
    displayComponent: 'Edit Custom Detail',
    icon: <EditOutlined />,
    isEnable: !(
      shipmentCustomDetail?.shipment?.accounting_status === ACCOUNTING_STATUS_CLOSED ||
      shipmentCustomDetail?.shipment?.status === SHIPMENT_STATUS_CANCELLED ||
      shipmentCustomDetail?.shipment?.status === SHIPMENT_STATUS_BACK_TO_TOWN
    ),
    performAction: renderEditShipmentCustomsAction,
    description: 'Edit Custom Detail',
  };
};

export const getReUploadFlatFile = (shipmentCustomDetail: ShipmentCustomDetailValue): Action => {
  let isVisible = false;
  if (
    shipmentCustomDetail?.shipment?.accounting_status !== ACCOUNTING_STATUS_CLOSED &&
    shipmentCustomDetail?.shipment?.status !== SHIPMENT_STATUS_CANCELLED &&
    shipmentCustomDetail?.shipment?.status !== SHIPMENT_STATUS_BACK_TO_TOWN &&
    shipmentCustomDetail.country === 'IN'
  ) {
    isVisible = true;
  }
  if (shipmentCustomDetail?.shipment?.freight_type === FREIGHT_TYPE_ROAD) {
    isVisible = false;
  }
  return {
    key: '',
    type: SECONDARY_TYPE,
    displayComponent: 'Update via .sb/.be file',
    icon: <EditOutlined />,
    isEnable: isVisible,
    performAction: renderReUploadFlatFile,
    description: 'Update via .sb/.be file',
  };
};
export const getShipmentCustomsActions = (shipmentCustomDetail: ShipmentCustomDetailValue) => {
  return [
    ...getShipmentCustomsNextEvents(shipmentCustomDetail),
    getEditShipmentCustomsAction(shipmentCustomDetail),
    getShipmentCustomsDeleteAction(shipmentCustomDetail),
    getReUploadFlatFile(shipmentCustomDetail),
  ];
};
