import { Button, Layout, Result, Skeleton, Tabs, TabsProps } from '@shipmnts/pixel-hub';
import { AppHelmet } from '@shipmnts/pixel-hub';
import React, { useState } from 'react';
import { Link, useParams } from 'wouter';
import ProductDetailHeader from './ProductDetailHeader';
import OverviewTab from './Tabs/OverviewTab';
import { useQuery } from '@apollo/client';
import { GET_PRODUCT } from 'operations/graphql/item';
import { CreateProductFormDrawer } from './CreateProductForm';
export type ProductTabType = 'overview' | undefined;
interface ProductDetailLayoutProps {
  id: string;
  tab?: ProductTabType;
  onClose?: () => void;
  externalLink?: boolean;
}
const ProductDetailLayoutWrapper: React.FC = (props: any) => {
  const params = useParams<{ id: string }>();
  const { id } = params;

  return <ProductDetailLayout id={id || props.id} {...props}></ProductDetailLayout>;
};
const { Content } = Layout;

const TabComponentMap = new Map();
TabComponentMap.set('overview', OverviewTab);

export const ProductDetailLayout = (props: ProductDetailLayoutProps) => {
  const { tab, id, onClose, externalLink } = props;
  const [activeTab, setActivetab] = useState<ProductTabType>(tab || 'overview');
  const TabComponent = TabComponentMap.get(activeTab);
  const { loading, error, data, refetch } = useQuery(GET_PRODUCT, {
    variables: { id: id },
  });
  const [visible, setVisible] = useState<boolean>(false);
  if (loading) {
    return <Skeleton avatar paragraph={{ rows: 3 }} loading={loading} active></Skeleton>;
  }

  if (error) {
    let errorCode: 500 | 404 = 500,
      title = 'Something went wrong while fetching the product.',
      subTitle = error ? error.message : '',
      button = (
        <Button
          onClick={() => {
            if (refetch) {
              refetch();
            }
          }}
          type="primary"
        >
          Retry
        </Button>
      );
    if (error && error.message.includes('Wms::Product for given id Not found')) {
      errorCode = 404;
      title = `Product #${id} does not exist`;
      subTitle = '';
      button = (
        <Button type="primary">
          <Link to="/">Go Home</Link>
        </Button>
      );
    }
    return <Result status={errorCode} title={title} subTitle={subTitle} extra={button} />;
  }

  if (!data?.get_product) return <div>Data not found</div>;

  const getTab = () => {
    const tabs: TabsProps['items'] = [];
    tabs.push({ label: 'Overview', key: 'overview' });
    return tabs;
  };

  return (
    <div style={{ height: '100vh' }}>
      <AppHelmet>
        <title>{`#${'App' || ''} | Shipment`}</title>
      </AppHelmet>
      <Layout>
        <CreateProductFormDrawer
          product={data?.get_product}
          id={id}
          visible={visible}
          setVisible={setVisible}
        />
        <ProductDetailHeader
          setVisible={setVisible}
          onClose={onClose}
          externalLink={externalLink}
          product={data?.get_product}
        />
        <Content>
          <Tabs
            activeKey={activeTab}
            onTabClick={(key: string) => {
              setActivetab(key as ProductTabType);
            }}
            tabBarStyle={{
              paddingLeft: '10px',
              margin: '0px',
            }}
            items={getTab()}
            style={{ margin: '0px 24px' }}
          ></Tabs>
          <Content>
            {(TabComponent && (
              <TabComponent externalLink={externalLink} product={data?.get_product} />
            )) || <></>}
          </Content>
        </Content>
      </Layout>
    </div>
  );
};

export default ProductDetailLayoutWrapper;
