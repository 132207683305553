import React from 'react';
import { List, Avatar, CustomIcon } from '@shipmnts/pixel-hub';
import {
  RoutingLegValue,
  MODE_OF_TRANSIT_SEA,
  MODE_OF_TRANSIT_AIR,
  MODE_OF_TRANSIT_RAIL,
} from 'operations/models/RoutingLeg';
import { startCase as _startCase, compact as _compact } from 'lodash';
import { observer } from 'mobx-react-lite';

interface RoutingDetailsViewProps {
  routingLegs?: Array<RoutingLegValue>;
}

const iconMap: { [key: string]: JSX.Element } = {
  [MODE_OF_TRANSIT_AIR]: (
    <CustomIcon className="" fill="#2196F3" height={24} icon="Airport" style={{}} width={24} />
  ),
  [MODE_OF_TRANSIT_SEA]: (
    <CustomIcon className="" fill="#2196F3" height={24} icon="Ship" style={{}} width={24} />
  ),

  [MODE_OF_TRANSIT_RAIL]: (
    <CustomIcon className="" fill="#2196F3" height={24} icon="ICD" style={{}} width={24} />
  ),
};

const RoutingLegRender = observer(function RoutingLegRender(props: {
  routing_leg: RoutingLegValue;
}): JSX.Element {
  const { routing_leg } = props;

  const origin = _compact([
    routing_leg?.origin?.location?.name,
    routing_leg?.origin?.location?.city_code,
    routing_leg?.origin?.location?.state_code,
    routing_leg?.origin?.location?.country_code,
  ]).join(', ');

  const destination = _compact([
    routing_leg?.destination?.location?.name,
    routing_leg?.destination?.location?.city_code,
    routing_leg?.destination?.location?.state_code,
    routing_leg?.destination?.location?.country_code,
  ]).join(', ');

  const mode_of_transit = routing_leg?.mode_of_transit || '';
  let transport_via = _startCase(mode_of_transit);
  if (mode_of_transit === MODE_OF_TRANSIT_SEA)
    transport_via = routing_leg?.vessel?.name || transport_via;

  const departure_time =
    routing_leg?.actual_time_of_departure || routing_leg?.estimated_time_of_departure;
  const arrival_time =
    routing_leg?.actual_time_of_arrival || routing_leg?.estimated_time_of_arrival;
  const show_departure_time = departure_time ? departure_time.format('DD MMM YYYY') : ' - ';
  const show_arrival_time = arrival_time ? arrival_time.format('DD MMM YYYY') : ' - ';
  const divStyle: React.CSSProperties = {
    width: '100px',
    fontSize: '14px',
    textAlign: 'center',
  };

  const voyage_information =
    mode_of_transit === MODE_OF_TRANSIT_SEA ? (
      <span>
        Voyage number: <span style={{ color: '#000000' }}>{routing_leg?.voyage_number}</span>
        &nbsp;&nbsp;Flag: {routing_leg?.vessel?.flag_id}
        &nbsp;&nbsp;IMO: {routing_leg?.vessel?.imo}
      </span>
    ) : (
      ''
    );
  return (
    <div key={routing_leg.id}>
      <List.Item style={{ background: 'ghostwhite' }}>
        <List.Item.Meta
          avatar={<div style={divStyle}>{show_departure_time}</div>}
          title={
            <span>
              Departs from <b>{origin}</b>
            </span>
          }
          description={<span>Terminal: {routing_leg?.origin?.terminal?.name}</span>}
        />
      </List.Item>
      <List.Item>
        <List.Item.Meta
          avatar={
            <div style={divStyle}>
              {
                <Avatar
                  style={{ background: 'none' }}
                  shape="square"
                  size={40}
                  icon={iconMap[mode_of_transit || '']}
                />
              }
            </div>
          }
          title={<span>Transport via {transport_via}</span>}
          description={voyage_information}
        />
      </List.Item>
      <List.Item style={{ background: 'ghostwhite' }}>
        <List.Item.Meta
          avatar={<div style={divStyle}>{show_arrival_time}</div>}
          title={
            <span>
              Arrives at <b>{destination}</b>
            </span>
          }
          description={<span>Terminal: {routing_leg?.destination?.terminal?.name}</span>}
        />
      </List.Item>
    </div>
  );
});

const RoutingDetailsView = observer(function RoutingDetailsView(
  props: RoutingDetailsViewProps
): JSX.Element {
  const { routingLegs = [] } = props;
  return (
    <List
      itemLayout="horizontal"
      dataSource={routingLegs}
      renderItem={(item) => <RoutingLegRender routing_leg={item} />}
    />
  );
});

export default RoutingDetailsView;
