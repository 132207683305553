/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree';

const Commodity = types.model({
  id: types.identifier,
  name: types.string,
  commodity_classification_code: types.maybe(types.string),
  commodity_coding_system: types.maybe(types.string),
  dry_cargo: types.maybe(types.boolean),
  dangerous_cargo: types.maybe(types.boolean),
  live_reefer_cargo: types.maybe(types.boolean),
  oog_cargo: types.maybe(types.boolean),
});

export type CommodityType = 'dry_cargo' | 'live_reefer_cargo' | 'dangerous_cargo' | 'oog_cargo';

export interface CommodityValue extends Instance<typeof Commodity> {}
export interface CommodityIn extends SnapshotIn<typeof Commodity> {}
export interface CommodityOut extends SnapshotOut<typeof Commodity> {}

export default Commodity;
