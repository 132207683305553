import DeleteViewSubscription from 'operations/modules/report_manager/components/DeleteViewSubscription';
import StatusUpdateViewSubscription from 'operations/modules/report_manager/components/StatusUpdateViewSubscription';
import { AutoEmailSubscriptionForm, AutoEmailFinanceSubscriptionForm } from '@shipmnts/pixel-hub';

export const editViewSubscriptionActionRender = ({ viewSubscription }: any) => {
  return {
    actionParams: {
      viewSubscriptionId: viewSubscription.id,
      viewSubscription: viewSubscription,
      isVisible: true,
    },
    component: viewSubscription.is_erpnext_auto_email
      ? AutoEmailFinanceSubscriptionForm
      : AutoEmailSubscriptionForm,
  };
};

export const deleteViewSubscriptionActionRender = ({ viewSubscription }: any) => {
  return {
    actionParams: {
      viewSubscription: viewSubscription,
    },
    component: DeleteViewSubscription,
  };
};

export const statusUpdateViewSubscriptionActionRender = ({ viewSubscription }: any) => {
  return {
    actionParams: {
      viewSubscription: viewSubscription,
    },
    component: StatusUpdateViewSubscription,
  };
};
