import { Button, Drawer, Space, message } from '@shipmnts/pixel-hub';
import React, { useEffect } from 'react';
import ManifestForm from './ManifestForm';
import { Form, CustomIcon } from '@shipmnts/pixel-hub';
import { useShipmentDetail } from '../ShipmentDetailLayout';
import { useMutation } from '@apollo/client';
import { CREATE_MANIFEST } from './graphql';
import { ShipmentManifestValue } from 'operations/models/ShipmentManifest';
import { omit } from 'lodash';
import { convertDatesTounix } from '@shipmnts/pixel-hub';
import { UPDATE_MANIFEST_DETAILS } from 'operations/graphql/shipmentManifest';

type Props = {
  type: 'CREATE' | 'UPDATE';
  onClose: () => void;
  open?: boolean;
  shipmentManifest?: ShipmentManifestValue;
  onSuccess?: () => void;
};

const { useForm } = Form;
const CreateUpdateManifest = (props: Props) => {
  // Contexts
  const [form] = useForm();
  const { shipment } = useShipmentDetail();

  // Queries and Mutations
  const [createManifest, { data, loading, error }] = useMutation(CREATE_MANIFEST);
  const [
    updateManifest,
    { data: updateData, loading: updatemanifestLoading, error: updateManifestError },
  ] = useMutation(UPDATE_MANIFEST_DETAILS);

  // Constants and Functions
  const { type, shipmentManifest } = props;
  const handleSubmit = (value: any) => {
    const location_id = value?.location?.id;
    const location_type = value?.location?.type?.includes('port')
      ? 'port'
      : value?.location?.type?.includes('ICD')
      ? 'icd'
      : undefined;
    if (type === 'CREATE') {
      createManifest({
        variables: {
          shipment_id: shipment?.id,
          shipment_manifest: {
            trade_type: shipment?.trade_type,
            location_id,
            location_type,
            country: value?.location?.country_code,
            ...omit(value, ['location']),
            ...convertDatesTounix(value, [
              'inward_date',
              'estimated_time_of_arrival',
              'manifest_date',
            ]),
          },
        },
      });
    } else if (type === 'UPDATE' && shipmentManifest) {
      updateManifest({
        variables: {
          id: shipmentManifest.id,
          shipment_manifest: {
            location_id,
            location_type,
            country: value?.location?.country_code,
            ...omit(value, ['location']),
            ...convertDatesTounix(value, [
              'inward_date',
              'estimated_time_of_arrival',
              'manifest_date',
            ]),
          },
        },
      });
    }
  };

  // Effects
  useEffect(() => {
    if (error) {
      message.error('Error occured');
    }
  }, [error]);
  useEffect(() => {
    if ((data || updateData) && !error && !updateManifestError) {
      if (props.onSuccess) props.onSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, updateData, updateManifestError]);

  return (
    <div>
      <Drawer
        title={
          <div style={{ display: 'flex' }}>
            <CustomIcon icon="CompassIcon" height={24} width={24} style={{ paddingRight: '5px' }} />
            {type === 'CREATE'
              ? `Create New Manifest Item for #${shipment?.job_number}`
              : `Update Manifest Details for ${
                  shipmentManifest?.document_number || shipment?.job_number
                }`}
          </div>
        }
        size={'large'}
        onClose={props.onClose}
        open={props.open}
        styles={{ body: { paddingBottom: 80 } }}
        footer={
          <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
            <Button onClick={props.onClose}>Cancel</Button>
            <Button
              type="primary"
              htmlType="submit"
              onClick={form.submit}
              loading={loading || updatemanifestLoading}
              disabled={loading || updatemanifestLoading}
            >
              {type === 'CREATE' ? 'Create' : 'Update'} Manifest
            </Button>
          </Space>
        }
        className="inquiry-form-drawer"
        destroyOnClose={true}
        rootClassName="inquiry-form-drawer"
      >
        <ManifestForm
          onSubmit={handleSubmit}
          form={form}
          shipmentManifest={shipmentManifest}
          shipment={shipment}
        />
      </Drawer>
    </div>
  );
};

export default CreateUpdateManifest;
