import React, { useContext, useEffect, useRef, useState } from 'react';
import '../style.css';

import { useQuery } from '@apollo/client';
import { Divider, Layout, Tabs, AppHelmet } from '@shipmnts/pixel-hub';

import { get } from 'lodash';
import { useParams } from 'wouter';

import InquiryOptionTable from 'sales_hub/components/InquiryOption/InquiryOptionTable';
import { GET_INQUIRY_FROM_GLOBAL_ID } from 'sales_hub/graphql/inquiry';
import Inquiry, {
  INQUIRY_CANCELED_STATUS,
  INQUIRY_WON_STATUS,
  InquiryValue,
  INQUIRY_LOST_STATUS,
} from 'sales_hub/models/inquiry';

import EditInquiryDrawer from '../EditInquiryDrawer';

import DocumentTab from './DocumentTab';
import InquiryDetailHeader from './InquiryDetailHeader';
import InquirySummary from './InquirySummary';
import Spinner from 'src/components/Spinner';

type Props = {
  id?: string; // in case of quick view we need id in props to fetch data
  externalLink?: boolean;
};

interface InquiryDetailContextProps {
  inquiry_global_id?: string;
  inquiry: InquiryValue;
  refetchInquiry: () => void;
}
const InquiryDetailContext = React.createContext<Partial<InquiryDetailContextProps>>({});
export const useInquiryDetail = () => useContext(InquiryDetailContext);

const InquiryDetailLayout = (props: Props) => {
  const params = useParams<{ id: string }>();
  const id = props.id || params.id;

  // States
  const [activeTab] = useState<''>('');
  const [showFormDrawer, setShowFormDrawer] = useState<boolean>(false);

  // Refs
  const summaryRef = useRef<null | HTMLDivElement>(null);

  // Queries and Mutations
  const { data, error, loading, refetch } = useQuery(GET_INQUIRY_FROM_GLOBAL_ID, {
    variables: { id: id },
    fetchPolicy: 'no-cache',
  });

  // Effects
  // Scroll to tab content on tab change
  useEffect(() => {
    const height = summaryRef?.current?.scrollHeight;
    if (height) window.scrollBy(0, height);
  }, [activeTab]);

  // handle loading
  if (loading) {
    return (
      <div className="centered fullscreen">
        <Spinner tip="Fetching RFQ details..." />
      </div>
    );
  }
  if (error) {
    // Todo: error components
    return <div>error occured</div>;
  }

  // Constants and Methods
  const inquiryDetails = Inquiry.create(get(data, 'get_inquiry_from_global_id'));
  const items = [
    {
      key: 'quotation',
      label: 'Quotation',
      children: (
        <InquiryOptionTable
          disableResource={[
            INQUIRY_CANCELED_STATUS,
            INQUIRY_LOST_STATUS,
            INQUIRY_WON_STATUS,
          ]?.includes(inquiryDetails?.last_action_status || '')}
          refetchInquiry={refetch}
          inquiry={inquiryDetails}
        />
      ),
    },
    {
      key: 'documents',
      label: 'Documents',
      children: <DocumentTab />,
    },
  ];
  const { Content } = Layout;
  return (
    <div style={{ height: '94vh', overflowY: 'scroll' }}>
      <InquiryDetailContext.Provider
        value={{
          inquiry: inquiryDetails,
          refetchInquiry: refetch,
          inquiry_global_id: id,
        }}
      >
        <AppHelmet
          titleTemplate={`#${inquiryDetails?.inquiry_number || ''} | RFQ | Shipmnts [Sales Hub]`}
        />
        <Layout style={{ paddingBottom: '24px' }}>
          <InquiryDetailHeader
            setShowFormDrawer={setShowFormDrawer}
            externalLink={props.externalLink}
          />
          <Content style={{ padding: '16px 24px' }}>
            <div ref={summaryRef}>
              <InquirySummary />
              <Divider style={{ marginBottom: '0px' }} />
              <Tabs defaultActiveKey="quotation" items={items} />
            </div>
          </Content>
        </Layout>
        {showFormDrawer && (
          <EditInquiryDrawer
            isVisible={showFormDrawer}
            inquiry={inquiryDetails}
            setIsVisible={setShowFormDrawer}
            onSuccess={refetch}
          />
        )}
      </InquiryDetailContext.Provider>
    </div>
  );
};

export default InquiryDetailLayout;
