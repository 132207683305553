import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import { Button, Drawer, Space, Typography } from '@shipmnts/pixel-hub';
import ChangeEventsTimeline from '../AttachedHouse/ChangeEventTimeline';
import { ShipmentValue } from 'operations/models/Shipment';
import { useSession } from 'common';
import { MailOutlined } from '@shipmnts/pixel-hub';
import { CREDIT_STATUS_PENDING } from 'operations/modules/shipment/constants';
import { RecentEventEmailDrawer } from 'operations/components/ChangeEvent/EventEmailDrawer';
import Spinner from 'src/components/Spinner';

const { Text } = Typography;
export const FETCH_CREDIT_EVENTS = gql`
  query shipment($id: ID!) {
    shipment(id: $id) {
      id
      credit_events {
        id
        event_by {
          id
          first_name
          last_name
        }
        event_date
        name
        event_data
        event_type
      }
    }
  }
`;

interface ShowCreditTrackingProps {
  shipment: ShipmentValue;
  onClose: () => void;
}

const ShipmentCreditStatusTimeLine = React.memo(function ShipmentCreditStatusTimeLine(
  props: ShowCreditTrackingProps
) {
  const { onClose, shipment } = props;
  const [showEmail, setShowEmail] = useState(false);
  const shipmentId = shipment.id;
  const sessionData = useSession();

  const { error, loading, data, refetch } = useQuery(FETCH_CREDIT_EVENTS, {
    variables: { id: shipment.id },
  });

  if (!data && error) {
    return (
      <div className="align-center">
        Unable to fetch timeline. &nbsp;
        <Button type="link" onClick={() => refetch()}>
          Retry
        </Button>
      </div>
    );
  }

  const events = data?.shipment?.credit_events;

  if (loading)
    return (
      <Drawer open={true}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Spinner size="small" tip="Fetching Timeline" />;
        </div>
      </Drawer>
    );

  if (events && events.length > 0)
    return (
      <Drawer
        title={'Credit Timeline'}
        open={true}
        onClose={onClose}
        footer={
          <>
            {CREDIT_STATUS_PENDING !== shipment.credit_status && (
              <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
                <Button
                  icon={<MailOutlined />}
                  onClick={() => {
                    setShowEmail(true);
                  }}
                >
                  Send Email Notification
                  {showEmail && (
                    <RecentEventEmailDrawer
                      companies_roles_mapping={shipment.getAllPartiesRolesMapping(sessionData)}
                      setIsFirstLoad={setShowEmail}
                      id={shipmentId}
                    />
                  )}
                </Button>
              </Space>
            )}
          </>
        }
      >
        <ChangeEventsTimeline change_events={events} />
      </Drawer>
    );
  return (
    <Drawer open={true} onClose={onClose}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Text style={{ textAlign: 'center' }} type="danger">
          No Events to Display
        </Text>{' '}
      </div>
    </Drawer>
  );
});

export default ShipmentCreditStatusTimeLine;
