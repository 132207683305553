import React, { useEffect } from 'react';
import { Form, FormInstance } from '@shipmnts/pixel-hub';
import { BOOKING_TYPE_SHIPPING_LINE } from 'operations/baseConstants';
import {
  ROUTING_TYPE_MAIN_CARRIAGE,
  getCarriageWiseRoutingLegs,
  hasPrecarriageLeg,
} from 'operations/models/RoutingLeg';
import BookingOrderConfirmation from 'operations/modules/booking/components/BookingOrderForm/BookingOrderConfirmation';
import { getDisbaledCutoffDateMapping } from 'operations/modules/booking/helpers/CutoffDateHelper';

interface BookingConfirmationDetailsProps {
  form: FormInstance;
}
export default function BookingConfirmationDetails(props: BookingConfirmationDetailsProps) {
  const { form } = props;
  const bookingType = Form.useWatch('booking_type', form);
  const loadType = Form.useWatch('load_type', form);

  useEffect(() => {
    form.setFieldValue('confirmed_booking', true);
  }, [form]);

  return (
    <>
      <Form.Item noStyle name="voyage_schedule_id">
        <div />
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.confirmed_booking !== currentValues.confirmed_booking ||
          prevValues.routing_details !== currentValues.routing_details
        }
      >
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.confirmed_booking !== currentValues.confirmed_booking ||
            prevValues.routing_details !== currentValues.routing_details
          }
        >
          {({ getFieldValue }) => {
            const confirmed_booking = getFieldValue('confirmed_booking');
            const routing_legs = getFieldValue('routing_details')?.routing_legs;
            const carriageWiseRoutingLegs = routing_legs
              ? getCarriageWiseRoutingLegs(routing_legs)
              : {};
            const etd =
              carriageWiseRoutingLegs?.[ROUTING_TYPE_MAIN_CARRIAGE]?.[0]
                ?.estimated_time_of_departure;
            const voyage_schedule_id = getFieldValue('voyage_schedule_id');
            const hasPrecarriage = hasPrecarriageLeg(routing_legs);

            return (
              <BookingOrderConfirmation
                etd={etd}
                areCutoffRequired={confirmed_booking && bookingType === BOOKING_TYPE_SHIPPING_LINE}
                disableCutoff={getDisbaledCutoffDateMapping(
                  Boolean(voyage_schedule_id),
                  hasPrecarriage
                )}
                load_type={loadType}
                stuffing_location_type={'cfs'}
                booking_type={bookingType}
              />
            );
          }}
        </Form.Item>
      </Form.Item>
    </>
  );
}
