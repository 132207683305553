import { types, Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree';
import { DOCUMENT_STATUS_PENDING_CREATION } from 'operations/modules/reports/constants';
import {
  CHARGE_AMOUNT_PREPAID,
  CHARGE_AMOUNT_COLLECT,
} from 'operations/modules/shipment/constants';

const ShipmentDocument = types
  .model({
    id: types.identifier,
    shipment_id: types.maybe(types.maybeNull(types.string)),
    shipment_number: types.maybe(types.maybeNull(types.string)),
    document_status: types.maybe(types.maybeNull(types.string)),
    eawb_status: types.maybe(types.maybeNull(types.string)),
    document_type: types.maybe(types.maybeNull(types.string)),
    document_id: types.maybe(types.maybeNull(types.string)),
    release_type: types.maybe(types.maybeNull(types.string)),
    charge_terms: types.maybe(
      types.maybeNull(
        types.model({
          freight_terms: types.maybe(
            types.maybeNull(
              types.union(
                types.literal(CHARGE_AMOUNT_PREPAID),
                types.literal(CHARGE_AMOUNT_COLLECT)
              )
            )
          ),
          other_charges_terms: types.maybe(
            types.maybeNull(
              types.union(
                types.literal(CHARGE_AMOUNT_PREPAID),
                types.literal(CHARGE_AMOUNT_COLLECT)
              )
            )
          ),
        })
      )
    ),
    stock_order_item: types.maybe(
      types.maybeNull(
        types.model({
          id: types.identifier,
        })
      )
    ),
    si_filing_date: types.maybe(types.maybeNull(types.number)),
    bl_type: types.maybe(types.maybeNull(types.string)),
    shipment_date: types.maybe(types.maybeNull(types.number)),
    shipper_on_document: types.maybe(
      types.maybeNull(
        types.enumeration([
          'shipper',
          'consignee',
          'origin_agent',
          'destination_agent',
          'to_order_bank',
        ])
      )
    ),
    consignee_on_document: types.maybe(
      types.maybeNull(
        types.enumeration([
          'shipper',
          'consignee',
          'origin_agent',
          'destination_agent',
          'to_order_bank',
        ])
      )
    ),
  })
  .actions((shipment_document) => ({
    handleDeleteGeneratedDocument: function () {
      shipment_document.document_id = null;
      shipment_document.document_status = DOCUMENT_STATUS_PENDING_CREATION;
    },
  }));

export type ShipmentDocumentValue = Instance<typeof ShipmentDocument>;
export type ShipmentDocumentIn = SnapshotIn<typeof ShipmentDocument>;
export type ShipmentDocumentOut = SnapshotOut<typeof ShipmentDocument>;

export default ShipmentDocument;
