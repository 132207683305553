import { message, Modal, dayjs } from '@shipmnts/pixel-hub';
import React, { useEffect } from 'react';
import { QuestionCircleOutlined } from '@shipmnts/pixel-hub';
import { useMutation } from '@apollo/client';
import { UPDATE_FREIGHT_CONTRACT } from './graphql/freightContract';
import { FIELD_VALID_TILL } from './constant';
interface ExpireNowModalProps {
  onClose?: () => void;
  freight_contract: any;
}
export default function ExpireNowModal(props: ExpireNowModalProps) {
  const { onClose, freight_contract } = props;
  const [fcUpdate, { data, loading, error }] = useMutation(UPDATE_FREIGHT_CONTRACT);

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
    if (data?.update_freight_contract) {
      message.success('Validity Updated Successfully');
      if (onClose) onClose();
    }
  }, [data, loading, error, onClose]);

  const handleSubmit = () => {
    fcUpdate({
      variables: {
        id: freight_contract.id,
        freight_contract: {
          [FIELD_VALID_TILL]: dayjs().unix(),
        },
      },
    });
  };

  return (
    <Modal
      okText="Yes"
      onOk={handleSubmit}
      onCancel={onClose}
      width="416px"
      destroyOnClose
      open={true}
      style={{ padding: '5px' }}
    >
      <QuestionCircleOutlined />{' '}
      <span className="ant-modal-confirm-title">Do you want to expire contract now?</span>
    </Modal>
  );
}
