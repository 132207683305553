import { get as _get } from 'lodash';
import { dayjs } from '@shipmnts/pixel-hub';
import { erpNextAxiosCall } from './utils/erpnextApiHelper';

export const getArrayStrings = (arr) => {
  return arr.map((a) => `"${a}"`).join(',');
};

const quotationFields = [
  'name',
  'creation',
  'title',
  'customer_name',
  'valid_till',
  'cost_center',
  'docstatus',
];
const quotationItemFields = [
  'item_code',
  'qty',
  'uom',
  'rate_in_item_currency',
  'exchange_rate',
  'item_currency',
  'idx',
  'parent',
  'shipment_tags',
];

const quotationFilters = ({ selectedBranches, searchTerm, selectedCustomer }) => {
  let filters = [
    ` ["Quotation", "valid_till", ">=", "${dayjs().format('YYYY-MM-DD')}"] `,
    ` ["Quotation", "docstatus", "<=", "2"] `,
  ];
  if (selectedBranches.length) {
    filters.push(` ["Quotation", "cost_center", "in", [${getArrayStrings(selectedBranches)}]] `);
  }

  if (selectedCustomer)
    filters.push(` ["Quotation", "customer_name", "=", "${selectedCustomer}"] `);
  return `[ ${filters.join(',')} ]`;
};

const quotationOrFilters = ({ searchTerm }) => {
  let filters = [];
  if (searchTerm) {
    filters.push(` ["Quotation", "title", "like", "%${searchTerm}%"] `);
    filters.push(` ["Quotation", "name", "like", "%${searchTerm}%"] `);
  }
  return `[ ${filters.join(',')} ]`;
};

const prepareResponse = (result) => {
  const { response, error, status } = result;
  return { data: _get(response, 'data', {}), error, status };
};

export const fetchErpNextQuotations = async (filters) => {
  const url = `/api/resource/Quotation`;

  try {
    const result = await erpNextAxiosCall({
      action: 'get',
      url,
      params: {
        fields: JSON.stringify(quotationFields),
        filters: quotationFilters(filters),
        or_filters: quotationOrFilters(filters),
        limit_page_length: 20,
      },
    });
    return prepareResponse(result);
  } catch (error) {
    return { data: {}, error, status: null };
  }
};

export const fetchErpNextQuotationItems = async (quotations) => {
  if (!quotations) throw new Error('invalid params');
  const filters = `[["Quotation Item", "parent", "in", [${getArrayStrings(quotations)}]]]`;
  const url = `/api/resource/Quotation Item?parent=Quotation`;

  try {
    const result = await erpNextAxiosCall({
      action: 'get',
      url,
      params: {
        fields: JSON.stringify(quotationItemFields),
        filters,
        limit_page_length: 200,
      },
    });

    return prepareResponse(result);
  } catch (error) {
    return { data: {}, error };
  }
};

export const fetchExchangeRate = async (from_currency, to_currency) => {
  const url = `/api/method/erpnext.setup.utils.get_exchange_rate`;
  const response = await erpNextAxiosCall({
    action: 'get',
    url,
    params: { from_currency, to_currency },
  });
  if (response) return response.response;
  return response;
};

export const fetchDocTypeListApi = async (type, options = { fields: '["name"]' }) => {
  try {
    const url = `/api/resource/${type}`;
    const { fields, filters } = options;
    const { response, error, status } = await erpNextAxiosCall({
      action: 'get',
      url,
      params: { fields, filters },
    });
    if (error) throw error;
    return { response, status };
  } catch (error) {
    console.error(error);
    return { error: error.message };
  }
};

export async function fetchErpNextConfigCompanyData() {
  try {
    const { response, error, status } = await erpNextAxiosCall({
      action: 'get',
      url: `api/method/shipmnts.custom_script.custom_company.custom_company.get_config_data`,
    });
    if (error) throw error;
    return { response, status };
  } catch (error) {
    console.error(error);
    return { error: error.message };
  }
}

export async function fetchErpNextConfigData() {
  try {
    const { response, error, status } = await erpNextAxiosCall({
      action: 'get',
      url: `/api/method/shipmnts.custom_script.custom_company.custom_company.get_network_config_data`,
    });
    if (error) throw error;
    return { response, status };
  } catch (error) {
    console.error(error);
    return { error: error.message };
  }
}

export async function getERPNextCompanyData(company_name) {
  try {
    const { response, error, status } = await erpNextAxiosCall({
      action: 'get',
      url: `/api/method/shipmnts.controllers.network_company.get_company`,
      params: { company_name },
    });
    if (error) throw error;
    return { response, status };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getERPNextAddressData(address_name) {
  try {
    const { response, error, status } = await erpNextAxiosCall({
      action: 'get',
      url: `/api/method/shipmnts.controllers.network_company.get_address`,
      params: { address_name },
    });
    if (error) throw error;
    return { response, status };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export const fetchEstimatestByJobNumbers = async (jobNumbers) => {
  try {
    if (!jobNumbers) throw new Error('invalid params');
    const url = `/api/method/shipmnts.controllers.shipment_estimates.get_project_estimates`;
    const { response, error, status } = await erpNextAxiosCall({
      action: 'get',
      url,
      params: { params: { job_numbers: jobNumbers } },
    });
    if (error) throw error;
    return { response, status };
  } catch (error) {
    console.error(error);
    return { error };
  }
};

export const fetchAccountingDocuments = async (job_numbers, party) => {
  try {
    if (!job_numbers) throw new Error('invalid params');
    const url = `/api/method/shipmnts.controllers.invoice.get_job_invoice_attachments_details`;
    const { response, error, status } = await erpNextAxiosCall({
      action: 'get',
      url,
      params: {
        filters: JSON.stringify({
          job_numbers: job_numbers,
          party: party,
        }),
      },
    });
    if (error) throw error;
    return { response, status };
  } catch (error) {
    console.error(error);
    return { error };
  }
};

export const mapJVToEstimate = async (jobNumbers) => {
  const { response, error } = await fetchEstimatestByJobNumbers(jobNumbers);
  let estimates = [];
  let errors = [];
  if (error) {
    return { estimates: [], errors: [error] };
  }
  estimates = _get(response, 'data.message', []).map((est, _index) => {
    let estimate = {
      item: est.item,
      id: _get(est, 'id'),
      shipment_id: est.shipment_id,
      quantity: _get(est, 'quantity'),
      sell_terms: _get(est, 'sell_terms', 'prepaid'),
      buy_terms: _get(est, 'buy_terms', 'prepaid'),
      uom: _get(est, 'uom'),

      supplier: _get(est, 'supplier'),
      customer: _get(est, 'customer'),
      tag: _get(est, 'tag', null),

      sell_rate: _get(est, 'sell_rate'),
      sell_exchange_rate: _returnFloatValue(_get(est, 'sell_exchange_rate', 1)),
      sell_currency: _get(est, 'sell_currency'),
      total_sell_billed_amount: _returnFloatValue(_get(est, 'total_sell_billed_amount')),
      sell_transaction_identifier: _get(est, 'sell_transaction_identifier', null),
      sell_transaction_doctype: _get(est, 'sell_transaction_doctype', null),
      sell_status: _get(est, 'sell_status'),
      sell_cost_center: _get(est, 'sell_cost_center'),
      sell_branch_id: _get(est, 'sell_branch_id'),

      buy_rate: _get(est, 'buy_rate'),
      buy_exchange_rate: _get(est, 'buy_exchange_rate', 1),
      buy_currency: _get(est, 'buy_currency'),
      buy_transaction_identifier: _get(est, 'buy_transaction_identifier', null),
      buy_transaction_doctype: _get(est, 'buy_transaction_doctype', null),
      buy_cost_center: _get(est, 'buy_cost_center'),
      buy_branch_id: _get(est, 'buy_branch_id'),
      buy_status: _get(est, 'buy_status'),
      total_buy_billed_amount: _get(est, 'total_buy_billed_amount'),
      remarks: _get(est, 'remark', null),
      sell_remarks: _get(est, 'sell_remarks', null),
    };
    return estimate;
  });
  return { estimates, errors };
};

function _returnFloatValue(number) {
  switch (typeof number) {
    case 'string':
      if (isNaN(number)) return 0;
      return parseFloat(number);
    case 'number':
      return number;
    default:
      return 0;
  }
}
