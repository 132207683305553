import { Divider, Space, hasPermission } from '@shipmnts/pixel-hub';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { PERMISSION_ESTIMATES_TOTAL } from 'common/constants/constant';
import { useSession, decimalPointFormater } from 'common';

const TotalsHeadingStyles = { fontWeight: 'bold' };
const TotalsValueStyles = {
  fontSize: '18px',
};
interface ShipmentEstimatesTotalProps {
  useStore: () => any;
}

const ShipmentEstimatesTotal = observer(function ShipmentEstimatesTotal(
  props: ShipmentEstimatesTotalProps
) {
  const { permissions } = useSession();
  if (!props.useStore) return null;
  const { store } = props.useStore();
  const estimateTotalsAllowed = hasPermission(permissions, {
    name: PERMISSION_ESTIMATES_TOTAL,
    docType: 'NewAccounting::ShipmentEstimate',
  });
  const totalMargin = store.totalProvisionalSellAmount - store.totalProvisionalBuyAmount;

  if (!estimateTotalsAllowed) return null;

  return (
    <Space split={<Divider type="vertical" />}>
      <Space>
        <div style={TotalsHeadingStyles}>Prov. Income</div>
        <div style={TotalsValueStyles}>
          {decimalPointFormater(store.totalProvisionalSellAmount)}
        </div>
      </Space>
      <Space>
        <div style={TotalsHeadingStyles}>Prov. Expense</div>
        <div style={TotalsValueStyles}>{decimalPointFormater(store.totalProvisionalBuyAmount)}</div>
      </Space>
      <Space>
        <div style={TotalsHeadingStyles}>Prov. Margin</div>
        <div
          style={{
            ...TotalsValueStyles,
            color: totalMargin > 0 ? '#237804' : '#f00',
          }}
        >
          {decimalPointFormater(totalMargin)}
        </div>
      </Space>
    </Space>
  );
});

export default ShipmentEstimatesTotal;
