import React, { MutableRefObject, useCallback, useEffect, useState } from 'react';
import { isFirstColumn } from 'operations/modules/reports/reportCommonFields';
// TODO: replace AgGridReact with Basetable
import { ContainerReportData } from 'operations/modules/reports/reportModels';
import { GridOptions, SelectionChangedEvent } from '@ag-grid-community/core';
import { getContainerSetting } from 'operations/modules/reports/oldCellRenderers';
import { Column } from 'operations/models/Report';
import { pick as _pick } from 'lodash';
import { BaseTable } from '@shipmnts/pixel-hub';
const fields: { [key: string]: Column } = {
  container_number: {
    headerName: 'Container Number',
    field: 'container_number',
    colId: 'container_number',
    filter: 'agTextColumnFilter',
    width: 160,
    pinned: 'left',
  },
  container_type: {
    headerName: 'Container Type',
    field: 'container_type',
    colId: 'container_type',
    filter: 'agTextColumnFilter',
    width: 150,
  },
  booking_number: { headerName: 'Booking #', field: 'booking_number', minWidth: 120 },
  container_settings: {
    headerName: 'Container Settings',
    field: 'container_settings',
    minWidth: 120,
  },
  pickup_date: { headerName: 'Pickup Date', field: 'pickup_date', minWidth: 120 },
  last_action_status: {
    headerName: 'Status',
    field: 'last_action_status',
    colId: 'last_action_status',
    pinned: 'right',
    width: 165,
    filter: 'agSetColumnFilter',
  },
  seal_number: {
    headerName: 'Seal #',
    field: 'seal_number',
    colId: 'seal_number',
    minWidth: 100,
  },
  total_gross_weight: {
    headerName: 'Gross Wt. (Kgs)',
    field: 'total_gross_weight',
    colId: 'total_gross_weight',
    minWidth: 100,
  },
  order_number: {
    headerName: 'Order #',
    field: 'order_number',
    colId: 'order_number',
    minWidth: 100,
  },
};

export const renderContainerData = (containers: ContainerReportData[]) => {
  const container_data: any[] = [];
  (containers || []).forEach((sc) => {
    if (sc) {
      const sc_settings = getContainerSetting({
        container_settings: sc?.container_settings,
        container_type_code: sc?.container_type_code,
      });
      container_data.push({
        container_number: sc.container_number,
        container_type: sc.container_type,
        container_settings: sc_settings,
        id: sc.id,
        last_action_status: sc.last_action_status,
        booking_number: sc.booking_number,
        order_number: sc?.booking_request?.shipment_booking_number || sc?.order_number,
        total_gross_weight: sc.cargo_gross_weight || 0,
        seal_number: sc?.carrier_seal_number,
      });
    }
  });
  return container_data;
};

const ContainerGrid = React.memo(function ContainerGrid(props: {
  tableHeading: string;
  containers: ContainerReportData[] | undefined;
  columnNames: string[];
  gridRef: MutableRefObject<GridOptions | undefined>;
  onSelectionChanged: () => void;
  renderFormatedData?: boolean;
}): JSX.Element {
  const { tableHeading, containers, columnNames, gridRef, onSelectionChanged, renderFormatedData } =
    props;

  const columns = [...Object.values(_pick(fields, columnNames))];
  const [rowData, setRowData] = useState<any>();

  const onSelectionChange = useCallback(
    (event: SelectionChangedEvent) => {
      if (onSelectionChanged) onSelectionChanged();
    },
    [onSelectionChanged]
  );

  useEffect(() => {
    if (containers) {
      if (renderFormatedData) {
        setRowData(renderContainerData(containers));
      } else {
        setRowData(containers);
      }
    }
  }, [containers, gridRef, renderFormatedData]);

  return (
    <>
      <p style={{ fontWeight: 500 }}>{tableHeading}</p>
      <div
        id="myGrid"
        style={{
          height: 360,
          width: '100%',
        }}
        className="ag-theme-material  custom-filter-style"
      >
        <BaseTable
          reportName="container_grid_render"
          columns={columns}
          rowSelection="multiple"
          reportConfig={{
            rowHeight: 36,
            rowMultiSelectWithClick: true,
            defaultColDef: {
              resizable: true,
              // checkboxSelection: isFirstColumn,
              headerCheckboxSelection: isFirstColumn,
            },
          }}
          gridRef={gridRef}
          rowData={rowData}
          onSelectionChanged={(params: any) => {
            onSelectionChange && onSelectionChange(params);
          }}
        />
      </div>
    </>
  );
});

export default ContainerGrid;
