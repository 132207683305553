import React from 'react';
import { Row, Col, Form } from '@shipmnts/pixel-hub';
import { CompanySearch } from 'common';

interface MiscellaneousServiceFormProps {
  field_name?: Array<string | number>;
  name: string;
  colSpan?: number;
}

const MiscellaneousServiceForm = React.memo(function MiscellaneousServiceForm(
  props: MiscellaneousServiceFormProps
): JSX.Element {
  const { field_name = [], name, colSpan } = props;
  return (
    <Row gutter={16}>
      <Form.Item noStyle name={[...field_name, 'name']} initialValue={name}>
        <span />
      </Form.Item>
      <Col span={colSpan || 18}>
        <Form.Item
          required
          rules={[{ required: true }]}
          label="Provider"
          name={[...field_name, 'vendor', 'party_company']}
        >
          <CompanySearch searchProps={{ is_vendor: true }} />
        </Form.Item>
      </Col>
    </Row>
  );
});

export default MiscellaneousServiceForm;
