import { Button, Card, Checkbox, FormInstance, Layout, PageHeader } from '@shipmnts/pixel-hub';
import React, { useState } from 'react';

const { Header, Content } = Layout;

interface CreateShipmentProps {
  hideHeaderFooter?: boolean;
  form?: FormInstance;
  formSubTitle?: string;
  hideComponents?: any;
  components?: any[];
  formTitle?: string | React.ReactNode;
  formOnSubmitText?: string;
  type?: string;
  loading?: boolean;
  onBack?: () => void;
  sendEmailProps?: {
    label: string;
    sendNotification: boolean;
    setSendNotification: (sendNotification: boolean) => void;
  };
}

interface ComponentMapReturnType {
  title: string;
  component: any;
  props?: any;
  isVisible: boolean;
  isAccordian: boolean;
}

const CollapsibleForm = (props: { componentDetail: ComponentMapReturnType }) => {
  const { componentDetail } = props;
  const Komponent = componentDetail.component;
  const componentTitle = componentDetail.title;
  const [show, setShow] = useState(false);
  return (
    <Card
      style={{ marginBottom: '10px' }}
      title={
        <span>
          <Checkbox
            checked={show}
            onChange={(e) => {
              setShow(e.target.checked);
            }}
            style={{ marginRight: '5px' }}
          />{' '}
          {componentTitle}
        </span>
      }
    >
      {Komponent && show && <Komponent {...componentDetail.props} />}
    </Card>
  );
};
export default function FormLayout(props: CreateShipmentProps) {
  const {
    formTitle,
    hideHeaderFooter = false,
    formSubTitle,
    form,
    components,
    formOnSubmitText,
    loading,
    onBack,
    sendEmailProps,
  } = props;

  return (
    <Layout className="form-layout">
      {!hideHeaderFooter && (
        <>
          <Header
            style={{
              padding: 0,
              background: '#fff',
              height: 'auto',
              position: 'sticky',
              top: 42,
              zIndex: 1,
              width: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <PageHeader
              style={{ padding: '10px 0px', width: '100%' }}
              onBack={onBack}
              title={<>{formTitle}</>}
              subTitle={formSubTitle}
              extra={[
                sendEmailProps && (
                  <Checkbox
                    checked={sendEmailProps.sendNotification}
                    onChange={(e) => sendEmailProps.setSendNotification(e.target.checked)}
                    key="send_notifications"
                  >
                    {sendEmailProps?.label}
                  </Checkbox>
                ),
                <Button
                  onClick={() => {
                    form?.submit();
                  }}
                  // htmlType="submit"
                  loading={loading}
                  type="primary"
                  key="save"
                >
                  {formOnSubmitText}
                </Button>,
              ]}
            />
          </Header>
        </>
      )}
      <Content>
        {(components || [])
          .filter((comp: ComponentMapReturnType) => comp.isVisible)
          .map((componentDetail: ComponentMapReturnType, idx: number) => {
            const Komponent = componentDetail.component;
            const componentTitle = componentDetail.title;
            const isAccordian = componentDetail.isAccordian;
            if (isAccordian) {
              return <CollapsibleForm key={idx} componentDetail={componentDetail} />;
            } else {
              return (
                <>
                  <Card style={{ marginBottom: '10px' }} key={idx} title={componentTitle}>
                    {Komponent && <Komponent {...componentDetail.props} />}
                  </Card>
                </>
              );
            }
          })}
      </Content>
    </Layout>
  );
}
