import React from 'react';
import { Card, Row, Col, Input, Form, DatePicker } from '@shipmnts/pixel-hub';
import { AddressCompanySearch } from 'common';
import {
  BOOKING_TYPE_SHIPPING_LINE,
  BOOKING_TYPE_VENDOR,
  LOAD_TYPE_LCL,
  LOAD_TYPE_FCL,
  BOOKING_TYPE_SELF,
} from 'operations/baseConstants';
import { CarrierType } from 'operations/models/Carrier';
import BookingOrderNewDesign from './BookingOrderNewDesign';
import { TRADE_TYPE_IMPORT } from 'operations/modules/shipment/constants';
import { ShipmentValue } from 'operations/models/Shipment';
import { GlobalSearch } from '@shipmnts/pixel-hub';
const ROW_GUTTER = 16;

const BookingOrderBasicDetails = React.memo(function BookingOrderBasicDetails(props: {
  booking_type: string;
  disabledFields?: Array<string>;
  load_type?: string;
  areCutoffRequired?: boolean;
  disableEmptyPickupLocation?: boolean;
  shipment?: ShipmentValue;
}): JSX.Element {
  const {
    shipment,
    booking_type,
    disabledFields,
    load_type,
    areCutoffRequired,
    disableEmptyPickupLocation,
  } = props;
  const carrier_types: CarrierType[] = ['ocean', 'nvocc'];
  if (load_type === LOAD_TYPE_LCL) carrier_types.push('coloader');
  return (
    <Card title="Basic Details">
      <Row gutter={ROW_GUTTER}>
        <Col span={12}>
          <Form.Item
            required={booking_type === BOOKING_TYPE_SHIPPING_LINE || load_type === LOAD_TYPE_LCL}
            rules={[
              {
                required:
                  booking_type === BOOKING_TYPE_SHIPPING_LINE || load_type === LOAD_TYPE_LCL,
              },
            ]}
            name="carrier"
            label={load_type === LOAD_TYPE_LCL ? 'Carrier/Co-loader' : 'Carrier'}
          >
            <GlobalSearch
              doc_type="Global::Carrier"
              disabled={disabledFields?.includes('carrier')}
              searchProps={{ carrier_type: carrier_types }}
            />
          </Form.Item>
          {booking_type === BOOKING_TYPE_VENDOR && load_type === LOAD_TYPE_FCL && (
            <Form.Item name="vendor" required rules={[{ required: true }]} label="Booking Vendor">
              <AddressCompanySearch
                companySearchProps={{
                  searchProps: { is_vendor: true },
                }}
              />
            </Form.Item>
          )}
          <Form.Item required rules={[{ required: true }]} name="customer" label="Booking Party">
            <AddressCompanySearch companySearchProps={{ disabled: true }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.confirmed_booking !== currentValues.confirmed_booking
            }
          >
            {({ getFieldValue }) => {
              const confirmed_booking = getFieldValue('confirmed_booking');
              const required =
                (booking_type === BOOKING_TYPE_SHIPPING_LINE || load_type === LOAD_TYPE_LCL) &&
                confirmed_booking;
              if (booking_type === BOOKING_TYPE_SELF) return <></>;
              return (
                <Form.Item
                  required={required}
                  rules={[
                    {
                      required,
                    },
                  ]}
                  name="booking_number"
                  label="Booking number"
                >
                  <Input placeholder="Booking number" />
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item required rules={[{ required: true }]} name="booking_date" label="Booking date">
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item required rules={[{ required: true }]} name="sales_person" label="Order By">
            <GlobalSearch doc_type="Network::SalesPerson" />
          </Form.Item>
          {load_type === LOAD_TYPE_FCL && (
            <Form.Item name="contract_number" label="RA number">
              <Input placeholder="RA number" />
            </Form.Item>
          )}
        </Col>
      </Row>
      {shipment?.trade_type !== TRADE_TYPE_IMPORT && (
        <Row gutter={ROW_GUTTER}>
          <BookingOrderNewDesign
            bookingType={booking_type}
            loadType={load_type}
            colSpan={24 / 2}
            areCutoffRequired={areCutoffRequired}
            disableEmptyPickupLocation={disableEmptyPickupLocation}
          />
        </Row>
      )}
    </Card>
  );
});

export default BookingOrderBasicDetails;
