import React, { useEffect, useRef } from 'react';
import { GridOptions } from '@ag-grid-community/core';
import {
  Drawer,
  Row,
  Col,
  Form,
  message,
  GlobalViewLayout,
  DrawerFooter,
} from '@shipmnts/pixel-hub';
import { CompassOutlined } from '@ant-design/icons';
import { ShipmentValue } from 'operations/models/Shipment';
import { getGenericInitialValue } from 'operations/modules/orderManagement/CreateShipmentIMPEX/helpers';
import { useMutation } from '@apollo/client';
import { MERGE_SHIPMENT } from 'operations/graphql/shipment';

const getGlobalView = (shipment: ShipmentValue) => {
  const globalView: any = {
    id: '1',
    name: 'Merge Shipment',
    color: '#eeeeee',
    doc_type_id: 'Shipment::Shipment',
    definition: {
      sort: [],
      fields: [
        {
          id: shipment.shipment_type ? 'shipments_job_number' : 'shipments_shipment_booking_number',
          label: shipment.shipment_type ? 'Job Number' : 'Customer Order Number',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'String',
          filterable: true,
          cell_renderer: {
            args: {
              id_field: 'id',
              doc_type_id: 'Shipment::Shipment',
            },
            function: 'link_render',
          },
          filter_options: '{}',
          groupable: false,
        },
        {
          id: 'shipments_port_of_discharge_id',
          label: 'Port Of Discharge',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'Search',
          filterable: true,
          cell_renderer: { args: {}, function: 'location_render' },
          filter_options: '{ "doc_type": "Global::Location" }',
          groupable: true,
        },
        {
          id: 'shipments_shipment_type',
          label: 'Shipment Type',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'String',
          groupable: true,
        },
        {
          id: 'shipments_port_of_loading_id',
          label: 'Port Of Loading',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'Search',
          filterable: true,
          cell_renderer: { args: {}, function: 'location_render' },
          filter_options: '{ "doc_type": "Global::Location" }',
          groupable: true,
        },
        {
          id: 'analytics_shipment_reports_shipper_id',
          label: 'Shipper',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'Search',
          filterable: true,
          cell_renderer: { args: {}, function: 'record_loader_render' },
          filter_options: '{"doc_type":"Network::Company"}',
          groupable: true,
        },
        {
          id: 'analytics_shipment_reports_consignee_id',
          label: 'Consignee',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'Search',
          filterable: true,
          cell_renderer: { args: {}, function: 'record_loader_render' },
          filter_options: '{"doc_type":"Network::Company"}',
          groupable: true,
        },
        {
          id: 'shipments_carrier_id',
          label: 'Airline',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'Search',
          filterable: true,
          cell_renderer: { args: {}, function: 'record_loader_render' },
          filter_options: '{"doc_type":"Global::Carrier"}',
          groupable: true,
        },
        {
          id: 'shipments_job_date',
          label: 'Job Date',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'Date',
          filterable: true,
          cell_renderer: {},
          filter_options: '{}',
          groupable: true,
        },
        {
          id: 'shipments_customer_company_id',
          label: 'Customer',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'Search',
          filterable: true,
          cell_renderer: { args: {}, function: 'record_loader_render' },
          filter_options: '{"doc_type":"Network::Company"}',
          groupable: true,
        },
        {
          id: 'analytics_shipment_reports_origin_clearance_date',
          label: 'Origin Clearance Date',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'DateTime',
          filterable: true,
          cell_renderer: {},
          filter_options: '{}',
          groupable: true,
        },
        {
          id: 'shipments_port_of_discharge_id',
          label: 'Port Of Discharge',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'Search',
          filterable: true,
          cell_renderer: { args: {}, function: 'location_render' },
          filter_options: '{ "doc_type": "Global::Location" }',
          groupable: true,
        },
        {
          id: 'analytics_shipment_reports_last_leg_voyage_number',
          label: 'Flight Number',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'String',
          filterable: true,
          filter_options: '{}',
          groupable: false,
        },
        {
          id: 'shipments_estimated_time_of_departure',
          label: 'Flight Date',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'DateTime',
          filterable: true,
          cell_renderer: {},
          filter_options: '{}',
          groupable: true,
        },
      ],
      filters: [
        {
          field_id: 'shipments_port_of_discharge_id',
          condition: {
            type: 'in',
            values: [shipment?.port_of_discharge],
          },
        },
        {
          field_id: 'shipments_port_of_loading_id',
          condition: {
            type: 'in',
            values: [shipment?.port_of_loading],
          },
        },
        {
          field_id: 'shipments_customer_company_id',
          condition: {
            type: 'in',
            values: [shipment?.customer_company],
          },
        },
        {
          field_id: 'shipments_shipment_type',
          condition: shipment?.shipment_type
            ? {
                type: 'equals',
                value: shipment?.shipment_type,
              }
            : {
                type: 'isNull',
                value: null,
              },
        },
        {
          field_id: 'shipments_is_temp_controlled',
          condition: {
            type: 'in',
            values: [shipment?.shipment_properties?.['is_temp_controlled'] || false],
          },
        },
      ],
    },
    standard_report: true,
    is_disabled: false,
    global: true,
  };

  if (shipment?.carrier) {
    globalView.definition.filters.push({
      field_id: 'shipments_carrier_id',
      condition: {
        type: 'in',
        values: [shipment?.carrier],
      },
    });
  }
  if (shipment?.job_number) {
    globalView.definition.filters.push({
      field_id: 'shipments_job_number',
      condition: {
        type: 'notEqual',
        value: shipment?.job_number,
      },
    });
  } else if (shipment?.shipment_booking_number) {
    globalView.definition.filters.push({
      field_id: 'shipments_shipment_booking_number',
      condition: {
        type: 'notEqual',
        value: shipment?.shipment_booking_number,
      },
    });
  }
  return globalView;
};

const MergeShipment = React.memo(function CreateShipmentFromExportCustomerOrder(props: {
  shipment: ShipmentValue;
  onClose: () => void;
  onSuccess: () => void;
}): JSX.Element {
  const { shipment, onClose, onSuccess } = props;
  const globalView = useRef(getGlobalView(shipment));
  const gridRef = useRef<GridOptions>();
  const [form] = Form.useForm();

  const [mergeShipment, { data, error }] = useMutation(MERGE_SHIPMENT);

  useEffect(() => {
    if (error) {
      message.error(error.message);
    } else if (data?.merge_shipment?.message) {
      message.success('Shipment merged successfully');
      onClose();
      onSuccess();
    }
  }, [data, error, onClose, onSuccess]);

  useEffect(() => {
    form.setFieldsValue(getGenericInitialValue(shipment));
  }, [shipment, form]);

  const onSubmit = () => {
    const rowData = gridRef?.current?.api?.getSelectedNodes();
    const from_shipment_id = rowData?.[0]?.id;
    const to_shipment_id = shipment?.id;
    if (!from_shipment_id) {
      message.error('Please Select Shipment to Merge');
      return;
    }
    mergeShipment({
      variables: {
        from_shipment_id,
        to_shipment_id,
      },
    });
  };

  return (
    <Drawer
      placement="right"
      title={
        <div>
          <Row>
            <Col span={16}>
              <div
                style={{
                  color: '#353F52',
                  fontSize: '18px',
                  fontWeight: 500,
                  marginBottom: '10px',
                }}
              >
                <CompassOutlined />
                <span style={{ marginLeft: '5px' }}>
                  <b>Merge Shipment</b>
                </span>
              </div>
            </Col>
          </Row>
        </div>
      }
      onClose={onClose}
      open={true}
      headerStyle={{ padding: '16px 16px 0px 24px' }}
      width="80%"
    >
      <GlobalViewLayout
        gridRef={gridRef}
        doc_type_id={'Shipment::Shipment'}
        globalView={globalView.current}
        quick_filter_field_ids={[
          'analytics_shipment_reports_shipper_id',
          'analytics_shipment_reports_consignee_id',
          'shipments_carrier_id',
        ]}
        height="70vh"
        defaultFilters={[]}
        reportConfig={{
          rowSelection: 'single',
        }}
        searchPanelVisible={false}
      />
      <DrawerFooter
        saveText="Merge Shipment"
        closeText="Cancel"
        onClose={() => onClose && onClose()}
        onSave={onSubmit}
      />
    </Drawer>
  );
});

export default MergeShipment;
