import { get } from 'lodash';
import React, { forwardRef } from 'react';
import RenderDocFlowEvents from './RenderDockFlowEvents';
import { ApplicationPropsType } from '../../../types';
import { Tag } from '@shipmnts/pixel-hub';

const DockflowTracker = forwardRef<HTMLDivElement, ApplicationPropsType>(
  ({ container, app }, ref) => {
    const dockFlowLastStatus = get(container.tracking_statuses, 'dockflow_tracker', null);

    if (!dockFlowLastStatus) {
      return (
        <>
          <div className="activity-box terminal-tracking" ref={ref}>
            <div className="title" style={{ fontWeight: 600 }}>
              {app?.label}
            </div>
            <div style={{ fontWeight: 500, fontSize: '14px', margin: '10px 0px' }}>
              <Tag color="error">No Data is available for {container.container_number}.</Tag>
            </div>
          </div>
        </>
      );
    }

    return (
      <div className="activity-box terminal-tracking" ref={ref}>
        <div className="title" style={{ fontWeight: 600 }}>
          {app?.label}
          {dockFlowLastStatus?.status === 'inactive' && (
            <span style={{ marginLeft: '25px' }}>
              <Tag color={'#E1E5E8'}>Inactive</Tag>
            </span>
          )}
          {dockFlowLastStatus?.status === 'error' && (
            <span style={{ marginLeft: '25px' }}>
              <Tag color="red">Error Occured</Tag>
            </span>
          )}
          {dockFlowLastStatus?.status === 'disabled' && (
            <span style={{ marginLeft: '25px' }}>
              <Tag color="blue">Tracking Disabled</Tag>
            </span>
          )}
          {dockFlowLastStatus?.status === 'not_applicable' && (
            <span style={{ marginLeft: '25px' }}>
              <Tag color={'#E1E5E8'}>Not Applicable</Tag>
            </span>
          )}
        </div>
        <RenderDocFlowEvents container={container} dockFlowLastStatus={dockFlowLastStatus} />
      </div>
    );
  }
);

export default DockflowTracker;
DockflowTracker.displayName = 'DockflowTracker';
