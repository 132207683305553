import {
  Form,
  Button,
  DateEditor,
  StringEditor,
  FloatEditor,
  EnumEditor,
  SearchDocTypeEditor,
  RecordLoaderRender,
  BaseTable,
  DataImportModal,
} from '@shipmnts/pixel-hub';
import React, { useRef, useState, useEffect } from 'react';
import {
  CellClassParams,
  EditableCallbackParams,
  GridOptions,
  RowNode,
  ValueGetterParams,
  ValueSetterParams,
  NewValueParams,
} from '@ag-grid-community/core';
import { Column } from 'operations/models/Report';
import CargoProperties from 'operations/components/CargoProperties';
import PackageDetailsDrawerForm from 'operations/components/PackageDetailsDrawerForm';
import { FormInstance } from '@shipmnts/pixel-hub';
import {
  DIMENSION_CBM,
  DIMENSION_CMS,
  DIMENTION_INCH,
  LOAD_TYPE_FCL,
  LOAD_TYPE_FTL_BREAK_BULK,
  LOAD_TYPE_FTL_BULK,
  VOLUME_UNIT_CBM,
  WEIGHT_UNIT_MTS,
} from 'operations/baseConstants';
import { QtyUomTypeRenderer, VehicleLoadSearch } from 'common';
import { DISABLE_CELL_STYLE, RequireHeaderWrapper } from './helpers';
import { round } from 'lodash';
import { VehicleValue } from 'operations/models/Vehicle';
import allFields from './allFields';
import { calculateVolumetricWeight } from 'sales_hub';
import { ShipmentValue } from 'operations/models/Shipment';
import { CargoPropertyValue } from 'operations/models/Cargo';
import { ERROR_CELL_STYLE } from '../../helpers/FormHelpers';

interface CargoDetailsProps {
  form: FormInstance;
  gridRef?: React.MutableRefObject<GridOptions<any> | undefined>;
  load_type?: string;
  freightType?: string;
  showVehicleSearch?: boolean;
  vehicleSuggestions?: VehicleValue[];
  shipment?: ShipmentValue;
  isUpdate?: boolean;
  cargosDefault?: any[];
}

type Props = {
  rowIndex: number;
  freightType: string;
  node?: RowNode<any>;
  onChange?: (value: CargoPropertyValue) => void;
};

export const getRoundOffValue = (value: any, decimal = 4) => {
  if (!value) return 0;
  return round(value, decimal);
};

function NewCargoProperties({ rowIndex, freightType, onChange, node }: Props) {
  if (node && node.isRowPinned()) return <></>;
  return (
    <Form.Item name={['cargos', rowIndex, 'cargo_properties']}>
      <CargoProperties formKey={rowIndex} freightType={freightType} onChange={onChange} />
    </Form.Item>
  );
}

const dimensionConversion: { [key: string]: number } = {
  [DIMENSION_CMS]: 1,
  [DIMENTION_INCH]: 2.54,
};

function CargoTableForm(props: CargoDetailsProps) {
  const { form, load_type, showVehicleSearch, vehicleSuggestions, shipment, isUpdate } = props;

  let { gridRef } = props;

  const internalGridRef = useRef<GridOptions>();
  const [open, setOpen] = useState(false);
  if (!gridRef) {
    gridRef = internalGridRef;
  }

  const components = {
    DateEditor,
    CargoProperties: NewCargoProperties,
    PackageDetailsDrawerForm,
    StringEditor,
    FloatEditor,
    EnumEditor,
    QtyUomTypeRenderer: QtyUomTypeRenderer,
    SearchDocTypeEditor,
    RecordLoaderRender,
    VehicleLoadSearch,
  };
  const isLoose = load_type !== LOAD_TYPE_FCL;
  const [selectedRowsCount, setSelectedRowsCount] = useState(0);
  const [cargos, setCargos] = useState<any[]>([]);
  const [disableAddCargo, setDisableAddCargo] = useState(false);

  useEffect(() => {
    const loadType = form.getFieldValue('load_type');
    if (isUpdate) {
      if (loadType === LOAD_TYPE_FTL_BREAK_BULK) setDisableAddCargo(true);
    }

    setCargos(
      (form.getFieldValue('cargos') || [{}]).map((cargo: any) => {
        return {
          ...cargo,
          intial_total_packages: cargo.total_packages || 0,
          initial_gross_weight: cargo.gross_weight || 0,
          allocated_qty: isUpdate
            ? Math.max(
                0,
                (loadType === LOAD_TYPE_FTL_BREAK_BULK
                  ? cargo.total_packages
                  : loadType === LOAD_TYPE_FTL_BULK
                  ? cargo.gross_weight
                  : cargo.allocation_pending_quantity) - cargo.allocation_pending_quantity
              )
            : 0,
        };
      })
    );
  }, [isUpdate, form]);

  useEffect(() => {
    form.setFieldValue('cargos', cargos);
  }, [cargos, form]);

  const onCellValueChanged = (params: NewValueParams<any>) => {
    const cargos = form.getFieldValue('cargos');
    const ind = params?.node?.rowIndex || 0;
    cargos[ind] = params.data;
    form.setFieldValue('cargos', cargos);
  };

  const isEditablePackageField = (params: EditableCallbackParams<any>) => {
    if (params?.node?.isRowPinned()) return false;
    const cargoIndex = params?.node?.rowIndex;
    if (
      typeof cargoIndex === 'number' &&
      cargoIndex < cargos.length &&
      cargos[cargoIndex].shipment_packages?.length
    )
      return false;
    return true;
  };

  const cellStylePackageField = (params: CellClassParams<any, any>) => {
    if (params?.node?.isRowPinned()) return;
    const cargoIndex = params?.node?.rowIndex;
    if (
      typeof cargoIndex === 'number' &&
      cargoIndex < cargos.length &&
      cargos[cargoIndex].shipment_packages?.length
    )
      return DISABLE_CELL_STYLE;
    return;
  };

  const valueGetterPackageField = (params: ValueGetterParams<any>) => {
    const cargoIndex = params?.node?.rowIndex;
    const fieldName = params.colDef?.field || '';
    if (typeof cargoIndex === 'number' && cargoIndex < cargos.length && cargos[cargoIndex]) {
      return cargos[cargoIndex][fieldName];
    }
  };

  const columnDefs: Column[] = [];

  if (showVehicleSearch)
    columnDefs.push(
      allFields.vehicle_load_search({
        headerComponent: () => <RequireHeaderWrapper text="Assign Trip / Vehicle" />,
        pinned: 'left',
        vehicleSuggestions,
      })
    );
  columnDefs.push(
    allFields.commodity_description({
      headerComponent: () => <RequireHeaderWrapper text="Cargo Description" />,
      editable: (params: { node: { isRowPinned: () => any } }) => !params.node.isRowPinned(),
    }),
    allFields.package_type({
      headerComponent:
        load_type === LOAD_TYPE_FTL_BREAK_BULK
          ? () => <RequireHeaderWrapper text="Package Type" />
          : undefined,
      editable: isEditablePackageField,
      valueGetter: valueGetterPackageField,
      cellStyle: (params: CellClassParams<any, any>) => {
        const disabledStyle = cellStylePackageField(params);
        if (disabledStyle) return disabledStyle;
        const error = params.data?.errors?.package_type;
        if (error) return ERROR_CELL_STYLE;
        return null;
      },
    }),
    allFields.total_packages({
      headerComponent:
        load_type === LOAD_TYPE_FTL_BREAK_BULK
          ? () => <RequireHeaderWrapper text="Qty" />
          : undefined,
      editable: isEditablePackageField,
      valueGetter: valueGetterPackageField,
      cellStyle: (params: CellClassParams<any, any>) => {
        const disabledStyle = cellStylePackageField(params);
        if (disabledStyle) return disabledStyle;
        const error = params.data?.errors?.total_packages;
        if (error) return ERROR_CELL_STYLE;
        return null;
      },
      valueSetter: (params: ValueSetterParams<any>) => {
        const newTotalQty =
          params.newValue < params?.data?.allocated_qty
            ? params?.data?.allocated_qty
            : params?.newValue;
        params.data.total_packages = newTotalQty;
        return true;
      },
      cellEditorParams: (params: any) => {
        return {
          precision: 0,
          min: params?.data?.allocated_qty || 0,
          step: 1,
        };
      },
    }),
    allFields.serial_number({
      editable: (params: { node: { isRowPinned: () => any } }) => !params.node.isRowPinned(),
    }),
    allFields.invoice_number({
      editable: (params: { node: { isRowPinned: () => any } }) => !params.node.isRowPinned(),
    })
  );

  columnDefs.push(
    allFields.gross_weight({
      headerComponent:
        load_type !== LOAD_TYPE_FTL_BREAK_BULK && load_type !== LOAD_TYPE_FCL
          ? () => <RequireHeaderWrapper text="Gross Wt." />
          : undefined,
      editable: isEditablePackageField,
      valueGetter: valueGetterPackageField,

      cellStyle: (params: CellClassParams<any, any>) => {
        const disabledStyle = cellStylePackageField(params);
        if (disabledStyle) return disabledStyle;
        const error = params.data?.errors?.gross_weight;
        if (error) return ERROR_CELL_STYLE;
        return null;
      },

      valueSetter: (params: ValueSetterParams<any>) => {
        const newGrossWeight =
          params.newValue < params?.data?.allocated_qty
            ? params?.data?.allocated_qty
            : params?.newValue;
        params.data.gross_weight = newGrossWeight;
        return true;
      },
      cellEditorParams: (params: any) => {
        return {
          min: params?.data?.allocated_qty || 0,
        };
      },
    }),
    allFields.net_weight({
      valueGetter: (params: { data: { net_weight: string } }) => {
        return params.data?.net_weight ? parseFloat(params.data?.net_weight) : 0;
      },
      editable: (params: { node: { isRowPinned: () => any } }) => !params.node.isRowPinned(),
    }),
    allFields.weight_unit({
      headerComponent: () => <RequireHeaderWrapper text="Weight Unit" />,
      editable: isEditablePackageField,
      cellStyle: cellStylePackageField,
      valueGetter: (params: ValueGetterParams<any>) => {
        const value = valueGetterPackageField(params);
        if (!value) return WEIGHT_UNIT_MTS;
        return value;
      },
      valueSetter: (params: ValueSetterParams<any>) => {
        const weight_unit = params.newValue || WEIGHT_UNIT_MTS;
        params.data.weight_unit = weight_unit;
        const allRowData: any[] = [];
        gridRef?.current?.api?.forEachNode((node) => allRowData.push(node.data));
        const updatedRowData = allRowData.map((cargo, index) => {
          if (index === params?.node?.rowIndex) {
            return params.data;
          }
          return {
            ...cargo,
            weight_unit: weight_unit || cargo.weight_unit,
            volume_unit: cargo.volume_unit || VOLUME_UNIT_CBM,
            shipment_packages: cargo?.shipment_packages?.map((pkg: { weight_unit: string }) => ({
              ...pkg,
              weight_unit: weight_unit || pkg.weight_unit,
            })),
          };
        });
        gridRef?.current?.api?.setRowData(updatedRowData);
        return true;
      },
    }),
    allFields.gross_volume({
      editable: (params: EditableCallbackParams<any>) => {
        if (isLoose) return isEditablePackageField(params);
        else return false;
      },
      cellStyle: (params: CellClassParams<any, any>) => {
        if (isLoose) return cellStylePackageField(params);
        else return DISABLE_CELL_STYLE;
      },
      valueGetter: valueGetterPackageField,
    }),
    allFields.volumetric_wt({
      cellRendererSelector: (params: {
        node: { rowIndex: any };
        data: { volume_unit: any; weight_unit: any };
      }) => {
        let volumetricWeight = 0;
        const cargoIndex = params?.node?.rowIndex;
        if (typeof cargoIndex === 'number' && cargoIndex < cargos.length && cargos[cargoIndex])
          volumetricWeight = cargos[cargoIndex]['gross_volume'];
        volumetricWeight = getRoundOffValue(
          calculateVolumetricWeight({
            volume: volumetricWeight,
            volumeUnit: params?.data?.volume_unit || DIMENSION_CBM,
            weightUnit: params?.data?.weight_unit || WEIGHT_UNIT_MTS,
            factor: 6000,
          })
        );
        return {
          component: 'QtyUomTypeRenderer',
          params: {
            qty: volumetricWeight,
            uom: params?.data?.weight_unit,
          },
        };
      },
    }),

    allFields.invoice_date({
      editable: (params: { node: { isRowPinned: () => any } }) => !params.node.isRowPinned(),
    }),
    allFields.batch_number({
      editable: (params: { node: { isRowPinned: () => any } }) => !params.node.isRowPinned(),
    }),
    allFields.custom_ref({
      editable: (params: { node: { isRowPinned: () => any } }) => !params.node.isRowPinned(),
    }),
    allFields.eway_bill_no({
      editable: (params: { node: { isRowPinned: () => any } }) => !params.node.isRowPinned(),
    }),
    allFields.eway_bill_validity({
      editable: (params: { node: { isRowPinned: () => any } }) => !params.node.isRowPinned(),
    }),
    allFields.cargo_properties({
      cellRendererParams: (params: any) => {
        return {
          freightType: props.freightType,
          onChange: (value: CargoPropertyValue) => {
            const index = params?.node?.rowIndex;
            const updatedCargos = cargos;
            updatedCargos[index] = { ...updatedCargos[index], cargo_properties: value };
            setCargos(updatedCargos);
          },
        };
      },
      valueGetter: (params: { node: { isRowPinned: () => any } }) => {
        if (!params?.node?.isRowPinned()) return false;
        return true;
      },
    }),

    allFields.add_package({
      cellRendererParams: {
        cargos: cargos,
        setCargos: setCargos,
      },
    })
  );

  const isRowSelectable = (node: any) => {
    if (!shipment?.id) return true;
    else if (!isUpdate) return true; // duplicate order case

    if (node?.data?.id && node?.data?.allocated_qty) {
      return false;
    }

    return true;
  };

  const getDefaultRowData = () => {
    const lastIndex = gridRef?.current?.api?.getLastDisplayedRow();
    if (lastIndex !== undefined) {
      const lastCargo = gridRef?.current?.api?.getDisplayedRowAtIndex(lastIndex)?.data;
      return {
        package_type: lastCargo?.package_type || 'Box',
        weight_unit: lastCargo?.weight_unit || WEIGHT_UNIT_MTS,
        volume_unit: lastCargo?.volume_unit || VOLUME_UNIT_CBM,
      };
    } else {
      return {
        package_type: 'Box',
        weight_unit: WEIGHT_UNIT_MTS,
        volume_unit: VOLUME_UNIT_CBM,
      };
    }
  };

  return (
    <>
      <Form.Item name="cargos">
        <BaseTable
          reportName={'shipment_form_cargo_details'}
          showCheckBoxOnHeader={true}
          rowSelection="multiple"
          gridRef={gridRef}
          columns={columnDefs}
          rowData={cargos}
          allowProcessDataFromClipboard={true}
          newRowOnPaste={true}
          reportConfig={{
            stopEditingWhenCellsLoseFocus: true,
            isRowSelectable,
            allowDragFromColumnsToolPanel: true,
            onRowSelected: (event) => {
              const rowsSelected = gridRef?.current?.api?.getSelectedRows();
              setSelectedRowsCount(rowsSelected?.length || 0);
            },
            onCellValueChanged,
            onRowDataUpdated: (params) => {
              const updatedRows: any[] = [];
              params.api.forEachNode((rowNode) => {
                updatedRows.push(rowNode.data);
              });
              setCargos(updatedRows);
            },
            defaultColDef: {
              minWidth: 100,
              resizable: true,
              suppressMenu: true,
            },
            rowHeight: 40,
            components,
            groupDisplayType: 'groupRows',
          }}
          checkbox_always_visible={false}
          height="40vh"
        />

        {!disableAddCargo && (
          <div style={{ marginTop: '10px', display: 'flex' }}>
            <Button
              size="small"
              style={{ marginRight: '16px' }}
              onClick={() => {
                gridRef?.current?.api?.applyTransaction({ add: [{ ...getDefaultRowData() }] });
              }}
            >
              {' '}
              Add Cargo
            </Button>
            <Button
              danger
              disabled={selectedRowsCount === 0}
              style={{ marginRight: '16px' }}
              size="small"
              onClick={() => {
                const sel = gridRef?.current?.api?.getSelectedRows();
                gridRef?.current?.api?.applyTransaction({ remove: sel });
                setSelectedRowsCount(0);
              }}
            >
              {' '}
              Delete Cargo
            </Button>
            <Button
              size="small"
              style={{ marginRight: '16px' }}
              onClick={() => {
                setOpen(true);
              }}
            >
              Import Cargo
            </Button>
          </div>
        )}
      </Form.Item>
      {open && (
        <DataImportModal
          open={open}
          setOpen={setOpen}
          onSucess={(data: any) => setCargos(createCargoPayload(data))}
          intialValue={{
            selectedDocType: 'Shipment::Cargo',
            selectedAssociation: ['Shipment::ShipmentPackage'],
          }}
        />
      )}
    </>
  );
}

function createCargoPayload(cargo: any[]) {
  const remove_prefix = ['cargos_', 'shipment_packages_'];
  const rename = {
    'Shipment::ShipmentPackage': 'shipment_packages',
  };
  function removePrefixesAndRenameKeys(
    obj: any,
    prefixes: string[],
    renameMap: Record<string, string>
  ) {
    const result: any = {};

    for (const key in obj) {
      let newKey = key;

      // Remove prefixes
      prefixes.forEach((prefix) => {
        if (newKey.startsWith(prefix)) {
          newKey = newKey.slice(prefix.length);
        }
      });

      // Rename keys
      if (renameMap[key]) {
        newKey = renameMap[key];
      }

      const value = obj[key];

      // Process nested arrays or objects, but avoid processing dayjs objects
      if (Array.isArray(value)) {
        result[newKey] = value.map((item) =>
          removePrefixesAndRenameKeys(item, prefixes, renameMap)
        );
      } else if (
        typeof value === 'object' &&
        value !== null &&
        !value?.isValid() // Avoid processing dayjs objects
      ) {
        result[newKey] = removePrefixesAndRenameKeys(value, prefixes, renameMap);
      } else {
        result[newKey] = value;
      }
    }

    return result;
  }

  let cargos = cargo.map((item) => removePrefixesAndRenameKeys(item, remove_prefix, rename));
  const calculateGrossVolume = (packages: any, dimensionConversion: Record<string, number>) => {
    let grossVolume = 0;
    packages.forEach((pkg: any) => {
      const dimensionUnit = pkg.dimension_unit || DIMENSION_CMS;
      grossVolume +=
        pkg.total_number_of_packages *
        ((pkg.length || 0) * dimensionConversion[dimensionUnit]) *
        ((pkg.width || 0) * dimensionConversion[dimensionUnit]) *
        ((pkg.height || 0) * dimensionConversion[dimensionUnit]);
    });
    return grossVolume / 1000000; // Convert to cubic meters
  };

  const calculateTotalPackages = (packages: any) => {
    return packages.reduce((sum: number, pkg: any) => sum + pkg.total_number_of_packages, 0);
  };

  const calculateGrossWeight = (packages: any) => {
    return packages.reduce(
      (sum: number, pkg: any) => sum + pkg.total_number_of_packages * pkg.per_piece_weight,
      0
    );
  };

  const getPackageType = (packages: any) => {
    return packages.length ? packages[0].type_of_package : null;
  };

  const getWeightUnit = (packages: any) => {
    return packages.length ? packages[0].weight_unit : null;
  };

  cargos = cargos.map((c: any) => {
    if (c?.shipment_packages?.length) {
      c.gross_volume =
        calculateGrossVolume(c.shipment_packages, dimensionConversion) || c.gross_volume;
      c.total_packages = calculateTotalPackages(c.shipment_packages) || c.total_packages;
      c.gross_weight = calculateGrossWeight(c.shipment_packages) || c.gross_weight;
      c.package_type = getPackageType(c.shipment_packages) || c.package_type;
      c.weight_unit = getWeightUnit(c.shipment_packages) || c.weight_unit;
    }
    if (c?.invoice_date) c['invoice_date'] = c?.['invoice_date']?.unix();
    return c;
  });
  return cargos;
}

export default CargoTableForm;
