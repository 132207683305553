import { ShipmentValue } from 'operations/models/Shipment';
import {
  DOCUMENT_STATUS_CANCELLED,
  DOCUMENT_STATUS_EXECUTED,
  DOCUMENT_STATUS_GIVEN_TO_CUSTOMER,
  DOCUMENT_STATUS_RECEIVED_AT_DESTINATION,
  DOCUMENT_STATUS_SURRENDERED_AT_ORIGIN,
} from 'operations/modules/reports/constants';
import { axiosInstance as axios } from 'common';

export const fetchDocumentsCount = async (
  parent_type: string,
  parent_ids: string[],
  user_id: string,
  company_id: string
) => {
  try {
    const { data } = await axios.get(`${process.env.DOCGEN_URL}/parent_documents_count`, {
      params: {
        user_id: user_id,
        company_id: company_id,
        parents: { parent_type: parent_type, parent_ids: parent_ids },
      },
    });

    return { data: data, error: false };
  } catch (error) {
    console.error(error);
    return { error };
  }
};

export const findReleasedDocument = (documents: any[]) => {
  const releasedDocument = documents.find((document: { document_status: string }) => {
    return [
      DOCUMENT_STATUS_EXECUTED,
      DOCUMENT_STATUS_GIVEN_TO_CUSTOMER,
      DOCUMENT_STATUS_SURRENDERED_AT_ORIGIN,
      DOCUMENT_STATUS_RECEIVED_AT_DESTINATION,
      DOCUMENT_STATUS_CANCELLED,
    ].includes(document.document_status);
  });
  return !!releasedDocument;
};
export const getReleasedShipments = (shipment: ShipmentValue) => {
  const house_shipments = shipment?.house_shipments;
  const shipmentsDocMapping: any[] = [];
  house_shipments?.forEach((el) => {
    shipmentsDocMapping.push({
      id: el.id,
      documents: el.shipment_documents || [],
    });
  });
  if (shipment && shipment.shipment_documents) {
    shipmentsDocMapping.push({
      id: shipment.id,
      documents: shipment.shipment_documents,
    });
  }

  const releasedShipments = shipmentsDocMapping.filter((shipment) =>
    findReleasedDocument(shipment.documents)
  );
  return releasedShipments;
};
