import React, { useEffect } from 'react';

import { useMutation } from '@apollo/client';
import { message, Popconfirm } from '@shipmnts/pixel-hub';
import { CANCEL_INQUIRY } from 'sales_hub/graphql/inquiry';

type Props = {
  onSuccess?: () => void;
  id?: string;
  session: any;
  inquiry: any;
};

const CancelInquiryLayout = ({ onSuccess, id }: Props) => {
  // Queries/ Mutations
  const [
    cancelInquiry,
    { data: cancelInquiryData, error: cancelInquiryError, loading: loadingWinInquiry },
  ] = useMutation(CANCEL_INQUIRY);

  // Effects

  // On cancel inquiry
  useEffect(() => {
    if (cancelInquiryData && !cancelInquiryError) {
      if (onSuccess) onSuccess();
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelInquiryData]);

  // On error
  useEffect(() => {
    if (cancelInquiryError) message.error(cancelInquiryError?.message);
  }, [cancelInquiryError]);

  // Methods and Constants
  const onClose = () => {
    if (!loadingWinInquiry) {
      // setIsVisible(false);
      if (onSuccess) onSuccess();
    }
  };

  const onFinish = () => {
    cancelInquiry({
      variables: {
        inquiry_id: id,
      },
    });
  };

  return (
    <Popconfirm
      // title='Cancel Inquiry'
      title="Are you sure want to cancel inquiry?"
      onConfirm={onFinish}
      onCancel={onClose}
      okText="Yes"
      cancelText="No"
      open={true}
      openClassName="right"
      autoAdjustOverflow={true}
      placement="bottomLeft"
    ></Popconfirm>
  );
};

export default CancelInquiryLayout;
