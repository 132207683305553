import React from 'react';
import { ApolloClient } from '@apollo/client';
import { message } from '@shipmnts/pixel-hub';
import { EditOutlined, StopOutlined } from '@shipmnts/pixel-hub';
import { SalesPersonValue } from 'common/models/SalesPerson';
import { UPSERT_SALES_PERRSON } from 'network/graphql/salesPerson';
import { PRIMARY_TYPE } from '../constants';
import { Action } from '../models';
import { hasPermission } from '@shipmnts/pixel-hub';
import { PERMISSION_SALES_PERSON_EDIT, PERMISSION_SALES_PERSON_DISABLE } from 'network/permissions';
import { SessionDataValue } from 'operations/models/SessionData';
import { PermissionValue } from 'network/models/SessionData';

export const enableSalesPerson = (
  salesPerson: SalesPersonValue,
  client: ApolloClient<object>,
  permissions: PermissionValue[]
): Action => {
  return {
    key: 'enable_sales_person',
    type: PRIMARY_TYPE,
    displayComponent: 'Enable',
    icon: <StopOutlined />,
    onClick: async (onSuccess: (() => void) | undefined) => {
      const { data, errors } = await client.mutate({
        mutation: UPSERT_SALES_PERRSON,
        variables: {
          sales_person: {
            id: salesPerson.id,
            enable: true,
          },
        },
      });
      if (errors) {
        console.error(errors);
        message.error(errors[0].message);
      } else if (data?.upsert_sales_person) {
        message.success('Enable Sales Person');
        if (onSuccess) onSuccess();
      }
    },
    isEnable:
      !salesPerson.enable &&
      hasPermission(permissions, {
        docType: 'Network::SalesPerson',
        name: PERMISSION_SALES_PERSON_DISABLE,
      }),
    description: 'Enable Sales Person',
  };
};

export const disableSalesPerson = (
  salesPerson: SalesPersonValue,
  client: ApolloClient<object>,
  permissions: PermissionValue[]
): Action => {
  return {
    key: 'disable_sales_person',
    type: PRIMARY_TYPE,
    displayComponent: 'Disable',
    icon: <StopOutlined />,
    onClick: async (onSuccess: (() => void) | undefined) => {
      const { data, errors } = await client.mutate({
        mutation: UPSERT_SALES_PERRSON,
        variables: {
          sales_person: {
            id: salesPerson.id,
            enable: false,
          },
        },
      });
      if (errors) {
        console.error(errors);
        message.error(errors[0].message);
      } else if (data?.upsert_sales_person) {
        message.success('Disabled Sales Person');
        if (onSuccess) onSuccess();
      }
    },
    isEnable:
      !!salesPerson.enable &&
      hasPermission(permissions, {
        docType: 'Network::SalesPerson',
        name: PERMISSION_SALES_PERSON_DISABLE,
      }),
    description: 'Disable Sales Person',
  };
};

export const editSalesPerson = (
  salesPerson: SalesPersonValue,
  permissions: PermissionValue[]
): Action => {
  return {
    key: 'edit_sales_person',
    type: PRIMARY_TYPE,
    displayComponent: 'Edit',
    icon: <EditOutlined />,
    onClick: () => {
      window.open(`/form/sales_person/${salesPerson.id}`, '_self');
    },
    isEnable: hasPermission(permissions, {
      docType: 'Network::SalesPerson',
      name: PERMISSION_SALES_PERSON_EDIT,
    }),
    description: 'Disable Sales Person',
  };
};
export const getSalesPersonActions = (
  salesPerson: SalesPersonValue,
  client: ApolloClient<object>,
  sessionData: SessionDataValue
) => {
  const permissions = sessionData.permissions;

  return [
    // FIX ME
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    enableSalesPerson(salesPerson, client, permissions),
    // FIX ME
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    disableSalesPerson(salesPerson, client, permissions),
    // FIX ME
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    editSalesPerson(salesPerson, permissions),
  ];
};
