/**
 *  Reusable global component to render next actions which are related to tracking events
 */
import React, { ReactNode, useState } from 'react';
import { useSession, RenderEventsForm, baseConstants } from 'common';
import { Dropdown } from '@shipmnts/pixel-hub';
import { DownOutlined } from '@shipmnts/pixel-hub';

import type { MenuProps } from '@shipmnts/pixel-hub';
type MenuItem = Required<MenuProps>['items'][number];
const { DOC_TYPE_METHODS } = baseConstants;
export type NextActionProps = {
  nextActions: string[];
  reference_id: string;
  children?: JSX.Element;
  onSuccess?: (() => void) | undefined;
  workflowType?: any;
  referenceType?: any;
  iconComponent?: ReactNode;
};

export const NextActionsComponent = ({
  nextActions,
  reference_id,
  children,
  onSuccess,
  workflowType, //? Not sure why this is used
  referenceType,
  iconComponent,
}: NextActionProps) => {
  const user = useSession();

  const [activeAction, setActiveAction] = useState<string | undefined>(undefined);
  const workflow_type = workflowType || 'main';
  if (!nextActions) return <></>;
  //Handlers
  const onClose = () => {
    setActiveAction(undefined);
  };

  const handleActions = (action: string) => {
    setActiveAction(action);
  };

  const _referenceType = referenceType || 'Shipment::ShipmentContainer';
  if (DOC_TYPE_METHODS[_referenceType].show_tracker_drawer && reference_id) {
    const reference_type = referenceType;

    const getItems = () => {
      const items: MenuItem[] = [];

      // eslint-disable-next-line no-lone-blocks, @typescript-eslint/no-unused-expressions
      nextActions.length === 0
        ? [
            items.push({
              disabled: true,
              label: 'No Actions Available',
              key: 0,
            }),
          ]
        : nextActions.map((action: string) => {
            return items.push({
              key: action,
              onClick: () => {
                handleActions(action);
              },
              label: action,
            });
          });

      return items;
    };

    return (
      <>
        <Dropdown
          placement="bottomLeft"
          menu={{ items: getItems() }}
          key={reference_id}
          trigger={['click']}
        >
          <div className="event-name">
            {children}
            {nextActions.length !== 0 && (
              <div style={{ marginLeft: '5px' }}>
                {iconComponent ? iconComponent : <DownOutlined />}
              </div>
            )}
          </div>
        </Dropdown>

        {reference_id && activeAction && (
          <RenderEventsForm
            type="UPDATE"
            referenceType={reference_type}
            id={reference_id.toString()}
            onSuccess={onSuccess}
            onClose={onClose}
            eventName={activeAction}
            session={user}
            workflow_type={workflow_type} //?why is this not in camelcase
          />
        )}
      </>
    );
  } else return <></>;
};
