import { gql } from '@apollo/client';

export const containerSettingsFields = gql`
  fragment containerSettingsFields on ContainerSettingsObjectType {
    is_active_reefer
    temperature
    temperature_unit
    ventilation_requested
    air_flow
    air_flow_unit
    humidity_control_requested
    relative_humidity_percent
    vent_setting
    additional_instructions
    genset_requested
    controlled_atmosphere_requested
    oxygen_level_percent
    nitrogen_level_percent
    carbon_dioxide_level_percent
    length
    width
    height
    lbh_unit
  }
`;

export const containerRequestFields = gql`
  fragment containerRequestFields on ContainerRequestObjectType {
    id
    container_type
    container_type_code
    quantity
    quantity_fulfilled
    quantity_unfulfilled
    quantity_origin_port_gated_in
    is_shipper_owned
    weight_per_container
    weight_unit
    container_settings {
      ...containerSettingsFields
    }
  }
  ${containerSettingsFields}
`;

export const containerRequestWithQuantityFields = gql`
  fragment containerRequestWithQuantityFields on ContainerRequestObjectType {
    ...containerRequestFields
    quantity_picked_up
    quantity_loaded_on_vessel
    quantity_cancelled
    quantity_shutout
    quantity_offloaded
  }
  ${containerRequestFields}
`;
