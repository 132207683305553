import React from 'react';

import { ProductOrderValue } from 'operations/models/ProductOrder';

interface DuplicateProductOrderProps {
  productOrder: ProductOrderValue;
  navigate: any;
}

const DuplicateProductOrder = (props: DuplicateProductOrderProps) => {
  const { productOrder, navigate } = props;

  navigate(`~/form/product_order/new?type=${productOrder.type}&duplicate_from=${productOrder?.id}`);
  return <></>;
};

export default DuplicateProductOrder;
