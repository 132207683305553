import {
  Col,
  Form,
  FormInstance,
  Input,
  Radio,
  Row,
  Select,
  DatePicker,
  AddressCompanySearch,
} from '@shipmnts/pixel-hub';
import { RadioOptionProps } from 'operations/commonTypeDefs';
import { ShipmentValue } from 'operations/models/Shipment';
import {
  FREIGHT_TYPES,
  TRADE_TYPES,
} from 'operations/modules/shipment/components/ShipmentForm/constants';
import {
  ROW_GUTTER,
  BOOKING_THROUGH_TYPES,
  BUSINESS_TYPE_SUBAGENT,
  BOOKING_THROUGH_OVERSEAS_AGENT,
  TRADE_TYPE_EXPORT,
  TRADE_TYPE_IMPORT,
} from 'operations/modules/shipment/constants';
import { LOAD_TYPE_OPTIONS, TypeFreightType } from 'operations/utils/constants';
import React from 'react';
import { GlobalSearch } from '@shipmnts/pixel-hub';
import { useSession } from 'common';
interface BasicDetailsProps {
  shipment?: ShipmentValue;
  disableHash?: any;
  form: FormInstance;
  hide?: boolean;
}
const { Option } = Select;
export default function BasicDetails(props: BasicDetailsProps) {
  const { form, shipment, hide, disableHash } = props;
  const sessionData = useSession();
  const businessType = Form.useWatch('business_type', form);
  const id = Form.useWatch('id', form);
  const editable = !!shipment ? shipment?.canEdit() : true;

  const priorityOptions = [
    { value: 'Highest', label: 'Highest' },
    { value: 'High', label: 'High' },
    { value: 'Medium', label: 'Medium' },
    { value: 'Low', label: 'Low' },
    { value: 'Lowest', label: 'Lowest' },
  ];
  return (
    <Row gutter={ROW_GUTTER}>
      <Col span={6}>
        <Form.Item noStyle shouldUpdate={true}>
          {({ getFieldValue }) => {
            const freightType: TypeFreightType = getFieldValue('freight_type');
            let options = [];
            if (freightType === 'road') options = TRADE_TYPES;
            else
              options = [
                {
                  key: TRADE_TYPE_IMPORT,
                  name: 'Import',
                },
                {
                  key: TRADE_TYPE_EXPORT,
                  name: 'Export',
                },
              ];
            return (
              <Form.Item rules={[{ required: !hide }]} name="trade_type" label="Trade Type">
                <Radio.Group disabled={disableHash?.trade_type || (!!shipment && !!id)}>
                  {options.map((option: RadioOptionProps, index: number) => (
                    <Radio key={index} value={option.key}>
                      {option.name}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item rules={[{ required: !hide }]} name="freight_type" label="Freight Type">
          <Radio.Group disabled={false}>
            {FREIGHT_TYPES.map((option: RadioOptionProps, index: number) => (
              <Radio
                key={index}
                value={option.key}
                disabled={false}
                onChange={(val) => {
                  form.setFieldValue(
                    'load_type',
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    LOAD_TYPE_OPTIONS[val.target.value || 'air'][0].value
                  );
                }}
              >
                {option.name}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item noStyle shouldUpdate={true}>
          {({ getFieldValue }) => {
            const freightType: TypeFreightType = getFieldValue('freight_type');
            const initialValue = LOAD_TYPE_OPTIONS[freightType || 'air'][0].value;
            return (
              <Form.Item
                name="load_type"
                label="Load Type"
                rules={[{ required: true, message: 'Please select load type' }]}
                dependencies={['freight_type']}
              >
                <Radio.Group
                  defaultValue={initialValue}
                  options={LOAD_TYPE_OPTIONS[freightType || 'air']}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item rules={[{ required: !hide }]} name={'involved_branch_id'} label="Branch">
          <Select
            showSearch
            placeholder="Booked By Branch"
            filterOption={(input, option) =>
              `${option?.children || ''}`.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            disabled={!editable}
          >
            {(sessionData?.branch_accounts || []).map((branch: { id: string; name: string }) => (
              <Select.Option key={branch.id} value={branch.id}>
                {branch.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={24 / 3}>
        <Form.Item name="job_date" rules={[{ required: !hide }]} label="Job Date">
          <DatePicker style={{ width: '100%' }} />
        </Form.Item>
      </Col>
      <Col span={24 / 3}>
        <Form.Item name="sales_agent" label="Sales Person">
          <GlobalSearch
            doc_type="Network::SalesPerson"
            disabled={!editable}
            extraProps={{
              placeholder: 'Name of the Sales Person',
            }}
          />
          {/* <SalesPerson /> */}
        </Form.Item>
      </Col>
      <Col span={24 / 3}>
        <Form.Item
          rules={[{ required: true }]}
          label="Business Booked Through"
          name="business_type"
        >
          <Radio.Group>
            {BOOKING_THROUGH_TYPES.map((option: RadioOptionProps, index: number) => (
              <Radio key={index} value={option.key}>
                {option.name}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
        {[BUSINESS_TYPE_SUBAGENT, BOOKING_THROUGH_OVERSEAS_AGENT].includes(businessType) && (
          <Form.Item
            rules={[{ required: !hide }]}
            name={[
              'party',
              `${businessType === BUSINESS_TYPE_SUBAGENT ? 'subagent' : 'overseas_agent'}`,
            ]}
            label={`${businessType === BUSINESS_TYPE_SUBAGENT ? 'Subagent' : 'Overseas Agent'}`}
          >
            <AddressCompanySearch />
          </Form.Item>
        )}
      </Col>

      <Col span={24 / 3}>
        <Form.Item noStyle>
          <Form.Item name="quotation_number" label="Quotation Ref">
            <Input placeholder="Enter quotation number" />
          </Form.Item>
        </Form.Item>
      </Col>
      <Col span={24 / 3}>
        <Form.Item name="other_job_number" label="Other Job #">
          <Input placeholder="Other Job #" />
        </Form.Item>
      </Col>
      <Col span={24 / 3}>
        <Form.Item name="incoterms" label="Incoterms">
          <GlobalSearch doc_type="Global::Incoterms" />
        </Form.Item>
      </Col>
      {/* <Col span={24 / 3}>
        <Form.Item noStyle dependencies={[['is_letter_of_credit_number_appliable']]}>
          {({ getFieldValue }) => {
            const is_letter_of_credit_number_appliable = getFieldValue(
              'is_letter_of_credit_number_appliable'
            );
            return (
              <Form.Item name="letter_of_credit_number" label="Letter of Credit #">
                <Input
                  placeholder="Letter of Credit #"
                  disabled={!is_letter_of_credit_number_appliable}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item name="is_letter_of_credit_number_appliable" valuePropName="checked">
          <Checkbox>Letter of Credit is Applicable</Checkbox>
        </Form.Item>
      </Col> */}
      <Col span={24 / 3}>
        <Form.Item noStyle dependencies={[['is_letter_of_credit_number_appliable']]}>
          {({ getFieldValue }) => {
            const is_letter_of_credit_number_appliable = getFieldValue(
              'is_letter_of_credit_number_appliable'
            );
            return (
              <Form.Item label="Letter of Credit Date" name="letter_of_credit_date">
                <DatePicker
                  disabled={!is_letter_of_credit_number_appliable}
                  showTime={false}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>
      <Col span={24 / 3}>
        <Form.Item name="priority" label="Priority">
          <Select disabled={false} placeholder="Select the priority of this order">
            {priorityOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </Row>
  );
}
