import React from 'react';
import { createRoot } from 'react-dom/client';
import * as serviceWorker from './serviceWorker';
// For observer batching
import 'mobx-react-lite/batchingForReactDom';
import BaseLayout from './BaseLayout';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<BaseLayout />);

serviceWorker.register({
  onSuccess: () => console.log('success'),
  onUpdate: (reg) => console.log('update'),
});
