import { types, Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree';

const ShipmentProductRating = types.model({
  id: types.identifier,
  cargo_type: types.maybe(types.maybeNull(types.enumeration(['uld', 'loose', 'extra']))),
  chargeable_basis: types.maybe(types.maybeNull(types.string)),
  chargeable_weight: types.maybe(types.maybeNull(types.number)),
  gross_weight: types.maybe(types.maybeNull(types.number)),
  quantity: types.maybe(types.maybeNull(types.number)),
  rate_class: types.maybe(types.maybeNull(types.string)),
  uld_numbers: types.maybe(types.maybeNull(types.string)),
  uld_type: types.maybe(types.maybeNull(types.string)),
  commodity_item_number: types.maybe(types.maybeNull(types.string)),
  rate_per_charge: types.maybe(types.maybeNull(types.number)),
  commission_percentage: types.maybe(types.maybeNull(types.number)),
});

export type ShipmentProductRatingValue = Instance<typeof ShipmentProductRating>;
export type ShipmentProductRatingIn = SnapshotIn<typeof ShipmentProductRating>;
export type ShipmentProductRatingOut = SnapshotOut<typeof ShipmentProductRating>;

export default ShipmentProductRating;
