import React, { useState, useEffect, useContext } from 'react';

import createAuth0Client from '@auth0/auth0-spa-js';
import { Modal, Spin } from '@shipmnts/pixel-hub';
import PropTypes from 'prop-types';

import { CrossOriginLocalStorage } from './utils/crossOriginLocalStorage';
import { axiosInstance as axios } from 'common/utils/axiosDefaults';
import { navigate as browserNavigation } from 'wouter/use-browser-location';

export const sessionKey = `${process.env.AUTH0_CLIENT_ID}::session`;

const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({ children, ...initOptions }) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);

      setAuth0(auth0FromHook);

      if (window.location.search.includes('code=')) {
        try {
          const { appState } = await auth0FromHook.handleRedirectCallback();

          browserNavigation(appState && appState.targetUrl ? appState.targetUrl : '/', {
            replace: true,
          });
        } catch (e) {
          console.error(e);
          window.open(
            `${window.location.pathname}?error=Auth Error&error_description=${e.message}`,
            '_self'
          );
        }
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();
      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        setUser(user);
      }

      setLoading(false);
    };
    try {
      initAuth0();
    } catch (e) {
      console.log(e);
    }
    // eslint-disable-next-line
  }, []);

  const getTokenSilently = async (...p) => {
    const token = await auth0Client.getTokenSilently(...p);
    const currentUser = await auth0Client.getUser();
    if (currentUser && user.sub !== currentUser.sub) {
      localStorage.removeItem(sessionKey);
      window.location.reload();
    }
    return token;
  };

  const deleteFcmToken = async (token) => {
    try {
      await axios.delete(`${process.env.ALEX_URL}/fcm/delete_token`, {
        params: {
          token: token,
        },
        timeout: 1000,
      });
    } catch (error) {
      return { error };
    }
  };

  const logout = ({ skipAuthSet = false, skipAuth0Logout = false }) => {
    let waitForIframe = false;
    const logoutFromAuth0 = async () => {
      await deleteFcmToken(localStorage.getItem('fcm_token'));
      localStorage.removeItem(sessionKey);
      localStorage.removeItem('fcm_token');
      if (!skipAuth0Logout)
        auth0Client.logout({
          returnTo: window.location.origin,
          federated: true,
        });
    };
    if (!skipAuthSet) {
      const iframe = document.getElementById('session_iframe');
      const cross = new CrossOriginLocalStorage(window, iframe, () => {
        return null;
      });
      cross.setData('isAuthenticated', false);
      let session = localStorage.getItem(sessionKey);
      if (session && session !== '') {
        try {
          session = JSON.parse(session);
        } catch {
          session = undefined;
        }
      }
      if (session && session?.company_account?.subdomain) {
        const erpnextIframe = document.createElement('iframe');
        erpnextIframe.src = `${session?.company_account?.subdomain}/log_out?local_only=true`;
        erpnextIframe.loading = 'eager';
        document.body.appendChild(erpnextIframe);
        waitForIframe = true;
        Modal.info({
          title: 'Logging you out',
          content: (
            <span>
              <Spin></Spin>
              &nbsp;&nbsp;This might take a while...
            </span>
          ),
          centered: true,
          keyboard: false,
          okButtonProps: { style: { display: 'none' } },
          icon: null,
        });
        erpnextIframe.addEventListener('load', function () {
          logoutFromAuth0();
        });
      }
    }

    if (!waitForIframe) {
      logoutFromAuth0();
    }
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        getTokenSilently,
        logout,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
Auth0Provider.propTypes = {
  children: PropTypes.node.isRequired,
};
