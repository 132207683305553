import React from 'react';
import { useParams, useLocation } from 'wouter';
import { Result, Button } from '@shipmnts/pixel-hub';
import { useQuery } from '@apollo/client';
import { startCase } from 'lodash';
import { BreadCrumbsMapRenderTypesAndConstants, baseConstants, CommonQueries } from 'common';
import Spinner from './components/Spinner';
const { linkMap } = BreadCrumbsMapRenderTypesAndConstants;
const { GET_ID_FROM_NAME } = CommonQueries;
const { FINANCE_DOCTYPE_MAPPING } = baseConstants;

function HandleLinkFromFinance() {
  const params = useParams<{ doctype: string; name: string }>();
  const { 1: navigate } = useLocation();
  const name = decodeURIComponent(params.name || '');
  const doctype = decodeURIComponent(params.doctype || '');
  const module_name = FINANCE_DOCTYPE_MAPPING[doctype]?.module;
  const { loading, error, data } = useQuery(GET_ID_FROM_NAME, {
    variables: { module_name, name },
    skip: !module_name || !name,
  });
  if (!module_name) {
    return (
      <Result
        status="404"
        title="Unable to locate the object you are looking for"
        subTitle={`module ${doctype} not available`}
        extra={[
          <Button key="home" type="primary" onClick={() => navigate('/', { replace: true })}>
            Go Home
          </Button>,
        ]}
      />
    );
  }
  if (!name) {
    navigate(`~/workspace?doc_type=${module_name}`, { replace: true });
  }
  if (loading) {
    return (
      <div className="centered fullscreen">
        <Spinner tip={`Fetching your ${startCase(linkMap[module_name])} information`}></Spinner>
      </div>
    );
  }
  if (error) {
    return (
      <Result
        status="404"
        title={`Unable to locate the ${startCase(linkMap[module_name])} you are looking for`}
        subTitle={`${startCase(linkMap[module_name])} ${name} not available`}
        extra={[
          <Button key="home" type="primary" onClick={() => navigate('/', { replace: true })}>
            Go Home
          </Button>,
        ]}
      />
    );
  }
  if (data?.get_id_from_name?.id) {
    navigate(
      `~/${FINANCE_DOCTYPE_MAPPING[doctype].link_type}/${linkMap[module_name]}/${data.get_id_from_name.id}`,
      { replace: true }
    );
  }
  return (
    <Result
      status="500"
      title="Something went wrong"
      subTitle="Error occurred while trying to load this page. Please contact support."
      extra={[
        <Button key="home" type="primary" onClick={() => navigate('/', { replace: true })}>
          Go Home
        </Button>,
      ]}
    />
  );
}

export default HandleLinkFromFinance;
