import { useMutation, useQuery } from '@apollo/client';
import { Input, message, Modal, Table, Form } from '@shipmnts/pixel-hub';
import { startCase } from 'lodash';
import { UPDATE_SHIPMENT_ACCOUNTING_STATUS } from 'operations/graphql/shipmentDocument';
import { FETCH_SHIPMENT_ESTIMATES } from 'operations/graphql/shipmentEstimate';
import { ShipmentValue } from 'operations/models/Shipment';
import React, { useEffect } from 'react';
import { errorMessageHandlerGraphQLString } from 'common';
import { ACCOUNTING_STATUS_CLOSED } from '../constants';
import { renderAmount } from './DetailLayout/Accounts/components/accountsTabColumns';
const { TextArea } = Input;
interface AccountingCloseProps {
  shipment: ShipmentValue;
  onClose?: () => void;
  onSuccess?: () => void;
}

export default function AccountingClose(props: AccountingCloseProps) {
  const { shipment, onClose, onSuccess } = props;
  const [form] = Form.useForm();
  const shipment_ids = [shipment.id];
  const allShipments = [shipment];
  if (shipment?.house_shipments) {
    shipment_ids.push(...(shipment.house_shipments || []).map((s) => s.id));
    allShipments.push(...shipment.house_shipments);
  }
  const shipmentIdvsJobNumbers = allShipments.reduce((acc: any, shp: any) => {
    acc[shp.id] = shp.job_number;
    return acc;
  }, {});

  const [accountingClose, { data, loading, error }] = useMutation(
    UPDATE_SHIPMENT_ACCOUNTING_STATUS
  );

  const { data: shipmentEstimates } = useQuery(FETCH_SHIPMENT_ESTIMATES, {
    variables: { shipment_ids: shipment_ids },
  });

  useEffect(() => {
    if (error) {
      message.error(errorMessageHandlerGraphQLString(error));
    }
    if (data?.update_accounting_status) {
      message.success(
        'Shipment status changed to ' + startCase(data?.update_accounting_status?.accounting_status)
      );
      onSuccess && onSuccess();
    }
  }, [data, loading, error, onClose, onSuccess]);

  function getDataSource() {
    // Fixed bottom rows support not available :- https://github.com/ant-design/ant-design/issues/5710
    const totals = { key: 'total_row', item: 'Total', unbilled_expense: 0, unbilled_income: 0 };
    const data = (shipmentEstimates?.fetch_shipment_estimate_list || [])?.reduce(
      (acc: any, estimate: any) => {
        if (!(estimate.buy_status === 'unbilled' || estimate.sell_status === 'unbilled'))
          return acc;
        const row: any = {
          item: estimate.item,
          key: estimate.id,
          job_number: shipmentIdvsJobNumbers[estimate.shipment_id],
        };
        if (estimate.total_buy_amount && !estimate.total_buy_billed_amount) {
          row['unbilled_expense'] = estimate.total_buy_amount;
          totals['unbilled_expense'] += estimate.total_buy_amount;
        }
        if (estimate.total_sell_amount && !estimate.total_sell_billed_amount) {
          row['unbilled_income'] = estimate.total_sell_amount;
          totals['unbilled_income'] += estimate.total_sell_amount;
        }
        acc.push(row);
        return acc;
      },
      []
    );
    if (data.length !== 0) {
      data.push(totals);
    }
    return data;
  }

  function getColumns() {
    const job_number_column: any[] = [];
    if (shipment.isConsolShipment()) {
      job_number_column.push({
        title: 'Job #',
        dataIndex: 'job_number',
        key: 'job_number',
      });
    }
    return [
      ...job_number_column,
      {
        title: 'Charge Code',
        dataIndex: 'item',
        key: 'item',
        render: (item: any) => (item === 'Total' ? <b>{item}</b> : item),
      },
      {
        title: 'Unbilled Income',
        dataIndex: 'unbilled_income',
        key: 'unbilled_income',
        render: (amt: any) => renderAmount(amt),
      },
      {
        title: 'Unbilled Expense',
        dataIndex: 'unbilled_expense',
        key: 'unbilled_expense',
        render: (amt: any) => renderAmount(amt),
      },
    ];
  }

  return (
    <Modal
      onCancel={onClose}
      okText="Close Shipment"
      title={`Review ${shipment.job_number}`}
      open
      width={'100vh'}
      onOk={form.submit}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={(values: { remarks: string }) => {
          accountingClose({
            variables: {
              id: shipment.id,
              next_status: ACCOUNTING_STATUS_CLOSED,
              remarks: values.remarks,
            },
          });
        }}
      >
        <p style={{ marginTop: 0, marginBottom: 0 }}>Unbooked Estimates</p>
        <p style={{ marginTop: 0 }}>
          <small>
            On closing job below un-billed income/expense will be set to zero and will not affect
            job profitability.
            <br />
            If you reopen the job these estimates will be reset to un-billed value and appear as
            pending.
          </small>
        </p>
        <Table
          dataSource={getDataSource()}
          columns={getColumns()}
          bordered={true}
          pagination={false}
        />
        <Form.Item name="remarks" label="Closing Remarks">
          <TextArea style={{ width: '100%' }} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
