import React from 'react';
import { collapsibleCard } from '../../../shipment/components/DetailLayout/Common/common';
import { CustomIcon, getDateFromUnix } from '@shipmnts/pixel-hub';
import { startCase as _startCase } from 'lodash';
import { ProductOrderValue } from 'operations/models/ProductOrder';

interface ShipmentDetailsProps {
  product_order: ProductOrderValue;
}

function ShipmentDetails(props: ShipmentDetailsProps) {
  const { product_order } = props;
  const shipmentDetails: { [key: string]: string | React.ReactNode } = {
    'Mode & Load of Shipment': [
      _startCase(product_order.freight_type || ''),
      product_order.load_type?.toUpperCase(),
    ]
      .filter(Boolean)
      .join(' - '),
    'Port of Loading': product_order.port_of_loading ? (
      <b>
        {[
          product_order.port_of_loading?.unlocode?.toUpperCase(),
          product_order.port_of_loading?.name,
        ]
          .filter(Boolean)
          .join(' - ')}
      </b>
    ) : (
      ''
    ),
    'Origin Country': product_order.port_of_loading
      ? product_order.port_of_loading?.country?.name
      : ' - ',
    'Target Cargo Ready Date': product_order.estimated_cargo_ready_date
      ? getDateFromUnix(product_order.estimated_cargo_ready_date)
      : '-',
    'Target Shipping Date': product_order.target_shipping_date
      ? getDateFromUnix(product_order.target_shipping_date)
      : '-',
    'Port of Discharge': product_order.port_of_discharge ? (
      <b>
        {[
          product_order.port_of_discharge?.unlocode?.toUpperCase(),
          product_order.port_of_discharge?.name,
        ]
          .filter(Boolean)
          .join(' - ')}
      </b>
    ) : (
      ''
    ),
    'Destination Country': product_order.port_of_discharge
      ? product_order.port_of_discharge?.country?.name
      : ' - ',
    'Target Delivery Date': product_order.target_delivery_date
      ? getDateFromUnix(product_order.target_delivery_date)
      : '-',
  };

  return (
    <div>
      {/* Shipment Detials */}
      {collapsibleCard({
        icon: <CustomIcon icon="Route" />,
        header: 'Shipment Details',
        data: shipmentDetails,
      })}
    </div>
  );
}

export default ShipmentDetails;
