export const PARTY_NAME = [
  { value: 'shipper', label: 'Shipper' },
  { value: 'consignee', label: 'Consignee' },
  { value: 'to_order', label: 'To Order' },
  { value: 'to_order_bank', label: 'To Order Bank' },
  {
    value: 'buyer',
    label: 'Buyer / Bill To',
    searchProps: {
      is_customer: true,
    },
  },
  {
    value: 'seller',
    label: 'Seller / Bill From',
    searchProps: {
      is_vendor: true,
    },
  },
  { value: 'notify_party_1', label: 'Notify Party 1' },
  { value: 'subagent', label: 'Subagent' },
  { value: 'manufacturer', label: 'Manufacturer' },
  { value: 'origin_agent', label: 'Origin Agent' },
  { value: 'agent', label: 'Agent' },
];

export const SERVICES_NAME = [
  { value: 'freight_forwarding_agent', label: 'Frieght Forwarding' },
  { value: 'nominee', label: 'Nominee' },
  {
    value: 'origin_clearance_agent',
    label: 'Origin Customs Clearance',
  },
  {
    value: 'origin_transport_agent',
    label: 'Origin Transporation',
  },
  {
    value: 'origin_palletization_agent',
    label: 'Origin Palletization',
  },
  {
    value: 'origin_fumigation_agent',
    label: 'Origin Fumigation',
  },
  {
    value: 'destination_clearance_agent',
    label: ' Destination Customs Clearance',
  },
  {
    value: 'destination_transport_agent',
    label: 'Destination Transporation',
  },
  {
    value: 'cargo_insurance_agent',
    label: 'Cargo Insurance',
  },
  {
    value: 'empty_container_insurance_agent',
    label: 'Empty Container Insurance',
  },
];

export const PARTY_TYPES = PARTY_NAME.map((p) => p.value);
export const SERVICES_TYPES = SERVICES_NAME.map((p) => p.value);
