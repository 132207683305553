import { gql } from '@apollo/client';
import { minimumCompanyFields } from 'operations/graphql/shipmentEstimate';

export const CREATE_VEHICLE = gql`
  mutation create_vehicle($vehicle: VehicleInputType!) {
    create_vehicle(vehicle: $vehicle) {
      id
      vehicle_category
      vehicle_chassis_number
      vehicle_fuel_type
      vehicle_insurance_amount
      vehicle_insurance_coverage_type
      vehicle_insurance_date
      vehicle_insurance_expiry_date
      vehicle_insurance_number
      vehicle_license_plate_number
      vehicle_registration_date
      vehicle_registration_expiry_date
      vehicle_status
      model
      make
      max_weight_capacity
      weight_unit
      engine_number
      acquisition_date
    }
  }
`;

export const UPDATE_VEHICLE = gql`
  mutation update_vehicle($id: ID!, $vehicle: VehicleInputType!) {
    update_vehicle(id: $id, vehicle: $vehicle) {
      id
    }
  }
`;

export const GET_VEHICLE = gql`
  query get_vehicle($id: ID!) {
    get_vehicle(id: $id) {
      id
      vehicle_category
      vehicle_chassis_number
      vehicle_fuel_type
      vehicle_insurance_amount
      vehicle_insurance_coverage_type
      vehicle_insurance_date
      vehicle_insurance_expiry_date
      vehicle_insurance_number
      vehicle_license_plate_number
      vehicle_registration_date
      vehicle_registration_expiry_date
      vehicle_status
      ownership_type
      company {
        ...minimumCompanyFields
      }
      model
      make
      max_weight_capacity
      weight_unit
      engine_number
      acquisition_date
    }
  }
  ${minimumCompanyFields}
`;
