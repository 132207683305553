import React, { useMemo } from 'react';
import { CompanyDetailsCard } from '../Cards/CompanyDetailsCard';
import { ProspectingDetailsCard } from '../Cards/ProspectingDetailsCard';
import { useCompanyView } from '../CompanyView';
import { TaskCard } from '../Cards/TaskCard';
import { TeamCard } from '../Cards/TeamCard';

export const OverviewTab = () => {
  const { is_customer, is_lead, company } = useCompanyView();
  const isSelfCompany = useMemo(() => company?.company_group === 'Self', [company]);
  return (
    <div
      style={{
        width: '95%',
        marginInline: 'auto',
        marginBlock: '1em 2em',
        display: 'flex',
        gap: '1em',
      }}
    >
      {!isSelfCompany && <ProspectingDetailsCard />}
      {!isSelfCompany ? (
        <div style={{ display: 'grid', gap: '1em' }}>
          <CompanyDetailsCard />
        </div>
      ) : (
        <>
          <CompanyDetailsCard />
        </>
      )}
      <TaskCard />
      {(is_customer || is_lead) && <TeamCard />}
    </div>
  );
};
