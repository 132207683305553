import { types, Instance, SnapshotIn, SnapshotOut, IAnyModelType } from 'mobx-state-tree';
import OrderCollaboration from 'operations/models/OrderCollaboration';

const MiscellaneousServiceOrder = types
  .model({
    id: types.identifier,
    name: types.string,
    order_collaborations: types.array(types.late((): IAnyModelType => OrderCollaboration)),
  })
  .views((self) => ({
    getVendorCompany(default_company_id: string) {
      return (self.order_collaborations || []).find(
        (oc) => oc?.customer_company?.id === default_company_id
      )?.vendor_company;
    },
  }));

export type MiscellaneousServiceOrderValue = Instance<typeof MiscellaneousServiceOrder>;
export type MiscellaneousServiceOrderIn = SnapshotIn<typeof MiscellaneousServiceOrder>;
export type MiscellaneousServiceOrderOut = SnapshotOut<typeof MiscellaneousServiceOrder>;

export default MiscellaneousServiceOrder;
