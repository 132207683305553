import { CreateDriverDrawer } from 'common';

export const editDriverActionRender = ({ driver }: any) => {
  return {
    actionParams: {
      id: driver.id,
      type: 'edit',
    },
    component: CreateDriverDrawer,
  };
};
