import React from 'react';
import { Typography, Button } from '@shipmnts/pixel-hub';
import { ApolloError } from '@apollo/client/errors';
import { GraphQLError } from 'graphql';
const { Text } = Typography;

export const errorMessageHandlerGraphQL = (
  error: ApolloError | readonly GraphQLError[],
  onRetry?: () => void,
  hideMarginBottom?: boolean
) => {
  const errorStyle = hideMarginBottom ? {} : { marginBottom: '10px' };
  const graphQLErrors = error instanceof ApolloError ? error.graphQLErrors : error;
  return (
    <div style={errorStyle}>
      <Text type="danger">
        {Array.isArray(graphQLErrors) &&
          graphQLErrors.map(({ message }, i) => <span key={i}>{message}</span>)}
      </Text>
      {onRetry && (
        <Button size="small" type="link" onClick={onRetry}>
          Retry
        </Button>
      )}
    </div>
  );
};

export const errorMessageHandlerGraphQLString = (
  error: ApolloError | ApolloError[] | string,
  defaultErrorMessage = 'Something Went Wrong'
): string => {
  if (!error) return defaultErrorMessage;
  if (typeof error === 'string') return error;
  if (Array.isArray(error)) {
    const errorMessage = error.reduce((errorString, err) => {
      return `${errorString} ${err.message || ''}`;
    }, '');
    return errorMessage || defaultErrorMessage;
  } else if (typeof error === 'object') {
    return error?.message || defaultErrorMessage;
  }
  return defaultErrorMessage;
};

// TODO: Why do we have function name same as file and it is not even default export??
export const errorMessageHandler = (
  error?: { status: number; details: any[]; message: string } | string,
  defaultErrorMessage = 'Something Went Wrong'
): string => {
  if (!error) return 'Something Went Wrong';
  if (typeof error === 'string') return error;
  if (typeof error === 'object') {
    if (error.status) {
      if (error.status === 422) {
        if (error.details && error.details.length > 0) {
          const errorMessageArray: string[] = [];
          error.details.forEach((detail) => {
            if (detail.attribute && detail.messages && detail.messages.length > 0) {
              errorMessageArray.push(`${detail.attribute} ${detail.messages[0]}`);
            }
          });
          if (errorMessageArray.length <= 0) {
            if (error.message) return error.message;
            return defaultErrorMessage;
          } else {
            return errorMessageArray.join(',');
          }
        } else if (error.message) {
          return error.message;
        }
      } else if (error.message) {
        return error.message;
      }
    }
  }
  return defaultErrorMessage;
};
