import React, { useCallback, useMemo } from 'react';
import { SELF_CONSOLIDATION_KEY } from 'operations/modules/shipment/constants';
import { Drawer, Skeleton, Button } from '@shipmnts/pixel-hub';
import { startCase as _startCase } from 'lodash';
import { useApolloClient } from '@apollo/client';
import { useEmailTemplatesContext, errorMessageHandlerGraphQL } from 'common';
import ConsolShipmentFromHousesForm from './ConsolShipmentFromHousesForm';
import { LocationValue } from 'operations/models/Location';
import { ShipmentValue } from 'operations/models/Shipment';
import ShipmentCreditApproval from './ShipmentCreditApproval';
import {
  CREDIT_STATUS_ON_HOLD_EVENT,
  CREDIT_STATUS_APPROVED_EVENT,
} from 'operations/modules/shipment/constants';
import { CompassOutlined } from '@shipmnts/pixel-hub';
import SendDocumentForm from 'operations/modules/shipment/components/PreAlertAndDispatch/SendDocumentForm';

export interface PropsForShipmentAction {
  consol_type?: string;
  house_shipments?: ShipmentValue[];
  port_of_loading?: LocationValue;
  shipment_id?: string;
  job_number?: string;
  shipment_type?: string;
}

export type actionTypeForShipment =
  | 'create_consol_shipment'
  | 'send_pre_alert'
  | 'dispatch_document'
  | 'manually_approve_credit'
  | 'manually_on_hold_credit'
  | undefined;

const ShipmentActionDrawer = React.memo(function ShipmentActionDrawer(props: {
  activeAction: actionTypeForShipment;
  setActiveAction: (value: actionTypeForShipment) => void;
  refetchReport?: () => void;
  propsForActiveAction?: PropsForShipmentAction;
  drawerWidth?: string;
}): JSX.Element {
  const { activeAction, propsForActiveAction, setActiveAction, refetchReport } = props;
  const drawerWidth = props.drawerWidth || '60%';

  const onCloseDrawer = useCallback(() => {
    setActiveAction(undefined);
  }, [setActiveAction]);

  const { fetchingEmailTemplates, templates, fetchEmailTemplatesError, fetchTemplates } =
    useEmailTemplatesContext();

  const client = useApolloClient();
  const job_number = propsForActiveAction?.job_number;
  const shipment_id = propsForActiveAction?.shipment_id;
  const shipment_type = propsForActiveAction?.shipment_type?.toLowerCase();
  const renderActionForm = useMemo(() => {
    switch (activeAction) {
      case 'create_consol_shipment':
        if (
          !propsForActiveAction?.consol_type ||
          !propsForActiveAction?.house_shipments ||
          !propsForActiveAction?.port_of_loading
        )
          return null;
        return (
          <ConsolShipmentFromHousesForm
            consol_type={propsForActiveAction?.consol_type}
            onClose={onCloseDrawer}
            port_of_loading={propsForActiveAction?.port_of_loading}
            house_shipments={propsForActiveAction?.house_shipments}
            onSuccess={refetchReport}
          />
        );
      case 'send_pre_alert':
        if (!shipment_id || !job_number) return null;
        if (fetchingEmailTemplates) return <Skeleton />;
        if (fetchEmailTemplatesError)
          return (
            <span>
              {errorMessageHandlerGraphQL(fetchEmailTemplatesError)}
              <Button
                size="small"
                type="link"
                onClick={() => {
                  if (fetchTemplates) {
                    fetchTemplates();
                  }
                }}
              >
                Retry
              </Button>
            </span>
          );
        return (
          <SendDocumentForm
            shipment_id={shipment_id}
            job_number={job_number}
            shipment_type={shipment_type}
            templates={templates}
            docgen_url={process.env.DOCGEN_URL || ''}
            network_url={process.env.NETWORK_API_URL || ''}
            onClose={onCloseDrawer}
            onSuccess={refetchReport}
            primaryActionName="Send Pre alert"
            emailTemplateActionName="pre_alert_notification"
            emailModalTitle={`Send Pre-alert email for JOB # ${
              job_number ? job_number : shipment_id
            }`}
            defaultShipmentPartyType="destination_agent"
            client={client}
          />
        );
      case 'dispatch_document':
        if (!shipment_id || !job_number) return null;
        if (fetchingEmailTemplates) return <Skeleton />;
        if (fetchEmailTemplatesError)
          return (
            <span>
              {errorMessageHandlerGraphQL(fetchEmailTemplatesError)}
              <Button
                size="small"
                type="link"
                onClick={() => {
                  if (fetchTemplates) {
                    fetchTemplates();
                  }
                }}
              >
                Retry
              </Button>
            </span>
          );
        return (
          <SendDocumentForm
            shipment_id={shipment_id}
            job_number={job_number}
            shipment_type={shipment_type}
            templates={templates}
            docgen_url={process.env.DOCGEN_URL || ''}
            network_url={process.env.NETWORK_API_URL || ''}
            onClose={onCloseDrawer}
            onSuccess={refetchReport}
            primaryActionName="Dispatch"
            emailTemplateActionName="document_dispatch_notification"
            emailModalTitle={`Send Document Dispatch email for JOB # ${
              job_number ? job_number : shipment_id
            }`}
            defaultShipmentPartyType="customer"
            client={client}
            showDispatchDetails
          />
        );
      case CREDIT_STATUS_APPROVED_EVENT:
      case CREDIT_STATUS_ON_HOLD_EVENT:
        if (!shipment_id) return <span />;

        return (
          <ShipmentCreditApproval
            onSuccess={refetchReport}
            onClose={onCloseDrawer}
            shipment_id={shipment_id}
            shipment_type={shipment_type}
            job_number={job_number}
            action_name={activeAction}
          />
        );
      default:
        return <span />;
    }
  }, [
    activeAction,
    propsForActiveAction,
    onCloseDrawer,
    refetchReport,
    shipment_id,
    job_number,
    fetchingEmailTemplates,
    fetchEmailTemplatesError,
    fetchTemplates,
    shipment_type,
    templates,
    client,
  ]);

  const titleMapping: { [key: string]: string } = {
    create_consol_shipment: `Create ${
      propsForActiveAction?.consol_type === SELF_CONSOLIDATION_KEY
        ? 'Self'
        : _startCase(propsForActiveAction?.consol_type)
    } Consol Shipment`,
    send_pre_alert: `Send Pre-alert To Agent For Job # ${
      propsForActiveAction?.job_number
        ? propsForActiveAction?.job_number
        : propsForActiveAction?.shipment_id || ''
    }`,
    dispatch_document: `Dispatch Document For Job # ${job_number || shipment_id || ''}`,
    [CREDIT_STATUS_APPROVED_EVENT]: `Approve Credit - ${job_number}`,
    [CREDIT_STATUS_ON_HOLD_EVENT]: `Mark as On Hold - ${job_number}`,
  };

  if (!activeAction) return <span />;

  return (
    <Drawer
      title={
        <>
          {activeAction === 'create_consol_shipment' && <CompassOutlined />}{' '}
          {titleMapping[activeAction]}
        </>
      }
      width={drawerWidth}
      open={Boolean(activeAction)}
      onClose={onCloseDrawer}
    >
      {renderActionForm}
    </Drawer>
  );
});

export default ShipmentActionDrawer;
