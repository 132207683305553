import { RowNode } from '@ag-grid-community/core';
import CreateConsolFromFclHouses from 'operations/modules/reports/components/ShipmentReports/CreateConsolFromFclHouses';
import CreateConsolFromHouses from 'operations/modules/reports/components/ShipmentReports/CreateConsolFromAirOceanHouses';
import EditShipmentField from 'operations/modules/reports/components/ShipmentReports/ShipmentEditFieldAction';
import { LOAD_TYPE_FCL } from 'operations/baseConstants';
import { FREIGHT_TYPE_OCEAN, TRADE_TYPE_EXPORT } from 'operations/modules/shipment/constants';

export const createConsolShipmentRenderer = (
  selectedNodes: RowNode[],
  onSuccess: () => void,
  selectedNodeCounts: number,
  extraProps: any,
  onCloseModal: () => void
) => {
  return {
    actionParams: {
      selectedNodes: selectedNodes,
      onSuccess: onSuccess,
      selectedNodeCounts: selectedNodeCounts,
      onCloseModal: onCloseModal,
      consolType: extraProps.consolType,
    },
    component:
      extraProps?.freightType === FREIGHT_TYPE_OCEAN &&
      extraProps?.loadType === LOAD_TYPE_FCL &&
      extraProps.tradeType === TRADE_TYPE_EXPORT
        ? CreateConsolFromFclHouses
        : CreateConsolFromHouses,
  };
};

export const editShipmentFieldRenderer = (
  selectedNodes: RowNode[],
  onSuccess: () => void,
  selectedNodeCounts: number,
  extraProps: any,
  onCloseModal: () => void
) => {
  return {
    actionParams: {
      selectedNodes: selectedNodes,
      onSuccess: onSuccess,
      selectedNodeCounts: selectedNodeCounts,
      onCloseModal: onCloseModal,
      fieldName: extraProps.field_name,
      label: extraProps.label,
    },
    component: EditShipmentField,
  };
};
