import {
  DOCUMENT_TYPE_HBL,
  DOCUMENT_TYPE_MBL,
  DOCUMENT_TYPE_NEW_HAWB,
  DOCUMENT_TYPE_NEW_MAWB,
  DOCUMENT_TYPE_NEW_CONSOL_GENERAL_MANIFEST,
  DOCUMENT_TYPE_STUFFING_CONFIRMATION,
  DOCUMENT_TYPE_LOADING_CONFIRMATION,
  DOCUMENT_TYPE_AIR_FREIGHT_CERTIFICATE,
  DOCUMENT_TYPE_OCEAN_FREIGHT_CERTIFICATE,
  DOCUMENT_TYPE_CARGO_ARRIVAL_NOTICE,
  DOCUMENT_TYPE_AIR_CARGO_ARRIVAL_NOTICE,
  DOCUMENT_TYPE_TRANSPORT_DELIVERY_ORDER,
  DOCUMENT_TYPE_SEA_CARGO_MANIFEST,
  DOCUMENT_TYPE_OCEAN_DELIVERY_ORDER,
  DOCUMENT_TYPE_AIR_DELIVERY_ORDER,
  LOCATION_TAG_POD,
  LOCATION_TAG_POL,
  PORT_TYPE_ICD,
  PORT_TYPE_SEAPORT,
  SHOW_RATE,
  SHIPMENT_DOCUMENT_TYPE_HOUSE,
  DOCUMENT_TYPE_PROOF_OF_DELIVERY,
  DOCUMENT_TYPE_PRODUCT_LABEL,
  DOCUMENT_TYPE_ANNEXURE_C,
  DOCUMENT_TYPE_IMPORT_SECURITY_FILING,
  DOCUMENT_TYPE_CONSIGNMENT_NOTE,
} from '../../constants';
import {
  get as _get,
  uniq as _uniq,
  find as _find,
  compact as _compact,
  startCase as _startCase,
  keyBy as _keyBy,
  pick as _pick,
} from 'lodash';
import { safelyResolveDocumentField } from 'operations/helpers/resolveDocumentField';
import { getDocumentJson } from 'operations/helpers/documentJsonHelper';
import { Document } from 'operations/apis/document';
import { ShipmentValue } from 'operations/models/Shipment';
import { ShipmentContainerValue } from 'operations/models/ShipmentContainer';
import { ShipmentDocumentValue } from 'operations/models/ShipmentDocument';
import {
  SHIPMENT_TYPE_CONSOL,
  SHIPMENT_TYPE_BACK_TO_BACK,
  FREIGHT_CHARGE_TAG,
  SHIPMENT_TYPE_HOUSE,
  FREIGHT_TYPE_AIR,
} from 'operations/modules/shipment/constants';
import {
  getContainerDetails,
  getInvoiceDetails,
  getShippingBillDetails,
} from 'operations/helpers/transformFunctions';
import { FETCH_SHIPMENT_CONTAINERS } from 'operations/modules/booking/graphql/shipmentContainer';
import { ApolloClient } from '@apollo/client';
import { fetchGeneratedDocuments, GenerateDocumentParams } from './document';
import {
  fetchContainers,
  fetchShipmentEstimates,
  generateDeliveryOrderNumber,
} from 'operations/helpers/documentPrintHelper';
import { SessionDataValue } from 'operations/models/SessionData';
import { guid, dayjs } from '@shipmnts/pixel-hub';

interface SourceDataValue {
  shipment?: ShipmentValue;
  master_shipment?: ShipmentValue;
  shipment_containers?: ShipmentContainerValue[];
  shipment_document?: ShipmentDocumentValue;
  document: Document;
}

const setPartyInitialValues = (sourceData: SourceDataValue) => {
  const initialPayload: any = {};
  const resolveField = (field_name: string) => {
    return safelyResolveDocumentField({ field_name, data: sourceData });
  };
  const document_initial_values = getDocumentJson(sourceData.document, resolveField);
  Object.keys(document_initial_values).forEach((partyKey) => {
    const partyObj = document_initial_values[partyKey];
    const registeredName = _get(partyObj, 'party_company.registered_name');
    if (registeredName)
      initialPayload[`${partyKey}_print_address`] = `${
        registeredName ? registeredName + '\n' : ''
      }${_get(partyObj, 'party_address.print_address')}`;
  });
  return initialPayload;
};

function getBLSourceData(
  shipment: ShipmentValue,
  house_shipments: ShipmentValue[] | [],
  shipment_id: string,
  template: Document
) {
  const shipmentObj = [shipment, ...(house_shipments || [])].find((s) => s.id === shipment_id);
  const master_shipment = shipment;
  return { shipment: shipmentObj, master_shipment, document: template };
}

const getDefaultCompanyRegulatoryDetail = (type: string, user: SessionDataValue) => {
  const reg_detail = (
    _get(user?.company_account?.default_company, 'regulatory_details_data', []) || []
  ).find((rd: any) => rd.key === type);
  return _get(reg_detail, 'value');
};

function generateHBLDocument(data: {
  shipment: ShipmentValue;
  house_shipments: ShipmentValue[] | [];
  shipment_id: string;
  template: Document;
  user: SessionDataValue;
}) {
  const default_company = data?.user?.company_account?.default_company;
  let initialPayload: any = {};
  const sourceData = getBLSourceData(
    data?.shipment,
    data?.house_shipments,
    data?.shipment_id,
    data?.template
  );
  initialPayload = setPartyInitialValues(sourceData);
  initialPayload.place_of_issue = _get(sourceData.shipment, 'involved_branch.city.name');
  initialPayload.mtn_registraion_no = getDefaultCompanyRegulatoryDetail('MTO', data?.user);
  const issuing_agent = safelyResolveDocumentField({
    field_name: 'issuing_agent',
    data: sourceData,
  });
  initialPayload.signed_authority =
    _get(issuing_agent, 'party_company.registered_name', '') ||
    _get(default_company, 'registered_name', '');
  const fmc_address = getDefaultCompanyRegulatoryDetail('FMC_ADDRESS', data?.user);
  const fmc_number = getDefaultCompanyRegulatoryDetail('FMC_NO', data?.user);
  const fmc_logo_url = getDefaultCompanyRegulatoryDetail('FMC_LOGO_URL', data?.user);
  if (fmc_address) initialPayload.fmc_registered_address = fmc_address;
  if (fmc_number) initialPayload.fmc_number = fmc_number;
  if (fmc_logo_url) initialPayload.fmc_logo_url = fmc_logo_url;
  return initialPayload;
}

async function generateInitialCargoDescriptionMBL(
  shipments: ShipmentValue[],
  shipment: ShipmentValue,
  client: ApolloClient<object>
) {
  let cargo_description = '';

  const { data: containers, errors } = await client.query({
    query: FETCH_SHIPMENT_CONTAINERS,
    variables: { shipment_ids: [shipment.id] },
  });

  if (errors) throw errors;

  const shipment_containers = _get(containers, 'fetch_shipment_containers', []);

  const container_string = getContainerDetails({
    data: { shipment, shipment_containers },
  });

  if (container_string) {
    cargo_description += container_string;
    cargo_description += '\n\n';
  }
  const invoice_details: string[] = [];
  const shipping_bill_details: string[] = [];
  const commodity_descriptions: string[] = [];
  shipments?.forEach((shipment) => {
    const shipment_invoice_details = getInvoiceDetails({ data: { shipment }, onNewLine: true });
    shipment_invoice_details && invoice_details.push(shipment_invoice_details);
    const shipment_shipping_bill_details = getShippingBillDetails({
      data: { shipment },
      onNewLine: true,
    });
    shipment_shipping_bill_details && shipping_bill_details.push(shipment_shipping_bill_details);
    shipment.commodity_description && commodity_descriptions.push(shipment.commodity_description);
  });

  if (invoice_details.length > 0) {
    cargo_description += 'Invoice Number and Date \n';
    cargo_description += invoice_details.join('\n');
    cargo_description += '\n\n';
  }
  if (shipping_bill_details.length > 0) {
    cargo_description += 'Shipping Bill Number and Date \n';
    cargo_description += shipping_bill_details.join('\n');
    cargo_description += '\n\n';
  }

  if (commodity_descriptions.length > 0) {
    cargo_description += 'Commodity \n';
    cargo_description += _uniq(commodity_descriptions).join('\n');
    cargo_description += '\n\n';
  }

  if (!cargo_description) return '';
  return generateInitialComputedValue(cargo_description);
}

async function generateMBLDocument(data: {
  shipment: ShipmentValue;
  house_shipments: ShipmentValue[];
  shipment_id: string;
  template: Document;
  client: ApolloClient<object>;
}) {
  let initialPayload: any = {};
  const sourceData = getBLSourceData(
    data?.shipment,
    data?.house_shipments,
    data?.shipment_id,
    data?.template
  );
  initialPayload = setPartyInitialValues(sourceData);
  initialPayload.place_of_issue = _get(sourceData.shipment, 'involved_branch.city.name');

  initialPayload['cargo_description'] = await generateInitialCargoDescriptionMBL(
    data?.shipment?.shipment_type === SHIPMENT_TYPE_CONSOL
      ? data?.house_shipments
      : [data?.shipment],
    data?.shipment,
    data?.client
  );
  return initialPayload;
}

const getPortPrint = (portObj: any, fieldName: string) => {
  if (!portObj) return '';
  let code = portObj.iata_code;
  if (portObj.type === PORT_TYPE_SEAPORT || portObj.type === PORT_TYPE_ICD) code = portObj.unlocode;
  const portName = _get(portObj, fieldName || 'name', '');
  return [portName, code].filter((val) => !!val).join(', ');
};

function generateInitialComputedValue(value: string) {
  return JSON.stringify({
    blocks: [
      {
        key: Math.random().toString(36).substring(7),
        text: value,
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
    ],
    entityMap: {},
  });
}

function generateAwbDocument(data: {
  shipment: ShipmentValue;
  house_shipments: ShipmentValue[];
  document_type: string;
  shipment_id: string;
  template: Document;
  user: SessionDataValue;
}) {
  const eawb_subscribed = data?.user?.company_account?.subscriptions?.some(
    (item: any) => item.app && item.app.name === 'Eawb'
  );
  let shipmentObj = data?.shipment;
  const master_shipment = data?.shipment;
  let initialPayload: any = {};
  if (data?.document_type === DOCUMENT_TYPE_NEW_HAWB) {
    if (data?.house_shipments?.length > 0) {
      const findShipmentFromHouseShipments = data?.house_shipments.find(
        (shipment) => shipment.id === data?.shipment_id
      );
      if (findShipmentFromHouseShipments) {
        shipmentObj = findShipmentFromHouseShipments;
      }
    }
  }
  const sourceData = { shipment: shipmentObj, master_shipment, document: data?.template };
  initialPayload = setPartyInitialValues(sourceData);
  initialPayload.port_of_loading_print = getPortPrint(
    _get(
      shipmentObj?.shipment_routing_details?.find(
        (routing_detail) =>
          routing_detail.location_tags &&
          routing_detail?.location_tags.includes(LOCATION_TAG_POL.key)
      ),
      'location'
    ) || shipmentObj.port_of_loading,
    'city.name'
  );
  initialPayload.port_of_discharge_print = getPortPrint(
    _get(
      shipmentObj?.shipment_routing_details?.find(
        (routing_detail) =>
          routing_detail.location_tags &&
          routing_detail?.location_tags.includes(LOCATION_TAG_POD.key)
      ),
      'location'
    ) || shipmentObj.port_of_discharge,
    'city.name'
  );
  if (eawb_subscribed) {
    const shipment_properties = data?.shipment?.shipment_properties;
    const packages = data?.shipment?.shipment_packages;
    const product_rating = data?.shipment?.shipment_product_ratings?.[0];
    const properties = [];
    if (shipment_properties) {
      if (shipment_properties?.temp_controlled_details?.cooling_type === 'active') {
        properties.push('ACT');
      }
      if (shipment_properties?.temp_controlled_details?.cooling_type === 'dry_ice') {
        properties.push('ICE');
      }
      if (shipment_properties?.hazardous_details?.air_craft_type === 'cargo') {
        properties.push('CAO');
      }
      if (shipment_properties?.battery_details?.battery_type === 'li_on') {
        properties.push('ELI');
      }
      if (
        shipment_properties?.temp_controlled_details?.temperature_range === 'fifteen_to_twenty_five'
      ) {
        properties.push('CRT');
      }
      if (
        shipment_properties?.temp_controlled_details?.temperature_range === 'two_to_twenty_five'
      ) {
        properties.push('ERT');
      }
      if (shipment_properties?.hazardous_details?.air_craft_type === 'passenger') {
        properties.push('PAC');
      }
      if (shipment_properties?.is_perishable === true) {
        properties.push('PER');
      }
    }
    if (packages && packages.some((p: any) => p.per_piece_weight >= 150)) {
      properties.push('HEA');
    }
    if (product_rating && product_rating.cargo_type === 'uld') {
      properties.push('BUP');
    }
    initialPayload.special_handling_codes = properties;
  }
  initialPayload.agent_iata_code = getDefaultCompanyRegulatoryDetail('IATA', data?.user);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  initialPayload.awb_currency = data?.user?.company_account?.default_currency;
  initialPayload.charge_code = 'PP';
  initialPayload.declared_carriage_value = 'NVD';
  initialPayload.declared_customs_value = 'NCV';
  initialPayload.declared_insurance_value = 'XXX';
  initialPayload.accounting_information = generateInitialComputedValue('FREIGHT PREPAID ');
  initialPayload.handling_information = generateInitialComputedValue(
    'PLEASE NOTIFY CONSIGNEE IMMEDIATELY ON ARRIVAL OF CARGO '
  );
  initialPayload.nature_and_quantity = generateInitialComputedValue('SAID TO CONTAIN ');
  initialPayload.awb_issue_place = _get(shipmentObj, 'involved_branch.city.name');
  initialPayload.shipper_sign = _get(
    safelyResolveDocumentField({ field_name: 'issuing_agent', data: sourceData }),
    'party_company.registered_name'
  );
  initialPayload.carrier_sign_auto = true;
  initialPayload.rate_print_option = SHOW_RATE.key;
  initialPayload.rating_information_print_option = SHOW_RATE.key;
  return initialPayload;
}

export const generateConsolDocumentData = (payload: GenerateDocumentParams) => {
  const { shipment, house_shipments } = payload;
  let initialValues: any = {
    shipment_type: 'T',
    house_table: {},
    master_shipment_type: shipment.shipment_type,
  };
  if (shipment.shipment_type === SHIPMENT_TYPE_BACK_TO_BACK) {
    const shipment_document = _find(_get(shipment, 'shipment_documents', []), {
      document_type: SHIPMENT_DOCUMENT_TYPE_HOUSE,
    });
    if (shipment_document)
      initialValues.house_table[shipment_document.id] = {
        shipment_type: 'T',
        nature_and_quantity: shipment.commodity_description,
        shipment_number: shipment_document.shipment_number,
      };
  }
  if (
    shipment.shipment_type === SHIPMENT_TYPE_BACK_TO_BACK ||
    shipment.shipment_type === SHIPMENT_TYPE_CONSOL
  ) {
    initialValues.nature_and_quantity = 'CONSOL';
  } else {
    initialValues.nature_and_quantity = shipment.commodity_description;
  }

  if (house_shipments.length) {
    initialValues = house_shipments.reduce((obj, houseShipment) => {
      const house_document = (houseShipment.shipment_documents || []).find(
        (doc) => doc.document_type === SHIPMENT_DOCUMENT_TYPE_HOUSE
      );
      obj.house_table[houseShipment.id] = {
        shipment_type: 'T',
        nature_and_quantity: houseShipment.commodity_description,
        shipment_number: house_document?.shipment_number,
      };
      return obj;
    }, initialValues);
  }
  return initialValues;
};

export const generateStuffingLoadingConfirmation = (payload: GenerateDocumentParams) => {
  const { template, shipment, house_shipments, shipment_id } = payload;
  let initialPayload = {};
  let shipmentObj = shipment;
  const master_shipment = shipment;
  if (house_shipments.length > 0)
    shipmentObj = house_shipments.find((shipment) => shipment.id === shipment_id) || shipment;
  const sourceData = { shipment: shipmentObj, document: template, master_shipment };
  initialPayload = setPartyInitialValues(sourceData);
  return initialPayload;
};

export const generateFreightCertificateDocument = async (payload: GenerateDocumentParams) => {
  const { shipment, house_shipments, shipment_id, client, user } = payload;
  let shipmentObj = shipment;
  if (house_shipments.length > 0)
    shipmentObj = house_shipments.find((shipment) => shipment.id === shipment_id) || shipment;
  const { shipment_estimates } = await fetchShipmentEstimates({
    shipment_ids: [shipmentObj.id],
    client,
  });
  let freight_item = {};
  if (shipment_estimates) {
    const freight_estimate = shipment_estimates.find((e: any) => e.tag === FREIGHT_CHARGE_TAG);
    if (freight_estimate && freight_estimate.sell_rate > 0) {
      freight_item = {
        description: freight_estimate.item,
        currency: freight_estimate.sell_currency,
        charges_from_estimates: [freight_estimate.id],
      };
    }
  }
  const initialPayload: any = {};
  initialPayload.prepared_by = _compact([_startCase(user.first_name), user.last_name]).join(' ');
  initialPayload.prepared_on = new Date().toLocaleDateString('en-GB');
  const id = guid();
  initialPayload.charge_details = {
    [id]: {
      id,
      ...freight_item,
    },
  };
  return initialPayload;
};

const getCANDOinitialValues = ({
  sourceData,
  user,
}: {
  sourceData: SourceDataValue;
  user: SessionDataValue;
}) => {
  const { shipment, master_shipment } = sourceData;
  const defaultCompany = user?.company_account?.default_company;
  const notify_party_key = [SHIPMENT_TYPE_BACK_TO_BACK, SHIPMENT_TYPE_HOUSE].includes(
    shipment?.shipment_type || ''
  )
    ? 'notify_party_1_house'
    : 'notify_party_1';

  const shipmentInitialValues: any = setPartyInitialValues(sourceData);
  if (shipment?.freight_type === FREIGHT_TYPE_AIR) {
    shipmentInitialValues.port_of_loading_print = getPortPrint(
      shipment?.getShipmentPortByType(LOCATION_TAG_POL.key) || shipment?.port_of_loading,
      'city.name'
    );
    shipmentInitialValues.port_of_discharge_print = getPortPrint(
      shipment?.getShipmentPortByType(LOCATION_TAG_POD.key) || shipment?.port_of_discharge,
      'city.name'
    );
  }

  return {
    ...shipmentInitialValues,
    issuing_agent: {
      company: defaultCompany,
      address: _get(master_shipment, ['involved_branch', 'default_address']),
    },
    issuing_agent_print_address: _get(master_shipment, [
      'involved_branch',
      'default_address',
      'print_address',
    ]),
    notify_party_key,
    issuing_agent_logo: _get(defaultCompany, ['logo']),
    place_of_carrier_delivery_terminal_print: _get(master_shipment, [
      'place_of_carrier_delivery_terminal',
      'address',
    ]),
    cargo_description: _get(shipment, 'commodity_description'),
  };
};

export const generateCargoArrivalNotice = (payload: GenerateDocumentParams) => {
  const { shipment, house_shipments, shipment_id, template, user } = payload;
  let shipmentObj = shipment;
  if (
    _get(shipment, 'shipment_type') === SHIPMENT_TYPE_CONSOL &&
    _get(shipment, 'id') !== shipment_id
  ) {
    shipmentObj = house_shipments.find((shipment) => shipment.id === shipment_id) || shipment;
  }
  const master_shipment = shipment;
  const sourceData = { shipment: shipmentObj, master_shipment, document: template };
  return { ...getCANDOinitialValues({ sourceData, user }) };
};

export const generateTransportDeliveryOrder = (payload: GenerateDocumentParams) => {
  const { shipment, house_shipments, shipment_id, template, shipment_document, user } = payload;
  let shipmentObj = shipment;
  if (
    _get(shipment, 'shipment_type') === SHIPMENT_TYPE_CONSOL &&
    _get(shipment, 'id') !== shipment_id
  ) {
    shipmentObj = house_shipments.find((shipment) => shipment.id === shipment_id) || shipment;
  }
  const master_shipment = shipment;
  const sourceData = {
    shipment: shipmentObj,
    master_shipment,
    document: template,
    shipment_document,
  };
  const consignee_on_document = safelyResolveDocumentField({
    field_name: 'consignee_on_document',
    data: sourceData,
  });
  const consignee = safelyResolveDocumentField({
    field_name: consignee_on_document,
    data: sourceData,
  });
  const transporter = shipmentObj.shipment_parties?.find(
    (p) => p.name === 'destination_transport_agent'
  );
  let transporterParty = {};
  if (transporter) {
    transporterParty = {
      transporter: {
        company: _get(transporter, 'party_company'),
        address: _get(transporter, 'party_address'),
      },
      transporter_print_address: `${_get(transporter, 'party_company.registered_name', '')}\n${_get(
        transporter,
        'party_address.print_address',
        ''
      )}`,
    };
  }
  const initialPayload = {
    ...getCANDOinitialValues({ sourceData, user }),
    deliver_to_party: {
      company: _get(consignee, 'party_company'),
      address: _get(consignee, 'party_address'),
    },
    deliver_to_party_print_address: `${_get(
      consignee,
      'party_company.registered_name',
      ''
    )}\n${_get(consignee, 'party_address.print_address', '')}`,
    ...transporterParty,
  };
  return initialPayload;
};

export async function generateProofOfDelivery(payload: GenerateDocumentParams) {
  const { shipment } = payload;
  return {
    general_description: shipment.commodity_description,
    special_instructions: shipment.remarks,
  };
}

export async function generateProductLabel(payload: GenerateDocumentParams) {
  const { shipment } = payload;
  const document_types = [DOCUMENT_TYPE_PROOF_OF_DELIVERY];
  const fields = FIELDS[DOCUMENT_TYPE_PRODUCT_LABEL];
  const shipment_ids = [shipment.id];
  const data = await fetchGeneratedDocuments({
    shipment_ids,
    document_types,
    fields,
  });

  try {
    if (data.response) {
      const { documents } = data.response.data;
      const podDocument = _find(documents, { document_type: DOCUMENT_TYPE_PROOF_OF_DELIVERY });
      if (!podDocument) return {};
      const resolvedObj: { [key: string]: string } = {};
      ['special_instructions', 'general_description'].forEach((field) => {
        resolvedObj[field] = safelyResolveDocumentField({
          field_name: field,
          data: { document: podDocument },
        });
      });
      return resolvedObj;
    } else {
      throw data.error;
    }
  } catch (error) {
    console.error(error);
    return {};
  }
}

export async function generateSeaCargoManifest(payload: GenerateDocumentParams) {
  const { shipment, client } = payload;
  let { house_shipments } = payload;
  const document_types = [DOCUMENT_TYPE_HBL];
  const fields = FIELDS[DOCUMENT_TYPE_SEA_CARGO_MANIFEST];
  if (shipment.shipment_type === SHIPMENT_TYPE_BACK_TO_BACK) house_shipments = [shipment];
  const shipment_ids = house_shipments.map((s) => s.id);
  const data = await fetchGeneratedDocuments({
    shipment_ids,
    document_types,
    fields,
  });

  const { containers, errors } = await fetchContainers({ shipment_ids, client });
  if (errors) throw errors;

  const idWiseHouseShipments = _keyBy(house_shipments, 'id');
  const shipmentIdWiseContainers = containers.reduce((acc: any, container: any) => {
    container.container_cargo_details.forEach((ccd: any) => {
      if (ccd.shipment_id in acc) {
        acc[ccd.shipment_id].push(container);
      } else {
        acc[ccd.shipment_id] = [container];
      }
    });
    return acc;
  }, {});
  try {
    if (data.response) {
      const { documents, rules, document_clauses } = data.response.data;
      const resolvedValue = documents.reduce((obj: any, currDoc: any) => {
        const { shipment_id } = currDoc;
        if (!obj[shipment_id]) obj[shipment_id] = {};
        Object.keys(currDoc.document_field).forEach((entity) => {
          const house_shipment = idWiseHouseShipments[shipment_id + ''];
          const shipment_containers = shipmentIdWiseContainers[shipment_id + ''];
          obj[shipment_id][entity] = safelyResolveDocumentField({
            field_name: entity,
            data: {
              document: { ...currDoc, rules, document_clauses },
              master_shipment: shipment,
              shipment: house_shipment,
              shipment_document: house_shipment.getShipmentDocument(SHIPMENT_DOCUMENT_TYPE_HOUSE),
              shipment_containers,
            },
          });
        });
        obj[shipment_id]['cargo_description'] = `${obj[shipment_id]['cargo_description'] || ''}\n${
          obj[shipment_id]['auto_applied_cargo_description'] || ''
        }`;
        return obj;
      }, {});
      return { hbl_details: resolvedValue };
    } else {
      throw data.error;
    }
  } catch (error) {
    console.error(error);
    return {};
  }
}

export async function generateDeliveryOrder(payload: GenerateDocumentParams) {
  const { shipment, house_shipments, shipment_id, template, client } = payload;
  let shipmentObj = shipment;
  if (
    _get(shipment, 'shipment_type') === SHIPMENT_TYPE_CONSOL &&
    _get(shipment, 'id') !== shipment_id
  ) {
    shipmentObj = house_shipments.find((shipment) => shipment.id === shipment_id) || shipment;
  }

  if (!shipmentObj.delivery_order_number || shipmentObj.delivery_order_number === '') {
    await generateDeliveryOrderNumber({ shipment_id: shipmentObj.id, client });
  }

  let cargo_description = _get(shipmentObj, 'commodity_description');
  if (
    _get(shipment, 'shipment_type') === SHIPMENT_TYPE_CONSOL &&
    _get(shipment, 'id') === shipment_id
  ) {
    cargo_description = house_shipments.map((sp) => _get(sp, 'commodity_description')).join('\n');
  }
  const master_shipment = shipment;
  const sourceData = { shipment: shipmentObj, master_shipment, document: template };
  const initialPayload = {
    ...setPartyInitialValues(sourceData),
    date_of_issue: dayjs().unix(),
    validity_date:
      shipment.estimated_time_of_arrival &&
      dayjs.unix(shipment.estimated_time_of_arrival).add(15, 'days').unix(),
    cargo_description: cargo_description,
    place_of_carrier_delivery_terminal_print: _get(master_shipment, [
      'place_of_carrier_delivery_terminal',
      'address',
    ]),
  };
  return initialPayload;
}

export const FIELDS = {
  [DOCUMENT_TYPE_SEA_CARGO_MANIFEST]: [
    {
      document_field: [
        'cargo_description',
        'marks_and_nos',
        'shipper_print_address',
        'consignee_print_address',
        'notify_party_1_print_address',
        'notify_party_2_print_address',
        'notify_party_3_print_address',
        'notify_party_4_print_address',
        'notify_same_as_consignee',
        'auto_apply_clause_group',
        'auto_applied_cargo_description',
        // All transform type fields
        'movement_type',
        'container_details',
        'temperature_details',
        'total_number_of_packages',
        'invoice_details',
        'shipping_bill_detaills',
      ],
    },
    'shipment_id',
    'print_option',
    'template_id',
    'rules',
    'document_clauses',
  ],
  [DOCUMENT_TYPE_PRODUCT_LABEL]: [
    {
      document_field: ['special_instructions', 'general_description'],
    },
    'document_type',
  ],
};

export async function generateAnnexureCPayload(payload: GenerateDocumentParams) {
  const { shipment, user } = payload;

  const parties = JSON.parse(JSON.stringify(shipment.shipment_parties));
  const consignee = parties.filter((p: any) => p.name === 'consignee')[0];

  const party_details = [
    {
      party_type: 'Exporter',
      party_company: user?.company_account?.default_company,
      party_address: user?.branch_accounts?.[0]?.default_address,
      party_details:
        user?.company_account?.default_company.registered_name +
        '\n' +
        user?.branch_accounts?.[0]?.default_address.print_address,
    },
    {
      party_type: 'Manufacturer',
      party_company: user?.company_account?.default_company,
      party_address: user?.branch_accounts?.[0]?.default_address,
      party_details:
        user?.company_account?.default_company.registered_name +
        '\n' +
        user?.branch_accounts?.[0]?.default_address.print_address,
    },
    {
      party_type: 'Consignee',
      party_company: consignee.party_company,
      party_address: consignee.party_address,
      party_details:
        consignee?.party_company?.registered_name + '\n' + consignee.party_address.print_address,
    },
  ];

  let container_details = shipment?.shipment_containers?.filter(
    (c: any) => c.commercial_invoice_number
  );

  container_details = container_details?.map((c: any) => {
    const container: any = _pick(c, [
      'id',
      'container_number',
      'container_type',
      'carrier_seal_number',
      'shipper_seal_number',
      'container_cargo_details',
      'commercial_invoice_number',
    ]);
    container['packages'] = container['container_cargo_details'][0].package_str;
    return container;
  });

  const linked_containers = (container_details || []).map((container: any) => container?.id);

  const shipment_invoices = JSON.parse(JSON.stringify(shipment.shipment_invoices));

  const container_linked_invoice_numbers: string[] = [];
  const container_linked_invoice_dates: string[] = [];

  container_details
    ?.filter((c: any) => c.commercial_invoice_number)
    .forEach((container: any) => {
      const linked_invoices = container.commercial_invoice_number.split(', ');
      linked_invoices.forEach((inv: any) => {
        if (!container_linked_invoice_numbers.includes(inv)) {
          const invoices = shipment_invoices.filter((i: any) => i.invoice_number === inv);
          if (invoices[0]?.invoice_number) {
            container_linked_invoice_numbers.push(invoices[0].invoice_number);
            container_linked_invoice_dates.push(
              dayjs.unix(invoices[0].invoice_date).format('DD/MM/YYYY')
            );
          }
        }
      });
    });

  const invoice_number = container_linked_invoice_numbers.join(', ');
  const invoice_date = container_linked_invoice_dates.join(', ');

  const initialPayload = {
    party_details,
    container_details,
    linked_containers,
    invoice_number,
    invoice_date,
  };

  return initialPayload;
}

export async function generateImportSecurityFilingPayload(payload: GenerateDocumentParams) {
  const { shipment } = payload;

  return {
    final_place_of_delivery_print: shipment?.final_place_of_delivery_print,
    commodity_description: shipment?.commodity_description,
  };
}

export async function generateConsignmentNotePayload(data: GenerateDocumentParams) {
  const sourceData = getBLSourceData(
    data?.shipment,
    data?.house_shipments,
    data?.shipment_id,
    data?.template
  );
  return {
    place_of_issue: _get(sourceData.shipment, 'involved_branch.city.name'),
  };
}

export const DOC_TYPE_GENERATE_FUNCTION_MAPPING = {
  [DOCUMENT_TYPE_NEW_HAWB]: generateAwbDocument,
  [DOCUMENT_TYPE_NEW_MAWB]: generateAwbDocument,
  [DOCUMENT_TYPE_MBL]: generateMBLDocument,
  [DOCUMENT_TYPE_HBL]: generateHBLDocument,
  [DOCUMENT_TYPE_NEW_CONSOL_GENERAL_MANIFEST]: generateConsolDocumentData,
  [DOCUMENT_TYPE_STUFFING_CONFIRMATION]: generateStuffingLoadingConfirmation,
  [DOCUMENT_TYPE_LOADING_CONFIRMATION]: generateStuffingLoadingConfirmation,
  [DOCUMENT_TYPE_AIR_FREIGHT_CERTIFICATE]: generateFreightCertificateDocument,
  [DOCUMENT_TYPE_OCEAN_FREIGHT_CERTIFICATE]: generateFreightCertificateDocument,
  [DOCUMENT_TYPE_CARGO_ARRIVAL_NOTICE]: generateCargoArrivalNotice,
  [DOCUMENT_TYPE_AIR_CARGO_ARRIVAL_NOTICE]: generateCargoArrivalNotice,
  [DOCUMENT_TYPE_TRANSPORT_DELIVERY_ORDER]: generateTransportDeliveryOrder,
  [DOCUMENT_TYPE_SEA_CARGO_MANIFEST]: generateSeaCargoManifest,
  [DOCUMENT_TYPE_OCEAN_DELIVERY_ORDER]: generateDeliveryOrder,
  [DOCUMENT_TYPE_AIR_DELIVERY_ORDER]: generateDeliveryOrder,
  [DOCUMENT_TYPE_PROOF_OF_DELIVERY]: generateProofOfDelivery,
  [DOCUMENT_TYPE_PRODUCT_LABEL]: generateProductLabel,
  [DOCUMENT_TYPE_ANNEXURE_C]: generateAnnexureCPayload,
  [DOCUMENT_TYPE_IMPORT_SECURITY_FILING]: generateImportSecurityFilingPayload,
  [DOCUMENT_TYPE_CONSIGNMENT_NOTE]: generateConsignmentNotePayload,
};
