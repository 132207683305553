import React from 'react';

import { BugFilled } from '@shipmnts/pixel-hub';
import * as Sentry from '@sentry/react';
import { Result, Button } from '@shipmnts/pixel-hub';

const ErrorBoundary = (props: {
  children: React.ReactNode;
  fallback?: React.ReactElement | Sentry.FallbackRender;
}) => {
  return (
    <Sentry.ErrorBoundary
      fallback={
        props.fallback ? (
          props.fallback
        ) : (
          <Result
            status="500"
            title="Sorry, Something went terribly wrong."
            subTitle="Don't worry our team has been notified and is working on solving the issue."
            extra={
              <>
                <Button icon={<BugFilled />} onClick={Sentry.showReportDialog}>
                  Report Feedback
                </Button>
                <Button type="primary" onClick={() => window.location.reload()}>
                  Reload
                </Button>
              </>
            }
          />
        )
      }
    >
      {props.children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
