import { MessageValue } from 'operations/models/Message';
import {
  CREATE_SHIPMENT_ACTION,
  DISCARD_MESSAGE_ACTION,
  ASSIGN_TO_ACTION,
} from 'operations/modules/reports/components/MessageReport/InboxMessageActionDrawer';
import InboxMessageActions from 'operations/modules/reports/components/MessageReport/InboxMessageActions';
import { SessionDataValue } from 'operations/models/SessionData';
import { EmailProps } from 'operations/components/ChangeEvent/EventEmailDrawer';

// import { EmailProps } from 'operations/modules/shipment/components/Tracking/ChangeEvent/EventEmailDrawer'

interface MessageActionType {
  message: MessageValue;
  setEmailProps: (emailProps: EmailProps) => void;
  setVisible: (visible: boolean) => void;
  sessionData: SessionDataValue;
  extraProps?: any;
  navigate: any;
}

export const discardMessageRenderer = (payload: MessageActionType) => {
  const { message } = payload;
  return {
    actionParams: {
      messageRow: message,
      actionName: DISCARD_MESSAGE_ACTION,
    },
    component: InboxMessageActions,
  };
};
export const assignToMessageRenderer = (payload: MessageActionType) => {
  const { message } = payload;
  return {
    actionParams: {
      messageRow: message,
      actionName: ASSIGN_TO_ACTION,
    },
    component: InboxMessageActions,
  };
};

export const createShipmentFromMessageRenderer = (payload: MessageActionType) => {
  const { message } = payload;
  return {
    actionParams: {
      messageRow: message,
      actionName: CREATE_SHIPMENT_ACTION,
    },
    component: InboxMessageActions,
  };
};
