import React, { useEffect, useState } from 'react';
import { GlobalSearch, Modal, message } from '@shipmnts/pixel-hub';
import { errorMessageHandler, useApplicationContentContext, useSession } from 'common';
import { useApolloClient } from '@apollo/client';
import { fetchSourceData } from 'operations/helpers/documentPrintHelper';
import { openPrintWindow } from 'operations/helpers/printHTMLhelper';
import { LETTER_HEAD_TEMPLATE_TYPE } from 'operations/baseConstants';
import { TemplateValue } from 'operations/models/Template';
import { fetchDocument } from 'operations/apis/document';
import Spinner from 'src/components/Spinner';

type Props = {
  document_id: string;
  onClose: () => void;
  width?: any;
  height?: any;
};
const DocumentPrintPreview = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [htmlUrl, setHtmlUrl] = useState('');
  const [showLetterHead, setShowLetterHead] = useState<boolean>(false);
  const [letterHead, setLetterHead] = useState<TemplateValue>();
  const [document, setDocument] = useState([]);
  const sessionData = useSession();
  const client = useApolloClient();
  const [HTML, setHTML] = useState<string>('');
  const { document_id, onClose } = props;
  const { config_data } = useApplicationContentContext();
  useEffect(() => {
    async function getFinalHTML() {
      setLoading(true);
      let html_url;
      const { response: docResponse, error: docError } = await fetchDocument(document_id);
      let document = undefined;
      if (docError) {
        const resolvedError = errorMessageHandler(docError as string);
        message.error(resolvedError);
      } else {
        document = docResponse?.data?.generate_document;
        setDocument(document);
      }
      const response = await fetchSourceData({
        document_id,
        sessionData,
        client,
        config_data,
        letterHead,
      });
      if ('getHTML' in response) {
        const html = response.getHTML();
        if (!html) {
          message.error('cannot print this document');
          setLoading(false);
          return;
        }
        setHTML(html);
        const blob = new Blob([html], {
          type: 'text/html',
        });
        html_url = window.URL.createObjectURL(blob);
        setHtmlUrl(html_url);
      }
      setLoading(false);
    }
    getFinalHTML();
  }, [client, sessionData, document_id, config_data, letterHead]);

  useEffect(() => {
    const canShowLetterHead = () => {
      if (
        // FIX ME
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        document?.document_type === 'vgm_certificate' &&
        !['manufacturer', 'trader'].includes(sessionData.company_account.primary_business)
      )
        return false;
      // FIX ME
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      if (document?.dependencies?.includes('letter_head')) return true;
      return false;
    };
    setShowLetterHead(canShowLetterHead());
  }, [document, sessionData]);

  const onCloseInternal = () => {
    if (onClose) onClose();
  };

  return (
    <Modal
      open
      className="print-preview-modal"
      onOk={() => {
        openPrintWindow({ html: HTML, file_name: 'print' });
        if (onClose) onClose();
      }}
      okText="Print"
      onCancel={onCloseInternal}
      width={props.width}
    >
      <>
        {loading && (
          <div className="align-center">
            <Spinner spinning={loading} tip="Loading preview" />
          </div>
        )}
        {showLetterHead && (
          <GlobalSearch
            doc_type="Network::Template"
            // FIX ME
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            value={letterHead}
            showCreation={false}
            extraProps={{
              prefillDefault: true,
            }}
            searchProps={{
              template_type: LETTER_HEAD_TEMPLATE_TYPE,
              resource_type: 'Network::CompanyAccount',
            }}
            onChange={(template?: TemplateValue) => setLetterHead(template)}
            style={{ margin: '0 0 8px 8px' }}
          />
        )}
        <div style={{ margin: '0px', padding: '0px', height: '100%' }}>
          <iframe
            title="Print Preview"
            id="print_preview"
            frameBorder="0"
            style={{ overflow: 'hidden', height: '100%', width: '100%' }}
            height="100%"
            width="100%"
            src={htmlUrl}
          />
        </div>
      </>
    </Modal>
  );
};

export default DocumentPrintPreview;
