import { types, Instance, SnapshotIn, SnapshotOut, IAnyModelType } from 'mobx-state-tree';
import Shipment from 'operations/models/Shipment';
import Location from 'operations/models/Location';
import TrackingEvent from './TrackingEvent';
import Activity from './Activity';
import { BILL_OF_ENTRY_TYPE, SHIPPING_BILL_TYPE } from 'operations/modules/shipment/constants';

const ShipmentCustomDetail = types
  .model({
    id: types.identifier,
    custom_document_number: types.maybe(types.maybeNull(types.string)),
    custom_document_date: types.maybe(types.maybeNull(types.number)),
    shipment_id: types.maybe(types.maybeNull(types.string)),
    shipment: types.maybe(types.maybeNull(types.late((): IAnyModelType => Shipment))),
    next_actions: types.maybe(types.array(types.string)),
    filing_type: types.maybe(types.maybeNull(types.string)),
    duty_free: types.maybe(types.maybeNull(types.boolean)),
    duty_payment_by: types.maybe(types.maybeNull(types.string)),
    custom_clearance_location_id: types.maybe(types.maybeNull(types.string)),
    custom_clearance_location: types.maybe(
      types.maybeNull(types.late((): IAnyModelType => Location))
    ),
    cargo_value: types.maybe(types.maybeNull(types.number)),
    insurance_value: types.maybe(types.maybeNull(types.number)),
    freight_value: types.maybe(types.maybeNull(types.number)),
    cargo_gross_weight: types.maybe(types.maybeNull(types.number)),
    cargo_net_weight: types.maybe(types.maybeNull(types.number)),
    cargo_volume: types.maybe(types.maybeNull(types.number)),
    duty_amount: types.maybe(types.maybeNull(types.number)),
    exchange_rate: types.maybe(types.maybeNull(types.number)),
    currency: types.maybe(types.maybeNull(types.string)),
    due_date_to_pay_duty: types.maybe(types.maybeNull(types.number)),
    filing_valid_till: types.maybe(types.maybeNull(types.number)),
    bank_realisation_cutoff_date: types.maybe(types.maybeNull(types.number)),
    scroll_date: types.maybe(types.maybeNull(types.number)),
    reward_scheme: types.maybe(types.array(types.string)),
    duty_benefits: types.maybe(types.maybeNull(types.number)),
    reward_amount: types.maybe(types.maybeNull(types.number)),
    duty_paid_amount: types.maybe(types.maybeNull(types.number)),
    drawback_bank_account: types.maybe(types.maybeNull(types.string)),
    forex_bank_account: types.maybe(types.maybeNull(types.string)),
    last_action_status: types.maybe(types.maybeNull(types.string)),
    tracking_events: types.maybe(
      types.maybeNull(types.array(types.late((): IAnyModelType => TrackingEvent)))
    ),
    bill_of_entry_type: types.maybe(types.maybeNull(types.enumeration(BILL_OF_ENTRY_TYPE))),
    shipping_bill_type: types.maybe(types.maybeNull(types.enumeration(SHIPPING_BILL_TYPE))),
    assessable_value: types.maybe(types.maybeNull(types.number)),
    fob_value: types.maybe(types.maybeNull(types.number)),
    cif_value: types.maybe(types.maybeNull(types.number)),
    invoice_value: types.maybe(types.maybeNull(types.number)),
    country: types.maybe(types.maybeNull(types.string)),
    trade_type: types.maybe(types.maybeNull(types.string)),
    last_comment: types.maybe(types.maybeNull(Activity)),
    last_status_update: types.maybe(types.maybeNull(Activity)),
    warehouse_location_id: types.maybe(types.maybeNull(types.string)),
    warehouse_location: types.maybe(types.maybeNull(types.late((): IAnyModelType => Location))),
    transhipment_permission_no: types.maybe(types.maybeNull(types.string)),
    transhipment_permission_date: types.maybe(types.maybeNull(types.number)),
    rewarehousing_certificate_no: types.maybe(types.maybeNull(types.string)),
    rewarehousing_certificate_date: types.maybe(types.maybeNull(types.number)),
    warehouse_be_no: types.maybe(types.maybeNull(types.string)),
    current_queue: types.maybe(types.maybeNull(types.string)),
    warehouse_be_date: types.maybe(types.maybeNull(types.number)),
    import_duty_free_no: types.maybe(types.maybeNull(types.string)),
    import_duty_free_date: types.maybe(types.maybeNull(types.number)),
    shipment_invoices: types.maybe(
      types.maybeNull(
        types.array(
          types.model({
            id: types.identifier,
            shipment_custom_detail_id: types.maybe(types.maybeNull(types.string)),
            invoice_number: types.maybe(types.maybeNull(types.string)),
            invoice_currency: types.maybe(types.maybeNull(types.string)),
            fob_invoice_amount: types.maybe(types.maybeNull(types.number)),
            cif_invoice_amount: types.maybe(types.maybeNull(types.number)),
            invoice_amount: types.maybe(types.maybeNull(types.number)),
            exchange_rate: types.maybe(types.maybeNull(types.number)),
          })
        )
      )
    ),
  })
  .views((self) => ({}));

export type ShipmentCustomDetailValue = Instance<typeof ShipmentCustomDetail>;
export type ShipmentCustomDetailIn = SnapshotIn<typeof ShipmentCustomDetail>;
export type ShipmentCustomDetailOut = SnapshotOut<typeof ShipmentCustomDetail>;

export default ShipmentCustomDetail;
