import React from 'react';
import { ContactsCard } from '../Cards/ContactsCard';
import { AddressCard } from '../Cards/AddressCard';

export const ContactsTab = () => {
  return (
    <div
      style={{
        width: '70%',
        marginInline: 'auto',
        marginBlock: '1em 2em',
      }}
    >
      <ContactsCard />
      <br />
      <AddressCard />
    </div>
  );
};
