import React from 'react';
import { DISABLE_CELL_STYLE, RequireHeaderWrapper } from './helpers';
import { Checkbox, dayjsGenerateConfig } from '@shipmnts/pixel-hub';
import { VehicleValue } from 'operations/models/Vehicle';
import { VEHICLE_CATEGORY } from 'operations/modules/vehicle/constants';
import { Column, VehicleLoadSearch } from 'common';
import {
  DIMENSION_CBM,
  DIMENSION_CMS,
  DIMENTION_INCH,
  VOLUME_UNIT_CBM,
  WEIGHT_UNIT_KGS,
  WEIGHT_UNIT_MTS,
  oceanPackageTypes,
} from 'operations/baseConstants';
import {
  ShipmentContainerValue,
  validateContainerNumber,
} from 'operations/models/ShipmentContainer';
import { ASSET_TYPES } from './constants';
import { ASSET_TYPE_OWN } from '../../constants';
import { ValueFormatterParams } from '@ag-grid-community/core';
import { ERROR_CELL_STYLE } from '../../helpers/FormHelpers';
import { GlobalSearch } from '@shipmnts/pixel-hub';

export const allFields: { [key: string]: (...args: any[]) => Column } = {
  container_number: ({
    pinned,
    editable,
  }: {
    pinned: Column['pinned'];
    editable: Column['editable'];
  }) => ({
    headerName: 'Container Number',
    field: 'container_number',
    pinned: pinned,
    editable: editable,
    valueSetter: (params) => {
      const is_non_iso_container = params.data.is_non_iso_container;
      const container_number = params.newValue;
      let container_number_error;
      params.data.container_number = container_number;
      try {
        if (!is_non_iso_container && container_number) {
          validateContainerNumber(container_number);
        }
      } catch (e) {
        container_number_error = e;
      }
      const existingErrors = params.data?.errors || {};
      params.data.errors = {
        ...existingErrors,
        container_number: container_number_error,
      };
      return true;
    },
    tooltipValueGetter: (params) => {
      const error = params.data?.errors?.container_number;
      if (error) return error;
      return '';
    },
    cellStyle: (params) => {
      const error = params.data?.errors?.container_number;
      if (error) return ERROR_CELL_STYLE;
      const isEditable = params.colDef?.editable;
      if (!isEditable) return DISABLE_CELL_STYLE;
      return null;
    },
  }),
  carrier_seal_number: () => ({
    headerName: 'S/L Seal Number',
    field: 'carrier_seal_number',
    colId: 'carrier_seal_number',
    editable: (o) => !o.node.isRowPinned(),
    columnType: 'String',
  }),
  link_order_no: ({ cellStyle }: { cellStyle: Column['cellStyle'] }) => ({
    headerName: 'Booking Order #',
    field: 'link_order_no',
    colId: 'link_order_no',
    editable: false,
    columnType: 'String',
    minWidth: 200,
    cellStyle,
  }),
  container_type: () => ({
    headerName: 'Container Type',
    field: 'container_type',
    colId: 'container_type',
    editable: false,
  }),
  weight_unit: ({
    headerComponent,
    editable = false,
    cellStyle,
    valueGetter,
    valueSetter,
    minWidth,
  }: {
    headerComponent: Column['headerComponent'];
    editable: Column['editable'];
    cellStyle: Column['cellStyle'];
    valueGetter: Column['valueGetter'];
    valueSetter: Column['valueSetter'];
    minWidth: Column['minWidth'];
  }) => ({
    headerComponent,
    headerName: 'Weight Unit',
    editable: editable,
    field: 'weight_unit',
    columnType: 'String',
    cellEditor: 'EnumEditor',
    minWidth,
    cellEditorParams: {
      options: [
        {
          label: WEIGHT_UNIT_MTS,
          value: WEIGHT_UNIT_MTS,
        },
        {
          label: WEIGHT_UNIT_KGS,
          value: WEIGHT_UNIT_KGS,
        },
      ],
    },
    aggFunc: undefined,
    cellStyle,
    valueGetter,
    valueSetter,
    suppressKeyboardEvent: (params: any) => {
      return params.event.key === 'Enter' && params.editing;
    },
  }),
  volume_unit: ({
    editable = false,
    cellStyle,
  }: {
    editable: Column['editable'];
    cellStyle: Column['cellStyle'];
  }) => ({
    headerName: 'Volume Unit',
    field: 'volume_unit',
    columnType: 'String',
    cellEditor: 'EnumEditor',
    cellEditorParams: {
      value: VOLUME_UNIT_CBM,
      options: [
        {
          label: VOLUME_UNIT_CBM,
          value: VOLUME_UNIT_CBM,
        },
      ],
    },
    editable,
    cellStyle,

    valueGetter: (params) => {
      return VOLUME_UNIT_CBM;
    },
    suppressKeyboardEvent: (params: any) => {
      return params.event.key === 'Enter' && params.editing;
    },
  }),
  container_settings: ({
    containers,
    setContainers,
  }: {
    containers: ShipmentContainerValue[];
    setContainers: React.Dispatch<React.SetStateAction<ShipmentContainerValue[]>>;
  }) => ({
    headerName: 'Container Settings',
    field: 'container_settings',
    colId: 'container_settings',
    cellRenderer: 'ContainerSettingsRenderer',
    cellRendererParams: {
      containers,
      setContainers,
    },
    valueGetter: (params) => {
      if (!params?.node?.isRowPinned()) return false;
      return true;
    },

    aggFunc: undefined,
  }),
  cargo_gross_weight: ({ editable = false }: { editable?: Column['editable'] } = {}) => ({
    headerName: 'Gross Weight',
    field: 'cargo_gross_weight',
    colId: 'cargo_gross_weight',
    columnType: 'Float',
    cellEditor: 'FloatEditor',
    cellEditorParams: {
      min: 0,
    },
    cellRendererSelector: (params) => {
      return {
        component: 'QtyUomTypeRenderer',
        params: {
          qty: params?.data?.gross_weight,
          uom: params?.data?.weight_unit,
        },
      };
    },
    editable: editable,
    cellStyle: (params) => {
      const isEditable = params.colDef?.editable;
      if (!isEditable) return DISABLE_CELL_STYLE;
      return null;
    },
    aggFunc: 'sum',
  }),
  cargo_net_weight: () => ({
    headerName: 'Net Weight',
    field: 'cargo_net_weight',
    colId: 'cargo_net_weight',
    columnType: 'Float',
  }),
  lr_search: ({
    lrSuggestions = [],
    editable = false,
    cellStyle,
  }: {
    lrSuggestions?: { value: string; label: string }[];
    editable?: Column['editable'];
    cellStyle?: Column['cellStyle'];
  } = {}) => ({
    headerName: 'Assign LR',
    field: 'lr_number',
    colId: 'lr_number',
    columnType: 'String',
    cellEditor: 'EnumEditor',
    valueFormatter: (params: ValueFormatterParams<any>) => {
      return lrSuggestions.find((lr) => lr.value === params.data.lr_number)?.label || '';
    },
    editable,
    cellStyle,
    cellEditorParams: {
      options: lrSuggestions?.map((lr: { label: string; value: string }) => ({
        value: lr.value,
        label: lr.label,
      })),
    },
  }),

  vehicle_details: ({
    headerComponent,
    pinned,
    vehicleSuggestions,
    valueSetter,
    onCellValueChanged,
  }: {
    headerComponent: Column['headerComponent'];
    pinned: Column['pinned'];
    vehicleSuggestions?: VehicleValue[];
    valueSetter?: Column['valueSetter'];
    onCellValueChanged?: Column['onCellValueChanged'];
  }): Column => ({
    headerComponent,
    field: 'vehicle_details',
    valueFormatter: (params) =>
      params.value?.vehicle_license_plate_number || params.value?.record_details,
    columnType: 'String',
    editable: (params) => !params.node.isRowPinned(),
    cellEditor: 'SearchDocTypeEditor',
    cellEditorParams: (params: any) => {
      return {
        CustomComponent: GlobalSearch,
        componentProps: {
          selectMode: 'single',
          doc_type: 'Shipment::Vehicle',
          searchProps: {
            ownership_type: params.data?.ownership_type,
          },
          extraProps: {
            options: vehicleSuggestions,
          },
          showCreation: true,
        },
      };
    },
    tooltipValueGetter: (params) => {
      const error =
        params.data?.errors?.vehicle_details || params.data?.errors?.vehicle_conflict_details;
      if (error) return error;
      return '';
    },
    cellStyle: (params) => {
      const error =
        params.data?.errors?.vehicle_details || params.data?.errors?.vehicle_conflict_details;
      if (error) return ERROR_CELL_STYLE;
      return null;
    },
    pinned: pinned,
    suppressKeyboardEvent: (params: any) => {
      return params.event.key === 'Enter' && params.editing;
    },
    valueSetter,
    onCellValueChanged,
  }),
  vehicle_load_search: ({
    headerComponent,
    pinned,
    tripOrderNo,
  }: {
    headerComponent: Column['headerComponent'];
    pinned: Column['pinned'];
    tripOrderNo?: string[];
  }): Column => ({
    headerComponent,
    field: 'assign_vehicle_load',
    valueFormatter: (params) =>
      params.value?.vehicle?.vehicle_license_plate_number || params.value?.record_details,
    columnType: 'String',
    editable: (params) => !params.node.isRowPinned(),
    cellEditor: 'SearchDocTypeEditor',
    cellEditorParams: {
      CustomComponent: VehicleLoadSearch,
      componentProps: {
        selectMode: 'single',
        tripOrderNo: tripOrderNo,
      },
    },
    tooltipValueGetter: (params) => {
      const error =
        params.data?.errors?.vehicle_details || params.data?.errors?.vehicle_conflict_details;
      if (error) return error;
      return '';
    },
    cellStyle: (params) => {
      const error =
        params.data?.errors?.vehicle_details || params.data?.errors?.vehicle_conflict_details;
      if (error) return ERROR_CELL_STYLE;
      return null;
    },
    pinned: pinned,
    suppressKeyboardEvent: (params: any) => {
      return params.event.key === 'Enter' && params.editing;
    },
  }),
  driver_details: () => ({
    headerName: 'Driver',
    field: 'driver_details',
    columnType: 'String',
    valueFormatter: (params) => params.value?.driver_name,
    editable: (params) => !params.node.isRowPinned(),
    cellEditor: 'SearchDocTypeEditor',
    cellEditorParams: {
      CustomComponent: GlobalSearch,
      componentProps: {
        doc_type: 'Shipment::Driver',
        selectMode: 'single',
        showCreation: true,
      },
    },
    tooltipValueGetter: (params) => {
      const error = params.data?.errors?.driver_details;
      if (error) return error;
      return '';
    },
    cellStyle: (params) => {
      const error = params.data?.errors?.driver_details;
      if (error) return ERROR_CELL_STYLE;
      return null;
    },
    suppressKeyboardEvent: (params: any) => {
      return params.event.key === 'Enter' && params.editing;
    },
  }),
  lr_number: () => ({
    headerName: 'Lorry Receipt #',
    field: 'lr_number',
    columnType: 'String',
    editable: (params) => !params.node.isRowPinned(),
  }),
  lr_date: () => ({
    headerName: 'LR Date',
    field: 'lr_date',
    columnType: 'Date',
    cellEditor: 'DateEditor',

    valueSetter: (params) => {
      const newDate = params.newValue;
      if (typeof newDate !== 'number') {
        params.data.lr_date = params.newValue?.unix?.();
      } else {
        params.data.lr_date = params.newValue;
      }
      return true;
    },
    valueGetter: (params) => {
      return params.data.lr_date;
    },
    editable: (params) => !params.node.isRowPinned(),
  }),
  eway_bill_no: ({ editable = false }: { editable?: Column['editable'] } = {}) => ({
    headerName: 'E-way #',
    field: 'eway_bill_no',
    columnType: 'String',
    editable: editable,
  }),
  vehicle_type: () => ({
    headerComponent: () => <RequireHeaderWrapper text="Ownership Type" />,
    field: 'vehicle_type',
    columnType: 'String',
    cellEditor: 'EnumEditor',
    editable: (params) => !params.node.isRowPinned(),
    cellEditorParams: {
      options: VEHICLE_CATEGORY.map((vehicle) => ({
        value: vehicle.name,
        label: vehicle.value,
      })),
    },
    valueFormatter: (params) => {
      if (!params.data.vehicle_type) return '';
      const value =
        VEHICLE_CATEGORY.find((vehicle) => vehicle.name === params.data.vehicle_type)?.value || '';
      return value;
    },
    tooltipValueGetter: (params) => {
      const error = params.data?.errors?.vehicle_type;
      if (error) return error;
      return '';
    },
    cellStyle: (params) => {
      const error = params.data?.errors?.vehicle_type;
      if (error) return ERROR_CELL_STYLE;
      return null;
    },
    suppressKeyboardEvent: (params: any) => {
      return params.event.key === 'Enter' && params.editing;
    },
  }),
  vehicle_ownership_type: ({
    onCellValueChanged,
  }: { onCellValueChanged?: Column['onCellValueChanged'] } = {}) => ({
    headerComponent: () => <RequireHeaderWrapper text="Ownership Type" />,
    field: 'ownership_type',
    columnType: 'String',
    cellEditor: 'EnumEditor',
    editable: (params) => !params.node.isRowPinned(),
    cellEditorParams: {
      options: ASSET_TYPES.map((vehicle) => ({ value: vehicle.key, label: vehicle.name })),
    },
    valueFormatter: (params) => {
      if (!params.data.ownership_type) return '';
      const value =
        ASSET_TYPES.find((vehicle) => vehicle.key === params.data.ownership_type)?.name || '';
      return value;
    },
    tooltipValueGetter: (params) => {
      const error = params.data?.errors?.ownership_type;
      if (error) return error;
      return '';
    },
    cellStyle: (params) => {
      const error = params.data?.errors?.ownership_type;
      if (error) return ERROR_CELL_STYLE;
      return null;
    },
    suppressKeyboardEvent: (params: any) => {
      return params.event.key === 'Enter' && params.editing;
    },
    onCellValueChanged,
  }),
  transporter: ({
    valueSetter,
    onCellValueChanged,
    valueGetter,
    cellEditorParams,
  }: {
    valueSetter?: Column['valueSetter'];
    onCellValueChanged?: Column['onCellValueChanged'];
    valueGetter?: Column['valueGetter'];
    cellEditorParams?: Column['cellEditorParams'];
  } = {}) => ({
    headerComponent: () => <RequireHeaderWrapper text="Transporter" />,
    field: 'transporter',
    valueFormatter: (params) => params.value?.registered_name || params.value?.record_details,
    columnType: 'String',
    cellEditor: 'SearchDocTypeEditor',
    cellEditorParams,
    tooltipValueGetter: (params) => {
      const error = params.data?.errors?.transporter;
      if (error) return error;
      return '';
    },
    editable: (params) => {
      return params.data?.ownership_type !== ASSET_TYPE_OWN || params.node.isRowPinned();
    },
    cellStyle: (params) => {
      const error = params.data?.errors?.transporter;
      const disabled = params.data?.ownership_type === ASSET_TYPE_OWN;
      if (error) return ERROR_CELL_STYLE;
      if (disabled) return DISABLE_CELL_STYLE;
      return null;
    },
    suppressKeyboardEvent: (params: any) => {
      return params.event.key === 'Enter' && params.editing;
    },
    valueSetter,
    onCellValueChanged,
    valueGetter,
  }),
  commodity_description: ({
    editable = false,
    pinned,
    headerComponent,
    cellStyle,
  }: {
    editable?: Column['editable'];
    pinned?: Column['pinned'];
    headerComponent?: Column['headerComponent'];
    cellStyle?: Column['cellStyle'];
  } = {}) => ({
    headerComponent,
    headerName: 'Cargo Description',
    field: 'commodity_description',
    columnType: 'String',
    pinned: pinned,
    aggFunc: undefined,
    editable: editable,
    tooltipValueGetter: (params) => {
      const error = params.data?.errors?.commodity_description;
      if (error) return error;
      return '';
    },
    cellStyle: (params) => {
      if (cellStyle && typeof cellStyle == 'function') {
        const disableStyle = cellStyle(params);
        if (disableStyle) return disableStyle;
      }
      const error = params.data?.errors?.commodity_description;
      if (error) return ERROR_CELL_STYLE;
      return null;
    },
  }),
  serial_number: ({
    editable = false,
    cellStyle,
  }: { editable?: Column['editable']; cellStyle?: Column['cellStyle'] } = {}) => ({
    headerName: 'Serial #',
    field: 'serial_number',
    columnType: 'String',
    editable,
    cellStyle,
  }),
  invoice_number: ({
    editable = false,
    cellStyle,
    floatingFilter = false,
    field = 'invoice_number',
  }: {
    editable?: Column['editable'];
    cellStyle?: Column['cellStyle'];
    floatingFilter?: Column['floatingFilter'];
    field?: Column['field'];
  } = {}) => ({
    headerName: 'Invoice #',
    field,
    columnType: 'String',
    editable,
    cellStyle,
    floatingFilter,
    filter: 'agTextColumnFilter',
  }),
  invoice_date: ({
    editable = false,
    cellStyle,
  }: { editable?: Column['editable']; cellStyle?: Column['cellStyle'] } = {}) => ({
    headerName: 'Invoice Date',
    field: 'invoice_date',
    columnType: 'Date',
    cellEditor: 'DateEditor',
    valueSetter: (params) => {
      const newDate = params.newValue;
      if (typeof newDate !== 'number') {
        params.data.invoice_date = params.newValue?.unix?.();
      } else {
        params.data.invoice_date = params.newValue;
      }
      return true;
    },
    valueGetter: (params) => {
      return params.data.invoice_date;
    },
    editable,
    cellStyle,
  }),
  gross_volume: ({
    editable = false,
    cellStyle,
    valueGetter,
  }: {
    editable?: Column['editable'];
    cellStyle?: Column['cellStyle'];
    valueGetter?: Column['valueGetter'];
  } = {}) => ({
    headerName: 'Gross Vol.',
    field: 'gross_volume',
    columnType: 'Float',
    cellEditor: 'FloatEditor',
    cellEditorParams: {
      min: 0,
    },
    cellRendererSelector: (params) => {
      return {
        component: 'QtyUomTypeRenderer',
        params: {
          qty: params?.data?.gross_volume,
          uom: params?.data?.volume_unit || DIMENSION_CBM,
        },
      };
    },
    aggFunc: 'sum',
    editable: editable,
    cellStyle,
    valueGetter,
  }),
  gross_weight: ({
    headerComponent,
    editable = false,
    valueGetter,
    cellStyle,
    valueSetter,
    cellEditorParams = {
      min: 0,
    },
  }: {
    headerComponent?: Column['headerComponent'];
    editable?: Column['editable'];
    valueGetter?: Column['valueGetter'];
    cellStyle?: Column['cellStyle'];
    valueSetter?: Column['valueSetter'];
    cellEditorParams?: Column['cellEditorParams'];
  } = {}) => ({
    headerComponent,
    headerName: 'Gross Wt.',
    field: 'gross_weight',
    aggFunc: 'sum',
    columnType: 'Float',
    cellEditor: 'FloatEditor',
    cellEditorParams,
    cellRendererSelector: (params) => {
      return {
        component: 'QtyUomTypeRenderer',
        params: {
          qty: params?.data?.gross_weight,
          uom: params?.data?.weight_unit,
        },
      };
    },
    editable: editable,
    valueGetter,
    valueSetter,
    cellStyle,
    tooltipValueGetter: (params) => {
      const error = params.data?.errors?.gross_weight;
      if (error) return error;
      return '';
    },
  }),
  net_weight: ({
    editable = false,
    valueGetter,
    cellStyle,
  }: {
    editable?: Column['editable'];
    valueGetter?: Column['valueGetter'];
    cellStyle?: Column['cellStyle'];
  } = {}) => ({
    headerName: 'Net Wt.',
    field: 'net_weight',
    columnType: 'Float',
    cellEditor: 'FloatEditor',
    cellEditorParams: {
      min: 0,
    },
    cellRendererSelector: (params) => {
      return {
        component: 'QtyUomTypeRenderer',
        params: {
          qty: params?.data?.net_weight,
          uom: params?.data?.weight_unit,
        },
      };
    },
    editable: editable,
    valueGetter,
    cellStyle,
  }),
  package_type: ({
    headerComponent,
    editable = false,
    cellStyle,
    valueGetter,
  }: {
    headerComponent?: Column['headerComponent'];
    editable?: Column['editable'];
    cellStyle?: Column['cellStyle'];
    valueGetter?: Column['valueGetter'];
  } = {}) => ({
    headerComponent,
    headerName: 'Package Type',
    field: 'package_type',
    columnType: 'String',
    aggFunc: undefined,
    editable: editable,
    tooltipValueGetter: (params) => {
      const error = params.data?.errors?.package_type;
      if (error) return error;
      return '';
    },
    cellStyle: cellStyle,
    cellEditor: 'EnumEditor',
    cellEditorParams: {
      options: oceanPackageTypes.map((option: { key: string; name: string }) => {
        return {
          label: option.name,
          value: option.key,
        };
      }),
    },
    valueGetter,
    suppressKeyboardEvent: (params: any) => {
      return params.event.key === 'Enter' && params.editing;
    },
  }),
  volumetric_wt: ({ cellRendererSelector }) => ({
    headerName: 'Volumetric Wt.',
    field: 'volumetric_wt',
    columnType: 'Float',
    cellEditor: 'FloatEditor',
    editable: false,
    cellEditorParams: {
      min: 0,
    },
    cellStyle: (params) => {
      return DISABLE_CELL_STYLE;
    },
    cellRendererSelector,
    aggFunc: undefined,
  }),
  type_of_package: () => ({
    headerComponent: () => <RequireHeaderWrapper text="Package Type" />,
    field: 'type_of_package',
    columnType: 'String',
    cellEditor: 'EnumEditor',
    cellEditorParams: {
      options: oceanPackageTypes.map((option: { key: string; name: string }) => {
        return {
          label: option.name,
          value: option.key,
        };
      }),
    },

    minWidth: 200,
    pinned: 'left',
    editable: (params) => !params.node.isRowPinned(),
    suppressKeyboardEvent: (params: any) => {
      return params.event.key === 'Enter' && params.editing;
    },
    tooltipValueGetter: (params) => {
      const error = params.data?.errors?.type_of_package;
      if (error) return error;
      return '';
    },
    cellStyle: (params) => {
      const error = params.data?.errors?.type_of_package;
      if (error) return ERROR_CELL_STYLE;
      return null;
    },
  }),
  total_number_of_packages: () => ({
    headerComponent: () => <RequireHeaderWrapper text="No. of pieces" />,
    field: 'total_number_of_packages',
    columnType: 'Integer',
    cellEditor: 'FloatEditor',
    cellEditorParams: {
      precision: 0,
      step: 1,
      min: 0,
    },
    cellRendererParams: {
      precision: 0,
    },
    minWidth: 220,

    editable: (params) => !params.node.isRowPinned(),
    tooltipValueGetter: (params) => {
      const error = params.data?.errors?.total_number_of_packages;
      if (error) return error;
      return '';
    },
    cellStyle: (params) => {
      const error = params.data?.errors?.total_number_of_packages;
      if (error) return ERROR_CELL_STYLE;
      return null;
    },
  }),
  length: () => ({
    headerComponent: () => <RequireHeaderWrapper text="Length" />,
    field: 'length',
    columnType: 'Float',
    cellEditor: 'FloatEditor',
    minWidth: 200,
    cellEditorParams: {
      min: 0,
    },

    editable: (params) => !params.node.isRowPinned(),
    tooltipValueGetter: (params) => {
      const error = params.data?.errors?.length;
      if (error) return error;
      return '';
    },
    cellStyle: (params) => {
      const error = params.data?.errors?.length;
      if (error) return ERROR_CELL_STYLE;
      return null;
    },
  }),
  width: () => ({
    headerComponent: () => <RequireHeaderWrapper text="Width" />,
    field: 'width',
    columnType: 'Float',
    cellEditor: 'FloatEditor',
    cellEditorParams: {
      min: 0,
    },

    minWidth: 200,

    editable: (params) => !params.node.isRowPinned(),
    tooltipValueGetter: (params) => {
      const error = params.data?.errors?.width;
      if (error) return error;
      return '';
    },
    cellStyle: (params) => {
      const error = params.data?.errors?.width;
      if (error) return ERROR_CELL_STYLE;
      return null;
    },
  }),
  height: () => ({
    field: 'height',
    headerComponent: () => <RequireHeaderWrapper text="Height" />,
    columnType: 'Float',
    cellEditor: 'FloatEditor',
    cellEditorParams: {
      min: 0,
    },
    minWidth: 200,
    editable: (params) => !params.node.isRowPinned(),
    tooltipValueGetter: (params) => {
      const error = params.data?.errors?.height;
      if (error) return error;
      return '';
    },
    cellStyle: (params) => {
      const error = params.data?.errors?.height;
      if (error) return ERROR_CELL_STYLE;
      return null;
    },
  }),
  dimension_unit: () => ({
    headerName: 'Dimension Unit',
    field: 'dimension_unit',
    columnType: 'String',
    cellEditor: 'EnumEditor',
    minWidth: 200,
    editable: true,

    cellEditorParams: {
      options: [
        {
          label: DIMENSION_CMS,
          value: DIMENSION_CMS,
        },
        {
          label: DIMENTION_INCH,
          value: DIMENTION_INCH,
        },
      ],
    },
    valueGetter: (params) => {
      if (!params.data.dimension_unit) return DIMENSION_CMS;
      return params.data.dimension_unit;
    },
    suppressKeyboardEvent: (params: any) => {
      return params.event.key === 'Enter' && params.editing;
    },
  }),

  per_piece_weight: () => ({
    field: 'per_piece_weight',
    headerComponent: () => <RequireHeaderWrapper text="Per piece Wt." />,
    columnType: 'Float',
    cellEditor: 'FloatEditor',
    cellEditorParams: {
      min: 0,
    },
    minWidth: 200,
    editable: (params) => !params.node.isRowPinned(),
    tooltipValueGetter: (params) => {
      const error = params.data?.errors?.per_piece_weight;
      if (error) return error;
      return '';
    },
    cellStyle: (params) => {
      const error = params.data?.errors?.per_piece_weight;
      if (error) return ERROR_CELL_STYLE;
      return null;
    },
  }),

  total_packages: ({
    headerComponent,
    editable = false,
    cellStyle,
    valueGetter,
    valueSetter,
    cellEditorParams = {
      precision: 0,
      min: 0,
      step: 1,
    },
  }: {
    headerComponent?: Column['headerComponent'];
    editable?: Column['editable'];
    cellStyle?: Column['cellStyle'];
    valueGetter?: Column['valueGetter'];
    valueSetter?: Column['valueSetter'];
    cellEditorParams?: Column['cellEditorParams'];
  } = {}) => ({
    headerComponent,
    headerName: 'Qty',
    aggFunc: 'sum',
    columnType: 'Float',
    cellEditor: 'FloatEditor',
    field: 'total_packages',
    cellRendererSelector: (params) => {
      return {
        component: 'QtyUomTypeRenderer',
        params: {
          qty: params?.data?.total_packages,
          uom: '',
          precision: 0,
        },
      };
    },
    editable: editable,
    valueGetter,
    cellStyle,
    tooltipValueGetter: (params) => {
      const error = params.data?.errors?.total_packages;
      if (error) return error;
      return '';
    },
    cellEditorParams,
    valueSetter,
  }),
  batch_number: ({
    editable = false,
    cellStyle,
  }: { editable?: Column['editable']; cellStyle?: Column['cellStyle'] } = {}) => ({
    headerName: 'Batch #',
    field: 'batch_number',
    columnType: 'String',
    editable: editable,
    cellStyle,
  }),
  custom_ref: ({
    editable = false,
    cellStyle,
  }: { editable?: Column['editable']; cellStyle?: Column['cellStyle'] } = {}) => ({
    headerName: 'Customs Ref #',
    field: 'custom_ref',
    columnType: 'String',
    editable: editable,
    cellStyle,
  }),
  allocation_pending_quantity: ({ isBreakBulk = false }: { isBreakBulk?: boolean } = {}) => ({
    headerName: 'Pending Qty',
    field: 'allocation_pending_quantity',
    columnType: isBreakBulk ? 'Integer' : 'Float',
    cellEditor: 'FloatEditor',
    valueGetter: (params) => {
      const pending = params.data?.allocation_pending_quantity;
      return pending < 0 ? 0 : pending;
    },
    cellEditorParams: isBreakBulk
      ? {
          precision: 0,
        }
      : undefined,
    cellRendererParams: isBreakBulk
      ? {
          precision: 0,
        }
      : undefined,
    editable: false,
    pinned: 'right',
  }),

  allocated_qty: ({
    onCellValueChanged,
    editable,
  }: {
    onCellValueChanged?: Column['onCellValueChanged'];
    editable?: Column['editable'];
    valueGetter?: Column['valueGetter'];
  } = {}) => ({
    headerComponent: () => <RequireHeaderWrapper text="Allocate Qty" />,
    field: 'allocated_qty',
    columnType: 'Float',
    cellEditor: 'FloatEditor',
    editable,
    cellEditorParams: {
      precision: 0,
      step: 1,
      min: 0,
    },
    cellRendererParams: {
      precision: 0,
    },
    tooltipValueGetter: (params) => {
      const error = params.data?.errors?.allocated_qty;
      if (error) return error;
      return '';
    },
    cellStyle: (params) => {
      const error = params.data?.errors?.allocated_qty;
      if (error) return ERROR_CELL_STYLE;
      return null;
    },
    pinned: 'left',
    onCellValueChanged,
  }),

  cargo_properties: ({
    cellRendererParams,
    valueGetter,
    cellStyle,
  }: {
    cellRendererParams: Column['cellRendererParams'];
    valueGetter: Column['valueGetter'];
    cellStyle?: Column['cellStyle'];
  }) => ({
    headerName: 'Properties',
    field: 'cargo_properties',
    colId: 'cargo_properties',
    cellRenderer: 'CargoProperties',

    aggFunc: undefined,
    cellRendererParams,
    valueGetter,
    cellStyle,
  }),

  add_package: ({
    cellRendererParams,
    cellStyle,
  }: {
    cellRendererParams: Column['cellRendererParams'];
    cellStyle?: Column['cellStyle'];
  }) => ({
    headerName: 'Packages',
    field: 'add_package',
    colId: 'add_package',
    cellRenderer: 'PackageDetailsDrawerForm',
    cellRendererParams,
    cellStyle,
    valueGetter: (params) => {
      if (!params?.node?.isRowPinned()) return false;
      return true;
    },
    aggFunc: undefined,
  }),

  allocated_gross_weight: ({
    onCellValueChanged,
    editable,
  }: {
    onCellValueChanged?: Column['onCellValueChanged'];
    editable?: Column['editable'];
  } = {}) => ({
    headerComponent: () => <RequireHeaderWrapper text="Allocate Qty" />,
    field: 'allocated_gross_weight',
    columnType: 'Float',
    cellEditor: 'FloatEditor',
    cellEditorParams: {
      min: 0,
    },
    tooltipValueGetter: (params) => {
      const error = params.data?.errors?.allocated_gross_weight;
      if (error) return error;
      return '';
    },
    cellStyle: (params) => {
      const error = params.data?.errors?.allocated_gross_weight;
      if (error) return ERROR_CELL_STYLE;
      return null;
    },
    pinned: 'left',
    onCellValueChanged,
    editable,
  }),
  pickup_date: ({ disableFutureDate }: { disableFutureDate?: boolean }) => ({
    headerName: 'Pickup Date',
    field: 'pickup_date',
    colId: 'pickup_date',
    columnType: 'Date',
    cellEditor: 'DateEditor',
    editable: (o) => !o.node.isRowPinned(),
    cellEditorParams: {
      disabledDate: disableFutureDate,
    },
    valueSetter: (params) => {
      const newDate = params.newValue;
      if (typeof newDate !== 'number') {
        params.data.pickup_date = params.newValue?.unix?.();
      } else {
        params.data.pickup_date = params.newValue;
      }
      return true;
    },
    valueGetter: (params) => {
      if (params.node?.isRowPinned()) return '';
      if (params.data.pickup_date == null) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        params.data.pickup_date = dayjsGenerateConfig.getNow() / 1000;
      }
      return params.data.pickup_date;
    },
  }),
  eway_bill_validity: ({ editable = false }: { editable?: Column['editable'] } = {}) => ({
    headerName: 'E-way Validity',
    field: 'eway_bill_validity',
    colId: 'eway_bill_validity',
    columnType: 'Date',
    cellEditor: 'DateEditor',
    editable: editable,
    valueSetter: (params) => {
      const newDate = params.newValue;
      if (typeof newDate !== 'number') {
        params.data.eway_bill_validity = params.newValue?.unix?.();
      } else {
        params.data.eway_bill_validity = params.newValue;
      }
      return true;
    },
    valueGetter: (params) => {
      return params.data.eway_bill_validity;
    },
  }),
  is_non_iso_container: () => ({
    headerName: 'Non ISO',
    field: 'is_non_iso_container',
    colId: 'is_non_iso_container',
    columnType: 'Boolean',
    cellRenderer: function checkboxRenderer(params: any) {
      if (params.node.isRowPinned()) return <></>;
      return (
        <Checkbox
          checked={params.data?.is_non_iso_container}
          onChange={(e) => {
            const is_non_iso_container = e.target.checked;
            let container_number_error;
            params.data.is_non_iso_container = is_non_iso_container;
            const container_number = params.data?.container_number;
            try {
              if (!is_non_iso_container && container_number) {
                validateContainerNumber(container_number);
              }
            } catch (e) {
              container_number_error = e;
            }
            const existingErrors = params.data?.errors || {};
            params.data.errors = {
              ...existingErrors,
              container_number: container_number_error,
            };
            if (!params.node) return;
            params.api.refreshCells({
              force: true,
              columns: ['is_non_iso_container', 'container_number'],
              rowNodes: [params?.node],
            });
          }}
        ></Checkbox>
      );
    },
    editable: true,
  }),
  job_number: () => ({
    headerName: 'Linked Trip',
    field: 'job_number',
    width: 200,
    cellRenderer: 'MultiLinkRender',
    cellRendererParams: {
      id_field: 'id',
      value_field: 'job_number',
      doc_type_id: 'Shipment::Shipment',
    },
  }),
  shipment_number: ({ headerName }: { headerName?: string }) => ({
    headerName: headerName,
    field: 'shipment_number',
    width: 200,
    cellRenderer: 'MultiLinkRender',
    cellRendererParams: {
      id_field: 'document_id',
      value_field: 'shipment_number',
      doc_type_id: 'Shipment::ShipmentDocument',
      extra_fields: ['shipment_id'],
    },
  }),
  shipment_date: ({ headerName }: { headerName?: string }) => ({
    headerName: headerName,
    field: 'shipment_date',
    cellRenderer: 'TagsRender',
    width: 200,
  }),
};

export default allFields;
