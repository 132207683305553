/**
 * Shipment container related helpers
 */
import { get } from 'lodash';
import { ShipmentValue } from 'operations/models/Shipment';
import { ACCOUNTING_STATUS_CLOSED, SHIPMENT_TYPE_HOUSE } from '../constants';

export const isFieldEditable = (container: any) => {
  return container;
};

export function isJobClosed(param: any) {
  const accountingStatus = get(param, 'accounting_status') || param;
  return accountingStatus === ACCOUNTING_STATUS_CLOSED;
}

export const DOCUMENT_DISABLED = {
  ADD: (shipment: ShipmentValue) => shipment.isShipmentCancelled() || isJobClosed(shipment),
  EDIT: (shipment: ShipmentValue) =>
    isJobClosed(shipment) || get(shipment, 'shipment_type') === SHIPMENT_TYPE_HOUSE
      ? shipment.isExportHouseExecuted()
      : shipment.isExportMasterExecuted(),
  DELETE: (shipment: ShipmentValue) =>
    shipment.isShipmentCancelled() ||
    isJobClosed(shipment) ||
    shipment.isExportHouseExecuted() ||
    shipment.isExportMasterExecuted(),
  UPLOAD: (shipment: ShipmentValue) => shipment.isShipmentCancelled() || isJobClosed(shipment),
};
