/* eslint-disable camelcase */

import React, { useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Alert, Col, InputNumber, Modal, Radio, Row, Form } from '@shipmnts/pixel-hub';
import { useSession } from 'common';
import { CompanySearch } from 'common';
import { startCase as _startCase, get as _get } from 'lodash';

import { RESERVE_STOCK } from 'operations/graphql/StockOrder';
import { CARRIER_TYPE_AIR } from 'operations/models/Carrier';

import { IATA_AGENT } from './constants';
import { GlobalSearch } from '@shipmnts/pixel-hub';

interface ReserveStockProps {
  visible: boolean;
  onClose: () => void;
}
export default function ReserveStock(props: ReserveStockProps) {
  const { visible, onClose } = props;
  const [reserved, setReserved] = useState(false);
  const [awbNumbers, setAwbNumbers] = useState([]);
  const [warningMessage, setWarningMessage] = useState<string>('');
  const [reserveStock, { loading, error, data }] = useMutation(RESERVE_STOCK);
  const [receivedFor, setReceivedFor] = useState('self');
  const [stockNum, setStockNum] = useState(1);
  const user = useSession();
  const [form] = Form.useForm();

  useEffect(() => {
    if (data?.reserve_awb_stock) {
      const awb_numbers = _get(data, 'reserve_awb_stock.awb_numbers', []);
      setReserved(true);
      setAwbNumbers(awb_numbers);
      if (data?.reserve_awb_stock?.message) {
        setWarningMessage(data?.reserve_awb_stock?.message);
      }
    }

    if (error) {
      setWarningMessage(error.message);
    }
  }, [data, error, loading]);

  const renderAwbNumbers = (awb_numbers: string[]) => {
    if (!awb_numbers.length) return;
    return (
      <div>
        <b>Reserved AWB Numbers:</b>
        <div>
          {awb_numbers.map((number: string, index: number) => (
            <div key={index} style={{ fontSize: '14px' }}>
              {number.substr(0, 3)}-{number.substr(3)}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderWarningMessage = (warningMessage: string | null) => {
    return warningMessage && <Alert message={_startCase(warningMessage)} type="warning" />;
  };

  return (
    <Modal
      width={800}
      title={reserved ? 'Stocks reserved' : 'Reserve AWB Stock'}
      okText={`Reserve ${stockNum || 1} Stock(s)`}
      cancelText="Close"
      open={visible}
      onCancel={onClose}
      onOk={form.submit}
      maskClosable={false}
      confirmLoading={loading}
      okButtonProps={{ disabled: reserved }}
    >
      <Form
        onFinish={(values) => {
          reserveStock({
            variables: {
              carrier_id: values.carrier_id.id,
              port_of_loading_id: values.port_of_loading.id,
              stock_required: values.stock_required,
              reserved_for_company_id:
                values.reserved_for_company_type === 'self'
                  ? user.company_account.default_company.id
                  : values.reserved_for_company_id,
            },
          });
        }}
        layout="vertical"
        form={form}
        initialValues={{
          reserved_for_company_type: 'self',
          stock_required: stockNum,
        }}
        style={{ overflow: 'auto' }}
        name="reserve_stock_form"
      >
        <Row gutter={22}>
          <Col span={10}>
            <Form.Item required label="Reserving For" name="reserved_for_company_type">
              <Radio.Group
                options={[
                  { value: 'self', label: 'Self' },
                  { value: 'subagent', label: 'Subagent' },
                ]}
                onChange={(e) => setReceivedFor(e.target.value)}
                disabled={reserved}
              />
            </Form.Item>
          </Col>
          {receivedFor === 'subagent' && (
            <Col span={10}>
              <Form.Item label="Subagent" name="reserved_for_company_id" required>
                <CompanySearch
                  searchProps={{ business_type: [IATA_AGENT.key] }}
                  disabled={reserved}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row className="padding-top-sm" gutter={22}>
          <Col span={8}>
            <Form.Item label="Airline" name="carrier_id" required>
              <GlobalSearch
                doc_type="Global::Carrier"
                searchProps={{ carrier_type: [CARRIER_TYPE_AIR] }}
                disabled={reserved}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              validateStatus={form.getFieldError('port_of_loading') ? 'error' : ''}
              help={form.getFieldError('port_of_loading')}
              label="Port of Loading"
              required
              name="port_of_loading"
              className="margin-bottom-zero"
            >
              <GlobalSearch
                doc_type="Global::Location"
                searchProps={{ type: ['Airport'] }}
                selectMode={undefined}
                disabled={reserved}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="stock_required" label="No of AWBs required">
              <InputNumber
                required
                step={1}
                onChange={(e) => setStockNum(parseInt(e?.toString() || '1'))}
                precision={0}
                max={100}
                min={1}
                disabled={reserved}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="padding-top-sm">{renderAwbNumbers(awbNumbers)}</Row>
        <Row className="padding-top-sm">{renderWarningMessage(warningMessage)}</Row>
      </Form>
    </Modal>
  );
}
