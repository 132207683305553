/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree';

const RegulatoryDetails = types.model({
  id: types.identifier,
  key: types.maybe(types.maybeNull(types.string)),
  valid_till: types.maybe(types.maybeNull(types.string)),
  value: types.maybe(types.maybeNull(types.string)),
  attachment: types.maybe(
    types.maybeNull(
      types.model({
        document_identifier: types.maybe(types.maybeNull(types.string)),
        document_url: types.maybe(types.maybeNull(types.string)),
        content_type: types.maybe(types.maybeNull(types.string)),
        file_size: types.maybe(types.maybeNull(types.union(types.number, types.string))),
        file_name: types.maybe(types.maybeNull(types.string)),
      })
    )
  ),
});

export interface RegulatoryDetailsValue extends Instance<typeof RegulatoryDetails> {}
export interface RegulatoryDetailsIn extends SnapshotIn<typeof RegulatoryDetails> {}
export interface RegulatoryDetailsOut extends SnapshotOut<typeof RegulatoryDetails> {}

export default RegulatoryDetails;
