import React from 'react';
import BasicDetail from '../Components/BasicDetail';
import BarCode from '../Components/BarCode';
import PrimaryPackaging from '../Components/PrimaryPackaging';
import ProductProperty from '../Components/ProductProperty';
import { ItemValue } from 'common/models/Item';
interface OverviewTabProps {
  product: ItemValue;
}
export default function OverviewTab(props: OverviewTabProps) {
  const { product } = props;
  return (
    <div style={{ background: '#fafafa', padding: '15px', display: 'flex' }}>
      <div style={{ width: '100%', marginLeft: '15px', backgroundColor: 'white' }}>
        <BasicDetail product={product} />
        <BarCode product={product} />
        <ProductProperty product={product} />
        <PrimaryPackaging product={product} />
      </div>
    </div>
  );
}
