import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Form,
  message,
  Modal,
  Typography,
  AccessibleByBranches,
} from '@shipmnts/pixel-hub';
import { useSession } from 'common';
import { UserContactValue } from 'common/models/UserContact';
import { omit } from 'lodash';
import {
  GET_COLLABORATOR,
  ADD_COLLABORATOR,
  GET_FREQUENT_COLLABORATORS,
} from 'network/graphql/collaborator';
import {
  GENERATE_SHIPMENT_PUBLIC_LINK,
  branchAccountFields,
  shipmentDocumentFields,
} from 'operations/graphql/shipment';
import React, { useEffect, useState } from 'react';
import AddedCollaboratorList from './AddedCollaboratorList';
import NotifyCollaborators from './NotifyCollaborators';
import { LinkOutlined } from '@ant-design/icons';
import { GlobalSearch } from '@shipmnts/pixel-hub';

interface AddCollaboratorsProps {
  onClose?: () => void;
  onSuccess?: () => void;
  referenceId?: string;
  referenceType: string;
  id?: string | null;
  collaborators?: UserContactValue[];
}

const GET_SHIPMENT = gql`
  query shipment($id: ID!) {
    shipment(id: $id) {
      id
      status
      job_number
      last_action_status
      freight_type
      trade_type
      load_type
      shipment_type
      shipment_booking_number
      company_role_list {
        role
        name
        company_id
        address_id
      }
      involved_branch {
        ...branchAccountFields
      }
      involved_branch_id
      accessible_by_branches {
        id
        name
        erp_cost_center_id
      }
      shipment_documents {
        ...shipmentDocumentFields
      }
    }
  }
  ${shipmentDocumentFields}
  ${branchAccountFields}
`;

export default function AddCollaborators(props: AddCollaboratorsProps) {
  const { onClose, onSuccess, referenceId, referenceType, id } = props;
  const [form] = Form.useForm();
  const session = useSession();
  const [collaborators, setCollaborators] = useState<UserContactValue[]>(props.collaborators || []);
  const [showAccessBranch, setShowAccessBranch] = useState(false);
  const [notify, setNotify] = useState(true);
  const newCollaborators = Form.useWatch('new_collaborators', form);
  const [getCollaborators, { data, loading, error }] = useLazyQuery(GET_COLLABORATOR);

  const { data: freqCollabsData } = useQuery(GET_FREQUENT_COLLABORATORS, {
    variables: { reference_id: referenceId, reference_type: referenceType },
  });
  const [getShipment, { data: shipmentData, refetch }] = useLazyQuery(GET_SHIPMENT);
  const [
    upsertCollab,
    { data: upsertCollabData, loading: upsertCollabLoading, error: upsertCollabError },
  ] = useMutation(ADD_COLLABORATOR);

  const [
    generateShipmentPublicLink,
    {
      data: generateShipmentPublicLinkData,
      loading: generateShipmentPublicLinkLoading,
      error: generateShipmentPublicLinkError,
    },
  ] = useMutation(GENERATE_SHIPMENT_PUBLIC_LINK);

  useEffect(() => {
    if (generateShipmentPublicLinkLoading) {
      return;
    }
    if (generateShipmentPublicLinkError) {
      message.error(generateShipmentPublicLinkError.message);
      return;
    }
    if (generateShipmentPublicLinkData?.generate_shipment_public_link) {
      const { link } = generateShipmentPublicLinkData?.generate_shipment_public_link;
      // copy link to clipboard
      navigator.clipboard.writeText(link);
      message.success('Link copied!');
    }
  }, [
    generateShipmentPublicLinkError,
    generateShipmentPublicLinkLoading,
    generateShipmentPublicLinkData,
  ]);

  useEffect(() => {
    if (collaborators.length === 0) {
      getCollaborators({
        variables: {
          reference_ids: [referenceId],
          reference_type: referenceType,
        },
      });
    }
  }, [collaborators, getCollaborators, referenceId, referenceType]);

  useEffect(() => {
    if (referenceId && referenceType === 'Shipment::Shipment') {
      getShipment({
        variables: {
          id: referenceId,
        },
      });
    }
  }, [getShipment, referenceId, referenceType]);

  useEffect(() => {
    if (upsertCollabError) {
      message.error(upsertCollabError.message);
    } else if (upsertCollabData?.add_collaborator) {
      message.success('Collaborator added sucessfully!');
      onSuccess && onSuccess();
      onClose && onClose();
    }
  }, [upsertCollabData, upsertCollabLoading, upsertCollabError, onClose, onSuccess]);

  useEffect(() => {
    if (data?.get_collaborators) {
      setCollaborators(data?.get_collaborators);
    }
  }, [data, error]);

  const validateBranch = (collaborators: any[]) => {
    let validate = true;
    const shipmentBranches = (shipmentData?.shipment?.accessible_by_branches || []).map(
      (b: any) => b.id
    );
    collaborators.forEach((c) => {
      if (c?.branch_accounts) {
        if (
          c.branch_accounts.filter((b: any) => {
            return (shipmentBranches || []).find((sb: any) => sb === b.id);
          }).length === 0
        )
          validate = false;
      }
    });
    return validate;
  };

  const footer = [];

  if (referenceType && referenceType === 'Shipment::Shipment')
    footer.push(
      <Button
        type="primary"
        onClick={() => {
          generateShipmentPublicLink({ variables: { id: referenceId } });
        }}
      >
        Copy Public Link <LinkOutlined />
      </Button>
    );
  if (newCollaborators?.length) {
    footer.push(
      <Button
        type="primary"
        key="submit_form"
        onClick={() => {
          form.submit();
        }}
      >
        Add Collaborators
      </Button>
    );
  }

  footer.push(
    <Button key="back" onClick={onClose}>
      Close
    </Button>
  );

  return (
    <>
      {showAccessBranch && (
        <AccessibleByBranches
          shipment={shipmentData?.shipment}
          onClose={() => setShowAccessBranch(false)}
          onSuccess={() => {
            refetch && refetch();
          }}
          errorToShow={'Share branch with user to enable Shipment access for collaborators.'}
        />
      )}
      <Modal
        title={id ? `Share ${id}` : 'Share'}
        confirmLoading={upsertCollabLoading}
        width={'700px'}
        footer={footer}
        open={true}
        onCancel={onClose}
      >
        <Typography.Text>Add Collaborators</Typography.Text>
        <Form
          initialValues={{}}
          onFinish={(val: any) => {
            if (referenceType === 'Shipment::Shipment') {
              const branchAccountValidate = validateBranch(val?.new_collaborators);
              if (!branchAccountValidate) {
                // setRunValidation(false);
                setShowAccessBranch(true);
                return;
              }
            }
            let allCollab: any[] = [];
            allCollab.push(
              ...(val?.new_collaborators || []).map((c: any) => ({
                user_contact: c,
                reference_type: referenceType,
                reference_id: referenceId,
                added_by: session,
              }))
            );
            allCollab = allCollab.map((c) => {
              return omit(
                {
                  ...c,
                  user_contact_id: c.user_contact?.id,
                  added_by_id: c.added_by?.id,
                },
                ['__typename', 'user_contact', 'added_by']
              );
            });
            upsertCollab({
              variables: {
                collaborators: allCollab,
                email_body: notify
                  ? val.email_body || 'You have been added as a collaborator'
                  : null,
              },
            });
          }}
          form={form}
        >
          <Form.Item required name="new_collaborators">
            <GlobalSearch
              doc_type="Network::UserContact"
              extraProps={{
                disabledContacts: (collaborators || [])
                  .filter((c: any) => !c._destroy)
                  .map((c: any) => c.user_contact),
                suggestions: {
                  'Frequent Collaborators': freqCollabsData?.fetch_frequent_collaborators,
                },
              }}
              selectMode="multiple"
              showCreation={false}
            />
          </Form.Item>
          {(newCollaborators || []).length > 0 ? (
            <NotifyCollaborators notify={notify} setNotify={setNotify} />
          ) : (
            <AddedCollaboratorList
              loading={loading}
              collaborators={collaborators}
              setCollaborators={setCollaborators}
              shipment={shipmentData?.shipment}
            />
          )}
        </Form>
      </Modal>
    </>
  );
}
