import { gql } from '@apollo/client';
import { containerRequestWithQuantityFields } from 'operations/modules/booking/graphql/containerRequest';
import { cargoFields } from 'operations/modules/booking/graphql/cargo';
import { shipmentContainerFields } from 'operations/modules/booking/graphql/shipmentContainer';
import { routingLocationObjectType } from 'operations/modules/booking/graphql/voyageSchedule';
import { salesPersonFields } from 'operations/graphql/salesPerson';

export const bookingRequestReportFields = gql`
  fragment bookingRequestReportFields on BookingRequestReportObjectType {
    id
    load_type
    shipment_booking_number
    status
    requested_at
    requested_since
    high_priority
    remarks
    cancelled_by
    cancellation_reason
    cancellation_marked_by {
      id
      first_name
      last_name
    }
    cancellation_booking_numbers
    container_requests {
      ...containerRequestWithQuantityFields
    }
    cargos {
      ...cargoFields
    }
    shipment_containers {
      ...shipmentContainerFields
    }
    preferred_carriers {
      id
      name
    }
    port_of_loading {
      id
      name
    }
    port_of_discharge {
      id
      name
    }
    place_of_carrier_receipt {
      id
      name
    }
    place_of_carrier_delivery {
      id
      name
    }
    vessel {
      imo
      name
    }
    sales_person {
      ...salesPersonFields
    }
    voyage_number
    estimated_time_of_departure
    estimated_time_of_arrival
    customer_company {
      id
      registered_name
      status
    }
    containers_grouped_by_booking_order {
      booking_request_id
      ocean_transport_order_id
      shipment_containers {
        ...shipmentContainerFields
      }
    }
    transporter_companies {
      id
      registered_name
    }
    shipper_company {
      id
      registered_name
    }
    gate_open_date
    gate_close_date
    recent_valid_till_date
    si_cutoff_date
    ocean_transport_orders {
      id
      booking_number
      booking_date
      valid_till_date
      dg_indexing_date
      global_carrier {
        id
        name
      }
      empty_pickup_location {
        id
        name
      }
      is_amendment_pending
      status
      status_time_duration_in_words
      is_independent
      created_by {
        id
        first_name
        last_name
      }
      sales_person {
        ...salesPersonFields
      }
    }
    master_shipment {
      id
      job_number
      shipment_type
      shipment_document_master {
        id
        shipment_number
      }
      shipment_document_house {
        id
        shipment_number
      }
    }
    house_shipments {
      id
      job_number
      shipment_document_house {
        id
        shipment_number
      }
    }
    created_by {
      id
      first_name
      last_name
    }
    carting_point {
      location {
        id
        name
      }
    }
  }
  ${containerRequestWithQuantityFields}
  ${cargoFields}
  ${salesPersonFields}
  ${shipmentContainerFields}
  ${salesPersonFields}
`;

export const BOOKING_REQUEST_REPORT = gql`
  query booking_request_report(
    $limit: Int
    $offset: Int
    $filters: [FilterInputType!]
    $sort: [SortInputTypeNew!]
  ) {
    booking_request_report(limit: $limit, offset: $offset, filters: $filters, sort: $sort) {
      total
      data {
        ...bookingRequestReportFields
      }
    }
  }
  ${bookingRequestReportFields}
`;

export const BOOKING_REQUEST_DASHBOARD_REPORT = gql`
  query booking_request_dashboard_report(
    $limit: Int
    $offset: Int
    $filters: [FilterInputType!]
    $sort: [SortInputTypeNew!]
  ) {
    booking_request_dashboard_report(
      limit: $limit
      offset: $offset
      filters: $filters
      sort: $sort
    ) {
      total
      data {
        id
        shipment_booking_number
        container_requests {
          ...containerRequestWithQuantityFields
        }
        cargos {
          id
          commodity_description
        }
        port_of_loading {
          id
          name
        }
        port_of_discharge {
          id
          name
        }
        place_of_carrier_receipt {
          id
          name
        }
        place_of_carrier_delivery {
          id
          name
        }
        vessel {
          imo
          name
        }
        sales_person {
          ...salesPersonFields
        }
        voyage_number
        estimated_time_of_departure
        customer_company {
          id
          registered_name
        }
        ocean_transport_orders {
          id
          global_carrier {
            id
            name
          }
          sales_person {
            ...salesPersonFields
          }
        }
        created_by {
          id
          first_name
          last_name
        }
      }
    }
  }
  ${containerRequestWithQuantityFields}
  ${salesPersonFields}
  ${salesPersonFields}
`;

export const BOOKING_REQUEST_AND_REMARKS = gql`
  query booking_request_report(
    $limit: Int
    $offset: Int
    $filters: [FilterInputType!]
    $sort: [SortInputTypeNew!]
  ) {
    booking_request_report(limit: $limit, offset: $offset, filters: $filters, sort: $sort) {
      total
      data {
        id
        quotation_number
        inquiry_option {
          id
        }
        is_external_quotation_number
        incoterm
        remarks
        consignee_company {
          id
          registered_name
        }
        consignee_address {
          id
          name
          print_address
          company_id
        }
        miscellaneous_service_orders {
          id
          name
          order_collaborations {
            id
            vendor_company {
              id
              registered_name
            }
            customer_company {
              id
              registered_name
            }
          }
        }
        port_of_loading {
          ...routingLocationObjectType
        }
        port_of_discharge {
          ...routingLocationObjectType
        }
        place_of_carrier_receipt {
          ...routingLocationObjectType
        }
        place_of_carrier_delivery {
          ...routingLocationObjectType
        }
      }
    }
  }
  ${routingLocationObjectType}
`;
