import React from 'react';

import { Breadcrumb } from '@shipmnts/pixel-hub';
import { startCase as _startCase } from 'lodash';
import { Link } from 'wouter';
import { findDocTypeId, keyLabelMapping } from './BreadCrumbsMapRender.types';
import { ItemType } from '@shipmnts/pixel-hub';

const BreadCrumbsMapRender = React.memo(function BreadCrumbsMapRender(): JSX.Element {
  const { location } = window;
  const pathSnippets = location.pathname.split('/').filter((i) => i);
  const [routeType, model, id] = pathSnippets;
  const extraBreadcrumbItems = [];
  if (['view', 'form'].includes(routeType)) {
    if (model) {
      const docType = findDocTypeId[model];
      const redirectUrl = id
        ? `/workspace?doc_type=${docType}&resource_id=${id}`
        : `/workspace?doc_type=${docType}`;
      extraBreadcrumbItems.push({
        path: redirectUrl,
        title: keyLabelMapping[model],
      });
    }
    if (id) {
      extraBreadcrumbItems.push({
        path: `/${routeType}/${model}/${id}`,
        title: _startCase(id),
      });
    }
  }
  const breadcrumbItems = [{ path: '/', title: 'Home' }].concat(extraBreadcrumbItems);
  function itemRender(route: ItemType, params: any, routes: ItemType[], paths: string[]) {
    const last = routes.indexOf(route) === routes.length - 1;
    if (!route) return null;
    if (last) {
      return <Link to={`~${route.path}`}>{route.title}</Link>;
    } else if (route.path === '/') {
      return <Link to="~/">{route.title}</Link>;
    } else {
      return <Link to={`~${paths.join('/')}`}>{route.title}</Link>;
    }
  }

  return <Breadcrumb itemRender={itemRender} items={breadcrumbItems}></Breadcrumb>;
});

export default BreadCrumbsMapRender;
