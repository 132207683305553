import React, { useCallback } from 'react';
import { Form, Row, Col, Select, Radio, Space } from '@shipmnts/pixel-hub';
import { uniqBy as _uniqBy, map as _map, compact as _compact } from 'lodash';
import { ShipmentPartyValue } from 'operations/models/ShipmentParty';
import { CompanyValue } from 'operations/models/Company';

interface SendDocumentBasicDetailsProps {
  shipmentParties: ShipmentPartyValue[];
  onPartyChanged?: (partyName: string) => void;
}

const SendDocumentBasicDetails = React.memo(function SendDocumentBasicDetails(
  props: SendDocumentBasicDetailsProps
): JSX.Element {
  const { shipmentParties, onPartyChanged } = props;
  const companies = _compact(
    _uniqBy(
      _map(shipmentParties, (sp: ShipmentPartyValue) => sp.party_company),
      'id'
    )
  );

  const onChange = useCallback(
    (partyvalue: any, option: any) => {
      if (onPartyChanged) onPartyChanged(option.props.name);
    },
    [onPartyChanged]
  );

  return (
    <>
      <Row>
        <Col span={12}>
          <Form.Item
            name="receiver_company_id"
            label="Send To"
            required
            rules={[{ required: true, message: 'Send To Required' }]}
          >
            <Select placeholder="Select your party" onChange={onChange}>
              {companies.map((cp: CompanyValue) => (
                <Select.Option key={cp.id} value={cp.id} name={cp?.registered_name}>
                  {cp?.registered_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.receiver_company_id !== currentValues.receiver_company_id
          }
        >
          {({ getFieldValue }) => {
            const receiver_company_id = getFieldValue('receiver_company_id');
            const company = companies.find((cp: CompanyValue) => cp.id === receiver_company_id);
            return (
              <Form.Item
                name="mode_of_sharing"
                label="Select Mode Of Sharing"
                required
                rules={[{ required: true }]}
              >
                <Radio.Group>
                  <Space direction="vertical">
                    <Radio
                      value="on_platform"
                      disabled={company && !Boolean(company?.global_company_account?.id)}
                    >
                      On Platform
                    </Radio>
                    <Radio value="email">Email</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            );
          }}
        </Form.Item>
      </Row>
    </>
  );
});

export default SendDocumentBasicDetails;
