import {
  IncircledFactorySvg,
  IncircledTruckSvg,
  EncircledStuffingIcon,
  EncircledTrainSvg,
} from './Icons';

export const PICKUP_PENDING = 'Pickup Pending';
export const EVENT_EMPTY_PICKED_UP = 'Empty Picked Up';
export const EVENT_STUFFED = 'Stuffing Done';
export const EVENT_ORIGIN_FACTORY_GATE_PASS_FILED = 'Pickup / Stuffing Appointment Confirmed';
export const EVENT_ORIGIN_FACTORY_GATE_IN = 'Gated In At Shipper Factory';
export const EVENT_ORIGIN_FACTORY_GATE_OUT = 'Gated Out From Shipper Factory';
export const EVENT_ORIGIN_CFS_GATE_IN = 'Gated In At CFS';
export const EVENT_ORIGIN_CFS_GATE_OUT = 'Gated Out From CFS';
export const EVENT_PRE_CARRIAGE_ICD_GATE_IN = 'Gated in At Place of Receipt';
export const EVENT_PRE_CARRIAGE_ICD_GATE_OUT = 'Gated Out From Place of Receipt';
export const EVENT_POL_GATE_PASS_CONFIRMED = 'PoL Gated Pass Confirmed';
export const EVENT_POL_GATE_IN = 'Gated In at PoL';
export const EVENT_DOCUMENTS_HANDED_OVER_TO_CARRIER = 'Handed Over To Carrier';
export const EVENT_POL_LOADED = 'Loaded on Vessel';
export const EVENT_TRANS_SHIPMENT_DISCHARGE = 'Discharged At Transhipment';
export const EVENT_TRANS_SHIPMENT_LOADED = 'Loaded From Transhipment';
export const EVENT_POD_DISCHARGE = 'Discharged at PoD';
export const EVENT_POD_GATE_PASS_CONFIRMED = 'Pickup Appointment Confirmed';
export const EVENT_POD_GATE_OUT = 'Gated Out From PoD';
export const EVENT_DESTUFFED = 'Destuffing Done';
export const EVENT_ON_CARRIAGE_ICD_GATE_IN = 'Gated In At Place of Delivery';
export const EVENT_ON_CARRIAGE_ICD_GATE_OUT = 'Gated Out From Place of Delivery';
export const EVENT_DESTINATION_CFS_GATE_IN = 'Gated In At CFS';
export const EVENT_DESTINATION_CFS_GATE_OUT = 'Gated Out From CFS';
export const EVENT_DESTINATION_FACTORY_GATE_PASS_FILED = 'Delivery Appointment Confirmed';
export const EVENT_DESTINATION_FACTORY_GATE_IN = 'Gated In At Consignee Factory';
export const EVENT_DESTINATION_FACTORY_GATE_OUT = 'Gated Out From Consignee Factor';
export const EVENT_DESTINATION_EMPTY_DELIVERED_TO_CARRIER = 'Empty Returned To Carrier';
export const EVENT_ORIGIN_EMPTY_DELIVERED_TO_CARRIER = 'Empty Returned To Carrier';
export const EVENT_OFFLOADED = 'Empty Offloaded';
export const EVENT_ROUTING_UPDATED = 'Routing Updated';

export const eventToIconsMaping = {
  [EVENT_EMPTY_PICKED_UP]: EncircledStuffingIcon,
  [EVENT_STUFFED]: EncircledStuffingIcon,
  [EVENT_ORIGIN_FACTORY_GATE_PASS_FILED]: IncircledFactorySvg,
  [EVENT_ORIGIN_FACTORY_GATE_IN]: IncircledFactorySvg,
  [EVENT_ORIGIN_FACTORY_GATE_OUT]: IncircledFactorySvg,
  [EVENT_ORIGIN_CFS_GATE_IN]: IncircledFactorySvg,
  [EVENT_ORIGIN_CFS_GATE_OUT]: IncircledFactorySvg,
  [EVENT_PRE_CARRIAGE_ICD_GATE_IN]: EncircledTrainSvg,
  [EVENT_PRE_CARRIAGE_ICD_GATE_OUT]: EncircledTrainSvg,
  [EVENT_POL_GATE_PASS_CONFIRMED]: IncircledFactorySvg,
  [EVENT_POL_GATE_IN]: IncircledTruckSvg,
  [EVENT_DOCUMENTS_HANDED_OVER_TO_CARRIER]: IncircledFactorySvg,
  [EVENT_POL_LOADED]: IncircledFactorySvg,
  [EVENT_TRANS_SHIPMENT_DISCHARGE]: IncircledFactorySvg,
  [EVENT_TRANS_SHIPMENT_LOADED]: IncircledFactorySvg,
  [EVENT_POD_DISCHARGE]: IncircledFactorySvg,
  [EVENT_POD_GATE_PASS_CONFIRMED]: IncircledFactorySvg,
  [EVENT_POD_GATE_OUT]: IncircledTruckSvg,
  [EVENT_DESTUFFED]: EncircledStuffingIcon,
  [EVENT_ON_CARRIAGE_ICD_GATE_IN]: EncircledTrainSvg,
  [EVENT_ON_CARRIAGE_ICD_GATE_OUT]: EncircledTrainSvg,
  [EVENT_DESTINATION_CFS_GATE_IN]: IncircledFactorySvg,
  [EVENT_DESTINATION_CFS_GATE_OUT]: IncircledFactorySvg,
  [EVENT_DESTINATION_FACTORY_GATE_PASS_FILED]: IncircledFactorySvg,
  [EVENT_DESTINATION_FACTORY_GATE_IN]: IncircledFactorySvg,
  [EVENT_DESTINATION_FACTORY_GATE_OUT]: IncircledFactorySvg,
  [EVENT_DESTINATION_EMPTY_DELIVERED_TO_CARRIER]: IncircledFactorySvg,
  [EVENT_ORIGIN_EMPTY_DELIVERED_TO_CARRIER]: IncircledFactorySvg,
  [EVENT_OFFLOADED]: IncircledFactorySvg,
  [EVENT_ROUTING_UPDATED]: IncircledFactorySvg,
};

export const FIELD_TYPE_REFERENCE = 'reference';

export const INDIAN_CUSTOMS_TRACKING_REQUEST_STATUS_ACTIVE = 'active';
export const INDIAN_CUSTOMS_TRACKING_REQUEST_STATUS_ACTIVE_AFTER_LEO_DATE = 'active_after_leo_date';
export const INDIAN_CUSTOMS_TRACKING_REQUEST_STATUS_INACTIVE = 'inactive';
export const INDIAN_CUSTOMS_TRACKING_REQUEST_STATUS_DISABLED = 'disabled';
export const INDIAN_CUSTOMS_TRACKING_REQUEST_STATUS_ERROR = 'error';
export const INDIAN_CUSTOMS_TRACKING_REQUEST_STATUS_NOT_APPLICABLE = 'not_applicable';
