import React from 'react';
import { Card, Col, Form, Input, Row } from '@shipmnts/pixel-hub';

// const Loss_reasons = [
//   { name: 'Price' },
//   { name: 'Product' },
//   { name: 'Service' },
//   { name: 'Support' },
//   { name: 'Others' },
// ];

const LeadLostForm = () => {
  return (
    <Card className="CreateLeadCard">
      <Row gutter={[16, 0]}>
        {/* <Col span={12}>
          <Form.Item
            label="Loss Reason"
            name="loss_reason"
            rules={[{ required: true, message: 'Please select a Loss Reason' }]}
          >
            <Select allowClear placeholder="Select a Loss Reason">
              {Loss_reasons?.map((lr) => (
                <Option key={lr.name} value={lr.name}>
                  {lr.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col> */}
        <Col span={12}>
          <Form.Item label="Remarks for Loss" name="remarks">
            <Input.TextArea placeholder="add Remarks for Loss of Lead" />
          </Form.Item>
        </Col>
      </Row>
      {/* <Row gutter={[16, 0]}>
        <Col span={12}>
          <Form.Item label="Competitor" name="competitor">
            <Input placeholder="Lead lost to Competitor" />
          </Form.Item>
        </Col>
      </Row> */}
    </Card>
  );
};

export default LeadLostForm;
