import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { ErpNextCompanyConfigDataContext } from 'network';
import { oceanPackageTypes } from 'operations/baseConstants';
import { PlusOutlined, DeleteOutlined, BaseTable } from '@shipmnts/pixel-hub';
import { SearchDocTypeEditor, EnumEditor, FloatEditor, DateEditor } from '@shipmnts/pixel-hub';
import {
  useApplicationContentContext,
  errorMessageHandlerGraphQLString,
  makeColumnForTab,
  // useSession,
} from 'common';
import { Column } from 'operations/models/Report';
import { Button, Card, Modal, message } from '@shipmnts/pixel-hub';
import { compact as _compact, omit } from 'lodash';
import { ItemCellRenderer } from 'operations/modules/shipment/components/DetailLayout/Estimates/ItemCellRenderer';
import {
  ERPNextCompanyConfigData,
  ErpNextCompanyConfigDataContextType,
} from 'network/utils/ErpNextCompanyConfigDataWrapper';
import { observer } from 'mobx-react-lite';
import { BASIS_TYPE_CONTAINER } from '../reports/constants';
import { ProductOrderItemValue } from 'operations/models/ProductOrderItem';
import GetProductFromShipmentDrawer from './GetProductFromShipmentDrawer';
import { SelectionChangedEvent } from '@ag-grid-community/core';

import { disableFutureDate } from '@shipmnts/pixel-hub';
import { useLazyQuery } from '@apollo/client';
import { GlobalSearch } from '@shipmnts/pixel-hub';
import { GET_CUSTOM_FIELDS_FROM_DOC_TYPE } from 'common/utils/graphql';
import { ILargeTextEditorParams } from '@ag-grid-community/core/dist/cjs/es5/rendering/cellEditors/largeTextCellEditor';

interface ProductOrderItemProps {
  onChange?: (value: Array<any>) => void;
  gridRef?: any;
  value?: Array<any>;
  productOrderItems?: Array<ProductOrderItemValue>;
  editTable?: boolean;
  rowSelection?: 'multiple' | 'single';
  uom?: any;
  getProductFromShipment?: boolean;
  type: string;
  setTotalProductValue?: any;
  orderCurrency?: string;
  linkedItems?: any[];
  updateStatus?: (cell: any) => void;
}

interface ProductOrderItemInternalProps extends ProductOrderItemProps {
  getErpConfigData?: any;
}

export const itemValidation = (productItems: Array<ProductOrderItemValue>) => {
  let error = '';
  productItems?.forEach((poi: any) => {
    if (
      !poi.product_name ||
      // !poi.seller_product_code ||
      !poi.unit_rate ||
      !poi.amount ||
      !poi.country_of_manufacture
    ) {
      error =
        'Product Name, Product Code, Unit Rate, Amount and Country of Origin are required field';
      return;
    }
  });
  return error;
};
export function getFinalOptions(listOfValue: { name: string }[]) {
  const newList: { value: string; label: string }[] = [];

  listOfValue.forEach((option) => {
    if (BASIS_TYPE_CONTAINER.name === option.name) {
      newList.push({
        value: BASIS_TYPE_CONTAINER.name,
        label: BASIS_TYPE_CONTAINER.name,
        // quantity: totalContainers || 1,
      });
      return;
    }
    newList.push({
      label: option.name,
      value: option.name,
      // quantity: defaultQuantities[option.name] || 1,
    });
  });
  return newList;
}

function getPackagingDescription(data: Record<any, any>, type: string) {
  if (
    !data[`${type}_package_type`] ||
    !data[`${type}_per_packet_wt`] ||
    !data[`${type}_package_qty`]
  )
    return '';
  if (type === 'outer') {
    if (!data['inner_package_type']) return '';
    return `${data[`${type}_package_qty`]} ${data[`${type}_package_type`]}s - ${
      data[`${type}_per_packet_wt`]
    } ${data['inner_package_type']} / ${data[`${type}_package_type`]} each`;
  }
  return `${data[`${type}_package_qty`]} ${data[`${type}_package_type`]}s of ${
    data[`${type}_per_packet_wt`]
  } kgs each`;
}
function getTotalWeight(data: Record<any, any>, type: string) {
  if (!data[`${type}_per_packet_wt`] || !data[`${type}_package_qty`]) return 0.0;
  return Number(Number(data[`${type}_per_packet_wt`] * data[`${type}_package_qty`]).toFixed(3));
}

const ProductOrderItem = forwardRef(function ProductItems(props: ProductOrderItemProps, itermsRef) {
  const {
    onChange,
    editTable = true,
    rowSelection,
    gridRef,
    productOrderItems,
    uom,
    type,
    getProductFromShipment,
    value,
    setTotalProductValue,
    orderCurrency,
    linkedItems,
    updateStatus,
  } = props;

  const [rowSelected, setRowSelected] = useState(false);

  const [showGetProduct, setShowGetProduct] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<any[]>([]);
  const { config_data } = useApplicationContentContext();
  const withLinkedItems = linkedItems?.length ? true : false;
  const [getFieldsFromDocType, { data: fieldData }] = useLazyQuery(GET_CUSTOM_FIELDS_FROM_DOC_TYPE);

  const extractWt = (val: number) => {
    if (Number.isNaN(val)) {
      return 0.0;
    }
    return val;
  };

  useEffect(() => {
    if (selectedProduct.length > 0) {
      const newRowData = (value || productOrderItems || []).map((rd: any) => {
        const itemFromSelectedPOI = selectedProduct.find(
          (sp) => sp.linked_to_id === rd.linked_to_id
        );
        if (itemFromSelectedPOI) {
          return {
            ...omit(rd, 'product_order_item_id'),
            invoice_qty:
              type === 'commercial_invoice' ? itemFromSelectedPOI?.invoice_qty : rd.invoice_qty,
            shipment_planned_qty:
              type === 'shipment'
                ? itemFromSelectedPOI?.shipment_planned_qty
                : rd.shipment_planned_qty,
            amount: itemFromSelectedPOI?.amount,
          };
        } else {
          return { ...omit(rd, 'product_order_item_id') };
        }
      });

      selectedProduct
        .filter(
          (sp) =>
            !(value || productOrderItems || []).find(
              (rd: any) => rd.linked_to_id === sp.linked_to_id
            )
        )
        .forEach((sp) => newRowData.push(sp));

      onChange && onChange(newRowData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct]);

  const onSelectionChanged = (event: SelectionChangedEvent) => {
    const selectedRows = event.api.getSelectedNodes();
    setRowSelected(selectedRows.length > 0);
  };

  const newDeleteRow = () => {
    const selectedRow = gridRef.current.api.getSelectedNodes();
    const selectedIndexes = selectedRow.map((element: any) => element.rowIndex);
    const result = (value || productOrderItems || []).filter(
      (_: any, index: any) => !selectedIndexes.includes(index)
    );
    onChange && onChange(result);
    gridRef.current.api.setRowData(result);
    let totalProductAmount = 0;
    gridRef.current.api.forEachNode((node: any) => {
      totalProductAmount += node.data.amount;
    });
    setTotalProductValue && setTotalProductValue(totalProductAmount);
    setRowSelected(false);
  };
  const getHeight = () => {
    if (!(value || productOrderItems)) {
      return undefined;
    }
    let detailPanelHeight = 5 * 39 + 160;

    if ((value || productOrderItems || []).length < 6) {
      detailPanelHeight = (value || productOrderItems || []).length * 39 + 160;
    }

    return detailPanelHeight;
  };

  const productCodeColName =
    type === 'sales' || type === 'commercial_invoice'
      ? 'seller_product_code'
      : 'buyer_product_code';

  const qty: string =
    type === 'commercial_invoice'
      ? 'invoice_qty'
      : type === 'shipment'
      ? 'shipment_planned_qty'
      : 'order_qty';

  const getColumns = useCallback(() => {
    const columnDefs: Column[] = _compact([
      {
        headerName: 'Product Code',
        field: productCodeColName,
        colId: productCodeColName,
        maxWidth: 150,
        pinned: 'left',
        lockPosition: true,
        suppressMovable: true,
        editable: (o) => !o.node.isRowPinned() && editTable && !withLinkedItems,
        suppressKeyboardEvent: (params) => {
          return params.event.key === 'Enter' && params.editing;
        },
        cellEditor: 'SearchDocTypeEditor',
        cellEditorParams: {
          CustomComponent: GlobalSearch,
          componentProps: {
            doc_type: 'OrderManagement::ProductOrderItem',
            searchProps: ['["Item", "is_service_item", "=", "0"]'],
            extraProps: {
              createLabel: 'Create a new product',
              createItemParams: { is_service_item: 0 },
            },
            showCreation: true,
          },
        },
        valueSetter: (params) => {
          if (!params.newValue) return false;
          params.data.product_name = params.newValue.item_name;
          const parser = new DOMParser();
          const itemDescription = parser.parseFromString(params.newValue?.description, 'text/html');
          params.data.product_description = itemDescription?.body?.textContent || '';
          if (type === 'sales' || type === 'commercial_invoice')
            params.data.seller_product_code = params.newValue.item_code;
          else params.data.buyer_product_code = params.newValue.item_code;
          params.data.uom = params.newValue.stock_uom;
          params.data.hs_code = params.newValue.gst_hsn_code;
          params.data.tax_percentage = parseFloat(params.newValue.tax_percentage);
          return true;
        },
      },
      {
        headerName: 'Product Name',
        field: 'product_name',
        colId: 'product_name',
        editable: (o) => !o.node.isRowPinned() && editTable && !withLinkedItems,
        suppressKeyboardEvent: (params) => {
          return params.event.key === 'Enter' && params.editing;
        },
        cellEditor: 'SearchDocTypeEditor',
        cellEditorParams: {
          CustomComponent: GlobalSearch,
          componentProps: {
            doc_type: 'OrderManagement::ProductOrderItem',
            searchProps: ['["Item", "is_service_item", "=", "0"]'],
            extraProps: {
              createLabel: 'Create a new product',
              createItemParams: { is_service_item: 0 },
            },
            showCreation: true,
          },
        },
        valueSetter: (params) => {
          if (!params.newValue) return false;
          params.data.product_name = params.newValue.item_name;
          const parser = new DOMParser();
          const itemDescription = parser.parseFromString(params.newValue?.description, 'text/html');
          params.data.product_description = itemDescription?.body?.textContent || '';
          if (type === 'sales' || type === 'commercial_invoice')
            params.data.seller_product_code = params.newValue.item_code;
          else params.data.buyer_product_code = params.newValue.item_code;
          params.data.uom = params.newValue.stock_uom;
          params.data.hs_code = params.newValue.gst_hsn_code;
          params.data.tax_percentage = parseFloat(params.newValue.tax_percentage);
          return true;
        },
        maxWidth: 300,
      },
      {
        headerName: 'Product Description',
        field: 'product_description',
        colId: 'product_description',
        columnType: 'String',
        cellEditorPopup: true,
        cellEditor: 'agLargeTextCellEditor',
        cellEditorParams: {
          maxLength: 600,
          rows: 15,
          cols: 54,
        } as ILargeTextEditorParams,
        editable: (o) => !o.node.isRowPinned() && editTable,
        width: 100,
        maxWidth: 300,
      },
      {
        headerName: 'Additional Description',
        field: 'additional_description',
        colId: 'additional_description',
        columnType: 'String',
        cellEditorPopup: true,
        cellEditor: 'agLargeTextCellEditor',
        cellEditorParams: {
          maxLength: 600,
          rows: 15,
          cols: 54,
        } as ILargeTextEditorParams,
        editable: (o) => !o.node.isRowPinned() && editTable,
        width: 100,
        maxWidth: 300,
      },
      (type === 'sales' || type === 'commercial_invoice') && {
        headerName: 'Buyer Product Code',
        field: 'buyer_product_code',
        colId: 'buyer_product_code',
        editable: (o) => !o.node.isRowPinned() && editTable,
        valueSetter: (params) => {
          if (!params.newValue) return false;
          params.data.buyer_product_code = params.newValue;
          return true;
        },
        maxWidth: 180,
      },
      type === 'purchase' && {
        headerName: 'Seller Product Code',
        field: 'seller_product_code',
        colId: 'seller_product_code',
        editable: (o) => !o.node.isRowPinned() && editTable,
        valueSetter: (params) => {
          if (!params.newValue) return false;
          params.data.seller_product_code = params.newValue;
          return true;
        },
        maxWidth: 180,
      },
      {
        headerName: 'HS Code',
        field: 'hs_code',
        colId: 'hs_code',
        editable: (o) => !o.node.isRowPinned() && editTable,
        valueSetter: (params) => {
          if (!params.newValue) return false;
          if (/^\d+$/.test(params.newValue)) params.data.hs_code = params.newValue;
          else return false;
          return true;
        },
        maxWidth: 100,
      },
      {
        headerName: 'Basis',
        field: 'uom',
        colId: 'uom',
        editable: (o) => !o.node.isRowPinned() && editTable,
        suppressKeyboardEvent: (params) => {
          return params.event.key === 'Enter' && params.editing;
        },
        columnType: 'String',
        cellEditor: 'EnumEditor',
        cellEditorParams: {
          options: getFinalOptions(uom),
        },
        width: 100,
      },
      {
        headerName: 'Qty',
        field: `${qty}`,
        colId: `${qty}`,
        editable: (o) => !o.node.isRowPinned() && editTable,
        cellEditor: 'FloatEditor',
        columnType: 'Float',
        cellEditorParams: {
          min: 0.00001,
          step: 0.00001,
        },
        valueSetter: (params: any) => {
          type === 'commercial_invoice'
            ? (params.data.invoice_qty = Number(Number(params.newValue).toFixed(3)))
            : type === 'shipment'
            ? (params.data.shipment_planned_qty = Number(Number(params.newValue).toFixed(3)))
            : (params.data.order_qty = Number(Number(params.newValue).toFixed(3)));
          if (params.data.unit_rate && params.data?.[qty]) {
            params.data.amount = Number(
              extractWt(Number(params.data.unit_rate) * Number(params.data?.[qty])).toFixed(3)
            );
          }
          return true;
        },
        valueGetter: (params) => {
          if (Number.isNaN(params.data?.[qty])) {
            return 0;
          }
          return params.data?.[qty];
        },
        width: 150,
      },
      // type === 'commercial_invoice' && {
      //   headerName: 'Qty',
      //   field: 'invoice_qty',
      //   colId: 'invoice_qty',
      //   editable: (o) => !o.node.isRowPinned() && editTable,
      //   cellEditor: 'FloatEditor',
      //   columnType: 'Float',
      //   cellEditorParams: {
      //     min: 0.00001,
      //     step: 0.00001,
      //   },
      //   valueSetter: (params) => {
      //     params.data.invoice_qty =  Number(Number(params.newValue).toFixed(3));
      //     if (params.data.unit_rate && params.data.invoice_qty) {
      //       params.data.amount = Number(
      //         extractWt(Number(params.data.unit_rate) * Number(params.data.invoice_qty)).toFixed(3)
      //       );
      //     }
      //     return true;
      //   },
      //   valueGetter: (params) => {
      //     if (Number.isNaN(params.data.invoice_qty)) {
      //       return 0;
      //     }
      //     return params.data.invoice_qty;
      //   },
      // },
      (type === 'sales' || type === 'purchase') &&
        !editTable && {
          headerName: 'Pending Qty',
          field: 'pending_qty',
          colId: 'pending_qty',
          editable: false,
          columnType: 'Float',
          valueGetter: (params) => {
            if (Number.isNaN(params.data.order_qty - params.data.shipment_planned_qty)) {
              return 0;
            }
            return params.data.order_qty - params.data.shipment_planned_qty;
          },
        },
      {
        headerName: `Unit Rate (${orderCurrency || 'Cur'})`,
        field: 'unit_rate',
        colId: 'unit_rate',
        editable: (o) => !o.node.isRowPinned() && editTable,
        cellEditor: 'FloatEditor',
        columnType: 'Float',
        cellEditorParams: {
          min: 0.00001,
          step: 0.00001,
        },
        valueSetter: (params) => {
          params.data.unit_rate = Number(Number(params.newValue).toFixed(3));
          // const qty =
          //   type === 'commercial_invoice' ? params.data.invoice_qty : params.data.order_qty;
          if (params.data.unit_rate && params.data?.[qty]) {
            params.data.amount = Number(
              extractWt(Number(params.data.unit_rate) * Number(params.data?.[qty])).toFixed(3)
            );
          }
          return true;
        },
        valueGetter: (params) => {
          return extractWt(params.data.unit_rate);
        },
      },
      {
        headerName: `Sell Amount (${orderCurrency || 'Cur'})`,
        field: 'amount',
        colId: 'amount',
        editable: false,
        cellEditor: 'FloatEditor',
        columnType: 'Float',
        cellEditorParams: {
          min: 0.00001,
          step: 0.00001,
        },
      },
      (type === 'commercial_invoice' || type === 'shipment') && {
        headerName: 'Tax %',
        field: 'tax_percentage',
        colId: 'tax_percentage',
        editable: (o) => !o.node.isRowPinned() && editTable,
        cellEditor: 'FloatEditor',
        columnType: 'Float',
        cellEditorParams: {
          min: 0.00001,
          step: 0.00001,
        },
        valueGetter: (params: any) => {
          if (params.node?.isRowPinned()) return <></>;
          return params?.data?.tax_percentage || '';
        },
      },
      (type === 'commercial_invoice' || type === 'shipment') && {
        headerName: 'Marks And Numbers',
        field: 'marks_and_numbers',
        colId: 'marks_and_numbers',
        cellEditorPopup: true,
        cellEditor: 'agLargeTextCellEditor',
        cellEditorParams: {
          maxLength: 600,
          rows: 15,
          cols: 54,
        } as ILargeTextEditorParams,
        // flex: 2,
        editable: (o) => !o.node.isRowPinned() && editTable,
        valueSetter: (params) => {
          if (!params.newValue) return false;
          params.data.marks_and_numbers = params.newValue;
          return true;
        },
        maxWidth: 400,
      },
      {
        headerName: 'Country of Origin',
        field: 'country_of_manufacture',
        colId: 'country_of_manufacture',
        editable: (o) => !o.node.isRowPinned() && editTable,
        suppressKeyboardEvent: (params) => {
          return params.event.key === 'Enter' && params.editing;
        },
        cellEditor: 'EnumEditor',
        cellEditorParams: {
          options: getFinalOptions(config_data?.list_of_countries || []),
        },
        valueSetter: (params) => {
          if (!params.newValue) return false;
          params.data.country_of_manufacture = params.newValue;
          return true;
        },
      },
      {
        headerName: 'Inner Package Type',
        field: 'inner_package_type',
        colId: 'inner_package_type',
        editable: (o) => !o.node.isRowPinned() && editTable,
        suppressKeyboardEvent: (params) => {
          return params.event.key === 'Enter' && params.editing;
        },
        cellEditor: 'EnumEditor',
        cellEditorParams: {
          options: getFinalOptions(oceanPackageTypes),
        },
        valueSetter: (params) => {
          params.data.inner_package_type = params.newValue;
          params.data.packing_remarks = getPackagingDescription(params.data, 'inner');
          return true;
        },
      },
      {
        headerName: 'Weight / Package (Kgs)',
        field: 'inner_per_packet_wt',
        colId: 'inner_per_packet_wt',
        editable: (o) => !o.node.isRowPinned() && editTable,
        cellEditor: 'FloatEditor',
        columnType: 'Float',
        cellEditorParams: {
          min: 0.00001,
          step: 0.00001,
        },
        valueSetter: (params) => {
          params.data.inner_per_packet_wt = Number(Number(params.newValue).toFixed(3));
          params.data.packing_remarks = getPackagingDescription(params.data, 'inner');
          params.data.net_weight = getTotalWeight(params.data, 'inner');
          return true;
        },
        valueGetter: (params) => {
          return extractWt(params.data.inner_per_packet_wt);
        },
      },
      {
        headerName: 'Inner Package Qty',
        field: 'inner_package_qty',
        colId: 'inner_package_qty',
        editable: (o) => !o.node.isRowPinned() && editTable,
        cellEditor: 'FloatEditor',
        cellEditorParams: {
          min: 0.00001,
          step: 0.00001,
        },
        columnType: 'Float',
        valueSetter: (params: any) => {
          params.data.inner_package_qty = Number(Number(params.newValue).toFixed(3));
          params.data.packing_remarks = getPackagingDescription(params.data, 'inner');
          params.data.net_weight = getTotalWeight(params.data, 'inner');
          return true;
        },
        valueGetter: (params) => {
          if (Number.isNaN(params.data.inner_package_qty)) {
            return 0;
          }
          return params.data.inner_package_qty;
        },
      },
      {
        headerName: 'Inner Package Instruction',
        field: 'packing_remarks',
        colId: 'packing_remarks',
        editable: (o) => !o.node.isRowPinned() && editTable,
      },
      {
        headerName: 'Net Weight',
        field: 'net_weight',
        colId: 'net_weight',
        editable: (o) => !o.node.isRowPinned() && editTable,
        cellEditor: 'FloatEditor',
        columnType: 'Float',
        cellEditorParams: {
          min: 0.00001,
          step: 0.00001,
        },
        valueSetter: (params) => {
          params.data.net_weight = Number(Number(params.newValue).toFixed(3));
          return true;
        },
        valueGetter: (params) => {
          if (params.data.net_weight) return params.data.net_weight;
          return 0.0;
        },
      },
      (type === 'commercial_invoice' || type === 'shipment') && {
        headerName: 'Outer Package Type',
        field: 'outer_package_type',
        colId: 'outer_package_type',
        editable: (o) => !o.node.isRowPinned() && editTable,
        suppressKeyboardEvent: (params) => {
          return params.event.key === 'Enter' && params.editing;
        },
        cellEditor: 'EnumEditor',
        cellEditorParams: {
          options: getFinalOptions(oceanPackageTypes),
        },
        valueSetter: (params) => {
          params.data.outer_package_type = params.newValue;
          params.data.outer_packing_remarks = getPackagingDescription(params.data, 'outer');
          return true;
        },
      },
      (type === 'commercial_invoice' || type === 'shipment') && {
        headerName: '#InnerPackage / OuterPackage',
        field: 'outer_per_packet_wt',
        colId: 'outer_per_packet_wt',
        editable: (o) => !o.node.isRowPinned() && editTable,
        cellEditor: 'FloatEditor',
        columnType: 'Float',
        cellEditorParams: {
          min: 0.00001,
          step: 0.00001,
        },
        valueSetter: (params) => {
          params.data.outer_per_packet_wt = Number(Number(params.newValue).toFixed(3));
          params.data.outer_packing_remarks = getPackagingDescription(params.data, 'outer');
          // params.data.gross_weight = getTotalWeight(params.data, 'outer');
          return true;
        },
        valueGetter: (params) => {
          return extractWt(params.data.outer_per_packet_wt);
        },
      },
      (type === 'commercial_invoice' || type === 'shipment') && {
        headerName: 'Outer Package Qty',
        field: 'outer_package_qty',
        colId: 'outer_package_qty',
        editable: (o) => !o.node.isRowPinned() && editTable,
        columnType: 'Float',
        cellEditor: 'FloatEditor',
        cellEditorParams: {
          min: 0.00001,
          step: 0.00001,
        },
        valueSetter: (params) => {
          params.data.outer_package_qty = Number(Number(params.newValue).toFixed(3));
          params.data.outer_packing_remarks = getPackagingDescription(params.data, 'outer');
          // params.data.gross_weight = getTotalWeight(params.data, 'outer');
          return true;
        },
        valueGetter: (params: any) => {
          if (Number.isNaN(params.data.outer_package_qty)) {
            return 0;
          }
          return params.data.outer_package_qty;
        },
      },
      (type === 'commercial_invoice' || type === 'shipment') && {
        headerName: 'Outer Package Instruction',
        field: 'outer_packing_remarks',
        colId: 'outer_packing_remarks',
        editable: (o) => !o.node.isRowPinned() && editTable,
      },
      (type === 'commercial_invoice' || type === 'shipment') && {
        headerName: 'Gross Weight',
        field: 'gross_weight',
        colId: 'gross_weight',
        editable: (o) => !o.node.isRowPinned() && editTable,
        cellEditor: 'FloatEditor',
        columnType: 'Float',
        cellEditorParams: {
          min: 0.00001,
          step: 0.00001,
        },
        valueSetter: (params) => {
          params.data.gross_weight = Number(Number(params.newValue).toFixed(3));
          return true;
        },
        valueGetter: (params) => {
          if (params.data.gross_weight) return params.data.gross_weight;
          return 0.0;
        },
      },
      (type === 'commercial_invoice' || type === 'shipment') && {
        headerName: 'Sales Order #',
        field: 'sales_order_number',
        colId: 'sales_order_number',
        columnType: 'String',
        editable: (o) => !o.node.isRowPinned() && editTable,
        width: 100,
      },
      (type === 'commercial_invoice' || type === 'shipment') && {
        headerName: 'Purchase Order #',
        field: 'purchase_order_number',
        colId: 'purchase_order_number',
        columnType: 'String',
        editable: (o) => !o.node.isRowPinned() && editTable,
        width: 100,
      },
      type !== 'commercial_invoice' && {
        headerName: 'Cargo Ready / Material Required Date',
        field: 'cargo_ready_date',
        colId: 'cargo_ready_date',
        // width: '12%',
        columnType: 'Date',
        cellEditor: 'DateEditor',
        editable: (o) => !o.node.isRowPinned(),
        cellEditorParams: {
          disabledDate: disableFutureDate,
        },
        valueSetter: (params) => {
          if (typeof params.newValue !== 'object') return false;
          params.data.cargo_ready_date = params.newValue?.unix();
          return true;
        },
        valueGetter: (params) => {
          if (params.node?.isRowPinned()) return '';
          return params.data.cargo_ready_date;
        },
        filter: false,
        cellEditorPopup: true,
      },
      type === 'shipment' && {
        headerName: 'Target Shipping Date',
        field: 'target_shipping_date',
        colId: 'target_shipping_date',
        // width: '12%',
        columnType: 'Date',
        cellEditor: 'DateEditor',
        editable: (o) => !o.node.isRowPinned() && type !== 'shipment',
        valueSetter: (params) => {
          if (typeof params.newValue !== 'object') return false;
          params.data.target_shipping_date = params.newValue.unix();
          return true;
        },
        valueGetter: (params) => {
          if (params.node?.isRowPinned()) return '';
          return params.data.target_shipping_date;
        },
        filter: false,
        cellEditorPopup: true,
      },
      type !== 'commercial_invoice' && {
        headerName: 'Target Delivery Date',
        field: 'target_delivery_date',
        colId: 'target_delivery_date',
        // width: '12%',
        columnType: 'Date',
        cellEditor: 'DateEditor',
        editable: (o) => !o.node.isRowPinned() && type !== 'shipment',
        valueSetter: (params) => {
          if (typeof params.newValue !== 'object') return false;
          params.data.target_delivery_date = params.newValue.unix();
          return true;
        },
        valueGetter: (params) => {
          if (params.node?.isRowPinned()) return '';
          return params.data.target_delivery_date;
        },
        filter: false,
        cellEditorPopup: true,
      },
    ]);

    return columnDefs;
  }, [
    config_data?.list_of_countries,
    editTable,
    orderCurrency,
    productCodeColName,
    qty,
    type,
    uom,
    withLinkedItems,
  ]);

  const [allColumnsDefs, setAllColumnsDefs] = useState<Column[]>(getColumns() || []);

  useEffect(() => {
    getFieldsFromDocType({ variables: { doc_type_id: 'OrderManagement::ProductOrderItem' } });
  }, [getFieldsFromDocType]);

  useEffect(() => {
    if (fieldData) {
      const resultArray = fieldData.get_custom_fields_from_doc_type.map((field: any) => {
        return makeColumnForTab(field);
      });
      setAllColumnsDefs([...allColumnsDefs, ...resultArray]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldData]);

  const components = {
    EnumEditor,
    FloatEditor,
    SearchDocTypeEditor,
    ItemCellRenderer,
    DateEditor,
  };

  const addNewRow = () => {
    const newRow = {};
    // setRowData([...rowData, newRow]);
    onChange && onChange([...(value || productOrderItems || []), newRow]);
    setTimeout(() => {
      gridRef?.current?.api?.startEditingCell({
        rowIndex: (value || productOrderItems || []).length,
        colKey: productCodeColName,
      });
      gridRef?.current?.api?.setFocusedCell(
        (value || productOrderItems || []).length,
        productCodeColName
      );
    }, 500);
  };

  const buttonLable = type === 'shipment' ? 'Get Product From Order' : 'Get Product From Shipment';
  return (
    <>
      <BaseTable
        rowData={value || productOrderItems || []}
        reportName=""
        height={getHeight() || '200px'}
        columns={allColumnsDefs}
        gridRef={gridRef}
        rowSelection={rowSelection}
        checkbox_always_visible={false}
        onSelectionChanged={onSelectionChanged}
        allMapping={true}
        reportConfig={{
          components: components,
          suppressCellFocus: false,
          suppressLastEmptyLineOnPaste: true,
          rowHeight: 40,
          onCellValueChanged: (params: any) => {
            const data = value || [];
            data[params.rowIndex] = {
              ...params.data,
            };
            onChange && onChange(data);

            if (setTotalProductValue) {
              const value = params.node.parent.allLeafChildren;
              let totalProductAmount = 0;
              value.forEach((pi: any) => {
                totalProductAmount += pi.data.amount;
              });
              setTotalProductValue(totalProductAmount);
            }
            if (updateStatus) {
              updateStatus(params);
            }
          },
          // onCellValueChanged={() => {
          //   nodes = []
          //   gridRef.api.current?.getNodes(node => {nodes.push(node)})
          //   onChange(nodes)
          // }}
          // suppressClipboardApi: true,
          defaultColDef: {
            // suppressMovable: true,
            // editable: false,
            resizable: true,
          },
          enableRangeSelection: true,
          enableCellChangeFlash: true,
          animateRows: true,
          tabToNextCell: (params: any) => {
            const col = gridRef?.current?.columnApi?.getColumn(productCodeColName);
            if (params.editing && !params.backwards && !params.nextCellPosition && col) {
              addNewRow();
              return {
                rowIndex: -1,
                column: col,
                rowPinned: null,
              };
            } else return params.nextCellPosition;
          },
          stopEditingWhenCellsLoseFocus: true,
        }}
      />

      {editTable && (
        <>
          <Button
            icon={<PlusOutlined />}
            onClick={() => addNewRow()}
            size="small"
            ghost
            type="primary"
            disabled={withLinkedItems}
          >
            Add Product
          </Button>
          {getProductFromShipment && (
            <Button
              icon={<PlusOutlined />}
              onClick={() => setShowGetProduct(true)}
              size="small"
              ghost
              type="primary"
              style={{ marginLeft: '10px' }}
              disabled={!withLinkedItems}
            >
              {buttonLable}
            </Button>
          )}
          <Button
            icon={<DeleteOutlined />}
            size="small"
            ghost
            type="primary"
            danger
            style={{ marginLeft: '10px' }}
            disabled={!rowSelected}
            onClick={() => {
              Modal.confirm({
                title: 'Confirm',
                content: 'Are you sure you want to delete this product item?',
                okText: 'Ok',
                okType: 'danger',
                onOk: () => {
                  newDeleteRow();
                },
                onCancel: () => {
                  gridRef?.current?.api?.deselectAll();
                },
                cancelText: 'Cancel',
              });
            }}
          ></Button>
          {showGetProduct && (
            <GetProductFromShipmentDrawer
              linkedItems={linkedItems}
              isVisible={showGetProduct}
              setIsVisible={setShowGetProduct}
              setSelectedProductItems={setSelectedProduct}
              orderCurrency={orderCurrency}
              type={type}
            />
          )}
        </>
      )}
    </>
  );
});

// export default ProductOrderItem;

const ProductOrderItemInternal = observer(function ChargesLayoutInternal(
  props: ProductOrderItemInternalProps
) {
  const [loading, setLoading] = useState(true);
  const [configData, setConfigData] = useState<ERPNextCompanyConfigData | undefined>(undefined);
  const { getErpConfigData } = props;

  useEffect(() => {
    async function fetchErpData() {
      const promises: Promise<any>[] = [getErpConfigData()];
      const result = await Promise.all(promises);
      if (result[0].error) message.error(errorMessageHandlerGraphQLString(result[0].error));
      setConfigData(result[0].data);
      setLoading(false);
    }
    fetchErpData();
  }, [getErpConfigData]);

  if (loading) return <Card loading={loading} />;

  if (!configData) return <div>Error while loading config data.</div>;
  const uom = configData.unit_of_measurements;

  return <ProductOrderItem {...props} uom={uom} />;
});

export default function ProductOrderItemWrapper(props: ProductOrderItemProps) {
  return (
    <ErpNextCompanyConfigDataContext.Consumer>
      {({ getErpConfigData }: ErpNextCompanyConfigDataContextType) => {
        return <ProductOrderItemInternal {...props} getErpConfigData={getErpConfigData} />;
      }}
    </ErpNextCompanyConfigDataContext.Consumer>
  );
}
