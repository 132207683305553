// eslint-disable-next-line @typescript-eslint/ban-ts-comment

import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';

import './style.css';
import { QuestionCircleOutlined, Tag } from '@shipmnts/pixel-hub';
import {
  Checkbox,
  Col,
  Collapse,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Tooltip,
  Typography,
  message,
} from '@shipmnts/pixel-hub';
import { CheckboxChangeEvent } from '@shipmnts/pixel-hub';
import { TeamFormTable, useSession } from 'common';
import {
  CustomIcon,
  addDocumentParents,
  deleteUploadedDocument,
  DocumentsList,
  documentsStore,
  uploadDocument,
  UploadDocumentModal,
  UploadDocumentPayload,
  UploadedDocuments,
  UploadedDocumentType,
  dayjs,
  DatePicker,
} from '@shipmnts/pixel-hub';
import { TagsSearch, AddressCompanySearch } from 'common';
import { CargoDetails, ContainerDetails } from 'operations';
import { uniq } from 'lodash';

import { getInquiryParamsFromInquiry } from 'sales_hub/components/Inquiry/common';
import { isLiveReeferCargo } from 'sales_hub/models/Cargo';
import { INQUIRY_LOST_STATUS, InquiryValue } from 'sales_hub/models/inquiry';
import {
  FREIGHT_TYPE_AIR,
  FREIGHT_TYPE_OCEAN,
  LOAD_TYPE_OPTIONS,
  LOAD_TYPE_TOOLTIP_TEXT,
  TypeFreightType,
  RENDER_INQUIRY_FOR_LOST,
  RENDER_INQUIRY_FOR_WON,
} from 'sales_hub/utils/constants';
import { LocationType } from 'sales_hub/models/Location';
import { GlobalSearch } from '@shipmnts/pixel-hub';
import TeamAutoFillWrapper from 'common/components/Team/TeamAutoFillWrapper';
import {
  TEAM_ROLE_CUSTOMER_SERVICE,
  TEAM_ROLE_PRICING,
  TEAM_ROLE_SALES,
} from 'network/baseConstants';

const { Panel } = Collapse;

const { Option } = Select;

type RenderFrom = typeof RENDER_INQUIRY_FOR_LOST | typeof RENDER_INQUIRY_FOR_WON;

type Props = {
  data?: any;
  inquiry?: InquiryValue;
  form: FormInstance;
  onFinish?: (x: any, callback: (inquiry_id: string) => Promise<void>) => void;
  renderForm?: RenderFrom;
  isAmend?: boolean;
};

const InquiryForm = (props: Props) => {
  // States
  const [defaultOpen, setDefaultOpen] = useState<string[]>(['']);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [preFillTeam, setPreFillTeam] = useState<
    | {
        reference_id: string;
        branch_id: string;
        reference_type: string;
      }
    | undefined
  >();

  const [documentsStoreValue, dispatch] = useReducer(documentsStore, {
    uploadingDocuments: [],
    errors: {},
    documents: [],
  });

  // Refs
  const containerRequestsRef = useRef<{ runValidation: () => boolean }>();

  // Methods and Constants
  const { inquiry, form, onFinish, renderForm } = props;
  const data = props.data || null;
  const editInquiryDisabled =
    inquiry?.last_action_status === INQUIRY_LOST_STATUS && renderForm !== RENDER_INQUIRY_FOR_WON;
  const session = useSession();

  const handleChange = (tag: string, checked: boolean) => {
    if (editInquiryDisabled) return;

    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag);
    setSelectedTags(nextSelectedTags);
    const services: any = {};
    nextSelectedTags.forEach((i) => {
      services[i] = true;
    });
    selectedTags.forEach((i) => {
      if (!nextSelectedTags.includes(i)) services[i] = false;
    });
    form.setFieldsValue({
      services: services,
    });
  };
  const uploadDoc = useCallback(async (doc: UploadDocumentPayload) => {
    dispatch({ type: 'uploading', payload: { doc } });
    const { response, error } = await uploadDocument(doc, process.env.DOCGEN_URL || '');
    if (error) {
      dispatch({ type: 'upload_failure', payload: { doc, error: error.message } });
    } else if (response) {
      const uploaded_document = response?.data?.document;
      dispatch({ type: 'upload_success', payload: { doc: uploaded_document } });
    }
  }, []);

  const deleteDoc = useCallback(
    async (doc: UploadedDocumentType) => {
      dispatch({ type: 'deleting', payload: { doc } });
      const { response, error } = await deleteUploadedDocument(
        doc.id,
        session.id,
        process.env.DOCGEN_URL || ''
      );
      if (error) {
        message.error(error.message);
        dispatch({ type: 'delete_failure', payload: { doc } });
      } else if (response) {
        dispatch({ type: 'remove_document', payload: { doc: doc } });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [session.id]
  );
  const {
    uploadingDocuments = [],
    errors: uploadingError = {},
    documents = [],
  } = documentsStoreValue;

  const updateDocs = useCallback(
    async (inquiry_id: string) => {
      if (documents.length > 0) {
        const _updateDocIds = documents.map((d: any) => d.id);
        const { response, error } = await addDocumentParents(
          _updateDocIds,
          [{ parent_type: 'inquiry', parent_id: inquiry_id }],
          session.id,
          process.env.DOCGEN_URL || ''
        );
        if (error) {
          message.error('Document upload failed');
        } else if (response) {
          dispatch({ type: 'reset_state' });
        }
      }
    },
    [documents, session.id]
  );

  const onFormSubmit = (values: any) => {
    if (onFinish) onFinish(values, updateDocs);
  };

  const handleCompanyChange = (value: any) => {
    const businessReceivedThrough = form.getFieldValue('business_received_through');
    if (businessReceivedThrough === 'direct' && value) {
      form.setFieldValue('billing_party', value);
    }

    if (
      value?.party_company?.sales_partner?.country_of_incorporation &&
      session?.company_account?.country_of_incorporation
    ) {
      if (
        value.party_company.sales_partner.country_of_incorporation ===
        session.company_account.country_of_incorporation
      ) {
        form.setFieldValue('business_received_through', 'subagent');
      } else {
        form.setFieldValue('business_received_through', 'agent_nomination');
      }
      form.setFieldValue('billing_party', {
        party_company: value.party_company.sales_partner,
      });
    }
    handlePrefillTeam();
  };

  const handlePrefillTeam = () => {
    const values = form.getFieldsValue();
    if (values?.customer?.party_company?.id) {
      setPreFillTeam({
        reference_id: values.customer.party_company.id,
        branch_id: values?.branchAccount?.id,
        reference_type: 'Network::Company',
      });
    }
  };

  // Effects
  //  Set default values based on Business Vertical
  useEffect(() => {
    if (data) {
      const { fields } = data;
      const toBeOpened: string[] = [];
      fields &&
        Object.entries(fields).forEach((x) => {
          const field = x[0].split('.');
          form.setFieldValue(field, x[1]);
          if (x[0].includes('freight_type') && x[1])
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            form.setFieldValue('load_type', LOAD_TYPE_OPTIONS[x[1] || 'air'][0].value);
          if (x[0].includes('services.')) toBeOpened.push(x[0]);
        });
      setDefaultOpen([...toBeOpened]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  useEffect(() => {
    // Set initial values for edit form
    if (inquiry) {
      const inquiryData = inquiry;
      const toBeOpened: string[] = [];
      if (inquiryData.services) {
        inquiryData.services.forEach((service) => {
          toBeOpened.push(`services.${service}`);
          selectedTags.push(service);
        });
        setSelectedTags(selectedTags);
        setDefaultOpen([...toBeOpened]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inquiry]);

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        requiredMark={true}
        onFinish={onFormSubmit}
        scrollToFirstError={true}
        initialValues={{
          cargos: [
            {
              package_type: 'Box',
              weight_unit: 'kgs',
              volume_unit: 'cbm',
              commodity: {
                commodity_classification_code: '000000',
                commodity_coding_system: 'MHS',
                dangerous_cargo: false,
                dry_cargo: true,
                id: '523',
                live_reefer_cargo: false,
                name: 'General Cargo',
                oog_cargo: false,
              },
              commodity_description: 'General Cargo',
            },
          ],
          other_terms: 'prepaid',
          freight_terms: 'prepaid',
          priority: 'lowest',
          trade_type: 'import',
          received_at: dayjs(new Date()),
          business_received_through: 'direct',
          business_vertical_id: String(
            inquiry?.business_vertical?.id ||
              props?.data?.business_vertical_id ||
              session.company_account.business_verticals?.[0]?.id
          ),
          customer: props?.data?.company && { party_company: props?.data?.company },
          ...inquiry,
          ...getInquiryParamsFromInquiry(inquiry),
        }}
      >
        {inquiry?.last_action_status === INQUIRY_LOST_STATUS &&
          renderForm !== RENDER_INQUIRY_FOR_WON && (
            <>
              <Typography.Title level={4} style={{ color: '#0a4fca' }}>
                Inquiry Lost Details
              </Typography.Title>
              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name="additional_loss_remarks"
                    label="Additional Loss Remarks / Loss Remarks for pricing"
                  >
                    <Input placeholder="Enter additional comments or remarks for loss" />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

        {/* {// TODO: Color should be configured from theme} */}
        <Typography.Title level={4} style={{ color: '#0a4fca', marginTop: '1em' }}>
          Basic Details
        </Typography.Title>
        {/* Only incase of duplication */}

        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item name="business_vertical_id" label="Business Vertical">
              <Select
                disabled={props?.isAmend}
                style={{ width: '100%' }}
                options={session.company_account.business_verticals.map(
                  (vertical: { id: string; name: string }) => ({
                    value: vertical.id,
                    label: vertical.name,
                    key: vertical.id,
                  })
                )}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={8}>
            <Form.Item
              name="freight_type"
              label="Freight Type"
              rules={[{ required: true, message: 'Please select freight type' }]}
            >
              <Radio.Group
                disabled={editInquiryDisabled || props?.isAmend}
                onChange={(val) => {
                  form.resetFields(['origin', 'destination', 'preferredCarrier']);
                  form.setFieldValue(
                    'load_type',
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    LOAD_TYPE_OPTIONS[val.target.value || 'air'][0].value
                  );
                }}
              >
                {/* TODO: Trade freight type constants */}
                <Radio.Button value="air">
                  <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                    <CustomIcon
                      height="12"
                      width="12"
                      style={{ marginRight: '6px' }}
                      icon="FlightTakeoff"
                    />
                    Air
                  </span>
                </Radio.Button>
                <Radio.Button value="ocean">
                  <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                    <CustomIcon
                      height="14"
                      width="14"
                      style={{ marginRight: '6px' }}
                      icon="ShipIconNew"
                    />
                    Ocean
                  </span>
                </Radio.Button>
                <Radio.Button value="road">
                  <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                    <CustomIcon
                      height="14"
                      width="14"
                      style={{ marginRight: '6px' }}
                      icon="TruckIcon"
                    />
                    Land
                  </span>
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              name="trade_type"
              label="Trade Type"
              rules={[{ required: true, message: 'Please select trade type' }]}
            >
              {/* //TODO: Trade trade type constants */}
              <Radio.Group disabled={editInquiryDisabled || props?.isAmend}>
                <Radio.Button value="import">Import</Radio.Button>
                <Radio.Button value="export">Export</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item noStyle shouldUpdate={true}>
              {({ getFieldValue }) => {
                const freightType: TypeFreightType = getFieldValue('freight_type');
                const initialValue = LOAD_TYPE_OPTIONS[freightType || 'air'][0].value;
                return (
                  <Form.Item
                    name="load_type"
                    label="Load Type"
                    rules={[{ required: true, message: 'Please select load type' }]}
                    dependencies={['freight_type']}
                    tooltip={LOAD_TYPE_TOOLTIP_TEXT[freightType]}
                  >
                    <Radio.Group
                      disabled={editInquiryDisabled || props?.isAmend}
                      defaultValue={initialValue}
                      options={LOAD_TYPE_OPTIONS[freightType || 'air']}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item name="branchAccount" label="Branch" rules={[{ required: true }]}>
              <GlobalSearch
                doc_type="Network::BranchAccount"
                onChange={handlePrefillTeam}
                disabled={editInquiryDisabled}
                extraProps={{
                  options: session.branch_accounts,
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="business_received_through"
              label="Business Received Through"
              rules={[{ required: true }]}
              tooltip="Source of business generated - allows you to classify self generated or business referred from an overseas nomination or a third-party subagent"
            >
              <Radio.Group
                disabled={editInquiryDisabled}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value === 'direct') {
                    const initialValue = form.getFieldValue('customer');
                    form.setFieldValue('billing_party', initialValue);
                  }
                }}
                options={[
                  {
                    label: 'Direct',
                    value: 'direct',
                  },
                  {
                    label: 'Subagent',
                    value: 'subagent',
                  },
                  {
                    label: 'Overseas Nomination',
                    value: 'agent_nomination',
                  },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item noStyle shouldUpdate={true}>
              <Form.Item name="customer" label="Customer/Lead" rules={[{ required: true }]}>
                <AddressCompanySearch
                  companySearchProps={{
                    searchProps: { is_lead: true },
                    disabled: editInquiryDisabled,
                    showCreditStatusTag: true,
                  }}
                  onChange={handleCompanyChange}
                  addressSearchProps={{
                    disabled: editInquiryDisabled,
                  }}
                />
              </Form.Item>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item noStyle shouldUpdate={true}>
              {({ getFieldValue }) => {
                const businessReceivedThrough = getFieldValue('business_received_through');
                const disabled = businessReceivedThrough === 'direct' || editInquiryDisabled;
                return (
                  <Form.Item
                    name="billing_party"
                    label="Agent Company"
                    rules={[{ required: true }]}
                    dependencies={['business_received_through', 'customer']}
                    tooltip="Agent through which you received customer."
                  >
                    <AddressCompanySearch
                      companySearchProps={{
                        disabled: disabled,
                      }}
                      addressSearchProps={{ disabled: disabled }}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item name="received_at" label="Inquiry Received On">
              <DatePicker
                disabled={editInquiryDisabled}
                showTime={true}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="priority"
              label="Priority"
              tooltip="The urgency of the Inquiry, Highest priority indicates that the Inquiry needs faster turn around on pricing"
            >
              <Select disabled={editInquiryDisabled} placeholder="Please choose the type">
                <Option value="highest">Highest</Option>
                <Option value="high">High</Option>
                <Option value="medium">Medium</Option>
                <Option value="low">Low</Option>
                <Option value="lowest">Lowest</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="inquiryDescription" label="Inquiry Description">
              <Input.TextArea
                disabled={editInquiryDisabled}
                rows={4}
                placeholder="Enter comments or inquiry as received from customer"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              name="target_rate"
              label="Target Rate"
              tooltip="The desired rate at which customer expects the Price"
            >
              <Input disabled={editInquiryDisabled} placeholder="Target Rate" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              name="targetClosingDate"
              label="Target Closing Date"
              tooltip="Target date to get closure on the Inquiry"
            >
              <DatePicker disabled={editInquiryDisabled} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="tags"
              label="Tags"
              tooltip="Categorize your Inquiries by providing custom tags and get better visibility on them."
            >
              <TagsSearch disabled={editInquiryDisabled} purpose={'inquiry'} />
            </Form.Item>
          </Col>
        </Row>

        <div style={{ marginBottom: '10px' }}>
          <Typography.Title level={4} style={{ color: '#0a4fca' }}>
            Team Details
          </Typography.Title>
          <div style={{ display: 'block' }}>
            <TeamAutoFillWrapper
              form={form}
              preFillFields={
                inquiry && !preFillTeam
                  ? undefined
                  : {
                      ...preFillTeam,
                      roles: [TEAM_ROLE_CUSTOMER_SERVICE, TEAM_ROLE_PRICING, TEAM_ROLE_SALES],
                    }
              }
            >
              <Form.Item name="teams">
                <TeamFormTable reference_type="SalesHub::Inquiry" editable={!editInquiryDisabled} />
              </Form.Item>
            </TeamAutoFillWrapper>
          </div>
        </div>

        {/* Services - Freight Forwarding / Customs Clearance */}
        <div style={{ marginBottom: '10px' }}>
          <Typography.Title level={4} style={{ color: '#0a4fca' }}>
            Services
          </Typography.Title>

          <Collapse
            activeKey={defaultOpen}
            onChange={(args) => {
              if (Array.isArray(args)) setDefaultOpen(args);
            }}
          >
            {/* Freight Forwarding Section */}
            <Panel
              header={
                <Form.Item
                  noStyle
                  name={['services', 'freight_forwarding']}
                  valuePropName="checked"
                >
                  <Checkbox
                    disabled={editInquiryDisabled}
                    onChange={(e: CheckboxChangeEvent) => {
                      const val = e.target.checked;
                      let temp = defaultOpen;
                      if (val) {
                        temp.push('services.freight_forwarding');
                      } else {
                        temp = temp.filter((i) => i !== 'services.freight_forwarding');
                      }
                      setDefaultOpen(uniq(temp));
                    }}
                  >
                    Freight Forwarding
                  </Checkbox>
                </Form.Item>
              }
              key="services.freight_forwarding"
              showArrow={false}
              collapsible={'icon'}
            >
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => {
                  return (
                    prevValues.services?.freight_forwarding !==
                      currentValues.services?.freight_forwarding ||
                    prevValues.load_type !== currentValues.load_type
                  );
                }}
              >
                {({ getFieldValue }) => {
                  const freightForwarding = getFieldValue(['services', 'freight_forwarding']);
                  const freightType = getFieldValue('freight_type');
                  const type: [LocationType] = ['City'];
                  const locationType: [LocationType] = ['ICD'];
                  if (freightType === FREIGHT_TYPE_AIR) {
                    type.push('Airport');
                    locationType.push('Airport');
                  } else if (freightType === FREIGHT_TYPE_OCEAN) {
                    type.push('Seaport');
                    type.push('ICD');
                    locationType.push('Seaport');
                  }
                  const locationSearchProps = {
                    type: type,
                  };
                  if (freightForwarding)
                    return (
                      <>
                        <Row gutter={16}>
                          <Col xs={24} sm={12}>
                            <Form.Item
                              name="origin"
                              label="Origin"
                              rules={[{ required: true }]}
                              extra="Search for a Port , Inland Container Depots or City"
                            >
                              <GlobalSearch
                                doc_type="Global::Location"
                                disabled={editInquiryDisabled}
                                searchProps={locationSearchProps}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12}>
                            <Form.Item
                              name="destination"
                              label="Destination"
                              rules={[{ required: true }]}
                              extra="Search for a Port , Inland Container Depots or City"
                            >
                              <GlobalSearch
                                doc_type="Global::Location"
                                disabled={editInquiryDisabled}
                                searchProps={locationSearchProps}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        {freightType === FREIGHT_TYPE_OCEAN && (
                          <Row gutter={16}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name="place_of_carrier_receipt"
                                label="Place Of Carrier Receipt"
                                extra="Search for an Inland Container Depot or Container Freight Station"
                              >
                                <GlobalSearch
                                  doc_type="Global::Location"
                                  disabled={editInquiryDisabled}
                                  searchProps={{ type: locationType }}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name="place_of_carrier_delivery"
                                label="Place Of Carrier Delivery"
                                extra="Search for an Inland Container Depot or Container Freight Station"
                              >
                                <GlobalSearch
                                  doc_type="Global::Location"
                                  disabled={editInquiryDisabled}
                                  searchProps={{ type: locationType }}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        )}
                        <Row gutter={16}>
                          <Col xs={24} sm={12}>
                            <Form.Item
                              noStyle
                              shouldUpdate={(prevValues, currentValues) =>
                                prevValues.freight_type !== currentValues.freight_type
                              }
                            >
                              {({ getFieldValue }) => {
                                const freightType: TypeFreightType = getFieldValue('freight_type');
                                return (
                                  <Form.Item name="preferredCarrier" label="Preferred Carrier">
                                    <GlobalSearch
                                      doc_type="Global::Carrier"
                                      selectMode="multiple"
                                      searchProps={{ carrier_type: [freightType] }}
                                      disabled={editInquiryDisabled}
                                    />
                                  </Form.Item>
                                );
                              }}
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12}>
                            <Form.Item
                              name="incoterms"
                              label="Incoterms"
                              tooltip="Standardized international trade terms that clarify the responsibilities between buyers and sellers for the delivery of goods"
                            >
                              <GlobalSearch
                                doc_type="Global::Incoterms"
                                disabled={editInquiryDisabled}
                              />
                            </Form.Item>
                          </Col>

                          <Col lg={6} sm={12}>
                            <Form.Item
                              name="cargoReadyDate"
                              label="Cargo Ready Date"
                              tooltip="The date when the goods will be ready for pickup"
                            >
                              <DatePicker
                                style={{ width: '100%' }}
                                disabled={editInquiryDisabled}
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={6} sm={12}>
                            <Form.Item name="targetDeliveryDate" label="Target Delivery Date">
                              <DatePicker
                                style={{ width: '100%' }}
                                disabled={editInquiryDisabled}
                              />
                            </Form.Item>
                          </Col>

                          <Col lg={6} sm={12}>
                            <Form.Item label="Freight Terms" name="freight_terms">
                              <Radio.Group
                                options={[
                                  { label: 'PP - Prepaid', value: 'prepaid' },
                                  { label: 'CC - Collect', value: 'collect' },
                                ]}
                                disabled={editInquiryDisabled}
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={6} sm={12}>
                            <Form.Item label="Other Terms" name="other_terms">
                              <Radio.Group
                                options={[
                                  { label: 'PP - Prepaid', value: 'prepaid' },
                                  { label: 'CC - Collect', value: 'collect' },
                                ]}
                                disabled={editInquiryDisabled}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, currentValues) =>
                            prevValues.freight_type !== currentValues.freight_type
                          }
                        >
                          {({ getFieldValue }) => {
                            const freightType: TypeFreightType = getFieldValue('freight_type');
                            if (freightType === FREIGHT_TYPE_AIR) return <></>;
                            return (
                              <>
                                <Row gutter={16}>
                                  <Col sm={24}>
                                    <label> Free Days</label>
                                  </Col>
                                  <Col lg={4} sm={12}>
                                    <Form.Item
                                      name="origin_carrier_free_days"
                                      label="Carrier Origin"
                                    >
                                      <InputNumber
                                        disabled={editInquiryDisabled}
                                        style={{ width: '100%' }}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col lg={4} sm={12}>
                                    <Form.Item
                                      name="destination_carrier_free_days"
                                      label="Carrier Destination"
                                    >
                                      <InputNumber
                                        disabled={editInquiryDisabled}
                                        style={{ width: '100%' }}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col lg={4} sm={12}>
                                    <Form.Item
                                      name="destination_port_free_days"
                                      label="Destination Port"
                                    >
                                      <InputNumber
                                        disabled={editInquiryDisabled}
                                        style={{ width: '100%' }}
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </>
                            );
                          }}
                        </Form.Item>
                      </>
                    );
                  return <></>;
                }}
              </Form.Item>
            </Panel>
            {/* Origin Customs Service Section */}
            <Panel
              header={
                <div style={{ width: '100%' }}>
                  <Form.Item
                    noStyle
                    name={['services', 'origin_custom_clearance']}
                    valuePropName="checked"
                  >
                    <Checkbox
                      disabled={editInquiryDisabled}
                      onChange={(e: CheckboxChangeEvent) => {
                        const val = e.target.checked;
                        let temp = defaultOpen;
                        if (val) {
                          temp.push('services.origin_custom_clearance');
                        } else {
                          temp = temp.filter((i) => i !== 'services.origin_custom_clearance');
                        }
                        setDefaultOpen(uniq(temp));
                      }}
                      name="origin_custom_clearance"
                    >
                      Origin Custom Clearance
                    </Checkbox>
                  </Form.Item>
                </div>
              }
              key="services.origin_custom_clearance"
              showArrow={false}
              collapsible={'icon'}
            >
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => {
                  return (
                    prevValues.services?.origin_custom_clearance !==
                    currentValues.services?.origin_custom_clearance
                  );
                }}
              >
                {({ getFieldValue }) => {
                  const customClearance = getFieldValue(['services', 'origin_custom_clearance']);
                  if (customClearance)
                    return (
                      <Row gutter={16}>
                        <Col xs={24} sm={12}>
                          <Form.Item name="originCustomClearance" label="Origin Custom Clearance">
                            <GlobalSearch
                              doc_type="Global::Location"
                              disabled={editInquiryDisabled}
                              searchProps={{ is_customs_location: true }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    );
                  return <></>;
                }}
              </Form.Item>
            </Panel>
            {/* Destination Customs Service Section */}
            <Panel
              header={
                <div style={{ width: '100%' }}>
                  <Form.Item
                    noStyle
                    name={['services', 'destination_custom_clearance']}
                    valuePropName="checked"
                  >
                    <Checkbox
                      disabled={editInquiryDisabled}
                      onChange={(e: CheckboxChangeEvent) => {
                        const val = e.target.checked;
                        let temp = defaultOpen;
                        if (val) {
                          temp.push('services.destination_custom_clearance');
                        } else {
                          temp = temp.filter((i) => i !== 'services.destination_custom_clearance');
                        }
                        setDefaultOpen(uniq(temp));
                      }}
                      name="destination_custom_clearance"
                    >
                      Destination Custom Clearance
                    </Checkbox>
                  </Form.Item>
                </div>
              }
              key="services.destination_custom_clearance"
              showArrow={false}
              collapsible={'icon'}
            >
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => {
                  return (
                    prevValues.services?.destination_custom_clearance !==
                    currentValues.services?.destination_custom_clearance
                  );
                }}
              >
                {({ getFieldValue }) => {
                  const customClearance = getFieldValue([
                    'services',
                    'destination_custom_clearance',
                  ]);
                  if (customClearance)
                    return (
                      <Row gutter={16}>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            name="destinationCustomClearance"
                            label="Destination Custom Clearance"
                          >
                            <GlobalSearch
                              doc_type="Global::Location"
                              disabled={editInquiryDisabled}
                              searchProps={{ is_customs_location: true }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    );
                  return <></>;
                }}
              </Form.Item>
            </Panel>
          </Collapse>
        </div>

        {/*  Shipment Details */}

        {/* Cargo details */}
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.freight_type !== currentValues.freight_type ||
            prevValues.load_type !== currentValues.load_type
          }
        >
          {({ getFieldValue }) => {
            const freightType: TypeFreightType = getFieldValue('freight_type');
            const loadType = getFieldValue('load_type');
            if (editInquiryDisabled) return <></>;
            return (
              <div>
                <Typography.Title level={4} style={{ color: '#0a4fca' }}>
                  Cargo & Shipment Details{' '}
                  <Tooltip title="Details about number of Individual units or Packages being shipped along with their properties">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </Typography.Title>
                <CargoDetails
                  required={false}
                  form={form}
                  load_type={loadType}
                  freightType={freightType}
                  grossVolumeRequired={freightType !== 'air'}
                />
              </div>
            );
          }}
        </Form.Item>
        {/*  Container Details */}
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.load_type !== currentValues.load_type
          }
        >
          {({ getFieldValue }) => {
            const loadType = getFieldValue('load_type');
            if (loadType === 'fcl' && !editInquiryDisabled)
              return (
                <>
                  <Typography.Title level={4} style={{ color: '#0a4fca' }}>
                    Container Details
                  </Typography.Title>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.cargos !== currentValues.cargos
                    }
                  >
                    {({ getFieldValue }) => {
                      const cargos = getFieldValue('cargos');
                      const isLiveReefer = cargos ? isLiveReeferCargo(cargos) : false;
                      return (
                        <Form.Item
                          name="containerRequests"
                          noStyle
                          rules={[
                            {
                              validator: (rule, value) => {
                                if (!containerRequestsRef?.current?.runValidation()) {
                                  return Promise.resolve();
                                }
                                return Promise.reject();
                              },
                            },
                          ]}
                        >
                          <ContainerDetails
                            disabled={editInquiryDisabled}
                            ref={containerRequestsRef}
                            required_fields={['container_type_code', 'quantity']}
                            isLiveReefer={isLiveReefer}
                            required={false}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </>
              );
            return <></>;
          }}
        </Form.Item>

        <Row>
          <Col span={24} sm={24}>
            <Typography.Title level={4} style={{ color: '#0a4fca' }}>
              Value Added Services{' '}
              <Tooltip title="Add Value added services like Tracking, Insurance, Pickup, etc.">
                <QuestionCircleOutlined />
              </Tooltip>
            </Typography.Title>
            {optionsWithDisabled.map((tag) => {
              const checked = selectedTags.includes(tag.value);
              return (
                <Form.Item key={tag.value} name={['services', tag.value]} noStyle={true}>
                  <Tag.CheckableTag
                    checked={selectedTags.includes(tag.value)}
                    onChange={(checked) => handleChange(tag.value, checked)}
                    style={{ backgroundColor: checked ? '#0a4fca' : '#f9f9f9', margin: '5px' }}
                  >
                    {tag.label}
                  </Tag.CheckableTag>
                </Form.Item>
              );
            })}
          </Col>
          <Col span={24} sm={24}>
            <Typography.Title level={4} style={{ color: '#0a4fca' }}>
              Upload Documents
            </Typography.Title>
            {inquiry && inquiry.id ? (
              <UploadedDocuments
                sessionData={session}
                parent_id={inquiry?.id}
                parent_type={'inquiry'}
                docgen_url={process.env.DOCGEN_URL || ''}
              />
            ) : (
              <>
                <UploadDocumentModal
                  onUpload={uploadDoc}
                  sessionData={session}
                  initialUploadDocumentTags={['RFQ']}
                />
                <DocumentsList
                  uploadingDocuments={uploadingDocuments}
                  uploadedDocuments={documents}
                  uploadingError={uploadingError}
                  onUpload={uploadDoc}
                  onDelete={deleteDoc}
                  sessionData={session}
                />
              </>
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default InquiryForm;

const optionsWithDisabled = [
  { label: 'Live Tracking', value: 'live_tracking' },
  { label: 'Cargo Insurance', value: 'cargo_insurance', disabled: false },
  { label: 'Empty Container Insurance', value: 'empty_container_insurance', disabled: false },
  { label: 'Palletization', value: 'palletization', disabled: false },
  { label: 'Fumigation', value: 'fumigation', disabled: false },
  { label: 'Warehousing / CFS', value: 'warehouse', disabled: false },
  { label: 'Origin Transport', value: 'origin_transport', disabled: false },
  { label: 'Destination Transport', value: 'destination_transport', disabled: false },
];
