import { gql } from '@apollo/client';

export const VALIDATE_EMAIL_CONTACTS = gql`
  query validate_email_contacts($emails: [String!]!, $looped_in_parties: [String!]!) {
    validate_email_contacts(emails: $emails, looped_in_parties: $looped_in_parties)
  }
`;

export const FETCH_EMAIL_TEMPLATES = gql`
  query email_templates(
    $limit: Int
    $offset: Int
    $filters: [FilterInputType!]
    $sort: [SortInputTypeNew!]
  ) {
    email_templates(limit: $limit, offset: $offset, filters: $filters, sort: $sort) {
      total
      data {
        id
        template_name
        action_name
        auto_attachment_tags
        default_parties
        allowed_parties
        resource_type
        has_email
        has_whatsapp
        pivot_fields
      }
    }
  }
`;

export const PARSE_TEXT_TEMPLATE = gql`
  mutation parse_text_template(
    $email_template_id: ID!
    $resource_ids: [ID!]!
    $custom_content: String
    $attachments: [EmailAttachmentInputType!]
    $attach_print: AttachPrintInputType
  ) {
    parse_text_template(
      email_template_id: $email_template_id
      resource_ids: $resource_ids
      custom_content: $custom_content
      attachments: $attachments
      attach_print: $attach_print
    ) {
      id
      action_name
      template_name
      text_template_body
    }
  }
`;

export const PARSE_EMAIL_TEMPLATE = gql`
  mutation parse_email_template(
    $email_template_id: ID!
    $resource_ids: [ID!]!
    $custom_content: String
  ) {
    parse_email_template(
      email_template_id: $email_template_id
      resource_ids: $resource_ids
      custom_content: $custom_content
    ) {
      id
      action_name
      subject
      body
      template_name
    }
  }
`;

export const DELIVER_DOCUMENT_EMAIL = gql`
  mutation deliver_document_email(
    $subject: String!
    $email_body: String!
    $recipient_emails: [String!]!
    $cc_emails: [String!]
    $bcc_emails: [String!]
    $document_type: String
    $document_id: String
    $email_template_id: ID
    $email_resource_ids: [ID!]
    $attachments: [EmailAttachmentInputType!]
    $attach_print: AttachPrintInputType
  ) {
    deliver_document_email(
      subject: $subject
      email_body: $email_body
      recipient_emails: $recipient_emails
      cc_emails: $cc_emails
      bcc_emails: $bcc_emails
      document_type: $document_type
      document_id: $document_id
      email_template_id: $email_template_id
      email_resource_ids: $email_resource_ids
      attachments: $attachments
      attach_print: $attach_print
    ) {
      message
    }
  }
`;

export const BULK_SEND_EMAIL = gql`
  mutation bulk_send_email($action_name: String!, $resource_ids: [ID!]!) {
    bulk_send_email(action_name: $action_name, resource_ids: $resource_ids) {
      message
    }
  }
`;

export const FETCH_EMAIL_ACTIVITY_FROM_RESOURCE = gql`
  query fetch_email_activity_from_resource($resource_id: ID!, $resource_type: String!) {
    fetch_email_activity_from_resource(resource_id: $resource_id, resource_type: $resource_type) {
      id
      purpose
      subject
      recipient_emails
      cc_emails
      sent_by {
        first_name
        last_name
      }
      delivered_count
      failed_count
      bounce_count
      recipients_count
      sent_at
    }
  }
`;
