import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { Table, Skeleton, Typography, Button } from '@shipmnts/pixel-hub';
import { AccountingDocumentType } from '@shipmnts/pixel-hub';
import { AlignType } from 'rc-table/lib/interface';
import { ShipmentValue } from 'operations/models/Shipment';
import { SessionDataValue } from 'operations/models/SessionData';
import { CompanyValue } from 'operations/models/Company';
import { decimalPointFormater } from 'common';
import { erpnextApis } from 'network';

const { Link, Text } = Typography;

interface AccountingDocumentsTableProps {
  fetchingAccountingDocuments: boolean;
  setFetchingAccountingDocuments: (value: boolean) => void;
  documents?: AccountingDocumentType[];
  shipment: ShipmentValue;
  selectedAccountingDocuments: React.Key[];
  setSelectedAccountingDocuments: (value: React.Key[]) => void;
  setAccountingDocuments: (value: AccountingDocumentType[]) => void;
  docgen_url: string;
  sessionData: SessionDataValue;
  selectedPartyName?: string;
  defaultParty: CompanyValue;
}

const renderMultiCurrencyTotal = (multiCurrencyTotal: { [key: string]: number }) => {
  return Object.keys(multiCurrencyTotal).map((currency) => {
    return (
      <div key={currency} style={{ display: 'flex', justifyContent: 'end' }}>
        <Text>{currency}</Text>
        <Text style={{ marginLeft: '10px' }}>
          {decimalPointFormater(String(multiCurrencyTotal[currency]))}
        </Text>
      </div>
    );
  });
};

const AccountingDocumentsTable = React.memo(function AccountingDocumentsTable(
  props: AccountingDocumentsTableProps
): JSX.Element {
  const {
    fetchingAccountingDocuments,
    setFetchingAccountingDocuments,
    documents,
    shipment,
    sessionData,
    selectedAccountingDocuments,
    setSelectedAccountingDocuments,
    setAccountingDocuments,
    selectedPartyName,
    defaultParty,
  } = props;
  const [fetchAccountingDocumentsError, setFetchAccountingDocumentsError] = useState<
    boolean | string
  >(false);

  const subdomain = sessionData?.company_account?.subdomain;
  const getAccountingDocuments = useCallback(async () => {
    if (shipment) {
      const job_numbers = [shipment, ...(shipment?.house_shipments || [])].map(
        (sh) => sh.job_number
      );
      if (!fetchingAccountingDocuments) setFetchingAccountingDocuments(true);
      const { response: accounting_documents, error: accounting_documents_error } =
        await erpnextApis.fetchAccountingDocuments(
          job_numbers,
          selectedPartyName || defaultParty?.registered_name
        );
      if (accounting_documents_error)
        setFetchAccountingDocumentsError(
          accounting_documents_error instanceof Error
            ? accounting_documents_error?.message
            : !!accounting_documents_error
        );
      else if (accounting_documents?.data?.message) {
        const documents = accounting_documents?.data?.message;
        setAccountingDocuments(documents);
      }
      setFetchingAccountingDocuments(false);
    }
  }, [
    shipment,
    fetchingAccountingDocuments,
    setAccountingDocuments,
    setFetchingAccountingDocuments,
    setFetchAccountingDocumentsError,
    selectedPartyName,
    defaultParty,
  ]);

  useEffect(() => {
    const fetchDocuments = async () => await getAccountingDocuments();
    if (shipment) fetchDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipment, selectedPartyName]);

  const renderSummary = useCallback((pageData: any) => {
    const multiCurrencyTotal = pageData.reduce(
      (acc: { [key: string]: number }, record: AccountingDocumentType) => {
        if (acc[record.voucher_currency]) {
          acc[record.voucher_currency] += record.voucher_total;
        } else {
          acc[record.voucher_currency] = record.voucher_total;
        }
        return acc;
      },
      {} as { [key: string]: number }
    );
    if (!Object.keys(multiCurrencyTotal).length) return null;
    return (
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} colSpan={5} align="right">
          <Text>Total</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={1} align="right">
          {Object.keys(multiCurrencyTotal).length > 1 ? (
            renderMultiCurrencyTotal(multiCurrencyTotal)
          ) : (
            <Text>{decimalPointFormater(String(Object.values(multiCurrencyTotal)[0]))}</Text>
          )}
        </Table.Summary.Cell>
      </Table.Summary.Row>
    );
  }, []);

  const columns = useMemo(
    () => [
      {
        title: 'Voucher Type',
        dataIndex: 'voucher_type',
      },
      {
        title: 'Voucher #',
        dataIndex: 'voucher_name',
        render: (voucher_name: string, record: AccountingDocumentType) => {
          let voucher_link = window.encodeURI(
            `${subdomain}/desk#Form/${record.voucher_type}/${record.name}`
          );
          if (record?.voucher_type === 'Sales Invoice')
            voucher_link = `${subdomain}${record?.voucher_pdf_link}`;
          return (
            <Link href={voucher_link} target="_blank" style={{ color: '#3b72d5' }}>
              {voucher_name}
            </Link>
          );
        },
      },
      {
        title: 'Voucher Date',
        dataIndex: 'voucher_date',
      },
      {
        title: 'Currency',
        dataIndex: 'voucher_currency',
        align: 'right' as AlignType,
      },
      {
        title: 'Voucher Amount',
        dataIndex: 'voucher_total',
        render: (voucher_total: number) => {
          return decimalPointFormater(String(voucher_total));
        },
        align: 'right' as AlignType,
      },
      ...(shipment?.shipment_type === 'consol'
        ? [
            {
              title: 'Job #',
              dataIndex: 'job_number',
            },
          ]
        : []),
      {
        title: 'Remarks',
        dataIndex: 'remarks',
      },
    ],
    [shipment?.shipment_type, subdomain]
  );

  if (fetchingAccountingDocuments) return <Skeleton active />;
  return (
    <>
      {fetchAccountingDocumentsError ? (
        <div>
          Something went wrong. {fetchAccountingDocumentsError}
          <span style={{ marginLeft: '5px' }}>
            <Button onClick={getAccountingDocuments}>Retry</Button>
          </span>
        </div>
      ) : (
        <Table
          rowSelection={{
            selectedRowKeys: selectedAccountingDocuments,
            onChange: (selectedRowKeys: React.Key[]) =>
              setSelectedAccountingDocuments(selectedRowKeys),
          }}
          columns={columns}
          dataSource={documents}
          size="small"
          bordered
          title={() => 'Select Invoice vouchers'}
          rowKey={(record) => record.name || ''}
          pagination={false}
          summary={renderSummary}
        />
      )}
    </>
  );
});

export default AccountingDocumentsTable;
