import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Select,
  Form,
  Input,
  message,
  Space,
  Button,
  Typography,
  deleteUploadedDocument,
  getDocumentUploadPayload,
  uploadDocument,
} from '@shipmnts/pixel-hub';
import { CloseOutlined, PaperClipOutlined } from '@shipmnts/pixel-hub';
import { Upload, RcFile, UploadProps } from '@shipmnts/pixel-hub';
import { useSession } from 'common';
import { useCompanyView } from './CompanyView/CompanyView';
import { truncate as _truncate } from 'lodash';
const { Option } = Select;

const ROW_GUTTER = 32;

function AttachFileComponent(props: any) {
  const [file, setFile] = useState<RcFile>();
  const { company } = useCompanyView();
  const session = useSession();

  useEffect(() => {
    const uploadDoc = async () => {
      const payload = getDocumentUploadPayload(file as RcFile, {}, session);
      props.setLoading(true);
      const { response, error } = await uploadDocument(payload, process.env.DOCGEN_URL || '');
      props.setLoading(false);
      if (error) {
        message.error(error);
      } else {
        message.success('Document uploaded successfully');
        const attachedDoc: any = {
          document_identifier: response.data.document.id,
          document_url: response.data.document.uploaded_document,
          content_type: response.data.document.content_type,
          file_size: response.data.document.file_size,
          file_name: response.data.document.file_name,
        };
        props.onChange(attachedDoc);
      }
    };
    file && uploadDoc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const deleteDoc = async () => {
    if (props?.value?.document_identifier) {
      props.setLoading(true);
      const { response, error } = await deleteUploadedDocument(
        props?.value?.document_identifier,
        session.id,
        process.env.DOCGEN_URL || ''
      );
      props.setLoading(false);
      if (error) {
        message.error('Error in deleting the document');
      } else if (response) {
        message.success('Removed successfully!');
        props.onChange({});
      }
    }
  };

  const downloadDoc = () => {
    window.open(props?.value?.document_url, '_blank')?.focus();
  };

  const fileUploadProps: UploadProps = {
    name: 'file',
    fileList: [],
    onRemove: async (file) => setFile(undefined),
    beforeUpload: (file, fileList) => {
      setFile(file);
    },
  };

  return (
    <Space>
      {props?.value?.file_name ? (
        <>
          <Button onClick={() => downloadDoc()} style={{ overflow: 'hidden' }} type="text">
            <Typography.Link title={props?.value?.file_name}>
              {_truncate(props?.value?.file_name, { length: 15 })}
            </Typography.Link>
          </Button>
          <Button
            disabled={company?.is_disabled}
            onClick={() => deleteDoc()}
            className="editor_btn"
            icon={<CloseOutlined />}
          ></Button>
        </>
      ) : (
        <Upload
          disabled={company?.is_disabled}
          {...fileUploadProps}
          style={{ width: '24px', height: '40px' }}
        >
          <Button title="Attach Files" icon={<PaperClipOutlined />} disabled={company?.is_disabled}>
            {'Attach File'}
          </Button>
        </Upload>
      )}
    </Space>
  );
}

const RegulatoryDetailsForm = React.memo(function RegulatoryDetailsForm({
  options,
  setLoading,
}: {
  options?: any[];
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <>
      <Row gutter={ROW_GUTTER}>
        <Col span={12} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <Form.Item name="key" label="Reference Body">
            <Select allowClear showSearch>
              {options?.map((opt) => (
                <Option key={opt.label} value={opt.value}>
                  {opt.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12} xs={{ span: 18 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <Form.Item name={`value`} label={`Reference Value`}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={`attachment`}>
            <AttachFileComponent setLoading={setLoading} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
});

export default RegulatoryDetailsForm;
