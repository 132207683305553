import { gql } from '@apollo/client';

export const FETCH_TICKET = gql`
  query service_management_ticket($id: ID!) {
    service_management_ticket(id: $id) {
      id
      title
      priority
      description
      ticket_number
      ticket_status
      assigned_to {
        department
        email
        name
        first_name
        id
        last_name
        mobile_number
      }
      raised_by_company {
        id
        registered_name
      }
      raised_by_user {
        department
        email
        name
        first_name
        id
        last_name
        mobile_number
      }
      resolution_by_company {
        id
        registered_name
      }
      resolution_by_user {
        department
        email
        name
        first_name
        id
        last_name
        mobile_number
      }
      references {
        id
        data
        reference_id
        reference_type
      }
      pipeline {
        id
        name
      }
      current_state {
        id
        name
      }
      target_resolution_date
    }
  }
`;
