import React from 'react';
import { Card, TrackerContextProvider } from '@shipmnts/pixel-hub';
import { ShipmentContainerValue } from 'operations/models/ShipmentContainer';
import { observer } from 'mobx-react-lite';
import { OceanTransportOrderValue } from 'operations/models/OceanTransportOrder';
import { BookingRequestValue } from 'operations/models/BookingRequest';
import { STATUS_PICKUP_PENDING } from 'operations/modules/reports/constants';
import ContainerDetails from 'operations/modules/shipment/components/DetailLayout/Containers/ContainerDetails';

interface ShipmentContainersCardProps {
  booking_request: BookingRequestValue;
  refetchData: () => void;
}

const ShipmentContainersCard = observer(function ShipmentContainersCard(
  props: ShipmentContainersCardProps
): JSX.Element {
  const { booking_request, refetchData } = props;

  const shipment_containers = booking_request.ocean_transport_orders
    .map((oto: OceanTransportOrderValue) => oto.parentBRShipmentContainers(booking_request.id))
    .flat()
    .filter((container: ShipmentContainerValue) => container.status !== STATUS_PICKUP_PENDING);

  return (
    <Card id="shipment_containers" title="Container Details">
      <TrackerContextProvider referenceType="Shipment::ShipmentContainer">
        <ContainerDetails
          containers={shipment_containers}
          parentType={'booking_request'}
          parentData={booking_request}
          parentId={booking_request.id}
          refetch={refetchData}
        />
      </TrackerContextProvider>
    </Card>
  );
});

export default ShipmentContainersCard;
