import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'wouter';
import { FallbackSkeleton } from '@shipmnts/pixel-hub';

// Lazy Loading View Components
const TicketDetailLayout = lazy(() => import('./components/TicketDetailLayout'));

// Lazy Loading Form Components
const TicketForm = lazy(() => import('./components/TicketForm'));

const ServiceManagementViewLayout = () => {
  return (
    <Switch>
      <Route path="/ticket/:ticket_id">
        <Suspense fallback={<FallbackSkeleton />}>
          <TicketDetailLayout />
        </Suspense>
      </Route>
    </Switch>
  );
};
const ServiceManagementFormLayout = () => {
  return (
    <Switch>
      <Route path="/ticket/:ticket_id">
        <Suspense fallback={<FallbackSkeleton />}>
          <TicketForm />
        </Suspense>
      </Route>
    </Switch>
  );
};

export { ServiceManagementViewLayout, ServiceManagementFormLayout };
