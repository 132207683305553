import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Progress, Typography, Col, Tooltip } from '@shipmnts/pixel-hub';
import _sumBy from 'lodash/sumBy';
import { ShipmentValue } from 'operations/models/Shipment';
import { ShipmentContainerValue } from 'operations/models/ShipmentContainer';
import { WarningOutlined } from '@shipmnts/pixel-hub';

const { Title, Text } = Typography;

const colorMapping: any = {
  '<50': '#FF8B71',
  '<75': '#FFC671',
  '<100': '#35986B',
  '100': '#F74E57',
};

const calcPercent = (val: number, maxVal: number): number | null => {
  if (maxVal <= 0) return null;
  return Math.min(Math.round((val * 100) / maxVal), 100);
};

interface OceanLclUtilisationProgressPropType {
  masterShipment: ShipmentValue;
  existingHouseShipments: any;
  selectedNodes: any[];
  isInline?: boolean;
}

const OceanLclUtilisationProgress = (props: OceanLclUtilisationProgressPropType) => {
  const { masterShipment, existingHouseShipments, selectedNodes, isInline } = props;
  const [consumedGrossWeight, setConsumedGrossWeight] = useState(0);
  const [consumedGrossVolume, setConsumedGrossVolume] = useState(0);

  const maximum_weight_volume = useMemo(() => {
    const containers = masterShipment.shipment_containers || [];
    const maximum_cargo_weight = _sumBy(
      containers,
      (c: ShipmentContainerValue): any => c.maximum_cargo_weight || 0
    );
    const maximum_cargo_volume = _sumBy(
      containers,
      (c: ShipmentContainerValue): any => c.maximum_cargo_volume || 0
    );
    return { maximum_cargo_weight, maximum_cargo_volume };
  }, [masterShipment]);

  useEffect(() => {
    const gross_weight =
      _sumBy(existingHouseShipments, (hs: any) => hs.gross_weight || 0) +
      _sumBy(selectedNodes, (node) => node.data.gross_weight_float || 0);
    const gross_volume =
      _sumBy(existingHouseShipments, (hs: any) => hs.gross_volume || 0) +
      _sumBy(selectedNodes, (node) => node.data.gross_volume_float || 0);

    setConsumedGrossWeight(gross_weight);
    setConsumedGrossVolume(gross_volume);
  }, [existingHouseShipments, selectedNodes]);

  const renderProgressWithDescription = useCallback(
    (val: any, maxVal: any, label: any, unit: any) => {
      const percent = calcPercent(val, maxVal);
      let color;
      if (percent && percent < 50) color = '<50';
      else if (percent && percent < 75) color = '<75';
      else if (percent && percent < 100) color = '<100';
      else color = '100';
      color = colorMapping[color];

      let tooltip: any = '';
      if (percent && percent >= 100) {
        const title =
          percent === 100 ? `Fully utilised` : `Exceeds by ${Math.round(val - maxVal)} ${unit}`;
        tooltip = (
          <Tooltip title={title}>
            <WarningOutlined style={{ color }} />
          </Tooltip>
        );
      }

      const title = (
        <Title
          style={{ fontWeight: 'bold', fontSize: isInline ? '14px' : '18px', width: 'max-content' }}
          level={4}
        >
          {label} ({unit})
        </Title>
      );

      const value =
        typeof val === 'number' && typeof maxVal === 'number' ? (
          <div style={{ display: 'flex', alignItems: 'center', columnGap: '8px' }}>
            <Text style={{ fontSize: isInline ? '14px' : '24px' }}>
              {val.toFixed(3)} / {maxVal.toFixed(3)}
            </Text>
            <span>{tooltip}</span>
          </div>
        ) : (
          <></>
        );

      if (isInline) {
        return (
          <div style={{ width: '150px' }}>
            {title}
            <Progress percent={percent || 0} status={'normal'} strokeColor={color} />
            {value}
          </div>
        );
      }
      return (
        <>
          <Col>
            <Progress
              type="circle"
              percent={percent || 0}
              format={(percent) => `${percent}%`}
              width={100}
              status={'normal'}
              strokeColor={color}
            />
          </Col>
          <Col>
            <div>
              {title}
              {value}
            </div>
          </Col>
        </>
      );
    },
    [isInline]
  );

  return (
    <div
      style={{
        display: 'flex',
        padding: '14px',
        ...(isInline
          ? { paddingRight: '22px', float: 'right', justifyContent: 'flex-end', columnGap: '24px' }
          : { justifyContent: 'flex-start', columnGap: '14px' }),
      }}
    >
      {renderProgressWithDescription(
        consumedGrossVolume,
        maximum_weight_volume.maximum_cargo_volume,
        'Volume',
        'cbm'
      )}
      {renderProgressWithDescription(
        consumedGrossWeight,
        maximum_weight_volume.maximum_cargo_weight,
        'Weight',
        'kgs'
      )}
      {isInline && (
        <div>
          <Title style={{ fontWeight: 'bold', fontSize: '14px', width: 'max-content' }} level={4}>
            Packages
          </Title>
          <Text style={{ fontSize: '20px' }}>
            {_sumBy(existingHouseShipments, (hs: any): any => hs.total_number_of_packages || 0)}
          </Text>
        </div>
      )}
    </div>
  );
};

OceanLclUtilisationProgress.defaultProps = {
  isInline: false,
};

export default OceanLclUtilisationProgress;
