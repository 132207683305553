import React, { Fragment, useState } from 'react';
import { Tag, Select, Tooltip } from '@shipmnts/pixel-hub';
import { LinkOutlined } from '@shipmnts/pixel-hub';
import { ShipmentValue } from 'operations/models/Shipment';
import { getProductRatingInfo } from './helper';
import { ShipmentProductRatingValue } from 'operations/models/ShipmentProductRating';
import { ENUM_BUY_SELL_STATUS } from 'operations/modules/shipment/constants';
import {
  ShipmentEstimateValue,
  useShipmentEstimateStore,
} from 'operations/models/ShipmentEstimate';

const { Option } = Select;

interface LinkTarrifType {
  data: any;
  shipment: ShipmentValue;
  updateLinkTariff: (id: string | null | undefined) => void;
}

export default function LinkTarrif(props: LinkTarrifType) {
  const { shipment, data, updateLinkTariff } = props;
  const { store } = useShipmentEstimateStore();
  const canDelinkProductRating = data.buy_status === ENUM_BUY_SELL_STATUS.UNBILLED;

  const [selectVisible, setSelectVisible] = useState<boolean>(false);
  const [productratingId, setProductratingId] = useState<string>(data?.product_rating?.id);

  const showSelect = () => {
    setSelectVisible(true);
  };

  const closeSelectTariff = () => {
    setSelectVisible(false);
  };

  const getLinkTariffOptions = () => {
    const linkTariffOptions: any[] = [];

    const linked_product_ratings = (store.estimates || [])
      .filter((shipment_estimate: ShipmentEstimateValue) => shipment_estimate?.product_rating?.id)
      .map((shipment_estimate: ShipmentEstimateValue) => shipment_estimate?.product_rating?.id);

    (shipment.shipment_product_ratings || []).forEach((product_rating) => {
      if (!linked_product_ratings.includes(product_rating?.id))
        linkTariffOptions.push({
          id: product_rating.id,
          name: getProductRatingInfo(product_rating),
        });
    });
    return linkTariffOptions;
  };

  const renderNewLinkTariff = () => {
    if (selectVisible) {
      return (
        <Select
          size="small"
          defaultOpen
          style={{ width: '70%' }}
          placeholder="Link Tariff"
          onChange={(value) => {
            closeSelectTariff();
            updateLinkTariff(value);
            setProductratingId(value);
          }}
          onBlur={closeSelectTariff}
        >
          {(getLinkTariffOptions() || []).map((option: any) => {
            return (
              <Option key={option.id} value={option.id}>
                {option.name}
              </Option>
            );
          })}
        </Select>
      );
    } else {
      return (
        <Tag
          key={0}
          color="blue"
          onClick={showSelect}
          style={{ background: '#fff', borderStyle: 'dashed' }}
        >
          <LinkOutlined />
        </Tag>
      );
    }
  };

  const initialProductRating = (shipment?.shipment_product_ratings || []).find(
    (pr: ShipmentProductRatingValue) => pr.id === productratingId
  );
  const linked_product_rating_name =
    (initialProductRating && getProductRatingInfo(initialProductRating)) || '';

  return (
    <Fragment>
      {productratingId ? (
        <Tooltip trigger={['hover']} title={linked_product_rating_name}>
          <Tag
            key={productratingId}
            closable={canDelinkProductRating}
            onClose={(e) => {
              updateLinkTariff(null);
              setProductratingId('');
            }}
          >
            <LinkOutlined style={{ color: 'green' }} />
          </Tag>
        </Tooltip>
      ) : (
        renderNewLinkTariff()
      )}
    </Fragment>
  );
}
