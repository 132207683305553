import React from 'react';
import { InquiryOptionValue } from 'operations/models/InquiryOption';
import LinkInquiryOptionToResource from './LinkInquiryOptionToResource';
import DeLinkInquiryOptionToResource from './DeLinkInquiryOptionToResource';
import { useSession } from 'common';
import { hasPermission } from '@shipmnts/pixel-hub';
import { PERMISSION_SALES_MODULE } from 'sales_hub';
interface InquiryOptionLinkTagProps {
  resourceId: string;
  resourceType: string;
  inquiryOption?: InquiryOptionValue;
  onSucess?: () => void;
  disabled?: boolean;
  searchProps: {
    customer_company_id?: string;
    involved_branch_id?: string;
    freight_type?: string;
    trade_type?: string;
  };
}

function InquiryOptionLinkTag(props: InquiryOptionLinkTagProps) {
  const { inquiryOption, resourceId, resourceType, onSucess, searchProps, disabled } = props;
  const sessionData = useSession();
  if (
    !hasPermission(sessionData?.permissions, {
      name: PERMISSION_SALES_MODULE,
      docType: 'SalesHub::Inquiry',
    })
  )
    return <></>;
  if (!inquiryOption)
    return (
      <LinkInquiryOptionToResource
        resourceType={resourceType}
        disabled={disabled}
        resourceId={resourceId}
        onSucess={onSucess}
        searchProps={searchProps}
      />
    );

  return (
    <DeLinkInquiryOptionToResource
      onSucess={onSucess}
      resourceId={resourceId}
      disabled={disabled}
      resourceType={resourceType}
      inquiryOption={inquiryOption}
    />
  );
}

export default InquiryOptionLinkTag;
