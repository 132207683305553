import React, { useContext, useEffect, useState, useMemo } from 'react';
import {
  fetchDocumentList,
  GenerateDocumentResponseType,
  GenerateDocumentType,
  GenerateDocumentReportType,
} from 'operations/modules/reports/components/ShipmentDocumentReports/document';
import { useShipmentDetail } from '../ShipmentDetailLayout';
import { ShipmentValue } from 'operations/models/Shipment';
import { message, Card, Skeleton } from '@shipmnts/pixel-hub';
import { errorMessageHandler } from 'common';
import { Column } from 'operations/models/Report';
import { BaseTable } from '@shipmnts/pixel-hub';
import AddDocumentDropdown from 'operations/modules/shipment/components/DetailLayout/Documents/AddDocumentDropdown';
import { useSession } from 'common';
import { useApolloClient } from '@apollo/client';
import { ALLOWED_MULTIPLE_DOCUMENTS } from 'operations/modules/reports/constants';
import { ActionRendererDetailReport } from 'operations';
export interface StringHash {
  [key: string]: string;
}

interface PartialShipmentType {
  id: string;
  house_shipments?: PartialShipmentType[];
}

const columnDefs: { [key: string]: Column } = {
  document_name: {
    headerName: 'Document',
    field: 'name',
    colId: 'name',
    minWidth: 200,
    pinned: 'left',
    columnType: 'Link',
    cellRendererParams: {
      id_field: 'id',
      doc_type_id: 'Shipment::ShipmentDocument',
      target: '_self',
    },
    lockPosition: true,
    filter: false,
    suppressMovable: true,
    lockVisible: true,
  },
  job_number: {
    headerName: 'Job Number',
    field: 'job_number',
    colId: 'job_number',
    minWidth: 130,
    columnType: 'String',
  },
  shipment_number: {
    headerName: 'BL/AWB/CN Number',
    field: 'shipment_number',
    colId: 'shipment_number',
    minWidth: 100,
    columnType: 'String',
  },
  created_by_name: {
    headerName: 'Created By',
    field: 'created_by_name',
    colId: 'created_by_name',
    minWidth: 100,
    columnType: 'String',
  },
  created_at: {
    headerName: 'Created At',
    field: 'created_at',
    colId: 'created_at',
    minWidth: 100,
    columnType: 'DateTime',
    filter: false,
  },
  updated_at: {
    headerName: 'Last Updated At',
    field: 'updated_at',
    colId: 'updated_at',
    minWidth: 100,
    columnType: 'DateTime',
    filter: false,
  },
  actions: {
    headerName: 'Actions',
    lockPosition: true,
    suppressMovable: true,
    suppressNavigable: true,
    colId: 'actions',
    field: 'actions',
    valueGetter: 'data',
    cellRenderer: 'ActionRendererDetailReport',
    cellRendererParams: {
      doc_type_id: 'Docgen::GeneratedDocuments',
    },
    width: 100,
    pinned: 'right',
    headerComponent: 'StaticHeaderComponent',
    lockVisible: true,
  },
};

export const flattenDoc = (rawDocuments: GenerateDocumentType[][], shipment: ShipmentValue) => {
  const allShipments = shipment.isRoadCustomerOrder()
    ? shipment?.trips || []
    : shipment?.house_shipments || [];
  const shipmentMap: StringHash = [shipment, ...allShipments].reduce((shipmentMap, value) => {
    shipmentMap[value.id] = value.job_number;
    return shipmentMap;
  }, {});
  const allDocuments = [
    ...(shipment.shipment_documents || []),
    ...allShipments.map((shp) => shp.shipment_documents).flat(),
  ];
  const documentMap: StringHash = allDocuments.reduce((documentMap, value) => {
    documentMap[value.id] = value.shipment_number;
    return documentMap;
  }, {});

  return rawDocuments.map((docs) => {
    const document: GenerateDocumentReportType = {
      ...(docs.find((doc) => !doc.ref_document_id) || docs[0]),
      name: {},
      docIds: docs.map((doc) => doc.id),
    };
    const name =
      docs.length > 1
        ? `${document.document_name} (${docs.length} copies)`
        : document.document_name;
    document.name = {
      value: name,
      extra_fields: {
        id: document.id,
        shipment_id: document.shipment_id,
        is_new_document: document?.is_new_document || false,
      },
    };
    document.job_number = shipmentMap[document.shipment_id] || '';
    if (document.shipment_document_id)
      document.shipment_number = documentMap[document.shipment_document_id] || '';
    return document;
  });
};

export const convertToArray = (
  response: GenerateDocumentResponseType,
  shipment: PartialShipmentType
) => {
  const handleGroupedDoc = (response: GenerateDocumentResponseType, shp: PartialShipmentType) => {
    if (response[shp.id]) {
      const groupedDocs = response[shp.id];
      const docArray: GenerateDocumentType[][] = [];

      Object.keys(groupedDocs).forEach((key) => {
        if (ALLOWED_MULTIPLE_DOCUMENTS.includes(key)) {
          docArray.push(...groupedDocs[key].map((doc) => [doc]));
        } else {
          docArray.push(groupedDocs[key]);
        }
      });

      // return Object.values(groupedDocs);
      return docArray;
    }
    return [];
  };

  const to_return: GenerateDocumentType[][] = [];
  to_return.push(...handleGroupedDoc(response, shipment));
  (shipment.house_shipments || []).forEach((s) => {
    to_return.push(...handleGroupedDoc(response, s));
  });
  return to_return;
};

interface CreatedDocumentsContextProps {
  handleDelete: (docIds: string[]) => void;
  shipment?: ShipmentValue;
}

const CreatedDocumentsContext = React.createContext<Partial<CreatedDocumentsContextProps>>({});
export const useCreatedDocuments = () => useContext(CreatedDocumentsContext);

const CreatedDocuments = () => {
  const { shipment } = useShipmentDetail();
  const [rawDocuments, setRawDocuments] = useState<GenerateDocumentType[][]>([]);
  const [documents, setDocuments] = useState<GenerateDocumentReportType[]>([]);
  const [loading, setLoading] = useState(true);
  const sessionData = useSession();
  const client = useApolloClient();

  const partialShipmentObject: PartialShipmentType | undefined = useMemo(() => {
    const allShipments = shipment?.isRoadCustomerOrder()
      ? shipment?.trips || []
      : shipment?.house_shipments || [];
    return shipment
      ? {
          id: shipment.id,
          house_shipments: allShipments.map((shp) => ({
            id: shp.id,
          })),
        }
      : undefined;
  }, [shipment]);

  useEffect(() => {
    const getDocumentList = async (shipment: PartialShipmentType) => {
      setLoading(true);
      const { response, error } = await fetchDocumentList([
        shipment.id,
        ...(shipment.house_shipments || []).map((hs) => hs.id),
      ]);
      if (error) {
        const resolvedError = errorMessageHandler(error as string);
        setLoading(false);
        message.error(resolvedError);
        return;
      }
      setRawDocuments(convertToArray(response, shipment));
      setLoading(false);
    };
    if (partialShipmentObject) {
      getDocumentList(partialShipmentObject);
    }
  }, [partialShipmentObject]);

  useEffect(() => {
    if (shipment && rawDocuments) setDocuments(flattenDoc(rawDocuments, shipment));
  }, [rawDocuments, shipment]);

  const handleDelete = (docIds: string[]) => {
    shipment?.handleDeleteGeneratedDocument(docIds);
    setRawDocuments(
      rawDocuments
        .map((row) => row.filter((doc) => !docIds.includes(doc.id)))
        .filter((row) => row.length > 0)
    );
  };

  if (!shipment) {
    return null;
  }

  if (loading) {
    return <Skeleton paragraph={{ rows: 4 }} loading={loading} active />;
  }

  return (
    <CreatedDocumentsContext.Provider
      value={{
        handleDelete,
        shipment,
      }}
    >
      <Card
        title="Created"
        extra={
          shipment?.isRoadCustomerOrder() ? (
            <></>
          ) : (
            <AddDocumentDropdown
              createdDocList={documents}
              parent_shipment={shipment}
              child_shipments={
                shipment?.isRoadCustomerOrder() ? shipment?.trips : shipment?.house_shipments || []
              }
              sessionData={sessionData}
              client={client}
            />
          )
        }
      >
        <BaseTable
          reportName={'documents'}
          rowData={documents || []}
          columns={Object.values(columnDefs)}
          reportConfig={{
            components: { ActionRendererDetailReport },
          }}
        />
      </Card>
    </CreatedDocumentsContext.Provider>
  );
};

export default CreatedDocuments;
