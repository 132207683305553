export const FIELD_VALID_TILL = 'valid_till';
export const FREIGHT_CONTRACT_TYPE_NET_NET = 'net_net';
export const FREIGHT_CONTRACT_TYPE_NET_NET_ULD = 'net_net_uld';
export const FREIGHT_CONTRACT_TYPE_DISCOUNT = 'discount';

export const FREIGHT_CONTRACT_STATUS_ACTIVE = 'Active';
export const FREIGHT_CONTRACT_STATUS_UPCOMING = 'Upcoming';
export const FREIGHT_CONTRACT_STATUS_EXPIRED = 'Expired';

export const FREIGHT_CONTRACT_DOC_STATUS_DRAFT = 'draft';
export const FREIGHT_CONTRACT_DOC_STATUS_SUBMITTED = 'submitted';
export const FREIGHT_CONTRACT_DOC_STATUS_CANCELLED = 'cancelled';
