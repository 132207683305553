import { gql } from '@apollo/client';
import { salesPersonFields } from 'operations/graphql/salesPerson';

export const orderCollaborationFields = gql`
  fragment orderCollaborationFields on OrderCollaborationObjectType {
    id
    sales_person {
      ...salesPersonFields
    }
    customer_company {
      id
      registered_name
      status
      logo
      status
    }
    customer_address {
      id
      name
      print_address
      company_id
    }
    managed_by_company {
      id
      registered_name
      logo
    }
    managed_by_address {
      id
      name
      print_address
      company_id
    }
    vendor_company {
      id
      registered_name
    }
    vendor_address {
      id
      name
      print_address
      company_id
    }
  }
  ${salesPersonFields}
`;
