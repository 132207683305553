export const DIRECT_AGENT = { key: 'airline', name: 'Direct Airline' };
export const SALES_AGENT = { key: 'general_sales_agent', name: 'General Sales Agent' };
export const IATA_AGENT = { key: 'freight_forwarder', name: 'IATA Agent' };

export const AGENTS = [DIRECT_AGENT, SALES_AGENT, IATA_AGENT];

export const STOCK_ORDER_ITEM_STATUS_TYPE_UNUSED = 'unused';
export const STOCK_ORDER_ITEM_STATUS_TYPE_BOOKED = 'booked';
export const STOCK_ORDER_ITEM_STATUS_TYPE_EXECUTED = 'executed';
export const STOCK_ORDER_ITEM_STATUS_TYPE_VOID = 'void';
export const STOCK_ORDER_ITEM_STATUS_TYPE_RESERVED = 'reserved';

export const PERMISSION_STOCK_MANAGER = 'Stock Manager';
export const UPDATE_ACTION = { key: 'update', icon: 'edit' };
export const DELETE_ACTION = { key: 'delete', icon: 'delete' };
export const UNRESERVE_ACTION = { key: 'unreserve', icon: 'undo' };
export const SPLIT_ACTION = { key: 'split', icon: 'disconnect' };
export const RESERVE_ACTION = { key: 'reserve', icon: 'bookmark' };
