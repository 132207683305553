import { Col, FormInstance, Input, InputNumber, Row, Select, Form } from '@shipmnts/pixel-hub';
import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_SHIPMENT_CONTAINERS } from 'operations/modules/booking/graphql/shipmentContainer';
import { containerTypes } from 'operations/baseConstants';
import Spinner from 'src/components/Spinner';

const renderHeaderForContainerDetails = () => {
  return (
    <Row gutter={15} style={{ minHeight: '20px', marginBottom: '15px' }}>
      <Col span={5} className="table-color">
        <span>Container #</span>
      </Col>
      <Col span={5} className="table-color">
        <span>Container Type</span>
      </Col>
      <Col span={5} className="table-color">
        <span>Gross Weight</span>
      </Col>
      <Col span={5} className="table-color">
        <span>Net Weight</span>
      </Col>
    </Row>
  );
};

interface ShipmentContainerTableProps {
  shipment_id: string;
  master_shipment_id?: string;
  form: FormInstance;
  noContainerMessage?: string;
}
export default function ShipmentContainerTable(props: ShipmentContainerTableProps) {
  const { shipment_id, form, master_shipment_id, noContainerMessage } = props;

  const fetch_shipment_id = master_shipment_id ? master_shipment_id : shipment_id;
  const { data, loading, error, refetch } = useQuery(FETCH_SHIPMENT_CONTAINERS, {
    variables: {
      shipment_ids: [fetch_shipment_id],
    },
  });

  const refetchData = () => {
    refetch();
  };

  useEffect(() => {
    if (data?.fetch_shipment_containers) {
      const iniVal = data?.fetch_shipment_containers.map((c: any) => ({
        shipment_container_id: c.id,
        container_number: c.container_number,
        container_type: c.container_type,
        gross_weight: c.gross_weight,
        net_weight: c.net_weight,
      }));
      const master = form.getFieldValue('master');
      if (master) {
        master.containers_details = iniVal;
      }
      form.setFieldsValue({ containers_details: iniVal });
    }
  }, [data, form]);

  if (!data?.fetch_shipment_containers) {
    return null;
  }
  return (
    <div>
      <div className="flex flex-space-between">
        <h3>Containers</h3>
      </div>
      {error ? (
        <div className="align-center">
          Unable to fetch containers.&nbsp;
          <div style={{ color: 'blue' }} onClick={() => refetchData()}>
            Retry
          </div>
        </div>
      ) : (
        <>
          <Spinner tip="Fetching container details" spinning={loading} />
          {!loading &&
          (!data?.fetch_shipment_containers || data?.fetch_shipment_containers.length === 0) ? (
            <div style={{ color: 'red' }}>{noContainerMessage}</div>
          ) : (
            renderHeaderForContainerDetails()
          )}

          <span>
            <Form.List name={['containers_details']}>
              {(formFields) => (
                <div style={{ marginBottom: '10px' }}>
                  {formFields.map((formField) => {
                    return (
                      <Row key={formField.name} style={{ marginBottom: '15px' }} gutter={15}>
                        <Form.Item name={[formField.name, 'container_id']}></Form.Item>
                        <Col span={5}>
                          <Form.Item required name={[formField.name, 'container_number']}>
                            <Input disabled />
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item required name={[formField.name, 'container_type']}>
                            <Select disabled>
                              {Object.keys(containerTypes).map((group) => (
                                <Select.Option key={group} value={group}>
                                  {containerTypes[group]}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Row gutter={5}>
                            <Col span={12}>
                              <Form.Item required name={[formField.name, 'gross_weight']}>
                                <InputNumber />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={5}>
                          <Row gutter={5}>
                            <Col span={12}>
                              <Form.Item required name={[formField.name, 'net_weight']}>
                                <InputNumber />
                              </Form.Item>
                            </Col>
                            <Col span={5}></Col>
                          </Row>
                        </Col>
                      </Row>
                    );
                  })}
                </div>
              )}
            </Form.List>
          </span>
        </>
      )}
    </div>
  );
}
