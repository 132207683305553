import { gql } from '@apollo/client';

export const DELETE_VIEW_SUBSCRIPTION = gql`
  mutation delete_view_subscription($id: ID!) {
    delete_view_subscription(id: $id) {
      message
    }
  }
`;

export const GET_VIEW_SUBSCRIPTION = gql`
  query view_subscription($id: ID!) {
    view_subscription(id: $id) {
      id
      name
      frequency
      email_definition
      status
      recipients {
        id
        first_name
        last_name
        email
      }
      created_by {
        id
        username
        first_name
        last_name
      }
    }
  }
`;

export const UPDATE_VIEW_SUBSCRIPTION = gql`
  mutation update_view_subscription(
    $id: ID!
    $view_subscription: UpdateViewSubscriptionInputType!
  ) {
    update_view_subscription(id: $id, view_subscription: $view_subscription) {
      id
    }
  }
`;
