import React from 'react';
import { Timeline, CustomIcon } from '@shipmnts/pixel-hub';
import FlightCard from './FlightCard';

const FsuRoutingDetailsSplitTimeline = React.memo(function FsuTrackingSummary(props: {
  data: any;
  location: string;
  getFlightDetails: any;
}) {
  const { data, location, getFlightDetails } = props;
  if (!data) {
    return <></>;
  }
  const location_data = data?.eawb_get_fsu_data?.location_data;
  const locationNode = location_data.find((e: any) => e.location === location);

  return (
    <div style={{ paddingTop: '10px' }}>
      <Timeline mode="left">
        <Timeline.Item dot={<></>}>
          {locationNode.arrival_flights.map((flightNumber: string, index: number) => {
            const flightDetail = getFlightDetails(flightNumber);
            if (flightDetail) {
              return <FlightCard flightDetail={flightDetail} key={index} />;
            } else return <></>;
          })}
        </Timeline.Item>
        <Timeline.Item dot={<CustomIcon icon="BlueSquareIcon" />}>
          <div style={{ fontSize: '12px', color: 'gray' }}>Transhipment Port</div>
          <div style={{ fontSize: '13px' }}>
            {`${locationNode.location_display} - ${locationNode.location}`}
          </div>
          {locationNode.departure_flights && locationNode.departure_flights.length > 0 && (
            <div>
              {locationNode.departure_flights.map((flightNumber: string, index: number) => {
                const flightDetail = getFlightDetails(flightNumber);
                if (flightDetail) {
                  return <FlightCard flightDetail={flightDetail} key={index} />;
                } else return <></>;
              })}
            </div>
          )}
        </Timeline.Item>
        <Timeline.Item
          dot={<></>}
          style={{ width: '0', height: '0', margin: '0', padding: '0' }}
        ></Timeline.Item>
      </Timeline>
    </div>
  );
});

export default FsuRoutingDetailsSplitTimeline;
