import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Button, message } from '@shipmnts/pixel-hub';

import { UPSERT_SALES_PERRSON } from '../graphql/salesPerson';

const DisableSalesPerson = React.memo(function DisableSalesPerson(props: {
  sales_person_id: string | undefined;
  flag: boolean;
  disabled: boolean;
  children: React.ReactNode;
  onSuccess: any;
}): JSX.Element {
  const { sales_person_id, flag, children, onSuccess, disabled } = props;

  const [disableSalesPerson, { data, error, loading }] = useMutation(UPSERT_SALES_PERRSON);

  useEffect(() => {
    if (!error && data) {
      message.success('Sales Person updated successfully!');
      if (onSuccess) onSuccess(data?.upsert_sales_person);
    }
    if (error) {
      message.error({
        content: error?.graphQLErrors.map((e) => e.message),
        key: 'disable_sales_person',
      });
    }
  }, [data, error, onSuccess]);

  return (
    <Button
      onClick={() => {
        if (sales_person_id)
          disableSalesPerson({
            variables: { sales_person: { id: sales_person_id, enable: flag } },
          });
      }}
      danger={!flag}
      disabled={disabled}
      loading={loading}
    >
      {children}
    </Button>
  );
});

export default DisableSalesPerson;
