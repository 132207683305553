import React from 'react';
import { BookingRequestValue } from 'operations/models/BookingRequest';
import {
  actionTypeForContainer,
  PropsForContainerAction,
  CreateShipmentType,
} from 'operations/modules/reports/components/ContainerReports/ContainerActions/ContainerActionDrawer';
import { ShipmentType, TradeType, TRADE_TYPE_EXPORT } from 'operations/modules/shipment/constants';
import { Drawer } from '@shipmnts/pixel-hub';
import CreateShipmentFromBookingRequest from 'operations/modules/reports/components/BookingRequestReports/CreateShipmentFromBookingRequest';
import { ContainerReportData } from 'operations/modules/reports/reportModels';
import CreateShipmentFromContainersForm from 'operations/modules/reports/components/ContainerReports/ContainerActions/CreateShipmentFromContainersForm';
import { startCase as _startCase } from 'lodash';
import {
  ROUTING_TYPE_MAIN_CARRIAGE,
  ROUTING_TYPE_ON_CARRIAGE,
  ROUTING_TYPE_PRE_CARRIAGE,
} from 'operations/models/RoutingLeg';

export const CreateShipmentForLCL = React.memo(function CreateShipmentForLCL(props: {
  bookingRequest: BookingRequestValue;
  activeAction: actionTypeForContainer;
  shipmentType: ShipmentType;
  onClose: () => void;
  onSuccess: () => void;
}): JSX.Element {
  const { bookingRequest, activeAction, shipmentType, onClose } = props;

  const tradeType = TRADE_TYPE_EXPORT;
  const main_carriage_routing_legs =
    bookingRequest.carriageWiseRoutingLegs[ROUTING_TYPE_MAIN_CARRIAGE];
  const pre_carriage_routing_legs =
    bookingRequest.carriageWiseRoutingLegs[ROUTING_TYPE_PRE_CARRIAGE] || [];
  const on_carriage_routing_legs =
    bookingRequest.carriageWiseRoutingLegs[ROUTING_TYPE_ON_CARRIAGE] || [];

  if (activeAction === 'create_shipment') {
    return (
      <Drawer
        title={`Create ${_startCase(shipmentType)} Shipment`}
        open={true}
        width="60%"
        onClose={onClose}
      >
        <CreateShipmentFromBookingRequest
          onClose={onClose}
          bookingRequest={{
            ...bookingRequest,
            consignee_company: bookingRequest.consignee?.party_company,
            consignee_address: bookingRequest.consignee?.party_address,
            port_of_loading: main_carriage_routing_legs?.[0]?.origin?.location,
            port_of_discharge:
              main_carriage_routing_legs?.[main_carriage_routing_legs?.length - 1]?.destination
                ?.location,
            place_of_carrier_receipt: pre_carriage_routing_legs[0]?.origin?.location,
            place_of_carrier_delivery:
              on_carriage_routing_legs[on_carriage_routing_legs?.length - 1]?.destination?.location,
            trade_type: tradeType,
          }}
          shipmentType={shipmentType as CreateShipmentType}
          customer={{
            party_company: bookingRequest.customerCompany || undefined,
            party_address: bookingRequest.customerAddress || undefined,
          }}
          shipper={
            bookingRequest?.shipper?.party_company && {
              party_company: bookingRequest?.shipper?.party_company,
              party_address: bookingRequest?.shipper?.party_address || undefined,
            }
          }
          branch_account_id={bookingRequest?.bookedByBranch?.id}
          carrier={bookingRequest?.ocean_transport_orders?.[0]?.global_carrier}
          onSuccess={onClose}
          tradeType={tradeType}
        />
      </Drawer>
    );
  } else return <></>;
});

export const CreateShipmentForFCL = React.memo(function CreateShipmentForFCL(props: {
  containers: ContainerReportData[];
  activeAction: actionTypeForContainer;
  propsForActiveAction?: PropsForContainerAction;
  onSuccess: () => void;
  onClose: () => void;
  tradeType: TradeType;
}): JSX.Element {
  const { containers, activeAction, propsForActiveAction, onSuccess, onClose, tradeType } = props;
  if (activeAction === 'create_shipment') {
    if (
      !propsForActiveAction?.shipmentType ||
      !propsForActiveAction?.customer ||
      !propsForActiveAction?.branch_account_id
    )
      return <></>;
    return (
      <CreateShipmentFromContainersForm
        shipment_container_ids={containers.map((c) => c.id)}
        shipmentType={propsForActiveAction.shipmentType}
        customer={propsForActiveAction.customer}
        shipper={propsForActiveAction?.shipper}
        branch_account_id={propsForActiveAction?.branch_account_id}
        carrier={propsForActiveAction?.carrier}
        bookingRequests={propsForActiveAction?.bookingRequests}
        onClose={onClose}
        onSuccess={onSuccess}
        oceanTransportOrderId={propsForActiveAction?.oceanTransportOrder?.id}
        tradeType={tradeType}
      />
    );
  } else return <></>;
});
