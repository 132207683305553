// import { erpnextApiHelper } from 'network';
import { ApolloClient } from '@apollo/client';
import { SalesPersonValue } from 'common/models/SalesPerson';
import { GET_SALES_PERSON_FOR_CUSTOMER } from 'network/graphql/contact';
import { SessionDataValue } from 'operations/models/SessionData';

export const getSalesPersonForCustomer = async (
  customer_company_id: string,
  branch_id: string,
  _sessionData: SessionDataValue,
  client?: ApolloClient<object>
): Promise<{ response?: SalesPersonValue; error?: Error }> => {
  try {
    if (!customer_company_id || !branch_id) throw new Error('invalid params');
    // const branch_accounts = sessionData?.branch_accounts ?? [];
    // const url = `/api/method/shipmnts.custom_script.custom_customer.custom_customer.get_sales_person_for_customer`;
    // const cost_center = branch_accounts.find((ba) => ba.id === branch_id)?.erp_cost_center_id;
    // const response = await erpnextApiHelper.erpNextAxiosCall({
    //   action: 'get',
    //   url,
    //   params: { operations_company_id: customer_company_id, cost_center },
    // });

    if (client) {
      const { data, error } = await client.query({
        query: GET_SALES_PERSON_FOR_CUSTOMER,
        variables: { customer_company_id, branch_id },
      });

      if (data) {
        return { response: data?.get_sales_person_for_customer as SalesPersonValue };
      }
      if (error) {
        throw error;
      }
    }

    return {};
  } catch (error) {
    console.error(error);
    return { error: error as Error };
  }
};
