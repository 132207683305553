import React, { useEffect, useRef } from 'react';
import { Drawer, message, DrawerFooter } from '@shipmnts/pixel-hub';
import CustomsOrContainerListTable from './CustomsOrContainerListTable';
import { GridOptions } from '@ag-grid-community/core';
import { Column } from 'operations/models/Report';
import { UPSERT_COMMERCIAL_INVOICE } from '../graphql/productOrder';
import { useMutation } from '@apollo/client';
import { CommercialInvoiceValue } from 'operations/models/CommercialInvoice';

interface LinkCustomsDrawerProps {
  commercialInvoice: CommercialInvoiceValue;
  onSuccess?: () => void;
  onClose: () => void;
}

export default function LinkCustomDrawer(props: LinkCustomsDrawerProps): JSX.Element {
  const { commercialInvoice, onSuccess, onClose } = props;

  const gridRef = useRef<GridOptions>();
  const [linkCustomsToInvoice, { data, error }] = useMutation(UPSERT_COMMERCIAL_INVOICE);

  useEffect(() => {
    if (data && data?.upsert_commercial_invoice?.id) {
      message.success(`Successfully Updated Commercial Invoice !`);
      if (onSuccess) onSuccess();
    }
    if (error) {
      message.error(error.message);
    }
  }, [data, error, onSuccess]);

  const filteredArray = commercialInvoice?.shipment_custom_detail
    ? [commercialInvoice?.shipment_custom_detail].filter((scd) =>
        commercialInvoice?.shipment?.shipment_custom_details?.some(
          (lscd: any) => scd.id === lscd.id
        )
      )
    : [];

  const onSaveDrawer = () => {
    const shipmentCustomsDetailsId = gridRef?.current?.api?.getSelectedNodes()[0]?.data?.id;
    if (filteredArray[0]?.id !== shipmentCustomsDetailsId) {
      linkCustomsToInvoice({
        variables: {
          commercial_invoice: {
            id: commercialInvoice.id,
            shipment_custom_detail_id: parseInt(shipmentCustomsDetailsId) || 0,
          },
        },
      });
    } else onClose();
  };

  const columns: Column[] = [
    {
      headerName: 'Document #',
      field: 'custom_document_number',
      colId: 'custom_document_number',
      filter: 'agTextColumnFilter',
      cellRenderer: 'render_tracker_drawer',
      pinned: 'left',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      width: 160,
    },
    {
      headerName: 'Custom Document Date',
      field: 'custom_document_date',
      colId: 'custom_document_date',
      columnType: 'Date',
      width: 200,
      filter: false,
    },
    {
      headerName: 'Customs Clearance Location',
      field: 'custom_clearance_location.name',
      colId: 'custom_clearance_location.name',
      width: 200,
      filter: 'agSetColumnFilter',
    },
    {
      headerName: 'Shipping Bill Type',
      field: 'shipping_bill_type',
      colId: 'shipping_bill_type',
      width: 200,
      filter: 'agSetColumnFilter',
    },
    {
      headerName: 'Invoice Value',
      field: 'invoice_value',
      colId: 'invoice_value',
      width: 200,
      filter: 'agSetColumnFilter',
    },
    {
      headerName: 'FOB Value',
      field: 'fob_value',
      colId: 'fob_value',
      width: 200,
      filter: 'agSetColumnFilter',
    },
    {
      headerName: 'CIF Value',
      field: 'cif_value',
      colId: 'cif_value',
      width: 200,
      filter: 'agSetColumnFilter',
    },
  ];

  return (
    <>
      <Drawer
        open={true}
        onClose={onClose}
        title={`Link Customs on Invoice ${commercialInvoice.invoice_number}`}
        width={1000}
        footer={<DrawerFooter saveText="Update Customs" onSave={onSaveDrawer} onClose={onClose} />}
      >
        <CustomsOrContainerListTable
          data={commercialInvoice?.shipment?.shipment_custom_details}
          isRowSelectable="single"
          gridRef={gridRef}
          alreadyLinked={filteredArray}
          columns={columns}
        />
      </Drawer>
    </>
  );
}
