import './ticket.css';
import React from 'react';
import { EditableCell, Table, TableColumnType } from '@shipmnts/pixel-hub';
import { TicketDataSourceItem } from './types';

interface AssociationTableProps {
  dataSource: TicketDataSourceItem[];
  columns: TableColumnType<TicketDataSourceItem>[];
}
const AssociationTable = (props: AssociationTableProps) => {
  const { dataSource, columns } = props;
  return (
    <Table
      dataSource={dataSource}
      className="record-fields"
      bordered
      columns={columns}
      components={{ body: { cell: EditableCell } }}
      pagination={false}
    ></Table>
  );
};

export default AssociationTable;
