import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { Skeleton, Table, Button, Typography } from '@shipmnts/pixel-hub';
import { ApolloClient } from '@apollo/client';
import {
  startCase as _startCase,
  concat as _concat,
  flatten as _flatten,
  sortBy as _sortBy,
} from 'lodash';
import { getGeneratedDocumentsPdf } from './documentPayloadHelper';
import { CreatedDocumentWithPdf } from './SendDocumentForm';
import { ShipmentValue } from 'operations/models/Shipment';
import { SessionDataValue } from 'operations/models/SessionData';
import { ShipmentDocumentValue } from 'operations/models/ShipmentDocument';
import { DOCUMENT_TYPE_HBL, DOCUMENT_TYPE_MBL } from 'operations/constants';
import { DOCUMENT_STATUS_DISPLAY_NAME_MAPPING } from 'operations/modules/reports/constants';
import { fetchGenerateDocuments } from 'operations/modules/reports/components/ShipmentDocumentReports/document';
import { useApplicationContentContext } from 'common';
import { CreatedDocument } from 'common/commonTypeDefs';
import { FREIGHT_TYPE_ROAD, SHIPMENT_TYPE_CONSOL } from '../../constants';
const { Link } = Typography;

interface GeneratedDocumentsTableProps {
  fetchingGeneratedDocuments: boolean;
  setFetchingGeneratedDocuments: (value: boolean) => void;
  documents?: CreatedDocumentWithPdf[];
  shipment: ShipmentValue;
  selectedGeneratedDocuments: React.Key[];
  setSelectedGeneratedDocuments: (value: React.Key[]) => void;
  setGeneratedDocuments: (value: CreatedDocumentWithPdf[]) => void;
  sessionData: SessionDataValue;
  client: ApolloClient<object>;
}

const GeneratedDocumentsTable = React.memo(function GeneratedDocumentsTable(
  props: GeneratedDocumentsTableProps
): JSX.Element {
  const {
    fetchingGeneratedDocuments,
    setFetchingGeneratedDocuments,
    documents,
    shipment,
    setGeneratedDocuments,
    selectedGeneratedDocuments,
    setSelectedGeneratedDocuments,
    sessionData,
    client,
  } = props;

  const [fetchGeneratedDocumentsError, setFetchGeneratedDocumentsError] = useState<
    boolean | string
  >(false);
  const { config_data } = useApplicationContentContext();

  const getGeneratedDocuments = useCallback(async () => {
    if (shipment) {
      if (!fetchingGeneratedDocuments) setFetchingGeneratedDocuments(true);
      const allShipments =
        shipment?.shipment_type === SHIPMENT_TYPE_CONSOL &&
        shipment.freight_type === FREIGHT_TYPE_ROAD
          ? shipment?.trips || []
          : shipment?.house_shipments || [];
      const shipment_ids = [shipment, ...allShipments].map((sh) => sh.id);
      const { response: generated_documents, error: generated_documents_error } =
        await fetchGenerateDocuments(shipment_ids);
      if (generated_documents_error) {
        let error_message: boolean | string = true;
        if (generated_documents_error instanceof Error)
          error_message = generated_documents_error.message;
        setFetchGeneratedDocumentsError(error_message);
      } else {
        const created_documents: {
          [key: string]: { [key: string]: CreatedDocument[] };
        } = generated_documents?.data?.documents;
        const generatedDocuments = Object.values(created_documents || {}).reduce(
          (docs: CreatedDocument[], obj: { [key: string]: CreatedDocument[] }) => {
            const new_docs = _flatten(_concat(Object.values(obj), docs));
            return new_docs;
          },
          []
        );
        const { documents: generatedDocumentWithPdf, error } = await getGeneratedDocumentsPdf({
          generatedDocuments,
          client,
          sessionData,
          config_data,
        });
        if (error) {
          setFetchGeneratedDocumentsError(error);
        } else setGeneratedDocuments(_sortBy(generatedDocumentWithPdf, 'shipment_id'));
      }
      setFetchingGeneratedDocuments(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipment, fetchingGeneratedDocuments]);

  useEffect(() => {
    const fetchDocuments = async () => await getGeneratedDocuments();
    if (shipment) fetchDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipment]);

  const allShipments: ShipmentValue[] = [shipment, ...(shipment?.house_shipments || [])];

  const getShipmentDocument = (shipment_document_id: string): ShipmentDocumentValue | undefined => {
    let shipment_document;
    allShipments.forEach((sh) => {
      (sh.shipment_documents || []).forEach((sd: any) => {
        if (sd.id === shipment_document_id) {
          shipment_document = sd;
        }
      });
    });

    return shipment_document;
  };

  const columns = useMemo(() => {
    return [
      {
        title: 'Name',
        dataIndex: 'document_name',
        render: (document_name: string, record: CreatedDocumentWithPdf, index: number) => (
          <Link
            href={record.attachment.uploaded_document}
            target="_blank"
            style={{ color: '#3b72d5' }}
          >
            <span key={`document_name_${index}`}>{document_name}</span>
          </Link>
        ),
      },
      {
        title: 'Reference No#',
        dataIndex: 'shipment_number',
        render: (ttaxt: string, record: CreatedDocumentWithPdf, index: number) => {
          if (!record.shipment_document_id) return '';
          const shipment_document = getShipmentDocument(record.shipment_document_id);
          return (
            <span key={`shipment_number_${index}`}>{shipment_document?.shipment_number || ''}</span>
          );
        },
      },
      {
        title: 'Type',
        dataIndex: 'bl_type',
        render: (ttaxt: string, record: CreatedDocumentWithPdf, index: number) => {
          if (!record.shipment_document_id) return '';
          const shipment_document = getShipmentDocument(record.shipment_document_id);
          return <span key={`bl_type_${index}`}>{shipment_document?.bl_type || ''}</span>;
        },
      },
      {
        title: 'Status',
        dataIndex: 'document_status',
        render: (ttaxt: string, record: CreatedDocumentWithPdf, index: number) => {
          if (!record.shipment_document_id) return '';
          const shipment_document = getShipmentDocument(record.shipment_document_id);
          if (!shipment_document?.document_status) return '';
          const document_status =
            DOCUMENT_STATUS_DISPLAY_NAME_MAPPING[shipment_document?.document_status] ||
            _startCase(shipment_document?.document_status || '');

          return <span key={`document_status_${index}`}>{document_status}</span>;
        },
      },
      ...(shipment?.shipment_type === 'consol'
        ? [
            {
              title: 'Job #',
              dataIndex: 'job_number',
              render: (ttaxt: string, record: CreatedDocumentWithPdf, index: number) => {
                const shipment = allShipments.find((sh) => sh.id === record?.shipment_id);
                return <span key={`job_number_${index}`}>{shipment?.job_number || ''}</span>;
              },
            },
          ]
        : []),
      {
        title: 'FPOD',
        dataIndex: 'final_place_of_delivery',
        render: (ttaxt: string, record: CreatedDocumentWithPdf, index: number) => {
          const shipment = allShipments.find((sh) => sh.id === record?.shipment_id);
          const fpod = shipment?.final_place_of_delivery;

          if (!fpod || ![DOCUMENT_TYPE_HBL, DOCUMENT_TYPE_MBL].includes(record.document_type))
            return <span>-</span>;
          return <span key={`final_place_of_delivery_${index}`}>{`${fpod?.name || ''}`}</span>;
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (fetchingGeneratedDocuments) return <Skeleton active />;

  return (
    <>
      {fetchGeneratedDocumentsError ? (
        <div>
          Something went wrong. {fetchGeneratedDocumentsError}
          <span style={{ marginLeft: '5px' }}>
            <Button onClick={getGeneratedDocuments}>Retry</Button>
          </span>
        </div>
      ) : (
        <Table
          rowSelection={{
            selectedRowKeys: selectedGeneratedDocuments,
            onChange: (selectedRowKeys: React.Key[]) =>
              setSelectedGeneratedDocuments(selectedRowKeys),
          }}
          columns={columns}
          dataSource={documents}
          pagination={false}
          size="small"
          bordered
          title={() => 'Select Created Documents'}
          rowKey={(record) => record.id || ''}
        />
      )}
    </>
  );
});

export default GeneratedDocumentsTable;
