import React from 'react';
import { Form } from '@shipmnts/pixel-hub';

import { useCompanyView } from '../CompanyView';
import { SectionCard } from 'common/components/Card/SectionCard';

export const ProspectingDetailsCard = () => {
  const { company, refetch } = useCompanyView();
  const [form] = Form.useForm();

  return (
    <SectionCard
      doc_type_id={'Network::Company'}
      section={'Prospecting'}
      record={company}
      form={form}
      title={'Prospecting Details'}
      refetch={refetch}
    />
  );
};
