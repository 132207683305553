import {
  Checkbox,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Typography,
  message,
  DrawerFooter,
  InputNumber,
  Radio,
} from '@shipmnts/pixel-hub';
import {
  TEMPERATURE_UNIT_CELSIUS,
  TEMPERATURE_UNIT_FAHRENHEIT,
  WEIGHT_UNIT_KGS,
  getUNDGNumberOptions,
  packingGroup,
} from 'network/baseConstants';
import { omit as _omit } from 'lodash';
import React from 'react';
import { batteryTypes, coolingTypes, tempRange } from '../reports/constants';
import { RadioOptionProps } from 'common/commonTypeDefs';

const { Option } = Select;

const renderInputWithUnit = (
  key: string | Array<string>,
  unit_key: string | Array<string>,
  label: string,
  options: Array<{ key: string; name: string }>,
  disabledUnitKey?: boolean,
  inputNumberProps?: object,
  defaultSelect?: string
) => {
  return (
    <Input.Group compact style={{ width: '100%' }}>
      <Form.Item name={key} label={label} style={{ width: '70%' }}>
        <InputNumber style={{ width: '100%' }} placeholder={label} {...inputNumberProps} />
      </Form.Item>
      <Form.Item name={unit_key} label="Unit" style={{ width: '30%' }}>
        <Select disabled={disabledUnitKey} defaultValue={defaultSelect}>
          {options.map((unit) => (
            <Option key={unit.key} value={unit.key}>
              {unit.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Input.Group>
  );
};

export default function ProductPropertyDrawer(props: any) {
  const {
    setShowProductPropertyDrawer,
    showProductPropertyDrawer,
    params,
    editable = true,
    mainForm,
  } = props;
  const closeDrawer = () => {
    setShowProductPropertyDrawer(false);
  };
  const [form] = Form.useForm();
  const options = [
    { label: 'Temp Controlled', value: 'Temp Controlled' },
    { label: 'Perishable', value: 'Perishable' },
    { label: 'Hazardous', value: 'Hazardous' },
    { label: 'Stackable', value: 'Stackable' },
    { label: 'Tiltable', value: 'Tiltable' },
    { label: 'Palletisable', value: 'Palletisable' },
    { label: 'Battery Inside', value: 'Battery Inside' },
  ];
  const temperatureOptions = [
    { key: TEMPERATURE_UNIT_CELSIUS, name: TEMPERATURE_UNIT_CELSIUS },
    { key: TEMPERATURE_UNIT_FAHRENHEIT, name: TEMPERATURE_UNIT_FAHRENHEIT },
  ];
  const initialProductProperty = (value: any) => {
    const property = [];
    if (value?.is_temp_controlled) property.push('Temp Controlled');
    if (value?.is_perishable) property.push('Perishable');
    if (value?.is_hazardous) property.push('Hazardous');
    if (value?.is_stackable) property.push('Stackable');
    if (value?.is_tiltable) property.push('Tiltable');
    if (value?.is_palletisable) property.push('Palletisable');
    if (value?.is_battery) property.push('Battery Inside');
    return property;
  };

  const getRequiredLabel = (label: string | undefined) => {
    return (
      <>
        <span style={{ color: '#D4380D' }}>{label}</span>
      </>
    );
  };

  const getInitialValue = () => {
    let initialvalue = {
      net_weight_unit: WEIGHT_UNIT_KGS,
      flash_point_unit: TEMPERATURE_UNIT_CELSIUS,
      temperature_unit: TEMPERATURE_UNIT_CELSIUS,
      ...params?.value,
      product_property: initialProductProperty(params?.value),
    };
    if (!params) {
      initialvalue = {
        ...initialvalue,
        ...mainForm?.getFieldValue('all_product_properties'),
        product_property: initialProductProperty(mainForm?.getFieldValue('all_product_properties')),
      };
    }
    return initialvalue;
  };
  return (
    <Drawer
      open={showProductPropertyDrawer}
      onClose={closeDrawer}
      title={`Product Property`}
      width="60%"
      footer={
        editable && (
          <DrawerFooter
            saveText={!params ? 'Update For All Products' : 'Update Product Property'}
            onSave={() => {
              form.submit();
            }}
            onClose={closeDrawer}
          />
        )
      }
    >
      <Form
        disabled={!editable}
        form={form}
        layout={'vertical'}
        initialValues={getInitialValue()}
        onFinish={(values: any) => {
          const payload = {
            ..._omit(values, ['product_property']),
            is_temp_controlled: values?.product_property?.includes('Temp Controlled'),
            is_perishable: values?.product_property?.includes('Perishable'),
            is_hazardous: values?.product_property?.includes('Hazardous'),
            is_stackable: values?.product_property?.includes('Stackable'),
            is_tiltable: values?.product_property?.includes('Tiltable'),
            is_palletisable: values?.product_property?.includes('Palletisable'),
            is_battery: values?.product_property?.includes('Battery Inside'),
          };
          params?.setValue(payload);
          const products = mainForm?.getFieldValue('warehouse_transaction_products');
          !params &&
            mainForm?.setFieldsValue({
              warehouse_transaction_products: products.map((prd: any) => {
                return { ...prd, product_property: payload };
              }),
              all_product_properties: payload,
            });
          message.success('Product Property Updated Successfully');
          closeDrawer();
        }}
        style={{ paddingBottom: '20px' }}
      >
        <Form.Item name="product_property" label="Product Property">
          <Checkbox.Group options={options} />
        </Form.Item>
        <Form.Item dependencies={['product_property']} noStyle>
          {({ getFieldValue }) => {
            const checkbox = getFieldValue('product_property');
            if (!checkbox?.includes('Temp Controlled')) {
              return <></>;
            }
            return (
              <>
                <Typography.Title level={5} style={{ color: '#0a4fca', paddingTop: '20px' }}>
                  Temperature Controlled
                </Typography.Title>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="temperature_range" label="Temperature Range">
                      <Radio.Group>
                        {tempRange.map((option: RadioOptionProps, index: number) => (
                          <Radio key={index} value={option.key}>
                            {option.name}
                          </Radio>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="cooling_type"
                      label={getRequiredLabel('Type of Cooling')}
                      required={true}
                      rules={[{ required: true }]}
                    >
                      <Select options={coolingTypes} placeholder="Select Type Of Cooling" />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            );
          }}
        </Form.Item>

        <Form.Item dependencies={['product_property']} noStyle>
          {({ getFieldValue }) => {
            const checkbox = getFieldValue('product_property');
            if (!checkbox?.includes('Hazardous')) {
              return <></>;
            }
            return (
              <>
                <Typography.Title level={5} style={{ color: '#0a4fca', paddingTop: '20px' }}>
                  Hazardous
                </Typography.Title>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="un_number"
                      label={getRequiredLabel('UNDG Number')}
                      required={true}
                      rules={[{ required: true }]}
                    >
                      <Select options={getUNDGNumberOptions()} placeholder="Select UNDG No." />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="un_imo_class"
                      label={getRequiredLabel('UN IMO Class')}
                      required={true}
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="Enter UN IMO Class" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="proper_shipping_name" label="Proper Shipping Name">
                      <Input placeholder="Enter Proper Shipping Name" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="technical_name" label="Technical Name">
                      <Input placeholder="Enter Technical Name" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="packaging_group" label="Packaging Group">
                      <Select options={packingGroup} placeholder="Select Packaging Group" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    {renderInputWithUnit(
                      'net_weight',
                      'net_weight_unit',
                      'Net Weight',
                      [{ key: WEIGHT_UNIT_KGS, name: WEIGHT_UNIT_KGS }],
                      true,
                      {
                        min: 0,
                      }
                    )}
                  </Col>
                  <Col span={12}>
                    {renderInputWithUnit(
                      'flash_point',
                      'flash_point_unit',
                      'Flash Point',
                      temperatureOptions
                    )}
                  </Col>

                  <Col span={12}>
                    <Form.Item name="ems_number" label="EMS Number">
                      <Input placeholder="Enter EMS Number" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="emergency_contact_name" label="Emergency Contact Person">
                      <Input placeholder="Enter Name" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="emergency_contact_number" label="Emergency Contact Number">
                      <Input placeholder="Enter Number" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="is_radioactive" valuePropName="checked">
                      <Checkbox>Is Radio Active</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            );
          }}
        </Form.Item>

        <Form.Item dependencies={['product_property']} noStyle>
          {({ getFieldValue }) => {
            const checkbox = getFieldValue('product_property');
            if (!checkbox?.includes('Battery Inside')) {
              return <></>;
            }
            return (
              <>
                <Typography.Title level={5} style={{ color: '#0a4fca', paddingTop: '20px' }}>
                  Battery Inside
                </Typography.Title>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="battery_type"
                      label={getRequiredLabel('Battery Type')}
                      required={true}
                      rules={[{ required: true }]}
                    >
                      <Select options={batteryTypes} placeholder="Select Battery Type" />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            );
          }}
        </Form.Item>
      </Form>
    </Drawer>
  );
}
