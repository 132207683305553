import { Form, Row, Col, Input, Select, DatePicker } from '@shipmnts/pixel-hub';
import { CarrierType } from 'common/models/Carrier';
import { RadioOptionProps } from 'network/commonTypeDefs';
import { ROW_GUTTER, SHIPPING_LINE_SERVICE_TYPES } from 'operations/modules/shipment/constants';
import React from 'react';
import { GlobalSearch } from '@shipmnts/pixel-hub';

export const OceanBookingDetails = () => {
  const carrier_types: CarrierType[] = ['ocean', 'nvocc'];

  return (
    <>
      <Row gutter={ROW_GUTTER}>
        <Col span={6}>
          <Form.Item name="carrier" label={'Carrier'}>
            <GlobalSearch
              doc_type="Global::Carrier"
              searchProps={{ carrier_type: carrier_types }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name={['ocean_shipment_booking', 'shipping_line_booking_number']}
            label="Booking #"
          >
            <Input placeholder="Enter Booking #" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name={['ocean_shipment_booking', 'shipping_line_booking_date']}
            label="Booking Date"
          >
            <DatePicker
              style={{ width: '100%' }}
              placeholder={'Booking Date'}
              format="DD-MM-YYYY"
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name={['ocean_shipment_booking', 'booking_validity_date']}
            label="Booking Validity Date"
          >
            <DatePicker
              style={{ width: '100%' }}
              placeholder={'Date till which booking is valid'}
              format="DD-MM-YYYY"
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Shipping Line Service Type"
            name={['ocean_shipment_booking', 'shipping_line_service_type']}
          >
            <Select>
              {SHIPPING_LINE_SERVICE_TYPES.map((option: RadioOptionProps, index: number) => (
                <Select.Option key={index} value={option.key}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item name={['ocean_shipment_booking', 'cy_cutoff_date']} label="CY Cutoff">
            <DatePicker
              style={{ width: '100%' }}
              placeholder={'Date by which container need to be Gated in'}
              format="DD-MM-YYYY"
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name={['ocean_shipment_booking', 'si_cutoff_date']} label="SI Cutoff">
            <DatePicker
              style={{ width: '100%' }}
              placeholder={'Date by which SI need to be Field'}
              format="DD-MM-YYYY"
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            name={['ocean_shipment_booking', 'documents_cutoff_date']}
            label="Docs Handover Cutoff"
          >
            <DatePicker
              style={{ width: '100%' }}
              placeholder={'Date by which Docs need to be Handed Over'}
              format="DD-MM-YYYY"
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
