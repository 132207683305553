import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { UPDATE_BOOKING_REQUEST_ALLOCATION } from 'operations/modules/booking/graphql/bookingRequest';
import { ExclamationCircleFilled } from '@shipmnts/pixel-hub';
import {
  Card,
  Badge,
  Table,
  message,
  Button,
  Tooltip,
  Typography,
  Form,
} from '@shipmnts/pixel-hub';
import { observer } from 'mobx-react-lite';
import { getSnapshot, cast } from 'mobx-state-tree';
import { OceanTransportOrderValue } from 'operations/models/OceanTransportOrder';
import { BookingRequestValue } from 'operations/models/BookingRequest';
import { startCase as _startCase, groupBy as _groupBy } from 'lodash';
import { STATUSES_MAPPING } from 'operations/modules/reports/constants';
import AllocatedOceanTransportOrderForm from './AllocatedOceanTransportOrderForm';
import { errorMessageHandlerGraphQL } from 'common';

import {
  showContainerAllocation,
  getContainerTypeSetting,
} from 'operations/models/ShipmentContainer';
import {
  validateAllocatedValues,
  getAllocatePayload,
} from 'operations/modules/reports/helpers/allocationHelper';
import { ContainerRequestValue } from 'operations/models/ContainerRequest';
import { LOAD_TYPE_FCL } from 'operations/baseConstants';
import { Link } from 'wouter';
import { ActionRenderer } from 'operations/modules/actionHelper/ActionRenderer';
import { deallocateBookingOrder } from 'operations/modules/actionHelper/BookingRequestActions/bookingRequestActionHelper';

const { Text } = Typography;

export interface UpdateAllocationFormValue {
  requested_containers?: { [key: string]: { [container_type: string]: { count?: number } } };
}

export const getCrTypeIdMapping = (container_requests: ContainerRequestValue[]) => {
  return container_requests.reduce(
    (crMapping: { [key: string]: string }, cr: ContainerRequestValue) => {
      if (cr.container_type_code && cr.id)
        crMapping[getContainerTypeSetting(cr?.container_type_code || '', cr.container_settings)] =
          cr.id;
      return crMapping;
    },
    {}
  );
};

const getInitialValuesForAllocation = (
  bookingRequest: BookingRequestValue,
  ocean_transport_orders: OceanTransportOrderValue[]
) => {
  const bookingRequestCrMapping = getCrTypeIdMapping(bookingRequest.container_requests);
  return ocean_transport_orders.reduce(
    (
      obj: Record<string, Record<string, Record<string, number>>>,
      oto: OceanTransportOrderValue
    ) => {
      const shipment_containers = _groupBy(
        oto.parentBRShipmentContainers(bookingRequest.id).map((sc) => ({
          ...sc,
          containerTypeAndSetting: getContainerTypeSetting(
            sc.container_type_code || '',
            sc.container_settings
          ),
        })),
        'containerTypeAndSetting'
      );
      const otoCrMapping = getCrTypeIdMapping(oto.container_requests || []);
      obj[oto.id] = {};
      Object.keys(shipment_containers).forEach((containerTypeAndSetting) => {
        const br_cr_id = bookingRequestCrMapping[containerTypeAndSetting];
        const oto_cr_id = otoCrMapping[containerTypeAndSetting];
        if (obj[oto.id][br_cr_id]) {
          obj[oto.id][br_cr_id][oto_cr_id] = shipment_containers[containerTypeAndSetting].length;
        } else {
          obj[oto.id][br_cr_id] = {
            [oto_cr_id]: shipment_containers[containerTypeAndSetting].length,
          };
        }
      });
      return obj;
    },
    {}
  );
};

const OceanTransportOrdersView = observer(function OceanTransportOrdersView(props: {
  booking_request: BookingRequestValue;
  oceanTransportOrders: OceanTransportOrderValue[];
  onUpdateAllocation: (booking_request: BookingRequestValue) => void;
  disabled?: boolean;
  disableReason?: string;
  refetchData?: () => void;
}): JSX.Element {
  const {
    oceanTransportOrders,
    booking_request,
    onUpdateAllocation,
    disabled,
    disableReason,
    refetchData,
  } = props;
  const [otoAllocationEdit, setOtoAllocationEdit] = useState<{
    edit: boolean;
    beforeUpdate?: Array<OceanTransportOrderValue>;
  }>({ edit: false });
  const [allocationFormError, setAllocationError] = useState<string>();

  const [
    updateBookingRequestAllocation,
    { data: updateAllocationData, loading: updateAllocationLoading, error: updateAllocationError },
  ] = useMutation(UPDATE_BOOKING_REQUEST_ALLOCATION);

  useEffect(() => {
    if (
      !updateAllocationError &&
      updateAllocationData &&
      updateAllocationData?.update_booking_request_allocation
    ) {
      message.success('Booking Allocation Updated!');
      onUpdateAllocation(updateAllocationData?.update_booking_request_allocation);
      setOtoAllocationEdit({ edit: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateAllocationError, updateAllocationData]);

  if (otoAllocationEdit.edit) {
    return (
      <Form
        scrollToFirstError
        initialValues={getInitialValuesForAllocation(booking_request, oceanTransportOrders)}
        // FIX ME: any
        onFinish={(values: any) => {
          const error = validateAllocatedValues(
            booking_request.container_requests,
            values,
            'quantity'
          );
          if (error) setAllocationError(error);
          else {
            const payload = getAllocatePayload(
              booking_request.id,
              values,
              'id',
              'ocean_transport_orders'
            );
            updateBookingRequestAllocation({ variables: payload });
          }
        }}
      >
        <Card
          id="ocean_transport_orders"
          title="Bookings"
          extra={[
            <Button
              style={{ marginRight: '5px' }}
              key="cancel"
              disabled={updateAllocationLoading}
              onClick={() => setOtoAllocationEdit({ edit: false })}
            >
              Cancel
            </Button>,
            <Button loading={updateAllocationLoading} key="save" htmlType="submit" type="primary">
              Save
            </Button>,
          ]}
        >
          {updateAllocationError && errorMessageHandlerGraphQL(updateAllocationError)}
          {allocationFormError && (
            <div style={{ marginBottom: '10px' }}>
              <Text type="danger">{allocationFormError}</Text>
            </div>
          )}
          <AllocatedOceanTransportOrderForm
            booking_request={booking_request}
            oceanTransportOrders={oceanTransportOrders}
          />
        </Card>
      </Form>
    );
  }

  const getColumns = () => {
    let columns: Array<{
      title: string;
      dataIndex: string;
      render?: (
        text: string,
        record: OceanTransportOrderValue,
        index: number
      ) => JSX.Element | null;
    }> = [
      {
        title: 'Booking Number',
        dataIndex: 'booking_number',
        render: function render(text: string, record: OceanTransportOrderValue, index: number) {
          return (
            <span>
              <Link to={`~/view/booking_order/${record.id}`}>
                {record.booking_number || record.id}
              </Link>
              {record.is_amendment_pending && (
                <Tooltip title="Amendment Pending">
                  <ExclamationCircleFilled
                    style={{ color: '#FFB84E', marginLeft: '5px', fontSize: '16px' }}
                  />
                </Tooltip>
              )}
            </span>
          );
        },
      },
      {
        title: 'Shipping Line/Vendor',
        dataIndex: 'vendorName',
      },
    ];
    if (booking_request.load_type === LOAD_TYPE_FCL) {
      columns = [
        ...columns,
        {
          title: 'Allocated',
          dataIndex: 'allocated',
          render: function render(text: string, record: OceanTransportOrderValue, index: number) {
            const allocated = record.parentShipmentContainersCountByTypeAndSetting(
              booking_request.id
            );
            return (
              <span>
                {Object.keys(allocated)
                  .map((container_type) => showContainerAllocation(allocated[container_type]))
                  .join(', ')}
              </span>
            );
          },
        },
        {
          title: 'Valid Till',
          dataIndex: 'valid_till_date',
          render: function render(text: string, record: OceanTransportOrderValue, index: number) {
            return (
              <span>{record?.valid_till_date && record.valid_till_date.format('DD/MM/YYYY')}</span>
            );
          },
        },
      ];
    }
    columns = [
      ...columns,
      {
        title: 'Status',
        dataIndex: 'status',
        render: function render(text: string, record: OceanTransportOrderValue, index: number) {
          return (
            <Badge
              status={record.status ? STATUSES_MAPPING[record.status] : 'default'}
              text={_startCase(record.status)}
            />
          );
        },
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        render: function render(text: string, record: OceanTransportOrderValue, index: number) {
          if (record.isAnyContainerPickedUp(booking_request.id)) return null;
          return (
            <ActionRenderer
              id={booking_request.id}
              data={booking_request}
              doc_type_id={'Shipment::BookingRequest'}
              selectedActions={[deallocateBookingOrder(booking_request, record)]}
              refetchData={refetchData}
            />
          );
        },
      },
    ];
    return columns;
  };

  return (
    <Card
      id="ocean_transport_orders"
      title="Bookings"
      extra={
        <Tooltip title={disableReason}>
          <Button
            disabled={disabled}
            onClick={() => {
              setOtoAllocationEdit({
                edit: true,
                beforeUpdate: oceanTransportOrders
                  ? getSnapshot<OceanTransportOrderValue[]>(cast(oceanTransportOrders)).slice()
                  : [],
              });
            }}
          >
            Edit
          </Button>
        </Tooltip>
      }
    >
      <Table
        dataSource={oceanTransportOrders.slice()}
        columns={getColumns()}
        pagination={false}
        rowKey={(record) => record.id || ''}
        size="small"
      />
    </Card>
  );
});

export default OceanTransportOrdersView;
