import { useLazyQuery } from '@apollo/client';
import { Button, Spin, dayjs } from '@shipmnts/pixel-hub';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { LdbEventType, LdbSingleEventType, TrackingStatusType } from '../../../types';
import { GET_LDB_EVENTS } from '../../../graphql/queries';
import { AnchorSvg } from '../../../Icons';
import Timeline from '../../timeline/Timeline';

type MoreEvents = {
  moreEvents: LdbEventType[];
};
type StructuredEventType = LdbEventType & MoreEvents;
type Props = {
  ldbLastStatus: {
    id?: string;
    last_event: LdbSingleEventType;
    status?: TrackingStatusType;
  };
};

const RenderLdbEvents = ({ ldbLastStatus }: Props): JSX.Element => {
  // States
  const [terminalTrackingExpand, setTerminalTrackingExpand] = useState<boolean>(false);
  // Queries
  const [getLdbEvents, { data: ldbEventsData, loading }] = useLazyQuery(GET_LDB_EVENTS, {
    variables: {
      tracking_request_id: ldbLastStatus?.id,
    },
  });

  const ldbEvents: LdbEventType[] = get(ldbEventsData, 'get_ldb_events', []);
  const { last_event } = ldbLastStatus;
  const structuredEvents: StructuredEventType[] = [];
  let lastOccuredEvent =
    !loading && Array.isArray(ldbEvents) ? ldbEvents[0]?.events[0] || last_event : last_event;

  // Effects
  useEffect(() => {
    getLdbEvents();
  }, [getLdbEvents]);
  useEffect(() => {
    if (!last_event?.name && ldbEvents?.length > 0) {
      setTerminalTrackingExpand(true);
    }
  }, [last_event, ldbEvents]);

  // Handlers
  const handleExpandClick = () => {
    setTerminalTrackingExpand((prev) => !prev);
  };

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spin></Spin>
      </div>
    );
  }

  Array.isArray(ldbEvents) &&
    ldbEvents.forEach((event, index) => {
      event.events.forEach((i: LdbSingleEventType) => {
        if (i.actual_date) {
          if (lastOccuredEvent) {
            const currentEventDate = dayjs(i.actual_date);
            const currentDate = dayjs(new Date());

            const prevEventDate = dayjs(lastOccuredEvent.actual_date);

            if (
              !currentEventDate.isBefore(prevEventDate) &&
              !currentDate.isBefore(currentEventDate)
            ) {
              lastOccuredEvent = i;
            }
          } else lastOccuredEvent = i;
        }
      });
      // Structuting For "Crossed Station" Grouping
      const prevEvent = ldbEvents[index - 1]?.events[0];
      if (event?.events[0]?.name === 'STATION CROSSED' && prevEvent?.name === 'STATION CROSSED') {
        const currentDate = dayjs(event.events[0].actual_date);
        const prevEventDate = dayjs(prevEvent.actual_date);
        if (!currentDate.isSame(prevEventDate)) {
          structuredEvents[structuredEvents.length - 1].moreEvents.push(event);
        } else {
          structuredEvents.push({ ...event, moreEvents: [] });
        }
      } else {
        structuredEvents.push({ ...event, moreEvents: [] });
      }
    });

  if (!terminalTrackingExpand && lastOccuredEvent?.current_location) {
    return (
      <>
        <div style={{ marginTop: '24px' }}>
          <Timeline
            headerIcon={AnchorSvg}
            headerText={`${lastOccuredEvent?.current_location}`}
            isAccordian={false}
            events={[lastOccuredEvent]}
            lastOccuredEvent={lastOccuredEvent}
          />
        </div>
        <Button className="expand-all-btn" ghost onClick={handleExpandClick}>
          Expand All
        </Button>
      </>
    );
  }

  return (
    <>
      {structuredEvents.map((item, index: number) => (
        <RenderSingleEvent event={item} key={index} lastOccuredEvent={lastOccuredEvent} />
      ))}
      {structuredEvents && structuredEvents.length > 0 && (
        <Button className="expand-all-btn" ghost onClick={handleExpandClick}>
          Collapse
        </Button>
      )}
    </>
  );
};

export default RenderLdbEvents;

const RenderSingleEvent = ({
  event,
  lastOccuredEvent,
}: {
  event: StructuredEventType;
  lastOccuredEvent: LdbSingleEventType | undefined | null;
}) => {
  const [showMoreEvents, setShowMoreEvents] = useState(false);
  return (
    <div style={{ marginTop: '24px' }} key={event.location}>
      <Timeline
        headerText={event.location}
        headerIcon={AnchorSvg}
        isAccordian={false}
        events={event.events}
        lastOccuredEvent={lastOccuredEvent}
      />
      {showMoreEvents &&
        event.moreEvents.map((item) => {
          return (
            <Timeline
              headerText={item.location}
              headerIcon={AnchorSvg}
              isAccordian={false}
              events={item.events}
              key={item.location}
              lastOccuredEvent={lastOccuredEvent}
            />
          );
        })}
      {event.moreEvents.length > 0 && (
        <div className="staion-crossed-count" onClick={() => setShowMoreEvents(!showMoreEvents)}>
          {!showMoreEvents ? `+ ${event.moreEvents.length}` : `-`}
        </div>
      )}
    </div>
  );
};
