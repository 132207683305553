import { gql } from '@apollo/client';

export const FETCH_TICKET = gql`
  query service_management_ticket($id: ID!) {
    service_management_ticket(id: $id) {
      id
      title
      priority
      description
      ticket_number
      ticket_status
      assigned_to {
        department
        email
        name
        first_name
        id
        last_name
        mobile_number
      }
      raised_by_company {
        id
        registered_name
      }
      raised_by_user {
        department
        email
        name
        first_name
        id
        last_name
        mobile_number
      }
      resolution_by_company {
        id
        registered_name
      }
      resolution_by_user {
        department
        email
        name
        first_name
        id
        last_name
        mobile_number
      }
      references {
        id
        data
        reference_id
        reference_type
      }
      pipeline {
        id
        name
      }
      current_state {
        id
        name
      }
      target_resolution_date
    }
  }
`;

export const CREATE_TICKET = gql`
  mutation create_ticket($ticket: CreateTicketInputType!) {
    create_ticket(ticket: $ticket) {
      id
      title
      ticket_number
    }
  }
`;

export const FETCH_PIPELINE_LIST = gql`
  query blueprint_pipeline_by_reference_type($doctype: String!) {
    blueprint_pipeline_by_reference_type(doctype: $doctype, is_active: true) {
      id
      name
      state_definitions {
        id
        name
      }
    }
  }
`;

export const UPDATE_TICKET = gql`
  mutation update_ticket($ticket: UpdateTicketInputType!) {
    update_ticket(ticket: $ticket) {
      id
      title
    }
  }
`;
