export const FUEL_TYPE = [
  { value: 'Petrol', name: 'petrol' },
  { value: 'Diesel', name: 'diesel' },
  { value: 'Natural Gas', name: 'natural_gas' },
  {
    value: 'Electric',
    name: 'electric',
  },
];

export const VEHICLE_CATEGORY = [
  { name: 'car_carrier_trailer', value: 'Car Carrier Trailer' },
  { name: 'car_carrier_truck', value: 'Car Carrier Truck' },
  { name: 'chassis_on_trailer', value: 'Chassis On Trailer (COT)' },
  { name: 'crane', value: 'Crane' },
  { name: 'excavator', value: 'Excavator' },
  { name: 'flat_bed_trailer', value: 'Flat Bed Trailer' },
  { name: 'forklift', value: 'Forklift' },
  { name: 'heavy_commercial_vehicle', value: 'Heavy Commercial Vehicle (HCV)' },
  { name: 'intermediate_commercial_vehicle', value: 'Intermediate Commercial Vehicle (ICV)' },
  { name: 'light_commercial_vehicle', value: 'Light Commercial Vehicle (LCV)' },
  { name: 'loader', value: 'Loader' },
  { name: 'low_bed_trailer', value: 'Low Bed Trailer' },
  { name: 'medium_commercial_vehicle', value: 'Medium Commercial Vehicle (MCV)' },
  { name: 'semi_low_bed_trailer', value: 'Semi Low Bed Trailer' },
  { name: 'small_commercial_vehicle', value: 'Small Commercial Vehicle (SCV)' },
  { name: 'tractor', value: 'Tractor' },
];

export const ASSET_TYPE_OWN = 'own';
export const ASSET_TYPE_HIRE = 'hire';

export const ASSET_TYPES = [
  {
    key: ASSET_TYPE_OWN,
    name: 'Own',
  },
  {
    key: ASSET_TYPE_HIRE,
    name: 'Hire',
  },
];
