import React from 'react';
import { Spin } from '@shipmnts/pixel-hub';

interface SpinnerProps {
  tip?: string;
  size?: 'small' | 'default' | 'large';
  spinning?: boolean;
  style?: React.CSSProperties;
}
const Spinner = (props: SpinnerProps): JSX.Element => {
  const { tip, size, spinning, style } = props;
  return (
    <>
      <Spin tip={tip} size={size} spinning={spinning} style={style}>
        <div style={{ padding: '100px' }} />
      </Spin>
    </>
  );
};

export default Spinner;
