import { CustomIcon, Tag, getDateFromUnix, getDateTimeFromUnix } from '@shipmnts/pixel-hub';
import {
  CANCELLED,
  STATUS_DELIVERED,
  STATUS_FULLY_DELIVERED,
  STATUS_FULLY_ISSUED,
  STATUS_FULLY_RECEIVED,
  STATUS_ISSUED,
  STATUS_PARTIALLY_DELIVERED,
  STATUS_PARTIALLY_ISSUED,
  STATUS_PARTIALLY_RECEIVED,
  STATUS_PENDING,
  STATUS_RECEIVED,
  WarehouseTransactionValue,
} from 'operations/models/WarehouseTransaction';
import React from 'react';
import { collapsibleCard } from 'operations/modules/shipment/components/DetailLayout/Common/common';
import { upperCase as _upperCase } from 'lodash';

interface WMSCreationDetailsProps {
  warehouseTransaction: WarehouseTransactionValue;
}

const WMSCreationDetails = (props: WMSCreationDetailsProps) => {
  const { warehouseTransaction } = props;
  const getTagsColor = (status: string | undefined) => {
    if (!status) return 'blue';
    if ([STATUS_PENDING, STATUS_RECEIVED, STATUS_ISSUED].includes(status)) return 'blue';
    else if (
      [STATUS_PARTIALLY_DELIVERED, STATUS_PARTIALLY_RECEIVED, STATUS_PARTIALLY_ISSUED].includes(
        status
      )
    )
      return 'gold';
    else if (
      [
        STATUS_FULLY_RECEIVED,
        STATUS_FULLY_DELIVERED,
        STATUS_FULLY_ISSUED,
        STATUS_DELIVERED,
      ].includes(status)
    )
      return 'green';
    else if ([CANCELLED].includes(status)) return 'red';
    return 'blue';
  };
  const CreationDetails = [];
  CreationDetails.push({
    Status: (
      <Tag color={getTagsColor(warehouseTransaction?.status)}>
        {_upperCase(warehouseTransaction?.status || '')}
      </Tag>
    ),
  });
  CreationDetails.push({
    Warehouse: warehouseTransaction?.target_warehouse?.node_name,
  });
  if (warehouseTransaction?.type === 'advance_receipt')
    CreationDetails.push({
      'Target Receipt Date': warehouseTransaction?.target_date ? (
        <div>{getDateTimeFromUnix(warehouseTransaction?.target_date)}</div>
      ) : null,
    });
  if (warehouseTransaction?.type === 'goods_issued')
    CreationDetails.push({
      'Target Delivery Date': warehouseTransaction?.target_date ? (
        <div>{getDateTimeFromUnix(warehouseTransaction?.target_date)}</div>
      ) : null,
    });
  CreationDetails.push({
    'Created by': warehouseTransaction.created_by
      ? [warehouseTransaction.created_by.first_name, warehouseTransaction.created_by.last_name]
          .filter(Boolean)
          .join(' ')
      : '',
  });
  CreationDetails.push({
    'Created At': warehouseTransaction?.created_at
      ? getDateFromUnix(warehouseTransaction?.created_at)
      : null,
  });

  return (
    <>
      {collapsibleCard({
        icon: <CustomIcon icon="Info" />,
        header: 'Creation Detail',
        data: CreationDetails,
      })}
    </>
  );
};

export default WMSCreationDetails;
