import React, { useEffect, useState } from 'react';
import { Modal, message, Form } from '@shipmnts/pixel-hub';
// import { SalesPerson } from 'common';
import { CustomerSearch } from 'common';
import { useMutation } from '@apollo/client';
import { UPDATE_HOUSE_SHIPMENT_FIELDS, UPDATE_SHIPMENT_FIELDS } from '../Containers/graphql';
import { ShipmentValue } from 'operations/models/Shipment';
import { GlobalSearch } from '@shipmnts/pixel-hub';

interface AddMissingFieldsBeforeInvoiceType {
  shipment: ShipmentValue;
  onCancel: () => void;
  onOk: (values: any) => void;
}

function AddMissingFieldsBeforeInvoice(props: AddMissingFieldsBeforeInvoiceType) {
  const { onOk, onCancel, shipment } = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const mutation = shipment.isHouseShipment()
    ? UPDATE_HOUSE_SHIPMENT_FIELDS
    : UPDATE_SHIPMENT_FIELDS;
  const [updateShipment, { data, error }] = useMutation(mutation);

  useEffect(() => {
    if (data) {
      const values = form.getFieldsValue();
      onOk(values);
      setLoading(false);
      message.success('Details Updated Successfully!');
    }
    if (error) message.error(error.message);
  }, [data, error, onOk, form]);

  const renderCustomer = () => {
    return (
      <Form.Item
        label="Customer/Primary Billing Party"
        required
        name="customer_company_id"
        rules={[{ required: true }]}
      >
        <CustomerSearch selectMode={undefined} trigger="create_shipment" />
      </Form.Item>
    );
  };

  const renderSalesPerson = () => {
    return (
      <Form.Item label="Sales Person" required name="sales_agent_id" rules={[{ required: true }]}>
        <GlobalSearch doc_type="Network::SalesPerson" selectMode={undefined} />
      </Form.Item>
    );
  };

  async function onSave(values: any) {
    setLoading(true);
    const variables = { ...values };
    if (variables.customer_company_id)
      variables.customer_company_id = variables.customer_company_id.id;
    if (variables.sales_agent_id) variables.sales_agent_id = variables.sales_agent_id.id;
    updateShipment({
      variables: {
        id: shipment.id,
        shipment: {
          ...variables,
        },
      },
    });
  }

  return (
    <Modal
      open={true}
      onCancel={onCancel}
      onOk={() => form.submit()}
      okText="Save"
      title="Update fields in Shipment"
      confirmLoading={loading}
    >
      <Form layout="vertical" form={form} onFinish={onSave}>
        {!shipment.customer_company?.id && renderCustomer()}
        {!shipment.sales_agent && renderSalesPerson()}
      </Form>
    </Modal>
  );
}

export default AddMissingFieldsBeforeInvoice;
