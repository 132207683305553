import React from 'react';
import { Button, Typography, UnorderedListOutlined } from '@shipmnts/pixel-hub';
import WarehouseUnitTree from './WarehouseUnitTree';
import { useLocation } from 'wouter';

const WarehouseTreeLayout = () => {
  const { 1: navigate } = useLocation();
  const redirectToListView = () => {
    navigate(`~/workspace?doc_type=Wms::WarehouseUnit`);
  };
  return (
    <>
      <div
        style={{
          height: '56px',
          borderBottom: '1px solid #dadee7',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <span style={{ paddingLeft: '24px', marginRight: 'auto' }}>
          <Typography.Title level={5}>Warehouse Management / Warehouse Tree</Typography.Title>
        </span>
        <span style={{ paddingRight: '24px' }}>
          <Button size="small" htmlType="submit" onClick={() => redirectToListView()}>
            <UnorderedListOutlined /> List View
          </Button>
        </span>
      </div>

      <WarehouseUnitTree />
    </>
  );
};

export default WarehouseTreeLayout;
