import { useMutation } from '@apollo/client';
import { message, Modal } from '@shipmnts/pixel-hub';
import React, { useEffect } from 'react';
import { UPDATE_VEHICLE } from './graphql';

interface StatusUpdateVehicleProps {
  vehicle: any;
  onClose?: () => void;
  onSuccess?: () => void;
}
export default function StatusUpdateVehicle(props: StatusUpdateVehicleProps) {
  const { onClose, vehicle, onSuccess } = props;
  const [disableV, { data, error }] = useMutation(UPDATE_VEHICLE);
  const activeVehicle = vehicle?.vehicle_status === 'enabled';
  const actionName = activeVehicle ? 'Disable' : 'Enable';

  const [modal, contextHolder] = Modal.useModal();
  useEffect(() => {
    if (error) {
      message.error(error.message);
      return;
    }
    if (data?.update_vehicle) {
      message.success(`Vehicle updated successfully!`);
      if (onSuccess) onSuccess();
      if (onClose) onClose();
    }
  }, [data, error, onSuccess, onClose, actionName]);

  const config = {
    title: `Are you sure to ${actionName} this Vehicle?`,
    content: `Once you click on Confirm, It will ${actionName} Vehicle`,
    okText: `Confirm ${actionName}`,
    cancelText: 'Cancel',
    onCancel: () => {
      if (onClose) onClose();
    },
    onOk: async () => {
      disableV({
        variables: {
          id: vehicle?.id,
          vehicle: {
            vehicle_status: activeVehicle ? 'disabled' : 'enabled',
            vehicle_license_plate_number: vehicle.vehicle_license_plate_number,
            vehicle_category: vehicle.vehicle_category,
          },
        },
      });
    },
  };

  useEffect(() => {
    Modal.confirm(config);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  return <>{contextHolder}</>;
}
