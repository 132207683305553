import Shipment, { ShipmentValue } from 'operations/models/Shipment';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Button, Drawer, Flex, Skeleton, message } from '@shipmnts/pixel-hub';
import { Input, Checkbox, CheckboxChangeEvent } from '@shipmnts/pixel-hub';
import { useMutation } from '@apollo/client';
import { ASSIGN_LOADS_TO_SHIPMENTS } from './graphql';
import CreateAndAssignContainerLoad, {
  ShipmentContainerData,
} from './CreateAndAssignContainerLoad';
import {
  FETCH_CARGOS_FOR_CONSOL,
  FETCH_LOADS_FROM_SAME_CUSTOMER,
  FETCH_CONTAINERS_FOR_CONSOL,
  FETCH_ALL_HOUSE_LOADS,
} from 'operations/graphql/shipment';
import { CargoValue } from 'operations/models/Cargo';
import { UPDATE_CONTAINER_PICKUP_DETAILS } from 'operations/modules/booking/graphql/shipmentContainer';
import { ContainerDetailsRow } from 'operations/modules/booking/components/RoadTransportOrder/ContainerDetailsForm';
import { useLazyQuery } from '@apollo/client';
import { pick as _pick, omit as _omit } from 'lodash';
import {
  LoadType,
  generateAssignCargoPayload,
  generateAssignContainerPayload,
  autoGenerateLR,
  getShipmentLR,
  LRSuggestionType,
} from './assignLoadHelpers';
import { GridOptions, RowNode } from '@ag-grid-community/core';
import { VOLUME_UNIT_CBM } from 'operations/baseConstants';
import CargoSelection, { CargoData } from '../DetailLayout/Common/CargoSelection';
import { useSession } from 'common';
import { ShipmentContainerValue } from 'operations/models/ShipmentContainer';
import { debounce as _debounce } from 'lodash';
import { DOCUMENT_TYPE_HOUSE, SHIPMENT_TYPE_CONSOL } from '../../constants';
import './CreateAndAssignLoad.css';

type Props = {
  shipment: ShipmentValue;
  onClose: () => void;
  onSuccess?: () => void;
  isAddNewHouse?: boolean;
  selectedIds?: string[] | null;
  matchingOrders?: ShipmentValue[];
};

function CreateAndAssignLoad(props: Props) {
  const {
    shipment,
    onClose,
    onSuccess,
    isAddNewHouse = false,
    selectedIds,
    matchingOrders,
  } = props;
  const [containers, setContainers] = useState<any[]>([]);
  const [cargos, setCargos] = useState<any>([]);
  const [initialCargos, setInitialCargos] = useState<any>([]);
  const [tripOrderNo, setTripOrderNo] = useState<string[]>([shipment?.id]);
  const [lrSuggestions, setLRSuggestions] = useState<LRSuggestionType[]>([]);
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [isMatchingSelected, setIsMatchingSelected] = useState(false);
  const [isAutoGenerateLR, setIsAutoGenerateLR] = useState(false);

  const gridRef = useRef<GridOptions>();
  const sessionData = useSession();

  const [
    fetchSameCustomerCargoContainer,
    {
      data: sameCustomerLoadData,
      loading: sameCustomerLoadsLoading,
      error: sameCustomerLoadsError,
    },
  ] = useLazyQuery(FETCH_LOADS_FROM_SAME_CUSTOMER, {
    variables: { shipment_id: shipment.id },
    fetchPolicy: 'network-only',
  });
  const [
    fetchAllHouseLoads,
    { data: houseLoadsData, loading: houseLoadsLoading, error: houseLoadsError },
  ] = useLazyQuery(FETCH_ALL_HOUSE_LOADS);
  const [
    fetchContainerForConsol,
    {
      data: containerData,
      loading: containerDataLoading,
      error: containerDataError,
      called: containerDataCalled,
    },
  ] = useLazyQuery(FETCH_CONTAINERS_FOR_CONSOL);
  const [fetchCargos, { data: cargoData, loading: cargoDataLoading }] =
    useLazyQuery(FETCH_CARGOS_FOR_CONSOL);

  const [
    assignLoadsToShipments,
    { data: assignLoadsData, loading: assignLoadLoading, error: assignLoadError },
  ] = useMutation(ASSIGN_LOADS_TO_SHIPMENTS);
  const [updateContainerPickupDetails, { loading: containerPickupLoading }] = useMutation(
    UPDATE_CONTAINER_PICKUP_DETAILS
  );

  const updateLRSuggestions = (lrSuggestions: LRSuggestionType[]) => {
    setLRSuggestions(lrSuggestions);
    if (lrSuggestions.length === 0 && shipment.split_from_order_id) setIsAutoGenerateLR(true);
    else setIsAutoGenerateLR(false);
  };

  const setBackTOBackLRSuggestion = (shipment: ShipmentValue) => {
    if (shipment.isBackToBackShipment()) {
      const LRNumber = getShipmentLR(shipment);
      if (LRNumber) {
        updateLRSuggestions([
          {
            value: shipment.id,
            label: LRNumber,
          },
        ]);
      } else {
        updateLRSuggestions([]);
      }
    }
  };

  const getPendingCargos = (
    cargos: CargoValue[],
    link_order_no?: string,
    lr_number?: string,
    is_single_load?: boolean
  ) => {
    const updatedCargos: CargoData[] = [];
    const isBreakBulk = shipment.isBreakBulk();
    cargos
      .filter((c: CargoValue) => !selectedIds || selectedIds.includes(c.id || ''))
      .forEach((cargo) => {
        if (cargo.allocation_pending_quantity) {
          const currentCargo: CargoData = {
            ...cargo,
            link_order_no,
            lr_number,
            is_single_load,
          };
          if (isBreakBulk && cargo.allocation_pending_quantity === 1 && !is_single_load) {
            currentCargo['allocated_qty'] = 1;
          }
          updatedCargos.push(currentCargo);
        }
      });
    return updatedCargos;
  };

  useEffect(() => {
    const orders = matchingOrders || sameCustomerLoadData?.fetch_loads_from_same_customer;
    if (!sameCustomerLoadsLoading && !sameCustomerLoadsError && orders) {
      let cargos: CargoData[] = [];
      let shipment_containers: ShipmentContainerData[] = [];
      const maching_order_ids: string[] = [];
      orders.forEach((shipment: ShipmentValue) => {
        cargos = [
          ...cargos,
          ...getPendingCargos(shipment?.cargos, shipment?.shipment_booking_number || ''),
        ];
        shipment_containers = [
          ...shipment_containers,
          ...(shipment?.shipment_containers || [])
            .filter((c: ShipmentContainerValue) => !selectedIds || selectedIds.includes(c.id || ''))
            .map((c: ShipmentContainerValue) => ({
              ...c,
              link_order_no: shipment?.shipment_booking_number,
            })),
        ];
        maching_order_ids.push(shipment.id);
      });
      if (shipment.isFcl()) setContainers(shipment_containers || []);
      else {
        setCargos(cargos || []);
        setInitialCargos(cargos || []);
      }
      setTripOrderNo(maching_order_ids);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sameCustomerLoadData, sameCustomerLoadsLoading, sameCustomerLoadsError, matchingOrders]);

  useEffect(() => {
    if (shipment && !matchingOrders) {
      const shipment_id =
        shipment.split_from_order_id && !isAddNewHouse
          ? shipment.split_from_order_id
          : shipment?.id;
      if (isAddNewHouse && shipment.isConsolShipment()) {
        fetchAllHouseLoads({
          variables: {
            id: shipment_id,
          },
        });
      } else {
        if (shipment.isFcl()) {
          fetchContainerForConsol({
            variables: {
              shipment_id: shipment_id,
            },
          });
        } else {
          fetchCargos({
            variables: {
              shipment_id: shipment_id,
            },
          });
        }

        if (shipment.isConsolShipment()) {
          fetchAllHouseLoads({
            variables: {
              id: shipment.id,
            },
          });
        }
      }
    } else if (!!matchingOrders) setIsMatchingSelected(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipment, isAddNewHouse]);

  useEffect(() => {
    if (houseLoadsData && !houseLoadsLoading && !houseLoadsError && houseLoadsData.shipment) {
      const shipment = Shipment.create(houseLoadsData.shipment);
      let cargos: any[] = [];
      const containers: any[] = [];
      const lrSuggestions: LRSuggestionType[] = [];
      shipment.house_shipments?.forEach((house_shipment: ShipmentValue) => {
        if (house_shipment && !house_shipment.isDocumentFinalised(DOCUMENT_TYPE_HOUSE)) {
          const lr_number = getShipmentLR(house_shipment);
          const currentHouseCargos = getPendingCargos(
            house_shipment.cargos,
            '',
            house_shipment.id,
            house_shipment.cargos?.length === 1
          );
          cargos = [...cargos, ...currentHouseCargos];

          house_shipment.shipment_containers?.forEach((container) => {
            containers.push({
              ...container,
              lr_number: house_shipment.id,
              is_single_load: house_shipment.shipment_containers?.length === 1,
            });
          });
          if (lr_number) lrSuggestions.push({ label: lr_number, value: house_shipment.id });
        }
      });
      if (isAddNewHouse && shipment.shipment_type === SHIPMENT_TYPE_CONSOL) {
        setCargos(cargos);
        setInitialCargos(cargos);
        setContainers(containers);
        updateLRSuggestions(lrSuggestions);
      } else {
        updateLRSuggestions(lrSuggestions);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [houseLoadsData, houseLoadsLoading, houseLoadsError, isAddNewHouse, shipment]);

  useEffect(() => {
    const lr_number = shipment.isBackToBackShipment() ? getShipmentLR(shipment) : '';
    if (cargoData?.fetch_cargos_for_consol && !cargoDataLoading) {
      setCargos(
        getPendingCargos(cargoData.fetch_cargos_for_consol, '', lr_number ? shipment.id : '')
      );
      setInitialCargos(
        (cargoData.fetch_cargos_for_consol || [])
          .filter((c: CargoValue) => !selectedIds || selectedIds.includes(c.id || ''))
          .map((c: CargoValue) => ({
            ...c,
          })) || []
      );
      setBackTOBackLRSuggestion(shipment);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cargoData, cargoDataLoading, shipment]);

  useEffect(() => {
    const lr_number = shipment.isBackToBackShipment() ? getShipmentLR(shipment) : '';

    if (
      containerData?.fetch_containers_for_consol &&
      containerDataCalled &&
      !containerDataError &&
      !containerDataLoading &&
      containers.length === 0
    ) {
      setContainers(
        (containerData.fetch_containers_for_consol || [])
          .filter((c: ShipmentContainerValue) => !selectedIds || selectedIds.includes(c.id || ''))
          .map((c: ShipmentContainerValue) => ({
            ...c,
            lr_number: lr_number ? shipment.id : '',
          })) || []
      );
    }
    setBackTOBackLRSuggestion(shipment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    containerData,
    containerDataCalled,
    containerDataError,
    containerDataLoading,
    isAddNewHouse,
    shipment,
  ]);

  useEffect(() => {
    if (assignLoadError || assignLoadLoading || !assignLoadsData) return;
    if ((isAutoGenerateLR || isAddNewHouse) && sessionData) {
      const numberOfShipments = assignLoadsData?.assign_loads_to_shipments?.length;
      if (numberOfShipments) {
        const lastShipment = assignLoadsData?.assign_loads_to_shipments?.[numberOfShipments - 1];
        if (lastShipment) {
          autoGenerateLR(lastShipment, sessionData);
        }
      }
    }

    message.success('Load Assigned Successfully');
    setDrawerOpen(false);
    if (onSuccess) onSuccess();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    assignLoadError,
    assignLoadLoading,
    assignLoadsData,
    sessionData,
    cargos,
    shipment,
    isAutoGenerateLR,
    isAddNewHouse,
  ]);

  const updateContainerDetails = (containers: ContainerDetailsRow[]) => {
    if (!containers) return;
    const payload = {
      shipment_containers: containers
        .filter((c: any) => c.container_number && c.pickup_date)
        .map((c: ContainerDetailsRow) => ({
          ..._pick(c, [
            'id',
            'container_number',
            'carrier_seal_number',
            'cargo_gross_weight',
            'weight_unit',
            'is_non_iso_container',
            'pickup_date',
            'container_settings',
            'eway_bill_no',
            'eway_bill_validity',
            'commercial_invoice_number',
            'invoice_date',
          ]),
          container_settings: _omit(c.container_settings, '__typename'),
        })),
    };

    updateContainerPickupDetails({ variables: payload });
  };

  const handleAssignLoads = () => {
    let loads: LoadType[] = [];
    let errorOccured = false;

    const containers: any[] = [];
    const cargos: any[] = [];

    gridRef?.current?.api?.forEachNode((node: RowNode<any>) => {
      const isSelected = node?.isSelected();
      const vehicle_type =
        node?.data?.assign_vehicle_load?.vehicle?.vehicle_category || node?.data?.vehicle_type;
      const vehicle_details = node?.data?.assign_vehicle_load?.vehicle;
      if (shipment.isFcl())
        containers.push({
          ...node?.data,
          vehicle_type,
          vehicle_details,
          is_row_selected: isSelected,
        });
      else
        cargos.push({
          ...node?.data,
          vehicle_type,
          vehicle_details,
          is_row_selected: isSelected,
          volume_unit: node?.data?.volume_unit || VOLUME_UNIT_CBM,
        });
    });

    if (shipment.isFcl()) {
      const data = generateAssignContainerPayload(containers, shipment, isAddNewHouse);
      loads = data?.loads;
      errorOccured = data?.errorOccured;
      const updatedContainers = data?.updatedContainers;

      setContainers(updatedContainers);
      if (!errorOccured && loads.length) updateContainerDetails(updatedContainers);
    } else {
      const data = generateAssignCargoPayload(cargos, initialCargos, shipment, isAddNewHouse);
      const updatedCargos = data.updatedCargos;
      errorOccured = data.errorOccured;
      loads = data.loads;
      setCargos(updatedCargos);
    }
    if (errorOccured) return;

    if (loads?.length)
      assignLoadsToShipments({
        variables: {
          loads: loads,
          is_auto_generate_consignment_note: isAutoGenerateLR,
          is_add_new_house: isAddNewHouse,
        },
      });
  };

  const onMatchOrderCheckboxChange = (e: any) => {
    setIsMatchingSelected(e.target.checked);
    if (!e.target.checked) {
      if (shipment.isFcl()) {
        if (
          containerData?.fetch_containers_for_consol &&
          containerDataCalled &&
          !containerDataError &&
          !containerDataLoading &&
          containers.length === 0
        ) {
          setContainers(
            (containerData.fetch_containers_for_consol || [])
              .filter(
                (c: ShipmentContainerValue) => !selectedIds || selectedIds.includes(c.id || '')
              )
              .map((c: ShipmentContainerValue) => ({
                ...c,
              })) || []
          );
        }
      } else {
        if (cargoData?.fetch_cargos_for_consol) {
          setCargos(
            (cargoData.fetch_cargos_for_consol || [])
              .filter((c: CargoValue) => !selectedIds || selectedIds.includes(c.id || ''))
              .map((c: CargoValue) => ({
                ...c,
              })) || []
          );
        }
      }
      setTripOrderNo([shipment?.id]);
      return;
    } else {
      fetchSameCustomerCargoContainer();
    }
  };

  const handleSearch = useMemo(
    () =>
      _debounce((e) => {
        const search = e?.currentTarget?.value;
        if (gridRef.current) gridRef.current.api?.setQuickFilter(search);
      }, 300),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const isAssigningToOrder = (shipment: ShipmentValue) => {
    return !shipment.split_from_order_id;
  };

  const getDrawerTitle = (shipment: ShipmentValue, isAddNewHouse: boolean) => {
    if (isAddNewHouse) return 'Select Load To Create Consignment Note';
    return `Update And Assign Loads ${
      isAssigningToOrder(shipment) ? '' : `To Trip ${shipment.job_number}`
    }`;
  };
  return (
    <Drawer
      className="create_and_assign_load"
      forceRender
      title={getDrawerTitle(shipment, isAddNewHouse)}
      width="80%"
      onClose={onClose}
      open={drawerOpen}
      footer={
        <div className="drawer-footer" style={{ display: 'flex', alignItems: 'center' }}>
          {shipment.split_from_order_id && !isAddNewHouse && (
            <Checkbox
              checked={isAutoGenerateLR}
              style={{ marginRight: '16px' }}
              onChange={(e: CheckboxChangeEvent) => setIsAutoGenerateLR(e.target.checked)}
            >
              Auto Generate Consignment Note
            </Checkbox>
          )}
          <Button disabled={assignLoadLoading || containerPickupLoading} onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="primary"
            loading={assignLoadLoading || containerPickupLoading}
            style={{ marginLeft: '16px' }}
            htmlType={'submit'}
            key="save"
            onClick={handleAssignLoads}
          >
            {isAddNewHouse ? 'Create Consignment Note' : 'Allocate'}
          </Button>
        </div>
      }
    >
      <Input
        placeholder="Filter..."
        type="text"
        style={{ width: '200px', marginBottom: '10px' }}
        onChange={handleSearch}
      />
      <Flex>
        {shipment.isRoadCustomerOrder() && !selectedIds && (
          <Checkbox
            style={{ marginBottom: '9px' }}
            checked={isMatchingSelected}
            onChange={onMatchOrderCheckboxChange}
          >
            Show matching loads from other open orders of same customer
          </Checkbox>
        )}
      </Flex>

      {cargoDataLoading || sameCustomerLoadsLoading || houseLoadsLoading ? (
        <Skeleton paragraph={{ rows: 3 }} active></Skeleton>
      ) : (
        <>
          {shipment.isFcl() ? (
            <CreateAndAssignContainerLoad
              containers={containers}
              tripOrderNo={tripOrderNo}
              gridRef={gridRef}
              setContainers={setContainers}
              isOrderAssignment={isAssigningToOrder(shipment)}
              isAddNewHouse={isAddNewHouse}
              lrSuggestions={lrSuggestions}
              isAutoGenerateLR={isAutoGenerateLR}
              isMatchingSelected={isMatchingSelected}
            />
          ) : (
            <CargoSelection
              cargos={cargos}
              gridRef={gridRef}
              loadType={shipment?.load_type || ''}
              onlyVehicleInput={true}
              tripOrderNo={tripOrderNo}
              isMatchingSelected={isMatchingSelected}
              setCargos={setCargos}
              freightType={shipment.freight_type || ''}
              isOrderAssignment={isAssigningToOrder(shipment)}
              isAddNewHouse={isAddNewHouse}
              lrSuggestions={lrSuggestions}
              isAutoGenerateLR={isAutoGenerateLR}
            />
          )}
        </>
      )}
    </Drawer>
  );
}

export default CreateAndAssignLoad;
