import React, { useState } from 'react';
import { Badge } from '@shipmnts/pixel-hub';
import { ShipmentValue } from 'operations/models/Shipment';
import { startCase } from 'lodash';
import ShipmentFinanceStatusTimeLine from './ShipmentFinanceStatusTimeLine';
import { CustomIcon } from '@shipmnts/pixel-hub';
type Props = {
  shipment: ShipmentValue;
};

function FinanceStatusWithTracker({ shipment }: Props) {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <div>
      <div>Finance</div>
      <div style={{ display: 'flex' }}>
        <div style={{ fontSize: '12px', color: '#cc933e' }}>
          <Badge color={'#cc933e'} /> {startCase(shipment.accounting_status || '')}{' '}
        </div>{' '}
        <span style={{ marginLeft: '2px' }}>
          <CustomIcon icon="TrackerIcon" onClick={() => setCollapsed(!collapsed)} />
        </span>
      </div>

      {!collapsed && (
        <ShipmentFinanceStatusTimeLine
          shipment={shipment}
          onClose={() => setCollapsed(!collapsed)}
        />
      )}
    </div>
  );
}

export default FinanceStatusWithTracker;
