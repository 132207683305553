import { gql } from '@apollo/client';

export const FIND_DOCUMENT_SIGNATURE = gql`
  query find_document_signature($branch_account_id: ID!, $document_type: String!) {
    find_document_signature(branch_account_id: $branch_account_id, document_type: $document_type) {
      id
      signature
    }
  }
`;
