import React, { useMemo } from 'react';
import { Row, Col, Radio, Select, Form, DatePicker } from '@shipmnts/pixel-hub';
import {
  SHIPMENT_TYPE_HOUSE,
  SHIPMENT_TYPE_CONSOL,
  CONSOLIDATION_TYPES,
  SELF_CONSOLIDATION_KEY,
  BOOKING_THROUGH_TYPES,
  BUSINESS_TYPE_SUBAGENT,
  BOOKING_THROUGH_OVERSEAS_AGENT,
  ROW_GUTTER,
  TRADE_TYPES,
} from 'operations/modules/shipment/constants';
import { AddressCompanySearch } from 'common';
import { RadioOptionProps } from 'operations/commonTypeDefs';
import { useSession } from 'common';
import { SessionDataValue } from 'operations/models/SessionData';
// import { SalesPerson } from 'common';
import { FormInstance } from '@shipmnts/pixel-hub';
import { GlobalSearch } from '@shipmnts/pixel-hub';

const getConsolidationOptions = (sessionData: SessionDataValue) => {
  if (sessionData.company_account)
    return [
      ...CONSOLIDATION_TYPES,
      {
        key: SELF_CONSOLIDATION_KEY,
        name: `${sessionData.company_account.display_name}'s Consolidation`,
      },
    ];
  return CONSOLIDATION_TYPES;
};
const colSpan = 12;

const MessageShipmentBasicDetails = React.memo(function MessageShipmentBasicDetails(props: {
  shipmentType: string;
  consolType?: string | null;
  form?: FormInstance;
}): JSX.Element {
  const { shipmentType, consolType, form } = props;
  const sessionData = useSession();
  const consolidation_types = useMemo(() => getConsolidationOptions(sessionData), [sessionData]);
  return (
    <>
      {shipmentType !== SHIPMENT_TYPE_HOUSE && (
        <Row gutter={ROW_GUTTER}>
          <Col span={colSpan}>
            <Form.Item required rules={[{ required: true }]} name="trade_type" label="Trade Type">
              <Radio.Group>
                {TRADE_TYPES.map((option: RadioOptionProps, index: number) => (
                  <Radio key={index} value={option.key}>
                    {option.name}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item required rules={[{ required: true }]} name="job_date" label="Job Date">
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row gutter={ROW_GUTTER}>
        {shipmentType === SHIPMENT_TYPE_CONSOL && (
          <Col span={colSpan}>
            <Form.Item
              required
              rules={[{ required: true }]}
              label="Consolidation Type"
              name="consol_type"
            >
              <Radio.Group>
                {consolidation_types.map((option: RadioOptionProps, index: number) => (
                  <Radio key={index} value={option.key}>
                    {option.name}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
          </Col>
        )}
        {shipmentType !== SHIPMENT_TYPE_HOUSE && (
          <Col span={colSpan}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.consol_type !== currentValues.consol_type
              }
            >
              {({ getFieldValue }) => {
                const consol_type = getFieldValue('consol_type');
                if (consol_type === SELF_CONSOLIDATION_KEY) return null;
                return (
                  <Form.Item
                    required
                    rules={[{ required: true }]}
                    label="Business Booked Through"
                    name="business_type"
                  >
                    <Radio.Group>
                      {BOOKING_THROUGH_TYPES.map((option: RadioOptionProps, index: number) => (
                        <Radio key={index} value={option.key}>
                          {option.name}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row gutter={ROW_GUTTER}>
        <Col span={colSpan}>
          <Form.Item
            name="customer"
            required
            rules={[{ required: true }]}
            label="Customer / Billing Party"
          >
            <AddressCompanySearch
              companySearchProps={{
                showCreditStatusTag: true,
                disabled:
                  shipmentType === SHIPMENT_TYPE_HOUSE && consolType !== SELF_CONSOLIDATION_KEY,
              }}
              addressSearchProps={{
                disabled:
                  shipmentType === SHIPMENT_TYPE_HOUSE && consolType !== SELF_CONSOLIDATION_KEY,
              }}
              customerSearchProps={{
                trigger: 'create_shipment',
              }}
              onChange={(value: any) => {
                if (
                  value?.sales_partner?.country_of_incorporation &&
                  sessionData?.company_account?.country_of_incorporation
                ) {
                  if (
                    value.sales_partner.country_of_incorporation ===
                    sessionData.company_account.country_of_incorporation
                  ) {
                    form?.setFieldValue('business_type', 'subagent');
                    form?.setFieldValue(['party', 'subagent'], {
                      party_company: value?.sales_partner,
                    });
                  } else {
                    form?.setFieldValue('business_type', 'agent_nomination');
                    form?.setFieldValue(['party', 'overseas_agent'], {
                      party_company: value?.sales_partner,
                    });
                  }
                }
              }}
            />
          </Form.Item>
        </Col>

        {shipmentType !== SHIPMENT_TYPE_HOUSE && (
          <Col span={colSpan}>
            <Form.Item
              name="involved_branch_id"
              required
              rules={[{ required: true }]}
              label="Booked By Branch"
            >
              <Select
                showSearch
                placeholder="Booked By Branch"
                filterOption={(input, option) =>
                  `${option?.children || ''}`.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {sessionData?.branch_accounts?.map((branch: { id: string; name: string }) => (
                  <Select.Option key={branch.id} value={branch.id}>
                    {branch.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}
        <Col span={colSpan}>
          <Form.Item name="sales_agent" label="Sales Person">
            <GlobalSearch doc_type="Network::SalesPerson" />
          </Form.Item>
          {shipmentType === SHIPMENT_TYPE_HOUSE && (
            <Form.Item noStyle name="job_date">
              <span />
            </Form.Item>
          )}
        </Col>
        {shipmentType !== SHIPMENT_TYPE_HOUSE && (
          <Col span={colSpan}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.business_type !== currentValues.business_type ||
                prevValues.consol_type !== currentValues.consol_type
              }
            >
              {({ getFieldValue }) => {
                const consol_type = getFieldValue('consol_type');
                const businessType = getFieldValue('business_type');
                if (
                  consol_type === SELF_CONSOLIDATION_KEY ||
                  ![BUSINESS_TYPE_SUBAGENT, BOOKING_THROUGH_OVERSEAS_AGENT].includes(businessType)
                )
                  return null;
                return (
                  <Form.Item
                    required
                    rules={[{ required: true }]}
                    name={[
                      'party',
                      `${businessType === BUSINESS_TYPE_SUBAGENT ? 'subagent' : 'overseas_agent'}`,
                    ]}
                    label={`${
                      businessType === BUSINESS_TYPE_SUBAGENT ? 'Subagent' : 'Overseas Agent'
                    }`}
                  >
                    <AddressCompanySearch />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        )}
      </Row>
    </>
  );
});

export default MessageShipmentBasicDetails;
