import {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  createContext,
  useContext,
  useCallback,
} from 'react';
import { BaseLocationHook } from 'wouter';
import { useBrowserLocation } from 'wouter/use-browser-location';

export function useInterval(callback: () => void, delay: number | null, callImmediately: boolean) {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (!delay && delay !== 0) {
      return;
    }
    if (callImmediately) savedCallback.current();
    const id = setInterval(() => savedCallback.current(), delay);

    return () => clearInterval(id);
  }, [delay, callImmediately]);
}

// similar to usestate but persists the state in localstorage so you get caching effect
export function useLocalStorage(key: string, initialValue: string | object) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.error(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: string | object) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.error(error);
    }
  };

  return [storedValue, setValue];
}

// cleanup effect... handles compnentwillunmount and window reload. pass handler which you want to be called before unmount.
export const useWindowUnloadEffect = (handler: (event?: any) => void, callOnCleanup?: boolean) => {
  const cb = useRef(handler);

  useEffect(() => {
    const handler = (event?: any) => cb.current(event);

    window.addEventListener('beforeunload', handler);

    return () => {
      if (!!callOnCleanup) handler();
      window.removeEventListener('beforeunload', handler);
    };
  }, [cb, callOnCleanup]);
};

export const usePrevious = <T extends object>(value: T): T | undefined => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<T>();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

const LockContext = createContext<{ lock: boolean; message?: string }>({
  lock: false,
});

// use this hook mark set the confirmation message on a current route
export const useRouteConfirmation = (condition: boolean, message: string) => {
  const ctx = useContext(LockContext);

  useEffect(() => {
    ctx.lock = condition;
    ctx.message = message;

    return () => {
      ctx.lock = false;
      ctx.message = undefined;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition]);
};

export const useLocationWithConfirmation: BaseLocationHook = () => {
  const [location, setLocation] = useBrowserLocation();
  const ctx = useContext(LockContext);
  const navigateWithConfirm = useCallback(
    (newLocation: any, options: any) => {
      let perfomNavigation = true;
      if (ctx.lock) {
        perfomNavigation = window.confirm(ctx.message || 'Are you sure?');
      }

      if (perfomNavigation) setLocation(newLocation, options);
    },
    [ctx.lock, ctx.message, setLocation]
  );

  return [location, navigateWithConfirm];
};
