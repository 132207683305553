/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree';

export const ContainerSetting = types.model({
  is_active_reefer: types.maybe(types.maybeNull(types.boolean)),
  temperature: types.maybe(types.maybeNull(types.number)),
  temperature_unit: types.maybe(types.maybeNull(types.string)),
  ventilation_requested: types.maybe(types.maybeNull(types.boolean)),
  air_flow: types.maybe(types.maybeNull(types.number)),
  air_flow_unit: types.maybe(types.maybeNull(types.string)),
  humidity_control_requested: types.maybe(types.maybeNull(types.boolean)),
  relative_humidity_percent: types.maybe(types.maybeNull(types.number)),
  vent_setting: types.maybe(types.maybeNull(types.string)),
  additional_instructions: types.maybe(types.maybeNull(types.string)),
  genset_requested: types.maybe(types.maybeNull(types.boolean)),
  controlled_atmosphere_requested: types.maybe(types.maybeNull(types.boolean)),
  oxygen_level_percent: types.maybe(types.maybeNull(types.number)),
  nitrogen_level_percent: types.maybe(types.maybeNull(types.number)),
  carbon_dioxide_level_percent: types.maybe(types.maybeNull(types.number)),
  length: types.maybe(types.maybeNull(types.number)),
  width: types.maybe(types.maybeNull(types.number)),
  height: types.maybe(types.maybeNull(types.number)),
  lbh_unit: types.maybe(types.maybeNull(types.string)),
});

export const containerSettingFields = [
  'is_active_reefer',
  'temperature',
  'temperature_unit',
  'humidity_control_requested',
  'relative_humidity_percent',
  'genset_requested',
];

const ContainerRequest = types.model({
  id: types.maybe(types.maybeNull(types.identifier)),
  _id: types.maybe(types.maybeNull(types.string)),
  container_type: types.maybe(types.maybeNull(types.string)),
  container_type_code: types.maybe(types.maybeNull(types.string)),
  is_shipper_owned: types.maybe(types.maybeNull(types.boolean)),
  quantity: types.maybe(types.maybeNull(types.integer)),
  quantity_fulfilled: types.maybe(types.maybeNull(types.integer)),
  quantity_unfulfilled: types.maybe(types.maybeNull(types.integer)),
  quantity_picked_up: types.maybe(types.maybeNull(types.integer)),
  quantity_origin_port_gated_in: types.maybe(types.maybeNull(types.integer)),
  quantity_loaded_on_vessel: types.maybe(types.maybeNull(types.integer)),
  quantity_offloaded: types.maybe(types.maybeNull(types.integer)),
  quantity_shutout: types.maybe(types.maybeNull(types.integer)),
  quantity_cancelled: types.maybe(types.maybeNull(types.integer)),
  weight_per_container: types.maybe(types.maybeNull(types.number)),
  weight_unit: types.maybe(types.maybeNull(types.string)),
  container_settings: types.maybe(types.maybeNull(ContainerSetting)),
});

export type ContainerSettingType =
  | 'is_active_reefer'
  | 'ventilation_requested'
  | 'humidity_control_requested'
  | 'controlled_atmosphere_requested'
  | 'genset_requested';

export type ContainerQuantityType =
  | 'quantity'
  | 'quantity_fulfilled'
  | 'quantity_unfulfilled'
  | 'quantity_picked_up'
  | 'quantity_origin_port_gated_in'
  | 'quantity_loaded_on_vessel'
  | 'quantity_shutout'
  | 'quantity_offloaded'
  | 'quantity_cancelled';

export interface ContainerRequestValue extends Instance<typeof ContainerRequest> {}
export interface ContainerRequestIn extends SnapshotIn<typeof ContainerRequest> {}
export interface ContainerRequestOut extends SnapshotOut<typeof ContainerRequest> {}

export interface ContainerSettingValue extends Instance<typeof ContainerSetting> {}
export interface ContainerSettingIn extends SnapshotIn<typeof ContainerSetting> {}
export interface ContainerSettingOut extends SnapshotOut<typeof ContainerSetting> {}

export default ContainerRequest;
