import { types, Instance, SnapshotIn, SnapshotOut, IAnyModelType } from 'mobx-state-tree';
import RoutingNode from 'operations/models/RoutingNode';
import OrderCollaboration from 'operations/models/OrderCollaboration';
import EmailActivity, { isEmailSentBefore } from 'operations/models/EmailActivity';
import Company from 'operations/models/Company';
import { DayJsDate as DayJsDateObj, convertToDayJs, Dayjs } from '@shipmnts/pixel-hub';
import { STATUS_STUFFING_ORDER_NOT_YET_CARTED_IN } from 'operations/constants';

const DayJsDate = types.custom<Dayjs, Dayjs>(DayJsDateObj);
const StuffingBufferServiceOrder = types
  .model({
    id: types.identifier,
    buffer_only: types.maybe(types.boolean),
    status: types.maybe(types.string),
    location_node: types.maybe(types.late((): IAnyModelType => RoutingNode)),
    order_collaborations: types.array(types.late((): IAnyModelType => OrderCollaboration)),
    stuffing_provider: types.maybe(types.maybeNull(types.late((): IAnyModelType => Company))),
    email_activities: types.maybe(
      types.maybeNull(types.array(types.late((): IAnyModelType => EmailActivity)))
    ),
    stuffing_cutoff_date: types.maybe(types.maybeNull(DayJsDate)),
  })
  .views((self) => ({
    get isCartedIn() {
      return self.status !== STATUS_STUFFING_ORDER_NOT_YET_CARTED_IN;
    },
    getVendorCompany(default_company_id: string) {
      return (self.order_collaborations || []).find(
        (oc) => oc?.customer_company?.id === default_company_id
      )?.vendor_company;
    },
    isEmailSentBefore(action_name: string): boolean {
      return isEmailSentBefore(self.email_activities || [], action_name);
    },
  }))
  .preProcessSnapshot(({ stuffing_cutoff_date, ...snapshot }) => ({
    ...snapshot,
    stuffing_cutoff_date: convertToDayJs(stuffing_cutoff_date),
  }));

export type StuffingBufferServiceOrderValue = Instance<typeof StuffingBufferServiceOrder>;
export type StuffingBufferServiceOrdertIn = SnapshotIn<typeof StuffingBufferServiceOrder>;
export type StuffingBufferServiceOrderOut = SnapshotOut<typeof StuffingBufferServiceOrder>;

export default StuffingBufferServiceOrder;
