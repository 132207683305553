import React, { useState, useEffect } from 'react';
import { RowNode } from '@ag-grid-community/core';
import { useApolloClient } from '@apollo/client';
import { VALIDATE_MERGE_BOOKINGS } from 'operations/modules/booking/graphql/oceanTransportOrder';
import MergeBooking from 'operations/modules/booking/components/MergeSplitBooking/MergeBooking';
import {
  ErrorMessages,
  showMergeErrorMessage,
} from 'operations/modules/reports/helpers/containerActionsHelper';
import { ExecutionResult } from 'graphql';

interface BookingsForMerge {
  booking_number: string;
  booking_id: string;
}

const OTOReportBulkActions = React.memo(function OTOReportBulkActions(props: {
  selectedNodes: RowNode[];
  onSuccess: () => void;
  selectedNodeCounts: number;
  onClose: () => void;
}): JSX.Element {
  const { selectedNodes, onSuccess, selectedNodeCounts } = props;
  const [isMergeModalVisible, setMergeModalVisible] = useState<boolean>(false);
  const [bookingsForMerge, setBookingsForMerge] = useState<BookingsForMerge[]>();
  const client = useApolloClient();

  useEffect(() => {
    const getOTOValidation = async function (booking_ids: string[]) {
      return await client.mutate({
        mutation: VALIDATE_MERGE_BOOKINGS,
        variables: { ocean_transport_order_ids: booking_ids },
      });
    };
    let mergeErrors: ErrorMessages[] = [];
    if (!selectedNodes || selectedNodes.length === 0) {
      mergeErrors.push({ messages: ['Please select bookings first'] });
    } else if (selectedNodes.length === 1) {
      mergeErrors.push({ messages: ['Please select atleast 2 bookings for merging'] });
    } else {
      const selectedCarriers: string[] = [];

      selectedNodes.forEach((node: RowNode) => {
        if (!selectedCarriers.includes(node.data.global_carrier_id))
          selectedCarriers.push(node.data.global_carrier_id);
        const booking_number = node.data.booking_number || node.data.id;

        if (node.data.status === 'Expired') {
          mergeErrors.push({
            messages: [`Booking#${booking_number} is in expired state can not be merged.`],
          });
        } else if (node.data.status === 'Requested') {
          mergeErrors.push({
            messages: [`Booking#${booking_number} is in requested state can not be merged.`],
          });
        }
      });

      if (selectedCarriers.length > 1) {
        mergeErrors.push({ messages: ['Please select bookings with same shipping line.'] });
      }

      if (mergeErrors.length === 0) {
        const booking_ids = selectedNodes.map((node) => node.data.id);
        const response = getOTOValidation(booking_ids);
        const { data, errors } = response as ExecutionResult;
        if (
          data?.validate_merge_bookings?.messages &&
          data.validate_merge_bookings.messages.length > 0 &&
          !errors
        ) {
          mergeErrors = data.validate_merge_bookings.messages;
        } else if (errors) {
          mergeErrors = [{ messages: errors.map((err: { message: string }) => err.message) }];
        }
      }
    }

    if (mergeErrors.length > 0) {
      showMergeErrorMessage(mergeErrors, 'Can Not Merge Booking');
    } else {
      const bookings: BookingsForMerge[] = selectedNodes.map((node: RowNode) => ({
        booking_number: node.data.booking_number,
        booking_id: node.data.id,
      }));
      setBookingsForMerge(bookings);
      setMergeModalVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNodeCounts, selectedNodes]);

  return (
    <>
      {isMergeModalVisible && bookingsForMerge && bookingsForMerge?.length > 0 && (
        <MergeBooking
          bookings={bookingsForMerge || []}
          setVisible={setMergeModalVisible}
          onSuccess={onSuccess}
        />
      )}
    </>
  );
});

export default OTOReportBulkActions;
