import React from 'react';
import { NEGATIVE_TYPE, PRIMARY_TYPE, SECONDARY_TYPE } from '../constants';
import { Action } from '../models';
import {
  EditOutlined,
  DisconnectOutlined,
  DeleteOutlined,
  UndoOutlined,
} from '@shipmnts/pixel-hub';
import {
  DELETE_ACTION,
  RESERVE_ACTION,
  SPLIT_ACTION,
  STOCK_ORDER_ITEM_STATUS_TYPE_BOOKED,
  STOCK_ORDER_ITEM_STATUS_TYPE_EXECUTED,
  STOCK_ORDER_ITEM_STATUS_TYPE_RESERVED,
  STOCK_ORDER_ITEM_STATUS_TYPE_UNUSED,
  STOCK_ORDER_ITEM_STATUS_TYPE_VOID,
  UNRESERVE_ACTION,
  UPDATE_ACTION,
} from 'operations/modules/stockManagement/constants';
import {
  deleteStockOrderRenderer,
  reserveStockOrderRenderer,
  splitStockOrderRenderer,
  unreserveStockOrderRenderer,
  updateStockOrderRenderer,
} from './stockOrderActionRenderer';
type actiontype = {
  key: string;
  icon: string;
};
type actionMap = {
  [key: string]: actiontype[];
};

const ActionMap: actionMap = {
  [STOCK_ORDER_ITEM_STATUS_TYPE_UNUSED]: [
    UPDATE_ACTION,
    DELETE_ACTION,
    SPLIT_ACTION,
    RESERVE_ACTION,
  ],
  [STOCK_ORDER_ITEM_STATUS_TYPE_RESERVED]: [UPDATE_ACTION, UNRESERVE_ACTION, SPLIT_ACTION],
  [STOCK_ORDER_ITEM_STATUS_TYPE_BOOKED]: [UPDATE_ACTION, SPLIT_ACTION],
  [STOCK_ORDER_ITEM_STATUS_TYPE_EXECUTED]: [UPDATE_ACTION, SPLIT_ACTION],
  [STOCK_ORDER_ITEM_STATUS_TYPE_VOID]: [SPLIT_ACTION],
};

export const updateStockOrder = (status: string): Action => {
  return {
    key: 'update_stock_order',
    type: SECONDARY_TYPE,
    displayComponent: 'Update',
    icon: <EditOutlined />,
    performAction: updateStockOrderRenderer,
    isEnable: ActionMap[status].includes(UPDATE_ACTION),
  };
};

export const spiltStockOrder = (status: string): Action => {
  return {
    key: 'split_stock_order',
    type: SECONDARY_TYPE,
    displayComponent: 'Split',
    icon: <DisconnectOutlined />,
    performAction: splitStockOrderRenderer,
    isEnable: ActionMap[status].includes(SPLIT_ACTION),
  };
};

export const deleteStockOrder = (status: string): Action => {
  return {
    key: 'delete_stock_order',
    type: NEGATIVE_TYPE,
    displayComponent: 'Delete',
    icon: <DeleteOutlined />,
    performAction: deleteStockOrderRenderer,
    isEnable: ActionMap[status].includes(DELETE_ACTION),
  };
};

export const unreserveStockOrder = (status: string): Action => {
  return {
    key: 'unreserve_stock_order',
    type: NEGATIVE_TYPE,
    displayComponent: 'Unreserve',
    icon: <UndoOutlined />,
    performAction: unreserveStockOrderRenderer,
    isEnable: ActionMap[status].includes(UNRESERVE_ACTION),
  };
};

// TODO: link to create customer order
// export const bookingStockOrder = (status: string): Action => {
//   return {
//     key: 'booking_stock_order',
//     type: NEGATIVE_TYPE,
//     displayComponent: 'create booking',
//     icon: <UndoOutlined />,
//     performAction: unreserveStockOrderRenderer,
//     isEnable: ActionMap[status].includes(UNRESERVE_ACTION),
//   };
// };

export const reserveStockOrder = (status: string): Action => {
  return {
    key: 'reserve_stock_order',
    type: PRIMARY_TYPE,
    displayComponent: 'Reserve Awb Stock',
    icon: <UndoOutlined />,
    performAction: reserveStockOrderRenderer,
    isEnable: ActionMap[status].includes(RESERVE_ACTION),
  };
};

export const getStockOrderActions = (status: string) => {
  return [
    updateStockOrder(status),
    spiltStockOrder(status),
    deleteStockOrder(status),
    unreserveStockOrder(status),
    reserveStockOrder(status),
  ];
};
