import React from 'react';

import { Avatar, Typography } from '@shipmnts/pixel-hub';

import { CompanyValue } from 'common/models/Company';

const { Text } = Typography;

type highlightType = { registered_name?: string };

export interface CompanyListItemProps {
  company: CompanyValue;
  highlight?: highlightType;
}

const CompanyListItem = React.memo(function CompanyListItem(
  props: CompanyListItemProps
): JSX.Element {
  const { company, highlight } = props;
  const showName = highlight ? (
    <div dangerouslySetInnerHTML={{ __html: highlight.registered_name || '' }} />
  ) : (
    company.registered_name
  );
  return (
    <div style={{ display: 'flex' }}>
      <Avatar shape="square" size="large" src={company.logo || undefined}>
        {company?.registered_name?.[0].toUpperCase()}
      </Avatar>
      <div style={{ marginLeft: '10px' }}>
        <Text>{showName}</Text>
        <div>
          <Text
            type="secondary"
            style={{
              maxWidth: '152px',
            }}
          >
            {company.company_group}
          </Text>
          <Text type="secondary"> - {company.country_of_incorporation}</Text>
        </div>
      </div>
    </div>
  );
});

export default CompanyListItem;
