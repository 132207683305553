// Company Stage Constants
export const WON_CUSTOMER = 'won_customer';
export const WON_SUPPLIER = 'won_supplier';
export const WON_AGENT = 'won_agent';
export const LEAD_STAGE = 'lead';
export const LOST_LEAD = 'lost';
export const CHURNED_CUSTOMER = 'churned';
export const ACTIVE_CUSTOMER = 'active_customer';

// Company Type Constants
export const CUSTOMER = 'Customer';
export const SUPPLIER = 'Supplier';
export const AGENT = 'Agent';
export const CONTACT = 'Contact';
export const LEAD = 'Lead';
