/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree';

const Driver = types.model({
  id: types.identifier,
  driver_name: types.maybe(types.maybeNull(types.string)),
  driver_contact_number: types.maybe(types.maybeNull(types.string)),
  driver_license_number: types.maybe(types.maybeNull(types.string)),
});

export interface DriverValue extends Instance<typeof Driver> {}
export interface DriverIn extends SnapshotIn<typeof Driver> {}
export interface DriverOut extends SnapshotOut<typeof Driver> {}

export default Driver;
