/* eslint-disable prefer-const */
import { message } from '@shipmnts/pixel-hub';
import { get as _get, find as _find } from 'lodash';
import { ApolloClient } from '@apollo/client';
import { fetchDocument, Document } from 'operations/apis/document';
import {
  SHIPMENT,
  HOUSE_SHIPMENTS,
  GENERATE_DELIVERY_ORDER_NUMBER,
} from 'operations/graphql/shipment';
import { FETCH_SHIPMENT_CONTAINERS } from 'operations/modules/booking/graphql/shipmentContainer';
import { FETCH_SHIPMENT_ESTIMATES } from 'operations/graphql/shipmentEstimate';
import { FIND_DOCUMENT_SIGNATURE } from 'operations/graphql/documentSignature';
import { ShipmentValue } from 'operations/models/Shipment';
import { ShipmentContainerValue } from 'operations/models/ShipmentContainer';
import { ShipmentDocumentValue } from 'operations/models/ShipmentDocument';
import { SessionDataValue } from 'operations/models/SessionData';
import {
  DOCUMENT_TYPE_MASTER,
  DOCUMENT_TYPE_HOUSE,
  FREIGHT_TYPE_OCEAN,
  FREIGHT_TYPE_ROAD,
  DocumentType,
} from 'operations/modules/shipment/constants';
import Shipment from 'operations/models/Shipment';
import TemplateGenerator from './renderTemplate';
import { getDocumentJson } from './documentJsonHelper';
import { safelyResolveDocumentField } from './resolveDocumentField';
import {
  DOCUMENT_STATUS_DRAFT,
  DOCUMENT_STATUS_DRAFT_APPROVED,
} from 'operations/modules/reports/constants';
import {
  DOCUMENT_TYPE_NEW_MAWB,
  DOCUMENT_TYPE_NEW_HAWB,
  DOCUMENT_TYPE_HBL,
  DOCUMENT_TYPE_MBL,
} from 'operations/constants';
import { shipmentDocumentQuery } from 'operations/graphql/shipmentDocument';
import { ApolloError } from '@apollo/client/errors';
import { CompanyAccountValue } from 'operations/models/CompanyAccount';
import { CompanyValue } from 'operations/models/Company';
import { ShipmentEstimateValue } from 'operations/models/ShipmentEstimate';
import { errorMessageHandler, errorMessageHandlerGraphQLString } from 'common';
import { TemplateValue } from 'operations/models/Template';
import { GET_HTML_FROM_TEMPLATE } from 'src/graphQL/template';
import { BranchAccountValue } from 'operations/models/BranchAccount';

interface SourceDataProps {
  document_id: string;
  client: ApolloClient<object>;
  sessionData: SessionDataValue;
  config_data?: any;
  letterHead?: TemplateValue;
}

interface SetDataOrErrorProps {
  apiCall: (payload: any) => { [key: string]: any };
  payload: unknown;
  errorKey?: string;
  dataKey: string;
  errorHandler?: (
    error: ApolloError | ApolloError[] | string,
    defaultErrorMessage?: string
  ) => string;
}

interface PrintOption {
  sections_to_print: string[];
}
export interface SourceData {
  document?: Document;
  shipment?: ShipmentValue;
  master_shipment?: ShipmentValue;
  shipment_containers?: ShipmentContainerValue[];
  shipment_document?: ShipmentDocumentValue;
  house_shipments?: ShipmentValue[];
  shipment_estimates?: { [key: string]: ShipmentEstimateValue[] };
  default_company?: CompanyValue | null;
  current_company?: CompanyAccountValue | null;
  user?: SessionDataValue;
  printOptions?: PrintOption;
  containersByShipment?: Record<string, number>;
}

export type SourceDataType = {
  document: Document;
  shipment: any;
  shipment_document: any;
  shipment_containers: any;
  master_shipment: any;
  house_shipments: any;
  shipment_estimates: any;
  document_signature: any;
  shipment_document_events: any;
  fetchError: any;
  user: any;
  default_company: any;
  getHTML: () => string | undefined;
};

const resolveDependency = async (
  dependencies: string[],
  field_name: string,
  resolver: Promise<any>
) => {
  if (dependencies.includes(field_name)) return await resolver;
  return null;
};

const resolveDependencyAsync = (
  dependencies: string[],
  field_name: string,
  resolver: Promise<any>
) => {
  if (dependencies.includes(field_name)) return resolver;
  return null;
};

export const generateDeliveryOrderNumber = async (props: {
  shipment_id: string;
  client: ApolloClient<object>;
}) => {
  const { errors } = await props.client.mutate({
    mutation: GENERATE_DELIVERY_ORDER_NUMBER,
    variables: { shipment_id: props.shipment_id },
  });
  if (errors) {
    console.error(errors);
    return { error: true };
  }
  return {
    success: true,
    errors: errors,
  };
};

const getShipment = async (props: { shipment_id: string; client: ApolloClient<object> }) => {
  const { data, errors } = await props?.client.query({
    query: SHIPMENT,
    variables: { id: props?.shipment_id },
  });
  if (errors) console.error(errors);
  return {
    shipment: data.shipment ? Shipment.create(data.shipment) : {},
    errors: errors,
  };
};

const fetchHouseShipments = async (props: {
  master_shipment_id: string;
  client: ApolloClient<object>;
}) => {
  const { data, errors } = await props.client.query({
    query: HOUSE_SHIPMENTS,
    variables: { master_shipment_id: props.master_shipment_id },
  });
  if (errors) console.error(errors);

  return { house_shipments: data?.shipments, errors };
};

export const fetchContainers = async (props: {
  shipment_ids: string[];
  client: ApolloClient<object>;
}) => {
  const { data, errors } = await props.client.query({
    query: FETCH_SHIPMENT_CONTAINERS,
    variables: { shipment_ids: props.shipment_ids },
    fetchPolicy: 'network-only',
  });
  if (errors) console.error(errors);
  return { containers: data?.fetch_shipment_containers, errors };
};

export const fetchShipmentEstimates = async (props: {
  shipment_ids: string[];
  client: ApolloClient<object>;
}) => {
  const { data, errors } = await props.client.query({
    query: FETCH_SHIPMENT_ESTIMATES,
    variables: { shipment_ids: props.shipment_ids },
    fetchPolicy: 'network-only',
  });
  if (errors) console.error(errors);
  return { shipment_estimates: data?.fetch_shipment_estimate_list, errors };
};

const findDocumentSignature = async (props: {
  branch_account_id: string;
  document_type: string;
  client: ApolloClient<object>;
}) => {
  const { branch_account_id, document_type, client } = props;
  const { data, errors } = await client.query({
    query: FIND_DOCUMENT_SIGNATURE,
    variables: { branch_account_id, document_type },
  });
  if (errors) console.error(errors);
  return { document_signature: data?.find_document_signature, errors };
};

const findShipmentDocument = async (props: { id: string; client: ApolloClient<object> }) => {
  const { id, client } = props;
  const { data, errors } = await client.query({
    query: shipmentDocumentQuery,
    variables: { id },
  });
  if (errors) {
    errors.map((error: any) => message.error(error.message));
  }
  return { shipment_document_events: data?.shipment_document.shipment_document_events, errors };
};

const getLetterHeadHTML = async (props: {
  resource_id: string;
  template_id: string;
  client: ApolloClient<object>;
  involved_branch: BranchAccountValue;
}) => {
  const { resource_id, template_id, client, involved_branch } = props;
  const { data, errors } = await client.query({
    query: GET_HTML_FROM_TEMPLATE,
    variables: {
      resource_id: resource_id,
      template_id: template_id,
      context: JSON.stringify({
        print_address: involved_branch?.print_address,
        tax_registration_number: involved_branch?.tax_registration_number,
      }),
    },
  });
  if (errors) {
    errors.map((error: any) => message.error(error.message));
  }
  return { letter_head: data?.get_html_from_template.message, errors };
};

const getShipmentNumber = (shipment: ShipmentValue, shipmentDocumentType: DocumentType) => {
  const shipment_document = _find(_get(shipment, 'shipment_documents', []), {
    document_type: shipmentDocumentType,
  });
  return _get(shipment_document, 'shipment_number');
};

export const getDocumentTitle = (document: Document, shipment: ShipmentValue) => {
  switch (document.document_type) {
    case DOCUMENT_TYPE_NEW_MAWB:
      const shipment_number = getShipmentNumber(shipment, DOCUMENT_TYPE_MASTER);
      const awb_number = shipment_number
        ? `${shipment_number.substr(0, 3)}-${shipment_number.substr(3)}`
        : shipment_number;
      return awb_number || `${shipment.job_number}-${document.document_name}`;
    case DOCUMENT_TYPE_MBL:
      return (
        getShipmentNumber(shipment, DOCUMENT_TYPE_MASTER) ||
        `${shipment.job_number}-${document.document_name}`
      );
    case DOCUMENT_TYPE_NEW_HAWB:
    case DOCUMENT_TYPE_HBL:
      return (
        getShipmentNumber(shipment, DOCUMENT_TYPE_HOUSE) ||
        `${shipment.job_number}-${document.document_name}`
      );
    default:
      return shipment.job_number + ' | ' + document.document_name;
  }
};

export const fetchSourceData = async (props: SourceDataProps) => {
  const { document_id, client, sessionData, config_data, letterHead = undefined } = props;

  let fetchError: boolean | string = false,
    document: Document,
    shipment,
    shipment_document,
    shipment_containers,
    master_shipment,
    house_shipments,
    shipment_estimates,
    document_signature,
    shipment_document_events,
    letter_head;

  const setDataOrError = async (props: SetDataOrErrorProps) => {
    const {
      apiCall,
      payload,
      errorKey = 'errors',
      dataKey,
      errorHandler = errorMessageHandlerGraphQLString,
    } = props;
    const response = await apiCall(payload);
    if (response?.[errorKey]) fetchError = errorHandler(response?.[errorKey]);
    return _get(response, dataKey);
  };

  const { response, error } = await fetchDocument(document_id);
  if (error) {
    const resolvedError = errorMessageHandler(error as string);
    message.error(resolvedError);
    return { fetchError: resolvedError };
  }
  document = response?.data?.generate_document;
  const dependencies = document?.dependencies;

  shipment = await resolveDependency(
    dependencies,
    'shipment',
    setDataOrError({
      apiCall: (payload) => getShipment(payload),
      payload: { shipment_id: document.shipment_id, client },
      dataKey: 'shipment',
    })
  );

  const parallelCalls: any[] = [],
    parallelResponse: any[] = [];

  if (
    shipment &&
    (shipment.freight_type === FREIGHT_TYPE_OCEAN || shipment.freight_type === FREIGHT_TYPE_ROAD)
  ) {
    parallelCalls.push(
      resolveDependencyAsync(
        dependencies,
        'shipment_containers',
        setDataOrError({
          apiCall: (payload) => fetchContainers(payload),
          payload: { shipment_ids: [shipment.id], client },
          dataKey: 'containers',
        })
      )
    );
    parallelResponse.push('shipment_containers');
  }

  if (shipment && shipment.master_shipment_id) {
    parallelCalls.push(
      resolveDependencyAsync(
        dependencies,
        'master_shipment',
        setDataOrError({
          apiCall: (payload) => getShipment(payload),
          payload: { shipment_id: shipment.master_shipment_id, client },
          dataKey: 'shipment',
        })
      )
    );
    parallelResponse.push('master_shipment');
  }

  if (shipment && !shipment.master_shipment_id) {
    parallelCalls.push(
      resolveDependencyAsync(
        dependencies,
        'house_shipments',
        setDataOrError({
          apiCall: fetchHouseShipments,
          payload: { master_shipment_id: shipment.id, client },
          dataKey: 'house_shipments',
        })
      )
    );
    parallelResponse.push('house_shipments');
  }

  if (shipment) {
    parallelCalls.push(
      resolveDependencyAsync(
        dependencies,
        'shipment_estimates',
        setDataOrError({
          apiCall: fetchShipmentEstimates,
          payload: {
            shipment_ids: [
              shipment.id,
              ...(shipment.house_shipments || []).map((sh: ShipmentValue) => sh.id),
            ],
            client,
          },
          dataKey: 'shipment_estimates',
        })
      )
    );
    parallelResponse.push('shipment_estimates');

    parallelCalls.push(
      resolveDependencyAsync(
        dependencies,
        'document_signature',
        setDataOrError({
          apiCall: findDocumentSignature,
          payload: {
            branch_account_id: shipment?.involved_branch?.id,
            document_type: document?.document_type,
            client,
          },
          dataKey: 'document_signature',
        })
      )
    );
    parallelResponse.push('document_signature');

    if (document.shipment_document_id) {
      parallelCalls.push(
        resolveDependencyAsync(
          dependencies,
          'shipment_document_events',
          setDataOrError({
            apiCall: findShipmentDocument,
            payload: { id: document.shipment_document_id, client },
            dataKey: 'shipment_document_events',
          })
        )
      );
      parallelResponse.push('shipment_document_events');
    }

    if (letterHead) {
      parallelCalls.push(
        resolveDependencyAsync(
          dependencies,
          'letter_head',
          setDataOrError({
            apiCall: getLetterHeadHTML,
            payload: {
              resource_id: sessionData?.company_account?.id,
              template_id: letterHead?.id,
              client,
              involved_branch: shipment?.involved_branch,
            },
            dataKey: 'letter_head',
          })
        )
      );
      parallelResponse.push('letter_head');
    }
  }
  const result = await Promise.all(parallelCalls);

  shipment_containers = result?.[parallelResponse.indexOf('shipment_containers')];
  master_shipment = result?.[parallelResponse.indexOf('master_shipment')] || shipment;
  house_shipments = result?.[parallelResponse.indexOf('house_shipments')];
  shipment_estimates = result?.[parallelResponse.indexOf('shipment_estimates')];
  document_signature = result?.[parallelResponse.indexOf('document_signature')];
  shipment_document_events = result?.[parallelResponse.indexOf('shipment_document_events')];
  letter_head = result?.[parallelResponse.indexOf('letter_head')];
  const shipmentDocumentId = document.shipment_document_id;
  shipment_document = _get(shipment, 'shipment_documents', []).find(
    (sd: ShipmentDocumentValue) => sd.id === shipmentDocumentId
  );

  const sourceData = {
    document,
    shipment,
    shipment_document,
    shipment_containers,
    master_shipment,
    house_shipments,
    shipment_estimates,
    document_signature,
    shipment_document_events,
    fetchError,
    user: sessionData,
    default_company: sessionData?.company_account?.default_company,
    field_country_translations: config_data?.field_country_translations,
    default_company_primary_business: sessionData?.company_account?.primary_business,
    letter_head,
    cargos: shipment?.cargos || [],
  };
  const getHTML = () => {
    const resolved_document_field = getDocumentJson(sourceData.document, (field_name: string) =>
      safelyResolveDocumentField({ field_name, data: sourceData })
    );
    resolved_document_field.isDraft = [
      DOCUMENT_STATUS_DRAFT,
      DOCUMENT_STATUS_DRAFT_APPROVED,
    ].includes(_get(sourceData, 'shipment_document.document_status'));

    if (!_get(document, 'html')) return;
    const finalHtml = TemplateGenerator.getFinalHtml({
      data: resolved_document_field,
      template: _get(document, 'html'),
    });
    return finalHtml;
  };

  return { ...sourceData, getHTML };
};
