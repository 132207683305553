import { types, Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree';
// import { PortObject } from './Shipment';
const PortObject = types.model({
  id: types.identifier,
  type: types.maybe(types.maybeNull(types.string)),
  name: types.maybe(types.maybeNull(types.string)),
  unlocode: types.maybe(types.maybeNull(types.string)),
  latlong: types.maybe(types.maybeNull(types.string)),
  city: types.maybe(
    types.maybeNull(
      types.model({
        name: types.maybe(types.maybeNull(types.string)),
      })
    )
  ),
  country: types.maybe(
    types.maybeNull(
      types.model({
        code: types.maybe(types.maybeNull(types.string)),
        name: types.maybe(types.maybeNull(types.string)),
      })
    )
  ),
  city_code: types.maybe(types.maybeNull(types.string)),
  state: types.maybe(types.maybeNull(types.string)),
  state_code: types.maybe(types.maybeNull(types.string)),
  country_code: types.maybe(types.maybeNull(types.string)),
  iata_code: types.maybe(types.maybeNull(types.string)),
  address: types.maybe(types.maybeNull(types.string)),
  is_customs_location: types.maybe(types.maybeNull(types.boolean)),
  customs_code: types.maybe(types.maybeNull(types.string)),
  city_id: types.maybe(types.maybeNull(types.string)),
  parent_id: types.maybe(types.maybeNull(types.string)),
});

const TrackingEvent = types.model({
  id: types.identifier,
  name: types.string,
  actual_date: types.maybe(types.maybeNull(types.number)),
  estimated_date: types.maybe(types.maybeNull(types.number)),
  event_data: types.maybe(types.maybeNull(types.map(types.frozen()))),
  location: types.maybe(types.maybeNull(PortObject)),
  sequence_number: types.maybe(types.maybeNull(types.number)),
});

export type TrackingEventValue = Instance<typeof TrackingEvent>;
export type TrackingEventIn = SnapshotIn<typeof TrackingEvent>;
export type TrackingEventOut = SnapshotOut<typeof TrackingEvent>;

export default TrackingEvent;
