import { hasPermission } from '@shipmnts/pixel-hub';
import {
  TYPE_ALL_COMPANIES,
  TYPE_CUSTOMERS,
  TYPE_VENDORS,
  TYPE_ADDRESS_BOOK,
  keyLabelMapping,
} from './constants';
import { PermissionValue } from 'common/models/SessionData';

export const STOCK_MANAGEMENT_FEATURE = 'stock_management';

// Network permissions
export const PERMISSION_CUSTOMER_LIST = 'customer_list';
export const PERMISSION_CUSTOMER_VIEW = 'customer_view';
export const PERMISSION_CUSTOMER_CREATE_EDIT = 'customer_edit';

export const PERMISSION_SUPPLIER_LIST = 'supplier_list';
export const PERMISSION_SUPPLIER_VIEW = 'supplier_view';
export const PERMISSION_SUPPLIER_CREATE_EDIT = 'supplier_edit';

export const PERMISSION_CREDIT_CONTROL_READ = 'Credit Control Read';
export const PERMISSION_CREDIT_CONTROL_WRITE = 'credit_control';

export const PERMISSION_DISABLE_COMPANY = 'disable';
export const PERMISSION_MERGE_RENAME_COMPANY = 'merge';
export const PERMISSION_ISSUE_DO_WITH_BRO = 'issue_do_with_bro';

export const getNetworkPermittedLinks = (userPermissions: Array<PermissionValue>) => {
  const network_links = [];
  const network_base = '/report/company';
  if (
    hasPermission(userPermissions, {
      AND: [
        { name: PERMISSION_CUSTOMER_LIST, docType: 'Network::Company' },
        { name: PERMISSION_SUPPLIER_LIST, docType: 'Network::Company' },
      ],
    })
  )
    network_links.push({
      link: `${network_base}/${TYPE_ALL_COMPANIES}`,
      title: keyLabelMapping[TYPE_ALL_COMPANIES],
    });
  if (
    hasPermission(userPermissions, { name: PERMISSION_CUSTOMER_LIST, docType: 'Network::Company' })
  )
    network_links.push({
      link: `${network_base}/${TYPE_CUSTOMERS}`,
      title: keyLabelMapping[TYPE_CUSTOMERS],
    });

  if (
    hasPermission(userPermissions, { name: PERMISSION_SUPPLIER_LIST, docType: 'Network::Company' })
  )
    network_links.push({
      link: `${network_base}/${TYPE_VENDORS}`,
      title: keyLabelMapping[TYPE_VENDORS],
    });
  network_links.push({
    link: `${network_base}/${TYPE_ADDRESS_BOOK}`,
    title: keyLabelMapping[TYPE_ADDRESS_BOOK],
  });
  return network_links;
};

export const PERMISSION_SALES_PERSON_EDIT = 'edit';
export const PERMISSION_SALES_PERSON_DISABLE = 'disable';
export const PERMISSION_SALES_PERSON_VIEW = 'view';
