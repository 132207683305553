import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useMutation } from '@apollo/client';
import { Card, Col, Input, message, Drawer, Form, Row } from '@shipmnts/pixel-hub';
import BookingOrderConfirmation from 'operations/modules/booking/components/BookingOrderForm/BookingOrderConfirmation';
import { DrawerFooter } from '@shipmnts/pixel-hub';
import { UPDATE_OCEAN_TRANSPORT_ORDER } from 'operations/modules/booking/graphql/oceanTransportOrder';
import { convertDatesTounix, convertValuesToDayJs } from '@shipmnts/pixel-hub';
import { OceanTransportOrderValue } from 'operations/models/OceanTransportOrder';
import { errorMessageHandlerGraphQL } from 'common';

import { ROUTING_TYPE_MAIN_CARRIAGE } from 'operations/models/RoutingLeg';
import { STATUS_CONFIRMED } from 'operations/modules/reports/constants';
import { BOOKING_TYPE_SELF } from 'operations/baseConstants';
import BookingOrderNewDesign from 'operations/modules/booking/components/BookingOrderForm/BookingOrderNewDesign';
import { ROW_GUTTER } from 'operations/modules/shipment/constants';

const ExtendValidityForm = observer(function ExtendValidityForm(props: {
  oto: OceanTransportOrderValue;
  onClose: () => void;
  onSuccess?: (oto: OceanTransportOrderValue) => void;
}): JSX.Element {
  const { oto, onClose, onSuccess } = props;
  const [updateOceanTransportOrder, { data, loading: extendValidityLoading, error }] = useMutation(
    UPDATE_OCEAN_TRANSPORT_ORDER
  );
  const main_carriage_routing_legs = oto.carriageWiseRoutingLegs[ROUTING_TYPE_MAIN_CARRIAGE];

  useEffect(() => {
    if (!error && data) {
      message.success('Validity Extended Successfully !');
      onClose();
      if (onSuccess) onSuccess(data.update_ocean_transport_order);
    }
  }, [error, data, onClose, onSuccess]);

  const date_fields = [
    'valid_till_date',
    'gate_open_date',
    'si_cutoff_date',
    'gate_close_date',
    'doc_cutoff_date',
  ];

  const [form] = Form.useForm();

  return (
    <Drawer
      title={`Extend Validity`}
      width="60%"
      open={true}
      onClose={onClose}
      footer={
        <DrawerFooter
          saveText="Revalidate Booking"
          loading={extendValidityLoading}
          onClose={onClose}
          onSave={form.submit}
        />
      }
    >
      <Form
        form={form}
        name="extend_validity"
        layout="vertical"
        //FIX ME: any
        onFinish={(values: any) => {
          const { booking_number, empty_pickup_location, surveyor } = values;
          const payload = {
            id: oto.id,
            booking_number: booking_number,
            empty_pickup_location_id: empty_pickup_location?.id,
            surveyor_company_id: surveyor?.party_company?.id,
            surveyor_address_id: surveyor?.party_address?.id,
            ...convertDatesTounix(values, date_fields),
          };
          updateOceanTransportOrder({ variables: { ocean_transport_order: payload } });
        }}
        initialValues={{
          booking_number: oto.booking_number,
          empty_pickup_location: oto.empty_pickup_location,
          origin_detention_free_days: oto.origin_detention_free_days,
          origin_demurrage_free_days: oto.origin_demurrage_free_days,
          destination_detention_free_days: oto.destination_detention_free_days,
          destination_demurrage_free_days: oto.destination_demurrage_free_days,
          surveyor: {
            party_company: oto?.surveyor_company,
            party_address: oto?.surveyor_address,
          },
          ...convertValuesToDayJs(oto, date_fields),
        }}
      >
        {error && errorMessageHandlerGraphQL(error)}
        <Card title="Booking Confirmation Details" className="custom-card">
          <Row gutter={ROW_GUTTER}>
            <BookingOrderNewDesign
              bookingType={oto.booking_type}
              loadType={oto.load_type}
              areCutoffRequired={oto?.is_confirmed && oto?.isShippingLineBooking}
            />
          </Row>
        </Card>
        <Card title="Booking Cutoff Details" className="custom-card">
          <BookingOrderConfirmation
            etd={main_carriage_routing_legs?.[0].estimated_time_of_departure}
            areCutoffRequired={oto?.is_confirmed && oto?.isShippingLineBooking}
            disableCutoff={oto?.getDisabledCutoffDateMapping()}
            load_type={oto?.load_type}
            booking_type={oto?.booking_type}
          />
          {oto.booking_type !== BOOKING_TYPE_SELF && (
            <Col span={12}>
              <Form.Item
                required={oto?.status === STATUS_CONFIRMED}
                rules={[{ required: oto?.status === STATUS_CONFIRMED }]}
                name="booking_number"
                label="Booking number"
              >
                <Input placeholder="Booking Number" />
              </Form.Item>
            </Col>
          )}
        </Card>
      </Form>
    </Drawer>
  );
});

export default ExtendValidityForm;
