import React, { useState, useEffect, useCallback } from 'react';
import VoyageScheduleFormDrawer from 'operations/modules/booking/components/BookingSchedules/VoyageScheduleFormDrawer';
import { Button, Col, Input, PlusOutlined, Row } from '@shipmnts/pixel-hub';
import { VoyageScheduleInitialValue, VoyageScheduleValue } from 'operations/models/VoyageSchedule';
import VoyageScheduleSelectionDrawer, { SearchPropsType } from './VoyageScheduleSelectionDrawer';

const VoyageScheduleInput = React.memo(function VoyageScheduleSearch(props: {
  value?: string;
  onChange?: (value: any) => void;
  disabled?: boolean;
  showEditVoyageSchedule?: boolean;
  initialVoyageScheduleFormData: VoyageScheduleInitialValue;
  voyageScheduleId?: string;
  onEditCloseDrawer?: () => void;
  onEditSuccess?: () => void;
  onVoyageChange?: (
    value: VoyageScheduleValue | undefined | null,
    isPartialMatch?: boolean
  ) => void;
  searchProps: SearchPropsType;
}): JSX.Element {
  const {
    value,
    disabled,
    initialVoyageScheduleFormData,
    showEditVoyageSchedule,
    onChange,
    onEditCloseDrawer,
    voyageScheduleId,
    onVoyageChange,
    searchProps,
  } = props;
  const [visibleDrawer, setVisibleDrawer] = useState<'create' | 'edit' | false>(false);
  const [_voyageScheduleId, setVoyageScheduleId] = useState(voyageScheduleId);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    setVisibleDrawer(Boolean(showEditVoyageSchedule) ? 'edit' : false);
  }, [showEditVoyageSchedule]);

  useEffect(() => {
    setVoyageScheduleId(voyageScheduleId);
  }, [voyageScheduleId]);

  const onSuccess = useCallback(
    (value: VoyageScheduleValue, type: string) => {
      onEditCloseDrawer && onEditCloseDrawer();
      onChange && onChange(value?.voyage_number);
      onVoyageChange && onVoyageChange(value);
      setVoyageScheduleId(value?.id);
    },
    [onChange, onEditCloseDrawer, onVoyageChange]
  );

  const onDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const allowToSelectVS = searchProps.carrier.id && searchProps.location;

  return (
    <React.Fragment>
      <VoyageScheduleSelectionDrawer
        onClose={onDrawerClose}
        open={isDrawerOpen}
        onVoyageChange={onVoyageChange}
        initialVoyageScheduleFormData={initialVoyageScheduleFormData}
        searchProps={searchProps}
      />
      <Row gutter={16}>
        <Col span={12}>
          <Input
            value={value}
            onChange={(e) => {
              onChange && onChange(e.target.value);
            }}
            disabled={disabled}
            placeholder={'Enter Voyage Number'}
          />
        </Col>
        <Col span={12}>
          {allowToSelectVS && (
            <Button style={{ flex: 1 }} size="small" onClick={() => setIsDrawerOpen(true)}>
              <PlusOutlined /> Select Voyage Schedule
            </Button>
          )}
        </Col>
      </Row>
      {visibleDrawer && (
        <VoyageScheduleFormDrawer
          visible={Boolean(visibleDrawer)}
          onCloseDrawer={onEditCloseDrawer}
          initialVoyageScheduleFormData={
            !_voyageScheduleId ? initialVoyageScheduleFormData : undefined
          }
          id={visibleDrawer === 'edit' ? _voyageScheduleId : 'new'}
          onSuccess={onSuccess}
        />
      )}
    </React.Fragment>
  );
});

export default VoyageScheduleInput;
