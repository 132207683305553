import { Descriptions } from '@shipmnts/pixel-hub';
import React from 'react';

interface Item {
  label: string;
  content: string | React.JSX.Element;
  span?: number;
}
interface DescriptionItemProps {
  title: string;
  data: Item[] | [];
  style?: React.CSSProperties;
}
export default function DescriptionItem(props: DescriptionItemProps) {
  const { title, data } = props;
  const getTitle = (title: string) => {
    return (
      <div
        style={{
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '24px',
          color: '#1677ff',
        }}
      >
        {title}
      </div>
    );
  };

  return (
    <Descriptions
      title={getTitle(title || '-')}
      layout="vertical"
      column={2}
      style={{ width: '85%', ...props.style }}
    >
      {(data || []).map((value: any, key: any) => (
        <Descriptions.Item
          key={key}
          label={value.label}
          style={{ paddingBottom: '1px', lineHeight: '17px' }}
          contentStyle={{
            marginBottom: '8px',
            fontSize: '14px',
            fontWeight: '500',
            color: '#1C2B36',
          }}
          labelStyle={{
            fontSize: '14px',
            fontWeight: 'bold',
            color: '#46546D',
          }}
          span={value?.span || 1}
        >
          {value.content}
        </Descriptions.Item>
      ))}
    </Descriptions>
  );
}
