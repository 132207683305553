import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Checkbox,
  FormTable,
  DateEditor,
  StringEditor,
  FloatEditor,
  disableFutureDate,
  Dayjs,
  dayjsGenerateConfig,
} from '@shipmnts/pixel-hub';
import { RoadTransportOrderValue } from 'operations/models/RoadTransportOrder';
import {
  ShipmentContainerValue,
  validateContainerNumber,
} from 'operations/models/ShipmentContainer';
import { getContainerSetting } from 'operations/modules/reports/oldCellRenderers';
import { Column } from 'operations/models/Report';
import { compact as _compact } from 'lodash';
import { VehicleValue } from 'operations/models/Vehicle';

export interface ContainerDetailsRow extends ShipmentContainerValue {
  booking_number: string;
  pickup_date?: Dayjs;
  vehicle_details?: VehicleValue;
}

const renderContainerType = (record: ContainerDetailsRow) => {
  const { container_type_code, container_settings, container_type } = record;
  const containerSettings = getContainerSetting({
    container_settings,
    container_type_code,
  });
  return containerSettings ? `${container_type} (${containerSettings})` : container_type;
};

interface ContainerDetailsFormProps {
  gridRef: any;
  roadTransportOrder?: RoadTransportOrderValue;
  otoContainers: ContainerDetailsRow[];
}

const ContainerDetailsForm = observer(function ContainerDetailsForm(
  props: ContainerDetailsFormProps
): JSX.Element {
  const { gridRef, roadTransportOrder, otoContainers } = props;
  const extractWt = (val: any) => {
    if (Number.isNaN(val)) {
      return 0.0;
    }
    return val;
  };
  const getColumns = useCallback(() => {
    const columnDefs: Column[] = _compact([
      {
        headerName: 'Pickup Date',
        field: 'pickup_date',
        colId: 'pickup_date',
        // width: '12%',
        columnType: 'Date',
        cellEditor: 'DateEditor',
        editable: (o) => !o.node.isRowPinned(),
        cellEditorParams: {
          disabledDate: disableFutureDate,
        },
        valueSetter: (params) => {
          params.data.pickup_date = params.newValue?.unix();
          return true;
        },
        valueGetter: (params) => {
          if (params.node?.isRowPinned()) return '';
          if (params.data.pickup_date == null) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            params.data.pickup_date = dayjsGenerateConfig.getNow() / 1000;
          }
          return params.data.pickup_date;
        },
        filter: false,
        cellEditorPopup: true,
      },
      {
        headerName: 'Booking #',
        field: 'booking_number',
        colId: 'booking_number',
        editable: false,
        pinned: 'left',
        lockPinned: true,
        // width: '10%',
      },
      {
        headerName: 'Container Type',
        field: 'container_type',
        colId: 'container_type',
        editable: false,
        pinned: 'left',
        lockPinned: true,
        // width: '15%',
        valueGetter: (params) => {
          return renderContainerType(params.data);
        },
      },
      {
        headerName: 'Non ISO',
        field: 'is_non_iso_container',
        colId: 'is_non_iso_container',
        cellRenderer: function checkboxRenderer(params: any) {
          if (params.node.isRowPinned()) return <></>;
          return (
            <Checkbox
              onChange={(e) => {
                params.data.is_non_iso_container = e.target.checked;
                params.api.refreshCells({
                  force: true,
                  columns: ['container_number'],
                  rowNodes: [params.node],
                });
              }}
            ></Checkbox>
          );
        },
        editable: false,
      },
      {
        headerName: 'Container Number',
        field: 'container_number',
        colId: 'container_number',
        valueSetter: (params) => {
          const is_non_iso_container = params.data.is_non_iso_container;
          let _rules = null;
          let newValue = params.newValue;
          newValue = newValue?.toUpperCase();
          newValue = newValue?.replace(/[^A-Z0-9]/g, '');
          params.data.container_number = newValue;
          try {
            _rules = is_non_iso_container ? true : newValue && validateContainerNumber(newValue);
          } catch (e) {
            console.log(e);
            return false;
          }
          return _rules || true;
        },
        tooltipValueGetter: (params) => {
          const is_non_iso_container = params.data.is_non_iso_container;
          let rules = null;
          let errorDetails;
          try {
            rules = is_non_iso_container
              ? true
              : params.value && validateContainerNumber(params.value);
          } catch (e) {
            errorDetails = e;
          }
          if (params.value && !rules) {
            return errorDetails;
          }
          return '';
        },
        cellStyle: (params) => {
          const is_non_iso_container = params.data.is_non_iso_container;
          let rules = null;
          try {
            rules = is_non_iso_container
              ? true
              : params.value && validateContainerNumber(params.value);
          } catch (e) {
            console.log(e);
          }
          if (params.value && !rules) {
            return { background: '#FFCCCB' };
          }
          return null;
        },
        editable: (o) => !o.node.isRowPinned(),
      },
      {
        headerName: 'S/L Seal Number',
        field: 'carrier_seal_number',
        colId: 'carrier_seal_number',
        editable: (o) => !o.node.isRowPinned(),
      },
    ]);
    if (!roadTransportOrder) {
      columnDefs.push(
        {
          headerName: 'Gross Wt (Kgs)',
          field: 'cargo_gross_weight',
          colId: 'cargo_gross_weight',
          cellEditor: 'FloatEditor',
          columnType: 'Float',
          cellEditorParams: {
            min: 0.00001,
            step: 0.00001,
          },
          valueSetter: (params) => {
            params.data.cargo_gross_weight = Number(params.newValue);
            return true;
          },
          valueGetter: (params) => {
            return extractWt(params.data.cargo_gross_weight);
          },
          editable: (o) => !o.node.isRowPinned(),
        },
        {
          headerName: 'VGM Wt (Kgs)',
          field: 'verified_gross_mass',
          colId: 'verified_gross_mass',
          cellEditor: 'FloatEditor',
          columnType: 'Float',
          cellEditorParams: {
            min: 0.00001,
            step: 0.00001,
          },
          valueSetter: (params) => {
            params.data.verified_gross_mass = Number(params.newValue);
            return true;
          },
          valueGetter: (params) => {
            return extractWt(params.data.verified_gross_mass);
          },
          // width: '9%',
          editable: (o) => !o.node.isRowPinned(),
        },
        {
          headerName: 'Net Wt (Kgs)',
          field: 'cargo_net_weight',
          colId: 'cargo_net_weight',
          cellEditor: 'FloatEditor',
          cellEditorParams: {
            min: 0.00001,
            step: 0.00001,
          },
          columnType: 'Float',
          valueSetter: (params) => {
            params.data.cargo_net_weight = Number(params.newValue);
            return true;
          },
          valueGetter: (params) => {
            return extractWt(params.data.cargo_net_weight);
          },
          editable: (o) => !o.node.isRowPinned(),
          // width: '9%',
        }
      );
    }
    if (roadTransportOrder?.id)
      columnDefs.push(
        {
          headerName: 'Vehicle No.',
          field: 'vehicle_number',
          colId: 'vehicle_number',
          editable: (o) => !o.node.isRowPinned(),
        },
        {
          headerName: 'Driver Contact',
          field: 'driver_contact',
          colId: 'driver_contact',
          editable: (o) => !o.node.isRowPinned(),
          // width: '9%',
        }
      );
    columnDefs.push(
      {
        headerName: 'Commercial Invoice #',
        field: 'commercial_invoice_number',
        colId: 'commercial_invoice_number',
        columnType: 'String',
        editable: (o) => !o.node.isRowPinned(),
        width: 180,
      },
      {
        headerName: 'Purchase Order #',
        field: 'purchase_order_number',
        colId: 'purchase_order_number',
        width: 180,
        columnType: 'String',
        editable: (o) => !o.node.isRowPinned(),
      },
      {
        headerName: 'Remarks',
        field: 'remarks',
        colId: 'remarks',
        width: 180,
        columnType: 'String',
        editable: (o) => !o.node.isRowPinned(),
      }
    );
    return columnDefs;
  }, [roadTransportOrder]);

  const components = {
    DateEditor,
    StringEditor,
    FloatEditor,
  };
  return (
    <FormTable
      reportName="container_details_form"
      rowData={otoContainers}
      columns={getColumns()}
      gridRef={gridRef}
      reportConfig={{
        components: components,
        suppressLastEmptyLineOnPaste: true,
        rowHeight: 40,
        suppressClipboardApi: true,
        defaultColDef: {
          editable: true,
          flex: 1,
          minWidth: 100,
          resizable: true,
        },
        enableRangeSelection: true,
        stopEditingWhenCellsLoseFocus: true,
      }}
    />
  );
});

export default ContainerDetailsForm;
