import { gql } from '@apollo/client';
import { addressFields } from 'operations/graphql/shipmentEstimate';
export const companyFields = gql`
  fragment companyFields on CompanyObjectType {
    id
    status
    created_at
    updated_at
    registered_name
    country_of_incorporation
    created_by {
      id
      first_name
      last_name
    }
    company_group
    company_type
    entity_type
    logo
    is_disabled
    remarks
    company_identification_number
    tax_registration_number
    tax_deduction_id
    receivable_credit_control_type
    addresses {
      ...addressFields
    }
    company_email_preferences {
      id
      email_template_id
      address_id
      to_emails
      cc_emails
      address {
        id
        name
        city_name
        print_address
      }
      email_template {
        id
        template_name
        action_name
      }
    }
    global_company_account {
      id
      registered_name
    }
  }
  ${addressFields}
`;
export const looseCargoFreightContractItemFields = gql`
  fragment looseCargoFreightContractItemFields on LooseCargoFreightContractItemObjectType {
    id
    doc_status
    flight_number
    minimum
    normal
    port_of_destination_ids
    port_of_destination_list {
      id
      name
      iata_code
    }
    rate_type
    id
    slab_1000_plus
    slab_100_plus
    slab_2000_plus
    slab_250_plus
    slab_300_plus
    slab_45_plus
    slab_5000_plus
    slab_500_plus
  }
`;

export const uldCargoFreightContractItemFields = gql`
  fragment uldCargoFreightContractItemFields on UldCargoFreightContractItemObjectType {
    id
    doc_status
    flight_number
    port_of_destination_ids
    port_of_destination_list {
      id
      name
      iata_code
    }
    id
    uld_type
    pivot_weight
    uld_rate
    over_pivot_rate
  }
`;

export const FreightContractFields = gql`
  fragment FreightContractFields on FreightContractObjectType {
    id
    branch_ids
    carrier_product
    compliance_notes
    contract_type
    doc_status
    status
    supplier_company {
      ...companyFields
    }
    tariff_charge_currency
    tariff_includes_charge_items
    terms
    valid_from
    valid_till
    carrier {
      id
      name
      iata_carrier_code
    }
    port_of_loading {
      id
      name
      iata_code
    }
    created_by {
      id
    }
    loose_cargo_freight_contract_items {
      ...looseCargoFreightContractItemFields
    }
    uld_cargo_freight_contract_items {
      ...uldCargoFreightContractItemFields
    }
  }
  ${looseCargoFreightContractItemFields}
  ${uldCargoFreightContractItemFields}
  ${companyFields}
`;

export const UPDATE_FREIGHT_CONTRACT = gql`
  mutation updateFreightContract($id: ID!, $freight_contract: FreightContractInputType!) {
    update_freight_contract(id: $id, freight_contract: $freight_contract) {
      ...FreightContractFields
    }
  }
  ${FreightContractFields}
`;

export const FETCH_FREIGHT_CONTRACT = gql`
  query fetchFreightContract($id: ID!) {
    freight_contract(id: $id) {
      ...FreightContractFields
    }
  }
  ${FreightContractFields}
`;

export const DELETE_FREIGHT_CONTRACT = gql`
  mutation deleteFC($id: ID!) {
    delete_freight_contract(id: $id) {
      message
    }
  }
`;
