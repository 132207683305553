import React from 'react';
import { get as _get, startCase as _startCase } from 'lodash';
import { ShipmentValue } from 'operations/models/Shipment';
import { BL_TYPE_E_BL, FREIGHT_TYPE_OCEAN } from 'operations/modules/shipment/constants';
import { Popover, Tag } from '@shipmnts/pixel-hub';
import {
  SHIPMENT_STATUS_CANCELLED,
  SHIPMENT_STATUS_BACK_TO_TOWN,
  DOCUMENT_STATUS_EXECUTED,
} from 'operations/modules/reports/constants';
import {
  BL_TYPE_EXPRESS,
  FREIGHT_TYPE_AIR,
  TRADE_TYPE_IMPORT,
} from 'operations/modules/shipment/constants';
import { LABEL_JSON } from 'operations/modules/shipment/helpers/labelKeyHelper';
import ShipmentDocumentTimeline from './ShipmentDocumentTimeline';
import { ShipmentDocumentValue } from 'operations/models/ShipmentDocument';
import { useShipmentDetail } from '../ShipmentDetailLayout';
import { ICellRendererParams } from '@ag-grid-community/core';
import { FREIGHT_TYPE_ROAD } from 'operations/utils/constants';

export const getDocumentStatus = (
  document: ShipmentDocumentValue,
  shipment: ShipmentValue
): any => {
  if (!document || !shipment) return '';
  if (shipment.status === SHIPMENT_STATUS_CANCELLED) {
    return 'cancelled';
  } else if (shipment.status === SHIPMENT_STATUS_BACK_TO_TOWN) {
    return 'back_to_town';
  }
  if (shipment.trade_type === TRADE_TYPE_IMPORT && shipment.freight_type === FREIGHT_TYPE_AIR) {
    return '';
  }
  if (shipment.trade_type === TRADE_TYPE_IMPORT && shipment.freight_type === FREIGHT_TYPE_OCEAN) {
    if (document.bl_type === BL_TYPE_EXPRESS) {
      return 'seaway_bill';
    }
    if (document.bl_type === BL_TYPE_E_BL) {
      return 'e-BL';
    }
  }

  if (document.document_status === DOCUMENT_STATUS_EXECUTED) {
    if (shipment.freight_type === FREIGHT_TYPE_OCEAN || shipment.freight_type === FREIGHT_TYPE_ROAD)
      return 'released';
    else return 'departed';
  }
  return document.document_status;
};

const ShipmentDocumentTimlineRenderer = (props: ICellRendererParams) => {
  const shipment_document = props.value;
  const { shipment } = useShipmentDetail();
  if (props.node.rowPinned) return <span></span>;
  const house_shipment = (shipment?.house_shipments || []).find(
    (shp) => shp.id === shipment_document.shipment_id
  );

  if (!house_shipment) return <span />;

  return (
    <div>
      {getDocumentStatus(shipment_document, house_shipment) && (
        <Popover
          destroyTooltipOnHide
          placement="topLeft"
          content={
            <ShipmentDocumentTimeline
              shipment_id={house_shipment.id}
              shipment_document_id={shipment_document?.id}
            />
          }
          title={
            <span style={{ color: '#0a4fca', fontWeight: 'bold' }}>
              {house_shipment.freight_type &&
                _get(LABEL_JSON, ['document_name', 'house', house_shipment.freight_type])}{' '}
              Events Timeline
            </span>
          }
        >
          <Tag color="pink">{_startCase(getDocumentStatus(shipment_document, house_shipment))}</Tag>
        </Popover>
      )}
    </div>
  );
};

export const ShipmentEawbStatusRenderer = (props: ICellRendererParams) => {
  return (
    <div className={`eawb-status ${props.value}`}>
      <b>{_startCase(props.value)}</b>
    </div>
  );
};

export default ShipmentDocumentTimlineRenderer;
