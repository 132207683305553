import { useMutation } from '@apollo/client';
import { Popconfirm, TrackingAppCredits } from '@shipmnts/pixel-hub';
import { UPDATE_MANIFEST_DETAILS } from 'operations/graphql/shipmentManifest';
import { ShipmentManifestValue } from 'operations/models/ShipmentManifest';
import React, { useEffect, useState } from 'react';
import CreateUpdateManifest from './CreateUpdateManifest';
import { useSession } from 'common';

type Props = {
  isVisible: boolean;
  shipmentManifest?: ShipmentManifestValue;
  type: 'ENABLE' | 'DISABLE';
  onSuccess?: any;
};

const EnableDisableAutoTracking = (props: Props) => {
  // States
  const [isFormOpen, setIsFormOpen] = useState(false);
  const sessionData = useSession();

  // Queries and Mutations
  const [updateManifestDetail, { data, loading, error }] = useMutation(UPDATE_MANIFEST_DETAILS);

  const subscription = sessionData
    .getSubscriptions()
    .find((i: any) => i.app.name === 'IndianCustomsTracker');

  const { shipmentManifest } = props;
  const isTrackingAllowed =
    shipmentManifest?.document_number &&
    shipmentManifest?.location_type &&
    shipmentManifest?.location &&
    shipmentManifest?.estimated_time_of_arrival
      ? true
      : false;

  //  Handle on  enable tracking
  const enableTracking = () => {
    if (!isTrackingAllowed) {
      setIsFormOpen(true);
      // props.onSuccess();
    } else {
      const input = {
        id: props.shipmentManifest?.id,
        shipment_manifest: {
          tracking_app_data: {
            tracking_enabled: props.type === 'ENABLE' ? true : false,
            app_name: 'IndianCustomsTracking',
            reference_id: props.shipmentManifest?.id,
          },
        },
      };
      updateManifestDetail({
        variables: input,
      });
    }
  };

  useEffect(() => {
    if (data && !error) {
      props.onSuccess();
    }
  }, [data, error, props, props.onSuccess]);

  return (
    <>
      <Popconfirm
        style={{
          top: '520px',
          left: 'calc(100vw - 490px)',
          width: '437px',
        }}
        title={
          <>
            {props.type === 'ENABLE' ? (
              isTrackingAllowed ? (
                <>
                  {' '}
                  This is to confirm that you are enabling
                  {` IGM tracking for ${props.shipmentManifest?.document_number}`}
                  {subscription && subscription.plan_type !== 'paid' && (
                    <TrackingAppCredits subscription={subscription} />
                  )}
                </>
              ) : (
                'Please fill required details first to enable tracking (document number/ location/ ETA)'
              )
            ) : (
              `This is to confirm that you are disabling auto IGM tracking for ${props.shipmentManifest?.document_number}`
            )}
          </>
        }
        placement="left"
        onConfirm={enableTracking}
        okText={isTrackingAllowed ? 'Yes' : 'Okay'}
        cancelText={'No'}
        open={props.isVisible && !isFormOpen}
        className="enable-popup"
        okButtonProps={{ loading: loading }}
        showCancel={isTrackingAllowed}
        overlayStyle={{ width: '437px' }}
        onCancel={props.onSuccess}
      ></Popconfirm>
      <CreateUpdateManifest
        type={'UPDATE'}
        onClose={props.onSuccess}
        open={isFormOpen}
        shipmentManifest={props.shipmentManifest}
        onSuccess={props.onSuccess}
      />
    </>
  );
};

export default EnableDisableAutoTracking;
