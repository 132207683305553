/**
 * Conponent to be rendered in Container Tab in shipment view, booking order view
 * uses AgGrid to render containers
 */

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Column } from 'operations/models/Report';
import { compact as _compact } from 'lodash';
import { Drawer, Form, Layout, message } from '@shipmnts/pixel-hub';
import { DrawerFooter, FormTable } from '@shipmnts/pixel-hub';
import { Link } from 'wouter';
import { OceanTransportOrderValue } from 'operations/models/OceanTransportOrder';
import { ShipmentValue } from 'operations/models/Shipment';
// import { DECREASE_QUANTITY } from 'operations/graphql/shipment';
import { useMutation } from '@apollo/client';
import { CHANGE_BOOKING_ALLOCATION } from 'operations/graphql/shipment';

export function ChangeAllocationOfBooking(props: {
  shipment: ShipmentValue;
  oceanTransportOrders?: OceanTransportOrderValue[];
  onClose: () => void;
  onSuccess: () => void;
  disabled?: boolean;
}) {
  const { shipment, onClose, onSuccess } = props;
  const [form] = Form.useForm();
  const gridRef = useRef<any>();
  const [changeBookingAllocation, { data, error }] = useMutation(CHANGE_BOOKING_ALLOCATION);

  const [rowData, setRowData] = useState<
    {
      booking_number: any;
      booking_type: any;
      global_carrier: any;
      container_type: any;
      allocated: any;
      valid_till_date: any;
      quantity: any;
      container_request_id: any;
      oto_id: any;
    }[]
  >([]);

  useEffect(() => {
    const extractedData: {
      booking_number: any;
      booking_type: any;
      global_carrier: any;
      container_type: any;
      quantity: any;
      valid_till_date: any;
      container_request_id: any;
      oto_id: any;
    }[] = [];
    shipment?.ocean_transport_orders?.forEach((item) => {
      const {
        booking_number,
        container_requests,
        booking_type,
        global_carrier,
        valid_till_date,
        id,
      } = item;
      container_requests.forEach((request: any) => {
        extractedData.push({
          container_request_id: request.id,
          oto_id: id,
          booking_number,
          booking_type,
          global_carrier,
          valid_till_date,
          container_type: request.container_type,
          quantity: request.quantity,
        });
      });
    });

    // Step 2: Group the extracted data by BookingNumber and code_type
    const groupedData = extractedData.reduce((acc: any, curr: any) => {
      const key = `${curr.booking_number}-${curr.container_type}`;
      acc[key] = curr;
      return acc;
    }, {});
    // Step 3: Calculate the sum of quantities for each group
    const summedData = Object.keys(groupedData).map((key) => ({
      oto_id: groupedData[key].oto_id,
      container_request_id: groupedData[key].container_request_id,
      booking_number: groupedData[key].booking_number,
      booking_type: groupedData[key].booking_type,
      global_carrier: groupedData[key].global_carrier,
      container_type: groupedData[key].container_type,
      valid_till_date: groupedData[key].valid_till_date,
      allocated: `${groupedData[key].quantity} x ${groupedData[key].container_type}`,
      quantity: groupedData[key].quantity,
    }));
    setRowData(summedData);
  }, [shipment]);

  useEffect(() => {
    if (error) {
      message.error(error.message);
    } else if (data?.change_booking_allocation?.message) {
      message.success('Changed Booking Allocation Sucessfully');
      onClose && onClose();
      onSuccess && onSuccess();
    }
  }, [data, error, onClose, onSuccess]);

  // columns
  const columnDefs: Column[] = _compact([
    {
      headerName: 'Booking Number',
      field: 'booking_number',
      colId: 'booking_number',
      cellRenderer: function render({ data }: { data: OceanTransportOrderValue }) {
        return (
          <span>
            <Link to={`/view/booking_order/${data.id}`}>{data.booking_number}</Link>
          </span>
        );
      },
    },
    {
      headerName: 'Booking Type',
      field: 'booking_type',
      colId: 'booking_type',
      width: 150,
    },
    {
      headerName: 'Carrier',
      field: 'global_carrier',
      colId: 'global_carrier',
      cellRenderer: function render({ data }: { data: OceanTransportOrderValue }) {
        return data?.global_carrier?.name;
      },
    },
    {
      headerName: 'Valid Till',
      field: 'valid_till_date',
      colId: 'valid_till_date',
    },
    {
      headerName: 'Allocated Quantity',
      field: 'allocated',
      colId: 'allocated',
      pinned: 'right',
    },
    {
      headerName: 'quantity',
      field: 'quantity',
      colId: 'quantity',
      hide: true,
      pinned: 'right',
    },
    {
      headerName: 'Change Allocated',
      field: 'change_allocated',
      colId: 'change_allocated',
      cellRenderer: 'NumberTypeRenderer',
      editable: true,
      pinned: 'right',
    },
  ]);

  const closeDrawer = useCallback(() => {
    form.resetFields();
    onClose();
  }, [form, onClose]);

  return (
    <Layout style={{ height: '100%' }}>
      <Drawer title={`Edit Booking Allocation`} width={'60%'} open={true} onClose={onClose}>
        <Form
          form={form}
          layout="vertical"
          onFinish={(value) => {
            const requested_crs: {
              count: any;
              container_request_id: any;
              oto_id: any;
            }[] = [];

            gridRef.current.api.forEachNode((node: any) => {
              const rowData = node.data;
              const { oto_id, container_request_id, change_allocated, quantity } = rowData;
              if (change_allocated) {
                const count = change_allocated;
                requested_crs.push({ oto_id, container_request_id, count: count - quantity });
              }
              changeBookingAllocation({
                variables: {
                  requested_crs: requested_crs,
                },
              });
            });
          }}
        >
          <Form.Item noStyle name="container_requests">
            <FormTable
              reportName={'container_details'}
              columns={columnDefs}
              rowData={rowData || []}
              gridRef={gridRef}
              reportConfig={{
                groupDefaultExpanded: 0,
                rowHeight: 40,
                stopEditingWhenCellsLoseFocus: true,
                enableCellChangeFlash: true,
                groupDisplayType: 'groupRows',
              }}
            />
          </Form.Item>
          <DrawerFooter saveText="Submit" closeText="Cancel" onClose={closeDrawer} />
        </Form>
      </Drawer>
    </Layout>
  );
}

export default ChangeAllocationOfBooking;
