import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { startCase as _startCase } from 'lodash';
import { useMutation } from '@apollo/client';
import { Card, Table, Space, Modal, message, Form } from '@shipmnts/pixel-hub';
import { useSession } from 'common';
import { useApolloClient } from '@apollo/client';
import { BookingRequestValue } from 'operations/models/BookingRequest';
import MiscellaneousServiceOrder, {
  MiscellaneousServiceOrderValue,
} from 'operations/models/MiscellaneousServiceOrder';
import MiscellaneousServiceForm from '../BookingRequestForm/MiscellaneousServiceForm';
import { isRoot } from 'mobx-state-tree';
import { SAVE_MISCELLANEOUS_SERVICE_ORDER } from '../../graphql/miscellaneousServiceOrder';
import { ActionRenderer } from 'operations/modules/actionHelper/ActionRenderer';
import {
  addServices,
  editServiceForEachMisc,
  deleteServiceForEachMisc,
} from 'operations/modules/actionHelper/BookingRequestActions/bookingRequestActionHelper';

export const createEmptyMiscellaneousServiceOrder = (
  default_company_id: string,
  currentService: string
) => {
  return MiscellaneousServiceOrder.create({
    name: currentService,
    id: '',
    order_collaborations: [
      {
        id: '',
        customer_company: {
          id: default_company_id,
          registered_name: '',
        },
      },
    ],
  });
};

const AddMiscellaneousService = function AddMiscellaneousService(props: {
  booking_request: BookingRequestValue;
  refetchData?: () => void;
}): JSX.Element {
  const { booking_request, refetchData } = props;

  return (
    <div style={{ marginTop: '10px' }}>
      <ActionRenderer
        refetchData={refetchData}
        id={booking_request.id}
        data={booking_request}
        doc_type_id={'Shipment::BookingRequest'}
        selectedActions={[addServices(booking_request)]}
      />
    </div>
  );
};

export const MiscellaneousServiceModal = function MiscellaneousServiceModal(props: {
  miscellaneous_service_order: MiscellaneousServiceOrderValue;
  default_company_id: string;
  booking_request: BookingRequestValue;
  visible: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
}): JSX.Element {
  const {
    miscellaneous_service_order,
    default_company_id,
    booking_request,
    visible,
    onClose,
    onSuccess,
  } = props;
  const [form] = Form.useForm();

  const [updateMiscellaneousService, { data, loading, error }] = useMutation(
    SAVE_MISCELLANEOUS_SERVICE_ORDER
  );

  useEffect(() => {
    if (!error && data && data.save_miscellaneous_service_order) {
      form.resetFields();
      if (onClose) onClose();
      if (isRoot(miscellaneous_service_order)) {
        message.success('Service added successfully!');
        const mso = MiscellaneousServiceOrder.create(data.save_miscellaneous_service_order);
        booking_request.addMiscellaneousServiceOrder(mso);
        if (onSuccess) {
          onSuccess();
        }
      } else {
        message.success('Service updated successfully!');
        booking_request.replaceMiscellaneousServiceOrder(data.save_miscellaneous_service_order);
        if (onSuccess) {
          onSuccess();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, onSuccess]);

  const submitForm = () => {
    form
      .validateFields()
      .then((values) => {
        const payload = {
          miscellaneous_service_order: {
            name: values.name,
            vendor_company_id: values?.vendor?.party_company?.id || null,
          },
          service_order_id:
            miscellaneous_service_order.id === '' ? undefined : miscellaneous_service_order.id,
          booking_request_id: booking_request.id,
        };
        updateMiscellaneousService({ variables: payload });
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  return (
    <Modal
      title={`${miscellaneous_service_order.id === '' ? 'Add' : 'Update'} ${_startCase(
        miscellaneous_service_order.name
      )} Service`}
      open={visible}
      onCancel={() => {
        if (onClose) onClose();
      }}
      destroyOnClose
      onOk={submitForm}
      okText={miscellaneous_service_order.id === '' ? 'Create' : 'Update'}
      confirmLoading={loading}
    >
      <Form
        scrollToFirstError
        form={form}
        initialValues={{
          vendor: {
            party_company: miscellaneous_service_order.getVendorCompany(default_company_id),
          },
        }}
        preserve={false}
        layout="vertical"
      >
        <MiscellaneousServiceForm
          name={miscellaneous_service_order.name}
          field_name={[]}
          colSpan={12}
        />
      </Form>
    </Modal>
  );
};

const RenderProvider = observer(function RenderProvider(props: {
  service: MiscellaneousServiceOrderValue;
  default_company_id: string;
}): JSX.Element {
  const { service, default_company_id } = props;
  return <span>{service.getVendorCompany(default_company_id)?.registered_name}</span>;
});

const MiscellaneousServiceCard = observer(function MiscellaneousServiceCard(props: {
  bookingRequest: BookingRequestValue;
  disabled?: boolean;
  refetchData?: () => void;
}): JSX.Element {
  const { bookingRequest, disabled, refetchData } = props;
  const sessionData = useSession();
  const client = useApolloClient();
  const default_company_id = sessionData.company_account.default_company.id;
  const miscServices = bookingRequest.miscellaneous_service_orders;

  const getColumns = useCallback(() => {
    return [
      {
        title: 'Name',
        dataIndex: 'name',
        render: function renderName(text: string) {
          return _startCase(text);
        },
      },
      {
        title: 'Provider',
        dataIndex: 'provider',
        render: function renderProvider(
          text: string,
          service: MiscellaneousServiceOrderValue,
          index: number
        ) {
          return <RenderProvider service={service} default_company_id={default_company_id} />;
        },
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        render: function renderActions(
          text: string,
          record: MiscellaneousServiceOrderValue,
          index: number
        ) {
          if (disabled) return null;
          return (
            <Space>
              <ActionRenderer
                id={bookingRequest.id}
                data={bookingRequest}
                doc_type_id={'Shipment::BookingRequest'}
                selectedActions={[
                  editServiceForEachMisc(bookingRequest, record),
                  deleteServiceForEachMisc(bookingRequest, record, client),
                ]}
                refetchData={refetchData}
              />
            </Space>
          );
        },
      },
    ];
  }, [default_company_id, disabled, bookingRequest, client, refetchData]);

  return (
    <Card title="Other Services">
      <Table
        dataSource={miscServices.slice()}
        columns={getColumns()}
        pagination={false}
        rowKey={(record) => record.id || ''}
        size="small"
      />
      <AddMiscellaneousService refetchData={refetchData} booking_request={bookingRequest} />
    </Card>
  );
});

export default MiscellaneousServiceCard;
