import { ShipmentValue } from 'operations/models/Shipment';
import {
  BASIS_TYPE_CHARGEABLE_WEIGHT,
  BASIS_TYPE_CONTAINER,
  BASIS_TYPE_GROSS_VOLUME,
  BASIS_TYPE_GROSS_WEIGHT,
  BASIS_TYPE_PACKAGES,
  BASIS_TYPE_SHIPMENT,
  BASIS_TYPE_ULD,
  CARGO_TYPE_AIR_LOOSE,
  CARGO_TYPE_AIR_ULD,
  BASIS_TYPE_NET_WEIGHT,
  BASIS_TYPE_NET_WEIGHT_MTS,
  BASIS_TYPE_TOTAL_ASSESSABLE_VALUE,
  BASIS_TYPE_TOTAL_INVOICE_VALUE,
  BASIS_TYPE_TOTAL_DUTY_VALUE,
} from 'operations/modules/reports/constants';
import { ShipmentProductRatingValue } from 'operations/models/ShipmentProductRating';
import { ShipmentContainerValue } from 'operations/models/ShipmentContainer';
import { CHARGE_AMOUNT_PREPAID, ENUM_BUY_SELL_STATUS } from 'operations/modules/shipment/constants';
import {
  ShipmentEstimateStoreValue,
  ShipmentEstimateValue,
} from 'operations/models/ShipmentEstimate';
import { uniq as _uniq, pick as _pick, startCase as _startCase } from 'lodash';
import { getSnapshot } from 'mobx-state-tree';
import { ApolloClient } from '@apollo/client';
import { SAVE_SHIPMENT_ESTIMATES } from 'operations/graphql/shipmentEstimate';
import { message } from '@shipmnts/pixel-hub';
import { errorMessageHandlerGraphQLString } from 'common';
import { uuid4 } from '@sentry/utils';
import { MutableRefObject } from 'react';
import { GridOptions } from '@ag-grid-community/core';
import { CompanyAccountValue } from 'operations/models/CompanyAccount';
import { ShipmentCustomDetailValue } from 'operations/models/ShipmentCustomDetail';

export const getDefaultUldValues = (productRatings: ShipmentProductRatingValue[]) => {
  const uldValues: { [key: string]: { value: string; label: string; quantity: number } } = {};
  productRatings.forEach((rating) => {
    const { cargo_type } = rating;
    const isLooseCargoType = cargo_type === CARGO_TYPE_AIR_LOOSE.key;
    const uld_code = rating.uld_type || (isLooseCargoType ? 'extra' : '');
    const key = `${BASIS_TYPE_ULD.name}.${uld_code}.${uld_code}`;
    uldValues[key] = {
      value: key,
      label: `${BASIS_TYPE_ULD.name} / ${uld_code}`,
      quantity: rating.quantity || 1,
    };
  });
  return uldValues;
};

const getDefaultContainerValues = (containers: ShipmentContainerValue[]) => {
  const containerValues: { [key: string]: { value: string; label: string; quantity: number } } = {};
  containers.forEach((container) => {
    const key = `${BASIS_TYPE_CONTAINER.name}.${container.container_type}.${container.container_type_code}`;
    containerValues[key] ||= {
      value: key,
      label: `${BASIS_TYPE_CONTAINER.name} / ${container.container_type}`,
      quantity: 0,
    };
    containerValues[key]['quantity'] = containerValues[key]['quantity'] + 1;
  });
  return containerValues;
};

// const getDefaultShipmentEstimatesValues = (estimates: ShipmentEstimateValue[]) => {
//   (estimates || []).filter((shp_estimate: ShipmentEstimateValue) => {
//     return shp_estimate.tag === FREIGHT_CHARGE_TAG && shp_estimate.uom === BASIS_TYPE_FREIGHT.name;
//   });
//   const values: { [key: string]: number } = {};
//   if (estimates) {
//     let total_freight_value = 0;
//     estimates.forEach((estimate: ShipmentEstimateValue) => {
//       if (estimate.total_sell_amount) total_freight_value += estimate.total_sell_amount;
//     });
//     values[BASIS_TYPE_FREIGHT.name] = total_freight_value;
//   }
//   return values;
// };

const getDefaultCustomDetailsValues = (shipment: ShipmentValue) => {
  const shipment_custom_details = shipment?.shipment_custom_details?.filter(
    (c: ShipmentCustomDetailValue) =>
      c?.trade_type === shipment?.trade_type && c.shipment_id === shipment?.id
  );
  const values: { [key: string]: number } = {};

  if (shipment_custom_details) {
    let total_invoice_value = 0;
    let total_assessable_value = 0;
    let total_duty_value = 0;
    shipment_custom_details.forEach((custom_det: ShipmentCustomDetailValue) => {
      if (custom_det.assessable_value) total_assessable_value += custom_det.assessable_value;
      if (custom_det.invoice_value) total_invoice_value += custom_det.invoice_value;
      if (custom_det.duty_amount) total_duty_value += custom_det.duty_amount;
    });
    values[BASIS_TYPE_TOTAL_INVOICE_VALUE.name] = total_invoice_value;
    values[BASIS_TYPE_TOTAL_ASSESSABLE_VALUE.name] = total_assessable_value;
    values[BASIS_TYPE_TOTAL_DUTY_VALUE.name] = total_duty_value;
  }
  return values;
};

export const getDefaultShipmentValues = (shipment: ShipmentValue) => {
  const { chargeable_weight, gross_weight, gross_volume, total_number_of_packages, net_weight } =
    shipment;
  const values: { [key: string]: number } = {};
  values[BASIS_TYPE_CHARGEABLE_WEIGHT.name] = chargeable_weight || 0;
  values[BASIS_TYPE_GROSS_WEIGHT.name] = gross_weight || 0;
  values[BASIS_TYPE_GROSS_VOLUME.name] = gross_volume || 0;
  values[BASIS_TYPE_PACKAGES.name] = total_number_of_packages || 0;
  values[BASIS_TYPE_SHIPMENT.name] = 1;
  values[BASIS_TYPE_NET_WEIGHT.name] = net_weight || 0;
  values[BASIS_TYPE_NET_WEIGHT_MTS.name] = Number((net_weight || 0) / 1000);
  return values;
};

const combinedShipmentDefaultValues = (
  shipment: ShipmentValue,
  estimates: ShipmentEstimateValue[]
) => {
  const defaultShipentQuantities = getDefaultShipmentValues(shipment);
  const defaultShipmentCustomDetails = getDefaultCustomDetailsValues(shipment);
  const combinedValues = {
    ...defaultShipentQuantities,
    ...defaultShipmentCustomDetails,
  };
  return combinedValues;
};

export function getFinalUoms(
  uoms: { name: string }[],
  shipment: ShipmentValue,
  estimates: ShipmentEstimateValue[]
) {
  let newUoms: { value: string; label: string; quantity: number }[] = [];
  const defaultQuantities = combinedShipmentDefaultValues(shipment, estimates);

  uoms.forEach((option) => {
    if (BASIS_TYPE_CONTAINER.name === option.name && shipment.shipment_containers) {
      const containerValues = getDefaultContainerValues(shipment.shipment_containers);
      newUoms = newUoms.concat(Object.values(containerValues));
      const totalContainers = Object.values(containerValues).reduce(
        (sum, value) => (sum += value.quantity),
        0
      );
      newUoms.push({
        value: BASIS_TYPE_CONTAINER.name,
        label: BASIS_TYPE_CONTAINER.name,
        quantity: totalContainers,
      });
      return;
    }
    if (BASIS_TYPE_ULD.name === option.name && shipment.shipment_product_ratings) {
      const ratingValues = getDefaultUldValues(shipment.shipment_product_ratings);
      newUoms = newUoms.concat(Object.values(ratingValues));
      return;
    }
    newUoms.push({
      label: option.name,
      value: option.name,
      quantity: defaultQuantities[option.name] || 1,
    });
  });
  return newUoms;
}

export function quantityDisabled(params: { data: ShipmentEstimateValue }) {
  return (
    params.data.sell_status !== ENUM_BUY_SELL_STATUS.UNBILLED ||
    params.data.buy_status !== ENUM_BUY_SELL_STATUS.UNBILLED
  );
}

// Create empty row with item null
export const addNewEstimateRow = (
  store: ShipmentEstimateStoreValue,
  shipment: ShipmentValue,
  gridRef: MutableRefObject<GridOptions | undefined>,
  company_account: CompanyAccountValue
) => {
  const estimates = store.estimates.filter(
    (estimate) => estimate.charge_type === 'Item' && estimate.shipment_id === shipment.id
  );
  let estimate_to_consider;
  if (estimates.length > 0) {
    estimate_to_consider = estimates[estimates.length - 1];
  }
  const customer_company = estimate_to_consider?.customer_company || shipment.customer_company;

  const default_estimate: ShipmentEstimateValue = {
    uuid: uuid4(),
    item: null,
    buy_branch_id: shipment.involved_branch_id,
    sell_branch_id: shipment.involved_branch_id,
    customer_company_id: estimate_to_consider?.customer_company_id || shipment.customer_company?.id,
    customer_company: customer_company ? getSnapshot(customer_company) : null,
    uom: null,
    quantity: 1,
    buy_rate: 0,
    buy_exchange_rate: estimate_to_consider?.buy_exchange_rate || 1,
    buy_terms: CHARGE_AMOUNT_PREPAID,
    buy_currency: estimate_to_consider?.buy_currency || company_account.default_currency,
    sell_rate: 0,
    sell_exchange_rate: estimate_to_consider?.sell_exchange_rate || 1,
    sell_terms: CHARGE_AMOUNT_PREPAID,
    sell_currency: estimate_to_consider?.sell_currency || company_account.default_currency,
    supplier_company_id: estimate_to_consider?.supplier_company_id,
    supplier_company: estimate_to_consider?.supplier_company
      ? getSnapshot(estimate_to_consider.supplier_company)
      : null,
    shipment_id: shipment.id,
    total_buy_amount: 0,
    total_buy_billed_amount: 0,
    total_sell_amount: 0,
    total_sell_billed_amount: 0,
    buy_status: ENUM_BUY_SELL_STATUS.UNBILLED,
    sell_status: ENUM_BUY_SELL_STATUS.UNBILLED,
    charge_type: 'Item',
  } as ShipmentEstimateValue;

  store.addEstimates([default_estimate]);
  setTimeout(() => {
    gridRef?.current?.api?.startEditingCell({ rowIndex: estimates.length, colKey: 'item' });
    gridRef?.current?.api?.setFocusedCell(estimates.length, 'item');
  }, 500);
};

export function callSync(store: ShipmentEstimateStoreValue, client: ApolloClient<object>) {
  const estimate_ids = _uniq(store.startSyncing()).filter((est) => !!est);
  if (estimate_ids.length === 0) return;
  if (store.estimates.find((est) => est.mismatch)) {
    store.updateSyncError(estimate_ids);
    return;
  }

  const estimates_to_be_synced = store.estimates.filter(
    (est) => estimate_ids.includes(est.uuid) && est.item && est.charge_type === 'Item'
  );

  store.addToBeSynced(
    store.estimates
      .filter((est) => estimate_ids.includes(est.uuid))
      .filter((est) => !est.item)
      .map((est) => est.uuid)
  );

  if (estimates_to_be_synced.length === 0) {
    store.updateSyncSuccess();
  }

  const shipment_estimates = estimates_to_be_synced
    .map((est) => getSnapshot(est))
    .map((est) => {
      const new_estimate: Partial<any> = _pick(est, [
        'id',
        'item',
        'buy_branch_id',
        'sell_branch_id',
        'supplier_company_id',
        'customer_company_id',
        'uom',
        'quantity',
        'buy_exchange_rate',
        'buy_rate',
        'buy_currency',
        'buy_terms',
        'sell_exchange_rate',
        'sell_rate',
        'sell_currency',
        'sell_terms',
        'sell_remarks',
        'remarks',
        'equipment_type',
        'equipment_name',
        'shipment_id',
        'tag',
        'uuid',
        'sequence',
        'tax_percentage',
        'taxable',
        'taxability',
      ]);
      new_estimate.product_rating_id = est.product_rating?.id || null;
      return new_estimate;
    });
  client
    .mutate({
      mutation: SAVE_SHIPMENT_ESTIMATES,
      variables: {
        shipment_estimates,
      },
    })
    .then((response) => {
      if (response.data?.save_shipment_estimates_new) {
        const new_estimates = estimates_to_be_synced.filter((est) => !est.id);
        new_estimates.forEach((est) => {
          const match = response.data.save_shipment_estimates_new.find(
            (new_est: ShipmentEstimateValue) => new_est.uuid === est.uuid
          );
          if (match) {
            est.updateDetailsOnCreate(match.id, match.buy_branch_id, match.supplier_company);
          }
        });
        store.updateSyncSuccess();
      } else if (response.errors) {
        // message.error(errorMessageHandlerGraphQLString(response.errors));
        store.updateSyncError(estimate_ids);
      }
    })
    .catch((error) => {
      message.error(errorMessageHandlerGraphQLString(error));
      store.updateSyncError(estimate_ids);
    });
}
export const getProductRatingInfo = (rating: ShipmentProductRatingValue) => {
  let item =
    rating.cargo_type === CARGO_TYPE_AIR_ULD.key
      ? `${_startCase(rating.cargo_type)} / ${rating.uld_type}`
      : _startCase(rating?.cargo_type || '');

  if (rating.chargeable_weight) {
    item += ` - ${rating.chargeable_weight} kgs`;
  }
  return item;
};
