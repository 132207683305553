/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree';
import Company from 'operations/models/Company';
import Address from 'operations/models/Address';
import SalesPerson from 'common/models/SalesPerson';

const OrderCollaboration = types.model({
  id: types.identifier,
  customer_company: types.maybe(types.maybeNull(Company)),
  customer_address: types.maybe(types.maybeNull(Address)),
  vendor_company: types.maybe(types.maybeNull(Company)),
  vendor_address: types.maybe(types.maybeNull(Address)),
  managed_by_company: types.maybe(types.maybeNull(Company)),
  managed_by_address: types.maybe(types.maybeNull(Address)),
  sales_person: types.maybe(types.maybeNull(SalesPerson)),
});

export interface OrderCollaborationValue extends Instance<typeof OrderCollaboration> {}
export interface OrderCollaborationIn extends SnapshotIn<typeof OrderCollaboration> {}
export interface OrderCollaborationOut extends SnapshotOut<typeof OrderCollaboration> {}

export default OrderCollaboration;
