/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree';
import Address from 'network/models/Address';

const NameCodeObject = types.model({
  name: types.maybe(types.maybeNull(types.string)),
  code: types.maybe(types.maybeNull(types.string)),
});

const BranchAccount = types.model({
  id: types.identifier,
  default_address: types.maybe(types.maybeNull(Address)),
  name: types.string,
  erp_cost_center_id: types.maybe(types.string),
  city: types.maybe(NameCodeObject),
  state: types.maybe(NameCodeObject),
  country: types.maybe(NameCodeObject),
  company_account_id: types.maybe(types.string),
  postal_code: types.maybe(types.maybeNull(types.string)),
  print_address: types.maybe(types.maybeNull(types.string)),
  tax_registration_number: types.maybe(types.maybeNull(types.string)),
});

export interface BranchAccountValue extends Instance<typeof BranchAccount> {}
export interface BranchAccountIn extends SnapshotIn<typeof BranchAccount> {}
export interface BranchAccountOut extends SnapshotOut<typeof BranchAccount> {}

export default BranchAccount;
