import React from 'react';
import { Row, Col, Form, InputNumber, dayjs, DatePicker } from '@shipmnts/pixel-hub';
import { SHIPMENT_TYPE_HOUSE, ROW_GUTTER } from 'operations/modules/shipment/constants';

const colSpan = 12;

const getDateValue = (date: string | undefined) =>
  date ? dayjs.unix(new Date(date).getTime() / 1000) : null;

const MessageShipmentFreeDaysAndLfd = React.memo(function MessageShipmentBasicDetails(props: {
  shipmentType: string;
  shipment: any;
  consolType?: string | null;
}): JSX.Element {
  const { shipmentType, shipment } = props;
  const lfd_at_pod_date = getDateValue(shipment?.lfd_at_pod?.value);
  const lfd_at_carrier_date = getDateValue(shipment?.lfd_at_carrier?.value);
  const lfd_at_empty_return_date = getDateValue(shipment?.lfd_at_empty_return?.value);
  const lfd_at_pocd_date = getDateValue(shipment?.lfd_at_pocd?.value);

  return (
    <>
      {shipmentType !== SHIPMENT_TYPE_HOUSE && (
        <>
          <Row>
            <Col span={8}>
              <Form.Item
                name="destination_carrier_free_days"
                label="Destination Carrier Free Days"
                initialValue={shipment?.destination_carrier_free_days?.value || null}
              >
                <InputNumber style={{ width: '75%' }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="destination_port_free_days"
                label="Destination Port Free Days"
                initialValue={shipment?.destination_port_free_days?.value || null}
              >
                <InputNumber style={{ width: '75%' }} />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="origin_carrier_free_days"
                label="Origin Carrier Free Days"
                initialValue={shipment?.origin_carrier_free_days?.value || null}
              >
                <InputNumber style={{ width: '75%' }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={ROW_GUTTER}>
            <Col span={colSpan}>
              <Form.Item
                name="lfd_at_carrier"
                label="Last free date at Carrier"
                initialValue={lfd_at_carrier_date}
              >
                <DatePicker showTime style={{ width: '100%' }} />
              </Form.Item>
            </Col>

            <Col span={colSpan}>
              <Form.Item
                name="lfd_at_empty_return"
                label="Last free date at Empty return"
                initialValue={lfd_at_empty_return_date}
              >
                <DatePicker showTime style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={ROW_GUTTER}>
            <Col span={colSpan}>
              <Form.Item
                name="lfd_at_pocd"
                label="Last free date at POCD"
                initialValue={lfd_at_pocd_date}
              >
                <DatePicker showTime style={{ width: '100%' }} />
              </Form.Item>
            </Col>

            <Col span={colSpan}>
              <Form.Item
                name="lfd_at_pod"
                label="Last free date at POD"
                initialValue={lfd_at_pod_date}
              >
                <DatePicker showTime style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
    </>
  );
});

export default MessageShipmentFreeDaysAndLfd;
