import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useApolloClient, useLazyQuery } from '@apollo/client';
import { GridOptions } from '@ag-grid-community/core';
import BookingRequest, { BookingRequestValue } from 'operations/models/BookingRequest';
import {
  Drawer,
  Checkbox,
  Steps,
  Row,
  Col,
  DrawerFooter,
  CompassOutlined,
} from '@shipmnts/pixel-hub';
import { STATUS_PRE_ALERT_PENDING } from 'operations/modules/reports/constants';
import { ContainerReportData } from 'operations/modules/reports/reportModels';
import { ShipmentType, TRADE_TYPE_EXPORT } from 'operations/modules/shipment/constants';
import ValidateShipmentCreation from 'operations/modules/reports/components/ContainerReports/ContainerActions/ValidateShipmentCreation';
import {
  actionTypeForContainer,
  PropsForContainerAction,
} from 'operations/modules/reports/components/ContainerReports/ContainerActions/ContainerActionDrawer';
import { BOOKING_REQUEST_AND_REMARKS } from 'operations/modules/reports/graphql/bookingRequestReport';
import CustomerOrderSearch from './CustomerOrderSearch';
import { CreateShipmentForFCL } from './CreateShipmentActions';
import { BookingRequestBasicInfo } from './BookingRequestBasicInfo';
import { fetchContainersByIds, fetchBRMatchingContainers } from './FetchContainersHelper';
import ContainerGrid, {
  renderContainerData,
} from 'operations/modules/booking/components/MergeSplitBooking/ContainerGridRender';
import { startCase as _startCase } from 'lodash';

const CreateShipmentFromFCLCustomerOrder = React.memo(
  function CreateShipmentFromFCLCustomerOrder(props: {
    bookingRequest: BookingRequestValue | undefined;
    shipmentType: ShipmentType;
    onClose: () => void;
    onSuccess: () => void;
  }): JSX.Element {
    const { bookingRequest, shipmentType: shipment_type, onClose, onSuccess } = props;
    const [current, setCurrent] = React.useState(0);

    const client = useApolloClient();
    const gridRef = useRef<GridOptions>();
    const [activeAction, setActiveAction] = useState<actionTypeForContainer>(undefined);
    const [isNextActionDisabled, setIsNextActionDisabled] = useState<boolean>(
      !!bookingRequest?.isFCL
    );
    const [propsForActiveAction, setPropsForActiveAction] = useState<PropsForContainerAction>();
    const [isValidateShipmentCreationVisible, setValidateShipmentCreationVisible] =
      useState<boolean>(false);
    const [validateShipmentCreationProps, setValidateShipmentCreationProps] = useState<
      | {
          containers: ContainerReportData[];
          shipmentType: ShipmentType;
        }
      | undefined
    >();

    const currentBookingRequestContainerIds = (bookingRequest?.shipmentContainers || [])
      .filter((sc) => Boolean(sc.container_number))
      .map((sc) => sc.id);
    const [fetchContainerIds, setFetchContainerIds] = useState<string[]>(
      currentBookingRequestContainerIds
    );
    const [matchedContainerIds, setMatchedContainerIds] = useState<string[]>([]);
    const [tableContainers, setTableContainers] = useState<string[]>([]);
    const [idWiseContainersData, setIdWiseContainersData] = useState<{
      [key: string]: ContainerReportData;
    }>({});
    const [selectedContainers, setSelectedContainers] = useState<ContainerReportData[]>([]);
    const [selectedBRs, setSelectedBrs] = useState<BookingRequestValue[]>([]);

    const [
      getBookingRequestAndRemarks,
      { data: bookingRequests, loading: fetchingBookingRequests, error: fetchBookingRequestError },
    ] = useLazyQuery(BOOKING_REQUEST_AND_REMARKS);

    const tradeType = TRADE_TYPE_EXPORT;

    const containerColumnNames = [
      'container_number',
      'order_number',
      'booking_number',
      'total_gross_weight',
      'last_action_status',
    ];
    const validateContainersForCreateShipment = useCallback(
      async (containers: ContainerReportData[], shipmentType: ShipmentType) => {
        setValidateShipmentCreationVisible(true);
        setValidateShipmentCreationProps({ containers, shipmentType });
      },
      []
    );

    // on container selection change
    const onSelectionChanged = useCallback(
      () => {
        const rowCount = gridRef?.current?.api?.getSelectedNodes().length || 0;
        setIsNextActionDisabled(rowCount === 0);
        const selectedContainersIds =
          gridRef?.current?.api?.getSelectedNodes().map((node) => node.data.id) || [];
        setSelectedContainers(selectedContainersIds.map((id) => idWiseContainersData[id]));
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    const onConfirmNextStep = useCallback(() => {
      validateContainersForCreateShipment(selectedContainers, shipment_type);
      setCurrent(1);
    }, [validateContainersForCreateShipment, shipment_type, selectedContainers]);

    const onSelectBookingRequest = useCallback(
      (brs: BookingRequestValue | Array<BookingRequestValue> | undefined | null) => {
        if (brs) {
          let container_ids: string[] = [];
          if (Array.isArray(brs)) {
            brs.forEach((br: BookingRequestValue) => {
              br = BookingRequest.create(br);
              container_ids = [...container_ids, ...br?.shipmentContainers.map((sc) => sc.id)];
            });
            setSelectedBrs(brs);
          } else {
            container_ids = [...container_ids, ...brs?.shipmentContainers.map((sc) => sc.id)];
            setSelectedBrs([brs]);
          }
          const new_container_ids = container_ids.filter(
            (id) => !(Object.keys(idWiseContainersData) || []).includes(id)
          );
          if (new_container_ids.length > 0) setFetchContainerIds(new_container_ids);
          else {
            setTableContainers([...container_ids, ...currentBookingRequestContainerIds]);
          }
        }
      },
      [idWiseContainersData, currentBookingRequestContainerIds]
    );

    useEffect(() => {
      if (fetchContainerIds.length > 0)
        fetchContainersByIds(fetchContainerIds, STATUS_PRE_ALERT_PENDING, client).then((data) => {
          const preIdWiseContainersData = idWiseContainersData;
          const preTableContainers = [
            ...tableContainers,
            ...data.map((c: ContainerReportData) => c.id),
          ];
          setTableContainers(preTableContainers);
          (data || []).forEach((container: ContainerReportData) => {
            preIdWiseContainersData[container.id] = container;
          });
          setIdWiseContainersData(preIdWiseContainersData);
        });

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchContainerIds]);

    useEffect(() => {
      if (
        !fetchingBookingRequests &&
        !fetchBookingRequestError &&
        bookingRequests &&
        bookingRequests.booking_request_report
      ) {
        setPropsForActiveAction((prevState) => ({
          ...prevState,
          bookingRequests: bookingRequests.booking_request_report.data,
        }));
        setActiveAction('create_shipment');
      }
    }, [bookingRequests, fetchBookingRequestError, fetchingBookingRequests]);

    const onChangeShowMatchingContainers = (e: any) => {
      if (e.target.checked) {
        if (matchedContainerIds.length > 0) {
          setTableContainers(matchedContainerIds);
        } else if (bookingRequest) {
          fetchBRMatchingContainers(bookingRequest, STATUS_PRE_ALERT_PENDING, client).then(
            (data) => {
              const preIdWiseContainersData = idWiseContainersData;
              const containre_ids: string[] = [];
              (data || []).forEach((container: ContainerReportData) => {
                preIdWiseContainersData[container.id] = container;
                containre_ids.push(container.id);
              });
              setIdWiseContainersData(preIdWiseContainersData);
              setMatchedContainerIds(containre_ids);
              setTableContainers(data.map((c: ContainerReportData) => c.id));
            }
          );
        }
      } else {
        setTableContainers(currentBookingRequestContainerIds);
      }
    };

    useEffect(() => {
      if (tableContainers) {
        const containers = tableContainers.map((id) => idWiseContainersData[id]);
        gridRef?.current?.api?.setRowData(renderContainerData(containers));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableContainers]);

    const onChangeStep = (current: number) => {
      if (current === 1 && !isNextActionDisabled) {
        validateContainersForCreateShipment(selectedContainers, shipment_type);
        setCurrent(current);
      } else if (current === 0) {
        setCurrent(current);
        setIsNextActionDisabled(true);
      }
    };
    const items = [
      { title: 'Step 1', description: 'Select Containers' },
      { title: 'Step 2', description: 'Create Shipment' },
    ];
    if (!bookingRequest) return <></>;
    return (
      <Drawer
        placement="right"
        title={
          <div>
            <Row>
              <Col span={16}>
                <div
                  style={{
                    color: '#353F52',
                    fontSize: '18px',
                    fontWeight: 500,
                    marginBottom: '10px',
                  }}
                >
                  <CompassOutlined />
                  <span style={{ marginLeft: '5px' }}>
                    <b>Create {_startCase(shipment_type)} Shipment</b>
                  </span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={16}>
                <Steps
                  items={items}
                  current={current}
                  size="small"
                  type="navigation"
                  onChange={onChangeStep}
                />
              </Col>
            </Row>
          </div>
        }
        onClose={onClose}
        open={true}
        styles={{ header: { padding: '16px 16px 0px 24px' } }}
        width="60%"
        footer={
          current === 0 ? (
            <DrawerFooter
              saveText="Confirm & Go to Next Step"
              closeText="Cancel"
              disabled={isNextActionDisabled}
              onClose={onClose}
              onSave={onConfirmNextStep}
            />
          ) : undefined
        }
      >
        <div className="steps-content">
          {current === 0 && (
            <>
              <BookingRequestBasicInfo bookingRequest={bookingRequest}></BookingRequestBasicInfo>
              <div>
                <div style={{ marginTop: '15px', marginBottom: '20px' }}>
                  <p style={{ fontWeight: 500 }}>
                    Search other order number(s) or container(s) of this customer to create shipment
                  </p>
                  <CustomerOrderSearch
                    value={selectedBRs}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    onSelectBookingRequest={onSelectBookingRequest}
                    bookingRequest={bookingRequest}
                  />
                </div>
                <div style={{ marginBottom: 20, fontWeight: 500 }}>
                  <Checkbox onChange={onChangeShowMatchingContainers}>
                    Show Other Matching Containers of this customer
                  </Checkbox>
                </div>
                <ContainerGrid
                  tableHeading="Select container(s) to create shipment"
                  containers={tableContainers.map((id) => idWiseContainersData[id])}
                  columnNames={containerColumnNames}
                  gridRef={gridRef}
                  onSelectionChanged={onSelectionChanged}
                  renderFormatedData
                />
              </div>
            </>
          )}
        </div>
        {isValidateShipmentCreationVisible && validateShipmentCreationProps && (
          <ValidateShipmentCreation
            containers={validateShipmentCreationProps.containers}
            shipmentType={validateShipmentCreationProps.shipmentType}
            client={client}
            setValidateShipmentCreationVisible={setValidateShipmentCreationVisible}
            setPropsForActiveAction={setPropsForActiveAction}
            getBookingRequestAndRemarks={getBookingRequestAndRemarks}
          />
        )}
        {current === 1 && selectedContainers && (
          <CreateShipmentForFCL
            containers={selectedContainers}
            activeAction={activeAction}
            propsForActiveAction={propsForActiveAction}
            onSuccess={onSuccess}
            onClose={onClose}
            tradeType={tradeType}
          />
        )}
      </Drawer>
    );
  }
);

export default CreateShipmentFromFCLCustomerOrder;
