import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'wouter';
import { FallbackSkeleton } from '@shipmnts/pixel-hub';
// Lazy Loading View Components
const ContactView = lazy(() => import('./components/ContactDetailView/ContactView'));
const CompanyView = lazy(() => import('./components/CompanyView/CompanyView'));

// Lazy Loading Form Components
const CompanyForm = lazy(() => import('./components/CompanyForm'));
const AddressForm = lazy(() => import('./components/AddressForm'));
const SalesPersonForm = lazy(() => import('./components/SalesPersonForm'));
const TemplateDetailLayout = lazy(() => import('./components/TemplateDetailLayout'));

const NetworkViewLayout = () => {
  return (
    <Switch>
      <Route path="/contact/:contact_id">
        <Suspense fallback={<FallbackSkeleton />}>
          <ContactView />
        </Suspense>
      </Route>
      <Route path="/company/:company_id">
        <Suspense fallback={<FallbackSkeleton />}>
          <CompanyView />
        </Suspense>
      </Route>
    </Switch>
  );
};
const NetworkFormLayout = () => {
  return (
    <Switch>
      <Route path="/template/:id">
        <Suspense fallback={<FallbackSkeleton />}>
          <TemplateDetailLayout />
        </Suspense>
      </Route>
      <Route path="/company/new">
        <Suspense fallback={<FallbackSkeleton />}>
          <CompanyForm />
        </Suspense>
      </Route>
      <Route path="/address/:id">
        <Suspense fallback={<FallbackSkeleton />}>
          <AddressForm />
        </Suspense>
      </Route>
      <Route path="/sales_person/:id">
        <Suspense fallback={<FallbackSkeleton />}>
          <SalesPersonForm />
        </Suspense>
      </Route>
    </Switch>
  );
};

export { NetworkFormLayout, NetworkViewLayout };
