import React, { useState } from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import { startCase as _startCase, toLower as _toLower, upperCase as _upperCase } from 'lodash';
import {
  Button,
  Row,
  Drawer,
  Typography,
  message,
  Modal,
  Tag,
  Popover,
  Tooltip,
} from '@shipmnts/pixel-hub';

import {
  EyeOutlined,
  DownOutlined,
  CopyOutlined,
  DownloadOutlined,
  StatusRenderer,
  TruncateWithToolTip,
} from '@shipmnts/pixel-hub';
import { CustomIcon } from '@shipmnts/pixel-hub';
import { ActionRenderer, ShipmentCreditStatusTimeLine, ShipmentDetailLayout } from 'operations';
import { FSU_CODES_MAPPING } from 'operations/constants';
import { useSession } from 'common';

// TODO: startcase in some fields

export interface TagsRendererType extends ICellRendererParams {
  avoid_start_case?: boolean;
  filter_options?: string;
}

interface LinkRenderParams extends ICellRendererParams {
  extra_fields: { [key: string]: string };
  id_field: string;
  type_field?: string;
  doc_type_id?: string;
  value_field?: string;
  separator?: string;
  fields?: string[];
  target?: React.HTMLAttributeAnchorTarget;
  onSuccess?: () => void;
  disabledResource?: boolean;
  component_props?: { [key: string]: any };
}

export const Link_map = (
  doc_type_id: string,
  id: string,
  extra_fields?: { [key: string]: string }
): string | undefined => {
  if (!id) return;
  // TODO: Why is this handled statically?
  if (doc_type_id === 'Network::Company') {
    return `/view/company/${id}`;
  } else if (doc_type_id === 'Network::VoyageSchedule') {
    return `/form/voyage_schedule/${id}`;
  } else if (doc_type_id === 'Shipment::BookingRequest') {
    return `/view/booking_request/${id}`;
  } else if (doc_type_id === 'Shipment::OceanTransportOrder') {
    return `/view/booking_order/${id}`;
  } else if (doc_type_id === 'Shipment::Shipment') {
    return `/view/shipment/${id}`;
  } else if (doc_type_id === 'Shipment::ShipmentDocument') {
    if (!extra_fields?.shipment_id) return;
    if (extra_fields.is_new_document) {
      return `${process.env.OPERATIONS_URL}/view/documents/${id}`;
    } else {
      return `${process.env.SHIPMNTS_WEB_URL}/shipment/${extra_fields.shipment_id}/documents_latest/${id}`;
    }
  } else if (doc_type_id === 'NewAccounting::FreightContract') {
    if (!extra_fields?.contract_type) return;
    return `${process.env.SHIPMNTS_WEB_URL}/rates/contracts/${extra_fields.contract_type}/form/${id}`;
  } else if (doc_type_id === 'ActiveStorage::Blob') {
    if (!extra_fields?.document_url) return;
    return extra_fields.document_url;
  } else if (doc_type_id === 'SalesHub::InquiryGlobal') {
    return `/view/rfq/${id}`;
  } else if (doc_type_id === 'SalesHub::InquiryOption') {
    return `/view/rfq-option/${id}`;
  } else if (doc_type_id === 'ReportManager::View') {
    if (!extra_fields?.workspace_id) return;
    return `/workspace/${extra_fields.workspace_id}/view/${id}`;
  } else if (doc_type_id === 'Network::Template') {
    return `/form/template/${id}`;
  } else if (doc_type_id === 'OrderManagement::ProductOrderGlobal') {
    return `/view/product_order/${id}`;
  } else if (doc_type_id === 'Shipment::ShipmentInvoice') {
    return `/form/commercial_invoice/${id}`;
  } else if (doc_type_id === 'Network::SalesPerson') {
    return `/form/sales_person/${id}`;
  } else if (doc_type_id === 'Network::UserContact') {
    return `/view/contact/${id}`;
  } else if (doc_type_id === 'ServiceManagement::Ticket::Ticket') {
    return `/view/ticket/${id}`;
  } else if (doc_type_id === 'Wms::Product') {
    return `/form/create_product/${id}`;
  } else if (
    doc_type_id === 'Wms::WarehouseTransaction' ||
    doc_type_id === 'Wms::AdvanceShippingNote' ||
    doc_type_id === 'Wms::GoodsReceivedNote' ||
    doc_type_id === 'Wms::GoodsIssuedOrder' ||
    doc_type_id === 'Wms::GoodsDeliveryNote'
  ) {
    return `/view/warehouse_transaction/${id}`;
  } else if (doc_type_id === 'Wms::WarehouseUnit') {
    return `/form/create_warehouse_unit/${id}`;
  }
  return;
};

export const ShipmentDocumentStatusRenderer = (props: LinkRenderParams) => {
  if (typeof props.value === 'string') return <span>{_startCase(props.value || '')}</span>;
  const value_field = props.value_field || 'value';
  const value = props.value?.[value_field];
  const extra_fields = props.value?.extra_fields || {};
  const coldef = props.colDef || {};
  const cellParams = coldef?.cellRendererParams || {};
  const doc_type_id = cellParams ? cellParams?.doc_type_id : '';

  // direct id field handling
  const id_field = cellParams ? cellParams?.id_field : '';
  const id_field_name = cellParams ? cellParams?.id_field_name : '';
  let id = extra_fields ? extra_fields[id_field] : undefined;

  if (id_field_name) {
    // extract id form dependent object field
    // id_field = 'master_shipment_number'
    // id_field_name = 'shipment_document_id'
    // master_shipment_number = {'shipment_document_id': xyz, ....}
    const id_field_obj = extra_fields ? extra_fields[id_field] : {};
    id = null;
    if (Array.isArray(id_field_obj) && id_field_obj.length === 1) {
      id = id_field_obj[0][id_field_name];
    } else if (id_field_obj && !Array.isArray(id_field_obj)) {
      id = id_field_obj[id_field_name] || null;
    }
  }

  if (id && doc_type_id) {
    return (
      <div className="event-renderer row-cell-action">
        <ActionRenderer id={id} doc_type_id={doc_type_id}>
          <div className="event-name">
            <div>{_startCase(value) || ''}</div>
            <div style={{ marginLeft: '5px' }}>
              <DownOutlined />
            </div>
          </div>
        </ActionRenderer>
      </div>
    );
  }

  return (
    <div className="event-renderer row-cell-action">
      <div className="event-name">
        <span>{_startCase(_toLower(value)) || ''}</span>
      </div>
    </div>
  );
};

export const CreditStatusRenderer = (props: ICellRendererParams) => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <Row style={{ fontSize: '14px', overflowWrap: 'break-word', position: 'relative' }}>
        <StatusRenderer {...props} />
        <CustomIcon
          icon="TrackerIcon"
          style={{
            fontSize: '16px',
            margin: 'auto',
            position: 'absolute',
            right: '1px',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
          onClick={() => {
            setVisible(true);
          }}
        />
      </Row>

      {visible && (
        <ShipmentCreditStatusTimeLine
          onClose={() => setVisible(false)}
          shipment={props?.node?.data}
        />
      )}
    </>
  );
};

export const ContainerStatusRenderer = (props: ICellRendererParams) => {
  const containerStatusJson = props?.value?.value;
  const id = props?.value?.extra_fields?.id;
  const [showContainerTab, setShowContainerTab] = useState(false);
  if (!containerStatusJson) return <></>;
  return (
    <>
      <div
        onClick={() => {
          setShowContainerTab(true);
        }}
      >
        <TruncateWithToolTip params={props}>
          {Object.keys(containerStatusJson)
            .map((k: string) => {
              return k + ' - ' + containerStatusJson[k];
            })
            .join(', ')}
        </TruncateWithToolTip>
      </div>
      {showContainerTab && (
        <Drawer
          placement="right"
          open={true}
          closable={false}
          width="85%"
          title={null}
          onClose={() => setShowContainerTab(false)}
          styles={{ body: { padding: '0px' } }}
          className="br-quickview-modal"
        >
          <ShipmentDetailLayout
            id={id}
            tab={'containers'}
            onClose={() => setShowContainerTab(false)}
            externalLink={true}
          />
        </Drawer>
      )}
    </>
  );
};

export const EawbMessageTypeRenderer = (props: any) => {
  const { value } = props;
  function formatXml(xmlString: string) {
    const xmlDoc = new DOMParser().parseFromString(xmlString, 'text/xml');
    const xsltDoc = new DOMParser().parseFromString(
      [
        '<?xml version="1.0"?>',
        '<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform" version="1.0">',
        '<xsl:output method="xml" indent="yes"/>',
        '<xsl:template match="/">',
        '<xsl:copy-of select="."/>',
        '</xsl:template>',
        '</xsl:stylesheet>',
      ].join('\n'),
      'application/xml'
    );

    const xsltProcessor = new XSLTProcessor();
    xsltProcessor.importStylesheet(xsltDoc);

    const resultDoc = xsltProcessor.transformToDocument(xmlDoc);
    const serializer = new XMLSerializer();
    const formattedXml = serializer.serializeToString(resultDoc);
    return formattedXml;
  }
  const xmlData = formatXml(value?.extra_fields?.xml_data);
  const awbNumber = value?.extra_fields?.awb_number;
  const [modalVisible, setModalVisible] = useState(false);

  const openModal = () => {
    setModalVisible(true);
  };
  const closeModal = () => {
    setModalVisible(false);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(xmlData);
    message.success('XML data copied to clipboard');
  };

  const handleDownload = () => {
    const blob = new Blob([xmlData], { type: 'text/xml' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${awbNumber}_${value?.value}.xml`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <>
      <Typography.Text strong>
        {_upperCase(value?.value)}
        <EyeOutlined onClick={openModal} style={{ marginLeft: 8, cursor: 'pointer' }} />
      </Typography.Text>
      <Modal
        title="Message Preview"
        centered={true}
        width={'70%'}
        open={modalVisible}
        onCancel={closeModal}
        footer={[
          <Button key="cancel" onClick={closeModal}>
            Cancel
          </Button>,
          <Button key="copy" icon={<CopyOutlined />} onClick={handleCopy}>
            Copy
          </Button>,
          <Button key="download" icon={<DownloadOutlined />} onClick={handleDownload}>
            Download
          </Button>,
        ]}
        bodyStyle={{ maxHeight: '70vh', overflowY: 'auto', width: '' }}
      >
        <pre>{xmlData}</pre>
      </Modal>
    </>
  );
};

export const EawbMessageDataRenderer = (props: any) => {
  const { value } = props;
  if (!value || !value.value) return '';

  const message_type = value.extra_fields.message_type;
  const parser = new DOMParser();
  const doc = parser.parseFromString(value.value, 'application/xml');
  let message_data = '';
  if (message_type === 'fma' || message_type === 'fna') {
    const statusCodeNode = doc.querySelector('StatusCode');
    if (statusCodeNode) {
      message_data = statusCodeNode.textContent || '';
    }
  } else if (message_type === 'fsu') {
    const reasonCodeNode = doc.querySelector('ReasonCode');
    if (reasonCodeNode) {
      const reasonCode = reasonCodeNode.textContent;
      message_data = FSU_CODES_MAPPING[reasonCode || ''] || '';
    }
  }
  return <div>{message_data}</div>;
};

export const formatNumber = (
  value: number,
  precision: number,
  numberFormat: string | undefined
) => {
  return value.toLocaleString(numberFormat, {
    minimumFractionDigits: precision || 3,
  });
};

export const QtyUomTypeRenderer = (props: any) => {
  const user = useSession();
  const userCountry = user?.company_account?.country_of_incorporation;
  const numberFormat = userCountry === 'IN' ? 'en-IN' : undefined;
  const precision = props?.precision === undefined ? 3 : props?.precision;
  const qty = props?.value?.hasOwnProperty('value')
    ? formatNumber(Number(props.value.value), precision, numberFormat)
    : props?.qty
    ? formatNumber(Number(props.qty), precision, numberFormat)
    : props?.value
    ? formatNumber(Number(props.value), precision, numberFormat)
    : formatNumber(0.0, precision, numberFormat);
  const uom = props?.value?.extra_fields?.uom || props?.uom || '';
  return <div style={{ textAlign: 'right' }}>{`${qty} ${uom}`}</div>;
};

export const CombinedPropertyIconRenderer = (props: any) => {
  const { value, is_hazardous, is_perishable, is_temp_controlled, is_battery } = props;
  if (!value) return <></>;
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {is_hazardous && value[is_hazardous] && (
        <Tooltip title={<div>Contains Hazardous Cargo</div>}>
          <CustomIcon style={{ fontSize: '20px' }} icon="HazardIcon" />
        </Tooltip>
      )}
      {is_perishable && value[is_perishable] && (
        <Tooltip title={<div>Perishable</div>}>
          <CustomIcon style={{ fontSize: '20px' }} icon="MdiLeafIcon" />
        </Tooltip>
      )}
      {is_temp_controlled && value[is_temp_controlled] && (
        <Tooltip title={<div>Temperature Controlled</div>}>
          <CustomIcon style={{ fontSize: '20px' }} icon="NormalTemperatureIcon" />
        </Tooltip>
      )}
      {is_battery && value[is_battery] && (
        <Tooltip title={<div>Battery Inside</div>}>
          <CustomIcon style={{ fontSize: '20px' }} icon="BatteryAlertIcon" />
        </Tooltip>
      )}
    </div>
  );
};
export const CompanyContactsRenderer = (props: any) => {
  return props.value?.map((val: any) => {
    return (
      <Popover
        key={val?.id}
        content={val?.record_details?.email || val?.record_details?.mobile_number}
      >
        <Tag>{[val?.record_details?.first_name, val?.record_details?.last_name].join(' ')}</Tag>
      </Popover>
    );
  });
};

export const CompanyAddressRenderer = (props: any) => {
  return props.value?.map((val: any) => {
    return (
      <Popover
        key={val?.id}
        content={val?.record_details?.print_address}
        overlayStyle={{ width: '300px' }}
      >
        <Tag>{val?.record_details?.name}</Tag>
      </Popover>
    );
  });
};

export const DimensionsWithUOM = (props: any) => {
  const lenght = props.length || props?.value?.extra_fields?.length || 0;
  const breadth = props.breadth || props?.value?.extra_fields?.breadth || 0;
  const height = props.height || props?.value?.extra_fields?.height || 0;
  const dimension_unit =
    props.dimension_unit || props?.value?.extra_fields?.dimension_unit || 'cms';
  return (
    <div
      style={{ textAlign: 'right' }}
    >{`${lenght} ${dimension_unit} X ${breadth} ${dimension_unit} X ${height} ${dimension_unit}`}</div>
  );
};
