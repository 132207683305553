/* eslint-disable no-lone-blocks */
import React, { useEffect, useRef, useState } from 'react';
import { Button, Dropdown, Tooltip, FormInstance, Modal } from '@shipmnts/pixel-hub';
import { LinkOutlined, BaseTable, CustomIcon } from '@shipmnts/pixel-hub';
import type { MenuProps } from '@shipmnts/pixel-hub';
import { Column } from 'common/models/Report';
import DeleteAction from '../Estimates/DeleteAction';
import { GridOptions } from '@ag-grid-community/core';
import { ShipmentCustomDetailValue } from 'operations/models/ShipmentCustomDetail';
import { getHeight } from 'operations/modules/orderManagement/CommercialInvoice/CommercialInvoiceListTable';
import { ActionRendererDetailReport } from 'operations';

type MenuItem = Required<MenuProps>['items'][number];

interface LinkedShipmentInvoiceProps {
  onChange?: (data: any[]) => void;
  form: FormInstance;
  shipmentCustomDetail: ShipmentCustomDetailValue;
  spoiData: any;
}

export default function LinkedShipmentInvoice(props: LinkedShipmentInvoiceProps): JSX.Element {
  const { form, shipmentCustomDetail, spoiData, onChange } = props;

  const [rowData, setRowData] = useState<any[]>(form.getFieldValue('shipment_invoices'));
  const gridRef = useRef<GridOptions>();

  const added_invoice = form.getFieldValue('shipment_invoices')?.map((si: any) => si.id);

  const status = ['cancelled'];
  const filterInvoiceByStatus = spoiData?.shipment?.commercial_invoices?.filter(
    (si: any) => !status.includes(si.status)
  );

  const pending_shipments = filterInvoiceByStatus?.filter(
    (shipment_invoice: any) => !(added_invoice || []).includes(shipment_invoice.id)
  );

  useEffect(() => {
    if (rowData) {
      onChange && onChange(rowData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData]);

  const getItems = () => {
    const items: MenuItem[] = [];
    {
      // eslint-disable-next-line array-callback-return
      (pending_shipments || []).map((shipment_invoice: any, index: any) => {
        items.push({
          key: index,
          onClick: () => {
            setRowData([
              ...rowData,
              {
                ...shipment_invoice,
                shipment_custom_detail_id: shipmentCustomDetail.id,
              },
            ]);
          },
          label: (
            <div>
              <div>
                <div style={{ fontWeight: 'bold' }}>{shipment_invoice?.invoice_number}</div>
              </div>
            </div>
          ),
        });
      });
    }
    return items;
  };

  const newDeleteRow = () => {
    const selectedRow = gridRef?.current?.api?.getFocusedCell();
    const result = (rowData || []).filter((_: any, index: any) => selectedRow?.rowIndex !== index);
    setRowData(result);
    onChange && onChange(result);
    gridRef?.current?.api?.setRowData(result);
  };

  const columns: Column[] = [
    {
      headerName: 'Invoice #',
      field: 'invoice_number',
      colId: 'invoice_number',
      columnType: 'String',
      pinned: 'left',
      minWidth: 160,
    },
    {
      headerName: 'Invoice Currency',
      field: 'invoice_currency',
      colId: 'invoice_currency',
      columnType: 'String',
      minWidth: 100,
    },
    {
      headerName: 'Ex. Rate',
      field: 'exchange_rate',
      colId: 'exchange_rate',
      minWidth: 100,
      columnType: 'Float',
      valueGetter: (params: any) => {
        if (params.node?.isRowPinned()) return <></>;
        return params.data.exchange_rate;
      },
    },
    {
      headerName: 'Invoice Value',
      field: 'invoice_amount',
      colId: 'invoice_amount',
      minWidth: 100,
      columnType: 'Float',
    },
    {
      headerName: 'FOB Value',
      field: 'fob_invoice_amount',
      colId: 'fob_invoice_amount',
      minWidth: 100,
      columnType: 'Float',
    },
    {
      headerName: 'CIF Value',
      field: 'cif_invoice_amount',
      colId: 'cif_invoice_amount',
      minWidth: 100,
      columnType: 'Float',
    },
    {
      headerName: 'De-Link',
      field: 'actions',
      colId: 'actions',
      minWidth: 100,
      columnType: 'String',
      cellRenderer: (params: any) => {
        if (params.node.isRowPinned()) return <></>;
        return (
          <Tooltip title="Delink">
            <div style={{ cursor: 'pointer' }}>
              <CustomIcon
                icon="Delink"
                onClick={() => {
                  Modal.confirm({
                    title: 'Confirm',
                    content: 'Are you sure you want to delink this invoice ?',
                    okText: 'Ok',
                    okType: 'danger',
                    onOk: () => {
                      newDeleteRow();
                    },
                    cancelText: 'Cancel',
                  });
                }}
              />
            </div>
          </Tooltip>
        );
      },
      width: 50,
      pinned: 'right',
      lockVisible: true,
    },
  ];

  const components = {
    DeleteAction,
    ActionRendererDetailReport,
  };

  return (
    <>
      <BaseTable
        reportName="shipment_invoices"
        columns={columns}
        rowData={rowData}
        gridRef={gridRef}
        reportConfig={{
          components: components,
          rowHeight: 40,
        }}
        height={getHeight(rowData) || '200px'}
      />

      <Dropdown
        disabled={pending_shipments?.length === 0}
        menu={{ items: getItems() }}
        placement="bottomLeft"
        arrow
      >
        <Button icon={<LinkOutlined />} size="small">
          Link Commercial Invoices
        </Button>
      </Dropdown>
    </>
  );
}
