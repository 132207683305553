import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Card,
  message,
  Drawer,
  Form,
  UploadedDocuments,
  dayjsGenerateConfig,
  DatePicker,
} from '@shipmnts/pixel-hub';
import { useSession } from 'common';
import { useMutation } from '@apollo/client';
import { OceanTransportOrderValue } from 'operations/models/OceanTransportOrder';
import { DrawerFooter, Dayjs } from '@shipmnts/pixel-hub';
import { UPDATE_OCEAN_TRANSPORT_ORDER } from 'operations/modules/booking/graphql/oceanTransportOrder';
import { errorMessageHandlerGraphQL } from 'common';

import { BOOKING_TYPE_OCEAN_TRANSPORT_ORDER } from 'operations/modules/booking/constants';

const MarkDgIndexing = observer(function MarkDgIndexing(props: {
  oto: OceanTransportOrderValue;
  onSuccess?: (oto: OceanTransportOrderValue, sendEmail: boolean) => void;
  onClose: () => void;
}): JSX.Element {
  const { oto, onClose, onSuccess } = props;
  const sessionData = useSession();
  const [sendEmail, setSendEmail] = useState<boolean>(true);

  const [updateOceanTransportOrder, { data, loading, error }] = useMutation(
    UPDATE_OCEAN_TRANSPORT_ORDER
  );

  const [form] = Form.useForm();

  useEffect(() => {
    if (data?.update_ocean_transport_order?.id && !error) {
      message.success('DG Indexing marked successfully!');
      onClose();
      if (onSuccess) onSuccess(data?.update_ocean_transport_order, sendEmail);
    }
  }, [data, error, onClose, onSuccess, sendEmail]);

  return (
    <Drawer
      title={`Mark DG Indexing For Booking ${oto.booking_number || ''}`}
      width={'60%'}
      open
      onClose={onClose}
      footer={
        <DrawerFooter
          showSendEmail
          sendEmail={sendEmail}
          setSendEmail={setSendEmail}
          sendEmailText="Send DGD Indexing Confirmation"
          saveText="Mark DG Indexing"
          loading={loading}
          onClose={onClose}
          onSave={form.submit}
        />
      }
    >
      <Form
        form={form}
        name="booking_request"
        initialValues={{
          dg_indexing_date: dayjsGenerateConfig.getNow(),
        }}
        onFinish={(values: { dg_indexing_date?: Dayjs }) => {
          const payload = {
            ocean_transport_order: {
              id: oto.id,
              dg_indexing_date: values?.dg_indexing_date?.unix(),
            },
          };
          updateOceanTransportOrder({
            variables: payload,
          });
        }}
      >
        {error && errorMessageHandlerGraphQL(error)}
        <Card title="Mark DG Indexing" className="custom-card" style={{ marginTop: '16px' }}>
          <Form.Item
            required
            rules={[{ required: true }]}
            name="dg_indexing_date"
            label="DG Indexing date"
          >
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
        </Card>
        <div style={{ margin: '16px 0 44px 0' }}>
          <p style={{ marginBottom: '8px' }}>Upload a new amended booking confirmation</p>
          <UploadedDocuments
            sessionData={sessionData}
            parent_id={oto.id}
            parent_type={BOOKING_TYPE_OCEAN_TRANSPORT_ORDER}
            docgen_url={process.env.DOCGEN_URL || ''}
            initialUploadDocumentTags={['Booking confirmation']}
          />
        </div>
      </Form>
    </Drawer>
  );
});

export default MarkDgIndexing;
