import React, { useEffect } from 'react';
import { useSession } from '../utils/SessionContext';

export default function RedirectToFinance() {
  const session = useSession();

  const currUrl = window.location.href;
  const position = currUrl.search('/fin/');
  const start = position + 5;
  const url = currUrl.substring(start);

  useEffect(() => {
    window.open(`${session.company_account.subdomain}/${url}`, '_self');
  }, [session, url]);

  return <div></div>;
}
