import {
  Col,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  DatePicker,
  GlobalSearch,
} from '@shipmnts/pixel-hub';
import { ShipmentValue } from 'operations/models/Shipment';
import {
  BILL_OF_ENTRY_TYPE,
  EXBOND_BILL,
  FREIGHT_TYPE_AIR,
  INBOND_BILL,
  ROW_GUTTER,
} from 'operations/modules/shipment/constants';
import React from 'react';
import { FILING_TYPE } from 'operations/modules/shipment/constants';
import {
  LOCATION_TYPE_AIRPORT,
  LOCATION_TYPE_CFS,
  LOCATION_TYPE_CITY,
  LOCATION_TYPE_ICD,
  LOCATION_TYPE_PORT_TERMINAL,
  LOCATION_TYPE_RAIL_TERMINAL,
  LOCATION_TYPE_SEAPORT,
} from 'operations/models/Location';

interface BillOfEntryDetailsProps {
  shipment?: ShipmentValue;
  disableHash?: any;
  form: FormInstance;
  hide?: boolean;
  tradeType?: string;
}

export default function BillOfEntryDetails(props: BillOfEntryDetailsProps) {
  const { form } = props;

  const billOfEntryType = Form.useWatch(['import_custom_details', 0, 'bill_of_entry_type'], form);

  return (
    <>
      <Row gutter={ROW_GUTTER}>
        <Col span={8}>
          <Form.Item noStyle hidden name={['import_custom_details', 0, 'id']}>
            <Input />
          </Form.Item>
          <Form.Item noStyle hidden name={['import_custom_details', 0, 'trade_type']}>
            <Input />
          </Form.Item>
          <Form.Item
            name={['import_custom_details', 0, 'bill_of_entry_type']}
            label="Bill Of Entry Type"
          >
            <Select showSearch placeholder="Bill of Entry Type" allowClear>
              {BILL_OF_ENTRY_TYPE.map((option, index) => (
                <Select.Option key={(option || '') + index} value={option}>
                  {option}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name={['import_custom_details', 0, 'document_number']} label="Bill Of Entry #">
            <Input placeholder="Enter Bill Of Entry #" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={['import_custom_details', 0, 'document_date']}
            label="Bill of Entry Date"
          >
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={ROW_GUTTER}>
        <Col span={8}>
          <Form.Item name={['import_custom_details', 0, 'filing_type']} label="Filing Type">
            <Select placeholder="Select Filling Type- Normal, Prior, Advance">
              {FILING_TYPE.map((option, index) => (
                <Select.Option key={(option.key || '') + index} value={option.value}>
                  {option.value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item noStyle shouldUpdate={true}>
            {({ getFieldValue }) => {
              const freightType = getFieldValue('freight_type');
              return (
                <Form.Item
                  className="custom-form-item"
                  name={['import_custom_details', 0, 'custom_clearance_location']}
                  label="Filing Location"
                  required
                  rules={[{ required: true }]}
                >
                  <GlobalSearch
                    doc_type="Global::Location"
                    searchProps={{
                      is_customs_location: true,
                      type:
                        freightType === FREIGHT_TYPE_AIR
                          ? [LOCATION_TYPE_AIRPORT, LOCATION_TYPE_CITY]
                          : [
                              LOCATION_TYPE_SEAPORT,
                              LOCATION_TYPE_CITY,
                              LOCATION_TYPE_CFS,
                              LOCATION_TYPE_ICD,
                              LOCATION_TYPE_RAIL_TERMINAL,
                              LOCATION_TYPE_PORT_TERMINAL,
                            ],
                    }}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        {(billOfEntryType === INBOND_BILL || billOfEntryType === EXBOND_BILL) && (
          <Col span={8}>
            <Form.Item
              required
              rules={[{ required: true }]}
              name={['import_custom_details', 0, 'warehouse_location']}
              label="Warehouse Location"
            >
              <GlobalSearch doc_type="Global::Location" />
            </Form.Item>
          </Col>
        )}
      </Row>
      {billOfEntryType === EXBOND_BILL && (
        <Row gutter={ROW_GUTTER}>
          <Col span={8}>
            <Form.Item
              required
              rules={[{ required: true }]}
              name={['import_custom_details', 0, 'warehouse_be_no']}
              label="Warehouse BE #"
            >
              <Input placeholder="Warehouse BE Number" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              required
              rules={[{ required: true }]}
              name={['import_custom_details', 0, 'warehouse_be_date']}
              label="Warehouse BE Date"
            >
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
      )}
    </>
  );
}
