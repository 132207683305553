/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree';

const Vessel = types.model({
  imo: types.string,
  name: types.string,
  vessel_type: types.maybe(types.string),
  flag_id: types.maybe(types.maybeNull(types.string)),
  mmsi: types.maybe(types.string),
  is_frequent: types.maybe(types.maybeNull(types.boolean)),
});

export interface VesselValue extends Instance<typeof Vessel> {}
export interface VesselIn extends SnapshotIn<typeof Vessel> {}
export interface VesselOut extends SnapshotOut<typeof Vessel> {}

export default Vessel;
