import React, { useState } from 'react';
import { Form, Modal } from '@shipmnts/pixel-hub';
import { FormOutlined } from '@ant-design/icons';
import SectionEditFormWrapper from '../companyDetailView/SectionEditFormWrapper';
import { useCompanyView } from './CompanyView';
import { UpsertCompanyFormValues } from '../CompanyForm';
import { SectionalEditorPropType } from './types';

export const SectionEditorModal = ({
  show,
  setShow,
  children,
  initialValues,
  title,
  onSubmit,
  modalWidth,
}: SectionalEditorPropType) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { onCompanyUpdate, company } = useCompanyView();

  return company ? (
    <Modal
      open={show}
      okText="Save"
      width={modalWidth || 800}
      centered
      onCancel={() => setShow(false)}
      title={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            lineHeight: '24px',
          }}
        >
          <FormOutlined />
          {title}
        </div>
      }
      onOk={onSubmit ? () => onSubmit(form.getFieldsValue()) : form.submit}
      confirmLoading={loading}
    >
      <SectionEditFormWrapper
        setEditMode={setShow}
        setLoading={setLoading}
        form={form}
        onSuccess={onCompanyUpdate}
        initialValues={initialValues as Partial<UpsertCompanyFormValues>}
      >
        {children && children({ form, setLoading })}
        {/* <CompanyFormBasicDetails form={form} company_id={company.id} /> */}
      </SectionEditFormWrapper>
    </Modal>
  ) : (
    <></>
  );
};
