import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Drawer, Input, message, Row, Form, Space } from '@shipmnts/pixel-hub';
import { CANCEL_PRODUCT_ORDER } from './graphql/productOrder';
import { ProductOrderValue } from 'operations/models/ProductOrder';

interface CancelProductOrderDrawerProps {
  productOrder: ProductOrderValue;
  onCancel?: () => void;
  onSuccess?: () => void;
}
const { TextArea } = Input;
export default function CancelProductOrderDrawer(props: CancelProductOrderDrawerProps) {
  const { productOrder, onCancel, onSuccess } = props;
  const [form] = Form.useForm();

  const [cancelOrder, { data, loading, error }] = useMutation(CANCEL_PRODUCT_ORDER);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (error) {
      message.error(error.message);
      return;
    }
    if (data?.cancel_product_order) {
      message.success('Order Successfully Cancelled');
      if (onSuccess) onSuccess();
    }
  }, [loading, error, data, onSuccess]);

  const onClose = () => {
    if (onCancel) onCancel();
  };
  const renderFooter = () => {
    return (
      <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
        <Button style={{ marginRight: '5px' }} onClick={onClose}>
          Close
        </Button>
        <Button type="primary" danger onClick={form.submit} className="margin-right-sm">
          Cancel Order
        </Button>
      </Space>
    );
  };
  return (
    <Drawer
      title={'Cancel Order'}
      width={500}
      onClose={onClose}
      open={true}
      footer={renderFooter()}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => {
          cancelOrder({
            variables: {
              id: productOrder?.id,
              remarks: values.remarks,
            },
          });
        }}
      >
        <Row style={{ width: '100%' }}>
          <Form.Item
            name="remarks"
            required
            rules={[{ required: true }]}
            style={{ width: '100%' }}
            label="Remarks For Cancellation"
          >
            <TextArea rows={5} style={{ width: '100%' }} />
          </Form.Item>
        </Row>
        <Row className="margin-top-lg"></Row>
      </Form>
    </Drawer>
  );
}
