import React, { useState, useEffect } from 'react';
import { Card, Button, Modal, message, Form } from '@shipmnts/pixel-hub';
import { useApolloClient, useMutation } from '@apollo/client';
import BookingRequestBasicDetails from 'operations/modules/booking/components/BookingRequestForm/BookingRequestBasicDetails';
import { UPDATE_BOOKING_REQUEST } from 'operations/modules/booking/graphql/bookingRequest';
import { UPDATE_OCEAN_TRANSPORT_ORDER } from 'operations/modules/booking/graphql/oceanTransportOrder';
import { BookingRequestOverviewProps } from './BookingRequestOverview';
import { transformUpdatePartyDetails } from 'operations/models/ShipmentParty';
import { observer } from 'mobx-react-lite';
import { clearInquiryOption, setSalesPerson } from '../BookingRequestForm/BookingRequestForm';
import { SalesPersonValue } from 'common/models/SalesPerson';
import { useSession } from 'common';

interface BookingRequestOverviewCardProps extends BookingRequestOverviewProps {
  setBrOverviewEdit: (value: boolean) => void;
}

interface UpdateBookingRequestValueType {
  booking_request: {
    id: string;
    sales_person?: SalesPersonValue;
    customer_company_id?: string;
    customer_address_id?: string;
    vendor_address_id?: string;
    high_priority?: boolean;
  };
}

const BookingRequestOverviewCard = observer(function BookingRequestOverview(
  props: BookingRequestOverviewCardProps
): JSX.Element {
  const { bookingRequest, onUpdate, setBrOverviewEdit } = props;

  const [payload, setPayload] = useState<UpdateBookingRequestValueType>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [updateAllocatedBooking, setUpdateAllocatedBooking] = useState<boolean>(false);
  const [updateBookingRequest, { data, loading, error }] = useMutation(UPDATE_BOOKING_REQUEST);
  const [
    updateOceanTransportOrder,
    { data: oceanTransportOrderData, loading: updatingOTO, error: otoError },
  ] = useMutation(UPDATE_OCEAN_TRANSPORT_ORDER);
  const sessionData = useSession();
  const client = useApolloClient();

  useEffect(() => {
    if (updateAllocatedBooking) {
      updateBookingRequest({ variables: payload });
    }
  }, [payload, updateAllocatedBooking, updateBookingRequest]);

  useEffect(() => {
    if (data?.update_booking_request?.id && !error) {
      if (updateAllocatedBooking) {
        updateOceanTransportOrder({
          variables: {
            ocean_transport_order: {
              id: bookingRequest?.ocean_transport_orders[0]?.id,
              customer_address_id: payload?.booking_request?.vendor_address_id,
            },
          },
        });
      } else {
        message.success('Customer Order Details Updated!');
        if (onUpdate) onUpdate(data?.update_booking_request);
        setBrOverviewEdit(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingRequest, data, error, payload, setBrOverviewEdit, updateAllocatedBooking]);

  useEffect(() => {
    if (oceanTransportOrderData?.update_ocean_transport_order?.id && !otoError) {
      message.success('Customer Order Details Updated!');
      if (onUpdate) onUpdate(data?.update_booking_request);
      setUpdateAllocatedBooking(false);
      setBrOverviewEdit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, oceanTransportOrderData, otoError, setBrOverviewEdit]);

  const [form] = Form.useForm();

  return (
    <Form
      layout="vertical"
      form={form}
      onValuesChange={(changedValues, allValues) => {
        setSalesPerson(form, changedValues, allValues, sessionData, client);
        clearInquiryOption(form, changedValues, allValues);
      }}
      initialValues={{
        ocean_transport_order: {
          sales_person: bookingRequest.salesPerson,
          customer: {
            party_company: bookingRequest.customerCompany,
            party_address: bookingRequest.customerAddress,
          },
          vendor: {
            party_address: bookingRequest.bookedByBranch,
          },
        },
        party: {
          shipper: bookingRequest.shipper,
          consignee: bookingRequest.consignee,
        },
        high_priority: bookingRequest.high_priority,
        quotation_number: bookingRequest.quotation_number,
        is_external_quotation_number: bookingRequest.is_external_quotation_number,
        inquiry_option: bookingRequest.inquiry_option,
        incoterm: bookingRequest.incoterm,
      }}
      onFinish={(values) => {
        const { ocean_transport_order, party } = values;
        const payload = {
          booking_request: {
            id: bookingRequest?.id,
            sales_person_id: ocean_transport_order?.sales_person?.id,
            customer_company_id: ocean_transport_order?.customer?.party_company?.id,
            customer_address_id: ocean_transport_order?.customer?.party_address?.id,
            vendor_address_id: ocean_transport_order?.vendor?.party_address?.id,
            high_priority: values?.high_priority,
            quotation_number: values?.quotation_number || null,
            inquiry_option_id: values?.inquiry_option?.id || null,
            is_external_quotation_number: values?.is_external_quotation_number,
            incoterm: values?.incoterm,
            booking_request_parties: transformUpdatePartyDetails(
              Object.assign({}, party),
              bookingRequest.booking_request_parties
            ),
          },
        };

        if (
          bookingRequest.isOneOnOneAllocated &&
          bookingRequest.bookedByBranch?.id !==
            values?.ocean_transport_order?.vendor?.party_address?.id
        ) {
          setPayload(payload);
          setModalVisible(true);
        } else {
          updateBookingRequest({ variables: payload });
        }
      }}
    >
      <Card
        title="Basic Details"
        extra={[
          <Button
            style={{ marginRight: '5px' }}
            key="cancel"
            onClick={() => setBrOverviewEdit(false)}
            disabled={loading || updatingOTO}
          >
            Cancel
          </Button>,
          <Button key="save" htmlType="submit" type="primary" loading={loading || updatingOTO}>
            Save
          </Button>,
        ]}
      >
        <BookingRequestBasicDetails load_type={bookingRequest.load_type || 'fcl'} colSpan={8} />
      </Card>
      <Modal
        title="Found one allocated booking"
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button
            key="back"
            onClick={() => setModalVisible(false)}
            disabled={loading || updatingOTO}
          >
            Close
          </Button>,
          <Button
            key="not_update_booking_order"
            onClick={() => {
              updateBookingRequest({ variables: payload });
            }}
            loading={!updateAllocatedBooking && (loading || updatingOTO)}
            disabled={updateAllocatedBooking && (loading || updatingOTO)}
          >
            No
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              setUpdateAllocatedBooking(true);
            }}
            loading={updateAllocatedBooking && (loading || updatingOTO)}
            disabled={!updateAllocatedBooking && (loading || updatingOTO)}
          >
            Update Booking Branch in Allocated Booking
          </Button>,
        ]}
      >
        <p>Do you want to change booking branch of that booking too?</p>
      </Modal>
    </Form>
  );
});

export default BookingRequestOverviewCard;
