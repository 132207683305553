/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree';

const ContainerCargoDetails = types.model({
  id: types.identifier,
  shipment_id: types.maybe(types.maybeNull(types.string)),
  shipment_container_id: types.maybe(types.maybeNull(types.string)),
  gross_volume: types.maybe(types.maybeNull(types.number)),
  gross_weight: types.maybe(types.maybeNull(types.number)),
  net_weight: types.maybe(types.maybeNull(types.number)),
  package_str: types.maybe(types.maybeNull(types.string)),
  total_number_of_packages: types.maybe(types.maybeNull(types.number)),
  type_of_package: types.maybe(types.maybeNull(types.string)),
  _destroy: types.maybe(types.maybeNull(types.boolean)),
});

export interface ContainerCargoDetailsValue extends Instance<typeof ContainerCargoDetails> {}
export interface ContainerCargoDetailsIn extends SnapshotIn<typeof ContainerCargoDetails> {}
export interface ContainerCargoDetailsOut extends SnapshotOut<typeof ContainerCargoDetails> {}
export default ContainerCargoDetails;
