import {
  ContainerDetailsValue,
  ContainerMetaInfo,
} from 'operations/modules/reports/components/ShipmentReports/ConsolShipmentFromHousesForm';

export const getContainerCapacity = (container_requests?: ContainerDetailsValue[]) => {
  let containerTypeMissing = true;
  const containerCapacity = (container_requests || []).reduce(
    (capacity: ContainerMetaInfo, cr: ContainerDetailsValue) => {
      if (containerTypeMissing && cr.container_type_code) containerTypeMissing = false;
      capacity.maximum_cargo_weight += cr.quantity * (cr.maximum_cargo_weight || 0);
      capacity.maximum_cargo_volume += cr.quantity * (cr.maximum_cargo_volume || 0);
      return capacity;
    },
    { maximum_cargo_weight: 0, maximum_cargo_volume: 0 }
  );
  return containerCapacity;
};
