// TODO: replace AG Grid react with Base Table
import { GridOptions, ColumnApi } from '@ag-grid-community/core';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useMutation, useApolloClient } from '@apollo/client';
import { FieldData } from 'rc-field-form/es/interface.d';
import {
  Row,
  Col,
  Input,
  message,
  Typography,
  Modal,
  Drawer,
  Form,
  BaseTable,
} from '@shipmnts/pixel-hub';
import { ROUTING_TYPE_MAIN_CARRIAGE } from 'operations/models/RoutingLeg';
import { map as _map, compact as _compact } from 'lodash';
import { Column, Variables, IndexableFieldType } from 'operations/models/Report';
import { CarrierValue } from 'operations/models/Carrier';
import { VesselValue } from 'operations/models/Vessel';
import { BookingRequestValue } from 'operations/models/BookingRequest';
import { ContainerRequestValue } from 'operations/models/ContainerRequest';
import BookingRequestSummaryCard from './BookingRequestSummaryCard';
import {
  VesselSearch,
  getColumnFiltersinGraphqlFormat,
  exportExcelStyles,
  errorMessageHandlerGraphQL,
} from 'common';
import { DrawerFooter } from '@shipmnts/pixel-hub';
import { fields } from 'operations/modules/reports/components/OTOReports/OTOReportFields';
import { OCEAN_TRANSPORT_ORDER_REPORT } from 'operations/modules/reports/graphql/otoReport';
import {
  renderContainerQuantityAndType,
  renderCommodity,
  renderContainerSettings,
} from 'operations/modules/reports/oldCellRenderers';
import { OTOReportData } from 'operations/modules/reports/components/OTOReports/OTOReports';
import { ALLOCATE_OCEAN_TRANSPORT_ORDERS } from 'operations/modules/booking/graphql/oceanTransportOrder';
import {
  validateAllocatedValues,
  getAllocatePayload,
  AllocationPayload,
} from 'operations/modules/reports/helpers/allocationHelper';
import AllocateContainerInputNumber from 'operations/modules/reports/components/OTOReports/AllocateContainerInputNumber';
import OTOBookingNumber from 'operations/modules/reports/components/OTOReports/OTOBookingNumber';
import { GlobalSearch } from '@shipmnts/pixel-hub';
const { Text } = Typography;
const PAGE_SIZE = 500;

type FilterValue = CarrierValue | PartialVesselValue;
export type PartialVesselValue = Omit<VesselValue, 'is_frequent'>;

const reportFields = fields as IndexableFieldType<Column>;

const renderOTOData = (data: Array<OTOReportData> | undefined) => {
  const response = (data || [])
    .filter((row) => row.voyage_number !== null || row.voyage_number !== undefined)
    .map((row: OTOReportData) => ({
      ...row,
      requested_quantity_and_type: renderContainerQuantityAndType(
        row.container_requests || [],
        'quantity'
      ),
      allocation_pending_quantity_and_type: renderContainerQuantityAndType(
        row.container_requests || [],
        'quantity_unfulfilled'
      ),
      status_text: `${row.status} ${row.status_time_duration_in_words || ''}`,
      created_by: `${row.created_by?.first_name || ''} ${row.created_by?.last_name || ''}`,
      voyage_number_text: `${row?.global_carrier?.name || ''} | ${row?.vessel?.name || ''} | ${
        row?.voyage_number || ''
      }`,
      commodity: renderCommodity(row.cargos || []),
      container_settings: renderContainerSettings(row.container_requests || []),
    }));
  return response;
};

const components = {
  allocateContainerInputNumber: AllocateContainerInputNumber,
  OTOBookingNumber,
};

const renderRemarks = (
  <Form.Item name="remarks" label="Remarks" style={{ width: '100%' }}>
    <Input.TextArea rows={2} />
  </Form.Item>
);

const AllocateOTOReport = observer(function AllocateOTOReport(props: {
  booking_request: BookingRequestValue;
  onClose: () => void;
  onSuccess?: (booking_request: BookingRequestValue, sendEmail: boolean) => void;
}): JSX.Element {
  const { booking_request, onClose, onSuccess } = props;
  const client = useApolloClient();
  const main_carriage_routing_legs =
    booking_request.carriageWiseRoutingLegs?.[ROUTING_TYPE_MAIN_CARRIAGE];

  const [otoData, setOTOData] = useState<Array<OTOReportData>>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [payloadForAllocation, setPayload] = useState<AllocationPayload>();
  const [selectedVoyageNumber, setSelectedVoyageNumber] = useState<string>();
  const gridRef = useRef<GridOptions>();
  const columnApi = useRef<ColumnApi>();
  const [allocationFormError, setAllocationError] = useState<string>();
  const [rowData, setRowData] = useState<any>();

  const [sendEmail, setSendEmail] = useState<boolean>(false);
  const [totalAllocatedQty, setTotalAllocatedQty] = useState<number>(0);
  const [
    allocateOTO,
    { data: allocateBooking, loading: allocationLoading, error: allocationError },
  ] = useMutation(ALLOCATE_OCEAN_TRANSPORT_ORDERS);

  const [filterValues, setFilterValues] = useState<{ [key: string]: Array<FilterValue> }>({
    ...(booking_request.isFullyUnallocated &&
    booking_request.preferred_carriers &&
    booking_request.preferred_carriers.length > 0
      ? { global_carrier_id: booking_request.preferred_carriers }
      : {}),
    ...(booking_request.isFullyUnallocated && main_carriage_routing_legs?.[0].vessel
      ? { vessel_id: [main_carriage_routing_legs?.[0].vessel] }
      : {}),
  });

  useEffect(() => {
    if (!allocationError && allocateBooking?.allocate_ocean_transport_orders) {
      message.success('Booking Order(s) Allocated Successfully!');
      onClose();
      if (onSuccess) onSuccess(allocateBooking.allocate_ocean_transport_orders, sendEmail);
    }
  }, [allocationError, allocateBooking, onSuccess, onClose, sendEmail]);

  const changeFilters = useCallback(
    (filter_key: string, values: FilterValue | null | undefined | Array<FilterValue>) => {
      setFilterValues((prev: { [key: string]: Array<FilterValue> }) => {
        return { ...prev, [filter_key]: values as Array<FilterValue> };
      });
    },
    []
  );

  let columnDefs = [
    reportFields.voyage_number,
    {
      headerName: 'Booking #',
      field: 'booking_number',
      colId: 'booking_number',
      width: 120,
      cellRenderer: 'OTOBookingNumber',
      cellRendererParams: {
        redirectToBase: `/view/booking_order`,
        idField: 'id',
        doNotShowChangeRequests: true,
      },
    },
    reportFields.requested_quantity_and_type,
    reportFields.allocation_pending_quantity_and_type,
  ];

  let count = 0;
  const unfulfilled_br_container_requests: ContainerRequestValue[] = [];
  _map(booking_request.container_requests, (cr: ContainerRequestValue) => {
    if (cr?.quantity_unfulfilled && cr.quantity_unfulfilled > 0) {
      unfulfilled_br_container_requests.push(cr);
      const containerSetting = renderContainerSettings([cr]);
      const headerName =
        containerSetting && containerSetting !== '-'
          ? `(${cr.quantity_unfulfilled}) Allocate ${
              cr.container_type
            } \n[${renderContainerSettings([cr])}]`
          : `(${cr.quantity_unfulfilled}) Allocate ${cr.container_type}`;
      columnDefs = columnDefs.concat({
        headerName,
        field: `allocate_container_${count++}`,
        cellRenderer: 'allocateContainerInputNumber',
        width: 200,
        cellEditorParams: { container_request: cr, selectedVoyageNumber },
      });
    }
  });

  columnDefs = columnDefs.concat(
    fields.commodity,
    fields.container_settings,
    fields.order_by,
    {
      headerName: 'Status',
      field: 'status_text',
      width: 100,
    },
    { headerName: 'Created By', field: 'created_by', width: 150 }
  );

  const Coldefs = columnDefs.map((c) => {
    const { filterKey, ...restCol } = c;
    return restCol;
  });

  const fetchData = useCallback(
    async (variables: Variables) => {
      const { data, errors } = await client.query({
        query: OCEAN_TRANSPORT_ORDER_REPORT,
        variables,
        fetchPolicy: 'network-only',
      });
      if (errors) console.error(errors);
      return {
        data: data?.ocean_transport_order_report?.data,
        total: data?.ocean_transport_order_report?.total,
        error: errors ? true : false,
      };
    },
    [client]
  );

  useEffect(() => {
    gridRef?.current?.api?.setRowData(renderOTOData(otoData) || []);
    setRowData(renderOTOData(otoData) || []);
  }, [otoData]);

  const updateDataSource = useCallback(async () => {
    const variables: Variables = {
      limit: PAGE_SIZE,
      offset: 0,
      filters: [
        {
          col: 'booking_request_id',
          condition: {
            filter_type: 'text',
            type: 'equals',
            value: booking_request.id,
          },
        },
        ...getColumnFiltersinGraphqlFormat({
          columnFilterValues: filterValues,
          reportFields,
        }),
      ],
    };
    const { data, error } = await fetchData(variables);
    gridRef?.current?.api?.hideOverlay();
    if (error) {
      gridRef?.current?.api?.showNoRowsOverlay();
    }
    setOTOData(data);
  }, [booking_request.id, fetchData, filterValues]);

  useEffect(() => {
    updateDataSource();
  }, [updateDataSource]);

  const onChangeAllocatedFields = (allFields: FieldData[]) => {
    const allocation_fields = allFields.filter(
      (field) => Array.isArray(field.name) && field.name?.[0] !== 'remarks'
    );
    const values = _compact(allocation_fields.map((field) => field.value));
    const totalAllocatedQty = values.reduce(
      (totalAllocatedQty: number, val: number) => totalAllocatedQty + (val || 0),
      0
    );
    setTotalAllocatedQty(totalAllocatedQty);
    if (!selectedVoyageNumber && values.length > 0) {
      const changedField = allFields.find((field) => field.touched);
      const changedFieldName = changedField?.name;
      let changedFieldId: string | number;
      if (Array.isArray(changedFieldName)) changedFieldId = changedFieldName[0];
      const updatedRow = (otoData || []).find((oto) => oto.id === changedFieldId);
      setSelectedVoyageNumber(updatedRow?.voyage_number);
    } else if (values.length === 0 && selectedVoyageNumber) {
      setSelectedVoyageNumber('');
    }
  };

  const validateDifferentCarrierVessel = (payload: AllocationPayload) => {
    let differentCarrier = false;
    let differentVessel = false;
    let selectedOTOId = '';
    if (Array.isArray(payload.ocean_transport_orders))
      selectedOTOId = payload.ocean_transport_orders[0]?.id;
    const selectedOTO = (otoData || []).find((oto) => oto.id === selectedOTOId);
    const preferred_carrier_ids =
      booking_request.preferred_carriers?.map((cr: CarrierValue) => cr.id) || [];
    const booking_request_vessel_imo = main_carriage_routing_legs?.[0].vessel?.imo;
    if (
      preferred_carrier_ids.length !== 0 &&
      !preferred_carrier_ids.includes(selectedOTO?.global_carrier?.id || '')
    )
      differentCarrier = true;

    if (booking_request_vessel_imo && selectedOTO?.vessel?.imo !== booking_request_vessel_imo)
      differentVessel = true;
    return { differentCarrier, differentVessel };
  };
  const [form] = Form.useForm();
  return (
    <Drawer
      title={`Confirm Customer Order By Allocating Booking`}
      width={'60%'}
      open={true}
      onClose={onClose}
      footer={
        <DrawerFooter
          saveText="Confirm"
          loading={allocationLoading}
          onClose={onClose}
          showSendEmail={booking_request.willFullyAllocatedAfterAllocation(totalAllocatedQty)}
          sendEmail={sendEmail}
          setSendEmail={setSendEmail}
          sendEmailText="Send Booking Confirmation"
          onSave={form.submit}
        />
      }
    >
      <BookingRequestSummaryCard booking_request={booking_request} />
      <Form
        form={form}
        name="booking_allocation"
        initialValues={{ remarks: booking_request.remarks }}
        //FIX ME: any
        onFinish={(values: any) => {
          const { remarks, ...allocation_values } = values;
          let error = validateAllocatedValues(
            unfulfilled_br_container_requests,
            allocation_values,
            'quantity_unfulfilled'
          );
          const allocationPayload = getAllocatePayload(
            booking_request.id,
            allocation_values,
            'booking_request_id',
            'ocean_transport_orders'
          );
          if (
            booking_request.isHazardous &&
            allocationPayload?.ocean_transport_orders?.length > 1
          ) {
            error = 'Cannot allocate more than one vendor booking to HAZ customer order';
          }
          if (error) setAllocationError(error);
          else {
            let response;
            if (booking_request.isFullyUnallocated)
              response = validateDifferentCarrierVessel(allocationPayload);
            const differentCarrier = response?.differentCarrier;
            if (differentCarrier) {
              setPayload(allocationPayload);
              setModalVisible(true);
            } else {
              const variables = { remarks, ...allocationPayload };
              allocateOTO({
                variables,
              });
            }
          }
        }}
        onFieldsChange={(changedFields: FieldData[], allFields: FieldData[]) => {
          if (allocationFormError) setAllocationError('');
          if (changedFields.length !== 0) onChangeAllocatedFields(allFields);
        }}
      >
        {booking_request.isFullyUnallocated ? (
          <Row gutter={16}>
            <Col
              span={5}
              style={{ margin: '16px 16px 16px 0' }}
              className="new-filter-select-style"
            >
              <GlobalSearch
                doc_type="Global::Carrier"
                searchProps={{ carrier_type: ['ocean', 'nvocc', 'coloader'] }}
                value={filterValues?.global_carrier_id as Array<CarrierValue>}
                selectMode="multiple"
                onChange={(value: CarrierValue | null | undefined | Array<CarrierValue>) =>
                  changeFilters('global_carrier_id', value)
                }
              />
            </Col>
            <Col
              span={5}
              style={{ width: '250px', margin: '16px 0' }}
              className="new-filter-select-style"
            >
              <VesselSearch
                value={filterValues?.vessel_id as Array<VesselValue> | undefined}
                selectMode="multiple"
                onChange={(
                  value: PartialVesselValue | null | undefined | Array<PartialVesselValue>
                ) => changeFilters('vessel_id', value)}
              />
            </Col>
            <Col span={13}>{renderRemarks}</Col>
          </Row>
        ) : (
          <Row>{renderRemarks}</Row>
        )}
        {allocationError && errorMessageHandlerGraphQL(allocationError)}
        {allocationFormError && (
          <div style={{ marginBottom: '10px' }}>
            <Text type="danger">{allocationFormError}</Text>
          </div>
        )}

        <div
          id="myGrid"
          style={{
            height: 'calc(100vh - 168px)',
            width: '100%',
            marginBottom: '44px',
          }}
          className="ag-theme-material ag-grid-editable-cell ag-grid-header-wrap"
        >
          <BaseTable
            reportName="allocate_oto_report"
            columns={Coldefs}
            rowSelection="multiple"
            reportConfig={{
              rowHeight: 36,
              overlayNoRowsTemplate: 'No Matching Booking Orders Found',
              enableCellChangeFlash: true,
              groupDisplayType: 'groupRows',
              enableCellTextSelection: true,
              maxConcurrentDatasourceRequests: 1,
              excelStyles: exportExcelStyles,
              groupDefaultExpanded: -1,
              defaultColDef: {
                resizable: true,
              },
              components,
            }}
            gridRef={gridRef}
            rowData={rowData}
            onGridReady={async (grid) => {
              gridRef.current = grid;
              columnApi.current = grid.columnApi;
            }}
          />
        </div>
      </Form>
      <Modal
        title=""
        open={modalVisible}
        onOk={() => {
          allocateOTO({ variables: payloadForAllocation });
          setModalVisible(false);
        }}
        confirmLoading={allocationLoading}
        onCancel={() => setModalVisible(false)}
      >
        <p>Are you sure you want to allocate booking order with different Carrier?</p>
      </Modal>
    </Drawer>
  );
});

export default AllocateOTOReport;
