import { Col, Row } from '@shipmnts/pixel-hub';
import React from 'react';
import ProductOrderParties from './Cards/ProductOrderParties';
// import ShipmentParties from './../Overview/Cards/ShipmentParties';
import OrderDetails from './Cards/OrderDetails';
import ShipmentDetails from './Cards/ShipmentDetails';
import CreationDetails from './Cards/CreationDetails';
import { ProductOrderValue } from 'operations/models/ProductOrder';

type OMSSummaryProps = {
  onClose?: () => void;
  externalLink?: boolean;
  product_order?: ProductOrderValue;
};

const OMSSummary = (props: OMSSummaryProps) => {
  const { product_order } = props;

  if (!product_order) return <></>;
  return (
    <Row gutter={16} className="shipment-summary">
      {/* Party Details */}
      <Col sm={24} md={12} lg={6}>
        <ProductOrderParties product_order={product_order} />
      </Col>
      {/* Order Details */}
      {
        <Col sm={24} md={12} lg={6}>
          <OrderDetails product_order={product_order} />
        </Col>
      }
      {/* Shipment Details */}
      {
        <Col sm={24} md={12} lg={6}>
          <ShipmentDetails product_order={product_order} />
        </Col>
      }
      {/* Creation Details */}
      <Col sm={24} md={12} lg={6}>
        <CreationDetails product_order={product_order} />
      </Col>
    </Row>
  );
};

export default OMSSummary;
