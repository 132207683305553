/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: only used in ops move it to ops
import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { gql, useLazyQuery } from '@apollo/client';
import { Spin, Select, Row, Col, Tag, Typography, SelectProps } from '@shipmnts/pixel-hub';
import { debounce as _debounce, omit as _omit } from 'lodash';

import { VoyageScheduleValue } from 'common/models/VoyageSchedule';

const { Option } = Select;
const { Text } = Typography;
export interface VoyageScheduleSearchProps {
  value?: VoyageScheduleValue | undefined | Array<VoyageScheduleValue>;
  onChange?: (value: VoyageScheduleValue | undefined | Array<VoyageScheduleValue>) => void;
  disabled?: boolean;
  selectMode?: 'multiple';
  selectProps?: SelectProps;
}

interface VoyageScheduleGraphqlValue extends VoyageScheduleValue {
  __typename?: string;
}

interface VoyageScheduleListItemProps {
  voyageSchedule: VoyageScheduleValue;
}

const getLabel = (voyageSchedule: VoyageScheduleValue) => {
  return `${voyageSchedule.vessel?.name} - ${voyageSchedule.voyage_number}`;
};

const getSelectValue = (value: VoyageScheduleValue | Array<VoyageScheduleValue> | undefined) => {
  if (Array.isArray(value)) {
    return value.map(
      (val) =>
        val && {
          value: val.id,
          label: getLabel(val),
          key: val.id,
        }
    );
  }
  return value
    ? {
        value: value.id,
        label: getLabel(value),
        key: value.id,
      }
    : undefined;
};

const SEARCH_VOYAGE_SCHEDULES = gql`
  query searchVoyageSchedule($term: String!) {
    search_voyage_schedule(term: $term) {
      id
      voyage_number
      vessel {
        imo
        name
      }
      carriers {
        name
      }
    }
  }
`;

const VoyageScheduleListItem = React.memo(function VoyageScheduleListItem(
  props: VoyageScheduleListItemProps
): JSX.Element {
  const { voyageSchedule } = props;
  return (
    <>
      <Row gutter={8}>
        <Col>
          <Text style={{ width: '100%', textAlign: 'center' }}>{getLabel(voyageSchedule)}</Text>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col>
          {voyageSchedule.carriers.map((carrier) => {
            return <Tag key={carrier.name}>{carrier.name}</Tag>;
          })}
        </Col>
      </Row>
    </>
  );
});

const VoyageScheduleSearch = React.memo(function VoyageScheduleSearch(
  props: VoyageScheduleSearchProps
): JSX.Element {
  const { value, onChange, disabled, selectMode } = props;
  const [voyageSchedules, setVoyageSchedules] = useState<Array<VoyageScheduleValue>>([]);
  const [selectValue, setSelectValue] = useState(getSelectValue(value));
  const [voyageScheduleInternal, setVoyageScheduleInternal] = useState(value);
  const [searchVoyageSchedules, { loading, data, error }] = useLazyQuery(SEARCH_VOYAGE_SCHEDULES);

  const fetchVoyageSchedules = useMemo(
    () =>
      _debounce(
        (term) =>
          searchVoyageSchedules({
            variables: { term },
          }),
        500
      ),
    [searchVoyageSchedules]
  );

  const onVoyageScheduleChange = useCallback(
    (
      changeValue:
        | any[]
        | { value: string; label: string; key: string }
        | ((
            prevState:
              | { value: string; label: string; key: string }[]
              | { value: string; label: string; key: string }
              | undefined
          ) =>
            | { value: string; label: string; key: string }[]
            | { value: string; label: string; key: string }
            | undefined)
        | undefined,
      data: {
        voyage_schedule: VoyageScheduleGraphqlValue | null | undefined;
        find: (
          arg0: (vs: {
            value: string;
            voyage_schedule?: VoyageScheduleGraphqlValue | undefined;
          }) => boolean
        ) => {
          (): any;
          new (): any;
          voyage_schedule: VoyageScheduleGraphqlValue | null | undefined;
        };
      }
    ) => {
      let voyageSchedule: VoyageScheduleValue | Array<VoyageScheduleValue> | undefined =
        data?.voyage_schedule
          ? _omit<VoyageScheduleGraphqlValue, '__typename'>(data.voyage_schedule, '__typename')
          : undefined;

      if (Array.isArray(changeValue)) {
        voyageSchedule = changeValue.map((val) => {
          let returnVal = null;
          if (
            Array.isArray(voyageScheduleInternal) &&
            voyageScheduleInternal.find((vs) => vs.id === val.value)
          )
            returnVal = _omit<VoyageScheduleGraphqlValue, '__typename'>(
              voyageScheduleInternal.find((c: { id: string }) => c.id === val.value),
              '__typename'
            );

          if (!returnVal)
            returnVal = _omit<VoyageScheduleGraphqlValue, '__typename'>(
              data.find(
                (vs: { value: string; voyage_schedule?: VoyageScheduleGraphqlValue }) =>
                  vs.value === val.value
              )?.voyage_schedule,
              '__typename'
            );
          return returnVal;
        });
      }

      setSelectValue(changeValue);
      if (onChange) onChange(voyageSchedule);
    },
    [voyageScheduleInternal, onChange]
  );

  useEffect(() => {
    setVoyageScheduleInternal(value);
    setSelectValue(getSelectValue(value));
  }, [value]);

  useEffect(() => {
    if (data && data.search_voyage_schedule) setVoyageSchedules(data.search_voyage_schedule);
  }, [data]);

  let notFoundContent = undefined;
  if (loading) {
    notFoundContent = <Spin size="small" />;
  } else if (error) {
    notFoundContent = (
      <div className="text-color-secondary">Unable to fetch voyages. Try searching again</div>
    );
  }

  return (
    <Select
      mode={selectMode}
      value={selectValue}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange={onVoyageScheduleChange}
      placeholder="Search for voyages..."
      notFoundContent={notFoundContent}
      filterOption={false}
      disabled={disabled}
      showSearch
      onSearch={fetchVoyageSchedules}
      allowClear
      optionLabelProp="title"
      labelInValue
      popupMatchSelectWidth={400}
      maxTagCount={3}
      {...props.selectProps}
    >
      {!loading &&
        !error &&
        voyageSchedules.map((vs) => {
          return (
            <Option title={getLabel(vs)} key={vs.id} value={vs.id} voyage_schedule={vs}>
              <VoyageScheduleListItem voyageSchedule={vs} />
            </Option>
          );
        })}
    </Select>
  );
});

export default VoyageScheduleSearch;
