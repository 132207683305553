import { SessionDataValue } from 'operations/models/SessionData';
import { PRIMARY_TYPE } from '../constants';
import { Action } from '../models';
import {
  addShipmentManifestActionRenderer,
  deleteShipmentManifestActionRenderer,
  disableAutoTrackingActionRenderer,
  editShipmentManifestActionRenderer,
  enableAutoTrackingActionRenderer,
} from './shipmentManifestActionHelper';
import { FREIGHT_TYPE_AIR } from 'operations/modules/shipment/constants';
import { INDIAN_CUSTOMS_TRACKING_REQUEST_STATUS_INACTIVE } from 'common/components/Tracking/constants';

export const enableManifestTracking = (
  shipmentManifest: any,
  session: SessionDataValue
): Action => {
  const tracking_statuses = shipmentManifest?.tracking_statuses;
  const isTrackingEnabled = tracking_statuses?.indian_customs_igm_tracking?.enabled;
  const subscription = session
    ?.getSubscriptions()
    .some((i: any) => i.app.name === 'IndianCustomsTracker');

  return {
    type: PRIMARY_TYPE,
    displayComponent: 'Enable IGM Tracking',
    key: 'enable_manifest_tracking',
    isEnable:
      subscription &&
      shipmentManifest.country === 'IN' &&
      !isTrackingEnabled &&
      !(
        shipmentManifest.shipment?.freight_type === FREIGHT_TYPE_AIR &&
        shipmentManifest.document_type === 'house'
      ),
    performAction: enableAutoTrackingActionRenderer,
  };
};
export const disableManifestTracking = (
  shipmentManifest: any,
  session: SessionDataValue
): Action => {
  const tracking_statuses = shipmentManifest?.tracking_statuses;
  const isEnabled =
    shipmentManifest.country === 'IN' &&
    tracking_statuses?.indian_customs_igm_tracking?.enabled &&
    tracking_statuses?.indian_customs_igm_tracking?.status !==
      INDIAN_CUSTOMS_TRACKING_REQUEST_STATUS_INACTIVE;
  return {
    type: PRIMARY_TYPE,
    displayComponent: 'Disable IGM Tracking',
    key: 'disable_manifest_tracking',
    isEnable: isEnabled,
    performAction: disableAutoTrackingActionRenderer,
  };
};
export const editShipmentManifest = (shipmentManifest: any, session: SessionDataValue): Action => {
  return {
    type: PRIMARY_TYPE,
    displayComponent: 'Edit Manifest Details',
    key: 'edit_shipment_manifest',
    isEnable: true,
    performAction: editShipmentManifestActionRenderer,
  };
};
export const deleteShipmentManifest = (
  shipmentManifest: any,
  session: SessionDataValue
): Action => {
  return {
    type: PRIMARY_TYPE,
    displayComponent: 'Delete Shipment Manifest',
    key: 'delete_shipment_manifest',
    isEnable: true,
    performAction: deleteShipmentManifestActionRenderer,
  };
};

export const getShipmentManifestActions = (shipmentManifest: any, session: SessionDataValue) => {
  return [
    enableManifestTracking(shipmentManifest, session),
    disableManifestTracking(shipmentManifest, session),
    editShipmentManifest(shipmentManifest, session),
    deleteShipmentManifest(shipmentManifest, session),
  ];
};

export const addShipmentManifestAction: any = ({ props }: any) => {
  return [
    {
      type: PRIMARY_TYPE,
      key: 'create_manifest',
      displayComponent: 'Add Shipment Manifest',
      isEnable: true,
      performAction: addShipmentManifestActionRenderer,
    },
  ];
};
