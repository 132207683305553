export const BOOKING_TYPE_BOOKING_REQUEST = 'booking_request';
export const BOOKING_TYPE_OCEAN_TRANSPORT_ORDER = 'ocean_transport_order';

export const MISCELLANEOUS_SERVICE_ORDERS = 'miscellaneous_service_orders';
export const CLEARANCE_SERVICE = 'clearance';
export const CARGO_INSURANCE_SERVICE = 'cargo_insurance';
export const EMPTY_CONTAINER_INSURANCE_SERVICE = 'empty_container_insurance';
export const MISCELLANEOUS_SERVICE_ORDER_LIST = [
  CLEARANCE_SERVICE,
  CARGO_INSURANCE_SERVICE,
  EMPTY_CONTAINER_INSURANCE_SERVICE,
];

export type BookingType = 'booking_request' | 'ocean_transport_order';

export const VOYAGE_SCHEDULE_PARTIAL = 'Partial';
export const VOYAGE_SCHEDULE_MATCH = 'Match';
