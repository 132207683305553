import React, { useMemo, useState } from 'react';
import { Card } from 'common';
import { useCompanyView } from '../CompanyView';
import { Button, Typography } from '@shipmnts/pixel-hub';
import { SectionEditorModal } from '../SectionEditorModal';
import { useEmailTemplatesContext } from 'common';
import CompanyEmailPreferencesForm from 'network/components/CompanyEmailPreferencesForm';

import { pick as _pick, orderBy as _orderBy } from 'lodash';
import { CompanyValue } from 'common/models/Company';
import { EmailTemplate } from 'common/commonTypeDefs';

const getFormInitialValues = (company: CompanyValue, templates?: EmailTemplate[]) => {
  const company_email_preferences =
    company.company_email_preferences?.map((st) =>
      _pick(st, ['id', 'email_template_id', 'address_id', 'to_emails', 'cc_emails'])
    ) || [];
  templates?.forEach((t) => {
    if (!company_email_preferences.find((pref) => pref.email_template_id === t.id)) {
      company_email_preferences.push({
        id: undefined,
        email_template_id: t.id,
        address_id: undefined,
        to_emails: undefined,
        cc_emails: undefined,
      });
    }
  });
  const formPayload = {
    ..._pick(company, ['id']),
    company_email_preferences,
  };
  return formPayload;
};

export const EmailPreferenceCard = () => {
  const { company, loading } = useCompanyView();
  const { templates } = useEmailTemplatesContext();
  const data = useMemo(() => company?.company_email_preferences, [company]);
  const [editMode, setEditMode] = useState(false);

  // fixed style
  const headingStyle = { display: 'block', opacity: 0.6, fontSize: '12px', fontWeight: '600' };

  return (
    <Card
      header={{ title: 'Email Preferences' }}
      style={{ width: '100%', marginInline: 'auto' }}
      loading={loading}
      Action={
        <Button
          disabled={company?.is_disabled}
          size="small"
          onClick={() => {
            if (!!!company?.is_disabled) {
              setEditMode(true);
            }
          }}
        >
          {'Edit'}
        </Button>
      }
    >
      <>
        {data && data.length > 0 ? (
          <div
            style={{
              display: 'grid',
              width: '100%',
              marginTop: '2em',
              maxHeight: '325px',
              overflowY: 'scroll',
              overflowX: 'hidden',
            }}
          >
            {data?.map((row, idx) => {
              return (
                <div
                  key={row?.id}
                  style={{
                    display: 'grid',
                    gridAutoFlow: 'column',
                    gridTemplateColumns: '20% 15% 33% 32%',
                    borderBottom: idx !== data.length - 1 ? '1px solid rgba(0, 0, 0, .1)' : '',
                    padding: '1em',
                    gap: '1em',
                  }}
                >
                  <section style={{ wordWrap: 'break-word' }}>
                    <span style={headingStyle}>Email Template</span>
                    <span>{row?.email_template?.template_name || '-'}</span>
                  </section>
                  <section style={{ wordWrap: 'break-word', alignSelf: 'start' }}>
                    <span style={headingStyle}>Address</span>
                    <Typography.Text ellipsis>
                      {row.address?.name || row.address?.city_name || row.address?.print_address}
                    </Typography.Text>
                  </section>
                  <section style={{ wordWrap: 'break-word', alignSelf: 'start' }}>
                    <span style={headingStyle}>To Emails</span>
                    <span>{row?.to_emails?.join(', ') || '-'}</span>
                  </section>

                  <section style={{ wordWrap: 'break-word', alignSelf: 'start' }}>
                    <span style={headingStyle}>CC Emails</span>
                    <span>{row?.cc_emails?.join(', ') || '-'}</span>
                  </section>
                </div>
              );
            })}
          </div>
        ) : (
          <div
            style={{ textAlign: 'center', padding: '1em', fontSize: '16px', opacity: '0.4' }}
          >{`No Data Available.`}</div>
        )}
        {editMode && company && (
          <SectionEditorModal
            title="Email Preferences"
            show={editMode}
            setShow={setEditMode}
            initialValues={getFormInitialValues(company, templates)}
            modalWidth={1200}
          >
            {({ form }) => {
              return (
                <CompanyEmailPreferencesForm
                  form={form}
                  addresses={_orderBy(
                    company?.addresses || [],
                    ['is_disabled', 'entity_type'],
                    ['asc', 'asc']
                  )}
                  templates={templates}
                  company={company}
                />
              );
            }}
          </SectionEditorModal>
        )}
      </>
    </Card>
  );
};
