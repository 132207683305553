import React, { ReactElement } from 'react';
import { Descriptions, DescriptionsProps, Typography } from '@shipmnts/pixel-hub';
import { PortValue, ShipmentValue } from 'operations/models/Shipment';
import { LocationValue } from 'operations/models/Location';
import { AddressValue } from 'operations/models/Address';
import { startCase } from 'lodash';
const { Paragraph } = Typography;

export const dividerStyles = {
  margin: '0px',
};
export const descriptionsProps: DescriptionsProps = {
  column: 1,
  colon: false,
  size: 'default',
  layout: 'horizontal',
  labelStyle: { color: '#46546D', width: '50%', lineHeight: '17px', fontSize: '12px' },
  className: 'ant-descriptions-header-custom',
  contentStyle: { lineHeight: '17px', width: '50%', fontSize: '12px' },
};

export const getPanelHeader = (icon: ReactElement | undefined, heading: string) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        color: '#586988',
        fontWeight: 600,
        fontSize: '14px',
      }}
    >
      {icon}
      <div style={{ marginLeft: '5px', textTransform: 'uppercase' }}>{heading}</div>
    </div>
  );
};
export const collapsibleCard = ({
  icon,
  header,
  data,
  extra,
}: {
  icon: ReactElement;
  header: string;
  data:
    | { [key: string]: string | React.ReactNode | null | undefined }
    | ({ [key: string]: string | React.ReactNode | null | undefined } | null)[];
  extra?: () => JSX.Element;
}) => {
  return (
    <>
      <Descriptions {...descriptionsProps} title={getPanelHeader(icon, header)}>
        {Array.isArray(data)
          ? data.map(
              (item, index) =>
                item &&
                typeof item === 'object' &&
                Object.keys(item).map((key, index) => (
                  <Descriptions.Item
                    key={index}
                    style={{ paddingBottom: '3px', lineHeight: '17px' }}
                    label={key}
                  >
                    {typeof item[key] === 'string' ? (
                      <Paragraph
                        ellipsis={typeof item[key] === 'string' ? { tooltip: true } : undefined}
                        style={{ marginBottom: '0' }}
                      >
                        {item[key] || '-'}
                      </Paragraph>
                    ) : (
                      item[key] || '-'
                    )}
                  </Descriptions.Item>
                ))
            )
          : Object.keys(data).map((key, index) => (
              <Descriptions.Item
                key={index}
                style={{ paddingBottom: '3px', lineHeight: '17px' }}
                label={key}
              >
                {typeof data[key] === 'string' ? (
                  <Paragraph
                    ellipsis={typeof data[key] === 'string' ? { tooltip: true } : undefined}
                    style={{ marginBottom: '0' }}
                  >
                    {data[key] || '-'}
                  </Paragraph>
                ) : (
                  data[key] || '-'
                )}
              </Descriptions.Item>
            ))}
      </Descriptions>
      {extra && extra()}
    </>
  );
};

export const getPortName = (
  port: PortValue | LocationValue,
  freight_type: string | null | undefined
) => {
  const printArry: any[] = [];
  if (freight_type === 'air') {
    if (port?.iata_code) {
      printArry.push(port.iata_code);
    }
  } else {
    if (port?.unlocode) {
      printArry.push(port.unlocode);
    }
  }
  if (port?.name) {
    printArry.push(port.name);
  }

  return printArry.join(' - ');
};

export const getStuffingAddress = (
  stuffing_location: {
    port?: PortValue | null;
    company_address?: AddressValue | null;
  },
  freightType: string | null | undefined
) => {
  if (stuffing_location.port) {
    return getPortName(stuffing_location.port, freightType) || '-';
  } else if (stuffing_location.company_address) {
    return stuffing_location.company_address?.print_address || '-';
  }
  return '-';
};

export const getAllPartyDetails = (shipment: ShipmentValue) => {
  const data: ({ [key: string]: string | React.ReactNode } | null)[] = [];
  const consignee = shipment.getShipmentPartyByName('consignee');
  const shipper = shipment.getShipmentPartyByName('shipper');
  const freight_forwarding_agent = shipment.getShipmentPartyByName('freight_forwarding_agent');
  const origin_agent = shipment.isImportShipment()
    ? shipment.getShipmentPartyByName('origin_agent')
    : undefined;
  const destination_agent = shipment.isExportShipment()
    ? shipment.getShipmentPartyByName('destination_agent')
    : undefined;
  const to_order = shipment.getShipmentPartyByName('to_order');
  const to_order_bank = shipment.getShipmentPartyByName('to_order_bank');
  const subagent = shipment.getShipmentPartyByName('subagent'); //Todo: move party types to constants
  const customer = shipment.customer_company;

  if (customer) {
    data.push({
      role: 'Customer',
      name: <b>{customer.registered_name}</b>,
      address: shipment.customer_address?.print_address,
    });
  }
  if (shipper && shipper?.party_company?.registered_name) {
    data.push({
      role: 'Shipper',
      name: <b>{shipper?.party_company?.registered_name}</b>,
      address: shipper.party_address?.print_address,
    });
  }
  if (consignee && consignee?.party_company?.registered_name) {
    data.push({
      role: 'Consignee',
      name: <b>{consignee?.party_company?.registered_name}</b>,
      address: consignee.party_address?.print_address,
    });
  }
  if (
    shipment.consignee_party_name === 'to_order' ||
    shipment.consignee_party_name === 'to_order_bank'
  ) {
    data.push({
      role: startCase(shipment.consignee_party_name),
      name: to_order?.party_details || to_order_bank?.party_details,
    });
  }
  if (origin_agent && origin_agent?.party_company?.registered_name) {
    data.push({
      role: 'Origin Agent',
      name: origin_agent?.party_company?.registered_name,
      address: origin_agent.party_address?.print_address,
    });
  }
  if (destination_agent && destination_agent?.party_company?.registered_name) {
    data.push({
      role: 'Destination Agent',
      name: destination_agent?.party_company?.registered_name,
      address: destination_agent.party_address?.print_address,
    });
  }
  if (subagent && subagent?.party_company?.registered_name) {
    data.push({
      role: 'Subagent',
      name: subagent?.party_company?.registered_name,
      address: subagent.party_address?.print_address,
    });
  }
  if (freight_forwarding_agent && freight_forwarding_agent?.party_company?.registered_name) {
    data.push({
      role: 'Freight Forwarding Agent',
      name: freight_forwarding_agent?.party_company?.registered_name,
      address: freight_forwarding_agent.party_address?.print_address,
    });
  }
  return data;
};
