import React, { useEffect, useState } from 'react';
import { LoadingOutlined, Space } from '@shipmnts/pixel-hub';
import { Button, Drawer, Input, message, Radio, Row, Form } from '@shipmnts/pixel-hub';
import { useMutation } from '@apollo/client';
import { CANCEL_AIR_SHIPMENT_REQUEST } from 'operations/graphql/shipment';
import {
  AIR_CANCELLATION_REASONS,
  AIR_PLANNED_CANCELLATION_REASONS,
  AIR_PLANNED_OR_HOUSE_SHIPMENT_CANCELLED_BY,
  BACK_TO_TOWN_REASONS,
  DOCUMENT_STATUS_EXECUTED,
  SHIPMENT_CANCELLED_BY,
  SHIPMENT_DOCUMENT_TYPE_HOUSE,
  SHIPMENT_RETURNED_FROM,
  SHIPMENT_STATUS_PLANNED,
} from '../../constants';
import { SHIPMENT_TYPE_CONSOL } from 'operations/modules/shipment/constants';
import { ShipmentValue } from 'operations/models/Shipment';
import { ShipmentDocumentValue } from 'operations/models/ShipmentDocument';

interface CancelShipmentDrawerProps {
  shipment: ShipmentValue;
  shipment_document_master: ShipmentDocumentValue;
  cancellationType: string;
  onCancel?: () => void;
  onSuccess?: () => void;
}
const { TextArea } = Input;
export default function CancelAirShipmentDrawer(props: CancelShipmentDrawerProps) {
  const { shipment, shipment_document_master, cancellationType, onCancel, onSuccess } = props;
  const [form] = Form.useForm();
  const isCancelling = false;
  const [showOtherCancellationReason, setShowOtherCancellationReason] = useState<boolean>(false);
  const isBackToTown = cancellationType === 'Back to Town';
  const isPlanned = shipment?.status === SHIPMENT_STATUS_PLANNED;
  const resourceType = shipment?.job_number ? 'Shipment' : isPlanned ? 'Customer Order' : 'Booking';

  const back_to_town_reasons = BACK_TO_TOWN_REASONS.map((item: { key: string; name: string }) => ({
    value: item.key,
    label: item.name,
  }));

  const air_planned_cancellation_reasons = AIR_PLANNED_CANCELLATION_REASONS.map(
    (item: { key: string; name: string }) => ({
      value: item.key,
      label: item.name,
    })
  );

  const air_cancellation_reasons = AIR_CANCELLATION_REASONS.map(
    (item: { key: string; name: string }) => ({
      value: item.key,
      label: item.name,
    })
  );

  const [
    cancelAirShipmentRequest,
    {
      data: cancelAirShipmentRequestdata,
      loading: cancelAirShipmentRequestloading,
      error: cancelAirShipmentRequesterror,
    },
  ] = useMutation(CANCEL_AIR_SHIPMENT_REQUEST);

  useEffect(() => {
    if (cancelAirShipmentRequestloading) {
      return;
    }
    if (cancelAirShipmentRequesterror) {
      message.error(cancelAirShipmentRequesterror.message);
      return;
    }
    if (cancelAirShipmentRequestdata?.cancel_air_shipment) {
      message.success('Successfully Cancelled!');
      if (onSuccess) onSuccess();
    }
  }, [
    cancelAirShipmentRequestloading,
    cancelAirShipmentRequesterror,
    cancelAirShipmentRequestdata,
    onSuccess,
  ]);

  const cancellationReasons = isBackToTown
    ? back_to_town_reasons
    : isPlanned
    ? air_planned_cancellation_reasons
    : air_cancellation_reasons;
  const actionName = isBackToTown ? 'Back to Town' : 'Cancel';

  const air_planned_or_house_shipment_cancelled_by = AIR_PLANNED_OR_HOUSE_SHIPMENT_CANCELLED_BY.map(
    (item: { key: string; name: string }) => ({ value: item.key, label: item.name })
  );
  const shipment_cancelled_by = SHIPMENT_CANCELLED_BY.map(
    (item: { key: string; name: string }) => ({
      value: item.key,
      label: item.name,
    })
  );
  const shipment_returned_from = SHIPMENT_RETURNED_FROM.map(
    (item: { key: string; name: string }) => ({
      value: item.key,
      label: item.name,
    })
  );
  const shipment_type = shipment?.shipment_type?.toLowerCase();
  const cancelledBy =
    isPlanned || shipment_type === SHIPMENT_DOCUMENT_TYPE_HOUSE
      ? air_planned_or_house_shipment_cancelled_by
      : shipment_cancelled_by;

  const isConsolMaster =
    shipment_type === SHIPMENT_TYPE_CONSOL && !!shipment?.is_from_consol_helper;

  const onClose = () => {
    if (onCancel) onCancel();
  };

  const radioStyle = { display: 'inline-grid', marginRight: '5px', marginBottom: '10px' };
  const AIR_CONSOL_CANCELLATION_HOUSE_ACTIONS = [
    {
      value: 'delink',
      label: (
        <div style={radioStyle}>
          Delink house shipments and plan on next consol
          <br />
          <i>(House shipments will then appear in consol pending list)</i>
        </div>
      ),
    },
    { value: 'cancel', label: <div style={radioStyle}>Cancel all attached house shipments</div> },
  ];

  const handleCancellationReasonChange = (event: any) => {
    event.preventDefault();
    setShowOtherCancellationReason(event?.target?.value === 'Other');
  };

  return (
    <Drawer
      title="Cancel Shipment"
      width={500}
      onClose={onClose}
      open={true}
      footer={
        <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
          <Button style={{ marginRight: '5px' }} onClick={onClose}>
            Close
          </Button>
          <Button
            type="primary"
            danger
            disabled={isCancelling}
            onClick={form.submit}
            className="margin-right-sm"
          >
            {isCancelling ? `Cancelling ${resourceType}..` : `${actionName} ${resourceType}`}
            {isCancelling ? <LoadingOutlined /> : null}
          </Button>
        </Space>
      }
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => {
          let cancellation_reason = values?.cancellation_reason;
          if (values.cancellation_reason === 'Other') {
            cancellation_reason = values?.other_cancellation_reason;
          }

          cancelAirShipmentRequest({
            variables: {
              id: shipment?.id,
              returned_from: values?.returned_from,
              cancellation_reason: cancellation_reason,
              cancelled_by: values?.cancelled_by,
              back_to_town: isBackToTown,
              action_for_houses: values?.action_for_houses,
            },
          });
        }}
      >
        {isConsolMaster && (
          <Row>
            <div style={{ background: '#FFF1F0', color: '#C23934', padding: '15px' }}>
              Cancelling this consol shipment will affect the attached houses as well
            </div>
            <Form.Item
              required
              initialValue={
                isBackToTown
                  ? AIR_CONSOL_CANCELLATION_HOUSE_ACTIONS[1].value
                  : AIR_CONSOL_CANCELLATION_HOUSE_ACTIONS[0].value
              }
              name="action_for_houses"
              label="Cancellation of houses"
            >
              <Radio.Group
                options={AIR_CONSOL_CANCELLATION_HOUSE_ACTIONS}
                name="action_for_house_radio"
              />
            </Form.Item>
          </Row>
        )}
        <Row>
          <Form.Item
            required
            initialValue={cancelledBy[0].value}
            name="cancelled_by"
            label={isBackToTown ? 'Back To Town By' : 'Cancelled By'}
          >
            <Radio.Group options={cancelledBy} name="action_for_house_radio" />
          </Form.Item>
        </Row>
        {isBackToTown && (
          <Row>
            <Form.Item
              initialValue={
                shipment_document_master?.document_status === DOCUMENT_STATUS_EXECUTED
                  ? shipment_returned_from[1].value
                  : shipment_returned_from[0].value
              }
              required
              name="returned_from"
              label={`${cancellationType} From`}
            >
              <Radio.Group options={shipment_returned_from} name="action_for_house_radio" />
            </Form.Item>
          </Row>
        )}
        <Row>
          <Form.Item
            initialValue={cancellationReasons[0].value}
            name="cancellation_reason"
            label={`${cancellationType} Reason`}
          >
            <Radio.Group
              options={cancellationReasons}
              name="action_for_house_radio"
              onChange={handleCancellationReasonChange}
            />
          </Form.Item>
          {showOtherCancellationReason && (
            <Form.Item
              required
              name="other_cancellation_reason"
              label={`${cancellationType} Reason`}
            >
              <TextArea
                rows={5}
                style={{ width: '400px' }}
                placeholder={isBackToTown ? 'Back To Town Reason' : 'Cancellation Reason'}
              />
            </Form.Item>
          )}
        </Row>
        <Row className="margin-top-lg"></Row>
      </Form>
    </Drawer>
  );
}
