import { Form, FormInstance } from '@shipmnts/pixel-hub';
import RoutingDetails from 'operations/components/RoutingDetails';
import { ShipmentValue } from 'operations/models/Shipment';
import React, { useEffect, useRef } from 'react';
import {
  MOVEMENT_MODE_AIR_EXPORT,
  MOVEMENT_MODE_AIR_IMPORT,
  MOVEMENT_MODE_DOOR_TO_DOOR,
  MOVEMENT_MODE_OCEAN_EXPORT,
  MOVEMENT_MODE_OCEAN_IMPORT,
} from '../../constants';
import { CompanyValue } from 'operations/models/Company';
import { AddressValue } from 'operations/models/Address';
import { LocationValue } from 'operations/models/Location';

interface RoadRoutingDetailsProps {
  shipment?: ShipmentValue;
  form?: FormInstance;
  allowAddNew?: boolean;
}
export default function RoadRoutingDetails(props: RoadRoutingDetailsProps) {
  const { form, shipment, allowAddNew = true } = props;
  const routing_details_ref = useRef<{ runValidation: () => boolean; addHop: () => void }>();
  const movementMode = Form.useWatch('movement_mode', form);
  const shipper = Form.useWatch(['party', 'shipper'], form);
  const consignee = Form.useWatch(['party', 'consignee'], form);
  const airMovementMode = [MOVEMENT_MODE_AIR_EXPORT, MOVEMENT_MODE_AIR_IMPORT].includes(
    movementMode
  );

  useEffect(() => {
    if (
      allowAddNew &&
      (movementMode === MOVEMENT_MODE_OCEAN_EXPORT ||
        movementMode === MOVEMENT_MODE_OCEAN_IMPORT) &&
      (form?.getFieldValue('routing_details')?.routing_legs?.length || 0) <= 1
    ) {
      routing_details_ref?.current?.addHop();
    }
  }, [movementMode, form, shipment, allowAddNew]);

  const getRoutingData = (
    company: CompanyValue | undefined,
    address: AddressValue | undefined,
    location: LocationValue | undefined
  ) => {
    return {
      company: company,
      address: address,
      location: location,
    };
  };

  return (
    <Form.Item
      noStyle
      dependencies={[['party', 'shipper'], ['party', 'consignee'], 'linked_to_shipment']}
    >
      {({ getFieldValue }) => {
        if (!shipment?.id) {
          const routingValue = getFieldValue('routing_details');
          const originNode = routingValue?.routing_legs?.[0];
          const routingLegsLength = routingValue?.routing_legs?.length || 0;
          const destinationNode = routingValue?.routing_legs?.[routingLegsLength - 1];
          const originId = originNode?.origin_id;
          const destinationId = destinationNode?.destination_id;
          const linkShipmentData = getFieldValue('linked_to_shipment');
          if (
            [
              MOVEMENT_MODE_DOOR_TO_DOOR,
              MOVEMENT_MODE_AIR_EXPORT,
              MOVEMENT_MODE_AIR_IMPORT,
            ].includes(movementMode)
          ) {
            if (!originId || !destinationId) return;

            routingValue.routing_nodes[originId] = {
              ...routingValue.routing_nodes[originId],
              address: shipper?.party_address,
              company: shipper?.party_company,
            };
            routingValue.routing_nodes[destinationId] = {
              ...routingValue.routing_nodes[destinationId],
              address: consignee?.party_address,
              company: consignee?.party_company,
            };
            form?.setFieldValue('routing_details', routingValue);
          } else if (movementMode === MOVEMENT_MODE_OCEAN_EXPORT) {
            const transhipmentId = originNode?.destination_id;
            routingValue.routing_nodes[transhipmentId] = {
              ...routingValue.routing_nodes[transhipmentId],
              address: shipper?.party_address,
              company: shipper?.party_company,
            };
            routingValue.routing_nodes[originId] = {
              ...routingValue.routing_nodes[originId],
              ...getRoutingData(
                undefined,
                undefined,
                routingValue.routing_nodes[originId]?.location
              ),
            };
            routingValue.routing_nodes[destinationId] = {
              ...routingValue.routing_nodes[destinationId],
              ...getRoutingData(undefined, undefined, linkShipmentData?.port_of_loading),
            };
          } else if (movementMode === MOVEMENT_MODE_OCEAN_IMPORT) {
            const transhipmentId = originNode?.destination_id;
            routingValue.routing_nodes[transhipmentId] = {
              ...routingValue.routing_nodes[transhipmentId],
              address: consignee?.party_address,
              company: consignee?.party_company,
            };
            routingValue.routing_nodes[originId] = {
              ...routingValue.routing_nodes[originId],
              ...getRoutingData(undefined, undefined, linkShipmentData?.port_of_discharge),
            };
            routingValue.routing_nodes[destinationId] = {
              ...routingValue.routing_nodes[destinationId],
              ...getRoutingData(
                undefined,
                undefined,
                routingValue.routing_nodes[destinationId]?.location
              ),
            };
          }
        }
        return (
          <Form.Item
            name="routing_details"
            noStyle
            rules={[
              {
                validator: (rule, value) => {
                  if (!routing_details_ref?.current?.runValidation()) {
                    return Promise.resolve();
                  }
                  return Promise.reject();
                },
              },
            ]}
          >
            <RoutingDetails
              ref={routing_details_ref}
              locationSearchType={
                airMovementMode
                  ? ['Airport', 'City']
                  : ['CFS', 'ICD', 'PortTerminal', 'Seaport', 'City']
              }
              startSequence={2}
              endSequence={3}
              routing_type="pickup"
              showTerminal={true}
            />
          </Form.Item>
        );
      }}
    </Form.Item>
  );
}
