import React, { useState } from 'react';
import { Badge, CustomIcon } from '@shipmnts/pixel-hub';
import { ShipmentValue } from 'operations/models/Shipment';
import ShipmentCreditStatusTimeLine from './ShipmentCreditStatusTimeLine';
import { startCase } from 'lodash';
import {
  CREDIT_STATUS_ON_AUTO_HOLD,
  CREDIT_STATUS_ON_HOLD,
} from 'operations/modules/shipment/constants';
type Props = {
  shipment: ShipmentValue;
};

function CreditStatusWithTracker({ shipment }: Props) {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <div
      style={{
        paddingBottom: '5%',
        background: [CREDIT_STATUS_ON_AUTO_HOLD, CREDIT_STATUS_ON_HOLD].includes(
          shipment?.credit_status || ''
        )
          ? '#ffe9e8'
          : '#ffffff',
      }}
    >
      <div>Credit </div>
      <div style={{ display: 'flex' }}>
        <div style={{ fontSize: '12px', color: '#cc933e' }}>
          <Badge color={'#cc933e'} /> {startCase(shipment.credit_status || '')}{' '}
        </div>{' '}
        <span style={{ marginLeft: '2px' }}>
          <CustomIcon icon="TrackerIcon" onClick={() => setCollapsed(!collapsed)} />
        </span>
      </div>

      {!collapsed && (
        <ShipmentCreditStatusTimeLine
          shipment={shipment}
          onClose={() => setCollapsed(!collapsed)}
        />
      )}
    </div>
  );
}

export default CreditStatusWithTracker;
