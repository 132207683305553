import React, { useEffect, useRef } from 'react';
import { Drawer, Card, message, Form, DrawerFooter } from '@shipmnts/pixel-hub';
import { FormValueType, getInitalRoutingNodes, getInitialRoutingLegs } from './VoyageScheduleForm';
import VoyageSchedule, { VoyageScheduleValue } from 'operations/models/VoyageSchedule';
import VoyageSchedulePortCallDetails, {
  startSequence,
  endSequence,
} from './VoyageSchedulePortCallDetails';
import { uniqueId as _uniqueId } from 'lodash';
import { useMutation } from '@apollo/client';
import { UPDATE_VOYAGE_SCHEDULE } from '../../graphql/voyageSchedule';
import { RoutingLegValue } from 'operations/models/RoutingLeg';
import { LocationValue } from 'operations/models/Location';
import { RoutingNodeValue, cutoffDateFields } from 'operations/models/RoutingNode';
import { convertDatesTounix, Dayjs } from '@shipmnts/pixel-hub';
import { CarrierValue } from 'operations/models/Carrier';

const getFinalPayload = (values: FormValueType, voyageSchedule: VoyageScheduleValue) => {
  const { routing_details } = values;
  const { carriers, vessel, voyage_number, routing_legs } = voyageSchedule;
  const initialRoutingLegs = getInitialRoutingLegs(routing_legs);
  const initialRoutingNodes = getInitalRoutingNodes(routing_legs);

  const updateRoutingLegs = routing_details?.routing_legs || [];
  const updateRoutingNodes = Object.values(
    { ...initialRoutingNodes, ...routing_details?.routing_nodes } || {}
  );

  let finalRoutingLegs = [...(initialRoutingLegs || []), ...updateRoutingLegs];
  finalRoutingLegs = finalRoutingLegs.sort((a, b) =>
    (a?.estimated_time_of_departure || 0) > (b?.estimated_time_of_departure || 0) ? 1 : -1
  );
  const instertedLegIndex = finalRoutingLegs.findIndex((rl) => !rl.sequence_number);
  if (instertedLegIndex !== -1) {
    finalRoutingLegs[instertedLegIndex].sequence_number =
      ((finalRoutingLegs[instertedLegIndex - 1]?.sequence_number || startSequence) +
        (finalRoutingLegs[instertedLegIndex + 1]?.sequence_number || endSequence)) /
      2;
  }
  const carrierIds = carriers?.map((carrier: CarrierValue) => carrier.id);

  return {
    carrier_ids: carrierIds,
    vessel_id: vessel?.imo,
    voyage_number,
    routing_legs: (finalRoutingLegs || []).map((rl: RoutingLegValue) => {
      const {
        vessel,
        estimated_time_of_departure,
        estimated_time_of_arrival,
        global_carrier,
        ...restRL
      } = rl;
      return {
        ...restRL,
        global_carrier_id: global_carrier?.id,
        estimated_time_of_departure: estimated_time_of_departure?.unix(),
        estimated_time_of_arrival: estimated_time_of_arrival?.unix(),
        origin: undefined,
        destination: undefined,
        voyage_schedule_id: undefined,
      };
    }),
    routing_nodes: Object.values(updateRoutingNodes || {}).map((rn: RoutingNodeValue) => {
      const { location, company, address, terminal, ...restRN } = rn;
      return {
        ...restRN,
        location_id: location?.id,
        terminal_id: terminal?.id || null,
        ...convertDatesTounix(restRN, cutoffDateFields),
        tags: [],
      };
    }),
  };
};

const VoyageScheduleAddPortCallDrawer = React.memo(function VoyageScheduleAddPortCallDrawer(props: {
  visible: boolean;
  onCloseDrawer: () => void;
  voyageSchedule: VoyageScheduleValue;
  initialVoyageScheduleFormData: {
    portCall?: LocationValue | null;
    terminal?: LocationValue | null;
    estimated_time_of_departure?: Dayjs | null;
  };
  onSuccess?: (value: VoyageScheduleValue, type: string) => void;
}) {
  const { visible, onCloseDrawer, onSuccess, voyageSchedule, initialVoyageScheduleFormData } =
    props;
  const { portCall, terminal, estimated_time_of_departure } = initialVoyageScheduleFormData;
  const [form] = Form.useForm();
  const routing_details_ref = useRef<{ runValidation: () => boolean }>();

  // const voyage_schedule_data = voyageSchedule;
  const origin_id = _uniqueId();
  const initialValues = {
    routing_legs: [
      {
        origin_id: origin_id,
        origin: {
          _id: origin_id,
          location: portCall,
          location_id: portCall?.id,
          terminal: terminal,
          terminal_id: terminal?.id,
        },
        estimated_time_of_departure: estimated_time_of_departure,
      },
    ] as unknown as Array<RoutingLegValue>,
  };

  const [upsertVoyageSchedule, { data, loading, error }] = useMutation(UPDATE_VOYAGE_SCHEDULE);

  useEffect(() => {
    if (!error && data?.update_voyage_schedule) {
      const updated_voyage_schedule_data = VoyageSchedule.create(data?.update_voyage_schedule);
      message.success('Voyage Schedule updated !');
      if (onSuccess) onSuccess(updated_voyage_schedule_data, 'updated');
    }
  }, [form, error, data, onSuccess]);

  return (
    <Drawer
      title={`Add Port Call to Voyage Schedule ${voyageSchedule.voyage_number}`}
      width="80%"
      open={visible}
      maskClosable={false}
      styles={{ body: { padding: '0px' } }}
      onClose={onCloseDrawer}
      destroyOnClose
      footer={
        <DrawerFooter
          loading={loading}
          saveText="Save"
          onClose={onCloseDrawer}
          onSave={form.submit}
        />
      }
    >
      <Form
        onFinish={(values) => {
          const foundError = Boolean(routing_details_ref?.current?.runValidation());
          if (!foundError) {
            const payload = getFinalPayload(values, voyageSchedule);
            const variables = { id: voyageSchedule.id, voyage_schedule: payload };
            upsertVoyageSchedule({ variables });
          }
        }}
        scrollToFirstError
        name="add_port_call"
        form={form}
        layout="vertical"
        style={{
          height: '100%',
        }}
        initialValues={{
          routing_details: {
            routing_nodes: getInitalRoutingNodes(initialValues?.routing_legs),
            routing_legs: getInitialRoutingLegs(initialValues?.routing_legs),
          },
        }}
      >
        <Card title="Port of Call Details">
          <Form.Item name="routing_details" noStyle>
            <VoyageSchedulePortCallDetails
              hideAddRemovePortCall
              ref={routing_details_ref}
              isEdit={false}
            />
          </Form.Item>
        </Card>
      </Form>
    </Drawer>
  );
});

export default VoyageScheduleAddPortCallDrawer;
