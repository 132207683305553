import React from 'react';
import {
  TagsOutlined,
  CustomIcon,
  // FilePdfOutlined,
  EditOutlined,
  FolderAddOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
} from '@shipmnts/pixel-hub';
import {
  cancelTransactionRenderer,
  enableDisableWmsProductRenderer,
  performAddChild,
  performCreateGoodsReceiptAction,
  performDeleteWarehouseUnit,
  performEditWarehouseUnit,
  performEditWmsProduct,
  // performPrintTransaction,
} from './WMSActionRenderer';
import {
  NEGATIVE_TYPE,
  PRIMARY_TYPE,
  // SECONDARY_TYPE,
  TERTIARY_TYPE,
} from '../actionHelper/constants';

import { Action } from '../actionHelper/models';
import { SessionDataValue } from 'operations/models/SessionData';
import { ItemValue } from 'common/models/Item';

const createGoodsReceipt = (
  transaction: any,
  nextTransaction: any,
  sessionData: SessionDataValue
): Action => {
  const total_available_qty =
    transaction?.warehouse_transaction_products?.reduce(
      (acc: any, curr: any) => acc + curr.available_qty,
      0
    ) || 0;

  return {
    key: 'create_goods_receipt',
    type: PRIMARY_TYPE,
    displayComponent: 'Create Goods Receipt',
    icon: <TagsOutlined />,
    isEnable: transaction?.status !== 'cancelled' && total_available_qty !== 0,
    extraProps: {
      nextTransaction: nextTransaction,
    },
    performAction: performCreateGoodsReceiptAction,
    description: 'Create Goods Receipt',
  };
};

const createGoodsIssue = (
  transaction: any,
  nextTransaction: any,
  sessionData: SessionDataValue
): Action => {
  const total_available_qty =
    transaction?.warehouse_transaction_products?.reduce(
      (acc: any, curr: any) => acc + curr.available_qty,
      0
    ) || 0;
  return {
    key: 'create_goods_issue',
    type: PRIMARY_TYPE,
    displayComponent: 'Create Goods Issue',
    icon: <TagsOutlined />,
    isEnable: transaction?.status !== 'cancelled' && total_available_qty !== 0,
    extraProps: {
      nextTransaction: nextTransaction,
    },
    performAction: performCreateGoodsReceiptAction,
    description: 'Create Goods Issue',
  };
};

const createGoodsDelivery = (
  transaction: any,
  nextTransaction: any,
  sessionData: SessionDataValue
): Action => {
  const total_available_qty =
    transaction?.warehouse_transaction_products?.reduce(
      (acc: any, curr: any) => acc + curr.available_qty,
      0
    ) || 0;
  return {
    key: 'create_goods_delivery',
    type: PRIMARY_TYPE,
    displayComponent: 'Create Goods Delivery',
    icon: <TagsOutlined />,
    isEnable: transaction?.status !== 'cancelled' && total_available_qty !== 0,
    extraProps: {
      nextTransaction: nextTransaction,
    },
    performAction: performCreateGoodsReceiptAction,
    description: 'Create Goods Delivery',
  };
};

export const cancelTransaction = (transaction: any): Action => {
  const total_issued_qty =
    transaction?.warehouse_transaction_products?.reduce(
      (acc: any, curr: any) => acc + curr.issued_qty,
      0
    ) || 0;
  return {
    type: NEGATIVE_TYPE,
    key: 'cancel_transaction',
    icon: <CustomIcon icon="FileTimesIcon" />,
    displayComponent: 'Cancel Transaction',
    isEnable: total_issued_qty === 0 && transaction?.status !== 'cancelled',
    performAction: cancelTransactionRenderer,
  };
};

// const printTransaction = (docType: any): Action => {
//   return {
//     key: 'print_order',
//     type: SECONDARY_TYPE,
//     displayComponent: 'Share',
//     isEnable: true,
//     icon: <FilePdfOutlined />,
//     extraProps: { docType: docType },
//     performAction: performPrintTransaction,
//     description: 'Share Transaction',
//   };
// };

const addChildUnit = (warehouseUnit: any): Action => {
  return {
    key: 'add_child_unit',
    type: PRIMARY_TYPE,
    displayComponent: 'Add Child',
    isEnable: true,
    icon: <FolderAddOutlined />,
    extraProps: { warehouseUnit: warehouseUnit },
    performAction: performAddChild,
    description: 'Add Child',
  };
};

const editWarehouseUnit = (warehouseUnit: any): Action => {
  return {
    key: 'edit_child_unit',
    type: PRIMARY_TYPE,
    displayComponent: 'Edit',
    isEnable: true,
    icon: <EditOutlined />,
    extraProps: { warehouseUnit: warehouseUnit },
    performAction: performEditWarehouseUnit,
    description: 'Edit',
  };
};

export const enableDisableWmsProduct = (wmsProduct: any): Action => {
  return {
    type: !wmsProduct?.disabled ? NEGATIVE_TYPE : TERTIARY_TYPE,
    key: 'enable_disable',
    displayComponent: !wmsProduct?.disabled ? 'Disable' : 'Enable',
    icon: !wmsProduct?.disabled ? <DeleteOutlined /> : <CheckCircleOutlined />,
    isEnable: true,
    performAction: enableDisableWmsProductRenderer,
  };
};

const editWmsProduct = (warehouseUnit: any): Action => {
  return {
    key: 'edit_child_unit',
    type: PRIMARY_TYPE,
    displayComponent: 'Edit',
    isEnable: true,
    icon: <EditOutlined />,
    performAction: performEditWmsProduct,
    description: 'Edit',
  };
};

export const deleteWarehouseUnit = (warehouseUnit: any): Action => {
  return {
    type: NEGATIVE_TYPE,
    key: 'delete_warehouse_unit',
    displayComponent: 'Delete',
    icon: <DeleteOutlined />,
    isEnable: !warehouseUnit?.child_unit_ids?.length && warehouseUnit?.node_type !== 'warehouse',
    extraProps: { warehouseUnit: warehouseUnit },
    performAction: performDeleteWarehouseUnit,
  };
};

export const createTransactionAction = (transaction: any, sessionData: SessionDataValue) => {
  if (transaction.type === 'advance_receipt') {
    return [createGoodsReceipt(transaction, 'goods_receipt', sessionData)];
  } else if (transaction.type === 'goods_receipt') {
    return [
      createGoodsIssue(transaction, 'goods_issued', sessionData),
      createGoodsDelivery(transaction, 'goods_delivery', sessionData),
      // printTransaction('Wms::GoodsReceivedNote'),
    ];
  } else if (transaction.type === 'goods_issued') {
    return [createGoodsDelivery(transaction, 'goods_delivery', sessionData)];
  }
  return [];
  // return [printTransaction('Wms::GoodsDeliveryNote')];
};

export const getWmsTransactionActions = (transaction: any, sessionData: SessionDataValue) => {
  return [createTransactionAction(transaction, sessionData), cancelTransaction(transaction)].flat();
};

export const getWmsWarehouseUnitActions = (warehouseUnit: any, sessionData: SessionDataValue) => {
  return [
    addChildUnit(warehouseUnit),
    editWarehouseUnit(warehouseUnit),
    deleteWarehouseUnit(warehouseUnit),
  ].flat();
};

export const getWmsProductActions = (wmsProduct: ItemValue, sessionData: SessionDataValue) => {
  return [editWmsProduct(wmsProduct), enableDisableWmsProduct(wmsProduct)].flat();
};
