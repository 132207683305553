import React, { useState } from 'react';
import { Card } from 'common';
import { useCompanyView } from '../CompanyView';
import { WithPermission } from '@shipmnts/pixel-hub';
import { PERMISSION_CUSTOMER_CREATE_EDIT, PERMISSION_CUSTOMER_VIEW } from 'network/permissions';
import { SectionEditorModal } from '../SectionEditorModal';
import CreditControlDetailsForm from 'network/components/CreditControlDetailsForm';
import { getFormInitialValues } from 'network/components/companyDetailView/CreditControlDetails';
import { Button } from '@shipmnts/pixel-hub';

export const CreditControlCard = ({
  loading,
  erpnextConfigData,
}: {
  loading?: boolean;
  erpnextConfigData?: any;
}) => {
  const { company, accounts_data } = useCompanyView();
  const [editMode, setEditMode] = useState(false);
  const data = {
    'Control Type': accounts_data?.receivable_credit_control_type || '-',
    'Credit Limit': accounts_data?.receivable_credit_limit || '-',
    'Credit Days': accounts_data?.receivable_payment_terms || '-',
    'Credit Overdue Limit': accounts_data?.credit_overdue_limit || '-',
  };

  return (
    <WithPermission permission={{ name: PERMISSION_CUSTOMER_VIEW, docType: 'Network::Company' }}>
      <Card
        header={{ title: 'Credit Control Details' }}
        loading={loading}
        style={{ width: '33%' }}
        Action={
          <WithPermission
            permission={{ name: PERMISSION_CUSTOMER_CREATE_EDIT, docType: 'Network::Company' }}
          >
            <Button
              disabled={company?.is_disabled}
              onClick={() => !!!company?.is_disabled && setEditMode(true)}
              size="small"
            >
              {'Edit'}
            </Button>
          </WithPermission>
        }
      >
        <div
          style={{
            display: 'grid',
            width: '100%',
            marginTop: '2em',
          }}
        >
          {Object.entries(data)
            .filter(([_, value]) => value)
            .map(([label, value]) => {
              return (
                <div
                  key={label}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '1em',
                    alignItems: 'center',
                  }}
                >
                  <span
                    style={{
                      fontWeight: '800px',
                      opacity: '.6',
                      width: '50%',
                    }}
                  >
                    {label}
                  </span>
                  <span
                    style={{
                      width: '40%',
                    }}
                  >
                    {value}
                  </span>
                </div>
              );
            })}
        </div>
      </Card>
      {editMode && company && (
        <SectionEditorModal
          title="Credit Control"
          show={editMode}
          setShow={setEditMode}
          initialValues={getFormInitialValues(company, accounts_data)}
        >
          {({ form }) => {
            return (
              <>
                <CreditControlDetailsForm form={form} erpnextConfigData={erpnextConfigData} />
              </>
            );
          }}
        </SectionEditorModal>
      )}
    </WithPermission>
  );
};
