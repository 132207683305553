import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import {
  Layout,
  Button,
  Card,
  message,
  Skeleton,
  Typography,
  Modal,
  Checkbox,
  Alert,
  Row,
  Col,
  Form,
  Tooltip,
} from '@shipmnts/pixel-hub';
import { PageHeader } from '@shipmnts/pixel-hub';
import { MailTwoTone } from '@shipmnts/pixel-hub';
import { useParams, useLocation } from 'wouter';
import { useMutation, useQuery } from '@apollo/client';
import {
  CREATE_VOYAGE_SCHEDULE,
  UPDATE_VOYAGE_SCHEDULE,
  FETCH_VOYAGE_SCHEDULE_BY_ID,
} from 'operations/modules/booking/graphql/voyageSchedule';
import { AppHelmet } from '@shipmnts/pixel-hub';
import { errorMessageHandlerGraphQL } from 'common';
import VoyageScheduleBasicDetails from './VoyageScheduleBasicDetails';
import VoyageSchedulePortCallDetails from './VoyageSchedulePortCallDetails';
import { RoutingLegValue } from 'operations/models/RoutingLeg';
import { cutoffDateFields, RoutingNodeValue } from 'operations/models/RoutingNode';
import { RoutingNodesHashValue } from 'operations/components/RoutingDetails';
import { convertDatesTounix } from '@shipmnts/pixel-hub';
import VoyageSchedule, {
  VoyageScheduleValue,
  VoyageScheduleInitialValue,
} from 'operations/models/VoyageSchedule';
import { VesselValue } from 'operations/models/Vessel';
import { CarrierValue } from 'operations/models/Carrier';
import _isEqual from 'lodash/isEqual';
import { CustomIcon } from '@shipmnts/pixel-hub';
import { cast, getSnapshot } from 'mobx-state-tree';

const { Header, Content } = Layout;
const { Text } = Typography;

type DestroyInterface<T> = T & {
  _destroy?: boolean;
};

export interface FormValueType {
  vessel?: VesselValue;
  voyage_number?: string;
  service_name?: string;
  carriers?: Array<CarrierValue>;
  routing_details?: {
    routing_nodes?: RoutingNodesHashValue | Array<RoutingNodeValue>;
    routing_legs?: Array<RoutingLegValue>;
  };
  send_email?: boolean;
}

export const getVoyageSchedulePayload = (
  values: FormValueType,
  initialValues: VoyageScheduleValue | undefined
) => {
  const { carriers, service_name, vessel, voyage_number, routing_details } = values;
  const previousRoutingLegs = getInitialRoutingLegs(initialValues?.routing_legs) || [];
  const previousRoutingNodes = Object.values(
    getInitalRoutingNodes(initialValues?.routing_legs) || {}
  );
  const updateRoutingLegs = routing_details?.routing_legs || [];
  const updateRoutingNodes = Object.values(routing_details?.routing_nodes || {});

  previousRoutingLegs.forEach((rl: RoutingLegValue) => {
    if (!updateRoutingLegs.find((l: RoutingLegValue) => l.id === rl.id))
      updateRoutingLegs.push({ ...rl, _destroy: true } as DestroyInterface<RoutingLegValue>);
  });
  previousRoutingNodes.forEach((rn: RoutingNodeValue) => {
    if (!updateRoutingNodes.find((n: any) => n.id === rn.id)) {
      updateRoutingNodes.push({ ...rn, _destroy: true } as DestroyInterface<RoutingNodeValue>);
    }
  });
  const carrierIds = carriers?.map((carrier: CarrierValue) => carrier.id);
  return {
    carrier_ids: carrierIds,
    vessel_id: vessel?.imo,
    service_name,
    voyage_number,
    routing_legs: (updateRoutingLegs || [])
      .sort((a, b) =>
        (a?.estimated_time_of_departure || 0) > (b?.estimated_time_of_departure || 0) ? 1 : -1
      )
      .map((rl: RoutingLegValue, index: number) => {
        const {
          vessel,
          estimated_time_of_departure,
          estimated_time_of_arrival,
          actual_time_of_departure,
          sequence_number,
          global_carrier,
          ...restRL
        } = rl;
        return {
          ...restRL,
          global_carrier_id: global_carrier?.id,
          estimated_time_of_departure: estimated_time_of_departure?.unix(),
          estimated_time_of_arrival: estimated_time_of_arrival?.unix(),
          actual_time_of_departure: actual_time_of_departure?.unix(),
          sequence_number: index + 1,
          origin: undefined,
          destination: undefined,
          voyage_schedule_id: undefined,
        };
      }),
    routing_nodes: Object.values(updateRoutingNodes || {}).map((rn: RoutingNodeValue) => {
      const { location, company, address, terminal, ...restRN } = rn;
      return {
        ...JSON.parse(JSON.stringify(restRN)),
        location: undefined,
        company: undefined,
        address: undefined,
        terminal: undefined,
        location_id: location?.id,
        terminal_id: terminal?.id || null,
        ...convertDatesTounix(restRN, cutoffDateFields),
        tags: [],
        id: rn?.id, //FIXME : mobx-state-tree issue
        _id: rn?._id,
      };
    }),
  };
};

export const getInitalRoutingNodes = (routing_legs?: Array<RoutingLegValue>) => {
  return routing_legs?.reduce((acc: RoutingNodesHashValue, rl: RoutingLegValue) => {
    if (rl?.origin?.id) acc[rl.origin.id] = rl.origin;
    else if (rl?.origin?._id) acc[rl.origin._id] = { ...rl.origin };

    if (rl?.destination?.id) acc[rl.destination.id] = rl.destination;
    else if (rl?.destination?._id) acc[rl.destination._id] = rl.destination;
    return acc;
  }, {} as RoutingNodesHashValue);
};

export const getInitialRoutingLegs = (routing_legs?: Array<RoutingLegValue>) => {
  return routing_legs?.map((rl: RoutingLegValue) => {
    return {
      ...rl,
      origin: undefined,
      destination: undefined,
    };
  });
};

const VoyageScheduleForm = forwardRef(function VoyageScheduleForm(
  props: {
    id?: string;
    onClose?: () => void;
    onSuccess?: (value: VoyageScheduleValue, type: string) => void;
    externalLink?: boolean;
    showFormHeader?: boolean;
    showFormLayout?: boolean;
    initialVoyageScheduleData?: VoyageScheduleInitialValue;
    renderFooter?: (props: { loading?: boolean }) => JSX.Element;
  },
  ref
): JSX.Element {
  const {
    onSuccess,
    showFormHeader = true,
    showFormLayout = true,
    initialVoyageScheduleData,
    renderFooter,
    onClose,
  } = props;
  const { 1: navigate } = useLocation();
  const source_voyage_schedule = window.history?.state?.voyage_schedule || {};
  const routing_details_ref = useRef<{ runValidation: () => boolean }>();
  const [form] = Form.useForm();
  const params = useParams<{ id: string }>();
  const externalLink = props?.externalLink;
  let id: string | undefined = undefined;
  if (!initialVoyageScheduleData) id = props?.id || params?.id;

  const isEdit = Boolean(id && id !== 'new');

  const [upsertVoyageSchedule, { data, loading, error }] = useMutation(
    isEdit ? UPDATE_VOYAGE_SCHEDULE : CREATE_VOYAGE_SCHEDULE
  );

  useEffect(() => {
    if (!error && data?.create_voyage_schedule) {
      message.success('Voyage Schedule created !');
      if (onSuccess) {
        onSuccess(VoyageSchedule.create(data?.create_voyage_schedule), 'created');
      } else {
        navigate(`~/form/voyage_schedule/${data?.create_voyage_schedule?.id}`);
      }
    } else if (!error && data?.update_voyage_schedule) {
      const updated_voyage_schedule_data: VoyageScheduleValue = getSnapshot(
        cast(VoyageSchedule.create(data?.update_voyage_schedule))
      );
      form.setFieldsValue({
        routing_details: {
          routing_nodes: getInitalRoutingNodes(updated_voyage_schedule_data?.routing_legs),
          routing_legs: getInitialRoutingLegs(updated_voyage_schedule_data?.routing_legs),
        },
      });
      message.success('Voyage Schedule updated !');
      if (onSuccess) onSuccess(updated_voyage_schedule_data, 'updated');
    }
  }, [form, error, data, navigate, onSuccess]);

  const [confirmModalVisible, setConfirmModalVisible] = useState(false);

  const handleConfirmModalOk = async () => {
    form.submit();
    setConfirmModalVisible(false);
  };

  const handleConfirmModalCancel = () => {
    setConfirmModalVisible(false);
  };

  const hasEditedLegs = () => {
    const previousValues = getInitialRoutingLegs(initialValues?.routing_legs) || [];
    const updatedExistingLegs = (form.getFieldValue('routing_details')?.routing_legs || [])
      .filter((elem: RoutingLegValue) => {
        return Object.keys(elem).includes('id');
      })
      .map((elem: RoutingLegValue) => {
        const previousRecord = previousValues.find((prevRecord: RoutingLegValue) => {
          return prevRecord.id === elem.id;
        });
        if (previousRecord) {
          // ignore origin_id & destination_id from comparison
          elem.origin_id = previousRecord.origin_id;
          elem.destination_id = previousRecord.destination_id;
        }
        return elem;
      });
    return !_isEqual(previousValues, updatedExistingLegs);
  };

  const hasEditedNodes = () => {
    const routingNodesData = initialValues?.routingNodes || {};
    const nodes = Object.values(form.getFieldValue('routing_details')?.routing_nodes || {}) as [
      RoutingNodeValue
    ];
    const updatedExistingNodes = nodes.reduce(
      (acc: RoutingNodesHashValue, rn: RoutingNodeValue) => {
        if (rn.id) acc[rn.id] = rn;
        return acc;
      },
      {} as RoutingNodesHashValue
    );

    return !_isEqual(routingNodesData, updatedExistingNodes);
  };

  const handleSaveButtonClick = () => {
    const foundError = Boolean(routing_details_ref?.current?.runValidation());
    if (!foundError) {
      if (isEdit && (hasEditedLegs() || hasEditedNodes())) {
        setConfirmModalVisible(true);
      } else {
        form.submit();
      }
    }
  };

  useImperativeHandle(ref, () => ({
    handleSaveButtonClick,
  }));

  const title = id !== 'new' ? `Voyage Schedule #${id}` : 'New Voyage Schedule';

  const renderFormHeader = () => {
    return (
      <React.Fragment>
        <AppHelmet>
          <title>{voyage_schedule_data ? 'Voyage Schedule' : 'New Voyage Schedule'}</title>
        </AppHelmet>
        <Header style={{ padding: 0, background: 'none', height: 'auto' }}>
          <PageHeader
            style={{ padding: '10px 0px' }}
            onBack={() => {
              if (onClose) {
                onClose();
              } else if (id !== 'new') {
                navigate(`~/workspace?doc_type=Network::VoyageSchedule&resource_id=${id}`);
              } else {
                navigate(`~/workspace?doc_type=Network::VoyageSchedule`);
              }
            }}
            title={
              <>
                {title}
                {externalLink && (
                  <span style={{ marginLeft: '10px' }}>
                    <Tooltip title="Open Detail Screen">
                      <a href={`/form/voyage_schedule/${id}`} target="_blank" rel="noreferrer">
                        <CustomIcon icon="ExternalLinkIcon" />
                      </a>
                    </Tooltip>
                  </span>
                )}
              </>
            }
            extra={[
              <Button
                loading={loading}
                htmlType="button"
                type="primary"
                key="save"
                onClick={handleSaveButtonClick}
              >
                Save
              </Button>,
            ]}
          >
            {error && errorMessageHandlerGraphQL(error)}
          </PageHeader>
        </Header>
      </React.Fragment>
    );
  };

  const {
    loading: fetching,
    error: voyageSchduleFetchError,
    data: voyageScheduleData,
    refetch,
  } = useQuery(FETCH_VOYAGE_SCHEDULE_BY_ID, {
    variables: { id },
    skip: !isEdit,
  });
  if (fetching) {
    return (
      <Layout className={showFormLayout ? 'form-layout' : ''}>
        {showFormHeader && (
          <Header style={{ padding: 0, background: 'none', height: 'auto' }}>
            <Skeleton paragraph={{ rows: 1 }} loading={fetching} active />
          </Header>
        )}
        <Content>
          <Card>
            <Skeleton paragraph={{ rows: 4 }} loading={fetching} active />
          </Card>
          <Card>
            <Skeleton paragraph={{ rows: 8 }} loading={fetching} active />
          </Card>
        </Content>
      </Layout>
    );
  } else if (voyageSchduleFetchError) {
    return (
      <div
        style={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center' }}
      >
        <Text style={{ fontSize: 15, marginRight: '5px' }} type="danger">
          Unable to fetch voyage schedule details
        </Text>
        <Button onClick={() => refetch()}>Retry</Button>
      </div>
    );
  }
  const voyage_schedule_data = voyageScheduleData?.voyage_schedule || data?.update_voyage_schedule;
  const initialValues = voyage_schedule_data
    ? VoyageSchedule.create(voyage_schedule_data)
    : undefined;
  const formInitialValues = initialValues
    ? { ...initialValues, send_email: true }
    : initialVoyageScheduleData;
  return (
    <Form
      onFinish={(values: FormValueType) => {
        const payload = getVoyageSchedulePayload(values, initialValues);
        const variables = isEdit
          ? { id, voyage_schedule: payload, send_email: values.send_email }
          : { voyage_schedule: payload };
        upsertVoyageSchedule({ variables });
      }}
      scrollToFirstError
      name="voyage_schedule"
      form={form}
      layout="vertical"
      style={{
        height: '100%',
      }}
      initialValues={{
        ...formInitialValues,
        routing_details: {
          routing_nodes: getInitalRoutingNodes(formInitialValues?.routing_legs),
          routing_legs: getInitialRoutingLegs(formInitialValues?.routing_legs),
        },
        ...source_voyage_schedule,
      }}
    >
      <Layout className={showFormLayout ? 'form-layout' : ''}>
        {showFormHeader
          ? renderFormHeader()
          : error && (
              <div style={{ background: 'white', paddingLeft: '10px' }}>
                {errorMessageHandlerGraphQL(error, undefined, true)}
              </div>
            )}
        <Content>
          <Card title="Basic Details">
            <VoyageScheduleBasicDetails disabled={!!voyage_schedule_data} />
          </Card>
          <Card title="Port of Call Details">
            <Form.Item name="routing_details" noStyle>
              <VoyageSchedulePortCallDetails ref={routing_details_ref} isEdit={isEdit} />
            </Form.Item>
          </Card>
        </Content>
        {renderFooter && renderFooter({ loading })}
      </Layout>

      <Modal
        key="confirm_save"
        title="Are you sure?"
        open={confirmModalVisible}
        onCancel={handleConfirmModalCancel}
        footer={[
          <Button key={'back'} onClick={handleConfirmModalCancel}>
            Close
          </Button>,
          <Button
            htmlType={'submit'}
            type={'primary'}
            key={'submit'}
            onClick={handleConfirmModalOk}
          >
            Submit
          </Button>,
        ]}
      >
        <Row gutter={[8, 8]}>
          <Col>
            <Alert
              message={
                'ETD & Cutoffs will be updated in linked booking(s) and shipment(s) if found.'
              }
              type={'warning'}
            />
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col>
            <Form.Item name="send_email" key="send_email" valuePropName="checked">
              <Checkbox>
                &nbsp;
                <MailTwoTone />
                &nbsp;&nbsp; Do you want to notify customer(s)?
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Modal>
    </Form>
  );
});

export default VoyageScheduleForm;
