import { useMutation } from '@apollo/client';
import { message, Modal } from '@shipmnts/pixel-hub';
import { MiscellaneousServiceOrderValue } from 'operations/models/MiscellaneousServiceOrder';
import React, { useEffect } from 'react';
import { DELETE_MISCELLANEOUS_SERVICE_ORDER } from '../../graphql/miscellaneousServiceOrder';

interface DeleteMiscellaneousServiceProps {
  miscellaneous_service_order: MiscellaneousServiceOrderValue;
  onClose?: () => void;
  onSuccess?: () => void;
}
export default function DeleteMiscellaneousService(props: DeleteMiscellaneousServiceProps) {
  const { miscellaneous_service_order, onClose, onSuccess } = props;
  const [deleteMisc, { data, error }] = useMutation(DELETE_MISCELLANEOUS_SERVICE_ORDER);
  const [modal, contextHolder] = Modal.useModal();
  useEffect(() => {
    if (error) {
      message.error(error.message);
      return;
    }
    if (data?.delete_miscellaneous_service_order) {
      message.success('Service deleted successfully!');
      if (onSuccess) onSuccess();
    }
  }, [data, error, onSuccess]);

  const config = {
    title: `Are you sure to Delete`,
    content: 'Once you click on Confirm, It will Delete',
    okText: 'Confirm Delete',
    cancelText: 'Cancel',
    onCancel: () => {
      if (onClose) onClose();
    },
    onOk: async () => {
      deleteMisc({
        variables: {
          id: miscellaneous_service_order.id,
        },
      });
    },
  };

  useEffect(() => {
    Modal.confirm(config);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  return <>{contextHolder}</>;
}
