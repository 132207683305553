import React, { useEffect, useRef, useState } from 'react';

import { useParams, Link } from 'wouter';
import { useQuery } from '@apollo/client';
import { FETCH_PRODUCT_ORDER } from 'operations/modules/orderManagement/graphql/productOrder';
import { Button, Layout, Skeleton, Tabs, Result, Divider, TabsProps } from '@shipmnts/pixel-hub';
import { AppHelmet } from '@shipmnts/pixel-hub';

import DocumentTab from './Tabs/DocumentTab';
import ProductsTab from './Tabs/ProductsTab';
import OMSDetailHeader from './OMSDetailHeader';
import OMSSummary from './OMSSummary';
import ShipmentsTab from './Tabs/ShipmentsTab';

const { Content } = Layout;

const TabComponentMap = new Map();
TabComponentMap.set('products', ProductsTab);
TabComponentMap.set('shipments', ShipmentsTab);
TabComponentMap.set('documents', DocumentTab);

export type OMSTabType = 'products' | 'shipments' | 'documents' | undefined;

const OMSDetailLayoutWrapper: React.FC = (props: any) => {
  const params = useParams<{ id: string }>();
  const { id } = params;

  return <OrderDetailLayout id={id || props.id} {...props}></OrderDetailLayout>;
};

interface OrderDetailLayoutProps {
  id: string;
  tab?: OMSTabType;
  onClose?: () => void;
  externalLink?: boolean;
}

export const OrderDetailLayout = (props: OrderDetailLayoutProps) => {
  const { tab, id, onClose, externalLink } = props;
  const summaryRef = useRef<null | HTMLDivElement>(null);
  const [activeTab, setActivetab] = useState<OMSTabType>(tab || 'products');

  // Queries / Mutations
  const { loading, error, data, refetch } = useQuery(FETCH_PRODUCT_ORDER, {
    variables: { id: id },
  });

  useEffect(() => {
    const height = summaryRef?.current?.scrollHeight;
    if (height) window.scrollBy(0, height);
  }, [activeTab]);
  useEffect(() => {
    setActivetab('products');
  }, [id]);

  if (loading) {
    return <Skeleton avatar paragraph={{ rows: 3 }} loading={loading} active></Skeleton>;
  }

  if (error) {
    let errorCode: 500 | 404 = 500,
      title = 'Something went wrong while fetching the product order.',
      subTitle = error ? error.message : '',
      button = (
        <Button
          onClick={() => {
            if (refetch) {
              refetch();
            }
          }}
          type="primary"
        >
          Retry
        </Button>
      );
    if (error && error.message.includes('OrderManagement::ProductOrder for given id Not found')) {
      errorCode = 404;
      title = `Product Order #${id} does not exist`;
      subTitle = '';
      button = (
        <Button type="primary">
          <Link to="/">Go Home</Link>
        </Button>
      );
    }
    return <Result status={errorCode} title={title} subTitle={subTitle} extra={button} />;
  }

  if (!data?.get_product_order) return <div>Data not found</div>;

  const TabComponent = TabComponentMap.get(activeTab);

  let order_number: string;
  if (data?.get_product_order.type === 'purchase') {
    order_number = data?.get_product_order?.purchase_order_number;
  } else {
    order_number = data?.get_product_order?.sales_order_number;
  }

  const getTab = () => {
    const tabs: TabsProps['items'] = [];
    tabs.push({ label: 'Products', key: 'products' });
    tabs.push({ label: 'Shipments', key: 'shipments' });
    tabs.push({ label: 'Documents', key: 'documents' });
    return tabs;
  };

  return (
    <div style={{ height: '94vh', overflowY: 'scroll' }} className="shipment-detail-layout">
      <AppHelmet>
        <title>{`#${order_number || ''} | Shipment`}</title>
      </AppHelmet>
      <Layout style={{ paddingBottom: '24px' }}>
        <OMSDetailHeader
          onClose={onClose}
          externalLink={externalLink}
          productOrder={data?.get_product_order}
        />
        <Content style={{ padding: '16px 24px', paddingTop: externalLink ? '12px' : '16px' }}>
          <div ref={summaryRef}>
            <OMSSummary product_order={data.get_product_order} />
            <Divider style={{ marginBottom: '0px' }} />
          </div>
          <Tabs
            activeKey={activeTab}
            onTabClick={(key: string) => {
              setActivetab(key as OMSTabType);
            }}
            tabBarStyle={{
              background: '#fafafa',
              paddingLeft: '10px',
            }}
            items={getTab()}
          ></Tabs>
          <Content style={{ minHeight: '500px' }}>
            <div style={{ height: '100%', overflow: 'auto' }}>
              {(TabComponent && (
                <TabComponent externalLink={externalLink} productOrder={data?.get_product_order} />
              )) || <></>}
            </div>
          </Content>
        </Content>
      </Layout>
    </div>
  );
};

export default OMSDetailLayoutWrapper;
