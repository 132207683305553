import { gql } from '@apollo/client';
import { shipmentFields } from '../../../../../graphql/shipment';
import { salesPersonFields } from 'operations/graphql/salesPerson';

export const LINK_HOUSE_SHIPMENT = gql`
  mutation link_house_shipments($house_shipment_ids: [ID!]!, $master_shipment_id: ID!) {
    link_house_shipments(
      house_shipment_ids: $house_shipment_ids
      master_shipment_id: $master_shipment_id
    ) {
      ...shipmentFields
      house_shipments {
        ...shipmentFields
      }
    }
  }
  ${shipmentFields}
`;

export const VALIDATE_LINK_HOUSE = gql`
  mutation validate_link_house($house_shipment_ids: [ID!]!, $master_shipment_id: ID!) {
    validate_link_house(
      house_shipment_ids: $house_shipment_ids
      master_shipment_id: $master_shipment_id
    ) {
      messages
      warnings
    }
  }
`;

export const SHIPMENT_REPORT_NEW = gql`
  query shipment_report_new(
    $filters: [FilterInputType!]
    $sort: [SortInputTypeNew!]
    $limit: Int
    $offset: Int
  ) {
    shipment_report_new(filters: $filters, sort: $sort, limit: $limit, offset: $offset) {
      total
      data {
        id
        job_number
        shipment_booking_number
        customer
        chargeable_weight
        chargeable_weight_float
        gross_weight
        gross_weight_float
        gross_volume
        gross_volume_float
        commodity_description
        port_of_loading {
          id
          name
          unlocode
          iata_code
          country {
            name
          }
        }
        port_of_discharge {
          id
          name
          unlocode
          iata_code
          country {
            name
          }
        }
        place_of_carrier_receipt {
          id
          name
          unlocode
        }
        place_of_carrier_delivery {
          id
          name
          unlocode
        }
        final_place_of_delivery
        shipper
        consignee
        origin_agent
        destination_agent
        shipment_created_at
        sales_agent_id
        sales_agent {
          ...salesPersonFields
        }
        shipment_document_house {
          shipment_number
        }
        involved_branch {
          name
        }
      }
    }
  }
  ${salesPersonFields}
`;

export const FETCH_SHIPMENT_DOCUMENTS = gql`
  query fetch_shipment_documents($shipment_ids: [ID!]!) {
    fetch_shipment_documents(shipment_ids: $shipment_ids) {
      id
      shipment_document_events {
        id
        event_by {
          id
          first_name
          last_name
        }
        event_date
        name
        event_data
      }
    }
  }
`;
