/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree';

const Package = types.model({
  id: types.maybe(types.identifier),
  _id: types.maybe(types.string),
  gross_volume: types.maybeNull(types.maybe(types.number)),
  height: types.maybeNull(types.maybe(types.number)),
  width: types.maybeNull(types.maybe(types.number)),
  length: types.maybeNull(types.maybe(types.number)),
  total_number_of_packages: types.maybeNull(types.maybe(types.number)),
  per_piece_weight: types.maybeNull(types.maybe(types.number)),
  type_of_package: types.maybeNull(types.maybe(types.string)),
  dimension_unit: types.maybeNull(types.maybe(types.string)),
});

export interface PackageValue extends Instance<typeof Package> {}
export interface PackageIn extends SnapshotIn<typeof Package> {}
export interface PackageOut extends SnapshotOut<typeof Package> {}

export default Package;
