import React from 'react';
import { Descriptions } from '@shipmnts/pixel-hub';

export interface InfoDataValue {
  label: string;
  value: any;
}

export interface BasicInfoRendererParams {
  title: string;
  noOfColumns: number;
  layout: 'horizontal' | 'vertical' | undefined;
  showColon: boolean;
  labelStyle: any;
  descriptionItemStyle: any;
  displayData: InfoDataValue[];
}

const BasicInfoRender = React.memo(function BasicInfoRender(
  props: BasicInfoRendererParams
): JSX.Element {
  const { title, noOfColumns, layout, showColon, labelStyle, descriptionItemStyle, displayData } =
    props;
  return (
    <>
      <Descriptions
        column={noOfColumns || 2}
        colon={showColon}
        size="default"
        layout={layout}
        title={title || 'Basic Info'}
        labelStyle={labelStyle}
        style={{ marginBottom: '10px' }}
        className="ant-descriptions-header-custom"
      >
        {displayData &&
          displayData.map((row: InfoDataValue, index: number) => (
            <Descriptions.Item key={index} style={descriptionItemStyle} label={row?.label}>
              {row?.value || '-'}
            </Descriptions.Item>
          ))}
      </Descriptions>
    </>
  );
});

export default BasicInfoRender;
