import { useMutation } from '@apollo/client';
import { Popconfirm } from '@shipmnts/pixel-hub';

import { ShipmentManifestValue } from 'operations/models/ShipmentManifest';
import React, { useEffect } from 'react';
import { DELETE_SHIPMENT_MANIFEST } from './graphql';

type Props = {
  isVisible: boolean;
  shipmentManifest?: ShipmentManifestValue;
  onSuccess?: any;
};

const DeleteShipmentManifest = (props: Props) => {
  // Queries and Mutations
  const [deleteManifestDetail, { data, loading, error }] = useMutation(DELETE_SHIPMENT_MANIFEST);

  //  Handle on  enable tracking
  const deleteShipmentManifest = () => {
    const input = {
      id: props.shipmentManifest?.id,
    };
    deleteManifestDetail({
      variables: input,
    });
  };

  useEffect(() => {
    if (data && !error) {
      props.onSuccess();
    }
  }, [data, error, props, props.onSuccess]);

  return (
    <>
      <Popconfirm
        style={{
          top: '520px',
          left: 'calc(100vw - 490px)',
          width: '437px',
        }}
        title={<>Are you sure you want to delete?</>}
        placement="left"
        onConfirm={deleteShipmentManifest}
        okText={'Yes'}
        cancelText={'No'}
        open={props.isVisible}
        className="enable-popup"
        okButtonProps={{ loading: loading }}
        overlayStyle={{ width: '437px' }}
        onCancel={props.onSuccess}
      ></Popconfirm>
    </>
  );
};

export default DeleteShipmentManifest;
