import React, { useState } from 'react';

import CreateInquiryDrawer from 'sales_hub/components/Inquiry/CreateInquiryDrawer';

interface CreateInquiryFromLeadProp {
  company?: any;
  onSuccess?: () => void;
}

function CreateInquiryFromLead(props: CreateInquiryFromLeadProp) {
  const { onSuccess, company } = props;
  const [visible, setVisible] = useState<boolean>(true);

  return (
    <CreateInquiryDrawer
      visible={visible}
      setVisible={setVisible}
      onSuccess={onSuccess}
      data={{
        company,
        fields: {
          freight_type: 'air',
          trade_type: 'export',
          'services.freight_forwarding': true,
        },
      }}
    />
  );
}

export default CreateInquiryFromLead;
