import React from 'react';
import { RowNode } from '@ag-grid-community/core';
import { useMutation } from '@apollo/client';
import { message, Row, Col, notification, Form, InputNumber, Modal } from '@shipmnts/pixel-hub';
import { BULK_UPDATE_SHIPMENT_FIELD } from 'operations/graphql/shipment';
import { CheckCircleTwoTone, DatePicker } from '@shipmnts/pixel-hub';
import { ColorPickerTool } from '@shipmnts/pixel-hub';
import { NotificationPlacement } from '@shipmnts/pixel-hub';

export const convertDateToUnix = (dateStr?: string) => {
  if (!dateStr) return null;
  return new Date(dateStr).getTime() / 1000;
};

const openNotification = (title: string, msg: string, placement: NotificationPlacement) => {
  notification.info({
    message: title,
    description: msg,
    placement,
    duration: 3,
    icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
  });
};

const EditShipmentField = React.memo(function EditShipmentField(props: {
  selectedNodes: RowNode[];
  selectedNodeCounts: number;
  onClose: () => void;
  onSuccess: () => void;
  fieldName: string;
  label: string;
}): JSX.Element {
  const { selectedNodes, onClose, onSuccess, fieldName, label } = props;

  // Queriess/Mutations
  const [updateEvent, { loading }] = useMutation(BULK_UPDATE_SHIPMENT_FIELD);

  const integerFields = [
    'destination_carrier_free_days',
    'destination_port_free_days',
    'origin_carrier_free_days',
  ];

  const isAnIntegerField = integerFields.includes(fieldName);
  const isColorField = fieldName === 'color_code';

  const onFinish = async (values: any) => {
    const variables: {
      ids?: number[];
      input?: any;
    } = { ids: selectedNodes.map((n) => parseInt(n.data.id)), input: {} };

    if (isAnIntegerField) {
      variables.input[fieldName] = values[fieldName];
    } else if (isColorField) {
      variables.input[fieldName] = values[fieldName] || '#FFFFFF';
    } else {
      variables.input[fieldName] = convertDateToUnix(values[fieldName]);
    }

    updateEvent({
      variables: variables,
    })
      .then((res) => {
        openNotification(
          'Success',
          `${label} is successfully updated ${
            label !== 'Colour' ? 'to ' + values[fieldName] : ''
          } in selected shipments.`,
          'bottomRight'
        );
        onSuccess();
      })
      .catch((err) => {
        message.error('Error Occured');
      });
  };

  const [form] = Form.useForm();

  return (
    <Modal
      title={`Update ${label}`}
      open={true}
      okText={'Update'}
      onOk={form.submit}
      onCancel={onClose}
      confirmLoading={loading}
    >
      <Form layout="inline" form={form} requiredMark={false} onFinish={onFinish}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={24}>
            <Form.Item className="custom-form-item" name={fieldName} label={label}>
              {isAnIntegerField ? (
                <InputNumber />
              ) : isColorField ? (
                <ColorPickerTool
                  onChange={(color: any) => {
                    form.setFieldsValue({ [fieldName]: color });
                  }}
                  value="#FFFFFF"
                />
              ) : (
                <DatePicker showTime format="DD-MM-YYYY HH:mm:ss" />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
});

export default EditShipmentField;
