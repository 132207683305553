import { useMutation } from '@apollo/client';
import { Input, message, Modal, Form } from '@shipmnts/pixel-hub';
import { startCase } from 'lodash';
import { UPDATE_SHIPMENT_ACCOUNTING_STATUS } from 'operations/graphql/shipmentDocument';
import { ShipmentValue } from 'operations/models/Shipment';
import React, { useEffect } from 'react';
import { errorMessageHandlerGraphQLString } from 'common';
import { ACCOUNTING_STATUS_BILLING_CLOSED } from '../constants';

interface AccountingReopenProps {
  shipment: ShipmentValue;
  onClose?: () => void;
  onSuccess?: () => void;
}
export default function AccoutingReopen(props: AccountingReopenProps) {
  const { shipment, onClose, onSuccess } = props;
  const [accountingReopen, { data, loading, error }] = useMutation(
    UPDATE_SHIPMENT_ACCOUNTING_STATUS
  );
  const [form] = Form.useForm();

  useEffect(() => {
    if (error) {
      message.error(errorMessageHandlerGraphQLString(error));
    }
    if (data?.update_accounting_status) {
      message.success(
        'Shipment status changed to ' + startCase(data?.update_accounting_status?.accounting_status)
      );
      onSuccess && onSuccess();
    }
  });

  return (
    <Modal
      title={`Reopen ${shipment.job_number}`}
      onOk={form.submit}
      okText="Reopen shipment"
      open
      onCancel={onClose}
      okButtonProps={{ loading }}
    >
      <Form
        form={form}
        onFinish={(values: { remarks: string }) => {
          accountingReopen({
            variables: {
              id: shipment.id,
              next_status: ACCOUNTING_STATUS_BILLING_CLOSED,
              remarks: values.remarks,
            },
          });
        }}
        layout="vertical"
      >
        <Form.Item name="remarks" label="Remarks" required>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
