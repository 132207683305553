import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useLocation, useParams } from 'wouter';
import { FETCH_TICKET, UPDATE_TICKET } from '../graphql/ticket';
import {
  AppHelmet,
  Button,
  CustomIcon,
  CustomSiderContextProvider,
  DeleteOutlined,
  Divider,
  DownOutlined,
  EditOutlined,
  GlobalSearch,
  Layout,
  PageHeader,
  PauseOutlined,
  Popconfirm,
  PrioritySelect,
  RenderCollaborators,
  Spin,
  Tooltip,
  UpOutlined,
  message,
  refClassTransformer,
} from '@shipmnts/pixel-hub';
import { transformReferences } from '../utils/utils';
import AssociationTable from './AssociationTable';
import TicketDetailSummary from './TicketDetailSummary';
import TicketForm from './TicketForm';
import './ticket.css';
import { TicketDataSourceItem } from './types';

interface TicketDetailLayoutProps {
  onClose?: () => void;
  externalLink?: boolean;
  id?: string;
}
const TicketDetailLayout = (props: TicketDetailLayoutProps) => {
  const { onClose, externalLink } = props;
  const params = useParams<{ ticket_id: string }>();
  let { ticket_id } = params;

  ticket_id = props.id || ticket_id;
  const [showTicketDrawer, setShowTicketDrawer] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<TicketDataSourceItem[]>([
    {
      link_type: null,
      linked_record: null,
    },
  ]);
  const [deletedDataSource, setDeletedDataSource] = useState<TicketDataSourceItem[]>([]);
  const [updateTicket, { data: updateData, loading: loadingUpdate, error: updateError }] =
    useMutation(UPDATE_TICKET);
  const [selectedPriority, setSelectedPriority] = useState<string>('');
  const [isPriorityChanged, setIsPriorityChanged] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  useEffect(() => {
    if (updateError) {
      message.error('Failed to update ticket');
    } else if (updateData) {
      if (!isPriorityChanged) {
        message.success('Ticket updated successfully');
        refetch();
        if (onClose) onClose();
      } else {
        setIsPriorityChanged(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateData, loadingUpdate, updateError]);

  const onOk = () => {
    const updatedRefs = [...dataSource];
    const originalRefIds = new Set(ticketData?.references?.map((ref: any) => ref.reference_id));

    const deletedRefs = deletedDataSource
      .filter((record: any) => originalRefIds.has(record.linked_record?.id?.toString()))
      .map((record: any) => {
        return {
          id: record.linked_record?.doc_ref_id,
          reference_id: record.linked_record?.id,
          reference_type: refClassTransformer[record.link_type],
          is_deleted: record.is_deleted,
          _destroy: record._destroy,
        };
      });

    const refs = updatedRefs.map((record: any) => {
      return {
        id: record.linked_record?.doc_ref_id,
        reference_id: record.linked_record?.id,
        reference_type: refClassTransformer[record.link_type],
        _destroy: record._destroy,
      };
    });

    const finalRefs = [...refs, ...deletedRefs];
    const updatePayload = {
      ticket: {
        id: ticket_id,
        references: finalRefs,
      },
    };

    updateTicket({
      variables: updatePayload,
    });
  };

  const onCancel = () => {
    // refetch();
    if (data) {
      const refs = transformReferences(data.service_management_ticket.references);
      setDataSource(refs);
    }
  };

  const { data, loading, error, refetch } = useQuery(FETCH_TICKET, {
    variables: { id: ticket_id },
  });
  const { 1: navigate } = useLocation();
  const onFieldChange = useCallback(
    (key: string, value: any, index: number) => {
      const doc_ref_id = dataSource[index]?.linked_record?.doc_ref_id;

      if (key === 'linked_record') {
        if (value) value.doc_ref_id = doc_ref_id;
      }

      const newData = [...dataSource];
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        [key]: value,
      });
      setDataSource(newData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataSource]
  );

  useEffect(() => {
    if (data) {
      const refs = transformReferences(data.service_management_ticket.references);
      setSelectedPriority(data.service_management_ticket?.priority);
      setDataSource(refs);
      data.service_management_ticket?.ticket_status === 'Close' && setDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const renderCollaborators = useMemo(
    () => (
      <RenderCollaborators
        id={data?.service_management_ticket.ticket_number}
        referenceId={ticket_id}
        referenceType={'ServiceManagement::Ticket::Ticket'}
        shareButton={true}
        key=""
      />
    ),
    [data?.service_management_ticket?.ticket_number, ticket_id]
  );
  if (error) {
    message.error('Failed to load ticket data');
  }
  if (loading) {
    return (
      <div className="centered fullscreen">
        <Spin tip={'Loading Ticket Data'} />
      </div>
    );
  }

  const order = [
    'Shipment::Shipment',
    'Shipment::ShipmentContainer',
    'Shipment::ShipmentCustomDetail',
    'Shipment::ShipmentDocument',
    'Network::Company',
    'ServiceManagement::Ticket::SalesInvoice',
    'ServiceManagement::Ticket::PurchaseInvoice',
  ];
  const { service_management_ticket: ticketData } = data;
  const handleDelete = (record: any) => {
    // case for customsider's onPopConfirmOk
    if (record?.reference_id) {
      // const newRecord = {
      //   is_deleted: true,
      //   reference_id: record?.reference_id,
      //   reference_type: record?.reference_type,
      // };
      const refIdToDelete = record?.reference_id;
      const updatedRefs = [...dataSource];
      const refs = updatedRefs.map((record: any) => {
        if (record.linked_record?.id?.toString() === refIdToDelete) {
          return {
            id: record.linked_record?.doc_ref_id,
            reference_id: record.linked_record?.id,
            reference_type: refClassTransformer[record.link_type],
            _destroy: true,
          };
        }
        return {
          id: record.linked_record?.doc_ref_id,
          reference_id: record.linked_record?.id,
          reference_type: refClassTransformer[record.link_type],
          _destroy: record._destroy,
        };
      });
      const updatePayload = {
        ticket: {
          id: ticket_id,
          references: refs,
        },
      };
      updateTicket({
        variables: updatePayload,
      });
      return;
    }
    const newData = dataSource.filter((item: any) => item !== record);
    record._destroy = true;

    setDeletedDataSource([...deletedDataSource, record]);
    setDataSource(newData);
  };
  const defaultColumns = [
    {
      title: 'Object Type',
      dataIndex: 'link_type',
      editable: true,
      renderComponent: 'select',
      componentProps: {
        showGroup: false,
        options: [
          { key: 'Shipment', name: 'Shipment' },
          { key: 'Container', name: 'Container' },
          { key: 'Company', name: 'Company' },
          { key: 'Sales Invoice', name: 'Sales Invoice' },
          { key: 'Purchase Invoice', name: 'Purchase Invoice' },
        ],
        placeholder: 'Select Type',
      },
      required: true,
    },
    {
      title: 'Linked Record',
      dataIndex: 'linked_record',
      editable: true,
      renderComponent: 'custom_field',
      customComponent: GlobalSearch,
      componentProps: {},
      required: true,
    },
    {
      title: 'Action',
      render: (_: string, record: { key: React.Key }) =>
        dataSource.length >= 1 ? (
          <Popconfirm title="Sure to delink?" onConfirm={() => handleDelete(record)}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <DeleteOutlined
                style={{
                  color: '#E70000D9',
                  cursor: 'pointer',
                  fontSize: '17px',
                }}
              />
            </div>
          </Popconfirm>
        ) : null,
      align: 'center',
    },
  ];

  const columns = defaultColumns.map((col: any) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any, rowIndex: number) => {
        return {
          record,
          rowIndex,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          renderComponent: col.renderComponent,
          componentProps:
            col.dataIndex === 'link_type'
              ? {
                  ...col.componentProps,
                  onChange: (value: string) => {
                    onFieldChange(col.dataIndex, value, rowIndex);
                    setDataSource((prevDataSource) => {
                      const newData = [...prevDataSource];
                      const item = newData[rowIndex];
                      newData.splice(rowIndex, 1, {
                        ...item,
                        linked_record: {
                          doc_ref_id: item.linked_record?.doc_ref_id,
                        },
                      });
                      return newData;
                    });
                  },
                }
              : {
                  ...col.componentProps,
                  doc_type: refClassTransformer[record['link_type']],
                  disabled: record['link_type'] === null,
                  value: dataSource[rowIndex].linked_record,
                },
          onFieldChange,
          required: col.required,
          customComponent: col.customComponent,
          //   errorsPresent: record[col.dataIndex] === '' || record[col.dataIndex] === null,
        };
      },
    };
  });

  const handleAdd = () => {
    const newData = {
      link_type: null,
      linked_record: null,
    };
    setDataSource([...dataSource, newData]);
  };

  const priorityOptions = [
    {
      value: 'High',
      label: 'High',
      icon: <UpOutlined style={{ color: '#ff4d4f' }} />,
    },
    {
      value: 'Medium',
      label: 'Medium',
      icon: <PauseOutlined style={{ color: '#ffc53d', transform: 'rotate(90deg)' }} />,
    },
    {
      value: 'Low',
      label: 'Low',
      icon: <DownOutlined style={{ color: '#40a9ff' }} />,
    },
  ];

  const handlePriorityChange = (value: string) => {
    setIsPriorityChanged(true);
    setSelectedPriority(value);
    const updatePayload = {
      ticket: {
        id: ticket_id,
        priority: value,
      },
    };
    updateTicket({
      variables: updatePayload,
    });
  };

  return (
    <>
      <Layout>
        <AppHelmet>
          <title>{ticketData?.title}</title>
        </AppHelmet>
        <PageHeader
          className="ticket-detail-header"
          ghost={false}
          style={{
            background: '#ffffff',
            top: externalLink ? '0px' : '40px',
          }}
          title={
            <div
              style={{
                fontSize: '18px',
                fontWeight: 600,
                marginLeft: '14px',
                display: 'flex',
              }}
            >
              {ticketData?.ticket_number}

              {externalLink && (
                <span>
                  <Tooltip title="Open Detail Screen">
                    <a href={`/view/ticket/${ticket_id}`} target="_blank" rel="noreferrer">
                      <CustomIcon icon="ExternalLinkIcon" />
                    </a>
                  </Tooltip>
                </span>
              )}
              <div style={{ marginLeft: '20px' }}>
                <PrioritySelect
                  priorityOptions={priorityOptions}
                  handlePriorityChange={handlePriorityChange}
                  selectedPriority={selectedPriority}
                />
              </div>
            </div>
          }
          onBack={() => {
            if (onClose) onClose();
            else
              navigate(
                `~/workspace?doc_type=ServiceManagement::Ticket::Ticket&resource_id=${ticket_id}`
              );
          }}
          extra={
            <>
              {renderCollaborators}
              <Button
                key={'edit'}
                size="small"
                onClick={() => {
                  setShowTicketDrawer(true);
                }}
                disabled={disabled}
              >
                <span style={{ marginRight: '5px' }}>
                  <EditOutlined />
                </span>
                Edit
              </Button>
            </>
          }
        >
          <Divider style={{ margin: '0px' }} />
        </PageHeader>
        <Layout
          className="site-layout"
          style={{ backgroundColor: '#F5F5F5', height: 'content-fit', minHeight: '100%' }}
        >
          <CustomSiderContextProvider
            records={ticketData?.references}
            order={order}
            title="Linked References"
            defaultOpen={true}
            modalComponent={
              disabled ? null : (
                <>
                  <AssociationTable dataSource={dataSource} columns={columns} />
                  <Button onClick={handleAdd} size="small" style={{ marginTop: '.5em' }}>
                    {`${String.fromCodePoint(43)} Add Row`}
                  </Button>
                </>
              )
            }
            onModalOk={onOk}
            onModalCancel={onCancel}
            onPopConfirmOk={handleDelete}
            refetchData={refetch}
          >
            <TicketDetailSummary ticketData={ticketData} ticket_id={ticket_id} />
          </CustomSiderContextProvider>
        </Layout>
      </Layout>
      {showTicketDrawer && (
        <TicketForm
          edit={true}
          ticket_id={ticket_id}
          visible={showTicketDrawer}
          setVisible={setShowTicketDrawer}
        />
      )}
    </>
  );
};

export default TicketDetailLayout;
