import { types, Instance, SnapshotIn, SnapshotOut, IAnyModelType } from 'mobx-state-tree';

import Carrier from './Carrier';
import Company from './Company';
import Inquiry from './inquiry';
import Location from './Location';
import ShipmentEstimate from './ShipmentEstimate';
import UserAccount from './UserAccount';
import Vessel from './Vessel';
import Template from './Template';

export const DRAFT_STATUS = 'draft';
export const WON_STATUS = 'won';
export const LOST_STATUS = 'lost';

const QuotationInquiry = types.model({
  inquiry: types.maybe(types.maybeNull(types.late((): IAnyModelType => Inquiry))),
});

const AmendFrom = types.model({
  id: types.identifier,
  quotation_number: types.maybe(types.maybeNull(types.string)),
});

const Quotation = types.model({
  id: types.identifier,
  shipment_estimates: types.maybe(types.maybeNull(types.array(ShipmentEstimate))),
  created_by: types.maybe(UserAccount),
  ocean_vessel: types.maybe(types.maybeNull(Vessel)),
  carrier: types.maybe(types.maybeNull(Carrier)),
  booking_vendor: types.maybe(types.maybeNull(Company)),
  port_of_loading: types.maybe(types.maybeNull(Location)),
  port_of_discharge: types.maybe(types.maybeNull(Location)),
  place_of_carrier_receipt: types.maybe(types.maybeNull(Location)),
  place_of_carrier_delivery: types.maybe(types.maybeNull(Location)),
  voyage_number: types.maybe(types.maybeNull(types.string)),
  vendor_rate_agreement_number: types.maybe(types.maybeNull(types.string)),
  tariff_rate: types.maybe(types.maybeNull(types.number)),
  valid_from: types.maybe(types.maybeNull(types.number)),
  valid_to: types.maybe(types.maybeNull(types.number)),
  routing: types.maybe(types.maybeNull(types.string)),
  quote_currency: types.maybe(types.maybeNull(types.string)),
  origin_carrier_free_days: types.maybe(types.maybeNull(types.number)),
  destination_carrier_free_days: types.maybe(types.maybeNull(types.number)),
  destination_port_free_days: types.maybe(types.maybeNull(types.number)),
  exchange_rate: types.maybe(types.maybeNull(types.number)),
  carrier_product: types.maybe(types.maybeNull(types.string)),
  estimated_time_of_departure: types.maybe(types.maybeNull(types.number)),
  estimated_time_of_arrival: types.maybe(types.maybeNull(types.number)),
  chargeable_weight: types.maybe(types.maybeNull(types.number)),
  quotation_number: types.maybe(types.maybeNull(types.string)),
  status: types.maybe(types.maybeNull(types.string)),
  transit_days: types.maybe(types.maybeNull(types.string)),
  total_sell: types.maybe(types.maybeNull(types.number)),
  total_buy: types.maybe(types.maybeNull(types.number)),
  freight_sell_unit_rate: types.maybe(types.maybeNull(types.number)),
  freight_buy_unit_rate: types.maybe(types.maybeNull(types.number)),
  freight_buy_unit_cur: types.maybe(types.maybeNull(types.string)),
  freight_sell_unit_cur: types.maybe(types.maybeNull(types.string)),
  terms_and_cond: types.maybe(types.maybeNull(Template)),
  terms_and_cond_desc: types.maybe(types.maybeNull(types.string)),
  validity_basis: types.maybe(types.maybeNull(types.string)),
  remarks: types.maybe(types.maybeNull(types.string)),
  amend_from: types.maybe(types.maybeNull(AmendFrom)),
  custom_field_values: types.maybe(types.maybeNull(types.string)),
  updated_at: types.maybe(types.maybeNull(types.number)),
});

export const QuotationWithInquiry = types
  .compose(Quotation, QuotationInquiry)
  .named('QuotationWithInquiry');

export type QuotationValue = Instance<typeof Quotation>;
export type QuotationIn = SnapshotIn<typeof Quotation>;
export type QuotationOut = SnapshotOut<typeof Quotation>;
export type QuotationWithInquiryValue = Instance<typeof QuotationWithInquiry>;

export default Quotation;
