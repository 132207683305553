import React from 'react';
import { RowNode } from '@ag-grid-community/core';
import { Action } from 'operations/modules/actionHelper/models';
import { PRIMARY_TYPE } from 'operations/modules/actionHelper/constants';
import { startCase } from 'lodash';
import {
  bulkEventActionsRenderer,
  getNextPossibleAction,
  GetIconForAction,
} from '../ShipmentContainerBulkActionsHelper';

const getShipmentCustomDetailAction = (action_name: string): Action => {
  return {
    key: action_name,
    type: PRIMARY_TYPE,
    displayComponent: startCase(action_name),
    icon: <GetIconForAction eventName={action_name} />,
    description: startCase(action_name),
    performAction: bulkEventActionsRenderer,
    isEnable: true,
    extraProps: {
      action_name: action_name,
      doctype_id: 'Shipment::ShipmentCustomDetail',
    },
  };
};

export const getShipmentCustomDetailBulkActions = (selectedNodes: RowNode[]) => {
  if (selectedNodes.length === 0) return [];

  const nextActions: string[] = getNextPossibleAction(selectedNodes);
  const actions: Action[] = [];
  nextActions.forEach((action_name: string) => {
    actions.push(getShipmentCustomDetailAction(action_name));
  });
  return actions;
};
