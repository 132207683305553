import React, { useState, useEffect, useMemo } from 'react';
import { debounce as _debounce } from 'lodash';
import { Select, Spin, Row, Col, Typography } from '@shipmnts/pixel-hub';
import { SearchOutlined } from '@shipmnts/pixel-hub';
import { useLazyQuery } from '@apollo/client';
import { BOOKING_REQUESTS_SEARCH } from 'operations/modules/booking/graphql/bookingRequest';
import { BookingRequestValue } from 'operations/models/BookingRequest';
import {
  getCarriageWiseRoutingLegs,
  ROUTING_TYPE_MAIN_CARRIAGE,
} from 'operations/models/RoutingLeg';

const { Option, OptGroup } = Select;
const { Text } = Typography;

const getSelectValue = (
  value: BookingRequestValue | Array<BookingRequestValue> | undefined | null
) => {
  if (Array.isArray(value)) {
    return value.map(
      (val) =>
        val && {
          value: val.id,
          label: val.shipment_booking_number,
          key: val.id,
        }
    );
  }
  return value
    ? {
        value: value.id,
        label: value.shipment_booking_number,
        key: value.id,
      }
    : undefined;
};

const getMainCarriageDetail = function (br: BookingRequestValue) {
  const oto = br.ocean_transport_orders[0];
  const carriageWiseRoutingLegs = getCarriageWiseRoutingLegs(oto?.routing_legs);
  return carriageWiseRoutingLegs[ROUTING_TYPE_MAIN_CARRIAGE];
};

const CustomerOrderSearch = React.memo(function CustomerOrderSearch(props: {
  value?: BookingRequestValue | Array<BookingRequestValue> | undefined | null;
  onSelectBookingRequest: (
    value: BookingRequestValue | Array<BookingRequestValue> | undefined | null | null[]
  ) => void;
  bookingRequest: BookingRequestValue;
}): JSX.Element {
  const { value, onSelectBookingRequest, bookingRequest } = props;
  const [errorMessage, setErrorMessage] = React.useState('');
  const hasSearchedOnce = React.useRef(false);
  const [fetching, setFetching] = useState(false);
  const [bookingRequests, setBookingRequests] = useState<Array<BookingRequestValue>>([]);

  const [searchBookingRequests, { data, error, loading }] = useLazyQuery(BOOKING_REQUESTS_SEARCH);

  useEffect(() => {
    if (data && data.booking_requests_search) setBookingRequests(data.booking_requests_search);
  }, [data]);

  const fetchData = useMemo(
    () =>
      _debounce(async (searchString: string) => {
        if (searchString) {
          const mcrl = getMainCarriageDetail(bookingRequest);
          hasSearchedOnce.current = true;
          setFetching(true);
          try {
            searchBookingRequests({
              variables: {
                search_text: searchString,
                customer_company_id: bookingRequest?.sell_collaborations?.[0]?.customer_company?.id,
                port_of_loading_id: mcrl?.[0].origin?.location?.id,
                port_of_discharge_id: mcrl?.[mcrl.length - 1].destination?.location?.id,
              },
            });
          } catch (error) {
            if (error instanceof Error) setErrorMessage(error?.message);
            console.error(error);
          }
          setFetching(false);
        }
      }, 1000),
    [bookingRequest, searchBookingRequests]
  );

  let notFoundContent: any = undefined;
  if (!hasSearchedOnce.current) {
    notFoundContent = 'Start typing to search booking requests';
  } else if (fetching) {
    notFoundContent = <Spin size="small" />;
  } else if (errorMessage) {
    notFoundContent = (
      <div className="text-color-secondary">
        Unable to fetch booking requests. Please Search Again
      </div>
    );
  }

  const onBookingRequerstChange = (changeValue: any, data: any): void => {
    let br: BookingRequestValue | undefined | Array<BookingRequestValue> | null[] =
      data?.booking_request || undefined;

    if (Array.isArray(changeValue)) {
      br = changeValue.map((val) => {
        let return_val = null;
        return_val = data.find(
          (booking_request: { value: string; booking_request?: BookingRequestValue }) =>
            booking_request.value === val.value
        )?.booking_request;
        return return_val;
      });
    }
    if (onSelectBookingRequest) onSelectBookingRequest(br);
  };

  const getLabelValueItem = (label: string, value: string | undefined) => {
    return (
      <small>
        <span style={{ color: '#586988', marginRight: '5px' }}>{label}:</span>
        <span style={{ fontWeight: 500, color: '#586988', marginRight: '10px' }}>{value}</span>
      </small>
    );
  };
  const getSelectOption = (br: BookingRequestValue) => {
    const mcrl = getMainCarriageDetail(br);
    const carrier =
      (br?.ocean_transport_orders || []).length > 0
        ? br?.ocean_transport_orders?.[0].global_carrier?.name
        : '';
    const customer = br?.sell_collaborations?.[0]?.customer_company?.registered_name;

    return (
      <Option title={br.shipment_booking_number} key={br.id} value={br.id} booking_request={br}>
        <Row className="flex">
          <Col span={24}>
            <Text>
              <div>
                <b>{br.shipment_booking_number}</b>
              </div>
            </Text>
            <Text>
              <div>
                {getLabelValueItem('Customer', customer)}
                {getLabelValueItem('Vessel', mcrl?.[0].vessel?.name)}
                {getLabelValueItem('Voyage', mcrl?.[0].voyage_number || undefined)}
              </div>
            </Text>
            <Text>
              <div>
                {getLabelValueItem('Carrier', carrier)}
                {getLabelValueItem('POL', mcrl?.[0].origin?.location?.name)}
                {getLabelValueItem('POD', mcrl?.[mcrl.length - 1].destination?.location?.name)}
              </div>
            </Text>
          </Col>
        </Row>
      </Option>
    );
  };

  return (
    <Select
      mode="multiple"
      value={getSelectValue(value)}
      onChange={onBookingRequerstChange}
      placeholder="Eg: BKDAMD123456"
      notFoundContent={notFoundContent}
      filterOption={false}
      showSearch
      onSearch={fetchData}
      allowClear
      optionLabelProp="title"
      labelInValue
      popupMatchSelectWidth={400}
      style={{ width: '100%' }}
      suffixIcon={<SearchOutlined />}
    >
      {!fetching && !loading && !error && bookingRequests.length > 0 && (
        <OptGroup label="ORDERS">
          {bookingRequests
            .filter((br) => br.load_type === 'fcl' && br.id !== bookingRequest.id)
            .map((br) => {
              return getSelectOption(br);
            })}
        </OptGroup>
      )}
    </Select>
  );
});

export default CustomerOrderSearch;
