import { ApolloError, useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import {
  CreditLimitCheckContext,
  CreditLimitCheckWrapper,
  Form,
  Skeleton,
  message,
} from '@shipmnts/pixel-hub';
import { LOAD_TYPE_FCL } from 'network/baseConstants';
import { SHIPMENT } from 'operations/graphql/shipment';
import Shipment, { ShipmentValue } from 'operations/models/Shipment';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'wouter';
import { FREIGHT_TYPE_OCEAN, TRADE_TYPE_EXPORT } from '../../constants';
import { getShipmentComponents, getShipmentApiHelper, redirectToShipment } from './helpers';
import { omit as _omit } from 'lodash';
import { useApplicationContentContext, useEmailDrawerContext, useSession } from 'common';
import { getInitialValue } from './getInitialValueHelper';
import {
  getGenericPayloadValue,
  getHouseDocumentPayload,
  getMasterDocumentPayload,
} from './getGenericPayloadHelper';
import FormLayout from '../ShipmentForm/FormLayout';
import { getSalesPersonForCustomer } from 'operations/helpers/fetchSalesPerson';
import InquiryOption, { InquiryOptionValue } from 'operations/models/InquiryOption';
import { GET_INQUIRY_OPTION } from 'operations/modules/booking/graphql/inquiryOption';
import { getJsonFromError } from 'operations/helpers/ruleEngineHelper';
import { BOOKING_TYPE_BOOKING_REQUEST } from 'operations/modules/booking/constants';
import { getFetchDocumentFunction } from 'operations/modules/helpers';

function useQuerySearch(): URLSearchParams {
  return new URLSearchParams(window.location.search);
}

interface NewShipmentFormProps {
  showCreditPopupWithAction?: (payload: any) => void;
}

function NewShipmentForm(props: NewShipmentFormProps) {
  const { showCreditPopupWithAction } = props;
  const { 1: navigate } = useLocation();
  const initialValue = window.history.state?.initialValue
    ? JSON.parse(window.history.state?.initialValue)
    : undefined;

  // hooks
  const [form] = Form.useForm();
  const params = useParams<{ id?: string; shipmentStatus?: string }>();
  const query = useQuerySearch();
  const sessionData = useSession();
  const client = useApolloClient();
  const { setVisible, setEmailProps } = useEmailDrawerContext();
  const { config_data } = useApplicationContentContext();

  // params
  const fromCustomerOrder: string | null | undefined = query.get('fromCustomerOrder');
  const fromOto: string | null | undefined = query.get('from_oto');
  const masterShipmentId: string | null | undefined = query.get('master_shipment_id');

  // states
  const [shipment, setShipment] = useState<ShipmentValue>();
  const [shipmentType, setShipmentType] = useState<string | null | undefined>(
    query.get('shipment_type')
  );
  const [freightType, setFreightType] = useState<string>(
    query.get('freight_type') || FREIGHT_TYPE_OCEAN
  );

  const [tradeType, setTradeType] = useState<string>(query.get('trade_type') || TRADE_TYPE_EXPORT);
  const [components, setComponents] = useState<any[]>([]);
  const [inquiryOption, setInquiryOption] = useState<InquiryOptionValue>();
  const [clearanceShipment, setClearanceShipment] = useState<boolean>(
    query.get('clearance_shipment') === 'true' ? true : false
  );

  const loadType = Form.useWatch('load_type', form);
  const bookingType = Form.useWatch('booking_type', form);
  const formInquiryOption = Form.useWatch('inquiry_option', form);
  const freight_forwarding_shipment = query.get('freight_forwarding_shipment') ? true : false;
  const [sendNotification, setSendNotification] = useState(false);

  // variables
  const { id: shipmentId } = params;
  const type = shipmentId === 'new' ? 'create' : 'update';
  const inquiry_option_id = query.get('inquiry_option_id');

  // mutations
  const [fetchShipment, { data: fetchedShipment }] = useLazyQuery(SHIPMENT);
  const [fetchInquiryOption, { data: fetchedInquiryOptionData }] = useLazyQuery(GET_INQUIRY_OPTION);
  const [createOrUpdateShipment, { data: createData, loading, error }] = useMutation(
    getShipmentApiHelper(type, shipmentType),
    { context: { skipShowError: true } }
  );

  useEffect(() => {
    if (fetchedInquiryOptionData?.get_inquiry_option) {
      setInquiryOption(InquiryOption.create(fetchedInquiryOptionData?.get_inquiry_option));
    }
  }, [fetchedInquiryOptionData]);

  useEffect(() => {
    if (inquiryOption) {
      const services = inquiryOption?.inquiry?.services;
      const origin_clearance_service = services?.includes('origin_custom_clearance');
      const destination_clearance_service = services?.includes('destination_custom_clearance');
      const freight_forwarding_service = services?.includes('freight_forwarding');

      if (
        (origin_clearance_service || destination_clearance_service) &&
        !freight_forwarding_service
      ) {
        setClearanceShipment(true);
      }
    }
  }, [inquiryOption]);

  useEffect(() => {
    if (loading) return;
    if (error) {
      const graphQLErrors = error instanceof ApolloError ? error.graphQLErrors : error;
      const data: any = getJsonFromError(graphQLErrors);
      if (data && showCreditPopupWithAction) {
        const values: any = form.getFieldsValue();
        setSendNotification(false);
        showCreditPopupWithAction({
          data: data,
          onSucess: () => {
            const variables: any = {};
            if (values.house?.shipment_document) {
              variables['shipment_document_house'] = getHouseDocumentPayload(values);
            }
            if (values.master?.shipment_document) {
              variables['shipment_document_master'] = getMasterDocumentPayload(values);
            }
            const payload: any = getGenericPayloadValue(
              {
                ...values,
                type,
                shipment_containers:
                  initialValue?.selectedContainers || values?.shipment_containers,
                shipment_type: shipmentType,
              },
              shipment
            );
            if (fromOto) {
              payload.allocate_ocean_transport_orders = [
                {
                  id: fromOto,
                  container_requests: values.shipment_container_quantity.map((cr: any) => ({
                    id: cr.id,
                    count: cr.quantity,
                  })),
                },
              ];
            }
            if (masterShipmentId) {
              variables['house_shipment'] = {
                ..._omit(payload, [
                  'freight_type',
                  'trade_type',
                  'involved_branch_id',
                  'job_date',
                  'shipment_type',
                ]),
              };
              variables['master_shipment_id'] = masterShipmentId;
            } else if (!shipmentType && shipmentId === 'new')
              variables['planned_shipment'] = payload;
            else variables['shipment'] = payload;
            if (shipmentId !== 'new') variables['id'] = shipment?.id;
            createOrUpdateShipment({ variables: variables });
          },
        });
      } else {
        message.error(error.message);
      }
    }
    if (createData?.create_shipment) {
      message.success(`${shipment?.getDisplayName() || 'Shipment'} created successfully`);
      navigate(
        `~/view/shipment/${createData?.create_shipment?.shipment?.id}/documents?first_load=1`
      );
    }
  }, [
    createData,
    loading,
    error,
    shipment,
    createOrUpdateShipment,
    form,
    showCreditPopupWithAction,
    fromOto,
    initialValue,
    masterShipmentId,
    shipmentId,
    shipmentType,
    type,
    navigate,
  ]);

  useEffect(() => {
    if (shipmentId !== 'new') {
      fetchShipment({ variables: { id: shipmentId } });
    }
  }, [fetchShipment, shipmentId]);

  // fetching inquiry data
  useEffect(() => {
    if (inquiry_option_id) {
      fetchInquiryOption({ variables: { id: inquiry_option_id } });
    }
  }, [fetchInquiryOption, inquiry_option_id]);

  useEffect(() => {
    if (fetchedShipment?.shipment) {
      const shipmentMbx = Shipment.create(fetchedShipment.shipment);
      setShipment(shipmentMbx);
      if (shipmentMbx.freight_type) setFreightType(shipmentMbx.freight_type);
      if (shipmentMbx.trade_type) setTradeType(shipmentMbx.trade_type);
      setShipmentType(shipmentMbx.shipment_type);
    }
  }, [fetchedShipment]);

  // after create
  useEffect(() => {
    if (loading) return;
    if (error) {
      const graphQLErrors = error instanceof ApolloError ? error.graphQLErrors : error;
      const data: any = getJsonFromError(graphQLErrors);
      if (!data) message.error(error.message);
    }
    const shipment =
      createData?.update_shipment_new ||
      createData?.create_house_shipment ||
      createData?.create_planned_shipment ||
      createData?.create_shipment;
    if (createData && shipment) {
      if (sendNotification && !shipmentType) {
        const isBookingConfirmed = shipment?.ocean_transport_orders?.length;
        setEmailProps({
          title: `${
            isBookingConfirmed ? 'Booking Confirmation' : 'Customer Order Acknowledgement'
          }`,
          action_name: `${
            isBookingConfirmed ? 'booking_confirmation' : 'booking_request_acknowledgement'
          }`,
          resource_ids: [shipment?.id],
          fetchDocumentParents: [
            {
              parent_type: BOOKING_TYPE_BOOKING_REQUEST,
              parent_ids: [shipment?.id],
            },
          ],
          companies_roles_mapping: [],
          showMarkDown: true,
          showEmail: false,
          fetchDocuments: client
            ? getFetchDocumentFunction(shipment, client, sessionData, config_data)
            : undefined,
        });
        setVisible(true);
      }
      redirectToShipment(type, shipmentType, createData, navigate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createData, error]);

  // get components
  useEffect(() => {
    setComponents(
      getShipmentComponents({
        freightType: freightType,
        tradeType: tradeType,
        form,
        shipment: shipment,
        type: shipmentId === 'new' ? 'create' : 'update',
        shipmentType: shipmentType,
        fromCustomerOrder,
        fromOto,
        loadType: loadType || shipment?.load_type || LOAD_TYPE_FCL,
        bookingType,
        tennantCountry: sessionData?.company_account?.country_of_incorporation,
        isClearance: clearanceShipment,
        inquiryOption: formInquiryOption,
        sessionData,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    shipment,
    freightType,
    tradeType,
    loadType,
    form,
    bookingType,
    setComponents,
    shipmentId,
    clearanceShipment,
    formInquiryOption,
  ]);

  const formInitialValue: any = getInitialValue(
    shipment || initialValue,
    {
      trade_type: tradeType,
      freight_type: freightType,
      shipment_type: shipmentType,
      clearance_shipment: clearanceShipment,
      freight_forwarding_shipment: freight_forwarding_shipment,
    },
    initialValue ? true : false,
    inquiryOption,
    sessionData
  );

  if ((shipmentId !== 'new' && !shipment) || (inquiry_option_id && !inquiryOption)) {
    return <Skeleton />;
  }

  return (
    <Form
      form={form}
      initialValues={formInitialValue}
      onFinish={(values): any => {
        const variables: any = {};
        if (values.house?.shipment_document) {
          variables['shipment_document_house'] = getHouseDocumentPayload(values);
        }
        if (values.master?.shipment_document) {
          variables['shipment_document_master'] = getMasterDocumentPayload(values);
        }
        const payload: any = getGenericPayloadValue(
          {
            ...values,
            type,
            shipment_containers: initialValue?.selectedContainers || values?.shipment_containers,
            shipment_type: shipmentType,
          },
          shipment
        );
        if (fromOto) {
          payload.allocate_ocean_transport_orders = [
            {
              id: fromOto,
              container_requests: values.shipment_container_quantity.map((cr: any) => ({
                id: cr.id,
                count: cr.quantity,
              })),
            },
          ];
        }
        if (masterShipmentId) {
          variables['house_shipment'] = {
            ..._omit(payload, [
              'freight_type',
              'trade_type',
              'involved_branch_id',
              'job_date',
              'shipment_type',
            ]),
          };
          variables['master_shipment_id'] = masterShipmentId;
        } else if (!shipmentType && shipmentId === 'new') variables['planned_shipment'] = payload;
        else variables['shipment'] = payload;

        variables['throw_error_on_credit_fail'] = true;
        if (shipmentId !== 'new') variables['id'] = shipment?.id;
        createOrUpdateShipment({ variables: variables });
      }}
      layout="vertical"
      style={{
        height: '100%',
      }}
      onValuesChange={async (changedValues) => {
        const formValues: any = {};
        if (changedValues.terms_and_condition) {
          formValues['terms_and_condition_description'] =
            changedValues.terms_and_condition?.content;
          //TODO: @gaurav-bajaj check for sales person update
        }
        if (changedValues.hasOwnProperty('customer')) {
          const branch_id = form.getFieldValue('involved_branch')?.id;
          const customer = changedValues.customer;
          if (branch_id && customer) {
            const { response } = await getSalesPersonForCustomer(
              customer?.party_company?.id,
              branch_id,
              sessionData,
              client
            );
            if (response) {
              formValues['sales_agent'] = response;
            }
          }
        }
        if (Object.keys(formValues).length > 0) form.setFieldsValue(formValues);
      }}
      requiredMark={true}
    >
      <FormLayout
        form={form}
        components={components}
        formTitle={
          <>
            {shipmentId === 'new' ? 'Create' : 'Update'}{' '}
            {shipmentType ? 'Shipment' : 'Customer Order'}{' '}
            {(shipment?.job_number || shipment?.shipment_booking_number) && (
              <Link to={'/view/shipment/' + shipment?.id}>
                {shipment?.job_number || shipment?.shipment_booking_number}
              </Link>
            )}
          </>
        }
        loading={loading}
        formSubTitle={shipment?.shipment_type ? shipment?.getShipmentType() : shipmentType || ''}
        formOnSubmitText={`${shipmentId === 'new' ? 'Create' : 'Update'}`}
        onBack={() => {
          if (shipmentId === 'new') {
            if (fromCustomerOrder === 'true') {
              navigate('/view/shipment/' + initialValue.id);
            } else {
              navigate('/workspace?doc_type=Shipment::Shipment');
            }
          } else {
            navigate('/view/shipment/' + shipmentId);
          }
        }}
        sendEmailProps={
          !shipmentType
            ? {
                label: 'Send Notification',
                sendNotification: sendNotification,
                setSendNotification: setSendNotification,
              }
            : undefined
        }
      />
    </Form>
  );
}

export default function NewShipmentFormWrapper() {
  const sessionData = useSession();
  return (
    <CreditLimitCheckWrapper sessionData={sessionData}>
      <CreditLimitCheckContext.Consumer>
        {(contextProps) => {
          return (
            <NewShipmentForm showCreditPopupWithAction={contextProps?.showCreditPopupWithAction} />
          );
        }}
      </CreditLimitCheckContext.Consumer>
    </CreditLimitCheckWrapper>
  );
}
