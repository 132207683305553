import React from 'react';
import { BookingRequestValue } from 'operations/models/BookingRequest';
import BasicInfoRender, { InfoDataValue } from '../BasicInfoRenderer';
import {
  getCarriageWiseRoutingLegs,
  ROUTING_TYPE_MAIN_CARRIAGE,
} from 'operations/models/RoutingLeg';
import { convertToStringFormat, DEFAULT_DATE_FORMAT } from '@shipmnts/pixel-hub';

export const BookingRequestBasicInfo = React.memo(function BookingRequestBasicInfo(props: {
  bookingRequest: BookingRequestValue | undefined;
}): JSX.Element {
  const { bookingRequest } = props;
  if (!bookingRequest) return <></>;
  const oto = bookingRequest.ocean_transport_orders[0];
  const carriageWiseRoutingLegs = getCarriageWiseRoutingLegs(oto?.routing_legs);
  const mcrl = carriageWiseRoutingLegs[ROUTING_TYPE_MAIN_CARRIAGE];
  const eta = mcrl?.[mcrl.length - 1]?.estimated_time_of_arrival;
  const etd = mcrl?.[0].estimated_time_of_departure;

  const basicInfoData: InfoDataValue[] = [
    { label: 'Order Number', value: bookingRequest.shipment_booking_number },
    { label: 'Customer', value: bookingRequest.customerCompany?.registered_name },
    { label: 'Shipper', value: bookingRequest.shipper?.party_company?.registered_name },
    { label: 'Order By', value: bookingRequest.salesPersonName },
    { label: 'Created By', value: bookingRequest.createdByName },
    { label: 'Port of Loading', value: mcrl?.[0].origin?.location?.name },
    { label: 'ETD', value: (etd && convertToStringFormat(etd, DEFAULT_DATE_FORMAT)) || '-' },
    { label: 'Voyage', value: [mcrl?.[0].vessel?.name, mcrl?.[0].voyage_number].join(' | ') },
    {
      label: 'Port of Discharge',
      value: mcrl?.[mcrl.length - 1].destination?.location?.name,
    },
    { label: 'ETA', value: (eta && convertToStringFormat(eta, DEFAULT_DATE_FORMAT)) || '-' },
  ];

  return (
    <>
      <BasicInfoRender
        title="Basic Info"
        noOfColumns={2}
        layout="horizontal"
        showColon={false}
        labelStyle={{ color: '#586988', minWidth: '40%' }}
        descriptionItemStyle={{ paddingBottom: '3px' }}
        displayData={basicInfoData}
      />
    </>
  );
});
