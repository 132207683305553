import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  Button,
  Layout,
  Result,
  Skeleton,
  Tabs,
  CustomSiderContextProvider,
} from '@shipmnts/pixel-hub';
import { Link, useLocation, useParams } from 'wouter';

import { AppHelmet, ComponentWrapper } from '@shipmnts/pixel-hub';
import { ContactValue } from 'network/models/Contact';

import { Header } from './Header';
import { ActivityTab } from './Tabs/ActivityTab';
import { DocumentsTab } from './Tabs/DocumentsTab';

import { ContactViewContextType, ContactViewType, TabBarOptionType, TabBarPropType } from './types';
import TabPane from 'antd/lib/tabs/TabPane';
import { FETCH_USER_CONTACT } from 'network/graphql/contact';
// context: so that we can easily use different states across the tabs & cards
const ContactViewContext = createContext<ContactViewContextType | null>(null);

// components
const TabBar = ({ options, activeKey, setActiveKey }: TabBarPropType) => {
  return (
    <Tabs
      className="view-tabs"
      tabBarStyle={{ background: 'white', paddingInline: '24px' }}
      activeKey={activeKey.toString()}
      onChange={(e) => setActiveKey(parseInt(e))}
    >
      {options.map((opt) => {
        return <TabPane tab={opt.label} key={opt.key} />;
      })}
    </Tabs>
  );
};

const validateTabKey = (options: Array<TabBarOptionType>, key?: string | number) => {
  if (!key || isNaN(Number(key))) return 1;
  key = Number(key);
  const opt = options.find((op) => op.key === key);
  if (!!opt && !opt?.disable) return key;
  return 1;
};

const ContactView = ({ id, onClose, externalLink, internal }: ContactViewType): JSX.Element => {
  // hooks
  const params = useParams<{ contact_id: string }>();
  const [location, navigate] = useLocation();

  const contact_data = window.history?.state?.contact;
  const refetchContact = window.history?.state?.refetch;

  const searchParams = useMemo(() => new URLSearchParams(window.location.search), []);

  const contact_id = (params?.contact_id || id || '').toString();

  // states
  const [contact, setContact] = useState<ContactValue | undefined>(contact_data);

  // queries & mutations
  const { loading, error, data, refetch } = useQuery(FETCH_USER_CONTACT, {
    variables: { id: contact_id },
    skip: !!contact_data && !refetchContact, // if we have contact data & don't need to refetch
    fetchPolicy: 'network-only',
  });

  // callback when contact is refetched from actions
  const onRefetchContact = useCallback(async () => {
    if (refetch) {
      const { data } = await refetch();
      if (data?.fetch_user_contact) {
        setContact(data?.fetch_user_contact);
      }
    }
  }, [refetch, setContact]);

  // effects
  // NOTE: change below two useEffect carefully, have designed it in such a way to prevent multiple api calls
  useEffect(() => {
    if (
      (!error &&
        data?.fetch_user_contact &&
        (!contact || data?.fetch_user_contact?.id !== contact?.id)) || // if we have data && it is different from contact we already have (or if we don't have)
      (refetchContact && data?.fetch_user_contact) // if we have refetched data
    ) {
      setContact(data?.fetch_user_contact);
      setTabKey(1);
    }
  }, [contact_id, contact, data, error, refetchContact]);

  // modifying history state ???
  useEffect(() => {
    const state = { ...window.history?.state };
    delete state?.contact;
    delete state?.refetch;
    navigate(location, { state: state, replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // no loading + current contact's id !== current contact_id
    if (!loading && contact_id !== contact?.id && contact_id !== data?.fetch_user_contact?.id) {
      if (contact_data) {
        setContact(contact_data);
      } else {
        refetch({ id: contact_id });
      }
    }
  }, [contact_id, contact, contact_data, loading, data, refetch]);

  let fetchError = null;
  if (error) {
    const showLoading = loading && !!!contact;
    let errorCode: 500 | 404 | 'error' = 500,
      title = 'Something went wrong while fetching the contact.',
      subTitle = error?.message,
      button = (
        <Button loading={showLoading} onClick={() => refetch()} type="primary">
          Retry
        </Button>
      );
    if (error?.message.includes('Network::UserContact for given id Not found')) {
      errorCode = 404;
      title = `Contact with ID: '${id}' does not exist`;
      subTitle = '';
      button = (
        <Button type="primary">
          <Link to="/">Go Home</Link>
        </Button>
      );
    }
    fetchError = <Result status={errorCode} title={title} subTitle={subTitle} extra={button} />;
  }

  // callback
  const onContactUpdate = useCallback((contact: ContactValue) => {
    setContact(contact);
  }, []);

  // options to show in tab bar
  const options: Array<TabBarOptionType> = useMemo(
    () => [
      {
        key: 1,
        id: 'Contact_activities',
        label: 'Activity Feed',
        content: ActivityTab,
        disable: false,
      },
      {
        key: 2,
        id: 'docs',
        label: 'Documents',
        content: DocumentsTab,
        disable: false,
      },
    ],
    []
  );

  // to access component mapped to option more easily
  const component_map: { [key: number]: { Component: any; props: any } } = options.reduce(
    (acc, option) => {
      if (option?.disable) return acc;
      acc[option.key] = { Component: option.content, props: option.props };
      return acc;
    },
    {} as { [key: number]: { Component: any; props: any } }
  );

  // Tab handling based on url query params
  const tab = searchParams.get('tab')
    ? validateTabKey(options, searchParams.get('tab') || '')
    : null;
  // for current active tab
  const [tabKey, setTabKey] = useState(tab || 1);
  useEffect(() => {
    if (searchParams.has('tab')) {
      searchParams.delete('tab');
      navigate(`${location}${searchParams.toString()}`, { replace: true });
    }
  }, [searchParams, location, navigate]);

  const { Component, props } = component_map[tabKey];

  return (
    <ComponentWrapper loading={false} error={error} refetch={refetch}>
      <Layout style={{ backgroundColor: '#F5F5F5', height: 'content-fit', minHeight: '100%' }}>
        <AppHelmet>
          <title>{[contact?.first_name, contact?.last_name].join(' ').trim()}</title>
        </AppHelmet>
        {fetchError}
        {!fetchError && contact && (
          <ContactViewContext.Provider
            value={{
              contact,
              loading,
              fetchError,
              refetch: onRefetchContact,
              setContact,
              onClose,
              onContactUpdate,
            }}
          >
            {!internal ? (
              <CustomSiderContextProvider
                records={(contact?.collaborations as any[]) || []}
                order={[
                  'Network::Company',
                  'Email::Task',
                  'SalesHub::Inquiry',
                  'Shipment::Shipment',
                ]}
                title="Associations"
                defaultOpen={false}
              >
                {!!contact && (
                  <div style={{ width: '100%', marginRight: '-20px' }}>
                    <Skeleton avatar active style={{ padding: '1em' }} loading={loading}>
                      <Header externalLink={externalLink} internal={internal} />
                    </Skeleton>
                    <TabBar
                      options={options.filter((opt) => !opt.disable)}
                      activeKey={tabKey}
                      setActiveKey={setTabKey}
                    />
                    {Component && <Component {...props} />}
                  </div>
                )}
              </CustomSiderContextProvider>
            ) : (
              <>
                {!!contact && (
                  <div style={{ width: '100%', marginRight: '-20px' }}>
                    <Skeleton avatar active style={{ padding: '1em' }} loading={loading}>
                      <Header externalLink={externalLink} internal={internal} />
                    </Skeleton>
                    <TabBar
                      options={options.filter((opt) => !opt.disable)}
                      activeKey={tabKey}
                      setActiveKey={setTabKey}
                    />
                    {Component && <Component {...props} />}
                  </div>
                )}
              </>
            )}
          </ContactViewContext.Provider>
        )}
      </Layout>
    </ComponentWrapper>
  );
};

export const useContactView = () => {
  const contextValue = useContext(ContactViewContext);
  if (contextValue === null) {
    throw new Error('contextValue cannot be null, please add a context provider');
  }
  return contextValue;
};

export const ContactViewWrapper = function ContactViewWrapper(props: ContactViewType): JSX.Element {
  return <ContactView {...props} />;
};

export default ContactView;
