import React, { useState, useRef, useEffect, useCallback } from 'react';
import { GridOptions, ColumnRowGroupChangedEvent, GridApi } from '@ag-grid-community/core';
import { useApolloClient, ApolloClient } from '@apollo/client';
import {
  Typography,
  Form,
  ClientSideReport,
  Dayjs,
  dayjsGenerateConfig,
  DatePicker,
} from '@shipmnts/pixel-hub';
import { pickBy as _pickBy } from 'lodash';
import { ContainerQuantityType, ContainerRequestValue } from 'operations/models/ContainerRequest';
import { Variables } from 'operations/models/Report';
import {
  VesselSearch,
  change_level,
  getColumnFiltersinGraphqlFormat,
  formatOverlayNoRowsTemplate,
} from 'common';
import { br_dashboard_columns_mapping } from './BookingRequestReportFields';
import { BOOKING_REQUEST_DASHBOARD_REPORT } from 'operations/modules/reports/graphql/bookingRequestReport';
import { BookingRequestReportData, renderBookingRequestData } from './BookingRequestReports';
import {
  DEFAULT_DASHBOARD_REPORT_HEIGHT,
  STATUS_ALL_REPORTS,
} from 'operations/modules/reports/constants';
import {
  renderContainerQuantity,
  renderLinkCell,
} from 'operations/modules/reports/oldCellRenderers';
import SummaryCard, { CardType } from '../SummaryCard';
import { LOAD_TYPE_FCL } from 'operations/baseConstants';
import { RangeValueType } from 'rc-picker/lib/PickerInput/RangePicker';
const { RangePicker } = DatePicker;
const PAGE_SIZE = 500;
const groupColId = 'ag-Grid-AutoColumn';

const components = {
  renderLinkCell: renderLinkCell,
};

const quantity_fields: ContainerQuantityType[] = [
  'quantity',
  'quantity_fulfilled',
  'quantity_unfulfilled',
  'quantity_picked_up',
  'quantity_origin_port_gated_in',
  'quantity_loaded_on_vessel',
  'quantity_shutout',
  'quantity_offloaded',
  'quantity_cancelled',
];

const cards: { [key: string]: CardType } = {
  customer_order_status: {
    title: (
      <div>
        Customer Order Status{' '}
        <Typography.Text type="secondary">( Container counts )</Typography.Text>
      </div>
    ),
    children: [
      { key: 'quantity', title: 'Order Received', minWidth: 100 },
      { key: 'quantity_fulfilled', title: 'Allocated', minWidth: 100 },
      { key: 'quantity_unfulfilled', title: 'Allocation Pending', minWidth: 150 },
      { key: 'quantity_offloaded', title: 'Offloaded', minWidth: 100 },
      { key: 'quantity_cancelled', title: 'Cancelled', minWidth: 100 },
    ],
  },
  container_operations_status: {
    title: 'Container Ops',
    children: [
      {
        key: 'quantity_picked_up',
        title: 'Pickedup',
        minWidth: 100,
      },
      {
        key: 'quantity_origin_port_gated_in',
        title: 'Gated in',
        minWidth: 100,
      },
      {
        key: 'quantity_loaded_on_vessel',
        title: 'Loaded',
        minWidth: 100,
      },
    ],
  },
};

export const getTotalRow = (
  bookingRequestdata: BookingRequestReportData[],
  setTotalQuantities: (object: Record<ContainerQuantityType, number>) => void
) => {
  const total_quantity_obj = quantity_fields.reduce(
    (total_obj: Record<string, number>, field: string) => {
      total_obj[field] = 0;
      return total_obj;
    },
    {}
  );

  bookingRequestdata.forEach((bookingRequest) => {
    bookingRequest.container_requests.forEach((cr: ContainerRequestValue) => {
      quantity_fields.forEach((field: ContainerQuantityType) => {
        total_quantity_obj[field] += cr?.[field] || 0;
      });
    });
  });
  setTotalQuantities(total_quantity_obj);
  return {
    ...total_quantity_obj,
    id: 'Grand Total  :',
  };
};

const fetchBookingRequestDashboardData = async (
  variables: Variables,
  client: ApolloClient<object>
) => {
  const { data, errors } = await client.query({
    query: BOOKING_REQUEST_DASHBOARD_REPORT,
    variables,
    fetchPolicy: 'network-only',
  });
  if (errors) console.error(errors);
  return {
    data: data?.booking_request_dashboard_report.data,
    total: data?.booking_request_dashboard_report.total,
    error: errors ? true : false,
  };
};

const renderBookingDashboardData = (bookingRequestData?: BookingRequestReportData[]) => {
  return (bookingRequestData || []).map((row) => {
    const quantityObj = quantity_fields.reduce(
      (QuantityObj: Record<string, number>, field: ContainerQuantityType) => {
        QuantityObj[field] = renderContainerQuantity(row.container_requests, field);
        return QuantityObj;
      },
      {}
    );
    return {
      ...renderBookingRequestData(row),
      ...quantityObj,
    };
  });
};

const required_filter_overlay = 'Please Enter Either ETD or Vessel Filter To Run This Report';

const BookingRequestDashboard = React.memo(function BookingRequestDashboard(): JSX.Element {
  const queryParams = new URLSearchParams(window.location.search);
  const report_name = queryParams.get('report_name') || 'booking_request_dashboard';
  const columns = br_dashboard_columns_mapping[report_name];
  const [form] = Form.useForm();
  const [bookingRequestData, setBookingRequestData] = useState<any[]>([]);
  const gridRef = useRef<GridOptions>();

  const client = useApolloClient();
  const [etdRange, setEtdRange] = useState<RangeValueType<Dayjs>>([
    dayjsGenerateConfig.getNow(),
    dayjsGenerateConfig.getNow().add(14, 'day'),
  ]);
  const [totalQuantities, setTotalQuantities] = useState<
    Record<ContainerQuantityType, number> | undefined
  >();
  const [overlayNoRowsTemplate, setOverlayNoRowsTemplate] =
    useState<string>(required_filter_overlay);
  const [loading, setLoading] = useState<boolean>(false);

  const updateTotalRow = useCallback(() => {
    const rowData: Array<any> = [];
    gridRef?.current?.api?.forEachNodeAfterFilter((node) => {
      if (!node.group) rowData.push(node.data);
    });

    const totalRow = getTotalRow(rowData, setTotalQuantities);
    gridRef?.current?.api?.setPinnedBottomRowData([totalRow]);
  }, []);

  const changeLevels = useCallback((numberOfRowGroups: number) => {
    if (gridRef?.current?.api) change_level(numberOfRowGroups - 1, gridRef.current.api);
  }, []);

  const handleRowGroupChange = useCallback(
    (event: ColumnRowGroupChangedEvent) => {
      changeLevels(event.columns?.length || 0);
    },
    [changeLevels]
  );

  const onGridReady = useCallback(
    (api: GridApi | null | undefined) => {
      if (!api) return;
      const rowGroups = (api.getColumnDefs() || []).filter((col) => {
        if ('rowGroup' in col) return col.rowGroup;
        return false;
      }).length;
      changeLevels(rowGroups);
    },
    [changeLevels]
  );

  const updateGridData = useCallback(
    (data: any) => {
      if (!gridRef?.current) return;
      gridRef?.current?.api?.setRowData(data);
      updateTotalRow();
      onGridReady(gridRef?.current?.api);
      setTimeout(() => {
        gridRef?.current?.columnApi?.autoSizeColumn(groupColId);
        gridRef?.current?.columnApi?.autoSizeAllColumns();
      }, 500);
    },
    [updateTotalRow, onGridReady]
  );

  useEffect(() => {
    updateGridData(bookingRequestData);
  }, [updateGridData, bookingRequestData]);

  const updateDataSource = useCallback(() => {
    form
      .validateFields()
      .then(async (values) => {
        const valuesPresent = _pickBy(values, (value) => {
          if (!value) return false;
          if (Array.isArray(value)) {
            return value.length > 0;
          }
          return true;
        });
        if (Object.keys(valuesPresent).length === 0) {
          setOverlayNoRowsTemplate(required_filter_overlay);
          setBookingRequestData([]);
          gridRef?.current?.api?.showNoRowsOverlay();
          return;
        }
        gridRef?.current?.api?.showLoadingOverlay();
        setLoading(true);
        const variables = {
          limit: PAGE_SIZE,
          offset: 0,
          filters: [
            {
              col: 'report_status',
              condition: {
                filter_type: 'text',
                type: 'equals',
                value: STATUS_ALL_REPORTS,
              },
            },
            {
              col: 'load_type',
              condition: {
                filter_type: 'text',
                type: 'equals',
                value: LOAD_TYPE_FCL,
              },
            },
            ...getColumnFiltersinGraphqlFormat({
              columnFilterValues: { vessel_id: valuesPresent.vessel_id },
              returnKey: 'imo',
            }),
            ...getColumnFiltersinGraphqlFormat({
              columnFilterValues: {
                estimated_time_of_departure: valuesPresent.estimated_time_of_departure,
              },
            }),
          ],
        } as Variables;
        const { data, error } = await fetchBookingRequestDashboardData(variables, client);
        gridRef?.current?.api?.hideOverlay();
        setLoading(false);
        setOverlayNoRowsTemplate('No vendor bookings found');
        if (error) {
          gridRef?.current?.api?.showNoRowsOverlay();
        }
        setBookingRequestData(renderBookingDashboardData(data));
      })
      .catch((error) => console.log(error));
  }, [client, form]);

  const disabledEtdDate = useCallback(
    (current: Dayjs) => {
      if (!etdRange) {
        return false;
      }
      const tooLate = etdRange[0] && current.diff(etdRange[0], 'day') > 14;
      const tooEarly = etdRange[1] && etdRange[1].diff(current, 'day') > 14;
      return Boolean(tooEarly || tooLate);
    },
    [etdRange]
  );

  const externalFilters = [
    {
      label: 'ETD',
      component: RangePicker,
      name: 'estimated_time_of_departure',
      componentProps: {
        style: { width: '100%' },
        disabledDate: disabledEtdDate,
        value: etdRange,
        onCalendarChange: setEtdRange,
      },
    },
    {
      label: 'Vessel',
      component: VesselSearch,
      name: 'vessel_id',
      componentProps: {
        selectMode: 'multiple',
        style: { width: '100%' },
      },
    },
  ];

  return (
    <ClientSideReport
      gridRef={gridRef}
      rowData={bookingRequestData || []}
      report_name={report_name}
      columns={columns}
      height={DEFAULT_DASHBOARD_REPORT_HEIGHT}
      reportConfig={{
        overlayNoRowsTemplate: formatOverlayNoRowsTemplate(overlayNoRowsTemplate),
        components: components,
        getRowId: (params: any) => params.data.id,
        // autoGroupColumnDef: {
        //   headerName: 'Group',
        //   field: 'shipment_booking_number',
        //   filter: 'agTextColumnFilter',
        //   minWidth: 100,
        //   maxWidth: 400,
        //   cellRenderer: 'agGroupCellRenderer',
        //   cellRendererParams: {
        //     suppressCount: true,
        //     innerRenderer: 'renderLinkCell',
        //     redirectToBase: `/view/booking_request`,
        //     idField: 'id',
        //   },
        //   pinned: 'left',
        //   tooltipValueGetter: (params) => {
        //     return params.value;
        //   },
        // },
        onColumnRowGroupChanged: handleRowGroupChange,
        onFilterChanged: () => updateTotalRow(),
      }}
      onGridReady={onGridReady}
      externalFilters={externalFilters}
      updateDataSource={updateDataSource}
      form={form}
      summarySection={
        <SummaryCard totalQuantities={totalQuantities} loading={loading} cards={cards} />
      }
      disableFilterCaching={['estimated_time_of_departure']}
      initialExternalFilterValue={{
        estimated_time_of_departure: [
          dayjsGenerateConfig.getNow(),
          dayjsGenerateConfig.getNow().add(14, 'day'),
        ],
      }}
    />
  );
});

export default BookingRequestDashboard;
