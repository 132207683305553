import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { GET_OCEAN_TRANSPORT_ORDER } from '../booking/graphql/oceanTransportOrder';
import UpdateOrSplitOto from './UpdateOrSplitOto';
import { ShipmentValue } from 'operations/models/Shipment';
import OceanTransportOrder, {
  OceanTransportOrderValue,
} from 'operations/models/OceanTransportOrder';

interface UpdateOrSplitOtoWrapperProps {
  shipment: ShipmentValue;
  onClose: () => void;
  onSuccess: () => void;
}
export default function UpdateOrSplitOtoWrapper(props: UpdateOrSplitOtoWrapperProps) {
  const { shipment } = props;
  const { data } = useQuery(GET_OCEAN_TRANSPORT_ORDER, {
    variables: { id: shipment.ocean_transport_order_id },
  });
  const [oto, setOto] = useState<OceanTransportOrderValue>();

  useEffect(() => {
    if (data?.ocean_transport_order) {
      setOto(OceanTransportOrder.create(data?.ocean_transport_order));
    }
  }, [data]);

  if (!oto) return <></>;
  return <UpdateOrSplitOto oceanTransportOrder={oto} {...props} />;
}
