import React, { useEffect, useState } from 'react';
import { Button, Divider, Tag, Tooltip } from '@shipmnts/pixel-hub';
import { PageHeader, RenderCollaborators } from '@shipmnts/pixel-hub';
import { upperCase as _upperCase } from 'lodash';
import { EditOutlined, CustomIcon, useActivityContext } from '@shipmnts/pixel-hub';
import { ActionRenderer } from 'operations/modules/actionHelper/ActionRenderer';
import { useLocation } from 'wouter';
import { dividerStyles } from '../../shipment/components/DetailLayout/Common/common';
import { ProductOrderValue } from 'operations/models/ProductOrder';

type OMSDetailHeaderProps = {
  onClose?: () => void;
  externalLink?: boolean;
  productOrder: ProductOrderValue;
};

const OMSDetailHeader = (props: OMSDetailHeaderProps) => {
  const { onClose, externalLink, productOrder } = props;
  const { 1: navigate } = useLocation();
  const [editDisabled, setEditDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (productOrder) {
      if (productOrder.total_shipment_planned_qty || productOrder.status === 'cancelled') {
        setEditDisabled(true);
      }
    }
  }, [productOrder]);

  let order_number: string;
  if (productOrder.type === 'purchase') {
    order_number = productOrder?.purchase_order_number || '';
  } else {
    order_number = productOrder?.sales_order_number || '';
  }

  const { setActivityProps } = useActivityContext();

  const extras = [
    <RenderCollaborators
      id={order_number}
      referenceId={productOrder?.id}
      referenceType={
        productOrder.type !== 'sales'
          ? 'OrderManagement::PurchaseOrder'
          : 'OrderManagement::SalesOrder'
      }
      key=""
    />,
    <Button
      key="shipment_header_activity" // updated
      size={'small'}
      style={{ display: 'flex', alignItems: 'center' }}
      onClick={() => {
        setActivityProps({
          visible: true,
          resource_id: productOrder.id,
          resource_type: 'OrderManagement::ProductOrderGlobal',
          showDrawer: externalLink,
          email_resource_id: productOrder.id,
          email_resource_type:
            productOrder.type === 'sales'
              ? 'OrderManagement::SalesOrder'
              : 'OrderManagement::PurchaseOrder',
        });
      }}
    >
      Activity
      <span style={{ marginLeft: '5px', display: 'flex' }}>
        <CustomIcon icon="ActivityIcon" />
      </span>
    </Button>,
    <Button
      key={'edit'}
      size={'small'}
      disabled={editDisabled}
      onClick={() => {
        if (externalLink) {
          window.open(`/form/product_order/${productOrder?.id}?type=${productOrder.type}`);
        } else {
          navigate(`~/form/product_order/${productOrder?.id}?type=${productOrder.type}`);
        }
      }}
    >
      Edit
      <span style={{ marginLeft: '5px' }}>
        <EditOutlined />
      </span>
    </Button>,
    <ActionRenderer
      id={productOrder.id}
      doc_type_id="OrderManagement::ProductOrder"
      // refetchData={refetchInquiry}
      isDetailScreen
      data={productOrder}
      key="actions"
    />,
  ];

  const orderCancel = productOrder?.status === 'cancelled';

  const titleAndBackButton = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          paddingLeft: '0px',
        }}
      >
        <div
          style={{
            fontSize: '18px',
            fontWeight: 600,
            color: '#0A4FCA', //Todo: constants
            marginLeft: '14px',
          }}
        >
          {order_number}

          {externalLink && (
            <span>
              <Tooltip title="Open Detail Screen">
                <a href={`/view/product_order/${productOrder.id}`} target="_blank" rel="noreferrer">
                  <CustomIcon icon="ExternalLinkIcon" />
                </a>
              </Tooltip>
            </span>
          )}
        </div>
        {orderCancel ? (
          <div style={{ fontSize: '12px', color: '#586988' }}>
            <Tag color="pink" style={{ marginLeft: '5px' }}>
              Cancelled
            </Tag>
          </div>
        ) : (
          <div style={{ display: 'flex', marginLeft: '5px' }}>
            <Tag color="blue">{_upperCase(productOrder?.status || '')}</Tag>
          </div>
        )}
      </div>
    );
  };
  const handleOnClose = () => {
    if (onClose) onClose();
    else {
      window.open(
        `/workspace?doc_type=OrderManagement::ProductOrder&resource_id=${productOrder.id}`,
        '_self'
      );
    }
  };
  return (
    <PageHeader
      ghost={false}
      onBack={handleOnClose}
      title={titleAndBackButton()}
      extra={extras}
      className={'shipment-detail-header'}
      style={{
        background: '#ffffff',
      }}
    >
      <Divider style={dividerStyles} />
    </PageHeader>
  );
};

export default OMSDetailHeader;
