import { gql } from '@apollo/client';

export const userContactFields = gql`
  fragment userContactFields on UserContactObjectType {
    id
    first_name
    last_name
    email
    title
    department
    mobile_number
    is_disabled
    is_internal
    dial_code
    created_at
    updated_at
    pretty_display
    name
  }
`;

export const FETCH_USER_CONTACT = gql`
  query fetch_user_contact($id: ID!) {
    fetch_user_contact(id: $id) {
      ...userContactFields
    }
  }
  ${userContactFields}
`;
