/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree';

import { convertValuesToDayJs, DayJsDate as DayJsDateObj, Dayjs } from '@shipmnts/pixel-hub';

const DayJsDate = types.custom<Dayjs, Dayjs>(DayJsDateObj);
const EmailActivity = types
  .model({
    id: types.string,
    purpose: types.maybe(types.string),
    sent_at: types.maybe(types.maybeNull(DayJsDate)),
  })
  .preProcessSnapshot(({ ...snapshot }) => ({
    ...snapshot,
    ...convertValuesToDayJs(snapshot, ['sent_at']),
  }));

export interface EmailActivityValue extends Instance<typeof EmailActivity> {}
export interface EmailActivityIn extends SnapshotIn<typeof EmailActivity> {}
export interface EmailActivityOut extends SnapshotOut<typeof EmailActivity> {}

export const isEmailSentBefore = (
  email_activities: EmailActivityValue[],
  action_name: string
): boolean => {
  const previouslySentMails = (email_activities || []).filter(
    (email_activity) => email_activity.purpose === action_name
  );
  if (previouslySentMails.length > 0) return true;
  return false;
};

export default EmailActivity;
