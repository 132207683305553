import React from 'react';
import { Tooltip } from '@shipmnts/pixel-hub';
import { ExclamationCircleFilled } from '@shipmnts/pixel-hub';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Link } from 'wouter';

const OTOBookingNumber = React.memo(function OTOBookingNumber(
  props: ICellRendererParams
): JSX.Element {
  const bookingNumber = props.value;
  const cellRendererParams = props?.colDef?.cellRendererParams;
  const id = props?.data?.[cellRendererParams?.idField];
  const is_amendment_pending = props?.data?.is_amendment_pending;

  return (
    <span className="centered">
      {id && cellRendererParams?.redirectToBase ? (
        <Link
          to={`${cellRendererParams?.redirectToBase}/${id}`}
          key={id}
          style={{ cursor: 'pointer' }}
        >
          {bookingNumber}
        </Link>
      ) : (
        { bookingNumber }
      )}
      {is_amendment_pending && !cellRendererParams.hideAmendmentPendingIcon && (
        <Tooltip title={'Amendment Pending'}>
          <ExclamationCircleFilled
            style={{ color: '#FFB84E', marginLeft: '5px', fontSize: '16px' }}
          />
        </Tooltip>
      )}
    </span>
  );
});

export default OTOBookingNumber;
