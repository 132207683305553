import { gql } from '@apollo/client';
export const VALIDATE_CREATE_SHIPMENT_FROM_MESSAGE = gql`
  mutation validate_create_shipment_from_message($message_id: ID!, $types: [String!]!) {
    validate_create_shipment_from_message(message_id: $message_id, types: $types) {
      errors
      existing_shipments {
        id
        job_number
      }
      message {
        id
        resource_data
        attachments {
          document_identifier
          document_url
          file_name
          tags
          parents {
            parent_id
            parent_type
          }
        }
      }
      tenant_mappings {
        source_tenant_id
        sender_reference_id
        receiver_reference_id
        resource_type
        destination_address {
          id
          name
          print_address
          company {
            id
            registered_name
          }
        }
      }
      sender_local_company {
        id
        registered_name
      }
    }
  }
`;

export const ASSIGNED_USER_TO_MESSAGE = gql`
  mutation update_message($id: ID!, $message: MessageInputType!) {
    update_message(id: $id, message: $message) {
      id
    }
  }
`;

export const CREATE_SHIPMENT_FROM_MESSAGE = gql`
  mutation create_shipment_from_message(
    $message_id: ID!
    $shipments: [ShipmentFromMessageInputType!]
    $tenant_mappings: [TenantMappingInputType!]
    $selected_attachments: [AttachmentTagInputType!]
    $finance_party: String
    $throw_error_on_credit_fail: Boolean
  ) {
    create_shipment_from_message(
      message_id: $message_id
      shipments: $shipments
      tenant_mappings: $tenant_mappings
      finance_party: $finance_party
      selected_attachments: $selected_attachments
      throw_error_on_credit_fail: $throw_error_on_credit_fail
    ) {
      id
      involved_branch {
        default_address {
          id
        }
      }
    }
  }
`;

export const DISCARD_MESSAGE = gql`
  mutation discard_message($message_id: ID!, $remarks: String, $send_email: Boolean) {
    discard_message(message_id: $message_id, remarks: $remarks, send_email: $send_email) {
      id
    }
  }
`;

export const FETCH_MESSAGE = gql`
  query fetchMessage($id: ID!) {
    message(id: $id) {
      id
      status
      message_type
      sender_company_account {
        id
      }
      assigned_to {
        id
        first_name
      }
      receiver_company_account {
        id
      }
    }
  }
`;
export const TENANT_MAPPING_ADDRESS_TYPE = 'Network::Address';
export const TENANT_MAPPING_CHARGE_TYPE = 'CHARGE_TYPE';
