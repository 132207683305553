/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotIn, SnapshotOut, IAnyModelType } from 'mobx-state-tree';
import Carrier from 'operations/models/Carrier';
import ContainerRequest from 'operations/models/ContainerRequest';
import RoutingLeg, { RoutingLegValue } from 'operations/models/RoutingLeg';
import { ROUTING_NODE_TYPE_STUFFING_LOCATION } from 'operations/models/RoutingNode';
import OrderCollaboration from 'operations/models/OrderCollaboration';
import Cargo from 'operations/models/Cargo';
import { OceanTransportOrderValue } from 'operations/models/OceanTransportOrder';
import ShipmentContainer, {
  ShipmentContainerValue,
  containersCountByTypeAndSetting,
  validContainers,
} from 'operations/models/ShipmentContainer';
import EmailActivity, { isEmailSentBefore } from 'operations/models/EmailActivity';
import Address from 'operations/models/Address';
import { compact as _compact, flattenDeep as _flattenDeep, sortBy as _sortBy } from 'lodash';
import { LOAD_TYPE_FCL, RTO_TYPE_LOOSE } from 'operations/baseConstants';

const RoadTransportOrder = types
  .model({
    id: types.identifier,
    status: types.maybe(types.string),
    cancellation_reason: types.maybe(types.maybeNull(types.string)),
    booking_number: types.maybe(types.maybeNull(types.string)),
    type: types.maybe(types.string),
    contract_number: types.maybe(types.maybeNull(types.string)),
    last_free_date_at_factory: types.maybe(types.maybeNull(types.number)),
    load_type: types.maybe(types.string),
    factory_location: types.maybe(types.maybeNull(types.late((): IAnyModelType => Address))),
    global_carrier: types.maybe(types.late((): IAnyModelType => Carrier)),
    cargos: types.array(types.late((): IAnyModelType => Cargo)),
    container_requests: types.maybe(types.array(types.late((): IAnyModelType => ContainerRequest))),
    routing_legs: types.array(types.late((): IAnyModelType => RoutingLeg)),
    order_collaborations: types.array(types.late((): IAnyModelType => OrderCollaboration)),
    shipment_containers: types.array(types.late((): IAnyModelType => ShipmentContainer)),
    email_activities: types.maybe(
      types.maybeNull(types.array(types.late((): IAnyModelType => EmailActivity)))
    ),
  })
  .views((self) => ({
    getVendorCompany(defaut_company_id: string) {
      const order_collaborations = self.order_collaborations;
      if (order_collaborations.length === 1) return order_collaborations[0].vendor_company;
      else {
        return order_collaborations.find((oc) => oc?.customer_company?.id === defaut_company_id)
          ?.vendor_company;
      }
    },
    getActualManagedByCompany(defaut_company_id: string) {
      const order_collaborations = self.order_collaborations;
      if (order_collaborations.length === 1) return order_collaborations[0].customer_company;
      else {
        return order_collaborations.find((oc) => oc?.customer_company?.id === defaut_company_id)
          ?.customer_company;
      }
    },
    parentShipmentContainersCountByTypeAndSetting(booking_request_id: string) {
      const parentShipmentContainers: ShipmentContainerValue[] = (
        self.shipment_containers || []
      ).filter((sc) => sc.booking_request_id === booking_request_id);
      return containersCountByTypeAndSetting(parentShipmentContainers);
    },
    get isAnyContainerPickedUp() {
      return self.shipment_containers.reduce((containerPickedUp, sc) => {
        return containerPickedUp || Boolean(sc.container_number);
      }, false);
    },
    get isFCL() {
      return self.load_type === LOAD_TYPE_FCL;
    },
    get isPickedUp() {
      return !!self.routing_legs[0]?.actual_time_of_departure;
    },
    get areAllContainersPickedUp() {
      if (self.shipment_containers.length === 0) return false;
      return self.shipment_containers.reduce((containerPickedUp, sc) => {
        return containerPickedUp && Boolean(sc.container_number);
      }, true);
    },
    getAllocatedOTOswithRTO(oceanTransportOrders: Array<OceanTransportOrderValue>) {
      const emptyArray: Array<string> = [];
      const oto_ids_linked_with_rto = _compact(
        _flattenDeep(
          self.shipment_containers.map((sc) => sc.ocean_transport_order_ids || emptyArray)
        )
      );
      return (oceanTransportOrders || []).filter((oto) => oto_ids_linked_with_rto.includes(oto.id));
    },
    isEmailSentBefore(action_name: string): boolean {
      return isEmailSentBefore(self.email_activities || [], action_name);
    },
    get looseRTOStatus() {
      const leg = (self.routing_legs || []).find((leg: RoutingLegValue) => {
        const tags: string[] = leg.destination?.tags || [];
        return tags.includes(ROUTING_NODE_TYPE_STUFFING_LOCATION);
      });
      return computeRTOStatus(leg);
    },
    get canDelete() {
      if (self.type === RTO_TYPE_LOOSE) {
        return !_sortBy(self.routing_legs, [(rl) => rl.sequence_number])[0]
          .actual_time_of_departure;
      }
      return true;
    },
  }))
  .actions((self) => ({
    updateShipmentContainers(shipmentContainers: ShipmentContainerValue[]) {
      const self_containers = self.shipment_containers;
      shipmentContainers.forEach((sc) => {
        const index = self_containers.findIndex((c) => c.id === sc.id);
        if (index !== -1) self_containers[index] = { ...self_containers[index], ...sc };
      });
    },
  }))
  .preProcessSnapshot(({ routing_legs = [], shipment_containers, ...snapshot }) => ({
    ...snapshot,
    routing_legs: routing_legs
      .slice()
      .sort((a, b) => (a?.sequence_number || 0) - (b?.sequence_number || 0)),
    shipment_containers: validContainers(
      shipment_containers as Instance<typeof ShipmentContainer>[]
    ),
  }));

export interface RoadTransportOrderValue extends Instance<typeof RoadTransportOrder> {}
export interface RoadTransportOrderIn extends SnapshotIn<typeof RoadTransportOrder> {}
export interface RoadTransportOrderOut extends SnapshotOut<typeof RoadTransportOrder> {}

export const computeRTOStatus = (leg?: RoutingLegValue) => {
  if (!leg) return '-';
  if (leg.actual_time_of_arrival) return 'Carted In';
  if (leg.actual_time_of_departure) return 'Picked up';
  return 'Pickup Pending';
};

export default RoadTransportOrder;
