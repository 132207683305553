import { GridOptions, RowDragEvent } from '@ag-grid-community/core';
import { ShipmentEstimateValue as OpsShipmentEstimateValue } from 'operations/models/ShipmentEstimate';
import { ShipmentEstimateValue } from 'sales_hub/models/ShipmentEstimate';

export function getSequencedEstimates(
  estimates: OpsShipmentEstimateValue[] | ShipmentEstimateValue[]
): OpsShipmentEstimateValue[] | ShipmentEstimateValue[] {
  // Sort the estimates array based on the sequence property
  const sortedEst = estimates.sort((a, b) => {
    const sequenceA = a.sequence ?? Infinity; // if sequence is null, treat it as Infinity
    const sequenceB = b.sequence ?? Infinity;

    return sequenceA - sequenceB;
  });

  // Iterate over sorted estimates and fill in missing sequence numbers
  let count = 1;
  sortedEst.forEach((est) => {
    if (!est.sequence) {
      est.updateField('sequence', count);
      count++;
    } else {
      count = est.sequence + 1;
    }
  });
  return sortedEst;
}

export function handleEstimatesRowDrag(
  event: RowDragEvent<any>,
  gridRef: React.MutableRefObject<GridOptions<any> | undefined>
) {
  if (!event || !gridRef?.current) return;
  const data = event?.node?.data;
  const rowIndex = event?.node?.rowIndex;
  const gridApi = gridRef.current?.api;

  if (!data || rowIndex === undefined || rowIndex === null || !gridApi) return;
  const rowData = gridApi.getRenderedNodes(); //Get all the rows in the grid
  if (!rowData || rowData.length < 2) return;

  const topRowIndex = rowIndex > 0 ? rowIndex - 1 : rowIndex;
  const bottomRowIndex = rowIndex < rowData.length - 1 ? rowIndex + 1 : rowIndex;

  // Calculate the average sequence of the top and bottom rows
  const topSequence = rowIndex !== 0 ? rowData[topRowIndex]?.data?.sequence || 0 : 0;
  const bottomSequence =
    rowIndex !== rowData.length - 1
      ? rowData[bottomRowIndex]?.data?.sequence || 0
      : topSequence + 2;

  const newSequence = (topSequence + bottomSequence) / 2;

  // Update the dragged row's sequence with the calculated average
  data.updateField('sequence', newSequence);

  // Refresh the grid cells
  gridApi.refreshCells();
}
