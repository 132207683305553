import { TemplateValue } from 'operations/models/Template';

export const DuplicateTemplateHelper = (template: TemplateValue, navigate: any) => {
  const newTemplate = {
    ...template,
    id: null,
    is_disabled: false,
    is_standard: false,
    created_by: null,
  };
  navigate(`~/form/template/new`, { state: { template: newTemplate } });
};
