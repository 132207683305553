/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotIn, SnapshotOut, IAnyModelType } from 'mobx-state-tree';
import Company from './Company';

// Defining a generic model to represent any type within the union
const ReferenceRecord = types.model({
  id: types.maybeNull(types.identifier),
  reference_type: types.maybe(types.maybeNull(types.string)),
  reference_id: types.maybe(types.maybeNull(types.string)),
  data: types.frozen<Record<string, any>>(),
});

const Contact = types.model({
  id: types.identifier,
  department: types.maybe(types.array(types.string)),
  email: types.maybe(types.string),
  first_name: types.maybe(types.maybeNull(types.string)),
  last_name: types.maybe(types.maybeNull(types.string)),
  mobile_number: types.maybe(types.maybeNull(types.string)),
  dial_code: types.maybe(types.maybeNull(types.string)),
  title: types.maybe(types.maybeNull(types.string)),
  is_disabled: types.maybe(types.maybeNull(types.boolean)),
  is_internal: types.maybe(types.maybeNull(types.boolean)),
  created_at: types.maybe(types.maybeNull(types.number)),
  pretty_display: types.maybe(types.maybeNull(types.string)),
  name: types.maybe(types.maybeNull(types.string)),
  collaborations: types.maybe(types.array(ReferenceRecord)),
  companies: types.maybe(types.array(types.late((): IAnyModelType => Company))),
});

export interface ContactValue extends Instance<typeof Contact> {}
export interface ContactIn extends SnapshotIn<typeof Contact> {}
export interface ContactOut extends SnapshotOut<typeof Contact> {}

export default Contact;
