import React from 'react';
import { observer } from 'mobx-react-lite';
import { Row, Col, Table } from '@shipmnts/pixel-hub';
import { ContainerReportData } from 'operations/modules/reports/reportModels';
import { renderCommodity, getContainerSetting } from 'operations/modules/reports/oldCellRenderers';

const columns = [
  {
    title: 'Container No & Type',
    dataIndex: 'container_number_and_type',
    render: function render(text: string, record: ContainerReportData) {
      return (
        <Col>
          <Row>{record.container_number}</Row>
          <Row>{record.container_type}</Row>
        </Col>
      );
    },
  },
  {
    title: 'Booking Number',
    dataIndex: 'booking_number',
  },
  {
    title: 'Port Of Discharge',
    dataIndex: 'port_of_discharge',
    render: function renderPortOfDischarge(text: string, record: ContainerReportData) {
      return <div>{record?.port_of_discharge?.name || ''}</div>;
    },
  },
  {
    title: 'Shipping Line',
    dataIndex: 'global_carrier',
    render: function renderShippingLine(text: string, record: ContainerReportData) {
      return <div>{record?.global_carrier?.name || ''}</div>;
    },
  },
  {
    title: 'Commodity',
    dataIndex: 'commodity',
    render: function renderContainerCommodity(text: string, record: any) {
      return <div>{renderCommodity(record.booking_cargos || record.cargos || [])}</div>;
    },
  },
  {
    title: 'Container Settings',
    dataIndex: 'container_settings',
    render: function renderContainerSettings(text: string, record: ContainerReportData) {
      return (
        <div>
          {typeof record?.container_settings === 'string'
            ? record?.container_settings
            : getContainerSetting({
                container_settings: record?.container_settings,
                container_type_code: record.container_type_code,
              })}
        </div>
      );
    },
  },
  {
    title: 'Container Status',
    dataIndex: 'container_status',
    render: function renderContainerStatus(text: string, record: ContainerReportData) {
      return (
        <Col>
          <Row>{record.last_action_status}</Row>
        </Col>
      );
    },
  },
];

const SiftingContainersListView = observer(function SiftingContainersListView(props: {
  containers: ContainerReportData[];
}): JSX.Element {
  const { containers } = props;

  return (
    <Table
      dataSource={containers}
      columns={columns}
      pagination={false}
      rowKey={(record) => record.id || ''}
      size="small"
    />
  );
});

export default SiftingContainersListView;
