/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree';
import Company from './Company';

const Vehicle = types.model({
  id: types.identifier,
  ownership_type: types.maybe(types.maybeNull(types.string)),
  vehicle_category: types.maybe(types.maybeNull(types.string)),
  vehicle_chassis_number: types.maybe(types.maybeNull(types.array(types.string))),
  vehicle_fuel_type: types.maybe(types.maybeNull(types.string)),
  vehicle_insurance_amount: types.maybe(types.maybeNull(types.number)),
  vehicle_insurance_coverage_type: types.maybe(types.maybeNull(types.string)),
  vehicle_insurance_date: types.maybe(types.maybeNull(types.string)),
  vehicle_insurance_expiry_date: types.maybe(types.maybeNull(types.string)),
  vehicle_insurance_number: types.maybe(types.maybeNull(types.string)),
  vehicle_license_plate_number: types.maybe(types.maybeNull(types.string)),
  vehicle_registration_date: types.maybe(types.maybeNull(types.string)),
  vehicle_registration_expiry_date: types.maybe(types.maybeNull(types.string)),
  vehicle_status: types.maybe(types.maybeNull(types.string)),
  company: types.maybe(types.maybeNull(Company)),
  model: types.maybe(types.maybeNull(types.string)),
  make: types.maybe(types.maybeNull(types.string)),
  max_weight_capacity: types.maybe(types.maybeNull(types.number)),
  weight_unit: types.maybe(types.maybeNull(types.string)),
  engine_number: types.maybe(types.maybeNull(types.string)),
  acquisition_date: types.maybe(types.maybeNull(types.string)),
});

export interface VehicleValue extends Instance<typeof Vehicle> {}
export interface VehicleIn extends SnapshotIn<typeof Vehicle> {}
export interface VehicleOut extends SnapshotOut<typeof Vehicle> {}

export default Vehicle;
