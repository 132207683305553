import React, { useState, useCallback, useEffect } from 'react';
import { RowNode } from '@ag-grid-community/core';
import { useMutation, useLazyQuery } from '@apollo/client';
import { Modal, Row, Radio, Drawer } from '@shipmnts/pixel-hub';
import { SELF_CONSOLIDATION_KEY } from 'operations/modules/shipment/constants';
import { actionTypeForShipment } from './ShipmentActionDrawer';
import { VALIDATE_CONSOL_FROM_HOUSES } from 'operations/modules/reports/graphql/shipmentReport';
import { CompassOutlined, QuestionCircleFilled } from '@shipmnts/pixel-hub';
import ConsolShipmentFromContainersForm from 'operations/modules/reports/components/ContainerReports/ContainerActions/ConsolShipmentFromContainersForm';
import { PropsForContainerAction } from 'operations/modules/reports/components/ContainerReports/ContainerActions/ContainerActionDrawer';
import OceanTransportOrder, {
  OceanTransportOrderValue,
} from 'operations/models/OceanTransportOrder';
import { GET_OCEAN_TRANSPORT_ORDER } from 'operations/modules/booking/graphql/oceanTransportOrder';
import { showErrorMessages, ValidateShipments } from './CreateConsolFromAirOceanHouses';
interface ValidateShipmentConsolCreationResponseType {
  errors?: string[];
  warnings?: string[];
  ocean_transport_orders?: Array<OceanTransportOrderValue>;
  shipment_containers_id?: string[];
}

const CreateConsolFromFclHouses = React.memo(function CreateConsolFromFclHouses(props: {
  selectedNodes: RowNode[];
  selectedNodeCounts: number;
  onClose: () => void;
  onSuccess: () => void;
  consolType: string;
}): JSX.Element {
  const { selectedNodes, onSuccess, selectedNodeCounts, consolType } = props;
  const [activeAction, setActiveAction] = useState<actionTypeForShipment>();
  const [propsForActiveAction, setPropsForActiveAction] = useState<PropsForContainerAction>();
  const [modalVisible, setModalVisible] = useState(false);
  const [validations, setValidations] = useState<ValidateShipmentConsolCreationResponseType>({});
  const [selectedOceanTransportOrder, setSelectedOceanTransportOrder] = useState<
    OceanTransportOrderValue | undefined
  >();
  const selectedData = (selectedNodes || []).map((node) => node.data);

  const [validateConsolFromHouses, { data: validationsData, loading, error: ValidationError }] =
    useMutation(VALIDATE_CONSOL_FROM_HOUSES);

  const [getOceanTransportOrder, { loading: fetchingOTO, error: otoError, data: otoData }] =
    useLazyQuery<{ ocean_transport_order: OceanTransportOrderValue }, { id: string }>(
      GET_OCEAN_TRANSPORT_ORDER
    );

  const handleSubmit = useCallback(
    (oceanTransportOrder?: OceanTransportOrderValue) => {
      if (oceanTransportOrder && getOceanTransportOrder)
        getOceanTransportOrder({ variables: { id: oceanTransportOrder.id } });
      setModalVisible(false);
    },
    [getOceanTransportOrder]
  );

  useEffect(() => {
    const validation_data = validationsData?.validate_consol_from_houses;
    if (validation_data?.messages && validation_data.messages.length > 0) {
      showErrorMessages(validation_data.messages, 'Cannot create shipment');
    } else {
      if (
        validation_data?.ocean_transport_orders &&
        validation_data?.ocean_transport_orders.length > 0
      ) {
        setSelectedOceanTransportOrder(validation_data.ocean_transport_orders[0]);
        if (
          (validation_data.warnings && validation_data.warnings.length > 0) ||
          validation_data.ocean_transport_orders.length > 1
        ) {
          setModalVisible(true);
        } else {
          handleSubmit(validation_data.ocean_transport_orders[0]);
        }
      }
    }
    setValidations(validation_data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationsData, ValidationError, loading]);

  useEffect(() => {
    if (!otoError && otoData?.ocean_transport_order) {
      const oceanTransportOrder = OceanTransportOrder.create(otoData?.ocean_transport_order);
      setPropsForActiveAction({ oceanTransportOrder });
      setActiveAction('create_consol_shipment');
    }
  }, [fetchingOTO, otoError, otoData]);

  useEffect(() => {
    if (selectedNodeCounts > 0) {
      const validation_errors: string[] = ValidateShipments(selectedData);
      if (validation_errors.length > 0) {
        showErrorMessages(validation_errors, 'Cannot create consol from selected shipments');
      } else {
        const variables = {
          consol_type: SELF_CONSOLIDATION_KEY,
          house_shipment_ids: selectedData?.map((data) => data?.id),
        };
        validateConsolFromHouses({ variables });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNodeCounts]);

  const onCloseDrawer = useCallback(() => {
    setActiveAction(undefined);
  }, [setActiveAction]);

  return (
    <>
      {modalVisible &&
        validations &&
        selectedOceanTransportOrder &&
        ((validations.warnings && validations.warnings.length > 0) ||
          (validations.ocean_transport_orders &&
            validations.ocean_transport_orders.length > 1)) && (
          <Modal
            title={
              <Row>
                <QuestionCircleFilled style={{ color: '#faad14' }} />
                <div style={{ marginLeft: '16px' }}>Do you want to proceed?</div>
              </Row>
            }
            visible
            okText="Please Confirm"
            onOk={() => handleSubmit(selectedOceanTransportOrder)}
            onCancel={() => setModalVisible(false)}
          >
            <>
              {validations.warnings && validations.warnings.length > 0 && (
                <ul style={{ paddingLeft: 'inherit' }}>
                  {validations.warnings.map((warning: string, index: number) => (
                    <li key={index}>{warning}</li>
                  ))}
                </ul>
              )}
              {validations.ocean_transport_orders &&
                validations.ocean_transport_orders.length > 1 && (
                  <div>
                    <p>Select the Booking into which other bookings will be merged</p>
                    <Radio.Group
                      onChange={(e) => setSelectedOceanTransportOrder(e.target.value)}
                      value={selectedOceanTransportOrder}
                    >
                      {validations.ocean_transport_orders.map((oto) => (
                        <Row key={oto.id}>
                          <Radio value={oto}>{oto.booking_number}</Radio>
                        </Row>
                      ))}
                    </Radio.Group>
                  </div>
                )}
            </>
          </Modal>
        )}
      {propsForActiveAction?.oceanTransportOrder &&
        activeAction === 'create_consol_shipment' &&
        validations.shipment_containers_id && (
          <Drawer
            placement="right"
            title={
              <div
                style={{
                  color: '#353F52',
                  fontSize: '18px',
                }}
              >
                <CompassOutlined />
                <span style={{ marginLeft: '5px' }}>Create Consol Shipment</span>
              </div>
            }
            closeIcon={<></>}
            open={true}
            headerStyle={{ padding: '16px' }}
            width="60%"
          >
            <ConsolShipmentFromContainersForm
              shipment_container_ids={validations.shipment_containers_id}
              oceanTransportOrder={propsForActiveAction?.oceanTransportOrder}
              carrier={propsForActiveAction?.oceanTransportOrder?.global_carrier || undefined}
              onClose={onCloseDrawer}
              onSuccess={onSuccess}
              console_type={consolType}
            />
          </Drawer>
        )}
    </>
  );
});

export default CreateConsolFromFclHouses;
