import { SessionDataValue } from 'operations/models/SessionData';
import { applySnapshot } from 'mobx-state-tree';
import BookingRequest, { BookingRequestValue } from 'operations/models/BookingRequest';
import { ShipmentContainerValue } from 'operations/models/ShipmentContainer';
import DeallocateOceanTranportOrder from 'operations/modules/booking/components/BookingRequestView/DeallocateOceanTranportOrder';
import {
  createEmptyMiscellaneousServiceOrder,
  MiscellaneousServiceModal,
} from 'operations/modules/booking/components/BookingRequestView/MiscellaneousServiceCard';
import { DeleteRoadTransportOrder } from 'operations/modules/booking/components/BookingRequestView/RoadTransportOrdersView';
import AddCartingDetails from 'operations/modules/booking/components/RoadTransportOrder/AddCartingDetails';
import AddContainerDetails from 'operations/modules/booking/components/RoadTransportOrder/AddContainerDetails';
import AddPickupDetails from 'operations/modules/booking/components/RoadTransportOrder/AddPickupDetails';
import CreateUpdateRoadTransportOrder from 'operations/modules/booking/components/RoadTransportOrder/CreateUpdateRoadTransportOrder';
import {
  BOOKING_TYPE_BOOKING_REQUEST,
  BOOKING_TYPE_OCEAN_TRANSPORT_ORDER,
} from 'operations/modules/booking/constants';
import AllocateOTOReport from 'operations/modules/reports/components/BookingRequestReports/AllocateOTOReport';
import CreateBookingOrderFromBR from 'operations/modules/reports/components/BookingRequestReports/CreateBookingOrderFromBR';
import ExtendValidityForm from 'operations/modules/reports/components/OTOReports/ExtendValidityForm';
import CreateShipmentFromFCLCustomerOrder from 'operations/modules/booking/components/BookingRequestView/CreateShipmentFromCustomerOrder';
import { CreateShipmentForLCL } from 'operations/modules/booking/components/BookingRequestView/CreateShipmentActions';
import { PerformAction } from '../models';
import DeleteMiscellaneousService from 'operations/modules/booking/components/BookingRequestView/DeleteMiscellaneousService';
import CreateShipmentFromCancelledBr from 'operations/modules/reports/components/CreateShipmentFromCancelledBr';
import { RoadTransportOrderValue } from 'operations/models/RoadTransportOrder';
import CancelBookingRequestForm from 'operations/modules/reports/components/BookingRequestReports/CancelBookingRequestForm';
import OffloadContainers from 'operations/modules/booking/components/MergeSplitBooking/OffloadContainers';
import { OceanTransportOrderValue } from 'operations/models/OceanTransportOrder';
import MarkDgIndexing from 'operations/modules/reports/components/BookingRequestReports/MarkDgIndexing';
import UpdateOrSplitOto from 'operations/modules/oto/UpdateOrSplitOto';
import UpdateRoutingDetails from 'operations/modules/oto/UpdateRoutingDetails';
import { EmailProps } from 'operations/components/ChangeEvent/EventEmailDrawer';
import { EVENT_EMPTY_PICKED_UP } from 'operations/modules/actionHelper/constants';
import { BOOKING_TYPE_SELF } from 'operations/baseConstants';
import { AddCollaborators, EmailDocType } from '@shipmnts/pixel-hub';

// import CreateShipmentFromProductOrder from 'operations/modules/booking/components/BookingRequestView/CreateShipmentFromProductOrder';

// import { EmailProps } from 'operations/modules/shipment/components/Tracking/ChangeEvent/EventEmailDrawer'

interface BookingRequestActionType {
  bookingRequest: BookingRequestValue;
  setEmailProps: (emailProps: EmailProps) => void;
  setVisible: (visible: boolean) => void;
  sessionData: SessionDataValue;
  extraProps?: any;
  navigate: any;
}

export const allocateOTOReportRenderer = (payload: BookingRequestActionType) => {
  const { bookingRequest, setEmailProps, setVisible, sessionData } = payload;
  const default_company = sessionData?.company_account?.default_company;
  return {
    actionParams: {
      booking_request: BookingRequest.create(bookingRequest, { sessionData }),
      onSuccess: (booking_request: BookingRequestValue, sendEmail: boolean) => {
        bookingRequest.setOceanTransportOrders(booking_request.ocean_transport_orders);
        bookingRequest.setContainerRequests(booking_request.container_requests);
        bookingRequest.setRemarks(booking_request.remarks);
        if (sendEmail) {
          setEmailProps({
            title: 'Booking Confirmation',
            action_name: 'booking_confirmation',
            resource_ids: [bookingRequest.id],
            fetchDocumentParents: [
              {
                parent_type: BOOKING_TYPE_BOOKING_REQUEST,
                parent_ids: [bookingRequest.id],
              },
              {
                parent_type: BOOKING_TYPE_OCEAN_TRANSPORT_ORDER,
                parent_ids: booking_request.ocean_transport_orders.map((oto) => oto.id),
              },
            ],
            companies_roles_mapping: default_company
              ? bookingRequest.getAllCompaniesRolesMapping(default_company)
              : [],
            showMarkDown: true,
          });
          setVisible(true);
        }
      },
    },
    component: AllocateOTOReport,
  };
};

export const deallocateOtoRenderer = (payload: BookingRequestActionType) => {
  const { bookingRequest, extraProps } = payload;
  return {
    actionParams: {
      bookingRequest,
      ...extraProps,
    },
    component: DeallocateOceanTranportOrder,
  };
};

export const createBookingOrderFromBRRenderer = (payload: BookingRequestActionType) => {
  const { bookingRequest, setEmailProps, setVisible, sessionData, extraProps } = payload;
  const default_company = sessionData?.company_account?.default_company;

  return {
    actionParams: {
      booking_type: extraProps?.type,
      booking_request: BookingRequest.create(bookingRequest, { sessionData }),
      load_type: bookingRequest.ocean_transport_orders[0].load_type,
      onSuccess: (booking_request: BookingRequestValue, sendEmail: boolean) => {
        const is_self_booking = booking_request.ocean_transport_orders.some(
          (oto) => oto.booking_type === BOOKING_TYPE_SELF
        );
        bookingRequest.setOceanTransportOrders(booking_request.ocean_transport_orders);
        bookingRequest.setContainerRequests(booking_request.container_requests);
        if (!!booking_request.stuffing_buffer_service_orders?.[0])
          applySnapshot(
            bookingRequest.stuffing_buffer_service_orders[0],
            booking_request.stuffing_buffer_service_orders[0]
          );
        if (sendEmail && !is_self_booking) {
          setEmailProps({
            title: 'Booking Confirmation',
            action_name: 'booking_confirmation',
            resource_ids: [bookingRequest.id],
            fetchDocumentParents: [
              {
                parent_type: BOOKING_TYPE_BOOKING_REQUEST,
                parent_ids: [bookingRequest.id],
              },
              {
                parent_type: BOOKING_TYPE_OCEAN_TRANSPORT_ORDER,
                parent_ids: booking_request.ocean_transport_orders.map((oto) => oto.id),
              },
            ],
            companies_roles_mapping: default_company
              ? bookingRequest.getAllCompaniesRolesMapping(default_company)
              : [],
            showMarkDown: true,
          });
          setVisible(true);
        }
      },
    },
    component: CreateBookingOrderFromBR,
  };
};

export const addContainerDetailsActionRenderer = (payload: BookingRequestActionType) => {
  const { bookingRequest, setEmailProps, setVisible, sessionData, extraProps } = payload;
  const confirmedOTOs = bookingRequest.ocean_transport_orders.filter((oto) => oto.is_confirmed);
  const default_company = sessionData?.company_account?.default_company;

  return {
    actionParams: {
      roadTransportOrder: extraProps?.roadTransportOrder,
      oceanTransportOrders: confirmedOTOs,
      bookingRequestId: bookingRequest.id,
      onSuccess: (shipment_containers: ShipmentContainerValue[], sendEmail: boolean) => {
        if (sendEmail) {
          const event_ids = shipment_containers
            .map(
              (container: ShipmentContainerValue) =>
                container.tracking_events?.find((event) => event.name === EVENT_EMPTY_PICKED_UP)
                  ?.id || ''
            )
            .filter((c) => c !== '');
          if (event_ids.length > 0) {
            setEmailProps({
              title: 'Pickup Confirmation',
              action_name: 'tracking_event_notification_bulk',
              docTypeId: 'Shipment::TrackingEvent',
              resource_ids: event_ids,
              fetchDocumentParents: [
                {
                  parent_type: 'tracking_event',
                  parent_ids: event_ids,
                },
              ],
              companies_roles_mapping: default_company
                ? bookingRequest.getAllCompaniesRolesMapping(default_company)
                : [],
              showMarkDown: true,
            });
            setVisible(true);
          }
        }
      },
    },
    component: AddContainerDetails,
  };
};

export const deleteMiscService = (payload: BookingRequestActionType) => {
  const { extraProps } = payload;
  return {
    actionParams: {
      miscellaneous_service_order: extraProps?.miscellaneous_service_order,
    },
    component: DeleteMiscellaneousService,
  };
};

export const offloadContainersFormRenderer = (payload: BookingRequestActionType) => {
  const { bookingRequest, setEmailProps, setVisible, sessionData } = payload;
  const default_company = sessionData?.company_account?.default_company;

  return {
    actionParams: {
      bookingRequest,
      onSuccess: (event_ids: string[], sendEmail: boolean) => {
        if (sendEmail) {
          setEmailProps({
            title: `Mark Selected Containers as Offloaded`,
            fetchDocumentParents: [
              {
                parent_type: 'tracking_event',
                parent_ids: event_ids,
              },
            ],
            action_name:
              event_ids.length === 1
                ? 'tracking_event_notification'
                : 'tracking_event_notification_bulk',
            resource_ids: event_ids,
            companies_roles_mapping: default_company
              ? bookingRequest.getAllCompaniesRolesMapping(default_company)
              : [],
          });
          setVisible(true);
        }
      },
    },
    component: OffloadContainers,
  };
};

export const cancelBookingFormRenderer = (payload: BookingRequestActionType) => {
  const { bookingRequest, setEmailProps, setVisible, sessionData, navigate } = payload;
  const default_company = sessionData?.company_account?.default_company;

  return {
    actionParams: {
      bookingType: BOOKING_TYPE_BOOKING_REQUEST,
      booking: bookingRequest,
      onSuccess: (booking_request: BookingRequestValue, sendEmail: boolean) => {
        const br = booking_request as BookingRequestValue;
        bookingRequest.setOceanTransportOrders(br.ocean_transport_orders);
        bookingRequest.setContainerRequests(br?.container_requests);
        bookingRequest.setRoadTransportOrders(br.road_transport_orders);
        bookingRequest.setRemarks(br?.remarks);
        bookingRequest.setStatus(br?.status);
        bookingRequest.setShipmentIds(br?.shipment_ids);
        if (br?.shipment_ids?.[0]) {
          navigate(`~/view/shipment/${br.shipment_ids[0]}/estimates`);
        }
        if (sendEmail) {
          setEmailProps({
            title: 'Booking Request Cancelled',
            action_name: 'booking_request_cancelled',
            resource_ids: [bookingRequest.id],
            showMarkDown: true,
            companies_roles_mapping: default_company
              ? bookingRequest.getAllCompaniesRolesMapping(default_company)
              : [],
          });
          setVisible(true);
        }
      },
    },
    component: CancelBookingRequestForm,
  };
};

export const markDGIndexingRenderer = (payload: BookingRequestActionType) => {
  const { bookingRequest, setEmailProps, setVisible } = payload;
  const oto = bookingRequest?.ocean_transport_orders[0];
  return {
    actionParams: {
      oto,
      onSuccess: (oto: OceanTransportOrderValue, sendEmail: boolean) => {
        bookingRequest.setOceanTransportOrders([oto]);
        if (sendEmail) {
          setEmailProps({
            title: 'DGD Indexing Confirmation',
            action_name: 'dgd_indexing_confirmation',
            resource_ids: [bookingRequest.id],
          });
          setVisible(true);
        }
      },
    },
    component: MarkDgIndexing,
  };
};

export const createUpdateRoadTransportOrderRenderer = (payload: BookingRequestActionType) => {
  const { bookingRequest, sessionData, extraProps } = payload;
  const { ocean_transport_orders } = bookingRequest;
  const bookingRequestObject = BookingRequest.create(bookingRequest, { sessionData });
  const default_company = sessionData?.company_account?.default_company?.id;
  return {
    actionParams: {
      bookingRequest: bookingRequestObject,
      roadTransportOrder: extraProps?.roadTransportOrder,
      oceanTransportOrders: ocean_transport_orders.filter((oto) => oto.is_independent),
      rtoType: 'origin',
      defaultCompanyId: default_company,
    },
    component: CreateUpdateRoadTransportOrder,
  };
};

export const deleteRoadTransportOrderRenderer = (
  payload: BookingRequestActionType
): PerformAction => {
  const { bookingRequest, extraProps } = payload;
  return {
    actionParams: {
      key: 'delete_rto',
      rto: extraProps?.roadTransportOrder,
      bookingRequestId: bookingRequest.id,
      onDelete: (rto_id: string) => bookingRequest.deleteRoadTransportOrder(rto_id),
    },
    component: DeleteRoadTransportOrder,
  };
};

export const addServiceRenderer = (payload: BookingRequestActionType): PerformAction => {
  const { bookingRequest, sessionData, extraProps } = payload;
  const default_company = sessionData?.company_account?.default_company?.id || '';
  return {
    actionParams: {
      booking_request: bookingRequest,
      default_company_id: sessionData?.company_account?.default_company?.id,
      miscellaneous_service_order:
        extraProps?.miscellaneous_service_order ||
        createEmptyMiscellaneousServiceOrder(default_company, extraProps?.service),
    },
    component: MiscellaneousServiceModal,
  };
};

export const extendValidityRenderer = (payload: BookingRequestActionType): PerformAction => {
  const { extraProps } = payload;
  return {
    actionParams: {
      ...extraProps,
    },
    component: ExtendValidityForm,
  };
};

export const addPickupDetailsRenderer = (payload: BookingRequestActionType): PerformAction => {
  const { bookingRequest, setEmailProps, setVisible, extraProps } = payload;
  return {
    actionParams: {
      ...extraProps,
      bookingRequestId: bookingRequest.id,
      onSuccess: (rto: RoadTransportOrderValue, sendEmail: boolean) => {
        if (sendEmail) {
          setEmailProps({
            title: 'Pickup Confirmation',
            action_name: 'rto_pickup_confirmation',
            resource_ids: [rto.id],
            showMarkDown: true,
          });
          setVisible(true);
        }
      },
    },
    component: AddPickupDetails,
  };
};

export const createShipmentFromBRRenderer = (payload: BookingRequestActionType): PerformAction => {
  const { bookingRequest, extraProps } = payload;
  return {
    actionParams: {
      bookingRequest: bookingRequest,
      shipmentType: extraProps.shipmentType,
      activeAction: 'create_shipment',
    },
    component: !!bookingRequest?.isFCL ? CreateShipmentFromFCLCustomerOrder : CreateShipmentForLCL,
  };
};

export const addCartingDetailsRenderer = (payload: BookingRequestActionType): PerformAction => {
  const { bookingRequest, setEmailProps, setVisible, sessionData, extraProps } = payload;
  const default_company = sessionData?.company_account?.default_company;
  let actionName = 'rto_based_carting_confirmation';
  if (extraProps.stuffingBufferServiceOrder) {
    actionName = 'stuffing_based_carting_confirmation';
  }
  return {
    actionParams: {
      ...extraProps,
      bookingRequest: bookingRequest,
      onSuccess: (data: any, sendEmail: boolean) => {
        if (sendEmail) {
          setEmailProps({
            title: 'Carting Confirmation',
            action_name: actionName,
            resource_ids: [data.id],
            showMarkDown: true,
            companies_roles_mapping: default_company
              ? bookingRequest.getAllCompaniesRolesMapping(default_company)
              : [],
          });
          setVisible(true);
        }
      },
    },
    component: AddCartingDetails,
  };
};

export const addCreateShipmentRenderer = (payload: BookingRequestActionType): PerformAction => {
  const { bookingRequest, extraProps } = payload;
  return {
    actionParams: {
      bookingRequest: bookingRequest,
      shipmentType: extraProps.shipmentType,
      activeAction: 'create_shipment',
    },
    component: !!bookingRequest?.isFCL ? CreateShipmentFromFCLCustomerOrder : CreateShipmentForLCL,
  };
};

export const createCancelledShipmentRenderer = (payload: BookingRequestActionType) => {
  const { bookingRequest, navigate } = payload;
  return {
    actionParams: {
      booking_request_id: bookingRequest.id,
      onSuccess: (booking_request: BookingRequestValue) => {
        const br = booking_request as BookingRequestValue;
        bookingRequest.setShipmentIds(br?.shipment_ids);
        if (br?.shipment_ids?.[0]) {
          navigate(`~/view/shipment/${br.shipment_ids[0]}/estimates`);
        }
      },
    },
    component: CreateShipmentFromCancelledBr,
  };
};

export const updateRoutingDetailsRenderer = (payload: BookingRequestActionType) => {
  const { bookingRequest } = payload;
  return {
    actionParams: {
      bookingRequest: bookingRequest,
    },
    component: UpdateRoutingDetails,
  };
};

export const notifyOnEmailRenderer = (payload: BookingRequestActionType): PerformAction => {
  const { bookingRequest, sessionData } = payload;
  const default_company = sessionData?.company_account?.default_company;
  const is_nvocc = sessionData?.company_account?.business_type.includes(
    'non_vessel_owner_container_carrier'
  );
  const has_self_booking = bookingRequest.ocean_transport_orders.some(
    (oto) => oto.booking_type === BOOKING_TYPE_SELF
  );
  return {
    actionParams: {
      resource_id: bookingRequest?.id,
      docType: 'Shipment::BookingRequest',
      fetchDocumentParents: [
        {
          parent_type: BOOKING_TYPE_BOOKING_REQUEST,
          parent_ids: [bookingRequest.id],
        },
        {
          parent_type: BOOKING_TYPE_OCEAN_TRANSPORT_ORDER,
          parent_ids: bookingRequest.ocean_transport_orders.map((oto) => oto.id),
        },
      ],
      companies_roles_mapping: default_company
        ? bookingRequest.getAllCompaniesRolesMapping(default_company)
        : [],
      showDocuments: is_nvocc && has_self_booking,
    },
    component: EmailDocType,
  };
};

export const updateOrSplitRollOverRenderer = (payload: BookingRequestActionType) => {
  const { bookingRequest, setEmailProps, setVisible, sessionData } = payload;
  const default_company = sessionData?.company_account?.default_company;
  return {
    actionParams: {
      bookingRequest: bookingRequest,
      onSuccess: (sendEmail: boolean, resource_ids: string[]) => {
        if (sendEmail) {
          setEmailProps({
            title: `Send Routing Update Email`,
            fetchDocumentParents: [
              {
                parent_type: 'tracking_event',
                parent_ids: resource_ids,
              },
            ],
            action_name:
              resource_ids.length === 1
                ? 'tracking_event_notification'
                : 'tracking_event_notification_bulk',
            resource_ids: resource_ids,
            companies_roles_mapping: default_company
              ? bookingRequest.getAllCompaniesRolesMapping(default_company)
              : [],
          });
          setVisible(true);
        }
      },
    },
    component: UpdateOrSplitOto,
  };
};

export const addCollaboratorsRenderer = (payload: BookingRequestActionType) => {
  const { bookingRequest } = payload;

  return {
    actionParams: {
      referenceId: bookingRequest?.id,
      referenceType: 'Shipment::BookingRequest',
      id: bookingRequest?.shipment_booking_number,
    },
    component: AddCollaborators,
  };
};

export const actionMap: { [key: string]: (...args: any[]) => any } = {
  allocate_oto_report: allocateOTOReportRenderer,
};
