import React, { useCallback, useContext, useState } from 'react';
import { fetchErpNextConfigCompanyData } from 'network/erpnextApis';
import { gql, useApolloClient } from '@apollo/client';
import { errorMessageHandlerGraphQLString } from 'common';

export const GET_BRANCH_ACCOUNTS = gql`
  query branch_accounts {
    branch_accounts {
      id
      name
      erp_cost_center_id
    }
  }
`;
export interface ERPNextCompanyConfigData {
  default_currency: string;
  currencies: {
    name: string;
  }[];
  unit_of_measurements: {
    name: string;
    must_be_whole_number: 0 | 1;
  }[];
  charges_settings: {
    reference_name: string;
    item: string;
  }[];
}

interface BranchAccountPartial {
  id: string;
  name: string;
  erp_cost_center_id?: string;
}

export interface BranchAccountWithCompany extends BranchAccountPartial {
  company?: string;
}

type FetchConfigDataType = () => Promise<{
  data?: ERPNextCompanyConfigData;
  error?: string;
}>;

type FetchCostCenterListType = () => Promise<{
  data?: BranchAccountWithCompany[];
  error?: string;
}>;

export interface ErpNextCompanyConfigDataContextType {
  getErpConfigData: FetchConfigDataType;
  getBranchList: FetchCostCenterListType;
}

export const ErpNextCompanyConfigDataContext =
  React.createContext<ErpNextCompanyConfigDataContextType>({
    getErpConfigData: () => {
      return new Promise((_resolve, reject) => {
        reject({ error: 'not initialized' });
      });
    },
    getBranchList: () => {
      return new Promise((_resolve, reject) => {
        reject({ error: 'not initialized' });
      });
    },
  });
export const useErpNextCompanyConfigDataContext = () => useContext(ErpNextCompanyConfigDataContext);

export default function ErpNextCompanyConfigDataWrapper(props: { children: React.ReactNode }) {
  const client = useApolloClient();

  const [erpnextConfigData, setErpNextConfigData] = useState<ERPNextCompanyConfigData | undefined>(
    undefined
  );
  const [branches, setBranches] = useState<BranchAccountWithCompany[] | undefined>(undefined);
  const [fetchError, setFetchError] = useState<undefined | string>(undefined);
  const [fetchBranchError, setFetchBranchError] = useState<undefined | string>(undefined);

  const fetchConfigData: FetchConfigDataType = useCallback(async () => {
    const result: {
      response?: { data: { message: ERPNextCompanyConfigData } };
      error?: string;
    } = await fetchErpNextConfigCompanyData();
    if (result.response) {
      setFetchError(undefined);
      const config_data = result.response?.data.message;
      setErpNextConfigData(config_data);
      return { data: config_data };
    } else {
      setFetchError(result.error);
      return { error: result.error };
    }
  }, []);

  const fetchBranchList = useCallback(async () => {
    const { data, errors } = await client.query({ query: GET_BRANCH_ACCOUNTS });
    if (data?.branch_accounts) {
      setBranches(data.branch_accounts);
      return { data: data.branch_accounts };
    } else {
      const errorMessage = errorMessageHandlerGraphQLString(errors as any);
      setFetchBranchError(errorMessage);
      return { error: errorMessage };
    }
  }, [client]);

  const getErpConfigData = useCallback(async () => {
    if (erpnextConfigData) return { data: erpnextConfigData };
    if (fetchError) return { error: fetchError };
    return await fetchConfigData();
  }, [erpnextConfigData, fetchError, fetchConfigData]);

  const getBranchList = useCallback(async () => {
    if (branches) return { data: branches };
    if (fetchBranchError) return { error: fetchBranchError };
    return await fetchBranchList();
  }, [branches, fetchBranchError, fetchBranchList]);

  return (
    <ErpNextCompanyConfigDataContext.Provider value={{ getErpConfigData, getBranchList }}>
      {props.children}
    </ErpNextCompanyConfigDataContext.Provider>
  );
}
