import React, { useCallback } from 'react';

import { Form, SelectProps } from '@shipmnts/pixel-hub';

import CompanySearch, {
  CompanySearchProps,
} from 'common/components/CustomInputs/CompanySearch/CompanySearch';
import CustomerSearch, {
  CustomerSearchProps,
} from 'common/components/CustomInputs/CustomerSearch/CustomerSearch';
import { AddressValue } from 'common/models/Address';
import { CompanyValue } from 'common/models/Company';
import { GlobalSearch } from '@shipmnts/pixel-hub';

export interface AddressSearchProps {
  value?: AddressValue | undefined;
  onChange?: (value: AddressValue | undefined) => void;
  showAddBranchAction?: boolean;
  company_id?: string;
  disabled?: boolean;
  selectProps?: SelectProps;
  searchProps?: {
    is_disabled?: Array<boolean>;
    entity_type?: string;
  };
}

export interface PartyValue {
  id?: string;
  name?: string;
  party_company?: CompanyValue;
  party_address?: AddressValue;
  print_address?: string | null;
}

interface AddressCompanySearchProps {
  value?: PartyValue | null | undefined;
  onChange?: (value: PartyValue | null | undefined, changeKey?: string) => void;
  companySearchProps?: CompanySearchProps;
  customerSearchProps?: CustomerSearchProps;
  addressSearchProps?: AddressSearchProps;
  showPrintAddress?: boolean;
  alignHorizontal?: boolean;
}

const AddressCompanySearch = React.memo(function AddressCompanySearch(
  props: AddressCompanySearchProps
): JSX.Element {
  const {
    value: party,
    onChange,
    companySearchProps = {},
    addressSearchProps = {},
    customerSearchProps,
    showPrintAddress,
    alignHorizontal = false,
  } = props;

  const onCompanyChange = useCallback(
    (value: CompanyValue | Array<CompanyValue> | undefined): void => {
      let newParty: PartyValue | null | undefined = {
        ...party,
        party_company: Array.isArray(value) ? value[0] : value,
      };
      if (!newParty.party_company) newParty = null;
      if (onChange) onChange(newParty);
    },
    [party, onChange]
  );
  const onAddressChange = useCallback(
    (value: AddressValue | undefined): void => {
      if (party?.party_address?.id === value?.id) return;
      let newParty: PartyValue | null | undefined = {
        ...party,
        party_address: value,
      };
      if (showPrintAddress) newParty.print_address = value?.print_address;
      if (!newParty.party_company && !newParty.party_address) newParty = null;
      if (onChange) onChange(newParty);
    },
    [party, onChange, showPrintAddress]
  );

  return (
    <div style={alignHorizontal ? { display: 'flex' } : {}}>
      <Form.Item style={alignHorizontal ? { marginBottom: 0, width: 200 } : {}}>
        {customerSearchProps ? (
          <CustomerSearch
            {...customerSearchProps}
            value={party?.party_company}
            onChange={onCompanyChange}
          />
        ) : (
          <CompanySearch
            {...companySearchProps}
            value={party?.party_company}
            onChange={onCompanyChange}
          />
        )}
      </Form.Item>
      <Form.Item
        style={
          alignHorizontal
            ? { marginBottom: 0, marginLeft: '15px', width: 250 }
            : { marginBottom: 0 }
        }
      >
        <GlobalSearch
          doc_type="Network::Address"
          {...addressSearchProps}
          searchProps={{
            ...(addressSearchProps.searchProps || {}),
            company_id: party?.party_company?.id,
          }}
          value={party?.party_address}
          onChange={onAddressChange}
        />
      </Form.Item>
    </div>
  );
});

export default AddressCompanySearch;
