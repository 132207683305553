import { message } from '@shipmnts/pixel-hub';
import { erpNextAxiosCall } from './utils/erpnextApiHelper';

const bankAccountFields =
  '["name","account", "account_name", "bank", "bank_account_no", "branch_code", "beneficiary_code", "iban", "swift_number", "ad_code"]';

export const searchBankListByNameApi = async (
  name: any,
  filters: any,
  options = {
    fields: bankAccountFields,
    limit_start: 0,
    limit_page_length: 10,
  }
) => {
  try {
    const url = `/api/resource/Bank Account`;

    if (name) filters['name'] = ['like', `%${name}%`];
    const response = await erpNextAxiosCall({
      action: 'get',
      url,
      params: { filters: JSON.stringify(filters), ...options },
    });
    return response;
  } catch (errors) {
    console.error(errors);
    return { errors };
  }
};

export const fetchBanks = async (fields = ['name'], filters: Record<string, any>) => {
  const { name, ...erpFilter } = filters;
  if (name) erpFilter['name'] = ['like', `%${name}%`];

  try {
    const url = `/api/resource/Bank`;
    const response: any = await erpNextAxiosCall({
      action: 'get',
      url,
      params: { fields: JSON.stringify(fields), filters: JSON.stringify(erpFilter) },
    });
    return response?.response?.data?.data;
  } catch (error) {
    message.error('Network Error. Unable to fetch Banks');
    console.error(error);
  }
};
