import { result as _result, defaultTo as _defaultTo } from 'lodash';
import {
  DOCUMENT_FIELD_LOCAL,
  DOCUMENT_FIELD_DIRECT,
  DOCUMENT_FIELD_COMPUTED,
  DOCUMENT_FIELD_TRANSFORM,
} from '../constants';
import { SourceData } from './documentPrintHelper';
import { DocumentField } from 'operations/apis/document';
import { transformFunctions } from './transformFunctions';

interface Params {
  field_name: string;
  data: SourceData;
  document_key?: string;
}

interface Field extends DocumentField {
  field_name: string;
}

export interface ResolveFieldProps {
  field?: Field;
  data: SourceData;
  document_key?: string;
  onNewLine?: boolean;
}

interface ComputedValue {
  blocks: { text: string }[];
}
const getDefaultValueByType = (field_type: string) => {
  switch (field_type) {
    case 'string':
      return '';

    case 'object':
      return {};

    case 'array':
      return [];

    default:
      return undefined;
  }
};

const resolveLocalField = (props: ResolveFieldProps) => {
  const { field, data, document_key } = props;
  if (!field) return;
  if (
    document_key &&
    _defaultTo(_result(data, [document_key, field.field_name]), undefined) !== undefined
  ) {
    return _defaultTo(
      _result(data, [document_key, field.field_name]),
      getDefaultValueByType(field.type)
    );
  }
  return _defaultTo(_result(field, 'value'), getDefaultValueByType(field.type));
};

const getResolveParams = (props: Params): ResolveFieldProps => {
  const { field_name, data, document_key } = props;
  if (!field_name) throw new Error('Field name not provided');
  if (!data.document) throw new Error('Document object required to resolve field value');

  const document = data.document;

  if (
    !_result(document, ['document_field', field_name]) &&
    !_result(document, ['print_option', field_name])
  )
    throw new Error(`Field [${field_name}] does not exist. Check document json`);

  let field: { field_name?: string; field_type?: string } | null = _result(document, [
    'document_field',
    field_name,
  ])
    ? Object.assign({}, _result(document, ['document_field', field_name]))
    : null;
  if (!field) {
    field = Object.assign({}, _result(document, ['print_option', field_name]));
    field.field_type = DOCUMENT_FIELD_LOCAL;
  }
  field.field_name = field_name;
  return { field: field as Field, data, document_key };
};

const resolveArray = (props: { data: any[]; properties?: { [key: string]: DocumentField } }) => {
  const { data, properties } = props;
  return data.map((lineItem) => resolveObject({ data: lineItem, properties }));
};

const resolveObject = (props: { data: object; properties?: { [key: string]: DocumentField } }) => {
  const { data, properties } = props;
  if (!properties) return {};
  return Object.keys(properties).reduce((obj: { [key: string]: any }, currPropertyKey: string) => {
    const currObj = properties[currPropertyKey];
    obj[currPropertyKey] = _defaultTo(
      _result(data, currObj.default_source),
      getDefaultValueByType(currObj.type)
    );
    return obj;
  }, {});
};

const resolveDirectField = (props: ResolveFieldProps) => {
  const { field, data } = props;
  if (field && !field.default_source) throw new Error('Default source not provided for field');
  const pattern = new RegExp(`\\[\\[([\\w\\.:,]+)\\]\\]`, 'g');
  if (field && field.hasOwnProperty('properties')) {
    const sourceData = _result(data, field.default_source, getDefaultValueByType(field.type));
    if (field.type === 'array')
      return resolveArray({ data: sourceData as any[], properties: field.properties });
    else if (field.type === 'object')
      return resolveObject({ data: sourceData, properties: field.properties });
  }
  if (!field) return;
  const resolved_source: string[] = field.default_source.map((source_field) => {
    if (source_field.match(pattern)) {
      const keys = source_field.substr(2, source_field.length - 4).split('.');
      const document_field_name = keys[0];
      const sub_fields = keys.slice(1, keys.length);
      const document_field_value = resolveDocumentField({
        field_name: document_field_name,
        data,
      });
      let final_value = document_field_value;
      if (sub_fields && sub_fields.length > 0) {
        final_value = _result(final_value, sub_fields);
      }
      return final_value;
    }
    return source_field;
  });
  return _defaultTo(_result(data, resolved_source), getDefaultValueByType(field.type));
};

const resolveComputedField = (props: ResolveFieldProps) => {
  const { field, data, document_key } = props;
  try {
    const jsonString = resolveLocalField({ field, data, document_key }) as string;
    const field_value = JSON.parse(jsonString) as ComputedValue;
    if (field_value?.blocks && field_value?.blocks?.length > 0) {
      return field_value.blocks.map((block: { text: string }) => block.text).join('\n');
    }
  } catch (error) {}
  return '';
};

const resolveTransformField = (props: ResolveFieldProps) => {
  const { field, data, document_key } = props;
  try {
    const transformFunctionName = field?.function_name;
    if (transformFunctionName)
      return transformFunctions[transformFunctionName]({ field, data, document_key });
  } catch (e) {
    console.error(e);
  }
  return '';
};

export const resolveDocumentField = (props: Params) => {
  const { field_name, data = {}, document_key } = props;
  const resolveParams = getResolveParams({ field_name, data, document_key });
  switch (_result(resolveParams, 'field.field_type')) {
    case DOCUMENT_FIELD_LOCAL:
      return resolveLocalField(resolveParams);

    case DOCUMENT_FIELD_DIRECT:
      return resolveDirectField(resolveParams);

    case DOCUMENT_FIELD_COMPUTED:
      return resolveComputedField(resolveParams);

    case DOCUMENT_FIELD_TRANSFORM:
      return resolveTransformField(resolveParams);

    default:
      return resolveLocalField(resolveParams);
  }
};

export const safelyResolveDocumentField = (params: Params) => {
  try {
    return resolveDocumentField(params);
  } catch (error) {
    console.error(error);
    return undefined;
  }
};
