import React from 'react';
import { SessionDataValue } from 'operations/models/SessionData';
import {
  MessageValue,
  MESSAGE_TYPE_OPS_PRE_ALERT,
  STATUS_MESSAGE_PENDING,
} from 'operations/models/Message';
import { NEGATIVE_TYPE, PRIMARY_TYPE } from '../constants';
import { Action } from '../models';
import { PlusOutlined, DeleteOutlined, UserAddOutlined } from '@shipmnts/pixel-hub';
import {
  createShipmentFromMessageRenderer,
  discardMessageRenderer,
  assignToMessageRenderer,
} from './messageActionRenderer';

export const createShipment = (message: MessageValue, shouldVisible: boolean): Action => {
  return {
    type: PRIMARY_TYPE,
    key: 'create_shipment_from_message',
    displayComponent: 'Create Shipment',
    isEnable:
      message?.message_type === MESSAGE_TYPE_OPS_PRE_ALERT &&
      message?.status === STATUS_MESSAGE_PENDING &&
      shouldVisible,
    icon: <PlusOutlined />,
    performAction: createShipmentFromMessageRenderer,
  };
};

export const assignTo = (message: MessageValue, shouldVisible: boolean): Action => {
  return {
    type: PRIMARY_TYPE,
    key: 'assign_to',
    displayComponent: 'Assign To',
    isEnable: message?.status === STATUS_MESSAGE_PENDING && shouldVisible,
    icon: <UserAddOutlined />,
    performAction: assignToMessageRenderer,
  };
};

export const discardMessage = (message: MessageValue, shouldVisible: boolean): Action => {
  return {
    type: NEGATIVE_TYPE,
    key: 'discard_message',
    displayComponent: 'Discard Message',
    isEnable:
      message?.message_type === MESSAGE_TYPE_OPS_PRE_ALERT &&
      message?.status === STATUS_MESSAGE_PENDING &&
      shouldVisible,
    icon: <DeleteOutlined />,
    performAction: discardMessageRenderer,
  };
};
export const getMessageAction = (message: MessageValue, user: SessionDataValue) => {
  const shouldVisible = message?.receiver_company_account?.id === user.company_account.id;
  return [
    createShipment(message, shouldVisible),
    discardMessage(message, shouldVisible),
    assignTo(message, shouldVisible),
  ];
};
