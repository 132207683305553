import { RowNode } from '@ag-grid-community/core';
import OTOReportBulkActions from 'operations/modules/reports/components/OTOReports/OTOReportBulkActions';

export const mergeBookingRenderer = (
  selectedNodes: RowNode[],
  onSuccess: () => void,
  selectedNodeCounts: number,
  extraProps: any,
  onCloseModal: () => void
) => {
  return {
    actionParams: {
      selectedNodes: selectedNodes,
      onSuccess: onSuccess,
      selectedNodeCounts: selectedNodeCounts,
      onCloseModal: onCloseModal,
    },
    component: OTOReportBulkActions,
  };
};
