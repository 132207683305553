import { gql } from '@apollo/client';
import { containerRequestFields } from './containerRequest';
import { cargoFields } from './cargo';
import { routingLegFields } from './routingLeg';
import { oceanTransportOrderFields } from './oceanTransportOrder';
import { orderCollaborationFields } from './orderCollaboration';
import { shipmentContainerFields } from './shipmentContainer';
import { roadTransportOrderFields } from './roadTransportOrder';
import { stuffingBufferServiceOrderFields } from './stuffingBufferServiceOrder';
import { miscellaneousServiceOrderFields } from './miscellaneousServiceOrder';
import { globalCarrierFields } from 'operations/graphql/inquiryOption';

export const bookingRequestFields = gql`
  fragment bookingRequestFields on BookingRequestObjectType {
    id
    shipment_booking_number
    status
    created_at
    updated_at
    load_type
    stuffing_location_type
    high_priority
    quotation_number
    is_external_quotation_number
    inquiry_option {
      id
      quotation_number
      vendor_rate_agreement_number
      port_of_loading {
        id
        name
        unlocode
      }
      port_of_discharge {
        id
        name
        unlocode
      }
      carrier {
        ...globalCarrierFields
      }
      origin_carrier_free_days
      destination_carrier_free_days
      destination_port_free_days
    }
    incoterm
    remarks
    shipment_ids
    shipments {
      id
      job_number
      master_shipment_id
      shipment_documents {
        id
        document_status
      }
      shipment_type
    }
    preferred_carriers {
      id
      name
      carrier_type
    }
    created_by {
      id
      first_name
      last_name
      email
    }
    booking_request_parties {
      id
      name
      party_company {
        id
        registered_name
      }
      party_address {
        id
        name
        print_address
        company_id
      }
    }
    container_requests {
      ...containerRequestFields
    }
    cargos {
      ...cargoFields
    }
    sell_collaborations {
      ...orderCollaborationFields
    }
    email_activities {
      id
      purpose
    }
    ocean_transport_orders {
      id
      load_type
      cargo_ready_date
      cargos {
        id
        commodity_description
      }
      valid_till_date
      booking_date
      booking_type
      booking_number
      valid_till_date
      gate_open_date
      si_cutoff_date
      gate_close_date
      doc_cutoff_date
      dg_indexing_date
      origin_detention_free_days
      origin_demurrage_free_days
      destination_detention_free_days
      destination_demurrage_free_days
      status
      is_independent
      is_amendment_pending
      buy_collaborations {
        ...orderCollaborationFields
      }
      sell_collaborations {
        ...orderCollaborationFields
      }
      empty_pickup_location {
        id
        name
      }
      empty_return_location {
        id
        name
      }
      global_carrier {
        id
        name
      }
      routing_legs {
        ...routingLegFields
      }
      container_requests {
        ...containerRequestFields
      }
      booking_requests {
        id
        status
        load_type
      }
      cargos {
        ...cargoFields
      }
      shipment_containers {
        ...shipmentContainerFields
        is_linked_with_shipments
        container_cargo_details {
          id
          shipment_id
          shipment_container_id
          total_number_of_packages
          gross_weight
          net_weight
        }
      }
      voyage_schedule_id
      surveyor_company {
        id
        registered_name
      }
      surveyor_address {
        id
        name
      }
    }
    road_transport_orders {
      ...roadTransportOrderFields
    }
    stuffing_buffer_service_orders {
      ...stuffingBufferServiceOrderFields
    }
    miscellaneous_service_orders {
      ...miscellaneousServiceOrderFields
    }
  }
  ${orderCollaborationFields}
  ${containerRequestFields}
  ${cargoFields}
  ${routingLegFields}
  ${shipmentContainerFields}
  ${roadTransportOrderFields}
  ${stuffingBufferServiceOrderFields}
  ${miscellaneousServiceOrderFields}
  ${globalCarrierFields}
`;

export const CREATE_BOOKING_REQUEST = gql`
  mutation create_booking_request($booking_request: BookingRequestInputType!) {
    create_booking_request(booking_request: $booking_request) {
      ...bookingRequestFields
    }
  }
  ${bookingRequestFields}
`;

export const GET_BOOKING_REQUEST = gql`
  query booking_request($id: ID!) {
    booking_request(id: $id) {
      ...bookingRequestFields
    }
  }
  ${bookingRequestFields}
`;

export const GET_BOOKING_REQUEST_OTOS = gql`
  query booking_request($id: ID!) {
    booking_request(id: $id) {
      id
      ocean_transport_orders {
        ...oceanTransportOrderFields
      }
      container_requests {
        ...containerRequestFields
      }
      road_transport_orders {
        ...roadTransportOrderFields
      }
    }
  }
  ${oceanTransportOrderFields}
  ${containerRequestFields}
  ${roadTransportOrderFields}
`;

export const UPDATE_BOOKING_REQUEST = gql`
  mutation update_booking_request($booking_request: UpdateBookingRequestInputType!) {
    update_booking_request(booking_request: $booking_request) {
      ...bookingRequestFields
    }
  }
  ${bookingRequestFields}
`;

export const CANCEL_OCEAN_SHIPMENT = gql`
  mutation cancel_ocean_shipment(
    $shipment_id: ID!
    $cancelled_by: String!
    $cancellation_reason: String!
    $remarks: String
    $cancel_allocated_resources: Boolean!
  ) {
    cancel_ocean_shipment(
      shipment_id: $shipment_id
      cancelled_by: $cancelled_by
      cancellation_reason: $cancellation_reason
      remarks: $remarks
      cancel_allocated_resources: $cancel_allocated_resources
    ) {
      message
    }
  }
`;

export const CANCEL_BOOKING_REQUEST = gql`
  mutation cancel_booking_request(
    $booking_request_id: ID!
    $cancelled_by: String!
    $cancellation_reason: String!
    $remarks: String
    $cancel_allocated_resources: Boolean!
    $create_cancelled_shipment: Boolean
  ) {
    cancel_booking_request(
      booking_request_id: $booking_request_id
      cancelled_by: $cancelled_by
      cancellation_reason: $cancellation_reason
      remarks: $remarks
      cancel_allocated_resources: $cancel_allocated_resources
      create_cancelled_shipment: $create_cancelled_shipment
    ) {
      id
      status
      remarks
      shipment_ids
      container_requests {
        ...containerRequestFields
      }
      road_transport_orders {
        ...roadTransportOrderFields
      }
      ocean_transport_orders {
        id
        routing_legs {
          ...routingLegFields
        }
        booking_requests {
          id
          status
          load_type
        }
      }
    }
  }
  ${containerRequestFields}
  ${routingLegFields}
  ${roadTransportOrderFields}
`;

export const ALLOCATE_BOOKING_REQUESTS = gql`
  mutation allocateBookingRequests(
    $ocean_transport_order_id: ID!
    $booking_requests: [AllocationResourceInputType!]!
  ) {
    allocate_booking_requests(
      ocean_transport_order_id: $ocean_transport_order_id
      booking_requests: $booking_requests
    ) {
      id
      booking_requests {
        ...bookingRequestFields
      }
      container_requests {
        ...containerRequestFields
      }
      shipment_containers {
        ...shipmentContainerFields
      }
    }
  }
  ${bookingRequestFields}
  ${shipmentContainerFields}
  ${containerRequestFields}
`;

export const CREATE_BOOKING_REQUEST_FROM_OCEAN_TRANSPORT_ORDER = gql`
  mutation create_booking_request_from_ocean_transport_order(
    $ocean_transport_order_id: ID!
    $booking_request: BookingRequestFromOtoInputType!
  ) {
    create_booking_request_from_ocean_transport_order(
      ocean_transport_order_id: $ocean_transport_order_id
      booking_request: $booking_request
    ) {
      ...bookingRequestFields
    }
  }
  ${bookingRequestFields}
`;

export const UPDATE_BOOKING_REQUEST_ALLOCATION = gql`
  mutation update_booking_request_allocation(
    $id: ID!
    $ocean_transport_orders: [AllocationResourceInputType!]!
  ) {
    update_booking_request_allocation(id: $id, ocean_transport_orders: $ocean_transport_orders) {
      id
      ocean_transport_orders {
        ...oceanTransportOrderFields
      }
      container_requests {
        ...containerRequestFields
      }
      road_transport_orders {
        ...roadTransportOrderFields
      }
    }
  }
  ${oceanTransportOrderFields}
  ${containerRequestFields}
  ${roadTransportOrderFields}
`;

export const DEALLOCATE_OCEAN_TRANSPORT_ORDER = gql`
  mutation deallocate_ocean_transport_order(
    $ocean_transport_order_id: ID!
    $booking_request_id: ID!
  ) {
    deallocate_ocean_transport_order(
      ocean_transport_order_id: $ocean_transport_order_id
      booking_request_id: $booking_request_id
    ) {
      booking_request {
        ...bookingRequestFields
      }
    }
  }
  ${bookingRequestFields}
`;

export const CREATE_INDEPENDENT_HOUSE_SHIPMENT = gql`
  mutation create_independent_house_shipment(
    $shipment_container_ids: [ID!]
    $house_shipment: CreateShipmentInput!
    $shipment_document_house: ShipmentDocumentInput
  ) {
    create_independent_house_shipment(
      shipment_container_ids: $shipment_container_ids
      house_shipment: $house_shipment
      shipment_document_house: $shipment_document_house
    ) {
      id
    }
  }
`;

export const CREATE_SHIPMENT_FROM_CANCELLED_BOOKING_REQUEST = gql`
  mutation create_shipment_from_cancelled_booking_request($booking_request_id: ID!) {
    create_shipment_from_cancelled_br(booking_request_id: $booking_request_id) {
      ...bookingRequestFields
    }
  }
  ${bookingRequestFields}
`;

export const CREATE_SHIPMENT_FROM_BR = gql`
  mutation create_shipment_from_br(
    $booking_request_id: ID!
    $shipment: ShipmentFromOtoInputType!
    $shipment_document_master: ShipmentDocumentInput!
    $shipment_document_house: ShipmentDocumentInput
    $throw_error_on_credit_fail: Boolean
  ) {
    create_shipment_from_br(
      booking_request_id: $booking_request_id
      shipment: $shipment
      shipment_document_master: $shipment_document_master
      shipment_document_house: $shipment_document_house
      throw_error_on_credit_fail: $throw_error_on_credit_fail
    ) {
      id
    }
  }
`;

export const BOOKING_REQUESTS_SEARCH = gql`
  query booking_requests_search(
    $search_text: String!
    $customer_company_id: ID!
    $port_of_loading_id: ID!
    $port_of_discharge_id: ID!
  ) {
    booking_requests_search(
      search_text: $search_text
      customer_company_id: $customer_company_id
      port_of_loading_id: $port_of_loading_id
      port_of_discharge_id: $port_of_discharge_id
    ) {
      id
      shipment_booking_number
      status
      load_type
      booking_request_parties {
        id
        name
        party_company {
          id
          registered_name
        }
      }
      preferred_carriers {
        id
        name
        carrier_type
      }
      sell_collaborations {
        ...orderCollaborationFields
      }
      ocean_transport_orders {
        id
        load_type
        booking_date
        booking_number
        status
        buy_collaborations {
          ...orderCollaborationFields
        }
        sell_collaborations {
          ...orderCollaborationFields
        }
        empty_pickup_location {
          id
          name
        }
        global_carrier {
          id
          name
        }
        routing_legs {
          ...routingLegFields
        }
        shipment_containers {
          ...shipmentContainerFields

          is_linked_with_shipments
          container_cargo_details {
            id
            shipment_id
            shipment_container_id
          }
        }
        voyage_schedule_id
      }
    }
  }
  ${orderCollaborationFields}
  ${routingLegFields}
  ${shipmentContainerFields}
`;
