// eslint-disable-next-line @typescript-eslint/ban-ts-comment

import React, { useEffect, useRef, useReducer, useCallback, useState } from 'react';
import {
  Layout,
  Button,
  Card,
  Input,
  message,
  Row,
  Col,
  Form,
  UploadDocumentModal,
  DocumentsList,
  UploadDocumentPayload,
  UploadedDocumentType,
  documentsStore,
  uploadDocument,
  deleteUploadedDocument,
  addDocumentParents,
  dayjs,
  dayjsGenerateConfig,
} from '@shipmnts/pixel-hub';
import { PageHeader } from '@shipmnts/pixel-hub';
import { useLocation } from 'wouter';
import { useSession, errorMessageHandlerGraphQL } from 'common';
import BookingOrderBasicDetails from './BookingOrderBasicDetails';
import BookingRoutingDetails from './BookingRoutingDetails';
import CargoDetails from 'operations/components/CargoDetails';
import ContainerDetails from 'operations/components/ContainerDetails';
import BookingOrderConfirmation from './BookingOrderConfirmation';
import { RoutingDetailsValue } from 'operations/components/RoutingDetails';
import { omit as _omit, startCase as _startCase } from 'lodash';
import {
  BOOKING_TYPE_SHIPPING_LINE,
  BOOKING_TYPE_VENDOR,
  LOAD_TYPE_FCL,
  WEIGHT_UNIT_KGS,
  VOLUME_UNIT_CBM,
} from 'operations/baseConstants';
import { useMutation } from '@apollo/client';
import { ContainerRequestValue } from 'operations/models/ContainerRequest';
import { getMainHopEtd, RoutingLegValue, hasPrecarriageLeg } from 'operations/models/RoutingLeg';
import { CargoValue, isLiveReeferCargo } from 'operations/models/Cargo';
import { CREATE_OCEAN_TRANSPORT_ORDER } from 'operations/modules/booking/graphql/oceanTransportOrder';
import { convertDatesTounix } from '@shipmnts/pixel-hub';
import { AppHelmet } from '@shipmnts/pixel-hub';

import { BOOKING_TYPE_OCEAN_TRANSPORT_ORDER } from 'operations/modules/booking/constants';
import { getDisbaledCutoffDateMapping } from 'operations/modules/booking/helpers/CutoffDateHelper';
import {
  getRoutingDetailsOnVoyageReset,
  checkVoyageResetOnValuesChange,
  getDefaultPreviousValue,
} from 'operations/modules/booking/helpers/RoutingDetailsHelper';

const { Header, Content } = Layout;

function useQuery(): URLSearchParams {
  return new URLSearchParams(window.location.search);
}

export const getOceanTransportOrderPayload = (values: any) => {
  const {
    cargos,
    container_requests,
    routing_details,
    customer,
    confirmed_booking,
    empty_pickup_location,
    empty_return_location,
    carrier,
    vendor,
    surveyor,
    sales_person,
    ...restProps
  } = values;
  const date_fields = [
    'booking_date',
    'valid_till_date',
    'gate_open_date',
    'si_cutoff_date',
    'gate_close_date',
    'doc_cutoff_date',
    'stuffing_cutoff_date',
  ];
  const payload = {
    ...JSON.parse(JSON.stringify(restProps)),
    ...convertDatesTounix(values, date_fields),
    routing_legs: (routing_details?.routing_legs || []).map((rl: RoutingLegValue) => {
      const {
        vessel,
        estimated_time_of_departure,
        estimated_time_of_arrival,
        global_carrier,
        ...restRL
      } = rl;
      return {
        ...restRL,
        global_carrier_id: global_carrier?.id,
        vessel_id: vessel?.imo,
        estimated_time_of_departure: estimated_time_of_departure
          ? dayjs(estimated_time_of_departure).unix()
          : undefined,
        estimated_time_of_arrival: estimated_time_of_arrival
          ? dayjs(estimated_time_of_arrival).unix()
          : undefined,
      };
    }),
    routing_nodes: Object.values(routing_details?.routing_nodes || {}).map((rn: any) => {
      const { location, company, address, terminal, ...restRN } = rn;
      return {
        ...JSON.parse(JSON.stringify(restRN)),
        location: undefined,
        company: undefined,
        address: undefined,
        terminal: undefined,
        location_id: location?.id,
        terminal_id: terminal?.id,
      };
    }),
    global_carrier_id: carrier?.id,
    empty_pickup_location_id: empty_pickup_location?.id,
    empty_return_location_id: empty_return_location?.id,
    vendor_company_id: vendor?.party_company?.id,
    vendor_address_id: vendor?.party_address?.id,
    customer_company_id: customer?.party_company?.id,
    customer_address_id: customer?.party_address?.id,
    surveyor_company_id: surveyor?.party_company?.id,
    surveyor_address_id: surveyor?.party_address?.id,
    status: 'confirmed',
    voyage_schedule_id: values?.voyage_schedule_id,
    sales_person_id: sales_person?.id,
    cargos: (cargos || []).map((cargo: CargoValue) => {
      const { commodity, _id, allocation_pending_quantity, ...restCargos } = JSON.parse(
        JSON.stringify(cargo)
      );
      return {
        ...restCargos,
        commodity_id: commodity?.id,
      };
    }),
    container_requests: (container_requests || []).map((container_request: ContainerRequestValue) =>
      _omit<ContainerRequestValue, '_id'>(container_request, '_id')
    ),
  };

  return payload;
};

const BookingOrderForm = React.memo(function BookingOrderForm(): JSX.Element {
  const { 1: navigate } = useLocation();
  const query = useQuery();
  const sessionData = useSession();
  const source_ocean_transport_order = window.history?.state?.ocean_transport_order
    ? JSON.parse(window.history?.state?.ocean_transport_order)
    : {};
  const booking_type = query.get('booking_type') || BOOKING_TYPE_VENDOR; //=== 'carrier' ? BOOKING_TYPE_SHIPPING_LINE : BOOKING_TYPE_VENDOR;
  const load_type = query.get('load_type') || LOAD_TYPE_FCL;
  const booking_type_label = _startCase(booking_type);
  const container_requests_ref = useRef<{ runValidation: () => boolean }>();
  const routing_details_ref = useRef<{ runValidation: () => boolean }>();

  const [documentUpdate, setDocumentUpdate] = useState<{
    updating: boolean;
    error?: boolean | string;
  }>({ updating: false, error: false });

  const [previousValue, setPreviousValue] = useState<{
    isFirstContainerTypeReefer?: boolean;
    mainCarriage?: {
      locationId?: string;
      vessel?: string;
    };
    carrierId?: string;
  }>(getDefaultPreviousValue(source_ocean_transport_order));

  const [updateDocIds, setUpdateDocIds] = useState<Array<string>>([]);

  const [documentsStoreValue, dispatch] = useReducer(documentsStore, {
    uploadingDocuments: [],
    errors: {},
    documents: [],
  });

  const uploadDoc = useCallback(async (doc: UploadDocumentPayload) => {
    dispatch({ type: 'uploading', payload: { doc } });
    const { response, error } = await uploadDocument(doc, process.env.DOCGEN_URL || '');
    if (error) {
      dispatch({ type: 'upload_failure', payload: { doc, error: error.message } });
    } else if (response) {
      const uploaded_document = response?.data?.document;
      dispatch({ type: 'upload_success', payload: { doc: uploaded_document } });
    }
  }, []);

  const deleteDoc = useCallback(
    async (doc: UploadedDocumentType) => {
      dispatch({ type: 'deleting', payload: { doc } });
      const { response, error } = await deleteUploadedDocument(
        doc.id,
        sessionData.id,
        process.env.DOCGEN_URL || ''
      );
      if (error) {
        message.error(error.message);
        dispatch({ type: 'delete_failure', payload: { doc } });
      } else if (response) {
        dispatch({ type: 'remove_document', payload: { doc: doc } });
      }
    },
    [sessionData.id]
  );

  const {
    uploadingDocuments = [],
    errors: uploadingError = {},
    documents = [],
  } = documentsStoreValue;

  const updateDocs = useCallback(
    async (booking_order_id: string, document_ids: Array<string>) => {
      setDocumentUpdate({ updating: true, error: false });
      const { response, error } = await addDocumentParents(
        document_ids,
        [{ parent_type: BOOKING_TYPE_OCEAN_TRANSPORT_ORDER, parent_id: booking_order_id }],
        sessionData.id,
        process.env.DOCGEN_URL || ''
      );
      if (error) {
        message.error('Document upload failed');
        setDocumentUpdate({ updating: false, error: !!error || error.message });
      } else if (response) {
        setDocumentUpdate({ updating: false, error: false });
        dispatch({ type: 'reset_state' });
        setUpdateDocIds([]);
      }
    },
    [sessionData.id]
  );

  const [createOceanTransportOrder, { data, loading, error }] = useMutation(
    CREATE_OCEAN_TRANSPORT_ORDER
  );

  const [form] = Form.useForm();

  useEffect(() => {
    if (!error && data?.create_ocean_transport_order) {
      message.success('Ocean Transporter Order Created !');
      form.setFieldsValue({ booking_number: undefined });
      if (updateDocIds && updateDocIds.length > 0)
        updateDocs(data?.create_ocean_transport_order?.id, updateDocIds);
    }
  }, [error, data, navigate, form, updateDocs, updateDocIds]);

  const resetMainHopVoyageNumber = useCallback(
    (routing_details: RoutingDetailsValue, voyage_schedule_id?: string) => {
      form.setFieldsValue({
        routing_details,
        voyage_schedule_id,
      });
    },
    [form]
  );

  useEffect(() => {
    form.setFieldValue('confirmed_booking', true);
  }, [form]);

  return (
    <Form
      form={form}
      onFinish={(values) => {
        let foundError = false;
        foundError = Boolean(container_requests_ref?.current?.runValidation());
        if (!foundError) {
          const payload = getOceanTransportOrderPayload(values);
          createOceanTransportOrder({ variables: { ocean_transport_order: payload } });
          setUpdateDocIds(documents.map((d) => d.id));
        }
      }}
      name="booking_order"
      layout="vertical"
      style={{
        height: '100%',
      }}
      initialValues={{
        booking_type,
        load_type,
        customer: { party_company: sessionData.company_account.default_company },
        confirmed_booking: false,
        valid_till_date: dayjsGenerateConfig.getNow().add(3, 'day'),
        booking_date: dayjsGenerateConfig.getNow(),
        cargos: [
          {
            package_type: 'Box',
            weight_unit: WEIGHT_UNIT_KGS,
            volume_unit: VOLUME_UNIT_CBM,
          },
        ],
        ...source_ocean_transport_order,
      }}
      onValuesChange={(changedValues, allValues) => {
        if (changedValues.routing_details) {
          let etd = getMainHopEtd(changedValues.routing_details.routing_legs);
          const current_valid_till_date = form.getFieldValue('valid_till_date');
          if (typeof etd === 'string') {
            etd = dayjs(etd);
          }
          if (etd && etd.isBefore(current_valid_till_date))
            form.setFieldsValue({ valid_till_date: etd.subtract(1, 'day') });
        }
        const { shouldReset, currentValue } = checkVoyageResetOnValuesChange({
          changedValues,
          allValues,
          previousValue,
        });
        setPreviousValue(currentValue);
        if (shouldReset) {
          getRoutingDetailsOnVoyageReset(allValues.routing_details, resetMainHopVoyageNumber);
        }
      }}
    >
      <Layout className="form-layout">
        <AppHelmet>
          <title>New {_startCase(booking_type)} Booking</title>
        </AppHelmet>
        <Header style={{ padding: 0, background: 'none', height: 'auto' }}>
          <PageHeader
            style={{ padding: '10px 0px' }}
            onBack={() => {
              window.history.back();
            }}
            title="New Booking Order"
            subTitle={booking_type_label + ' ' + load_type.toUpperCase()}
            extra={[
              <Button
                loading={loading}
                disabled={
                  uploadingDocuments.length > 0 || documentUpdate.updating || !!documentUpdate.error
                }
                htmlType="submit"
                type="primary"
                key="save"
              >
                Save
              </Button>,
            ]}
          >
            {(error && errorMessageHandlerGraphQL(error)) ||
              (documentUpdate.error && (
                <span>
                  Something went wrong while uploading the documents.
                  {documentUpdate.error}
                  <Button
                    onClick={() => updateDocs(data?.create_ocean_transport_order?.id, updateDocIds)}
                    size="small"
                    type="link"
                  >
                    Retry
                  </Button>
                </span>
              ))}
          </PageHeader>
        </Header>
        <Content>
          <Form.Item noStyle name="load_type">
            <span />
          </Form.Item>
          <Form.Item noStyle name="booking_type">
            <span />
          </Form.Item>
          <BookingOrderBasicDetails
            booking_type={booking_type}
            load_type={load_type}
            areCutoffRequired={booking_type === BOOKING_TYPE_SHIPPING_LINE}
          />
          <Card title="Cargo Details">
            <CargoDetails form={form} load_type={load_type} />
          </Card>
          {load_type === LOAD_TYPE_FCL && (
            <Card title="Container Details">
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.cargos !== currentValues.cargos
                }
              >
                {({ getFieldValue }) => {
                  const cargos = getFieldValue('cargos');
                  const isLiveReefer = cargos ? isLiveReeferCargo(cargos) : false;
                  return (
                    <Form.Item
                      name="container_requests"
                      noStyle
                      rules={[
                        {
                          validator: (rule, value) => {
                            if (!container_requests_ref?.current?.runValidation()) {
                              return Promise.resolve();
                            }
                            return Promise.reject();
                          },
                        },
                      ]}
                    >
                      <ContainerDetails
                        ref={container_requests_ref}
                        required_fields={['container_type_code', 'quantity']}
                        isLiveReefer={isLiveReefer}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Card>
          )}

          <Card title="Routing Details">
            <Form.Item
              name="routing_details"
              rules={[
                {
                  validator: (rule, value) => {
                    if (!routing_details_ref?.current?.runValidation()) {
                      return Promise.resolve();
                    }
                    return Promise.reject();
                  },
                },
              ]}
              noStyle
            >
              <BookingRoutingDetails
                ref={routing_details_ref}
                validateVesselVoyage={true}
                bookingType={booking_type}
                isReeferContainer={previousValue.isFirstContainerTypeReefer}
              />
            </Form.Item>
          </Card>

          <Card title="Booking Cutoff Details">
            <Form.Item noStyle name="voyage_schedule_id">
              <div />
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.confirmed_booking !== currentValues.confirmed_booking ||
                prevValues.routing_details !== currentValues.routing_details
              }
            >
              {({ getFieldValue }) => {
                const confirmed_booking = getFieldValue('confirmed_booking');
                const routing_legs = getFieldValue('routing_details')?.routing_legs;
                const voyage_schedule_id = getFieldValue('voyage_schedule_id');
                const hasPrecarriage = hasPrecarriageLeg(routing_legs);
                const etd = getMainHopEtd(routing_legs);
                return (
                  <BookingOrderConfirmation
                    etd={etd}
                    areCutoffRequired={
                      confirmed_booking && booking_type === BOOKING_TYPE_SHIPPING_LINE
                    }
                    disableCutoff={getDisbaledCutoffDateMapping(
                      Boolean(voyage_schedule_id),
                      hasPrecarriage
                    )}
                    load_type={load_type}
                    booking_type={booking_type}
                  />
                );
              }}
            </Form.Item>
            <Row gutter={16}>
              <Col span={12}>
                <div>
                  <UploadDocumentModal
                    onUpload={uploadDoc}
                    sessionData={sessionData}
                    initialUploadDocumentTags={['Booking confirmation']}
                  />
                  <DocumentsList
                    uploadingDocuments={uploadingDocuments}
                    uploadedDocuments={documents}
                    uploadingError={uploadingError}
                    onUpload={uploadDoc}
                    onDelete={deleteDoc}
                    sessionData={sessionData}
                  />
                </div>
              </Col>
            </Row>
          </Card>
          <Card title="Other Details">
            <Form.Item name="remarks" label="Remarks">
              <Input.TextArea rows={2} />
            </Form.Item>
          </Card>
        </Content>
      </Layout>
    </Form>
  );
});

export default BookingOrderForm;
