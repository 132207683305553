import { gql } from '@apollo/client';

export const addressFields = gql`
  fragment addressFields on AddressObjectType {
    id
    name
    entity_type
    city {
      id
      name
    }
    city_name
    company_id
    address_line_1
    address_line_2
    postal_code
    print_address
    state_tax_code
    state_name
    state_code
    country_name
    country_code
    attn
    accounts_address_link
    is_disabled
    company {
      id
      registered_name
      company_type
      status
      country_of_incorporation
    }
  }
`;

export const invoiceAddressFields = gql`
  fragment invoiceAddressFields on AddressObjectType {
    name
    address_line_1
    address_line_2
    state_tax_code
    postal_code
    entity_type
    id
    print_address
    state_code
    state_name
    city_name
    city {
      id
      name
      city_code
    }
    country_code
    country_name
  }
`;

export const GET_ADDRESS = gql`
  query address($id: ID!) {
    address(id: $id) {
      ...addressFields
    }
  }
  ${addressFields}
`;
