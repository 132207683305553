import {
  Button,
  Divider,
  Form,
  Layout,
  message,
  Skeleton,
  Dropdown,
  MenuProps,
} from '@shipmnts/pixel-hub';
import { PageHeader } from '@shipmnts/pixel-hub';

import {
  DeleteOutlined,
  PlayCircleOutlined,
  ClearOutlined,
  AppstoreAddOutlined,
  CopyOutlined,
} from '@shipmnts/pixel-hub';
import { isArray as _isArray } from 'lodash';
import React, { useEffect, useState } from 'react';
import TemplateContent from './TemplateContent';
import { useLocation, useParams } from 'wouter';
import { useMutation, useLazyQuery } from '@apollo/client';
import { GET_TEMPLATE, UPSERT_TEMPLATE } from 'network/graphql/template';
import { TemplateValue } from 'network/models/Template';
import { extractInitialValues, useSession } from 'common';
import { get } from 'lodash';
import { DownOutlined } from '@shipmnts/pixel-hub';
import { DuplicateTemplateHelper } from './DuplicateTemplateHelper';

const { Content } = Layout;
interface TemplateDetailLayoutProp {
  onClose?: () => void;
  onSuccess?: (value: TemplateValue) => void | undefined;
  id?: string;
  externalLink?: boolean;
  template_type?: string | string[];
  resource_type?: string;
  onCreateDisableType?: boolean;
}

export default function TemplateDetailLayout(props: TemplateDetailLayoutProp) {
  const {
    onClose,
    onSuccess,
    id,
    externalLink,
    template_type,
    resource_type,
    onCreateDisableType = false,
  } = props;
  const [form] = Form.useForm();
  const { 1: navigate } = useLocation();
  const params = useParams<{ id: string }>();
  const template_id = id || params?.id;
  const [template, setTemplate] = useState<TemplateValue | undefined>(
    window.history?.state?.template
  );
  const session = useSession();
  const isEditDisable = (template: TemplateValue | undefined) => {
    let disable = template?.is_disabled || false;
    if (!disable && template?.is_standard) {
      // eslint-disable-next-line eqeqeq
      disable = session?.id == template?.created_by?.id ? false : true;
    }

    return disable;
  };

  const DISABLE_ACTION = 'disable';
  const ENABLE_ACTION = 'enable';
  const SET_DEAFULT_ACTION = 'set_default';
  const CLEAR_DEFAULT = 'clear_default';
  const DUPLICATE = 'duplicate';
  const disabled = isEditDisable(template);

  const ACTION_MAPPING = {
    [DISABLE_ACTION]: {
      key: DISABLE_ACTION,
      label: 'Disable',
      danger: true,
      payload: {
        is_disabled: true,
      },
      icon: <DeleteOutlined />,
      disabled: template?.is_standard,
    },
    [ENABLE_ACTION]: {
      key: ENABLE_ACTION,
      label: 'Enable',
      payload: {
        is_disabled: false,
      },
      icon: <PlayCircleOutlined />,
      disabled: template?.is_standard,
    },
    [SET_DEAFULT_ACTION]: {
      key: SET_DEAFULT_ACTION,
      label: 'Set Default',
      payload: {
        is_default: true,
      },
      icon: <AppstoreAddOutlined />,
      disabled: disabled,
    },
    [CLEAR_DEFAULT]: {
      key: CLEAR_DEFAULT,
      label: 'Clear Default',
      payload: {
        is_default: false,
      },
      icon: <ClearOutlined />,
      disabled: disabled,
    },
    [DUPLICATE]: {
      key: DUPLICATE,
      label: 'Duplicate Template',
      payload: {},
      icon: <CopyOutlined />,
      disabled: false,
    },
  };

  const getActions = (template: TemplateValue | undefined) => {
    const items: any = [];
    if (!template) return items;
    // items.push(ACTION_MAPPING[DUPLICATE]);
    if (template?.is_disabled) items.push(ACTION_MAPPING[ENABLE_ACTION]);
    else items.push(ACTION_MAPPING[DISABLE_ACTION]);

    if (template?.is_default) items.push(ACTION_MAPPING[CLEAR_DEFAULT]);
    else items.push(ACTION_MAPPING[SET_DEAFULT_ACTION]);

    return items;
  };

  const items: any = getActions(template);

  const onMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key === DUPLICATE) {
      template && DuplicateTemplateHelper(template, navigate);
    } else {
      const currentAcction: any = get(ACTION_MAPPING, e.key);

      if (template && template_id && template_id !== 'new') {
        upsertTemplate({
          variables: {
            template: {
              ...getUpsertTemplatePayload(template),
              id: template_id,
              ...currentAcction.payload,
            },
          },
        });
      }
    }
  };

  const [upsertTemplate, { data: upsertData, error: upsertError, loading: upsertLoad }] =
    useMutation(UPSERT_TEMPLATE);

  const [getTemplate, { data, loading, error }] = useLazyQuery(GET_TEMPLATE, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (upsertData && !upsertError) {
      message.success('Template saved successfully.');
      const template: TemplateValue = upsertData?.upsert_template;
      setTemplate(template);
      if (onSuccess) {
        onSuccess(template);
      } else if (template_id === 'new') navigate('~/workspace?doc_type=Network::Template');

      if (onClose) onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSuccess, upsertData, upsertError]);

  useEffect(() => {
    if (template_id !== 'new') {
      getTemplate({
        variables: {
          id: template_id,
        },
      });
    }
  }, [getTemplate, template_id]);

  useEffect(() => {
    if (data && !error) {
      let template = data?.template;
      if (template?.print_option)
        template = { ...template, ...extractInitialValues(template?.print_option) };
      setTemplate(template);
      form.setFieldsValue(template);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);

  const extraButtons = [
    <Button
      key="save"
      type="primary"
      onClick={() => form.submit()}
      loading={upsertLoad}
      disabled={isEditDisable(template)}
    >
      Save
    </Button>,
  ];

  if (template_id !== 'new')
    extraButtons.push(
      <Dropdown menu={{ items, onClick: onMenuClick }}>
        <Button>
          Actions <DownOutlined />
        </Button>
      </Dropdown>
    );

  const getUpsertTemplatePayload = (values: any) => {
    const print_option: any = template?.print_option;
    if (print_option) {
      delete print_option['__typename'];
      print_option['fields'] = print_option?.fields.map((option: any) => {
        option['default'] = values[option['field_name']]?.toString();
        delete option['__typename'];
        return option;
      });
    }

    return {
      content: values?.content,
      template_type: values?.template_type,
      name: values?.name,
      is_disabled: values?.is_disabled,
      id: template_id === 'new' ? undefined : template_id,
      resource_type: values?.resource_type,
      print_option: print_option,
    };
  };
  return (
    <Layout style={{ margin: 0, padding: 0 }}>
      <PageHeader
        ghost={false}
        onBack={() => {
          if (externalLink && onClose) onClose();
          else window.history.back();
        }}
        title={'Template'}
        extra={extraButtons}
      >
        <Divider style={{ margin: '0', padding: '0' }} />
        {loading && <Skeleton />}
        {!loading && (
          <Content>
            <Form
              form={form}
              layout="vertical"
              disabled={template?.is_standard || disabled}
              initialValues={
                template || {
                  template_type: _isArray(template_type) ? template_type?.[0] : template_type,
                  resource_type: resource_type,
                }
              }
              onFinish={(values) => {
                const payload = getUpsertTemplatePayload(values);
                upsertTemplate({
                  variables: { template: payload },
                });
              }}
              onValuesChange={(changedValues, _allValues) => {
                if ('resource_type' in changedValues) {
                  form.setFieldsValue({
                    template_type: undefined,
                  });
                }
              }}
            >
              <TemplateContent
                onCreateDisableType={onCreateDisableType}
                disable={template?.is_standard || disabled}
                template={template}
                form={form}
              />
            </Form>
          </Content>
        )}
      </PageHeader>
    </Layout>
  );
}
