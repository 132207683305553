import { Form } from '@shipmnts/pixel-hub';
import { ShipmentValue } from 'operations/models/Shipment';
import React, { useRef } from 'react';
import ContainerDetailsForm from '../DetailLayout/Containers/ContainerForm';

interface ContainerDetailsProps {
  shipment?: ShipmentValue;
}

export default function PickupContainerDetails(props: ContainerDetailsProps) {
  const gridRef = useRef<any>();
  const ref = useRef<{ runValidation: () => boolean }>(null);

  return (
    <Form.Item
      name="shipment_containers"
      rules={[
        {
          validator: (rule, value) => {
            if (!ref?.current?.runValidation()) {
              return Promise.resolve();
            }
            return Promise.reject();
          },
        },
      ]}
    >
      <ContainerDetailsForm
        gridRef={gridRef}
        ref={ref}
        isCreateContainer={true}
        shipment={props.shipment}
        requiredFields={['container_type']}
      />
    </Form.Item>
  );
}
