import { VoyageScheduleValue } from 'operations/models/VoyageSchedule';
import { TERTIARY_TYPE } from '../constants';
import { Action } from '../models';
import { duplicateVoyageScheduleData } from 'operations/modules/reports/components/BookingScheduleReport/VoyageScheduleReportActions';

export const duplicationVoyageSchedule = (
  voyageSchedule: VoyageScheduleValue,
  navigate: any
): Action => {
  return {
    type: TERTIARY_TYPE,
    key: 'duplicate_voyage_schedule',
    displayComponent: 'Duplicate Voyage Schedule',
    isEnable: true,
    onClick: () => {
      duplicateVoyageScheduleData(voyageSchedule, navigate);
    },
  };
};

export const getVoyageScheduleAction = (
  voyageSchedule: VoyageScheduleValue,
  navigate: any
): Action[] => {
  return [duplicationVoyageSchedule(voyageSchedule, navigate)];
};
