import React from 'react';
import BookingRequest, { BookingRequestValue } from 'operations/models/BookingRequest';
import { Action } from '../models';
import { NEGATIVE_TYPE, PRIMARY_TYPE, SECONDARY_TYPE, TERTIARY_TYPE } from '../constants';
import {
  BOOKING_TYPE_SELF,
  BOOKING_TYPE_SHIPPING_LINE,
  BOOKING_TYPE_VENDOR,
  LOAD_TYPE_FCL,
  STUFFING_LOCATION_TYPE_FACTORY,
} from 'operations/baseConstants';
import { STATUS_CANCELLED } from 'operations/modules/reports/constants';
import { duplicateBookingRequest } from 'operations/modules/booking/helpers/DuplicateBookingHelper';
import {
  ToolOutlined,
  TagsOutlined,
  SolutionOutlined,
  CarryOutOutlined,
  CopyOutlined,
  MailOutlined,
  WhatsAppOutlined,
  FileAddOutlined,
  EditOutlined,
  DeleteOutlined,
  DisconnectOutlined,
  CompassOutlined,
  ShareAltOutlined,
} from '@shipmnts/pixel-hub';
import {
  addCartingDetailsRenderer,
  addContainerDetailsActionRenderer,
  addPickupDetailsRenderer,
  addServiceRenderer,
  allocateOTOReportRenderer,
  cancelBookingFormRenderer,
  createBookingOrderFromBRRenderer,
  createUpdateRoadTransportOrderRenderer,
  deallocateOtoRenderer,
  deleteRoadTransportOrderRenderer,
  extendValidityRenderer,
  addCreateShipmentRenderer,
  // addCreateShipmentRendererPo,
  deleteMiscService,
  createCancelledShipmentRenderer,
  offloadContainersFormRenderer,
  markDGIndexingRenderer,
  updateRoutingDetailsRenderer,
  updateOrSplitRollOverRenderer,
  notifyOnEmailRenderer,
  addCollaboratorsRenderer,
} from './bookingRequestActionRenderer';
import { PermissionValue, SessionDataValue } from 'operations/models/SessionData';
import {
  BOOKING_TYPE_BOOKING_REQUEST,
  BOOKING_TYPE_OCEAN_TRANSPORT_ORDER,
  CARGO_INSURANCE_SERVICE,
  CLEARANCE_SERVICE,
  EMPTY_CONTAINER_INSURANCE_SERVICE,
} from 'operations/modules/booking/constants';
import {
  STATUS_STUFFING_ORDER_CARTED_IN,
  STATUS_STUFFING_ORDER_NOT_YET_CARTED_IN,
} from 'operations/constants';
import { OceanTransportOrderValue } from 'operations/models/OceanTransportOrder';
import { RoadTransportOrderValue } from 'operations/models/RoadTransportOrder';
import { startCase } from 'lodash';
import { isEtdCrossedOto } from '../OTOActions/otoActionHelper';
import { MiscellaneousServiceOrderValue } from 'operations/models/MiscellaneousServiceOrder';
import { ApolloClient } from '@apollo/client';
import {
  SHIPMENT_TYPE_DIRECT,
  SHIPMENT_TYPE_BACK_TO_BACK,
  SHIPMENT_TYPE_HOUSE,
  ShipmentType,
} from 'operations/modules/shipment/constants';
import { CustomIcon } from '@shipmnts/pixel-hub';
import { hasPermission } from '@shipmnts/pixel-hub';
import { PERMISSION_ROLLOVER_CONTAINER } from 'operations/permissions';
// import { EmailProps } from 'operations/modules/shipment/components/Tracking/ChangeEvent/EventEmailDrawer'

import { EmailProps } from 'operations/components/ChangeEvent/EventEmailDrawer';

const allocationAction = (bookingRequest: BookingRequestValue): Action => {
  return {
    type: PRIMARY_TYPE,
    key: `allocate_booking`,
    displayComponent: 'Allocate Booking',
    icon: <TagsOutlined />,
    isEnable:
      bookingRequest.load_type === LOAD_TYPE_FCL &&
      bookingRequest?.is_unfulfilled &&
      bookingRequest.status !== STATUS_CANCELLED,
    performAction: allocateOTOReportRenderer,
    description:
      bookingRequest.load_type === LOAD_TYPE_FCL
        ? `Allocate confirm booking or create a new booking`
        : 'Create new booking and allocate',
  };
};

const createCarrierBooking = (bookingRequest: BookingRequestValue): Action => {
  return {
    type: PRIMARY_TYPE,
    key: 'create_carrier_booking',
    displayComponent: 'Create Shipping Line Booking',
    icon: <CustomIcon icon="ShipIconNew" />,
    isEnable:
      bookingRequest.load_type === LOAD_TYPE_FCL &&
      bookingRequest?.is_unfulfilled &&
      bookingRequest.status !== STATUS_CANCELLED &&
      !bookingRequest.ocean_transport_orders.some(
        (order: any) => order?.booking_type === BOOKING_TYPE_SELF
      ),
    performAction: createBookingOrderFromBRRenderer,
    extraProps: { type: BOOKING_TYPE_SHIPPING_LINE },
  };
};

const createVendorBooking = (bookingRequest: BookingRequestValue): Action => {
  return {
    type: PRIMARY_TYPE,
    key: 'create_vendor_booking',
    displayComponent: 'Create Forwarder FCL Booking',
    icon: <SolutionOutlined />,
    isEnable:
      bookingRequest.load_type === LOAD_TYPE_FCL &&
      bookingRequest?.is_unfulfilled &&
      bookingRequest.status !== STATUS_CANCELLED &&
      !bookingRequest.ocean_transport_orders.some(
        (order: any) => order?.booking_type === BOOKING_TYPE_SELF
      ),
    performAction: createBookingOrderFromBRRenderer,
    extraProps: { type: BOOKING_TYPE_VENDOR },
  };
};

const createSelfBooking = (
  bookingRequest: BookingRequestValue,
  sessionData: SessionDataValue
): Action => {
  return {
    type: PRIMARY_TYPE,
    key: 'create_self_booking',
    displayComponent: 'Create CRO / Self Booking',
    icon: <SolutionOutlined />,
    isEnable:
      bookingRequest.load_type === LOAD_TYPE_FCL &&
      bookingRequest?.is_unfulfilled &&
      bookingRequest.status !== STATUS_CANCELLED &&
      sessionData?.company_account?.business_type.includes('non_vessel_owner_container_carrier'),
    performAction: createBookingOrderFromBRRenderer,
    extraProps: { type: BOOKING_TYPE_SELF },
  };
};

export const deallocateBookingOrder = (
  bookingRequest: BookingRequestValue,
  oceanTransportOrder: OceanTransportOrderValue
): Action => {
  return {
    type: NEGATIVE_TYPE,
    key: 'deallocate_booking_order',
    displayComponent: 'Deallocate Booking',
    icon: <DisconnectOutlined />,
    isEnable:
      bookingRequest.load_type === LOAD_TYPE_FCL &&
      oceanTransportOrder.booking_type !== BOOKING_TYPE_SELF &&
      !oceanTransportOrder.isAnyContainerPickedUp(bookingRequest.id),
    extraProps: { oceanTransportOrder },
    performAction: deallocateOtoRenderer,
  };
};

const createOrAllocateBooking = (
  bookingRequest: BookingRequestValue,
  sessionData: SessionDataValue
): Action => {
  return {
    type: PRIMARY_TYPE,
    key: 'create_or_allocate_booking',
    displayComponent: 'Create Or Allocate Booking',
    icon: <FileAddOutlined />,
    isEnable: true,
    childComponents: [
      allocationAction(bookingRequest),
      createCarrierBooking(bookingRequest),
      createVendorBooking(bookingRequest),
      createSelfBooking(bookingRequest, sessionData),
    ],
  };
};

const cancelOrder = (bookingRequest: BookingRequestValue): Action => {
  return {
    type: NEGATIVE_TYPE,
    key: 'cancel_booking',
    displayComponent: 'Cancel Customer Order',
    icon: <CustomIcon icon="FileTimesIcon" />,
    isEnable:
      bookingRequest?.status !== STATUS_CANCELLED &&
      !bookingRequest.isAnyContainerPickedUp &&
      bookingRequest?.shipment_ids?.length === 0,
    performAction: cancelBookingFormRenderer,
  };
};

const offloadContainers = (bookingRequest: BookingRequestValue): Action => {
  let isEnable =
    bookingRequest.load_type === LOAD_TYPE_FCL && bookingRequest?.status !== STATUS_CANCELLED;

  const containers = bookingRequest.ocean_transport_orders
    .filter((oto) => oto.is_independent)
    .map((oto) => oto.shipment_containers)
    .flat();
  if (containers.filter((container) => container.status === 'active').length === 0) {
    isEnable = false;
  }

  return {
    type: NEGATIVE_TYPE,
    key: 'offload_containers',
    displayComponent: 'Offload / Cancel',
    icon: <CustomIcon icon="FileTimesIcon" />,
    isEnable,
    performAction: offloadContainersFormRenderer,
  };
};

const duplicateOrder = (bookingRequest: BookingRequestValue, navigate: any): Action => {
  return {
    type: TERTIARY_TYPE,
    key: 'duplicate_order',
    displayComponent: 'Duplicate Customer Order',
    icon: <CopyOutlined />,
    isEnable: !!bookingRequest,
    onClick: () => {
      duplicateBookingRequest(bookingRequest, navigate);
    },
  };
};

export const createNewPickupOrder = (bookingRequest: BookingRequestValue): Action => {
  const isEnable = () => {
    if (bookingRequest?.status === STATUS_CANCELLED) return false;

    if (bookingRequest.stuffing_location_type === STUFFING_LOCATION_TYPE_FACTORY) {
      if (bookingRequest.fcl_road_transport_orders.length === 0)
        return !bookingRequest.isAnyContainerPickedUp;
      return bookingRequest.isAnyContainerNotAllocatedWithRto;
    }

    return (
      bookingRequest.isAnyCargoNotAllocatedWithRto &&
      bookingRequest.stuffing_buffer_service_orders &&
      bookingRequest.stuffing_buffer_service_orders.length > 0 &&
      bookingRequest.stuffing_buffer_service_orders[0].status !== STATUS_STUFFING_ORDER_CARTED_IN
    );
  };
  return {
    type: SECONDARY_TYPE,
    key: 'create_new_pickup_order',
    displayComponent: 'Create new Pickup Order',
    icon: <CustomIcon icon="TruckIcon" />,
    isEnable: isEnable(),
    performAction: createUpdateRoadTransportOrderRenderer,
  };
};

const addServiceForEachMisc = (bookingRequest: BookingRequestValue, service: string): Action => {
  const miscServices = bookingRequest.miscellaneous_service_orders;
  const serviceNames = miscServices.map((ms) => ms.name);
  return {
    type: SECONDARY_TYPE,
    key: service,
    displayComponent: startCase(service),
    isEnable: !serviceNames.includes(service),
    performAction: addServiceRenderer,
    extraProps: { service },
  };
};

export const editServiceForEachMisc = (
  bookingRequest: BookingRequestValue,
  miscellaneous_service_order: MiscellaneousServiceOrderValue
): Action => {
  const miscServices = bookingRequest.miscellaneous_service_orders;
  const serviceNames = miscServices.map((ms) => ms.name);
  return {
    type: SECONDARY_TYPE,
    icon: <EditOutlined />,
    key: 'edit' + miscellaneous_service_order.name,
    displayComponent: 'Edit',
    isEnable: serviceNames.includes(miscellaneous_service_order.name),
    performAction: addServiceRenderer,
    extraProps: { service: miscellaneous_service_order.name, miscellaneous_service_order },
  };
};

export const deleteServiceForEachMisc = (
  bookingRequest: BookingRequestValue,
  miscellaneous_service_order: MiscellaneousServiceOrderValue,
  client: ApolloClient<object>
): Action => {
  const miscServices = bookingRequest.miscellaneous_service_orders;
  const serviceNames = miscServices.map((ms) => ms.name);
  return {
    type: NEGATIVE_TYPE,
    key: 'delete_' + miscellaneous_service_order.name,
    icon: <DeleteOutlined />,
    displayComponent: 'Delete',
    isEnable: serviceNames.includes(miscellaneous_service_order.name),
    performAction: deleteMiscService,
    extraProps: { miscellaneous_service_order },
  };
};

export const addServices = (bookingRequest: BookingRequestValue): Action => {
  return {
    type: TERTIARY_TYPE,
    key: 'add_service',
    displayComponent: 'Add Service',
    icon: <ToolOutlined />,
    isEnable: bookingRequest?.status !== STATUS_CANCELLED,
    childComponents: [
      addServiceForEachMisc(bookingRequest, CLEARANCE_SERVICE),
      addServiceForEachMisc(bookingRequest, CARGO_INSURANCE_SERVICE),
      addServiceForEachMisc(bookingRequest, EMPTY_CONTAINER_INSURANCE_SERVICE),
    ],
  };
};

const addContainerDetailsForEachRto = (
  bookingRequest: BookingRequestValue,
  rto: RoadTransportOrderValue,
  sessionData: SessionDataValue
): Action => {
  const default_company = sessionData?.company_account?.default_company?.id;
  let transporter = '';
  if (default_company) {
    transporter = rto.getVendorCompany(default_company)?.registered_name || '';
  }
  const confirmedOTOs = bookingRequest.ocean_transport_orders.filter((oto) => oto.is_confirmed);
  return {
    type: PRIMARY_TYPE,
    key: 'add_container_details_' + rto.id,
    displayComponent: 'Add Container Details (' + transporter + ')',
    icon: <CustomIcon icon="ContainerIcon" />,
    isEnable:
      rto.isFCL &&
      rto.shipment_containers.length > 0 &&
      confirmedOTOs.length > 0 &&
      !rto.areAllContainersPickedUp,
    extraProps: { roadTransportOrder: rto },
    performAction: addContainerDetailsActionRenderer,
  };
};

const addContainerDetailsForRto = (
  bookingRequest: BookingRequestValue,
  sessionData: SessionDataValue
): Action => {
  return {
    type: PRIMARY_TYPE,
    key: 'add_container_details_for_rto',
    displayComponent: 'Add Containers Details',
    icon: <CustomIcon icon="ContainerIcon" />,
    isEnable: true,
    childComponents: bookingRequest.road_transport_orders.map((rto) =>
      addContainerDetailsForEachRto(bookingRequest, rto, sessionData)
    ),
  };
};

const addContainerDetails = (bookingRequest: BookingRequestValue): Action => {
  return {
    type: PRIMARY_TYPE,
    key: 'add_container_details',
    displayComponent: 'Add Containers Details',
    icon: <CustomIcon icon="ContainerIcon" />,
    isEnable:
      bookingRequest?.status !== STATUS_CANCELLED &&
      !bookingRequest.areAllContainersPickedUp &&
      bookingRequest.fcl_road_transport_orders.length === 0 &&
      !bookingRequest?.isFullyUnallocated,
    performAction: addContainerDetailsActionRenderer,
  };
};

const extendValidityForEachOto = (oto: OceanTransportOrderValue): Action => {
  return {
    type: SECONDARY_TYPE,
    key: oto.id,
    displayComponent: oto.booking_number || 'Extend Validity',
    isEnable: oto.load_type === LOAD_TYPE_FCL && !isEtdCrossedOto(oto) && !!oto.is_independent,
    performAction: extendValidityRenderer,
    extraProps: {
      oto: oto,
    },
  };
};

const extendValidity = (bookingRequest: BookingRequestValue): Action => {
  return {
    type: SECONDARY_TYPE,
    key: 'extend_validity',
    displayComponent: 'Extend Validity',
    icon: <CarryOutOutlined />,
    isEnable: bookingRequest?.status !== STATUS_CANCELLED,
    childComponents: bookingRequest.ocean_transport_orders.map((oto) =>
      extendValidityForEachOto(oto)
    ),
  };
};

const addPickupDetailsForEachRto = (
  rto: RoadTransportOrderValue,
  sessionData: SessionDataValue
): Action => {
  const default_company = sessionData?.company_account?.default_company?.id;
  let transporter = '';
  if (default_company) {
    transporter = rto.getVendorCompany(default_company)?.registered_name || '';
  }
  return {
    type: PRIMARY_TYPE,
    key: rto.id,
    icon: <CustomIcon icon="TruckIcon" />,
    displayComponent: 'Add PickUp Details (' + transporter + ')',
    isEnable: !rto.isFCL && rto.looseRTOStatus === 'Pickup Pending',
    performAction: addPickupDetailsRenderer,
    extraProps: {
      roadTransportOrder: rto,
    },
  };
};

const updateRoadTrasportOrder = (rto: RoadTransportOrderValue): Action => {
  return {
    type: SECONDARY_TYPE,
    key: 'update_' + rto.id,
    displayComponent: 'Update Road Transport Order',
    icon: <EditOutlined />,
    isEnable: rto.isFCL
      ? !rto.areAllContainersPickedUp
      : ['Picked up', 'Pickup Pending'].includes(rto.looseRTOStatus),
    extraProps: { roadTransportOrder: rto },
    performAction: createUpdateRoadTransportOrderRenderer,
  };
};

const deleteRoadTrasportOrder = (rto: RoadTransportOrderValue): Action => {
  return {
    type: NEGATIVE_TYPE,
    key: 'delete_' + rto.id,
    displayComponent: 'Delete Road Tranport Order',
    icon: <DeleteOutlined />,
    performAction: deleteRoadTransportOrderRenderer,
    extraProps: { roadTransportOrder: rto },
    isEnable: rto.isFCL ? !rto.isAnyContainerPickedUp : rto.looseRTOStatus === 'Pickup Pending',
  };
};

const addPickupDetails = (
  bookingRequest: BookingRequestValue,
  sessionData: SessionDataValue
): Action => {
  const isEnable = () => {
    if (bookingRequest?.status === STATUS_CANCELLED) return false;
    if (bookingRequest?.road_transport_orders.length === 0) return false;
    if (bookingRequest.isFCL && bookingRequest?.areAllContainersPickedUp) return false;
    return true;
  };
  return {
    type: PRIMARY_TYPE,
    key: 'add_pickup_details',
    displayComponent: 'Add PickUp Details',
    icon: <CustomIcon icon="TruckIcon" />,
    isEnable: isEnable(),
    childComponents: bookingRequest.road_transport_orders.map((rto) =>
      addPickupDetailsForEachRto(rto, sessionData)
    ),
  };
};

const addCartingDetailsForEachRto = (
  rto: RoadTransportOrderValue,
  sessionData: SessionDataValue
): Action => {
  const default_company = sessionData?.company_account?.default_company?.id;
  let transporter = '';
  if (default_company) {
    transporter = rto.getVendorCompany(default_company)?.registered_name || '';
  }
  return {
    type: PRIMARY_TYPE,
    key: rto.id,
    icon: <CustomIcon icon="CartingIcon" />,
    displayComponent: 'Add Carting Details (' + transporter + ')',
    isEnable: !rto.isFCL && rto.looseRTOStatus === 'Picked up',
    performAction: addCartingDetailsRenderer,
    extraProps: {
      roadTransportOrder: rto,
    },
  };
};

const addCartingDetails = (
  bookingRequest: BookingRequestValue,
  sessionData: SessionDataValue
): Action => {
  const isEnable = () => {
    if (bookingRequest?.status === STATUS_CANCELLED) return false;
    if (bookingRequest?.road_transport_orders.length === 0) return false;
    if (bookingRequest.isFCL && bookingRequest?.areAllContainersPickedUp) return false;
    return true;
  };
  return {
    type: PRIMARY_TYPE,
    key: 'add_carting_details',
    displayComponent: 'Add Carting Details',
    icon: <CustomIcon icon="CartingIcon" />,
    isEnable: isEnable(),
    childComponents: bookingRequest.road_transport_orders.map((rto) =>
      addCartingDetailsForEachRto(rto, sessionData)
    ),
  };
};

const createCancelledShipment = (bookingRequest: BookingRequestValue): Action => {
  return {
    type: NEGATIVE_TYPE,
    key: 'create_canelled_shipment',
    displayComponent: 'Create Cancelled Shipment',
    icon: <CustomIcon icon="FileTimesIcon" />,
    isEnable:
      bookingRequest?.shipment_ids?.length === 0 && bookingRequest?.status === STATUS_CANCELLED,
    performAction: createCancelledShipmentRenderer,
  };
};

const notifyOnEmail = (
  bookingRequest: BookingRequestValue,
  setEmailProps: (emailProps: EmailProps) => void,
  setVisible: (visible: boolean) => void,
  sessionData: SessionDataValue
): Action => {
  return {
    type: TERTIARY_TYPE,
    key: 'notify_on_email',
    displayComponent: 'Notify On Email',
    icon: <MailOutlined />,
    isEnable: true,
    performAction: notifyOnEmailRenderer,
  };
};

const notifyOnWhatsApp = (
  bookingRequest: BookingRequestValue,
  setEmailProps: (emailProps: EmailProps) => void,
  setVisible: (visible: boolean) => void,
  sessionData: SessionDataValue
): Action => {
  const default_company = sessionData?.company_account?.default_company;
  return {
    type: TERTIARY_TYPE,
    key: 'notify_on_wa',
    displayComponent: 'Notify On WhatsApp',
    icon: <WhatsAppOutlined />,
    isEnable: true,
    onClick: () => {
      setEmailProps({
        title: 'Send WhatsApp Notification',
        docTypeId: 'Shipment::BookingRequest',
        resource_ids: [bookingRequest.id],
        fetchDocumentParents: [
          {
            parent_type: BOOKING_TYPE_BOOKING_REQUEST,
            parent_ids: [bookingRequest.id],
          },
          {
            parent_type: BOOKING_TYPE_OCEAN_TRANSPORT_ORDER,
            parent_ids: bookingRequest.ocean_transport_orders.map((oto) => oto.id),
          },
        ],
        companies_roles_mapping: default_company
          ? bookingRequest.getAllCompaniesRolesMapping(default_company)
          : [],
        showMarkDown: true,
        showEmail: false,
      });
      setVisible(true);
    },
  };
};

const notifyOnEmailForEachRto = (
  bookingRequest: BookingRequestValue,
  rto: RoadTransportOrderValue,
  setEmailProps: (emailProps: EmailProps) => void,
  setVisible: (visible: boolean) => void,
  sessionData: SessionDataValue
): Action => {
  const default_company = sessionData?.company_account?.default_company;
  return {
    type: TERTIARY_TYPE,
    key: 'notify_on_email_rto',
    displayComponent: 'Notify On Email',
    icon: <MailOutlined />,
    isEnable: true, // general shipment ??
    onClick: () => {
      setEmailProps({
        title: 'Send Email Notification',
        docTypeId: 'Shipment::RoadTransportOrder',
        resource_ids: [rto.id],
        companies_roles_mapping: default_company
          ? bookingRequest.getAllCompaniesRolesMapping(default_company)
          : [],
      });
      setVisible(true);
    },
  };
};

const notifyOnWhatsAppForEachRto = (
  bookingRequest: BookingRequestValue,
  rto: RoadTransportOrderValue,
  setEmailProps: (emailProps: EmailProps) => void,
  setVisible: (visible: boolean) => void,
  sessionData: SessionDataValue
): Action => {
  const default_company = sessionData?.company_account?.default_company;
  return {
    type: TERTIARY_TYPE,
    key: 'notify_on_wa_rto',
    displayComponent: 'Notify On WhatsApp',
    icon: <WhatsAppOutlined />,
    isEnable: true, // general shipment ??
    onClick: () => {
      setEmailProps({
        title: 'Send WhatsApp Notification',
        docTypeId: 'Shipment::RoadTransportOrder',
        resource_ids: [rto.id],
        companies_roles_mapping: default_company
          ? bookingRequest.getAllCompaniesRolesMapping(default_company)
          : [],
        showMarkDown: true,
        showEmail: false,
      });
      setVisible(true);
    },
  };
};

const addCartingDetailsForEachStuffing = (bookingRequest: BookingRequestValue): Action => {
  const so = bookingRequest.stuffing_buffer_service_orders[0];
  return {
    type: PRIMARY_TYPE,
    key: 'add_carting_details_for_each_stuffing',
    displayComponent: 'Add Carting Details',
    icon: <CustomIcon icon="CartingIcon" />,
    isEnable:
      !!so &&
      so.status === STATUS_STUFFING_ORDER_NOT_YET_CARTED_IN &&
      bookingRequest?.road_transport_orders?.length === 0 &&
      !(bookingRequest.shipment_ids?.length !== 0 || bookingRequest?.status === STATUS_CANCELLED),
    performAction: addCartingDetailsRenderer,
    extraProps: { stuffingBufferServiceOrder: so },
  };
};

export const updateRoutingDetails = (
  bookingRequest: BookingRequestValue,
  permissions: PermissionValue[]
): Action => {
  const isSelf_Vendor = bookingRequest?.ocean_transport_orders?.some((e) => {
    return e.booking_type === BOOKING_TYPE_SELF || e.booking_type === BOOKING_TYPE_VENDOR;
  });

  const isupdateOrSplit = bookingRequest?.isAnyContainerPickedUp;
  const has_oto =
    bookingRequest?.ocean_transport_orders && bookingRequest?.ocean_transport_orders.length > 0;
  return {
    type: SECONDARY_TYPE,
    key: 'update_routing_details',
    displayComponent: 'Update Routing Details',
    icon: <CustomIcon icon="EditRoadIcon" />,
    isEnable:
      hasPermission(permissions, {
        name: PERMISSION_ROLLOVER_CONTAINER,
        docType: 'Shipment::ShipmentContainer',
      }) &&
      has_oto &&
      !isSelf_Vendor &&
      bookingRequest.status !== STATUS_CANCELLED,
    performAction: isupdateOrSplit ? updateOrSplitRollOverRenderer : updateRoutingDetailsRenderer,
  };
};

const notifyOnEmailForStuffing = (
  bookingRequest: BookingRequestValue,
  setEmailProps: (emailProps: EmailProps) => void,
  setVisible: (visible: boolean) => void,
  sessionData: SessionDataValue
): Action => {
  const default_company = sessionData?.company_account?.default_company;
  const so = bookingRequest.stuffing_buffer_service_orders[0];
  return {
    type: TERTIARY_TYPE,
    key: 'notify_on_email_rto',
    displayComponent: 'Notify On Email',
    icon: <MailOutlined />,
    isEnable: !(
      so.status === STATUS_STUFFING_ORDER_NOT_YET_CARTED_IN &&
      !(bookingRequest.shipment_ids?.length !== 0 || bookingRequest?.status === STATUS_CANCELLED)
    ),
    onClick: () => {
      setEmailProps({
        title: 'Send Email Notification',
        action_name: 'stuffing_based_carting_confirmation',
        resource_ids: [so.id],
        companies_roles_mapping: default_company
          ? bookingRequest.getAllCompaniesRolesMapping(default_company)
          : [],
      });
      setVisible(true);
    },
  };
};

const sendSiBlDraftReminder = (
  bookingRequest: BookingRequestValue,
  setEmailProps: (emailProps: EmailProps) => void,
  setVisible: (visible: boolean) => void,
  sessionData: SessionDataValue
): Action => {
  const default_company = sessionData?.company_account?.default_company;
  let isEnable;
  if (bookingRequest.isFCL) {
    isEnable = bookingRequest.shipmentContainers.some(
      (container) => !container.is_linked_with_shipments
    );
  } else {
    isEnable = (bookingRequest.shipment_ids || []).length === 0;
  }
  return {
    type: TERTIARY_TYPE,
    key: 'send_si_bl_reminder',
    displayComponent: 'Send SI/BL Reminder',
    icon: <MailOutlined />,
    isEnable: isEnable, // ToDo
    onClick: () => {
      setEmailProps({
        title: 'Send Si/BL Reminder',
        action_name: 'si_bl_reminder',
        resource_ids: [bookingRequest.id],
        companies_roles_mapping: default_company
          ? bookingRequest.getAllCompaniesRolesMapping(default_company)
          : [],
      });
      setVisible(true);
    },
  };
};

const notifyOnWhatsAppStuffing = (
  bookingRequest: BookingRequestValue,
  setEmailProps: (emailProps: EmailProps) => void,
  setVisible: (visible: boolean) => void,
  sessionData: SessionDataValue
): Action => {
  const default_company = sessionData?.company_account?.default_company;
  const so = bookingRequest.stuffing_buffer_service_orders[0];
  return {
    type: TERTIARY_TYPE,
    key: 'notify_on_wa_rto',
    displayComponent: 'Notify On WhatsApp',
    icon: <WhatsAppOutlined />,
    isEnable: !(
      so.status === STATUS_STUFFING_ORDER_NOT_YET_CARTED_IN &&
      !(bookingRequest.shipment_ids?.length !== 0 || bookingRequest?.status === STATUS_CANCELLED)
    ),
    onClick: () => {
      setEmailProps({
        title: 'Send WhatsApp Notification',
        action_name: 'stuffing_based_carting_confirmation',
        resource_ids: [so.id],
        companies_roles_mapping: default_company
          ? bookingRequest.getAllCompaniesRolesMapping(default_company)
          : [],
        showMarkDown: true,
        showEmail: false,
      });
      setVisible(true);
    },
  };
};

const addCreateShipmentActions = (
  bookingRequest: BookingRequestValue,
  shipmentType: ShipmentType
): Action => {
  return {
    type: PRIMARY_TYPE,
    key: shipmentType,
    displayComponent: startCase(shipmentType),
    isEnable: true,
    performAction: addCreateShipmentRenderer,
    extraProps: { shipmentType },
  };
};

// const addCreateShipmentActionsPo = (
//   bookingRequest: BookingRequestValue,
//   shipmentType: any
// ): Action => {
//   return {
//     type: PRIMARY_TYPE,
//     key: shipmentType,
//     displayComponent: startCase(shipmentType),
//     isEnable: true,
//     performAction: addCreateShipmentRendererPo,
//     extraProps: { shipmentType },
//   };
// };

const createShipmentFromBR = (bookingRequest: BookingRequestValue): Action => {
  let renderCreateShipmentButton = false;
  if (bookingRequest?.load_type === LOAD_TYPE_FCL) {
    Array.isArray(bookingRequest?.shipmentContainers) &&
      bookingRequest?.shipmentContainers.forEach((sc) => {
        if (sc?.status === 'active' && sc?.container_cargo_details?.length === 0) {
          renderCreateShipmentButton = true;
        }
      });
  } else {
    if (bookingRequest?.shipments?.length === 0) renderCreateShipmentButton = true;
  }

  return {
    type: PRIMARY_TYPE,
    key: 'create_shipment',
    displayComponent: 'Create Shipment',
    icon: <CompassOutlined />,
    isEnable: renderCreateShipmentButton,
    childComponents: [
      addCreateShipmentActions(bookingRequest, SHIPMENT_TYPE_DIRECT),
      // addCreateShipmentActionsPo(bookingRequest, `From SO or PO`),
      addCreateShipmentActions(bookingRequest, SHIPMENT_TYPE_BACK_TO_BACK),
      addCreateShipmentActions(bookingRequest, SHIPMENT_TYPE_HOUSE),
    ],
  };
};

const markDGIndexing = (bookingRequest: BookingRequestValue): Action => {
  return {
    type: PRIMARY_TYPE,
    key: 'mark_dg_indexing',
    displayComponent: 'DG Indexing Done',
    icon: <CustomIcon icon="FileTimesIcon" />,
    isEnable:
      bookingRequest.isHazardous &&
      !bookingRequest.isFullyUnallocated &&
      bookingRequest?.status !== STATUS_CANCELLED &&
      !bookingRequest.ocean_transport_orders[0]?.dg_indexing_date,
    performAction: markDGIndexingRenderer,
  };
};

export const addCollaborators = (bookingRequest: BookingRequestValue): Action => {
  return {
    type: PRIMARY_TYPE,
    key: 'add_collaborators',
    icon: <ShareAltOutlined />,
    isEnable: true,
    displayComponent: 'Add Collaborators',
    performAction: addCollaboratorsRenderer,
  };
};

export const getBookigRequestActions = (
  bookingRequest: BookingRequestValue,
  navigate: any,
  sessionData: SessionDataValue,
  setEmailProps: (emailProps: EmailProps) => void,
  setVisible: (visible: boolean) => void
) => {
  bookingRequest = BookingRequest.create(bookingRequest, { sessionData });
  const permissions = sessionData.permissions;
  return [
    createShipmentFromBR(bookingRequest),
    createOrAllocateBooking(bookingRequest, sessionData),
    addContainerDetailsForRto(bookingRequest, sessionData),
    addContainerDetails(bookingRequest),
    cancelOrder(bookingRequest),
    offloadContainers(bookingRequest),
    duplicateOrder(bookingRequest, navigate),
    createNewPickupOrder(bookingRequest),
    addServices(bookingRequest),
    addCartingDetails(bookingRequest, sessionData),
    addCartingDetailsForEachStuffing(bookingRequest),
    addPickupDetails(bookingRequest, sessionData),
    extendValidity(bookingRequest),
    createCancelledShipment(bookingRequest),
    markDGIndexing(bookingRequest),
    notifyOnEmail(bookingRequest, setEmailProps, setVisible, sessionData),
    notifyOnWhatsApp(bookingRequest, setEmailProps, setVisible, sessionData),
    // FIX ME
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    updateRoutingDetails(bookingRequest, permissions),
    sendSiBlDraftReminder(bookingRequest, setEmailProps, setVisible, sessionData),
    addCollaborators(bookingRequest),
  ];
};

export const getAllocationAction = (
  bookingRequest: BookingRequestValue,
  sessionData: SessionDataValue
) => {
  return [createOrAllocateBooking(bookingRequest, sessionData)];
};

export const getRtoAction = (
  bookingRequest: BookingRequestValue,
  rto: RoadTransportOrderValue,
  setEmailProps: (emailProps: EmailProps) => void,
  setVisible: (visible: boolean) => void,
  sessionData: SessionDataValue
): Action[] => {
  return [
    addPickupDetailsForEachRto(rto, sessionData),
    addCartingDetailsForEachRto(rto, sessionData),
    updateRoadTrasportOrder(rto),
    deleteRoadTrasportOrder(rto),
    addContainerDetailsForEachRto(bookingRequest, rto, sessionData),
    notifyOnEmailForEachRto(bookingRequest, rto, setEmailProps, setVisible, sessionData),
    notifyOnWhatsAppForEachRto(bookingRequest, rto, setEmailProps, setVisible, sessionData),
  ];
};

export const getStuffingAction = (
  bookingRequest: BookingRequestValue,
  setEmailProps: (emailProps: EmailProps) => void,
  setVisible: (visible: boolean) => void,
  sessionData: SessionDataValue
) => {
  return [
    addCartingDetailsForEachStuffing(bookingRequest),
    notifyOnEmailForStuffing(bookingRequest, setEmailProps, setVisible, sessionData),
    notifyOnWhatsAppStuffing(bookingRequest, setEmailProps, setVisible, sessionData),
  ];
};
