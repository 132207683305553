import { Row, Form, Input, Col, Select } from '@shipmnts/pixel-hub';
import React, { useEffect, useState } from 'react';
import { FormInstance } from '@shipmnts/pixel-hub';
import { TemplateValue } from 'network/models/Template';
import { DOC_TYPE_METHODS, RichTextEditorWrapper } from 'common';
import { DocFormRenderer } from '@shipmnts/pixel-hub';

interface TemplateContentProps {
  form: FormInstance;
  template?: TemplateValue;
  disable?: boolean;
  onCreateDisableType?: boolean;
}

const { TextArea } = Input;

export default function TemplateContent(props: TemplateContentProps) {
  const { form, template, disable, onCreateDisableType = false } = props;
  let resourceTemplateTypeDisable = disable || onCreateDisableType;

  if (template?.id) {
    resourceTemplateTypeDisable = true;
  }
  const [content, setContent] = useState<string | undefined>(
    template?.content ? template?.content : '<!DOCTYPE html><html lang="en"><body></body></html>'
  );

  let resourceTemplateTypeMapping: any = [];
  const resourceType: any = [];
  // eslint-disable-next-line array-callback-return
  Object.keys(DOC_TYPE_METHODS).map((element: any) => {
    if (DOC_TYPE_METHODS[element]?.template_types) {
      resourceType.push({
        value: element,
        label: DOC_TYPE_METHODS[element]?.display_name,
      });

      resourceTemplateTypeMapping = {
        ...resourceTemplateTypeMapping,
        [element || '']: DOC_TYPE_METHODS[element]?.template_types,
      };
    }
  });

  useEffect(() => {
    if (template?.content) {
      setContent(template?.content);
    }
  }, [template?.content]);

  useEffect(() => {
    if (content)
      form.setFieldsValue({
        content: content,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  return (
    <Row gutter={32}>
      <Col span={8} xs={{ span: 16 }} sm={{ span: 8 }} md={{ span: 8 }} lg={{ span: 8 }}>
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input disabled={disable} />
        </Form.Item>
      </Col>
      <Col span={8} xs={{ span: 16 }} sm={{ span: 8 }} md={{ span: 8 }} lg={{ span: 8 }}>
        <Form.Item name="resource_type" label="Resource Type" rules={[{ required: true }]}>
          <Select
            showSearch
            disabled={resourceTemplateTypeDisable}
            filterOption={(input, option) =>
              (option?.label?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={resourceType}
          />
        </Form.Item>
      </Col>
      <Col span={8} xs={{ span: 16 }} sm={{ span: 8 }} md={{ span: 8 }} lg={{ span: 8 }}>
        <Form.Item noStyle shouldUpdate={true}>
          {({ getFieldValue }) => {
            const resourceType = getFieldValue('resource_type');
            return (
              <Form.Item name="template_type" label="Template Type" rules={[{ required: true }]}>
                <Select
                  showSearch
                  disabled={resourceTemplateTypeDisable}
                  filterOption={(input, option) =>
                    (option?.label?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={resourceTemplateTypeMapping[resourceType]}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name="content" label="Content" rules={[{ required: true }]}>
          <Row>
            <Col span={24}>
              {['letter_head', 'print'].includes(form.getFieldValue('template_type')) ? (
                <TextArea
                  disabled={disable}
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                  rows={14}
                />
              ) : (
                <RichTextEditorWrapper
                  height={'200px'}
                  disabled={disable}
                  toolbarOptions={{
                    container: [
                      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                      [{ list: 'ordered' }, { list: 'bullet' }],
                      [{ color: [] }, { background: [] }],
                      ['emoji'],
                      [{ indent: '-1' }, { indent: '+1' }],
                    ],
                  }}
                  value={content}
                  onChange={(content: string) => setContent(content)}
                />
              )}
            </Col>
          </Row>
        </Form.Item>
      </Col>
      <Col span={24}>
        {template?.print_option && <DocFormRenderer docForm={template.print_option} />}
      </Col>
    </Row>
  );
}
