import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { Input, Modal, Spin, Form } from '@shipmnts/pixel-hub';
import { FormInstance } from '@shipmnts/pixel-hub';
import { CarrierValue } from 'operations/models/Carrier';
import {
  DOCUMENT_TYPE_MASTER,
  DocumentType,
  FREIGHT_TYPE_OCEAN,
} from 'operations/modules/shipment/constants';
import { errorMessageHandlerGraphQL } from 'common';

import { useLocation } from 'wouter';
import { FREIGHT_TYPE_AIR } from 'operations/utils/constants';

const SEARCH_SHIPMENT_BY_SHIPMENT_NUMBER = gql`
  query search_shipment_by_shipment_number($shipment_number: String!, $carrier_id: ID!) {
    search_shipment_by_shipment_number(shipment_number: $shipment_number, carrier_id: $carrier_id) {
      id
      job_number
    }
  }
`;

export function generateCheckSumDigit(mawbNumber: any) {
  return mawbNumber.substr(3, 7) % 7;
}

export function validateMAWB(str: string) {
  if (!str) throw new Error('value not provided');
  if (typeof str !== 'string' && typeof str !== 'number')
    throw new Error('should only contain numbers');
  const strval = str.toString();
  const numberRegex = new RegExp('^[0-9]+$');
  if (!numberRegex.test(str)) throw new Error('should only contain numbers');
  if (strval.length !== 11) throw new Error('should be 11 digits');
  const lst = strval.substr(10, 1);
  if (generateCheckSumDigit(strval) === parseFloat(lst)) {
    return true;
  } else {
    throw new Error("The check digit doesn't match");
  }
}
interface ShipmentNumberProps {
  carrier?: CarrierValue;
  label: string;
  form: FormInstance;
  documentType: DocumentType;
  freightType?: string;
}

const ShipmentNumber = React.memo(function ShipmentNumber(props: ShipmentNumberProps): JSX.Element {
  const { carrier, documentType, label, form, freightType = FREIGHT_TYPE_OCEAN } = props;
  const [isDuplicateModalVisible, setDuplicateModalVisible] = useState<boolean>(false);
  const [searchShipment, { loading, data, error }] = useLazyQuery(
    SEARCH_SHIPMENT_BY_SHIPMENT_NUMBER
  );
  const { 1: navigate } = useLocation();

  useEffect(() => {
    if (data && data.search_shipment_by_shipment_number?.length > 0) {
      setDuplicateModalVisible(true);
    }
  }, [data]);

  const checkShipmentPresence = useCallback(
    (shipment_number?: string) => {
      if (!carrier || !shipment_number) return;

      const variables = {
        shipment_number,
        carrier_id: carrier.id,
      };
      searchShipment({ variables });
    },
    [carrier, searchShipment]
  );

  const retryCheckShipmentPresence = useCallback(() => {
    const field_name = [documentType, 'shipment_document', 'shipment_number'];
    checkShipmentPresence(form.getFieldValue(field_name));
  }, [checkShipmentPresence, form, documentType]);

  const retryHelp = useMemo(
    () =>
      error
        ? {
            help: errorMessageHandlerGraphQL(error, retryCheckShipmentPresence),
          }
        : {},
    [error, retryCheckShipmentPresence]
  );

  const openJob = useCallback(
    (shipment_id: string) => {
      setDuplicateModalVisible(false);
      const formValues = {
        [documentType]: { shipment_document: { shipment_number: undefined } },
      };
      form.setFieldsValue(formValues);
      navigate(`~/view/shipment/${shipment_id}/documents`);
    },
    [documentType, form, navigate]
  );

  return (
    <>
      <Spin spinning={loading}>
        <Form.Item
          name={[documentType, 'shipment_document', 'shipment_number']}
          label={label}
          {...retryHelp}
          rules={[
            {
              validator: async (_, value) => {
                if (
                  freightType === FREIGHT_TYPE_AIR &&
                  documentType === DOCUMENT_TYPE_MASTER &&
                  carrier?.iata_awb_prefix &&
                  value
                ) {
                  validateMAWB(`${carrier?.iata_awb_prefix}${value}`);
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          {freightType === FREIGHT_TYPE_AIR && documentType === DOCUMENT_TYPE_MASTER ? (
            <Input
              // onBlur={(e) => {
              //   const originalValue = e.target.value.trim();
              //   const prefix = carrier?.iata_awb_prefix || '';
              //   const finalValue = `${prefix}${originalValue}`;

              //   // Assuming `form` is the instance of your Ant Design form
              //   form.setFieldsValue({
              //     [documentType]: {
              //       shipment_document: {
              //         shipment_number: finalValue,
              //       },
              //     },
              //   });

              //   checkShipmentPresence(finalValue); // Call your function with the modified value
              // }}
              addonBefore={carrier?.iata_awb_prefix}
              disabled={!carrier?.iata_awb_prefix}
            />
          ) : (
            <Input onBlur={(e) => checkShipmentPresence(e.target.value.trim())} />
          )}
        </Form.Item>
      </Spin>
      <Modal
        title="Duplicate Shipment(s) Found"
        open={isDuplicateModalVisible}
        maskClosable={true}
        closable={true}
        footer={null}
        onCancel={() => setDuplicateModalVisible(false)}
      >
        <p>We found shipment(s) with this Shipment Number for {carrier?.name}</p>
        <br />
        <p>Go to that Shipment</p>
        <ul>
          {(data?.search_shipment_by_shipment_number || []).map(
            (shipment: { id: string; job_number: string }, index: string) => {
              return (
                <li key={index}>
                  <span onClick={() => openJob(shipment.id)}>
                    <u style={{ cursor: 'pointer' }}>{shipment?.job_number}</u>
                  </span>
                </li>
              );
            }
          )}
        </ul>
      </Modal>
    </>
  );
});

export default ShipmentNumber;
