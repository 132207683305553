import React from 'react';
import { Column, FilterType, FilterConditionType } from 'common/models/Report';
import {
  findKey as _findKey,
  padStart as _padStart,
  get as _get,
  isEmpty as _isEmpty,
  // startCase as _startCase,
} from 'lodash';
import { useLocalStorage } from './hooks';
import { GridApi, ColDef } from '@ag-grid-community/core';
import { roundToTwoDecimals } from 'common/helpers/parseNumberToLocale';
// import { STATUS_EXPIRED, STATUS_EXPIRING_SOON } from 'operations/modules/reports/constants';
import { ExcelStyle } from '@ag-grid-community/core';
import { RangeValueType } from 'rc-picker/lib/PickerInput/RangePicker';
import {
  getDateTimeFromUnix,
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_TIME_FORMAT,
  dayjs,
  Dayjs,
  isDayjs,
} from '@shipmnts/pixel-hub';

export const renderDate = (
  date: Dayjs | number | undefined | null,
  returnStringFormat: string | undefined
) => {
  // date is unix timestmap and respinse is in returnStringFormat
  if (!date) return '';
  const returnFormat = returnStringFormat || DEFAULT_DATE_TIME_FORMAT;
  if (isDayjs(date)) return date.format(returnFormat);
  else {
    const dayjsObj = dayjs.unix(date);
    return dayjsObj.format(returnFormat);
  }
};

const getFinalDate = (date?: string) => {
  if (!date) return '';
  let finalDate = date;
  if (finalDate.length !== 10) {
    const year = finalDate.substr(0, finalDate.indexOf('-'));
    finalDate = _padStart(year, 4, '0') + date.substr(finalDate.indexOf('-'));
  }
  return finalDate;
};

export const calculateCondition = (fieldFilterModel: any, idKey: string): FilterConditionType => {
  const { type, filterType, filter, dateFrom, dateTo, values } = fieldFilterModel;
  const dateFromFinal = getFinalDate(dateFrom),
    dateToFinal = getFinalDate(dateTo);
  return {
    filter_type: filterType,
    type: type === 'inRange' ? 'dateRange' : filterType === 'set' ? 'in' : type,
    ...(filterType === 'date'
      ? type === 'inRange'
        ? {
            values: [
              dayjs(dateFromFinal, 'YYYY-MM-DD').unix().toString(),
              dayjs(dateToFinal, 'YYYY-MM-DD').endOf('day').unix().toString() ??
                dayjs(dateFromFinal, 'YYYY-MM-DD').endOf('day').unix().toString(),
            ],
          }
        : {
            value: dayjs(dateFromFinal, 'YYYY-MM-DD').unix().toString(),
          }
      : filterType === 'set'
      ? {
          values:
            values && typeof values[0] === 'object'
              ? values.map((val: any) => val[idKey].toString())
              : values,
        }
      : { value: filter }),
  };
};

export const makeGraphqlQueryFilters = ({
  filterModel,
  columnDefs,
}: {
  filterModel: any;
  columnDefs: Column[];
}) => {
  const columnDefsByField = columnDefs.reduce((acc: { [key: string]: Column }, cur: Column) => {
    if (cur.colId) acc[cur.colId] = cur;
    return acc;
  }, {});
  return Object.keys(filterModel)
    .map((colId: string) => {
      const columnDef = columnDefsByField[colId];
      return {
        col: columnDef?.filterKey as string,
        condition: calculateCondition(
          filterModel[colId],
          columnDef?.filterParams?.returnValueKey ||
            columnDef?.floatingFilterComponentParams?.returnValueKey ||
            'id'
        ),
      };
    })
    .filter(
      (filterObj: FilterType) =>
        !(filterObj.condition.filter_type === 'set' && filterObj.condition?.values?.length === 0) &&
        Boolean(filterObj.col)
    );
};

export function getColumnFiltersinGraphqlFormat<T>(props: {
  columnFilterValues: {
    [key: string]: T | Array<T> | string;
  };
  reportFields?: Record<string, Column>;
  returnKey?: string;
}) {
  const { columnFilterValues, reportFields, returnKey } = props;
  return Object.keys(columnFilterValues).reduce((filters: FilterType[], key: string) => {
    let return_val_key = returnKey || 'id';

    if (reportFields) {
      if (columnFilterValues[key] && !_isEmpty(columnFilterValues[key])) {
        const field_key = _findKey(reportFields, function (o) {
          return o.filterKey === key;
        });
        return_val_key =
          (field_key && reportFields[field_key]?.floatingFilterComponentParams?.returnValueKey) ||
          'id';
      }
    }
    const filterValue = columnFilterValues[key];
    if (Array.isArray(filterValue)) {
      if (dayjs.isDayjs(filterValue?.[0])) {
        filters.push({
          col: key,
          condition: {
            filter_type: 'date',
            type: 'dateRange',
            values: filterValue.reduce((acc: Array<string>, vl: any) => {
              return [...acc, dayjs(vl).unix().toString()];
            }, []),
          },
        });
      } else {
        filters.push({
          col: key,
          condition: {
            filter_type: 'text',
            type: 'in',
            values: filterValue.reduce((acc: Array<string>, vl: any) => {
              if (typeof vl === 'string') return [...acc, vl];
              return [...acc, vl[return_val_key]?.toString() || ''];
            }, []),
          },
        });
      }
    } else if (typeof filterValue === 'string') {
      filters = filters.concat(
        getInputFilterInGraphqlFormat({
          inputFilters: [{ filterKey: key, type: 'contains', value: filterValue }],
        })
      );
    } else if (typeof filterValue === 'object') {
      filters.push({
        col: key,
        condition: {
          filter_type: 'text',
          type: 'equals',
          value: _get(filterValue, return_val_key)?.toString(),
        },
      });
    }
    return filters;
  }, []);
}

export interface InputFilter {
  filterKey: string;
  type: 'equals' | 'contains';
  value?: string | undefined;
}

export const getInputFilterInGraphqlFormat = (props: { inputFilters: InputFilter[] }) => {
  return props.inputFilters.reduce((filters: FilterType[], inputFilter: InputFilter) => {
    if (inputFilter.value && inputFilter.value !== '') {
      filters.push({
        col: inputFilter.filterKey,
        condition: {
          filter_type: 'text',
          type: inputFilter.type,
          value: inputFilter.value,
        },
      });
    }

    return filters;
  }, []);
};

export interface DateFilter {
  filterKey: string;
  type: 'equals' | 'dateRange';
  values?: RangeValueType<Dayjs>;
  value?: Dayjs;
}

export const getDateFiltersInGraphqlFormat = (props: { dateFilters: DateFilter[] }) => {
  return props.dateFilters.reduce((filters: FilterType[], date: DateFilter) => {
    if (date.type === 'equals' && date.value) {
      filters.push({
        col: date.filterKey,
        condition: {
          filter_type: 'date',
          type: date.type,
          value: dayjs(date.value).unix().toString(),
        },
      });
    } else if (date.type === 'dateRange' && date.values && date.values[0] && date.values[1]) {
      filters.push({
        col: date.filterKey,
        condition: {
          filter_type: 'date',
          type: date.type,
          values: [
            dayjs(date.values[0]).unix().toString(),
            dayjs(date.values[1]).endOf('day').unix().toString(),
          ],
        },
      });
    }
    return filters;
  }, []);
};

export function useReportCache(name: string) {
  return useLocalStorage(`report_${name}`, {});
}

export const clientSideDateFilter = (filterLocalDateAtMidnight: any, cellValue?: number | null) => {
  if (!cellValue) {
    return -1;
  }

  const filterDate = dayjs(filterLocalDateAtMidnight);
  const cellDate = dayjs(cellValue, DEFAULT_DATE_FORMAT);

  if (cellDate.isBefore(filterDate)) {
    return -1;
  } else if (cellDate.isAfter(filterDate)) {
    return 1;
  } else {
    return 0;
  }
};

export const clientSideDateSort = (valueA?: string, valueB?: string, sortDateFormat?: string) => {
  if (!valueA) return -1;
  if (!valueB) return 1;
  const firstDate = dayjs(valueA, sortDateFormat || DEFAULT_DATE_FORMAT);
  const secondDate = dayjs(valueB, sortDateFormat || DEFAULT_DATE_FORMAT);
  if (firstDate.isAfter(secondDate)) {
    return 1;
  } else if (firstDate.isBefore(secondDate)) {
    return -1;
  } else {
    return 0;
  }
};

export const exportExcelStyles: ExcelStyle[] = [
  {
    id: 'header',
    interior: {
      color: '#CCCCCC',
      pattern: 'Solid',
    },
    borders: {
      borderBottom: {
        color: '#5687f5',
        lineStyle: 'Continuous',
        weight: 1,
      },
      borderLeft: {
        color: '#5687f5',
        lineStyle: 'Continuous',
        weight: 1,
      },
      borderRight: {
        color: '#5687f5',
        lineStyle: 'Continuous',
        weight: 1,
      },
      borderTop: {
        color: '#5687f5',
        lineStyle: 'Continuous',
        weight: 1,
      },
    },
  },
  {
    id: 'aggrid-yellow-cell',
    interior: {
      color: '#ffe3b8',
      pattern: 'Solid',
    },
    font: {
      color: '#996E2F',
    },
  },
  {
    id: 'aggrid-green-cell',
    interior: {
      color: '#9acbb5',
      pattern: 'Solid',
    },
    font: {
      color: '#014C2A',
    },
  },
  {
    id: 'aggrid-red-cell',
    interior: {
      color: '#e7b0ae',
      pattern: 'Solid',
    },
    font: {
      color: '#74221F',
    },
  },
  {
    id: 'aggrid-blue-cell',
    interior: {
      color: '#9db9ea',
      pattern: 'Solid',
    },
    font: {
      color: '#062F79',
    },
  },
  {
    id: 'aggrid-red-cell-text',
    font: {
      color: '#c23934',
    },
  },
];

export function updateTotalRow(api: GridApi | null | undefined, rowSelection?: string) {
  if (!api) return;
  const rowData: Array<any> = [];
  api.forEachNodeAfterFilter((node) => {
    if (!node.group) rowData.push(node);
  });

  const filterAggFunction = (colDef: ColDef) => 'aggFunc' in colDef && !!colDef.aggFunc;

  const aggregateColumns: ColDef[] = (api.getColumnDefs() || [])
    .map((colDef) => {
      if ('groupId' in colDef) {
        return colDef.children.filter(filterAggFunction);
      }
      return [colDef].filter(filterAggFunction);
    })
    .flat();

  if (aggregateColumns.length === 0) return;

  const total_quantity_obj = aggregateColumns.reduce(
    (total_obj: Record<string, number>, column) => {
      total_obj[column.field || column.colId || ''] = 0;
      return total_obj;
    },
    {}
  );

  aggregateColumns.forEach((column) => {
    const key = column.field || column.colId || '';
    rowData.forEach((row: any) => {
      if (column.aggFunc === 'sum' || column.aggFunc === 'avg')
        total_quantity_obj[key] += api.getValue(key, row) || 0;
      if (column.aggFunc === 'count') total_quantity_obj[key] += 1;
    });
    if (column.aggFunc === 'avg' && rowData.length > 0)
      total_quantity_obj[key] += roundToTwoDecimals(total_quantity_obj[key] / rowData.length);
  });

  api.setPinnedBottomRowData([total_quantity_obj]);
}

export function updateTotalSelectedRow(api: GridApi | null | undefined) {
  if (!api) return;
  const rowData: Array<any> = api.getSelectedNodes();
  if (!rowData || rowData.length === 0) {
    api.setPinnedBottomRowData([]);
    return;
  }
  const filterAggFunction = (colDef: ColDef) => {
    return 'aggFunc' in colDef && !!colDef.aggFunc;
  };
  const aggregateColumns: ColDef[] = (api.getColumnDefs() || [])
    .map((colDef) => {
      if ('groupId' in colDef) {
        return colDef.children.filter(filterAggFunction);
      }
      return [colDef].filter(filterAggFunction);
    })
    .flat();

  if (aggregateColumns.length === 0) return;
  const total_quantity_obj = aggregateColumns.reduce(
    (total_obj: Record<string, number>, column) => {
      total_obj[column.field || column.colId || ''] = 0;
      return total_obj;
    },
    {}
  );

  aggregateColumns.forEach((column) => {
    const key = column.field || column.colId || '';
    rowData.forEach((row: any) => {
      if (column.aggFunc === 'sum' || column.aggFunc === 'avg')
        total_quantity_obj[key] += parseFloat(row?.data?.[key] || 0);
      if (column.aggFunc === 'count') total_quantity_obj[key] += 1;
    });
    if (column.aggFunc === 'avg' && rowData.length > 0)
      total_quantity_obj[key] += roundToTwoDecimals(total_quantity_obj[key] / rowData.length);
  });
  api.setPinnedBottomRowData([total_quantity_obj]);
}

export function change_level(level: number, api: GridApi | null | undefined) {
  if (!api) return;
  if (level === undefined) return;
  if (level === -1) {
    api.expandAll();
  } else if (level === 0) {
    api.collapseAll();
  } else {
    api.forEachNode((node, b) => {
      if (!node.allChildrenCount) return;
      node.expanded = node.level < level;
    });
    api.onGroupExpandedOrCollapsed();
  }
}

export const formatOverlayNoRowsTemplate = (overlayNoRowsTemplate: string) =>
  `<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; font-size:14px;">${overlayNoRowsTemplate}</span>`;

export const simpleSelectFilter = (input: string, option: { label: string }) => {
  return option?.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0;
};

export const getDateStyleAndLabel = (date: string | number) => {
  let statusColor = '';
  let backgroundStatusColor = '';
  let expired_status_label = '';
  const expire_soon = '';
  // _startCase(STATUS_EXPIRING_SOON);
  const expired = '';
  // _startCase(STATUS_EXPIRED);
  const today = new Date().getTime();
  if (!date) return { color: statusColor, background: backgroundStatusColor };
  const compare_date = new Date(date).getTime();
  const day = Math.round((compare_date - today) / 1000 / 60 / 60 / 24);
  if (day > 0) {
    statusColor = '#027E46';
    backgroundStatusColor = '#CCE5DA';
    expired_status_label = `${expire_soon} in ${day} days`;
  } else if (day < 0) {
    statusColor = '#D91F11';
    backgroundStatusColor = '#FADCD9';
    expired_status_label = `${expired} ${Math.abs(day)} days ago`;
  } else {
    let validity_label = '';
    if (compare_date > today) {
      statusColor = '#996E2F';
      backgroundStatusColor = '#FFF1DC';
      validity_label = expire_soon;
    } else {
      statusColor = '#D91F11';
      backgroundStatusColor = '#FADCD9';
      validity_label = expired;
    }
    expired_status_label = `${validity_label} Today`;
  }
  return {
    color: statusColor,
    background: backgroundStatusColor,
    expired_status_label: expired_status_label,
  };
};

export const renderDateWithAlert = (date: number) => {
  if (!date) {
    return <></>;
  }
  const { color, expired_status_label } = getDateStyleAndLabel(date * 1000);
  return (
    <div>
      <div>{getDateTimeFromUnix(date)}</div>
      <div style={{ color: color }}>{expired_status_label}</div>
    </div>
  );
};
