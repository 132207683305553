import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Card, Button, message, Tooltip, Form } from '@shipmnts/pixel-hub';
import { getSnapshot, cast } from 'mobx-state-tree';
import { useMutation } from '@apollo/client';
import { observer } from 'mobx-react-lite';
import { RoutingLegValue, getFirstMainHopIndex } from 'operations/models/RoutingLeg';
import RoutingDetailsView from './RoutingDetailsView';
import { RoutingNodeValue } from 'operations/models/RoutingNode';
import { RoutingNodesHashValue, RoutingDetailsValue } from 'operations/components/RoutingDetails';
import { UPDATE_OCEAN_TRANSPORT_ORDER } from 'operations/modules/booking/graphql/oceanTransportOrder';
import BookingRoutingDetails from '../BookingOrderForm/BookingRoutingDetails';
import { errorMessageHandlerGraphQL } from 'common';

import {
  getRoutingDetailsOnVoyageReset,
  checkVoyageResetOnValuesChange,
  getDefaultPreviousValue,
} from 'operations/modules/booking/helpers/RoutingDetailsHelper';

interface RoutingDetailsCardProps {
  routingLegs: RoutingLegValue[];
  parent_id: string;
  parent_type: 'ocean_transport_order';
  onUpdate?: (value: RoutingLegValue[]) => void;
  disabled?: boolean;
  disableReason?: string;
  onlyTranshipmentHopEditable?: boolean;
  globalCarrierId?: string;
  voyageScheduleId?: string | null;
  isReeferContainer?: boolean;
  bookingType?: string | null;
  validateVesselVoyage?: boolean;
}

type DestroyInterface<T> = T & {
  _destroy?: boolean;
};

const RoutingDetailsCard = observer(function RoutingDetailsCard(
  props: RoutingDetailsCardProps
): JSX.Element {
  const {
    routingLegs,
    parent_id,
    parent_type,
    onUpdate,
    disabled,
    disableReason,
    onlyTranshipmentHopEditable,
    globalCarrierId,
    voyageScheduleId,
    bookingType,
    isReeferContainer,
    validateVesselVoyage,
  } = props;
  const [routingEdit, setRoutingEdit] = useState<{
    edit: boolean;
    beforeUpdate?: { routing_legs: Array<RoutingLegValue>; routing_nodes: RoutingNodesHashValue };
  }>({ edit: false });
  const routing_legs_ref = useRef<{ runValidation: () => boolean }>();
  const [previousValue, setPreviousValue] = useState<{
    isFirstContainerTypeReefer?: boolean;
    mainCarriage?: {
      locationId?: string;
      vessel?: string;
    };
    carrierId?: string;
  }>({
    mainCarriage: routingLegs[getFirstMainHopIndex(routingLegs)]
      ? {
          locationId: routingLegs[getFirstMainHopIndex(routingLegs)].origin?.location?.id,
          vessel: routingLegs[getFirstMainHopIndex(routingLegs)].vessel?.imo,
        }
      : {},
  });

  const [updateParent, { data, loading, error }] = useMutation(UPDATE_OCEAN_TRANSPORT_ORDER);
  const [form] = Form.useForm();

  useEffect(() => {
    if (!error && data && data[`update_${parent_type}`]) {
      const { routing_legs } = data[`update_${parent_type}`];
      message.success('Routing Details Updated!');
      if (onUpdate) onUpdate(routing_legs);
      setRoutingEdit({ edit: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);

  const resetMainHopVoyageNumber = useCallback(
    (routing_details: RoutingDetailsValue, voyage_schedule_id?: string) => {
      form.setFieldsValue({
        routing_details,
        voyage_schedule_id,
      });
    },
    [form]
  );
  if (routingEdit.edit) {
    return (
      <Form
        form={form}
        scrollToFirstError
        layout="vertical"
        onFinish={(values: {
          routing_details?: RoutingDetailsValue;
          voyage_schedule_id?: string;
        }) => {
          let foundError = false;
          foundError = Boolean(routing_legs_ref?.current?.runValidation());
          if (!foundError) {
            const previousRoutingLegs = routingEdit.beforeUpdate?.routing_legs || [];
            const previousRoutingNodes = Object.values(
              routingEdit.beforeUpdate?.routing_nodes || {}
            );
            const { routing_details } = values;
            const updateRoutingLegs = [...(routing_details?.routing_legs || [])];
            const updateRoutingNodes = Object.values(routing_details?.routing_nodes || {});

            previousRoutingLegs.forEach((rl) => {
              if (!updateRoutingLegs.find((l: RoutingLegValue) => l.id === rl.id))
                updateRoutingLegs.push({
                  ...rl,
                  _destroy: true,
                } as DestroyInterface<RoutingLegValue>);
            });
            previousRoutingNodes.forEach((rn) => {
              if (!updateRoutingNodes.find((n: RoutingNodeValue) => n.id === rn.id))
                updateRoutingNodes.push({
                  ...rn,
                  _destroy: true,
                } as DestroyInterface<RoutingNodeValue>);
            });
            const voyage_schedule = values?.voyage_schedule_id
              ? { voyage_schedule_id: values?.voyage_schedule_id || null }
              : {};
            updateParent({
              variables: {
                [parent_type]: {
                  id: parent_id,
                  routing_legs: updateRoutingLegs.map((rl: RoutingLegValue) => {
                    const {
                      vessel,
                      estimated_time_of_departure,
                      estimated_time_of_arrival,
                      global_carrier,
                      ...restRL
                    } = rl;
                    return {
                      ...restRL,
                      global_carrier_id: global_carrier?.id,
                      vessel_id: vessel?.imo ? vessel?.imo : null,
                      estimated_time_of_departure: estimated_time_of_departure
                        ? estimated_time_of_departure?.unix()
                        : null,
                      estimated_time_of_arrival: estimated_time_of_arrival
                        ? estimated_time_of_arrival?.unix()
                        : null,
                    };
                  }),
                  routing_nodes: updateRoutingNodes.map((rn: RoutingNodeValue) => {
                    const { location, company, address, terminal, ...restRN } = rn;
                    return {
                      ...restRN,
                      location_id: location ? location?.id : null,
                      terminal_id: terminal ? terminal?.id : null,
                    };
                  }),
                  ...voyage_schedule,
                },
              },
            });
          }
        }}
        onValuesChange={(changedValues, allValues) => {
          const { shouldReset, currentValue } = checkVoyageResetOnValuesChange({
            changedValues,
            allValues,
            previousValue,
          });
          setPreviousValue(currentValue);
          if (shouldReset) {
            getRoutingDetailsOnVoyageReset(allValues.routing_details, resetMainHopVoyageNumber);
          }
        }}
      >
        <Card
          id="routing_details"
          title="Routing Details"
          extra={[
            <Button
              style={{ marginRight: '5px' }}
              key="cancel"
              disabled={loading}
              onClick={() => setRoutingEdit({ edit: false, beforeUpdate: undefined })}
            >
              Cancel
            </Button>,
            <Button loading={loading} key="save" htmlType="submit" type="primary">
              Save
            </Button>,
          ]}
        >
          {error && errorMessageHandlerGraphQL(error)}
          <Form.Item noStyle name="voyage_schedule_id">
            <div />
          </Form.Item>
          <Form.Item
            name="routing_details"
            rules={[
              {
                validator: (rule, value) => {
                  if (!routing_legs_ref?.current?.runValidation()) {
                    return Promise.resolve();
                  }
                  return Promise.reject();
                },
              },
            ]}
            noStyle
          >
            <BookingRoutingDetails
              validateVesselVoyage={validateVesselVoyage}
              ref={routing_legs_ref}
              globalCarrierId={globalCarrierId}
              bookingType={bookingType}
              allowVoyageScheduleSearch={!!voyageScheduleId}
              isReeferContainer={isReeferContainer}
              onlyTranshipmentHopEditable={onlyTranshipmentHopEditable}
            />
          </Form.Item>
        </Card>
      </Form>
    );
  }

  return (
    <Card
      id="routing_details"
      title="Routing Details"
      extra={
        <Tooltip title={disableReason}>
          <Button
            disabled={disabled}
            onClick={() => {
              const routing_nodes: RoutingNodesHashValue = {};
              let routing_legs = routingLegs
                ? getSnapshot<RoutingLegValue[]>(cast(routingLegs)).slice()
                : [];
              routing_legs = routing_legs.map((rl) => {
                if (rl?.origin?.id) routing_nodes[rl.origin.id] = rl.origin;
                if (rl?.destination?.id) routing_nodes[rl.destination.id] = rl.destination;
                return {
                  ...rl,
                  origin: undefined,
                  destination: undefined,
                  origin_id: rl?.origin?.id,
                  destination_id: rl?.destination?.id,
                };
              });
              setRoutingEdit({
                edit: true,
                beforeUpdate: {
                  routing_legs: routing_legs,
                  routing_nodes: routing_nodes,
                },
              });
              form.setFieldsValue({
                routing_details: {
                  routing_legs: routing_legs,
                  routing_nodes: routing_nodes,
                },
                voyage_schedule_id: voyageScheduleId,
              });
              setPreviousValue(
                getDefaultPreviousValue({ routing_details: { routing_legs, routing_nodes } })
              );
            }}
          >
            Edit
          </Button>
        </Tooltip>
      }
    >
      <RoutingDetailsView routingLegs={routingLegs} />
    </Card>
  );
});

export default RoutingDetailsCard;
