import React from 'react';
import { Table, Form } from '@shipmnts/pixel-hub';
import { ShipmentEstimatesFieldValue } from 'operations/modules/message/messageResourceDataModel';
import { AlignType } from 'rc-table/lib/interface';
import { CompanySearch } from 'common';
import { GlobalSearch } from '@shipmnts/pixel-hub';
interface MessageShipmentChargeMappingProps {
  shipmentEstimates: ShipmentEstimatesFieldValue[];
  tradeType: string | null;
  freightType: string | null;
  activeShipmentIndex: number;
  is_customer: boolean | undefined;
  is_vendor: boolean | undefined;
}

interface ChargeMappingTableProp extends ShipmentEstimatesFieldValue {
  tradeType: string | null;
  freightType: string | null;
}

const columns = [
  {
    title: 'Received Name of Charge',
    key: 'item',
    render: function renderChargeName(
      text: string,
      record: ShipmentEstimatesFieldValue,
      index: number
    ) {
      return <div key={index}>{record?.item?.value}</div>;
    },
  },
  {
    title: 'Map to Charge Master',
    key: 'charge_maping',
    align: 'center' as AlignType,
    render: function renderChargeSearch(text: string, record: ChargeMappingTableProp) {
      return (
        <Form.Item
          name={['charge_mapping', record?.item?.value || '']}
          label={'Search Item'}
          required
          rules={[{ required: true, message: 'Please select charge.' }]}
          style={{ width: 300 }}
        >
          <GlobalSearch
            doc_type="OrderManagement::ProductOrderItem"
            extraProps={{
              freightType: record.freightType,
              tradeType: record.tradeType,
              shouldExcludeFreightItems: false,
              shouldExcludeRebateItems: false,
            }}
            showCreation={false}
          />
        </Form.Item>
      );
    },
  },
];

const MessageShipmentChargeMapping = React.memo(function MessageShipmentChargeMapping(
  props: MessageShipmentChargeMappingProps
): JSX.Element {
  const { shipmentEstimates, tradeType, freightType, activeShipmentIndex, is_customer, is_vendor } =
    props;
  const uniqueCharge = new Set<string>();
  const filterCharge = (p: ShipmentEstimatesFieldValue) => {
    const item = p?.item?.value;
    const cond = item && !uniqueCharge.has(item);
    if (cond && item) uniqueCharge.add(item);
    return cond;
  };

  const tableProp = shipmentEstimates.map((ele) => ({ ...ele, tradeType, freightType }));
  return (
    <>
      {activeShipmentIndex === 0 && (
        <Form.Item
          name={'party_type'}
          required
          rules={[{ required: true, message: 'Please select finance party.' }]}
          label="Finance Party"
        >
          <CompanySearch searchProps={{ is_customer, is_vendor }} />
        </Form.Item>
      )}
      <Table
        columns={columns}
        dataSource={tableProp.filter(filterCharge)}
        pagination={false}
        size="small"
        rowKey={(row) => row.item.value || ''}
      />
    </>
  );
});

export default MessageShipmentChargeMapping;
