import { gql } from '@apollo/client';

export const CREATE_STOCK_ORDER_ITEM = gql`
  mutation createStockOrderItem($stock_order: StockOrderInputType!) {
    create_stock_order(stock_order: $stock_order) {
      id
    }
  }
`;

export const FETCH_STOCK_ORDER = gql`
  query stock_order($id: ID!) {
    stock_order(id: $id) {
      id
      airline {
        id
        name
      }
      ports {
        id
        name
      }
      billing_branch {
        id
        name
      }
      supplier_company {
        id
        registered_name
      }
      stock_order_items {
        id
        status
        awb_number
      }
    }
  }
`;

export const FETCH_STOCK_ORDER_ITEMS = gql`
  query stock_order_items($id: ID!) {
    fetch_stock_order_item(id: $id) {
      id
      awb_number
      stock_order {
        id
        airline {
          id
          name
        }
        ports {
          id
          name
        }
        billing_branch {
          id
          name
        }
        supplier_company {
          id
          registered_name
        }
        stock_order_items {
          id
          status
          awb_number
        }
      }
      status
    }
  }
`;
export const UPDATE_STOCK_ORDER_ITEM = gql`
  mutation updateStockOrder($id: ID!, $stock_order: UpdateStockOrderInput!) {
    update_stock_order(id: $id, stock_order: $stock_order) {
      id
    }
  }
`;

export const SPLIT_STOCK_ORDER = gql`
  mutation splitStockOrder($id: ID!, $stock_order: [ID!]!) {
    split_stock_order(id: $id, stock_order_item_ids: $stock_order) {
      id
    }
  }
`;

export const FETCH_PORT_HANDLING_QUERY = gql`
  query fetchPortHandling($handled_for_id: ID!, $global_port_ids: [ID!]!) {
    fetch_port_handling(handled_for_id: $handled_for_id, global_port_ids: $global_port_ids) {
      supplier_company_id
      supplier_company {
        id
        registered_name
      }
      billing_branch_id
      billing_branch {
        id
        name
      }
    }
  }
`;

export const DELETE_STOCK_ORDER_ITEM = gql`
  mutation deleteStockOrderItem($id: ID!) {
    delete_stock_order_item(id: $id) {
      message
    }
  }
`;

export const MOVE_STOCK_STATE = gql`
  mutation moveStockState($id: ID!, $action: StockOrderItemStateActionsEnumType!) {
    stock_order_item_state_change(id: $id, action: $action) {
      id
    }
  }
`;

export const RESERVE_STOCK = gql`
  mutation reserve_awb_stock(
    $carrier_id: ID!
    $port_of_loading_id: ID!
    $stock_required: Int!
    $reserved_for_company_id: ID!
  ) {
    reserve_awb_stock(
      carrier_id: $carrier_id
      port_of_loading_id: $port_of_loading_id
      stock_required: $stock_required
      reserved_for_company_id: $reserved_for_company_id
    ) {
      awb_numbers
      message
    }
  }
`;
