/**
 * ToDo: Do not allow edit on system events
 * Todo: POL gate pass confirmed date -> for export containeer
 */

/**
 * Container Tab in Shipments View
 */
import { useQuery } from '@apollo/client';
import { FETCH_SHIPMENT_CONTAINERS } from 'operations/modules/booking/graphql/shipmentContainer';
import React, { useEffect, useState } from 'react';
import { useShipmentDetail } from '../ShipmentDetailLayout';
import { get } from 'lodash';
import ContainerDetails from './ContainerDetails';
import { ActionRenderer } from 'operations/modules/actionHelper/ActionRenderer';
import {
  changeAllocation,
  assignLoadAction,
  containerActions,
} from 'operations/modules/actionHelper/ShipmentActions/shipmentActionHelper';
import { getWithCustomFieldValues } from 'common';

const ContainerTab = (props: any) => {
  //context
  const { shipment, refetchShipments } = useShipmentDetail();
  const { externalLink } = props;
  const [containers, setContainers] = useState(shipment?.shipment_containers || []);

  //queries
  const { data, loading, error, refetch } = useQuery(FETCH_SHIPMENT_CONTAINERS, {
    variables: { shipment_ids: [shipment?.id] },
  });

  useEffect(() => {
    if (!shipment || loading) return;
    const new_containers = get(data, 'fetch_shipment_containers', []);
    shipment.replaceContainers(new_containers);
    setContainers(getWithCustomFieldValues(new_containers));
  }, [data, shipment, loading]);

  if (!shipment || !data) return <></>;

  // Loading and error handling
  if (loading && !data) {
    return <div>loading...</div>;
  }
  if (error) {
    return <div>error occured</div>;
  }

  const refatchContainerAndShipment = async () => {
    if (refetchShipments) refetchShipments();
    refetch();
  };

  return (
    <div>
      <div className="flex-row" style={{ justifyContent: 'space-between', padding: '0px 12px' }}>
        <h3>Containers</h3>

        <div className="flex-row">
          {[assignLoadAction(shipment), containerActions(shipment), changeAllocation(shipment)].map(
            (actions, index) => {
              return (
                <div style={{ paddingLeft: '6px' }} key={index}>
                  <ActionRenderer
                    id={shipment.id}
                    doc_type_id="Shipment::Shipment"
                    isDetailScreen
                    selectedActions={actions}
                    buttonSize="small"
                    data={shipment}
                    refetchData={refetch}
                  />
                </div>
              );
            }
          )}
        </div>
      </div>
      <ContainerDetails
        parentType="shipment"
        parentId={shipment.id}
        parentData={shipment}
        containers={containers}
        refetch={refatchContainerAndShipment}
        externalLink={externalLink}
      />
    </div>
  );
};

export default ContainerTab;
