import React from 'react';

import { Row, Col, Button, Skeleton } from '@shipmnts/pixel-hub';
import { useApplicationContentContext } from 'common';
import { startCase as _startCase } from 'lodash';

import { MessageValue } from 'operations/models/Message';
import {
  ResourceDataValue,
  ShipmentPackagesFieldValue,
  ShipmentContainerFieldvalue,
  ShipmentEstimatesFieldValue,
} from 'operations/modules/message/messageResourceDataModel';
import {
  FREIGHT_TYPE_AIR,
  FREIGHT_TYPE_OCEAN,
  TRADE_TYPE_IMPORT,
} from 'operations/modules/shipment/constants';
import { ROW_GUTTER } from 'operations/modules/shipment/constants';

import { convertToStringFormat, DEFAULT_DATE_FORMAT } from '@shipmnts/pixel-hub';
import { SHIPMENT_MANIFEST_LOCATION_TYPE_PORT } from 'operations/models/ShipmentManifest';

const colSpan = 11;
const labelColor = 'slategray';
const headerolor = '#008eff';
const headerStyle = { color: headerolor, marginTop: '20px' };

const ShipmentMessageOverview = React.memo(function ShipmentMessageOverview(props: {
  shipment?: ResourceDataValue;
  message?: MessageValue;
}) {
  const { shipment, message } = props;
  const isOcean = shipment?.freight_type?.value === FREIGHT_TYPE_OCEAN;
  const { config_data, configDataError, fetchConfigData, fetchingConfigData } =
    useApplicationContentContext();
  const renderRoutingDetails = () => {
    return (
      <>
        <h3 style={{ color: headerolor }}>Routing Details</h3>
        <Row gutter={ROW_GUTTER}>
          <Col span={colSpan}>
            <div style={{ color: labelColor }}>Port of Loading</div>
            <div>{shipment?.port_of_loading_id?.label || '-'}</div>
          </Col>
          <Col span={colSpan}>
            <div style={{ color: labelColor }}>Port of Discharge</div>
            <div>{shipment?.port_of_discharge_id?.label || '-'}</div>
          </Col>
        </Row>
        <Row gutter={ROW_GUTTER} style={{ marginTop: '10px' }}>
          <Col span={colSpan}>
            <div style={{ color: labelColor }}>Place of Carrier Receipt</div>
            <div>{shipment?.place_of_carrier_receipt_id?.label || '-'}</div>
          </Col>
          <Col span={colSpan}>
            <div style={{ color: labelColor }}>Place of Carrier Delivery</div>
            <div>{shipment?.place_of_carrier_delivery_id?.label || '-'}</div>
          </Col>
        </Row>
        {isOcean && (
          <Row gutter={ROW_GUTTER} style={{ marginTop: '10px' }}>
            <Col span={colSpan}>
              <div style={{ color: labelColor }}>Final Place of Delivery</div>
              <div>{shipment?.final_place_of_delivery_id?.label || '-'}</div>
            </Col>
          </Row>
        )}
      </>
    );
  };

  const renderCustomDetails = () => {
    if (!shipment?.shipment_manifests) return <span />;
    const igm_details = shipment.shipment_manifests?.find((sm) => {
      return (
        sm.trade_type === TRADE_TYPE_IMPORT &&
        sm.location_type === SHIPMENT_MANIFEST_LOCATION_TYPE_PORT
      );
    });
    const pod_country = (igm_details?.country || '') as string;
    const import_general_manifest_label =
      config_data?.field_country_translations?.import_general_manifest?.[pod_country] ||
      'Import Manifest';

    return (
      <>
        {igm_details && <h3 style={headerStyle}>Custom Compliance Details</h3>}
        {igm_details && (
          <Row gutter={ROW_GUTTER}>
            <Col span={colSpan}>
              <div style={{ color: labelColor }}>{import_general_manifest_label} Number</div>
              <div>{igm_details?.manifest_number}</div>
            </Col>
            <Col span={colSpan}>
              <div style={{ color: labelColor }}>{import_general_manifest_label} Date</div>
              <div>{convertToStringFormat(igm_details?.manifest_date, DEFAULT_DATE_FORMAT)}</div>
            </Col>
          </Row>
        )}
      </>
    );
  };

  const renderShipmentPackages = () => {
    if (
      shipment?.freight_type?.value === FREIGHT_TYPE_OCEAN ||
      !shipment?.shipment_packages ||
      shipment?.shipment_packages.length === 0
    )
      return null;
    const shipment_packages: ShipmentPackagesFieldValue[] = shipment?.shipment_packages;
    return (
      <>
        <h3 style={headerStyle}>Packages Details</h3>
        <Row style={{ fontSize: '11px', color: labelColor }} justify="space-between">
          <Col span={4}>Type</Col>
          <Col span={3}># of pieces</Col>
          <Col span={3}>Length</Col>
          <Col span={3}>Width</Col>
          <Col span={3}>Height</Col>
          <Col span={3}>per piece weight</Col>
          <Col span={3}>Unit</Col>
        </Row>
        {shipment_packages.map((p: ShipmentPackagesFieldValue, i) => {
          return (
            <Row key={`packages_${i}`} style={{ fontSize: '11px' }} justify="space-between">
              <Col span={4}>{`${p?.type_of_package?.value}`}</Col>
              <Col span={3}>{`${p?.total_number_of_packages?.value}`}</Col>
              <Col span={3}>{`${p?.length?.value}`}</Col>
              <Col span={3}>{`${p?.width?.value}`}</Col>
              <Col span={3}>{`${p?.height?.value}`}</Col>
              <Col span={3}>{`${p?.per_piece_weight?.value}`}</Col>
              <Col span={3}>{`${p?.dimension_unit?.value}`}</Col>
            </Row>
          );
        })}
      </>
    );
  };

  const renderCargoDetails = () => {
    if (shipment?.freight_type?.value === FREIGHT_TYPE_OCEAN) return null;
    return (
      <>
        <h3 style={headerStyle}>Cargo Details</h3>
        <Row gutter={ROW_GUTTER}>
          <Col span={colSpan}>
            <div style={{ color: labelColor }}>Cargo Type</div>
            <div>{_startCase(shipment?.load_type?.value || '-')}</div>
          </Col>
          <Col span={colSpan}>
            <div style={{ color: labelColor }}>Commodity Description</div>
            <div>{`${shipment?.commodity_description?.value || '-'}`}</div>
          </Col>
        </Row>
      </>
    );
  };

  const renderContainerDetails = () => {
    if (
      shipment?.freight_type?.value === FREIGHT_TYPE_AIR ||
      !shipment?.shipment_containers ||
      shipment?.shipment_containers.length === 0
    )
      return null;

    const shipment_containers: ShipmentContainerFieldvalue[] = shipment?.shipment_containers;
    return (
      <>
        <h3 style={headerStyle}>Container Details</h3>
        <Row style={{ fontSize: '11px', color: labelColor }} justify="space-between">
          <Col span={6}>Container No.</Col>
          <Col span={6}>Container Type</Col>
          <Col span={6}>Shipping Line Seal</Col>
          <Col span={6}>VGM wt.(kgs)</Col>
        </Row>
        {shipment_containers.map((c: ShipmentContainerFieldvalue, i) => {
          return (
            <Row key={`containers_${i}`} style={{ fontSize: '11px' }} justify="space-between">
              <Col span={6}>{`${c?.container_number?.value}`}</Col>
              <Col span={6}>{`${c?.container_type?.value}`}</Col>
              <Col span={6}>{`${c?.carrier_seal_number?.value}`}</Col>
              <Col span={6}>{`${c?.verified_gross_mass?.value}`}</Col>
            </Row>
          );
        })}
      </>
    );
  };

  const renderEstimatesDetails = () => {
    if (shipment?.shipment_estimates && shipment?.shipment_estimates.length === 0) return null;
    const shipment_estimates: ShipmentEstimatesFieldValue[] | undefined =
      shipment?.shipment_estimates;
    return (
      <>
        <h3 style={headerStyle}>Charge Details</h3>
        <Row style={{ fontSize: '11px', color: labelColor }} justify="space-between">
          <Col span={6}>Charge Name.</Col>
          <Col span={6}>Qty</Col>
          <Col span={6}>Selling Rate</Col>
          <Col span={6}>Buying Rate</Col>
        </Row>
        {shipment_estimates &&
          shipment_estimates.map((c: ShipmentEstimatesFieldValue, i) => {
            return (
              <Row key={`items_${i}`} style={{ fontSize: '11px' }} justify="space-between">
                <Col span={6}>{c?.item?.value}</Col>
                <Col span={6}>{c?.quantity?.value}</Col>
                <Col span={6}>
                  {`${c?.sell_currency?.value || ''}`} {`${c?.sell_rate?.value || '-'}`}
                </Col>
                <Col span={6}>
                  {`${c?.buy_currency?.value || ''}`} {`${c?.buy_rate?.value || '-'}`}
                </Col>
              </Row>
            );
          })}
      </>
    );
  };

  const renderRemarks = () => {
    if (!message?.remarks || shipment?.master_shipment_id?.value) return null;
    return (
      <>
        <h3 style={headerStyle}>Remarks</h3>
        <div>{message?.remarks}</div>
      </>
    );
  };

  if (configDataError) {
    return (
      <div>
        Something went wrong. {configDataError?.message}
        {'  '}
        <span>
          <Button
            onClick={() => {
              if (fetchConfigData) fetchConfigData();
            }}
          >
            Retry
          </Button>
        </span>
      </div>
    );
  } else if (fetchingConfigData) return <Skeleton active />;

  return (
    <div>
      <div>{renderRoutingDetails()}</div>
      <div>{renderCargoDetails()}</div>
      <div>{renderShipmentPackages()}</div>
      <div>{renderCustomDetails()}</div>
      <div>{renderContainerDetails()}</div>
      <div>{renderRemarks()}</div>
      <div>{renderEstimatesDetails()}</div>
    </div>
  );
});

export default ShipmentMessageOverview;
