import { CompanySearch } from 'common';

import {
  reportCommonFields,
  getShippingLine,
  getDateObject,
  add_row_groups,
} from 'operations/modules/reports/reportCommonFields';
import { dayjs } from '@shipmnts/pixel-hub';
import { CellClassParams, ValueGetterParams, ValueFormatterParams } from '@ag-grid-community/core';
import {
  STATUS_REQUESTED,
  STATUS_EXPIRED,
  STATUS_EXPIRING_SOON,
  STATUS_CONFIRMED,
  REQUESTED_AGEING,
  // PIVOT_CUSTOMER,
} from 'operations/modules/reports/constants';
import { BookingRequestReportData } from './BookingRequestReports';
import {
  snakeCase as _snakeCase,
  startsWith as _startsWith,
  pick as _pick,
  cloneDeep as _cloneDeep,
} from 'lodash';
import { Column } from 'operations/models/Report';
import { LOAD_TYPE_FCL, LOAD_TYPE_LCL } from 'operations/baseConstants';
import { isCargoHazardous } from 'operations/modules/reports/oldCellRenderers';
import { GlobalSearch } from '@shipmnts/pixel-hub';

const formatHazCargoFilter = (params: ValueFormatterParams) =>
  [true, 'true'].includes(params?.value) ? 'Hazardous' : 'Non Hazardous';

export const fields: { [key: string]: Column } = {
  ...reportCommonFields,
  booking_request_id_without_link: {
    headerName: 'Order #',
    field: 'shipment_booking_number',
    colId: 'shipment_booking_number',
    width: 80,
  },
  booking_request_id: {
    headerName: 'Order #',
    field: 'shipment_booking_number',
    colId: 'shipment_booking_number',
    filterKey: 'booking_requests.shipment_booking_number',
    filter: 'agTextColumnFilter',
    minWidth: 100,
    // pinned: 'left',
    cellRenderer: 'renderLinkCell',
    cellRendererParams: { redirectToBase: `/view/booking_request`, idField: 'id' },
  },
  booking_id_group_renderer: {
    headerName: 'Order #',
    field: 'shipment_booking_number',
    colId: 'shipment_booking_number',
    filterKey: 'booking_requests.shipment_booking_number',
    filter: 'agTextColumnFilter',
    width: 200,
    // pinned: 'left',
    cellRenderer: 'agGroupCellRenderer',
    cellRendererParams: {
      innerRenderer: 'renderBookingRequestId',
      redirectToBase: `/view/booking_request`,
      idField: 'id',
    },
  },
  allocated_booking_numbers: {
    headerName: 'Booking #',
    field: 'allocated_booking_numbers',
    colId: 'allocated_booking_numbers',
    minWidth: 100,
    filter: 'agTextColumnFilter',
    filterKey: 'booking_number',
  },
  preferred_carriers: getShippingLine(
    'Requested Carrier',
    'preferred_carriers',
    'preferred_carrier_ids'
  ),
  carrier: getShippingLine('Shipping Line', 'shipping_line', 'global_carrier_id'),
  transporter_names: {
    headerName: 'Transporter(s)',
    field: 'transporter_names',
    colId: 'transporter_names',
    minWidth: 100,
  },
  shipper: {
    headerName: 'Shipper',
    field: 'shipper',
    colId: 'shipper',
    filterKey: 'shipper_company_id',
    filter: 'CustomFilterWrapper',
    floatingFilterComponent: 'NewFloatingFilter',
    floatingFilterComponentParams: {
      valuePropName: 'registered_name',
      suppressFilterButton: true,
    },
    filterParams: {
      component: CompanySearch,
      componentProps: {
        selectMode: 'multiple',
        searchProps: {
          is_disabled: [true, false],
        },
        renderViewType: 'NewTab',
      },
    },
    minWidth: 100,
  },
  requested_at: {
    headerName: 'Order Date',
    field: 'requested_at',
    colId: 'created_at',
    filterKey: 'booking_requests.created_at',
    filter: 'agDateColumnFilter',
    width: 160,
    sortable: true,
    filterParams: {
      filterOptions: ['inRange', 'equals'],
      suppressAndOrCondition: true,
      clearButton: true,
      debounceMs: 500,
    },
  },
  created_by: {
    headerName: 'Created By',
    field: 'created_by',
    colId: 'created_by',
    filterKey: 'booking_requests.created_by_id',
    filter: 'CustomFilterWrapper',
    floatingFilterComponent: 'NewFloatingFilter',
    floatingFilterComponentParams: {
      valuePropName: 'first_name',
      suppressFilterButton: true,
    },
    filterParams: {
      component: GlobalSearch,
      componentProps: { doc_type: 'Network::UserContact', selectMode: 'multiple' },
    },
    minWidth: 120,
  },
  recent_valid_till_date: {
    headerName: 'Validity Date',
    field: 'recent_valid_till_date',
    colId: 'recent_valid_till_date',
  },
  si_cutoff_date: getDateObject('SI Cutoff', 'si_cutoff_date', 'si_cutoff_date', true),
  cancellation_booking_numbers: {
    headerName: 'Booking #',
    field: 'cancellation_booking_numbers',
    colId: 'cancellation_booking_numbers',
  },
  hazardous_cargo: {
    headerName: 'Hazardous',
    colId: 'hazardous_cargo',
    minWidth: 165,
    valueGetter: (params: ValueGetterParams) => isCargoHazardous(params?.data?.cargos),
    filterKey: 'is_hazardous',
    filter: 'agSetColumnFilter',
    filterParams: {
      values: [true, false],
      valueFormatter: formatHazCargoFilter,
    },
    cellRenderer: 'renderHazardousCargo',
    cellRendererParams: {
      cargoField: 'cargos',
    },
    cellClassRules: {
      'aggrid-red-cell-text': function (params: CellClassParams) {
        return params?.value === 'Hazardous';
      },
    },
  },
  dg_indexing_date: getDateObject(
    'DG Indexing Date',
    'dg_indexing_date',
    'ocean_transport_orders.dg_indexing_date',
    false,
    'DG Not Filed'
  ),
  status_text: {
    headerName: 'Status',
    field: 'status_text',
    colId: 'status_text',
    filterKey: 'status',
    width: 200,
    // pinned: 'right',
    cellStyle: function (params: { data?: BookingRequestReportData }) {
      if (params?.data?.requested_at) {
        const requested_at = dayjs.unix(params.data.requested_at);
        const difference = dayjs(new Date()).diff(requested_at, 'hour');
        if (difference > REQUESTED_AGEING) {
          return { backgroundColor: '#FFF1B8' };
        }
      }
      return null;
    },
  },
  requested_since: {
    headerName: 'Requested Since',
    // pinned: 'right',
    field: 'requested_since',
    colId: 'requested_since',
    minWidth: 80,
    cellStyle: function (params: { data?: BookingRequestReportData }) {
      if (params?.data?.requested_at) {
        const requested_at = dayjs.unix(params.data.requested_at);
        const difference = dayjs(new Date()).diff(requested_at, 'hour');
        if (difference > REQUESTED_AGEING) {
          return { backgroundColor: '#FFF1B8' };
        }
      }
      return null;
    },
    sortable: true,
  },
  recent_oto_status: {
    headerName: 'Status',
    field: 'recent_oto_status',
    colId: 'recent_oto_status',
    width: 110,
    // pinned: 'right',
    filterKey: 'ocean_transport_orders_status',
    filter: 'agSetColumnFilter',
    filterParams: {
      cellHeight: 20,
      values: [STATUS_REQUESTED, STATUS_CONFIRMED, STATUS_EXPIRED],
      debounceMs: 1000,
    },
    cellStyle: function (params: { value: string; data?: BookingRequestReportData }) {
      const value = _snakeCase(params?.value);
      const status = _startsWith(params.value, 'Expiring') ? STATUS_EXPIRING_SOON : value;
      const status_backgroundcolor_mapping: { [key: string]: string } = {
        [STATUS_EXPIRING_SOON]: '#FFF1B8',
        [STATUS_EXPIRED]: '#FFCCC7',
        [STATUS_CONFIRMED]: '#B7EB8F',
      };
      return { backgroundColor: status_backgroundcolor_mapping[status] };
    },
  },
  load_type: {
    headerName: 'Load Type',
    filterKey: 'booking_requests.load_type',
    field: 'load_type',
    colId: 'load_type',
    filter: 'agSetColumnFilter',
    filterParams: {
      values: [LOAD_TYPE_FCL, LOAD_TYPE_LCL],
      debounceMs: 1000,
    },
    cellStyle: { textTransform: 'uppercase' },
    minWidth: 70,
  },
  carting_gross_weight: {
    headerName: 'Gross Weight',
    field: 'carting_gross_weight',
    colId: 'carting_gross_weight',
    filter: false,
  },
  carting_point: {
    headerName: 'Carting Location',
    field: 'carting_point.location.name',
    colId: 'carting_point.location.name',
    filter: false,
    minWidth: 180,
  },
  stuffing_status: {
    headerName: 'Status',
    field: 'stuffing_status',
    colId: 'stuffing_status',
    filter: false,
    // pinned: 'right',
    minWidth: 150,
  },
};

export const drilldownFields = {
  booking_number: {
    headerName: 'Booking #',
    field: 'booking_number',
    colId: 'booking_number',
    cellRenderer: 'renderLinkCell',
    cellRendererParams: {
      redirectToBase: `/view/booking_order`,
      idField: 'id',
    },
  },
  booking_date: {
    headerName: 'Booking Date',
    field: 'booking_date',
    colId: 'booking_date',
  },
  oto_wise_allocated_containers_quantity: {
    headerName: 'Allocated Qty',
    field: 'oto_wise_allocated_containers_qty',
    colId: 'oto_wise_allocated_containers_qty',
  },
  empty_pickup_location: {
    headerName: 'Empty Yard',
    field: 'empty_pickup_location.name',
    colId: 'empty_pickup_location.name',
  },
  pickedup_containers_quantity: {
    headerName: 'Picked Up Qty',
    field: 'pickedup_containers_quantity',
    colId: 'pickedup_containers_quantity',
  },
  pickup_pending_containers_quantity: {
    headerName: 'Pickup Pending Qty',
    field: 'pickup_pending_containers_quantity',
    colId: 'pickup_pending_containers_quantity',
  },
  valid_till_date: {
    headerName: 'Validity Date',
    field: 'valid_till_date',
    colId: 'valid_till_date',
  },
  status_text: {
    headerName: 'Status',
    field: 'status_text',
    colId: 'status_text',
  },
};

export const rtoDrillDownFields = {
  rto_id: {
    headerName: 'RTO #',
    field: 'rto_id',
    colId: 'rto_id',
  },
  transporter_name: {
    headerName: 'Transporter',
    field: 'transporter_name',
    colId: 'transporter_name',
  },
  driver_contact: {
    headerName: 'Driver Contact No',
    field: 'routing_legs.0.driver_contact',
    colId: 'routing_legs.0.driver_contact',
    minWidth: 120,
  },
  vehicle_number: {
    headerName: 'Vehicle No',
    field: 'routing_legs.0.voyage_number',
    colId: 'routing_legs.0.voyage_number',
    minWidth: 120,
  },
  carting_gross_weight: {
    headerName: 'Gross Weight',
    field: 'carting_gross_weight',
    colId: 'carting_gross_weight',
    filter: false,
  },
  pickup_time: {
    headerName: 'Pickup Time',
    field: 'pickup_time',
    colId: 'pickup_time',
  },
  carting_time: {
    headerName: 'Carting Time',
    field: 'carting_time',
    colId: 'carting_time',
  },
  rto_status: {
    headerName: 'Status',
    field: 'rto_status',
    colId: 'rto_status',
  },
};

const booking_request_dashboard_columns = (default_pivots: string[]): Array<Column> => {
  const brDashboardFields = _cloneDeep(fields);
  brDashboardFields['quantity_unfulfilled'] = {
    ...brDashboardFields['quantity_unfulfilled'],
    // pinned: 'right',
    cellClassRules: {
      'highlight-na': function (params: CellClassParams) {
        return params?.value > 0;
      },
    },
  };

  const hiddenColumns: Array<string> = [
    'booking_request_id',
    'created_by',
    'port_of_loading',
    'commodity',
    'quantity_offloaded',
    'quantity',
    'quantity_unfulfilled',
    // 'quantity_origin_port_gate_in_pending',
    // 'quantity_loading_pending',
    // 'quantity_pick_up_pending',
    'quantity_fulfilled',
    'quantity_picked_up',
    'quantity_origin_port_gated_in',
    'quantity_loaded_on_vessel',
    'quantity_shutout',
    'quantity_cancelled',
  ];
  hiddenColumns.forEach((hc) => {
    brDashboardFields[hc]['hide'] = true;
    brDashboardFields[hc]['pinned'] = null;
  });

  const all_columns = [
    ...Object.values(
      _pick(brDashboardFields, [
        'customer',
        'sales_person',
        'carrier',
        'port_of_discharge',
        'vessel_voyage',
        'estimated_time_of_departure',
        'booking_request_id',
        'created_by',
        'port_of_loading',
        'commodity',
        'quantity_offloaded',
        'quantity',
        'quantity_unfulfilled',
        // 'quantity_origin_port_gate_in_pending',
        // 'quantity_loading_pending',
        // 'quantity_pick_up_pending',
        'quantity_fulfilled',
        'quantity_picked_up',
        'quantity_origin_port_gated_in',
        'quantity_loaded_on_vessel',
        'quantity_shutout',
        'quantity_cancelled',
      ])
    ),
  ];

  return add_row_groups(all_columns, default_pivots);
};

export const br_dashboard_columns_mapping: { [key: string]: Array<Column> } = {
  booking_request_dashboard: booking_request_dashboard_columns([]),
};
