import {
  Button,
  Checkbox,
  message,
  Modal,
  Typography,
  Form,
  UploadedDocuments,
  dayjs,
  DatePicker,
} from '@shipmnts/pixel-hub';
import React, { useEffect, useState } from 'react';
import { DOCUMENT_STATUS_SURRENDERED_AT_ORIGIN } from '../../constants';
import { startCase as _startCase } from 'lodash';
import { useMutation } from '@apollo/client';
import { UPDATE_SHIPMENT_DOCUMENT_STATUS } from 'operations/graphql/shipmentDocument';
import { ShipmentValue } from 'operations/models/Shipment';
import { ShipmentDocumentValue } from 'operations/models/ShipmentDocument';
import { useSession } from 'common';
const { Text } = Typography;
interface UpdateShipmentDocumentStatusProps {
  shipment: ShipmentValue;
  shipment_document: ShipmentDocumentValue;
  document_status: string;
  visible?: boolean;
  onClose?: () => void;
  onSuccess?: (sendEmail: boolean) => void;
  action_name?: string;
  title?: string;
  sendEmailDefault?: boolean;
  showUploadSection?: boolean;
  uploadDocTags?: string[];
}

export default function UpdateShipmentDocumentStatus(props: UpdateShipmentDocumentStatusProps) {
  const {
    shipment,
    shipment_document,
    document_status,
    visible,
    onClose,
    title = '',
    sendEmailDefault = true,
    onSuccess,
    showUploadSection,
    uploadDocTags,
  } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [sendEmail, setSendEmail] = useState<boolean>(sendEmailDefault);
  const [form] = Form.useForm();
  const session = useSession();
  const [updateDocumentStatus, { loading: updateDocumentStatusLoading, data, error }] = useMutation(
    UPDATE_SHIPMENT_DOCUMENT_STATUS
  );

  useEffect(() => {
    if (updateDocumentStatusLoading) {
      setLoading(true);
      return;
    }
    if (error) {
      message.error(error.message);
      setLoading(false);
      if (onClose) onClose();
    }
    if (data?.update_shipment_document_status) {
      message.success('Updated successfully');
      setLoading(false);
      if (onSuccess) {
        onSuccess(sendEmail);
      }
      if (onClose) onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDocumentStatusLoading, data, error]);

  return (
    <Modal
      onCancel={() => {
        if (onClose) onClose();
      }}
      title={title}
      open={visible}
      footer={[
        ...(document_status === DOCUMENT_STATUS_SURRENDERED_AT_ORIGIN
          ? [
              <Checkbox
                key="id"
                checked={sendEmail}
                onChange={(e) => setSendEmail(e.target.checked)}
              >
                Send Email
              </Checkbox>,
            ]
          : []),
        <Button
          key="Cancel"
          onClick={() => {
            if (onClose) onClose();
          }}
        >
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={form.submit}>
          Save
        </Button>,
      ]}
    >
      <Form
        onFinish={(values: any) => {
          const id = shipment_document?.id;
          const document_status_event_date = dayjs(values.document_status_event_date).unix();
          updateDocumentStatus({
            variables: { id, document_status, document_status_event_date },
          });
        }}
        form={form}
        layout="vertical"
      >
        <Text strong style={{ margin: '5px' }}>
          BL Number: {shipment_document?.shipment_number}
        </Text>
        <Form.Item
          label={`${_startCase(document_status)} Date`}
          name="document_status_event_date"
          required
          rules={[{ required: true, message: 'Required' }]}
        >
          <DatePicker style={{ width: '100%' }} />
        </Form.Item>
        {showUploadSection && (
          <UploadedDocuments
            parent_id={shipment.id}
            parent_type={'shipment'}
            sessionData={session}
            docgen_url={process.env.DOCGEN_URL || ''}
            initialUploadDocumentTags={uploadDocTags || []}
          />
        )}
      </Form>
    </Modal>
  );
}
