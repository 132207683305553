import React, { useEffect, useState } from 'react';
import { useShipmentDetail } from '../ShipmentDetailLayout';
import CargoDetails from './CargoDetails';
import { CargoValue } from 'operations/models/Cargo';
import { useQuery } from '@apollo/client';
import { CARGO_WITH_TRIPS } from 'operations/graphql/shipment';
import { getDateFromUnix, Skeleton } from '@shipmnts/pixel-hub';
import { SHIPMENT_TYPE_CONSOL } from 'operations/modules/shipment/constants';
import { ShipmentDocumentValue } from 'operations/models/ShipmentDocument';
import { cargoActions } from 'operations/modules/actionHelper/ShipmentActions/shipmentActionHelper';
import { ActionRenderer } from 'operations/modules/actionHelper/ActionRenderer';

interface Trip {
  id: string;
  job_number?: string | null;
  shipment_documents?: ShipmentDocumentValue[];
}

interface CargoData extends CargoValue {
  trips?: Trip[] | null;
  job_number?: Trip[] | null;
  shipment_number?: ShipmentDocumentValue[] | null;
  shipment_date?: string[] | null;
}

function CargoTab() {
  const { shipment, refetchShipments } = useShipmentDetail();
  const [cargos, setCargos] = useState<CargoData[]>([]);
  const {
    data: cargoWithTrips,
    loading,
    error,
  } = useQuery(CARGO_WITH_TRIPS, {
    variables: { id: shipment?.id },
  });

  const getCargoWithTripData = (cargo: CargoData) => {
    const documents: ShipmentDocumentValue[] = [];
    const shipment_dates: string[] = [];
    cargo.trips?.forEach((trip: Trip) => {
      trip.shipment_documents?.forEach((document: ShipmentDocumentValue) => {
        if (document.document_type === 'house') {
          documents.push(document);
          if (document?.shipment_date)
            shipment_dates.push(getDateFromUnix(document?.shipment_date));
        }
      });
    });
    return {
      ...cargo,
      job_number: cargo.trips,
      shipment_number: documents,
      shipment_date: shipment_dates,
    };
  };

  useEffect(() => {
    if (error || loading || !cargoWithTrips) return;

    const shipmentResponse = cargoWithTrips.shipment;
    let cargos: CargoData[] = shipmentResponse.cargos || [];
    cargos = cargos.map((cargo: CargoData) => getCargoWithTripData(cargo));

    // show house shipment cargos if road split shipment and shipment type consol
    if (shipment?.split_from_order_id && shipment.shipment_type === SHIPMENT_TYPE_CONSOL) {
      let houseCargos: CargoData[] = [];
      shipment?.house_shipments?.forEach((hs) => {
        houseCargos = [...houseCargos, ...hs.cargos];
      });
      cargos = houseCargos;
    }

    setCargos(cargos);
  }, [loading, error, cargoWithTrips, shipment]);

  return loading || !shipment ? (
    <Skeleton />
  ) : (
    <div>
      <div className="flex-row" style={{ justifyContent: 'space-between', padding: '0px 12px' }}>
        <h3>Cargos</h3>

        {!shipment.isFcl() && (
          <ActionRenderer
            id={shipment.id}
            doc_type_id="Shipment::Shipment"
            isDetailScreen
            selectedActions={cargoActions(shipment)}
            data={shipment}
            refetchData={refetchShipments}
            buttonSize="small"
          />
        )}
      </div>
      <CargoDetails cargos={cargos} loadType={shipment?.load_type || ''} shipment={shipment} />
    </div>
  );
}

export default CargoTab;
