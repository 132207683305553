import React, { useEffect } from 'react';
import { Button, message } from '@shipmnts/pixel-hub';
import { DISABLE_CONTACT } from '../graphql/contact';
import { useMutation } from '@apollo/client';
import { getContactPayload } from './ContactForm';

const DisableContact = React.memo(function DisableContact(props: {
  contact_id: string | undefined;
  flag: boolean;
  disabled: boolean;
  children: React.ReactNode;
  onSuccess: any;
}): JSX.Element {
  const { contact_id, flag, children, onSuccess, disabled } = props;

  const [disableContact, { data, error, loading }] = useMutation(DISABLE_CONTACT);

  useEffect(() => {
    if (!error && data) {
      message.success('Contact updated successfully!');
      if (onSuccess) onSuccess(getContactPayload(data?.disable_contact));
    }
    if (error) {
      message.error({
        content: error?.graphQLErrors.map((e) => e.message),
        key: 'disable_contact',
      });
    }
  }, [data, error, onSuccess]);

  return (
    <Button
      onClick={() => {
        if (contact_id) disableContact({ variables: { id: contact_id, is_disabled: flag } });
      }}
      danger={flag}
      disabled={disabled}
      loading={loading}
    >
      {children}
    </Button>
  );
});

export default DisableContact;
