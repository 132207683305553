import { useLazyQuery } from '@apollo/client';
import { Col, Form, FormInstance, Input, Row, DatePicker } from '@shipmnts/pixel-hub';
import { VesselSearch } from 'common';
import { SHIPMENT } from 'operations/graphql/shipment';
import { convertToDayJs } from '@shipmnts/pixel-hub';
import { ShipmentValue } from 'operations/models/Shipment';
import React, { useEffect, useState } from 'react';
import {
  FreightType,
  MOVEMENT_MODE_AIR_EXPORT,
  MOVEMENT_MODE_AIR_IMPORT,
  MOVEMENT_MODE_DOOR_TO_DOOR,
  MOVEMENT_MODE_OCEAN_EXPORT,
  MOVEMENT_MODE_OCEAN_IMPORT,
  ROW_GUTTER,
} from '../../constants';
import { GlobalSearch } from '@shipmnts/pixel-hub';

interface LinkShipmentDetailsProps {
  shipment: ShipmentValue;
  form: FormInstance<any>;
  disableShipmentSearch: boolean;
  hide?: boolean;
}

export default function LinkShipmentDetails(props: LinkShipmentDetailsProps) {
  const { form, disableShipmentSearch = false, shipment } = props;
  const shipmentFromSearch = Form.useWatch('linked_to_shipment', form);
  const movementMode = Form.useWatch('movement_mode', form) || shipment?.movement_mode;
  const [fetchLinkedShipment, { data: linkedShipmentData }] = useLazyQuery(SHIPMENT);
  const [searchFreightType, setSearchFreightType] = useState<FreightType | undefined>(undefined);
  const [searchTradeType, setSearchTradeType] = useState<string | undefined>(undefined);
  const [voyageNumberLabel, setVoyageNumberLabel] = useState<string>('Voyage Number');
  const linkedShipmentId = Form.useWatch('linked_shipment_id', form);
  const isOceanMovementMode = [MOVEMENT_MODE_OCEAN_IMPORT, MOVEMENT_MODE_OCEAN_EXPORT].includes(
    movementMode || ''
  );
  const isAirMovementMode = [MOVEMENT_MODE_AIR_EXPORT, MOVEMENT_MODE_AIR_IMPORT].includes(
    movementMode || ''
  );
  const isDoorMovementMode = MOVEMENT_MODE_DOOR_TO_DOOR === movementMode;

  const updateFormWithLinkShipment = (
    form: FormInstance<any>,
    linkedShipmentData: ShipmentValue
  ) => {
    form?.setFieldsValue({
      ocean_vessel: linkedShipmentData?.ocean_vessel,
      carrier: linkedShipmentData?.carrier,
      lfd_at_empty_return: convertToDayJs(linkedShipmentData.lfd_at_empty_return),
      estimated_time_of_arrival: convertToDayJs(linkedShipmentData.estimated_time_of_arrival),
      estimated_time_of_departure: convertToDayJs(linkedShipmentData.estimated_time_of_departure),
      voyage_number: linkedShipmentData.voyage_number,
      ocean_shipment_booking: {
        booking_validity_date: convertToDayJs(
          linkedShipmentData.ocean_shipment_booking?.booking_validity_date
        ),
        shipping_line_booking_number:
          linkedShipmentData.ocean_shipment_booking?.shipping_line_booking_number,
        cy_cutoff_date: convertToDayJs(linkedShipmentData.ocean_shipment_booking?.cy_cutoff_date),
      },
      lfd_at_pod: convertToDayJs(linkedShipmentData.lfd_at_pod),
    });
  };

  useEffect(() => {
    if (
      movementMode === MOVEMENT_MODE_OCEAN_EXPORT ||
      movementMode === MOVEMENT_MODE_OCEAN_IMPORT
    ) {
      setSearchFreightType('ocean');
    } else if (
      movementMode === MOVEMENT_MODE_AIR_EXPORT ||
      movementMode === MOVEMENT_MODE_AIR_IMPORT
    ) {
      setSearchFreightType('air');
    } else {
      setSearchFreightType(undefined);
    }

    if (movementMode === MOVEMENT_MODE_AIR_EXPORT || movementMode === MOVEMENT_MODE_OCEAN_EXPORT) {
      setSearchTradeType('export');
    } else if (movementMode === MOVEMENT_MODE_DOOR_TO_DOOR) {
      setSearchTradeType(undefined);
    } else {
      setSearchTradeType('import');
    }
    setVoyageNumberLabel(isAirMovementMode ? 'Flight Number' : 'Voyage Number');
  }, [movementMode, isAirMovementMode]);

  useEffect(() => {
    if (linkedShipmentId) {
      fetchLinkedShipment({
        variables: { id: linkedShipmentId },
      });
    }
  }, [fetchLinkedShipment, linkedShipmentId]);

  useEffect(() => {
    if (linkedShipmentData?.shipment) {
      form.setFieldValue('linked_to_shipment', linkedShipmentData?.shipment);
      updateFormWithLinkShipment(form, linkedShipmentData?.shipment);
    }
  }, [form, linkedShipmentData]);

  return (
    <Row gutter={ROW_GUTTER}>
      {
        <Col span={8}>
          <Form.Item name={'linked_shipment_id'} noStyle />
          <Form.Item name="linked_to_shipment" label="Linked to an Existing Shipment">
            <GlobalSearch
              doc_type="Shipment::Shipment"
              disabled={disableShipmentSearch}
              onChange={(value) => {
                if (value) {
                  form?.setFieldsValue({
                    ocean_vessel: value.ocean_vessel,
                    carrier: value.carrier,
                    lfd_at_empty_return: convertToDayJs(value.lfd_at_empty_return),
                    estimated_time_of_arrival: convertToDayJs(value.estimated_time_of_arrival),
                    estimated_time_of_departure: convertToDayJs(value.estimated_time_of_departure),
                    voyage_number: value.voyage_number,
                    ocean_shipment_booking: {
                      booking_validity_date: convertToDayJs(
                        value.ocean_shipment_booking?.booking_validity_date
                      ),
                      shipping_line_booking_number:
                        value.ocean_shipment_booking?.shipping_line_booking_number,
                      cy_cutoff_date: convertToDayJs(value.ocean_shipment_booking?.cy_cutoff_date),
                    },
                    lfd_at_pod: convertToDayJs(value.lfd_at_pod),
                  });
                  if (movementMode === MOVEMENT_MODE_DOOR_TO_DOOR)
                    value.freight_type === 'air'
                      ? setVoyageNumberLabel('Flight Number')
                      : setVoyageNumberLabel('Voyage Number');
                } else {
                  form?.setFieldsValue({
                    ocean_vessel: undefined,
                    carrier: undefined,
                    lfd_at_empty_return: undefined,
                    estimated_time_of_arrival: undefined,
                    estimated_time_of_departure: undefined,
                    voyage_number: undefined,
                    ocean_shipment_booking: {
                      booking_validity_date: undefined,
                      shipping_line_booking_number: undefined,
                      cy_cutoff_date: undefined,
                    },
                    lfd_at_pod: undefined,
                  });
                }
              }}
              searchProps={{
                freight_type: searchFreightType,
                trade_type: searchTradeType,
              }}
            />
          </Form.Item>
        </Col>
      }
      {
        <Col span={8}>
          <Form.Item name="carrier" label={'Carrier'}>
            <GlobalSearch
              doc_type="Global::Carrier"
              searchProps={{
                carrier_type:
                  movementMode === MOVEMENT_MODE_DOOR_TO_DOOR
                    ? ['ocean', 'air']
                    : searchFreightType === 'ocean'
                    ? ['ocean', 'nvocc']
                    : ['air'],
              }}
              disabled={!!shipmentFromSearch}
            />
          </Form.Item>
        </Col>
      }

      {[
        MOVEMENT_MODE_OCEAN_IMPORT,
        MOVEMENT_MODE_OCEAN_EXPORT,
        MOVEMENT_MODE_DOOR_TO_DOOR,
      ].includes(movementMode) && (
        <Col span={8}>
          <Form.Item
            name={['ocean_shipment_booking', 'shipping_line_booking_number']}
            label="Carrier Ref #"
          >
            <Input disabled={!!shipmentFromSearch} placeholder="Enter Carrier Reference Number" />
          </Form.Item>
        </Col>
      )}
      {(isOceanMovementMode || isDoorMovementMode) && (
        <Col span={8}>
          <Form.Item label="Vessel" name="ocean_vessel">
            <VesselSearch disabled={!!shipmentFromSearch} />
          </Form.Item>
        </Col>
      )}
      {
        <Col span={8}>
          <Form.Item name="voyage_number" label={voyageNumberLabel}>
            <Input disabled={!!shipmentFromSearch} placeholder="Enter Voyage Number" />
          </Form.Item>
        </Col>
      }
      {isOceanMovementMode && (
        <Col span={8}>
          <Form.Item
            name={['ocean_shipment_booking', 'booking_validity_date']}
            label="Carrier Validity Date"
          >
            <DatePicker disabled={!!shipmentFromSearch} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      )}
      {[MOVEMENT_MODE_OCEAN_IMPORT].includes(movementMode) && (
        <Col span={8}>
          <Form.Item name="lfd_at_pod" label="LFD Port">
            <DatePicker disabled={!!shipmentFromSearch} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      )}
      {[MOVEMENT_MODE_OCEAN_IMPORT].includes(movementMode) && (
        <Col span={8}>
          <Form.Item name="lfd_at_empty_return" label="LFD Empty Return">
            <DatePicker disabled={!!shipmentFromSearch} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      )}
      {[MOVEMENT_MODE_OCEAN_IMPORT, MOVEMENT_MODE_AIR_IMPORT, MOVEMENT_MODE_DOOR_TO_DOOR].includes(
        movementMode
      ) && (
        <Col span={8}>
          <Form.Item name="estimated_time_of_arrival" label="ETA at Port">
            <DatePicker disabled={!!shipmentFromSearch} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      )}
      {[MOVEMENT_MODE_OCEAN_EXPORT, MOVEMENT_MODE_AIR_EXPORT, MOVEMENT_MODE_DOOR_TO_DOOR].includes(
        movementMode
      ) && (
        <Col span={8}>
          <Form.Item name="estimated_time_of_departure" label="ETD at Port">
            <DatePicker disabled={!!shipmentFromSearch} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      )}
      {[MOVEMENT_MODE_OCEAN_EXPORT].includes(movementMode) && (
        <>
          <Col span={8}>
            <Form.Item
              name={['ocean_shipment_booking', 'cy_cutoff_date']}
              label="Gate Close Cutoff"
            >
              <DatePicker disabled={!!shipmentFromSearch} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </>
      )}
    </Row>
  );
}
