import { Alert, Button, Col, Modal, Row, Spin, Tag, Tooltip, message } from '@shipmnts/pixel-hub';
import { InquiryOptionValue } from 'operations/models/InquiryOption';
import { WarningOutlined, LinkRender } from '@shipmnts/pixel-hub';
import React, { useEffect, useState } from 'react';
import { DOC_TYPE_METHODS } from 'common';
import { useMutation } from '@apollo/client';
import { DELINK_INQUIRY_OPTION_FROM_RESOURCE } from 'operations/graphql/inquiryOption';
import { Dissconnect } from 'common/components/Tracking/Icons';

interface LinkInquiryOptionToResourceProps {
  resourceId: string;
  resourceType: string;
  inquiryOption?: InquiryOptionValue;
  onSucess?: () => void;
  disabled?: boolean;
  is_external_quotation_number?: boolean;
  quotation_number?: string | null;
}

function DeLinkInquiryOptionToResource(props: LinkInquiryOptionToResourceProps) {
  const [open, setOpen] = useState(false);
  const {
    resourceId,
    resourceType,
    inquiryOption,
    onSucess,
    disabled,
    is_external_quotation_number = false,
    quotation_number,
  } = props;
  const [delink, { data, loading, error }] = useMutation(DELINK_INQUIRY_OPTION_FROM_RESOURCE);
  const resourceDispayName = DOC_TYPE_METHODS[resourceType]?.display_name;
  useEffect(() => {
    if (data && !loading) {
      setOpen(false);
      if (onSucess) onSucess();
      message.success('Successfully De-linked.');
    }
    if (error) setOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  if (loading) return <Spin />;
  return (
    <>
      <Modal
        title={
          <div>
            <span style={{ marginRight: '8px' }}>
              <WarningOutlined style={{ color: 'orange' }} /> {/* Warning icon */}
            </span>
            Delink Quote from {resourceDispayName}
          </div>
        }
        open={open}
        onCancel={() => setOpen(false)}
        okText={`Delink Quote from ${resourceDispayName}`}
        onOk={() => {
          delink({
            variables: {
              resource_id: resourceId,
              resource_type: resourceType,
              delete_charges: true,
            },
          });
        }}
      >
        <div>
          <p>Are you sure you want to De-link quotation?</p>
          {!is_external_quotation_number && (
            <Alert
              message={`On Delinking Quote, all charges added from this quote will get removed from this
          ${resourceDispayName}`}
              type="warning"
            />
          )}
        </div>
      </Modal>
      <Tag color="blue">
        <Row>
          <Col span={18}>
            {is_external_quotation_number ? (
              <Tag color="cyan" style={{ marginTop: '5px' }}>
                {quotation_number}
              </Tag>
            ) : (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              <LinkRender
                value={inquiryOption}
                data={inquiryOption}
                value_field="quotation_number"
                id_field="id"
                doc_type_id="SalesHub::InquiryOption"
              />
            )}
          </Col>
          <Col span={4}>
            <Tooltip title="De-link Quotation">
              <Button
                disabled={disabled}
                type="link"
                style={{ fontSize: '10px' }}
                onClick={() => setOpen(true)}
              >
                <Dissconnect />
              </Button>
            </Tooltip>
          </Col>
        </Row>
      </Tag>
    </>
  );
}

export default DeLinkInquiryOptionToResource;
