/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree';

const UserContact = types.model({
  id: types.identifier,
  first_name: types.string,
  last_name: types.maybe(types.maybeNull(types.string)),
  email: types.maybe(types.maybeNull(types.string)),
  title: types.maybe(types.maybeNull(types.string)),
  department: types.maybe(types.maybeNull(types.array(types.string))),
  mobile_number: types.maybe(types.maybeNull(types.string)),
  is_disabled: types.maybe(types.maybeNull(types.boolean)),
  is_internal: types.maybe(types.maybeNull(types.boolean)),
  dial_code: types.maybe(types.maybeNull(types.string)),
  pretty_display: types.maybe(types.maybeNull(types.string)),
  name: types.maybe(types.maybeNull(types.string)),
});

export interface UserContactValue extends Instance<typeof UserContact> {}
export interface UserContactIn extends SnapshotIn<typeof UserContact> {}
export interface UserContactOut extends SnapshotOut<typeof UserContact> {}

export default UserContact;
