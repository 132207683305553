export const FREIGHT_TYPE_AIR = 'air';
export const FREIGHT_TYPE_OCEAN = 'ocean';
export const FREIGHT_TYPE_ROAD = 'road';
export const FREIGHT_TYPE_RAIL = 'rail';

export const LOAD_TYPE_LOOSE = 'loose';
export const LOAD_TYPE_ULD = 'ULD';
export const LOAD_TYPE_FCL = 'FCL';
export const LOAD_TYPE_LCL = 'LCL';
export const LOAD_TYPE_BREAKBULK = 'Breakbulk';
export const LOAD_TYPE_FTL = 'FTL';

export const LOAD_TYPE_OPTIONS: any = {
  [FREIGHT_TYPE_AIR]: [
    {
      label: 'Loose',
      value: 'loose',
    },
    {
      label: 'ULD',
      value: 'uld',
    },
  ],
  [FREIGHT_TYPE_OCEAN]: [
    {
      label: 'FCL',
      value: 'fcl',
    },
    {
      label: 'LCL',
      value: 'lcl',
    },
    {
      label: 'Breakbulk',
      value: 'breakbulk',
      disabled: true,
    },
  ],
  [FREIGHT_TYPE_ROAD]: [
    {
      label: 'FCL',
      value: 'fcl',
    },
    {
      label: 'FTL - Break Bulk',
      value: 'breakbulk',
    },
    {
      label: 'FTL - Bulk',
      value: 'bulk',
    },
    {
      label: 'FTL - Liquid',
      value: 'liquid',
    },
    {
      label: 'LTL',
      value: 'ltl',
    },
  ],
};

export const LOAD_TYPE_TOOLTIP_TEXT = {
  [FREIGHT_TYPE_OCEAN]:
    'Type of load such as Full Container Load (FCL), Less than container load (LCL), or Break Bulk',
  [FREIGHT_TYPE_AIR]: 'Type of load such as Loose Cargo or Cargo loaded on Unit Load Device',
};

export type TypeFreightType = 'ocean' | 'air' | 'road';
