import React from 'react';

// import { Divider } from '@shipmnts/pixel-hub';
// import { Typography } from '@shipmnts/pixel-hub';
import ProductOrderItemWrapper from '../../ProductOrderItem';
import { ProductOrderValue } from 'operations/models/ProductOrder';
import { getWithCustomFieldValues } from 'common';
interface ProductsTabProps {
  productOrder: ProductOrderValue;
}

const ProductsTab = (props: ProductsTabProps) => {
  const { productOrder } = props;
  return (
    <ProductOrderItemWrapper
      productOrderItems={getWithCustomFieldValues(productOrder.product_order_items)}
      editTable={false}
      type={productOrder.type || 'sales'}
      orderCurrency={productOrder?.order_currency || ''}
    />
  );
};

export default ProductsTab;
