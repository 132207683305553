import React, { useCallback, useContext, useEffect, useState } from 'react';
import { fetchErpNextConfigData } from 'network/erpnextApis';

declare type nameObj = {
  name: string;
};

export interface ERPNextConfigData {
  companies?: Array<nameObj>;
  currencies?: Array<nameObj>;
  sales_persons?: Array<nameObj>;
  default_currency?: string;
  receivable_accounts?: Array<nameObj>;
  payable_accounts?: Array<nameObj>;
  payment_terms_templates?: Array<nameObj>;
  receivable_tax_withholding_categories?: Array<nameObj>;
  payable_tax_withholding_categories?: Array<nameObj>;
}

export interface ERPNextContextType {
  fetching: boolean;
  erpnextConfigData?: ERPNextConfigData;
  fetchError?: string;
  fetchConfigData: (() => void) | undefined;
}

export const ERPNextContext = React.createContext<ERPNextContextType>({
  fetching: false,
  erpnextConfigData: undefined,
  fetchError: undefined,
  fetchConfigData: undefined,
});
export const useErpNextConfig = () => useContext(ERPNextContext);

export default function ErpNextConfigDataWrapper(props: { children: React.ReactNode }) {
  const [fetching, setFetching] = useState(false);
  const [erpnextConfigData, setErpNextConfigData] = useState<ERPNextConfigData | undefined>(
    undefined
  );
  const [fetchError, setFetchError] = useState<undefined | string>(undefined);

  const fetchConfigData = useCallback(async () => {
    setFetching(true);
    const result: {
      response?: { data: { message: ERPNextConfigData } };
      error?: string;
    } = await fetchErpNextConfigData();
    if (result.response) {
      setFetching(false);
      setFetchError(undefined);
      const config_data = result.response?.data.message;
      setErpNextConfigData(config_data);
    } else {
      setFetching(false);
      setFetchError(result.error);
    }
  }, []);

  useEffect(() => {
    fetchConfigData();
  }, [fetchConfigData]);

  return (
    <ERPNextContext.Provider value={{ fetching, erpnextConfigData, fetchError, fetchConfigData }}>
      {props.children}
    </ERPNextContext.Provider>
  );
}
