import React, { Component } from 'react';
import { Dropdown, message, Button, Modal } from '@shipmnts/pixel-hub';
import { errorMessageHandler } from 'common';
import { get as _get } from 'lodash';
import {
  docDependencyRule,
  DOCUMENT_TYPE_NEW_MAWB,
  DOCUMENT_TYPE_NEW_HAWB,
  DOCUMENT_TYPE_STUFFING_CONFIRMATION,
  STUFFING_TYPE_FACTORY,
  SHIPMENT_DOCUMENTS_RULES,
  DOCUMENT_TYPE_SEA_FREIGHT_MANIFEST,
  DOCUMENT_TYPE_AIR_FREIGHT_CERTIFICATE,
  DOCUMENT_TYPE_OCEAN_FREIGHT_CERTIFICATE,
  DOCUMENT_STATUS_PENDING_CREATION,
  DOCUMENT_TYPE_AIR_DELIVERY_ORDER,
  DOCUMENT_TYPE_OCEAN_DELIVERY_ORDER,
  DOCUMENT_TYPE_AIR_CARGO_ARRIVAL_NOTICE,
  DOCUMENT_TYPE_CARGO_ARRIVAL_NOTICE,
  DOCUMENT_TYPE_MBL,
  DOCUMENT_TYPE_HBL,
  PERMISSION_ISSUE_DO_WITH_BRO,
  DOCUMENT_TYPE_CONSIGNMENT_NOTE,
  DOCUMENT_TYPE_ANNEXURE_C,
} from 'operations/modules/reports/constants';
import {
  ACCOUNTING_STATUS_CLOSED,
  CUSTOMER_ORDER_TYPE,
  FREIGHT_TYPE_AIR,
  FREIGHT_TYPE_OCEAN,
} from 'operations/modules/shipment/constants';
import {
  getMasterDocument,
  isShipmentCancelled,
  getHouseDocument,
} from 'operations/modules/actionHelper/ShipmentActions/shipmentActionHelper';
import {
  fetchTemplates,
  createDocument,
  getGenerateDocumentPayload,
} from 'operations/modules/reports/components/ShipmentDocumentReports/document';
import { hasPermission } from '@shipmnts/pixel-hub';
import {
  DownOutlined,
  FolderOutlined,
  SyncOutlined,
  FileOutlined,
  LoadingOutlined,
} from '@shipmnts/pixel-hub';
import DuplicateAwbModal from 'operations/modules/reports/components/ShipmentDocumentReports/DuplicateAwbModal';
import { STOCK_MANAGEMENT_FEATURE } from 'operations/modules/reports/components/ShipmentDocumentReports/CreateDocument';
import Spinner from 'src/components/Spinner';

class AddDocumentDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchingTemplate: true,
      fetchTemplateError: false,
      creatingDoc: false,
      executionLoading: false,
      shipment_id: '',
      isDuplicateModalVisible: false,
      docType: '',
    };
  }

  toggleDuplicateModal = () => {
    this.setState((prevState) => ({
      isDuplicateModalVisible: !prevState.isDuplicateModalVisible,
      creatingDoc: false,
    }));
  };

  fetchTemplates = async () => {
    const { parent_shipment } = this.props;

    const { templates, error } = await fetchTemplates(
      parent_shipment.freight_type,
      parent_shipment.trade_type
    );
    this.setState({
      templates,
      fetchingTemplate: false,
      fetchTemplateError: Boolean(error),
    });
  };
  componentDidMount = async () => {
    this._mounted = true;
    this.fetchTemplates();
  };

  componentWillUnmount() {
    this._mounted = false;
  }

  handleCreateSuccess = (data, open_tab_type = '_self') => {
    const { generate_document } = data;
    if (generate_document?.is_new_document) {
      window.open(`${process.env.OPERATIONS_URL}/view/documents/${generate_document.id}`, '_blank');
    } else {
      window.open(
        `${process.env.SHIPMNTS_WEB_URL}/shipment/${generate_document.shipment_id}/documents_latest/${generate_document.id}`,
        '_blank'
      );
    }

    message.success('Document created successfully');
  };

  getParentDocIds({ createdDocList, creatingDocType }) {
    if (
      createdDocList.length &&
      creatingDocType &&
      docDependencyRule.hasOwnProperty(creatingDocType)
    ) {
      return createdDocList.reduce((acc, doc) => {
        if (docDependencyRule[creatingDocType].indexOf(doc.document_type) > -1) {
          acc = [...acc, doc.id];
        }
        return acc;
      }, []);
    }
    return [];
  }

  createErrorModal = (message, extraProps) => {
    const config = {
      title: 'Create Document Error',
      content: message,
      ...extraProps,
    };
    Modal.error(config);
  };

  checkDocumentCreationBlockingConditions = ({ creatingDocType, item, allowedMultiple }) => {
    const { createdDocList, parent_shipment, child_shipments, sessionData } = this.props;
    const { shipment_id } = item.props;
    let shipmentObj = parent_shipment;
    if (parent_shipment.id !== shipment_id)
      shipmentObj = child_shipments.find((shipment) => shipment.id === shipment_id);

    if (
      //if document with same shipment id already exist , throw error
      !allowedMultiple &&
      createdDocList.some(
        (createdDoc) =>
          createdDoc.document_type === creatingDocType && createdDoc.shipment_id === shipment_id
      )
    ) {
      this.createErrorModal('Document already exists');
      return false;
    }

    // Check stock created and is forwarding before creating MAWB
    if (
      sessionData.isFeatureEnabled(STOCK_MANAGEMENT_FEATURE) &&
      creatingDocType === DOCUMENT_TYPE_NEW_MAWB &&
      !parent_shipment.isStockAllocated()
    ) {
      this.createErrorModal('Please create stock order before creating AWB', {
        okText: 'Create Stock Order',
        onOk: () => {
          window.open(`/workspace?doc_type=Shipment::StockOrderItem`, '_self');
        },
        cancelText: 'Cancel',
      });
      return false;
    }

    if (
      [DOCUMENT_TYPE_NEW_MAWB, DOCUMENT_TYPE_NEW_HAWB, DOCUMENT_TYPE_HBL].includes(
        creatingDocType
      ) &&
      !shipmentObj.isForwardingShipment()
    ) {
      this.createErrorModal('Please select freight forwarding in the services section');
      return false;
    }

    if (
      [DOCUMENT_TYPE_AIR_DELIVERY_ORDER, DOCUMENT_TYPE_OCEAN_DELIVERY_ORDER].includes(
        creatingDocType
      ) &&
      !!shipmentObj.getToOrderBank()
    ) {
      if (
        !hasPermission(sessionData.permissions, {
          name: PERMISSION_ISSUE_DO_WITH_BRO,
          docType: 'External::Docgen::Document',
        })
      ) {
        this.createErrorModal('BRO Involved!! You dont have permissions to issue DO');
        return false;
      }
      const bro = shipmentObj.getBankReleaseOrder();
      if (!(bro && bro.event_number && !!bro.event_number !== '' && !!bro.event_date)) {
        this.createErrorModal('Please fill up the BRO details');
        return false;
      }
    }
    return true;
  };

  createDoc = async ({ item }) => {
    this.setState({
      creatingDoc: true,
    });
    const { template, shipment_id, shipment_type, shipment_document_id, open_tab_type } =
      item.props;
    const { createdDocList, sessionData, client } = this.props;
    const creatingDocType = template.document_type;
    const allowedMultiple = template?.allowed_multiple || false;
    const parentDocIds = this.getParentDocIds({ createdDocList, creatingDocType });

    if (!this.checkDocumentCreationBlockingConditions({ creatingDocType, item, allowedMultiple })) {
      this.setState({
        creatingDoc: false,
      });
      return;
    }

    //Add condition here for HAWB as well
    if (creatingDocType === DOCUMENT_TYPE_NEW_MAWB || creatingDocType === DOCUMENT_TYPE_NEW_HAWB) {
      this.toggleDuplicateModal();
      this.setState({
        shipment_id,
        docType: creatingDocType,
      });
      return; //Now shipment would be created by the DuplicateMawbModal Component so we're returning from here
    }

    const { params, initialPayload } = await getGenerateDocumentPayload({
      shipment: this.props.parent_shipment,
      house_shipments: this.props.child_shipments || [],
      shipment_id,
      shipment_type,
      shipment_document_id,
      document_type: creatingDocType,
      template,
      parentDocIds,
      user: sessionData,
      client,
    });
    const { response, error } = await createDocument({
      ...params,
      values: initialPayload,
    });

    if (response) {
      this.handleCreateSuccess(response.data, open_tab_type);
    } else if (error) {
      message.error(errorMessageHandler(error));
    }
    if (this._mounted) this.setState({ creatingDoc: false });
  };

  getDocumentListInShipment = ({ shipment, templates, creatingDoc, parent_shipment }) => {
    const { freight_type, trade_type, id: shipment_id, shipment_type } = shipment;
    const { createdDocList } = this.props;
    const { sessionData } = this.props;
    const subdomain = sessionData.company_account.subdomain;
    const stuffing_type = _get(parent_shipment, 'stuffing_type', '');
    // TODO: Fix this
    let ruleDocList = _get(
      SHIPMENT_DOCUMENTS_RULES,
      `${freight_type.toLowerCase()}.${trade_type}.${shipment_type || CUSTOMER_ORDER_TYPE}`,
      []
    );
    if (stuffing_type === STUFFING_TYPE_FACTORY)
      ruleDocList = ruleDocList.filter((rdl) => rdl !== DOCUMENT_TYPE_STUFFING_CONFIRMATION);
    if (!subdomain)
      ruleDocList = ruleDocList.filter(
        (rdl) =>
          ![
            DOCUMENT_TYPE_SEA_FREIGHT_MANIFEST,
            DOCUMENT_TYPE_AIR_FREIGHT_CERTIFICATE,
            DOCUMENT_TYPE_OCEAN_FREIGHT_CERTIFICATE,
          ].includes(rdl)
      );
    const house_document = getHouseDocument(shipment);
    if (house_document && house_document.document_status !== DOCUMENT_STATUS_PENDING_CREATION) {
      ruleDocList = ruleDocList.filter((rdl) => rdl !== DOCUMENT_TYPE_HBL);
    }
    const master_document = getMasterDocument(shipment);
    if (master_document && master_document.document_status !== DOCUMENT_STATUS_PENDING_CREATION) {
      // due to status change removing si_filled condition
      ruleDocList = ruleDocList.filter((rdl) => rdl !== DOCUMENT_TYPE_MBL);
    }
    //Removing Cargo Arrival Notice option for House Shipments not linked to master
    if (shipment.shipment_type === 'house' && !shipment.master_shipment_id) {
      ruleDocList = ruleDocList.filter(
        (rdl) =>
          (shipment.freight_type === FREIGHT_TYPE_AIR &&
            rdl !== DOCUMENT_TYPE_AIR_CARGO_ARRIVAL_NOTICE) ||
          (shipment.freight_type === FREIGHT_TYPE_OCEAN &&
            rdl !== DOCUMENT_TYPE_CARGO_ARRIVAL_NOTICE)
      );
    }

    if (!['manufacturer', 'trader'].includes(sessionData.company_account.primary_business)) {
      ruleDocList = ruleDocList.filter((rdl) => rdl !== DOCUMENT_TYPE_ANNEXURE_C);
    }

    return ruleDocList
      ? ruleDocList.reduce((acc, docType) => {
          const isAlreadyExist = createdDocList.some(
            (createdDoc) =>
              createdDoc.document_type === docType && createdDoc.shipment_id === shipment_id
          );
          const template = templates[docType];
          if (
            templates.hasOwnProperty(docType) &&
            (!isAlreadyExist || template?.allowed_multiple)
          ) {
            let shipment_document = _get(shipment, 'shipment_documents', []).find(
              (doc) => doc.document_type === template.shipment_document_type
            );
            if (docType === DOCUMENT_TYPE_CONSIGNMENT_NOTE) {
              const document_type = ['house', 'back_to_back'].includes(
                _get(shipment, 'shipment_type')
              )
                ? 'house'
                : 'master';
              shipment_document = _get(shipment, 'shipment_documents', []).find(
                (doc) => doc.document_type === document_type
              );
            }
            const menuItem = {
              disabled: creatingDoc,
              key: `${template.id}.${docType}.${shipment_id}.${shipment_type}`,
              icon: <FileOutlined />,
              label: template.display_name,
              shipment_document_id: _get(shipment_document, 'id'),
              template: template,
              shipment_id: shipment_id,
              shipment_type: shipment_type,
              open_tab_type:
                template?.document_type === DOCUMENT_TYPE_CONSIGNMENT_NOTE ? '_blank' : '_self',
            };
            acc = [...acc, menuItem];
          }
          return acc;
        }, [])
      : [];
  };
  dropDownMenu = () => {
    const { templates, fetchingTemplate, creatingDoc, fetchTemplateError } = this.state;
    const { parent_shipment, child_shipments } = this.props;
    let items = [];
    if (fetchingTemplate) {
      items = [
        { key: 'no_items', disabled: true, label: <Spinner size="small" tip="Fetching List" /> },
      ];
      return { items };
    }
    if (fetchTemplateError) {
      items = [
        {
          key: 'no_items',
          label: (
            <div>
              Refetch Document List <SyncOutlined />
            </div>
          ),
        },
      ];

      return { items, onClick: this.fetchTemplates };
    }
    if (!parent_shipment || !templates) {
      items = [
        {
          key: 'no_items',
          disabled: true,
          label: ' No items available',
        },
      ];
      return { items };
    }
    const getItems = () => {
      let items = this.getDocumentListInShipment({
        shipment: parent_shipment,
        parent_shipment,
        templates,
        creatingDoc,
      });
      if (child_shipments) {
        items = items.concat(
          child_shipments.map((child_shipment, index) => {
            return {
              key: `${index}-${child_shipment.id}`,
              label: `House ${child_shipment.job_number}`,
              icon: <FolderOutlined />,
              children: this.getDocumentListInShipment({
                shipment: child_shipment,
                parent_shipment,
                templates,
                creatingDoc,
              }),
            };
          })
        );
      }
      return { items, onClick: (!fetchingTemplate || !creatingDoc) && this.createDoc };
    };

    items = getItems();
    return items;
  };

  render() {
    const { creatingDoc, isDuplicateModalVisible, shipment_id } = this.state;
    const { parent_shipment } = this.props;
    const shipmentForDuplicate =
      parent_shipment.id === shipment_id
        ? parent_shipment
        : parent_shipment.house_shipments.find((hs) => hs.id === shipment_id);
    const disabled =
      isShipmentCancelled(parent_shipment) ||
      parent_shipment.accounting_status === ACCOUNTING_STATUS_CLOSED;

    if (disabled) return null;
    return (
      <div>
        <Dropdown menu={this.dropDownMenu()}>
          {creatingDoc ? (
            <Button type="primary">
              Creating doc...
              <LoadingOutlined />
            </Button>
          ) : (
            <Button type="primary">
              Add Document <DownOutlined />
            </Button>
          )}
        </Dropdown>
        {isDuplicateModalVisible && (
          <DuplicateAwbModal
            onClose={this.toggleDuplicateModal}
            shipment={shipmentForDuplicate}
            docType={this.state.docType}
          />
        )}
      </div>
    );
  }
}

export default AddDocumentDropdown;
