// options are like { minNumDigits: 2, maxNumDigits: 2 }
export function decimalPointFormater(value, options = {}) {
  const minNumDigits = isNaN(options.minNumDigits) ? 2 : options.minNumDigits;
  const maxNumDigits = isNaN(options.maxNumDigits) ? 2 : options.maxNumDigits;
  let parsedValue = parseFloat(value);
  if (isNaN(parsedValue)) return '';
  return parsedValue.toLocaleString('en-IN', {
    minimumFractionDigits: minNumDigits,
    maximumFractionDigits: maxNumDigits,
  });
}

export function roundToTwoDecimals(num) {
  return Math.round((num + 0.00001) * 100) / 100;
}

export function roundToDecimals(num, decimalPoint) {
  if (isNaN(decimalPoint) || decimalPoint < 1)
    throw new Error('Invalid decimal points for precision.');

  const decimalPointValue = Math.pow(10, decimalPoint);
  const inverseDecimalPointVal = 1 / Math.pow(10, decimalPoint + 1);

  return (
    Math.sign(num) *
    (Math.round((Math.abs(num) + inverseDecimalPointVal) * decimalPointValue) / decimalPointValue)
  );
}
