import React, { useCallback, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { message, Row, Col, Input, Drawer, Form } from '@shipmnts/pixel-hub';
import { UPDATE_SHIPMENT_CREDIT_STATUS } from 'operations/modules/reports/graphql/shipmentReport';
import { useSession } from 'common';

import { DrawerFooter } from '@shipmnts/pixel-hub';
import {
  CREDIT_STATUS_APPROVED_EVENT,
  CREDIT_STATUS_APPROVED,
} from 'operations/modules/shipment/constants';
import { GlobalSearch } from '@shipmnts/pixel-hub';
interface ShipmentCreditApprovalProps {
  shipment_id: string;
  shipment_type?: string;
  job_number?: string | null;
  onSuccess?: (id: string) => void;
  onClose?: () => void;
  action_name: 'manually_on_hold_credit' | 'manually_approve_credit';
}

const ShipmentCreditApproval = React.memo(function ShipmentCreditApproval(
  props: ShipmentCreditApprovalProps
): JSX.Element {
  const { shipment_id, onSuccess, onClose, action_name, job_number } = props;
  const [form] = Form.useForm();
  const text = action_name === CREDIT_STATUS_APPROVED_EVENT ? 'Approve Credit' : 'Mark as On Hold';
  const [approveCreditForShipmentMutation, { data, loading, error }] = useMutation(
    UPDATE_SHIPMENT_CREDIT_STATUS
  );
  const ROW_STYLE = { marginBottom: '15px' };
  const ROW_GUTTER = 15;
  const COL_SPAN = 24;

  const session = useSession();

  useEffect(() => {
    if (error) {
      message.error(error?.message);
    } else if (data?.update_shipment_credit_status?.credit_status) {
      message.success(
        `Shipment ${
          data?.update_shipment_credit_status?.credit_status === CREDIT_STATUS_APPROVED
            ? 'Approved'
            : 'On Hold'
        } Successfully `
      );
      const renderEmailEvent = data?.update_shipment_credit_status?.credit_events;
      if (renderEmailEvent && onSuccess) {
        const sorted_events = [...renderEmailEvent].sort((a, b) => b.event_date - a.event_date);
        const recent_event = sorted_events?.[0];
        onSuccess(recent_event?.id);
      }
    }
  }, [data, error, onSuccess]);

  const handleCancel = () => {
    if (onClose) onClose();
  };

  const handleSubmit = useCallback(() => {
    const variables = {
      id: shipment_id,
      requested_by_id: form.getFieldValue('requested_by_id')?.id,
      reason: form.getFieldValue('reason'),
      remarks: form.getFieldValue('remarks'),
      action: action_name,
    };
    approveCreditForShipmentMutation({ variables });
  }, [approveCreditForShipmentMutation, action_name, form, shipment_id]);

  return (
    <Drawer
      open={true}
      title={`${text} - ${job_number}`}
      width="30%"
      footer={
        <DrawerFooter
          saveText={text}
          loading={loading}
          onClose={handleCancel}
          onSave={form.submit}
        />
      }
    >
      <Form form={form} layout={'vertical'} onFinish={handleSubmit}>
        <Row style={ROW_STYLE} gutter={ROW_GUTTER}>
          <Col span={COL_SPAN}>
            <Form.Item name={'requested_by_id'} label="Requested By" rules={[{ required: true }]}>
              <GlobalSearch
                doc_type="Network::UserContact"
                extraProps={{
                  company: session?.company_account?.default_company,
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row style={ROW_STYLE} gutter={ROW_GUTTER}>
          <Col span={COL_SPAN}>
            <Form.Item name={'reason'} label="Reason" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row style={ROW_STYLE} gutter={ROW_GUTTER}>
          <Col span={COL_SPAN}>
            <Form.Item name={'remarks'} label="Remarks" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
});

export default ShipmentCreditApproval;
