import RenameCompanyModal from 'network/components/RenameCompanyModal';
import { PerformAction } from '../models';
import AddCollaborators from 'network/components/collaborators/AddCollaborators';
import { CreateInquiryFromLead, UpdateLeadAction } from 'sales_hub';

export const addCollaboratorsRenderer = (payload: any): PerformAction => {
  const { company } = payload;
  return {
    actionParams: {
      referenceId: company.id,
      referenceType: 'Network::Company',
      id: company?.registered_name,
    },
    component: AddCollaborators,
  };
};

export const performCompanyAction = (payload: any): PerformAction => {
  const { company, extraProps } = payload;
  return {
    actionParams: {
      initialValue: company,
      action: extraProps?.action,
      type: extraProps?.type,
      title: extraProps?.title || 'Edit Form',
    },
    component: UpdateLeadAction,
  };
};

export const performRenameAction = (payload: any): PerformAction => {
  const { company } = payload;
  return {
    actionParams: {
      company: company,
    },
    component: RenameCompanyModal,
  };
};

export const performCreateInquiryAction = (payload: any): PerformAction => {
  const { company } = payload;
  return {
    actionParams: {
      company: company,
    },
    component: CreateInquiryFromLead,
  };
};
