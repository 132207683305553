import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Modal, Radio, Row, message } from '@shipmnts/pixel-hub';
import { MERGE_BOOKINGS } from 'operations/modules/booking/graphql/oceanTransportOrder';
import { errorMessageHandlerGraphQL } from 'common';

export interface Bookings {
  booking_number: string;
  booking_id: string;
}

const MergeBooking = React.memo(function MergeBooking(props: {
  bookings: Bookings[];
  setVisible: (value: boolean) => void;
  onSuccess?: () => void;
}): JSX.Element {
  const { bookings, setVisible, onSuccess } = props;
  const [selectedBooking, setSelectedBooking] = useState<string>(bookings[0].booking_id);

  const [mergeBookings, { data, loading, error }] = useMutation(MERGE_BOOKINGS);

  useEffect(() => {
    if (!error && data?.merge_bookings?.message) {
      message.success('Bookings Merged Successfully !');
      setVisible(false);
      if (onSuccess) onSuccess();
    }
  }, [error, data, onSuccess, setVisible]);

  return (
    <Modal
      title="Do you want to merge bookings?"
      open
      okText="Confirm Merge"
      okButtonProps={{ loading }}
      onOk={() =>
        mergeBookings({
          variables: {
            target_ocean_transport_order_id: selectedBooking,
            ocean_transport_order_ids: bookings.map((b) => b.booking_id),
          },
        })
      }
      onCancel={() => setVisible(false)}
    >
      {error && errorMessageHandlerGraphQL(error)}
      <p>Select the Booking into which other bookings will be merged</p>
      <Radio.Group onChange={(e) => setSelectedBooking(e.target.value)} value={selectedBooking}>
        {bookings.map((booking) => (
          <Row key={booking.booking_id}>
            <Radio value={booking.booking_id}>{booking.booking_number}</Radio>
          </Row>
        ))}
      </Radio.Group>
    </Modal>
  );
});

export default MergeBooking;
