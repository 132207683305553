import React, { useEffect } from 'react';
import { Drawer, Form, Button, Space, message } from '@shipmnts/pixel-hub';
import { FormOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { UPSERT_COMPANY } from 'network/graphql/company';
import { UpsertCompanyFormValues } from 'network/components/CompanyForm';
import LeadForm from './LeadForm';
import './lead.less';
import { LEAD, LEAD_STAGE } from 'sales_hub/constants';

interface PayloadType extends Partial<UpsertCompanyFormValues> {
  contacts?: Array<any>;
  sales_partner_id?: string | null;
  entity_type?: string | null;
  company_stage?: string;
  lead_owner_id?: number;
  remarks?: string;
  pipeline_id?: string;
  current_state_id?: string;
  addresses?: any;
}

type Props = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  onSuccess: (value?: any) => void;
  data?: any;
};

const CreateLeadDrawer = (props: Props) => {
  const { visible, setVisible } = props;
  const [upsertCompany, { data, loading }] = useMutation(UPSERT_COMPANY);
  const [form] = Form.useForm();

  const onClose = () => {
    form.resetFields();
    setVisible && setVisible(false);
  };

  useEffect(() => {
    if (data?.upsert_company?.company) {
      message.success({
        content: (
          <>
            Lead Created Successfully,{' '}
            <span style={{ color: 'blue', cursor: 'pointer' }}>
              <u>Click here to view</u>
            </span>
          </>
        ),
        duration: 5,
        onClick: () => window.open(`/view/company/${data.upsert_company.company.id}`, '_blank'),
      });
      form.resetFields();
      setVisible && setVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, form]);

  function getUpsertCompanyPayload(formValues: any) {
    const payload: PayloadType = {
      company_type: LEAD,
      company_stage: LEAD_STAGE,
      registered_name: formValues?.registered_name,
      country_of_incorporation: formValues?.country_of_incorporation,
      company_group: formValues?.company_group,
      domain: formValues?.domain,
      entity_type: formValues?.entity_type,
      sales_partner_id: formValues?.sales_partner?.id,
      lead_owner_id: parseInt(formValues?.lead_owner?.id),
      remarks: formValues?.remarks,
      contacts:
        formValues.email || formValues.mobile_number?.number
          ? [
              {
                email: formValues.email,
                first_name: formValues.first_name,
                last_name: formValues.last_name,
                department: formValues.department,
                mobile_number: formValues.mobile_number?.number,
                dial_code: formValues.mobile_number?.dialCode,
              },
            ]
          : [],
      pipeline_id: formValues.pipeline === 'is_deleted' ? null : formValues.pipeline,
      current_state_id: formValues.pipeline_status,
    };

    if (!!formValues?.address && formValues?.address.print_address) {
      const address_entity_type = formValues?.address?.is_billing ? 'billing' : 'shipping';
      const city_id = formValues?.address?.city?.id;
      delete formValues?.address?.city;
      delete formValues?.address?.is_billing;
      payload.addresses = [
        {
          ...formValues?.address,
          entity_type: address_entity_type,
          city_id,
        },
      ];
    }

    if (
      !!!formValues?.email &&
      !!!formValues?.mobile_number?.mobile &&
      (!!formValues?.first_name || !!formValues?.last_name || !!formValues.department)
    ) {
      message.error('Contact Name cannot be saved without any contact');
    }

    return payload;
  }

  const handleFormSubmit = (values: any) => {
    const payload = getUpsertCompanyPayload(values);
    upsertCompany({ variables: { company: payload } });
  };

  return (
    <Drawer
      title={
        <>
          <FormOutlined /> Create New Lead
        </>
      }
      placement="right"
      closable={false}
      onClose={onClose}
      bodyStyle={{
        padding: '8px',
        backgroundColor: '#fafafa',
      }}
      open={visible}
      width={720}
      footer={
        <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            loading={loading}
            type="primary"
            onClick={() => {
              form.submit();
            }}
          >
            Create
          </Button>
        </Space>
      }
    >
      <Form layout="vertical" form={form} onFinish={handleFormSubmit}>
        <LeadForm form={form} />
      </Form>
    </Drawer>
  );
};

export default CreateLeadDrawer;
