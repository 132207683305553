import React from 'react';
import { roundToDecimals, decimalPointFormater } from 'common';
import { Tooltip } from '@shipmnts/pixel-hub';

export const formatAmount = (amt, defaultDecimalPoint = 2, minNumDigits) => {
  return decimalPointFormater(roundToDecimals(amt, defaultDecimalPoint), {
    minNumDigits: isNaN(minNumDigits) ? defaultDecimalPoint : minNumDigits,
    maxNumDigits: defaultDecimalPoint,
  });
};

export const renderActualSymbol = () => {
  return (
    <Tooltip title="Actual Amount">
      <span style={{ cursor: 'help' }} className="margin-right-xs">
        {'(A)'}
      </span>
    </Tooltip>
  );
};

export const renderAmountWithCurrency = (
  value,
  currency,
  defaultDecimalPoint = 2,
  show_actual_symbol = false
) => {
  if (value === null || value === undefined || isNaN(value)) return '';
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
      <div>{currency}</div>
      <div style={{ marginLeft: '10px', fontWeight: '500' }}>
        {show_actual_symbol && renderActualSymbol()}
        {formatAmount(value, defaultDecimalPoint)}
      </div>
    </div>
  );
};

export const renderMultipleCurrencyAmount = (charges) => {
  if (charges && charges.length > 0) {
    const totalWithCurrency = charges.reduce((total, charge) => {
      if (!isNaN(charge.amount) && charge.currency) {
        total[charge.currency] = total[charge.currency]
          ? total[charge.currency] + charge.amount
          : charge.amount;
      }
      return total;
    }, {});
    return Object.keys(totalWithCurrency).map((currency) =>
      renderAmountWithCurrency(totalWithCurrency[currency], currency)
    );
  }
  return '';
};

export const renderAmount = (
  value,
  defaultDecimalPoint = 2,
  minNumDigits,
  show_actual_symbol = false
) => {
  if (value === null || value === undefined || isNaN(value)) return '';
  return (
    <div style={{ textAlign: 'right' }}>
      {show_actual_symbol && renderActualSymbol()}
      {formatAmount(value, defaultDecimalPoint, minNumDigits)}
    </div>
  );
};
