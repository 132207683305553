import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  InputNumber,
  Input,
  Modal,
  Space,
  message,
  Tooltip,
  Form,
  dayjs,
  DatePicker,
} from '@shipmnts/pixel-hub';
import { QuestionCircleOutlined } from '@shipmnts/pixel-hub';
import { PlusOutlined, MinusCircleOutlined } from '@shipmnts/pixel-hub';
import { CompanySearch } from 'common';
import { padStart, isInteger, get as _get, omit as _omit } from 'lodash';
import { useMutation, useLazyQuery } from '@apollo/client';
import { CREATE_STOCK_ORDER_ITEM, FETCH_PORT_HANDLING_QUERY } from '../../graphql/StockOrder';
import { SupplierCompany } from './stockManagementModel';
import { RuleObject } from '@shipmnts/pixel-hub';
import { LocationValue } from 'operations/models/Location';
import { GlobalSearch } from '@shipmnts/pixel-hub';

interface AwbStockFormProps {
  handleCancel: () => void;
  onSuccess: () => void;
}
export default function AwbStockForm(props: AwbStockFormProps) {
  const [form] = Form.useForm();
  const { handleCancel, onSuccess } = props;
  const [createStockOrderItem, { data, loading, error }] = useMutation(CREATE_STOCK_ORDER_ITEM);
  const [showNotFoundPrefixModal, setShowNotFoundPrefixModal] = useState<boolean>(false);
  const [prefix, setPrefix] = useState<string | undefined>();
  const [renderer, setRenderer] = useState<boolean>(false);
  const [freezeSupplierAndBillingBranch, setFreezeSupplierAndBillingBranch] =
    useState<boolean>(false);
  const [supplier, setSupplier] = useState<SupplierCompany | undefined>(undefined);
  const [airlineId, setAirlineId] = useState<string>();
  const [portIds, setPortIds] = useState<string[]>([]);
  const toolTipText = freezeSupplierAndBillingBranch
    ? 'Prefilled based on selected Airline and Port(s)'
    : null;

  const [
    fetchPortHandling,
    {
      data: fetchPortHandlingData,
      loading: fetchPortHandlingLoading,
      error: fetchPortHandlingError,
    },
  ] = useLazyQuery(FETCH_PORT_HANDLING_QUERY);

  const disabledDate = (current: any) => {
    return current && current.valueOf() > Date.now();
  };

  useEffect(() => {
    if (data?.create_stock_order?.id) {
      message.success('sucessfully created AWB Stock');
      Modal.destroyAll();
      setSupplier(undefined);
      setPrefix(undefined);
      setFreezeSupplierAndBillingBranch(false);
      form.resetFields();
      handleCancel();
      if (onSuccess) onSuccess();
    }
    if (error) {
      message.error(error.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, error]);

  useEffect(() => {
    if (airlineId && portIds.length > 0) {
      prefilFieldsFromPortHandling(airlineId, portIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [airlineId, portIds]);

  useEffect(() => {
    if (fetchPortHandlingLoading) {
      return;
    }
    if (fetchPortHandlingError) {
      message.error(fetchPortHandlingError.message);
      return;
    }
    if (fetchPortHandlingData?.fetch_port_handling) {
      if (fetchPortHandlingData.fetch_port_handling.length > 0) {
        form.setFieldsValue({
          supplier_company_id: fetchPortHandlingData.fetch_port_handling[0].supplier_company,
          billing_branch_id: fetchPortHandlingData.fetch_port_handling[0].billing_branch,
        });
        setFreezeSupplierAndBillingBranch(true);
        setSupplier(fetchPortHandlingData.fetch_port_handling[0].supplier_company);
      } else {
        form.setFieldsValue({
          supplier_company_id: undefined,
          billing_branch_id: null,
        });
        setFreezeSupplierAndBillingBranch(false);
        setSupplier(undefined);
      }
    }
  }, [fetchPortHandlingData, fetchPortHandlingError, fetchPortHandlingLoading, form]);

  const prefilFieldsFromPortHandling = async (airlineId: string, portIds: string[]) => {
    portIds = portIds.filter((portId) => portId !== null);

    fetchPortHandling({
      variables: { handled_for_id: airlineId, global_port_ids: portIds },
    });
  };

  const generateCheckSumDigit = (mawbNumber: string) => {
    return parseInt(mawbNumber.substr(3, 7)) % 7;
  };

  function getLastMawbNumber(index: number) {
    const stock_order_item = form.getFieldValue('stock_order_items');
    const mawbNumber = stock_order_item[index]?.awb_number;
    const quantity = stock_order_item[index]?.quantity;
    validateMAWB(mawbNumber);
    if (quantity <= 0) throw new Error('Quantity should be greater than zero');
    if (!isInteger(quantity)) throw new Error('Quantity should be integer');
    const strMawbNumber = mawbNumber.toString();
    const prefix = strMawbNumber.slice(0, -1);
    const prefixStr = Number(prefix.substr(3)) + quantity - 1 + '';
    if (prefixStr.length >= 11) throw new Error(`Quantity is greater than expected`);
    const checkSumDigit = generateCheckSumDigit(prefix.substr(0, 3) + prefixStr);
    return prefix.substr(0, 3) + padStart(prefixStr, 7, '0') + checkSumDigit;
  }

  function getLastMawbNumberWrapper(index: number) {
    try {
      return getLastMawbNumber(index);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  const closeNotFoundPrefixModal = () => {
    setShowNotFoundPrefixModal(false);
  };

  const renderMawbPrefixNotFoundModal = () => {
    return (
      <Modal
        title="AWB Prefix Not Found"
        open={showNotFoundPrefixModal}
        footer={[
          <Button key="cancel" type="primary" onClick={closeNotFoundPrefixModal}>
            Ok
          </Button>,
        ]}
      >
        <p>We could not find IATA AWB Prefix for the selected airline.</p>
        <p>Please contact support@shipmnts.com .</p>
      </Modal>
    );
  };

  function generateNextMawbNumbers(mawbNumber: string, quantity: number) {
    validateMAWB(mawbNumber);
    if (quantity <= 0) throw new Error('Quantity should be greater than zero');
    if (!isInteger(quantity)) throw new Error('Quantity should be integer');
    const strMawbNumber = mawbNumber.toString();
    let prefix = strMawbNumber.slice(0, -1);
    const mawbNumbers: any[] = [];
    const newPrefix = prefix.substr(0, 3);
    for (let i = 0; i < quantity; i++) {
      mawbNumbers.push(newPrefix + prefix.substr(3) + generateCheckSumDigit(prefix));
      prefix = prefix.substr(0, 3) + padStart(Number(prefix.substr(3)) + 1 + '', 7, '0');
      if (prefix.length >= 11) throw new Error(`Quantity is greater than expected`);
    }
    return mawbNumbers;
  }

  function validateMAWB(str: string) {
    if (!str) throw new Error('value not provided');
    if (typeof str !== 'string' && typeof str !== 'number')
      throw new Error('should only contain numbers');
    const strval = str.toString();
    const numberRegex = new RegExp('^[0-9]+$');
    if (!numberRegex.test(str)) throw new Error('should only contain numbers');
    if (strval.length !== 11) throw new Error('should be 11 digits');
    const lst = strval.substr(10, 1);
    if (generateCheckSumDigit(strval) === parseFloat(lst)) {
      return true;
    } else {
      throw new Error("The check digit doesn't match");
    }
  }

  function MawbFormValidator(rule: RuleObject, value: string, callback: any, required = true) {
    try {
      if (!value && required) {
        validateMAWB(value);
      } else if (value) {
        validateMAWB(value);
      }
      callback();
    } catch (e) {
      callback(e);
    }
  }

  const mawbPrefixValidation = (
    rule: RuleObject,
    value: string,
    callback: any,
    prefix: string | undefined
  ) => {
    try {
      if (!value || value.substring(0, 3) !== prefix)
        throw new Error('Iata Awb prefix not matched with selected Airline');
      callback();
    } catch (e) {
      callback(e);
    }
  };

  const setAwbPrefix = (carrier: any) => {
    const stock_order_items = form.getFieldValue('stock_order_items');
    const iata_awb_prefix = _get(carrier, 'iata_awb_prefix');
    setPrefix(iata_awb_prefix);

    let showNotFoundPrefixModal = false;
    if (!iata_awb_prefix && carrier) {
      showNotFoundPrefixModal = true;
    }
    setAirlineId(_get(carrier, 'id'));
    setFreezeSupplierAndBillingBranch(false);
    setShowNotFoundPrefixModal(showNotFoundPrefixModal);
    setSupplier(undefined);
    Object.keys(stock_order_items).map(
      (id) => (stock_order_items[id]['awb_number'] = iata_awb_prefix)
    );
  };

  return (
    <>
      <Form
        onFinish={(values: any) => {
          let payload = Object.assign({}, values);
          const mawbNumbers = values.stock_order_items.reduce((acc: any, currVal: any) => {
            return [...acc, ...generateNextMawbNumbers(currVal.awb_number, currVal.quantity)];
          }, []);
          payload.stock_order_items = mawbNumbers.map((number: string) => {
            return { awb_number: number };
          });
          payload.billing_branch_id = payload.billing_branch_id.id;
          payload.supplier_company_id = payload.supplier_company_id.id;
          payload.airline_id = payload.airline_id.id;
          payload.ports = values.ports.map((port: LocationValue) => port.id);
          payload.quantity = payload.stock_order_items.length;
          payload.received_date = payload.received_date.unix();
          payload = _omit(payload, ['_ports', 'iata_awb_prefix']);
          createStockOrderItem({
            variables: {
              stock_order: payload,
            },
          });
        }}
        initialValues={{ received_date: dayjs() }}
        name="awb_stock_form"
        layout="vertical"
        form={form}
        style={{ minWidth: '800px' }}
      >
        {showNotFoundPrefixModal && renderMawbPrefixNotFoundModal()}
        <Row gutter={22} style={{ marginBottom: '25px' }}>
          <Col span={8}>
            <Form.Item
              required
              label="Airline"
              rules={[{ required: true, message: 'Please select a field to filter' }]}
              name="airline_id"
            >
              <GlobalSearch
                doc_type="Global::Carrier"
                searchProps={{ carrier_type: ['air'] }}
                onChange={(value: any) => {
                  setAwbPrefix(value);
                }}
                selectMode={undefined}
              />
            </Form.Item>
          </Col>
          <Col span={14}>
            <Form.Item
              name="ports"
              label="Issued for Ports (Port of Loading)"
              required
              rules={[{ required: true, message: 'Please select a field to filter' }]}
            >
              <GlobalSearch
                doc_type="Global::Location"
                onChange={(e: any) => {
                  const ports = form.getFieldValue('ports');
                  let portIds = [];
                  if (ports.length > 0) {
                    portIds = ports.map((elem: LocationValue) => _get(elem, 'id'));
                  }
                  setPortIds(portIds);
                  setFreezeSupplierAndBillingBranch(false);
                  setSupplier(undefined);
                }}
                searchProps={{ type: ['Airport'] }}
                selectMode="multiple"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ marginBottom: '10px', fontWeight: 700 }} gutter={22}>
          <Col span={8}>Starting AWB Number</Col>
          <Col span={4}>No of AWBs</Col>
          <Col span={5}>Ending AWB No</Col>
        </Row>
        <Row style={{ marginBottom: '10px' }} gutter={22}>
          <Form.List initialValue={[{ awb_number: prefix, quantity: 1 }]} name="stock_order_items">
            {(formFields, { add, remove }) => (
              <>
                {formFields.map((formField, index) => (
                  <>
                    <Col span={8}>
                      <Form.Item
                        name={[formField.name, 'awb_number']}
                        rules={[
                          {
                            validator: (rule, value, callback) =>
                              MawbFormValidator(rule, value, callback),
                          },
                          {
                            validator: (rule, value, callback) =>
                              mawbPrefixValidation(rule, value, callback, prefix),
                          },
                        ]}
                      >
                        <Input
                          disabled={!prefix}
                          className="ant-input-number-mawb-wrapper"
                          required
                          onChange={(e) => setRenderer(!renderer)}
                          placeholder="Please Enter a Value"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item name={[formField.name, 'quantity']}>
                        <InputNumber
                          className="ant-input-number-mawb-wrapper"
                          required
                          onChange={(e) => setRenderer(!renderer)}
                          placeholder="Please Enter a Value"
                          max={500}
                          min={1}
                          precision={0}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={5}>{getLastMawbNumberWrapper(formField.name)}</Col>
                    <Col
                      span={2}
                      style={{ color: 'indianred', cursor: 'pointer' }}
                      onClick={() => {
                        remove(formField.name);
                      }}
                    >
                      {formFields.length > 1 ? <MinusCircleOutlined /> : <></>}
                    </Col>
                  </>
                ))}
                <Col span={22}>
                  <Button
                    onClick={() => {
                      add({ awb_number: prefix });
                    }}
                    style={{ color: '#008eff', marginBottom: '10px' }}
                    icon={<PlusOutlined />}
                  >
                    Add More Stock Entry
                  </Button>
                </Col>
              </>
            )}
          </Form.List>
        </Row>
        <Row gutter={22} style={{ marginBottom: '20px' }}>
          <Col span={8}>
            <Form.Item
              label={
                freezeSupplierAndBillingBranch ? (
                  <>
                    Supplier &nbsp;
                    <span>
                      <Tooltip title={toolTipText}>
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </span>
                  </>
                ) : (
                  'Supplier'
                )
              }
              required
              rules={[{ required: true, message: 'Please select a field to filter' }]}
              name="supplier_company_id"
            >
              {freezeSupplierAndBillingBranch ? (
                <span>{supplier?.registered_name}</span>
              ) : (
                <CompanySearch searchProps={{ is_vendor: true }} selectMode={undefined} />
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              required
              label={
                freezeSupplierAndBillingBranch ? (
                  <>
                    CSR Branch &nbsp;
                    <span>
                      <Tooltip title={toolTipText}>
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </span>
                  </>
                ) : (
                  'CSR Branch'
                )
              }
              rules={[{ required: true, message: 'Please select a field to filter' }]}
              name="billing_branch_id"
            >
              <GlobalSearch
                doc_type="Network::BranchAccount"
                disabled={freezeSupplierAndBillingBranch}
                selectMode={undefined}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              required
              label="Received Date"
              rules={[{ required: true, message: 'Please select a field to filter' }]}
              name="received_date"
            >
              <DatePicker
                style={{ minWidth: 150 }}
                format="DD-MM-YYYY"
                disabledDate={disabledDate}
              />
            </Form.Item>
          </Col>
        </Row>
        <div className="flex-row report-modal-footer">
          <Space style={{ marginLeft: 'auto' }}>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button type="primary" htmlType={'submit'} key="save" style={{ marginLeft: '10px' }}>
              Save AWB Stock in Entry
            </Button>
          </Space>
        </div>
      </Form>
    </>
  );
}
