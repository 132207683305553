import { DocFormValue } from 'common/models/DocForm';

export function extractInitialValues(form: DocFormValue) {
  return form.fields.reduce((initialValueHash: Record<string, any>, field) => {
    if (field.default !== null && field.default !== undefined) {
      let defaultValue: any = field.default;
      if (field.field_type === 'Boolean') defaultValue = defaultValue === 'true';
      initialValueHash[field.field_name] = defaultValue;
    }
    return initialValueHash;
  }, {});
}
