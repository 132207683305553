import {
  VesselSearch,
  CompanySearch,
  VoyageScheduleSearch,
  clientSideDateFilter,
  clientSideDateSort,
} from 'common';
// import { SalesPerson } from 'common';
import { Column } from 'operations/models/Report';
import {
  FILTER_VESSEL_VOYAGE_NUMBER,
  FILTER_ETD,
  FILTER_VESSEL,
  FILTER_VOYAGE_NUMBER,
} from './constants';
import { DatePicker, FormItemProps, Input } from '@shipmnts/pixel-hub';
import { getHBLNumbersText, getHouseJobNumbersText, getJobNumbersText } from './oldCellRenderers';
import { GlobalSearch } from '@shipmnts/pixel-hub';

const { RangePicker } = DatePicker;
export const getDateObject = (
  headerName: string,
  field: string,
  filterKey: string,
  sortable?: boolean,
  blankFilterDisplayName?: string
) => ({
  headerName,
  field,
  colId: field,
  filterKey,
  filter: 'agDateColumnFilter',
  minWidth: 100,
  filterParams: {
    filterOptions: [
      'inRange',
      'equals',
      'greaterThanOrEqual',
      {
        displayKey: 'isNull',
        displayName: blankFilterDisplayName || 'Blank',
        predicate: (filterValue: any, cellValue: any) => !Boolean(cellValue),
        numberOfInputs: 0,
      },
    ],
    suppressAndOrCondition: true,
    clearButton: true,
    debounceMs: 500,
    comparator: (filterLocalDateAtMidnight: any, cellValue: number) => {
      return clientSideDateFilter(filterLocalDateAtMidnight, cellValue);
    },
  },
  ...(sortable !== undefined ? { sortable } : {}),
  comparator: (valueA?: string, valueB?: string) => {
    return clientSideDateSort(valueA, valueB);
  },
});

export const getSalesPerson = (headerName: string) => ({
  headerName: headerName,
  field: 'sales_person',
  colId: 'sales_person',
  filterKey: 'sales_person',
  minWidth: 120,
  filter: 'CustomFilterWrapper',
  floatingFilterComponent: 'NewFloatingFilter',
  floatingFilterComponentParams: {
    valuePropName: 'name',
    suppressFilterButton: true,
  },
  filterParams: {
    component: GlobalSearch,
    componentProps: {
      doc_type: 'Network::UserContact',
      selectMode: 'multiple',
    },
  },
});

export const add_row_groups = (colDefs: Array<Column>, pivotCols: string[]) => {
  return colDefs.map((col) => {
    const index = pivotCols.findIndex((p) => p === col.colId);
    if (index !== -1) {
      col.rowGroup = true;
      col.rowGroupIndex = index;
    }
    return col;
  });
};

export const getShippingLine = (headerName: string, field: string, filterKey: string) => ({
  headerName: headerName,
  field: field,
  colId: field,
  filterKey: filterKey,
  minWidth: 120,
  filter: 'CustomFilterWrapper',
  floatingFilterComponent: 'NewFloatingFilter',
  floatingFilterComponentParams: {
    valuePropName: 'name',
    suppressFilterButton: true,
  },
  filterParams: {
    component: GlobalSearch,
    componentProps: {
      doc_type: 'Global::Carrier',
      selectMode: 'multiple',
      searchProps: { carrier_type: ['ocean', 'nvocc'] },
    },
  },
});

const customer = {
  headerName: 'Customer',
  field: 'customer_company.registered_name',
  colId: 'customer_company.registered_name',
  filterKey: 'customer_company_id',
  minWidth: 200,
  filter: 'CustomFilterWrapper',
  floatingFilterComponent: 'NewFloatingFilter',
  floatingFilterComponentParams: {
    valuePropName: 'registered_name',
    suppressFilterButton: true,
  },
  filterParams: {
    component: CompanySearch,
    componentProps: {
      selectMode: 'multiple',
      searchProps: {
        is_customer: true,
        is_disabled: [true, false],
      },
      renderViewType: 'NewTab',
    },
  },
};

export const reportCommonFields: { [key: string]: Column } = {
  customer,
  customer_with_hot_shipment: {
    ...customer,
    cellRenderer: 'hotShipmentRenderer',
  },
  shipping_line: getShippingLine('Shipping Line', 'global_carrier.name', 'global_carrier_id'),
  estimated_time_of_departure: getDateObject(
    'ETD',
    'estimated_time_of_departure',
    'estimated_time_of_departure',
    true
  ),
  estimated_time_of_arrival: getDateObject(
    'ETA',
    'estimated_time_of_arrival',
    'estimated_time_of_arrival',
    true
  ),
  place_of_carrier_receipt: {
    headerName: 'Place Of Carrier Receipt',
    field: 'place_of_carrier_receipt.name',
    colId: 'place_of_carrier_receipt.name',
    filterKey: 'place_of_carrier_receipt_id',
    minWidth: 120,
    filter: 'CustomFilterWrapper',
    floatingFilterComponent: 'NewFloatingFilter',
    floatingFilterComponentParams: {
      valuePropName: 'name',
      suppressFilterButton: true,
    },
    filterParams: {
      component: GlobalSearch,
      componentProps: {
        selectMode: 'multiple',
        searchProps: { type: ['ICD', 'City', 'Seaport'] },
        doc_type: 'Global::Location',
      },
    },
  },
  port_of_loading: {
    headerName: 'Port Of Loading',
    field: 'port_of_loading.name',
    colId: 'port_of_loading.name',
    filterKey: 'port_of_loading_id',
    minWidth: 150,
    filter: 'CustomFilterWrapper',
    floatingFilterComponent: 'NewFloatingFilter',
    floatingFilterComponentParams: {
      valuePropName: 'name',
      suppressFilterButton: true,
    },
    filterParams: {
      component: GlobalSearch,
      componentProps: {
        selectMode: 'multiple',
        searchProps: { type: ['Seaport'] },
        doc_type: 'Global::Location',
      },
    },
  },
  transshipment_hop_locations: {
    headerName: 'Via Ports',
    field: 'transshipment_hop_locations',
    colId: 'transshipment_hop_locations',
    minWidth: 150,
  },
  port_of_discharge: {
    headerName: 'Port Of Discharge',
    field: 'port_of_discharge.name',
    colId: 'port_of_discharge.name',
    filterKey: 'port_of_discharge_id',
    minWidth: 150,
    filter: 'CustomFilterWrapper',
    floatingFilterComponent: 'NewFloatingFilter',
    floatingFilterComponentParams: {
      valuePropName: 'name',
      suppressFilterButton: true,
    },
    filterParams: {
      component: GlobalSearch,
      componentProps: {
        selectMode: 'multiple',
        searchProps: { type: ['Seaport'] },
        doc_type: 'Global::Location',
      },
    },
  },
  place_of_carrier_delivery: {
    headerName: 'Place Of Carrier Delivery',
    field: 'place_of_carrier_delivery.name',
    colId: 'place_of_carrier_delivery.name',
    filterKey: 'place_of_carrier_delivery_id',
    minWidth: 120,
    filter: 'CustomFilterWrapper',
    floatingFilterComponent: 'NewFloatingFilter',
    floatingFilterComponentParams: {
      valuePropName: 'name',
      suppressFilterButton: true,
    },
    filterParams: {
      component: GlobalSearch,
      componentProps: {
        selectMode: 'multiple',
        searchProps: { type: ['ICD', 'City', 'Seaport'] },
        doc_type: 'Global::Location',
      },
    },
  },
  vessel_voyage: {
    headerName: 'Vessel / Voyage',
    field: 'vessel_voyage',
    colId: 'vessel_voyage',
    filterKey: 'vessel_id',
    minWidth: 120,
    filter: 'CustomFilterWrapper',
    floatingFilterComponent: 'NewFloatingFilter',
    floatingFilterComponentParams: {
      valuePropName: 'name',
      suppressFilterButton: true,
      returnValueKey: 'imo',
    },
    filterParams: {
      component: VesselSearch,
      componentProps: {
        selectMode: 'multiple',
        global_network_only: true,
      },
      returnValueKey: 'imo',
    },
  },
  requested_quantity_and_type: {
    headerName: 'Requested Quantity',
    field: 'requested_quantity_and_type',
    colId: 'requested_quantity_and_type',
    minWidth: 100,
  },
  allocated_quantity_and_type: {
    headerName: 'Allocated',
    field: 'allocated_quantity_and_type',
    colId: 'allocated_quantity_and_type',
    minWidth: 100,
  },
  pickedup_quantity_and_type: {
    headerName: 'Pickedup',
    field: 'pickedup_quantity_and_type',
    colId: 'pickedup_quantity_and_type',
    minWidth: 100,
  },
  origin_port_gated_in_quantity_and_type: {
    headerName: 'Gated In',
    field: 'origin_port_gated_in_quantity_and_type',
    colId: 'origin_port_gated_in_quantity_and_type',
    minWidth: 100,
  },
  loaded_on_vessel_quantity_and_type: {
    headerName: 'Loaded On Vessel',
    field: 'loaded_on_vessel_quantity_and_type',
    colId: 'loaded_on_vessel_quantity_and_type',
    minWidth: 100,
  },
  quantity: {
    headerName: 'Requested',
    field: 'quantity',
    colId: 'quantity',
    minWidth: 60,
    aggFunc: 'sum',
    sortable: true,
  },
  quantity_fulfilled: {
    headerName: 'Allocated',
    field: 'quantity_fulfilled',
    colId: 'quantity_fulfilled',
    minWidth: 60,
    aggFunc: 'sum',
    sortable: true,
  },
  quantity_unfulfilled: {
    headerName: 'Allocation Pending',
    field: 'quantity_unfulfilled',
    colId: 'quantity_unfulfilled',
    minWidth: 60,
    aggFunc: 'sum',
    sortable: true,
  },
  quantity_received: {
    headerName: 'Received',
    field: 'quantity_received',
    colId: 'quantity_received',
    minWidth: 60,
    aggFunc: 'sum',
    sortable: true,
  },
  quantity_picked_up: {
    headerName: 'Pickedup',
    field: 'quantity_picked_up',
    colId: 'quantity_picked_up',
    minWidth: 60,
    aggFunc: 'sum',
    sortable: true,
  },
  quantity_origin_port_gated_in: {
    headerName: 'Gated In',
    field: 'quantity_origin_port_gated_in',
    colId: 'quantity_origin_port_gated_in',
    minWidth: 60,
    aggFunc: 'sum',
    sortable: true,
  },
  quantity_loaded_on_vessel: {
    headerName: 'Loaded',
    field: 'quantity_loaded_on_vessel',
    colId: 'quantity_loaded_on_vessel',
    minWidth: 60,
    aggFunc: 'sum',
    sortable: true,
  },
  quantity_shutout: {
    headerName: 'Shutout',
    field: 'quantity_shutout',
    colId: 'quantity_shutout',
    minWidth: 60,
    aggFunc: 'sum',
    sortable: true,
  },
  quantity_cancelled: {
    headerName: 'Cancelled',
    field: 'quantity_cancelled',
    colId: 'quantity_cancelled',
    minWidth: 60,
    aggFunc: 'sum',
    sortable: true,
  },
  quantity_requested: {
    headerName: 'Requested',
    field: 'quantity_requested',
    colId: 'quantity_requested',
    minWidth: 60,
    aggFunc: 'sum',
    sortable: true,
  },
  // quantity_received: {
  //   headerName: 'Received',
  //   field: 'quantity_received',
  //   colId: 'quantity_received',
  //   minWidth: 60,
  //   maxWidth: 100,
  //   aggFunc: 'sum',
  //   sortable: true,
  // },
  quantity_confirmed: {
    headerName: 'Confirmed',
    field: 'quantity_confirmed',
    colId: 'quantity_confirmed',
    minWidth: 60,
    aggFunc: 'sum',
    sortable: true,
  },
  quantity_expired: {
    headerName: 'Expired',
    field: 'quantity_expired',
    colId: 'quantity_expired',
    minWidth: 60,
    aggFunc: 'sum',
    sortable: true,
  },
  quantity_allocated: {
    headerName: 'Allocated',
    field: 'quantity_allocated',
    colId: 'quantity_allocated',
    minWidth: 60,
    aggFunc: 'sum',
    sortable: true,
  },
  quantity_allocation_pending: {
    headerName: 'Allocation Pending',
    field: 'quantity_allocation_pending',
    colId: 'quantity_allocation_pending',
    minWidth: 60,
    aggFunc: 'sum',
    sortable: true,
  },
  quantity_pick_up_pending: {
    headerName: 'Pick Up Pending',
    field: 'quantity_pick_up_pending',
    colId: 'quantity_pick_up_pending',
    minWidth: 60,
    aggFunc: 'sum',
    sortable: true,
  },
  quantity_origin_port_gate_in_pending: {
    headerName: 'Gate In Pending',
    field: 'quantity_origin_port_gate_in_pending',
    colId: 'quantity_origin_port_gate_in_pending',
    minWidth: 60,
    aggFunc: 'sum',
    sortable: true,
  },
  quantity_offloaded: {
    headerName: 'Offloaded',
    field: 'quantity_offloaded',
    colId: 'quantity_offloaded',
    minWidth: 60,
    aggFunc: 'sum',
    sortable: true,
  },
  quantity_gate_pass_pending: {
    headerName: 'Gate Pass Pending',
    field: 'quantity_gate_pass_pending',
    colId: 'quantity_gate_pass_pending',
    minWidth: 60,
    aggFunc: 'sum',
    sortable: true,
  },
  quantity_gate_pass_confirmed: {
    headerName: 'Gate Pass Confirmed',
    field: 'quantity_gate_pass_confirmed',
    colId: 'quantity_gate_pass_confirmed',
    minWidth: 60,
    aggFunc: 'sum',
    sortable: true,
  },
  quantity_loading_pending: {
    headerName: 'Loading Pending',
    field: 'quantity_loading_pending',
    colId: 'quantity_loading_pending',
    minWidth: 60,
    aggFunc: 'sum',
    sortable: true,
  },
  allocation_pending_quantity_and_type: {
    headerName: 'Allocation Pending',
    field: 'allocation_pending_quantity_and_type',
    colId: 'allocation_pending_quantity_and_type',
    minWidth: 60,
  },
  pickup_pending_quantity_and_type: {
    headerName: 'Pickup Pending',
    field: 'pickup_pending_quantity_and_type',
    colId: 'pickup_pending_quantity_and_type',
    minWidth: 60,
  },
  commodity: {
    headerName: 'Commodity',
    field: 'commodity',
    colId: 'commodity',
    filterKey: 'commodity_id',
    minWidth: 100,
    maxWidth: 300,
    filter: 'CustomFilterWrapper',
    floatingFilterComponent: 'NewFloatingFilter',
    floatingFilterComponentParams: {
      valuePropName: 'name',
      suppressFilterButton: true,
    },
    filterParams: {
      component: GlobalSearch,
      componentProps: {
        doc_type: 'Global::Commodity',
        selectMode: 'multiple',
      },
    },
  },
  container_settings: {
    headerName: 'Container Settings',
    field: 'container_settings',
    colId: 'container_settings',
    minWidth: 100,
  },
  container_numbers: {
    headerName: 'Container #',
    field: 'container_numbers',
    colId: 'container_numbers',
    minWidth: 100,
  },
  container_types: {
    headerName: 'Container Types',
    field: 'container_types',
    colId: 'container_types',
    minWidth: 100,
  },
  mbl_number: {
    headerName: 'MBL #',
    field: 'mbl_number',
    colId: 'mbl_number',
    minWidth: 100,
  },
  hbl_numbers: {
    headerName: 'HBL #',
    field: 'hbl_numbers',
    colId: 'hbl_numbers',
    valueGetter: getHBLNumbersText,
    cellRenderer: 'hbl_numbers',
    minWidth: 100,
    filter: 'agTextColumnFilter',
  },
  house_job_numbers: {
    headerName: 'Job #',
    field: 'house_job_numbers',
    colId: 'house_job_numbers',
    valueGetter: getHouseJobNumbersText,
    cellRenderer: 'house_job_numbers',
    minWidth: 100,
  },
  job_numbers: {
    headerName: 'Job #',
    field: 'job_numbers',
    colId: 'job_numbers',
    valueGetter: getJobNumbersText,
    cellRenderer: 'job_numbers',
    minWidth: 100,
    filter: 'agTextColumnFilter',
  },
  gate_open_date: getDateObject('Gate Open Date', 'gate_open_date', 'gate_open_date', true),
  gate_close_date: getDateObject('Gate Close Date', 'gate_close_date', 'gate_close_date', true),
  sales_person: getSalesPerson('Sales Person'),
  order_by: getSalesPerson('Order By'),
  booking_number: {
    headerName: 'Booking #',
    field: 'booking_number',
    colId: 'booking_number',
    filterKey: 'booking_number',
    filter: 'agTextColumnFilter',
    width: 110,
    pinned: 'left',
  },
  remarks: {
    headerName: 'Remarks',
    field: 'remarks',
    colId: 'remarks',
    minWidth: 100,
    tooltipField: 'remarks',
  },
  cancelled_by: {
    headerName: 'Cancelled By',
    field: 'cancelled_by',
    colId: 'cancelled_by',
    minWidth: 80,
  },
  cancellation_reason: {
    headerName: 'Cancellation Reason',
    field: 'cancellation_reason',
    colId: 'cancellation_reason',
    minWidth: 100,
  },
  cancellation_marked_by: {
    headerName: 'Cancel Marked By',
    field: 'cancellation_marked_by',
    colId: 'cancellation_marked_by',
    minWidth: 100,
  },
};

export interface InlineFilterProps {
  label: string;
  filterProps: {
    component: any;
    filterKey: string;
    componentProps?: object;
  };
}
export interface ExternalFilterProps<T> {
  label: string;
  component: any;
  name: string;
  componentProps?: T;
  formItemProps?: FormItemProps;
}
export const external_filter_mapping: { [key: string]: ExternalFilterProps<any> } = {
  [FILTER_VESSEL_VOYAGE_NUMBER]: {
    label: 'Vessel / Voyage',
    component: VoyageScheduleSearch,
    name: 'voyage_schedule_id',
    componentProps: {
      selectMode: 'multiple',
      style: { width: '100%' },
    },
    formItemProps: {
      required: true,
      rules: [{ required: true }],
    },
  },
  [FILTER_ETD]: {
    label: 'ETD',
    component: RangePicker,
    name: 'estimated_time_of_departure',
    componentProps: {
      style: { width: '100%' },
    },
    formItemProps: {
      required: true,
      rules: [{ required: true }],
    },
  },
  [FILTER_VESSEL]: {
    label: 'Vessel',
    component: VesselSearch,
    name: FILTER_VESSEL,
    componentProps: {
      style: { width: '100%' },
    },
    formItemProps: {
      required: true,
      rules: [{ required: true }],
    },
  },
  [FILTER_VOYAGE_NUMBER]: {
    label: 'Voyage #',
    component: Input,
    name: FILTER_VOYAGE_NUMBER,
    componentProps: {
      style: { width: '100%' },
    },
    formItemProps: {
      required: true,
      rules: [{ required: true }],
    },
  },
};

export const isFirstColumn = (params: any) => {
  const displayedColumns = params?.columnApi?.getAllDisplayedColumns() || [];
  const thisIsFirstColumn = displayedColumns.length > 0 && displayedColumns[0] === params?.column;
  return thisIsFirstColumn;
};

export const salesPersonName = (params: any): string => {
  const { data } = params;
  if (data) {
    const collaboration = data.sell_collaborations.find((collaboration: any) => {
      return collaboration?.sales_person;
    });

    return collaboration?.sales_person?.name;
  } else {
    return '';
  }
};

export const createdByName = (params: any): string => {
  const { data } = params;
  if (data) {
    const created_by = data.booking_request?.created_by;

    return created_by ? `${created_by.first_name} ${created_by.last_name}` : '';
  } else {
    return '';
  }
};
