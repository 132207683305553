import React, { useMemo, useState } from 'react';

import { Select } from '@shipmnts/pixel-hub';
import { debounce as _debounce } from 'lodash';
import { gql } from '@apollo/client';
import { useLazyQuery } from '@apollo/client';

export interface BankFilter {
  name: string;
}
interface Props {
  placeholder: string;
  field_id: string;
  value?: string;
  onChange?: (value?: string) => void;
  disabled?: boolean;
}

const COL_SEARCH = gql`
  query get_column_values($field_name: String!, $seatch_text: String!) {
    get_column_values(field_name: $field_name, seatch_text: $seatch_text)
  }
`;

export const DistinctColSearch = (props: Props) => {
  const { disabled, onChange, value, field_id, placeholder } = props;
  const [getDistinctCol, { data, loading }] = useLazyQuery(COL_SEARCH);
  const [search, setSearch] = useState<string>();

  const handleSearch = useMemo(
    () =>
      _debounce((search: string) => {
        setSearch(search);
        getDistinctCol({
          variables: {
            field_name: field_id,
            seatch_text: search,
          },
        });
      }, 300),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [field_id]
  );

  const getOptions = () => {
    let options = (data?.get_column_values || []).map((ele: string) => {
      return {
        value: ele,
        label: ele,
      };
    });
    if ((options?.length === 0 || !options) && search && !loading)
      options = [
        {
          value: search,
          label: search,
        },
      ];

    return options || [];
  };

  return (
    <>
      <Select
        onFocus={() => {
          if (!search && !value) handleSearch('');
        }}
        filterOption={true}
        onSearch={handleSearch}
        loading={loading}
        placeholder={placeholder}
        showSearch
        allowClear
        onChange={(value: string) => {
          if (onChange) onChange(value);
        }}
        value={value}
        style={{ width: '100%' }}
        popupMatchSelectWidth={400}
        disabled={disabled}
        options={getOptions()}
      />
    </>
  );
};
