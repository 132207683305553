import { PerformAction } from '../models';
import { ShipmentCustomDetailValue } from 'operations/models/ShipmentCustomDetail';
import { SessionDataValue } from 'operations/models/SessionData';
import DeleteShipmentCustomDetail from 'operations/modules/shipment/components/DetailLayout/Customs/DeleteShipmentCustomDetail';
import EditShipmentCustomDetail from 'operations/modules/shipment/components/DetailLayout/Customs/EditShipmentCustomDetail';
import { TrackerLayout, RenderEventsForm } from 'common';
import ReuploadUploadSbBeFile from './../../shipment/components/DetailLayout/Customs/ReuploadUploadSbBeFile';
// import { EmailProps } from 'operations/modules/shipment/components/Tracking/ChangeEvent/EventEmailDrawer'

interface ShipmentCustomDetailActionType {
  shipment_custom_detail: ShipmentCustomDetailValue;
  setEmailProps: (emailProps: any) => void;
  setVisible: (visible: boolean) => void;
  sessionData: SessionDataValue;
  extraProps?: any;
  navigate: any;
}

export const performShipmentCustomsEvent = (
  payload: ShipmentCustomDetailActionType
): PerformAction => {
  const { shipment_custom_detail, sessionData, extraProps } = payload;
  const eventName = extraProps?.event_name;
  return {
    actionParams: {
      id: shipment_custom_detail.id,
      referenceType: 'Shipment::ShipmentCustomDetail',
      eventName: eventName,
      type: extraProps?.event_type,
      onSuccess: () => {
        // TODO: on success
      },
      session: sessionData,
    },
    component: RenderEventsForm,
  };
};

export const renderShipmentCustomsEventTimeLine = (
  payload: ShipmentCustomDetailActionType
): PerformAction => {
  const { shipment_custom_detail, sessionData } = payload;
  return {
    actionParams: {
      id: shipment_custom_detail.id,
      referenceType: 'Shipment::ShipmentCustomDetail',
      isOpen: true,
      onClose: () => {
        // TODO: On Close
      },
      onSuccess: () => {
        // TODO: on success
      },
      session: sessionData,
    },
    component: TrackerLayout,
  };
};

export const renderShipmentCustomsDeteleAction = (
  payload: ShipmentCustomDetailActionType
): PerformAction => {
  const { shipment_custom_detail } = payload;
  return {
    actionParams: {
      shipmentCustomDetail: shipment_custom_detail,
    },
    component: DeleteShipmentCustomDetail,
  };
};

export const renderEditShipmentCustomsAction = (
  payload: ShipmentCustomDetailActionType
): PerformAction => {
  const { shipment_custom_detail } = payload;
  return {
    actionParams: {
      shipmentCustomDetail: shipment_custom_detail,
      shipment: shipment_custom_detail?.shipment,
    },
    component: EditShipmentCustomDetail,
  };
};

export const renderReUploadFlatFile = (payload: ShipmentCustomDetailActionType): PerformAction => {
  const { shipment_custom_detail } = payload;
  return {
    actionParams: {
      title: 'Reupload .sb/.be File',
      shipmentCustomDetail: shipment_custom_detail,
      shipment: shipment_custom_detail?.shipment,
      api: 'update',
      isVisible: true,
    },
    component: ReuploadUploadSbBeFile,
  };
};
