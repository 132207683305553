import { types, Instance, SnapshotIn, SnapshotOut, IAnyModelType } from 'mobx-state-tree';
import UserAccount from 'operations/models/UserAccount';

const ChangeEvent = types.model({
  id: types.identifier,
  name: types.maybe(types.maybeNull(types.string)),
  event_date: types.maybe(types.maybeNull(types.number)),
  event_by: types.maybe(types.maybeNull(types.late((): IAnyModelType => UserAccount))),
  event_number: types.maybe(types.maybeNull(types.string)),
});

export type ChangeEventValue = Instance<typeof ChangeEvent>;
export type ChangeEventIn = SnapshotIn<typeof ChangeEvent>;
export type ChangeEventOut = SnapshotOut<typeof ChangeEvent>;

export default ChangeEvent;
