import React, { useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import { message, Popconfirm } from '@shipmnts/pixel-hub';

import { DELETE_INQUIRY_OPTION } from 'sales_hub/graphql/inquiryOption';

interface InquiryOptionDeleteProps {
  id: string;
  isVisible?: boolean;
  visible?: boolean;
  onSuccess?: () => void;
  setIsVisible?: (isVisible: boolean) => void;
  refetchInquiry?: () => void;
}
function InquiryOptionDelete(props: InquiryOptionDeleteProps) {
  const { id, onSuccess, visible, refetchInquiry } = props;
  const [open, setOpen] = useState<boolean | undefined>(true);
  const [deleteInquiryOptions, { data, error }] = useMutation(DELETE_INQUIRY_OPTION);
  useEffect(() => {
    if (error) message.error('unable to delete');

    if (data && !error) {
      message.success('Quotation deleted');
      if (onSuccess) onSuccess();
      if (refetchInquiry) refetchInquiry();
    }
  }, [data, error, onSuccess, refetchInquiry]);

  if (!id) return <></>;
  return (
    <>
      {visible && (
        <Popconfirm
          placement="topLeft"
          title={'Do you want to delete quotation?'}
          okText="Yes"
          open={open}
          onConfirm={() => {
            deleteInquiryOptions({
              variables: {
                inquiry_option_id: id,
              },
            });
          }}
          onCancel={() => {
            setOpen(false);
          }}
        ></Popconfirm>
      )}
    </>
  );
}

export default InquiryOptionDelete;
