import { ICellRendererParams } from '@ag-grid-community/core';
import {
  Tooltip,
  PopoverProps,
  NextActionRenderer,
  useTrackerContext,
  localizedFormat,
} from '@shipmnts/pixel-hub';
import React from 'react';
import LastEventDetails from 'operations/modules/shipment/components/DetailLayout/Common/LastEventDetails';
import { CustomIcon, dayjs } from '@shipmnts/pixel-hub';

dayjs.extend(localizedFormat); // to use local format
interface EventRendererType extends ICellRendererParams {
  doc_type_id: string;
  subscription: any;
  refetch: () => void;
  refetchData?: () => void;
}

const EventRenderer = (props: EventRendererType) => {
  const event = props.value;
  const { setReferenceId, setEditEventId } = useTrackerContext();
  if (!event) {
    return <span></span>;
  }
  const _date = event?.actual_date || event?.estimated_date;
  const dayjsObj = _date ? dayjs.unix(parseInt(_date)) : null;
  const date = dayjsObj ? dayjsObj.format('llll') : '';
  const last_tracked_at = event.last_tracked_at
    ? dayjs.unix(parseInt(event.last_tracked_at))
    : null;
  const id = props?.data?.id;
  const field_name = props?.colDef?.field;
  const cellRendererParams = props.colDef?.cellRendererParams || props;
  const field_data = {
    extra_fields: {
      next_actions: props?.data?.next_actions,
    },
  };

  if (!event?.name) return <span></span>;

  const handleOnEventNameClick = () => {
    setEditEventId(event.id);
    setReferenceId(props.data.id);
  };

  const popoverProps = () => {
    const props: PopoverProps = {};
    if (!event.event_data) {
      props.open = false;
    }
    return props;
  };

  return (
    <div className="event-renderer">
      {props.doc_type_id ? (
        <Tooltip title={() => <LastEventDetails {...props} />} {...popoverProps()}>
          <NextActionRenderer
            field_data={field_data}
            fieldName={field_name}
            reference_id={id}
            cellParams={cellRendererParams}
            onSuccess={props?.refetchData}
          >
            <Tooltip title={event?.name || ''}>
              <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {event?.name || ''}
              </div>
            </Tooltip>
          </NextActionRenderer>
        </Tooltip>
      ) : (
        <div className="event-name" onClick={handleOnEventNameClick}>
          <Tooltip title={event?.name || ''}>
            <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {event?.name || ''}
            </div>
          </Tooltip>
        </div>
      )}
      <div className="date-and-info sub-text" style={{ display: 'flex' }}>
        <div>{date}</div>
        {event.current_location && (
          <>
            <Tooltip title={event.current_location} color={'#0A4FCA'}>
              <CustomIcon icon="Location" style={{ strokeWidth: '0px' }} height="16px" />
            </Tooltip>

            {last_tracked_at && (
              <Tooltip title={last_tracked_at.format('llll')}>
                <CustomIcon icon="Online" style={{ strokeWidth: '0px' }} height="16px" />
              </Tooltip>
            )}
          </>
        )}
      </div>
    </div>
  );
};
export default EventRenderer;
