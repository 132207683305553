import React from 'react';
import { Form, Input, Row, Col, Select } from '@shipmnts/pixel-hub';
import { DEPARTMENTS } from 'network/constants';
import type { SelectProps } from '@shipmnts/pixel-hub';
import { ContactValue } from '../models/Contact';
import { FormInstance } from '@shipmnts/pixel-hub';
import { CompanySearch, PhoneNumberInput } from 'common';
import { CompanyValue } from '../models/Company';

export interface ContactFormProp {
  contact?: ContactValue;
  fieldName?: string;
  form?: FormInstance;
  is_mandatory?: boolean;
  initialCompany?: CompanyValue;
  showCompany?: boolean;
}

const ROW_GUTTER = 32;
const options: SelectProps['options'] = DEPARTMENTS.sort().map((ele) => {
  return {
    value: ele,
    label: ele,
  };
});
const ContactFormContent = React.memo(function ContactForm(props: ContactFormProp) {
  const { contact, fieldName, is_mandatory = true, initialCompany, showCompany = false } = props;

  const is_internal = contact?.is_internal || false;
  const country_code = initialCompany?.country_of_incorporation;
  return (
    <>
      <Row gutter={ROW_GUTTER}>
        <Col span={12} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <Form.Item
            name={fieldName ? [fieldName, 'first_name'] : 'first_name'}
            label="First Name"
            rules={[{ required: !!is_mandatory }]}
          >
            <Input disabled={is_internal} />
          </Form.Item>
        </Col>
        <Col span={12} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <Form.Item name={fieldName ? [fieldName, 'last_name'] : 'last_name'} label="Last Name">
            <Input disabled={is_internal} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={ROW_GUTTER}>
        <Col span={12} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <Form.Item name={fieldName ? [fieldName, 'title'] : 'title'} label="Designation">
            <Input disabled={is_internal} />
          </Form.Item>
        </Col>
        <Col span={12} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <Form.Item name={fieldName ? [fieldName, 'department'] : 'department'} label="Department">
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="Please select department"
              disabled={is_internal}
              onChange={() => {
                return {};
              }}
              options={options}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={ROW_GUTTER}>
        <Col span={12} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <Form.Item
            name={fieldName ? [fieldName, 'email'] : 'email'}
            label="Email ID"
            rules={[{ type: 'email', required: false }]}
          >
            <Input disabled={is_internal} />
          </Form.Item>
        </Col>
        <Col span={12} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <Form.Item
            name={fieldName ? [fieldName, 'mobile_number'] : 'mobile_number'}
            label="Contact Number"
          >
            <PhoneNumberInput defaultValueDialCode={country_code} disabled={is_internal} />
          </Form.Item>
        </Col>
      </Row>
      {!!!initialCompany && showCompany && (
        <Row gutter={ROW_GUTTER}>
          <Col span={12} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
            <Form.Item
              name={'company'}
              label={'Company Name'}
              required
              rules={[{ required: true }]}
            >
              <CompanySearch showAddCompanyAction={false} />
            </Form.Item>
          </Col>
        </Row>
      )}
    </>
  );
});

export default ContactFormContent;
