import React, { useState, useEffect } from 'react';
import { Card, Button, Row, Col, Input, Modal, message, Form } from '@shipmnts/pixel-hub';
import { useMutation } from '@apollo/client';
import { UPDATE_BOOKING_REQUEST } from 'operations/modules/booking/graphql/bookingRequest';
import { UPDATE_OCEAN_TRANSPORT_ORDER } from 'operations/modules/booking/graphql/oceanTransportOrder';
import { BookingOrderOverviewProps } from './BookingOrderOverview';
import { errorMessageHandlerGraphQL, useSession } from 'common';
import { observer } from 'mobx-react-lite';
import { SalesPersonValue } from 'common/models/SalesPerson';
import { BOOKING_TYPE_SELF } from 'common/baseConstants';
import { GlobalSearch } from '@shipmnts/pixel-hub';

interface BookingOrderOverviewCardProps extends BookingOrderOverviewProps {
  setOtoOverviewEdit: (value: boolean) => void;
}

interface UpdateOceanTransportOrderValueType {
  ocean_transport_order: {
    id: string;
    sales_person?: SalesPersonValue;
    customer_address_id?: string;
  };
}

const BookingOrderOverviewCard = observer(function BookingOrderOverview(
  props: BookingOrderOverviewCardProps
): JSX.Element {
  const { oceanTransportOrder, onUpdate, setOtoOverviewEdit } = props;
  const session = useSession();
  const [payload, setPayload] = useState<UpdateOceanTransportOrderValueType>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [updateAllocatedBooking, setUpdateAllocatedBooking] = useState<boolean>(false);

  const [updateOceanTransportOrder, { data, loading, error }] = useMutation(
    UPDATE_OCEAN_TRANSPORT_ORDER
  );
  const [updateBookingRequest, { data: bookingRequestData, loading: updatingBR, error: brError }] =
    useMutation(UPDATE_BOOKING_REQUEST);
  useEffect(() => {
    if (updateAllocatedBooking) {
      updateOceanTransportOrder({ variables: payload });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload, updateAllocatedBooking]);

  useEffect(() => {
    if (data?.update_ocean_transport_order?.id && !error) {
      if (updateAllocatedBooking) {
        updateBookingRequest({
          variables: {
            booking_request: {
              id: oceanTransportOrder?.booking_requests?.[0]?.id,
              vendor_address_id: payload?.ocean_transport_order?.customer_address_id,
            },
          },
        });
      } else {
        message.success('Booking Order Details Updated!');
        if (onUpdate) onUpdate(data?.update_ocean_transport_order);
        setModalVisible(false);
        setOtoOverviewEdit(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, oceanTransportOrder, payload, setOtoOverviewEdit, updateAllocatedBooking]);

  useEffect(() => {
    if (bookingRequestData?.update_booking_request?.id && !brError) {
      message.success('Booking Order Details Updated!');
      if (onUpdate) onUpdate(data?.update_ocean_transport_order);
      setModalVisible(false);
      setUpdateAllocatedBooking(false);
      setOtoOverviewEdit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingRequestData, brError, data, setOtoOverviewEdit]);

  return (
    <Form
      layout="vertical"
      initialValues={{
        booking_number: oceanTransportOrder.booking_number,
        sales_person: oceanTransportOrder.sales_person,
        customer: {
          party_address: oceanTransportOrder.customer_address,
        },
      }}
      onFinish={(values: any) => {
        const payload = {
          ocean_transport_order: {
            id: oceanTransportOrder?.id,
            booking_number: values.booking_number,
            sales_person_id: values?.sales_person?.id,
            customer_address_id: values?.customer?.party_address?.id,
          },
        };
        if (
          oceanTransportOrder.isOneOnOneAllocated &&
          oceanTransportOrder.bookedByBranch?.id !== values?.customer?.party_address?.id
        ) {
          setPayload(payload);
          setModalVisible(true);
        } else {
          updateOceanTransportOrder({ variables: payload });
        }
      }}
    >
      <Card
        title="Basic Details"
        extra={[
          <Button
            style={{ marginRight: '5px' }}
            key="cancel"
            onClick={() => setOtoOverviewEdit(false)}
            disabled={loading || updatingBR}
          >
            Cancel
          </Button>,
          <Button key="save" htmlType="submit" type="primary" loading={loading || updatingBR}>
            Save
          </Button>,
        ]}
      >
        {error && errorMessageHandlerGraphQL(error)}
        <Row gutter={16}>
          {
            // Booking Number Feild Disabled for Self Type
            oceanTransportOrder.booking_type !== BOOKING_TYPE_SELF ? (
              <Col span={5}>
                <Form.Item
                  required={true}
                  rules={[{ required: true }]}
                  name="booking_number"
                  label="Booking number"
                >
                  <Input placeholder="Booking Number" />
                </Form.Item>
              </Col>
            ) : (
              <></>
            )
          }

          <Col span={5}>
            <Form.Item required rules={[{ required: true }]} name="sales_person" label="Order By">
              <GlobalSearch doc_type="Network::SalesPerson" />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item
              required
              rules={[{ required: true }]}
              name={['customer', 'party_address']}
              label="Booked By Branch"
            >
              <GlobalSearch
                doc_type="Network::Address"
                searchProps={{
                  company_id: session.company_account.default_company.id,
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <Modal
        title="Found one allocated customer order"
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button
            key="back"
            onClick={() => setModalVisible(false)}
            disabled={loading || updatingBR}
          >
            Close
          </Button>,
          <Button
            key="not_update_booking_request"
            onClick={() => {
              updateOceanTransportOrder({ variables: payload });
            }}
            loading={!updateAllocatedBooking && (loading || updatingBR)}
            disabled={updateAllocatedBooking && (loading || updatingBR)}
          >
            No
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              setUpdateAllocatedBooking(true);
            }}
            loading={updateAllocatedBooking && (loading || updatingBR)}
            disabled={!updateAllocatedBooking && (loading || updatingBR)}
          >
            Update Booking Branch in Allocated Booking
          </Button>,
        ]}
      >
        <p>Do you want to change booking branch of that customer order too?</p>
      </Modal>
    </Form>
  );
});

export default BookingOrderOverviewCard;
