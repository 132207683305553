import React, { useState, useEffect, useCallback } from 'react';
import { useSession } from 'common';
import { ASSIGNED_USER_TO_MESSAGE } from 'operations/modules/message/graphql/message';
import { useMutation } from '@apollo/client';
import { message, Button, Divider, Space } from '@shipmnts/pixel-hub';
import { MessageValue } from 'operations/models/Message';
import { UserContactValue } from 'operations/models/UserContact';
import UserAccount from 'operations/models/UserAccount';
import { GlobalSearch } from '@shipmnts/pixel-hub';

const AssignUser = (props: {
  message: MessageValue;
  onSuccess: () => void;
  onClose: () => void;
}) => {
  const assign_to = props?.message?.assigned_to;
  const sessionData = useSession();
  const [user, setUser] = useState<UserContactValue | undefined>(assign_to ? assign_to : undefined);
  const [updateAssignedTo, { error, loading }] = useMutation(ASSIGNED_USER_TO_MESSAGE);

  const onSubmit = useCallback(() => {
    try {
      updateAssignedTo({
        variables: {
          id: props.message.id,
          message: { assigned_to_id: user?.id || sessionData.id },
        },
      });
      props.onSuccess?.();
      props.onClose();
    } catch (error) {
      message.error('something went wrong while updating user');
    }
  }, [user, updateAssignedTo, props, sessionData.id]);

  useEffect(() => {
    if (error) {
      message.error('Unable to assign User');
    }
  }, [error]);

  const onChange = useCallback((user?: any) => {
    if (user && typeof user === 'object' && !Array.isArray(user)) {
      setUser(user);
    } else if (!user) {
      setUser(undefined);
    }
  }, []);
  const userAcc = user ? UserAccount.create(user) : undefined;
  return (
    <div>
      <label style={{ marginBottom: '5px' }}>Select User</label>
      <GlobalSearch
        doc_type="Network::UserAccount"
        value={userAcc ? { name: '', ...userAcc } : undefined}
        onChange={onChange}
      />
      <Divider style={{ marginTop: '15px', marginBottom: '10px' }} />
      <Space style={{ textAlign: 'right' }}>
        <Button onClick={props.onSuccess}>Close</Button>
        <Button type="primary" onClick={onSubmit} loading={loading}>
          Submit
        </Button>
      </Space>
    </div>
  );
};

export default AssignUser;
