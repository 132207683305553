import { SessionDataValue } from 'common/models/SessionData';
import React from 'react';
import { EventType, TrackingReferenceType } from '../../../types';
import EventsForm from './EventsForm';

type Props = {
  ids: string[];
  referenceType: TrackingReferenceType;
  eventName: string;
  type: 'ADD' | 'UPDATE';
  onClose: () => void;
  onSuccess?: (value?: boolean) => void;
  session?: SessionDataValue;
  events?: EventType[];
  refData?: any;
};

const BulkUpdateEvents = (props: Props): JSX.Element => {
  const { onClose, eventName, session, onSuccess, referenceType, events, refData } = props;

  const event_ids: number[] = [];
  events?.forEach((i) => i.id && event_ids.push(parseInt(i.id)));
  const onSubmit = (success?: boolean) => {
    if (success && onSuccess) {
      onSuccess(success);
    }
  };

  return (
    <div className="container-activity">
      <div style={{ padding: '0px 45px' }}>
        {session && (
          <EventsForm
            event={{ name: eventName, sequence_number: 0 }}
            afterSubmit={onSubmit}
            onDiscard={onClose}
            session={session}
            referenceType={referenceType}
            event_ids={event_ids}
            type="bulk"
            refData={refData}
          />
        )}
      </div>
    </div>
  );
};

export default BulkUpdateEvents;
