import { useMutation } from '@apollo/client';
import { message, Modal } from '@shipmnts/pixel-hub';
import { UPDATE_SHIPMENT_DOCUMENT } from 'operations/graphql/shipmentDocument';
import { ShipmentValue } from 'operations/models/Shipment';
import { DOCUMENT_STATUS_EXECUTED } from 'operations/modules/reports/constants';
import React, { useEffect } from 'react';

interface FinaliseConsolProps {
  shipment: ShipmentValue;
  onClose?: () => void;
  onSuccess?: () => void;
}
export default function FinaliseConsol(props: FinaliseConsolProps) {
  const { onClose, onSuccess, shipment } = props;
  const [modal, contextHolder] = Modal.useModal();
  const shipment_document_master = shipment.getShipmentDocument('master');
  const [updateShipmentDocument, { data, loading, error }] = useMutation(UPDATE_SHIPMENT_DOCUMENT);

  useEffect(() => {
    if (error) {
      message.error(error.message);
    } else if (data?.update_shipment_document) {
      message.success('Document updated successfully');
      onSuccess && onSuccess();
    }
  }, [data, loading, error, onSuccess]);

  const config = {
    title: `Are you sure you want to Finalise the consolidation`,
    content: (
      <div style={{ padding: '15px' }}>
        <p>After finalising consolidation you will not be able to </p>
        <ul>
          <li>Add new house to this consol</li>
          <li>Delink house from this consol</li>
          <li>Cancel house from this consol</li>
        </ul>
      </div>
    ),
    okText: 'Yes',
    cancelText: 'No',
    onCancel: () => {
      if (onClose) onClose();
    },
    onOk: async () => {
      updateShipmentDocument({
        variables: {
          id: shipment_document_master.id,
          shipment_document: { document_status: DOCUMENT_STATUS_EXECUTED },
        },
      });
    },
  };

  useEffect(() => {
    Modal.confirm(config);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  return <>{contextHolder}</>;
}
