import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Badge, Button, Card, Space, Tag, Switch } from '@shipmnts/pixel-hub';
import { SyncOutlined } from '@shipmnts/pixel-hub';
import { useApolloClient } from '@apollo/client';
import { compact as _compact, get as _get, startCase as _startCase } from 'lodash';
import { useShipmentDetail } from '../ShipmentDetailLayout';
import { FETCH_SHIPMENT_ESTIMATES } from 'operations/graphql/shipmentEstimate';
import { erpnextApis } from 'network';
import {
  ShipmentEstimateStoreContextProvider,
  ShipmentEstimateStore,
  ShipmentEstimateStoreValue,
  useShipmentEstimateStore,
} from 'operations/models/ShipmentEstimate';
import ShipmentEstimateLayout from './ShipmentEstimateLayout';
import { useSession } from 'common';
import { callSync } from './helper';
import { observer } from 'mobx-react-lite';
import { getEnv } from 'mobx-state-tree';
import { ShipmentEstimatesTotal } from 'common';
const SYNC_TIME_IN_SECONDS = 3;
export function useLocalStorage(key: string, initialValue: string | object) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.error(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: string | object) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.error(error);
    }
  };

  return [storedValue, setValue];
}

function ShipmentEstimatesTab() {
  const { shipment } = useShipmentDetail();

  const [displayMode, setDisplayMode] = useState('view');
  const [config, setConfig] = useLocalStorage('estimate_config', {
    ignored: true,
  });
  const client = useApolloClient();
  const { permissions } = useSession();
  const session = useSession();
  const currentInterval = useRef<any>(null);

  const [loading, setLoading] = useState(true);
  const [initialDataFetched, setInitialDataFetched] = useState(false);
  const [fetchError, setFetchError] = useState(false);

  const store = useRef<ShipmentEstimateStoreValue>(
    ShipmentEstimateStore.create(
      { estimates: [], to_be_synced: [], sync_state: 'saved' },
      {
        session,
        permissions,
        accounting_status: shipment?.accounting_status,
        showIgnored: config.ignored,
      }
    )
  );

  const shipmentIds = useMemo(
    () => _compact([shipment?.id, ...(shipment?.house_shipments?.map((hs) => hs.id) || [])]),
    [shipment?.id, shipment?.house_shipments]
  );
  const shipmentJobNumbers = useMemo(
    () =>
      _compact([
        shipment?.job_number || shipment?.shipment_booking_number,
        ...(shipment?.house_shipments?.map((hs) => hs.job_number || hs.shipment_booking_number) ||
          []),
      ]),
    [shipment?.job_number, shipment?.shipment_booking_number, shipment?.house_shipments]
  );

  const fetchEstimateData = async () => {
    setLoading(true);
    store.current.clearEstimates();
    const { data, errors } = await client.query({
      query: FETCH_SHIPMENT_ESTIMATES,
      variables: { shipment_ids: shipmentIds },
      fetchPolicy: 'no-cache',
    });
    let jv_estimates = [];
    if (shipmentJobNumbers.length >= 1) {
      const { estimates } = await erpnextApis.mapJVToEstimate(shipmentJobNumbers);
      jv_estimates = estimates;
    }

    if (errors) {
      setFetchError(true);
    } else {
      store.current.replaceEstimates(
        _get(data, 'fetch_shipment_estimate_list', []),
        jv_estimates || []
      );
      if (!initialDataFetched && store.current.estimates.length === 0) {
        setDisplayMode('edit');
      }
      //clearing interval if already other sync is going on.
      if (currentInterval.current) {
        clearInterval(currentInterval.current);
      }
      //starting new sync
      currentInterval.current = setInterval(() => {
        if (store.current.sync_state === 'unsaved') callSync(store.current, client);
      }, SYNC_TIME_IN_SECONDS * 1000);
    }
    setLoading(false);
    setInitialDataFetched(true);
  };

  useEffect(() => {
    fetchEstimateData?.();
    getEnv(store.current).accounting_status = shipment?.accounting_status;
    getEnv(store.current).showIgnored = config.ignored;
    store.current.updateEstimatePermissions();
    // eslint-disable-next-line
  }, [shipment?.accounting_status, config.ignored]);

  if (!shipment) return null;
  if (fetchError)
    return (
      <Card>
        <p>Something went wrong. Please retry.</p>
      </Card>
    );
  if (!initialDataFetched) return <Card loading />;

  if (!store) return <div>Not Found</div>;

  return (
    <ShipmentEstimateStoreContextProvider
      value={{ store: store.current, fetchEstimateData, loading }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Space>
          <Switch
            checkedChildren="View"
            unCheckedChildren="Edit"
            className="estimates-switch"
            checked={displayMode === 'edit'}
            onClick={(checked) => {
              const displayMode = checked ? 'edit' : 'view';
              setDisplayMode(displayMode);
            }}
          />

          <SavingBanner />
        </Space>
        <Space>
          <ShipmentEstimatesTotal useStore={useShipmentEstimateStore} />
        </Space>
        <Space>
          {shipment?.isAccountingClosed() && (
            <>
              Show Ignored Estimates
              <Switch
                checked={config.ignored}
                onChange={() => setConfig({ ignored: !config.ignored })}
              />
            </>
          )}
          Finance Status:
          <div className={`status_color_dark_yellow`}>
            <Badge color={'red'} text={_startCase(shipment.accounting_status || '')} />
          </div>
        </Space>
      </div>

      <div style={{ height: 'calc(100% - 80px)', overflow: 'auto' }}>
        <ShipmentEstimateLayout
          shipment={shipment}
          displayMode={displayMode}
          showCurrencyTotals={config.currencyTotals}
        />
      </div>
    </ShipmentEstimateStoreContextProvider>
  );
}

export default ShipmentEstimatesTab;

const SavingBanner = observer(function SavingBanner() {
  const { store } = useShipmentEstimateStore();
  const client = useApolloClient();
  const [time, setTime] = useState(true);

  useEffect(() => {
    if (store.sync_state === 'saved') {
      const timer = setTimeout(() => {
        setTime(true);
      }, 5000);
      return () => clearTimeout(timer);
    } else {
      setTime(false);
      return;
    }
  }, [store.sync_state]);

  if (store.sync_state === 'error') {
    return (
      <Space>
        <Tag color="error">Error while saving estimates</Tag>
        <Button size="small" icon={<SyncOutlined />} onClick={() => callSync(store, client)} />
      </Space>
    );
  }

  if (store.sync_state === 'unsaved') {
    return <Tag color="warning">Unsaved changes</Tag>;
  }

  if (store.sync_state === 'saving') {
    return <Tag color="processing">Saving changes...</Tag>;
  }

  if (store.sync_state === 'saved' && !time) {
    return <Tag color="success">All changes saved</Tag>;
  }

  return <></>;
});
