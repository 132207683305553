import { CompanySearch } from 'common';

import {
  STATUS_REQUESTED,
  STATUS_CONFIRMED,
  STATUS_EXPIRED,
  STATUS_EXPIRING_SOON,
  STATUS_BOOKING_ORDER_DASHBOARD,
  STATUS_BOOKING_ALLOCATED_SUMMARY,
  REQUESTED_AGEING,
  // PIVOT_VOYAGE,
  // PIVOT_PORT_OF_DISCHARGE,
  // PIVOT_CUSTOMER,
} from 'operations/modules/reports/constants';
import {
  reportCommonFields,
  getDateObject,
  add_row_groups,
} from 'operations/modules/reports/reportCommonFields';
import { dayjs } from '@shipmnts/pixel-hub';
import { snakeCase as _snakeCase, pick as _pick, cloneDeep as _cloneDeep } from 'lodash';
import { Column } from 'operations/models/Report';
import { CellClassParams, ColDef } from '@ag-grid-community/core';
import {
  BOOKING_TYPE_SHIPPING_LINE,
  BOOKING_TYPE_VENDOR,
  LOAD_TYPE_FCL,
  LOAD_TYPE_LCL,
} from 'operations/baseConstants';
import { GlobalSearch } from '@shipmnts/pixel-hub';

interface OTOReportData {
  status: string;
  created_at: number;
}

export const fields: { [key: string]: Column } = {
  ...reportCommonFields,
  booking_id: {
    headerName: 'Booking Id',
    field: 'id',
    colId: 'id',
    width: 90,
    minWidth: 90,
    // pinned: 'left',
    cellRenderer: 'renderLinkCell',
    cellRendererParams: { redirectToBase: `/view/booking_order`, idField: 'id' },
  },
  booking_number_without_link: {
    headerName: 'Booking #',
    field: 'booking_number',
    colId: 'booking_number',
    width: 120,
  },
  booking_number: {
    headerName: 'Booking #',
    field: 'booking_number',
    colId: 'booking_number',
    filterKey: 'booking_number',
    filter: 'agTextColumnFilter',
    minWidth: 120,
    // pinned: 'left',
    cellRenderer: 'OTOBookingNumber',
    cellRendererParams: { redirectToBase: `/view/booking_order`, idField: 'id' },
  },
  booking_number_group_renderer: {
    headerName: 'Booking #',
    field: 'booking_number',
    colId: 'booking_number',
    filterKey: 'booking_number',
    filter: 'agTextColumnFilter',
    minWidth: 120,
    cellRenderer: 'agGroupCellRenderer',
    // pinned: 'left',
    cellRendererParams: {
      innerRenderer: 'OTOBookingNumber',
      redirectToBase: `/view/booking_order`,
      idField: 'id',
    },
  },
  vendor: {
    headerName: 'Vendor',
    field: 'vendor_company.registered_name',
    colId: 'vendor_company.registered_name',
    filter: 'CustomFilterWrapper',
    filterKey: 'vendor_company_id',
    width: 200,
    floatingFilterComponent: 'NewFloatingFilter',
    floatingFilterComponentParams: {
      valuePropName: 'registered_name',
      suppressFilterButton: true,
    },
    filterParams: {
      component: CompanySearch,
      componentProps: {
        selectMode: 'multiple',
        searchProps: { is_disabled: [true, false], is_vendor: true },
      },
      renderViewType: 'NewTab',
    },
  },
  booking_date: getDateObject('Booking Date', 'booking_date', 'booking_date', true),
  valid_till_date: {
    headerName: 'Validity Date',
    field: 'valid_till_date',
    colId: 'valid_till_date',
    filterKey: 'valid_till_date',
    filter: 'agDateColumnFilter',
    sortable: true,
    width: 160,
    filterParams: {
      filterOptions: ['inRange', 'equals'],
      suppressAndOrCondition: true,
      clearButton: true,
      debounceMs: 500,
    },
  },
  empty_yard: {
    headerName: 'Empty Yard',
    field: 'empty_pickup_location.name',
    colId: 'empty_pickup_location.name',
    filterKey: 'empty_pickup_location_id',
    width: 150,
    filter: 'CustomFilterWrapper',
    floatingFilterComponent: 'NewFloatingFilter',
    floatingFilterComponentParams: {
      valuePropName: 'name',
      suppressFilterButton: true,
    },
    filterParams: {
      component: GlobalSearch,
      componentProps: {
        selectMode: 'multiple',
        searchProps: { type: ['ICD', 'CFS'] },
        doc_type: 'Global::Location',
      },
    },
  },
  created_by: {
    headerName: 'Created By',
    field: 'created_by',
    colId: 'created_by',
    filterKey: 'created_by_id',
    width: 200,
    filter: 'CustomFilterWrapper',
    floatingFilterComponent: 'NewFloatingFilter',
    floatingFilterComponentParams: {
      valuePropName: 'first_name',
      suppressFilterButton: true,
    },
    filterParams: {
      component: GlobalSearch,
      componentProps: {
        doc_type: 'Network::UserContact',
        selectMode: 'multiple',
      },
    },
  },
  status_text: {
    headerName: 'Status',
    // pinned: 'right',
    field: 'status_text',
    colId: 'status_text',
    filterKey: 'status_text',
    width: 150,
    cellStyle: function (params: { data?: OTOReportData }) {
      const status = _snakeCase(params?.data?.status);
      if (status === STATUS_REQUESTED && params?.data?.created_at) {
        const requested_at = dayjs.unix(params.data.created_at);
        const difference = dayjs(new Date()).diff(requested_at, 'hour');
        if (difference > REQUESTED_AGEING) {
          return { backgroundColor: '#FFF1B8' };
        }
        return null;
      }
      const status_backgroundcolor_mapping: { [key: string]: string } = {
        [STATUS_CONFIRMED]: '#B7EB8F',
        [STATUS_EXPIRING_SOON]: '#FFF1B8',
        [STATUS_EXPIRED]: '#FFCCC7',
      };
      return { backgroundColor: status_backgroundcolor_mapping[status] };
    },
  },
  requested_since: {
    headerName: 'Requested Since',
    // pinned: 'right',
    field: 'requested_since',
    colId: 'requested_since',
    width: 150,
    cellStyle: function (params: { data?: OTOReportData }) {
      const status = _snakeCase(params?.data?.status);
      if (status === STATUS_REQUESTED && params?.data?.created_at) {
        const requested_at = dayjs.unix(params.data.created_at);
        const difference = dayjs(new Date()).diff(requested_at, 'hour');
        if (difference > REQUESTED_AGEING) {
          return { backgroundColor: '#FFF1B8' };
        }
      } else if (status === STATUS_EXPIRED) return { backgroundColor: '#FFCCC7' };
      return null;
    },
  },
  oto_actions: {
    headerName: 'Action',
    field: 'actions',
    lockVisible: true,
    // pinned: 'right',
    suppressSizeToFit: true,
    cellStyle: { textDecoration: 'none' },
    minWidth: 84,
    maxWidth: 90,
  },
  voyage_number: {
    field: 'voyage_number_text',
    colId: 'voyage_number_text',
    rowGroup: true,
    hide: true,
  },
  carrier: {
    ...reportCommonFields['shipping_line'],
  },
  temperature: {
    field: 'temperature',
    colId: 'temperature',
  },
  container_type: {
    headerName: 'Container Type',
    field: 'container_type_code',
    colId: 'container_type_code',
    width: 150,
  },
  oto_id: {
    field: 'oto_id',
    colId: 'oto_id',
    cellRenderer: 'agGroupCellRenderer',
    // pinned: 'left',
    cellRendererParams: {
      innerRenderer: 'renderLinkCell',
      redirectToBase: `/view/booking_order`,
      idField: 'id',
    },
  },
  load_type: {
    headerName: 'Load Type',
    filterKey: 'load_type',
    field: 'load_type',
    colId: 'load_type',
    filter: 'agSetColumnFilter',
    filterParams: {
      values: [LOAD_TYPE_FCL, LOAD_TYPE_LCL],
      debounceMs: 1000,
    },
    cellStyle: { textTransform: 'uppercase' },
  },
  gross_weight: {
    headerName: 'Gross Weight',
    field: 'gross_weight',
    colId: 'gross_weight',
    filter: false,
  },
  booking_type: {
    headerName: 'Booking Type',
    filterKey: 'booking_type',
    field: 'booking_type',
    colId: 'booking_type',
    filter: 'agSetColumnFilter',
    filterParams: {
      values: [BOOKING_TYPE_SHIPPING_LINE, BOOKING_TYPE_VENDOR],
      debounceMs: 1000,
    },
    cellStyle: { textTransform: 'uppercase' },
  },
  last_amended_by: {
    headerName: 'Last Amended By',
    field: 'last_amended_by',
    colId: 'last_amended_by',
    // pinned: 'right',
    minWidth: 120,
  },
};

export const cancelled_booing_fields = [
  ...Object.values(
    _pick(fields, [
      'booking_type',
      'load_type',
      'booking_id',
      'booking_number',
      'shipping_line',
      'vendor',
      'booking_date',
      'port_of_loading',
      'port_of_discharge',
      'vessel_voyage',
    ])
  ),
  {
    ...fields.estimated_time_of_departure,
    sort: 'desc',
  },
  ...Object.values(
    _pick(fields, [
      'commodity',
      'gross_weight',
      'requested_quantity_and_type',
      'created_by',
      'remarks',
      'cancelled_by',
      'cancellation_reason',
      'cancellation_marked_by',
    ])
  ),
  { ...fields.requested_quantity_and_type },
  { ...fields.allocation_pending_quantity_and_type },
];

export const shift_container_booking_fields = [
  'booking_number',
  'vessel_voyage',
  'port_of_discharge',
  'commodity',
  'container_settings',
  'shipping_line',
  'port_of_loading',
];

export type OTODashboardQuantityType =
  | 'quantity_requested'
  | 'quantity_received'
  | 'quantity_confirmed'
  | 'quantity_expired'
  | 'quantity_allocated'
  | 'quantity_allocation_pending'
  | 'quantity_picked_up'
  | 'quantity_pick_up_pending'
  | 'quantity_origin_port_gate_in_pending'
  | 'quantity_origin_port_gated_in'
  | 'quantity_loading_pending'
  | 'quantity_loaded_on_vessel'
  | 'quantity_shutout'
  | 'quantity_offloaded'
  | 'quantity_gate_pass_pending'
  | 'quantity_gate_pass_confirmed';

export const quantity_fields: OTODashboardQuantityType[] = [
  'quantity_requested',
  'quantity_received',
  'quantity_confirmed',
  'quantity_expired',
  'quantity_allocation_pending',
  'quantity_allocated',
  'quantity_pick_up_pending',
  'quantity_picked_up',
  'quantity_origin_port_gate_in_pending',
  'quantity_origin_port_gated_in',
  'quantity_loading_pending',
  'quantity_loaded_on_vessel',
  'quantity_shutout',
  'quantity_offloaded',
  'quantity_gate_pass_pending',
  'quantity_gate_pass_confirmed',
];

interface DashboardColumnsConfigType {
  fieldName: string;
  cssClass: string;
  extraConfig?: ColDef;
}

const dashboardColumnsConfig: DashboardColumnsConfigType[] = [
  {
    fieldName: 'quantity_expired',
    cssClass: 'aggrid-red-cell',
  },
  {
    fieldName: 'quantity_allocation_pending',
    cssClass: 'aggrid-blue-cell',
    extraConfig: { sort: 'desc' },
  },
  {
    fieldName: 'quantity_pick_up_pending',
    cssClass: 'aggrid-yellow-cell',
  },
  {
    fieldName: 'quantity_origin_port_gated_in',
    cssClass: 'aggrid-green-cell',
  },
];
const getOTODashboardColumns = (default_pivots: string[]): Array<Column> => {
  const hiddenColumns: Array<string> = [
    'quantity_requested',
    'quantity_gate_pass_pending',
    'quantity_gate_pass_confirmed',
    'quantity_shutout',
    'quantity_offloaded',
    'booking_date',
    'container_type',
    'temperature',
    'commodity',
    'empty_yard',
    'port_of_loading',
    'estimated_time_of_departure',
    'created_by',
    'booking_number_without_link',
    'container_settings',
    'place_of_carrier_receipt',
    'quantity_requested',
    'quantity_confirmed',
    'quantity_pick_up_pending',
    'quantity_picked_up',
    'quantity_origin_port_gate_in_pending',
    'quantity_origin_port_gated_in',
    'quantity_loading_pending',
    'quantity_loaded_on_vessel',
    'quantity_shutout',
    'quantity_offloaded',
    'quantity_gate_pass_pending',
    'quantity_gate_pass_confirmed',
  ];
  const otoDashboardFields = _cloneDeep(fields);
  hiddenColumns.forEach((hc) => {
    otoDashboardFields[hc]['hide'] = true;
    otoDashboardFields[hc]['pinned'] = null;
  });

  dashboardColumnsConfig.forEach((config) => {
    otoDashboardFields[config.fieldName] = {
      ...otoDashboardFields[config.fieldName],
      cellClassRules: {
        [config.cssClass]: function (params: CellClassParams) {
          return params?.value > 0;
        },
      },
      ...(config.extraConfig ? config.extraConfig : {}),
    };
  });

  const all_columns = [
    ...Object.values(
      _pick(otoDashboardFields, ['customer', 'shipping_line', 'vessel_voyage', 'port_of_discharge'])
    ),
    ...quantity_fields.map((field) => ({ ...otoDashboardFields[field] })),
  ];
  return add_row_groups(all_columns, default_pivots);
};

export const oto_dashboard_columns_mapping: { [key: string]: Array<Column> } = {
  [STATUS_BOOKING_ORDER_DASHBOARD]: getOTODashboardColumns([]),
  [STATUS_BOOKING_ALLOCATED_SUMMARY]: getOTODashboardColumns([]),
};
