import React, { useCallback, useMemo } from 'react';
import { get as _get } from 'lodash';
import { Row, Col, Radio, Input, Checkbox, Form } from '@shipmnts/pixel-hub';
import {
  ROW_GUTTER,
  DocumentType,
  FreightType,
  ShipmentType,
  TradeType,
  FREIGHT_TYPE_OCEAN,
  TRADE_TYPE_EXPORT,
  TRADE_TYPE_IMPORT,
  SHIPMENT_TYPE_DIRECT,
  SHIPMENT_TYPE_BACK_TO_BACK,
  SHIPMENT_TYPE_CONSOL,
  SHIPMENT_TYPE_HOUSE,
  DOCUMENT_TYPE_HOUSE,
  DOCUMENT_TYPE_MASTER,
  CONSIGNEE_TYPES,
  CONSIGNEE_TYPE_TO_ORDER,
  CONSIGNEE_TYPE_TO_ORDER_BANK,
  BUSINESS_TYPE_SUBAGENT,
  BOOKING_THROUGH_OVERSEAS_AGENT,
  BL_TYPE_EXPRESS,
  SELF_CONSOLIDATION_KEY,
  BUYER_CONSOLIDATION,
  SELLER_CONSOLIDATION,
  BL_TYPE_E_BL,
} from 'operations/modules/shipment/constants';
import { FormInstance } from '@shipmnts/pixel-hub';
import { AddressCompanySearch } from 'common';
import { LABEL_JSON } from 'operations/modules/shipment/helpers/labelKeyHelper';
import { RadioOptionProps } from 'operations/commonTypeDefs';

const { TextArea } = Input;
export type AgentType = 'origin_agent' | 'destination_agent';
export type PartyType = 'shipper' | 'consignee';

interface DocumentInitialValues {
  shipper_on_document?: PartyType | AgentType | undefined | null;
  consignee_on_document?: PartyType | AgentType | undefined | null;
}

interface ShipmentPartyDetailsProps {
  form: FormInstance;
  shipmentType: ShipmentType;
  documentType: DocumentType;
  freightType: FreightType;
  tradeType: TradeType;
  notShowConsigneePartyTypes?: boolean;
  hideShipperConsignee?: boolean;
  showDestinationAgent?: boolean;
  documentInitialValues?: DocumentInitialValues;
}

const consginee_to_order_keys = [CONSIGNEE_TYPE_TO_ORDER.key, CONSIGNEE_TYPE_TO_ORDER_BANK.key];

const isPartyToOrder = (consigneeType: string) => consginee_to_order_keys.includes(consigneeType);

const getCheckboxInitialValue = (
  party: PartyType,
  shipmentType: ShipmentType,
  documentType: DocumentType,
  documentInitialValues?: DocumentInitialValues
) => {
  if (
    shipmentType === SHIPMENT_TYPE_HOUSE ||
    shipmentType === SHIPMENT_TYPE_DIRECT ||
    (shipmentType === SHIPMENT_TYPE_BACK_TO_BACK && documentType === DOCUMENT_TYPE_HOUSE)
  )
    return false;
  return _get(documentInitialValues, `${party}_on_document`) !== party;
};

const isSubAgentBusiness = (form: FormInstance) =>
  [BUSINESS_TYPE_SUBAGENT].includes(form.getFieldValue('business_type'));

const isShipperMandatory = (
  freightType: FreightType,
  tradeType: TradeType,
  documentType: DocumentType,
  form: FormInstance
) => {
  return (
    (tradeType === TRADE_TYPE_EXPORT || documentType === DOCUMENT_TYPE_HOUSE) &&
    freightType !== FREIGHT_TYPE_OCEAN &&
    isSubAgentBusiness(form)
  );
};

const ShipmentPartyDetails = React.memo(function ShipmentPartyDetails(
  props: ShipmentPartyDetailsProps
): JSX.Element {
  const {
    form,
    freightType,
    tradeType,
    shipmentType,
    documentType,
    notShowConsigneePartyTypes,
    hideShipperConsignee,
    documentInitialValues,
  } = props;

  const getAgentSameAsPartyValue = useCallback(
    (agent: AgentType, party: PartyType) => {
      let agent_same_as_party = form.getFieldValue([
        documentType,
        'shipment_document',
        `${agent}_same_as_${party}`,
      ]);
      agent_same_as_party =
        agent_same_as_party === undefined
          ? getCheckboxInitialValue(party, shipmentType, documentType, documentInitialValues)
          : agent_same_as_party;
      return agent_same_as_party;
    },
    [documentInitialValues, documentType, form, shipmentType]
  );

  const shouldToOrderPartyRender = useMemo(() => {
    const formConsigneePartyType = form.getFieldValue([documentType, 'consignee_party_name']);
    return isPartyToOrder(formConsigneePartyType);
  }, [documentType, form]);

  const isConsigneeMandatory = useMemo(() => {
    return (
      (tradeType === TRADE_TYPE_IMPORT || documentType === DOCUMENT_TYPE_HOUSE) &&
      freightType !== FREIGHT_TYPE_OCEAN &&
      isSubAgentBusiness(form)
    );
  }, [documentType, form, freightType, tradeType]);

  const renderParty = useCallback(
    (props: { party: PartyType | AgentType; required: boolean; companyHeader?: string }) => {
      const { party, companyHeader, required } = props;
      return (
        <Form.Item
          required={required}
          rules={[{ required: required }]}
          label={companyHeader || LABEL_JSON[`${party}_name`]?.[documentType]?.[freightType]}
          name={[documentType, 'party', party]}
        >
          <AddressCompanySearch />
        </Form.Item>
      );
    },
    [documentType, freightType]
  );

  const renderPartyConditional = (agent: AgentType, party: PartyType, required: boolean) => {
    const agent_same_as_party = getAgentSameAsPartyValue(agent, party);
    const hidePartyInput =
      !agent_same_as_party &&
      shipmentType === SHIPMENT_TYPE_BACK_TO_BACK &&
      documentType === DOCUMENT_TYPE_MASTER;
    const companyHeader = _get(LABEL_JSON, [
      `${party}_name`,
      documentType || DOCUMENT_TYPE_MASTER,
      freightType,
    ]);

    if (hidePartyInput) {
      const housePartyCompany = form.getFieldValue([DOCUMENT_TYPE_HOUSE, `party.${party}`]);
      const housePartyName = housePartyCompany?.registered_name;
      return (
        <Form.Item label={companyHeader}>
          <small>
            {`(Same as ${_get(LABEL_JSON, [`${party}_name`, DOCUMENT_TYPE_HOUSE, freightType])})`}
          </small>
          <br />
          <b>{housePartyName}</b>
        </Form.Item>
      );
    }

    return renderParty({
      party: agent_same_as_party ? agent : party,
      required: required,
      companyHeader: agent_same_as_party
        ? companyHeader
        : shouldToOrderPartyRender && party === 'consignee'
        ? 'Actual Consignee'
        : '',
    });
  };

  return (
    <Col>
      {!hideShipperConsignee && (
        <Row gutter={ROW_GUTTER}>
          <Col span={12}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues[documentType]?.shipment_document?.origin_agent_same_as_shipper !==
                currentValues[documentType]?.shipment_document?.origin_agent_same_as_shipper
              }
            >
              {({ getFieldValue }) => {
                return renderPartyConditional(
                  'origin_agent',
                  'shipper',
                  isShipperMandatory(freightType, tradeType, documentType, form)
                );
              }}
            </Form.Item>
          </Col>

          <Col span={12}>
            {notShowConsigneePartyTypes ? (
              <Form.Item noStyle name={[documentType, 'consignee_party_name']}>
                <span />
              </Form.Item> //TODO Check for initiak value once finished
            ) : (
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues[documentType]?.shipment_document?.bl_type !==
                  currentValues[documentType]?.shipment_document?.bl_type
                }
              >
                {({ getFieldValue }) => {
                  const bl_type = getFieldValue([documentType, 'shipment_document', 'bl_type']);
                  const disabledKeys =
                    bl_type === BL_TYPE_EXPRESS || bl_type === BL_TYPE_E_BL
                      ? consginee_to_order_keys
                      : [];
                  return (
                    <Form.Item
                      required
                      rules={[{ required: true }]}
                      label={LABEL_JSON.consignee_party_name[documentType][freightType]}
                      name={[documentType, 'consignee_party_name']}
                    >
                      <Radio.Group>
                        {CONSIGNEE_TYPES.map((option: RadioOptionProps, index: number) => (
                          <Radio
                            key={index}
                            value={option.key}
                            disabled={disabledKeys.includes(option.key)}
                          >
                            {option.name}
                          </Radio>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                  );
                }}
              </Form.Item>
            )}

            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues[documentType]?.consignee_party_name !==
                currentValues[documentType]?.consignee_party_name
              }
            >
              {({ getFieldValue }) => {
                const consignee_party_name = getFieldValue([documentType, 'consignee_party_name']);
                const shouldToOrderPartyRender = isPartyToOrder(consignee_party_name);
                if (!shouldToOrderPartyRender) return null;
                return (
                  <Form.Item
                    required={isConsigneeMandatory}
                    rules={[{ required: isConsigneeMandatory }]}
                    name={[documentType, 'party', consignee_party_name, 'party_details']}
                    label={LABEL_JSON[consignee_party_name][documentType][freightType]}
                  >
                    <TextArea rows={2} />
                  </Form.Item>
                );
              }}
            </Form.Item>

            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues[documentType]?.shipment_document?.destination_agent_same_as_consignee !==
                  currentValues[documentType]?.shipment_document
                    ?.destination_agent_same_as_consignee ||
                prevValues?.business_type !== currentValues?.business_type
              }
            >
              {({ getFieldValue }) => {
                const agent_same_as_party = getAgentSameAsPartyValue(
                  'destination_agent',
                  'consignee'
                );
                const businessType = getFieldValue('business_type');
                const isRequired =
                  (shipmentType === SHIPMENT_TYPE_CONSOL &&
                    agent_same_as_party &&
                    businessType === BOOKING_THROUGH_OVERSEAS_AGENT) ||
                  (shouldToOrderPartyRender
                    ? freightType !== FREIGHT_TYPE_OCEAN
                    : isConsigneeMandatory);

                return renderPartyConditional('destination_agent', 'consignee', isRequired);
              }}
            </Form.Item>
          </Col>
        </Row>
      )}

      {(shipmentType === SHIPMENT_TYPE_CONSOL || documentType === DOCUMENT_TYPE_MASTER) &&
        shipmentType !== SHIPMENT_TYPE_DIRECT &&
        !hideShipperConsignee && (
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.consol_type !== currentValues.consol_type
            }
          >
            {({ getFieldValue }) => {
              const consolType = getFieldValue('consol_type');
              return (
                <Row gutter={ROW_GUTTER}>
                  <Col span={12}>
                    <Form.Item
                      name={[documentType, 'shipment_document', 'origin_agent_same_as_shipper']}
                      valuePropName="checked"
                    >
                      <Checkbox
                        disabled={
                          consolType === SELF_CONSOLIDATION_KEY ||
                          consolType === BUYER_CONSOLIDATION.key
                        }
                      >
                        Origin Agent same as {LABEL_JSON['shipper_name'][documentType][freightType]}
                      </Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={[
                        documentType,
                        'shipment_document',
                        'destination_agent_same_as_consignee',
                      ]}
                      valuePropName="checked"
                    >
                      <Checkbox
                        disabled={
                          consolType === SELF_CONSOLIDATION_KEY ||
                          consolType === SELLER_CONSOLIDATION.key
                        }
                      >
                        Destination Agent same as{' '}
                        {LABEL_JSON['consignee_name'][documentType][freightType]}
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              );
            }}
          </Form.Item>
        )}

      <Row gutter={ROW_GUTTER}>
        <Col span={12}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues[documentType]?.shipment_document?.origin_agent_same_as_shipper !==
              currentValues[documentType]?.shipment_document?.origin_agent_same_as_shipper
            }
          >
            {({ getFieldValue }) => {
              const showOriginAgent =
                shipmentType === SHIPMENT_TYPE_CONSOL &&
                !getAgentSameAsPartyValue('origin_agent', 'shipper');

              if (!showOriginAgent) return null;
              else
                return renderParty({
                  party: 'origin_agent',
                  companyHeader: 'Origin Agent',
                  required: false,
                });
            }}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues[documentType]?.shipment_document?.destination_agent_same_as_consignee !==
                currentValues[documentType]?.shipment_document
                  ?.destination_agent_same_as_consignee ||
              prevValues?.business_type !== currentValues?.business_type
            }
          >
            {({ getFieldValue }) => {
              const showDestinationAgent =
                ((shipmentType === SHIPMENT_TYPE_CONSOL || documentType === DOCUMENT_TYPE_MASTER) &&
                  ([SHIPMENT_TYPE_DIRECT, SHIPMENT_TYPE_HOUSE].includes(shipmentType) ||
                    !getAgentSameAsPartyValue('destination_agent', 'consignee'))) ||
                props.showDestinationAgent;
              const businessType = getFieldValue('business_type');
              if (!showDestinationAgent) return null;
              else
                return renderParty({
                  party: 'destination_agent',
                  companyHeader: 'Destination Agent',
                  required: businessType === BOOKING_THROUGH_OVERSEAS_AGENT,
                });
            }}
          </Form.Item>
        </Col>
      </Row>
    </Col>
  );
});

export default ShipmentPartyDetails;
