export const FREIGHT_TYPE_AIR = 'air';
export const FREIGHT_TYPE_OCEAN = 'ocean';
export const FREIGHT_TYPE_ROAD = 'road';
export const FREIGHT_TYPE_RAIL = 'rail';

export const LOAD_TYPE_LOOSE = 'loose';
export const LOAD_TYPE_ULD = 'ULD';
export const LOAD_TYPE_FCL = 'FCL';
export const LOAD_TYPE_LCL = 'LCL';
export const LOAD_TYPE_BREAKBULK = 'Breakbulk';
export const LOAD_TYPE_FTL = 'FTL';

export const LOAD_TYPE_OPTIONS = {
  [FREIGHT_TYPE_AIR]: [
    {
      label: 'Loose',
      value: 'loose',
    },
    {
      label: 'ULD',
      value: 'uld',
    },
  ],
  [FREIGHT_TYPE_OCEAN]: [
    {
      label: 'FCL',
      value: 'fcl',
    },
    {
      label: 'LCL',
      value: 'lcl',
    },
    {
      label: 'Breakbulk',
      value: 'breakbulk',
      disabled: true,
    },
  ],
  [FREIGHT_TYPE_ROAD]: [
    {
      label: 'FTL',
      value: 'ftl',
    },
    {
      label: 'FCL',
      value: 'fcl',
    },
    {
      label: 'LCL',
      value: 'lcl',
    },
  ],
};

export const LOAD_TYPE_TOOLTIP_TEXT: { [key: string]: string } = {
  [FREIGHT_TYPE_OCEAN]:
    'Type of load such as Full Container Load (FCL), Less than container load (LCL), or Break Bulk',
  [FREIGHT_TYPE_AIR]: 'Type of load such as Loose Cargo or Cargo loaded on Unit Load Device',
};

export const SERVICE_TYPE_FREIGHT = 'freight_forwarding';
export const SERVICE_TYPE_ORIGIN_CLEARANCE = 'origin_custom_clearance';
export const SERVICE_TYPE_DESTINATION_CLEARANCE = 'destination_custom_clearance';
export const SERVICE_TYPE_ORIGIN_TRANSPORT = 'origin_transport';
export const SERVICE_TYPE_DESTINATION_TRANSPORT = 'destination_transport';
export const SERVICE_PALLETIZATION = 'palletization';
export const SERVICE_FUMIGATION = 'fumigation';
export const SERVICE_CARGO_INSURANCE = 'cargo_insurance';
export const SERVICE_EMPTY_CONTAINER_INSURANCE = 'empty_container_insurance';
export const SERVICE_EMPTY_WAREHOUSE = 'warehouse';
export const SERVICE_EMPTY_LIVE_TRACKING = 'live_tracking';

// ! copied from tracker
export type FieldType = {
  id?: string;
  name: string;
  label: string;
  type?: string;
  cellRenderer?: string;
  initialValue?: any;
  rules?: any;
  dependency?: {
    name: string;
    value?: any;
  };
  options?: {
    name: string;
    value: string;
  }[];
  colSpan?: number;
  defaultDisplay?: boolean; // default show dependent field
  searchProps?: any;
  fieldType?: string;
  onChange?: (val?: any, rf?: any) => void;
  addonBefore?: React.ReactNode;
  addonAfter?: React.ReactNode;
};

export type TypeFreightType = 'ocean' | 'air' | 'road';

export const PERMISSION_ESTIMATES_TOTAL = 'margin';
export const PERMISSION_FREIGHT_BUY = 'freight_buy';
export const PERMISSION_FREIGHT_SELL = 'freight_sell';
export const PERMISSION_REBATE_BUY = 'rebate_buy';
export const PERMISSION_REBATE_SELL = 'rebate_sell';
export const PERMISSION_SALES_MODULE = 'list';
export const DOCUMENT_PERMISSION_VIEW = 'view';
export const PERMISSION_BUY_ESTIMATE = 'buy';
export const PERMISSION_SELL_ESTIMATE = 'sell';

export const ENUM_BUY_SELL_STATUS = {
  UNBILLED: 'unbilled',
  BILLED: 'billed',
  DRAFT: 'draft',
  CANCELLED: 'cancelled',
  IGNORED: 'ignored',
};
export type ENUM_BUY_SELL_STATUS_TYPE = 'unbilled' | 'billed' | 'draft' | 'ignored';

export const FREIGHT_CHARGE_TAG = 'freight_charge';
export const REBATE_CHARGE_TAG = 'rebate_charge';
export const DUE_CARRIER_TAG = 'due_carrier';

export const CHARGE_AMOUNT_PREPAID = 'prepaid';
export const CHARGE_AMOUNT_COLLECT = 'collect';
export const CHARGE_TERMS = [
  { key: CHARGE_AMOUNT_PREPAID, name: 'PP-prepaid' },
  { key: CHARGE_AMOUNT_COLLECT, name: 'CC-collect' },
];

export const BASIS_TYPE_CHARGEABLE_WEIGHT = {
  name: 'Chargeable Weight',
  key: 'chargeable_weight',
};

export const BASIS_TYPE_GROSS_WEIGHT = {
  name: 'Gross Weight',
  key: 'gross_weight',
};

export const BASIS_TYPE_SHIPMENT = {
  name: 'Shipment',
  key: 'shipment',
};

export const BASIS_TYPE_GROSS_VOLUME = {
  name: 'Gross Volume',
  key: 'gross_volume',
};

export const BASIS_TYPE_PACKAGES = {
  name: 'Package',
  key: 'total_number_of_packages',
};

export const BASIS_TYPE_ULD = {
  name: 'Uld',
  key: 'uld',
};

export const BASIS_TYPE_CONTAINER = {
  name: 'Container',
  key: 'container',
};

export const INQUIRY_OPTION_WON_STATUS = 'won';
export const INQUIRY_OPTION_LOST_STATUS = 'lost';
export const INQUIRY_OPTION_DRAFT_STATUS = 'draft';
export const INQUIRY_OPTION_CANCELLED_STATUS = 'cancelled';
export const INQUIRY_OPTION_SEND_TO_CUSTOMER_STATUS = 'sent_to_customer';

export const INQUIRY_OPTIONS_DISPLAY_HASH = {
  [INQUIRY_OPTION_WON_STATUS]: 'Won',
  [INQUIRY_OPTION_DRAFT_STATUS]: 'Draft',
  [INQUIRY_OPTION_LOST_STATUS]: 'Lost',
  [INQUIRY_OPTION_CANCELLED_STATUS]: 'Cancelled',
  [INQUIRY_OPTION_SEND_TO_CUSTOMER_STATUS]: 'Sent To Customer',
};

export const INQUIRY_OPTIONS_COLOR_HASH = {
  [INQUIRY_OPTION_WON_STATUS]: 'green',
  [INQUIRY_OPTION_DRAFT_STATUS]: 'default',
  [INQUIRY_OPTION_LOST_STATUS]: 'red',
  [INQUIRY_OPTION_CANCELLED_STATUS]: 'red',
  [INQUIRY_OPTION_SEND_TO_CUSTOMER_STATUS]: 'blue',
};

export const PRINT_TEMPLATE_TYPE = 'print';
export const TERMS_AND_COND_TEMPLATE_TYPE = 'terms_and_condition';
export const DOC_INS_TEMPLATE_TYPE = 'documentation_instructions';
export const LETTER_HEAD_TEMPLATE_TYPE = 'letter_head';

export const CREATE_BLANK_ACTION = 'blank_action';
export const CREATE_COPY_ACTION = 'create_copy_action';

export const RENDER_INQUIRY_FOR_WON = 'mark_won';
export const RENDER_INQUIRY_FOR_LOST = 'mark_lost';

export const SALES_PERSON_DISABLED_FEATURE = 'sales_person_disabled';
