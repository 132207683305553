import React from 'react';
import { ShipmentValue } from 'operations/models/Shipment';
import { ActionRenderer } from 'operations/modules/actionHelper/ActionRenderer';
import { addNewHouseOrLinkShipmentActions } from 'operations/modules/actionHelper/ShipmentActions/shipmentActionHelper';
interface addHousePropType {
  masterShipment: ShipmentValue;
  refetchShipments?: () => void;
}

// Assumptiom: this component will mount only for Consol shipment
const AddHouseShipment = ({ masterShipment, refetchShipments }: addHousePropType) => {
  if (!masterShipment) return <div />;
  return (
    <>
      <span style={{ padding: '10px' }}>
        {masterShipment && (
          <ActionRenderer
            isDetailScreen
            id={masterShipment.id}
            refetchData={refetchShipments}
            data={masterShipment}
            doc_type_id="Shipment::Shipment"
            selectedActions={addNewHouseOrLinkShipmentActions(masterShipment).filter(
              (action) => action.isEnable
            )}
            buttonSize="small"
          />
        )}
      </span>
    </>
  );
};

export default AddHouseShipment;
