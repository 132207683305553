import React from 'react';
import { Row, Col, Input, Form } from '@shipmnts/pixel-hub';
import { AddressCompanySearch } from 'common';
import { LOCATION_TYPE_CITY } from 'operations/models/Location';
import { ROW_GUTTER, SHIPMENT_TYPE_DIRECT } from 'operations/modules/shipment/constants';
import { CreateShipmentType } from 'operations/modules/reports/components/ContainerReports/ContainerActions/ContainerActionDrawer';
import { GlobalSearch } from '@shipmnts/pixel-hub';

const DestinationTransportDetail = React.memo(function DestinationTransportDetail(props: {
  shipmentType: CreateShipmentType;
  service_name: string[];
}): JSX.Element {
  const { shipmentType } = props;
  return (
    <Row gutter={ROW_GUTTER}>
      <Col span={12}>
        <Form.Item
          label="Destination Transport Agent"
          name={['party', 'destination_transport_agent']}
        >
          <AddressCompanySearch />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Final Place Of Delivery" name="final_place_of_delivery">
          <GlobalSearch doc_type="Global::Location" searchProps={{ type: [LOCATION_TYPE_CITY] }} />
        </Form.Item>
        {shipmentType !== SHIPMENT_TYPE_DIRECT && (
          <Form.Item
            label="Print Final Place Of Delivery on HBL as"
            name="final_place_of_delivery_print"
          >
            <Input />
          </Form.Item>
        )}
      </Col>
    </Row>
  );
});

export default DestinationTransportDetail;
