import { gql } from '@apollo/client';
import { branchAccountFields, userAccountFields } from 'operations/graphql/shipment';
import { userContactFields } from './contact';

export const collaboratorsFields = gql`
  fragment collaboratorsFields on CollaboratorObjectType {
    id
    user_contact {
      id
      department
      email
      first_name
      last_name
      mobile_number
      dial_code
      title
      is_internal
      is_disabled
      branch_accounts {
        ...branchAccountFields
      }
      companies {
        registered_name
      }
    }
    added_by {
      ...userAccountFields
    }
    reference_id
    reference_type
  }
  ${userAccountFields}
  ${branchAccountFields}
`;

export const GET_COLLABORATOR = gql`
  query get_collaborators($reference_ids: [ID!]!, $reference_type: String!) {
    get_collaborators(reference_ids: $reference_ids, reference_type: $reference_type) {
      ...collaboratorsFields
    }
  }
  ${collaboratorsFields}
`;

export const ADD_COLLABORATOR = gql`
  mutation add_collaborator($collaborators: [CollaboratorInputType!]!, $email_body: String) {
    add_collaborator(collaborators: $collaborators, email_body: $email_body) {
      ...collaboratorsFields
    }
  }
  ${collaboratorsFields}
`;

export const REMOVE_COLLABORATOR = gql`
  mutation remove_collaborator($id: ID!) {
    remove_collaborator(id: $id) {
      message
    }
  }
`;

export const GET_FREQUENT_COLLABORATORS = gql`
  query fetch_frequent_collaborators($reference_id: ID!, $reference_type: String!) {
    fetch_frequent_collaborators(reference_id: $reference_id, reference_type: $reference_type) {
      ...userContactFields
    }
  }
  ${userContactFields}
`;
