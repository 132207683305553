import { axiosInstance as axios } from 'common';

export interface Template {
  styles: string;
  scripts: string;
  body: string;
}

export interface DocumentField {
  type: string;
  field_type: string;
  default_source: string[];
  key?: string;
  item?: string;
  fallback_source?: string[];
  properties?: { [key: string]: DocumentField };
  function_name?: string;
}

interface Condition {
  fact: string;
  operator: string;
  value: string | string[];
}
export interface Rule {
  id: string;
  document_clause_id: string;
  conditions: {
    all: Condition[];
  };
}

interface DocumentClause {
  id: string;
  field_name: string;
  field_type: string;
  label?: string;
  value?: string;
  prefix?: string;
  fields_applicable: string[];
  document_clause_ids?: string[];
}

export interface Document {
  id: string;
  document_name: string;
  document_type: string;
  dependencies: string[];
  document_field: { [key: string]: DocumentField };
  shipment_id: string;
  html: Template;
  rules: Rule[];
  document_clauses: DocumentClause[];
  print_option: object;
  shipment_document_id?: string;
  is_new_document?: boolean;
}

export const fetchDocument = async (docId: string) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.DOCGEN_URL}/generate_documents/${docId}`,
    });
    return { response, error: false };
  } catch (error) {
    console.error(error);
    return { error };
  }
};
