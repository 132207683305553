import React, { useEffect, useState } from 'react';
import { Button, Input, Space, Typography } from '@shipmnts/pixel-hub';
import PhOpenSelectOption from './PhOpenSelectOption';

const { Text } = Typography;

interface PhOpenSelectProps {
  options: { label?: string; value: any; className?: string }[];
  value?: any | any[];
  mode?: 'single' | 'multiple';
  onChange?: (value: any) => void;
  disabled?: boolean;
}

function PhOpenSelect(props: PhOpenSelectProps) {
  const { options, value, onChange, mode = 'multiple', disabled } = props;
  const [searchValue, setSearchValue] = useState('');
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    if (onChange) onChange(internalValue);
  }, [internalValue, onChange]);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const onSelect = (selectedValue: any) => {
    if (mode === 'single') setInternalValue(selectedValue);
    else setInternalValue(internalValue ? internalValue.concat(selectedValue) : [selectedValue]);
  };

  const onDeselect = (selectedValue: any) => {
    if (mode === 'single') setInternalValue(undefined);
    else setInternalValue(internalValue?.filter((item: any) => item !== selectedValue));
  };

  const onClear = () => {
    setInternalValue(mode === 'single' ? undefined : []);
  };

  const onSelectAll = () => {
    if (mode !== 'multiple') return;
    setInternalValue(options.map((option) => option.value));
  };

  return (
    <Space direction="vertical">
      {mode === 'multiple' && (
        <Space>
          <Text type="secondary">Select</Text>
          <Space size={1}>
            <Button size="small" type="link" disabled={disabled} onClick={onSelectAll}>
              All
            </Button>
            <span>/</span>
            <Button size="small" type="link" disabled={disabled} onClick={onClear}>
              None
            </Button>
          </Space>
        </Space>
      )}
      {options.length > 5 && (
        <Input
          placeholder="Type to filter options"
          value={searchValue}
          autoFocus
          onChange={(e) => setSearchValue(e.target.value || '')}
        />
      )}
      <div
        style={{
          maxHeight: '300px',
          overflow: 'auto',
        }}
      >
        {options
          .filter(
            (option) => `${option?.label}`.toLowerCase().indexOf(searchValue?.toLowerCase()) >= 0
          )
          .map((option) => (
            <PhOpenSelectOption
              key={option.value}
              value={option.value}
              disabled={disabled}
              selected={
                mode === 'single'
                  ? internalValue === option.value
                  : internalValue?.includes(option.value)
              }
              onSelect={onSelect}
              onDeselect={onDeselect}
            >
              <div className={option.className}>{option.label || option.value}</div>
            </PhOpenSelectOption>
          ))}
      </div>
    </Space>
  );
}

export default PhOpenSelect;
