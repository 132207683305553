/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotIn, SnapshotOut, IAnyModelType } from 'mobx-state-tree';
import Location from 'network/models/Location';
import Company from 'network/models/Company';

const Address = types.model({
  id: types.identifier,
  name: types.maybe(types.maybeNull(types.string)),
  company_id: types.maybe(types.string),
  entity_type: types.maybe(types.maybeNull(types.string)),
  city: types.maybe(types.maybeNull(Location)),
  city_name: types.maybe(types.maybeNull(types.string)),
  state_code: types.maybe(types.maybeNull(types.string)),
  state_name: types.maybe(types.maybeNull(types.string)),
  country_code: types.maybe(types.maybeNull(types.string)),
  country_name: types.maybe(types.maybeNull(types.string)),
  print_address: types.maybe(types.string),
  is_disabled: types.maybe(types.boolean),
  is_internal_address: types.maybe(types.boolean),
  company: types.maybe(types.late((): IAnyModelType => Company)),
  accounts_address_link: types.maybe(types.maybeNull(types.string)),
  address_line_1: types.maybe(types.maybeNull(types.string)),
  address_line_2: types.maybe(types.maybeNull(types.string)),
  postal_code: types.maybe(types.maybeNull(types.string)),
  attn: types.maybe(types.maybeNull(types.string)),
  state_tax_code: types.maybe(types.maybeNull(types.string)),
});

export interface AddressValue extends Instance<typeof Address> {}
export interface AddressIn extends SnapshotIn<typeof Address> {}
export interface AddressOut extends SnapshotOut<typeof Address> {}

export default Address;
