import React from 'react';

import { UploadedDocuments } from '@shipmnts/pixel-hub';
import { Card } from '../Card';
import { useSession } from 'common';
import { useContactView } from '../ContactView';

export const DocumentsTab = () => {
  const { contact, loading } = useContactView();
  const session = useSession();

  return (
    <>
      <div
        style={{
          width: '70%',
          marginInline: 'auto',
          marginBlock: '1em 2em',
        }}
      >
        <Card
          loading={loading}
          header={{ title: 'Documents' }}
          style={{ marginInline: 'auto', width: '100%', marginTop: '1em' }}
          Action={<></>}
        >
          <div style={{ marginTop: '1.5em' }}>
            {contact ? (
              <UploadedDocuments
                sessionData={session}
                parent_id={contact.id}
                parent_type={'user_contact'}
                docgen_url={process.env.DOCGEN_URL || ''}
              />
            ) : (
              <></>
            )}
          </div>
        </Card>
      </div>
    </>
  );
};
