// eslint-disable-next-line @typescript-eslint/ban-ts-comment

import React, { useEffect, useRef, useState, useReducer, useCallback } from 'react';
import {
  Layout,
  Button,
  Card,
  Row,
  Col,
  message,
  Checkbox,
  Input,
  Radio,
  Form,
  UploadDocumentModal,
  DocumentsList,
  documentsStore,
  UploadDocumentPayload,
  UploadedDocumentType,
  uploadDocument,
  deleteUploadedDocument,
  addDocumentParents,
} from '@shipmnts/pixel-hub';
import { PageHeader } from '@shipmnts/pixel-hub';

import { useLocation } from 'wouter';
import { PartyValue } from 'operations/modules/reports/components/CreateShipmentFormContent';
import { ShipmentPartyValue, transformPartyDetails } from 'operations/models/ShipmentParty';
import BookingRequestBasicDetails from './BookingRequestBasicDetails';
import ContainerDetails from 'operations/components/ContainerDetails';
import CargoDetails from 'operations/components/CargoDetails';
import {
  LOAD_TYPE_FCL,
  RTO_TYPE_LOOSE,
  STUFFING_LOCATION_TYPE_FACTORY,
  stuffing_location_types,
  LOAD_TYPE_LCL,
  WEIGHT_UNIT_KGS,
  VOLUME_UNIT_CBM,
} from 'operations/baseConstants';
import RoadTransportOrderForm from '../RoadTransportOrder/RoadTransportOrderForm';
import StuffingServiceForm from './StuffingServiceForm';
import BookingRoutingDetails from '../BookingOrderForm/BookingRoutingDetails';
import { omit as _omit, startCase as _startCase } from 'lodash';
import { ApolloClient, useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import { RoutingLegValue } from 'operations/models/RoutingLeg';
import { CarrierValue } from 'operations/models/Carrier';
import { ContainerRequestValue } from 'operations/models/ContainerRequest';
// import { SalesPersonValue } from 'common';
import { CREATE_BOOKING_REQUEST } from 'operations/modules/booking/graphql/bookingRequest';
import { CargoValue, isLiveReeferCargo } from 'operations/models/Cargo';
import { RoutingNodeValue } from 'operations/models/RoutingNode';
import { AddressValue } from 'operations/models/Address';
import { useEmailDrawerContext, useSession, errorMessageHandlerGraphQL } from 'common';
import { AppHelmet, dayjs, Dayjs, DatePicker } from '@shipmnts/pixel-hub';

import {
  BOOKING_TYPE_BOOKING_REQUEST,
  CLEARANCE_SERVICE,
  CARGO_INSURANCE_SERVICE,
  EMPTY_CONTAINER_INSURANCE_SERVICE,
  MISCELLANEOUS_SERVICE_ORDERS,
} from 'operations/modules/booking/constants';
import { getSalesPersonForCustomer } from 'operations/helpers/fetchSalesPerson';
import { BranchAccountValue } from 'operations/models/BranchAccount';
import { SessionDataValue } from 'operations/models/SessionData';
import { FormInstance } from '@shipmnts/pixel-hub';
import BookingRequest from 'operations/models/BookingRequest';
import { CreateBRFormValue } from 'operations/modules/reports/components/OTOReports/CreateBookingRequestFromOTO';
import MiscellaneousServiceForm from './MiscellaneousServiceForm';
import { createRoutingValues } from 'operations/components/RoutingDetails';
import { InquiryOptionValue } from 'operations/models/InquiryOption';
import { GET_INQUIRY_OPTION } from '../../graphql/inquiryOption';
import { checkCustomerValid } from '../../helpers/DuplicateBookingHelper';
import { getServicesPayloadFromInquiry } from 'operations/modules/helpers';
import { SalesPersonValue } from 'common/models/SalesPerson';

const { Header, Content } = Layout;

function useQuery(): URLSearchParams {
  return new URLSearchParams(window.location.search);
}

interface OTOFormValue {
  routing_details: {
    routing_legs: RoutingLegValue[];
    routing_nodes: RoutingNodeValue[];
  };
  vendor?: PartyValue;
  customer?: PartyValue;
  sales_person?: SalesPersonValue;
}

const getOTOPayload = (ocean_transport_order: OTOFormValue) => {
  const { routing_details, vendor, customer, sales_person, ...restProps } = ocean_transport_order;
  const { routing_legs, routing_nodes } = routing_details;
  return {
    ...restProps,
    routing_legs: routing_legs.map((rl: RoutingLegValue) => {
      const {
        vessel,
        estimated_time_of_departure,
        estimated_time_of_arrival,
        global_carrier,
        ...restRL
      } = rl;
      return {
        ...restRL,
        global_carrier_id: global_carrier?.id,
        vessel_id: vessel?.imo,
        estimated_time_of_departure: estimated_time_of_departure?.unix(),
        estimated_time_of_arrival: estimated_time_of_arrival?.unix(),
      };
    }),
    routing_nodes: Object.values(routing_nodes).map((rn: RoutingNodeValue) => {
      const { location, company, address, terminal, ...restRN } = rn;
      return { ...restRN, location_id: location?.id, terminal_id: terminal?.id };
    }),
    vendor_company_id: vendor?.party_company?.id,
    vendor_address_id: vendor?.party_address?.id,
    customer_company_id: customer?.party_company?.id,
    customer_address_id: customer?.party_address?.id,
    sales_person_id: sales_person?.id,
  };
};

interface RTOFormValue {
  load_type: string;
  vendor?: PartyValue;
  managed_by?: string;
  factory_location?: AddressValue;
}

export interface StuffingBufferServiceFormValue {
  vendor?: PartyValue;
  location_node?: RoutingNodeValue;
  stuffing_cutoff_date?: Dayjs;
}

export interface MiscellaneousServiceOrderFormValue {
  vendor?: PartyValue;
  enabled: boolean;
  name: string;
}

export interface BookingRequestFormOTOType {
  ocean_transport_order?: OTOFormValue;
}

export interface BookingRequestFormValueType extends BookingRequestFormOTOType {
  load_type?: string;
  high_priority?: boolean;
  quotation_number?: string;
  inquiry_option?: InquiryOptionValue;
  is_external_quotation_number?: boolean;
  incoterm?: string;
  remarks?: string;
  stuffing_location_type: string;
  cargos?: CargoValue[];
  container_requests?: ContainerRequestValue[];
  preferred_carriers?: CarrierValue[];
  pickupRequested?: boolean;
  road_transport_orders?: RTOFormValue[];
  party?: {
    [key: string]: ShipmentPartyValue;
  };
  stuffing_buffer_service_order?: StuffingBufferServiceFormValue;
  [MISCELLANEOUS_SERVICE_ORDERS]: MiscellaneousServiceOrderFormValue[];
  cargo_ready_date?: Dayjs;
}

const isLclRTOPresent = (road_transport_orders: RTOFormValue[] | undefined) => {
  if ((road_transport_orders || []).length === 0) return false;
  return (road_transport_orders || []).reduce(
    (acc: boolean, rto: RTOFormValue) => acc && rto.load_type !== LOAD_TYPE_FCL,
    true
  );
};

const getBookingRequestPayload = (
  values: BookingRequestFormValueType,
  pickupRequested: boolean,
  bufferRequested: boolean
) => {
  const {
    load_type,
    high_priority,
    quotation_number,
    inquiry_option,
    is_external_quotation_number,
    incoterm,
    remarks,
    ocean_transport_order,
    cargos,
    container_requests,
    preferred_carriers,
    road_transport_orders,
    party,
    stuffing_buffer_service_order,
    stuffing_location_type,
    miscellaneous_service_orders,
    cargo_ready_date,
  } = values;
  return {
    load_type,
    high_priority,
    quotation_number,
    inquiry_option_id: inquiry_option?.id,
    is_external_quotation_number,
    incoterm,
    remarks,
    preferred_carrier_ids: (preferred_carriers || []).map((pc: CarrierValue) => pc.id),
    ocean_transport_order: ocean_transport_order && getOTOPayload(ocean_transport_order),
    cargos: (cargos || []).map((cargo: CargoValue) => {
      const { commodity, _id, allocation_pending_quantity, ...restCargos } = cargo;
      return {
        ...restCargos,
        commodity_id: commodity?.id,
      };
    }),
    container_requests: (container_requests || []).map((container_request: ContainerRequestValue) =>
      _omit<ContainerRequestValue, '_id'>(container_request, '_id')
    ),
    road_transport_orders: pickupRequested
      ? (road_transport_orders || []).map((rto) => ({
          managed_by: rto.managed_by,
          load_type: rto.load_type,
          vendor_company_id: rto?.vendor?.party_company?.id,
          vendor_address_id: rto?.vendor?.party_address?.id,
          factory_location_id: rto?.factory_location?.id,
          type: 'origin',
        }))
      : [],
    booking_request_parties: transformPartyDetails(Object.assign({}, party)),
    stuffing_location_type,
    stuffing_buffer_service_order:
      bufferRequested || isLclRTOPresent(road_transport_orders)
        ? {
            vendor_company_id: stuffing_buffer_service_order?.vendor?.party_company?.id,
            buffer_only: stuffing_location_type === STUFFING_LOCATION_TYPE_FACTORY,
            location_node: {
              location_id: stuffing_buffer_service_order?.location_node?.location?.id,
              tags: ['stuffing_location'],
            },
            stuffing_cutoff_date: stuffing_buffer_service_order?.stuffing_cutoff_date?.unix(),
          }
        : undefined,
    miscellaneous_service_orders: miscellaneous_service_orders
      .filter((mso) => !!mso.enabled)
      .map((mso) => ({
        vendor_company_id: mso?.vendor?.party_company?.id,
        name: mso?.name,
      })),
    cargo_ready_date: cargo_ready_date ? cargo_ready_date.unix() : undefined,
  };
};

export const setSalesPerson = async (
  form: FormInstance,
  changedValues: BookingRequestFormOTOType,
  allValues: BookingRequestFormOTOType,
  sessionData: SessionDataValue,
  client: ApolloClient<object>
) => {
  // NOTE: getting called twice due to customer.party_company & customer.party_address
  if (
    (changedValues?.ocean_transport_order?.customer?.party_company ||
      changedValues?.ocean_transport_order?.vendor?.party_address) &&
    allValues?.ocean_transport_order?.customer?.party_company &&
    allValues?.ocean_transport_order?.vendor?.party_address
  ) {
    const branch_id = sessionData.branch_accounts?.find(
      (ba: BranchAccountValue) =>
        ba?.default_address?.id === allValues?.ocean_transport_order?.vendor?.party_address?.id
    )?.id;
    if (!branch_id) return;
    const { response } = await getSalesPersonForCustomer(
      allValues?.ocean_transport_order?.customer?.party_company?.id,
      branch_id,
      sessionData,
      client
    );
    form.setFieldsValue({
      ocean_transport_order: { sales_person: response },
    });
  }
};

const getUpdatedRtoLoadType = (
  stuffing_location_type: string,
  road_transport_orders: Array<RTOFormValue>
): Array<RTOFormValue> => {
  const rtoType =
    stuffing_location_type === STUFFING_LOCATION_TYPE_FACTORY ? LOAD_TYPE_FCL : RTO_TYPE_LOOSE;

  return road_transport_orders?.map((rto, index) => {
    return {
      ...rto,
      load_type: rtoType,
    };
  });
};

const setRTOLoadType = async (
  form: FormInstance,
  changedValues: BookingRequestFormValueType,
  allValues: BookingRequestFormValueType
) => {
  if (!allValues?.road_transport_orders) return;
  if (changedValues?.stuffing_location_type) {
    form.setFieldsValue({
      road_transport_orders: getUpdatedRtoLoadType(
        changedValues.stuffing_location_type,
        allValues?.road_transport_orders
      ),
    });
  }
};

export const clearStuffingLocationType = async (
  form: FormInstance,
  changedValues: BookingRequestFormValueType,
  allValues: BookingRequestFormValueType | CreateBRFormValue
) => {
  if (!allValues?.stuffing_buffer_service_order) return;
  if (changedValues?.stuffing_location_type) {
    form.setFieldsValue({
      stuffing_buffer_service_order: {
        ...allValues?.stuffing_buffer_service_order,
        location_node: undefined,
      },
    });
  }
};

export const clearInquiryOption = async (
  form: FormInstance,
  changedValues: BookingRequestFormValueType,
  allValues: BookingRequestFormValueType
) => {
  if (
    changedValues?.ocean_transport_order?.customer?.party_company &&
    allValues.inquiry_option &&
    changedValues.ocean_transport_order.customer.party_company.id !==
      allValues.inquiry_option.inquiry?.customer_company?.id
  ) {
    form.setFieldsValue({
      inquiry_option: null,
    });
  }
  if (changedValues?.ocean_transport_order?.vendor?.party_address && allValues.inquiry_option) {
    form.setFieldsValue({
      inquiry_option: null,
    });
  }
};

const BookingRequestForm = React.memo(function BookingRequestForm(): JSX.Element {
  const query = useQuery();
  const { 1: navigate } = useLocation();
  const source_booking_request = window.history?.state?.booking_request
    ? JSON.parse(window.history?.state?.booking_request, (key, val) => {
        if (key === 'estimated_time_of_departure' || key === 'estimated_time_of_arrival') {
          return dayjs(val);
        }

        return val;
      })
    : {};
  const load_type = query.get('load_type') || LOAD_TYPE_FCL;
  const initial_inquiry_option_id = query.get('inquiry_option_id');
  const sessionData = useSession();
  const { setVisible, setEmailProps } = useEmailDrawerContext();

  const [createBookingRequest, { data, loading, error }] = useMutation(CREATE_BOOKING_REQUEST);
  const [fetchInquiryOption, { data: inquiryOptionData }] = useLazyQuery(GET_INQUIRY_OPTION);

  const client = useApolloClient();

  const [documentUpdate, setDocumentUpdate] = useState<{
    updating: boolean;
    error?: boolean | string;
  }>({ updating: false, error: false });

  const [updateDocIds, setUpdateDocIds] = useState<Array<string>>([]);

  const [documentsStoreValue, dispatch] = useReducer(documentsStore, {
    uploadingDocuments: [],
    errors: {},
    documents: [],
  });

  const uploadDoc = useCallback(async (doc: UploadDocumentPayload) => {
    dispatch({ type: 'uploading', payload: { doc } });
    const { response, error } = await uploadDocument(doc, process.env.DOCGEN_URL || '');
    if (error) {
      dispatch({ type: 'upload_failure', payload: { doc, error: error.message } });
    } else if (response) {
      const uploaded_document = response?.data?.document;
      dispatch({ type: 'upload_success', payload: { doc: uploaded_document } });
    }
  }, []);

  const deleteDoc = useCallback(
    async (doc: UploadedDocumentType) => {
      dispatch({ type: 'deleting', payload: { doc } });
      const { response, error } = await deleteUploadedDocument(
        doc.id,
        sessionData.id,
        process.env.DOCGEN_URL || ''
      );
      if (error) {
        message.error(error.message);
        dispatch({ type: 'delete_failure', payload: { doc } });
      } else if (response) {
        dispatch({ type: 'remove_document', payload: { doc: doc } });
      }
    },
    [sessionData.id]
  );

  const {
    uploadingDocuments = [],
    errors: uploadingError = {},
    documents = [],
  } = documentsStoreValue;

  const updateDocs = useCallback(
    async (booking_order_id: string, document_ids: Array<string>) => {
      setDocumentUpdate({ updating: true, error: false });
      const { response, error } = await addDocumentParents(
        document_ids,
        [{ parent_type: BOOKING_TYPE_BOOKING_REQUEST, parent_id: booking_order_id }],
        sessionData.id,
        process.env.DOCGEN_URL || ''
      );
      if (error) {
        message.error('Document upload failed');
        setDocumentUpdate({ updating: false, error: !!error || error.message });
      } else if (response) {
        setDocumentUpdate({ updating: false, error: false });
        dispatch({ type: 'reset_state' });
        setUpdateDocIds([]);
      }
    },
    [sessionData.id]
  );
  const [sendNotifications, setSendNotifications] = useState<boolean>(false);
  const [form] = Form.useForm();

  const setInquiryDefaultValues = async (
    form: FormInstance,
    changedValues: BookingRequestFormValueType,
    allValues: BookingRequestFormValueType | CreateBRFormValue
  ) => {
    if (changedValues?.inquiry_option) {
      fetchInquiryOption({ variables: { id: changedValues.inquiry_option.id } });
    }
  };

  useEffect(() => {
    if (initial_inquiry_option_id)
      fetchInquiryOption({ variables: { id: initial_inquiry_option_id } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!inquiryOptionData || !form) return;
    const inquiryOption = inquiryOptionData.get_inquiry_option;
    if (inquiryOption) {
      const valid = checkCustomerValid(inquiryOption.inquiry.customer_company);
      if (!valid) {
        message.error("Inquiry doesn't have a valid customer.");
        return;
      }
      if (inquiryOption.status !== 'won') {
        message.error('Please select a valid quotation');
        return;
      }
      const vendor_address = sessionData.branch_accounts?.find(
        (ba: BranchAccountValue) => ba.id === inquiryOption.inquiry.involved_branch.id
      )?.default_address;
      const otoValues = form.getFieldValue('ocean_transport_order');
      const vendor = {
        party_company: sessionData.company_account.default_company,
        party_address: vendor_address,
      };
      const formData: any = {
        ocean_transport_order: {
          ...otoValues,
          customer: {
            party_company: inquiryOption.inquiry.customer_company,
            party_address:
              inquiryOption.inquiry.customer_address?.entity_type === 'billing'
                ? inquiryOption.inquiry.customer_address
                : null,
          },
          sales_person: inquiryOption.inquiry.sales_person,
          vendor: vendor,
          routing_details: createRoutingValues({
            place_of_carrier_receipt: inquiryOption.place_of_carrier_receipt,
            port_of_loading: inquiryOption.port_of_loading,
            port_of_discharge: inquiryOption.port_of_discharge,
            place_of_carrier_delivery: inquiryOption.place_of_carrier_delivery,
            main_carriage_vessel: inquiryOption.ocean_vessel,
            main_carriage_voyage_number: inquiryOption.voyage_number,
            estimated_time_of_departure: inquiryOption.estimated_time_of_departure,
            estimated_time_of_arrival: inquiryOption.estimated_time_of_arrival,
          }),
        },
        [MISCELLANEOUS_SERVICE_ORDERS]: getServicesPayloadFromInquiry(
          inquiryOption.inquiry,
          vendor
        ),
      };

      const inquiry_option = form.getFieldValue('inquiry_option');
      if (!inquiry_option) formData['inquiry_option'] = inquiryOption;
      if (inquiryOption.carrier) formData['preferred_carriers'] = [inquiryOption.carrier];
      if (inquiryOption.inquiry.incoterms) formData['incoterm'] = inquiryOption.inquiry.incoterms;
      if (inquiryOption.inquiry.cargos)
        formData['cargos'] = (inquiryOption.inquiry.cargos || []).map((cargo: any) => {
          return {
            ..._omit(cargo, ['__typename', 'allocation_pending_quantity', 'id']),
            cargo_properties: _omit(cargo.cargo_properties, '__typename'),
            shipment_packages: (cargo.shipment_packages || []).map((pkg: any) =>
              _omit(pkg, '__typename')
            ),
          };
        });
      const fulfilled_container_map = JSON.parse(inquiryOption.inquiry?.fulfilled_container_map);
      if (inquiryOption.inquiry.container_requests)
        formData['container_requests'] = (inquiryOption.inquiry.container_requests || []).map(
          (cr: any) => {
            return {
              ..._omit(cr, ['__typename', 'id']),
              quantity: cr.quantity - (fulfilled_container_map?.[cr.container_type_code] || 0),
              container_settings: _omit(cr.container_settings, '__typename'),
            };
          }
        );
      form.setFieldsValue(formData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inquiryOptionData, form]);

  useEffect(() => {
    if (!error && data?.create_booking_request) {
      const { id } = data.create_booking_request;
      message.success('Customer Order created !');
      if (updateDocIds && updateDocIds.length > 0)
        updateDocs(data?.create_booking_request?.id, updateDocIds);
      else {
        if (sendNotifications) {
          const bookingRequest = BookingRequest.create(data?.create_booking_request, {
            sessionData,
          });
          const default_company = sessionData.company_account.default_company;
          setEmailProps({
            title: 'Customer Order Acknowledgement',
            action_name: 'booking_request_acknowledgement',
            resource_ids: [id],
            fetchDocumentParents: [
              {
                parent_type: BOOKING_TYPE_BOOKING_REQUEST,
                parent_ids: [id],
              },
            ],
            companies_roles_mapping: default_company
              ? bookingRequest.getAllCompaniesRolesMapping(default_company)
              : [],
            showMarkDown: true,
            showEmail: false,
          });
          setVisible(true);
        }
        navigate(`~/view/booking_request/${id}`, {
          state: {
            booking_request: data.create_booking_request,
          },
        });
      }
    }
  }, [error, data, navigate, updateDocs, updateDocIds, sendNotifications, sessionData, setEmailProps, setVisible]);
  const container_requests_ref = useRef<{ runValidation: () => boolean }>();
  const routing_details_ref = useRef<{ runValidation: () => boolean }>();

  const [pickupRequested, setPickupRequested] = useState<boolean>(false);
  const [bufferRequested, setBufferRequested] = useState(load_type === LOAD_TYPE_LCL);

  useEffect(() => {
    if (!pickupRequested) return;
    const stuffing_location_type = form.getFieldValue('stuffing_location_type');
    const road_transport_orders = form.getFieldValue('road_transport_orders');
    form.setFieldsValue({
      road_transport_orders: getUpdatedRtoLoadType(stuffing_location_type, road_transport_orders),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickupRequested]);

  return (
    <Form
      form={form}
      onFinish={(values: BookingRequestFormValueType) => {
        let foundError = false;
        foundError = Boolean(container_requests_ref?.current?.runValidation());
        if (!foundError) {
          const payload = getBookingRequestPayload(values, pickupRequested, bufferRequested);
          createBookingRequest({ variables: { booking_request: payload } });
          setUpdateDocIds(documents.map((d) => d.id));
        }
      }}
      scrollToFirstError
      name="booking_request"
      layout="vertical"
      style={{
        height: '100%',
      }}
      initialValues={{
        load_type,
        ocean_transport_order: {
          vendor: {
            party_company: sessionData.company_account.default_company,
          },
        },
        [MISCELLANEOUS_SERVICE_ORDERS]: [
          { name: CLEARANCE_SERVICE },
          { name: CARGO_INSURANCE_SERVICE },
          { name: EMPTY_CONTAINER_INSURANCE_SERVICE },
        ],
        cargos: [
          {
            package_type: 'Box',
            weight_unit: WEIGHT_UNIT_KGS,
            volume_unit: VOLUME_UNIT_CBM,
          },
        ],
        road_transport_orders: [
          {
            load_type: load_type === LOAD_TYPE_FCL ? LOAD_TYPE_FCL : RTO_TYPE_LOOSE,
            managed_by: 'forwarder',
          },
        ],
        ...source_booking_request,
      }}
      onValuesChange={(changedValues, allValues) => {
        setSalesPerson(form, changedValues, allValues, sessionData, client);
        setRTOLoadType(form, changedValues, allValues);
        clearStuffingLocationType(form, changedValues, allValues);
        clearInquiryOption(form, changedValues, allValues);
        setInquiryDefaultValues(form, changedValues, allValues);
      }}
    >
      <Layout className="form-layout">
        <AppHelmet>
          <title>New Customer Order</title>
        </AppHelmet>
        <Header style={{ padding: 0, background: 'none', height: 'auto' }}>
          <PageHeader
            style={{ padding: '10px 0px' }}
            onBack={() => {
              window.history.back();
            }}
            title="New Customer Order"
            subTitle={load_type.toUpperCase()}
            extra={[
              <Checkbox
                checked={sendNotifications}
                onChange={(e) => setSendNotifications(e.target.checked)}
                key="send_notifications"
              >
                Send Notifications
              </Checkbox>,
              <Button
                loading={loading}
                disabled={
                  uploadingDocuments.length > 0 || documentUpdate.updating || !!documentUpdate.error
                }
                htmlType="submit"
                type="primary"
                key="save"
              >
                Save
              </Button>,
            ]}
          >
            {(error && errorMessageHandlerGraphQL(error)) ||
              (documentUpdate.error && (
                <span>
                  Something went wrong while uploading the documents.
                  {documentUpdate.error}
                  <Button
                    onClick={() => updateDocs(data?.create_booking_request?.id, updateDocIds)}
                    size="small"
                    type="link"
                  >
                    Retry
                  </Button>
                </span>
              ))}
          </PageHeader>
        </Header>
        <Content>
          <Form.Item noStyle name="load_type">
            <span />
          </Form.Item>
          <Form.Item noStyle name={['ocean_transport_order', 'vendor', 'party_company']}>
            <span />
          </Form.Item>
          <Card title="Basic Details">
            <BookingRequestBasicDetails
              showPreferredCarriers={load_type === LOAD_TYPE_FCL}
              load_type={load_type}
              colSpan={8}
            />
          </Card>
          <Card title="Routing Details">
            <Row gutter={16}>
              <Col span={6}>
                <Form.Item name={'cargo_ready_date'} label="Cargo Ready Date">
                  <DatePicker style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  required
                  name="stuffing_location_type"
                  label="Stuffing Location Type"
                  rules={[{ required: true }]}
                >
                  <Radio.Group options={stuffing_location_types(load_type)} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              name={['ocean_transport_order', 'routing_details']}
              rules={[
                {
                  validator: (rule, value) => {
                    if (!routing_details_ref?.current?.runValidation()) {
                      return Promise.resolve();
                    }
                    return Promise.reject();
                  },
                },
              ]}
              noStyle
            >
              <BookingRoutingDetails ref={routing_details_ref} />
            </Form.Item>
          </Card>
          <Card title="Cargo Details">
            <CargoDetails form={form} load_type={load_type} />
          </Card>
          {load_type === LOAD_TYPE_FCL && (
            <Card title="Container Details">
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.cargos !== currentValues.cargos
                }
              >
                {({ getFieldValue }) => {
                  const cargos = getFieldValue('cargos');
                  const isLiveReefer = cargos ? isLiveReeferCargo(cargos) : false;
                  return (
                    <Form.Item
                      name="container_requests"
                      noStyle
                      rules={[
                        {
                          validator: (rule, value) => {
                            if (!container_requests_ref?.current?.runValidation()) {
                              return Promise.resolve();
                            }
                            return Promise.reject();
                          },
                        },
                      ]}
                    >
                      <ContainerDetails
                        ref={container_requests_ref}
                        required_fields={['container_type_code', 'quantity']}
                        isLiveReefer={isLiveReefer}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Card>
          )}
          <Card title="Other Details">
            <Form.Item name="remarks" label="Remarks">
              <Input.TextArea rows={2} />
            </Form.Item>
            <Row gutter={16}>
              <Col span={12}>
                <div>
                  <UploadDocumentModal onUpload={uploadDoc} sessionData={sessionData} />
                  <DocumentsList
                    uploadingDocuments={uploadingDocuments}
                    uploadedDocuments={documents}
                    uploadingError={uploadingError}
                    onUpload={uploadDoc}
                    onDelete={deleteDoc}
                    sessionData={sessionData}
                  />
                </div>
              </Col>
            </Row>
          </Card>
          <Card
            title={
              <span>
                <Checkbox
                  checked={pickupRequested}
                  onChange={(e) => {
                    setPickupRequested(e.target.checked);
                  }}
                  style={{ marginRight: '5px' }}
                />
                Pickup Service
              </span>
            }
          >
            {pickupRequested && (
              <Form.List name="road_transport_orders">
                {(fields, { add, remove }) => {
                  return (
                    <div>
                      {fields.map((field, index) => {
                        return (
                          <Form.Item
                            key={field.key}
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                              prevValues.container_requests !== currentValues.container_requests ||
                              prevValues.ocean_transport_order?.customer?.party_company?.id !==
                                currentValues.ocean_transport_order?.customer?.party_company?.id ||
                              prevValues.party?.shipper?.party_company?.id !==
                                currentValues.party?.shipper?.party_company?.id ||
                              prevValues.road_transport_orders[field.name] !==
                                currentValues.road_transport_orders[field.name]
                            }
                          >
                            {({ getFieldValue }) => {
                              return (
                                <RoadTransportOrderForm
                                  key={field.key}
                                  fieldName={[field.name]}
                                  shipperCompanyId={getFieldValue([
                                    'party',
                                    'shipper',
                                    'party_company',
                                    'id',
                                  ])}
                                  customerCompanyId={getFieldValue([
                                    'ocean_transport_order',
                                    'customer',
                                    'party_company',
                                    'id',
                                  ])}
                                />
                              );
                            }}
                          </Form.Item>
                        );
                      })}
                    </div>
                  );
                }}
              </Form.List>
            )}
          </Card>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.road_transport_orders !== currentValues.road_transport_orders ||
              prevValues.stuffing_service_order !== currentValues.stuffing_service_order ||
              prevValues.stuffing_location_type !== currentValues.stuffing_location_type
            }
          >
            {({ getFieldValue }) => {
              const road_transport_orders = getFieldValue('road_transport_orders');
              const stuffing_location_type = getFieldValue('stuffing_location_type');
              const not_factory_stuffing =
                stuffing_location_type !== STUFFING_LOCATION_TYPE_FACTORY;
              const lcl_rto_present = isLclRTOPresent(road_transport_orders);
              let show_stuffing_service =
                not_factory_stuffing || lcl_rto_present || bufferRequested;
              if (!not_factory_stuffing) show_stuffing_service = false;
              if (bufferRequested !== show_stuffing_service)
                setBufferRequested(show_stuffing_service);
              return (
                <Card
                  title={
                    <span>
                      <Checkbox
                        checked={show_stuffing_service}
                        disabled={true}
                        onChange={(e) => {
                          setBufferRequested(e.target.checked);
                        }}
                        style={{ marginRight: '5px' }}
                      />
                      {!lcl_rto_present ? 'Buffer' : 'Stuffing & Buffer'} Service
                    </span>
                  }
                >
                  {show_stuffing_service && (
                    <StuffingServiceForm
                      load_type={load_type}
                      field_name={['stuffing_buffer_service_order']}
                      stuffing_location_type={stuffing_location_type}
                    />
                  )}
                </Card>
              );
            }}
          </Form.Item>
          <Card title={<span>Other Services</span>}>
            <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
              <Form.List name="miscellaneous_service_orders">
                {(fields) => {
                  return fields.map((field, index) => {
                    const serviceName = form.getFieldValue([
                      'miscellaneous_service_orders',
                      field.name,
                      'name',
                    ]);
                    return (
                      <Card
                        key={index}
                        title={
                          <Form.Item
                            name={[field.name, 'enabled']}
                            valuePropName="checked"
                            style={{ marginBottom: 0 }}
                          >
                            <Checkbox>{_startCase(serviceName)}</Checkbox>
                          </Form.Item>
                        }
                        style={{ minWidth: '400px' }}
                      >
                        <Form.Item
                          noStyle
                          dependencies={[['miscellaneous_service_orders', field.name, 'enabled']]}
                        >
                          {({ getFieldValue }) => {
                            const enabled = getFieldValue([
                              'miscellaneous_service_orders',
                              field.name,
                              'enabled',
                            ]);
                            if (!enabled) return <span />;
                            return (
                              <MiscellaneousServiceForm
                                name={serviceName}
                                field_name={[field.name]}
                              />
                            );
                          }}
                        </Form.Item>
                      </Card>
                    );
                  });
                }}
              </Form.List>
            </div>
          </Card>
        </Content>
      </Layout>
    </Form>
  );
});

export default BookingRequestForm;
