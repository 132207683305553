import React, { forwardRef, ForwardedRef } from 'react';

import { Form, Row, Col, SelectProps, GlobalSearch } from '@shipmnts/pixel-hub';
import { BaseSelectRef } from 'rc-select/es/BaseSelect';
import { CompanyValue, CompanyStatus } from 'common/models/Company';
import { CustomerCreditStatusTag } from '@shipmnts/pixel-hub';

export interface CompanySearchProps {
  value?: CompanyValue | undefined | Array<CompanyValue>;
  selectMode?: 'multiple';
  showAddCompanyAction?: boolean;
  disabled?: boolean;
  searchProps?: {
    is_customer?: boolean;
    is_vendor?: boolean;
    is_lead?: boolean;
    business_type?: Array<string>;
    status?: Array<CompanyStatus>;
    is_disabled?: Array<boolean>;
  };
  onChange?: (prop?: CompanyValue | CompanyValue[]) => void;
  errorMessage?: string;
  open?: boolean;
  showCreditStatusTag?: boolean; // To handle visibility of credit status tag and modal
  hideCompanies?: Array<string>;
  selectProps?: SelectProps;
  renderViewType?: 'Drawer' | 'NewTab';
}

const CompanySearch = React.memo<CompanySearchProps>(
  forwardRef(function CompanySearch(
    props: CompanySearchProps,
    ref: ForwardedRef<BaseSelectRef>
  ): JSX.Element {
    const {
      disabled,
      selectMode,
      searchProps = {},
      showAddCompanyAction = true,
      value,
      onChange,
      errorMessage,
      showCreditStatusTag,
      hideCompanies = [],
      selectProps,
      renderViewType,
    } = props;

    return (
      <>
        <Form.Item
          validateStatus={errorMessage ? 'error' : undefined}
          help={errorMessage}
          style={{ marginBottom: '0px', width: '100%' }}
        >
          <Row gutter={8}>
            <Col span={showCreditStatusTag ? 16 : 24}>
              <GlobalSearch
                doc_type="Network::Company"
                disabled={disabled}
                selectMode={selectMode}
                searchProps={{ ...searchProps, hideCompanies }}
                showCreation={showAddCompanyAction}
                onChange={onChange}
                selectProps={selectProps}
                value={value}
                extraProps={{
                  renderViewType: renderViewType,
                }}
              />
            </Col>
            {showCreditStatusTag && value && !Array.isArray(value) && (
              <Col span={8}>
                {
                  // FIXME

                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  <CustomerCreditStatusTag companyInternal={value} />
                }
              </Col>
            )}
          </Row>
        </Form.Item>
      </>
    );
  })
);

CompanySearch.displayName = 'CompanySearch';

export default CompanySearch;
