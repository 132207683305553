import {
  get as _get,
  find as _find,
  sortBy as _sortBy,
  filter as _filter,
  map as _map,
  keyBy as _keyBy,
} from 'lodash';
import { dayjs } from '@shipmnts/pixel-hub';
import { ResolveFieldProps } from './resolveDocumentField';
import { DOCUMENT_TYPE_HOUSE } from 'operations/modules/shipment/constants';
import { ShipmentValue } from 'operations/models/Shipment';
import { ContainerSettingValue } from 'operations/models/ContainerRequest';
import { AIRFLOW_UNITS } from 'operations/baseConstants';
import { safelyResolveDocumentField } from './resolveDocumentField';
import { getDataForRuleEngine, getAutoApplyClause } from './ruleEngineHelper';
import { FIELD_TYPE_STATIC, FIELD_TYPE_VARIABLE } from 'operations/modules/shipment/constants';

const getDimensions = (props: ResolveFieldProps) => {
  const { data } = props;
  const shipment_packages = _get(data, 'shipment.shipment_packages', []);
  if (shipment_packages.length === 0) return '';
  const sortedPackages = _sortBy(shipment_packages, [
    (p) => p.total_number_of_packages && parseFloat(`${p.total_number_of_packages}`),
  ]);
  const dimension = _get(sortedPackages[0], 'dimension_unit', '');
  if (dimension) {
    const headerText = `DIMS IN ${dimension.toUpperCase()}`;
    const packagesText = sortedPackages
      .map((pack) => {
        if (pack && pack.width && pack.height && pack.length)
          return `${pack.total_number_of_packages} = ${Math.round(pack.length)} X ${Math.round(
            pack.width
          )} X ${Math.round(pack.height)}`;
        return '';
      })
      .join(', ');
    return `${headerText}: ${packagesText}`;
  }
  return;
};

const getHouseShipmentNumbers = (props: ResolveFieldProps) => {
  const { data } = props;
  const house_shipments = _get(data, 'house_shipments', []);
  return house_shipments
    .map((shipment: ShipmentValue) => {
      const shipment_document = _find(_get(shipment, 'shipment_documents', []), {
        document_type: DOCUMENT_TYPE_HOUSE,
      });
      return _get(shipment_document, 'shipment_number', '');
    })
    .join(', ');
};

export const getInvoiceDetails = (props: ResolveFieldProps) => {
  const { data, onNewLine } = props;
  const shipment_invoices = _get(data, 'shipment.shipment_invoices', []);
  const joinChar = onNewLine ? '\n' : ', ';
  return shipment_invoices
    .map((invoice) => {
      const invoice_date = invoice.invoice_date
        ? dayjs.unix(invoice.invoice_date).format('DD/MM/YYYY')
        : '';
      return `${_get(invoice, 'invoice_number', '')} ${invoice_date}`;
    })
    .join(joinChar);
};

// Todo: rename this or add country "IN"
export const getShippingBillDetails = (props: ResolveFieldProps) => {
  const { data } = props;
  const shipment_custom_details = _get(data, 'shipment.shipment_custom_details', []);
  const shippingBills = _filter(shipment_custom_details, { trade_type: 'export' });

  return shippingBills
    .map((shipping_bill) => {
      const shipping_bill_date = shipping_bill.custom_document_date
        ? dayjs.unix(shipping_bill.custom_document_date).format('DD/MM/YYYY')
        : '';
      return `${_get(shipping_bill, 'custom_document_number', '')} ${shipping_bill_date}`;
    })
    .join(', ');
};

const getMovementType = (props: ResolveFieldProps) => {
  const { data } = props;
  const { shipment, master_shipment } = data;
  const movementType = `${_get(shipment, 'load_type', '')}/${_get(
    master_shipment,
    'load_type',
    ''
  )}`;
  return movementType;
};

const getTemperatureSettings = (props: ResolveFieldProps) => {
  const { data } = props;
  const { shipment_containers } = data;
  const final_settings: { [key: number]: ContainerSettingValue } = {};
  let settings_there = false;
  shipment_containers?.forEach((container) => {
    if (container.container_settings?.temperature) {
      final_settings[container.container_settings.temperature] = container.container_settings;
      settings_there = true;
    }
  });

  if (!settings_there) return '';
  let printed_settings = 'CARRIERS RESPONSIBILITY IS LIMITED ONLY TO MAINTAIN THE SETTING';
  Object.values(final_settings).forEach((setting) => {
    if (setting.temperature !== undefined && setting.temperature !== null)
      printed_settings += ` AIR TEMPERATURE OF ${setting.temperature} ${setting.temperature_unit}`;
    if (
      setting.ventilation_requested &&
      setting.air_flow !== undefined &&
      setting.air_flow !== null &&
      setting.air_flow_unit
    ) {
      const air_flow_unit = AIRFLOW_UNITS.find((au: any) => au.key === setting.air_flow_unit);
      printed_settings += ` VENT ${setting.air_flow} ${air_flow_unit?.name}`;
    }
    if (
      setting.humidity_control_requested &&
      setting.relative_humidity_percent !== undefined &&
      setting.relative_humidity_percent !== null
    )
      printed_settings += ` HUMD. : ${setting.relative_humidity_percent} %`;
    if (setting.controlled_atmosphere_requested)
      printed_settings += ` CONTROLLED ATMOSPHERE OF O2: ${
        setting.oxygen_level_percent !== undefined && setting.oxygen_level_percent !== null
          ? setting.oxygen_level_percent
          : ''
      } %, N: ${
        setting.nitrogen_level_percent !== undefined && setting.nitrogen_level_percent !== null
          ? setting.nitrogen_level_percent
          : ''
      } %, CO2: ${
        setting.carbon_dioxide_level_percent !== undefined &&
        setting.carbon_dioxide_level_percent !== null
          ? setting.carbon_dioxide_level_percent
          : ''
      } %`;
    if (setting.genset_requested) printed_settings += ' GENSET REQUIRED';
    printed_settings += '\n';
  });
  printed_settings += 'AS PER SHIPPERS/RECEIVERS WRITTEN INSTRUCTION';
  return printed_settings;
};

export const getContainerDetails = (props: ResolveFieldProps) => {
  const { data } = props;
  const { shipment_containers, shipment } = data;
  const loadType = shipment?.load_type?.toUpperCase();
  const containers = (shipment_containers || []).reduce(
    (obj: { [key: string]: { count: number; text: string } }, container) => {
      if (container.container_type_code && container.container_type) {
        if (!obj[container.container_type_code])
          obj[container.container_type_code] = { count: 1, text: container.container_type };
        else
          obj[container.container_type_code] = {
            count: obj[container.container_type_code].count + 1,
            text: container.container_type,
          };
      }
      return obj;
    },
    {}
  );
  return Object.values(containers)
    .map((container) => {
      return `\n${container.count} X ${container.text} ${loadType} CONTAINER SAID TO CONTAIN`;
    })
    .join(', ');
};

const getTotalNumberOfPackages = (props: ResolveFieldProps) => {
  const { data } = props;
  let total_number_of_packages = 0;
  const { shipment_containers, shipment } = data;
  const shipmentId = _get(shipment, 'id');
  _map(shipment_containers, (container) => {
    _map(container.container_cargo_details, (cargo) => {
      if (_get(cargo, 'shipment_id') === shipmentId) {
        total_number_of_packages += _get(cargo, 'total_number_of_packages', 0) || 0;
      }
    });
  });
  return total_number_of_packages;
};

const getCargoDescriptionClauseText = (props: ResolveFieldProps) => {
  const { data, document_key } = props;
  const auto_apply_clause_group = safelyResolveDocumentField({
    field_name: 'auto_apply_clause_group',
    data: data,
    document_key,
  });
  let cargo_description = '';
  if (auto_apply_clause_group && data?.document?.rules && data?.document?.rules?.length > 0) {
    const dataForRuleEngine = getDataForRuleEngine(data);

    const clause_id = getAutoApplyClause(dataForRuleEngine, data.document.rules);
    if (clause_id) {
      const document_clauses = _keyBy(data?.document?.document_clauses, 'id');
      const clause_template = document_clauses[clause_id];

      (clause_template?.document_clause_ids || []).forEach((document_clause_id) => {
        const clause = document_clauses[document_clause_id];
        if (clause.field_type === FIELD_TYPE_STATIC) cargo_description += clause.value + '\n';
        if (clause.field_type === FIELD_TYPE_VARIABLE) {
          const resolvedValue = safelyResolveDocumentField({
            field_name: clause.field_name,
            data: data,
            document_key,
          });
          cargo_description += _get(clause, 'prefix', '') + resolvedValue + '\n';
        }
      });
    }
  }
  return cargo_description;
};

export const transformFunctions: { [key: string]: (props: ResolveFieldProps) => any } = {
  getDimensions,
  getHouseShipmentNumbers,
  getInvoiceDetails,
  getShippingBillDetails,
  getMovementType,
  getTemperatureSettings,
  getContainerDetails,
  getTotalNumberOfPackages,
  getCargoDescriptionClauseText,
};
