import { Dayjs } from '@shipmnts/pixel-hub';

export const disableAfterEtd = (current: Dayjs, routing_leg: { [key: string]: any }) => {
  return routing_leg?.estimated_time_of_departure
    ? current.isAfter(routing_leg?.estimated_time_of_departure)
    : false;
};

export const reeferGateOpenDisableDate = (
  current: Dayjs,
  routing_leg: { [key: string]: any },
  node: { [key: string]: any }
) => {
  return (
    disableAfterEtd(current, routing_leg) ||
    (node?.reefer_gate_close_date && current.isAfter(node?.reefer_gate_close_date))
  );
};
export const reeferGateCloseDisableDate = (
  current: Dayjs,
  routing_leg: { [key: string]: any },
  node: { [key: string]: any }
) => {
  return (
    disableAfterEtd(current, routing_leg) ||
    (node?.reefer_gate_open_date && current.isBefore(node?.reefer_gate_open_date))
  );
};

export const generalGateCloseDisableDate = (
  current: Dayjs,
  routing_leg: { [key: string]: any },
  node: { [key: string]: any }
) => {
  return (
    disableAfterEtd(current, routing_leg) ||
    (node?.gate_open_date && current.isBefore(node?.gate_open_date))
  );
};

export const generalGateOpenDisableDate = (
  current: Dayjs,
  routing_leg: { [key: string]: any },
  node: { [key: string]: any }
) => {
  return (
    disableAfterEtd(current, routing_leg) ||
    (node?.gate_close_date && current.isAfter(node?.gate_close_date))
  );
};

export const getCutoffDateDisableFunction = () => {
  return {
    gate_open_date: generalGateOpenDisableDate,
    gate_close_date: generalGateCloseDisableDate,
    reefer_gate_open_date: reeferGateOpenDisableDate,
    reefer_gate_close_date: reeferGateCloseDisableDate,
    load_list_cutoff_date: disableAfterEtd,
    form13_cutoff_date: disableAfterEtd,
    doc_cutoff_date: disableAfterEtd,
    si_cutoff_date: disableAfterEtd,
    valid_till_date: disableAfterEtd,
  };
};

export const getDisbaledCutoffDateMapping = (
  hasVoyageScheduleId: boolean,
  hasPrecarriage: boolean
) => {
  return {
    gate_open_date: hasVoyageScheduleId,
    gate_close_date: hasVoyageScheduleId,
    si_cutoff_date: hasVoyageScheduleId && !hasPrecarriage,
    doc_cutoff_date: hasVoyageScheduleId && !hasPrecarriage,
  };
};
