import { SessionDataValue } from 'common/models/SessionData';
import React, { useContext } from 'react';

export const SessionContext = React.createContext<SessionDataValue | null>(null);

export const SessionContextProvider = ({
  session,
  children,
}: {
  session: SessionDataValue;
  children: React.ReactNode;
}) => {
  return <SessionContext.Provider value={session}>{children}</SessionContext.Provider>;
};

export const useSession = () => {
  const context = useContext(SessionContext);

  if (!context) throw new Error('SessionContext must be called from within the SessionProvider');

  return context;
};
