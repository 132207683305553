import React, { useEffect, useCallback, useState, useMemo } from 'react';
import {
  Table,
  Tag,
  Skeleton,
  Typography,
  Button,
  LinkedParentType,
  UploadedDocumentType,
  UploadParentType,
  fetchUploadedDocuments,
} from '@shipmnts/pixel-hub';
import { sortBy as _sortBy } from 'lodash';
import { ShipmentValue } from 'operations/models/Shipment';
import { SessionDataValue } from 'operations/models/SessionData';
import { SHIPMENT_TYPE_CONSOL } from '../../constants';

const { Link } = Typography;

interface UploadedDocumentsTableProps {
  fetchingUploadedDocuments: boolean;
  setFetchingUploadedDocuments: (value: boolean) => void;
  documents?: UploadedDocumentType[];
  shipment: ShipmentValue;
  selectedUploadedDocuments: React.Key[];
  setSelectedUploadedDocuments: (value: React.Key[]) => void;
  setUploadedDocuments: (value: UploadedDocumentType[]) => void;
  docgen_url: string;
  sessionData: SessionDataValue;
}

export const sortUploadedDocuments = (documents: UploadedDocumentType[]) => {
  const new_docs = (documents || []).map((doc) => ({
    ...doc,
    parents: _sortBy(doc.parents, 'parent_id'),
  }));

  const sorted_docs = new_docs.sort((doc1, doc2) => {
    if (doc1.parents?.[0].parent_id < doc2.parents?.[0].parent_id) {
      return -1;
    } else if (doc2.parents?.[0].parent_id < doc1.parents?.[0].parent_id) {
      return 1;
    }
    return 0;
  });

  return sorted_docs;
};

const UploadedDocumentsTable = React.memo(function UploadedDocumentsTable(
  props: UploadedDocumentsTableProps
): JSX.Element {
  const {
    fetchingUploadedDocuments,
    setFetchingUploadedDocuments,
    documents,
    shipment,
    selectedUploadedDocuments,
    setSelectedUploadedDocuments,
    setUploadedDocuments,
    docgen_url,
    sessionData,
  } = props;
  const [fetchUploadDocumentsError, setFetchUploadDocumentsError] = useState<boolean | string>(
    false
  );

  const getUploadedDocuments = useCallback(async () => {
    if (shipment && sessionData?.company_account?.id) {
      if (!fetchingUploadedDocuments) setFetchingUploadedDocuments(true);
      const shipment_ids = [shipment, ...(shipment?.house_shipments || [])].map((sh) => sh.id);
      const parents: LinkedParentType[] = [{ parent_ids: shipment_ids, parent_type: 'shipment' }];
      const { response: uploaded_documents, error: uploaded_documents_error } =
        await fetchUploadedDocuments(
          docgen_url,
          sessionData.id,
          sessionData?.company_account?.id,
          parents
        );

      if (uploaded_documents_error)
        setFetchUploadDocumentsError(
          uploaded_documents_error?.message || !!uploaded_documents_error
        );
      else if (uploaded_documents?.data?.documents) {
        const documents =
          shipment.shipment_type === SHIPMENT_TYPE_CONSOL
            ? sortUploadedDocuments(uploaded_documents?.data?.documents)
            : uploaded_documents?.data?.documents;
        setUploadedDocuments(documents);
      }
      setFetchingUploadedDocuments(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipment, fetchingUploadedDocuments]);

  useEffect(() => {
    const fetchDocuments = async () => await getUploadedDocuments();
    if (shipment) fetchDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipment]);

  const allShipments = [shipment, ...(shipment?.house_shipments || [])];
  const columns = useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'file_name',
        render: (file_name: string, record: UploadedDocumentType) => (
          <Link href={record?.uploaded_document} target="_blank" style={{ color: '#3b72d5' }}>
            {file_name}
          </Link>
        ),
      },
      {
        title: 'Doc Tags',
        dataIndex: 'tags',
        render: (tags?: string[]) => {
          if (!tags) return '';
          return tags.map((doc_tag: string, index: number) => (
            <Tag key={index} color="purple">
              {doc_tag}
            </Tag>
          ));
        },
      },
      ...(shipment?.shipment_type === 'consol'
        ? [
            {
              title: 'Job #',
              dataIndex: 'job_number',
              render: (ttaxt: string, record: UploadedDocumentType, index: number) => {
                const parentType = 'shipment' as UploadParentType;
                const parent_ids = record.parents
                  ?.filter((p) => p.parent_type === parentType)
                  .map((p) => p.parent_id);
                const job_numbers = allShipments
                  .filter((sh) => parent_ids?.includes(sh.id))
                  .map((sh) => sh.job_number);
                return <span key={`job_number_${index}`}>{job_numbers.join(', ')}</span>;
              },
            },
          ]
        : []),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (fetchingUploadedDocuments) return <Skeleton active />;
  return (
    <>
      {fetchUploadDocumentsError ? (
        <div>
          Something went wrong. {fetchUploadDocumentsError}
          <span style={{ marginLeft: '5px' }}>
            <Button onClick={getUploadedDocuments}>Retry</Button>
          </span>
        </div>
      ) : (
        <Table
          rowSelection={{
            selectedRowKeys: selectedUploadedDocuments,
            onChange: (selectedRowKeys: React.Key[]) =>
              setSelectedUploadedDocuments(selectedRowKeys),
          }}
          columns={columns}
          dataSource={documents}
          size="small"
          bordered
          title={() => 'Select Uploaded Documents'}
          rowKey={(record) => record.id || ''}
          pagination={false}
        />
      )}
    </>
  );
});

export default UploadedDocumentsTable;
