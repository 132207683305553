import { CompleteTaskAction, TaskForm, DeleteTask } from '@shipmnts/pixel-hub';
import { PerformAction } from '../models';

export const performEditTaskAction = (payload: any): PerformAction => {
  const { task } = payload;

  return {
    actionParams: {
      task,
      visible: true,
    },
    component: TaskForm,
  };
};

export const performDuplicateTaskAction = (payload: any): PerformAction => {
  const { task } = payload;

  // as we want to create a new task, we must remove few feilds from this one
  const { id, due_date, title, description, ...rest } = task;
  return {
    actionParams: {
      task: rest,
      visible: true,
    },
    component: TaskForm,
  };
};

export const performFollowUpTaskAction = (payload: any): PerformAction => {
  const { task } = payload;

  // as we want to create a new task, we must remove id & due_date from this one
  const { id, due_date, ...rest } = task;

  return {
    actionParams: {
      task: { parent_task_id: id, ...rest, due_date: null, reminder: null },
      visible: true,
    },
    component: TaskForm,
  };
};

export const performDeleteTaskAction = (payload: any): PerformAction => {
  const { task } = payload;

  return {
    actionParams: {
      id: task?.id,
      visible: true,
    },
    component: DeleteTask,
  };
};

export const performCompleteAction = (payload: any): PerformAction => {
  const { task } = payload;

  return {
    actionParams: { task, renderAsAction: true },
    component: CompleteTaskAction,
  };
};
