import React from 'react';

import { editTicketAction, shareTicketAction } from './ticketActionRenderer';

import { ShareAltOutlined } from '@ant-design/icons';
import { CustomIcon } from '@shipmnts/pixel-hub';
import { Action } from '../models';

export const getTicketActions = (payload: any) => {
  const actions: Action[] = [];
  actions.push({
    key: 'edit_ticket',
    type: 'primary',
    displayComponent: 'Edit Ticket',
    icon: <CustomIcon height="15" icon="Edit" width="15" />,
    performAction: editTicketAction,
    description: 'edit_ticket',
    isEnable: payload?.ticket_status === 'Open',
  });
  actions.push({
    key: 'add_collaborators',
    type: 'primary',
    displayComponent: 'Share Ticket',
    icon: <ShareAltOutlined />,
    performAction: shareTicketAction,
    isEnable: true,
  });

  return actions;
};
