/* eslint-disable no-lone-blocks */
import React from 'react';
import {
  Button,
  InputNumber,
  Dropdown,
  Table,
  Tooltip,
  Form,
  FormListFieldData,
  FormInstance,
  Modal,
} from '@shipmnts/pixel-hub';
import { LinkOutlined, CustomIcon } from '@shipmnts/pixel-hub';
import type { MenuProps } from '@shipmnts/pixel-hub';
import { useQuery } from '@apollo/client';
import { FETCH_PRODUCT_ORDER_ITEMS_FROM_SHIPMENT } from 'operations/modules/orderManagement/graphql/productOrder';
type MenuItem = Required<MenuProps>['items'][number];

interface LinkedShipmentInvoiceProps {
  form: FormInstance;
  fields: FormListFieldData[];
  removeRow: (index: number | number[]) => void;
  addRow: (defaultValue?: any, insertIndex?: number | undefined) => void;
  shipmentId: string;
}

export default function LinkedShipmentInvoice(props: LinkedShipmentInvoiceProps): JSX.Element {
  const { form, fields, removeRow, addRow, shipmentId } = props;

  const { data } = useQuery(FETCH_PRODUCT_ORDER_ITEMS_FROM_SHIPMENT, {
    variables: { id: shipmentId },
  });

  const added_invoice = form
    .getFieldValue('containers_commercial_invoices')
    ?.map((ccd: any) => ccd.shipment_invoice.id);

  const status = ['cancelled'];
  const filterInvoiceByStatus = data?.shipment?.commercial_invoices?.filter(
    (si: any) => !status.includes(si.status)
  );

  const pending_shipments: any = filterInvoiceByStatus?.filter(
    (shipment_invoice: any) => !(added_invoice || []).includes(shipment_invoice.id)
  );
  const getItems = () => {
    const items: MenuItem[] = [];
    {
      // eslint-disable-next-line array-callback-return
      (pending_shipments || []).map((shipment_invoice: any, index: any) => {
        items.push({
          key: index,
          onClick: () => {
            let grossWeight = 0,
              netWeight = 0;
            shipment_invoice.product_order_items.forEach((ci_poi: any) => {
              grossWeight += ci_poi.gross_weight;
              netWeight += ci_poi.net_weight;
            });
            addRow({
              shipment_invoice: {
                id: shipment_invoice.id,
                invoice_number: shipment_invoice.invoice_number,
                gross_weight: grossWeight,
                net_weight: netWeight,
              },
              is_disabled: false,
            });
          },
          label: (
            <div>
              <div>
                <div style={{ fontWeight: 'bold' }}>{shipment_invoice?.invoice_number}</div>
              </div>
            </div>
          ),
        });
      });
    }
    return items;
  };

  const columns = [
    {
      title: 'Invoice #',
      dataIndex: 'name',
      key: 'invoice_number',
      render: (text: any, record: FormListFieldData, index: string | number) => {
        return (
          <Form.Item
            noStyle
            dependencies={[
              ['containers_commercial_invoices', record.name, 'shipment_invoice', 'id'],
            ]}
          >
            {({ getFieldValue }) => {
              const shipment_invoice_id = getFieldValue([
                'containers_commercial_invoices',
                record.name,
                'shipment_invoice',
                'id',
              ]);
              const shipmentInvoice = data?.shipment?.commercial_invoices.find(
                (s: any) => s.id === shipment_invoice_id
              );
              return (
                <>
                  <Form.Item name={[record.name, 'shipment_invoice_id']} noStyle>
                    <span />
                  </Form.Item>
                  <Form.Item name={[record.name, 'id']} noStyle>
                    <span />
                  </Form.Item>
                  <div>
                    <div style={{ fontWeight: 'bold' }}>{shipmentInvoice?.invoice_number}</div>
                    <div style={{ color: '#586988' }}></div>
                  </div>
                </>
              );
            }}
          </Form.Item>
        );
      },
    },
    {
      title: 'Gross Wt (kgs)',
      dataIndex: 'gross_weight',
      key: 'gross_weight',
      width: 130,
      render: (text: any, record: FormListFieldData, index: string | number) => {
        const is_disabled = true;
        return (
          <Form.Item
            noStyle
            dependencies={[
              ['containers_commercial_invoices', record.name, 'shipment_invoice', 'id'],
            ]}
          >
            {({ getFieldValue }) => {
              const grossWeight = getFieldValue([
                'containers_commercial_invoices',
                record.name,
                'shipment_invoice',
                'gross_weight',
              ]);
              return (
                <Form.Item
                  name={[record.name, `gross_weight`]}
                  style={{ marginBottom: '0px' }}
                  initialValue={grossWeight}
                >
                  <InputNumber
                    disabled={is_disabled}
                    placeholder="Gross Weight"
                    min={0.00001}
                    step={0.00001}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        );
      },
    },
    {
      title: 'Net Wt (kgs)',
      dataIndex: 'net_weight',
      key: 'net_weight',
      width: 130,
      render: (text: any, record: FormListFieldData, index: string | number) => {
        const is_disabled = true;
        return (
          <Form.Item
            noStyle
            dependencies={[
              ['containers_commercial_invoices', record.name, 'shipment_invoice', 'id'],
            ]}
          >
            {({ getFieldValue }) => {
              const netWeight = getFieldValue([
                'containers_commercial_invoices',
                record.name,
                'shipment_invoice',
                'net_weight',
              ]);

              return (
                <Form.Item
                  name={[record.name, `net_weight`]}
                  style={{ marginBottom: '0px' }}
                  initialValue={netWeight}
                >
                  <InputNumber
                    disabled={is_disabled}
                    placeholder="Net Weight"
                    min={0.00001}
                    step={0.00001}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record: FormListFieldData, index: any) => {
        if (fields.length > 0)
          return (
            <Tooltip title="Delink">
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  Modal.confirm({
                    title: 'Confirm',
                    content: 'Are you sure you want to delink this invoice ?',
                    okText: 'Ok',
                    okType: 'danger',
                    onOk: () => {
                      removeRow(record.name);
                    },
                    cancelText: 'Cancel',
                  });
                }}
              >
                <CustomIcon icon="Delink" />
              </div>
            </Tooltip>
          );
        return <></>;
      },
    },
  ];

  return (
    <>
      <Table columns={columns} dataSource={fields} pagination={false} />

      <div style={{ paddingBottom: '30px' }}>
        <Dropdown
          disabled={pending_shipments?.length === 0}
          menu={{ items: getItems() }}
          placement="bottomLeft"
          arrow
        >
          <Button icon={<LinkOutlined />} size="small">
            Link Commercial Invoices
          </Button>
        </Dropdown>
      </div>
    </>
  );
}
