import React, { useState, useEffect } from 'react';
import {
  Input,
  message,
  Drawer,
  Row,
  Col,
  Select,
  Alert,
  Radio,
  Table,
  Form,
} from '@shipmnts/pixel-hub';
import { useMutation } from '@apollo/client';
import { startCase as _startCase } from 'lodash';
import { DrawerFooter } from '@shipmnts/pixel-hub';
import { OceanTransportOrderValue } from 'operations/models/OceanTransportOrder';
import {
  BOOKING_CANCELLED_BY,
  BOOKING_CANCELLATION_REASONS,
} from 'operations/modules/reports/constants';
import { showContainerAllocation } from 'operations/models/ShipmentContainer';
import { CANCEL_OCEAN_TRANSPORT_ORDER } from 'operations/modules/booking/graphql/oceanTransportOrder';
import { errorMessageHandlerGraphQL } from 'common';

import { LOAD_TYPE_FCL, LOAD_TYPE_LCL } from 'operations/baseConstants';
import { BookingRequestValue } from 'operations/models/BookingRequest';
const { TextArea } = Input;

type CancelBookingFormProps = {
  onClose: () => void;
  booking: OceanTransportOrderValue;
  onSuccess?: (booking: OceanTransportOrderValue, sendEmail?: boolean) => void;
};

const CancelBookingForm = React.memo(function CancelBookingForm(
  props: CancelBookingFormProps
): JSX.Element {
  const { onClose, booking, onSuccess } = props;
  const [cancelAllocatedResources, setCancelAllocatedResources] = useState<boolean>(
    booking.isFullyUnallocated || booking.load_type === LOAD_TYPE_LCL
  );
  const [cancelBooking, { data, loading, error }] = useMutation(CANCEL_OCEAN_TRANSPORT_ORDER);

  useEffect(() => {
    if (!error && data?.cancel_ocean_transport_order) {
      message.success('Booking Cancelled Successfully!');
      onClose();
      if (onSuccess) onSuccess(data?.cancel_ocean_transport_order);
    }
  }, [error, data, onClose, onSuccess]);

  const [form] = Form.useForm();

  return (
    <Drawer
      title={`Cancel Ocean Booking`}
      width={'60%'}
      open={true}
      onClose={onClose}
      footer={
        <DrawerFooter
          saveText="Cancel Booking"
          loading={loading}
          onClose={onClose}
          onSave={form.submit}
        />
      }
    >
      <Form
        name="booking_cancellation"
        form={form}
        layout="vertical"
        onFinish={(values) => {
          const variables = {
            ocean_transport_order_id: booking?.id,
            cancelled_by: values.cancellation_reason.split('----')[0],
            cancellation_reason: values.cancellation_reason.split('----')[1],
            remarks: values.remarks,
            cancel_allocated_resources: cancelAllocatedResources,
          };
          cancelBooking({ variables });
        }}
        initialValues={{
          remarks: booking.remarks,
        }}
      >
        {error && errorMessageHandlerGraphQL(error)}
        <Col span={12}>
          <Form.Item
            name="cancellation_reason"
            label="Select Reason For Cancellation"
            required
            rules={[{ required: true }]}
          >
            <Select showSearch allowClear={false} placeholder="Select Cancellation Reason">
              {BOOKING_CANCELLED_BY.map((cancelled_by: string) => (
                <Select.OptGroup key={cancelled_by} label={_startCase(cancelled_by)}>
                  {BOOKING_CANCELLATION_REASONS[cancelled_by].map((option, index) => (
                    <Select.Option
                      key={`${cancelled_by}_${index}`}
                      value={`${cancelled_by}----${option}`}
                    >
                      {_startCase(option)}
                    </Select.Option>
                  ))}
                </Select.OptGroup>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {booking.load_type === LOAD_TYPE_FCL && booking.isOneOnOneAllocated && (
          <div>
            <Row>
              <div className="ant-descriptions-title">
                Select Ocean Bookings to Delink or Cancel
              </div>
            </Row>
            <BookingOrderTable
              ocean_transport_order={booking}
              cancelAllocatedResources={cancelAllocatedResources}
              setCancelAllocatedResources={setCancelAllocatedResources}
            />
          </div>
        )}
        {booking.load_type === LOAD_TYPE_FCL &&
          !booking.isFullyUnallocated &&
          !booking.isOneOnOneAllocated && (
            <Alert
              message="This booking will be delinked from allocated customer orders"
              description="There are multiple associated customer orders, cancelling this booking will delink the orders and the quantities in orders will become unallocated."
              type="warning"
              showIcon
              style={{ marginBottom: '10px' }}
            />
          )}
        <Form.Item name="remarks" label="Remarks For Cancellation">
          <TextArea rows={3} />
        </Form.Item>
      </Form>
    </Drawer>
  );
});

const BookingOrderTable = function BookingOrderTable(props: {
  ocean_transport_order: OceanTransportOrderValue;
  cancelAllocatedResources: boolean;
  setCancelAllocatedResources: (arg0: boolean) => void;
}): JSX.Element {
  const { ocean_transport_order, cancelAllocatedResources, setCancelAllocatedResources } = props;

  const getColumns = () => {
    let columns: Array<{
      title: string;
      dataIndex: string;
      render?: (text: string, record: BookingRequestValue, index: number) => JSX.Element | null;
    }> = [
      {
        title: 'Customer Order Number',
        dataIndex: 'shipment_booking_number',
      },
      {
        title: 'Customer',
        dataIndex: 'customerCompany',
        render: function render(text: string, record: BookingRequestValue, index: number) {
          return <span>{record.customerCompany?.registered_name || ''}</span>;
        },
      },
    ];
    if (ocean_transport_order.load_type === LOAD_TYPE_FCL) {
      columns = [
        ...columns,
        {
          title: 'Allocated',
          dataIndex: 'allocated',
          render: function render(text: string, record: BookingRequestValue, index: number) {
            const allocated = ocean_transport_order.parentShipmentContainersCountByTypeAndSetting(
              record.id
            );
            return (
              <span>
                {Object.keys(allocated)
                  .map((container_type) => showContainerAllocation(allocated[container_type]))
                  .join(', ')}
              </span>
            );
          },
        },
      ];
    }
    columns = [
      ...columns,
      {
        title: 'Actions',
        dataIndex: 'actions',
        render: function render(text: string, record: BookingRequestValue, index: number) {
          return (
            <Radio.Group
              onChange={(e) => setCancelAllocatedResources(e.target.value)}
              value={cancelAllocatedResources}
            >
              <Radio value={false}>Delink</Radio>
              <Radio value={true}>Cancel</Radio>
            </Radio.Group>
          );
        },
      },
    ];
    return columns;
  };

  return (
    <Table
      dataSource={(ocean_transport_order.booking_requests || []).slice()}
      columns={getColumns()}
      pagination={false}
      rowKey={(record) => record.id || ''}
      size="small"
    />
  );
};

export default CancelBookingForm;
