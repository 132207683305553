import React, { useEffect } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  DatePicker,
} from '@shipmnts/pixel-hub';
import { CustomIcon, dayjs } from '@shipmnts/pixel-hub';
import { get } from 'lodash';

import InquiryForm from 'sales_hub/components/Inquiry/InquiryForm';
import { GET_INQUIRY, UPDATE_INQUIRY, LOSE_INQUIRY } from 'sales_hub/graphql/inquiry';
import Inquiry, { InquiryValue } from 'sales_hub/models/inquiry';

import { createInquiryParamsFromForm } from './common';

type Props = {
  onSuccess?: () => void;
  id?: string;
  inquiry: InquiryValue;
};

export const convertDateToUnix = (dateStr?: string) => {
  if (!dateStr) return null;
  return new Date(dateStr).getTime() / 1000;
};
const { useForm } = Form;
const LostInquiryLayout = ({ onSuccess, id }: Props) => {
  //   Context
  const [form] = useForm();
  const _loseForm = useForm();
  const loseForm = _loseForm[0];

  // Queries/ Mutations
  const {
    data: inquiryDetails,
    loading,
    error,
  } = useQuery(GET_INQUIRY, { variables: { id: id }, fetchPolicy: 'no-cache' });
  const [
    loseInquiry,
    { data: loseInquiryData, error: loseInquiryError, loading: loadingLoseInquiry },
  ] = useMutation(LOSE_INQUIRY);
  const [
    updateInquiry,
    { data: updateInquiryData, error: updateInquiryError, loading: loadingUpdateInquiry },
  ] = useMutation(UPDATE_INQUIRY);

  // Effects
  // On update inquiry successfull
  useEffect(() => {
    if (updateInquiryData && !updateInquiryError) {
      const values = loseForm.getFieldsValue(['actualDate', 'reasonForLoss', 'remarks']);
      loseForm.validateFields().then((res) => {
        if (res.actualDate)
          loseInquiry({
            variables: {
              inquiry_id: id,
              actual_date: convertDateToUnix(values.actualDate),
              event_data: { reason: values.reasonForLoss, remarks: values.remarks },
            },
          });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateInquiryData]);
  // On lose inquiry
  useEffect(() => {
    if (loseInquiryData && !loseInquiryError) {
      if (onSuccess) onSuccess();
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loseInquiryData]);
  // On error
  useEffect(() => {
    if (updateInquiryError) message.error(updateInquiryError?.message);
  }, [updateInquiryError]);
  // On error
  useEffect(() => {
    if (loseInquiryError) message.error(loseInquiryError?.message);
  }, [loseInquiryError]);

  // Handling loading/error
  if (loading) {
    return <div>loading...</div>;
  }
  if (error) {
    return <div>erorr occured</div>;
  }

  // Methods and Constants
  const inquiry = Inquiry.create(get(inquiryDetails, 'get_inquiry'));

  const onClose = () => {
    if (!loadingUpdateInquiry || !loadingLoseInquiry) {
      // setIsVisible(false);
      if (onSuccess) onSuccess();
      form.resetFields();
    }
  };

  const onFinish = (values: any) => {
    updateInquiry({
      variables: {
        id: id,
        inquiry: { ...createInquiryParamsFromForm(values, inquiry) },
      },
    });
  };

  const today = dayjs().add(1, 'day');

  return (
    <>
      <Drawer
        title={
          <div style={{ display: 'flex' }}>
            <CustomIcon icon="CompassIcon" height={24} width={24} style={{ paddingRight: '5px' }} />
            <div>Mark Inquiry As Lost - #{inquiry.inquiry_number}</div>
          </div>
        }
        width={1057}
        onClose={onClose}
        open={true}
        styles={{ body: { paddingBottom: 80 } }}
        footer={
          <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
            <Button
              onClick={onClose}
              disabled={loading || loadingLoseInquiry || loadingUpdateInquiry}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => {
                form.submit();
                loseForm.submit();
              }}
              loading={loading || loadingLoseInquiry || loadingUpdateInquiry}
              disabled={loading || loadingLoseInquiry || loadingUpdateInquiry}
            >
              Mark Inquiry As Lost
            </Button>
          </Space>
        }
        destroyOnClose={true}
      >
        <Space direction="vertical">
          <Card title="Lose Details">
            <Form layout="vertical" form={loseForm}>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Inquiry lost on"
                    name={'actualDate'}
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      showTime={true}
                      disabledDate={(currentDate) => currentDate.isAfter(today)}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Reason for loss"
                    name={'reasonForLoss'}
                    rules={[{ required: true }]}
                  >
                    <Select placeholder="Please choose the type">
                      <Select.Option value="Price too high">Price too high</Select.Option>
                      <Select.Option value="Shipment got cancelled">
                        Shipment got cancelled
                      </Select.Option>
                      <Select.Option value="Costing Purpose">Costing Purpose</Select.Option>
                      <Select.Option value="Transit too high">Transit too high</Select.Option>
                      <Select.Option value="Time to quote high">Time to quote high</Select.Option>
                      <Select.Option value="Lower credit period">Lower credit period</Select.Option>
                      <Select.Option value="Cargo Ready but Vessel / Space unavailable">
                        Cargo Ready but Vessel / Space unavailable
                      </Select.Option>
                      <Select.Option value="Additional services requested">
                        Additional services requested
                      </Select.Option>
                      <Select.Option value="Higher free days required">
                        Higher free days required
                      </Select.Option>
                      <Select.Option value="Other Reason ">Other Reason </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item label="Loss Remarks" name={'remarks'} rules={[{ required: true }]}>
                    <Input.TextArea rows={2} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
          <Card title="Inquiry Details">
            <InquiryForm onFinish={onFinish} form={form} inquiry={inquiry} />
          </Card>
        </Space>
      </Drawer>
    </>
  );
};

export default LostInquiryLayout;
