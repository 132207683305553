import { useMutation } from '@apollo/client';
import { message, Modal } from '@shipmnts/pixel-hub';
import React, { useEffect } from 'react';
import { DELETE_FREIGHT_CONTRACT } from './graphql/freightContract';
interface DeleteFreightContractProps {
  onClose?: () => void;
  onSuccess?: () => void;
  freight_contract: any;
}
export default function DeleteFreightContract(props: DeleteFreightContractProps) {
  const { onClose, freight_contract, onSuccess } = props;
  const [deleteFC, { data, error }] = useMutation(DELETE_FREIGHT_CONTRACT);
  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    if (error) {
      message.error(error.message);
      return;
    }
    if (data?.delete_freight_contract) {
      message.success('Service deleted successfully!');
      if (onSuccess) onSuccess();
      if (onClose) onClose();
    }
  }, [data, error, onSuccess, onClose]);

  const config = {
    title: `Are you sure to Delete this Contract?`,
    content: 'Once you click on Confirm, It will Delete Contract',
    okText: 'Confirm Delete',
    cancelText: 'Cancel',
    onCancel: () => {
      if (onClose) onClose();
    },
    onOk: async () => {
      deleteFC({
        variables: {
          id: freight_contract.id,
        },
      });
    },
  };

  useEffect(() => {
    Modal.confirm(config);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  return <>{contextHolder}</>;
}
