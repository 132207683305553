/* eslint-disable no-lone-blocks */
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useParams, Link, useLocation } from 'wouter';
import {
  Layout,
  Button,
  Tabs,
  Card,
  Skeleton,
  Result,
  Typography,
  Row,
  Col,
  Tag,
  Tooltip,
  TabsProps,
} from '@shipmnts/pixel-hub';
import {
  PageHeader,
  RenderCollaborators,
  FireFilled,
  useActivityContext,
  UploadedDocuments,
} from '@shipmnts/pixel-hub';
import BookingRequestOverview from './BookingRequestOverview';
import { useQuery } from '@apollo/client';
import { startCase as _startCase } from 'lodash';

import BookingRequest, {
  BookingRequestValue,
  AmendmentDisableDetail,
} from 'operations/models/BookingRequest';
import { ShipmentContainerValue } from 'operations/models/ShipmentContainer';
import { isLiveReeferCargo } from 'operations/models/Cargo';
import { GET_BOOKING_REQUEST } from 'operations/modules/booking/graphql/bookingRequest';
import OceanTransportOrdersView from './OceanTransportOrdersView';
import ContainerDetailsCard from './ContainerDetailsCard';
import CargoDetailsCard from './CargoDetailsCard';
import RoutingDetailsCard from './RoutingDetailsCard';
import RoadTransportOrdersView from './RoadTransportOrdersView';
import OtherDetailsCard from './OtherDetailsCard';
import ShipmentContainersCard from './ShipmentContainersCard';
import StuffingBufferServiceCard from './StuffingBufferServiceCard';
import { BreadCrumbsMapRender, useSession } from 'common';
import { AppHelmet, CustomIcon } from '@shipmnts/pixel-hub';
import { STATUS_CANCELLED } from 'operations/modules/reports/constants';
import { observer } from 'mobx-react-lite';
import {
  BOOKING_TYPE_BOOKING_REQUEST,
  BOOKING_TYPE_OCEAN_TRANSPORT_ORDER,
} from 'operations/modules/booking/constants';

import { STATUS_STUFFING_ORDER_CARTED_IN } from 'operations/constants';
import MiscellaneousServiceCard from './MiscellaneousServiceCard';
import { ActionRenderer } from 'operations/modules/actionHelper/ActionRenderer';
import {
  createNewPickupOrder,
  getAllocationAction,
} from 'operations/modules/actionHelper/BookingRequestActions/bookingRequestActionHelper';
import {
  STUFFING_LOCATION_TYPE_FACTORY,
  LOAD_TYPE_FCL,
  LOAD_TYPE_LCL,
} from 'operations/baseConstants';

const { Content, Header } = Layout;
const { Paragraph } = Typography;

const BookingRequestViewContent = observer(function BookingRequestViewContent(props: {
  refetchData: () => void;
  bookingRequest: BookingRequestValue;
}): JSX.Element {
  const { bookingRequest, refetchData } = props;
  const sessionData = useSession();
  const isLiveReefer = useMemo(
    () => (bookingRequest.cargos ? isLiveReeferCargo(bookingRequest.cargos) : false),
    [bookingRequest.cargos]
  );
  const load_type = bookingRequest.load_type;

  const oceanTransportOrder = bookingRequest.ocean_transport_orders[0];

  const rtoCreateDisabled =
    bookingRequest.stuffing_location_type === STUFFING_LOCATION_TYPE_FACTORY
      ? !bookingRequest.isAnyContainerNotAllocatedWithRto
      : !bookingRequest.isAnyCargoNotAllocatedWithRto;

  const allSections = [
    'overview',
    'routing_details',
    'ocean_transport_orders',
    'container_requests',
    'cargos',
    'road_transport_orders',
    'stuffing_buffer_service',
    'shipment_containers',
    'other_details',
  ];
  const amendmentDisableDetails = allSections.reduce(
    (
      obj: {
        [key: string]: AmendmentDisableDetail;
      },
      section: string
    ) => {
      obj[section] = bookingRequest.amendmentDisableDetails(section);
      return obj;
    },
    {}
  );
  const isLinkedWithShipments = Boolean(
    bookingRequest.shipments && bookingRequest.shipments.length > 0
  );
  return (
    <>
      <BookingRequestOverview
        bookingRequest={bookingRequest}
        disabled={amendmentDisableDetails?.overview?.isDisable}
        disableReason={amendmentDisableDetails?.overview?.disableReason}
        onUpdate={(booking_request) => bookingRequest.setBasicDetails(booking_request)}
      />
      <RoutingDetailsCard
        validateVesselVoyage={
          bookingRequest.load_type === LOAD_TYPE_FCL && !bookingRequest.isFullyUnallocated
        }
        parent_id={oceanTransportOrder?.id}
        parent_type="ocean_transport_order"
        routingLegs={oceanTransportOrder?.routing_legs}
        globalCarrierId={oceanTransportOrder?.global_carrier?.id}
        voyageScheduleId={oceanTransportOrder?.voyage_schedule_id}
        isReeferContainer={oceanTransportOrder?.container_requests?.[0]?.is_reefer_container}
        bookingType={oceanTransportOrder?.booking_type}
        onUpdate={(routing_legs) => oceanTransportOrder.setRoutingLegs(routing_legs)}
        disabled={amendmentDisableDetails?.routing_details?.isDisable}
        disableReason={amendmentDisableDetails?.routing_details?.disableReason}
        onlyTranshipmentHopEditable={
          !amendmentDisableDetails?.routing_details?.isDisable &&
          amendmentDisableDetails?.routing_details?.blReleased
        }
      />

      {bookingRequest.isFullyUnallocated && load_type === LOAD_TYPE_FCL ? (
        <Card id="ocean_transport_orders" title="Bookings">
          <div style={{ textAlign: 'center', alignContent: 'center' }}>
            <ActionRenderer
              id={bookingRequest.id}
              data={bookingRequest}
              doc_type_id={'Shipment::BookingRequest'}
              selectedActions={getAllocationAction(bookingRequest, sessionData)}
              refetchData={refetchData}
            />
          </div>
        </Card>
      ) : (
        <OceanTransportOrdersView
          booking_request={bookingRequest}
          oceanTransportOrders={bookingRequest.ocean_transport_orders}
          onUpdateAllocation={(booking_request) => {
            bookingRequest.setOceanTransportOrders(booking_request.ocean_transport_orders);
            bookingRequest.setContainerRequests(booking_request.container_requests);
            bookingRequest.setRoadTransportOrders(booking_request.road_transport_orders);
          }}
          disabled={amendmentDisableDetails?.ocean_transport_orders?.isDisable}
          disableReason={amendmentDisableDetails?.ocean_transport_orders?.disableReason}
          refetchData={refetchData}
        />
      )}

      <div id="service_orders">
        {!(
          (bookingRequest.stuffing_location_type === STUFFING_LOCATION_TYPE_FACTORY &&
            bookingRequest.fcl_road_transport_orders.length === 0 &&
            bookingRequest.isAnyContainerPickedUp) ||
          (bookingRequest.stuffing_location_type !== STUFFING_LOCATION_TYPE_FACTORY &&
            bookingRequest.loose_road_transport_orders.length === 0 &&
            bookingRequest.stuffing_buffer_service_orders[0]?.isCartedIn)
        ) && (
          <Card id="road_transport_orders" title="Pickup Orders">
            {bookingRequest.road_transport_orders.length === 0 ? (
              <div style={{ textAlign: 'center' }}>
                <ActionRenderer
                  id={bookingRequest.id}
                  data={bookingRequest}
                  doc_type_id={'Shipment::BookingRequest'}
                  refetchData={refetchData}
                  selectedActions={[createNewPickupOrder(bookingRequest)]}
                />
              </div>
            ) : (
              <RoadTransportOrdersView
                bookingRequest={bookingRequest}
                roadTransportOrders={bookingRequest.road_transport_orders}
                oceanTransportOrders={bookingRequest.ocean_transport_orders}
                onUpdate={(rto) => bookingRequest.updateRoadTransportOrder(rto)}
                onDelete={(rto_id) => {
                  bookingRequest.deleteRoadTransportOrder(rto_id);
                  const new_ocean_transport_orders = bookingRequest.ocean_transport_orders.map(
                    (oto) => {
                      return {
                        ...oto,
                        shipment_containers: oto.shipment_containers.map(
                          (sc: ShipmentContainerValue) => ({
                            ...sc,
                            road_transport_order_ids: (sc.road_transport_order_ids || []).filter(
                              (road_transport_order_id) => road_transport_order_id !== rto_id
                            ),
                          })
                        ),
                      };
                    }
                  );
                  bookingRequest.setOceanTransportOrders(new_ocean_transport_orders);
                }}
                onAdd={(roadTransportOrder) =>
                  bookingRequest.addRoadTransportOrder(roadTransportOrder)
                }
                refetchData={refetchData}
                areAllContainersPickedUp={bookingRequest.areAllContainersPickedUp}
                isEditable={!amendmentDisableDetails?.road_transport_orders?.isDisable}
                isCreateDisabled={rtoCreateDisabled}
              />
            )}
          </Card>
        )}
        <StuffingBufferServiceCard
          bookingRequest={bookingRequest}
          load_type={load_type}
          disabled={amendmentDisableDetails?.stuffing_buffer_service?.isDisable}
          disableReason={amendmentDisableDetails?.stuffing_buffer_service?.disableReason}
          refetchData={refetchData}
        />
        <MiscellaneousServiceCard
          bookingRequest={bookingRequest}
          disabled={isLinkedWithShipments}
          refetchData={refetchData}
        />
      </div>
      {load_type === LOAD_TYPE_FCL && (
        <ContainerDetailsCard
          parent_id={bookingRequest.id}
          parent_type={BOOKING_TYPE_BOOKING_REQUEST}
          containerRequests={bookingRequest.container_requests}
          onUpdate={(booking_request) => {
            const br = booking_request as BookingRequestValue;
            bookingRequest.setContainerRequests(br.container_requests);
            bookingRequest.setOceanTransportOrders(br.ocean_transport_orders);
            bookingRequest.setRoadTransportOrders(br.road_transport_orders);
          }}
          isLiveReefer={isLiveReefer}
          disabled={amendmentDisableDetails?.container_requests?.isDisable}
          disableReason={amendmentDisableDetails?.container_requests?.disableReason}
          disableAdd={isLinkedWithShipments}
          disableFields={amendmentDisableDetails?.container_requests?.disableFields}
        />
      )}

      <CargoDetailsCard
        parent_id={bookingRequest.id}
        parent_type={BOOKING_TYPE_BOOKING_REQUEST}
        cargos={bookingRequest.cargos}
        onUpdate={(cargos) => bookingRequest.setCargos(cargos)}
        load_type={bookingRequest.load_type}
        disabled={amendmentDisableDetails?.cargos?.isDisable}
        disableReason={amendmentDisableDetails?.cargos?.disableReason}
        disableCargoAddRemove={
          bookingRequest.load_type === LOAD_TYPE_LCL &&
          bookingRequest.stuffing_buffer_service_orders?.[0]?.status ===
            STATUS_STUFFING_ORDER_CARTED_IN
        }
      />
      <OtherDetailsCard
        parent_id={bookingRequest.id}
        parent_type={BOOKING_TYPE_BOOKING_REQUEST}
        remarks={bookingRequest?.remarks}
        onUpdate={(remarks: string) => bookingRequest.setRemarks(remarks)}
        disabled={amendmentDisableDetails?.other_details?.isDisable}
        disableReason={amendmentDisableDetails?.other_details?.disableReason}
      />
      <Card id="attachements" title="Uploaded Documents">
        <UploadedDocuments
          sessionData={sessionData}
          parent_id={bookingRequest.id}
          parent_type={BOOKING_TYPE_BOOKING_REQUEST}
          linked_parents={[
            {
              parent_type: BOOKING_TYPE_OCEAN_TRANSPORT_ORDER,
              parent_ids: bookingRequest.ocean_transport_orders.map((oto) => oto.id),
            },
          ]}
          deleteDisabled={bookingRequest?.status === STATUS_CANCELLED}
          docgen_url={process.env.DOCGEN_URL || ''}
        />
      </Card>
      {load_type === LOAD_TYPE_FCL && (
        <ShipmentContainersCard booking_request={bookingRequest} refetchData={refetchData} />
      )}
    </>
  );
});

export const BookingRequestView = React.memo(function BookingRequestView(props: {
  id: string;
  onClose?: () => void;
  externalLink?: boolean;
}): JSX.Element {
  const [location, navigate] = useLocation();
  const { id, onClose, externalLink } = props;
  const sessionData = useSession();

  const {
    loading: fetching,
    error,
    data,
    refetch,
  } = useQuery(GET_BOOKING_REQUEST, {
    variables: { id },
    fetchPolicy: 'network-only',
  });

  const [bookingRequest, setBookingRequest] = useState<BookingRequestValue | undefined>(undefined);

  const loading = fetching && !bookingRequest;

  const didMountRef = useRef(false);

  const [activeTab, setActiveTab] = useState('overview');

  const scrollFunction = useCallback(() => {
    const content = document.getElementById('booking_request_content');
    if (content)
      [
        'overview',
        'routing_details',
        'ocean_transport_orders',
        'service_orders',
        'container_requests',
        'cargos',
        'attachements',
        'shipment_containers',
      ].every((key) => {
        const elem = document.getElementById(key);
        if (elem) {
          const bounding = elem.getBoundingClientRect();
          if (bounding.bottom >= content.offsetTop) {
            setActiveTab(key);
            return false;
          }
        }
        return true;
      });
  }, []);
  const { setActivityProps } = useActivityContext();
  useEffect(() => {
    const state = { ...window.history.state };
    delete state?.booking_request;
    navigate(location, { state: state, replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!didMountRef.current) {
      const content = document.getElementById('booking_request_content');
      if (content) content.addEventListener('scroll', scrollFunction);
    }
    didMountRef.current = true;
    if (!error && data?.booking_request) {
      setBookingRequest(BookingRequest.create(data?.booking_request, { sessionData }));
    }
  }, [data, error, scrollFunction, sessionData]);

  if (error || data?.booking_request === null) {
    let errorCode: 500 | 404 = 500,
      title = 'Something went wrong while fetching the booking request.',
      subTitle = error ? error.message : '',
      button = (
        <Button
          onClick={() => {
            if (refetch) {
              refetch();
            }
          }}
          type="primary"
        >
          Retry
        </Button>
      );
    if (
      (error && error.message.includes('Shipment::BookingRequest for given id Not found')) ||
      data.booking_request === null
    ) {
      errorCode = 404;
      title = `Customer Order #${id} does not exist`;
      subTitle = '';
      button = (
        <Button type="primary">
          <Link to="/">Go Home</Link>
        </Button>
      );
    }
    return <Result status={errorCode} title={title} subTitle={subTitle} extra={button} />;
  }

  const title = (
    <Row>
      <div>{bookingRequest?.shipment_booking_number}</div>
      <span>&nbsp;|&nbsp;</span>
      <div>{bookingRequest?.customerCompany?.registered_name}</div>
      {bookingRequest?.high_priority && (
        <div style={{ marginLeft: '8px' }}>
          <FireFilled style={{ color: '#fa8c16' }} />
        </div>
      )}
      {bookingRequest?.status === STATUS_CANCELLED && (
        <Tag style={{ marginLeft: '8px' }} color="red">
          {_startCase(STATUS_CANCELLED)}
        </Tag>
      )}
      {externalLink && bookingRequest && (
        <span style={{ marginLeft: '10px' }}>
          <Tooltip title="Open Detail Screen">
            <a
              href={`/view/booking_request/${bookingRequest?.id}`}
              target="_blank"
              rel="noreferrer"
            >
              <CustomIcon icon="ExternalLinkIcon" />
            </a>
          </Tooltip>
        </span>
      )}
    </Row>
  );

  const getTab = () => {
    let tabs: TabsProps['items'] = [];

    tabs = [
      {
        label: 'Overview',
        key: 'overview',
      },
      {
        label: 'Routing Details',
        key: 'routing_details',
      },
      {
        label: 'Bookings',
        key: 'ocean_transport_orders',
      },
      {
        label: 'Services',
        key: 'service_orders',
      },
      {
        label: 'Cargo Details',
        key: 'cargos',
      },
      { label: 'Uploaded Documents', key: 'attachements' },
      { label: 'Container Details', key: 'shipment_containers' },
    ];

    {
      bookingRequest?.load_type === LOAD_TYPE_FCL &&
        tabs.push({ label: 'Container Requests', key: 'container_requests' });
    }

    return tabs;
  };

  return (
    <Layout style={{ height: '100%' }}>
      <Layout>
        <AppHelmet>
          <title>{`#${bookingRequest?.shipment_booking_number || ''} | Customer Order`}</title>
        </AppHelmet>
        <Header className="component-background" style={{ padding: 0, height: 'auto' }}>
          {!onClose && (
            <div style={{ marginTop: '10px', marginLeft: '24px' }}>
              <BreadCrumbsMapRender />
            </div>
          )}
          <Skeleton avatar paragraph={{ rows: 3 }} loading={loading} active>
            <Row>
              <Col style={{ width: '100%' }}>
                <PageHeader
                  style={{ paddingTop: '3px', width: '100%' }}
                  title={title}
                  onBack={onClose}
                  extra={[
                    <RenderCollaborators
                      referenceId={bookingRequest?.id}
                      referenceType={'Shipment::BookingRequest'}
                      key=""
                      id={bookingRequest?.shipment_booking_number}
                    />,
                    <Button
                      key="activity"
                      size={'small'}
                      style={{ display: 'flex', alignItems: 'center' }}
                      onClick={() => {
                        bookingRequest?.id &&
                          setActivityProps({
                            visible: true,
                            resource_id: bookingRequest?.id,
                            resource_type: 'Shipment::BookingRequest',
                            showDrawer: externalLink,
                          });
                      }}
                    >
                      Activity
                      <span style={{ marginLeft: '5px', display: 'flex' }}>
                        <CustomIcon icon="ActivityIcon" />
                      </span>
                    </Button>,
                    bookingRequest ? (
                      <>
                        <ActionRenderer
                          id={bookingRequest.id}
                          doc_type_id="Shipment::BookingRequest"
                          isDetailScreen
                          data={bookingRequest}
                          refetchData={refetch}
                        />
                      </>
                    ) : (
                      <></>
                    ),
                  ]}
                  tags={[
                    <Tag color="blue" key="load_type_trade_type">
                      {bookingRequest?.load_type?.toUpperCase()}{' '}
                    </Tag>,
                  ]}
                  avatar={{
                    src: bookingRequest?.customerCompany?.logo || '',
                    icon: !bookingRequest?.customerCompany?.logo
                      ? bookingRequest?.customerCompany?.registered_name[0]
                      : '',
                  }}
                  footer={
                    <Tabs
                      activeKey={activeTab}
                      onTabClick={(key: string) => {
                        const elem = document.getElementById(key);
                        const content = document.getElementById('booking_request_content');
                        if (elem && content) {
                          elem.scrollIntoView({ behavior: 'smooth' });
                        }
                        setActiveTab(key);
                      }}
                      items={getTab()}
                    ></Tabs>
                  }
                >
                  <Paragraph ellipsis type="secondary">
                    {bookingRequest?.customerAddress?.print_address}
                  </Paragraph>

                  {bookingRequest?.shipments && bookingRequest?.shipments?.length > 0 && (
                    <Paragraph>
                      JOB Number(s) # &nbsp;&nbsp;
                      {bookingRequest.shipments.map((shipment, index) => {
                        return (
                          <>
                            <Link to={`~/view/shipment/${shipment.id}/documents`}>
                              {shipment.job_number}
                            </Link>
                            {index === (bookingRequest?.shipments?.length || 0) - 1 ? '' : ', '}
                          </>
                        );
                      })}
                    </Paragraph>
                  )}
                </PageHeader>
              </Col>
            </Row>
          </Skeleton>
        </Header>
        <Content id="booking_request_content" className="view-content">
          {loading && (
            <>
              <Card>
                <Skeleton avatar paragraph={{ rows: 3 }} loading={loading} active />
              </Card>
              <Card>
                <Skeleton avatar paragraph={{ rows: 3 }} loading={loading} active />
              </Card>
              <Card>
                <Skeleton avatar paragraph={{ rows: 3 }} loading={loading} active />
              </Card>
            </>
          )}
          {bookingRequest && (
            <BookingRequestViewContent refetchData={refetch} bookingRequest={bookingRequest} />
          )}
        </Content>
      </Layout>
    </Layout>
  );
});

const BookingRequestViewWrapper = React.memo(function BookingRequestViewWrapper(): JSX.Element {
  const params = useParams<{ id: string }>();
  const { id } = params;
  if (!id) return <></>;
  return <BookingRequestView id={id}></BookingRequestView>;
});

export default BookingRequestViewWrapper;
