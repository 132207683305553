/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree';

const Template = types.model({
  id: types.identifier,
  name: types.string,
});

export interface TemplateValue extends Instance<typeof Template> {}
export interface TemplateIn extends SnapshotIn<typeof Template> {}
export interface TemplateOut extends SnapshotOut<typeof Template> {}

export default Template;
