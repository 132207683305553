import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { message, Modal } from '@shipmnts/pixel-hub';
import { DELETE_STOCK_ORDER_ITEM } from 'operations/graphql/StockOrder';

interface AwbStockDeleteProps {
  id: number;
  stock_order_items_awb_number: string;
  onClose?: () => void;
  onSuccess?: () => void;
}

export default function AwbStockDelete(props: AwbStockDeleteProps) {
  const { id, stock_order_items_awb_number, onClose, onSuccess } = props;
  const [modal, contextHolder] = Modal.useModal();
  const [
    deleteStockOrderItem,
    {
      data: deleteStockOrderItemData,
      loading: deleteStockOrderItemLoading,
      error: deleteStockOrderItemError,
    },
  ] = useMutation(DELETE_STOCK_ORDER_ITEM);

  useEffect(() => {
    if (deleteStockOrderItemLoading) {
      return;
    }
    if (deleteStockOrderItemError) {
      // message.error(deleteStockOrderItemError)
    } else if (deleteStockOrderItemData?.delete_stock_order_item) {
      message.success(`Stock ${stock_order_items_awb_number} deleted successfully`);
      if (onSuccess) onSuccess();
      if (onClose) onClose();
    }
  }, [
    deleteStockOrderItemData,
    deleteStockOrderItemLoading,
    deleteStockOrderItemError,
    stock_order_items_awb_number,
    onSuccess,
    onClose,
  ]);

  const config = {
    title: `Are you sure to delete the AWB number: ${stock_order_items_awb_number}?`,
    content: 'Once you click on Confirm, this AWB number will no longer be available',
    okText: 'Confirm delete',
    cancelText: 'Cancel',
    onCancel: () => {
      if (onClose) onClose();
    },
    onOk: async () => {
      deleteStockOrderItem({ variables: { id } });
    },
  };

  useEffect(() => {
    modal.confirm(config);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  return <>{contextHolder}</>;
}
