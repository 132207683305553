import { PerformAction } from '../models';
import { RenderEventsForm } from 'common';
import { AddCollaborators } from '@shipmnts/pixel-hub';
import {
  InquiryOptionDrawer,
  WinInquiryLayout,
  LostInquiryLayout,
  CancelInquiryLayout,
  EditInquiryDrawer,
  CreateResourceByInquiry,
  DuplicateInquiryDrawer,
  ShareInquiry,
  InquiryLinkedResources,
  AmendInquiry,
} from 'sales_hub';
import { toJS } from 'mobx';
import UpdateTeamActionRenderer from 'common/components/Team/UpdateTeamDrawer';

export const performInquiryAction = (payload: any): PerformAction => {
  const { inquiry, sessionData, extraProps } = payload;
  const eventName = extraProps?.event_name;
  return {
    actionParams: {
      id: inquiry.inquiry_global_id,
      referenceType: 'SalesHub::InquiryGlobal',
      eventName: eventName,
      session: sessionData,
      onSuccess: (sendEmail: boolean) => {
        // if (sendEmail) {
        //   setEmailProps({
        //     title: `Send ${startCase(
        //       (actionName || '').replace('_and_update_booking', '')
        //     )} Confirmation Email`,
        //     action_name: 'shutout_notice',
        //     resource_ids: [container.id],
        //   });
        //   setVisible(true);
        // }
      },
    },
    component: RenderEventsForm,
  };
};

export const performInquiryWinAction = (payload: any): PerformAction => {
  const { inquiry, sessionData } = payload;
  return {
    actionParams: {
      id: inquiry.id,
      referenceType: 'SalesHub::Inquiry',
      session: sessionData,
      inquiry: inquiry,
      onSuccess: (sendEmail: boolean) => {
        // if (sendEmail) {
        //   setEmailProps({
        //     title: `Send ${startCase(
        //       (actionName || '').replace('_and_update_booking', '')
        //     )} Confirmation Email`,
        //     action_name: 'shutout_notice',
        //     resource_ids: [container.id],
        //   });
        //   setVisible(true);
        // }
      },
    },
    component: WinInquiryLayout,
  };
};

export const performInquiryOptionCreation = (payload: any): PerformAction => {
  const { inquiry, sessionData } = payload;
  return {
    actionParams: {
      id: inquiry.id,
      referenceType: 'SalesHub::Inquiry',
      session: sessionData,
      inquiry: inquiry,
      isVisible: true,
      onSuccess: () => {
        // if (sendEmail) {
        //   setEmailProps({
        //     title: `Send ${startCase(
        //       (actionName || '').replace('_and_update_booking', '')
        //     )} Confirmation Email`,
        //     action_name: 'shutout_notice',
        //     resource_ids: [container.id],
        //   });
        //   setVisible(true);
        // }
      },
    },
    component: InquiryOptionDrawer,
  };
};

export const performInquiryOptionSharePDF = (payload: any): PerformAction => {
  const { inquiry } = payload;
  return {
    actionParams: {
      docTypeId: inquiry.id,
      docType: 'SalesHub::Inquiry',
      doc: inquiry,
    },
    component: ShareInquiry,
  };
};

export const performInquiryLostAction = (payload: any): PerformAction => {
  const { inquiry, sessionData } = payload;
  return {
    actionParams: {
      id: inquiry.id,
      referenceType: 'SalesHub::Inquiry',
      session: sessionData,
      inquiry: inquiry,
      ChildrenComponent: LostInquiryLayout,
      onSuccess: (sendEmail: boolean) => {
        // if (sendEmail) {
        //   setEmailProps({
        //     title: `Send ${startCase(
        //       (actionName || '').replace('_and_update_booking', '')
        //     )} Confirmation Email`,
        //     action_name: 'shutout_notice',
        //     resource_ids: [container.id],
        //   });
        //   setVisible(true);
        // }
      },
    },
    component: InquiryLinkedResources,
  };
};

export const performInquiryCancelAction = (payload: any): PerformAction => {
  const { inquiry, sessionData } = payload;
  return {
    actionParams: {
      id: inquiry.id,
      referenceType: 'SalesHub::Inquiry',
      session: sessionData,
      inquiry: inquiry,
      ChildrenComponent: CancelInquiryLayout,
      onSuccess: (sendEmail: boolean) => {
        // if (sendEmail) {
        //   setEmailProps({
        //     title: `Send ${startCase(
        //       (actionName || '').replace('_and_update_booking', '')
        //     )} Confirmation Email`,
        //     action_name: 'shutout_notice',
        //     resource_ids: [container.id],
        //   });
        //   setVisible(true);
        // }
      },
    },
    component: InquiryLinkedResources,
  };
};
export const performInquiryEditAction = (payload: any): PerformAction => {
  const { inquiry, sessionData } = payload;
  return {
    actionParams: {
      id: inquiry.id,
      referenceType: 'SalesHub::Inquiry',
      session: sessionData,
      inquiry: inquiry,
      onSuccess: (sendEmail: boolean) => {
        // TODO
      },
      isVisible: true,
      setIsVisible: () => {
        //Todo
      },
    },
    component: EditInquiryDrawer,
  };
};

export const performInquiryAmendAction = (payload: any): PerformAction => {
  const { inquiry, sessionData } = payload;
  return {
    actionParams: {
      id: inquiry.id,
      referenceType: 'SalesHub::Inquiry',
      session: sessionData,
      inquiry: inquiry,
      onSuccess: (sendEmail: boolean) => {
        // TODO
      },
      isVisible: true,
      setIsVisible: () => {
        //Todo
      },
      ChildrenComponent: AmendInquiry,
    },
    component: InquiryLinkedResources,
  };
};
export const performDuplicateInquiryAction = (payload: any): PerformAction => {
  const { inquiry, sessionData } = payload;
  return {
    actionParams: {
      id: inquiry.id,
      referenceType: 'SalesHub::Inquiry',
      session: sessionData,
      inquiry: inquiry,
      onSuccess: (sendEmail: boolean) => {
        // TODO
      },
      isVisible: true,
      setIsVisible: () => {
        //Todo
      },
    },
    component: DuplicateInquiryDrawer,
  };
};

export const performCreateResource = (payload: any): PerformAction => {
  const { inquiry, sessionData, extraProps } = payload;
  const { shipment_type: shipmentType, resource_type: resourceType } = extraProps;
  return {
    actionParams: {
      id: inquiry.id,
      referenceType: 'SalesHub::Inquiry',
      resourceType: resourceType,
      session: sessionData,
      inquiry: inquiry,
      shipmentType: shipmentType,
      onSuccess: (sendEmail: boolean) => {
        // TODO
      },
    },
    component: CreateResourceByInquiry,
  };
};

export const addCollaboratorsRenderer = (payload: any): PerformAction => {
  const { inquiry } = payload;
  return {
    actionParams: {
      referenceId: inquiry.id,
      referenceType: 'SalesHub::Inquiry',
      id: inquiry?.inquiry_numbeer,
    },
    component: AddCollaborators,
  };
};

export const updateTeamRenderer = (payload: any) => {
  const { inquiry } = payload;
  const initialValues = {
    team: toJS(inquiry?.teams ?? []),
  };
  return {
    actionParams: {
      initialValues: initialValues,
      reference_type: 'SalesHub::Inquiry',
      reference_id: inquiry?.id,
    },
    component: UpdateTeamActionRenderer,
  };
};
