import React, { useState, useRef, useCallback, useMemo } from 'react';
import { Drawer, Button, Typography, Modal, message, Layout } from '@shipmnts/pixel-hub';
import { get as _get } from 'lodash';
import { ShipmentValue } from 'operations/models/Shipment';
import { FREIGHT_TYPE_OCEAN } from '../../../constants';
import { useApolloClient } from '@apollo/client';
import { LINK_HOUSE_SHIPMENT, VALIDATE_LINK_HOUSE } from './query';
import OceanLclUtilisationProgress from './OceanLclUtilizationProgress';
import { ConsolPendingAgGridReport } from './ConsolPendingReport';
import { applySnapshot } from 'mobx-state-tree';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import { LOAD_TYPE_LCL } from 'operations/baseConstants';

interface LinkHouseButtonProp {
  shipment: ShipmentValue;
  existingHouseShipments: any;
}
interface LinkHouseDrawerType {
  visible: boolean;
  onClose: () => void;
  shipment: ShipmentValue;
  existingHouseShipments: any;
}

const { Content } = Layout;
const { Text } = Typography;

const LinkHouseDrawer = (props: LinkHouseDrawerType) => {
  const { visible, onClose, shipment, existingHouseShipments } = props;
  const [saving, setSaving] = useState(false);
  const [saveError, setSaveError] = useState('');
  const [selectedNodes, setSelectedNodes] = useState([]);
  const tradeType = shipment.trade_type;
  const gridRef = useRef<any>();
  const client = useApolloClient();
  const isOceanConsol = shipment.freight_type === FREIGHT_TYPE_OCEAN;

  const getPortDisplay = (record: ShipmentValue, portType: any) => {
    const code =
      _get(record, [portType, 'iata_code'], '') || _get(record, [portType, 'unlocode'], '');
    return `${_get(record, [portType, 'name'], '')}, ${code}`;
  };
  const onRowsSelectionChanged = useCallback(() => {
    const selectedNodes = (gridRef?.current?.api?.getSelectedNodes() || []).filter(
      (node: any) => !node.group
    );
    setSelectedNodes(selectedNodes);
  }, []);

  const linkHouseWithMaster = useCallback(
    async (house_shipment_ids: any) => {
      if (!house_shipment_ids || house_shipment_ids.length === 0) return;
      try {
        setSaving(true);
        setSaveError('');
        const variables = {
          house_shipment_ids,
          master_shipment_id: shipment.id,
        };
        const { data, errors } = await client.mutate({
          mutation: LINK_HOUSE_SHIPMENT,
          variables,
        });
        const error = errors ? getGraphQLErrorsByModel(errors) : undefined;
        setSaving(false);
        if (error) {
          const linkErrors =
            'link_house_shipments' in error
              ? error['link_house_shipments']
              : 'others' in error
              ? error['others']
              : [{ message: 'Something Went Wrong!' }];
          throw linkErrors[0]['message'];
        } else if (data && data?.link_house_shipments) {
          const shp = data?.link_house_shipments;
          try {
            applySnapshot(shipment, shp);
            message.success('Shipments linked');
            onClose();
          } catch (error) {
            setSaveError('something went wrong');
            console.error(error);
          }
        }
      } catch (errors) {
        console.error(errors);
        setSaveError('something went wrong while linking this shipment');
      }
    },
    [onClose, shipment, client]
  );
  const getGraphQLErrorsByModel = (errors: any): any => {
    if (errors && Array.isArray(errors)) {
      return groupBy(errors, (error) => {
        const type = get(error, 'path.[0]');
        if (type) return type;
        return 'others';
      });
    } else {
      return { others: [errors] };
    }
  };

  const onLink = useCallback(async () => {
    const selectedNodes = (gridRef.current.api.getSelectedNodes() || []).filter(
      (node: any): any => !node.group
    );
    if (!selectedNodes || selectedNodes.length === 0) {
      Modal.error({
        title: 'Error',
        content: 'Please select rows first',
      });
    } else {
      const house_shipment_ids = selectedNodes.map((node: any): any => node.data.id);
      if (!isOceanConsol) {
        linkHouseWithMaster(house_shipment_ids);
        return;
      }
      setSaving(true);
      const { data, errors } = await client.mutate({
        mutation: VALIDATE_LINK_HOUSE,
        variables: {
          house_shipment_ids,
          master_shipment_id: shipment.id,
        },
      });
      const warnings = data?.validate_link_house?.warnings;
      setSaving(false);
      if (errors && errors.length > 0) {
        Modal.error({
          title:
            'Selected house shipments are not valid for consolidation with current master because of the following reasons',
          content: (
            <ul style={{ listStyle: 'disc' }}>
              {errors.map((e, i) => (
                <li key={i}>{`${e}`}</li>
              ))}
            </ul>
          ),
          width: 'fit-content',
          style: {
            width: 'fit-content',
            maxWidth: '1000px',
          },
        });
      } else if (warnings && warnings.length > 0) {
        Modal.confirm({
          title:
            'Selected houses have below mismatches with master, please review them before you proceed ahead!',
          content: (
            <ul style={{ listStyle: 'disc' }}>
              {warnings.map((e: any, i: any) => (
                <li key={i}>{e}</li>
              ))}
            </ul>
          ),
          width: 'fit-content',
          style: {
            width: 'fit-content',
            maxWidth: '1000px',
          },
          onOk: () => linkHouseWithMaster(house_shipment_ids),
        });
      } else {
        linkHouseWithMaster(house_shipment_ids);
      }
    }
  }, [isOceanConsol, linkHouseWithMaster, shipment.id, client]);

  const onFirstDataRendered = useCallback(() => {
    if (!gridRef?.current) return;
    const filters = {
      ...(isOceanConsol
        ? {
            port_of_loading_display: {
              type: 'set',
              values: [getPortDisplay(shipment, 'port_of_loading')],
            },
          }
        : {
            port_of_loading_country: {
              type: 'set',
              values: [shipment?.port_of_loading?.country?.name],
            },
            port_of_discharge_country: {
              type: 'set',
              values: [shipment?.port_of_discharge?.country?.name],
            },
          }),
    };
    gridRef?.current?.api?.setFilterModel(filters);
  }, [isOceanConsol, shipment]);

  const defaultFilters = useMemo(() => {
    const oceanFilters: any[] = [];
    if (isOceanConsol && shipment?.load_type === LOAD_TYPE_LCL) {
      oceanFilters.push({
        col: 'load_type',
        condition: {
          filter_type: 'text',
          type: 'equals',
          value: LOAD_TYPE_LCL,
        },
      });
    }
    return [
      {
        col: 'involved_branch_id',
        condition: {
          filter_type: 'ID',
          type: 'equals',
          value: shipment?.involved_branch?.id,
        },
      },
      ...oceanFilters,
    ];
  }, [isOceanConsol, shipment?.involved_branch?.id, shipment?.load_type]);

  const gridOptions = { onFirstDataRendered };

  return (
    <Drawer
      title={`Link house shipment(s)`}
      width="75%"
      open={visible}
      onClose={onClose}
      styles={{ body: { padding: 0 } }}
      destroyOnClose
      footer={
        <div style={{}}>
          <Button disabled={saving} style={{ marginRight: '10px' }} onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onLink} type="primary" loading={saving} disabled={saving}>
            Link
          </Button>
        </div>
      }
    >
      <Layout>
        <Content style={{ background: '#fff' }}>
          {saveError && (
            <Text style={{ marginRight: '10px' }} type="danger">
              ERROR: {saveError}
            </Text>
          )}
          {isOceanConsol && shipment.isFcl() && (
            <OceanLclUtilisationProgress
              masterShipment={shipment}
              existingHouseShipments={existingHouseShipments}
              selectedNodes={selectedNodes}
            />
          )}
          <ConsolPendingAgGridReport
            gridRef={gridRef}
            tradeType={tradeType}
            gridOptions={gridOptions}
            defaultFilters={defaultFilters}
            isOceanConsol={isOceanConsol}
            onRowsSelectionChanged={onRowsSelectionChanged}
          />
        </Content>
      </Layout>
    </Drawer>
  );
};

const LinkHouseButton = (props: LinkHouseButtonProp) => {
  const [linkHouseDrawerVisible, setLinkDrawerVisible] = useState(false);

  // Doing useCallback here because onClose is in dependency in onLink of LinkHouseDrawer
  const onClose = useCallback(() => {
    setLinkDrawerVisible(false);
  }, []);
  return (
    <>
      {linkHouseDrawerVisible && (
        <LinkHouseDrawer visible={linkHouseDrawerVisible} onClose={onClose} {...props} />
      )}
      <Button type="primary" size="small" onClick={() => setLinkDrawerVisible(true)}>
        Link Existing House Shipment(s)
      </Button>
    </>
  );
};

export default LinkHouseButton;
