import React from 'react';
import { Button } from '@shipmnts/pixel-hub';

interface CreateShipmentFooterType {
  showDiscardAction?: boolean;
  onClose?: () => void;
  onBack?: () => void;
  onDiscard?: () => void;
  loading?: boolean;
  activeShipmentIndex: number;
  lastShipmentIndex: number;
}

const CreateShipmentFooter = React.memo(function CreateShipmentFooter(
  props: CreateShipmentFooterType
): JSX.Element {
  const {
    loading,
    onClose,
    onBack,
    onDiscard,
    showDiscardAction,
    activeShipmentIndex,
    lastShipmentIndex,
  } = props;
  const saveText = activeShipmentIndex === lastShipmentIndex ? 'Create Shipment' : 'Next';
  return (
    <div
      className="drawer-footer"
      style={{
        position: 'absolute',
        bottom: '22px',
        width: '98%',
        borderTop: '1px solid #e8e8e8',
        textAlign: 'right',
        background: '#fff',
        padding: '7px',
        margin: '-22px -15px',
      }}
    >
      {activeShipmentIndex && activeShipmentIndex !== lastShipmentIndex + 1 ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '5px',
            width: '100%',
          }}
        >
          <Button key="back" loading={loading} onClick={onBack}>
            Back
          </Button>
          {`${activeShipmentIndex} of ${lastShipmentIndex} House shipments`}
          <Button type="primary" loading={loading} key="next" htmlType="submit">
            {saveText || 'Next'}
          </Button>
        </div>
      ) : (
        <>
          <Button disabled={loading} key="close" onClick={onClose}>
            Close
          </Button>
          {showDiscardAction && (
            <Button
              disabled={loading}
              danger
              style={{ marginLeft: '16px' }}
              key="discard"
              onClick={onDiscard}
            >
              Discard
            </Button>
          )}
          <Button
            type="primary"
            loading={loading}
            style={{ marginLeft: '16px' }}
            htmlType="submit"
            key="save"
          >
            {saveText}
          </Button>
        </>
      )}
    </div>
  );
});

export default CreateShipmentFooter;
