import { types, Instance, SnapshotIn, SnapshotOut, IAnyModelType } from 'mobx-state-tree';
import UserAccount from 'operations/models/UserAccount';
import Company from 'operations/models/Company';

export const STATUS_MESSAGE_PENDING = 'pending';
export const STATUS_MESSAGE_PROCESSED = 'processed';
export const STATUS_MESSAGE_DISCARDED = 'discarded';
export const MESSAGE_TYPE_OPS_PRE_ALERT = 'ops_pre_alert';

const Resource = types.model({
  id: types.identifier,
  job_number: types.maybe(types.string),
});

const Attachment = types.model({
  document_identifier: types.maybe(types.string),
  document_url: types.maybe(types.maybeNull(types.string)),
  file_name: types.maybe(types.maybeNull(types.string)),
  tags: types.maybe(types.array(types.string)),
  parents: types.maybe(
    types.array(
      types.model({
        parent_id: types.maybe(types.string),
        parent_type: types.maybe(types.string),
      })
    )
  ),
});

const Message = types
  .model({
    id: types.identifier,
    message_type: types.maybe(types.maybeNull(types.string)),
    remarks: types.maybe(types.maybeNull(types.string)),
    // resource_data: types.maybe(types.map),
    resource_type: types.maybe(types.maybeNull(types.string)),
    status: types.maybe(types.maybeNull(types.string)),
    subject: types.maybe(types.maybeNull(types.string)),
    assigned_to: types.maybe(types.maybeNull(types.late((): IAnyModelType => UserAccount))),
    created_by: types.maybe(types.maybeNull(types.late((): IAnyModelType => UserAccount))),
    created_at: types.maybe(types.maybeNull(types.number)),
    sender_company_account: types.maybe(types.maybeNull(types.late((): IAnyModelType => Company))),
    receiver_company_account: types.maybe(
      types.maybeNull(types.late((): IAnyModelType => Company))
    ),
    sender_resource_id: types.maybe(types.maybeNull(types.string)),
    receiver_resource_id: types.maybe(types.maybeNull(types.string)),
    sender_resource: types.maybe(types.maybeNull(types.late((): IAnyModelType => Resource))),
    receiver_resource: types.maybe(types.maybeNull(types.late((): IAnyModelType => Resource))),
    attachments: types.maybe(
      types.maybeNull(types.array(types.late((): IAnyModelType => Attachment)))
    ),
  })
  .views((self) => ({}));

export type MessageValue = Instance<typeof Message>;
export type MessageIn = SnapshotIn<typeof Message>;
export type MessageOut = SnapshotOut<typeof Message>;

export type AttachmentValue = Instance<typeof Attachment>;
export type AttachmentIn = SnapshotIn<typeof Attachment>;
export type AttachmentOut = SnapshotOut<typeof Attachment>;

export default Message;
