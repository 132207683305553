import DeleteFreightContract from 'operations/modules/freightContract/DeleteFreightContract';
import ExpireContractValidityModal from 'operations/modules/freightContract/ExpireContractValidityModal';
import ExpireNowModal from 'operations/modules/freightContract/ExpireNowModal';
import { PerformAction } from '../models';
import { SessionDataValue } from 'operations/models/SessionData';
import { EmailProps } from 'operations/components/ChangeEvent/EventEmailDrawer';
// import { EmailProps } from 'operations/modules/shipment/components/Tracking/ChangeEvent/EventEmailDrawer'

interface FreightContractActionType {
  freight_contract: any;
  setEmailProps: (emailProps: EmailProps) => void;
  setVisible: (visible: boolean) => void;
  sessionData: SessionDataValue;
  extraProps?: any;
  navigate: any;
}

export const expireOnRenderer = (payload: FreightContractActionType): PerformAction => {
  const { freight_contract } = payload;
  return {
    actionParams: {
      freight_contract,
    },
    component: ExpireContractValidityModal,
  };
};

export const deleteRenderer = (payload: FreightContractActionType): PerformAction => {
  const { freight_contract } = payload;
  return {
    actionParams: {
      freight_contract,
    },
    component: DeleteFreightContract,
  };
};

export const expireNowRenderer = (payload: FreightContractActionType) => {
  const { freight_contract } = payload;
  return {
    actionParams: {
      freight_contract,
    },
    component: ExpireNowModal,
  };
};
