import React, { useCallback, useEffect, useState } from 'react';
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Button,
  Card,
  SelectProps,
  List,
  Alert,
  FormInstance,
  Radio,
  UploadedDocuments,
  CompanyGroupSearch,
} from '@shipmnts/pixel-hub';
import { LoadingOutlined } from '@ant-design/icons';
import countries from 'common/constants/countries';
import { PhoneNumberInput } from 'common/components/CustomInputs';
import { CompanyListItem, axiosInstance as axios, CompanySearch, useSession } from 'common';
// import { SessionDataValue } from 'network/models/SessionData';
// import { BranchAccountValue } from 'network/models/BranchAccount';
import {
  COMPANY_TYPE_INDIVIDUAL,
  COMPANY_TYPE_COMPANY,
  COMPANY_TYPE_PRIVATE,
  COMPANY_TYPE_PUBLIC,
  COMPANY_TYPE_INC,
  COMPANY_TYPE_LLP,
  COMPANY_TYPE_PARTNERSHIP,
  DEPARTMENTS,
} from 'network/constants';
import { COMPANY_SEARCH_URL } from 'network/baseConstants';
import { CompanyValue } from 'network/models/Company';
import {
  PERMISSION_CUSTOMER_CREATE_EDIT,
  PERMISSION_CUSTOMER_VIEW,
  PERMISSION_SUPPLIER_CREATE_EDIT,
  PERMISSION_SUPPLIER_VIEW,
} from 'network/permissions';
import { WON_AGENT, WON_CUSTOMER, WON_SUPPLIER } from 'sales_hub/constants';
import { WithPermission, hasPermission, SelectPipeline, GlobalSearch } from '@shipmnts/pixel-hub';
import { AddressFormContent } from 'network';

type highlightType = { registered_name?: string };

const DepartmentOptions: SelectProps['options'] = DEPARTMENTS.sort().map((e) => {
  return {
    value: e,
    label: e,
  };
});

type Props = {
  form: FormInstance<any>;
  showContactCard?: boolean;
  isWonForm?: boolean;
  company_id?: string;
  isEdit?: boolean;
};

const LeadForm = (props: Props) => {
  const { form, showContactCard = true, isWonForm = false, company_id, isEdit = false } = props;
  const [duplicateCompanies, setDuplicateCompanies] = useState<any[]>([]);
  const [fetchingDuplicates, setFetchingDuplicates] = useState(false);
  const [fetchDuplicatesError, setFetchDuplicatesError] = useState<boolean | string>(false);

  const session = useSession();

  useEffect(() => {
    setDuplicateCompanies([]);
    setFetchingDuplicates(false);
    setFetchDuplicatesError(false);
  }, [props]);

  const uploadViewPermission = {
    OR: [
      { name: PERMISSION_CUSTOMER_VIEW, docType: 'Network::Company' },
      { name: PERMISSION_SUPPLIER_VIEW, docType: 'Network::Company' },
    ],
  };
  const uploadEditPermission = hasPermission(session.permissions, {
    OR: [
      { name: PERMISSION_CUSTOMER_CREATE_EDIT, docType: 'Network::Company' },
      { name: PERMISSION_SUPPLIER_CREATE_EDIT, docType: 'Network::Company' },
    ],
  });

  const fetchDuplicates = useCallback(
    async ({ company_name, domain }: { company_name?: string; domain?: string }) => {
      let dupCompanies = [];

      if (!company_name) {
        setFetchingDuplicates(false);
        setDuplicateCompanies([]);
        setFetchDuplicatesError(false);
        return;
      }
      setFetchingDuplicates(true);
      setFetchDuplicatesError(false);
      try {
        const response = await axios.get(COMPANY_SEARCH_URL, {
          params: {
            registered_name: company_name,
            domain: domain || '',
            limit: 3,
            min_score: 15,
          },
        });
        const { data } = response;
        dupCompanies = data.map(
          (searchRow: { _source: CompanyValue; highlight?: highlightType }) => ({
            company: searchRow._source,
            highlight: searchRow.highlight,
          })
        );
      } catch (e) {
        if (e instanceof Error) setFetchDuplicatesError(e.message);
      }
      setFetchingDuplicates(false);
      setDuplicateCompanies(dupCompanies);
    },
    []
  );

  let helpText = undefined;
  if (fetchingDuplicates) {
    helpText = (
      <span>
        <LoadingOutlined /> Checking for duplicates
      </span>
    );
  }
  if (fetchDuplicatesError) {
    helpText = (
      <span>
        Error while checking for duplicates.{' '}
        <Button
          onClick={() => fetchDuplicates(form.getFieldValue('registered_name'))}
          type="link"
          size="small"
        >
          Retry
        </Button>
      </span>
    );
  }

  return (
    <>
      <Card className="CreateLeadCard" title="Basic Details">
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Form.Item
              required
              rules={[{ required: true }, { max: 140, message: 'Max 140 characters allowed' }]}
              label="Company Name"
              name="registered_name"
              help={helpText}
              validateStatus={fetchDuplicatesError ? 'warning' : undefined}
            >
              <Input
                disabled={isEdit}
                placeholder="Enter Registered Company Name"
                onBlur={(e) => {
                  fetchDuplicates({
                    company_name: e.target.value,
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Domain URL" name="domain">
              <Input
                placeholder="Enter Domain URL of the Company"
                onBlur={(e) => {
                  fetchDuplicates({
                    company_name: form.getFieldValue('registered_name'),
                    domain: e.target.value,
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        {!fetchingDuplicates && duplicateCompanies && duplicateCompanies.length > 0 && (
          <Row gutter={[16, 0]}>
            <Col span={24}>
              <Alert
                type="warning"
                banner
                closable
                onClose={() => {
                  setDuplicateCompanies([]);
                }}
                message="Looks like a duplicate. Are you sure it isn't any one of the following companies?"
              />
              <List
                size="small"
                itemLayout="horizontal"
                dataSource={duplicateCompanies}
                renderItem={(dupComp) => (
                  <List.Item
                    onClick={() => {
                      return window.open(`/view/company/${dupComp.company.id}`, '_blank');
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <CompanyListItem
                      key={dupComp.company.id}
                      company={dupComp.company}
                      highlight={dupComp.highlight}
                    />
                  </List.Item>
                )}
              />
            </Col>
          </Row>
        )}
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Form.Item
              required
              rules={[{ required: true }]}
              label="Company Category"
              name="company_group"
            >
              <CompanyGroupSearch include_disabled={false} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item required rules={[{ required: true }]} label="Lead Owner" name="lead_owner">
              <GlobalSearch doc_type="Network::SalesPerson" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Form.Item
              required
              rules={[{ required: true }]}
              label="Country of Incorporation"
              name="country_of_incorporation"
            >
              <Select
                placeholder="Country where Company is Located"
                filterOption={(input, option): boolean => {
                  return `${option?.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                showSearch
                onSelect={(value: any) => {
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  //dummy on select
                }}
              >
                {countries.map(
                  (country: {
                    code: React.Key | null | undefined;
                    name:
                      | string
                      | number
                      | boolean
                      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                      | React.ReactFragment
                      | React.ReactPortal
                      | null
                      | undefined;
                  }) => {
                    return (
                      <Select.Option key={country.code} value={country.code}>
                        {country.name}
                      </Select.Option>
                    );
                  }
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Sales Partner / Referrer" name="sales_partner">
              <CompanySearch />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Form.Item
              required
              rules={[{ required: true }]}
              label="Company Type"
              name="entity_type"
            >
              <Select showSearch placeholder="Enter Company type">
                {[
                  COMPANY_TYPE_COMPANY,
                  COMPANY_TYPE_INDIVIDUAL,
                  COMPANY_TYPE_PRIVATE,
                  COMPANY_TYPE_PUBLIC,
                  COMPANY_TYPE_INC,
                  COMPANY_TYPE_LLP,
                  COMPANY_TYPE_PARTNERSHIP,
                ].map((type) => (
                  <Select.Option key={type} value={type}>
                    {type}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {isWonForm && (
            <Col span={12}>
              <Form.Item label="Won As" name="company_stage">
                <Radio.Group defaultValue={WON_CUSTOMER}>
                  <Radio.Button value={WON_CUSTOMER}>Customer</Radio.Button>
                  <Radio.Button value={WON_SUPPLIER}>Supplier</Radio.Button>
                  <Radio.Button value={WON_AGENT}>Agent</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          )}
        </Row>
        <SelectPipeline
          doctype={'Network::Company'}
          isRequired={true}
          form={form}
          resource_id={company_id || ''}
        />
      </Card>
      {showContactCard && (
        <Card className="CreateLeadCard" title="Contact Details">
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <Form.Item label="First Name" name="first_name">
                <Input placeholder="Enter first name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Last Name" name="last_name">
                <Input placeholder="Enter last name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <Form.Item label="Email" name="email">
                <Input placeholder="Enter email" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Mobile Number" name="mobile_number">
                <PhoneNumberInput defaultValueDialCode={'IN'} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <Form.Item label="Department" name="department">
                <Select
                  showSearch
                  placeholder="Enter department"
                  mode="multiple"
                  options={DepartmentOptions}
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      )}

      <Card className="CreateLeadCard" title="Address Details">
        <Form.Item noStyle dependencies={['country_of_incorporation']}>
          {({ getFieldValue }) => {
            return (
              <AddressFormContent
                fieldName={'address'}
                form={form}
                country_code={getFieldValue('country_of_incorporation')}
                is_lead={true}
                is_customer_or_supplier={false}
              />
            );
          }}
        </Form.Item>
      </Card>

      <Card className="CreateLeadCard" title="Add Remarks">
        <Form.Item label="Remarks" name="remarks">
          <Input.TextArea placeholder="Enter Remarks" />
        </Form.Item>
      </Card>
      {isWonForm && (
        <>
          {company_id && (
            <WithPermission permission={uploadViewPermission}>
              <Card id="attachments" title="Uploaded Documents" className="CreateLeadCard">
                <UploadedDocuments
                  sessionData={session}
                  parent_id={company_id}
                  parent_type={'company'}
                  docgen_url={process.env.DOCGEN_URL || ''}
                  uploadDisabled={!uploadEditPermission}
                  deleteDisabled={!uploadEditPermission}
                />
              </Card>
            </WithPermission>
          )}
        </>
      )}
    </>
  );
};

export default LeadForm;
