import { SessionDataValue } from 'common/models/SessionData';
import { TrackingEventValue } from 'common/models/TrackingEvent';
// import { FREIGHT_TYPE_AIR, FREIGHT_TYPE_OCEAN } from '~/modules/shipment/constants';
import { EventType } from '../../../types';
import { FormInstance, dayjs } from '@shipmnts/pixel-hub';
import {
  EVENT_DESTINATION_CFS_GATE_IN,
  EVENT_DESTINATION_CFS_GATE_OUT,
  EVENT_DESTINATION_EMPTY_DELIVERED_TO_CARRIER,
  EVENT_DESTINATION_FACTORY_GATE_OUT,
  EVENT_DESTUFFED,
  EVENT_EMPTY_PICKED_UP,
  EVENT_ON_CARRIAGE_ICD_GATE_OUT,
  EVENT_ORIGIN_FACTORY_GATE_OUT,
  EVENT_POD_GATE_OUT,
  EVENT_POL_GATE_IN,
  EVENT_PRE_CARRIAGE_ICD_GATE_OUT,
  EVENT_STUFFED,
  FIELD_TYPE_REFERENCE,
} from '../../../constants';

export const FREIGHT_TYPE_AIR = 'air';
export const FREIGHT_TYPE_OCEAN = 'ocean';
export type FieldType = {
  id?: string;
  name: string;
  label: string;
  type?: string;
  cellRenderer?: string;
  initialValue?: any;
  rules?: any;
  dependency?: {
    name: string;
    value?: any;
  };
  options?: {
    name: string;
    value: string;
  }[];
  colSpan?: number;
  defaultDisplay?: boolean; //default show dependent field
  searchProps?: any;
  fieldType?: string;
  onChange?: (val?: any, rf?: any) => void;
};

export const getEventInputTypes = (
  event: EventType,
  refData?: any,
  session?: SessionDataValue,
  form?: FormInstance,
  handleOnChange?: (name: string, value: any) => void,
  referenceField?: any
) => {
  let fields: FieldType[] = [];
  if (event.reference_type === 'Shipment::ShipmentCustomDetail') {
    return getShipmentCustomsEventsFields(event, refData, session);
  } else if (event.reference_type === 'Shipment::Shipment') {
    return getShipmentEventsFields(event, refData, session);
  }
  let referenceFields: FieldType[] = [];
  let actualDateConfig = {
    onChange: () => {
      console.log('');
    },
  };
  if (event.name) {
    const eventName = event.name;
    let extraFields: FieldType[] = [];
    switch (eventName) {
      case EVENT_EMPTY_PICKED_UP:
        extraFields = [
          {
            name: 'location',
            label: 'Final Pickup Location',
            initialValue: event.location
              ? { ...event.location, city: event.location?.city?.name }
              : null,
            type: 'location',
          },
          {
            name: 'shipper_seal_number',
            label: 'Shipper Seal Number',
            initialValue: event.event_data?.shipper_seal_number,
            colSpan: 12,
          },
          {
            name: 'vehicle_number',
            label: 'Vehicle Number',
            initialValue: event.event_data?.vehicle_number,
            colSpan: 12,
          },
          {
            name: 'driver_contact',
            label: 'Driver Contact',
            initialValue: event.event_data?.driver_contact,
            colSpan: 12,
          },
          {
            name: 'transporter_company_id',
            label: 'Transporter Name',
            initialValue: event.event_data?.transporter_company_id,
            colSpan: 12,
            type: 'company',
            searchProps: { is_vendor: true },
          },
        ];

        break;
      case EVENT_STUFFED:
        extraFields = [
          {
            name: 'location',
            label: 'Stuffing Location',
            initialValue: event.location,
            type: 'address',
          },
          {
            name: 'cargo_gross_weight',
            label: 'Gross Weight',
            type: 'number',
            initialValue: event.event_data?.cargo_gross_weight,
            colSpan: 8,
          },
          {
            name: 'cargo_net_weight',
            label: 'Net Weight',
            type: 'number',
            initialValue: event.event_data?.cargo_net_weight,
            colSpan: 8,
          },
          {
            name: 'verified_gross_mass',
            label: 'VGM Weight',
            type: 'number',
            initialValue: event.event_data?.verified_gross_mass,
            colSpan: 8,
          },
          {
            name: 'shipper_seal_number',
            label: 'Shipper Seal Number',
            initialValue: event.event_data?.shipper_seal_number,
            colSpan: 12,
          },
          {
            name: 'attachments',
            label: 'Attachments',
            type: 'upload',
          },
        ];

        break;
      case EVENT_ORIGIN_FACTORY_GATE_OUT:
        extraFields = [
          {
            name: 'location',
            label: 'Location',
            initialValue: event.location,
            type: 'address',
          },
          {
            name: 'vehicle_detention',
            label: 'Vehicle Detention',
            type: 'checkbox',
            initialValue: event.event_data?.vehicle_detention,
          },
          {
            name: 'detention_amount',
            label: 'Detention Amount',
            type: 'number',
            initialValue: event.event_data?.detention_amount,
          },
        ];

        break;
      case EVENT_PRE_CARRIAGE_ICD_GATE_OUT:
        extraFields = [
          {
            name: 'mode_of_transit',
            label: 'Mode of transit',
            type: 'select',
            options: [
              {
                name: 'Rail',
                value: 'rail',
              },
              {
                name: 'Road',
                value: 'road',
              },
            ],
            initialValue: event.event_data?.mode_of_transit,
          },
          {
            name: 'train_number',
            label: 'Train Number',
            dependency: {
              name: 'mode_of_transit',
              value: 'rail',
            },
            initialValue: event.event_data?.train_number,
            colSpan: 12,
          },
          {
            name: 'wagon_number',
            label: 'Wagon Number',
            dependency: {
              name: 'mode_of_transit',
              value: 'rail',
            },
            initialValue: event.event_data?.wagon_number,
            colSpan: 12,
          },
          {
            name: 'vehicle_number',
            label: 'Vehicle Number',
            dependency: {
              name: 'mode_of_transit',
              value: 'road',
            },
            initialValue: event.event_data?.vehicle_number,
            colSpan: 12,
          },
          {
            name: 'driver_contact',
            label: 'Driver Contact',
            dependency: {
              name: 'mode_of_transit',
              value: 'road',
            },
            initialValue: event.event_data?.driver_contact,
            colSpan: 12,
          },
        ];

        break;
      case EVENT_POL_GATE_IN:
        actualDateConfig = {
          onChange: () => {
            const actual_date = form?.getFieldValue('actual_date');
            const gate_close_date = referenceField.gate_close_date;
            if (actual_date && gate_close_date) {
              form?.setFieldsValue({
                late_gate_in_detention: actual_date.isAfter(dayjs.unix(gate_close_date)),
              });
              handleOnChange &&
                handleOnChange(
                  'late_gate_in_detention',
                  actual_date.isAfter(dayjs.unix(gate_close_date))
                );
            }
          },
        };
        referenceFields = [
          {
            name: 'gate_close_date',
            label: 'Gate Close cutoff',
            type: 'datetime',
            cellRenderer: 'cutoffRenderer',
            fieldType: FIELD_TYPE_REFERENCE,
          },
        ];
        extraFields = [
          {
            name: 'late_gate_in_detention',
            label: 'Late gate in detention',
            type: 'checkbox',
            initialValue: !!event.event_data?.late_gate_in_detention,
            colSpan: 12,
          },
          {
            name: 'late_gate_in_amount',
            label: 'Late gate in Amount',
            type: 'number',
            initialValue: event.event_data?.late_gate_in_amount,
            dependency: {
              name: 'late_gate_in_detention',
              value: true,
            },
            colSpan: 12,
            defaultDisplay: !!event.event_data?.late_gate_in_amount,
          },
        ];
        break;
      case EVENT_POD_GATE_OUT:
        actualDateConfig = {
          onChange: () => {
            const actual_date = form?.getFieldValue('actual_date');
            const lfd_at_pod = referenceField.lfd_at_pod;
            if (actual_date && lfd_at_pod) {
              form?.setFieldsValue({
                port_demurrage: actual_date.isAfter(dayjs.unix(lfd_at_pod)),
              });
              handleOnChange &&
                handleOnChange('port_demurrage', actual_date.isAfter(dayjs.unix(lfd_at_pod)));
            }
          },
        };
        referenceFields = [
          {
            name: 'lfd_at_pod',
            label: 'LFD at POD',
            type: 'datetime',
            cellRenderer: 'cutoffRenderer',
            fieldType: FIELD_TYPE_REFERENCE,
          },
        ];
        extraFields = [
          {
            name: 'port_demurrage',
            label: 'Port Demurrage',
            type: 'checkbox',
            colSpan: 12,
            initialValue: event.event_data?.port_demurrage && true,
          },
          {
            name: 'port_demurrage_amount',
            label: 'Port Demurrage Amount',
            type: 'number',
            dependency: {
              name: 'port_demurrage',
              value: true,
            },
            initialValue: event.event_data?.port_demurrage_amount,
          },
          {
            name: 'mode_of_transit',
            label: 'Mode of Transit',
            type: 'select',
            options: [
              {
                name: 'Rail',
                value: 'rail',
              },
              {
                name: 'Road',
                value: 'road',
              },
            ],
            initialValue: event.event_data?.mode_of_transit,
          },
          {
            name: 'train_number',
            label: 'Train Number',
            dependency: {
              name: 'mode_of_transit',
              value: 'rail',
            },
            initialValue: event.event_data?.train_number,
            colSpan: 12,
          },
          {
            name: 'wagon_number',
            label: 'Wagon Number',
            dependency: {
              name: 'mode_of_transit',
              value: 'rail',
            },
            initialValue: event.event_data?.wagon_number,
            colSpan: 12,
          },
          {
            name: 'vehicle_number',
            label: 'Vehicle Number',
            dependency: {
              name: 'mode_of_transit',
              value: 'road',
            },
            initialValue: event.event_data?.vehicle_number,
            colSpan: 12,
          },
          {
            name: 'driver_contact',
            label: 'Driver Contact',
            dependency: {
              name: 'mode_of_transit',
              value: 'road',
            },
            initialValue: event.event_data?.driver_contact,
            colSpan: 12,
          },
        ];
        break;
      case EVENT_DESTINATION_CFS_GATE_IN:
        extraFields = [
          {
            name: 'location',
            label: 'Final Pickup Location',
            rules: [
              {
                required: true,
                message: 'Please add location',
              },
            ],
            initialValue: event.location
              ? { ...event.location, city: event.location?.city?.name }
              : null,
            type: 'location',
          },
          {
            name: 'lfd',
            label: 'CFS Last Free Date',
            type: 'date',
            initialValue: event.event_data?.lfd,
            rules: [
              {
                required: true,
                message: 'Please add CFS last free date',
              },
            ],
          },
        ];

        break;
      case EVENT_DESTINATION_CFS_GATE_OUT:
        extraFields = [
          {
            name: 'cfs_demurrage',
            label: 'CFS Demurrage',
            type: 'checkbox',
            initialValue: event.event_data?.cfs_demurrage && true,
          },
          {
            name: 'cfs_demurrage_amount',
            label: 'Demurrage Amount',
            type: 'number',
            initialValue: event.event_data?.cfs_demurrage_amount,
            dependency: {
              name: 'cfs_demurrage',
              value: true,
            },
          },
          {
            name: 'vehicle_number',
            label: 'Vehicle Number',
            initialValue: event.event_data?.vehicle_number,
            colSpan: 12,
          },
          {
            name: 'driver_contact',
            label: 'Driver Contact',

            initialValue: event.event_data?.driver_contact,
            colSpan: 12,
          },
        ];

        break;
      case EVENT_ON_CARRIAGE_ICD_GATE_OUT:
        actualDateConfig = {
          onChange: () => {
            const actual_date = form?.getFieldValue('actual_date');
            const lfd_at_pocd = referenceField.lfd_at_pocd;
            if (actual_date && lfd_at_pocd) {
              form?.setFieldsValue({
                icd_demurrage: actual_date.isAfter(dayjs.unix(lfd_at_pocd)),
              });
              handleOnChange &&
                handleOnChange('icd_demurrage', actual_date.isAfter(dayjs.unix(lfd_at_pocd)));
            }
          },
        };
        referenceFields = [
          {
            name: 'lfd_at_pocd',
            label: 'LFD at POCD',
            type: 'datetime',
            cellRenderer: 'cutoffRenderer',
            fieldType: FIELD_TYPE_REFERENCE,
          },
        ];
        extraFields = [
          {
            name: 'icd_demurrage',
            label: 'ICD Demurrage',
            type: 'checkbox',
            initialValue: event.event_data?.icd_demurrage && true,
          },
          {
            name: 'icd_demurrage_amount',
            label: 'ICD Demurrage Amount',
            type: 'number',
            initialValue: event.event_data?.cfs_demurrage_amount,
            dependency: {
              name: 'icd_demurrage',
              value: true,
            },
          },
          {
            name: 'vehicle_number',
            label: 'Vehicle Number',
            initialValue: event.event_data?.vehicle_number,
            colSpan: 12,
          },
          {
            name: 'driver_contact',
            label: 'Driver Contact',

            initialValue: event.event_data?.driver_contact,
            colSpan: 12,
          },
        ];
        break;
      case EVENT_DESTINATION_EMPTY_DELIVERED_TO_CARRIER:
        actualDateConfig = {
          onChange: () => {
            const actual_date = form?.getFieldValue('actual_date');
            const lfd_at_empty_return = referenceField.lfd_at_empty_return;
            if (actual_date && lfd_at_empty_return) {
              form?.setFieldsValue({
                carrier_detention: actual_date.isAfter(dayjs.unix(lfd_at_empty_return)),
              });
              handleOnChange &&
                handleOnChange(
                  'carrier_detention',
                  actual_date.isAfter(dayjs.unix(lfd_at_empty_return))
                );
            }
          },
        };
        referenceFields = [
          {
            name: 'lfd_at_empty_return',
            label: 'LFD at Empty Return',
            type: 'datetime',
            cellRenderer: 'cutoffRenderer',
            fieldType: FIELD_TYPE_REFERENCE,
          },
        ];
        extraFields = [
          {
            name: 'carrier_detention',
            label: 'Carrier Detention',
            type: 'checkbox',
            initialValue: event.event_data?.carrier_detention && true,
          },
          {
            name: 'detention_amount',
            label: 'Detention Amount',
            type: 'number',
            initialValue: event.event_data?.detention_amount,
            dependency: {
              name: 'carrier_detention',
              value: true,
            },
          },
          {
            name: 'attachments',
            label: 'Attachments',
            type: 'upload',
          },
        ];
        break;
      case EVENT_DESTINATION_FACTORY_GATE_OUT:
        extraFields = [
          {
            name: 'location',
            label: 'location',
            initialValue: event.location,
            type: 'address',
          },
          {
            name: 'lr_receipt_number',
            label: 'LR Receipt Number',
            initialValue: event.event_data?.lr_receipt_number,
          },
          {
            name: 'attachments',
            label: 'Attachments',
            type: 'upload',
          },
          {
            name: 'doc_upload',
            label: 'Docs Upload',
            type: 'upload',
          },
        ];
        break;
      case EVENT_DESTUFFED:
        extraFields = [
          {
            name: 'location',
            label: 'Location',
            initialValue: event.location,
            type: 'address',
          },
          {
            name: 'attachments',
            label: 'Attachments',
            type: 'upload',
          },
        ];
        break;
      default:
        break;
    }

    fields = fields.concat(extraFields);
  }

  return { fields: fields, referenceFields: referenceFields, actualDateConfig: actualDateConfig };
};

// export const getEventIconFromEventName = (event_name: string) => {

// };

const getShipmentEventsFields = (event: EventType, refData?: any, session?: SessionDataValue) => {
  let fields: FieldType[] = [];
  let referenceFields: FieldType[] = [];
  const shipper = refData?.shipment_parties?.find((p: any) => p?.name === 'shipper');
  const consignee = refData?.shipment_parties?.find((p: any) => p?.name === 'consignee');
  const destination_agent = refData?.shipment_parties?.find(
    (p: any) => p?.name === 'destination_agent'
  );

  if (event.name) {
    // const tenant_country_code = session?.company_account?.default_company?.country_of_incorporation;

    const eventName = event.name;
    let extraFields: FieldType[] = [];
    switch (eventName) {
      case 'Booking Confirmed':
        extraFields = [];
        if (refData?.freight_type === FREIGHT_TYPE_OCEAN) {
          extraFields = [
            {
              name: 'booking_no',
              label: 'Booking #',
              initialValue: event.event_data?.booking_no,
            },
            {
              name: 'booking_validity',
              label: 'Booking Validity',
              type: 'date',
              initialValue: event.event_data?.booking_validity,
            },
            {
              name: 'si_cutoff',
              label: 'SI Cutoff',
              type: 'date',
              initialValue: event.event_data?.si_cutoff,
            },
            {
              name: 'gate_cutoff',
              label: 'Gate Cutoff',
              type: 'date',
              initialValue: event.event_data?.gate_cutoff,
            },
            {
              name: 'doc_cutoff',
              label: 'Doc Cutoff',
              type: 'date',
              initialValue: event.event_data?.doc_cutoff,
            },
          ];
        }
        break;
      case 'Movement Confirmed By Customer':
        referenceFields = [
          {
            name: 'movement_type',
            label: 'Movement Type Entered',
            type: 'string',
            cellRenderer: 'upperCaseRenderer',
            fieldType: FIELD_TYPE_REFERENCE,
          },
          {
            name: 'movement_location',
            label: 'Movement Location Entered',
            type: 'Location',
            cellRenderer: 'LocationRenderer',
            fieldType: FIELD_TYPE_REFERENCE,
          },
        ];
        extraFields = [
          {
            name: 'movement_type',
            label: 'Movement Type',
            initialValue: event.event_data?.movement_type,
            type: 'select',
            options: [
              {
                name: 'DPD',
                value: 'dpd',
              },
              {
                name: 'DPD-CFS',
                value: 'dpd-cfs',
              },
              {
                name: 'CFS',
                value: 'cfs',
              },
              {
                name: 'ICD',
                value: 'icd',
              },
            ],
          },
          {
            name: 'movement_location',
            label: 'Movement Location',
            type: 'location',
            dependency: {
              name: 'movement_type',
              value: ['dpd-cfs', 'cfs', 'icd'],
            },
            initialValue: event.event_data?.movement_location,
          },
        ];
        break;
      case 'Cargo Departed':
        extraFields = [];
        if (refData?.freight_type === FREIGHT_TYPE_AIR) {
          extraFields.push(
            ...[
              {
                name: 'chargeable_weight',
                label: 'Chargeable Weight',
                type: 'number',
                initialValue: event.event_data?.chargeable_weight,
              },
              {
                name: 'cargo_gross_weight',
                label: 'Gross Weight',
                type: 'number',
                initialValue: event.event_data?.cargo_gross_weight,
              },
              {
                name: 'gross_volume',
                label: 'Gross Volume',
                type: 'number',
                initialValue: event.event_data?.gross_volume,
              },
            ]
          );
        }
        break;
      case 'Cargo Received':
        extraFields = [
          {
            name: 'cargo_gross_weight',
            label: 'Gross Weight',
            type: 'number',
            initialValue: event.event_data?.cargo_gross_weight,
          },
          {
            name: 'gross_volume',
            label: 'Gross Volume',
            type: 'number',
            initialValue: event.event_data?.gross_volume,
          },
        ];
        break;
      case 'Pre Alert Received':
        extraFields = [
          {
            name: 'doc_upload',
            label: 'Docs Upload',
            type: 'upload',
          },
        ];
        break;
      case 'Agent CN/DN Received':
        extraFields = [
          {
            name: 'doc_upload',
            label: 'Docs Upload',
            type: 'upload',
          },
        ];
        break;
      case 'Manifest Docs Sent':
        extraFields = [
          {
            name: 'doc_upload',
            label: 'Docs Upload',
            type: 'upload',
          },
        ];
        break;
      case 'Manifest Filed':
        extraFields = [
          {
            name: 'igm_no',
            label: 'Manifest Filed #',
            initialValue: event.event_data?.igm_no,
          },
          {
            name: 'igm_date',
            label: 'Manifest Filed Date',
            type: 'date',
            initialValue: event.event_data?.igm_date,
          },
          {
            name: 'igm_location',
            label: 'Manifest Filed Location',
            type: 'location',
            initialValue: event.event_data?.igm_location,
          },
        ];
        if (refData?.port_of_discharge?.country?.code === 'IN') {
          extraFields.push(
            {
              name: 'line_number',
              label: 'Line #',
              initialValue: event.event_data?.line_number,
            },
            {
              name: 'subline_number',
              label: 'Sub Line #',
              initialValue: event.event_data?.subline_number,
            }
          );
        }
        break;
      case 'Arrived at POD':
        break;
      case 'SI Received From Customer':
        extraFields = [
          {
            name: 'attachments',
            label: 'Attachments',
            type: 'upload',
          },
        ];
        break;
      case 'Inland Manifest Filed':
        extraFields = [
          {
            name: 'icd_igm_no',
            label: 'Inland Manifest Filed #',
            initialValue: event.event_data?.icd_igm_no,
          },
          {
            name: 'icd_igm_date',
            label: 'Inland Manifest Filed Date',
            type: 'date',
            initialValue: event.event_data?.icd_igm_date,
          },
          {
            name: 'icd_igm_location',
            label: 'Inland Manifest Filed Location',
            type: 'location',
            initialValue: event.event_data?.icd_igm_location,
          },
        ];
        if (refData?.place_of_carrier_delivery?.country?.code === 'IN') {
          extraFields.push(
            {
              name: 'line_number',
              label: 'Line #',
              initialValue: event.event_data?.line_number,
            },
            {
              name: 'subline_number',
              label: 'Sub Line #',
              initialValue: event.event_data?.subline_number,
            }
          );
        }
        break;
      case 'Arrived at PCD':
        break;
      case 'Carrier Released':
        extraFields = [
          {
            name: 'do_no',
            label: 'DO #',
            initialValue: event.event_data?.do_no,
          },
          {
            name: 'do_validity_date',
            label: 'DO Validity Date',
            type: 'date',
            initialValue: event.event_data?.do_validity_date,
          },
          {
            name: 'doc_upload',
            label: 'Docs Upload',
            type: 'upload',
          },
        ];
        break;
      case 'Payment on Hold':
        extraFields = [
          {
            name: 'reason_for_hold',
            label: 'Reason For Hold',
            type: 'select',
            options: [
              {
                name: 'Discrepancy',
                value: 'Discrepancy',
              },
              {
                name: 'Payment not received from consignee',
                value: 'Payment not received from consignee',
              },
              { name: 'Freight Rate Mismatch', value: 'Freight Rate Mismatch' },
              {
                name: 'Wrong Charges Billed',
                value: 'Wrong Charges Billed',
              },
            ],
            initialValue: event.event_data?.reason_for_hold,
          },
        ];
        break;
      case 'Invoice Received':
        extraFields = [
          {
            name: 'invoice_number',
            label: 'Invoice #',
            initialValue: event.event_data?.invoice_number,
          },
          {
            name: 'invoice_date',
            label: 'Invoice Date',
            type: 'date',
            initialValue: event.event_data?.invoice_date,
          },
          {
            name: 'invoice_amount',
            label: 'Invoice Amount',
            initialValue: event.event_data?.invoice_amount,
          },
          {
            name: 'doc_upload',
            label: 'Docs Upload',
            type: 'upload',
          },
        ];
        break;
      case 'ISF Filed':
        extraFields = [
          {
            name: 'isf_filing_no',
            label: 'ISF Filing #',
            initialValue: event.event_data?.isf_filing_no,
          },
          {
            name: 'isf_filed_by',
            label: 'ISF Filed by',
            initialValue: event?.event_data?.isf_filed_by,
            type: 'select',
            options: [
              { value: 'self', name: 'Self' },
              {
                value: 'shipper',
                name: `Shipper ${
                  shipper && shipper.party_company
                    ? '(' + shipper.party_company?.registered_name + ')'
                    : ''
                }`,
              },
              {
                value: 'consignee',
                name: `Consignee ${
                  consignee && consignee.party_company
                    ? '(' + consignee.party_company?.registered_name + ')'
                    : ''
                }`,
              },
              {
                value: 'destination_agent',
                name: `Destination Agent ${
                  destination_agent && destination_agent.party_company
                    ? '(' + destination_agent.party_company?.registered_name + ')'
                    : ''
                }`,
              },
            ],
          },
        ];
        break;
      default:
        break;
    }
    fields = fields.concat(extraFields);
  }

  return {
    fields: fields,
    referenceFields: referenceFields,
    actualDateConfig: {
      onChange: () => {
        console.log('');
      },
    },
  };
};

const getShipmentCustomsEventsFields = (
  event: EventType,
  refData?: any,
  session?: SessionDataValue
) => {
  let fields: FieldType[] = [];
  const actualDateConfig = {
    onChange: () => {
      console.log('');
    },
    initialValue: null,
  };
  if (event.name) {
    const tenant_country_code = session?.company_account?.default_company?.country_of_incorporation;
    const eventName = event.name;
    let extraFields: FieldType[] = [];
    switch (eventName) {
      case 'Customs Entry Filed':
        extraFields = [
          {
            name: 'doc_upload',
            label: 'Docs Upload',
            type: 'upload',
          },
        ];
        break;
      case 'Examination Done':
        extraFields = [
          {
            name: 'exam_fees',
            label: 'Exam Fees',
            type: 'number',
            initialValue: event.event_data?.exam_fees,
          },
          {
            name: 'open_examination',
            label: 'Open Examination',
            type: 'checkbox',
            initialValue: event.event_data?.open_examination,
          },
          {
            name: 'examination_type',
            label: 'Type Of Examination',
            initialValue: event.event_data?.examination_type,
            type: 'select',
            options: [
              {
                name: 'X-Ray',
                value: 'X-Ray',
              },
              {
                name: 'Tail Gate',
                value: 'Tail Gate',
              },
              {
                name: 'Intensive',
                value: 'Intensive',
              },
            ],
          },
          {
            name: 'examination_hold_from',
            label: 'Examination Hold From',
            initialValue: event.event_data?.examination_hold_from,
            type: 'select',
            options: [
              {
                name: 'Manifest Hold',
                value: 'Manifest Hold',
              },
              {
                name: 'FDA',
                value: 'FDA',
              },
              {
                name: 'USDA',
                value: 'USDA',
              },
              {
                name: 'CPSC',
                value: 'CPSC',
              },
              {
                name: 'FCC',
                value: 'FCC',
              },
              {
                name: 'CET',
                value: 'CET',
              },
            ],
          },
          {
            name: 'examination_reason',
            label: 'Examination Reason',
            initialValue: event.event_data?.examination_reason,
          },
          {
            name: 'doc_upload',
            label: 'Docs Upload',
            type: 'upload',
          },
        ];
        break;
      case 'LEO Done':
        extraFields = [
          {
            name: 'pdf_copy',
            label: 'PDF Copy',
            type: 'upload',
          },
        ];
        break;
      case 'Duty Paid':
        extraFields = [
          {
            name: 'duty_paid_amount',
            label: 'Duty Paid Amount',
            type: 'number',
            initialValue: event.event_data?.duty_paid_amount,
          },
          {
            name: 'duty_paid_date',
            label: 'Duty Paid Date',
            type: 'date',
            initialValue: event.event_data?.duty_paid_date,
          },
          {
            name: 'challan_no',
            label: 'Challan #',
            initialValue: event.event_data?.challan_no,
          },
          {
            name: 'challan_date',
            label: 'Challan Date',
            type: 'date',
            initialValue: event.event_data?.challan_date,
          },
          {
            name: 'doc_upload',
            label: 'Docs Upload',
            type: 'upload',
          },
        ];
        break;
      case 'Customs Released':
        extraFields = [
          {
            name: 'customs_release_number',
            label: 'Customs Release #',
            initialValue: event.event_data?.customs_release_number,
          },
        ];
        if (tenant_country_code === 'IN') {
          extraFields.push({
            name: 'doc_upload',
            label: 'Customs Released Copy',
            type: 'upload',
          });
        }
        break;
      case 'EGM Filed':
        extraFields = [
          {
            name: 'egm_no',
            label: 'EGM #',
            initialValue: event.event_data?.egm_no,
          },
          {
            name: 'error_reporting',
            label: 'Error Reporting',
            initialValue: event.event_data?.error_reporting,
          },
        ];
        break;
      case 'Drawback Processed':
        extraFields = [
          {
            name: 'drawback_scroll_number',
            label: 'Drawback Scroll #',
            initialValue: event.event_data?.drawback_scroll_number,
          },
          {
            name: 'error_reporting',
            label: 'Error Reporting',
            initialValue: event.event_data?.error_reporting,
          },
        ];
        break;
      case 'ICD EGM Filed':
        extraFields = [
          {
            name: 'icd_egm_no',
            label: 'ICD EGM #',
            initialValue: event.event_data?.icd_egm_no,
          },
          {
            name: 'error_reporting',
            label: 'Error Reporting',
            initialValue: event.event_data?.error_reporting,
          },
        ];
        break;
      case 'Import Docs Submitted in Bank':
        extraFields = [
          {
            name: 'bank_ref_no',
            label: 'Bank Ref #',
            initialValue: event.event_data?.bank_ref_no,
          },
          {
            name: 'bill_id',
            label: 'Bill ID',
            initialValue: event.event_data?.bill_id,
          },
        ];
        break;
      case 'Export Docs Submitted in Bank':
        extraFields = [
          {
            name: 'bank_ref_no',
            label: 'Bank Ref #',
            initialValue: event.event_data?.bank_ref_no,
          },
          {
            name: 'bill_id',
            label: 'Bill ID',
            initialValue: event.event_data?.bill_id,
          },
          {
            name: 'document_transfer',
            label: 'Document Transfer',
            type: 'checkbox',
            initialValue: event.event_data?.document_transfer,
          },
          {
            name: 'courier_provider_name',
            label: 'Courier Provider Name',
            initialValue: event.event_data?.courier_provider_name,
          },
          {
            name: 'courier_tracking_no',
            label: 'Courier Tracking #',
            initialValue: event.event_data?.courier_tracking_no,
          },
          {
            name: 'doc_upload',
            label: 'Docs Upload',
            type: 'upload',
          },
        ];
        break;
      case 'Checklist Created/Received':
        extraFields = [
          {
            name: 'doc_upload',
            label: 'Docs Upload',
            type: 'upload',
          },
        ];
        break;
      case 'Docs Sent to CHA':
        extraFields = [
          {
            name: 'doc_upload',
            label: 'Docs Upload',
            type: 'upload',
          },
        ];
        break;
      case 'BRC Closed':
        extraFields = [
          {
            name: 'brc_no',
            label: 'BRC #',
            initialValue: event.event_data?.brc_no,
          },
          {
            name: 'currency',
            label: 'Currency',
            initialValue: event.event_data?.currency,
          },
          {
            name: 'amount_realised',
            label: 'Amount Realised',
            initialValue: event.event_data?.amount_realised,
          },
          {
            name: 'doc_upload',
            label: 'Docs Upload',
            type: 'upload',
          },
        ];
        break;
      case 'AES Created Received':
        extraFields = [
          {
            name: 'doc_upload',
            label: 'Docs Upload',
            type: 'upload',
          },
        ];
        break;
      case 'AES Approved':
        extraFields = [
          {
            name: 'doc_upload',
            label: 'Docs Upload',
            type: 'upload',
          },
        ];
        break;
      case '7501 Created Received':
        extraFields = [
          {
            name: 'doc_upload',
            label: 'Docs Upload',
            type: 'upload',
          },
        ];
        break;
      case '7501 Approved':
        extraFields = [
          {
            name: 'doc_upload',
            label: 'Docs Upload',
            type: 'upload',
          },
        ];
        break;
      case 'Checklist Approved':
        extraFields = [
          {
            name: 'doc_upload',
            label: 'Docs Upload',
            type: 'upload',
          },
        ];
        break;
      case 'OOC Done':
        extraFields = [
          {
            name: 'doc_upload',
            label: 'Docs Upload',
            type: 'upload',
          },
        ];
        break;
      case 'Bill of Entry Filed':
        extraFields = [
          {
            name: 'custom_document_number',
            label: 'BE #',
            type: 'string',
            initialValue: refData?.custom_document_number,
          },
        ];
        actualDateConfig.initialValue = refData?.custom_document_date;
        break;
      case 'Shipping Bill Filed':
        extraFields = [
          {
            name: 'custom_document_number',
            label: 'SB #',
            type: 'string',
            initialValue: refData?.custom_document_number,
          },
        ];
        actualDateConfig.initialValue = refData?.custom_document_date;
        break;

      default:
        break;
    }
    fields = fields.concat(extraFields);
  }
  return {
    fields: fields,
    referenceFields: [],
    actualDateConfig: actualDateConfig,
  };
};

export function getLastEvent(tracking_events: TrackingEventValue[], last_action_status?: string) {
  const last: any = tracking_events.find((e) => e.name === last_action_status);
  const event = last
    ? { ...last, current_location: last?.location?.name }
    : { name: last_action_status };
  return event;
}
