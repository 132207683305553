import { types } from 'mobx-state-tree';

const OceanShipmentBooking = types.model({
  id: types.maybe(types.maybeNull(types.identifier)),
  shipping_line_service_type: types.maybe(types.maybeNull(types.string)),
  shipping_line_booking_number: types.maybe(types.maybeNull(types.string)),
  cy_cutoff_date: types.maybe(types.maybeNull(types.number)),
  si_cutoff_date: types.maybe(types.maybeNull(types.number)),
  vgm_cutoff_date: types.maybe(types.maybeNull(types.number)),
  booking_validity_date: types.maybe(types.maybeNull(types.number)),
  shipping_line_booking_date: types.maybe(types.maybeNull(types.number)),
  documents_cutoff_date: types.maybe(types.maybeNull(types.number)),
  form13_cutoff_date: types.maybe(types.maybeNull(types.number)),
});

export default OceanShipmentBooking;
