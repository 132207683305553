import React from 'react';
import { PrintPreviewDocType } from '@shipmnts/pixel-hub';
import { ShipmentValue } from 'operations/models/Shipment';

interface ShareShipmentProps {
  docTypeId: string;
  docType: string;
  doc: ShipmentValue;
  onClose?: () => void;
}

export default function ShareShipment(props: ShareShipmentProps) {
  const { docType, docTypeId, doc, onClose } = props;

  if (!doc) return <></>;

  return <PrintPreviewDocType docType={docType} resource_id={docTypeId} onClose={onClose} />;
}
