import React from 'react';
import { useCompanyView } from '../CompanyView';
import { ActivityFeed } from '@shipmnts/pixel-hub';

export const ActivityTab = () => {
  const { company } = useCompanyView();
  if (!company) return <></>;
  return (
    <div
      style={{
        width: '100%',
        marginInline: 'auto',
        marginBottom: '2em',
      }}
    >
      <ActivityFeed
        resource_id={company.id}
        resource_type={'Network::Company'}
        resource={{ ...company, displayName: company?.registered_name }}
        extraClassname={'tab'}
      />
    </div>
  );
};
