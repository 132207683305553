import React, { Fragment, useState, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import {
  Timeline,
  Typography,
  Button,
  Tooltip,
  Drawer,
  Empty,
  TimelineItemProps,
} from '@shipmnts/pixel-hub';
import { CheckOutlined, CheckCircleTwoTone, dayjs } from '@shipmnts/pixel-hub';
import { startCase as _startCase } from 'lodash';
import { UserAccountValue } from 'operations/models/UserAccount';
import MarkAmendmentDoneForm from 'operations/modules/reports/components/OTOReports/MarkAmendmentDoneForm';
import { GET_OTO_CHANGE_REQUESTS } from 'operations/modules/booking/graphql/oceanTransportOrder';
import { errorMessageHandlerGraphQL } from 'common';

import { STATUS_EXPIRED } from 'operations/modules/reports/constants';
import containerRequest from 'operations/icons/containerRequest.svg';
import cargos from 'operations/icons/cargos.svg';
import carrierDetails from 'operations/icons/carrierDetails.svg';
import routingDetails from 'operations/icons/routingDetails.svg';
import Spinner from 'src/components/Spinner';

const { Title } = Typography;

interface ChangeRequestValue {
  display_string: { [key: string]: string[] };
  requested_at: number;
  requested_by: UserAccountValue;
  resolved_at?: number;
  resolved_by?: UserAccountValue;
}

const timelineTagMapping: { [key: string]: { label: string; img: string } } = {
  container_requests: { label: 'Container Details', img: containerRequest },
  cargos: { label: 'Cargo Details', img: cargos },
  ocean_transport_order: { label: 'Ocean Booking', img: carrierDetails },
  main_carriage_legs: { label: 'Main Carriage', img: routingDetails },
  pre_carriage_legs: { label: 'Pre Carriage', img: routingDetails },
  on_carriage_legs: { label: 'On Carriage', img: routingDetails },
};

const ChangeRequestsView = React.memo(function ChangeRequestsView(props: {
  ocean_transport_order_id: string;
  onClose?: () => void;
  onSuccess?: () => void;
}): JSX.Element {
  const { ocean_transport_order_id, onClose, onSuccess } = props;
  const { loading, error, data, refetch } = useQuery(GET_OTO_CHANGE_REQUESTS, {
    variables: { id: ocean_transport_order_id },
  });
  const [amendmentDoneDrawerVisible, setAmendmentDoneDrawerVisible] = useState<boolean>(false);

  const onCloseDrawer = useCallback(() => {
    setAmendmentDoneDrawerVisible(false);
  }, []);

  const onAmendmentDone = useCallback(() => {
    if (onSuccess) onSuccess();
  }, [onSuccess]);

  if (loading && !data?.ocean_transport_order?.change_requests) {
    return <Spinner style={{ width: '100%' }} tip="Loading ..." />;
  }
  if (error) {
    return errorMessageHandlerGraphQL(error, refetch);
  }

  if (data && data.ocean_transport_order.change_requests.length === 0) {
    return (
      <Drawer width={'50%'} title="Amendment Activity" onClose={onClose} open={true}>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={<span>No amendments to show</span>}
        />
      </Drawer>
    );
  }

  const isOTOExpired = data?.ocean_transport_order?.status === STATUS_EXPIRED;

  const timelineItems:
    | TimelineItemProps[]
    | {
        dot: React.JSX.Element | undefined;
        label?: string | undefined;
        children: React.JSX.Element;
      }[]
    | undefined = [];
  (data.ocean_transport_order.change_requests || []).forEach(
    (change_request: ChangeRequestValue, index: number) => {
      return Object.keys(change_request.display_string).forEach((display_string_key, index) => {
        const requested_by = change_request?.requested_by;
        const resolved_by = change_request?.resolved_by;
        let dot;
        if (change_request?.resolved_at) {
          dot = <CheckCircleTwoTone twoToneColor="#35986B" />;
        } else if (timelineTagMapping[display_string_key].img) {
          dot = <img src={timelineTagMapping[display_string_key].img} alt="logo" />;
        }

        return timelineItems.push({
          dot: dot,
          children: (
            <>
              <Title level={5}>
                {timelineTagMapping[display_string_key]?.label || _startCase(display_string_key)}
              </Title>
              <p>{change_request.display_string[display_string_key].join(', ')}</p>
              <p className="font-weight-600">
                by {requested_by?.first_name || ''} {requested_by?.last_name || ''}
              </p>
              <p className="text-color-secondary">
                {dayjs.unix(change_request.requested_at).format('DD MMMM YYYY, HH:mm')}
              </p>
              {change_request.resolved_at && (
                <p className="font-color-success font-weight-600">
                  <CheckOutlined style={{ color: '#35986B' }} />
                  {'  '}Resolved by {resolved_by?.first_name || ''} {resolved_by?.last_name || ''}
                </p>
              )}
            </>
          ),
        });
      });
    }
  );

  return (
    <Drawer width={'50%'} onClose={onClose} title="Amendment Activity" open={true}>
      <Timeline className="timeline" items={timelineItems} />
      {data?.ocean_transport_order?.is_amendment_pending && (
        <div className="primary-footer">
          {onClose && <Button onClick={onClose}>Close</Button>}
          <Tooltip
            title={
              isOTOExpired
                ? 'Resolving amendments while booking is in expired stage is not allowed, please revalidate the booking first.'
                : ''
            }
          >
            <Button
              type="primary"
              style={{ marginLeft: '16px' }}
              ghost
              disabled={isOTOExpired}
              onClick={() => setAmendmentDoneDrawerVisible(true)}
            >
              Resolve Amendments
            </Button>
          </Tooltip>
        </div>
      )}
      {amendmentDoneDrawerVisible && (
        <Drawer title={'Confirm Amendment'} width="60%" open={true} onClose={onCloseDrawer}>
          <MarkAmendmentDoneForm
            ocean_transport_order_id={ocean_transport_order_id}
            onClose={onCloseDrawer}
            onSuccess={onAmendmentDone}
          />
        </Drawer>
      )}
    </Drawer>
  );
});

export default ChangeRequestsView;
