export const canTrack = (subscription: any) => {
  const subscriptionData = JSON.parse(subscription?.subscription_data || '{}');
  if (subscription.plan_type === 'paid') {
    return true;
  }
  if (subscriptionData['available_credit'] > 0) {
    return true;
  }
  return false;
};
