import { Checkbox, Col, Collapse, Form, FormInstance, Row } from '@shipmnts/pixel-hub';
import { AddressCompanySearch } from 'common';
import React, { MutableRefObject } from 'react';
import { GridOptions } from '@ag-grid-community/core';
import {
  ROW_GUTTER,
  TRADE_TYPE_EXPORT,
  TRADE_TYPE_IMPORT,
} from 'operations/modules/shipment/constants';
import { startCase } from 'lodash';
import { PartyValue } from 'common/components/CustomInputs/AddressCompanySearch/AddressCompanySearch';

interface PartyDetailsProps {
  form?: FormInstance;
  onChange?: (data: any[]) => void;
  autoFillParty?: (data: any[], index: any) => void;
  value?: any[];
  invoiceStatus?: string;
  setInvoiceStatus?: (value: string) => void;
  editable: boolean;
  addDeleteButton?: boolean;
  mandatoryFields?: string[];
  gridRef: MutableRefObject<GridOptions | undefined>;
  partyEnable?: boolean;
  tradeType?: string;
  isClearance?: boolean;
}

const isSameParty = (party1?: PartyValue | null, party2?: PartyValue | null) => {
  if (party1 && party2) return party1?.party_company?.id === party2?.party_company?.id;
  else return false;
};

export default function PartyDetails(props: PartyDetailsProps) {
  const { mandatoryFields, form, isClearance = false } = props;
  const additional_parties = ['notify_party_1', 'notify_party_2', 'notify_party_3'];
  const Panel = Collapse.Panel;

  return (
    <div>
      <Row gutter={ROW_GUTTER}>
        <Col span={6} style={{ paddingBottom: '10px' }}>
          <Form.Item noStyle dependencies={['trade_type']}>
            {({ getFieldValue }) => {
              return (
                <>
                  <Form.Item
                    rules={[
                      {
                        required:
                          getFieldValue('trade_type') === TRADE_TYPE_EXPORT ||
                          mandatoryFields?.includes('shipper'),
                      },
                    ]}
                    name={['party', 'shipper']}
                    label={!isClearance ? 'Shipper' : 'Exporter / Shipper'}
                    style={{ marginBottom: '10px' }}
                  >
                    <AddressCompanySearch
                      addressSearchProps={{
                        showAddBranchAction: true,
                      }}
                    />
                  </Form.Item>
                  {getFieldValue('trade_type') === TRADE_TYPE_EXPORT && (
                    <Form.Item noStyle valuePropName="checked" name="shipper_same_as_customer">
                      <Checkbox
                        onChange={(e) => {
                          const customer = form?.getFieldValue('customer');
                          const shipper = form?.getFieldValue(['party', 'shipper']);
                          if (!e.target.checked) {
                            if (isSameParty(shipper, customer))
                              form?.setFieldValue(['party', 'shipper'], null);
                          } else {
                            form?.setFieldValue(['party', 'shipper'], {
                              ...customer,
                              id: shipper?.id,
                              name: 'shipper',
                            });
                          }
                        }}
                      >
                        Same As Customer
                      </Checkbox>
                    </Form.Item>
                  )}
                </>
              );
            }}
          </Form.Item>
        </Col>
        <Col span={6} style={{ paddingBottom: '10px' }}>
          <Form.Item noStyle dependencies={['trade_type']}>
            {({ getFieldValue }) => {
              return (
                <>
                  <Form.Item
                    rules={[
                      {
                        required:
                          getFieldValue('trade_type') === TRADE_TYPE_IMPORT ||
                          mandatoryFields?.includes('consignee'),
                      },
                    ]}
                    name={['party', 'consignee']}
                    label={!isClearance ? 'Consignee' : 'Importer / Consignee'}
                    style={{ marginBottom: '10px' }}
                  >
                    <AddressCompanySearch
                      addressSearchProps={{
                        showAddBranchAction: true,
                      }}
                    />
                  </Form.Item>
                  {getFieldValue('trade_type') === TRADE_TYPE_IMPORT && (
                    <Form.Item noStyle valuePropName="checked" name="consignee_same_as_customer">
                      <Checkbox
                        onChange={(e) => {
                          const customer = form?.getFieldValue('customer');
                          const consignee = form?.getFieldValue(['party', 'consignee']);
                          if (!e.target.checked) {
                            if (isSameParty(consignee, customer))
                              form?.setFieldValue(['party', 'consignee'], null);
                          } else {
                            form?.setFieldValue(['party', 'consignee'], {
                              ...customer,
                              id: consignee?.id,
                              name: 'consignee',
                            });
                          }
                        }}
                      >
                        Same As Customer
                      </Checkbox>
                    </Form.Item>
                  )}
                </>
              );
            }}
          </Form.Item>
        </Col>
        {isClearance && (
          <>
            <Col span={6} style={{ paddingBottom: '10px' }}>
              <Form.Item name={['party', 'seller']} label="Seller" style={{ marginBottom: '10px' }}>
                <AddressCompanySearch
                  addressSearchProps={{
                    showAddBranchAction: true,
                  }}
                />
              </Form.Item>
              <Form.Item noStyle valuePropName="checked" name="seller_same_as_shipper">
                <Checkbox
                  onChange={(e) => {
                    const seller = form?.getFieldValue(['party', 'seller']);
                    const shipper = form?.getFieldValue(['party', 'shipper']);
                    if (!e.target.checked) {
                      if (isSameParty(shipper, seller))
                        form?.setFieldValue(['party', 'seller'], null);
                    } else {
                      form?.setFieldValue(['party', 'seller'], {
                        ...shipper,
                        id: seller?.id,
                        name: 'seller',
                      });
                    }
                  }}
                >
                  Same As Exporter / Shipper
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={6} style={{ paddingBottom: '10px' }}>
              <Form.Item name={['party', 'buyer']} label="Buyer" style={{ marginBottom: '10px' }}>
                <AddressCompanySearch
                  addressSearchProps={{
                    showAddBranchAction: true,
                  }}
                />
              </Form.Item>
              <Form.Item noStyle valuePropName="checked" name="buyer_same_as_consignee">
                <Checkbox
                  onChange={(e) => {
                    const buyer = form?.getFieldValue(['party', 'buyer']);
                    const consignee = form?.getFieldValue(['party', 'consignee']);
                    if (!e.target.checked) {
                      if (isSameParty(consignee, buyer))
                        form?.setFieldValue(['party', 'buyer'], null);
                    } else {
                      form?.setFieldValue(['party', 'buyer'], {
                        ...consignee,
                        name: 'buyer',
                        id: buyer?.id,
                      });
                    }
                  }}
                >
                  Same As Importer / Consignee
                </Checkbox>
              </Form.Item>
            </Col>
          </>
        )}
        {!isClearance && (
          <>
            <Col span={6}>
              <Form.Item
                rules={[{ required: mandatoryFields?.includes('origin_agent') }]}
                name={['party', 'origin_agent']}
                label="Origin Agent"
              >
                <AddressCompanySearch
                  addressSearchProps={{
                    showAddBranchAction: true,
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                rules={[{ required: mandatoryFields?.includes('destination_agent') }]}
                name={['party', 'destination_agent']}
                label="Destination Agent"
              >
                <AddressCompanySearch
                  addressSearchProps={{
                    showAddBranchAction: true,
                  }}
                />
              </Form.Item>
            </Col>
          </>
        )}
      </Row>
      {additional_parties && (
        <Collapse>
          <Panel
            header={<div style={{ color: '#0a4fca' }}>Additional Parties</div>}
            key="1"
            forceRender
          >
            <Row gutter={ROW_GUTTER}>
              {additional_parties.map((party, index) => {
                return (
                  <Col key={index} span={8}>
                    <Form.Item name={['party', party]} label={startCase(party)}>
                      <AddressCompanySearch
                        addressSearchProps={{
                          showAddBranchAction: true,
                        }}
                      />
                    </Form.Item>
                  </Col>
                );
              })}
            </Row>
          </Panel>
        </Collapse>
      )}
    </div>
  );
}
