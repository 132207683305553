import { gql } from '@apollo/client';

export const CREATE_DRIVER = gql`
  mutation create_driver($driver: DriverInputType!) {
    create_driver(driver: $driver) {
      id
      driver_name
      driver_contact_number
      driver_license_number
    }
  }
`;

export const UPDATE_DRIVER = gql`
  mutation update_driver($id: ID!, $driver: DriverInputType!) {
    update_driver(id: $id, driver: $driver) {
      id
      driver_name
      driver_contact_number
      driver_license_number
    }
  }
`;

export const GET_DRIVER = gql`
  query get_driver($id: ID!) {
    get_driver(id: $id) {
      id
      driver_name
      driver_contact_number
      driver_license_number
    }
  }
`;
