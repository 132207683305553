import React from 'react';
import { Card, Checkbox, Typography, Row, Col, Form, Select } from '@shipmnts/pixel-hub';
import { startCase as _startCase } from 'lodash';
import { AddressCompanySearch } from 'common';
import { CreateShipmentType } from 'operations/modules/reports/components/ContainerReports/ContainerActions/ContainerActionDrawer';
import {
  DOCUMENT_TYPE_HOUSE,
  DOCUMENT_TYPE_MASTER,
  FREIGHT_TYPE_OCEAN,
  FreightType,
  ROW_GUTTER,
  SHIPMENT_TYPE_CONSOL,
  SHIPMENT_TYPE_HOUSE,
} from '../constants';
import { FormInstance } from '@shipmnts/pixel-hub';
const { Text } = Typography;

export interface ShipmentServiceRenderType {
  name: string[];
  render?: boolean;
  component?: React.FC<{ service_name: string[]; shipmentType: CreateShipmentType }>;
}

export const freightForwardingService: ShipmentServiceRenderType[] = [
  {
    name: ['freight_forwarding'],
    component: function emptyService(): JSX.Element {
      return <span />;
    },
  },
];

export const default_services: ShipmentServiceRenderType[] = [
  ...freightForwardingService,
  {
    name: ['origin', 'clearance'],
  },
  {
    name: ['origin', 'transport'],
  },
  {
    name: ['origin', 'palletization'],
  },
  {
    name: ['origin', 'fumigation'],
  },
  {
    name: ['destination', 'clearance'],
  },
  {
    name: ['destination', 'transport'],
  },
  {
    name: ['cargo_insurance'],
  },
  {
    name: ['empty_container_insurance'],
  },
];

const ShipmentServiceDetails = React.memo(function ShipmentServiceDetails(props: {
  services?: ShipmentServiceRenderType[];
  shipmentType: CreateShipmentType;
  colSpan?: number;
  freightType?: FreightType;
  hideIsfFiledBy?: boolean;
  form?: FormInstance;
}): JSX.Element {
  const { shipmentType, colSpan, freightType, hideIsfFiledBy, form } = props;
  const docType = shipmentType !== SHIPMENT_TYPE_HOUSE ? DOCUMENT_TYPE_MASTER : DOCUMENT_TYPE_HOUSE;
  const shipper = Form?.useWatch([docType, 'party', 'shipper'], form);
  const consignee = Form?.useWatch([docType, 'party', 'consignee'], form);
  const destination_agent = Form?.useWatch([docType, 'party', 'destination_agent'], form);
  return (
    <Row gutter={ROW_GUTTER}>
      {(props.services || default_services).map((service: ShipmentServiceRenderType) => {
        const field_name = ['services', ...service.name];
        if (service.render === false) {
          return (
            <Form.Item key={service.name.join('_')} noStyle name={field_name}>
              <span />
            </Form.Item>
          );
        }
        return (
          <Col key={service.name.join('_')} span={colSpan || 8}>
            <Card
              key={`card_${service.name.join('_')}`}
              title={
                <Form.Item name={field_name} valuePropName="checked" style={{ marginBottom: 0 }}>
                  <Checkbox>
                    <Text style={{ marginLeft: '10px', fontSize: '16px', fontWeight: 500 }}>
                      {_startCase(service.name.join('_'))}
                    </Text>
                  </Checkbox>
                </Form.Item>
              }
              className="custom-card margin-top-md"
            >
              <Form.Item noStyle dependencies={[field_name]}>
                {({ getFieldValue }) => {
                  const enabled = getFieldValue(field_name);
                  if (!enabled) return <span />;
                  const ServiceComponent = service?.component || GenericServiceComponent;
                  return (
                    <ServiceComponent service_name={service.name} shipmentType={shipmentType} />
                  );
                }}
              </Form.Item>
            </Card>
          </Col>
        );
      })}
      {!hideIsfFiledBy &&
        freightType === FREIGHT_TYPE_OCEAN &&
        shipmentType !== SHIPMENT_TYPE_CONSOL && (
          <Col span={colSpan || 8}>
            <Card className="custom-card margin-top-md">
              <Form.Item name={'isf_filed_by'} style={{ marginBottom: 0 }} label="ISF Filed By">
                <Select
                  options={[
                    { key: 'self', value: 'self', label: 'Self' },
                    {
                      key: 'shipper',
                      value: 'shipper',
                      label: `Shipper ${
                        shipper && shipper.party_company
                          ? '(' + shipper.party_company?.registered_name + ')'
                          : ''
                      }`,
                    },
                    {
                      key: 'consignee',
                      value: 'consignee',
                      label: `Consignee ${
                        consignee && consignee.party_company
                          ? '(' + consignee.party_company?.registered_name + ')'
                          : ''
                      }`,
                    },
                    {
                      key: 'destination_agent',
                      value: 'destination_agent',
                      label: `Destination Agent ${
                        destination_agent && destination_agent.party_company
                          ? '(' + destination_agent.party_company?.registered_name + ')'
                          : ''
                      }`,
                    },
                  ]}
                ></Select>
              </Form.Item>
            </Card>
          </Col>
        )}
    </Row>
  );
});

export const GenericServiceComponent = function GenericServiceComponent(props: {
  service_name: string[];
  shipmentType: CreateShipmentType;
}): JSX.Element {
  const { service_name } = props;
  return (
    <Col span={24}>
      <Form.Item
        label={`${_startCase(service_name.join('_'))} Agent`}
        name={['party', `${service_name.join('_')}_agent`]}
      >
        <AddressCompanySearch />
      </Form.Item>
    </Col>
  );
};

export default ShipmentServiceDetails;
