import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useShipmentDetail } from '../ShipmentDetailLayout';
import { gql } from '@apollo/client';
import { FSU_CODES_MAPPING } from 'operations/constants';
import FsuRoutingDetails from './FsuRoutingDetails';
import {
  getDateTimeFromUnix,
  BaseTable,
  Button,
  Spin,
  Card,
  Switch,
  Tooltip,
} from '@shipmnts/pixel-hub';
import { Column, ColumnGroup } from 'operations/models/Report';

export type FlightDataType = {
  flight_number: string;
  arrival_date?: number;
  departure_date?: number;
  arrival_date_type?: string;
  departure_date_type?: string;
  no_of_pcs_departed?: string;
  no_of_pcs_arrived?: string;
  departure_location?: string;
  arrival_location?: string;
  gross_weight?: string;
  no_of_pcs?: string;
  arrival_location_iata_code?: string;
  departure_location_iata_code?: string;
};

type FsuMessageDataType = {
  id: string;
  fsu_code?: string;
  flight_number?: string;
  arrival_date?: number;
  departure_date?: number;
  event_date?: number;
  arrival_date_type?: string;
  departure_date_type?: string;
  event_date_type?: string;
  arrival_location?: string;
  departure_location?: string;
  no_of_pcs?: string;
  gross_weight?: string;
  created_at: number;
  arrival_location_iata_code?: string;
  departure_location_iata_code?: string;
};

export type LocationDataType = {
  location?: string;
  location_display?: string;
  arrival_flights?: string[];
  departure_flights?: string[];
  next_locations?: string[];
};

type FsuDataType = {
  flight_data: FlightDataType[];
  fsu_messages_data: FsuMessageDataType[];
  location_data: LocationDataType[];
};

export type FsuDataResponseType = {
  eawb_get_fsu_data: FsuDataType;
};

const FETCH_FSU_DATA = gql`
  query eawb_get_fsu_data($document_id: ID!) {
    eawb_get_fsu_data(document_id: $document_id) {
      flight_data {
        flight_number
        arrival_date
        departure_date
        arrival_date_type
        departure_date_type
        no_of_pcs_departed
        no_of_pcs_arrived
        departure_location
        arrival_location
        gross_weight
        no_of_pcs
        arrival_location_iata_code
        departure_location_iata_code
      }
      fsu_messages_data {
        id
        fsu_code
        flight_number
        arrival_date
        departure_date
        event_date
        arrival_date_type
        departure_date_type
        event_date_type
        arrival_location
        departure_location
        no_of_pcs
        gross_weight
        created_at
        arrival_location_iata_code
        departure_location_iata_code
      }
      location_data {
        location
        location_display
        arrival_flights
        departure_flights
        next_locations
      }
    }
  }
`;

const FsuTrackingTab = () => {
  const { shipment } = useShipmentDetail();
  const [checked, setChecked] = useState(false);
  const master_document = shipment?.shipment_documents?.find(
    (d: any) => d.document_type === 'master'
  );
  const { error, loading, data, refetch } = useQuery(FETCH_FSU_DATA, {
    variables: { document_id: master_document?.id },
  });
  const fsu_messages_data = data?.eawb_get_fsu_data?.fsu_messages_data.map((message: any) => ({
    ...message,
  }));

  const fsu_summarized_data = (fsu_messages_data || []).filter((message: any) =>
    ['RCS', 'DEP', 'ARR', 'RCF'].includes(message.fsu_code)
  );

  const columns: (Column | ColumnGroup)[] = [
    {
      headerName: 'Code',
      field: 'fsu_code',
      colId: 'fsu_code',
      filter: 'agTextColumnFilter',
      minWidth: 30,
      pinned: 'left',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
    },
    {
      headerName: 'Description',
      field: 'description',
      colId: 'description',
      minWidth: 300,
      cellRenderer: function descriptionRenderer(params: any) {
        const description = params.data.fsu_code ? FSU_CODES_MAPPING[params.data.fsu_code] : '';
        return <>{description}</>;
      },
    },
    {
      headerName: 'Flight #',
      field: 'flight_number',
      colId: 'flight_number',
      minWidth: 100,
    },
    {
      headerName: 'Departure Location',
      field: 'departure_location_iata_code',
      colId: 'departure_location_iata_code',
      minWidth: 100,
      cellRenderer: function departureTooltipRenderer(params: any) {
        const departureLocation = params.data.departure_location_iata_code;
        const tooltipText = params.data.departure_location;
        return (
          <Tooltip title={tooltipText}>
            <div>{departureLocation}</div>
          </Tooltip>
        );
      },
    },
    {
      headerName: 'Arrival Location',
      field: 'arrival_location_iata_code',
      colId: 'arrival_location_iata_code',
      minWidth: 100,
      cellRenderer: function arrivalTooltipRenderer(params: any) {
        const arrivalLocation = params.data.arrival_location_iata_code;
        const tooltipText = params.data.arrival_location;
        return (
          <Tooltip title={tooltipText}>
            <div>{arrivalLocation}</div>
          </Tooltip>
        );
      },
    },
    {
      headerName: 'No of Pcs',
      field: 'no_of_pcs',
      colId: 'no_of_pcs',
      minWidth: 50,
    },
    {
      headerName: 'Gross Wt (Kgs)',
      field: 'gross_weight',
      colId: 'gross_weight',
      minWidth: 50,
    },
    {
      headerName: 'Departure Date',
      field: 'departure_date',
      colId: 'departure_date',
      minWidth: 50,
      cellStyle: { color: 'grey' },
      cellRenderer: function departureDateRenderer(params: any) {
        const departureDate = params.data.departure_date
          ? params.data.departure_date_type === 'A'
            ? ' (A) ' + getDateTimeFromUnix(params.data.departure_date)
            : ' (E) ' + getDateTimeFromUnix(params.data.departure_date)
          : '';
        return <>{departureDate}</>;
      },
    },
    {
      headerName: 'Arrival Date',
      field: 'arrival_date',
      colId: 'arrival_date',
      minWidth: 50,
      cellStyle: { color: 'grey' },
      cellRenderer: function arrivalDateRenderer(params: any) {
        const arrivalDate = params.data.arrival_date
          ? params.data.arrival_date_type === 'A'
            ? ' (A) ' + getDateTimeFromUnix(params.data.arrival_date)
            : ' (E) ' + getDateTimeFromUnix(params.data.arrival_date)
          : '';
        return <>{arrivalDate}</>;
      },
    },
    {
      headerName: 'Event Date',
      field: 'event_date',
      colId: 'event_date',
      hide: true,
      minWidth: 50,
      cellStyle: { color: 'grey' },
      cellRenderer: function departureDateRenderer(params: any) {
        const eventDate = params.data.event_date ? getDateTimeFromUnix(params.data.event_date) : '';
        return <>{eventDate}</>;
      },
    },
    {
      headerName: 'Message Received at',
      field: 'message_received_at',
      colId: 'message_received_at',
      minWidth: 50,
      hide: true,
      cellStyle: { color: 'grey' },
      cellRenderer: function messageReceivedRenderer(params: any) {
        const messageReceivedAt = params.data.created_at
          ? getDateTimeFromUnix(params.data.created_at)
          : '';
        return <>{messageReceivedAt}</>;
      },
    },
  ];

  if (!data && error) {
    return (
      <div className="align-center">
        Unable to fetch data. &nbsp;
        <Button type="link" onClick={() => refetch()}>
          Retry
        </Button>
      </div>
    );
  }
  if (loading) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Spin size="small" tip="Fetching Data" />;
      </div>
    );
  }
  const getRowData = () => {
    return checked ? fsu_summarized_data : fsu_messages_data;
  };
  return (
    <>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: '0 0 25%', padding: '8px' }}>
          <FsuRoutingDetails data={data} />
        </div>
        <div style={{ flex: '0 0 75%', padding: '8px' }}>
          <Card
            title={
              <div
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                <div>Messages</div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: '5px' }}>Summarized FSU</span>
                  <Switch
                    checked={checked}
                    onChange={(e) => setChecked(e)}
                    style={{ transform: 'scale(0.7)' }}
                  />
                </div>
              </div>
            }
            bodyStyle={{ padding: 0 }}
            headStyle={{ borderBottom: 'none' }}
          >
            <BaseTable
              reportName="fsu_table"
              rowData={getRowData()}
              columns={columns}
              reportConfig={{
                groupDefaultExpanded: 1,
              }}
            />
          </Card>
        </div>
      </div>
    </>
  );
};

export default FsuTrackingTab;
