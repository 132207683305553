import { types } from 'mobx-state-tree';

const Activity = types.model({
  text: types.maybe(types.maybeNull(types.string)),
  sent_by: types.maybe(
    types.maybeNull(
      types.model({
        id: types.maybe(types.maybeNull(types.number)),
        last_name: types.maybe(types.maybeNull(types.string)),
        first_name: types.maybe(types.maybeNull(types.string)),
      })
    )
  ),
});

export default Activity;
