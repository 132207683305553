// https://gist.github.com/buren/8d7c831822bc474164cc37dd522c2d1d
const allowedOrigins = [
  'http://localhost:8080',
  'http://localhost:3000',
  'http://localhost:8081',
  'https://session.shipmnts.com',
  'https://testing.shipmnts.com',
  'https://booking-staging.shipmnts.com',
  'https://app.shipmnts.com',
  'https://booking.shipmnts.com',
  'https://beta.shipmnts.com',
];

export class CrossOriginLocalStorage {
  constructor(
    currentWindow,
    iframe,
    onMessage = () => {
      return null;
    }
  ) {
    this.allowedOrigins = allowedOrigins;

    let childWindow;
    // some browser (don't remember which one) throw exception when you try to access
    // contentWindow for the first time, it works when you do that second time
    try {
      childWindow = iframe.contentWindow;
    } catch (e) {
      childWindow = iframe.contentWindow;
    }

    currentWindow.onmessage = (event) => {
      if (!this.isAllowedOrigin(event.origin)) {
        return;
      }
      let data = event.data;
      try {
        data = JSON.parse(event.data);
      } catch (error) {
        // console.error(error);
      }

      return onMessage(data, event);
    };

    this.isAllowedOrigin = (origin) => {
      return this.allowedOrigins.includes(origin);
    };

    this.getData = (key) => {
      const messageData = {
        key: key,
        method: 'get',
      };
      this.postMessage(messageData);
    };

    this.setData = (key, data) => {
      const messageData = {
        key: key,
        method: 'set',
        data: data,
      };
      this.postMessage(messageData);
    };

    this.removeData = (key) => {
      const messageData = {
        key: key,
        method: 'remove',
      };
      this.postMessage(messageData);
    };

    this.startLogoutPoll = () => {
      const messageData = {
        key: 'startLogoutPoll',
        method: 'startLogoutPoll',
      };
      this.postMessage(messageData);
    };

    this.postMessage = (messageData) => {
      childWindow.postMessage(JSON.stringify(messageData), '*');
    };
  }
}
