import React from 'react';
import DescriptionItem from './DescriptionItem';
import { ItemValue } from 'common/models/Item';
interface BarCodeProps {
  product: ItemValue;
}
export default function BarCode(props: BarCodeProps) {
  const product = props?.product;
  const data = [
    { label: 'ISBN', content: product?.isbn_number || '-' },
    { label: 'EAN', content: product?.ean_number || '-' },
    { label: 'UPC', content: product?.upc_number || '-' },
    { label: 'MPN', content: product?.mpn_number || '-' },
  ];
  return (
    <div style={{ padding: '15px', borderBottom: '1px solid #9FB1BD' }}>
      <DescriptionItem title="Bar Codes" data={data} />
    </div>
  );
}
