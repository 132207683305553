/**
 * This component will render Shipment Number/ Container Number etc
 * and will open tracker drawer on number click
 * This component will also render document icon with soc count which will
 * open documents drawer
 */
import React, { useState } from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';

import { useSession } from 'common';
import {
  Badge,
  Button,
  Drawer,
  RenderTrackingOnLink,
  useTrackerContext,
  UploadedDocuments,
  UploadParentType,
} from '@shipmnts/pixel-hub';
import { PaperClipOutlined } from '@shipmnts/pixel-hub';

import { startCase } from 'lodash';

const referenceTypeToParentTypeMap: {
  [key: string]: UploadParentType;
} = {
  'Shipment::ShipmentCustomDetail': 'shipment_custom_detail',
  'Shipment::Shipment': 'shipment',
  'Shipment::ShipmentContainer': 'shipment_container',
  'Shipment::ShipmentManifest': 'shipment_manifest',
};

const DocTypeNumberRenderer = (props: ICellRendererParams) => {
  const id = props.data?.id;
  const doc_count = props.data?.doc_count;
  const headerText = props.value;
  // States

  const [visibleUploadDocument, setVisibleUploadDocument] = useState(false);

  const { referenceType } = useTrackerContext();
  const sessionData = useSession();

  if (props.node.rowPinned) return <span></span>;

  const parentType: UploadParentType = referenceTypeToParentTypeMap[referenceType];
  let isTrackingAllowed = true;
  if (parentType === 'shipment_manifest') {
    if (headerText !== 'IGM' && headerText !== 'ICD IGM') isTrackingAllowed = false;
  }
  return (
    <div className="table-primary-renderer">
      <div style={{ textTransform: 'capitalize' }}>{headerText}</div>

      {id && (
        <div className="table-primary-icons">
          {parentType !== 'shipment_manifest' && (
            <div style={{ marginLeft: '3px' }} onClick={() => setVisibleUploadDocument(true)}>
              <Badge size="small" count={doc_count}>
                <Button icon={<PaperClipOutlined />} size="small" />
              </Badge>
            </div>
          )}
          {isTrackingAllowed && (
            <div style={{ marginLeft: '10px' }}>
              <RenderTrackingOnLink id={id} />
            </div>
          )}
        </div>
      )}

      {visibleUploadDocument && sessionData.company_account?.default_company && (
        <Drawer
          open={visibleUploadDocument}
          onClose={() => setVisibleUploadDocument(false)}
          title={'Upload Documents For ' + startCase(parentType)}
          width={680}
          footer={
            <div style={{ textAlign: 'right' }}>
              <Button type="primary" onClick={() => setVisibleUploadDocument(false)} size="small">
                Close
              </Button>
            </div>
          }
        >
          <div>
            <UploadedDocuments
              sessionData={sessionData}
              parent_id={id}
              parent_type={parentType}
              docgen_url={process.env.DOCGEN_URL || ''}
            />
          </div>
        </Drawer>
      )}
    </div>
  );
};

export default DocTypeNumberRenderer;
