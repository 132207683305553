/**
 * Component for editing container details
 * Opens container drawer with form
 */
import React from 'react';
import { useQuery } from '@apollo/client';
import { Button, Result, Drawer, message } from '@shipmnts/pixel-hub';
import { FETCH_CONTAINER_FOR_EDIT } from 'operations/modules/booking/graphql/shipmentContainer';
import EditContainerForm from './EditContainerForm';

interface EditContainerDetailsProps {
  container_id: string;
  onClose: () => void;
  onSuccess: () => void;
}

export default function EditContainerDetails(props: EditContainerDetailsProps): JSX.Element {
  const { container_id, onClose, onSuccess } = props;

  const { loading, error, data, refetch } = useQuery(FETCH_CONTAINER_FOR_EDIT, {
    variables: { id: container_id },
    fetchPolicy: 'no-cache',
  });

  React.useEffect(() => {
    if (loading) message.loading('loading', 1);
  }, [loading]);

  if (error) {
    const title = 'Something went wrong while fetching the container.',
      subTitle = error ? error.message : '',
      button = (
        <Button
          onClick={() => {
            if (refetch) {
              refetch();
            }
          }}
          type="primary"
        >
          Retry
        </Button>
      );
    return (
      <Drawer key="edit_container_details" width={'80%'} visible onClose={onClose}>
        <Result status={500} title={title} subTitle={subTitle} extra={button} />
      </Drawer>
    );
  }

  return (
    <>
      {data && (
        <EditContainerForm
          container={data.fetch_basic_container_details_by_id}
          onClose={onClose}
          onSuccess={onSuccess}
        />
      )}
    </>
  );
}
