import React, { useState } from 'react';
import { Row, Col, Select, InputNumber, Radio, Form } from '@shipmnts/pixel-hub';
import { FormInstance, WithPermission } from '@shipmnts/pixel-hub';
import { ERPNextConfigData } from 'network/utils/ErpNextConfigDataWrapper';
import { PERMISSION_CREDIT_CONTROL_WRITE } from 'network/permissions';
import {
  CREDIT_CONTROL_TYPE_AUTOMATIC,
  CREDIT_CONTROL_TYPE_MANUAL,
  CREDIT_CONTROL_TYPE_NO_CREDIT,
  CREDIT_STATUSES_FOR_MANUAL,
  CREDIT_STATUSES_FOR_AUTOMATIC,
  CREDIT_STATUSES_FOR_NO_CREDIT,
} from 'network/constants';
import { RadioOptionProps } from 'network/commonTypeDefs';
import { COMPANY_STATUS_APPROVED, COMPANY_STATUS_NO_CREDIT } from 'network/models/Company';

const { Option } = Select;

export interface CreditControlDetailsFormProps {
  form: FormInstance;
  erpnextConfigData: ERPNextConfigData;
}

const ROW_GUTTER = 32;

const CreditControlDetailsForm = React.memo(function CreditControlDetailsForm(
  props: CreditControlDetailsFormProps
): JSX.Element {
  const { form, erpnextConfigData } = props;
  const [showCreditFields, setShowCreditFields] = useState(
    form.getFieldValue(`status`) === CREDIT_CONTROL_TYPE_NO_CREDIT
  );
  return (
    <>
      <WithPermission
        permission={{ name: PERMISSION_CREDIT_CONTROL_WRITE, docType: 'Network::Company' }}
      >
        <Row gutter={ROW_GUTTER}>
          <Col span={12} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
            <Form.Item
              required
              rules={[{ required: true }]}
              name="receivable_credit_control_type"
              label="Credit Control Type"
            >
              <Radio.Group
                onChange={(e) => {
                  e.target.value === CREDIT_CONTROL_TYPE_NO_CREDIT
                    ? setShowCreditFields(true)
                    : setShowCreditFields(false);
                }}
              >
                <Radio
                  onChange={(e) => {
                    if (e.target.checked) form.setFieldsValue({ status: COMPANY_STATUS_APPROVED });
                  }}
                  value={CREDIT_CONTROL_TYPE_AUTOMATIC}
                >
                  Automatic
                </Radio>
                <Radio
                  value={CREDIT_CONTROL_TYPE_MANUAL}
                  onChange={(e) => {
                    if (e.target.checked) {
                      form.setFieldsValue({ status: undefined });
                    }
                  }}
                >
                  Manual
                </Radio>
                <Radio
                  onChange={(e) => {
                    if (e.target.checked) {
                      form.setFieldsValue({
                        status: COMPANY_STATUS_NO_CREDIT,
                        credit_overdue_limit: 0,
                        receivable_credit_limit: 0,
                        receivable_payment_terms: null,
                      });
                    }
                  }}
                  value={CREDIT_CONTROL_TYPE_NO_CREDIT}
                >
                  No Credit
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={12} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
            <Form.Item noStyle dependencies={[`receivable_credit_control_type`]}>
              {() => {
                const receivable_credit_control_type = form.getFieldValue(
                  `receivable_credit_control_type`
                );
                let status_options: RadioOptionProps[] = CREDIT_STATUSES_FOR_MANUAL;
                if (CREDIT_CONTROL_TYPE_NO_CREDIT === receivable_credit_control_type) {
                  status_options = CREDIT_STATUSES_FOR_NO_CREDIT;
                } else if (CREDIT_CONTROL_TYPE_AUTOMATIC === receivable_credit_control_type) {
                  status_options = CREDIT_STATUSES_FOR_AUTOMATIC;
                }
                return (
                  <Form.Item
                    name="status"
                    label="Credit Risk Profile"
                    help="In case of Automatic, system will set the value on the basis of outstanding and credit limit. In case of Manual, you have to set it."
                    required
                    rules={[{ required: true }]}
                  >
                    <Radio.Group
                      disabled={receivable_credit_control_type === CREDIT_CONTROL_TYPE_AUTOMATIC}
                    >
                      {status_options.map((option: RadioOptionProps, index: number) => (
                        <Radio key={index} value={option.key}>
                          {option.name}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
      </WithPermission>
      <Row gutter={ROW_GUTTER} hidden={showCreditFields}>
        <Col span={12} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <Form.Item
            name={`receivable_payment_terms`}
            label={`Default Receivable Payment Terms Template`}
            help="Credit Days"
          >
            <Select allowClear showSearch>
              {(erpnextConfigData?.payment_terms_templates || []).map((pt) => (
                <Option key={pt.name} value={pt.name}>
                  {pt.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <Form.Item name="receivable_credit_limit" label="Receivable Credit Limit">
            <InputNumber style={{ width: '100%' }} min={0} />
          </Form.Item>

          <Form.Item name="credit_overdue_limit" label="Credit Overdue Limit">
            <InputNumber style={{ width: '100%' }} min={0} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
});

export default CreditControlDetailsForm;
