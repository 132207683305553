import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Card, Drawer, message, Form, DrawerFooter } from '@shipmnts/pixel-hub';
import { BookingRequestValue } from 'operations/models/BookingRequest';
import { BookingRequestBasicInfo } from '../booking/components/BookingRequestView/BookingRequestBasicInfo';
import BookingRoutingDetails from '../booking/components/BookingOrderForm/BookingRoutingDetails';
import { UPDATE_OCEAN_TRANSPORT_ORDER } from '../booking/graphql/oceanTransportOrder';
import { omit as _omit } from 'lodash';
import { LOAD_TYPE_FCL } from 'operations/baseConstants';
import { RoutingDetailsValue, RoutingNodesHashValue } from 'operations/components/RoutingDetails';
import {
  getRoutingDetailsOnVoyageReset,
  checkVoyageResetOnValuesChange,
} from 'operations/modules/booking/helpers/RoutingDetailsHelper';
import { getFirstMainHopIndex, RoutingLegValue } from 'operations/models/RoutingLeg';
import { useMutation } from '@apollo/client';
import { cast, getSnapshot } from 'mobx-state-tree';
import { RoutingNodeValue } from 'operations/models/RoutingNode';
import { convertDatesTounix } from '@shipmnts/pixel-hub';
import { CustomIcon } from '@shipmnts/pixel-hub';

interface UpdateRoutingDetailsProps {
  onClose: () => void;
  onSuccess: () => void;
  bookingRequest: BookingRequestValue;
}

type DestroyInterface<T> = T & {
  _destroy?: boolean;
};

export default function UpdateRoutingDetails(props: UpdateRoutingDetailsProps) {
  const { bookingRequest, onClose } = props;
  const oceanTransportOrder = bookingRequest.ocean_transport_orders[0];
  const routingLegs = oceanTransportOrder?.routing_legs;
  const [form] = Form.useForm();
  const routing_legs_ref = useRef<{ runValidation: () => boolean }>();

  const [updateOto, { data, loading, error }] = useMutation(UPDATE_OCEAN_TRANSPORT_ORDER);

  useEffect(() => {
    if (error) {
      message.error(error.message);
    } else if (data?.update_ocean_transport_order) {
      message.success('Routing Updated Successfully');
      onClose();
    }
  }, [data, loading, error, onClose]);

  const [previousValue, setPreviousValue] = useState<{
    isFirstContainerTypeReefer?: boolean;
    mainCarriage?: {
      locationId?: string;
      vessel?: string;
    };
    carrierId?: string;
  }>({
    mainCarriage: routingLegs[getFirstMainHopIndex(routingLegs)]
      ? {
          locationId: routingLegs[getFirstMainHopIndex(routingLegs)].origin?.location?.id,
          vessel: routingLegs[getFirstMainHopIndex(routingLegs)].vessel?.imo,
        }
      : {},
  });

  const resetMainHopVoyageNumber = useCallback(
    (routing_details: RoutingDetailsValue, voyage_schedule_id?: string) => {
      form.setFieldsValue({
        routing_details,
        voyage_schedule_id,
      });
    },
    [form]
  );

  const routing_nodes: RoutingNodesHashValue = {};
  let routing_legs = oceanTransportOrder?.routing_legs
    ? getSnapshot<RoutingLegValue[]>(cast(oceanTransportOrder.routing_legs)).slice()
    : [];

  routing_legs = routing_legs.map((rl) => {
    if (rl?.origin?.id) routing_nodes[rl.origin.id] = rl.origin;
    if (rl?.destination?.id) routing_nodes[rl.destination.id] = rl.destination;
    return {
      ...rl,
      origin: undefined,
      destination: undefined,
      origin_id: rl?.origin?.id,
      destination_id: rl?.destination?.id,
    };
  });

  const omitFields = ['wagon_number'];

  routing_legs = routing_legs.map((rl: any) =>
    _omit<RoutingLegValue>(rl, omitFields)
  ) as RoutingLegValue[];

  return (
    <Drawer
      title={
        <b>
          <CustomIcon icon="EditRoadIcon" /> Update Routing Details
        </b>
      }
      width={'60%'}
      onClose={onClose}
      visible
      footer={<DrawerFooter onSave={form.submit} saveText="Update" onClose={onClose} />}
    >
      <Form
        style={{ paddingBottom: '30px' }}
        form={form}
        scrollToFirstError
        layout="vertical"
        onFinish={(values: {
          routing_details?: RoutingDetailsValue;
          voyage_schedule_id?: string;
        }) => {
          const old_routing_legs = oceanTransportOrder.routing_legs || [];
          const old_routing_nodes = old_routing_legs.reduce((acc: any, rl: any) => {
            rl.origin && acc.push(rl.origin);
            rl.destination && acc.push(rl.destination);
            return acc;
          }, [] as Array<RoutingNodeValue>);
          const new_routing_legs: Array<RoutingLegValue> = [
            ...(values.routing_details?.routing_legs || []),
          ];
          const new_routing_nodes: Array<RoutingNodeValue> = Object.values({
            ...(values.routing_details?.routing_nodes || {}),
          });

          old_routing_legs.forEach((rl: any) => {
            if (!new_routing_legs.find((l: RoutingLegValue) => l.id === rl.id)) {
              new_routing_legs.push({ ...rl, _destroy: true } as DestroyInterface<RoutingLegValue>);
            }
          });
          old_routing_nodes.forEach((rn: any) => {
            if (!new_routing_nodes.find((n: RoutingNodeValue) => n.id === rn.id)) {
              new_routing_nodes.push({
                ...rn,
                _destroy: true,
              } as DestroyInterface<RoutingNodeValue>);
            }
          });

          updateOto({
            variables: {
              ocean_transport_order: {
                id: oceanTransportOrder.id,
                routing_legs: new_routing_legs.map((rl) => {
                  const { vessel, origin, destination, global_carrier, ...restRL } = rl;
                  return {
                    ...restRL,
                    global_carrier_id: global_carrier?.id,
                    ...convertDatesTounix(rl, [
                      'estimated_time_of_departure',
                      'estimated_time_of_arrival',
                    ]),
                    vessel_id: vessel?.imo,
                  };
                }),
                routing_nodes: new_routing_nodes.map((rn: any) => {
                  const { location, company, address, terminal, ...restRN } = rn;
                  return { ...restRN, location_id: location?.id, terminal_id: terminal?.id };
                }),
                voyage_schedule_id: values.voyage_schedule_id,
              },
            },
          });
        }}
        initialValues={{
          routing_details: { routing_legs, routing_nodes },
        }}
        onValuesChange={(changedValues, allValues) => {
          const { shouldReset, currentValue } = checkVoyageResetOnValuesChange({
            changedValues,
            allValues,
            previousValue,
          });
          setPreviousValue(currentValue);
          if (shouldReset) {
            getRoutingDetailsOnVoyageReset(allValues.routing_details, resetMainHopVoyageNumber);
          }
        }}
      >
        <BookingRequestBasicInfo bookingRequest={bookingRequest} />
        <Card title="Routing Details">
          <Form.Item noStyle name="voyage_schedule_id">
            <div />
          </Form.Item>
          <Form.Item
            name="routing_details"
            rules={[
              {
                validator: (rule, value) => {
                  if (!routing_legs_ref?.current?.runValidation()) {
                    return Promise.resolve();
                  }
                  return Promise.reject();
                },
              },
            ]}
            noStyle
          >
            <BookingRoutingDetails
              validateVesselVoyage={
                bookingRequest.load_type === LOAD_TYPE_FCL && !bookingRequest.isFullyUnallocated
              }
              globalCarrierId={oceanTransportOrder?.global_carrier?.id}
              isReeferContainer={oceanTransportOrder?.container_requests?.[0]?.is_reefer_container}
              bookingType={oceanTransportOrder?.booking_type}
              onlyTranshipmentHopEditable={false}
              ref={routing_legs_ref}
              allowVoyageScheduleSearch={!!oceanTransportOrder.voyage_schedule_id}
            />
          </Form.Item>
        </Card>
      </Form>
    </Drawer>
  );
}
