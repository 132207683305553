import { gql } from '@apollo/client';

export const GET_SHIPMENT_MANIFEST = gql`
  query get_shipment_manifest($id: ID!) {
    shipment_manifest(id: $id) {
      id
      trade_type
      document_type
      tracking_statuses
      country
      document_number
      shipment {
        job_number
      }
    }
  }
`;

export const UPDATE_MANIFEST_DETAILS = gql`
  mutation update_shipment_manifest($id: ID!, $shipment_manifest: ShipmentManifestInputType!) {
    update_shipment_manifest(id: $id, shipment_manifest: $shipment_manifest) {
      id
      trade_type
    }
  }
`;

export const GET_INDIAN_IGM_TRACKING_REQUEST = gql`
  query IndianCustomsTrackingRequestByCustomDetailID($shipment_manifest_id: ID!) {
    indian_customs_igm_tracking_request_by_manifest_id(
      shipment_manifest_id: $shipment_manifest_id
    ) {
      id
      status
      shipment_manifest_id
      last_event
      last_tracked_at
    }
  }
`;

export const REFRESH_IMG_TRACKING = gql`
  mutation refreshTracking($tracking_request_id: ID!) {
    indian_custom_igm_tracker_refresh_tracking(tracking_request_id: $tracking_request_id) {
      id
      status
      shipment_manifest_id
      last_event
      last_tracked_at
    }
  }
`;
