import React from 'react';
import { Switch, Route } from 'wouter';
import { Layout } from '@shipmnts/pixel-hub';
import BookingRequestDashboard from './modules/reports/components/BookingRequestReports/BookingRequestDashboard';
import OTODashboard from './modules/reports/components/OTOReports/OTODashboard';

const { Content } = Layout;

function AnalyticsBase() {
  return (
    <Layout style={{ height: '100%' }}>
      <Layout
        style={{
          height: '100%',
          padding: '0px 20px 10px',
        }}
      >
        <Content style={{ overflow: 'initial' }} className="component-background">
          <Switch>
            <Route path={`/ocean_booking_request`}>
              <BookingRequestDashboard />
            </Route>
            <Route path={`/ocean_transport_order`}>
              <OTODashboard report_name={'booking_allocated_summary'} />
            </Route>
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
}

export default AnalyticsBase;
