import React, { useEffect, useState, useCallback } from 'react';
import { Route, Switch } from 'wouter';
import { useAuth0, sessionKey } from './Auth0';
import { setAxiosDefaults, showLogoutModal } from './utils/axiosDefaults';
import ApplicationLayout from './ApplicationLayout';
import AuthCallback from './AuthCallback';
import { getClient } from '../../graphQL/configureApolloClient';
import { ApolloProvider } from '@apollo/client';
import { initializeSentry } from './utils/thirdPartyIntegrations';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { CrossOriginLocalStorage } from './utils/crossOriginLocalStorage';
import Spinner from 'src/components/Spinner';
import '@shipmnts/pixel-hub/dist/index.css';

LicenseManager.setLicenseKey(
  'basdhjk098uyghbncskmai0d98uyhbjnksaod98y7[v1]yh12300e57c62c6dcbf4e15d1f000c3d973c5'
);

const App = (props) => {
  const { loading } = useAuth0();
  useEffect(() => {
    initializeSentry();
  }, []);

  if (loading) {
    return (
      <div className="centered fullscreen">
        <Spinner tip="checking your credentials and logging you in..." />
      </div>
    );
  }

  return (
    <Switch>
      <Route exact path="/cb/auth" component={AuthCallback} />
      <Route exact path="*">
        <PrivateRoute
          path="/"
          component={() => <ApplicationLayout>{props.children}</ApplicationLayout>}
          graphqlUrl={props.graphqlUrl}
        />
      </Route>
    </Switch>
  );
};

const PrivateRoute = ({ component: Component, path, graphqlUrl, ...rest }) => {
  const { isAuthenticated, loginWithRedirect, getTokenSilently, logout, user } = useAuth0();
  const { location } = window;

  useEffect(() => {
    const fn = async () => {
      if (!isAuthenticated) {
        await loginWithRedirect({
          appState: {
            targetUrl: `${location.pathname}${location.search}`,
            state: window.history.state,
          },
        });
      }
    };
    fn();
    // eslint-disable-next-line
  }, []);

  const [apolloClient, setApolloClient] = useState();

  const onMessage = useCallback(
    (payload, event) => {
      switch (payload.method) {
        case 'storage#get':
          break;
        case 'auth_change':
          const isAuthenticated = payload?.data?.isAuthenticated;
          if (isAuthenticated) {
            window.location.reload();
          } else {
            logout({ skipAuthSet: true, skipAuth0Logout: true });
            showLogoutModal(null, () => {
              window.location.reload();
            });
          }
          break;
        default:
          break;
      }
    },
    [logout]
  );

  useEffect(() => {
    const setClient = async () => {
      setApolloClient(await getClient(getTokenSilently, logout, graphqlUrl));
    };
    if (isAuthenticated) {
      const iframe = document.getElementById('session_iframe');
      const cross = new CrossOriginLocalStorage(window, iframe, onMessage);
      cross.setData('isAuthenticated', true);
      cross.startLogoutPoll();
      setAxiosDefaults(getTokenSilently, logout);
      setClient();
    }
  }, [isAuthenticated, getTokenSilently, logout, onMessage, user, graphqlUrl]);

  let render = () => <></>;
  if (isAuthenticated && apolloClient) {
    render = function render(props) {
      let session = localStorage.getItem(sessionKey);
      if (session && session !== '') {
        try {
          session = JSON.parse(session);
          const now_time = Date.now();
          const user_account_id =
            user[`${process.env.AUTH0_AUDIENCE}/user_metadata`]?.user_account_id;
          if (user_account_id?.toString() !== session?.id?.toString()) {
            session = undefined;
          }

          if ((session?.expires_at || 0) < now_time) {
            session = undefined;
          }
        } catch {
          session = undefined;
        }
      }
      return (
        <ApolloProvider client={apolloClient}>
          <Component {...props} session={session} />
        </ApolloProvider>
      );
    };
  }
  return render();
};

export default App;
