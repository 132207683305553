import { message } from '@shipmnts/pixel-hub';

export function reloadApp() {
  window.location.reload();
}

export async function clearAndReload() {
  try {
    localStorage.clear();
    message.success('Successfully cleared local cache.');
    reloadApp();
  } catch (error) {
    message.error('Error occurred while clearing cache. Please try to clear site data manually');
    console.error('Error in clearing application cache.', error);
  }
}
