/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotIn, SnapshotOut, cast, IAnyModelType } from 'mobx-state-tree';
import Carrier, { CarrierValue } from 'operations/models/Carrier';
import RoutingLeg, { RoutingLegValue } from 'operations/models/RoutingLeg';
import Vessel, { VesselValue } from 'operations/models/Vessel';
import { RoutingNodeValue } from './RoutingNode';
import { RoutingNodesHashValue } from 'operations/components/RoutingDetails';
import { Dayjs } from '@shipmnts/pixel-hub';

const sortRoutingLegs = (
  routing_legs: Instance<typeof RoutingLeg>[]
): Instance<typeof RoutingLeg>[] =>
  (routing_legs || []).slice().sort((a, b) => a.sequence_number - b.sequence_number);

const VoyageSchedule = types
  .model({
    id: types.identifier,
    vessel_id: types.string,
    carrier_ids: types.array(types.string),
    voyage_number: types.string,
    service_name: types.maybe(types.maybeNull(types.string)),
    preferable_for_perishable: types.maybe(types.maybeNull(types.boolean)),
    vessel: types.late((): IAnyModelType => Vessel),
    carriers: types.array(types.late((): IAnyModelType => Carrier)),
    routing_legs: types.array(types.late((): IAnyModelType => RoutingLeg)),
  })
  .views((self) => ({
    get routingNodes(): RoutingNodesHashValue {
      return self.routing_legs?.reduce((acc: RoutingNodesHashValue, rl: RoutingLegValue) => {
        if (rl?.origin?.id) acc[rl.origin.id] = rl.origin;
        else if (rl?.origin?._id) acc[rl.origin._id] = rl.origin;

        if (rl?.destination?.id) acc[rl.destination.id] = rl.destination;
        else if (rl?.destination?._id) acc[rl.destination._id] = rl.destination;

        return acc;
      }, {} as RoutingNodesHashValue);
    },
    getRoutingNodeByOriginLocation(location_id: string | undefined): RoutingNodeValue {
      return (
        self.routing_legs?.find(
          (rl: RoutingLegValue) => location_id && rl?.origin?.location?.id === location_id
        )?.origin || ({} as RoutingNodeValue)
      );
    },
    getETDByOriginLocation(location_id: string | undefined): Dayjs | undefined {
      return (
        self.routing_legs?.find(
          (rl: RoutingLegValue) => location_id && rl?.origin?.location?.id === location_id
        )?.estimated_time_of_departure || undefined
      );
    },
  }))
  .actions((self) => ({
    setRoutingLegs(newRoutingLegs: Instance<typeof RoutingLeg>[]) {
      self.routing_legs = cast(sortRoutingLegs(newRoutingLegs));
    },
    updateVoyageSchedule(voyage_schedule_fields: VoyageScheduleValue) {
      self = cast(voyage_schedule_fields);
    },
  }))
  .preProcessSnapshot(({ routing_legs, ...snapshot }) => ({
    ...snapshot,
    routing_legs: sortRoutingLegs(routing_legs as Instance<typeof RoutingLeg>[]),
  }));

export interface VoyageScheduleValue extends Instance<typeof VoyageSchedule> {}
export interface VoyageScheduleIn extends SnapshotIn<typeof VoyageSchedule> {}
export interface VoyageScheduleOut extends SnapshotOut<typeof VoyageSchedule> {}

export interface VoyageScheduleInitialValue {
  vessel?: VesselValue;
  carriers?: Array<CarrierValue>;
  routing_legs?: Array<RoutingLegValue>;
}

export default VoyageSchedule;
