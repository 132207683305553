import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { errorMessageHandlerGraphQL, useSession, useEmailDrawerContext } from 'common';
import { useMutation } from '@apollo/client';
import { SAVE_STUFFING_BUFFER_SERVICE_ORDER } from 'operations/modules/booking/graphql/stuffingBufferServiceOrder';
import { Card, Button, Descriptions, Tooltip, message, Space, Form } from '@shipmnts/pixel-hub';
import StuffingBufferServiceOrder from 'operations/models/StuffingBufferServiceOrder';
import { RoutingNodeValue } from 'operations/models/RoutingNode';
import { CompanyValue } from 'operations/models/Company';
import StuffingServiceForm from 'operations/modules/booking/components/BookingRequestForm/StuffingServiceForm';
import { PlusOutlined, EditOutlined } from '@shipmnts/pixel-hub';
import { LOAD_TYPE_LCL } from 'operations/baseConstants';
import { convertToStringFormat, DEFAULT_DATE_FORMAT, Dayjs } from '@shipmnts/pixel-hub';
import { BookingRequestValue } from 'operations/models/BookingRequest';
import { applySnapshot } from 'mobx-state-tree';
import { STUFFING_LOCATION_TYPE_FACTORY } from 'operations/baseConstants';
import { ActionRenderer } from 'operations/modules/actionHelper/ActionRenderer';
import { getStuffingAction } from 'operations/modules/actionHelper/BookingRequestActions/bookingRequestActionHelper';

interface StuffingBufferServiceFormValue {
  vendor?: { party_company?: CompanyValue | null };
  location_node?: RoutingNodeValue;
  stuffing_cutoff_date?: Dayjs | null;
}
const StuffingBufferServiceView = observer(function StuffingBufferServiceView(props: {
  bookingRequest: BookingRequestValue;
  load_type?: string;
  disabled?: boolean;
  disableReason?: string;
  refetchData?: () => void;
}): JSX.Element {
  const { bookingRequest, load_type, disabled, disableReason, refetchData } = props;
  const { setVisible, setEmailProps } = useEmailDrawerContext();
  const user = useSession();
  const stuffingBufferService = bookingRequest.stuffing_buffer_service_orders[0];
  const buffer_only =
    stuffingBufferService?.buffer_only ||
    bookingRequest.stuffing_location_type === STUFFING_LOCATION_TYPE_FACTORY;
  const [stuffingBufferEdit, setStuffingBufferEdit] = useState<{
    edit: boolean;
    beforeUpdate?: StuffingBufferServiceFormValue;
  }>({ edit: false });
  const cardTitle = buffer_only ? 'Buffer Service' : 'Stuffing & Buffer Service';
  const sessionData = useSession();
  const default_company_id = sessionData.company_account.default_company.id;
  const [updateStuffingBufferService, { data, loading, error }] = useMutation(
    SAVE_STUFFING_BUFFER_SERVICE_ORDER
  );

  useEffect(() => {
    if (!error && data && data.save_stuffing_buffer_service_order) {
      message.success('Stuffing Buffer Service updated successfully!');
      if (!stuffingBufferEdit.beforeUpdate) {
        const order = StuffingBufferServiceOrder.create(data.save_stuffing_buffer_service_order);
        bookingRequest.addStuffingBufferServiceOrder(order);
      } else {
        applySnapshot(stuffingBufferService, data.save_stuffing_buffer_service_order);
      }
      setStuffingBufferEdit({ edit: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);
  if (stuffingBufferEdit.edit) {
    return (
      <Form
        scrollToFirstError
        // FIX ME: any
        onFinish={(values: any) => {
          const stuffing_buffer_service_order = {
            vendor_company_id: values?.vendor?.party_company?.id || null,
            buffer_only,
            location_node: {
              id: stuffingBufferService?.location_node?.id,
              location_id: values?.location_node?.location?.id || null,
              tags: buffer_only ? ['origin_cfs'] : ['stuffing_location'],
            },
            stuffing_cutoff_date: values?.stuffing_cutoff_date?.unix(),
          };
          const payload = { booking_request_id: bookingRequest.id, stuffing_buffer_service_order };
          updateStuffingBufferService({ variables: payload });
        }}
        initialValues={{
          ...stuffingBufferEdit.beforeUpdate,
        }}
        layout="vertical"
      >
        <Card
          id="stuffing_buffer_service_order"
          title={cardTitle}
          extra={[
            <Button
              style={{ marginRight: '5px' }}
              key="cancel"
              disabled={loading}
              onClick={() => setStuffingBufferEdit({ edit: false })}
            >
              Cancel
            </Button>,
            <Button loading={loading} key="save" htmlType="submit" type="primary">
              Save
            </Button>,
          ]}
        >
          {error && errorMessageHandlerGraphQL(error)}
          <Form.Item noStyle name="stuffing_buffer_service_order">
            <StuffingServiceForm
              load_type={load_type}
              buffer_only={buffer_only}
              stuffing_location_type={bookingRequest.stuffing_location_type}
            />
          </Form.Item>
        </Card>
      </Form>
    );
  }

  if (
    !stuffingBufferService &&
    bookingRequest.stuffing_location_type === STUFFING_LOCATION_TYPE_FACTORY &&
    !bookingRequest.id
  ) {
    // Do not allow to create stuffing service in factory BR.
    return <></>;
  }
  return (
    <Card
      id="stuffing_buffer_service_order"
      title={cardTitle}
      extra={
        stuffingBufferService && (
          <Space>
            <Tooltip title={disableReason}>
              <Button
                onClick={() => {
                  setStuffingBufferEdit({
                    edit: true,
                    beforeUpdate: stuffingBufferService
                      ? {
                          vendor: {
                            party_company:
                              stuffingBufferService.getVendorCompany(default_company_id) ||
                              undefined,
                          },
                          location_node: stuffingBufferService?.location_node,
                          stuffing_cutoff_date: stuffingBufferService?.stuffing_cutoff_date,
                        }
                      : undefined,
                  });
                }}
                disabled={disabled}
              >
                <EditOutlined />
                Edit
              </Button>
            </Tooltip>
          </Space>
        )
      }
    >
      {stuffingBufferService ? (
        <Descriptions
          column={load_type === LOAD_TYPE_LCL ? 4 : 3}
          colon={false}
          size="default"
          layout="vertical"
        >
          <Descriptions.Item key="provider" label="Provider">
            {stuffingBufferService?.stuffing_provider?.registered_name}
          </Descriptions.Item>
          <Descriptions.Item key="location" label="Stuffing/Buffer Location">
            {stuffingBufferService?.location_node?.location?.name}
          </Descriptions.Item>
          {load_type === LOAD_TYPE_LCL && (
            <Descriptions.Item key="stuffing_cutoff_date" label="Stuffing Cutoff Date">
              {stuffingBufferService?.stuffing_cutoff_date &&
                convertToStringFormat(
                  stuffingBufferService?.stuffing_cutoff_date,
                  DEFAULT_DATE_FORMAT
                )}
            </Descriptions.Item>
          )}
          <Descriptions.Item key="action" label="Actions">
            <ActionRenderer
              id={bookingRequest.id}
              data={bookingRequest}
              doc_type_id={'Shipment::BookingRequest'}
              refetchData={refetchData}
              selectedActions={getStuffingAction(bookingRequest, setEmailProps, setVisible, user)}
            />
          </Descriptions.Item>
        </Descriptions>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <Tooltip title={disableReason}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setStuffingBufferEdit({ edit: true, beforeUpdate: undefined });
              }}
              disabled={disabled}
            >
              Add Buffer Service
            </Button>
          </Tooltip>
        </div>
      )}
    </Card>
  );
});

export default StuffingBufferServiceView;
