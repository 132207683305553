import React from 'react';
import { Table, Form } from '@shipmnts/pixel-hub';
import { startCase as _startCase } from 'lodash';
import { AddressCompanySearch } from 'common';
import {
  ShipmentPartyFieldValue,
  ShipmentEstimatesFieldValue,
} from 'operations/modules/message/messageResourceDataModel';
import { AlignType } from 'rc-table/lib/interface';

interface MessageShipmentPartyMappingProps {
  shipmentParties: ShipmentPartyFieldValue[];
  shipmentEstimates?: ShipmentEstimatesFieldValue[] | [];
}

const columns = [
  {
    title: 'Received Name of Party',
    key: 'party_company_id',
    render: function renderReceivedPartyName(
      text: string,
      record: ShipmentPartyFieldValue,
      index: number
    ) {
      return (
        <div key={index}>
          {record?.party_company_id?.label},&nbsp;{record?.party_address_id?.label}
          <div style={{ color: 'slategray' }}>({_startCase(record?.name?.value || '')})</div>
        </div>
      );
    },
  },
  {
    title: 'Map to Party Master',
    key: 'companies_mappings',
    align: 'center' as AlignType,
    render: function renderAddressCompanySearch(text: string, record: ShipmentPartyFieldValue) {
      return (
        <Form.Item
          required
          rules={[{ required: true, message: 'Please select party.' }]}
          name={['companies_mappings', `${record?.party_address_id?.value}`]}
        >
          <AddressCompanySearch alignHorizontal />
        </Form.Item>
      );
    },
  },
];

const MessageShipmentPartyMapping = React.memo(function MessageShipmentPartyMapping(
  props: MessageShipmentPartyMappingProps
): JSX.Element {
  const { shipmentParties } = props;
  return (
    <Table
      columns={columns}
      dataSource={shipmentParties}
      pagination={false}
      size="small"
      rowKey={(row) => row.id.value || ''}
    />
  );
});

export default MessageShipmentPartyMapping;
