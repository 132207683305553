import React, { useState, useMemo, useCallback, useEffect } from 'react';
import {
  Row,
  Col,
  Table,
  Button,
  Typography,
  FormInstance,
  EditableCell,
  Dayjs,
} from '@shipmnts/pixel-hub';
import { uniqueId as _uniqueId } from 'lodash';
import { baseConstants, useApplicationContentContext } from 'common';
import { CreateShipmentFormBRValue } from 'operations/modules/reports/components/CreateShipmentFormContent';
import { TradeType } from '../constants';
import { ShipmentValue } from 'operations/models/Shipment';
import { GlobalSearch } from '@shipmnts/pixel-hub';
const { Text } = Typography;
const { RENDER_INPUT, RENDER_DATE, RENDER_SELECT, RENDER_CUSTOM_FIELD } = baseConstants;
export interface ExportClearanceTableValue {
  trade_type?: TradeType;
  id?: string;
  _id?: string;
  document_number?: string;
  document_date?: Dayjs;
  country?: string | null;
  custom_clearance_location?: any;
}

interface ExportClearanceTableProps {
  value?: ExportClearanceTableValue[];
  onChange?: (value: ExportClearanceTableValue[]) => void;
  label: string;
  tableHeader: string;
  bookingRequest?: CreateShipmentFormBRValue;
  shipment?: ShipmentValue;
  type?: 'CUSTOM' | 'INVOICE';
  tradeType?: TradeType;

  form: FormInstance;
}

const getClearanceTableColumns = (label: string) => [
  {
    title: `${label} Number`,
    dataIndex: 'document_number',
    editable: true,
    renderComponent: RENDER_INPUT,
  },
  {
    title: `${label} Date`,
    dataIndex: 'document_date',
    editable: true,
    renderComponent: RENDER_DATE,
    formItemProps: {
      validateStatus: 'success',
    },
  },
];

const components: any = {
  body: {
    cell: EditableCell,
  },
};

const ExportClearanceTable = React.memo(function ExportClearanceTable(
  props: ExportClearanceTableProps
): JSX.Element {
  const { value, onChange, label, tableHeader, type, tradeType } = props;
  const [selectedRowKeys, setSelectedRowKeys] = useState<Array<React.ReactText>>([]);

  const defaultClearanceDetails: ExportClearanceTableValue[] = [
    {
      _id: 'cr_0',
      document_number: undefined,
      document_date: undefined,
    },
  ];

  const [customsDetails, setCustomsDetails] = useState<ExportClearanceTableValue[]>(
    value || defaultClearanceDetails
  );

  const { config_data } = useApplicationContentContext();
  const list_of_countries = config_data?.list_of_countries;

  useEffect(() => {
    if (value) {
      setCustomsDetails(value);
    }
  }, [value]);

  const onFieldChange = useCallback(
    (key: string, value: any, index: number) => {
      const new_clearance_details = customsDetails;
      new_clearance_details[index] = { ...new_clearance_details[index], [key]: value };
      // if ()form.resetFields(['custom_clearance_location'])
      setCustomsDetails(new_clearance_details);
      if (onChange) onChange([...new_clearance_details]);
    },
    [customsDetails, onChange]
  );

  let columns: any = useMemo(() => getClearanceTableColumns(label), [label]);

  if (type === 'CUSTOM') {
    columns = columns.concat(
      {
        title: `${label} Country`,
        dataIndex: 'country',
        editable: true,
        renderComponent: RENDER_SELECT,
        componentProps: {
          options: (list_of_countries || []).map(
            ({ code, name }: { code: string; name: string }) => {
              return { key: code, name: name };
            }
          ),
          showGroup: false,
        },
      },
      {
        title: `${label} Location`,
        dataIndex: 'custom_clearance_location',
        editable: true,
        renderComponent: RENDER_CUSTOM_FIELD,
        customComponent: LocationSearchWrapper,
        componentProps: {
          // value: locationDefaultValue,
          searchProps: {
            is_customs_location: true,
          },
        },
      }
    );
  }

  const clearanceColumns = columns.map((col: any) => {
    return {
      ...col,
      onCell: (record: ExportClearanceTableValue, rowIndex: number) => ({
        record,
        rowIndex,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        renderComponent: col.renderComponent,
        onFieldChange,
        value,
        componentProps: col.componentProps,
        customComponent: col.customComponent,
      }),
    };
  });

  const addRow = () => {
    const new_clearance_details: ExportClearanceTableValue[] = [
      ...customsDetails,
      {
        _id: _uniqueId('cr_'),
        document_number: undefined,
        document_date: undefined,
        trade_type: tradeType,
        country: undefined,
        custom_clearance_location: undefined,
      },
    ];
    setCustomsDetails(new_clearance_details);
    if (onChange) onChange(new_clearance_details);
  };

  const removeRow = () => {
    const new_clearance_details: any = customsDetails.filter(
      (cd: ExportClearanceTableValue) => !selectedRowKeys.includes(cd._id || cd.id || '')
    );
    setCustomsDetails(new_clearance_details);
    setSelectedRowKeys([]);
    if (onChange) onChange(new_clearance_details);
  };

  const rowSelectionProps = {
    rowSelection: {
      selectedRowKeys,
      onChange: (selectedRowKeys: any) => setSelectedRowKeys(selectedRowKeys),
    },
  };

  return (
    <Col>
      <Row>
        <Text style={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.85)', padding: '0 0 8px' }}>
          {tableHeader}
        </Text>
      </Row>
      <Row>
        <Table
          style={{ width: '100%' }}
          {...rowSelectionProps}
          components={components}
          dataSource={customsDetails}
          columns={clearanceColumns}
          pagination={false}
          rowKey={(record) => record._id || record?.id || ''}
          size="small"
        />
      </Row>
      <Row style={{ margin: '10px' }} gutter={16}>
        <Button
          size="small"
          style={{
            marginRight: 16,
          }}
          onClick={addRow}
        >
          Add row
        </Button>
        <Button
          danger
          size="small"
          style={{
            marginRight: 16,
          }}
          disabled={selectedRowKeys.length === 0}
          onClick={removeRow}
        >
          Delete row(s)
        </Button>
      </Row>
    </Col>
  );
});

export default ExportClearanceTable;

const LocationSearchWrapper = function RenderComponent(props: any): React.ReactElement {
  const { value, onChange, record, dataIndex, rowIndex, ...restProps } = props;
  const [locationValue, setlocationValue] = useState(value);
  return (
    <GlobalSearch
      doc_type="Global::Location"
      value={locationValue}
      onChange={(v: any) => {
        onChange(v);
        setlocationValue(v);
      }}
      {...restProps}
      searchProps={{
        is_customs_location: true,
        country_code: record.country,
      }}
    />
  );
};
