import { useMutation } from '@apollo/client';
import { message, Modal } from '@shipmnts/pixel-hub';
import { DEALLOCATE_OCEAN_TRANSPORT_ORDER_FROM_SHIPMENT } from 'operations/graphql/shipment';
import { OceanTransportOrderValue } from 'operations/models/OceanTransportOrder';
import { ShipmentValue } from 'operations/models/Shipment';
import React, { useEffect } from 'react';

interface DeallocateOceanTranportOrderFromShipmentProps {
  oceanTransportOrder: OceanTransportOrderValue;
  shipment: ShipmentValue;
  onSuccess?: () => void;
  onClose?: () => void;
}
export default function DeallocateOceanTranportOrderFromShipment(
  props: DeallocateOceanTranportOrderFromShipmentProps
) {
  const { oceanTransportOrder, shipment, onSuccess, onClose } = props;
  const [modal, contextHolder] = Modal.useModal();
  const [deallocateOTO, { data, error }] = useMutation(
    DEALLOCATE_OCEAN_TRANSPORT_ORDER_FROM_SHIPMENT,
    {
      fetchPolicy: 'no-cache',
    }
  );

  useEffect(() => {
    if (error) {
      message.error(error.message);
      if (onSuccess) onSuccess();
    } else if (data?.deallocate_ocean_transport_order_from_shipment) {
      message.success('Booking Order Deallocated successfully');
      if (onSuccess) onSuccess();
    }
  }, [data, error, onSuccess]);

  const deallocateOtoFromShipment = () => {
    deallocateOTO({
      variables: {
        ocean_transport_order_id: oceanTransportOrder.id,
        shipment_id: shipment.id,
      },
    });
  };

  const config = {
    title: `Are you sure to deallocate this booking?`,
    content: (
      <div style={{ background: '#FFFBE6', padding: '15px' }}>
        <p>Delinking this booking from shipment will update the</p>
        <ul>
          <li>After deallocation, booking will be available as independent booking</li>
          <li>Order Quantity will be move in Allocation Pending</li>
        </ul>
      </div>
    ),
    okText: 'Confirm Deallocate',
    cancelText: 'Cancel',
    onCancel: () => {
      if (onClose) onClose();
    },
    onOk: deallocateOtoFromShipment,
  };

  useEffect(() => {
    Modal.confirm(config);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);
  return <>{contextHolder}</>;
}
