import { gql } from '@apollo/client';

export const trackingEventFields = gql`
  fragment trackingEventFields on TrackingEventObject {
    id
    name
    actual_date
    estimated_date
    event_data
    event_type
    sequence_number
    location {
      ... on AddressObjectType {
        id
        name
        country_code
        city {
          name
        }
        print_address
      }
      ... on PortObjectType {
        id
        name
        country_code
        city {
          name
        }
      }
    }
  }
`;
