/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotIn, SnapshotOut, IAnyModelType } from 'mobx-state-tree';
import BranchAccount from './BranchAccount';
import Company from './Company';

const UserContact = types
  .model({
    id: types.identifier,
    first_name: types.string,
    pretty_display: types.maybe(types.maybeNull(types.string)),
    last_name: types.maybe(types.maybeNull(types.string)),
    department: types.maybe(types.maybeNull(types.array(types.string))),
    dial_code: types.maybe(types.maybeNull(types.string)),
    is_disabled: types.maybe(types.maybeNull(types.boolean)),
    is_internal: types.maybe(types.maybeNull(types.boolean)),
    mobile_number: types.maybe(types.maybeNull(types.string)),
    title: types.maybe(types.maybeNull(types.string)),
    email: types.maybe(types.maybeNull(types.string)),
    branch_accounts: types.maybe(types.maybeNull(BranchAccount)),
    created_at: types.maybe(types.maybeNull(types.number)),
    name: types.maybe(types.maybeNull(types.string)),
    companies: types.maybe(types.array(types.late((): IAnyModelType => Company))),
  })
  .volatile((self) => ({ _destroy: types.maybe(types.maybeNull(types.boolean)) }));

export interface UserContactValue extends Instance<typeof UserContact> {}
export interface UserContactIn extends SnapshotIn<typeof UserContact> {}
export interface UserContactOut extends SnapshotOut<typeof UserContact> {}

export default UserContact;
