/**
 * Conponent to be rendered in Container Tab in shipment view, booking order view
 * uses AgGrid to render containers
 */

import React from 'react';
import { Column } from 'operations/models/Report';
import { ActionRenderer } from 'operations/modules/actionHelper/ActionRenderer';
import { compact as _compact, startCase } from 'lodash';
import { useShipmentDetail } from 'operations/modules/shipment/components/DetailLayout/ShipmentDetailLayout';
import { BaseTable, TrackerContextProvider, Tag } from '@shipmnts/pixel-hub';
import { OceanTransportOrderValue } from 'operations/models/OceanTransportOrder';
import { statusTagColor } from 'operations/modules/reports/constants';
import { startCase as _startCase } from 'lodash';
import {
  BOOKING_TYPE_SELF,
  BOOKING_TYPE_SHIPPING_LINE,
  LOAD_TYPE_LCL,
} from 'operations/baseConstants';

export function BookingTab() {
  const { shipment, refetchShipments } = useShipmentDetail();

  // columns
  const columnDefs: Column[] = _compact([
    {
      headerName: 'Booking Number',
      field: 'booking_number',
      colId: 'booking_number',
      width: 250,
      minWidth: 250,
      pinned: 'left',
      columnType: 'Link',
      cellRendererParams: {
        id_field: 'id',
        doc_type_id: 'Shipment::OceanTransportOrder',
      },
      lockPosition: true,
      suppressMovable: true,
      lockVisible: true,
    },
    {
      headerName: 'Booking type',
      field: 'booking_type',
      colId: 'booking_type',
      cellRenderer: function render({ data }: { data: OceanTransportOrderValue }) {
        if (data?.booking_type === 'self') return 'CRO';
        return startCase(data?.booking_type || '');
      },
      width: 150,
      lockPosition: true,
      suppressMovable: true,
      lockVisible: true,
    },
    {
      headerName: 'Shipping Line/Vendor',
      field: 'vendorName',
      colId: 'vendorName',
      cellRenderer: function render({ data }: { data: OceanTransportOrderValue }) {
        if (
          data.booking_type === BOOKING_TYPE_SHIPPING_LINE ||
          data.booking_type === BOOKING_TYPE_SELF ||
          data.load_type === LOAD_TYPE_LCL
        )
          return data.global_carrier?.name;
        return data.vendor_company?.registered_name;
      },
      width: 150,
      lockPosition: true,
      suppressMovable: true,
      lockVisible: true,
    },
    {
      headerName: 'Allocated',
      field: 'allocated',
      colId: 'allocated',
      cellRenderer: function render({ data }: { data: OceanTransportOrderValue }) {
        return (data.container_requests || [])
          .map((cr: any) => {
            return cr.quantity_fulfilled + ' X ' + cr.container_type_code;
          })
          .join(', ');
      },
      lockPosition: true,
      suppressMovable: true,
      lockVisible: true,
    },
    {
      headerName: 'Valid Till',
      field: 'valid_till_date',
      colId: 'valid_till_date',
      columnType: 'Date',
      lockPosition: true,
      suppressMovable: true,
      lockVisible: true,
    },
    {
      headerName: 'Status',
      field: 'status',
      colId: 'status',
      cellRenderer: function render({ data }: { data: OceanTransportOrderValue }) {
        const statusTagProps = data?.status ? { color: statusTagColor[data?.status] } : {};
        return <Tag {...statusTagProps}>{_startCase(data?.status)}</Tag>;
      },
      lockPosition: true,
      suppressMovable: true,
      lockVisible: true,
    },

    {
      headerName: 'Actions',
      colId: 'actions',
      field: 'actions',
      cellRenderer: function render({ data }: { data: OceanTransportOrderValue }) {
        // if (oceanTransportOrder.isAnyContainerPickedUp(record.id)) return null;
        if (!shipment) {
          return <></>;
        }
        return (
          <ActionRenderer
            id={data.id}
            doc_type_id="Shipment::OceanTransportOrder"
            refetchData={refetchShipments}
            // selectedActions={[deallocateBookingFromShipment(data, shipment)]}
          />
        );
      },
      pinned: 'right',
      lockPosition: true,
      suppressMovable: true,
      lockVisible: true,
    },
  ]);

  return (
    <TrackerContextProvider referenceType="Shipment::Shipment">
      <BaseTable
        reportName={'ocean_transport_ordedrs'}
        columns={columnDefs}
        rowData={
          (shipment?.ocean_transport_orders || []).map((oto) => {
            return {
              ...oto,
              booking_number: {
                value: oto.booking_number || '',
                extra_fields: { id: oto.id },
              },
              valid_till_date: oto.valid_till_date.unix(),
            };
          }) || []
        }
        reportConfig={{
          groupDefaultExpanded: 0,
          rowHeight: 40,
          stopEditingWhenCellsLoseFocus: true,
          enableCellChangeFlash: true,
          groupDisplayType: 'groupRows',
        }}
      />
    </TrackerContextProvider>
  );
}

export default BookingTab;
