import {
  AddressCompanySearch,
  Col,
  Form,
  FormInstance,
  GlobalSearch,
  Input,
  Row,
} from '@shipmnts/pixel-hub';
import BookingRoutingDetailsNew from 'operations/modules/booking/components/BookingOrderForm/BookingRoutingDetailsNew';
import { useRef } from 'react';
import { TYPE_UPDATE } from './helpers';
import { BOOKING_TYPE_SHIPPING_LINE } from 'operations/baseConstants';
import React from 'react';
import { FreightType } from '../../constants';
import { RoutingDetailsValue } from 'operations/components/RoutingDetails';
import { LOCATION_TYPE_CITY } from 'operations/models/Location';
import { get as _get } from 'lodash';

interface RoutingDetailsWrapperProps {
  value?: RoutingDetailsValue;
  onChange?: (value: RoutingDetailsValue) => void;
  disableNodes?: boolean;
  disableVoyageUpdate?: boolean;
  validateVesselVoyage?: boolean;
  globalCarrierId?: string;
  bookingType?: string | null;
  isReeferContainer?: boolean;
  allowVoyageScheduleSearch?: boolean;
  onlyTranshipmentHopEditable?: boolean;
  showPickupSection?: boolean;
  showStuffingSection?: boolean;
  showDeStuffingSection?: boolean;
  showMovementSection?: boolean;
  showPodBufferSection?: boolean;
  showPolBufferSection?: boolean;
  showDeliverySection?: boolean;
  freightType?: FreightType;
  loadType?: string;
  form: FormInstance;
  type?: string;
}

export default function RoutingDetailsWrapper(props: RoutingDetailsWrapperProps) {
  const { freightType, form, type } = props;
  const routing_details_ref = useRef<{ runValidation: () => boolean }>();
  const bookingType = Form.useWatch('booking_type', form);

  return (
    <>
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item label="Pickup Address" name={['party', 'pickup']}>
            <AddressCompanySearch
              onChange={(value) => {
                form.setFieldValue(
                  'pickup_address_print',
                  value?.party_address?.print_address || ''
                );
              }}
              addressSearchProps={{
                showAddBranchAction: true,
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Final Place Of Delivery" name="final_place_of_delivery_id">
            <GlobalSearch
              doc_type="Global::Location"
              searchProps={{ type: [LOCATION_TYPE_CITY] }}
              onChange={(value) => {
                const fpod_print_value = `${_get(value, 'city', '')}, ${_get(value, 'state', '')}`;
                form.setFieldValue('final_place_of_delivery_print', fpod_print_value);
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item name={'pickup_address_print'} label="Pickup Address Print">
            <Input.TextArea rows={2} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="final_place_of_delivery_print" label="Final Place Of Delivery Print">
            <Input.TextArea rows={2} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="routing_details"
        noStyle
        // rules={[
        //   {
        //     validator: (rule, value) => {
        //       // if (!routing_details_ref?.current?.runValidation()) {
        //       //   return Promise.resolve();
        //       // }
        //       // return Promise.reject();
        //     },
        //   },
        // ]}
      >
        <BookingRoutingDetailsNew
          allowVoyageScheduleSearch={!!bookingType}
          form={props.form}
          ref={routing_details_ref}
          freightType={freightType}
          loadType={props.loadType}
          bookingType={bookingType}
          disableNodes={type === TYPE_UPDATE}
          disableVoyageUpdate={type === TYPE_UPDATE}
          validateVesselVoyage={bookingType === BOOKING_TYPE_SHIPPING_LINE}
        />
      </Form.Item>
    </>
  );
}
