/* eslint-disable camelcase */
import React, { useEffect } from 'react';

import { useMutation } from '@apollo/client';
import { message, Modal } from '@shipmnts/pixel-hub';

import { MOVE_STOCK_STATE } from 'operations/graphql/StockOrder';

interface AwbStockUnreservedProps {
  id: number;
  stock_order_items_awb_number: string;
  onClose?: () => void;
  onSuccess?: () => void;
}
export default function AwbStockUnreserved(props: AwbStockUnreservedProps) {
  const { id, stock_order_items_awb_number, onSuccess, onClose } = props;
  const [modal, contextHolder] = Modal.useModal();
  const [
    moveStockState,
    { data: moveStockStateData, loading: moveStockStateLoading, error: moveStockStateError },
  ] = useMutation(MOVE_STOCK_STATE);

  const shipment_number = `${stock_order_items_awb_number.substr(
    0,
    3
  )}-${stock_order_items_awb_number.substr(3)}`;

  useEffect(() => {
    if (moveStockStateError) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      message.error(moveStockStateError);
      return;
    }
    if (moveStockStateLoading) {
      return;
    }
    if (moveStockStateData?.stock_order_item_state_change) {
      message.success(`Stock ${shipment_number} unreserved successfully`);
      if (onSuccess) onSuccess();
    }
  }, [moveStockStateData, moveStockStateLoading, moveStockStateError, shipment_number, onSuccess]);

  const config = {
    title: `Are you sure to unreserve the AWB number: ${shipment_number}?`,
    content: 'Once you click on Confirm, this AWB number will no longer be reserved for you',
    okText: 'Confirm',
    cancelText: 'Cancel',
    onCancel: () => {
      if (onClose) onClose();
    },
    onOk: () => {
      moveStockState({ variables: { id, action: 'unreserve' } });
      if (onSuccess) onSuccess();
    },
  };

  useEffect(() => {
    modal.confirm(config);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{contextHolder}</>;
}
