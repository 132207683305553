import React from 'react';

import { SyncOutlined } from '@shipmnts/pixel-hub';
import { ApolloError } from '@apollo/client/errors';
import { Skeleton, Result, Button, Typography } from '@shipmnts/pixel-hub';
const { Text } = Typography;

interface ComponentWrapperProps {
  loading: boolean;
  showLoader?: boolean;
  children: React.ReactNode;
  error?: ApolloError;
  refetch?: () => void;
}
const ComponentWrapper = React.memo(function ComponentWrapper(props: ComponentWrapperProps) {
  const { loading, children, error, refetch } = props;

  if (loading) return <Skeleton />;

  if (error) {
    return (
      <Result
        status="error"
        title="Something went wrong."
        subTitle={
          <Text type="danger">
            {error.graphQLErrors.map(({ message }, i) => (
              <span key={i}>{message}</span>
            ))}
          </Text>
        }
        extra={[
          <Button
            icon={<SyncOutlined style={{ color: 'rgb(0, 142, 255)' }} />}
            loading={loading}
            onClick={() => {
              if (refetch) {
                refetch();
              }
            }}
            size="small"
            key="retry"
          >
            Retry
          </Button>,
        ]}
      />
    );
  }

  return <>{children}</>;
});

export default ComponentWrapper;
