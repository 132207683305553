import React, { useCallback, useEffect, useRef, useState } from 'react';
import { GridOptions } from '@ag-grid-community/core';
import { Col, Drawer, Row, DrawerFooter, CustomIcon, Form } from '@shipmnts/pixel-hub';
import ContainerGrid, {
  renderContainerData,
} from '../booking/components/MergeSplitBooking/ContainerGridRender';
import { Steps } from '@shipmnts/pixel-hub';
import SplitAndCreateBooking from './SplitAndCreateBooking';
import { ContainerReportData } from 'operations/modules/reports/reportModels';
import { ShipmentValue } from 'operations/models/Shipment';
import { ShipmentContainerValue } from 'operations/models/ShipmentContainer';
import { gql, useLazyQuery } from '@apollo/client';
import { oceanTransportOrderFields } from '../booking/graphql';
import OceanTransportOrder from 'operations/models/OceanTransportOrder';

interface UpdateOrSplitOtoProps {
  onClose: () => void;
  onSuccess: (sendEmail?: boolean, resource_ids?: string[]) => void;
  shipment?: ShipmentValue;
}

const SHIPMENT = gql`
  query shipment($id: ID!) {
    shipment(id: $id) {
      id
      ocean_transport_orders {
        ...oceanTransportOrderFields
      }
    }
  }
  ${oceanTransportOrderFields}
`;

export default function UpdateRoutingOto(props: UpdateOrSplitOtoProps) {
  const { onClose, shipment, onSuccess } = props;
  const gridRef = useRef<GridOptions>();
  const [oceanTransportOrder, setOceanTransportOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [form] = Form.useForm();

  const [fetchShipmentOceanTransportOrder, { data }] = useLazyQuery(SHIPMENT);

  const get_booking_number = (
    shipment?: ShipmentValue,
    container?: ShipmentContainerValue
  ): string => {
    const ocean_transport_order_id = container?.ocean_transport_order_ids
      ? container?.ocean_transport_order_ids[0]
      : null;
    const matched_oto = (shipment?.ocean_transport_orders || []).find(
      (order) => order.id === ocean_transport_order_id
    );
    return matched_oto?.booking_number || '';
  };

  const [current, setCurrent] = React.useState(0);

  const tableContainers: any[] = (shipment?.shipment_containers || [])
    .filter((sc) => Boolean(sc.container_number))
    .map((sc) => ({
      ...sc,
      total_gross_weight: sc.cargo_gross_weight,
      booking_number: get_booking_number(shipment, sc),
    }));

  //   const [oto, setOto] = useState<OceanTransportOrderValue | undefined>(oceanTransportOrder);
  //   const [actionName, setActionName] = useState('');
  const [selectedContainers, setSelectedContainers] = useState<ContainerReportData[]>([]);
  const containerColumnNames = [
    'container_number',
    'booking_number',
    'total_gross_weight',
    'last_action_status',
  ];

  const onSelectionChanged = useCallback(
    () => {
      const selectedContainersIds =
        gridRef?.current?.api?.getSelectedNodes().map((node) => node.data.id) || [];
      //   const selectedBookingNumbers = gridRef?.current?.api
      //     ?.getSelectedNodes()
      //     .map((node) => node.data.booking_number);

      //   if (bookingRequest) {
      //     setOto(
      //       bookingRequest?.ocean_transport_orders.find((oto) =>
      //         selectedBookingNumbers?.includes(oto.booking_number)
      //       )
      //     );
      //   }
      setSelectedContainers(
        tableContainers.filter((container) => selectedContainersIds.includes(container.id))
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const changeStep = () => {
    setCurrent(1);
  };
  const RenderFooter = useCallback(() => {
    if (current === 0) {
      return (
        <DrawerFooter
          saveText={'Next Step'}
          disabled={selectedContainers.length === 0}
          onClose={onClose}
          onSave={changeStep}
          loading={loading}
        />
      );
    } else if (current === 1) {
      return (
        <DrawerFooter
          saveText="Update booking"
          onSave={() => form.submit()}
          loading={loading}
          onClose={() => {
            if (onClose) onClose();
          }}
          showSendEmail
          sendEmail={sendEmail}
          setSendEmail={setSendEmail}
          sendEmailText={`Send Split Confirmation`}
        />
      );
    }
    return <></>;
  }, [current, form, loading, onClose, selectedContainers.length, sendEmail]);

  useEffect(() => {
    if (data?.shipment) {
      setOceanTransportOrder({
        ...data?.shipment.ocean_transport_orders[0],
        port_of_discharge: shipment?.port_of_discharge,
      });
    }
  }, [data, setOceanTransportOrder, shipment]);
  useEffect(() => {
    if (shipment?.isHouseShipment() && shipment.master_shipment_id) {
      fetchShipmentOceanTransportOrder({ variables: { id: shipment?.master_shipment_id } });
    } else {
      fetchShipmentOceanTransportOrder({ variables: { id: shipment?.id } });
    }
  }, [shipment, fetchShipmentOceanTransportOrder]);
  const RenderSteps = useCallback(() => {
    if (current === 0) {
      return (
        <ContainerGrid
          tableHeading="Select container(s) to update routing details"
          containers={renderContainerData(tableContainers)}
          columnNames={containerColumnNames}
          gridRef={gridRef}
          onSelectionChanged={onSelectionChanged}
          renderFormatedData
        />
      );
    } else {
      return (
        <>
          <SplitAndCreateBooking
            ocean_transport_order={
              oceanTransportOrder ? OceanTransportOrder.create(oceanTransportOrder) : null
            }
            containers={selectedContainers}
            rolloverOrShutout={'rollover'}
            onClose={onClose}
            onSuccess={onSuccess}
            shipment={shipment}
            splitFromShipment={true}
            setLoading={setLoading}
            form={form}
          />
        </>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  const items = [
    {
      title: 'Step 1',
      description: 'Select Containers',
      onClick: () => {
        setSelectedContainers([]);
      },
    },
    {
      title: 'Step 2',
      description: 'Update Routing details or Split booking',
    },
  ];

  return (
    <Drawer
      placement="right"
      footer={<RenderFooter />}
      title={
        <div>
          <Row>
            <Col span={16}>
              <div
                style={{
                  color: '#353F52',
                  fontSize: '18px',
                  fontWeight: 500,
                  marginBottom: '10px',
                }}
              >
                <span style={{ marginLeft: '5px' }}>
                  <b>
                    <CustomIcon icon="EditRoadIcon" /> Update Rollover Details or Split booking
                  </b>
                </span>
              </div>
            </Col>
          </Row>
          <Row>
            {tableContainers.length > 0 && (
              <Col span={16}>
                <Steps
                  current={current}
                  size="small"
                  type="navigation"
                  onChange={(key: number) => {
                    setCurrent(key);
                    console.log(key, 'key_here');
                  }}
                  items={items}
                ></Steps>
              </Col>
            )}
          </Row>
        </div>
      }
      onClose={onClose}
      open={true}
      bodyStyle={{}}
      headerStyle={{ padding: '16px 16px 0px 24px' }}
      width="60%"
    >
      <RenderSteps />
    </Drawer>
  );
}
