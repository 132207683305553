import React, { useRef, useState, useEffect } from 'react';
import { RoutingLegValue } from 'operations/models/RoutingLeg';
import { ShipmentValue } from 'operations/models/Shipment';
import { omit as _omit, filter as _filter, pick as _pick } from 'lodash';
import { convertToDayJs, dayjs, Dayjs } from '@shipmnts/pixel-hub';
import {
  containerTypes,
  LOAD_TYPE_FCL,
  STUFFING_LOCATION_TYPE_CFS,
} from 'operations/baseConstants';
import { getInitialNodes } from 'operations/components/RoutingDetails';
import BasicDetails from './BasicDetails';
import {
  BUSINESS_TYPE_DIRECT,
  MOVEMENT_MODE_DOOR_TO_DOOR,
  CARGO_TYPE_CONTAINER,
  TRADE_TYPE_EXPORT,
  SHIPMENT_TYPE_DIRECT,
  ASSET_TYPE_OWN,
  SHIPMENT_TYPE_CONSOL,
  TRADE_TYPE_DOMESTIC,
  ROW_GUTTER,
} from 'operations/modules/shipment/constants';
import { FREIGHT_TYPE_OCEAN } from 'sales_hub/utils/constants';
import PartyDetails from '../CommercialInvoice/PartyDetails';
import { Checkbox, Col, DatePicker, Form, Input, Radio, Row, Select } from '@shipmnts/pixel-hub';
import BookingRoutingDetails from 'operations/modules/booking/components/BookingOrderForm/BookingRoutingDetails';
import { AddressCompanySearch } from 'common';
import { LOCATION_TYPE_CFS, LOCATION_TYPE_ICD } from 'network/models/Location';
import ProductOrderItemWrapper from '../ProductOrderItem';
import ShipmentServiceDetails from './ShipmentServiceDetails';
import VehicleDetails from 'operations/modules/shipment/components/ShipmentForm/VehicleDetails';
import { FREIGHT_TYPE_AIR, FREIGHT_TYPE_ROAD, LOAD_TYPE_LOOSE } from 'operations/utils/constants';
import RoadRoutingDetails from 'operations/modules/shipment/components/ShipmentForm/RoadRoutingDetails';
import { MOVEMENT_MODES } from 'operations/modules/shipment/components/ShipmentForm/constants';
import { ProductOrderValue } from 'operations/models/ProductOrder';
import ContainerQuantity from 'operations/modules/shipment/components/ShipmentForm/ContainerQuantity';
import { OceanBookingDetails } from './OceanBookingDetails';
import { OceanLastFreeDays } from './OceanLastFreeDays';
import BLDetails from './BLDetails';
import { ProductOrderItemValue } from 'operations/models/ProductOrderItem';
import { GlobalSearch } from '@shipmnts/pixel-hub';
import { GridOptions } from '@ag-grid-community/core';

// initialValues
export const getRoutingDetails = (
  shipment: ShipmentValue | undefined | null,
  productOrder: ProductOrderValue | undefined | null
) => {
  const routingLegs = shipment?.routing_legs;
  // let routing_nodes: RoutingNodesHashValue = {};
  let routing_nodes: any = {};
  const routing_legs = (routingLegs || []).map((rl: any) => {
    if (rl?.origin?.id) routing_nodes[rl.origin.id] = rl.origin;
    if (rl?.destination?.id) routing_nodes[rl.destination.id] = rl.destination;
    return {
      ...rl,
      origin: productOrder?.port_of_loading || undefined,
      destination: productOrder?.port_of_discharge || undefined,
      origin_id: rl?.origin?.id,
      destination_id: rl?.destination?.id,
    };
  });

  const isPickUp =
    productOrder?.freight_type === 'air' &&
    !!productOrder?.place_of_carrier_receipt &&
    productOrder?.port_of_loading?.id !== productOrder?.place_of_carrier_receipt?.id;
  const isPreCarriage =
    productOrder?.freight_type === 'ocean' &&
    !!productOrder?.place_of_carrier_receipt &&
    productOrder?.port_of_loading?.id !== productOrder?.place_of_carrier_receipt?.id;
  const isOnCarriage =
    productOrder?.freight_type === 'ocean' &&
    !!productOrder?.place_of_carrier_delivery &&
    productOrder?.port_of_discharge?.id !== productOrder?.place_of_carrier_delivery?.id;
  const isDelivery =
    productOrder?.freight_type === 'air' &&
    !!productOrder?.place_of_carrier_delivery &&
    productOrder?.port_of_discharge?.id !== productOrder?.place_of_carrier_delivery?.id;

  if (routing_legs.length === 0 && productOrder) {
    const routingDetails = getInitialNodes('main_carriage');
    const pickUproutingDetails = getInitialNodes('pickup');
    const preroutingDetails = getInitialNodes('pre_carriage');
    const onroutingDetails = getInitialNodes('on_carriage');
    const deliveryroutingDetails = getInitialNodes('delivery');
    if (isPickUp) {
      routing_legs.push({
        routing_type: 'pickup',
        origin_id: pickUproutingDetails.origin._id,
        destination_id: routingDetails.origin._id,
        mode_of_transit:
          productOrder?.freight_type === 'ocean' ? 'sea' : productOrder?.freight_type,
        sequence_number: 1.1,
      });
      routing_nodes = {
        [pickUproutingDetails.origin._id || '']: {
          location: productOrder?.place_of_carrier_receipt,
          company: productOrder?.freight_type === 'road' ? productOrder?.ship_from_company : null,
          address: productOrder?.freight_type === 'road' ? productOrder?.ship_from_address : null,
          tags: ['place_of_carrier_receipt'],
          _id: pickUproutingDetails.origin._id,
        },
      };
    }
    if (isPreCarriage) {
      routing_legs.push({
        routing_type: 'pre_carriage',
        origin_id: preroutingDetails.origin._id,
        destination_id: routingDetails.origin._id,
        mode_of_transit:
          productOrder?.freight_type === 'ocean' ? 'sea' : productOrder?.freight_type,
        sequence_number: 1.1,
      });
      routing_nodes = {
        [preroutingDetails.origin._id || '']: {
          location: productOrder?.place_of_carrier_receipt,
          company: productOrder?.freight_type === 'road' ? productOrder?.ship_from_company : null,
          address: productOrder?.freight_type === 'road' ? productOrder?.ship_from_address : null,
          tags: ['place_of_carrier_receipt'],
          _id: preroutingDetails.origin._id,
        },
      };
    }
    routing_legs.push({
      routing_type: 'main_carriage',
      origin_id: routingDetails.origin._id,
      destination_id: routingDetails.destination._id,
      mode_of_transit: productOrder?.freight_type === 'ocean' ? 'sea' : productOrder?.freight_type,
      sequence_number: 2.1,
    });
    routing_nodes = {
      ...routing_nodes,
      [routingDetails.origin._id || '']: {
        location: productOrder?.port_of_loading,
        company: productOrder?.freight_type === 'road' ? productOrder?.ship_from_company : null,
        address: productOrder?.freight_type === 'road' ? productOrder?.ship_from_address : null,
        tags: ['port_of_loading'],
        _id: routingDetails.origin._id,
      },
      [routingDetails.destination._id || '']: {
        company: productOrder?.freight_type === 'road' ? productOrder?.ship_to_company : null,
        address: productOrder?.freight_type === 'road' ? productOrder?.ship_to_address : null,
        location: productOrder?.port_of_discharge,
        tags: ['port_of_discharge'],
        _id: routingDetails.destination._id,
      },
    };
    if (isOnCarriage) {
      routing_legs.push({
        routing_type: 'on_carriage',
        origin_id: routingDetails.destination._id,
        destination_id: onroutingDetails.destination._id,
        mode_of_transit:
          productOrder?.freight_type === 'ocean' ? 'sea' : productOrder?.freight_type,
        sequence_number: 3.1,
      });
      routing_nodes = {
        ...routing_nodes,
        [onroutingDetails.destination._id || '']: {
          location: productOrder?.place_of_carrier_delivery,
          tags: ['place_of_carrier_delivery'],
          _id: onroutingDetails.destination._id,
        },
      };
    }
    if (isDelivery) {
      routing_legs.push({
        routing_type: 'delivery',
        origin_id: routingDetails.destination._id,
        destination_id: deliveryroutingDetails.destination._id,
        mode_of_transit:
          productOrder?.freight_type === 'ocean' ? 'sea' : productOrder?.freight_type,
        sequence_number: 4.1,
      });
      routing_nodes = {
        ...routing_nodes,
        [deliveryroutingDetails.destination._id || '']: {
          location: productOrder?.place_of_carrier_delivery,
          tags: ['place_of_carrier_delivery'],
          _id: deliveryroutingDetails.destination._id,
        },
      };
    }
  }
  if (routing_legs.length === 0) return;
  return {
    routing_legs,
    routing_nodes,
  };
};
export const getDriverContactDetails = (shipment: ShipmentValue | undefined | null) => {
  const driver_number = (shipment?.driver?.driver_contact_number || '').split(' ');
  const driver_contact_number =
    driver_number.length > 1
      ? {
          dialCode: driver_number[0],
          number: driver_number[1],
        }
      : undefined;
  return driver_contact_number;
};
export const getDefaultInitialValue = () => {
  return {
    movement_mode: MOVEMENT_MODE_DOOR_TO_DOOR,
    cargo_type: CARGO_TYPE_CONTAINER,
    freight_type: FREIGHT_TYPE_OCEAN,
    trade_type: TRADE_TYPE_EXPORT,
    shipment_type: SHIPMENT_TYPE_DIRECT,
    shipment_containers: [{}, {}],
    services: [
      { name: 'freight_forwarding_agent', is_required: false },
      {
        name: 'origin_clearance_agent',
        is_required: false,
      },
      {
        name: 'origin_transport_agent',
        is_required: false,
      },
      {
        name: 'origin_palletization_agent',
        is_required: false,
      },
      {
        name: 'origin_fumigation_agent',
        is_required: false,
      },
      {
        name: 'destination_clearance_agent',
        is_required: false,
      },
      {
        name: 'destination_transport_agent',
        is_required: false,
      },
      {
        name: 'cargo_insurance_agent',
        is_required: false,
      },
      {
        name: 'empty_container_insurance_agent',
        is_required: false,
      },
    ],
  };
};

export const getGenericInitialValue = (
  shipment: ShipmentValue | undefined | null,
  extraValue?: any
) => {
  // const partyList = [
  //   'buyer',
  //   'seller',
  //   'shipper',
  //   'consignee',
  //   'manufacturer',
  //   'notify_party_1',
  //   'subagent',
  //   'to_order',
  //   'to_order_bank',
  //   'agent',
  // ];
  const job_date = new Date(shipment?.job_date || '').getTime() / 1000;
  const driverContactNumber = getDriverContactDetails(shipment);
  const productOrder = extraValue?.shipment_product_order_items
    ? extraValue?.shipment_product_order_items[0]?.product_order
    : '';
  const initialValue = {
    ...getDefaultInitialValue(),
    ...shipment,
    movement_type: shipment?.movement_type || 'dpd',
    destuffing_type: shipment?.destuffing_type || 'factory',
    stuffing_type: shipment?.stuffing_type || 'factory',
    is_letter_of_credit_number_appliable: shipment?.letter_of_credit_number || false,
    sales_agent: shipment?.sales_agent || productOrder?.sales_person,
    is_cargo_ready_date_appliable: shipment?.is_cargo_ready_date_appliable,
    is_purchase_order_number_appliable: shipment?.is_purchase_order_number_appliable,
    is_sales_order_number_appliable: shipment?.is_sales_order_number_appliable,
    cargo_ready_date: shipment?.cargo_ready_date ? dayjs.unix(shipment?.cargo_ready_date) : null,
    target_shipping_date: shipment?.target_shipping_date
      ? dayjs.unix(shipment?.target_shipping_date)
      : null,
    target_delivery_date: shipment?.target_delivery_date
      ? dayjs.unix(shipment?.target_delivery_date)
      : null,
    vehicle: {
      vehicle_details: shipment?.vehicle,
      ...(shipment?.vehicle || {}),
      ownership_type: shipment?.vehicle?.ownership_type || ASSET_TYPE_OWN,
    },
    driver: {
      driver_details: {
        ...shipment?.driver,
        driver_contact_number: driverContactNumber,
      },
      ...shipment?.driver,
      driver_contact_number: driverContactNumber,
    },
    job_date: convertToDayJs(job_date) || dayjs(),
    lfd_at_pod: convertToDayJs(shipment?.lfd_at_pod),
    lfd_at_carrier: convertToDayJs(shipment?.lfd_at_carrier),
    lfd_at_empty_return: convertToDayJs(shipment?.lfd_at_empty_return),
    lfd_at_pocd: convertToDayJs(shipment?.lfd_at_pocd),
    ocean_shipment_booking: {
      ...shipment?.ocean_shipment_booking,
      shipping_line_booking_date: convertToDayJs(
        shipment?.ocean_shipment_booking?.shipping_line_booking_date
      ),
      cy_cutoff_date: convertToDayJs(shipment?.ocean_shipment_booking?.cy_cutoff_date),
      si_cutoff_date: convertToDayJs(shipment?.ocean_shipment_booking?.si_cutoff_date),
      documents_cutoff_date: convertToDayJs(
        shipment?.ocean_shipment_booking?.documents_cutoff_date
      ),
      booking_validity_date: convertToDayJs(
        shipment?.ocean_shipment_booking?.booking_validity_date
      ),
      id: shipment?.ocean_shipment_booking?.id,
    },
    shipment_document_master: {
      ...(shipment?.shipment_documents && shipment?.shipment_documents[0]),
      shipment_date:
        shipment?.shipment_documents &&
        convertToDayJs(shipment?.shipment_documents[0]?.shipment_date),
      id: shipment?.shipment_documents && shipment?.shipment_documents[0]?.id,
      bl_type:
        shipment?.shipment_documents && shipment?.shipment_documents[0]?.bl_type
          ? shipment?.shipment_documents[0]?.bl_type
          : 'original',
    },
    master: {
      shipment_document: {
        shipment_number:
          shipment?.shipment_documents &&
          (shipment.freight_type === 'air'
            ? shipment?.shipment_documents[0]?.shipment_number?.slice(3)
            : shipment?.shipment_documents[0]?.shipment_number),
      },
    },
    document_parties: {
      issuing_party: {
        id: shipment?.shipment_parties?.filter((sp: any) => sp.name === 'issuing_party')[0]?.id,
        party_company: shipment?.shipment_parties?.filter(
          (sp: any) => sp.name === 'issuing_party'
        )[0]?.party_company,
        party_address: shipment?.shipment_parties?.filter(
          (sp: any) => sp.name === 'issuing_party'
        )[0]?.party_address,
      },
      [shipment?.consignee_party_name || '']: {
        id: shipment?.shipment_parties?.filter(
          (sp: any) => sp.name === shipment?.consignee_party_name
        )[0]?.id,
        party_details: shipment?.shipment_parties?.filter(
          (sp: any) => sp.name === shipment?.consignee_party_name
        )[0]?.party_details,
      },
    },
    received_for_shipment_by_forwarder_date: convertToDayJs(
      shipment?.received_for_shipment_by_forwarder_date
    ),
    shipped_on_board_date: convertToDayJs(shipment?.shipped_on_board_date),
    letter_of_credit_date: convertToDayJs(shipment?.letter_of_credit_date),
    involved_branch_id: shipment?.involved_branch?.id || productOrder?.branch?.id,
    business_type:
      shipment?.business_type || productOrder?.business_through || BUSINESS_TYPE_DIRECT,
    incoterms: shipment?.incoterms || productOrder?.incoterm,
    load_type:
      shipment?.load_type ||
      productOrder?.load_type ||
      (extraValue?.freight_type === 'air' ? LOAD_TYPE_LOOSE : LOAD_TYPE_FCL),
    place_of_carrier_delivery_id:
      shipment?.place_of_carrier_delivery?.id || productOrder?.place_of_carrier_delivery,
    place_of_carrier_receipt_id:
      shipment?.place_of_carrier_receipt?.id || productOrder?.place_of_carrier_receipt,
    vessel: shipment?.ocean_vessel,
    carrier: shipment?.carrier,
    estimated_time_of_arrival: convertToDayJs(shipment?.estimated_time_of_arrival),
    estimated_time_of_departure: convertToDayJs(shipment?.estimated_time_of_departure),
    shipping_bill_details: _filter(shipment?.shipment_custom_details, {
      trade_type: 'export',
    }).map((sb: any) => ({
      id: sb.id,
      document_number: sb.custom_document_number,
      document_date: convertToDayJs(sb.custom_document_date),
      country: sb.country || sb.custom_clearance_location?.country_code,
      custom_clearance_location: sb.custom_clearance_location,
      trade_type: sb.trade_type,
    })),
    import_custom_details: _filter(shipment?.shipment_custom_details, {
      trade_type: 'import',
    }).map((sb: any) => ({
      id: sb.id,
      document_number: sb.custom_document_number,
      document_date: convertToDayJs(sb.custom_document_date),
      country: sb.country || sb.custom_clearance_location?.country_code,
      custom_clearance_location: sb.custom_clearance_location,
      trade_type: sb.trade_type,
    })),
    shipment_parties: [
      {
        name: 'shipper',
        party_company: productOrder?.ship_from_company,
        party_address: productOrder?.ship_from_address,
        party_details: productOrder?.print_as?.shipper,
      },
      {
        name: 'consignee',
        party_company: productOrder?.ship_to_company,
        party_address: productOrder?.ship_to_address,
        party_details: productOrder?.print_as?.consignee,
      },
      {
        name: 'buyer',
        party_company: productOrder?.buyer,
        party_address: productOrder?.buyer_address,
        party_details: productOrder?.print_as?.buyer,
      },
      {
        name: 'seller',
        party_company: productOrder?.seller,
        party_address: productOrder?.seller_address,
        party_details: productOrder?.print_as?.seller,
      },
      {
        name: 'manufacturer',
      },
    ],
    services: getServicesInitial(
      shipment?.trade_type || 'export',
      shipment?.services,
      shipment?.shipment_parties
    ),
    // routing_details: getRoutingDetails(shipment, productOrder),
    stuffing_location: shipment?.stuffing_location
      ? shipment?.stuffing_location?.port
        ? shipment?.stuffing_location?.port
        : {
            party_company: shipment?.stuffing_location?.company,
            party_address: shipment?.stuffing_location?.company_address,
          }
      : null,
    destuffing_location: shipment?.destuffing_location
      ? shipment?.destuffing_location?.port
        ? shipment?.destuffing_location?.port
        : {
            party_company: shipment?.destuffing_location?.company,
            party_address: shipment?.destuffing_location?.company_address,
          }
      : null,
    ...extraValue,
  };
  return initialValue;
};
export const getGenericInitialValueForDuplicate = (shipment: ShipmentValue, extraValue?: any) => {
  const partyList = ['buyer', 'seller', 'shipper', 'consignee'];
  const job_date = new Date().getTime() / 1000;
  const driverContactNumber = getDriverContactDetails(shipment);
  const initialValue: any = {
    ...extraValue,
    freight_type: shipment?.freight_type,
    trade_type: shipment?.trade_type,
    cargo_type: shipment?.cargo_type || CARGO_TYPE_CONTAINER,
    load_type: shipment?.load_type || LOAD_TYPE_FCL,
    destuffing_type: shipment?.destuffing_type || 'factory',
    stuffing_type: shipment?.stuffing_type || 'factory',
    vehicle: {
      vehicle_details: shipment?.vehicle,
      ...(shipment?.vehicle || {}),
      ownership_type: shipment?.vehicle?.ownership_type || ASSET_TYPE_OWN,
    },
    driver: {
      driver_details: {
        ...shipment?.driver,
        driver_contact_number: driverContactNumber,
      },
      ...shipment?.driver,
      driver_contact_number: driverContactNumber,
    },
    job_date: convertToDayJs(job_date) || dayjs(),
    lfd_at_pod: convertToDayJs(shipment?.lfd_at_pod),
    lfd_at_carrier: convertToDayJs(shipment?.lfd_at_carrier),
    lfd_at_empty_return: convertToDayJs(shipment?.lfd_at_empty_return),
    lfd_at_pocd: convertToDayJs(shipment?.lfd_at_pocd),
    ocean_shipment_booking: {
      ...shipment?.ocean_shipment_booking,
      shipping_line_booking_date: convertToDayJs(
        shipment?.ocean_shipment_booking?.shipping_line_booking_date
      ),
      cy_cutoff_date: convertToDayJs(shipment?.ocean_shipment_booking?.cy_cutoff_date),
      si_cutoff_date: convertToDayJs(shipment?.ocean_shipment_booking?.si_cutoff_date),
      documents_cutoff_date: convertToDayJs(
        shipment?.ocean_shipment_booking?.documents_cutoff_date
      ),
      booking_validity_date: convertToDayJs(
        shipment?.ocean_shipment_booking?.booking_validity_date
      ),
      id: shipment?.ocean_shipment_booking?.id,
    },
    business_type: shipment?.business_type || BUSINESS_TYPE_DIRECT,
    involved_branch_id: shipment?.involved_branch?.id,
    movement_mode: shipment?.movement_mode || MOVEMENT_MODE_DOOR_TO_DOOR,
    shipment_containers: [{}, {}],
    stuffing_location: shipment?.stuffing_location
      ? shipment?.stuffing_location?.port
        ? shipment?.stuffing_location?.port
        : {
            party_company: shipment?.stuffing_location?.company,
            party_address: shipment?.stuffing_location?.company_address,
          }
      : null,
    destuffing_location: shipment?.destuffing_location
      ? shipment?.destuffing_location?.port
        ? shipment?.destuffing_location?.port
        : {
            party_company: shipment?.destuffing_location?.company,
            party_address: shipment?.destuffing_location?.company_address,
          }
      : null,
    shipment_product_order_items: extraValue?.shipment_product_order_items.map((spoi: any) =>
      _omit(spoi, [
        'id',
        'target_delivery_date',
        'target_shipping_date',
        'sales_order_number',
        'purchase_order_number',
        'cargo_ready_date',
      ])
    ),
    shipment_parties: shipment?.shipment_parties
      ?.filter((sp: any) => partyList.includes(sp.name))
      ?.map((sp: any) => _omit(sp, ['id'])),
    incoterms: shipment?.incoterms,
    services: getServicesInitial(
      shipment?.trade_type,
      shipment?.services,
      shipment?.shipment_parties
    )?.map((service: any) => _omit(service, ['id'])),
  };
  if (shipment?.routing_legs) {
    const routingDetails = getRoutingDetails(shipment, undefined);
    initialValue['routing_details'] = {
      routing_nodes: routingDetails?.routing_nodes,
      routing_legs: (routingDetails?.routing_legs || []).map((rl) => _omit(rl, 'id')),
    };
  }
  return initialValue;
};

//payload
export const getRoutingLegsPayload = (values: any, shipment: ShipmentValue | undefined | null) => {
  const routingLegs = (values.routing_details?.routing_legs || []).map((rl: RoutingLegValue) => {
    const {
      vessel,
      global_carrier,
      estimated_time_of_departure,
      estimated_time_of_arrival,
      ...restRL
    } = rl;
    return {
      ...restRL,
      vessel_id: vessel?.imo,
      global_carrier_id: global_carrier?.id,
      estimated_time_of_departure: estimated_time_of_departure?.unix(),
      estimated_time_of_arrival: estimated_time_of_arrival?.unix(),
    };
  });

  (shipment?.routing_legs || []).forEach((rl: RoutingLegValue) => {
    const {
      global_carrier,
      vessel,
      origin,
      destination,
      estimated_time_of_departure,
      estimated_time_of_arrival,
      ...restRL
    } = rl;
    const routingLegExsist = (values.routing_details?.routing_legs || []).find(
      (c: any) => c.id === rl.id
    );
    if (!routingLegExsist) {
      const formatedRoutingLeg = {
        ...restRL,
        vessel_id: vessel?.imo,
        global_carrier_id: global_carrier?.id,
        origin_id: origin?.id,
        destination_id: destination?.id,
        estimated_time_of_departure: estimated_time_of_departure?.unix(),
        estimated_time_of_arrival: estimated_time_of_arrival?.unix(),
      };
      routingLegs.push({ ...formatedRoutingLeg, _destroy: true });
    }
  });

  return routingLegs;
};

export const shipmentCustomDetailPayload = (
  values: any,
  shipment: ShipmentValue | undefined | null
) => {
  const shipmentCustoms = (values.shipping_bill_details || [])
    .concat(values.import_custom_details || [])
    .map((sb: any) => ({
      id: sb.id,
      custom_document_number: sb.document_number,
      custom_document_date: sb.document_date?.format('YYYY-MM-DD'),
      country: sb.country || sb.custom_clearance_location?.country_code,
      custom_clearance_location_id: sb.custom_clearance_location?.id,
      trade_type: sb.trade_type,
    }));

  (shipment?.shipment_custom_details || []).forEach((sb) => {
    const scExists = (shipmentCustoms || []).find((c: any) => c.id === sb.id);
    if (!scExists) {
      shipmentCustoms.push({
        id: sb.id,
        custom_document_number: sb.custom_document_number,
        custom_document_date: sb.custom_document_date,
        country: sb.country || sb.custom_clearance_location?.country_code,
        custom_clearance_location_id: sb.custom_clearance_location?.id,
        trade_type: sb.trade_type,
        _destroy: true,
      });
    }
  });

  return shipmentCustoms.length > 0 ? shipmentCustoms : undefined;
};
export const getVehiclePayload = (values: any) => {
  let vehicle = values.vehicle;
  if (!vehicle?.vehicle_details?.vehicle_license_plate_number) {
    return undefined;
  }
  vehicle = {
    ...vehicle,
    vehicle_license_plate_number: vehicle?.vehicle_details?.vehicle_license_plate_number,
    id: vehicle?.vehicle_details?.id,
    company_id: vehicle?.company?.id,
  };
  return _omit(vehicle, ['vehicle_details', 'company']);
};
export const getDriverPayload = (values: any) => {
  let driver = values.driver;
  if (!driver?.driver_details?.driver_name) {
    return undefined;
  }
  driver = {
    ...driver,
    driver_name: driver?.driver_details?.driver_name,
    driver_contact_number: driver?.driver_contact_number?.number
      ? (driver?.driver_contact_number?.dialCode || '') +
        ' ' +
        (driver?.driver_contact_number?.number || '')
      : undefined,
    id: driver?.driver_details?.id,
  };
  return _omit(driver, 'driver_details');
};
export const getContainerFromContainerQty = (values: any) => {
  const container_qty = values?.shipment_container_quantity;
  const shipment_container_from_qty: any[] = [];

  (container_qty || []).forEach((q: any) => {
    for (let i = 0; i < q.quantity; i++) {
      shipment_container_from_qty.push({
        container_type_code: q.container_type_code,
        container_type: containerTypes[q.container_type_code],
        container_settings: q.container_settings,
        cargo_gross_weight: q.weight_per_container,
        maximum_cargo_volume: q.maximum_cargo_volume,
        maximum_cargo_weight: q.maximum_cargo_weight,
      });
    }
  });
  return shipment_container_from_qty;
};

export const getShipmentPartiesPayload = (
  shipment: ShipmentValue | undefined | null,
  shipment_parties: any,
  services: any,
  tradeType: any,
  documentParties: any
) => {
  const serviceParty: any = [];
  services.forEach((sp: any) => {
    if (sp.is_required) {
      serviceParty.push({ ..._omit(sp, 'is_required') });
    }
  });

  const allParties = serviceParty.concat(shipment_parties).map((party: any) => {
    return {
      id: party?.id,
      name: party?.name,
      party_company_id: party?.party_company?.id,
      party_address_id: party?.party_address?.id,
      party_details: party?.party_details,
    };
  });

  documentParties &&
    Object.keys(documentParties)?.forEach((doc_party: any) => {
      if (doc_party === 'issuing_party') {
        allParties.push({
          name: 'issuing_party',
          party_address_id: documentParties['issuing_party']?.party_address?.id,
          party_company_id: documentParties['issuing_party']?.party_company?.id,
          id: shipment?.shipment_parties?.filter((sp: any) => sp.name === 'issuing_party')[0]?.id,
        });
      }
      if (doc_party === 'to_order' || doc_party === 'to_order_bank') {
        allParties.push({
          name: doc_party,
          party_details: documentParties[doc_party]?.party_details,
          id: shipment?.shipment_parties?.filter((sp: any) => sp.name === doc_party)[0]?.id,
        });
      }
    });

  return allParties;
};

export const getServicesInitial = (tradeType: any, values: any, parties: any) => {
  const services = [
    {
      id: parties?.filter((sp: any) => sp.name === 'freight_forwarding_agent')[0]?.id,
      name: 'freight_forwarding_agent',
      is_required: values?.freight_forwarding || true,
      party_company: parties?.filter((sp: any) => sp.name === 'freight_forwarding_agent')[0]
        ?.party_company,
      party_address: parties?.filter((sp: any) => sp.name === 'freight_forwarding_agent')[0]
        ?.party_address,
    },
    {
      id: parties?.filter((sp: any) => sp.name === 'nominee')[0]?.id,
      name: 'nominee',
      is_required: values?.freight_forwarding || true,
      party_company: parties?.filter((sp: any) => sp.name === 'nominee')[0]?.party_company,
      party_address: parties?.filter((sp: any) => sp.name === 'nominee')[0]?.party_address,
    },
    {
      id: parties?.filter((sp: any) => sp.name === 'origin_clearance_agent')[0]?.id,
      name: 'origin_clearance_agent',
      is_required: values?.origin?.hasOwnProperty('clearance')
        ? values?.origin?.clearance
        : tradeType === 'export',
      party_company: parties?.filter((sp: any) => sp.name === 'origin_clearance_agent')[0]
        ?.party_company,
      party_address: parties?.filter((sp: any) => sp.name === 'origin_clearance_agent')[0]
        ?.party_address,
    },
    {
      id: parties?.filter((sp: any) => sp.name === 'origin_transport_agent')[0]?.id,
      name: 'origin_transport_agent',
      is_required: values?.origin?.transport,
      party_company: parties?.filter((sp: any) => sp.name === 'origin_transport_agent')[0]
        ?.party_company,
      party_address: parties?.filter((sp: any) => sp.name === 'origin_transport_agent')[0]
        ?.party_address,
    },
    {
      id: parties?.filter((sp: any) => sp.name === 'origin_palletization_agent')[0]?.id,
      name: 'origin_palletization_agent',
      is_required: values?.origin?.palletization,
      party_company: parties?.filter((sp: any) => sp.name === 'origin_palletization_agent')[0]
        ?.party_company,
      party_address: parties?.filter((sp: any) => sp.name === 'origin_palletization_agent')[0]
        ?.party_address,
    },
    {
      id: parties?.filter((sp: any) => sp.name === 'origin_fumigation_agent')[0]?.id,
      name: 'origin_fumigation_agent',
      is_required: values?.origin?.fumigation,
      party_company: parties?.filter((sp: any) => sp.name === 'origin_fumigation_agent')[0]
        ?.party_company,
      party_address: parties?.filter((sp: any) => sp.name === 'origin_fumigation_agent')[0]
        ?.party_address,
    },
    {
      id: parties?.filter((sp: any) => sp.name === 'destination_clearance_agent')[0]?.id,
      name: 'destination_clearance_agent',
      is_required: values?.destination?.hasOwnProperty('clearance')
        ? values?.destination?.clearance
        : tradeType === 'import',
      party_company: parties?.filter((sp: any) => sp.name === 'destination_clearance_agent')[0]
        ?.party_company,
      party_address: parties?.filter((sp: any) => sp.name === 'destination_clearance_agent')[0]
        ?.party_address,
    },
    {
      id: parties?.filter((sp: any) => sp.name === 'destination_transport_agent')[0]?.id,
      name: 'destination_transport_agent',
      is_required: values?.destination?.transport,
      party_company: parties?.filter((sp: any) => sp.name === 'destination_transport_agent')[0]
        ?.party_company,
      party_address: parties?.filter((sp: any) => sp.name === 'destination_transport_agent')[0]
        ?.party_address,
    },
    {
      id: parties?.filter((sp: any) => sp.name === 'cargo_insurance_agent')[0]?.id,
      name: 'cargo_insurance_agent',
      is_required: values?.cargo_insurance,
      party_company: parties?.filter((sp: any) => sp.name === 'cargo_insurance_agent')[0]
        ?.party_company,
      party_address: parties?.filter((sp: any) => sp.name === 'cargo_insurance_agent')[0]
        ?.party_address,
    },
    {
      id: parties?.filter((sp: any) => sp.name === 'empty_container_insurance_agent')[0]?.id,
      name: 'empty_container_insurance_agent',
      is_required: values?.empty_container_insurance,
      party_company: parties?.filter((sp: any) => sp.name === 'empty_container_insurance_agent')[0]
        ?.party_company,
      party_address: parties?.filter((sp: any) => sp.name === 'empty_container_insurance_agent')[0]
        ?.party_address,
    },
  ];
  return services;
};
export const getServicesPayload = (values: any) => {
  let temp: any = {};
  let origin: any = {};
  let destination: any = {};
  values.forEach((service: any) => {
    if (service.name === 'freight_forwarding_agent')
      temp = {
        ...temp,
        freight_forwarding: service.is_required,
      };
    else if (service.name === 'cargo_insurance_agent')
      temp = {
        ...temp,
        cargo_insurance: service.is_required,
      };
    else if (service.name === 'empty_container_insurance_agent')
      temp = {
        ...temp,
        empty_container_insurance: service.is_required,
      };
    else if (service.name === 'origin_clearance_agent')
      origin = {
        ...origin,
        clearance: service.is_required,
      };
    else if (service.name === 'origin_transport_agent')
      origin = {
        ...origin,
        transport: service.is_required,
      };
    else if (service.name === 'origin_palletization_agent')
      origin = {
        ...origin,
        palletization: service.is_required,
      };
    else if (service.name === 'origin_fumigation_agent')
      origin = {
        ...origin,
        fumigation: service.is_required,
      };
    else if (service.name === 'destination_clearance_agent')
      destination = {
        ...destination,
        clearance: service.is_required,
      };
    else if (service.name === 'destination_transport_agent')
      destination = {
        ...destination,
        transport: service.is_required,
      };
  });
  return {
    ...temp,
    origin: {
      ...origin,
    },
    destination: {
      ...destination,
    },
  };
};

const getProductOrderItemPayload = (
  productOrderItem: ProductOrderItemValue[],
  oldItem: any,
  type: any
) => {
  if (type === 'update') {
    (oldItem || []).forEach((old: ProductOrderItemValue) => {
      if (!(productOrderItem || []).find((c: ProductOrderItemValue) => c.id === old.id))
        productOrderItem.push({
          ...old,
          _destroy: true,
        });
    });
  }

  return (productOrderItem || []).map((sp: ProductOrderItemValue) => {
    if (type === 'duplicate')
      return {
        ..._omit(sp, [
          'product_order',
          '__typename',
          'linked_product_order_items',
          'linked_to',
          'linked_to_id',
          'existing_shipment_planned_qty',
          'shipment',
          'custom_field_values',
        ]),
        order_qty: sp?.order_qty || sp?.shipment_planned_qty,
      };
    else
      return {
        ..._omit(sp, [
          'product_order',
          '__typename',
          'linked_product_order_items',
          'linked_to',
          'existing_shipment_planned_qty',
          'shipment',
          'custom_field_values',
        ]),
        order_qty: sp?.order_qty || sp?.shipment_planned_qty,
      };
  });
};

export const getGenericPayloadValue = (
  values: any,
  shipment?: ShipmentValue | undefined | null,
  productOrderItems?: ProductOrderItemValue[],
  type?: any
) => {
  let portOfLoadingId: any, portOfDischargeId: any;
  const payload = {
    ...values,
    cargo_ready_date: values.cargo_ready_date?.unix() || null,
    target_delivery_date: values.target_delivery_date?.unix() || null,
    target_shipping_date: values.target_shipping_date?.unix() || null,
    sales_agent_id: values?.sales_agent?.id,
    // shipment_document_master: {
    //   ...values?.shipment_document_master,
    //   shipment_date: values?.shipment_document_master?.shipment_date?.unix(),
    //   shipment_number: values?.master?.shipment_document?.shipment_number
    //     ? `${
    //         values?.freight_type === 'air' && values?.iata_awb_prefix ? values?.iata_awb_prefix : ''
    //       }${values?.master?.shipment_document?.shipment_number}`
    //     : null,
    //   id: shipment?.shipment_documents ? shipment?.shipment_documents[0]?.id : undefined,
    // },
    consol_type: 'agent',
    ocean_shipment_booking: {
      ...values.ocean_shipment_booking,
      shipping_line_booking_date:
        values?.ocean_shipment_booking?.shipping_line_booking_date?.unix(),
      cy_cutoff_date: values?.ocean_shipment_booking?.cy_cutoff_date?.unix(),
      si_cutoff_date: values?.ocean_shipment_booking?.si_cutoff_date?.unix(),
      documents_cutoff_date: values?.ocean_shipment_booking?.documents_cutoff_date?.unix(),
      booking_validity_date: values?.ocean_shipment_booking?.booking_validity_date?.unix(),
      id: shipment?.ocean_shipment_booking?.id,
    },
    consignee_party_name: values?.consignee_party_name,
    vehicle: getVehiclePayload(values),
    driver: getDriverPayload(values),
    customer_company_id:
      values?.trade_type === 'import'
        ? null
        : values?.shipment_parties?.filter((sp: any) => sp.name === 'buyer')[0]?.party_company?.id,
    customer_address_id:
      values?.trade_type === 'import'
        ? null
        : values?.shipment_parties?.filter((sp: any) => sp.name === 'buyer')[0]?.party_address?.id,
    inquiry_option_id: values?.inquiry_option?.id,
    ocean_vessel_id: values.ocean_vessel?.imo,
    stuffing_location_id:
      values?.stuffing_type === 'factory'
        ? values?.stuffing_location?.party_address?.id
        : values?.stuffing_location?.id,
    destuffing_location_id:
      values?.destuffing_type === 'factory'
        ? values?.destuffing_location?.party_address?.id
        : values?.destuffing_location?.id,
    letter_of_credit_date: values?.letter_of_credit_date?.unix(),
    routing_legs: getRoutingLegsPayload(values, shipment),
    routing_nodes: Object.values(values.routing_details?.routing_nodes || {})?.map((rn: any) => {
      const { location, address, company, terminal, ...restRN } = rn;
      if (rn?.tags[0] === 'port_of_loading') {
        portOfLoadingId = location?.id;
      }
      if (rn?.tags[0] === 'port_of_discharge') {
        portOfDischargeId = location?.id;
      }
      return {
        ...JSON.parse(JSON.stringify(restRN)),
        location_id: location?.id || null,
        terminal_id: terminal?.id || null,
        address_id: address?.id || null,
        company_id: company?.id || null,
        location: undefined,
        address: undefined,
        company: undefined,
        terminal: undefined,
      };
    }),
    carrier_id: values.carrier?.id,
    port_of_loading_id: portOfLoadingId,
    port_of_discharge_id: portOfDischargeId,
    shipment_parties: getShipmentPartiesPayload(
      shipment,
      values?.shipment_parties,
      values?.services,
      values?.trade_type,
      values?.document_parties
    ),
    shipment_containers: values.shipment_containers
      ? (values.shipment_containers || [])
          .filter((c: { container_number: string; pickup_date: Dayjs }) => c.container_number)
          .map((c: { container_number: string; pickup_date: Dayjs }) => ({
            ..._pick(c, [
              'id',
              'is_non_iso_container',
              'container_number',
              'carrier_seal_number',
              'cargo_gross_weight',
              'verified_gross_mass',
              'cargo_net_weight',
              'commercial_invoice_number',
              'purchase_order_number',
              'container_type',
              'container_type_code',
              'remarks',
            ]),
          }))
      : undefined,
    shipment_custom_details: shipmentCustomDetailPayload(values, shipment),
    linked_shipment_id: values?.linked_to_shipment?.id,
    shipment_product_order_items: getProductOrderItemPayload(
      values?.shipment_product_order_items,
      productOrderItems,
      type
    ),
    services: getServicesPayload(values?.services),
  };
  if (
    values?.load_type === LOAD_TYPE_FCL &&
    values?.shipment_container_quantity &&
    values?.shipment_container_quantity.length > 0
  ) {
    payload.shipment_containers = getContainerFromContainerQty(values);
  }
  const omitFields = [
    'routing_details',
    'iata_awb_prefix',
    'ocean_vessel',
    'carrier',
    'party',
    'customer',
    'pickup_containers',
    'import_custom_details',
    'shipping_bill_details',
    'linked_to_shipment',
    'inquiry_option',
    'estimated_time_of_departure',
    'estimated_time_of_arrival',
    'shipment_container_quantity',
    'is_letter_of_credit_number_appliable',
    'id',
    'stuffing_location',
    'destuffing_location',
    'master',
    'document_parties',
    'sales_agent',
  ];
  if (payload.shipment_type !== SHIPMENT_TYPE_CONSOL) {
    omitFields.push('consol_type');
  }
  if (!payload.shipment_type) {
    omitFields.push('shipment_containers');
  }
  return _omit(JSON.parse(JSON.stringify(payload)), omitFields);
};

export const getServices = (tradeType: string) => {
  let services: any[] = [];

  if (tradeType === TRADE_TYPE_DOMESTIC) {
    services = [
      {
        name: ['origin', 'palletization'],
      },
      {
        name: ['cargo_insurance'],
      },
    ];
  } else {
    services = [
      {
        name: ['origin', 'clearance'],
      },
      {
        name: ['cargo_insurance'],
      },
      {
        name: ['destination', 'clearance'],
      },
      {
        name: ['origin', 'palletization'],
      },
    ];
  }
  return services;
};

const PartyDetailsWrapper = (props: any) => {
  const gridRef = useRef<GridOptions>();
  return (
    <Form.Item name={'shipment_parties'}>
      <PartyDetails {...props} gridRef={gridRef} />
    </Form.Item>
  );
};

const ShipmentServiceDetailsWrapper = (props: any) => {
  return (
    <Form.Item name={'services'}>
      <ShipmentServiceDetails {...props} editable={true} />
    </Form.Item>
  );
};

const RoutingDetailsWrapper = (props: any) => {
  const routing_details_ref = useRef<{ runValidation: () => boolean }>();
  const tradeType = Form.useWatch('trade_type', props.form);
  const freightType = Form.useWatch('freight_type', props.form) || props.freightType;
  if (freightType === FREIGHT_TYPE_ROAD)
    return (
      <RoadRoutingDetails
        shipment={props.shipment}
        form={props.form}
        allowAddNew={props.type !== 'update'}
      />
    );

  return (
    <>
      {tradeType === 'export' && (
        <Row gutter={ROW_GUTTER}>
          <Col span={6}>
            <Form.Item label="Stuffing Type" name="stuffing_type" style={{ width: '100%' }}>
              <Radio.Group
                options={[
                  { label: 'Factory', value: 'factory' },
                  { label: 'CFS', value: 'cfs' },
                  { label: 'ICD', value: 'icd' },
                ]}
                disabled={false}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item noStyle shouldUpdate={true}>
              {({ getFieldValue }) => {
                const stuffingType = getFieldValue('stuffing_type');
                return (
                  <Form.Item name="stuffing_location" label="Stuffing Location">
                    {stuffingType === 'factory' ? (
                      <AddressCompanySearch
                        companySearchProps={{
                          searchProps: { is_customer: true },
                          disabled: !props.editable,
                          showCreditStatusTag: true,
                        }}
                        addressSearchProps={{ disabled: !props.editable }}
                      />
                    ) : (
                      <GlobalSearch
                        doc_type="Global::Location"
                        disabled={!props.editable}
                        searchProps={{
                          type: [
                            stuffingType === STUFFING_LOCATION_TYPE_CFS
                              ? LOCATION_TYPE_CFS
                              : LOCATION_TYPE_ICD,
                          ],
                        }}
                      />
                    )}
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
      )}
      <Form.Item
        name="routing_details"
        rules={[
          {
            validator: (rule, value) => {
              if (!routing_details_ref?.current?.runValidation()) {
                return Promise.resolve();
              }
              return Promise.reject();
            },
          },
        ]}
        noStyle
      >
        <BookingRoutingDetails
          ref={routing_details_ref}
          validateVesselVoyage={false}
          freightType={freightType}
          showPickupSection={true}
          showDeliverySection={true}
          onlyTranshipmentHopEditable={!props.editable}
        />
      </Form.Item>
      {tradeType === 'import' && freightType === 'ocean' && (
        <Row gutter={ROW_GUTTER}>
          <Col span={6}>
            <Form.Item label="Movement Type" name="movement_type" style={{ width: '100%' }}>
              <Radio.Group
                options={[
                  {
                    label: 'DPD',
                    value: 'dpd',
                  },
                  {
                    label: 'DPD-CFS',
                    value: 'dpd-cfs',
                  },
                  {
                    label: 'CFS',
                    value: 'cfs',
                  },
                  {
                    label: 'ICD',
                    value: 'icd',
                  },
                ]}
                disabled={false}
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item label="De-Stuffing Type" name="destuffing_type" style={{ width: '100%' }}>
              <Radio.Group
                options={[
                  { label: 'Factory', value: 'factory' },
                  { label: 'CFS', value: 'cfs' },
                  { label: 'ICD', value: 'icd' },
                ]}
                disabled={false}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item noStyle shouldUpdate={true}>
              {({ getFieldValue }) => {
                const stuffingType = getFieldValue('destuffing_type');
                return (
                  <Form.Item name="destuffing_location" label="De-Stuffing Location">
                    {stuffingType === 'factory' ? (
                      <AddressCompanySearch
                        companySearchProps={{
                          searchProps: { is_customer: true },
                          disabled: false,
                          showCreditStatusTag: true,
                        }}
                        addressSearchProps={{ disabled: false }}
                      />
                    ) : (
                      <GlobalSearch
                        doc_type="Global::Location"
                        searchProps={{
                          type: [
                            stuffingType === STUFFING_LOCATION_TYPE_CFS
                              ? LOCATION_TYPE_CFS
                              : LOCATION_TYPE_ICD,
                          ],
                        }}
                      />
                    )}
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
      )}
    </>
  );
};

const ProductDetailsWrapper = (props: any) => {
  const gridRef = useRef<any>();
  const { form, linkedItems, duplicate } = props;

  const showOrderNumbers = !duplicate && linkedItems?.length ? false : true;
  const [targetDeliveryDate, setTargetDeliveryDate] = useState(null);
  const [cargoReadyDate, setCargoReadyDate] = useState(null);

  useEffect(() => {
    form.setFieldsValue({ target_delivery_date: targetDeliveryDate });
    form.setFieldsValue({ cargo_ready_date: cargoReadyDate });
  }, [targetDeliveryDate, cargoReadyDate, form]);

  const disabledDateCRD = (current: any): boolean => {
    return targetDeliveryDate
      ? current
        ? current.isAfter(targetDeliveryDate, 'day')
        : false
      : false;
  };

  const disabledDateTDD = (current: any): boolean => {
    return cargoReadyDate ? (current ? current.isBefore(cargoReadyDate, 'day') : false) : false;
  };

  return (
    <>
      <Row gutter={ROW_GUTTER}>
        {showOrderNumbers && (
          <>
            <Col span={6}>
              <Form.Item noStyle dependencies={[['is_sales_order_number_appliable']]}>
                {({ getFieldValue }) => {
                  const is_sales_order_number_appliable = getFieldValue(
                    'is_sales_order_number_appliable'
                  );
                  return (
                    <Form.Item name="sales_order_number" label="Sales Order #">
                      <Input
                        placeholder="Sales Order #"
                        disabled={!is_sales_order_number_appliable}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
              <Form.Item name="is_sales_order_number_appliable" valuePropName="checked">
                <Checkbox>Same Sales Order # for all products</Checkbox>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item noStyle dependencies={[['is_purchase_order_number_appliable']]}>
                {({ getFieldValue }) => {
                  const is_purchase_order_number_appliable = getFieldValue(
                    'is_purchase_order_number_appliable'
                  );
                  return (
                    <Form.Item name="purchase_order_number" label="Purchase Order #">
                      <Input
                        placeholder="Purchase Order #"
                        disabled={!is_purchase_order_number_appliable}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
              <Form.Item name="is_purchase_order_number_appliable" valuePropName="checked">
                <Checkbox>Same Purchase Order # for all products</Checkbox>
              </Form.Item>
            </Col>
          </>
        )}

        <Col span={6}>
          <Form.Item noStyle dependencies={[['is_cargo_ready_date_appliable']]}>
            {({ getFieldValue }) => {
              const is_cargo_ready_date_appliable = getFieldValue('is_cargo_ready_date_appliable');
              return (
                <Form.Item name="cargo_ready_date" label="Cargo Ready / Material Required Date">
                  <DatePicker
                    disabled={!is_cargo_ready_date_appliable}
                    showTime={false}
                    style={{ width: '100%' }}
                    // FIXME:
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    onChange={(date) => setCargoReadyDate(date)}
                    disabledDate={(current) => disabledDateCRD(current)}
                    format="DD-MM-YYYY"
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item name="is_cargo_ready_date_appliable" valuePropName="checked">
            <Checkbox>Same CRD for all products</Checkbox>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="target_shipping_date"
            label="Target Shipping Date"
            tooltip="It's always Same Target Shipping Date for all products"
          >
            <DatePicker showTime={false} style={{ width: '100%' }} format="DD-MM-YYYY" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="target_delivery_date"
            label="Target Delivery Date"
            tooltip="It's always Same Target Delivery Date for all products"
          >
            <DatePicker
              showTime={false}
              style={{ width: '100%' }}
              // FIXME:
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onChange={(date) => setTargetDeliveryDate(date)}
              disabledDate={(current) => disabledDateTDD(current)}
              format="DD-MM-YYYY"
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name={'shipment_product_order_items'}>
        <ProductOrderItemWrapper {...props} gridRef={gridRef} />
      </Form.Item>
    </>
  );
};

const VehicleDetailsWrapper = (props: any) => {
  const freightType = Form.useWatch('freight_type', props.form);
  if (freightType === FREIGHT_TYPE_ROAD)
    return (
      <>
        <VehicleDetails {...props} />
        <Row gutter={ROW_GUTTER}>
          <Col span={8}>
            <Form.Item rules={[{ required: true }]} name={'movement_mode'} label="Movement Mode">
              <Select placeholder="Movement Mode">
                {MOVEMENT_MODES.map((movementMode: any) => (
                  <Select.Option key={movementMode.key} value={movementMode.key}>
                    {movementMode.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  return <></>;
};

export const getShipmentComponents = (props: any) => {
  const {
    freightType,
    loadType,
    shipment,
    form,
    type,
    editable,
    duplicate = false,
    productOrderItems,
  } = props;
  return [
    {
      title: 'Basic Details',
      component: BasicDetails,
      props: { form, shipment },
      isVisible: true,
    },
    {
      title: 'Party Details',
      component: PartyDetailsWrapper,
      props: { editable },
      isVisible: true,
    },
    {
      title: 'Product Details',
      component: ProductDetailsWrapper,
      props: {
        form,
        type: 'shipment',
        getProductFromShipment: true,
        linkedItems: productOrderItems,
        editTable: editable,
        rowSelection: 'multiple',
        duplicate: duplicate,
      },
      isVisible: true,
    },
    {
      title: 'Booking Details',
      component: OceanBookingDetails,
      props: { form },
      isVisible: freightType === FREIGHT_TYPE_OCEAN && type === 'update',
    },
    {
      title: 'Last Free Days',
      component: OceanLastFreeDays,
      props: { form },
      isVisible: freightType === FREIGHT_TYPE_OCEAN && type === 'update',
    },
    {
      title: 'Vehicle & Driver Details',
      component: VehicleDetailsWrapper,
      props: { shipment, form },
      isVisible: freightType === FREIGHT_TYPE_ROAD,
    },
    {
      title: 'Routing Details',
      component: RoutingDetailsWrapper,
      props: { form, shipment, editable, type, freightType },
      isVisible: true,
    },
    {
      title: 'BL Details',
      component: BLDetails,
      props: { form },
      isVisible:
        (freightType === FREIGHT_TYPE_OCEAN || freightType === FREIGHT_TYPE_AIR) &&
        type === 'update',
    },
    // {
    //   title: 'Cargo Details',
    //   component: CargoDetails,
    //   props: { shipment, form, load_type: loadType, showCommoditySearch: false },
    //   isVisible: true,
    // },
    {
      title: 'Container Details',
      component: ContainerQuantity,
      isVisible:
        (freightType === FREIGHT_TYPE_OCEAN || freightType === FREIGHT_TYPE_ROAD) &&
        loadType === LOAD_TYPE_FCL &&
        type !== 'update',
    },
    {
      title: 'Services Provided',
      component: ShipmentServiceDetailsWrapper,
      // props: { shipmentType, services, form },
      isVisible: true,
    },
  ];
};
