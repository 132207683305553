// TODO: REFACTOR THIS
import React, { useContext, useState } from 'react';
import { Layout } from '@shipmnts/pixel-hub';
import AppHeader from 'common/components/ApplicationHeader';
const { Content } = Layout;

const NavbarContext = React.createContext();
export const useNavbarContext = () => useContext(NavbarContext);

const NavbarContextWrapper = React.memo(function ApplicationLayout(props) {
  const [navJson, setNavJson] = useState(undefined);

  return (
    <NavbarContext.Provider value={{ navJson }}>
      <Layout className="layout" style={{ height: '100vh' }}>
        <AppHeader setNavJson={setNavJson} />
        <Content style={{ height: '100%' }}>{props.children}</Content>
      </Layout>
    </NavbarContext.Provider>
  );
});

export default NavbarContextWrapper;
