import { Button, Drawer, Space } from '@shipmnts/pixel-hub';
import { Form } from '@shipmnts/pixel-hub';
import React, { useEffect } from 'react';
import DriverForm from './DriverForm';

interface CreateDriverDrawerProps {
  visible: boolean;
  value?: any;
  setVisible: (visible: boolean) => void;
  onSuccess: (value?: any) => void;
  onCloseDrawer: () => void;
  onClose?: () => void;
  id?: string;
}
const { useForm } = Form;
export default function CreateDriverDrawer(props: CreateDriverDrawerProps) {
  const {
    visible,
    setVisible,
    value,
    onSuccess,
    onCloseDrawer,
    id,
    onClose: onCloseActions,
  } = props;
  const [form] = useForm();

  const onClose = () => {
    setVisible && setVisible(false);
    onCloseDrawer && onCloseDrawer();
    onCloseActions && onCloseActions();
  };

  useEffect(() => {
    if (value) {
      form.setFieldsValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Drawer
      title={<div style={{ display: 'flex' }}>{!!id ? 'Update' : 'Create New'} Driver</div>}
      onClose={onClose}
      open={visible}
      styles={{ body: { paddingBottom: 80 } }}
      footer={
        <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="primary" htmlType="submit" onClick={form.submit}>
            {!!id ? 'Update' : 'Create'} Driver
          </Button>
        </Space>
      }
      destroyOnClose={true}
    >
      <DriverForm form={form} onClose={onClose} onSuccess={onSuccess} id={id} />
    </Drawer>
  );
}
