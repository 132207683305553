// import { EmailProps } from 'operations/modules/shipment/components/Tracking/ChangeEvent/EventEmailDrawer'

import { ContainerReportData } from 'operations/modules/reports/reportModels';
import { PerformAction } from '../models';
import { SessionDataValue } from 'operations/models/SessionData';
import EditContainerDetails from 'operations/modules/shipment/components/DetailLayout/Containers/EditContainer/EditContainerDetails';
import DeleteContainer from 'operations/modules/shipment/components/DetailLayout/Containers/DeleteContainer';
import PrintContainer from 'operations/modules/shipment/components/DetailLayout/Containers/PrintContainer';
import { RenderEventsForm } from 'common';
import { EmailProps } from 'operations/components/ChangeEvent/EventEmailDrawer';
import Shipment from 'operations/models/Shipment';
import AssignVehicleFormTable from 'operations/modules/shipment/components/ShipmentForm/AssignVehicleFormDrawer';
import CreateAndAssignLoad from 'operations/modules/shipment/components/ShipmentForm/CreateAndAssignLoad';

interface ContainerActionType {
  container: ContainerReportData;
  setEmailProps: (emailProps: EmailProps) => void;
  setVisible: (visible: boolean) => void;
  sessionData: SessionDataValue;
  extraProps?: any;
  navigate: any;
}

export const performContainerAction = (payload: ContainerActionType): PerformAction => {
  const { container, sessionData, extraProps } = payload;
  const eventName = extraProps?.event_name;
  return {
    actionParams: {
      id: container.id,
      referenceType: 'Shipment::ShipmentContainer',
      eventName: eventName,
      session: sessionData,
      onSuccess: (sendEmail: boolean) => {
        // if (sendEmail) {
        //   setEmailProps({
        //     title: `Send ${startCase(
        //       (actionName || '').replace('_and_update_booking', '')
        //     )} Confirmation Email`,
        //     action_name: 'shutout_notice',
        //     resource_ids: [container.id],
        //   });
        //   setVisible(true);
        // }
      },
    },
    component: RenderEventsForm,
  };
};

export const performEditContainerAction = (payload: ContainerActionType): PerformAction => {
  const { container, setVisible } = payload;
  return {
    actionParams: {
      container_id: container.id,
      onClose: () => {
        setVisible(false);
      },
    },
    component: EditContainerDetails,
  };
};

export const performDeleteContainerAction = (payload: ContainerActionType): PerformAction => {
  const { container, setVisible } = payload;
  return {
    actionParams: {
      container_id: container.id,
      onClose: () => {
        setVisible(false);
      },
    },
    component: DeleteContainer,
  };
};

export const performPrintContainer = (payload: ContainerActionType): PerformAction => {
  const { container } = payload;
  return {
    actionParams: {
      docTypeId: container.id,
      docType: 'Shipment::ShipmentContainer',
    },
    component: PrintContainer,
  };
};

export const assignVehicleRender = (payload: ContainerActionType) => {
  const { container } = payload;
  const shipment = Shipment.create(container.container_cargo_details?.[0].shipment);

  return {
    actionParams: {
      shipment: shipment,
    },
    component: AssignVehicleFormTable,
  };
};

export const assignLoadRender = (payload: ContainerActionType) => {
  const { container } = payload;
  const shipment = Shipment.create(container.container_cargo_details?.[0].shipment);

  return {
    actionParams: {
      shipment: shipment,
      selectedIds: [container.id.toString()],
    },
    component: CreateAndAssignLoad,
  };
};
