import React from 'react';
import BasicInfoRender, { InfoDataValue } from '../booking/components/BasicInfoRenderer';
import { startCase as _startCase } from 'lodash';
import { ProductOrderValue } from 'operations/models/ProductOrder';
import { getDateFromUnix } from '@shipmnts/pixel-hub';

interface BasicInfoProps {
  productOrder?: ProductOrderValue;
}

export const ProductOrderShipmentBasicInfo = React.memo(function BookingRequestBasicInfo(
  props: BasicInfoProps
): JSX.Element {
  const { productOrder } = props;
  // if (!productOrder) return <></>;
  // const oto = productOrder.ocean_transport_orders[0];
  // const carriageWiseRoutingLegs = getCarriageWiseRoutingLegs(oto?.routing_legs);
  // const mcrl = carriageWiseRoutingLegs[ROUTING_TYPE_MAIN_CARRIAGE];
  // const eta = mcrl?.[mcrl.length - 1]?.estimated_time_of_arrival;
  // const etd = mcrl?.[0].estimated_time_of_departure;

  // "Seller's SO#": productOrder.sales_order_number ? productOrder.sales_order_number : '-',
  //   "Buyer's PO#": productOrder.purchase_order_number ? productOrder.purchase_order_number : '-',

  const basicInfoData: InfoDataValue[] = [
    { label: 'Sales Order #', value: productOrder?.sales_order_number },
    {
      label: 'Port of Loading',
      value: productOrder?.port_of_loading
        ? [
            productOrder?.port_of_loading?.unlocode?.toUpperCase(),
            productOrder?.port_of_loading?.name,
          ]
            .filter(Boolean)
            .join(' - ')
        : '',
    },
    { label: 'Purchase Order #', value: productOrder?.purchase_order_number },
  ];

  basicInfoData.push({
    label: 'Port of Discharge',
    value: productOrder?.port_of_discharge
      ? [
          productOrder?.port_of_discharge?.unlocode?.toUpperCase(),
          productOrder?.port_of_discharge?.name,
        ]
          .filter(Boolean)
          .join(' - ')
      : '',
  });
  if (productOrder?.type === 'purchase') {
    basicInfoData.push({
      label: 'Seller',
      value: productOrder?.seller ? productOrder?.seller.registered_name : '-',
    });
  } else {
    basicInfoData.push({
      label: 'Buyer of',
      value: productOrder?.buyer ? productOrder?.buyer.registered_name : '-',
    });
  }
  basicInfoData.push(
    {
      label: 'Cargo Ready Date',
      value: productOrder?.estimated_cargo_ready_date
        ? getDateFromUnix(productOrder?.estimated_cargo_ready_date)
        : '-',
    },
    {
      label: 'Mode Of Shipment',
      value: [_startCase(productOrder?.freight_type || ''), productOrder?.load_type?.toUpperCase()]
        .filter(Boolean)
        .join(' - '),
    },
    {
      label: 'Target Shipping Date',
      value: productOrder?.target_shipping_date
        ? getDateFromUnix(productOrder?.target_shipping_date)
        : '-',
    },
    { label: 'Incoterms', value: productOrder?.incoterm ? productOrder?.incoterm : '-' },
    {
      label: 'Target Delivery Date',
      value: productOrder?.target_delivery_date
        ? getDateFromUnix(productOrder?.target_delivery_date)
        : '-',
    }
  );
  return (
    <>
      <BasicInfoRender
        title="Basic Info"
        noOfColumns={2}
        layout="horizontal"
        showColon={false}
        labelStyle={{ color: '#586988', minWidth: '40%' }}
        descriptionItemStyle={{ paddingBottom: '3px' }}
        displayData={basicInfoData}
      />
    </>
  );
});
