import { gql } from '@apollo/client';

export const UPDATE_CONNECTION_REQUEST = gql`
  mutation update_connection_request($id: ID!, $connection_request: ConnectionRequestInputType!) {
    update_connection_request(id: $id, connection_request: $connection_request) {
      id
    }
  }
`;
export const DELETE_CONNECTION_REQUEST = gql`
  mutation delete_connection_request($id: ID!) {
    delete_connection_request(id: $id) {
      message
    }
  }
`;
