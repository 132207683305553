import React from 'react';

import { BugFilled } from '@shipmnts/pixel-hub';
import * as Sentry from '@sentry/react';
import { Result, Button } from '@shipmnts/pixel-hub';

const ErrorBoundary = (props: { children: React.ReactNode }) => {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error }) => {
        return (
          <Result
            status="500"
            title="Something unexpected happened"
            subTitle="Don't worry our team has been notified and is working on solving the issue."
            extra={
              <>
                <Button type={'link'}>
                  <a
                    href={`mailto:support@shipmnts.com?subject=Error Occurred&body=${error.toString()}`}
                  >
                    Contact Support
                  </a>
                </Button>
                <Button type={'dashed'} icon={<BugFilled />} onClick={Sentry.showReportDialog}>
                  Report Feedback
                </Button>
                <Button type="primary" onClick={() => window.location.reload()}>
                  Reload
                </Button>
              </>
            }
          />
        );
      }}
    >
      {props.children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
