import React, { useCallback, useRef } from 'react';
import { Drawer, DrawerFooter } from '@shipmnts/pixel-hub';
import VoyageScheduleForm from './VoyageScheduleForm';
import { VoyageScheduleValue, VoyageScheduleInitialValue } from 'operations/models/VoyageSchedule';
const VoyageScheduleFormDrawer = React.memo(function VoyageScheduleFormDrawer(props: {
  visible?: boolean;
  onCloseDrawer?: () => void;
  onSuccess?: (value: VoyageScheduleValue, type: string) => void;
  initialVoyageScheduleFormData?: VoyageScheduleInitialValue;
  id?: string;
}) {
  const { visible, onCloseDrawer, initialVoyageScheduleFormData, onSuccess, id } = props;
  const onClose = useCallback(() => {
    if (onCloseDrawer) onCloseDrawer();
  }, [onCloseDrawer]);

  const form_ref = useRef<{ handleSaveButtonClick: () => boolean }>();

  const onSave = () => {
    form_ref?.current?.handleSaveButtonClick();
    return;
  };

  const renderFooter = (props: { loading?: boolean }): JSX.Element => {
    const { loading } = props;
    return (
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          textAlign: 'right',
          background: '#fff',
          padding: '.75em 1em',
        }}
      >
        <DrawerFooter
          saveText="Save"
          loading={loading}
          onClose={onClose}
          onSave={onSave}
          saveButtonHTMLType={'button'}
        />
      </div>
    );
  };
  return (
    <Drawer
      title={'Voyage Schedule Details'}
      width="80%"
      open={visible}
      maskClosable={false}
      styles={{ body: { padding: '0px' } }}
      onClose={onClose}
      destroyOnClose
    >
      <VoyageScheduleForm
        ref={form_ref}
        showFormHeader={false}
        showFormLayout={false}
        onSuccess={onSuccess}
        initialVoyageScheduleData={initialVoyageScheduleFormData}
        id={id}
        renderFooter={renderFooter}
      />
    </Drawer>
  );
});

export default VoyageScheduleFormDrawer;
