import React, { useState } from 'react';

import { PrintPreviewDocType } from '@shipmnts/pixel-hub';
import { INQUIRY_WON_STATUS, InquiryValue } from 'sales_hub/models/inquiry';
import { WON_STATUS } from 'sales_hub/models/quotation';
import { Form, Select } from '@shipmnts/pixel-hub';

interface ShareInquiryProp {
  docTypeId: string;
  docType: string;
  doc: InquiryValue;
  selectedQuote: string;
  onSuccess?: () => void;
  onClose?: () => void;
  visible?: boolean;
}

export default function ShareInquiry(props: ShareInquiryProp) {
  const { docType, docTypeId, doc, onClose, selectedQuote } = props;
  const getInitialQuote = () => {
    if (selectedQuote) return [selectedQuote];

    if (doc.last_action_status === INQUIRY_WON_STATUS)
      return doc.inquiry_options
        ?.filter((ele) => ele.status === WON_STATUS)
        .map((ele) => ele.quotation_number);

    return doc?.inquiry_options
      ?.filter((ele) => ele.shipment_estimates.length > 0)
      .map((ele) => ele.quotation_number);
  };
  const [quoteSelected, setQuoteSelected] = useState(getInitialQuote());

  if (!doc) return <></>;

  return (
    <PrintPreviewDocType
      docType={docType}
      resource_id={docTypeId}
      context={{ quotations: quoteSelected }}
      onClose={onClose}
      emailProps={{
        fetchDocumentParents: [{ parent_ids: [doc.id], parent_type: 'inquiry' }],
        companies_roles_mapping: doc.getAllPartiesRolesMapping(),
        customContent: JSON.stringify({ quotations: quoteSelected }),
        defaultTemplate: 'share_sell_rates',
      }}
      whatsappProps={{
        fetchDocumentParents: [{ parent_ids: [doc.id], parent_type: 'inquiry' }],
        customContent: JSON.stringify({ quotations: quoteSelected }),
        defaultTemplate: 'share_sell_rates',
      }}
    >
      <Form.Item required label={'Quotations'} style={{ marginBottom: 0 }}>
        <Select
          mode="multiple"
          value={quoteSelected}
          style={{ width: '300px' }}
          onChange={(value) => setQuoteSelected(value)}
          maxTagCount={'responsive'}
          options={doc?.inquiry_options
            ?.filter((ele) => ele.shipment_estimates.length > 0)
            ?.map((ele) => {
              return {
                label: ele?.quotation_number,
                value: ele?.quotation_number,
              };
            })}
        />
      </Form.Item>
    </PrintPreviewDocType>
  );
}
