import React from 'react';
import { Card as PixelCard } from '@shipmnts/pixel-hub';
import { Skeleton } from '@shipmnts/pixel-hub';
import { AlignLeftOutlined, FormOutlined } from '@ant-design/icons';
import { CardPropTypes } from './types';

export const Card = (props: CardPropTypes) => {
  const { children, style, header, loading, Action } = props;
  const { icon: HeaderIcon } = header;

  return (
    <PixelCard
      style={{
        ...style,
      }}
    >
      <Skeleton active loading={loading}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'space-between',
            color: '#586988',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', gap: '.5em' }}>
            {HeaderIcon ? (
              typeof HeaderIcon === 'function' ? (
                <HeaderIcon />
              ) : (
                HeaderIcon
              )
            ) : (
              <AlignLeftOutlined />
            )}
            <h4 style={{ margin: 0, color: '#586988', fontWeight: '600px' }}>{header?.title}</h4>
          </div>
          <>
            {!!Action ? (
              typeof Action === 'function' ? (
                <Action />
              ) : (
                Action
              )
            ) : (
              <FormOutlined style={{ cursor: 'pointer' }} />
            )}
          </>
        </div>
        {children}
      </Skeleton>
    </PixelCard>
  );
};
