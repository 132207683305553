import { gql } from '@apollo/client';
import { salesPersonFields } from 'operations/graphql/salesPerson';
export const userContactFields = gql`
  fragment userContactFields on UserContactObjectType {
    id
    department
    email
    pretty_display
    name
    first_name
    last_name
    mobile_number
    dial_code
    title
    is_internal
    is_disabled
    created_at
    updated_at
  }
`;

export const FETCH_USER_CONTACT = gql`
  query fetch_user_contact($id: ID!) {
    fetch_user_contact(id: $id) {
      ...userContactFields
      companies {
        id
        registered_name
      }
      collaborations {
        id
        reference_id
        reference_type
        data: reference
      }
    }
  }
  ${userContactFields}
`;

export const DISABLE_CONTACT = gql`
  mutation disable_contact($id: ID!, $is_disabled: Boolean!) {
    disable_contact(id: $id, is_disabled: $is_disabled) {
      ...userContactFields
    }
  }
  ${userContactFields}
`;

export const UPSERT_CONTACT = gql`
  mutation upsert_contact($contact: ContactInputType!, $company_id: ID) {
    upsert_contact(contact: $contact, company_id: $company_id) {
      ...userContactFields
    }
  }
  ${userContactFields}
`;

export const GET_SALES_PERSON_FOR_CUSTOMER = gql`
  query get_sales_person_for_customer($customer_company_id: ID!, $branch_id: ID) {
    get_sales_person_for_customer(
      customer_company_id: $customer_company_id
      branch_id: $branch_id
    ) {
      ...salesPersonFields
    }
  }
  ${salesPersonFields}
`;
