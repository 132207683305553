import React from 'react';
import FsuRoutingDetailsSplitTimeline from './FsuRoutingDetailsSplitTimeline';
import FlightCard from './FlightCard';
import { Card, Timeline, CustomIcon } from '@shipmnts/pixel-hub';
import { FsuDataResponseType } from './FsuTrackingTab';
import { LocationDataType } from './FsuTrackingTab';

export const BlueSquareIcon = () => (
  <svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="12" height="12" fill={'#084fca'} rx="4" />
  </svg>
);

const determineTimelineOrder = (locationData: LocationDataType[]) => {
  const order: any[] = [];

  const startIndex = locationData.findIndex(
    (node: LocationDataType) => !node.arrival_flights || node.arrival_flights.length === 0
  );
  if (startIndex === -1) return order;

  order.push(locationData[startIndex]);

  let currentNode = locationData[startIndex];
  while (currentNode && currentNode.next_locations && currentNode.next_locations.length > 0) {
    const nextNode = locationData.find(
      // eslint-disable-next-line no-loop-func
      (node: LocationDataType) => node.location === currentNode?.next_locations?.[0]
    );
    if (nextNode) {
      order.push(nextNode);
      currentNode = nextNode;
    } else {
      break;
    }
  }

  return order;
};

const FsuRoutingDetails = React.memo(function FsuTrackingSummary(props: {
  data: FsuDataResponseType;
}) {
  const { data } = props;
  if (!data) {
    return <></>;
  }
  const location_data = data?.eawb_get_fsu_data?.location_data;
  const flight_data = data?.eawb_get_fsu_data?.flight_data;

  const getFlightDetails = (flightNumber: string) => {
    if (!flightNumber || flightNumber.trim() === '') {
      return null;
    }
    const targetPrefix = flightNumber.substring(0, 2);
    const targetMiddle = parseInt(flightNumber?.match(/\d+/g)?.[0] ?? '', 10);
    const targetSuffix = flightNumber.slice(-1).match(/[a-zA-Z]/) ? flightNumber.slice(-1) : '';
    return flight_data.find((flight: any) => {
      const flightPrefix = flight.flight_number.substring(0, 2);
      const flightMiddle = parseInt(flight.flight_number.match(/\d+/g)?.[0], 10);
      const flightSuffix = flight.flight_number.slice(-1).match(/[a-zA-Z]/)
        ? flight.flight_number.slice(-1)
        : '';
      return (
        flightPrefix === targetPrefix &&
        flightMiddle === targetMiddle &&
        flightSuffix === targetSuffix
      );
    });
  };

  const timelineOrder = determineTimelineOrder(location_data);
  return (
    <>
      <Card
        size="small"
        title="Routing Details"
        style={{
          maxWidth: '100%',
          borderRadius: '4px',
          margin: '4px 4px 0px 0px',
        }}
        headStyle={{
          borderBottom: 'none',
        }}
      >
        <Timeline mode="left">
          {timelineOrder.map((locationNode: LocationDataType, outerIndex: any) => (
            <Timeline.Item key={outerIndex} dot={<CustomIcon icon="BlueSquareIcon" />}>
              <div style={{ fontSize: '12px', color: 'gray' }}>
                {outerIndex === 0
                  ? 'Port of Loading'
                  : outerIndex === timelineOrder.length - 1
                  ? 'Port of Discharge'
                  : 'Transhipment Port'}
              </div>
              <div style={{ fontSize: '13px' }}>
                {`${locationNode.location_display} - ${locationNode.location}`}
              </div>
              {locationNode.departure_flights && locationNode.departure_flights.length > 0 && (
                <div>
                  {locationNode.departure_flights.map((flightNumber: string, innerIndex: any) => {
                    const flightDetail = getFlightDetails(flightNumber);
                    const nextNode = timelineOrder[outerIndex + 1];
                    const display_flight = nextNode?.arrival_flights?.includes(flightNumber);
                    if (flightDetail && display_flight) {
                      return <FlightCard flightDetail={flightDetail} key={innerIndex} />;
                    } else return <></>;
                  })}
                  {locationNode.next_locations &&
                    locationNode?.next_locations?.length > 1 &&
                    locationNode.next_locations
                      .slice(1)
                      .map((nextLocation: any, index: number) => (
                        <FsuRoutingDetailsSplitTimeline
                          key={index}
                          location={nextLocation}
                          data={data}
                          getFlightDetails={getFlightDetails}
                        />
                      ))}
                </div>
              )}
            </Timeline.Item>
          ))}
        </Timeline>
      </Card>
    </>
  );
});

export default FsuRoutingDetails;
