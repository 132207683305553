import React from 'react';

import { Badge, Divider } from '@shipmnts/pixel-hub';
import { startCase, get, find } from 'lodash';
import { SHIPMENT_DOCUMENT_TYPE_HOUSE } from 'operations/modules/reports/constants';
import { ShipmentValue } from 'operations/models/Shipment';
import { useShipmentDetail } from '../ShipmentDetailLayout';
import BlStatusWithTracker from '../Common/BlStatusWithTracker';
import { getDocumentStatus } from '../AttachedHouse/ShipmentDocumentTimelineRenderer';
import { ACCOUNTING_STATUS_CLOSED, FreightType } from 'operations/modules/shipment/constants';
import StatusRenderer from './StatusRenderer';
import CreditStatusWithTracker from './CreditStatusWithTimeline';
import { CustomIcon } from '@shipmnts/pixel-hub';
import FinanceStatusWithTracker from './FinanceStatusWithTimeline';

const fetchShipmentDocument = (shipment: ShipmentValue) => {
  let master_shipment_document: any = null;
  if (shipment.isMasterShipment()) {
    master_shipment_document = shipment.getShipmentDocument('master');
  } else if (shipment.master_shipment) {
    master_shipment_document = shipment.master_shipment.getShipmentDocument('master');
  }
  return master_shipment_document;
};

const Statuses = () => {
  const { shipment } = useShipmentDetail();

  if (!shipment) return <></>;

  let master_shipment_document: any = null;
  const status = !shipment?.isRoadShipment() ? shipment?.last_action_status : undefined;
  master_shipment_document = fetchShipmentDocument(shipment);
  let master_number = master_shipment_document?.shipment_number;
  if (master_number && shipment.isAirShipment()) {
    master_number = `${master_number.substr(0, 3)}-${master_number.substr(3)}`;
  }
  if (!master_number) master_number = '-';
  const shipment_document_house = find(get(shipment, 'shipment_documents', []), {
    document_type: SHIPMENT_DOCUMENT_TYPE_HOUSE,
  });

  if (shipment.isRoadShipment() && !shipment_document_house?.id) return <></>;
  const master_status = startCase(getDocumentStatus(master_shipment_document, shipment));

  const house_status = shipment_document_house
    ? startCase(getDocumentStatus(shipment_document_house, shipment))
    : '';
  const house_number = get(shipment_document_house, 'shipment_number', '-');
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const freight_type: FreightType | null = shipment.freight_type;
  const containerStatuses: any[] = [];
  if (shipment.container_tracking_events_summary?.gated_in_containers) {
    containerStatuses.push(() => (
      <div style={{ fontSize: '12px' }} className={`status_color_green`}>
        <Badge color={`green`} />{' '}
        {`${
          shipment.container_tracking_events_summary?.gated_in_containers || 0
        } Containers Gated In`}
      </div>
    ));
  }
  if (shipment.container_tracking_events_summary?.late_gated_in_containers) {
    containerStatuses.push(() => (
      <div style={{ fontSize: '12px' }} className={`status_color_red`}>
        <Badge color={`red`} />{' '}
        {`${
          shipment.container_tracking_events_summary?.late_gated_in_containers || 0
        } Containers Late Gated In`}
      </div>
    ));
  }
  if (shipment.container_tracking_events_summary?.loaded_containers) {
    containerStatuses.push(() => (
      <div style={{ fontSize: '12px' }} className={`status_color_green`}>
        <Badge color={`green`} />{' '}
        {`${shipment.container_tracking_events_summary?.loaded_containers || 0} Containers Loaded`}
      </div>
    ));
  }
  if (shipment?.freight_type === 'ocean' && shipment?.trade_type === 'import') {
    if (shipment.container_tracking_events_summary?.gated_out_containers) {
      containerStatuses.push(() => (
        <div style={{ fontSize: '12px' }} className={`status_color_green`}>
          <Badge color={`green`} />{' '}
          {`${
            shipment.container_tracking_events_summary?.gated_out_containers || 0
          } Containers Gated Out`}
        </div>
      ));
    }
    if (shipment.container_tracking_events_summary?.demurrage_containers) {
      containerStatuses.push(() => (
        <div style={{ fontSize: '12px' }} className={`status_color_green`}>
          <Badge color={`green`} />{' '}
          {`${
            shipment.container_tracking_events_summary?.demurrage_containers || 0
          } Containers Demurrage`}
        </div>
      ));
    }
    if (shipment.container_tracking_events_summary?.empty_returned_containers) {
      containerStatuses.push(() => (
        <div style={{ fontSize: '12px' }} className={`status_color_green`}>
          <Badge color={`green`} />{' '}
          {`${
            shipment.container_tracking_events_summary?.empty_returned_containers || 0
          } Containers Empty Returned`}
        </div>
      ));
    }
    if (shipment.container_tracking_events_summary?.detention_containers) {
      containerStatuses.push(() => (
        <div style={{ fontSize: '12px' }} className={`status_color_green`}>
          <Badge color={`green`} />{' '}
          {`${
            shipment.container_tracking_events_summary?.detention_containers || 0
          } Containers Detention`}
        </div>
      ));
    }
  }
  return (
    <>
      {/* header */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          color: '#586988',
          padding: '16px 0px',
          fontWeight: '600',
        }}
      >
        <CustomIcon icon="Info" className="status-icon" />
        <div style={{ marginLeft: '5px', textTransform: 'uppercase', fontSize: '14px' }}>
          Statuses
        </div>
      </div>

      <div style={{ display: 'flex' }} className="shipment-summary">
        {/* shipment status */}
        {status && (
          <>
            <div style={{ width: '16%' }}>
              <div
                style={{
                  color: '#586988',
                }}
              >
                {'Overall'}
              </div>

              {/*  <div className={`status_color_primary_blue`}>
              <Badge color={'red'} /> {startCase(status || '')}
             </div> */}

              <StatusRenderer
                color="red"
                status={startCase(status || '')}
                actionProps={
                  shipment.next_actions
                    ? {
                        reference_id: shipment.id,
                        referenceType: 'Shipment::Shipment',
                        nextActions: shipment.next_actions,
                      }
                    : undefined
                }
                trackerProps={{
                  referenceId: shipment.id,
                  referenceType: 'Shipment::Shipment',
                  editable: shipment?.accounting_status !== ACCOUNTING_STATUS_CLOSED,
                }}
              ></StatusRenderer>
            </div>
            <Divider type="vertical" style={{ height: 'auto !important' }} />
          </>
        )}
        {/* mbl status */}
        {master_shipment_document?.id && !shipment.isRoadShipment() && (
          <>
            <div style={{ width: '22%' }}>
              <BlStatusWithTracker
                shipment_document={master_shipment_document}
                shipment={shipment}
                number={master_number}
                job_number={shipment.job_number}
                status={master_status}
                documentType="master"
                freightType={freight_type}
              />
            </div>
            <Divider type="vertical" style={{ height: 'auto !important' }} />
          </>
        )}
        {/* hbl status */}
        {shipment_document_house?.id && (
          <>
            <div style={{ width: '22%' }}>
              <BlStatusWithTracker
                shipment_document={shipment_document_house}
                shipment={shipment}
                number={house_number}
                job_number={shipment.job_number}
                status={house_status}
                documentType="house"
                freightType={freight_type}
              />
            </div>
            <Divider type="vertical" style={{ height: 'auto !important' }} />
          </>
        )}
        {/* container */}
        {containerStatuses && !shipment.isAirShipment() && !shipment.isRoadShipment() && (
          <>
            <div style={{ width: '16%' }}>
              <div>Container</div>
              {containerStatuses.map((Item, index) => {
                if (Item) return <Item key={index} />;
                else return <></>;
              })}
            </div>
            <Divider type="vertical" style={{ height: 'auto !important' }} />
          </>
        )}

        {!shipment.isRoadShipment() && (
          <React.Fragment>
            {/*  fincance status */}
            <div style={{ width: '16%' }}>
              <FinanceStatusWithTracker shipment={shipment} />
            </div>
            <Divider type="vertical" style={{ height: 'auto !important' }} />

            {/*  credit  status  */}
            <div style={{ width: '16%' }}>
              <CreditStatusWithTracker shipment={shipment} />
            </div>
          </React.Fragment>
        )}
      </div>
    </>
  );
};

export default Statuses;
