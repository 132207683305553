import { axiosInstance as axios } from 'common';

export async function fetchCompanyInfoFromMCA(registered_name) {
  if (!registered_name) return;
  try {
    const response = await axios.get(`${process.env.NETWORK_API_URL}/companies/search_mca`, {
      params: {
        query: registered_name,
      },
    });
    return { response: response.data, error: false };
  } catch (error) {
    console.error(error);
    return { error };
  }
}
