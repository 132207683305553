import React from 'react';
import { ShipmentDocumentValue } from 'operations/models/ShipmentDocument';
import {
  DOCUMENT_HBL,
  DOCUMENT_MAWB,
  DOCUMENT_MBL,
  NEGATIVE_TYPE,
  PRIMARY_TYPE,
  SECONDARY_TYPE,
} from '../constants';
import { Action } from '../models';
import {
  FileDoneOutlined,
  FileProtectOutlined,
  EditOutlined,
  AuditOutlined,
  FlagOutlined,
  FileAddOutlined,
  DatabaseOutlined,
  IssuesCloseOutlined,
} from '@shipmnts/pixel-hub';
import { ShipmentValue } from 'operations/models/Shipment';
import {
  actionNameMap,
  DOCUMENT_STATUS_DRAFT,
  DOCUMENT_STATUS_DRAFT_APPROVED,
  DOCUMENT_STATUS_EXECUTED,
  DOCUMENT_STATUS_GIVEN_TO_CUSTOMER,
  DOCUMENT_STATUS_PENDING_CREATION,
  DOCUMENT_STATUS_SURRENDERED_AT_ORIGIN,
  SHIPMENT_DOCUMENT_TYPE_MASTER,
  DOCUMENT_STATUS_HANDOVER_SET_PRINTED,
  PERMISSION_UNRELEASE_DOCUMENT,
  PERMISSION_RELEASE_DOCUMENT,
  PERMISSION_BL_DRAFT_APPROVE,
  SHIPMENT_DOCUMENT_TYPE_HOUSE,
  DOCUMENT_STATUS_RECEIVED_AT_DESTINATION,
  SHIPMENT_STATUS_PLANNED,
  DOCUMENT_STATUS_SI_FILED,
  DOCUMENT_STATUS_FIRST_DRAFT_SHARED,
  DOCUMENT_STATUS_RECEIVED_BY_SHIPPER,
  DOCUMENT_STATUS_DRAFT_RECEIVED,
  DOCUMENT_STATUS_BL_AUDITED,
  DOCUMENT_TYPE_CONSIGNMENT_NOTE,
} from 'operations/modules/reports/constants';
import { ApolloClient } from '@apollo/client';
import { SessionDataValue } from 'operations/models/SessionData';
import {
  BL_TYPE_ORIGINAL,
  SHIPMENT_TYPE_BACK_TO_BACK,
  SHIPMENT_TYPE_CONSOL,
  SHIPMENT_TYPE_DIRECT,
  FREIGHT_TYPE_OCEAN,
  TRADE_TYPE_EXPORT,
  FREIGHT_TYPE_AIR,
} from 'operations/modules/shipment/constants';
import {
  DOCUMENT_TYPE_HBL,
  DOCUMENT_TYPE_MBL,
  DOCUMENT_TYPE_NEW_HAWB,
  DOCUMENT_TYPE_NEW_MAWB,
} from 'operations/constants';
import {
  blReleaseToCustomerRenderer,
  createDocumentRenderer,
  onBackToTownRenderer,
  onCancelRenderer,
  receivedAtDestinationActionRenderer,
  releaseMblRenderer,
  sifiledRenderer,
  surrenderedAtOriginActionRenderer,
  unreleaseBlActionRenderer,
  documentStatusUpdateActionRenderer,
  editMbl,
  sendPreAlertRenderer,
  firstDraftSharedWithCustomerRenderer,
  firstDraftReceived,
  proofOfDeliveryRecievedRenderer,
} from './shipmentDocumentActionRenderer';
import { CustomIcon } from '@shipmnts/pixel-hub';
import { hasPermission } from '@shipmnts/pixel-hub';

import { EmailProps } from 'operations/components/ChangeEvent/EventEmailDrawer';
import { FREIGHT_TYPE_ROAD } from 'operations/utils/constants';

const isMaster = (shipment_document: ShipmentDocumentValue) => {
  return shipment_document?.document_type === SHIPMENT_DOCUMENT_TYPE_MASTER;
};

const isDraft = (shipment_document: ShipmentDocumentValue) => {
  return shipment_document?.document_status === DOCUMENT_STATUS_DRAFT;
};
const isDraftReceived = (shipment_document: ShipmentDocumentValue) => {
  return shipment_document?.document_status === DOCUMENT_STATUS_DRAFT_RECEIVED;
};
const isPendingCreation = (shipment_document: ShipmentDocumentValue) => {
  return shipment_document?.document_status === DOCUMENT_STATUS_PENDING_CREATION;
};
const isSiFiled = (shipment_document: ShipmentDocumentValue) => {
  return shipment_document?.document_status === DOCUMENT_STATUS_SI_FILED;
};
const isFirstDraftShared = (shipment_document: ShipmentDocumentValue) => {
  return shipment_document?.document_status === DOCUMENT_STATUS_FIRST_DRAFT_SHARED;
};
const isExecuted = (shipment_document: ShipmentDocumentValue) => {
  return shipment_document?.document_status === DOCUMENT_STATUS_EXECUTED;
};
const isDraftApproved = (shipment_document: ShipmentDocumentValue) => {
  return shipment_document?.document_status === DOCUMENT_STATUS_DRAFT_APPROVED;
};
const isReceivedAtOrigin = (shipment_document: ShipmentDocumentValue) => {
  return shipment_document?.document_status === DOCUMENT_STATUS_RECEIVED_BY_SHIPPER;
};
const isGivenToCustomer = (shipment_document: ShipmentDocumentValue) => {
  return shipment_document.document_status === DOCUMENT_STATUS_GIVEN_TO_CUSTOMER;
};
export const documentType = (shipment_document: ShipmentDocumentValue, shipment: ShipmentValue) => {
  return `${isMaster(shipment_document) ? 'M' : 'H'}${
    shipment.freight_type === 'air' ? 'AWB' : 'BL'
  }`;
};

export const DocumentToNewDocument: { [key: string]: string } = {
  MAWB: 'new_mawb',
  MBL: 'new_mbl',
  HBL: 'new_hbl',
  HAWB: 'new_hawb',
};

export const NewDocumentToDocument: { [key: string]: string } = {
  new_mawb: 'MAWB',
  new_mbl: 'MBL',
  new_hbl: 'HBL',
  new_hawb: 'HAWB',
};

export const getDocumentType = (
  shipment_document: ShipmentDocumentValue,
  shipment: ShipmentValue
) => {
  const docType = documentType(shipment_document, shipment);
  if (docType === DOCUMENT_MBL) {
    return DOCUMENT_TYPE_MBL;
  } else if (docType === DOCUMENT_HBL) {
    return DOCUMENT_TYPE_HBL;
  } else if (docType === DOCUMENT_MAWB) {
    return DOCUMENT_TYPE_NEW_MAWB;
  } else {
    return DOCUMENT_TYPE_NEW_HAWB;
  }
};

export const hbLDraftApproval = (
  shipment_document: ShipmentDocumentValue,
  shipment: ShipmentValue,
  client: ApolloClient<object>,
  sessionData: SessionDataValue
): Action => {
  const { permissions } = sessionData;
  const isEnable = () => {
    if (
      // FIX ME
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      !hasPermission(permissions, {
        name: PERMISSION_BL_DRAFT_APPROVE,
        docType: 'Shipment::ShipmentDocument',
      })
    ) {
      return false;
    }
    if (
      documentType(shipment_document, shipment) === DOCUMENT_HBL &&
      (isDraft(shipment_document) || isDraftReceived(shipment_document))
    ) {
      return true;
    }
    return false;
  };

  if (shipment?.freight_type === FREIGHT_TYPE_OCEAN && !shipment_document?.document_id) {
    return {
      type: PRIMARY_TYPE,
      key: 'bl_draft_approval',
      displayComponent: 'First Draft Approved by Customer/Agent',
      icon: <FileDoneOutlined />,
      extraProps: {
        action_name: 'bl_draft_approval',
        document_status: DOCUMENT_STATUS_DRAFT_APPROVED,
        title: 'Draft Approve',
      },
      performAction: documentStatusUpdateActionRenderer,
      isEnable:
        documentType(shipment_document, shipment) === DOCUMENT_HBL &&
        (isDraft(shipment_document) || isDraftReceived(shipment_document)) &&
        // FIX ME
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        hasPermission(permissions, {
          name: PERMISSION_BL_DRAFT_APPROVE,
          docType: 'Shipment::ShipmentDocument',
        }),
    };
  }

  return {
    type: PRIMARY_TYPE,
    key: 'bl_draft_approval',
    displayComponent: 'First Draft Approved by Customer/Agent',
    icon: <FileDoneOutlined />,
    onClick: async () => {
      if (shipment && shipment_document?.document_id) {
        window.open(
          `${process.env.SHIPMNTS_WEB_URL}/shipment/${shipment?.id}/documents_latest/${shipment_document?.document_id}?shouldDraftApprove=true`,
          '_self'
        );
      }
    },
    isEnable: isEnable(),
  };
};

export const draftReceived = (
  shipment_document: ShipmentDocumentValue,
  shipment: ShipmentValue
): Action => {
  const isEnable = () => {
    if (
      documentType(shipment_document, shipment) === 'HBL' &&
      isPendingCreation(shipment_document)
    ) {
      return true;
    }
    if (
      documentType(shipment_document, shipment) === 'MBL' &&
      (isPendingCreation(shipment_document) ||
        isSiFiled(shipment_document) ||
        isDraft(shipment_document))
    ) {
      return true;
    }
    return false;
  };

  return {
    type: PRIMARY_TYPE,
    key: 'draft_received',
    displayComponent: 'First Draft Received From Carrier',
    icon: <FileDoneOutlined />,
    isEnable: isEnable(),
    performAction: firstDraftReceived,
  };
};

export const mblDraftApproval = (
  shipment_document: ShipmentDocumentValue,
  shipment: ShipmentValue,
  client: ApolloClient<object>,
  sessionData: SessionDataValue
): Action => {
  const { permissions } = sessionData;
  const isEnable = () => {
    if (
      // FIX ME
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      !hasPermission(permissions, {
        name: PERMISSION_BL_DRAFT_APPROVE,
        docType: 'Shipment::ShipmentDocument',
      })
    ) {
      return false;
    }
    if (
      documentType(shipment_document, shipment) === DOCUMENT_MBL &&
      (isDraft(shipment_document) ||
        isPendingCreation(shipment_document) ||
        isSiFiled(shipment_document) ||
        isFirstDraftShared(shipment_document) ||
        isDraftReceived(shipment_document))
    ) {
      return true;
    }
    return false;
  };

  return {
    type: PRIMARY_TYPE,
    key: 'bl_draft_approval',
    displayComponent: 'First Draft Approved by Customer/Agent',
    icon: <FileDoneOutlined />,
    extraProps: {
      action_name: 'bl_draft_approval',
      document_status: DOCUMENT_STATUS_DRAFT_APPROVED,
      title: 'Draft Approve',
    },
    performAction: documentStatusUpdateActionRenderer,
    isEnable: isEnable(),
  };
};

export const ReleaseMBL = (
  shipment_document: ShipmentDocumentValue,
  shipment: ShipmentValue,
  sessionData: SessionDataValue
): Action => {
  const { permissions } = sessionData;
  return {
    type: PRIMARY_TYPE,
    key: 'release_mbl',
    displayComponent: 'Release MBL',
    isEnable:
      // FIX ME
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      hasPermission(permissions, {
        name: PERMISSION_RELEASE_DOCUMENT,
        docType: 'Shipment::ShipmentDocument',
      }) &&
      documentType(shipment_document, shipment) === DOCUMENT_MBL &&
      shipment_document?.document_status
        ? [
            DOCUMENT_STATUS_DRAFT,
            DOCUMENT_STATUS_DRAFT_RECEIVED,
            DOCUMENT_STATUS_DRAFT_APPROVED,
            DOCUMENT_STATUS_PENDING_CREATION,
            DOCUMENT_STATUS_SI_FILED,
            DOCUMENT_STATUS_FIRST_DRAFT_SHARED,
            DOCUMENT_STATUS_BL_AUDITED,
          ].includes(shipment_document.document_status)
        : false,
    performAction: releaseMblRenderer,
    icon: <AuditOutlined />,
  };
};

export const EditMbl = (
  shipment_document: ShipmentDocumentValue,
  shipment: ShipmentValue,
  sessionData: SessionDataValue
): Action => {
  const { permissions } = sessionData;
  return {
    type: SECONDARY_TYPE,
    key: 'edit_mbl',
    displayComponent: 'Edit MBL',
    isEnable:
      // FIX ME
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      hasPermission(permissions, {
        name: PERMISSION_RELEASE_DOCUMENT,
        docType: 'Shipment::ShipmentDocument',
      }) &&
      documentType(shipment_document, shipment) === DOCUMENT_MBL &&
      shipment_document?.document_status
        ? [
            DOCUMENT_STATUS_DRAFT,
            DOCUMENT_STATUS_DRAFT_RECEIVED,
            DOCUMENT_STATUS_DRAFT_APPROVED,
            DOCUMENT_STATUS_PENDING_CREATION,
            DOCUMENT_STATUS_BL_AUDITED,
          ].includes(shipment_document.document_status)
        : false,
    performAction: editMbl,
    icon: <EditOutlined />,
  };
};

export const ReleaseHBL = (
  shipment_document: ShipmentDocumentValue,
  shipment: ShipmentValue,
  sessionData: SessionDataValue
): Action => {
  const { permissions } = sessionData;
  const isEnable = () => {
    if (
      // FIX ME
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      !hasPermission(permissions, {
        name: PERMISSION_RELEASE_DOCUMENT,
        docType: 'Shipment::ShipmentDocument',
      })
    ) {
      return false;
    }
    return documentType(shipment_document, shipment) === DOCUMENT_HBL &&
      shipment_document?.document_status
      ? [
          DOCUMENT_STATUS_DRAFT,
          DOCUMENT_STATUS_DRAFT_RECEIVED,
          DOCUMENT_STATUS_DRAFT_APPROVED,
        ].includes(shipment_document.document_status)
      : false;
  };

  if (shipment?.freight_type === FREIGHT_TYPE_OCEAN && !shipment_document?.document_id) {
    return {
      type: PRIMARY_TYPE,
      key: 'release_hbl',
      displayComponent: 'Release HBL',
      icon: <FileDoneOutlined />,
      extraProps: {
        action_name: 'release_hbl',
        document_status: DOCUMENT_STATUS_EXECUTED,
        title: 'Release HBL',
      },
      performAction: documentStatusUpdateActionRenderer,
      isEnable: isEnable(),
    };
  }

  return {
    type: PRIMARY_TYPE,
    key: 'release_hbl',
    displayComponent:
      shipment?.freight_type === FREIGHT_TYPE_ROAD ? 'Release Consignment Note' : 'Release HBL',
    onClick: () => {
      if (shipment && shipment_document?.document_id) {
        window.open(
          `${process.env.SHIPMNTS_WEB_URL}/shipment/${shipment?.id}/documents_latest/${shipment_document?.document_id}?shouldRelease=true`,
          '_self'
        );
      }
    },
    componentProps: { data: shipment, document: shipment_document },
    icon: <AuditOutlined />,
    isEnable: isEnable(),
  };
};

export const givenToCustomer = (
  shipment_document: ShipmentDocumentValue,
  shipment: ShipmentValue,
  user: SessionDataValue,
  client: ApolloClient<object>,
  setEmailProps: (emailProps: EmailProps) => void,
  setVisible: (visible: boolean) => void
): Action => {
  return {
    type: PRIMARY_TYPE,
    key: 'given_to_customer',
    displayComponent: 'Mark MBL as given to customer',
    isEnable:
      shipment?.shipment_type === SHIPMENT_TYPE_DIRECT &&
      isExecuted(shipment_document) &&
      documentType(shipment_document, shipment) === DOCUMENT_MBL,
    icon: <FileProtectOutlined />, // TODO: Loading Icon
    performAction: blReleaseToCustomerRenderer,
  };
};

export const receivedAtOrigin = (
  shipment_document: ShipmentDocumentValue,
  shipment: ShipmentValue
): Action => {
  const docType = documentType(shipment_document, shipment);

  const isEnable = () => {
    if (shipment.trade_type !== TRADE_TYPE_EXPORT) return false;
    if (
      docType === DOCUMENT_MBL &&
      shipment_document?.bl_type === BL_TYPE_ORIGINAL &&
      ([SHIPMENT_TYPE_CONSOL, SHIPMENT_TYPE_BACK_TO_BACK].includes(shipment.shipment_type || '')
        ? isGivenToCustomer(shipment_document) || isExecuted(shipment_document)
        : isGivenToCustomer(shipment_document))
    ) {
      return true;
    } else if (
      docType === DOCUMENT_HBL &&
      shipment_document?.bl_type === BL_TYPE_ORIGINAL &&
      isExecuted(shipment_document)
    ) {
      return true;
    }
    return false;
  };
  return {
    type: PRIMARY_TYPE,
    key: 'received_by_shipper',
    icon: <FlagOutlined />,
    displayComponent: `Mark ${docType} as Received by Shipper`,
    isEnable: isEnable(),
    extraProps: {
      action_name: 'received_by_shipper',
      document_status: DOCUMENT_STATUS_RECEIVED_BY_SHIPPER,
      title: 'Received by Shipper',
    },
    performAction: documentStatusUpdateActionRenderer,
  };
};

export const blAudit = (
  shipment_document: ShipmentDocumentValue,
  shipment: ShipmentValue
): Action => {
  const docType = documentType(shipment_document, shipment);

  const isEnable = () => {
    if (shipment.trade_type !== TRADE_TYPE_EXPORT) return false;
    if (
      docType === DOCUMENT_MBL &&
      (isPendingCreation(shipment_document) ||
        isDraft(shipment_document) ||
        isSiFiled(shipment_document) ||
        isDraftReceived(shipment_document) ||
        isFirstDraftShared(shipment_document) ||
        isDraftApproved(shipment_document))
    ) {
      return true;
    }
    return false;
  };
  return {
    type: PRIMARY_TYPE,
    key: 'bl_audited',
    icon: <FlagOutlined />,
    displayComponent: `BL Audited`,
    isEnable: isEnable(),
    extraProps: {
      action_name: 'bl_audited',
      document_status: DOCUMENT_STATUS_BL_AUDITED,
      title: 'BL Audit',
    },
    performAction: documentStatusUpdateActionRenderer,
  };
};

export const surrenderedAtOrigin = (
  shipment_document: ShipmentDocumentValue,
  shipment: ShipmentValue
): Action => {
  const docType = documentType(shipment_document, shipment);
  const isEnable = () => {
    if (
      docType === DOCUMENT_MBL &&
      shipment_document?.bl_type === BL_TYPE_ORIGINAL &&
      (isReceivedAtOrigin(shipment_document) ||
        ([SHIPMENT_TYPE_CONSOL, SHIPMENT_TYPE_BACK_TO_BACK].includes(shipment.shipment_type || '')
          ? isGivenToCustomer(shipment_document) || isExecuted(shipment_document)
          : isGivenToCustomer(shipment_document)))
    ) {
      return true;
    } else if (
      docType === DOCUMENT_HBL &&
      shipment_document?.bl_type === BL_TYPE_ORIGINAL &&
      (isReceivedAtOrigin(shipment_document) || isExecuted(shipment_document))
    ) {
      return true;
    }
    return false;
  };

  return {
    type: PRIMARY_TYPE,
    key: 'surrendered_at_origin',
    icon: <FlagOutlined />,
    displayComponent: `Mark ${docType} as Surrendered At Origin`,
    isEnable: isEnable(),
    performAction: surrenderedAtOriginActionRenderer,
  };
};

export const receivedAtDestination = (
  shipment_document: ShipmentDocumentValue,
  shipment: ShipmentValue
): Action => {
  const docType = documentType(shipment_document, shipment);
  const isEnable = () => {
    if (
      docType === DOCUMENT_MBL &&
      shipment_document?.bl_type === BL_TYPE_ORIGINAL &&
      (isReceivedAtOrigin(shipment_document) ||
        ([SHIPMENT_TYPE_CONSOL, SHIPMENT_TYPE_BACK_TO_BACK].includes(shipment.shipment_type || '')
          ? isGivenToCustomer(shipment_document) || isExecuted(shipment_document)
          : isGivenToCustomer(shipment_document)))
    ) {
      return true;
    } else if (
      docType === DOCUMENT_HBL &&
      shipment_document?.bl_type === BL_TYPE_ORIGINAL &&
      (isReceivedAtOrigin(shipment_document) || isExecuted(shipment_document))
    ) {
      return true;
    }
    return false;
  };

  return {
    type: PRIMARY_TYPE,
    key: 'received_a_destination',
    icon: <CustomIcon icon="SystemUpdateIcon" />,
    displayComponent: `Mark ${docType} as Received At Destination`,
    isEnable: isEnable(),
    performAction: receivedAtDestinationActionRenderer,
  };
};

export const unreleaseDocument = (
  shipment_document: ShipmentDocumentValue,
  shipment: ShipmentValue,
  sessionData: SessionDataValue
): Action => {
  const { permissions } = sessionData;
  const isReopen =
    shipment_document?.document_type === SHIPMENT_DOCUMENT_TYPE_HOUSE &&
    shipment_document.document_status === DOCUMENT_STATUS_DRAFT_APPROVED;
  const isEnable = () => {
    if (
      // FIX ME
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      !hasPermission(permissions, {
        name: PERMISSION_UNRELEASE_DOCUMENT,
        docType: 'Shipment::ShipmentDocument',
      })
    )
      return false;
    if (
      shipment_document?.document_type === SHIPMENT_DOCUMENT_TYPE_HOUSE &&
      shipment_document.document_status === DOCUMENT_STATUS_DRAFT_APPROVED
    )
      return true;
    if (
      [
        DOCUMENT_STATUS_HANDOVER_SET_PRINTED,
        DOCUMENT_STATUS_SURRENDERED_AT_ORIGIN,
        DOCUMENT_STATUS_EXECUTED,
        DOCUMENT_STATUS_RECEIVED_AT_DESTINATION,
        DOCUMENT_STATUS_GIVEN_TO_CUSTOMER,
        DOCUMENT_STATUS_RECEIVED_BY_SHIPPER,
      ].includes(shipment_document?.document_status || '')
    ) {
      return true;
    }
    return false;
  };

  const getDisplayComponent = () => {
    if (documentType(shipment_document, shipment) === DOCUMENT_HBL) {
      if (isReopen) return 'Re-open HBL for Edit';
    }
    let docType = getDocumentType(shipment_document, shipment);
    if (shipment.freight_type === FREIGHT_TYPE_ROAD) {
      docType = DOCUMENT_TYPE_CONSIGNMENT_NOTE;
    }
    return actionNameMap[docType].buttonLabel;
  };
  return {
    type: NEGATIVE_TYPE,
    key: 'unrelease_bl',
    icon: isReopen ? <CustomIcon icon="FileRedoIcon" /> : <CustomIcon icon="FileSeachIcon" />,
    displayComponent: getDisplayComponent(),
    isEnable: isEnable(),
    performAction: unreleaseBlActionRenderer,
  };
};

export const changeAirLineInfo = (
  shipment_document: ShipmentDocumentValue,
  shipment: ShipmentValue,
  navigate: any
): Action => {
  const resourceType = 'shipment';
  const stock_allocated = shipment_document?.stock_order_item?.id;
  return {
    type: SECONDARY_TYPE,
    key: `${resourceType}_edit_airline`,
    icon: <EditOutlined />,
    displayComponent: 'Change Airline Information',
    onClick: () => {
      window.open(`${process.env.SHIPMNTS_WEB_URL}/${resourceType}/${shipment?.id}/edit_airline`);
    },
    isEnable:
      stock_allocated &&
      documentType(shipment_document, shipment) === DOCUMENT_MAWB &&
      shipment_document?.document_status
        ? [
            DOCUMENT_STATUS_DRAFT,
            DOCUMENT_STATUS_DRAFT_RECEIVED,
            DOCUMENT_STATUS_HANDOVER_SET_PRINTED,
            DOCUMENT_STATUS_PENDING_CREATION,
          ].includes(shipment_document?.document_status)
        : false,
  };
};

export const cancelMawb = (
  shipment_document: ShipmentDocumentValue,
  shipment: ShipmentValue
): Action => {
  return {
    type: NEGATIVE_TYPE,
    key: 'cancel_MAWB',
    displayComponent: 'Cancel',
    icon: <CustomIcon icon="FileTimesIcon" />,
    isEnable:
      documentType(shipment_document, shipment) === DOCUMENT_MAWB &&
      shipment_document?.document_status
        ? [
            DOCUMENT_STATUS_DRAFT,
            DOCUMENT_STATUS_DRAFT_RECEIVED,
            DOCUMENT_STATUS_HANDOVER_SET_PRINTED,
          ].includes(shipment_document?.document_status)
        : false,
    performAction: onCancelRenderer,
  };
};

export const backToTown = (
  shipment_document: ShipmentDocumentValue,
  shipment: ShipmentValue
): Action => {
  return {
    type: NEGATIVE_TYPE,
    key: 'back_to_town',
    icon: <IssuesCloseOutlined />,
    displayComponent: 'Back To Town',
    isEnable:
      documentType(shipment_document, shipment) === DOCUMENT_MAWB &&
      shipment_document?.document_status
        ? shipment_document?.document_status === DOCUMENT_STATUS_HANDOVER_SET_PRINTED ||
          shipment_document?.document_status === DOCUMENT_STATUS_EXECUTED
        : false,
    performAction: onBackToTownRenderer,
  };
};

export const releaseMAWB = (
  shipment_document: ShipmentDocumentValue,
  shipment: ShipmentValue,
  sessionData: SessionDataValue
): Action => {
  const { permissions } = sessionData;
  const actionType =
    documentType(shipment_document, shipment) === DOCUMENT_MAWB
      ? 'shouldHandover'
      : 'shouldRelease';
  const displayName =
    documentType(shipment_document, shipment) === DOCUMENT_MAWB
      ? 'Print Handover set'
      : 'Mark as Released';
  const isEnable = () => {
    if (shipment.freight_type !== FREIGHT_TYPE_AIR) return false;
    if (
      // FIX ME
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      hasPermission(permissions, {
        name: PERMISSION_RELEASE_DOCUMENT,
        docType: 'Shipment::ShipmentDocument',
      })
    ) {
      if (
        shipment_document.document_status === DOCUMENT_STATUS_DRAFT ||
        shipment_document.document_status === DOCUMENT_STATUS_DRAFT_RECEIVED
      )
        return true;
    }
    return false;
  };
  return {
    type: PRIMARY_TYPE,
    key: 'release_document',
    icon: <DatabaseOutlined />,
    displayComponent: displayName,
    onClick: () => {
      window.open(
        `${process.env.SHIPMNTS_WEB_URL}/shipment/${shipment?.id}/documents_latest/${shipment_document?.document_id}?${actionType}=true`
      );
    },
    isEnable: isEnable(),
  };
};

export const siFiling = (
  shipment_document: ShipmentDocumentValue,
  shipment: ShipmentValue
): Action => {
  return {
    type: PRIMARY_TYPE,
    displayComponent: 'SI Filed',
    key: 'si_filed',
    icon: <FileAddOutlined />,
    isEnable:
      documentType(shipment_document, shipment) === DOCUMENT_MBL &&
      (isPendingCreation(shipment_document) || isDraft(shipment_document)),
    performAction: sifiledRenderer,
  };
};

export const firstDraftSharedWithCustomer = (
  shipment_document: ShipmentDocumentValue,
  shipment: ShipmentValue
): Action => {
  return {
    type: PRIMARY_TYPE,
    displayComponent: 'First Draft Shared With Customer',
    key: 'first_draft_shared_with_customer',
    icon: <FileAddOutlined />,
    isEnable:
      shipment.trade_type === TRADE_TYPE_EXPORT &&
      documentType(shipment_document, shipment) === DOCUMENT_MBL &&
      (isSiFiled(shipment_document) ||
        isDraft(shipment_document) ||
        isDraftReceived(shipment_document)),
    performAction: firstDraftSharedWithCustomerRenderer,
  };
};

export const createDocument = (shipment_document: ShipmentDocumentValue): Action => {
  const isEnable = () => {
    if (isPendingCreation(shipment_document)) {
      return true;
    }
    return false;
  };
  return {
    type: SECONDARY_TYPE,
    key: 'create_document',
    displayComponent: 'Create Draft',
    icon: <CustomIcon icon="FileEditIcon" />,
    isEnable: isEnable(),
    performAction: createDocumentRenderer,
  };
};

export const preAlertPending = (
  shipment_document: ShipmentDocumentValue,
  shipment: ShipmentValue
): Action => {
  const isEnable = () => {
    return (
      shipment?.trade_type === TRADE_TYPE_EXPORT &&
      !Boolean(shipment.master_shipment_id) &&
      shipment.status !== SHIPMENT_STATUS_PLANNED
    );
  };
  return {
    type: SECONDARY_TYPE,
    key: 'send_pre_alert_shipment_document',
    icon: shipment.ops_pre_alert_sent_date ? (
      <CustomIcon icon="DoneIcon" />
    ) : (
      <CustomIcon icon="EnvelopeUploadIcon" />
    ),
    displayComponent: shipment.ops_pre_alert_sent_date ? 'Resend Pre-Alert' : 'Send Pre-Alert',
    isEnable: isEnable(),
    performAction: sendPreAlertRenderer,
  };
};

export const proofOfDeliveryReceivedAction = (
  shipment_document: ShipmentDocumentValue,
  shipment: ShipmentValue
): Action => {
  const isEnable = () => {
    if (
      shipment?.freight_type === FREIGHT_TYPE_ROAD &&
      [DOCUMENT_STATUS_EXECUTED].includes(shipment_document?.document_status || '')
    ) {
      return true;
    }
    return false;
  };

  return {
    type: SECONDARY_TYPE,
    key: 'proof_of_delivery_received',
    icon: <FileDoneOutlined />,
    displayComponent: 'Proof Of Delivery Received',
    isEnable: isEnable(),
    performAction: proofOfDeliveryRecievedRenderer,
  };
};

export const getShipmentDocumentActions = (
  shipment_document: ShipmentDocumentValue,
  shipment: ShipmentValue,
  client: ApolloClient<object>,
  sessionData: SessionDataValue,
  setEmailProps: (emailProps: EmailProps) => void,
  setVisible: (visible: boolean) => void,
  navigate: any
) => {
  if (shipment.freight_type === FREIGHT_TYPE_ROAD)
    return [
      createDocument(shipment_document),
      ReleaseHBL(shipment_document, shipment, sessionData),
      unreleaseDocument(shipment_document, shipment, sessionData),
      proofOfDeliveryReceivedAction(shipment_document, shipment),
    ];
  return [
    siFiling(shipment_document, shipment),
    draftReceived(shipment_document, shipment),
    firstDraftSharedWithCustomer(shipment_document, shipment),
    mblDraftApproval(shipment_document, shipment, client, sessionData),
    hbLDraftApproval(shipment_document, shipment, client, sessionData),
    blAudit(shipment_document, shipment),
    ReleaseMBL(shipment_document, shipment, sessionData),
    ReleaseHBL(shipment_document, shipment, sessionData),
    releaseMAWB(shipment_document, shipment, sessionData),
    givenToCustomer(shipment_document, shipment, sessionData, client, setEmailProps, setVisible),
    receivedAtOrigin(shipment_document, shipment),
    surrenderedAtOrigin(shipment_document, shipment),
    receivedAtDestination(shipment_document, shipment),
    unreleaseDocument(shipment_document, shipment, sessionData),
    createDocument(shipment_document),
    EditMbl(shipment_document, shipment, sessionData),
    changeAirLineInfo(shipment_document, shipment, navigate),
    preAlertPending(shipment_document, shipment),
  ];
};
