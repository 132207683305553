import React, { useEffect, useRef, useState } from 'react';
import { Modal, Layout, Button, message } from '@shipmnts/pixel-hub';
import { ShipmentValue } from 'operations/models/Shipment';
import { NAVBAR_HEIGHT } from 'shipmnts_navigation';
import { GridOptions } from '@ag-grid-community/core';
import { duplicateAwbDoc, generateBlankDoc } from './CreateDocument';
import { GlobalViewLayout } from '@shipmnts/pixel-hub';
import { useSession } from 'common';
import { useApolloClient, useLazyQuery } from '@apollo/client';
import { SHIPMENT } from 'operations/graphql/shipment';
import { LoadingOutlined } from '@shipmnts/pixel-hub';
import { NewDocumentToDocument } from 'operations/modules/actionHelper/ShipmentDocumentActions/shipmentDocumentActionHelper';
import { DOCUMENT_MAWB } from 'operations/modules/actionHelper/constants';
import { NATURE_OF_CARGO_TYPES } from '../../constants';

const { Content } = Layout;
interface DuplicateAwbModalProps {
  onClose: () => void;
  docType: string;
  shipment: ShipmentValue;
}

const renderPartyDetailByName = (params: { name: string; shipment: ShipmentValue }): any => {
  const { shipment, name } = params;
  const party = shipment.getShipmentPartyByName(name);
  if (!party) return '';
  return party.party_company;
};

const getGlobalView = (shipment: ShipmentValue) => {
  const globalView: any = {
    id: '1',
    name: 'Duplicate AWB',
    color: '#eeeeee',
    doc_type_id: 'Shipment::ShipmentDocument',
    definition: {
      sort: [
        {
          field_id: 'shipment_documents_shipment_date',
          direction: 'desc',
        },
      ],
      fields: [
        {
          id: 'shipment_documents_shipment_number',
          label: 'AWB #',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'String',
          filterable: true,
          cell_renderer: {
            args: {
              id_field: 'document_id',
              doc_type_id: 'Shipment::ShipmentDocument',
              extra_fields: ['shipment_documents_shipment_id'],
            },
            function: 'link_render',
          },
          filter_options: '{}',
          groupable: false,
        },
        {
          id: 'shipment_documents_shipment_date',
          label: 'Shipment Date',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'Date',
          filterable: true,
          cell_renderer: {},
          filter_options: '{}',
          groupable: true,
        },
        {
          id: 'analytics_shipment_reports_shipper_id',
          label: 'Shipper',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'Search',
          filterable: true,
          cell_renderer: { args: {}, function: 'record_loader_render' },
          filter_options: '{"doc_type":"Network::Company"}',
          groupable: true,
        },
        {
          id: 'shipments_carrier_id',
          label: 'Airline',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'Search',
          filterable: true,
          cell_renderer: { args: {}, function: 'record_loader_render' },
          filter_options: '{"doc_type":"Global::Carrier"}',
          groupable: true,
        },
        {
          id: 'analytics_shipment_reports_consignee_id',
          label: 'Consignee',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'Search',
          filterable: true,
          cell_renderer: { args: {}, function: 'record_loader_render' },
          filter_options: '{"doc_type":"Network::Company"}',
          groupable: true,
        },
        {
          id: 'shipments_total_number_of_packages',
          label: 'Total Number of packages',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'Integer',
          filterable: true,
          cell_renderer: {},
          filter_options: '{}',
          groupable: false,
        },
        {
          id: 'shipments_gross_weight',
          label: 'Gross Weight',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'Float',
          filterable: true,
          cell_renderer: {},
          filter_options: '{}',
          groupable: false,
        },
        {
          id: 'shipments_chargeable_weight',
          label: 'Chargeable Weight',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'Float',
          filterable: true,
          cell_renderer: {},
          filter_options: '{}',
          groupable: false,
        },
        {
          id: 'shipments_job_date',
          label: 'Job Date',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'Date',
          filterable: true,
          cell_renderer: {},
          filter_options: '{}',
          groupable: true,
        },
        {
          id: 'analytics_shipment_reports_origin_clearance_date',
          label: 'Origin Clearance Date',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'DateTime',
          filterable: true,
          cell_renderer: {},
          filter_options: '{}',
          groupable: true,
        },
        {
          id: 'shipments_port_of_discharge_id',
          label: 'Port Of Discharge',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'Search',
          filterable: true,
          cell_renderer: { args: {}, function: 'location_render' },
          filter_options: '{ "doc_type": "Global::Location" }',
          groupable: true,
        },
        {
          id: 'analytics_shipment_reports_last_leg_voyage_number',
          label: 'Flight Number',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'String',
          filterable: true,
          filter_options: '{}',
          groupable: false,
        },
        {
          id: 'shipments_estimated_time_of_departure',
          label: 'Flight Date',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'DateTime',
          filterable: true,
          cell_renderer: {},
          filter_options: '{}',
          groupable: true,
        },
      ],
      filters: [],
    },
    standard_report: true,
    is_disabled: false,
    global: true,
  };

  const shipper = renderPartyDetailByName({ shipment: shipment, name: 'shipper' });

  if (shipper) {
    globalView?.definition?.filters?.push({
      field_id: 'analytics_shipment_reports_shipper_id',
      condition: {
        type: 'in',
        values: [shipper],
      },
      isQuickFilter: true,
    });
  }

  const natureOfCargoFilters = Object.keys(shipment?.shipment_properties?.activeProperties || {});
  if (natureOfCargoFilters) {
    natureOfCargoFilters.forEach((ncf) => {
      const label = (NATURE_OF_CARGO_TYPES || [])?.find((nct) => nct.key === ncf)?.name;
      globalView?.definition?.fields?.push({
        id: `shipments_${ncf}`,
        label,
        width: 80,
        section: 'Basic',
        sortable: false,
        field_type: 'Enum',
        filterable: true,
        cell_renderer: {},
        filter_options:
          '{ "values": ["true", "false"], "display_hash": { "true": "Yes", "false": "No" } }',
        groupable: false,
      });
      globalView?.definition?.filters?.push({
        field_id: `shipments_${ncf}`,
        condition: { type: 'equals', value: 'true' },
      });
    });
  }

  return globalView;
};

const getDefaultFilters = (shipment: ShipmentValue, actualDocType: string) => {
  const defaultFilters: any[] = [
    {
      field_id: 'shipments_freight_type',
      condition: { type: 'equals', value: 'air' },
    },
    {
      field_id: 'shipments_trade_type',
      condition: { type: 'equals', value: 'export' },
    },
    {
      field_id: 'shipment_documents_document_type',
      condition: { type: 'equals', value: actualDocType === DOCUMENT_MAWB ? 'master' : 'house' },
    },
    {
      field_id: 'shipment_documents_document_status',
      condition: { type: 'in', values: ['draft', 'handover_set_printed', 'executed'] },
    },
    {
      field_id: 'shipment_documents_document_id',
      condition: { type: 'isNotNull' },
    },
  ];

  if (shipment?.involved_branch) {
    defaultFilters.push({
      field_id: 'shipments_involved_branch_id',
      condition: { type: 'equals', value: shipment.involved_branch },
    });
  }

  return defaultFilters;
};

export default function DuplicateAwbModal(props: DuplicateAwbModalProps) {
  const { onClose, docType, shipment } = props;
  const gridRef = useRef<GridOptions>();
  const user = useSession();
  const client = useApolloClient();
  const [fetchShipment, { data, error, loading }] = useLazyQuery(SHIPMENT, {
    fetchPolicy: 'no-cache',
  });
  const actualDocType = NewDocumentToDocument[docType];

  const globalView = useRef(getGlobalView(shipment));
  const defaultFilters = useRef(getDefaultFilters(shipment, actualDocType));

  const [rowSelected, setRowSelected] = useState(false);
  const [creatingDoc, setCreatingDoc] = useState(false);
  const [duplicatingDoc, setDuplicatingDoc] = useState(false);

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
    if (data?.shipment) {
      duplicateAwbDoc(data.shipment, shipment, docType, user, client, setDuplicatingDoc);
    }
  }, [data, error, client, docType, shipment, user]);

  const duplicateAwb = () => {
    const selectedShipment = gridRef?.current?.api?.getSelectedRows();
    if (!selectedShipment || selectedShipment.length === 0) return;
    fetchShipment({ variables: { id: selectedShipment[0].shipment_id } });
  };

  const RenderFooter = () => {
    return (
      <div className="align-right">
        <Button className="margin-right-sm" onClick={onClose}>
          Cancel
        </Button>
        <Button
          className="margin-right-sm"
          disabled={creatingDoc || duplicatingDoc}
          onClick={() => {
            generateBlankDoc(shipment, docType, user, client, setCreatingDoc);
          }}
        >
          {creatingDoc ? `...Creating ${actualDocType}` : `Create Blank ${actualDocType}`}
          {creatingDoc ? <LoadingOutlined /> : null}
        </Button>
        <Button
          type="primary"
          className="margin-right-sm"
          disabled={!rowSelected || creatingDoc || duplicatingDoc || loading}
          onClick={duplicateAwb}
        >
          {duplicatingDoc ? `...Duplicating ${actualDocType}` : `Duplicate ${actualDocType}`}
          {duplicatingDoc ? <LoadingOutlined /> : null}
        </Button>
      </div>
    );
  };

  const onSelectionChange = () => {
    setRowSelected(gridRef?.current?.api?.getSelectedRows().length !== 0);
  };

  return (
    <Modal
      open={true}
      className="overflow-y-hidden"
      onCancel={onClose}
      title={`Select & Copy From Previous ${actualDocType}`}
      footer={<RenderFooter />}
      width="100%"
      style={{ width: '100% !important', top: NAVBAR_HEIGHT }}
    >
      <Layout>
        <Content style={{ background: 'white' }}>
          <GlobalViewLayout
            gridRef={gridRef}
            doc_type_id={'Shipment::ShipmentDocument'}
            globalView={globalView.current}
            defaultFilters={defaultFilters.current}
            quick_filter_field_ids={[
              'shipment_documents_shipment_date',
              'analytics_shipment_reports_shipper_id',
              'analytics_shipment_reports_consignee_id',
              'shipments_carrier_id',
            ]}
            reportConfig={{
              onSelectionChanged: onSelectionChange,
              rowSelection: 'single',
            }}
            height="50vh"
          />
        </Content>
      </Layout>
    </Modal>
  );
}
