import { omit } from 'lodash';
import React from 'react';
import { useLocation } from 'wouter';
const RedirectToTransactionForm = (props: any) => {
  const { transaction, nextTransaction } = props;
  const { 1: navigate } = useLocation();
  const products: any = [];
  transaction?.warehouse_transaction_products?.forEach((tp: any) => {
    if (tp?.available_qty > 0)
      products.push({
        ...omit(tp, [
          'issued_qty',
          'available_qty',
          'id',
          'warehouse_transaction_type',
          '__typename',
          'warehouse_transaction',
        ]),
        compare_id: tp?.id,
        qty: tp?.available_qty,
        linked_to_id: tp?.id,
      });
  });
  const value = {
    ...transaction,
    warehouse_transaction_products: products,
  };
  navigate(`~/form/${nextTransaction}/new`, { state: { transaction: JSON.stringify(value) } });

  return <></>;
};

export default RedirectToTransactionForm;
