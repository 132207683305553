import React from 'react';
import { useTrackerContext } from '../../TrackingContext';
import { EventType } from '../../types';
import SingleEvent from './SingleEvent';
import TimeLineHeader from './TimelineHeader';

type Props = {
  headerIcon: React.ComponentType;
  headerText: string;
  isAccordian?: boolean;
  events: EventType[];
  editable?: boolean;
  onFormSubmit?: (success?: boolean) => void;
  lastOccuredEvent: EventType | undefined | null;
};

const Timeline = ({
  isAccordian,
  headerIcon,
  headerText,
  events,
  editable,
  onFormSubmit,
  lastOccuredEvent,
}: Props): JSX.Element => {
  const { getStateOfEvent } = useTrackerContext();
  const state = getStateOfEvent(events, lastOccuredEvent);
  return (
    <div className="timeline">
      <TimeLineHeader icon={headerIcon} title={headerText} state={state} />
      {events?.map((event, index) => {
        return (
          <SingleEvent
            key={index}
            isAccordian={isAccordian}
            event={event}
            editable={editable}
            onFormSubmit={onFormSubmit}
            lastOccuredEvent={lastOccuredEvent}
          />
        );
      })}
    </div>
  );
};

export default Timeline;
