import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Input, message, Modal, Form, dayjs } from '@shipmnts/pixel-hub';
import { UPDATE_SHIPMENT_DOCUMENT_STATUS } from 'operations/graphql/shipmentDocument';
import { useMutation } from '@apollo/client';
import {
  DOCUMENT_TYPE_HBL,
  DOCUMENT_TYPE_MBL,
  DOCUMENT_TYPE_NEW_HAWB,
  DOCUMENT_TYPE_NEW_MAWB,
} from 'operations/constants';
import {
  DOCUMENT_STATUS_DRAFT,
  DOCUMENT_STATUS_DRAFT_APPROVED,
  DOCUMENT_STATUS_PENDING_CREATION,
  SHIPMENT_DOCUMENT_TYPE_HOUSE,
} from '../../constants';
import { ShipmentDocumentValue } from 'operations/models/ShipmentDocument';
import { ShipmentValue } from 'operations/models/Shipment';

interface UnreleaseDocumentProps {
  documentType: string;
  shipmentDocument: ShipmentDocumentValue;
  shipment: ShipmentValue;
  visible?: boolean;
  onClose?: () => void;
  onSuccess?: (sendEmail?: boolean) => void;
}
export default function UnreleaseDocument(props: UnreleaseDocumentProps) {
  const { documentType, shipmentDocument, visible, onClose, onSuccess } = props;
  const [form] = Form.useForm();
  const showSendEmail = !(
    shipmentDocument?.document_type === SHIPMENT_DOCUMENT_TYPE_HOUSE &&
    shipmentDocument.document_status === DOCUMENT_STATUS_DRAFT_APPROVED
  );

  const [sendEmail, setSendEmail] = useState<boolean>(showSendEmail);
  const prevStatusMap: { [key: string]: string } = {
    [DOCUMENT_TYPE_NEW_MAWB]: DOCUMENT_STATUS_DRAFT,
    [DOCUMENT_TYPE_NEW_HAWB]: DOCUMENT_STATUS_DRAFT,
    [DOCUMENT_TYPE_HBL]: shipmentDocument?.document_id
      ? DOCUMENT_STATUS_DRAFT
      : DOCUMENT_STATUS_PENDING_CREATION,
    [DOCUMENT_TYPE_MBL]: shipmentDocument?.document_id
      ? DOCUMENT_STATUS_DRAFT
      : DOCUMENT_STATUS_PENDING_CREATION,
  };

  const [updateDocumentStatus, { loading: updateDocumentStatusLoading, data, error }] = useMutation(
    UPDATE_SHIPMENT_DOCUMENT_STATUS
  );

  useEffect(() => {
    if (updateDocumentStatusLoading) {
      return;
    }
    if (error) {
      if (onClose) onClose();
      message.error(error.message);
    }
    if (data?.update_shipment_document_status) {
      message.success('Updated successfully');
      if (onSuccess) onSuccess(sendEmail);
      if (onClose) onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDocumentStatusLoading, data, error, onSuccess]);

  return (
    <Modal
      open={visible}
      title="Reopen For Amendment"
      footer={[
        showSendEmail ? (
          <Checkbox key="1" checked={sendEmail} onChange={(e) => setSendEmail(e.target.checked)}>
            Send Email
          </Checkbox>
        ) : (
          <span />
        ),
        <Button
          key="cancel"
          disabled={updateDocumentStatusLoading}
          onClick={() => {
            if (onClose) onClose();
          }}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={updateDocumentStatusLoading}
          onClick={form.submit}
        >
          Unrelease
        </Button>,
      ]}
      onCancel={() => {
        if (onClose) onClose();
      }}
    >
      <Form
        form={form}
        onFinish={(values: any) => {
          const { remarks } = values;
          const nextstatus = prevStatusMap[documentType];
          const document_status_event_date = dayjs().unix();
          updateDocumentStatus({
            variables: {
              id: shipmentDocument?.id,
              document_status: nextstatus,
              document_status_event_date: document_status_event_date,
              ...(remarks
                ? { document_status_event_data: JSON.stringify({ remarks: remarks }) }
                : {}),
            },
          });
        }}
        layout="vertical"
      >
        <Form.Item name="remarks" label="Remarks For Reopen" rules={[{ required: true }]} required>
          <Input.TextArea style={{ width: '100%' }} rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
