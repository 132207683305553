import {
  Col,
  Form,
  FormInstance,
  Input,
  Radio,
  Row,
  TextArea,
  DatePicker,
} from '@shipmnts/pixel-hub';
import { AddressCompanySearch } from 'common';
import { RadioOptionProps } from 'operations/commonTypeDefs';
import { ShipmentValue } from 'operations/models/Shipment';
import ShipmentNumber from 'operations/modules/shipment/components/ShipmentNumber';
import {
  ROW_GUTTER,
  CONSIGNEE_TYPES,
  CONSIGNEE_TYPE_TO_ORDER,
  CONSIGNEE_TYPE_TO_ORDER_BANK,
  BL_TYPE_ARRAY,
} from 'operations/modules/shipment/constants';
import React, { useEffect, useState } from 'react';
import { GlobalSearch } from '@shipmnts/pixel-hub';

interface BLDetailsProps {
  shipment?: ShipmentValue;
  form: FormInstance;
}

const consignee_to_order_keys = [CONSIGNEE_TYPE_TO_ORDER.key, CONSIGNEE_TYPE_TO_ORDER_BANK.key];

const isPartyToOrder = (consigneeType: string) => consignee_to_order_keys.includes(consigneeType);

export default function BLDetails(props: BLDetailsProps) {
  const { form } = props;
  const freightType = Form.useWatch('freight_type', form);
  const routingDetails = Form.useWatch('routing_details', form);
  const [iataAwbPrefix, setIataAwbPrefix] = useState<any>();
  const [carrier, setCarrier] = useState<any>();
  useEffect(() => {
    (routingDetails?.routing_legs || []).forEach((rl: any) => {
      if (rl?.routing_type === 'main_carriage' && rl?.sequence_number === 2.1) {
        if (!!rl?.global_carrier?.iata_awb_prefix) {
          form.setFieldsValue({
            iata_awb_prefix: rl?.global_carrier?.iata_awb_prefix,
            carrier: rl?.global_carrier,
          });
          setIataAwbPrefix(rl?.global_carrier?.iata_awb_prefix);
        }
        setCarrier(rl?.global_carrier);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routingDetails]);

  return (
    <Row gutter={ROW_GUTTER}>
      <Col span={6}>
        {freightType === 'air' ? (
          <>
            <Form.Item noStyle name={'iata_awb_prefix'}></Form.Item>
            <Form.Item
              shouldUpdate
              name={['master', 'shipment_document', 'shipment_number']}
              label={'AWB #'}
            >
              <Input placeholder={'AWB #'} addonBefore={iataAwbPrefix} />
            </Form.Item>
          </>
        ) : (
          <ShipmentNumber form={form} carrier={carrier} label={'BL #'} documentType="master" />
        )}
      </Col>
      <Col span={6}>
        <Form.Item
          name={['shipment_document_master', 'shipment_date']}
          label={freightType === 'ocean' ? 'BL Date' : 'AWB Date'}
        >
          <DatePicker style={{ width: '100%' }} />
        </Form.Item>
      </Col>
      <Col span={6}>
        {freightType === 'ocean' ? (
          <Form.Item name="received_for_shipment_by_forwarder_date" label="RFS Date">
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
        ) : (
          <>
            <Form.Item noStyle name={'carrier'}></Form.Item>
            <Form.Item noStyle dependencies={['carrier']}>
              {({ getFieldValue }) => {
                return (
                  <Form.Item labelCol={{ span: 24 }} name="carrier_product" label="Carrier Product">
                    <GlobalSearch
                      doc_type="Global::CarrierProduct"
                      extraProps={{
                        carrier_code: getFieldValue('carrier')?.iata_carrier_code,
                      }}
                      // disabled={disabled}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </>
        )}
      </Col>
      <Col span={6}>
        {freightType === 'ocean' ? (
          <Form.Item name="shipped_on_board_date" label="SOB Date">
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
        ) : (
          <Form.Item
            label="Other Charge Terms"
            name={['shipment_document_master', 'charge_terms', 'other_charges_terms']}
          >
            <Radio.Group
              options={[
                { label: 'PP - Prepaid', value: 'prepaid' },
                { label: 'CC - Collect', value: 'collect' },
              ]}
            />
          </Form.Item>
        )}
      </Col>
      <Col span={6}>
        <Form.Item name={['document_parties', 'issuing_party']} label={'Issuing Party'}>
          <AddressCompanySearch
            companySearchProps={{
              searchProps: { is_customer: true },
              // disabled: false,
              showCreditStatusTag: true,
            }}
            // addressSearchProps={{ disabled: false }}
          />
        </Form.Item>
      </Col>
      {freightType === 'ocean' && (
        <Col span={6}>
          <Form.Item
            label="B/L Type"
            name={['shipment_document_master', 'bl_type']}
            style={{ width: '100%' }}
          >
            <Radio.Group>
              {BL_TYPE_ARRAY.map((option: RadioOptionProps, index: number) => (
                <Radio key={index} value={option.key} disabled={false}>
                  {option.name}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </Col>
      )}
      <Col span={6}>
        <Form.Item
          label={freightType === 'ocean' ? 'BL Consignee Type' : 'AWB Consignee Type'}
          // name={['master', 'shipment_document', 'bl_type']}
          name="consignee_party_name"
          style={{ width: '100%' }}
        >
          <Radio.Group>
            {CONSIGNEE_TYPES.map((option: RadioOptionProps, index: number) => (
              <Radio
                key={index}
                value={option.key}
                // disabled={disabledKeys.includes(option.key)}
              >
                {option.name}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item noStyle shouldUpdate={true}>
          {({ getFieldValue }) => {
            const consignee_party_name = getFieldValue('consignee_party_name');
            const shouldToOrderPartyRender = isPartyToOrder(consignee_party_name);

            if (!shouldToOrderPartyRender) return null;
            return (
              <Form.Item
                name={['document_parties', consignee_party_name, 'party_details']}
                label={freightType === 'ocean' ? 'Consignee On BL' : 'Consignee On AWB'}
                dependencies={['consignee_party_name']}
              >
                <TextArea rows={4} />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>

      <Col span={6}>
        <Form.Item
          label="Freight Charge Terms"
          name={['shipment_document_master', 'charge_terms', 'freight_terms']}
        >
          <Radio.Group
            options={[
              { label: 'PP - Prepaid', value: 'prepaid' },
              { label: 'CC - Collect', value: 'collect' },
            ]}
          />
        </Form.Item>
      </Col>
      {freightType === 'ocean' && (
        <Col span={6}>
          <Form.Item
            label="Other Charge Terms"
            name={['shipment_document_master', 'charge_terms', 'other_charges_terms']}
          >
            <Radio.Group
              options={[
                { label: 'PP - Prepaid', value: 'prepaid' },
                { label: 'CC - Collect', value: 'collect' },
              ]}
            />
          </Form.Item>
        </Col>
      )}
    </Row>
  );
}
