import { lazy } from 'react';
import { PerformAction } from '../models';
import DocumentPrintPreview from 'operations/modules/shipment/components/DetailLayout/Documents/DocumentPrintPreview';
import DocumentPdfGenerator from 'operations/modules/shipment/components/DetailLayout/Documents/DocumentPdfGenerator';
import { GenerateDocumentReportType } from 'operations/modules/reports/components/ShipmentDocumentReports/document';
import { SessionDataValue } from 'operations/models/SessionData';
import { EmailProps } from 'operations/components/ChangeEvent/EventEmailDrawer';

const DocumentDelete = lazy(
  () => import('operations/modules/shipment/components/DetailLayout/Documents/DocumentDelete')
);

interface GeneratedDocumentActionType {
  document: GenerateDocumentReportType;
  setEmailProps: (emailProps: EmailProps) => void;
  setVisible: (visible: boolean) => void;
  sessionData: SessionDataValue;
  extraProps?: any;
  navigate: any;
}

export const performDocumentPreviewAction = (
  payload: GeneratedDocumentActionType
): PerformAction => {
  const { document } = payload;
  return {
    actionParams: {
      document_id: document.id,
    },
    component: DocumentPrintPreview,
  };
};

export const performDocumentPdfAction = (payload: GeneratedDocumentActionType): PerformAction => {
  const { document } = payload;
  return {
    actionParams: {
      document_id: document.id,
      document_name: document.document_name,
    },
    component: DocumentPdfGenerator,
  };
};

export const performDocumentDeleteAction = (
  payload: GeneratedDocumentActionType
): PerformAction => {
  const { document, navigate } = payload;
  let docIds = document.docIds;
  let callback = () => undefined;

  // when called from document form in frontend base
  if (!docIds && document?.id) {
    docIds = [document.id];
    // FIX ME
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    callback = () => {
      // FIX ME
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      navigate && navigate(`~/view/shipment/${document.shipment_id}`);
    };
  }

  return {
    actionParams: {
      docIds: docIds || [],
      callback: callback,
    },
    component: DocumentDelete,
  };
};
