import React, { useEffect, useState, useCallback, forwardRef, useImperativeHandle } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Link } from 'wouter';
import { FREIGHT_TYPE_AIR, FREIGHT_TYPE_OCEAN } from '../../../constants';
import { STATUS_SHIPMENT_CREATED } from '../../../../reports/constants';
import _get from 'lodash/get';
import _sumBy from 'lodash/sumBy';
import { message } from '@shipmnts/pixel-hub';
import { ShipmentValue } from 'operations/models/Shipment';
import { SHIPMENT_REPORT_NEW } from './query';
import { Column } from 'operations/models/Report';
import { BaseTable, dayjs } from '@shipmnts/pixel-hub';
import { ActionRendererDetailReport } from 'operations';

const DEFAULT_REPORT_HEIGHT = 'calc(100vh - 168px)';

export const getPortDisplay = (record: ShipmentValue, portType: string) => {
  const code =
    _get(record, [portType, 'iata_code'], '') || _get(record, [portType, 'unlocode'], '');
  return `${_get(record, [portType, 'name'], '')}, ${code}`;
};

const getCountry = (record: ShipmentValue, portType: string) => {
  return _get(record, [portType, 'country', 'name'], '');
};

const renderLinkCell = (props: any) => {
  const id = props?.data?.[_get(props, 'idField')];
  const display = props?.value;
  if (id) {
    return (
      <span>
        <Link
          to={`${props?.redirectToBase}/${id}`}
          key={id}
          style={{ color: 'black', textDecoration: 'underline', cursor: 'pointer' }}
        >
          {display}
        </Link>
      </span>
    );
  }
  return <span>{display}</span>;
};

const WeightTotalDisplay = forwardRef(function WeightTotalDisplay(props: any, ref) {
  const { label, selectKey, api } = props;
  const [total, setTotal] = useState(0);

  useImperativeHandle(ref, () => {
    return {
      updateTotal: () => {
        const selectedNodes = (api?.getSelectedNodes() || []).filter((node: any) => !node.group);
        const total = _sumBy(selectedNodes, (node: any) => node.data[selectKey]);
        setTotal(total);
      },
    };
  });
  return (
    <div className="ag-status-name-value">
      <span className="component">{label}&nbsp;</span>
      <span className="ag-status-name-value-value">{total}</span>
    </div>
  );
});

const columnDefinitions: { [key: string]: Column } = {
  job_number: {
    headerName: 'Job #',
    colId: 'job_number',
    field: 'job_number',
    pinned: 'left',
    filter: 'agTextColumnFilter',
    cellRenderer: 'renderLinkCell',
    cellRendererParams: {
      idField: 'id',
      redirectToBase: '/view/shipment',
    },
    // checkboxSelection: true,
    // headerCheckboxSelection: true,
    // headerCheckboxSelectionFilteredOnly: true,
  },
  house_shipment_number: {
    headerName: 'HAWB #',
    field: 'house_shipment_number',
    filter: 'agTextColumnFilter',
  },
  port_of_loading_display: {
    headerName: 'Origin Port',
    field: 'port_of_loading_display',
    filter: 'agSetColumnFilter',
    enableRowGroup: true,
  },
  port_of_loading_country: {
    headerName: 'Origin Country',
    field: 'port_of_loading_country',
    filter: 'agSetColumnFilter',
    enableRowGroup: true,
  },
  port_of_discharge_display: {
    headerName: 'Dest. Port',
    field: 'port_of_discharge_display',
    filter: 'agSetColumnFilter',
    enableRowGroup: true,
  },
  port_of_discharge_country: {
    headerName: 'Dest. Country',
    field: 'port_of_discharge_country',
    filter: 'agSetColumnFilter',
    enableRowGroup: true,
  },
  pocr_display: {
    headerName: 'Place of Carrier Receipt',
    field: 'pocr_display',
    filter: 'agSetColumnFilter',
    enableRowGroup: true,
  },
  fpod_display: {
    headerName: 'FPOD',
    field: 'fpod_display',
    filter: 'agSetColumnFilter',
    enableRowGroup: true,
  },
  customer: {
    headerName: 'Customer',
    field: 'customer',
    filter: 'agSetColumnFilter',
    enableRowGroup: true,
  },
  origin_agent: {
    headerName: 'Origin Agent',
    field: 'origin_agent',
    filter: 'agSetColumnFilter',
    enableRowGroup: true,
  },
  destination_agent: {
    headerName: 'Dest. Agent',
    field: 'destination_agent',
    filter: 'agSetColumnFilter',
    enableRowGroup: true,
  },
  shipment_booking_number: {
    headerName: 'Order #',
    field: 'shipment_booking_number',
    filter: 'agTextColumnFilter',
  },
  chargeable_weight_float: {
    headerName: 'Ch. Wt.(Kgs)',
    field: 'chargeable_weight_float',
    aggFunc: 'sum',
  },
  gross_weight_float: {
    headerName: 'Gross Wt.(Kgs)',
    field: 'gross_weight_float',
    aggFunc: 'sum',
  },
  gross_volume_float: {
    headerName: 'Gross Vol.(CBM)',
    field: 'gross_volume_float',
    // filter: 'agNumberColumnFilter',
    aggFunc: 'sum',
  },
  commodity_description: {
    headerName: 'Commodity',
    field: 'commodity_description',
    filter: 'agTextColumnFilter',
  },
  shipper: {
    headerName: 'Shipper',
    field: 'shipper',
    filter: 'agSetColumnFilter',
    enableRowGroup: true,
  },
  consignee: {
    headerName: 'Consignee',
    field: 'consignee',
    filter: 'agSetColumnFilter',
    enableRowGroup: true,
  },
  shipment_created_at_display: {
    headerName: 'Requested On',
    field: 'shipment_created_at_display',
    filter: 'agDateColumnFilter',
  },
  involved_branch_name: {
    headerName: 'Branch',
    field: 'involved_branch_name',
    filter: 'agSetColumnFilter',
    enableRowGroup: true,
  },
  sales_agent: {
    headerName: 'Sales Person',
    field: 'sales_agent',
    filter: 'agSetColumnFilter',
    enableRowGroup: true,
  },
};

const airConsolColumns = [
  'job_number',
  'house_shipment_number',
  'port_of_loading_display',
  'port_of_loading_country',
  'port_of_discharge_display',
  'port_of_discharge_country',
  'customer',
  'origin_agent',
  'destination_agent',
  'shipment_booking_number',
  'chargeable_weight_float',
  'gross_weight_float',
  'commodity_description',
  'shipper',
  'consignee',
  'shipment_created_at_display',
  'involved_branch_name',
  'sales_agent',
];

const oceanConsolColumns = [
  'job_number',
  'customer',
  'shipper',
  'house_shipment_number',
  'pocr_display',
  'port_of_loading_display',
  'port_of_discharge_display',
  'fpod_display',
  'gross_weight_float',
  'gross_volume_float',
  'commodity_description',
  'involved_branch_name',
  'sales_agent',
];

const oceanCustomPropertiesMapping: { [key: string]: any } = {
  house_shipment_number: { headerName: 'HBL #' },
  port_of_loading_display: { headerName: 'Port of Loading' },
  port_of_dischare_display: { headerName: 'Port of Discharge' },
};

interface filterType {
  col: string;
  condition: {
    filter_type: string;
    type: string;
    value: string | undefined;
  };
}

interface ConsolPendingAgGridReportPropType {
  gridRef: any;
  tradeType: any;
  gridOptions: any;
  defaultFilters: filterType[];
  isOceanConsol: boolean;
  onRowsSelectionChanged: () => void;
}

const ConsolPendingAgGridReport = (props: ConsolPendingAgGridReportPropType) => {
  const { tradeType, gridRef, gridOptions, defaultFilters, isOceanConsol, onRowsSelectionChanged } =
    props;
  const [rowGroupColumns, setRowGroupColumns] = useState([]);
  const [columnDefs, setColumnDefs] = useState<{ [key: string]: any }>([]);
  const [shipmentReportNew, { data, loading, error }] = useLazyQuery(SHIPMENT_REPORT_NEW);
  const [rawData, setRawData] = useState();
  const updateData = useCallback(
    (data: any) => {
      const reportData = data.map((row: any) => {
        const rowData = { ...row };
        rowData['house_shipment_number'] = row?.shipment_document_house?.shipment_number;
        rowData['port_of_loading_display'] = getPortDisplay(row, 'port_of_loading');
        rowData['port_of_loading_country'] = getCountry(row, 'port_of_loading');
        rowData['port_of_discharge_display'] = getPortDisplay(row, 'port_of_discharge');
        rowData['port_of_discharge_country'] = getCountry(row, 'port_of_discharge');
        rowData['involved_branch_name'] = _get(row, ['involved_branch', 'name']);
        rowData['shipment_created_at_display'] =
          row.shipment_created_at && dayjs.unix(row.shipment_created_at).format('DD-MM-YYYY');
        return rowData;
      });
      setRawData(reportData);
      gridRef?.current?.api?.hideOverlay();
      setTimeout(() => {
        gridRef?.current?.columnApi?.autoSizeAllColumns();
      }, 200);
    },
    [gridRef]
  );

  const getData = useCallback(async () => {
    gridRef?.current?.api?.showLoadingOverlay();
    const filters = [
      {
        col: 'status',
        condition: {
          filter_type: 'text',
          type: 'equals',
          value: STATUS_SHIPMENT_CREATED,
        },
      },
      {
        col: 'shipment_type',
        condition: {
          filter_type: 'text',
          type: 'equals',
          value: 'independent_house',
        },
      },
      {
        col: 'freight_type',
        condition: {
          filter_type: 'text',
          type: 'equals',
          value: isOceanConsol ? FREIGHT_TYPE_OCEAN : FREIGHT_TYPE_AIR,
        },
      },
      {
        col: 'trade_type',
        condition: {
          filter_type: 'text',
          type: 'equals',
          value: tradeType,
        },
      },
      ...defaultFilters,
    ];
    const variables = { filters };
    await shipmentReportNew({ variables });
  }, [defaultFilters, gridRef, isOceanConsol, tradeType, shipmentReportNew]);

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
    if (data) {
      updateData(data.shipment_report_new.data);
    }
  }, [data, loading, error, updateData]);

  useEffect(() => {
    setColumnDefs(
      (isOceanConsol ? oceanConsolColumns : airConsolColumns).map((c: string): Column => {
        const col = columnDefinitions[c];
        if (isOceanConsol && c in oceanCustomPropertiesMapping) {
          Object.assign(col, oceanCustomPropertiesMapping[c]);
        }
        return col;
      })
    );
  }, [isOceanConsol]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    const colDefs = gridRef?.current?.api?.getColumnDefs() || [];
    if (!colDefs || colDefs.length === 0) return;
    const isGroupApplied = rowGroupColumns.length > 0;
    const job_number_column_index = colDefs.findIndex((c: any) => c.colId === 'job_number');
    colDefs[job_number_column_index]['hide'] = isGroupApplied;
    gridRef?.current?.api?.setColumnDefs(colDefs);
    setTimeout(() => {
      gridRef?.current?.columnApi?.autoSizeAllColumns();
    }, 200);
  }, [gridRef, rowGroupColumns]);

  const updateSelectedTotal = useCallback((statusBarComponent: any) => {
    let componentInstance = statusBarComponent;
    if (statusBarComponent.getFrameworkComponentInstance) {
      componentInstance = statusBarComponent.getFrameworkComponentInstance();
    }
    componentInstance.updateTotal();
  }, []);

  const onSelectionChanged = useCallback(() => {
    if (!gridRef?.current?.api) return;
    if (isOceanConsol) {
      onRowsSelectionChanged();
    } else {
      updateSelectedTotal(gridRef.current.api.getStatusPanel('chargeable_weight_total'));
      updateSelectedTotal(gridRef.current.api.getStatusPanel('gross_weight_total'));
    }
  }, [gridRef, isOceanConsol, onRowsSelectionChanged, updateSelectedTotal]);

  const updateFilterLabel = useCallback(() => {
    if (gridRef?.current?.api) {
      const filtersCount = Object.keys(gridRef.current.api.getFilterModel()).length;
      const buttons = document.getElementsByClassName('ag-side-button');
      if (buttons.length === 2) {
        const filterButton = buttons[1];
        filtersCount > 0
          ? filterButton.classList.add('primary-color')
          : filterButton.classList.remove('primary-color');
      }
    }
  }, [gridRef]);

  const onGridReady = useCallback(() => {
    updateFilterLabel();
  }, [updateFilterLabel]);

  const onFilterChanged = useCallback(() => {
    updateFilterLabel();
  }, [updateFilterLabel]);

  interface StatusPanelDef {
    statusPanel?: string;
    statusPanelFramework?: any;
    align?: string;
    key?: string;
    statusPanelParams?: any;
  }

  const statusBar: { statusPanels: StatusPanelDef[] } = isOceanConsol
    ? { statusPanels: [] }
    : {
        statusPanels: [
          { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
          {
            statusPanel: 'WeightTotalDisplay',
            align: 'right',
            key: 'chargeable_weight_total',
            statusPanelParams: {
              label: 'Selected Ch. Wt. ',
              selectKey: 'chargeable_weight_float',
            },
          },
          {
            statusPanel: 'WeightTotalDisplay',
            align: 'right',
            key: 'gross_weight_total',
            statusPanelParams: {
              label: 'Selected Gross Wt. ',
              selectKey: 'gross_weight_float',
            },
          },
        ],
      };

  return (
    <div
      style={{
        height: DEFAULT_REPORT_HEIGHT,
        width: '100%',
        padding: '0px 15px',
      }}
      className="ag-theme-material custom-filter-style"
    >
      {rawData && (
        <BaseTable
          reportName={'consol_pending'}
          rowSelection="multiple"
          reportConfig={{
            rowMultiSelectWithClick: true,
            groupDefaultExpanded: -1,
            groupSelectsChildren: true,
            groupSelectsFiltered: true,
            suppressAggFuncInHeader: true,
            components: {
              WeightTotalDisplay,
              renderLinkCell,
              ActionRendererDetailReport,
            },
            onSelectionChanged: onSelectionChanged,
            onFilterChanged: onFilterChanged,
            onColumnRowGroupChanged: (params: any) => {
              setRowGroupColumns(params?.columns?.map((col: any) => col.colId));
            },
            statusBar: statusBar,
            defaultColDef: {
              resizable: true,
              floatingFilter: true,
              sortable: true,
              filterParams: {
                buttons: ['clear'],
              },
            },
            autoGroupColumnDef: {
              pinned: 'left',
              field: 'job_number',
              cellRenderer: 'agGroupCellRenderer',
              cellRendererParams: {
                suppressCount: true,
                checkbox: false,
                innerRenderer: 'renderLinkCell',
                redirectToBase: `/shipment`,
                idField: 'id',
              },
              filter: 'agTextColumnFilter',
              filterParams: {
                showTooltips: true,
                buttons: ['reset'],
              },
              headerCheckboxSelection: true,
              headerCheckboxSelectionFilteredOnly: true,
              tooltipValueGetter: (params: any) => {
                return params.value;
              },
            },
            sideBar: {
              toolPanels: [
                {
                  id: 'columns',
                  labelDefault: 'Columns',
                  labelKey: 'columns',
                  iconKey: 'columns',
                  toolPanel: 'agColumnsToolPanel',
                  toolPanelParams: {
                    syncLayoutWithGrid: true,
                    suppressPivotMode: true,
                  },
                },
                {
                  id: 'filters',
                  labelDefault: 'Filters',
                  labelKey: 'filters',
                  iconKey: 'filter',
                  toolPanel: 'agFiltersToolPanel',
                  toolPanelParams: {
                    syncLayoutWithGrid: true,
                  },
                },
              ],
              defaultToolPanel: '',
              position: 'left',
            },
            ...gridOptions,
            defaultExcelExportParams: {
              fileName: 'air-consol-pending',
            },
            rowGroupPanelShow: 'always',
          }}
          onGridReady={onGridReady}
          columns={Object.values(columnDefs)}
          gridRef={gridRef}
          rowData={rawData}
          checkbox_always_visible={false}
          showCheckBoxOnHeader={true}
        />
      )}
    </div>
  );
};

ConsolPendingAgGridReport.defaultProps = {
  gridOptions: {},
  defaultFilters: [],
  isOceanConsol: false,
};

export { ConsolPendingAgGridReport };
