import { refClassTransformer } from '@shipmnts/pixel-hub';

export const transformReferences = (references: any[]) => {
  return references.map((reference: any) => {
    const linkedRec = {
      ...reference.data,
      value: reference.reference_id,
      pretty_display: reference.data?.title,
      doc_ref_id: reference.id,
    };
    return {
      link_type: refClassTransformer[reference.reference_type],
      linked_record: linkedRec,
    };
  });
};
