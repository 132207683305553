import React, { useState, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Descriptions,
  Card,
  Input,
  message,
  Drawer,
  Form,
  DrawerFooter,
  DatePicker,
  dayjsGenerateConfig,
} from '@shipmnts/pixel-hub';
import { disableFutureDate } from '@shipmnts/pixel-hub';
import { RoadTransportOrderValue } from 'operations/models/RoadTransportOrder';
import { useSession } from 'common';
import { useMutation } from '@apollo/client';
import { UPDATE_CARGO_PICKED_UP } from 'operations/modules/booking/graphql/roadTransportOrder';
import { errorMessageHandlerGraphQL } from 'common';

import { WEIGHT_UNIT_KGS } from 'operations/baseConstants';

interface AddPickupDetailsProps {
  roadTransportOrder?: RoadTransportOrderValue;
  bookingRequestId: string;
  onSuccess?: (value: RoadTransportOrderValue, sendEmail: boolean) => void;
  onClose: () => void;
}

const AddPickupDetails = observer(function AddPickupDetails(
  props: AddPickupDetailsProps
): JSX.Element {
  const [form] = Form.useForm();
  const { roadTransportOrder, onSuccess, onClose } = props;
  const sessionData = useSession();
  const default_company_id = sessionData.company_account.default_company.id;
  const [sendEmail, setSendEmail] = useState<boolean>(true);

  const [updateDetails, { data, loading, error }] = useMutation(UPDATE_CARGO_PICKED_UP);

  useEffect(() => {
    if (data && data.update_cargo_picked_up) {
      message.success('Cargo Pickup Details updated successfully');
      form.resetFields();
      if (onSuccess) onSuccess(data.update_cargo_picked_up.road_transport_order, sendEmail);
      if (!sendEmail) onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const closeDrawer = useCallback(() => {
    form.resetFields();
    onClose();
  }, [form, onClose]);

  return (
    <Drawer
      title={'Add Pickup Details'}
      width={'60%'}
      visible
      onClose={onClose}
      footer={
        <DrawerFooter
          saveText="Submit"
          closeText="Cancel"
          showSendEmail
          sendEmail={sendEmail}
          setSendEmail={setSendEmail}
          sendEmailText="Send Pickup Confirmation"
          loading={loading}
          onClose={closeDrawer}
          onSave={form.submit}
        />
      }
    >
      {error && errorMessageHandlerGraphQL(error)}
      {roadTransportOrder && (
        <Card style={{ marginBottom: '10px' }} className="no-padding-description custom-card">
          <Descriptions column={4} colon={false} size="small" layout="vertical">
            <Descriptions.Item key="transporter" label="Transporter">
              {roadTransportOrder.getVendorCompany(default_company_id)?.registered_name}
            </Descriptions.Item>
            <Descriptions.Item
              style={{ paddingRight: '8px' }}
              key="factory_location"
              label="Factory Location"
            >
              {roadTransportOrder.factory_location?.print_address}
            </Descriptions.Item>
            <Descriptions.Item key="gross_weight" label="Gross Weight">
              {roadTransportOrder.cargos.reduce(
                (sum, cargo) => (sum += cargo.gross_weight || 0),
                0
              )}{' '}
              {WEIGHT_UNIT_KGS}
            </Descriptions.Item>
          </Descriptions>
        </Card>
      )}
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => {
          const payload = {
            ...values,
            actual_time_of_departure: values.actual_time_of_departure.unix(),
            rto_id: roadTransportOrder?.id,
          };
          updateDetails({ variables: payload });
        }}
        initialValues={{
          actual_time_of_departure: dayjsGenerateConfig.getNow(),
        }}
      >
        <Form.Item
          required
          rules={[{ required: true }]}
          name="actual_time_of_departure"
          label="Pickup Date"
        >
          <DatePicker showTime style={{ width: '100%' }} disabledDate={disableFutureDate} />
        </Form.Item>
        <Form.Item name={'vehicle_number'} label="Vehicle No.">
          <Input placeholder="Vehicle number" />
        </Form.Item>
        <Form.Item name={'driver_contact'} label="Driver Contact">
          <Input placeholder="Driver contact" />
        </Form.Item>
      </Form>
    </Drawer>
  );
});

export default AddPickupDetails;
