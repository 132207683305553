/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree';

const EmailTemplate = types.model({
  id: types.maybe(types.identifier),
  action_name: types.maybe(types.string),
  template_name: types.maybe(types.string),
  subject: types.maybe(types.string),
  body: types.maybe(types.string),
  resource_type: types.maybe(types.string),
  resource_as_list: types.maybe(types.boolean),
});

export interface CompanyEmailPreferenceValue extends Instance<typeof EmailTemplate> {}
export interface CompanyEmailPreferenceIn extends SnapshotIn<typeof EmailTemplate> {}
export interface CompanyEmailPreferenceOut extends SnapshotOut<typeof EmailTemplate> {}

export default EmailTemplate;
