import React from 'react';
import { collapsibleCard } from '../../../shipment/components/DetailLayout/Common/common';
import { CustomIcon } from '@shipmnts/pixel-hub';
import { ProductOrderValue } from 'operations/models/ProductOrder';

interface OrderDetailsProps {
  product_order: ProductOrderValue;
}

const OrderDetails = (props: OrderDetailsProps) => {
  const { product_order } = props;

  const orderDetails: { [key: string]: string | React.ReactNode } = {
    Incoterms: product_order.incoterm ? <b>{product_order.incoterm}</b> : '-',
    'Payment Terms': product_order.payment_term ? <b>{product_order.payment_term}</b> : '-',
    'Total Order Amount': product_order.total_order_amount ? (
      <b>{product_order.total_order_amount + ' ' + product_order.order_currency}</b>
    ) : (
      '-'
    ),
    "Seller's SO#": product_order.sales_order_number ? product_order.sales_order_number : '-',
    "Buyer's PO#": product_order.purchase_order_number ? product_order.purchase_order_number : '-',
    'No of Items': '-',
    'Gross Weight': '-',
  };

  return (
    <>
      {collapsibleCard({
        icon: <CustomIcon icon="Info" />,
        header: 'Order Details',
        data: orderDetails,
      })}
    </>
  );
};

export default OrderDetails;
