import React from 'react';
import { useShipmentDetail } from '../../ShipmentDetailLayout';
import { get as _get } from 'lodash';
import { collapsibleCard } from '../../Common/common';
import { CompassOutlined } from '@shipmnts/pixel-hub';
import {
  MOVEMENT_MODE_AIR_EXPORT,
  MOVEMENT_MODE_AIR_IMPORT,
  MOVEMENT_MODE_DOOR_TO_DOOR,
  MOVEMENT_MODE_OCEAN_EXPORT,
  MOVEMENT_MODE_OCEAN_IMPORT,
} from 'operations/modules/shipment/constants';
import { utcStringToFormatedString } from '@shipmnts/pixel-hub';

export default function LinkShipmentDetails() {
  const { shipment } = useShipmentDetail();
  const linkShipmentDetails: { [key: string]: string | null | undefined } = {};
  const linked_shipment_json = _get(shipment, 'link_shipment_json');
  const movementMode = shipment?.movement_mode || '';
  const isAirMovementMode = [MOVEMENT_MODE_AIR_EXPORT, MOVEMENT_MODE_AIR_IMPORT].includes(
    movementMode || ''
  );
  const isOceanMovementMode = [MOVEMENT_MODE_OCEAN_IMPORT, MOVEMENT_MODE_OCEAN_EXPORT].includes(
    movementMode || ''
  );

  const isDoorMovementMode = MOVEMENT_MODE_DOOR_TO_DOOR === movementMode;

  const carrierName = linked_shipment_json?.carrier?.name;
  linkShipmentDetails['Carrier'] = carrierName;
  if (
    [MOVEMENT_MODE_OCEAN_IMPORT, MOVEMENT_MODE_OCEAN_EXPORT, MOVEMENT_MODE_DOOR_TO_DOOR].includes(
      movementMode
    )
  )
    linkShipmentDetails['Carrier Ref #'] = linked_shipment_json?.shipping_line_booking_number || '';

  if (isOceanMovementMode || isDoorMovementMode)
    linkShipmentDetails['Vessel'] = linked_shipment_json?.ocean_vessel?.name || '';

  linkShipmentDetails[isAirMovementMode ? 'Flight Number' : 'Voyage #'] =
    linked_shipment_json?.voyage_number;

  if (isOceanMovementMode)
    linkShipmentDetails['Carrier Validity Date'] = utcStringToFormatedString(
      linked_shipment_json?.booking_validity_date
    );

  if ([MOVEMENT_MODE_OCEAN_IMPORT].includes(movementMode)) {
    linkShipmentDetails['LFD Port'] = utcStringToFormatedString(linked_shipment_json?.lfd_at_pod);
    linkShipmentDetails['LFD Empty Return'] = utcStringToFormatedString(
      linked_shipment_json?.lfd_at_empty_return
    );
  }
  if (
    [MOVEMENT_MODE_OCEAN_IMPORT, MOVEMENT_MODE_AIR_IMPORT, MOVEMENT_MODE_DOOR_TO_DOOR].includes(
      movementMode
    )
  )
    linkShipmentDetails['ETA at Port'] = utcStringToFormatedString(
      linked_shipment_json?.estimated_time_of_arrival
    );

  if (
    [MOVEMENT_MODE_OCEAN_EXPORT, MOVEMENT_MODE_AIR_EXPORT, MOVEMENT_MODE_DOOR_TO_DOOR].includes(
      movementMode
    )
  )
    linkShipmentDetails['ETD at Port'] = utcStringToFormatedString(
      linked_shipment_json?.estimated_time_of_departure
    );

  if ([MOVEMENT_MODE_OCEAN_EXPORT].includes(movementMode))
    linkShipmentDetails['Gate Close Cutof'] = utcStringToFormatedString(
      linked_shipment_json?.cy_cutoff_date
    );

  const updatedLinkShipmentDetails: { [key: string]: string } = {};
  Object.keys(linkShipmentDetails).forEach((label: string) => {
    let value = linkShipmentDetails[label];
    if (!value) value = '-';
    updatedLinkShipmentDetails[label] = value;
  });

  return (
    <div>
      {collapsibleCard({
        icon: <CompassOutlined />,
        header: 'Link Shipment Details',
        data: updatedLinkShipmentDetails,
      })}
    </div>
  );
}
