import { Form, Input, Switch } from '@shipmnts/pixel-hub';
import React from 'react';
const { TextArea } = Input;

export default function NotifyCollaborators({
  notify,
  setNotify,
}: {
  notify: boolean;
  setNotify: (st: boolean) => void;
}) {
  return (
    <>
      Notify People <Switch size="small" checked={notify} onChange={(e) => setNotify(e)} />
      <br />
      {notify && (
        <Form.Item name="email_body">
          <TextArea rows={4} />
        </Form.Item>
      )}
    </>
  );
}
