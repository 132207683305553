import React from 'react';
import { Descriptions, Form, EditModeCard } from '@shipmnts/pixel-hub';
import CreditControlDetailsForm from '../CreditControlDetailsForm';
import { CompanyValue, CompanyAccountsData } from 'network/models/Company';
import SectionEditFormWrapper from './SectionEditFormWrapper';
import { pick as _pick } from 'lodash';
import { ERPNextConfigData } from 'network/utils/ErpNextConfigDataWrapper';
import { CompanyViewSectionProps } from '../CompanyView';
import { PERMISSION_CREDIT_CONTROL_WRITE } from 'network/permissions';

interface CreditControlDetailsProps extends CompanyViewSectionProps {
  erpnextConfigData: ERPNextConfigData;
}

export const getFormInitialValues = (
  company: CompanyValue,
  companyAccountsData?: CompanyAccountsData
) => {
  const formPayload = {
    ..._pick(company, ['id', 'receivable_credit_control_type']),
    ..._pick(companyAccountsData, [
      'receivable_credit_limit',
      'receivable_payment_terms',
      'credit_overdue_limit',
    ]),
    status: company.status,
  };
  return formPayload;
};

const CreditControlDetails = React.memo(function CreditControlDetails(
  props: CreditControlDetailsProps
): JSX.Element {
  const [form] = Form.useForm();
  const { company, companyAccountsData, erpnextConfigData, onCompanyUpdate } = props;
  const initialValues = getFormInitialValues(company, companyAccountsData);

  return (
    <EditModeCard
      onEdit={() => form.setFieldsValue(initialValues)}
      editDisabled={company.is_disabled}
      onSave={form.submit}
      cardKey="credit_control_details"
      title="Credit Control Details"
      editPermission={{ name: PERMISSION_CREDIT_CONTROL_WRITE, docType: 'Network::Company' }}
    >
      {({
        editMode,
        setEditMode,
        setLoading,
      }: {
        editMode: any;
        setEditMode: any;
        setLoading: any;
      }) => {
        if (editMode)
          return (
            <SectionEditFormWrapper
              setEditMode={setEditMode}
              form={form}
              setLoading={setLoading}
              onSuccess={onCompanyUpdate}
              initialValues={initialValues}
            >
              <CreditControlDetailsForm form={form} erpnextConfigData={erpnextConfigData} />
            </SectionEditFormWrapper>
          );
        return (
          <>
            <Descriptions
              className="no-padding-description"
              column={{ xs: 2, sm: 4, md: 4, lg: 4 }}
              size="small"
              layout="vertical"
              colon={false}
            >
              <Descriptions.Item label="Credit Control Type">
                {companyAccountsData?.receivable_credit_control_type || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Credit Limit">
                {companyAccountsData?.receivable_credit_limit || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Credit Overdue Limit">
                {companyAccountsData?.credit_overdue_limit || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Default Payment Terms">
                {companyAccountsData?.receivable_payment_terms
                  ? `${companyAccountsData?.receivable_payment_terms} credit days`
                  : '-'}
              </Descriptions.Item>
            </Descriptions>
          </>
        );
      }}
    </EditModeCard>
  );
});

export default CreditControlDetails;
