import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Table, Space, message, Modal } from '@shipmnts/pixel-hub';
import { RoadTransportOrderValue } from 'operations/models/RoadTransportOrder';
import { useSession } from 'common';
import { OceanTransportOrderValue } from 'operations/models/OceanTransportOrder';
import { useMutation, useApolloClient } from '@apollo/client';
import { DELETE_ROAD_TRANSPORT_ORDER } from 'operations/modules/booking/graphql/roadTransportOrder';
import { GET_BOOKING_REQUEST } from 'operations/modules/booking/graphql/bookingRequest';
import { BookingRequestValue } from 'operations/models/BookingRequest';
import { startCase as _startCase, upperCase as _upperCase } from 'lodash';
import {
  ShipmentContainerValue,
  showContainerAllocation,
} from 'operations/models/ShipmentContainer';
import { ActionRenderer } from 'operations/modules/actionHelper/ActionRenderer';
import {
  createNewPickupOrder,
  getRtoAction,
} from 'operations/modules/actionHelper/BookingRequestActions/bookingRequestActionHelper';
import { useEmailDrawerContext } from 'common';

interface RoadTransportOrdersViewProps {
  bookingRequest: BookingRequestValue;
  roadTransportOrders: RoadTransportOrderValue[];
  oceanTransportOrders: OceanTransportOrderValue[];
  onUpdate: (value: RoadTransportOrderValue) => void;
  onDelete: (rto_id: string) => void;
  onAdd?: (value: RoadTransportOrderValue) => void;
  areAllContainersPickedUp: boolean;
  isEditable: boolean;
  isCreateDisabled?: boolean;
  refetchData: () => void;
}

interface RoadTransportOrderColumnProps {
  record: RoadTransportOrderValue;
  default_company_id: string;
}

export const DeleteRoadTransportOrder = observer(function DeleteRoadTransportOrder(props: {
  rto: RoadTransportOrderValue;
  bookingRequestId: string;
  onDelete?: (rto_id: string) => void;
  onSuccess?: () => void;
}): JSX.Element {
  const { rto, bookingRequestId, onDelete, onSuccess } = props;
  const [deleteId, setDeleteId] = useState<string | undefined>(undefined);
  const client = useApolloClient();
  const [modal, contextHolder] = Modal.useModal();
  const [deleteRoadTransportOrder, { data, loading, error }] = useMutation(
    DELETE_ROAD_TRANSPORT_ORDER,
    {
      update(cache, { data: { delete_road_transport_order } }) {
        if (delete_road_transport_order) {
          const booking_request = cache.readQuery<{ booking_request: BookingRequestValue }>({
            query: GET_BOOKING_REQUEST,
            variables: { id: bookingRequestId },
          })?.booking_request;
          if (booking_request) {
            client.writeQuery({
              query: GET_BOOKING_REQUEST,
              data: {
                booking_request: {
                  ...booking_request,
                  road_transport_orders: booking_request.road_transport_orders.filter(
                    (roadTransportOrder: RoadTransportOrderValue) =>
                      roadTransportOrder.id !== rto.id
                  ),
                  ocean_transport_orders: booking_request.ocean_transport_orders.map((oto) => {
                    return {
                      ...oto,
                      shipment_containers: oto.shipment_containers.map(
                        (sc: ShipmentContainerValue) => ({
                          ...sc,
                          road_transport_order_ids: (sc.road_transport_order_ids || []).filter(
                            (rto_id) => rto_id !== deleteId
                          ),
                        })
                      ),
                    };
                  }),
                },
              },
            });
          }
        }
      },
    }
  );

  useEffect(() => {
    if (data && data.delete_road_transport_order && deleteId) {
      message.success('Deleted successfully');
      if (onDelete) onDelete(deleteId);
      if (onSuccess) onSuccess();
      setDeleteId(undefined);
    }
    if (error && error.message) {
      message.error(error.message);
    }
  }, [data, error, deleteId, onDelete, onSuccess]);

  const config = {
    title: `Are you sure to delete?`,
    content: 'Once you click on Confirm, It will Delete the Pickup Order',
    okText: 'Confirm delete',
    cancelText: 'Cancel',
    confirmLoading: loading,
    onOk: async () => {
      setDeleteId(rto.id);
      deleteRoadTransportOrder({ variables: { id: rto.id } });
    },
  };

  useEffect(() => {
    modal.confirm(config);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  return <>{contextHolder}</>;
});

const RenderVendorName = observer(function RenderVendorName(
  props: RoadTransportOrderColumnProps
): JSX.Element {
  const { record, default_company_id } = props;
  return <span>{record.getVendorCompany(default_company_id)?.registered_name}</span>;
});

const RenderAllocatedContainers = observer(function RenderAllocatedContainers(props: {
  record: RoadTransportOrderValue;
  bookingRequestId: string;
}): JSX.Element {
  const { record, bookingRequestId } = props;
  const allocated = record.parentShipmentContainersCountByTypeAndSetting(bookingRequestId);
  return (
    <span>
      {Object.keys(allocated)
        .map((container_type) => showContainerAllocation(allocated[container_type]))
        .join(', ')}
    </span>
  );
});

const RoadTransportOrdersView = observer(function RoadTransportOrdersView(
  props: RoadTransportOrdersViewProps
): JSX.Element {
  const { bookingRequest, roadTransportOrders, isEditable, isCreateDisabled, refetchData } = props;
  const sessionData = useSession();
  const { setVisible, setEmailProps } = useEmailDrawerContext();
  const user = useSession();
  const default_company_id = sessionData.company_account.default_company.id;
  const getColumns = useCallback(() => {
    return [
      {
        title: 'Pickup Order #',
        dataIndex: 'id',
      },
      {
        title: 'Transporter',
        dataIndex: 'transporter',
        render: function renderTransporter(
          text: string,
          record: RoadTransportOrderValue,
          index: number
        ) {
          return <RenderVendorName record={record} default_company_id={default_company_id} />;
        },
      },
      {
        title: 'Managed By',
        dataIndex: 'managed_by',
        render: function renderManagedBy(
          text: string,
          record: RoadTransportOrderValue,
          index: number
        ) {
          return (
            <span>{record?.getActualManagedByCompany(default_company_id)?.registered_name}</span>
          );
        },
      },
      {
        title: 'Load Type',
        dataIndex: 'load_type',
        render: function renderLoadType(
          text: string,
          record: RoadTransportOrderValue,
          index: number
        ) {
          return <span>{_upperCase(record?.load_type)}</span>;
        },
      },
      {
        title: 'Allocated',
        dataIndex: 'allocated',
        render: function renderAllocated(
          text: string,
          record: RoadTransportOrderValue,
          index: number
        ) {
          return <RenderAllocatedContainers record={record} bookingRequestId={bookingRequest.id} />;
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: function renderStatus(
          text: string,
          record: RoadTransportOrderValue,
          index: number
        ) {
          return <span>{_startCase(record?.status)}</span>;
        },
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        render: function renderActions(
          text: string,
          record: RoadTransportOrderValue,
          index: number
        ) {
          return (
            <Space>
              <ActionRenderer
                id={bookingRequest.id}
                data={bookingRequest}
                doc_type_id={'Shipment::BookingRequest'}
                selectedActions={getRtoAction(
                  bookingRequest,
                  record,
                  setEmailProps,
                  setVisible,
                  user
                )}
                refetchData={refetchData}
              />
            </Space>
          );
        },
      },
    ];
  }, [default_company_id, bookingRequest, setEmailProps, setVisible, user, refetchData]);
  return (
    <div>
      <Table
        dataSource={roadTransportOrders.slice()}
        columns={getColumns()}
        pagination={false}
        rowKey={(record) => record.id || ''}
        size="small"
      />
      {isEditable && !isCreateDisabled && (
        <div style={{ marginTop: '10px' }}>
          <ActionRenderer
            id={bookingRequest.id}
            data={bookingRequest}
            doc_type_id={'Shipment::BookingRequest'}
            refetchData={refetchData}
            selectedActions={[createNewPickupOrder(bookingRequest)]}
          />
        </div>
      )}
    </div>
  );
});

export default RoadTransportOrdersView;
