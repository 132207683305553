/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree';

const Subscription = types.model({
  id: types.identifier,
  plan_type: types.maybe(types.maybeNull(types.string)),
  subscription_data: types.maybe(types.maybeNull(types.string)),
  app: types.maybe(
    types.maybeNull(
      types.model({
        label: types.maybe(types.maybeNull(types.string)),
        name: types.maybe(types.maybeNull(types.string)),
        description: types.maybe(types.maybeNull(types.string)),
        app_type: types.maybe(types.maybeNull(types.string)),
      })
    )
  ),
});

export interface SubscriptionValue extends Instance<typeof Subscription> {}
export interface SubscriptionIn extends SnapshotIn<typeof Subscription> {}
export interface SubscriptionOut extends SnapshotOut<typeof Subscription> {}

export default Subscription;
