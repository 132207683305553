import { useMutation } from '@apollo/client';
import { message, Modal } from '@shipmnts/pixel-hub';
import { BookingRequestValue } from 'operations/models/BookingRequest';
import { OceanTransportOrderValue } from 'operations/models/OceanTransportOrder';
import React, { useEffect } from 'react';
import { DEALLOCATE_OCEAN_TRANSPORT_ORDER } from '../../graphql/bookingRequest';

interface DeallocateOceanTranportOrderProps {
  oceanTransportOrder: OceanTransportOrderValue;
  bookingRequest: BookingRequestValue;
  onSuccess?: () => void;
  onClose?: () => void;
}
export default function DeallocateOceanTranportOrder(props: DeallocateOceanTranportOrderProps) {
  const { oceanTransportOrder, bookingRequest, onSuccess, onClose } = props;
  const [modal, contextHolder] = Modal.useModal();
  const [deallocateOTO, { data, error }] = useMutation(DEALLOCATE_OCEAN_TRANSPORT_ORDER);

  useEffect(() => {
    if (error) {
      message.error(error.message);
    } else if (data?.deallocate_ocean_transport_order) {
      message.success('Booking Order Deallocated successfully');
      if (onSuccess) onSuccess();
    }
  }, [data, error, onSuccess]);

  const config = {
    title: `Are you sure to deallocate this booking?`,
    content: 'Once you click on Confirm, It will Deallocate Order',
    okText: 'Confirm Deallocate',
    cancelText: 'Cancel',
    onCancel: () => {
      if (onClose) onClose();
    },
    onOk: async () => {
      deallocateOTO({
        variables: {
          ocean_transport_order_id: oceanTransportOrder.id,
          booking_request_id: bookingRequest.id,
        },
      });
    },
  };

  useEffect(() => {
    Modal.confirm(config);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);
  return <>{contextHolder}</>;
}
