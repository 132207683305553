import { GridOptions } from '@ag-grid-community/core';
import { gql, useLazyQuery } from '@apollo/client';
import {
  Button,
  CutOffRender,
  DateTypeRenderer,
  Drawer,
  DrawerFooter,
  GlobalViewLayout,
  LinkRender,
  LocationRenderer,
  message,
  RecordLoaderRender,
} from '@shipmnts/pixel-hub';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { voyageScheduleObjectType } from '../../graphql/voyageSchedule';
import VoyageScheduleTypeRenderer from './VoyageScheduleTypeRenderer';
import VoyageSchedule, {
  VoyageScheduleInitialValue,
  VoyageScheduleValue,
} from 'operations/models/VoyageSchedule';
import VoyageScheduleAddPortCallDrawer from './VoyageScheduleAddPortCallDrawer';
import VoyageScheduleFormDrawer from './VoyageScheduleFormDrawer';

export interface SearchPropsType {
  location?: any;
  carrier?: any;
  vessel?: any;
}

interface VoyageScheduleSelectionDrawerProps {
  onClose: () => void;
  open: boolean;
  onVoyageChange?: (
    value: VoyageScheduleValue | undefined | null,
    isPartialMatch?: boolean
  ) => void;
  searchProps?: SearchPropsType;
  initialVoyageScheduleFormData: VoyageScheduleInitialValue;
}

const components = {
  voyage_schedule_type_renderer: VoyageScheduleTypeRenderer,
  record_loader_render: RecordLoaderRender,
  location_render: LocationRenderer,
  date_type_renderer: DateTypeRenderer,
  cut_off_renderer: CutOffRender,
  link_render: LinkRender,
};

const getGlobalView = (searchProps: SearchPropsType | undefined) => {
  const globalView: any = {
    id: '1',
    name: 'Voyage Schedule',
    color: '#eeeeee',
    doc_type_id: 'Network::VoyageSchedule',
    definition: {
      sort: [
        {
          field_id: 'routing_legs_estimated_time_of_departure',
          direction: 'desc',
        },
      ],
      fields: [
        {
          id: 'voyage_schedules_id',
          label: 'ID',
          width: 90,
          section: 'Vessel',
          sortable: true,
          field_type: 'String',
          filterable: true,
          cell_renderer: {
            args: {
              id_field: 'id',
              doc_type_id: 'Network::VoyageSchedule',
            },
            function: 'link_render',
          },
          filter_options: '{}',
          groupable: false,
          pinned: 'left',
        },
        {
          id: 'routing_nodes_gate_close_date',
          label: 'Type',
          field_type: 'MultiSearch',
          width: 50,
          use_record_loader: true,
          record_loader_config: { doc_type: 'Global::Carrier', attribute: 'name', multi: true },
          cell_renderer: {
            args: {
              selectedCarrierId: `${searchProps?.carrier?.id}`,
              selectedLocationId: `${searchProps?.location?.id}`,
            },
            function: 'voyage_schedule_type_renderer',
          },
          section: 'Vessel',
          sortable: false,
          filterable: false,
          groupable: false,
          searchable: false,
          filter_options: JSON.stringify({ doc_type: 'Global::Carrier' }),
        },
        {
          id: 'voyage_schedules_vessel_id',
          label: 'Vessel',
          field_type: 'Search',
          use_record_loader: true,
          record_loader_config: { doc_type: 'Global::OceanVessel', attribute: 'name' },
          cell_renderer: { args: {}, function: 'record_loader_render' },
          section: 'Vessel',
          sortable: false,
          filterable: true,
          groupable: true,
          searchable: true,
          filter_options: JSON.stringify({ doc_type: 'Global::OceanVessel' }),
        },
        {
          id: 'voyage_schedules_voyage_number',
          label: 'Voyage',
          width: 160,
          section: 'Vessel',
          field_type: 'String',
          sortable: false,
          filterable: true,
          groupable: false,
          searchable: true,
          filter_options: '{}',
        },
        {
          id: 'voyage_schedules_carrier_ids',
          label: 'Carriers',
          field_type: 'MultiSearch',
          use_record_loader: true,
          record_loader_config: { doc_type: 'Global::Carrier', attribute: 'name', multi: true },
          cell_renderer: { args: {}, function: 'record_loader_render' },
          section: 'Vessel',
          sortable: false,
          filterable: true,
          groupable: false,
          searchable: true,
          filter_options: JSON.stringify({ doc_type: 'Global::Carrier' }),
        },
        {
          doc_type_id: 'Shipment::RoutingNode',
          id: 'routing_nodes_location_id',
          label: 'POL',
          field_type: 'Search',
          use_record_loader: true,
          cell_renderer: { args: {}, function: 'location_render' },
          record_loader_config: { doc_type: 'Global::Location', attribute: 'view_display' },
          section: 'Routing',
          sortable: false,
          filterable: true,
          groupable: true,
          searchable: true,
          filter_options: JSON.stringify({ doc_type: 'Global::Location' }),
        },
        {
          doc_type_id: 'Shipment::RoutingLeg',
          id: 'routing_nodes_gate_open_date',
          label: 'Get Open Cutoff',
          field_type: 'Date',
          section: 'Routing',
          cell_renderer: { args: {}, function: 'cut_off_renderer' },
          sortable: false,
          filterable: false,
          groupable: false,
          searchable: true,
          filter_options: '{}',
        },
        {
          doc_type_id: 'Shipment::RoutingLeg',
          id: 'routing_nodes_gate_close_date',
          label: 'Get Close cutoff',
          field_type: 'Date',
          section: 'Routing',
          cell_renderer: { args: {}, function: 'cut_off_renderer' },
          sortable: false,
          filterable: false,
          groupable: false,
          searchable: true,
          filter_options: '{}',
        },
        {
          doc_type_id: 'Shipment::RoutingLeg',
          id: 'routing_legs_estimated_time_of_departure',
          label: 'ETD',
          field_type: 'Date',
          cell_renderer: { args: {}, function: 'cut_off_renderer' },
          section: 'Routing',
          sortable: false,
          filterable: true,
          groupable: false,
          searchable: true,
          filter_options: '{}',
          pinned: 'right',
        },
      ],
      filters: [
        {
          field_id: 'routing_legs_estimated_time_of_departure',
          condition: { type: 'is', value: 'greaterThanOrEqualToday' },
          isQuickFilter: true,
        },
      ],
    },
    standard_report: true,
    is_disabled: false,
    global: true,
  };

  if (searchProps?.carrier?.id) {
    globalView.definition.filters.push({
      field_id: 'voyage_schedules_carrier_ids',
      condition: {
        type: 'anyOf',
        values: [searchProps.carrier],
      },
      isQuickFilter: true,
    });
  }

  if (searchProps?.vessel) {
    globalView.definition.filters.push({
      field_id: 'voyage_schedules_vessel_id',
      condition: {
        type: 'in',
        values: [searchProps.vessel],
      },
      isQuickFilter: true,
    });
  }

  return globalView;
};

const GET_VOYAGE_SCHEDULE = gql`
  query voyage_schedule($id: ID!) {
    voyage_schedule(id: $id) {
      ...voyageScheduleObjectType
    }
  }
  ${voyageScheduleObjectType}
`;
const VoyageScheduleSelectionDrawer = ({
  open,
  onClose,
  onVoyageChange,
  searchProps,
  initialVoyageScheduleFormData,
}: VoyageScheduleSelectionDrawerProps) => {
  const [getVoyageSchedule, { loading }] = useLazyQuery(GET_VOYAGE_SCHEDULE);
  const [currentPartialVoyageSchedule, setCurrentPartialVoyageSchedule] = useState<
    undefined | VoyageScheduleValue
  >();
  const [visibleDrawer, setVisibleDrawer] = useState<boolean>(false);

  const gridRef = useRef<GridOptions>();

  const globalView = useMemo(() => getGlobalView(searchProps), [searchProps]);

  const onSubmit = async () => {
    const selected = gridRef?.current?.api?.getSelectedNodes()?.[0];
    if (!selected) {
      message.error('Please select a schedule to apply');
      return;
    }
    if (selected?.id) {
      const { data } = await getVoyageSchedule({
        variables: {
          id: selected?.id,
        },
      });
      const voyageSchedule = VoyageSchedule.create(data?.voyage_schedule);
      const isMatch = voyageSchedule.routing_legs?.some(
        // eslint-disable-next-line eqeqeq
        (leg) => leg.origin?.location?.id == searchProps?.location?.id
      );

      if (isMatch) {
        onVoyageChange && onVoyageChange(voyageSchedule, !isMatch);
        setCurrentPartialVoyageSchedule(undefined);
        onClose();
        return;
      }
      onVoyageChange && onVoyageChange(voyageSchedule, !isMatch);
      setCurrentPartialVoyageSchedule(voyageSchedule);
    }
    onClose();
  };

  const closeShowAddPortCallDrawer = useCallback(() => {
    setCurrentPartialVoyageSchedule(undefined);
    gridRef?.current?.api?.deselectAll();
    if (onVoyageChange) onVoyageChange(undefined);
  }, [onVoyageChange]);

  const onSuccess = useCallback(
    (value: VoyageScheduleValue, type: string) => {
      onClose();
      if (onVoyageChange) onVoyageChange(value);
      setCurrentPartialVoyageSchedule(undefined);
      setVisibleDrawer(false);
    },
    [onClose, onVoyageChange]
  );

  return (
    <>
      {visibleDrawer && (
        <VoyageScheduleFormDrawer
          visible={Boolean(visibleDrawer)}
          onCloseDrawer={() => setVisibleDrawer(false)}
          initialVoyageScheduleFormData={initialVoyageScheduleFormData}
          id={'new'}
          onSuccess={onSuccess}
        />
      )}
      <Drawer
        open={open}
        title="Schedule Selection"
        footer={
          <DrawerFooter
            saveText="Select and Apply Schedule"
            onClose={onClose}
            onSave={onSubmit}
            loading={loading}
          />
        }
        onClose={() => {
          gridRef?.current?.api?.deselectAll();
          onClose();
        }}
        width={'80%'}
        extra={
          <Button type="primary" onClick={() => setVisibleDrawer(true)}>
            Create New Schedule
          </Button>
        }
      >
        <GlobalViewLayout
          key={JSON.stringify(searchProps)}
          gridRef={gridRef}
          doc_type_id={'Network::VoyageSchedule'}
          globalView={globalView}
          height="70vh"
          defaultFilters={[]}
          reportConfig={{
            rowSelection: 'single',
            components: components,
          }}
          quick_filter_field_ids={[
            'routing_legs_estimated_time_of_departure',
            'routing_nodes_location_id',
            'voyage_schedules_carrier_ids',
            'voyage_schedules_voyage_number',
            'voyage_schedules_vessel_id',
          ]}
          label="Voyage Schedule"
        />
      </Drawer>
      {currentPartialVoyageSchedule && (
        <VoyageScheduleAddPortCallDrawer
          visible={Boolean(currentPartialVoyageSchedule)}
          onCloseDrawer={closeShowAddPortCallDrawer}
          voyageSchedule={currentPartialVoyageSchedule}
          initialVoyageScheduleFormData={{
            portCall: initialVoyageScheduleFormData.routing_legs?.[0]?.origin?.location,
            terminal: initialVoyageScheduleFormData.routing_legs?.[0]?.origin?.terminal,
            estimated_time_of_departure:
              initialVoyageScheduleFormData.routing_legs?.[0]?.estimated_time_of_departure,
          }}
          onSuccess={onSuccess}
        />
      )}
    </>
  );
};

export default VoyageScheduleSelectionDrawer;
