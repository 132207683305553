import React, { useEffect } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import { Card, message, Form } from '@shipmnts/pixel-hub';
import { omit as _omit } from 'lodash';
import { useSession } from 'common';
import ShipmentBasicDetails from 'operations/modules/shipment/components/ShipmentBasicDetails';
import ShipmentDocumentDetails from 'operations/modules/shipment/components/ShipmentDocumentDetails';
import { DrawerFooter } from '@shipmnts/pixel-hub';
import {
  FREIGHT_TYPE_OCEAN,
  TRADE_TYPE_EXPORT,
  DOCUMENT_TYPE_MASTER,
  SHIPMENT_TYPE_CONSOL,
  SHIPPING_LINE_SERVICE_TYPES,
  BOOKING_THROUGH_TYPES,
  BUYER_CONSOLIDATION,
  SELLER_CONSOLIDATION,
  SELF_CONSOLIDATION_KEY,
  CONSIGNEE_TYPE_ACTUAL_CONSIGNEE,
  BOOKING_THROUGH_OVERSEAS_AGENT,
} from 'operations/modules/shipment/constants';
import { ShipmentPartyValue, transformPartyDetails } from 'operations/models/ShipmentParty';
import { CarrierValue } from 'operations/models/Carrier';
import { Dayjs, dayjsGenerateConfig } from '@shipmnts/pixel-hub';
import { CREATE_CONSOL_SHIPMENT_FROM_OTO } from 'operations/modules/booking/graphql/oceanTransportOrder';
import { errorMessageHandlerGraphQL } from 'common';

import { OceanTransportOrderValue } from 'operations/models/OceanTransportOrder';
import {
  shipment_document_initial_value,
  DocumentDetailFormValue,
} from '../../CreateShipmentFormContent';
import { BranchAccountValue } from 'operations/models/BranchAccount';
import { getSalesPersonForCustomer } from 'operations/helpers/fetchSalesPerson';
import { SalesPersonValue } from 'common/models/SalesPerson';

interface ConsolShipmentFromContainersFormProps {
  shipment_container_ids: string[];
  oceanTransportOrder: OceanTransportOrderValue;
  carrier?: CarrierValue;
  onClose: () => void;
  onSuccess?: () => void;
  console_type?: string;
}

interface ConsolShipmentFormValue {
  job_date?: Dayjs;
  business_type?: string;
  ocean_shipment_booking?: {
    shipping_line_service_type?: string;
  };
  customer?: ShipmentPartyValue;
  sales_agent?: SalesPersonValue;
  party?: { [key: string]: ShipmentPartyValue };
  incoterms?: string;
  master?: DocumentDetailFormValue;
}

const getInitialValues = (
  oceanTransportOrder: OceanTransportOrderValue,
  console_type: string | undefined
) => ({
  job_date: dayjsGenerateConfig.getNow(),
  business_type: BOOKING_THROUGH_TYPES[0].key,
  consol_type: console_type || BUYER_CONSOLIDATION.key,
  ocean_shipment_booking: {
    shipping_line_service_type: SHIPPING_LINE_SERVICE_TYPES[0].key,
  },
  master: {
    shipment_document: {
      ...shipment_document_initial_value.shipment_document,
      origin_agent_same_as_shipper: true,
      destination_agent_same_as_consignee: false,
    },
    consignee_party_name: CONSIGNEE_TYPE_ACTUAL_CONSIGNEE.key,
    party: {
      origin_agent: {
        party_company: oceanTransportOrder.customer_company,
        party_address: oceanTransportOrder.customer_address,
      },
    },
  },
});

const consol_type_party_mapping = {
  [BUYER_CONSOLIDATION.key]: { origin_agent_same_as_shipper: true },
  [SELLER_CONSOLIDATION.key]: { destination_agent_same_as_consignee: true },
  [SELF_CONSOLIDATION_KEY]: {
    origin_agent_same_as_shipper: true,
    destination_agent_same_as_consignee: true,
  },
};

const getCreateShipmentPayload = (
  values: ConsolShipmentFormValue,
  shipment_container_ids: string[],
  ocean_transport_order_id: string
) => {
  const { job_date, business_type, party, master, customer, sales_agent, ...restVal } = values;
  const overseas_agent =
    business_type === BOOKING_THROUGH_OVERSEAS_AGENT
      ? { overseas_agent: master?.party?.destination_agent }
      : {};
  const payload = {
    shipment_container_ids,
    ocean_transport_order_id,
    shipment: {
      ...restVal,
      sales_agent_id: sales_agent?.id,
      customer_company_id: customer?.party_company?.id,
      customer_address_id: customer?.party_address?.id,
      business_type,
      job_date: job_date?.format('YYYY-MM-DD'),
      shipment_parties: transformPartyDetails(
        Object.assign({}, master?.party || {}, party, overseas_agent)
      ),
      consignee_party_name: master?.consignee_party_name,
    },
    shipment_document_master: {
      ..._omit(master?.shipment_document, [
        'origin_agent_same_as_shipper',
        'destination_agent_same_as_consignee',
      ]),
      shipper_on_document: master?.shipment_document?.origin_agent_same_as_shipper
        ? 'origin_agent'
        : 'shipper',
      consignee_on_document: master?.shipment_document?.destination_agent_same_as_consignee
        ? 'destination_agent'
        : 'consignee',
    },
  };
  return payload;
};

const ConsolShipmentFromContainersForm = React.memo(function ConsolShipmentFromContainersForm(
  props: ConsolShipmentFromContainersFormProps
): JSX.Element {
  const { onClose, onSuccess, oceanTransportOrder, shipment_container_ids, carrier, console_type } =
    props;
  const [createConsolShipmentFromOto, { data, loading, error }] = useMutation(
    CREATE_CONSOL_SHIPMENT_FROM_OTO
  );

  const client = useApolloClient();

  useEffect(() => {
    if (!error && data?.create_consol_shipment_from_oto) {
      message.success('Shipment Successfully Created !');
      onClose();
      if (onSuccess) onSuccess();
    }
  }, [error, data, onClose, onSuccess]);

  const [form] = Form.useForm();
  const sessionData = useSession();
  return (
    <Form
      form={form}
      name="create_consol_shipment"
      layout="vertical"
      initialValues={getInitialValues(oceanTransportOrder, console_type)}
      onValuesChange={async (changedValues, allValues) => {
        let formValues: ConsolShipmentFormValue = {};
        if (changedValues.hasOwnProperty('consol_type')) {
          formValues = {
            master: { shipment_document: consol_type_party_mapping[changedValues.consol_type] },
          };
        }
        if (changedValues.hasOwnProperty('customer')) {
          const branch_id = sessionData.branch_accounts?.find(
            (ba: BranchAccountValue) =>
              ba?.default_address?.id === oceanTransportOrder?.customer_address?.id
          )?.id;
          const customer = changedValues.customer;
          if (branch_id && customer) {
            const { response } = await getSalesPersonForCustomer(
              customer.id,
              branch_id,
              sessionData,
              client
            );
            if (response) {
              formValues['sales_agent'] = response;
            }
          }
        }
        if (Object.keys(formValues).length > 0) form.setFieldsValue(formValues);
      }}
      onFinish={(values: ConsolShipmentFormValue) => {
        const payload = getCreateShipmentPayload(
          values,
          shipment_container_ids,
          oceanTransportOrder.id
        );
        createConsolShipmentFromOto({ variables: payload });
      }}
    >
      {error && errorMessageHandlerGraphQL(error)}
      <Card title="Basic Details" className="custom-card">
        <ShipmentBasicDetails shipmentType={SHIPMENT_TYPE_CONSOL} form={form} />
      </Card>
      <Card
        title="MBL Instructions"
        className="custom-card"
        style={{ marginTop: '16px', marginBottom: '44px' }}
      >
        <ShipmentDocumentDetails
          form={form}
          freightType={FREIGHT_TYPE_OCEAN}
          tradeType={TRADE_TYPE_EXPORT}
          shipmentType={SHIPMENT_TYPE_CONSOL}
          documentType={DOCUMENT_TYPE_MASTER}
          carrier={carrier}
        />
      </Card>
      <div
        style={{
          position: 'absolute',
          bottom: '22px',
          width: '98%',
          borderTop: '1px solid #e8e8e8',
          textAlign: 'right',
          background: '#fff',
          padding: '7px',
          margin: '-22px -20px',
        }}
      >
        <DrawerFooter saveText="Create Shipment" loading={loading} onClose={onClose} />
      </div>
    </Form>
  );
});

export default ConsolShipmentFromContainersForm;
