import React from 'react';
import { Row, Col } from '@shipmnts/pixel-hub';
import WMSParties from './Components/WMSParties';
import WMSShipmentDetails from './Components/WMSShipmentDetails';
import WMSCreationDetails from './Components/WMSCreationDetails';
import WMSReceiptDetails from './Components/WMSReceiptDetails';
import {
  TRANSACTION_TYPE_ASN,
  TRANSACTION_TYPE_GIO,
  WarehouseTransactionValue,
} from 'operations/models/WarehouseTransaction';
interface WMSSummaryProps {
  warehouseTransaction: WarehouseTransactionValue;
}
const WMSSummary = (props: WMSSummaryProps) => {
  const { warehouseTransaction } = props;

  if (!warehouseTransaction) return <></>;
  return (
    <Row gutter={16} className="shipment-summary">
      {/* Party Details */}
      <Col sm={24} md={12} lg={6}>
        <WMSParties warehouseTransaction={warehouseTransaction} />
      </Col>
      {/* Linked Shipment Details */}
      {
        <Col sm={24} md={12} lg={6}>
          <WMSShipmentDetails warehouseTransaction={warehouseTransaction} />
        </Col>
      }
      {/* Receipt Detail  */}
      {warehouseTransaction.type !== TRANSACTION_TYPE_ASN &&
        warehouseTransaction.type !== TRANSACTION_TYPE_GIO && (
          <Col sm={24} md={12} lg={6}>
            <WMSReceiptDetails warehouseTransaction={warehouseTransaction} />
          </Col>
        )}
      {/* Creation Details */}
      <Col sm={24} md={12} lg={6}>
        <WMSCreationDetails warehouseTransaction={warehouseTransaction} />
      </Col>
    </Row>
  );
};

export default WMSSummary;
