import React from 'react';
import { Column } from 'operations/models/Report';
import { ShipmentValue } from 'operations/models/Shipment';
import ActionRendererReport from 'operations/modules/actionHelper/ActionRenderer';
import EventRenderer from '../Containers/EventRenderer';
import {
  BaseTable,
  MultiLinkRender,
  TagsRender,
  TrackerContextProvider,
} from '@shipmnts/pixel-hub';
import ShipmentDocumentTimlineRenderer from './ShipmentDocumentTimelineRenderer';
import { ShipmentDocumentValue } from 'operations/models/ShipmentDocument';
import { FREIGHT_TYPE_ROAD } from 'operations/modules/shipment/constants';
import { VehicleValue } from 'operations/models/Vehicle';
import { DriverValue } from 'operations/models/Driver';
import { ShipmentEawbStatusRenderer } from './ShipmentDocumentTimelineRenderer';
import { ActionRendererDetailReport } from 'operations';
import { QtyUomTypeRenderer, useSession } from 'common';

export interface HouseShipmentRowDataInterface {
  id: string;
  job_number?: {
    value?: string;
    extra_fields: { id: string };
  };
  shipper?: string;
  consignee?: string;
  shipment_document?: ShipmentDocumentValue;
  shipment_document_eawb_status?: string;
  last_event?: any;
  incoterms?: string;
  load?: string;
  sales_agent?: string;
  // sales_agent_id?: number;
  gross_volume?: number;
  gross_weight?: number;
  chargeable_weight?: number;
  remarks?: string;
  services?: string;
  final_place_of_delivery?: string;
  number_of_container?: number;
  vehicle?: VehicleValue;
  driver?: DriverValue;
  total_number_of_packages?: number;
  net_weight?: number;
  container_numbers?: string[];
  weight_unit?: string;
}

interface propType {
  rowData: HouseShipmentRowDataInterface[];
  shipment: ShipmentValue;
  refetchData: () => void;
  columns: string[];
}
export const DocumentName: { [key: string]: string } = {
  air: 'HAWB',
  ocean: 'HBL',
  road: 'Consignment Note',
};

const getColMap = (
  shipment: ShipmentValue,
  refetchData: () => void,
  iseAWBSubscribed: boolean
): { [key: string]: Column } => {
  return {
    house_shipment: {
      headerName:
        shipment.freight_type === FREIGHT_TYPE_ROAD ? 'Linked Shipment' : 'House Shipment',
      field: 'job_number',
      width: 250,
      minWidth: 250,
      pinned: 'left',
      columnType: 'Link',
      cellRendererParams: { id_field: 'id', doc_type_id: 'Shipment::Shipment' },
      lockPosition: true,
      suppressMovable: true,
      lockVisible: true,
    },
    shipper: {
      headerName: 'Shipper',
      field: 'shipper',
      columnType: 'String',
      width: 200,
    },
    consignee: {
      headerName: 'Consignee',
      field: 'consignee',
      columnType: 'String',
      width: 140,
    },
    shipment_number: {
      headerName: `${DocumentName[shipment?.freight_type || 'ocean']} #`,
      field: 'shipment_document.shipment_number',
      width: 200,
      cellRenderer: 'MultiLinkRender',
      cellRendererParams: {
        id_field: 'id',
        value_field: 'name',
        doc_type_id: 'Shipment::ShipmentDocument',
        extra_fields: ['shipment_id'],
      },
    },
    shipment_date: {
      headerName: `${DocumentName[shipment?.freight_type || 'ocean']} Date`,
      field: 'shipment_document.shipment_date',
      cellRenderer: 'TagsRender',
      width: 130,
    },
    shipment_document_status: {
      headerName: `${DocumentName[shipment?.freight_type || 'ocean']} Status`,
      field: 'shipment_document',
      cellRenderer: 'shipment_document_event_timeline',
    },
    shipment_document_eawb_status: {
      headerName: `E-HAWB Status`,
      field: 'shipment_document_eawb_status',
      cellRenderer: 'shipment_eawb_status_renderer',
      hide:
        !iseAWBSubscribed || shipment?.freight_type !== 'air' || shipment?.trade_type !== 'export',
    },
    incoterm: {
      headerName: 'Incoterm',
      field: 'incoterms',
      columnType: 'String',
      width: 130,
    },
    load: {
      headerName: 'Load',
      field: 'load',
      columnType: 'String',
      width: 100,
    },
    sales_person: {
      headerName: 'Sales Person',
      field: 'sales_agent',
      // columnType: 'String',
      width: 150,
    },
    vehicle_license_plate_number: {
      headerName: 'Vehicle Number',
      field: 'vehicle.vehicle_license_plate_number',
      columnType: 'String',
      width: 130,
    },
    driver_name: {
      headerName: 'Driver Name',
      field: 'driver.driver_name',
      columnType: 'String',
      width: 130,
    },
    number_of_container: {
      headerName: 'Number of Containers',
      field: 'number_of_container',
      columnType: 'Integer',
      width: 150,
    },
    gross_weight: {
      headerName: 'Gross Wt',
      field: 'gross_weight',
      columnType: 'Float',
      width: 120,
      cellRendererSelector: (params) => {
        return {
          component: 'QtyUomTypeRenderer',
          params: {
            qty: params?.data?.gross_weight,
            uom: params?.data?.weight_unit,
          },
        };
      },
    },
    gross_volume: {
      headerName: 'Gross Vol',
      field: 'gross_volume',
      columnType: 'Float',
      width: 150,
      hide: true,
    },
    last_action_status: {
      headerName: 'Current Status',
      field: 'last_event',
      colId: 'last_event',
      width: 258,
      pinned: 'right',
      cellRenderer: 'event_renderer',
      cellRendererParams: {
        doc_type_id: 'Shipment::Shipment',
        refetchData: refetchData,
        workflow_type: 'main',
      },
      lockVisible: true,
    },
    action: {
      headerName: 'Actions',
      field: 'action',
      lockPosition: true,
      pinned: 'right',
      cellRenderer: 'actions_renderer',
      minWidth: 100,
      cellRendererParams: {
        doc_type_id: 'Shipment::Shipment',
        refetchData: refetchData,
        isDetailScreen: true,
      },
      lockVisible: true,
    },
    final_place_of_delivery: {
      headerName: 'Final place of delivery',
      field: 'final_place_of_delivery',
      width: 150,
    },
    remarks: {
      headerName: 'Remarks',
      field: 'remarks',
      width: 150,
    },
    services: {
      headerName: 'Services',
      field: 'services',
      width: 150,
    },
    net_weight: {
      headerName: 'Net Weight',
      field: 'net_weight',
      columnType: 'Float',
      width: 150,
      cellRendererSelector: (params) => {
        return {
          component: 'QtyUomTypeRenderer',
          params: {
            qty: params?.data?.net_weight,
            uom: params?.data?.weight_unit,
          },
        };
      },
    },
    total_number_of_packages: {
      headerName: 'Qty',
      field: 'total_number_of_packages',
      columnType: 'Integer',
      width: 150,
    },
    container_numbers: {
      headerName: 'Container Numbers',
      field: 'container_numbers',
      cellRenderer: 'TagsRender',
    },
  };
};

const AttachedHousesTable = (prop: propType) => {
  const { shipment, refetchData, rowData, columns } = prop;
  const sessionData = useSession();
  const iseAWBSubscribed = sessionData?.company_account?.subscriptions
    .map((subs: any) => subs?.app?.app_type)
    .includes('carrier_filing');
  const colMap = getColMap(shipment, refetchData, iseAWBSubscribed);

  const columnDefs: Column[] = columns.map((col) => colMap[col]);

  if (shipment.freight_type === 'air') {
    columnDefs.push({
      headerName: 'Chargeable Wt',
      field: 'chargeable_weight',
      width: 120,
    });
  }

  const components = {
    actions_renderer: ActionRendererReport,
    event_renderer: EventRenderer,
    shipment_document_event_timeline: ShipmentDocumentTimlineRenderer,
    shipment_eawb_status_renderer: ShipmentEawbStatusRenderer,
    ActionRendererDetailReport,
    QtyUomTypeRenderer,
    MultiLinkRender,
    TagsRender,
  };
  return (
    <TrackerContextProvider referenceType="Shipment::Shipment">
      <BaseTable
        reportName={'attached_house'}
        columns={columnDefs}
        rowData={rowData}
        reportConfig={{
          components: components,
          rowHeight: 46,
        }}
      />
    </TrackerContextProvider>
  );
};

export default AttachedHousesTable;
