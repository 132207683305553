import { PRIMARY_TYPE } from '../constants';
import { Action } from '../models';
import { editVehicleActionRender, statusUpdateVehicleActionRender } from './vehicleActionRenderer';

export const disableVehicle = (vehicle: any): Action => {
  return {
    type: PRIMARY_TYPE,
    displayComponent: 'Disable Vehicle',
    key: 'disable_vehicle',
    isEnable: true && vehicle?.vehicle_status === 'enabled',
    performAction: statusUpdateVehicleActionRender,
  };
};

export const enableVehicle = (vehicle: any): Action => {
  return {
    type: PRIMARY_TYPE,
    displayComponent: 'Enable Vehicle',
    key: 'enable_vehicle',
    isEnable: true && vehicle?.vehicle_status === 'disabled',
    performAction: statusUpdateVehicleActionRender,
  };
};

export const editVehicle = (vehicle: any): Action => {
  return {
    type: PRIMARY_TYPE,
    displayComponent: 'Edit Vehicle',
    key: 'edit_subscription',
    isEnable: true && vehicle?.vehicle_status === 'enabled',
    performAction: editVehicleActionRender,
  };
};
export const getVehicleActions = (vehicle: any) => {
  return [enableVehicle(vehicle), disableVehicle(vehicle), editVehicle(vehicle)];
};
