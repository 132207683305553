import React from 'react';
import { RowNode } from '@ag-grid-community/core';
import {
  FREIGHT_TYPE_AIR,
  FREIGHT_TYPE_OCEAN,
  SHIPMENT_TYPE_HOUSE,
} from 'operations/modules/shipment/constants';
import { Action } from 'operations/modules/actionHelper/models';
import { PRIMARY_TYPE } from 'operations/modules/actionHelper/constants';
import { CompassOutlined, EditOutlined } from '@shipmnts/pixel-hub';
import {
  SELF_CONSOLIDATION_KEY,
  SELLER_CONSOLIDATION,
  BUYER_CONSOLIDATION,
} from 'operations/modules/shipment/constants';
import {
  createConsolShipmentRenderer,
  editShipmentFieldRenderer,
} from './shipmentBulkActionRenderer';
import { startCase } from 'lodash';
import {
  bulkEventActionsRenderer,
  getNextPossibleAction,
  GetIconForAction,
} from '../ShipmentContainerBulkActionsHelper';

const editFieldAction = (field_name: string, label: string): Action => {
  return {
    key: field_name,
    type: PRIMARY_TYPE,
    displayComponent: label,
    icon: <EditOutlined />,
    description: label,
    performAction: editShipmentFieldRenderer,
    isEnable: true,
    extraProps: { field_name: field_name, label: label, render_inside: true },
  };
};

const editShipmentFieldsAction = (selectedNodes: RowNode[]): Action => {
  const containsHouseShipment = selectedNodes
    .map((entry) => entry?.data?.shipment_type)
    .includes(SHIPMENT_TYPE_HOUSE);
  const childComponents = [
    editFieldAction('estimated_time_of_arrival', 'ETA at PoD'),
    editFieldAction('estimated_time_of_arrival_at_fpod', 'ETA at FPOD'),
    editFieldAction('estimated_time_of_arrival_at_pcd', 'ETA at PCD'),
  ];
  if (!containsHouseShipment) {
    childComponents.push(
      editFieldAction('lfd_at_pod', 'LFD PoD'),
      editFieldAction('lfd_at_pocd', 'LFD PcD'),
      editFieldAction('lfd_at_carrier', 'LFD Carrier'),
      editFieldAction('lfd_at_empty_return', 'LFD Empty Return'),
      editFieldAction('destination_carrier_free_days', 'Destination Carrier Free Days'),
      editFieldAction('destination_port_free_days', 'Destination Port Free Days'),
      editFieldAction('origin_carrier_free_days', 'Origin Carrier Free Days')
    );
  }
  childComponents.push(editFieldAction('color_code', 'Colour'));
  return {
    key: 'edit_fields',
    type: PRIMARY_TYPE,
    displayComponent: 'Edit Fields',
    icon: <EditOutlined />,
    description: 'Edit Fields',
    childComponents: childComponents,
    isEnable: true,
  };
};

const allConsolAction = (
  consolType: string,
  label: string,
  freightType: string,
  loadType: string,
  tradeType: string
): Action => {
  return {
    key: consolType,
    type: PRIMARY_TYPE,
    displayComponent: label,
    icon: <CompassOutlined />,
    description: label,
    performAction: createConsolShipmentRenderer,
    isEnable: true,
    extraProps: { consolType, freightType, loadType, tradeType },
  };
};

const createAllConsolShipment = (
  selectedNodes: RowNode[],
  freightType: string,
  loadType: string,
  tradeType: string
): Action => {
  let enable = true;

  (selectedNodes || []).forEach((node) => {
    if (node.data.shipment_type !== SHIPMENT_TYPE_HOUSE || node.data.master_shipment_id) {
      enable = false;
    }
  });
  return {
    key: 'create_consol_shipment',
    type: PRIMARY_TYPE,
    displayComponent: 'Consol Shipment',
    icon: <CompassOutlined />,
    description: 'Consol Shipment',
    childComponents: [
      allConsolAction(SELF_CONSOLIDATION_KEY, 'Self Consol', freightType, loadType, tradeType),
      allConsolAction(BUYER_CONSOLIDATION.key, 'Buyer Consol', freightType, loadType, tradeType),
      allConsolAction(SELLER_CONSOLIDATION.key, 'Seller Consol', freightType, loadType, tradeType),
    ],
    isEnable: enable,
  };
};

const getShipmentAction = (action_name: string): Action => {
  return {
    key: action_name,
    type: PRIMARY_TYPE,
    displayComponent: startCase(action_name),
    icon: <GetIconForAction eventName={action_name} />,
    description: startCase(action_name),
    performAction: bulkEventActionsRenderer,
    isEnable: true,
    extraProps: {
      action_name: action_name,
      doctype_id: 'Shipment::Shipment',
    },
  };
};

export const getShipmentBulkActions = (selectedNodes: RowNode[]) => {
  if (selectedNodes.length === 0) return [];
  const selectedData = selectedNodes.map((node) => node.data);
  const loadType = selectedData.length > 0 ? selectedData[0]?.load_type : null;
  const freightType = selectedData.length > 0 ? selectedData[0]?.freight_type : null;
  const tradeType = selectedData.length > 0 ? selectedData[0]?.trade_type : null;
  let actions: Action[] = [];
  if (freightType === FREIGHT_TYPE_OCEAN || freightType === FREIGHT_TYPE_AIR) {
    actions = [createAllConsolShipment(selectedNodes, freightType, loadType, tradeType)];
  }
  actions.push(editShipmentFieldsAction(selectedNodes));

  // next actions
  const nextActions: string[] = getNextPossibleAction(selectedNodes);
  nextActions.forEach((action_name: string) => {
    actions.push(getShipmentAction(action_name));
  });

  return actions;
};
