import {
  FREIGHT_CONTRACT_DOC_STATUS_DRAFT,
  FREIGHT_CONTRACT_STATUS_ACTIVE,
  FREIGHT_CONTRACT_STATUS_EXPIRED,
} from 'operations/modules/freightContract/constant';
import { NEGATIVE_TYPE, PRIMARY_TYPE } from '../constants';
import { Action } from '../models';
import {
  deleteRenderer,
  expireNowRenderer,
  expireOnRenderer,
} from './freightContractActionRenderer';

const editFreightContract = (fc: any): Action => {
  return {
    type: PRIMARY_TYPE,
    key: 'edit_freight_contract',
    displayComponent: 'Edit',
    onClick: () => {
      window.open(
        `${process.env.SHIPMNTS_WEB_URL}/rates/contracts/${fc.contract_type}/form/${fc.id}`,
        '_self'
      );
    },
    isEnable: fc.status !== FREIGHT_CONTRACT_STATUS_EXPIRED,
  };
};

const duplicateFreightContract = (fc: any): Action => {
  return {
    type: PRIMARY_TYPE,
    key: 'duplicate_freight_contract',
    displayComponent: 'Duplicate',
    onClick: () => {
      window.open(
        `${process.env.SHIPMNTS_WEB_URL}/rates/contracts/${fc.contract_type}/form/new/?id=${fc.id}`,
        '_self'
      );
    },
    isEnable: true,
  };
};

const expireOnAction = (fc: any): Action => {
  return {
    type: PRIMARY_TYPE,
    key: 'expire_on',
    displayComponent: 'Expire On',
    isEnable: fc.status === FREIGHT_CONTRACT_STATUS_ACTIVE,
    performAction: expireOnRenderer,
  };
};

const deleteAction = (fc: any): Action => {
  return {
    type: NEGATIVE_TYPE,
    key: 'delete_action',
    displayComponent: 'Delete',
    isEnable: fc.doc_status === FREIGHT_CONTRACT_DOC_STATUS_DRAFT,
    performAction: deleteRenderer,
  };
};

const expireNowActionateFC = (fc: any): Action => {
  return {
    type: PRIMARY_TYPE,
    key: 'expire_now',
    displayComponent: 'Expire Now',
    isEnable: fc.status === FREIGHT_CONTRACT_STATUS_ACTIVE,
    performAction: expireNowRenderer,
  };
};

export const getFreightContractActions = (fc: any) => {
  return [
    editFreightContract(fc),
    expireOnAction(fc),
    deleteAction(fc),
    expireNowActionateFC(fc),
    duplicateFreightContract(fc),
  ];
};
