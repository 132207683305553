import React from 'react';
import { AddressCompanySearch } from 'common';
import { Row, Col, InputNumber, Form, dayjs, Dayjs, DatePicker } from '@shipmnts/pixel-hub';
import { getCutoffDateDisableFunction } from 'operations/modules/booking/helpers/CutoffDateHelper';
import { LOAD_TYPE_LCL, LOAD_TYPE_FCL } from 'operations/baseConstants';
import { BOOKING_TYPE_SELF } from 'operations/baseConstants';
const ROW_GUTTER = 16;

const cutoffDateDisable = getCutoffDateDisableFunction();

const BookingOrderConfirmation = React.memo(function BookingOrderConfirmation(props: {
  etd: Dayjs | undefined | null;
  areCutoffRequired?: boolean;
  disableCutoff?: { [key: string]: boolean };
  load_type?: string;
  stuffing_location_type?: string;
  disableEmptyPickupLocation?: boolean;
  booking_type?: string | null;
}): JSX.Element {
  const { etd, areCutoffRequired, disableCutoff, load_type, booking_type } = props;
  const estimated_time_of_departure = etd;
  return (
    <>
      {/* {load_type === LOAD_TYPE_LCL && stuffing_location_type && (
        <Row gutter={ROW_GUTTER}>
          <Col span={12}>
            <Form.Item noStyle name={['stuffing_buffer_service_order', 'location_node', 'id']} />
            <Form.Item noStyle name={['stuffing_buffer_service_order', 'location_node', 'tags']} />
            <Form.Item
              label="Stuffing Location"
              name={['stuffing_buffer_service_order', 'location_node', 'location']}
            >
              <GlobalSearch
                doc_type="Global::Location"
                searchProps={{
                  type: [
                    stuffing_location_type === STUFFING_LOCATION_TYPE_CFS
                      ? LOCATION_TYPE_CFS
                      : LOCATION_TYPE_ICD,
                  ],
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      )} */}
      <Row gutter={ROW_GUTTER}>
        <Col span={12}>
          {load_type !== LOAD_TYPE_LCL && (
            <>
              {/* <Form.Item
                required={areCutoffRequired}
                rules={[{ required: areCutoffRequired }]}
                name="valid_till_date"
                label="Validity Date"
              >
                <DatePicker
                  disabledDate={(current: Dayjs) =>
                    cutoffDateDisable['valid_till_date'](current, { estimated_time_of_departure })
                  }
                  style={{ width: '100%' }}
                  defaultOpenValue={dayjs()}
                />
              </Form.Item> */}
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.gate_close_date !== currentValues.gate_close_date ||
                  prevValues.si_cutoff_date !== currentValues.si_cutoff_date
                }
              >
                {({ getFieldValue }) => {
                  const gate_close_date = getFieldValue('gate_close_date');
                  return (
                    <Form.Item
                      required={areCutoffRequired}
                      rules={[{ required: areCutoffRequired }]}
                      name="gate_open_date"
                      label="Gate Open Cutoff"
                    >
                      <DatePicker
                        disabledDate={(current: Dayjs) =>
                          cutoffDateDisable['gate_open_date'](
                            current,
                            { estimated_time_of_departure },
                            { gate_close_date }
                          )
                        }
                        showTime
                        style={{ width: '100%' }}
                        disabled={disableCutoff?.gate_open_date}
                        defaultOpenValue={dayjs()}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </>
          )}
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.gate_open_date !== currentValues.gate_open_date
            }
          >
            <Form.Item
              required={areCutoffRequired}
              rules={[{ required: areCutoffRequired }]}
              name="si_cutoff_date"
              label="SI cutoff"
            >
              <DatePicker
                disabledDate={(current: Dayjs) =>
                  cutoffDateDisable['si_cutoff_date'](current, { estimated_time_of_departure })
                }
                showTime
                style={{ width: '100%' }}
                disabled={disableCutoff?.si_cutoff_date}
                defaultOpenValue={dayjs()}
              />
            </Form.Item>
          </Form.Item>
          <Row gutter={ROW_GUTTER}>
            <Col span={12}>
              <Form.Item name="origin_detention_free_days" label="Origin Detention Free Days">
                <InputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="origin_demurrage_free_days" label="Origin Demurrage Free Days">
                <InputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Col span={12}>
          {load_type !== LOAD_TYPE_LCL && (
            <>
              {/* <Form.Item
                required={areCutoffRequired}
                rules={[{ required: areCutoffRequired }]}
                name="empty_pickup_location"
                label="Empty Pickup Location"
              >
                <GlobalSearch
                  doc_type="Global::Location"
                  disabled={disableEmptyPickupLocation}
                  searchProps={{ type: [LOCATION_TYPE_ICD, LOCATION_TYPE_CFS] }}
                />
              </Form.Item> */}
              {/* {booking_type !== BOOKING_TYPE_VENDOR &&
                booking_type !== BOOKING_TYPE_SHIPPING_LINE && (
                  <Form.Item
                    required={areCutoffRequired}
                    rules={[{ required: areCutoffRequired }]}
                    name="empty_return_location"
                    label="Empty Return Location"
                  >
                    <GlobalSearch
                      doc_type="Global::Location"
                      searchProps={{ type: [LOCATION_TYPE_ICD, LOCATION_TYPE_CFS] }}
                    />
                  </Form.Item>
                )} */}
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.gate_open_date !== currentValues.gate_open_date
                }
              >
                {({ getFieldValue }) => {
                  const gate_open_date = getFieldValue('gate_open_date');
                  return (
                    <Form.Item
                      required={areCutoffRequired}
                      rules={[{ required: areCutoffRequired }]}
                      name="gate_close_date"
                      label="Gate Close Cutoff"
                    >
                      <DatePicker
                        disabledDate={(current: Dayjs) =>
                          cutoffDateDisable['gate_close_date'](
                            current,
                            { estimated_time_of_departure },
                            { gate_open_date }
                          )
                        }
                        disabled={disableCutoff?.gate_close_date}
                        showTime={{ defaultOpenValue: dayjs() }}
                        style={{ width: '100%' }}
                        defaultOpenValue={dayjs()}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
              <Form.Item
                required={areCutoffRequired}
                rules={[{ required: areCutoffRequired }]}
                name="doc_cutoff_date"
                label="Docs Handover cutoff"
              >
                <DatePicker
                  disabledDate={(current: Dayjs) =>
                    cutoffDateDisable['doc_cutoff_date'](current, { estimated_time_of_departure })
                  }
                  showTime
                  style={{ width: '100%' }}
                  disabled={disableCutoff?.doc_cutoff_date}
                  defaultOpenValue={dayjs()}
                />
              </Form.Item>
            </>
          )}
          {/* {load_type !== LOAD_TYPE_LCL && (
            <Form.Item
              rules={[{ required: false }]}
              label="Stuffing Cutoff Date"
              name={['stuffing_buffer_service_order', 'stuffing_cutoff_date']}
            >
              <DatePicker style={{ width: '100%' }} defaultOpenValue={dayjs()} />
            </Form.Item>
          )} */}

          <Row gutter={ROW_GUTTER}>
            <Col span={12}>
              <Form.Item
                name="destination_detention_free_days"
                label="Destination Detention Free Days"
              >
                <InputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="destination_demurrage_free_days"
                label="Destination Demurrage Free Days"
              >
                <InputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      {booking_type === BOOKING_TYPE_SELF && load_type === LOAD_TYPE_FCL && (
        <Row gutter={ROW_GUTTER}>
          <Col span={12}>
            <Form.Item name="surveyor" label="Surveyor">
              <AddressCompanySearch
                companySearchProps={{
                  searchProps: { is_vendor: true },
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
    </>
  );
});

export default BookingOrderConfirmation;
