import { SessionDataValue } from 'operations/models/SessionData';
import { axiosInstance as axios } from 'common';
import { get as _get, compact as _compact, startCase as _startCase } from 'lodash';
import { message } from '@shipmnts/pixel-hub';
import { DOC_TYPE_GENERATE_FUNCTION_MAPPING } from './DocumentGeneration';
import { ApolloClient } from '@apollo/client';
import { Document } from 'operations/apis/document';
import { ShipmentValue } from 'operations/models/Shipment';
import {
  FreightType,
  TradeType,
  SHIPMENT_TYPE_CONSOL,
  SHIPMENT_TYPE_BACK_TO_BACK,
  SHIPMENT_TYPE_HOUSE,
} from 'operations/modules/shipment/constants';
import {
  DOCUMENT_TYPE_CARGO_ARRIVAL_NOTICE,
  DOCUMENT_TYPE_AIR_CARGO_ARRIVAL_NOTICE,
  DOCUMENT_TYPE_TRANSPORT_DELIVERY_ORDER,
  DOCUMENT_TYPE_OCEAN_DELIVERY_ORDER,
  DOCUMENT_TYPE_AIR_DELIVERY_ORDER,
  SHIPMENT_DOCUMENT_TYPE_HOUSE,
  SHIPMENT_DOCUMENT_TYPE_MASTER,
} from '../../constants';
import { ShipmentDocumentValue } from 'operations/models/ShipmentDocument';

export interface GenerateDocumentType {
  created_at: number;
  created_by_id: number;
  created_by_name: string;
  document_name: string;
  document_type: string;
  id: string;
  shipment_document_id?: string;
  shipment_id: string;
  status: string;
  template_id: string;
  tenant_id: number;
  updated_at: number;
  ref_document_id: string;
}

export type GenerateDocumentResponseType = {
  [key: string]: {
    [key: string]: GenerateDocumentType[];
  };
};

export interface GenerateDocumentReportType extends GenerateDocumentType {
  name: object;
  job_number?: string;
  shipment_number?: string;
  docIds: string[];
  is_new_document?: boolean;
}

export interface GenerateDocumentParams {
  shipment: ShipmentValue;
  house_shipments: ShipmentValue[];
  shipment_document?: ShipmentDocumentValue;
  shipment_id: string;
  document_type: string;
  template: Document;
  user: SessionDataValue;
  client: ApolloClient<object>;
}

interface FetchGeneratedDocumentsType {
  shipment_ids: string[];
  document_types?: string[];
  fields?: (string | { [key: string]: string[] })[];
}

export const getProperUserName = (user: SessionDataValue) => {
  const firstName = _get(user, 'first_name');
  const lastName = _get(user, 'last_name');
  return _compact([_startCase(firstName), lastName]).join(' ');
};

export async function fetchGeneratedDocuments(payload: FetchGeneratedDocumentsType) {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.DOCGEN_URL}/generated_documents`,
      data: payload,
    });
    if (response) {
      return { response, error: false };
    }
    return { response: undefined };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function fetchGenerateDocuments(shipment_ids: string[]) {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.DOCGEN_URL}/generate_documents`,
      params: {
        shipment_id: shipment_ids,
      },
    });
    if (response) {
      return { response, error: false };
    }
    return { response: undefined };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export const fetchTemplates = async (
  freight_type: FreightType | undefined | null,
  trade_type: TradeType | undefined | null
) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.DOCGEN_URL}/templates`,
      params: { freight_type: freight_type, trade_type: trade_type },
      timeout: 30e3,
    });
    if (response) {
      const templates = _get(response, 'data.templates', []).reduce((acc: any, template: any) => {
        acc = {
          ...acc,
          [template.document_type]: template,
        };
        return acc;
      }, {});
      return { templates };
    }
    return { error: true };
  } catch (error) {
    if (error instanceof Error) message.error(error.message);
    console.log(error);
    return { error };
  }
};

export const createDocument = async (data: any) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.DOCGEN_URL}/generate_documents`,
      data,
    });
    if (response) {
      return { response, error: false };
    }
    return { response: undefined };
  } catch (error) {
    console.error(error);
    return { error };
  }
};

export async function duplicateDocument(data: any) {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.DOCGEN_URL}/duplicate_documents/duplicate`,
      data,
    });
    if (response) {
      return { response, error: false };
    }
    return { response: undefined };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export const getGenerateDocumentPayload = async (props: {
  shipment: ShipmentValue;
  house_shipments: ShipmentValue[];
  shipment_id: string;
  shipment_type: string | undefined | null;
  shipment_document_id: string | undefined;
  document_type: string;
  template: Document;
  parentDocIds: string[];
  user: SessionDataValue;
  client: ApolloClient<object>;
  document_id?: string;
}) => {
  const {
    shipment,
    house_shipments,
    shipment_id,
    shipment_type,
    shipment_document_id,
    document_id,
    document_type,
    template,
    parentDocIds,
    user,
    client,
  } = props;
  const params = {
    shipment_id,
    shipment_type,
    template_id: template?.id,
    shipment_document_id,
    created_by_id: user?.id,
    created_by_name: getProperUserName(user),
    document_id,
    document_ids: parentDocIds && parentDocIds.length ? parentDocIds.join(',') : null,
  };
  let generateParams: GenerateDocumentParams = {
    template,
    shipment,
    house_shipments,
    shipment_id,
    document_type,
    user,
    client,
  };
  // if ([DOCUMENT_TYPE_NEW_CONSOL_GENERAL_MANIFEST].includes(document_type)) {
  //   const cgmCachedFields = (cachedFields.get('cgm') || Map({})).toJS();
  //   generateParams = { ...generateParams, cgmCachedFields };
  // }
  if (
    [
      DOCUMENT_TYPE_CARGO_ARRIVAL_NOTICE,
      DOCUMENT_TYPE_TRANSPORT_DELIVERY_ORDER,
      DOCUMENT_TYPE_OCEAN_DELIVERY_ORDER,
      DOCUMENT_TYPE_AIR_DELIVERY_ORDER,
      DOCUMENT_TYPE_AIR_CARGO_ARRIVAL_NOTICE,
    ].includes(document_type)
  ) {
    let shipment_document;
    if (
      shipment.shipment_type === SHIPMENT_TYPE_BACK_TO_BACK ||
      shipment.shipment_type === SHIPMENT_TYPE_HOUSE ||
      (shipment.shipment_type === SHIPMENT_TYPE_CONSOL && shipment.id !== shipment_id)
    ) {
      let shipmentObj = shipment;
      if (shipment.id !== shipment_id) {
        const house_shipment = house_shipments.find((shipment) => shipment.id === shipment_id);
        if (house_shipment) shipmentObj = house_shipment;
      }
      shipment_document = shipmentObj.shipment_documents?.find(
        (sd) => sd.document_type === SHIPMENT_DOCUMENT_TYPE_HOUSE
      );
    } else {
      shipment_document = shipment.shipment_documents?.find(
        (sd) => sd.document_type === SHIPMENT_DOCUMENT_TYPE_MASTER
      );
    }
    const shipment_document_id = _get(shipment_document, 'id', '');
    params.shipment_document_id = shipment_document_id;
    generateParams = { ...generateParams, shipment_document };
  }
  const initialPayloadFunct = _get(DOC_TYPE_GENERATE_FUNCTION_MAPPING, document_type, () => ({}));
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const initialPayload = await initialPayloadFunct(generateParams);
  return { params, initialPayload };
};

export const fetchDocumentList = async (shipmentIds: string[]) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.DOCGEN_URL}/generate_documents`,
      params: { shipment_id: shipmentIds },
    });
    return { response: response.data.documents, error: false };
  } catch (error) {
    console.error(error);
    return { error };
  }
};

export async function fetchShipmentDocumentList(params: any) {
  try {
    const url = `${process.env.DOCGEN_URL}/shipment_documents`;
    const response = await axios({
      method: 'get',
      url,
      params,
    });
    return { response: response.data, error: false };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export const deleteDocuments = async (docIds: string[]) => {
  const promises = docIds.map((id) =>
    axios({
      method: 'delete',
      url: `${process.env.DOCGEN_URL}/generate_documents/${id}`,
    })
  );

  try {
    await Promise.all(promises);
    return { error: false };
  } catch (error) {
    console.error(error);
    return { error: true };
  }
};

export async function updateDocument(params: any) {
  try {
    const { docId, payload } = params;
    const url = `${process.env.DOCGEN_URL}/generate_documents/${docId}`;
    const response = await axios({
      method: 'put',
      url,
      data: payload,
    });
    return { error: false, response: response.data };
  } catch (err) {
    return { error: err };
  }
}
