import { types, Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree';
import Company from 'operations/models/Company';
import Address from 'operations/models/Address';
import { keyBy as _keyBy } from 'lodash';
const ShipmentParty = types
  .model({
    id: types.maybe(types.maybeNull(types.identifier)),
    name: types.maybe(types.maybeNull(types.string)),
    party_company: types.maybe(types.maybeNull(Company)),
    party_address: types.maybe(types.maybeNull(Address)),
    party_details: types.maybe(types.maybeNull(types.string)),
    party_referenceable_id: types.maybe(types.maybeNull(types.string)),
    party_referenceable_type: types.maybe(types.maybeNull(types.string)),
    shipment_id: types.maybe(types.maybeNull(types.string)),
  })
  .views((self) => ({}));

export type ShipmentPartyValue = Instance<typeof ShipmentParty>;
export type ShipmentPartyIn = SnapshotIn<typeof ShipmentParty>;
export type ShipmentPartyOut = SnapshotOut<typeof ShipmentParty>;

export const transformPartyDetails = (parties: { [key: string]: ShipmentPartyValue }) => {
  if (!parties) return [];
  return Object.keys(parties).map((partyKey) => {
    const party = parties[partyKey];
    return {
      id: party?.id,
      name: partyKey,
      party_company_id: party?.party_company?.id,
      party_address_id: party?.party_address?.id,
      party_details: party?.party_details,
    };
  });
};

export const transformUpdatePartyDetails = (
  parties: { [key: string]: ShipmentPartyValue },
  existingParties?: ShipmentPartyValue[] | null
) => {
  if (!parties) return [];
  const existingPartiesByName = _keyBy(existingParties, 'name');
  return Object.keys(parties).map((partyKey) => {
    const party = parties[partyKey];
    return {
      id: party?.id || existingPartiesByName?.[partyKey]?.id,
      name: partyKey,
      party_company_id: party?.party_company?.id || null,
      party_address_id: party?.party_address?.id || null,
      party_details: party?.party_details,
    };
  });
};

export default ShipmentParty;
