import React, { MutableRefObject, useEffect } from 'react';
import { GridOptions } from '@ag-grid-community/core';
import { Column } from 'operations/models/Report';
import { BaseTable } from '@shipmnts/pixel-hub';
import { getHeight } from './CommercialInvoiceListTable';
import { ActionRendererDetailReport } from 'operations';
import { xor } from 'lodash';

interface CustomsOrContainerListTableProps {
  data: any;
  isRowSelectable?: any;
  gridRef?: MutableRefObject<GridOptions | undefined>;
  alreadyLinked?: any;
  columns: Column[];
  setDisabled?: (visible: boolean) => void;
}

const CustomsOrContainerListTable = React.memo(function CustomsGrid(
  props: CustomsOrContainerListTableProps
): JSX.Element {
  const { gridRef, data, isRowSelectable = false, alreadyLinked, columns, setDisabled } = props;
  const refreshRowSelection = () => {
    const alreadyLinkedIds = alreadyLinked.map((al: any) => al.id);
    gridRef?.current?.api?.forEachNode((node) => {
      if (alreadyLinkedIds.includes(node.data.id)) {
        node.setSelected(true);
      } else {
        node.setSelected(false);
      }
    });
  };

  // NOTE: Need to refresh row selection as the grid does not refresh automatically
  useEffect(() => {
    refreshRowSelection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alreadyLinked]);

  return (
    <>
      <BaseTable
        reportName="customs_or_containers_link_on_invoice"
        columns={columns}
        rowData={data}
        gridRef={gridRef}
        rowSelection={isRowSelectable}
        reportConfig={{
          rowHeight: 40,
          components: { ActionRendererDetailReport },
          onCellValueChanged: (params: any) => {
            setDisabled && setDisabled(false);
          },
        }}
        onGridReady={(params: any) => {
          refreshRowSelection();
        }}
        onSelectionChanged={(params: any) => {
          const selectedIds = gridRef?.current?.api
            ?.getSelectedRows()
            .map((container: any) => container?.id);
          const alreadyLinkedIds = alreadyLinked.map((container: any) => container.id);
          const isNewAdded = xor(selectedIds, alreadyLinkedIds).length > 0;
          setDisabled && setDisabled(!isNewAdded);
        }}
        height={getHeight(data) || '200px'}
      />
    </>
  );
});

export default CustomsOrContainerListTable;
