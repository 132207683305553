// This is entry file to the SALES HUB APP
import React, { lazy } from 'react';
import { Route, Switch } from 'wouter';
import { hasPermission } from '@shipmnts/pixel-hub';
import { useSession } from 'common';
import { DOCUMENT_PERMISSION_VIEW } from 'sales_hub/utils/constants';

import CreateInquiryDrawer from './components/Inquiry/CreateInquiryDrawer';
const InquiryDetailLayout = lazy(
  () => import('./components/Inquiry/InquiryDetail/InquiryDetailLayout')
);
const InquiryOptionDetailLayout = lazy(
  () => import('./components/InquiryOption/InquiryOptionDetailLayout')
);

function SalesHubViewLayout() {
  const sessionData = useSession();
  if (
    !hasPermission(sessionData?.permissions, {
      name: DOCUMENT_PERMISSION_VIEW,
      docType: 'SalesHub::Inquiry',
    })
  )
    return (
      <Switch>
        <Route path="/rfq/:id">
          <div>
            <p>User has no permission to access Sales Hub module. </p>
          </div>
        </Route>

        <Route path="/rfq-option/:id">
          <div>
            <p>User has no permission to access Sales Hub module. </p>
          </div>
        </Route>
      </Switch>
    );

  return (
    <Switch>
      <Route path="/create/rfq">
        <CreateInquiryDrawer
          data={{ business_vertical_id: 1 }}
          setVisible={() => console.log('hi')}
          visible={true}
        />
      </Route>

      <Route path="/rfq/:id">
        <InquiryDetailLayout />
      </Route>

      <Route path="/rfq-option/:id">
        <InquiryOptionDetailLayout />
      </Route>
    </Switch>
  );
}

export default SalesHubViewLayout;
