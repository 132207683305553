import React, { useEffect, useState } from 'react';
import { message, Modal } from '@shipmnts/pixel-hub';
import { get as _get } from 'lodash';
import { useSession } from 'common';
import {
  createDocument,
  duplicateDocument,
  fetchTemplates,
  getGenerateDocumentPayload,
} from './document';
import { ApolloClient, useApolloClient } from '@apollo/client';
import AllocateJobNumber from '../AirBookingReports/AllocateJobNumber';
import {
  DOCUMENT_TYPE_MASTER,
  DOCUMENT_TYPE_HOUSE,
  FreightType,
  TradeType,
} from 'operations/modules/shipment/constants';
import { ShipmentDocumentValue } from 'operations/models/ShipmentDocument';
import { ShipmentValue } from 'operations/models/Shipment';
import DuplicateAwbModal from './DuplicateAwbModal';
import { DOCUMENT_MAWB, DOCUMENT_MBL } from 'operations/modules/actionHelper/constants';
import { DOCUMENT_TYPE_HBL, DOCUMENT_TYPE_NEW_HAWB, DOCUMENT_TYPE_NEW_MAWB } from '../../constants';
import { NewDocumentToDocument } from 'operations/modules/actionHelper/ShipmentDocumentActions/shipmentDocumentActionHelper';
export const STOCK_MANAGEMENT_FEATURE = 'stock_management';

interface CreateDocumentProps {
  shipment: ShipmentValue;
  docType: string;
  onClose: () => void;
}

export const duplicateAwbDoc = async (
  selectedShipment: ShipmentValue,
  shipment: ShipmentValue,
  new_doc_type: string,
  user: any,
  client: ApolloClient<object>,
  setLoading?: (loading: boolean) => void
) => {
  const docType = NewDocumentToDocument[new_doc_type];
  const { templates, error } = await fetchTemplates(
    shipment?.freight_type as FreightType,
    shipment?.trade_type as TradeType
  );
  if (templates) {
    const template = templates[new_doc_type];
    let shipment_document;
    setLoading && setLoading(true);
    if (docType === DOCUMENT_MAWB || docType === DOCUMENT_MBL) {
      shipment_document = _get(shipment, 'shipment_documents', []).find(
        (doc: ShipmentDocumentValue) => doc.document_type === DOCUMENT_TYPE_MASTER
      );
    } else {
      shipment_document = _get(shipment, 'shipment_documents', []).find(
        (doc: ShipmentDocumentValue) => doc.document_type === DOCUMENT_TYPE_HOUSE
      );
    }

    let selectedShipmentDocument;
    if (docType === DOCUMENT_MAWB || docType === DOCUMENT_MBL) {
      selectedShipmentDocument = _get(selectedShipment, 'shipment_documents', []).find(
        (doc: ShipmentDocumentValue) => doc.document_type === DOCUMENT_TYPE_MASTER
      );
    } else {
      selectedShipmentDocument = _get(selectedShipment, 'shipment_documents', []).find(
        (doc: ShipmentDocumentValue) => doc.document_type === DOCUMENT_TYPE_HOUSE
      );
    }
    const { params, initialPayload } = await getGenerateDocumentPayload({
      shipment,
      house_shipments: [],
      shipment_id: shipment?.id,
      shipment_type: shipment?.shipment_type,
      shipment_document_id: _get(shipment_document, 'id'),
      document_type: new_doc_type,
      template,
      user,
      client,
      parentDocIds: [],
      document_id: selectedShipmentDocument?.document_id || undefined,
    });
    let duplicate_fields = template?.duplicate_fields;
    ['shipper', 'consignee', 'origin_agent', 'destination_agent'].forEach((party_type) => {
      if (
        (shipment.shipment_parties || []).find((sp) => sp.name === party_type)?.party_company
          ?.id !==
        (selectedShipment.shipment_parties || []).find((sp) => sp.name === party_type)
          ?.party_company?.id
      ) {
        duplicate_fields = (duplicate_fields || []).filter(
          (key: string) => key !== `${party_type}_print_address`
        );
      }
    });
    const { response: createDocumentResponse, error: createDocumentError } =
      await duplicateDocument({
        ...params,
        values: initialPayload,
        duplicate_fields,
      });
    setLoading && setLoading(false);
    if (createDocumentResponse) {
      const { generate_document } = createDocumentResponse.data;
      window.open(
        `${process.env.SHIPMNTS_WEB_URL}/shipment/${generate_document.shipment_id}/documents_latest/${generate_document.id}`,
        '_self'
      );
    } else if (createDocumentError) {
      if (createDocumentError instanceof Error) message.error(createDocumentError.message);
    }
  } else if (error) {
    if (error instanceof Error) message.error(error.message);
  }
};

export const generateBlankDoc = async (
  shipment: ShipmentValue,
  new_doc_type: string,
  user: any,
  client: ApolloClient<object>,
  setLoading?: (loading: boolean) => void
) => {
  const docType = NewDocumentToDocument[new_doc_type];
  const { templates, error } = await fetchTemplates(
    shipment?.freight_type as FreightType,
    shipment?.trade_type as TradeType
  );
  if (templates) {
    const template = templates[new_doc_type];
    setLoading && setLoading(true);
    let shipment_document;
    if (docType === DOCUMENT_MAWB || docType === DOCUMENT_MBL) {
      shipment_document = _get(shipment, 'shipment_documents', []).find(
        (doc: ShipmentDocumentValue) => doc.document_type === DOCUMENT_TYPE_MASTER
      );
    } else {
      shipment_document = _get(shipment, 'shipment_documents', []).find(
        (doc: ShipmentDocumentValue) => doc.document_type === DOCUMENT_TYPE_HOUSE
      );
    }
    const { params, initialPayload } = await getGenerateDocumentPayload({
      shipment,
      house_shipments: [],
      shipment_id: shipment?.id,
      shipment_type: shipment?.shipment_type,
      shipment_document_id: _get(shipment_document, 'id'),
      document_type: new_doc_type,
      template,
      user,
      client,
      parentDocIds: [],
    });
    const { response: createDocumentResponse, error: createDocumentError } = await createDocument({
      ...params,
      values: initialPayload,
    });
    setLoading && setLoading(false);
    if (createDocumentResponse) {
      const { generate_document } = createDocumentResponse.data;
      window.open(
        `${process.env.SHIPMNTS_WEB_URL}/shipment/${generate_document.shipment_id}/documents_latest/${generate_document.id}`,
        '_self'
      );
    } else if (createDocumentError) {
      if (createDocumentError instanceof Error) message.error(createDocumentError.message);
    }
  } else if (error) {
    if (error instanceof Error) message.error(error.message);
  }
};

export default function CreateDocument(props: CreateDocumentProps) {
  const { docType, shipment, onClose } = props;
  const [allocateJobNumber, setAllocateJobNumber] = useState<boolean>(false);
  const [duplicateAwbVisible, setDuplicateAwbVisible] = useState<boolean>(false);
  const [onSuccess, setOnSuccess] = useState<boolean>(false);
  const user = useSession();
  const client = useApolloClient();

  const createErrorModal = (message: string, extraProps: any) => {
    const config = {
      title: 'Create Document Error',
      content: message,
      ...extraProps,
    };
    Modal.error(config);
  };

  useEffect(() => {
    if (onSuccess) {
      checkForDuplicateAwb();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSuccess]);

  const checkForDuplicateAwb = () => {
    const creatingDocType = docType;
    if (
      user.isFeatureEnabled(STOCK_MANAGEMENT_FEATURE) &&
      creatingDocType === DOCUMENT_TYPE_NEW_MAWB &&
      !shipment.isStockAllocated()
    ) {
      createErrorModal('Please create stock order before creating AWB', {
        okText: 'Create Stock Order',
        onOk: () => {
          window.open(`/workspace?doc_type=Shipment::StockOrderItem`, '_blank');
        },
        cancelText: 'Cancel',
      });
      return;
    }
    if (
      [DOCUMENT_TYPE_NEW_MAWB, DOCUMENT_TYPE_NEW_HAWB, DOCUMENT_TYPE_HBL].includes(
        creatingDocType
      ) &&
      !shipment.isForwardingShipment() &&
      !shipment.isRoadShipment()
    ) {
      createErrorModal('Please select freight forwarding in the services section', {
        onOk: () => {
          window.open(`${process.env.SHIPMNTS_WEB_URL}/shipment/${shipment.id}/edit`, '_blank');
        },
      });
      return;
    }
    if (creatingDocType === DOCUMENT_TYPE_NEW_MAWB || creatingDocType === DOCUMENT_TYPE_NEW_HAWB) {
      setDuplicateAwbVisible(true);
      return;
    }
    generateBlankDoc(shipment, docType, user, client);
  };

  useEffect(() => {
    if (docType && NewDocumentToDocument[docType] === DOCUMENT_MAWB) {
      if (!shipment?.job_number) {
        setAllocateJobNumber(true);
        return;
      }
    }
    checkForDuplicateAwb();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docType, shipment]);

  const onCloseModal = () => {
    setAllocateJobNumber(false);
    onClose();
  };

  return (
    <>
      {allocateJobNumber && (
        <AllocateJobNumber
          setOnSuccess={setOnSuccess}
          onClose={onCloseModal}
          booking_id={shipment.id}
        />
      )}
      {duplicateAwbVisible && (
        <DuplicateAwbModal onClose={onClose} docType={docType} shipment={shipment} />
      )}
    </>
  );
}
