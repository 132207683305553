import { gql } from '@apollo/client';
import { orderCollaborationFields } from './orderCollaboration';
import { shipmentContainerFields } from './shipmentContainer';
import { routingLegFields } from './routingLeg';
import { cargoFields } from './cargo';
import { stuffingBufferServiceOrderFields } from './stuffingBufferServiceOrder';

export const roadTransportOrderFields = gql`
  fragment roadTransportOrderFields on RoadTransportOrderObjectType {
    id
    last_free_date_at_factory
    factory_location {
      id
      name
      print_address
      company_id
    }
    type
    load_type
    status
    order_collaborations {
      ...orderCollaborationFields
    }
    shipment_containers {
      ...shipmentContainerFields
    }
    routing_legs {
      ...routingLegFields
    }
    cargos {
      ...cargoFields
    }
    email_activities {
      id
      purpose
    }
  }
  ${orderCollaborationFields}
  ${shipmentContainerFields}
  ${routingLegFields}
  ${cargoFields}
`;

export const CREATE_ROAD_TRANSPORT_ORDER = gql`
  mutation create_road_transport_order(
    $road_transport_order: RoadTransportOrderInputType!
    $booking_request_id: ID!
  ) {
    create_road_transport_order(
      road_transport_order: $road_transport_order
      booking_request_id: $booking_request_id
    ) {
      ...roadTransportOrderFields
    }
  }
  ${roadTransportOrderFields}
`;

export const UPDATE_ROAD_TRANSPORT_ORDER = gql`
  mutation update_road_transport_order($road_transport_order: UpdateRoadTransportOrderInputType!) {
    update_road_transport_order(road_transport_order: $road_transport_order) {
      ...roadTransportOrderFields
    }
  }
  ${roadTransportOrderFields}
`;

export const DELETE_ROAD_TRANSPORT_ORDER = gql`
  mutation delete_road_transport_order($id: ID!) {
    delete_road_transport_order(id: $id) {
      message
    }
  }
`;

export const UPDATE_CARGO_PICKED_UP = gql`
  mutation update_cargo_picked_up(
    $rto_id: ID!
    $driver_contact: String
    $voyage_number: String
    $actual_time_of_departure: Time!
  ) {
    update_cargo_picked_up(
      rto_id: $rto_id
      driver_contact: $driver_contact
      voyage_number: $voyage_number
      actual_time_of_departure: $actual_time_of_departure
    ) {
      road_transport_order {
        ...roadTransportOrderFields
      }
    }
  }
  ${roadTransportOrderFields}
`;

export const UPDATE_CARGO_CARTED_IN = gql`
  mutation update_cargo_carted_in($br_id: ID!, $rto_id: ID, $actual_time_of_arrival: Time!) {
    update_cargo_carted_in(
      br_id: $br_id
      rto_id: $rto_id
      actual_time_of_arrival: $actual_time_of_arrival
    ) {
      road_transport_order {
        ...roadTransportOrderFields
      }
      stuffing_buffer_service_order {
        ...stuffingBufferServiceOrderFields
      }
    }
  }
  ${roadTransportOrderFields}
  ${stuffingBufferServiceOrderFields}
`;
