import { dayjs } from '@shipmnts/pixel-hub';
import { convertToDayJs } from '@shipmnts/pixel-hub';
import { ShipmentValue } from 'operations/models/Shipment';
import {
  filter as _filter,
  keyBy as _keyBy,
  omit as _omit,
  pick as _pick,
  groupBy as _groupBy,
  startCase,
} from 'lodash';
import {
  BUSINESS_TYPE_DIRECT,
  CARGO_TYPE_CONTAINER,
  DUTY_FREE,
  FREIGHT_TYPE_OCEAN,
  HOME_BILL,
  MOVEMENT_MODE_DOOR_TO_DOOR,
  RELEASE_TYPE_SOB,
  SERVICE_TYPE_PORT_TO_PORT,
  SHIPMENT_TYPE_DIRECT,
  TRADE_TYPE_EXPORT,
  TRADE_TYPE_IMPORT,
} from '../../constants';
import {
  LOAD_TYPE_FCL,
  STUFFING_LOCATION_TYPE_CFS,
  STUFFING_LOCATION_TYPE_FACTORY,
  STUFFING_LOCATION_TYPE_ICD,
  VOLUME_UNIT_CBM,
  WEIGHT_UNIT_KGS,
} from 'operations/baseConstants';
import { getRoutingDetails } from '../ShipmentForm/helpers';
import { MODE_OF_TRANSIT_RAIL, RoutingLegValue } from 'operations/models/RoutingLeg';
import { RoutingDetailsValue, RoutingNodesHashValue } from 'operations/components/RoutingDetails';
import { InquiryOptionValue } from 'operations/models/InquiryOption';
import { CompanyValue } from 'operations/models/Company';
import { convertNumber } from 'sales_hub/utils/dimensionHelper';
import { FREIGHT_TYPE_AIR } from 'operations/utils/constants';
import {
  LOCATION_TAG_POD,
  LOCATION_TAG_POL,
  RATE_CLASS_QUANTITY,
} from 'operations/modules/reports/constants';
import { InquiryValue } from 'operations/models/Inquiry';
import { SessionDataValue } from 'operations/models/SessionData';
import { getNewRoutingNode } from 'operations/components/RoutingDetails';
import { RoutingNodeTag, RoutingNodeValue } from 'operations/models/RoutingNode';
import { OceanTransportOrderValue } from 'operations/models/OceanTransportOrder';
const getRoutingDetailsFormValue = (
  routingLegs: RoutingLegValue[],
  noCopyLeg?: boolean
): RoutingDetailsValue => {
  const routing_nodes: RoutingNodesHashValue = {};
  let routing_legs = routingLegs ? routingLegs : [];
  routing_legs = routing_legs.map((rl) => {
    if (rl?.origin?.id)
      routing_nodes[rl.origin.id] = { ...rl.origin, _id: rl.origin.id, id: undefined };
    if (rl?.destination?.id)
      routing_nodes[rl.destination.id] = {
        ...rl.destination,
        _id: rl.destination.id,
        id: undefined,
      };
    return {
      ...{ ...rl, _id: rl.id, id: undefined },
      origin: undefined,
      destination: undefined,
      origin_id: rl?.origin?.id,
      destination_id: rl?.destination?.id,
    };
  });
  const omitFields = [
    'estimated_time_of_arrival',
    'actual_time_of_arrival',
    'estimated_time_of_departure',
    'actual_time_of_departure',
    'vessel',
    'voyage_number',
    'wagon_number',
  ];
  return {
    routing_legs: noCopyLeg
      ? (routing_legs.map((rl) => _omit<RoutingLegValue>(rl, omitFields)) as RoutingLegValue[])
      : routing_legs,
    routing_nodes: routing_nodes,
  };
};

const getDefaultInitialValue = (extraValue: any, sessionData: SessionDataValue | undefined) => {
  const is_clearance_shipment = extraValue?.clearance_shipment;
  const is_freight_forwarding_shipment = extraValue?.freight_forwarding_shipment;
  return {
    business_type: BUSINESS_TYPE_DIRECT,
    movement_mode: MOVEMENT_MODE_DOOR_TO_DOOR,
    load_type: LOAD_TYPE_FCL,
    cargo_type: CARGO_TYPE_CONTAINER,
    freight_type: FREIGHT_TYPE_OCEAN,
    trade_type: TRADE_TYPE_EXPORT,
    shipment_type: SHIPMENT_TYPE_DIRECT,
    shipment_containers: [{}, {}],
    cargos: [
      {
        package_type: 'Box',
        weight_unit: WEIGHT_UNIT_KGS,
        volume_unit: VOLUME_UNIT_CBM,
      },
    ],
    services: {
      freight_forwarding: is_freight_forwarding_shipment ? true : false,
      cargo_insurance: false,
      empty_container_insurance: false,
      origin: {
        clearance:
          extraValue?.trade_type === TRADE_TYPE_EXPORT && is_clearance_shipment ? true : false,
        transport: false,
        fumigation: false,
        palletization: false,
      },
      destination: {
        clearance:
          extraValue?.trade_type !== TRADE_TYPE_EXPORT && is_clearance_shipment ? true : false,
        transport: false,
      },
    },
  };
};

const getDefaultParties = (extraValue: any, sessionData: SessionDataValue | undefined) => {
  const is_clearance_shipment = extraValue?.clearance_shipment;
  const is_freight_forwarding_shipment = extraValue?.freight_forwarding_shipment;
  return {
    origin_clearance_agent: {
      party_company:
        is_clearance_shipment && extraValue?.trade_type === TRADE_TYPE_EXPORT
          ? sessionData?.company_account?.default_company
          : undefined,
    },
    destination_clearance_agent: {
      party_company:
        is_clearance_shipment && extraValue?.trade_type !== TRADE_TYPE_EXPORT
          ? sessionData?.company_account?.default_company
          : undefined,
    },
    freight_forwarding_agent: {
      party_company: is_freight_forwarding_shipment
        ? sessionData?.company_account?.default_company
        : undefined,
    },
    origin_agent: {
      party_company:
        extraValue?.trade_type === TRADE_TYPE_EXPORT
          ? sessionData?.company_account?.default_company
          : undefined,
    },
    destination_agent: {
      party_company:
        extraValue?.trade_type === TRADE_TYPE_IMPORT
          ? sessionData?.company_account?.default_company
          : undefined,
    },
  };
};

export function getRoutingValues(
  shipment?: ShipmentValue | undefined | null,
  oto?: OceanTransportOrderValue | undefined | null
) {
  const routing_legs = shipment?.routing_legs || oto?.routing_legs;
  if (!routing_legs) return {};
  const stuffing_node = routing_legs.find((leg) => leg.routing_type === 'pickup')?.origin;
  const destuffing_node = routing_legs.find((leg) => leg.routing_type === 'delivery')?.destination;
  const destination_cfs = routing_legs.find(
    (leg) => leg.routing_type === 'pod_buffer'
  )?.destination;
  const origin_cfs = routing_legs.find((leg) => leg.routing_type === 'pol_buffer')?.origin;
  const routing_values: any = {};
  if (origin_cfs) {
    routing_values['pol_buffer_location'] = origin_cfs.location;
  }
  if (destination_cfs) {
    routing_values['pod_buffer_location'] = destination_cfs.location;
  }
  if (stuffing_node) {
    let stuffing_type = undefined;
    if (stuffing_node?.location?.type === 'CFS') {
      stuffing_type = STUFFING_LOCATION_TYPE_CFS;
    } else if (stuffing_node?.location?.type === 'ICD') {
      stuffing_type = STUFFING_LOCATION_TYPE_ICD;
    } else if (stuffing_node?.address) {
      stuffing_type = STUFFING_LOCATION_TYPE_FACTORY;
    }
    routing_values['stuffing_type'] = stuffing_type;
    if (stuffing_node?.address) {
      routing_values['stuffing_location'] = {
        party_address: stuffing_node?.address,
        party_company: stuffing_node?.company,
      };
    } else {
      routing_values['stuffing_location'] = stuffing_node?.location;
    }
  }
  if (destuffing_node) {
    let destuffing_type = undefined;
    if (destuffing_node?.location?.type === 'CFS') {
      destuffing_type = STUFFING_LOCATION_TYPE_CFS;
    } else if (destuffing_node?.location?.type === 'ICD') {
      destuffing_type = STUFFING_LOCATION_TYPE_ICD;
    } else if (destuffing_node?.address) {
      destuffing_type = STUFFING_LOCATION_TYPE_FACTORY;
    }
    routing_values['destuffing_type'] = destuffing_type;
    if (destuffing_node?.address) {
      routing_values['destuffing_location'] = {
        party_address: destuffing_node?.address,
        party_company: destuffing_node?.company,
      };
    } else {
      routing_values['destuffing_location'] = destuffing_node?.location;
    }
  }
  return routing_values;
}

const getGenericInitialValue = (
  shipment: ShipmentValue | undefined | null,
  extraValue?: any,
  sessionData?: SessionDataValue | undefined
) => {
  const buyer_id = shipment?.shipment_parties?.find((party) => party.name === 'buyer')
    ?.party_company?.id;
  const seller_id = shipment?.shipment_parties?.find((party) => party.name === 'seller')
    ?.party_company?.id;
  const shipper_id = shipment?.shipment_parties?.find((party) => party.name === 'shipper')
    ?.party_company?.id;
  const consignee_id = shipment?.shipment_parties?.find((party) => party.name === 'consignee')
    ?.party_company?.id;
  const customer_id = shipment?.customer_company?.id;
  const job_date =
    new Date(shipment?.job_date || '').getTime() / 1000 || dayjs.unix(Date.now() / 1000);
  const default_master_document_values = {
    shipment_document: {
      release_type: RELEASE_TYPE_SOB,
      charge_terms: {
        freight_terms: 'prepaid',
        other_charges_terms: 'prepaid',
      },
      document_status:
        extraValue?.trade_type === TRADE_TYPE_EXPORT ? 'pending_creation' : 'draft_received',
      bl_type: 'original',
    },
  };
  const default_house_document_values = {
    shipment_document: {
      release_type: RELEASE_TYPE_SOB,
      charge_terms: {
        freight_terms: 'prepaid',
        other_charges_terms: 'prepaid',
      },
      consignee_on_document: 'consignee',
      document_status:
        extraValue?.trade_type === TRADE_TYPE_EXPORT ? 'pending_creation' : 'draft_received',
      bl_type: 'original',
    },
  };
  const initialValue = {
    ...getDefaultInitialValue(extraValue, sessionData),
    ...shipment,
    ...getRoutingValues(shipment),
    job_date: convertToDayJs(job_date),
    buyer_same_as_consignee: buyer_id && consignee_id && consignee_id === buyer_id ? true : false,
    seller_same_as_shipper: seller_id && shipper_id && shipper_id === seller_id ? true : false,
    shipper_same_as_customer:
      shipper_id && customer_id && shipper_id === customer_id ? true : false,
    consignee_same_as_customer:
      consignee_id && customer_id && consignee_id === customer_id ? true : false,
    priority: shipment ? shipment?.priority : 'Medium',
    customer: shipment?.customer_company
      ? {
          party_company: shipment?.customer_company,
          party_address: shipment?.customer_address,
        }
      : undefined,
    valid_till_date: dayjs().add(3, 'day'),
    involved_branch_id: shipment?.involved_branch?.id,
    vessel: shipment?.ocean_vessel,
    lfd_at_carrier: convertToDayJs(shipment?.lfd_at_carrier),
    lfd_at_empty_return: convertToDayJs(shipment?.lfd_at_empty_return),
    lfd_at_pocd: convertToDayJs(shipment?.lfd_at_pocd),
    lfd_at_pod: convertToDayJs(shipment?.lfd_at_pod),
    shipping_bill_details: _filter(shipment?.shipment_custom_details || [], {
      trade_type: 'export',
    }).map((sb: any) => ({
      id: sb.id,
      document_number: sb.custom_document_number,
      document_date: convertToDayJs(sb.custom_document_date),
      country: sb.country || sb.custom_clearance_location?.country_code,
      custom_clearance_location: sb.custom_clearance_location,
      trade_type: sb.trade_type,
    })),
    import_custom_details: _filter(shipment?.shipment_custom_details || [], {
      trade_type: 'import',
    }).map((sb: any) => ({
      id: sb.id,
      document_number: sb.custom_document_number,
      document_date: convertToDayJs(sb.custom_document_date),
      country: sb.country || sb.custom_clearance_location?.country_code,
      custom_clearance_location: sb.custom_clearance_location,
      trade_type: sb.trade_type,
    })),
    shipment_invoices: (shipment?.shipment_invoices || []).map((si) => ({
      id: si.id,
      document_number: si.invoice_number,
      document_date: convertToDayJs(si.invoice_date),
    })),
    party: shipment?.shipment_parties
      ? {
          ..._keyBy(shipment?.shipment_parties, 'name'),
        }
      : getDefaultParties(extraValue, sessionData),
    ...extraValue,
  };
  if (shipment) {
    initialValue['routing_details'] = getRoutingDetails(shipment);
  }
  if (!shipment?.ocean_transport_orders) {
    initialValue['booking_date'] =
      new Date(shipment?.job_date || '').getTime() / 1000 || dayjs.unix(Date.now() / 1000);
    initialValue['service_type'] = SERVICE_TYPE_PORT_TO_PORT;
  }
  if (shipment?.shipment_documents && shipment?.shipment_documents[0]) {
    initialValue['master'] = {
      shipment_document: {
        ...(shipment?.shipment_documents && shipment?.shipment_documents[0]),
        shipment_number:
          shipment?.shipment_documents &&
          (shipment.freight_type === 'air'
            ? shipment?.shipment_documents[0]?.shipment_number?.slice(3)
            : shipment?.shipment_documents[0]?.shipment_number),
        shipment_date:
          shipment?.shipment_documents &&
          convertToDayJs(shipment?.shipment_documents[0]?.shipment_date),
        id: shipment?.shipment_documents && shipment?.shipment_documents[0]?.id,
        bl_type:
          shipment?.shipment_documents && shipment?.shipment_documents[0]?.bl_type
            ? shipment?.shipment_documents[0]?.bl_type
            : 'original',
      },
    };
  } else {
    initialValue['master'] = default_master_document_values;
  }
  if (shipment?.shipment_documents && shipment?.shipment_documents[1]) {
    initialValue['house'] = {
      shipment_document: {
        ...(shipment?.shipment_documents && shipment?.shipment_documents[1]),
        shipment_number:
          shipment?.shipment_documents &&
          (shipment.freight_type === 'air'
            ? shipment?.shipment_documents[1]?.shipment_number?.slice(3)
            : shipment?.shipment_documents[1]?.shipment_number),
        shipment_date:
          shipment?.shipment_documents &&
          convertToDayJs(shipment?.shipment_documents[1]?.shipment_date),
        id: shipment?.shipment_documents && shipment?.shipment_documents[1]?.id,
        bl_type:
          shipment?.shipment_documents && shipment?.shipment_documents[1]?.bl_type
            ? shipment?.shipment_documents[1]?.bl_type
            : 'original',
      },
    };
  } else {
    initialValue['house'] = default_house_document_values;
  }
  return initialValue;
};

export const getGenericInitialValueForDuplicate = (
  shipment: any,
  extraValue?: any,
  sessionData?: SessionDataValue | undefined
) => {
  const job_date = new Date().getTime() / 1000;

  const containerMap: any = _groupBy(shipment?.shipment_containers, 'container_type_code');
  const shipmentContainerQuantity: any[] = [];
  Object.keys(containerMap).forEach((key) => {
    shipmentContainerQuantity.push({
      container_type_code: containerMap[key][0].container_type_code,
      container_type: containerMap[key][0].container_type,
      quantity: containerMap[key].length,
      container_settings: containerMap[key][0].container_settings,
    });
  });
  const initialValue: any = {
    ...getDefaultInitialValue(extraValue, sessionData),
    ...getRoutingValues(shipment),
    ...extraValue,
    customer: shipment?.customer_company
      ? {
          party_company: shipment?.customer_company,
          party_address: shipment?.customer_address,
        }
      : undefined,
    load_type: shipment?.load_type,
    valid_till_date: dayjs().add(3, 'day'),
    job_date: convertToDayJs(job_date) || dayjs(),
    business_type: shipment?.business_type || BUSINESS_TYPE_DIRECT,
    involved_branch_id: shipment?.involved_branch?.id,
    involved_branch: shipment?.involved_branch,
    sales_agent: shipment?.sales_agent,
    incoterms: shipment?.incoterms,
    lfd_at_pod: convertToDayJs(shipment?.lfd_at_pod),
    lfd_at_carrier: convertToDayJs(shipment?.lfd_at_carrier),
    lfd_at_empty_return: convertToDayJs(shipment?.lfd_at_empty_return),
    lfd_at_pocd: convertToDayJs(shipment?.lfd_at_pocd),
    cargos: (shipment?.cargos || []).map(({ id, ...cargo }: any) => ({
      ...cargo,
      shipment_packages: cargo.shipment_packages?.map((sp: any) => {
        return _pick(sp, [
          'dimension_unit',
          'gross_volume',
          'height',
          'length',
          'total_number_of_packages',
          'per_piece_weight',
          'type_of_package',
          'width',
        ]);
      }),
    })) || [
      {
        package_type: 'Box',
        weight_unit: WEIGHT_UNIT_KGS,
        volume_unit: VOLUME_UNIT_CBM,
      },
    ],
    master: {
      shipment_document: {
        release_type: RELEASE_TYPE_SOB,
        charge_terms: {
          freight_terms: 'prepaid',
          other_charges_terms: 'prepaid',
        },
        consignee_on_document: 'consignee',
        document_status:
          shipment?.trade_type === TRADE_TYPE_EXPORT ? 'pending_creation' : 'draft_received',
        bl_type: 'original',
        shipment_date:
          shipment?.shipment_documents &&
          convertToDayJs(shipment?.shipment_documents[0]?.shipment_date),
      },
    },
    house: {
      shipment_document: {
        release_type: RELEASE_TYPE_SOB,
        charge_terms: {
          freight_terms: 'prepaid',
          other_charges_terms: 'prepaid',
        },
        consignee_on_document: 'consignee',
        document_status:
          shipment?.trade_type === TRADE_TYPE_EXPORT ? 'pending_creation' : 'draft_received',
        bl_type: 'original',
        shipment_date:
          shipment?.shipment_documents &&
          convertToDayJs(shipment?.shipment_documents[1]?.shipment_date),
      },
    },
    shipping_bill_details: _filter(shipment?.shipment_custom_details || [], {
      trade_type: 'export',
    }).map((sb: any) => ({
      document_number: sb.custom_document_number,
      document_date: convertToDayJs(sb.custom_document_date),
      country: sb.country || sb.custom_clearance_location?.country_code,
      custom_clearance_location: sb.custom_clearance_location,
      trade_type: sb.trade_type,
    })),
    import_custom_details: _filter(shipment?.shipment_custom_details || [], {
      trade_type: 'import',
    }).map((sb: any) => ({
      document_number: sb.custom_document_number,
      document_date: convertToDayJs(sb.custom_document_date),
      country: sb.country || sb.custom_clearance_location?.country_code,
      custom_clearance_location: sb.custom_clearance_location,
      trade_type: sb.trade_type,
    })),
    shipment_invoices: (shipment?.shipment_invoices || []).map((si: any) => ({
      document_number: si.invoice_number,
      document_date: convertToDayJs(si.invoice_date),
    })),
    shipment_container_quantity: shipment?.shipment_container_quantity
      ? shipment?.shipment_container_quantity
      : shipmentContainerQuantity,
    stuffing_location: shipment?.stuffing_location
      ? shipment?.stuffing_location?.port
        ? shipment?.stuffing_location?.port
        : {
            party_company: shipment?.stuffing_location?.company,
            party_address: shipment?.stuffing_location?.company_address,
          }
      : null,
    destuffing_location: shipment?.destuffing_location
      ? shipment?.destuffing_location?.port
        ? shipment?.destuffing_location?.port
        : {
            party_company: shipment?.destuffing_location?.company,
            party_address: shipment?.destuffing_location?.company_address,
          }
      : null,
    party: shipment?.shipment_parties
      ? {
          ..._keyBy(
            shipment?.shipment_parties.map((sp: any) => _omit(sp, ['id'])),
            'name'
          ),
        }
      : getDefaultParties(extraValue, sessionData),
    services: shipment?.services,
  };
  if (shipment?.routing_legs) {
    const routingDetails = getRoutingDetailsFormValue(shipment?.routing_legs);
    initialValue['routing_details'] = {
      routing_nodes: routingDetails?.routing_nodes,
      routing_legs: (routingDetails?.routing_legs || []).map((rl) => _omit(rl, 'id')),
    };
  }
  return initialValue;
};

export const getInitialValue = (
  shipment: ShipmentValue | undefined,
  extraValue: any,
  isDup: boolean,
  inquiryOption?: InquiryOptionValue,
  sessionData?: SessionDataValue
) => {
  if (inquiryOption) {
    return getIntialValuesFromInquiryOption(
      inquiryOption,
      extraValue?.shipment_type,
      sessionData?.company_account?.default_company
    );
  }
  if (isDup) {
    return getGenericInitialValueForDuplicate(shipment, extraValue);
  }
  return getGenericInitialValue(shipment, extraValue, sessionData);
};

export const getIntialValuesFromInquiryOption = (
  inquiryOption: InquiryOptionValue,
  shipmentType: string,
  default_company: CompanyValue
) => {
  const inquiry = inquiryOption.inquiry;
  const default_master_document_values = {
    shipment_document: {
      release_type: RELEASE_TYPE_SOB,
      charge_terms: {
        freight_terms: 'prepaid',
        other_charges_terms: 'prepaid',
      },
      document_status: inquiry?.trade_type ? 'pending_creation' : 'draft_received',
      bl_type: 'original',
    },
  };
  const default_house_document_values = {
    shipment_document: {
      release_type: RELEASE_TYPE_SOB,
      charge_terms: {
        freight_terms: 'prepaid',
        other_charges_terms: 'prepaid',
      },
      consignee_on_document: 'consignee',
      document_status: inquiry?.trade_type ? 'pending_creation' : 'draft_received',
      bl_type: 'original',
    },
  };
  const fulfilled_container_map = JSON.parse(inquiry?.fulfilled_container_map);
  const inquiryValues: any = _pick(inquiry, [
    'freight_type',
    'trade_type',
    'load_type',
    'involved_branch',
    'incoterms',
    'customer_company',
    'customer_address',
    'billing_party',
    'billing_party_address',
    'business_received_through',
    'services',
    'origin_custom_clearance_location',
    'destination_custom_clearance_location',
    'freight_terms',
    'other_terms',
    'container_requests',
    'business_type',
    'gross_weight',
    'gross_volume',
    'shipment_documents',
    'total_number_of_packages',
    'shipment_routing_details',
    'cargos',
    'priority',
  ]);
  const inquiryOptionValues: any = _pick(inquiryOption, [
    'ocean_vessel',
    'voyage_number',
    'carrier',
    'chargeable_weight',
    'tariff_rate',
    'carrier_product',
    'destination_carrier_free_days',
    'destination_port_free_days',
    'origin_carrier_free_days',
    'lfd_at_carrier',
    'lfd_at_empty_return',
    'lfd_at_pocd',
    'lfd_at_pod',
    'shipment_routing_details',
    'shipment_product_ratings',
  ]);
  inquiryValues.business_type = inquiryValues.business_received_through;
  if (inquiryValues?.customer_address?.entity_type !== 'billing') {
    inquiryValues.customer_address = null;
  }
  inquiryValues['party'] = {};
  inquiryValues['priority'] = startCase(inquiryOption?.inquiry?.priority);

  if (inquiryValues.business_received_through !== 'direct') {
    const business_received_through_party_name =
      inquiryValues.business_received_through === 'agent_nomination'
        ? 'overseas_agent'
        : inquiryValues.business_received_through;
    inquiryValues['party'][`${business_received_through_party_name}`] = {
      party_company: inquiryValues.billing_party,
      party_address: inquiryValues.billing_party_address,
    };
  }

  if (inquiryValues?.services?.includes('origin_custom_clearance')) {
    inquiryValues['party']['origin_clearance_agent'] = {
      party_company: default_company,
    };
  }

  if (inquiryValues?.services?.includes('destination_custom_clearance')) {
    inquiryValues['party']['destination_clearance_agent'] = {
      party_company: default_company,
    };
  }

  if (inquiryValues?.services?.includes('freight_forwarding')) {
    inquiryValues['party']['freight_forwarding_agent'] = {
      party_company: default_company,
    };
  }

  const pol_node = getNewRoutingNode({
    tags:
      inquiryOption?.place_of_carrier_receipt?.id &&
      inquiryOption?.port_of_loading?.id !== inquiryOption?.place_of_carrier_receipt?.id
        ? ['port_of_loading']
        : (['port_of_loading', 'place_of_carrier_receipt'] as Array<RoutingNodeTag>),
    location: inquiryOption?.port_of_loading,
  } as RoutingNodeValue);

  const pod_node = getNewRoutingNode({
    tags:
      inquiryOption?.place_of_carrier_delivery?.id &&
      inquiryOption?.port_of_discharge?.id !== inquiryOption?.place_of_carrier_delivery?.id
        ? (['port_of_discharge'] as Array<RoutingNodeTag>)
        : (['port_of_discharge', 'place_of_carrier_delivery'] as Array<RoutingNodeTag>),
    location: inquiryOption?.port_of_discharge,
  } as RoutingNodeValue);

  const main_carriage_leg: RoutingLegValue[] = [
    {
      routing_type: 'main_carriage',
      origin_id: pol_node?._id,
      destination_id: pod_node?._id,
      mode_of_transit:
        inquiryValues?.freight_type === 'ocean' ? 'sea' : inquiryValues?.freight_type,
      sequence_number: 3.1,
      estimated_time_of_arrival: convertToDayJs(inquiryOption?.estimated_time_of_arrival),
      estimated_time_of_departure: convertToDayJs(inquiryOption?.estimated_time_of_departure),
      vessel: inquiryOption?.ocean_vessel,
      voyage_number: inquiryOption?.voyage_number,
    } as RoutingLegValue,
  ];

  const routing_nodes = { [pol_node._id || '']: pol_node, [pod_node._id || '']: pod_node };
  let routing_legs = [...main_carriage_leg];

  if (
    inquiryOption?.place_of_carrier_receipt?.id &&
    inquiryOption?.port_of_loading?.id !== inquiryOption?.place_of_carrier_receipt?.id
  ) {
    const pcr_node = getNewRoutingNode({
      tags: ['place_of_carrier_receipt'] as Array<RoutingNodeTag>,
      location: inquiryOption?.place_of_carrier_receipt,
    } as RoutingNodeValue);
    const pre_carriage_leg = [
      {
        routing_type: 'pre_carriage',
        origin_id: pcr_node._id,
        destination_id: pol_node._id,
        mode_of_transit: MODE_OF_TRANSIT_RAIL,
        sequence_number: 2.1,
      } as RoutingLegValue,
    ];
    routing_legs = [...pre_carriage_leg, ...routing_legs];
    routing_nodes[pcr_node._id || ''] = pcr_node;
  }
  if (
    inquiryOption?.place_of_carrier_delivery?.id &&
    inquiryOption?.port_of_discharge?.id !== inquiryOption?.place_of_carrier_delivery?.id
  ) {
    const pcd_node = getNewRoutingNode({
      tags: ['place_of_carrier_delivery'] as Array<RoutingNodeTag>,
      location: inquiryOption?.place_of_carrier_delivery,
    } as RoutingNodeValue);
    const on_carriage_leg = [
      {
        routing_type: 'on_carriage',
        origin_id: pod_node._id,
        destination_id: pcd_node._id,
        mode_of_transit: MODE_OF_TRANSIT_RAIL,
        sequence_number: 4.1,
      } as RoutingLegValue,
    ];
    routing_legs = [...routing_legs, ...on_carriage_leg];
    routing_nodes[pcd_node._id || ''] = pcd_node;
  }
  inquiryValues['routing_details'] = {
    routing_nodes: routing_nodes,
    routing_legs: routing_legs,
  };
  const weight_unit = inquiry?.cargos?.[0]?.weight_unit || 'kg';
  inquiryValues.gross_weight = convertNumber(
    sumValue(inquiry.cargos, 'gross_weight'),
    weight_unit,
    'kg'
  );
  inquiryValues.gross_volume = sumValue(inquiry.cargos, 'gross_volume');

  //  For b2b shipment we will prefill terms only for HBL
  if (shipmentType === 'back_to_back') {
    inquiryValues.house = default_house_document_values;
  }
  inquiryValues.master = default_master_document_values;
  inquiryValues.total_number_of_packages = sumValue(inquiry.cargos, 'total_packages');
  if (inquiry.freight_type === FREIGHT_TYPE_AIR) {
    inquiryOptionValues.shipment_product_ratings = [
      {
        id: null,
        cargo_type: inquiry.load_type,
        quantity: 1,
        rate_class: RATE_CLASS_QUANTITY.key,
        chargeable_weight: inquiryOption.chargeable_weight || inquiry.gross_weight,
        gross_weight: inquiry.gross_weight || inquiryOption.chargeable_weight,
        tariff_rate: inquiryOption.tariff_rate,
      },
    ];
    inquiryOptionValues.shipment_routing_details = [
      {
        id: 1,
        carrier: inquiryOption.carrier,
        location_id: inquiryOption.port_of_loading?.id,
        location: inquiryOption.port_of_loading,
        location_tags: [LOCATION_TAG_POL.key],
        route_order: 1,
        routing_type: 'carriage',
      },
      {
        id: 2,
        carrier: inquiryOption.carrier,
        location_id: inquiryOption.port_of_discharge?.id,
        location: inquiryOption.port_of_discharge,
        location_tags: [LOCATION_TAG_POD.key],
        route_order: 100,
        routing_type: 'carriage',
      },
    ];
  }
  const services = getServicesPayloadFromInquiry(inquiryValues);
  const customDetails = [];
  if (inquiryValues.origin_custom_clearance_location) {
    customDetails.push({
      country: inquiryValues.origin_custom_clearance_location.country_code,
      custom_clearance_location: inquiryValues.origin_custom_clearance_location,
      trade_type: 'export',
    });
  }
  if (inquiryValues.destination_custom_clearance_location) {
    customDetails.push({
      country: inquiryValues.destination_custom_clearance_location.country_code,
      custom_clearance_location: inquiryValues.destination_custom_clearance_location,
      trade_type: 'export',
    });
  }
  inquiryValues['cargos'] = inquiryValues['cargos'].map((c: any) => _omit(c, ['id']));
  return {
    ...inquiryValues,
    ...inquiryOptionValues,
    party: {
      origin_agent: {
        party_company:
          inquiryValues?.trade_type === TRADE_TYPE_EXPORT ? default_company : undefined,
      },
      destination_agent: {
        party_company:
          inquiryValues?.trade_type === TRADE_TYPE_IMPORT ? default_company : undefined,
      },
    },
    customer: {
      party_company: inquiryValues?.customer_company,
      party_addresss: inquiryValues?.customer_address,
    },
    shipment_custom_details: customDetails,
    estimated_time_of_departure: convertToDayJs(inquiryOption?.estimated_time_of_departure),
    estimated_time_of_arrival: convertToDayJs(inquiryOption?.estimated_time_of_arrival),
    sales_agent: inquiry.sales_person,
    services: services,
    inquiry_option: inquiryOption,
    job_date: dayjs.unix(Date.now() / 1000),

    shipment_container_quantity: (inquiryOption.inquiry.container_requests || []).map((cr: any) => {
      return {
        ..._omit(cr, ['__typename', 'id']),
        quantity: cr.quantity - (fulfilled_container_map?.[cr.container_type_code] || 0),
        container_settings: _omit(cr.container_settings, '__typename'),
      };
    }),
    shipping_bill_details: [
      {
        trade_type: TRADE_TYPE_EXPORT,
        shipping_bill_type: DUTY_FREE,
        custom_clearance_location: inquiryValues?.origin_custom_clearance_location,
      },
    ],
    import_custom_details: [
      {
        trade_type: TRADE_TYPE_IMPORT,
        bill_of_entry_type: HOME_BILL,
        custom_clearance_location: inquiryValues?.destination_custom_clearance_location,
      },
    ],
  };
};

function sumValue(arr: any[], attribute: string) {
  return arr.reduce((sum, cargo) => {
    sum += cargo[attribute] || 0;
    return sum;
  }, 0);
}

function getServicesPayloadFromInquiry(
  inquiry: InquiryValue
): Record<string, Record<string, boolean>> {
  const inquiryServiceMap: Record<string, boolean> = {};

  (inquiry.services || []).forEach((service) => {
    inquiryServiceMap[service] = true;
  });

  const shipmentServices: any = {};

  for (const key in INQUIRY_OPTION_SERVICES_MAP) {
    const value = INQUIRY_OPTION_SERVICES_MAP[key];
    if (Array.isArray(value)) {
      shipmentServices[key] = {};
      value.forEach((service) => {
        if (typeof service === 'string') {
          shipmentServices[key][service] = !!inquiryServiceMap[service];
        } else {
          const from = service['from'];
          const to = service['to'];
          shipmentServices[key][to] = !!inquiryServiceMap[from];
        }
      });
    } else {
      shipmentServices[key] = !!inquiryServiceMap[key];
    }
  }

  return shipmentServices;
}

type ServiceMapping = { from: string; to: string } | string;
interface InquiryOptionServicesMap {
  [key: string]: ServiceMapping[] | boolean;
}

export const INQUIRY_OPTION_SERVICES_MAP: InquiryOptionServicesMap = {
  origin: [
    { from: 'origin_custom_clearance', to: 'clearance' },
    { from: 'origin_transport', to: 'transport' },
    'palletization',
    'fumigation',
  ],
  destination: [
    { from: 'destination_transport', to: 'transport' },
    { from: 'destination_custom_clearance', to: 'clearance' },
  ],
  cargo_insurance: true,
  freight_forwarding: true,
  empty_container_insurance: true,
};
