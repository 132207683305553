import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { message, Modal } from '@shipmnts/pixel-hub';
import { DELETE_SHIPMENT_CUSTOM_DETAIL } from 'operations/graphql/shipmentCustomDetail';
import { ShipmentCustomDetailValue } from 'operations/models/ShipmentCustomDetail';

export default function DeleteShipmentCustomDetail(props: {
  shipmentCustomDetail: ShipmentCustomDetailValue;
  onClose?: () => void;
  onSuccess?: () => void;
}) {
  const { shipmentCustomDetail, onClose, onSuccess } = props;
  const [modal, contextHolder] = Modal.useModal();

  const [deleteShipmentCustomDetail, { data, error }] = useMutation(DELETE_SHIPMENT_CUSTOM_DETAIL);

  useEffect(() => {
    if (error) {
      if (onClose) onClose();
      message.error(error.message);
      return;
    }
    if (data?.delete_shipment_custom_detail?.message) {
      message.success('Deleted successfully!');
      if (onSuccess) onSuccess();
      if (onClose) onClose();
    }
  }, [data, error, onSuccess, onClose]);

  const config = {
    content: `Are you sure you want to delete shipment custom detail?`,
    onOk: () => {
      deleteShipmentCustomDetail({ variables: { id: shipmentCustomDetail.id } });
    },
    okText: 'Yes',
    cancelText: 'No',
    onCancel: () => {
      if (onClose) onClose();
    },
  };

  useEffect(() => {
    Modal.confirm(config);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  return <>{contextHolder}</>;
}
