import React from 'react';
import { FilePdfOutlined, TagsOutlined, LinkOutlined } from '@shipmnts/pixel-hub';
import { NEGATIVE_TYPE, PRIMARY_TYPE, SECONDARY_TYPE } from '../../actionHelper/constants';

import { Action } from '../../actionHelper/models';
import { CustomIcon } from '@shipmnts/pixel-hub';
import {
  amendInvoiceAction,
  cancelInvoiceAction,
  performLinkContainers,
  performLinkCustom,
  performPrintCommercialInvoice,
} from './CommercialInvoiceActionRenderer';
import { SessionDataValue } from 'operations/models/SessionData';
import { CommercialInvoiceValue } from 'operations/models/CommercialInvoice';

const amendInvoice = (commercialInvoice: CommercialInvoiceValue): Action => {
  return {
    key: 'amend',
    type: PRIMARY_TYPE,
    displayComponent: 'Amend',
    icon: <TagsOutlined />,
    isEnable: commercialInvoice.status === 'submitted',
    performAction: amendInvoiceAction,
    description: 'Amend',
  };
};

export const cancelInvoice = (commercialInvoice: CommercialInvoiceValue): Action => {
  return {
    type: NEGATIVE_TYPE,
    key: 'cancel_invoice',
    icon: <CustomIcon icon="FileTimesIcon" />,
    displayComponent: 'Cancel Invoice',
    isEnable: commercialInvoice?.status !== 'cancelled',
    performAction: cancelInvoiceAction,
  };
};

const printCommercialInvoice = (commercialInvoice: CommercialInvoiceValue): Action => {
  return {
    key: 'share_invoice',
    type: SECONDARY_TYPE,
    displayComponent: 'Share',
    isEnable: true,
    icon: <FilePdfOutlined />,
    performAction: performPrintCommercialInvoice,
    description: 'Share Invoice',
  };
};

const linkContainers = (commercialInvoice: CommercialInvoiceValue): Action => {
  return {
    key: 'link_containers',
    type: SECONDARY_TYPE,
    displayComponent: 'Link Containers',
    isEnable: commercialInvoice?.status !== 'cancelled',
    icon: <LinkOutlined />,
    performAction: performLinkContainers,
    description: 'Link Containers',
  };
};

const linkCustoms = (commercialInvoice: CommercialInvoiceValue): Action => {
  return {
    key: 'link_custom',
    type: SECONDARY_TYPE,
    displayComponent: 'Link Custom',
    isEnable: commercialInvoice?.status !== 'cancelled',
    icon: <LinkOutlined />,
    performAction: performLinkCustom,
    description: 'Link Customs',
  };
};

export const getCommercialInvoiceActions = (
  commercialInvoice: CommercialInvoiceValue,
  sessionData: SessionDataValue
) => {
  return [
    amendInvoice(commercialInvoice),
    printCommercialInvoice(commercialInvoice),
    cancelInvoice(commercialInvoice),
    linkContainers(commercialInvoice),
    linkCustoms(commercialInvoice),
  ];
};
