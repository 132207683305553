/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotIn, SnapshotOut, IAnyModelType } from 'mobx-state-tree';
import { groupBy as _groupBy, sortBy as _sortBy } from 'lodash';
import RoutingNode from 'operations/models/RoutingNode';
import Vessel from 'operations/models/Vessel';
import { DayJsDate as DayJsDateObj, convertToDayJs, Dayjs } from '@shipmnts/pixel-hub';
import Carrier from 'common/models/Carrier';

export const MODE_OF_TRANSIT_SEA = 'sea';
export const MODE_OF_TRANSIT_AIR = 'air';
export const MODE_OF_TRANSIT_ROAD = 'road';
export const MODE_OF_TRANSIT_RAIL = 'rail';
export const MODE_OF_TRANSIT_RIVER = 'river';

export const ROUTING_TYPE_PRE_CARRIAGE = 'pre_carriage';
export const ROUTING_TYPE_STUFFING = 'stuffing';
export const ROUTING_TYPE_DESTUFFING = 'destuffing';
export const ROUTING_TYPE_MAIN_CARRIAGE = 'main_carriage';
export const ROUTING_TYPE_ON_CARRIAGE = 'on_carriage';
export const ROUTING_TYPE_PICKUP_OCEAN = 'pickup_ocean';
export const ROUTING_TYPE_PICKUP_DELIVERY = 'pickup';
export const ROUTING_TYPE_MOVEMENT = 'movement';
export const ROUTING_TYPE_DELIVERY = 'delivery';
export const PRE_CARRIAGE_FIRST_LEG = 'pre_carriage_first_leg';
export const ON_CARRIAGE_LAST_LEG = 'on_carriage_last_leg';
export const MAIN_CARRIAGE_FIRST_LEG = 'main_carriage_first_leg';
export const MAIN_CARRIAGE_LAST_LEG = 'main_carriage_last_leg';
export const ROUTING_TYPE_POL_BUFFER = 'pol_buffer';
export const ROUTING_TYPE_POD_BUFFER = 'pod_buffer';

export declare type RoutingLegModeOfTransit = 'sea' | 'air' | 'road' | 'rail' | 'river';
export declare type RoutingLegRoutingType =
  | 'pre_carriage'
  | 'stuffing'
  | 'destuffing'
  | 'pickup_ocean'
  | 'main_carriage'
  | 'on_carriage'
  | 'pickup'
  | 'movement'
  | 'delivery'
  | 'pol_buffer'
  | 'pod_buffer';

const DayJsDate = types.custom<Dayjs, Dayjs>(DayJsDateObj);

const RoutingLeg = types
  .model({
    id: types.maybe(types.identifier),
    origin_id: types.maybe(types.string),
    origin: types.maybe(types.late((): IAnyModelType => RoutingNode)),
    destination: types.maybe(types.maybeNull(types.late((): IAnyModelType => RoutingNode))),
    destination_id: types.maybe(types.maybeNull(types.string)),
    estimated_time_of_arrival: types.maybe(types.maybeNull(DayJsDate)),
    actual_time_of_arrival: types.maybe(types.maybeNull(DayJsDate)),
    estimated_time_of_departure: types.maybe(types.maybeNull(DayJsDate)),
    actual_time_of_departure: types.maybe(types.maybeNull(DayJsDate)),
    sequence_number: types.number,
    driver_contact: types.maybe(types.maybeNull(types.string)),
    mode_of_transit: types.maybe(
      types.maybeNull(
        types.union(
          types.literal(MODE_OF_TRANSIT_SEA),
          types.literal(MODE_OF_TRANSIT_AIR),
          types.literal(MODE_OF_TRANSIT_ROAD),
          types.literal(MODE_OF_TRANSIT_RAIL),
          types.literal(MODE_OF_TRANSIT_RIVER)
        )
      )
    ),
    routing_type: types.maybe(
      types.maybeNull(
        types.union(
          types.literal(ROUTING_TYPE_PRE_CARRIAGE),
          types.literal(ROUTING_TYPE_STUFFING),
          types.literal(ROUTING_TYPE_DESTUFFING),
          types.literal(ROUTING_TYPE_MAIN_CARRIAGE),
          types.literal(ROUTING_TYPE_MAIN_CARRIAGE),
          types.literal(ROUTING_TYPE_ON_CARRIAGE),
          types.literal(ROUTING_TYPE_PICKUP_OCEAN),
          types.literal(ROUTING_TYPE_PICKUP_DELIVERY),
          types.literal(ROUTING_TYPE_MOVEMENT),
          types.literal(ROUTING_TYPE_DELIVERY),
          types.literal(ROUTING_TYPE_POL_BUFFER),
          types.literal(ROUTING_TYPE_POD_BUFFER)
        )
      )
    ),
    vessel: types.maybe(types.maybeNull(Vessel)),
    voyage_number: types.maybe(types.maybeNull(types.string)),
    wagon_number: types.maybe(types.maybeNull(types.string)),
    voyage_schedule_id: types.maybe(types.maybeNull(types.string)),
    global_carrier: types.maybe(types.maybeNull(Carrier)),
  })
  .preProcessSnapshot(
    ({
      estimated_time_of_arrival,
      actual_time_of_arrival,
      estimated_time_of_departure,
      actual_time_of_departure,
      ...snapshot
    }) => ({
      ...snapshot,
      estimated_time_of_arrival: convertToDayJs(estimated_time_of_arrival),
      actual_time_of_arrival: convertToDayJs(actual_time_of_arrival),
      estimated_time_of_departure: convertToDayJs(estimated_time_of_departure),
      actual_time_of_departure: convertToDayJs(actual_time_of_departure),
    })
  );

export interface RoutingLegValue extends Instance<typeof RoutingLeg> {}
export interface RoutingLegIn extends SnapshotIn<typeof RoutingLeg> {}
export interface RoutingLegOut extends SnapshotOut<typeof RoutingLeg> {}

export default RoutingLeg;

export interface CarriageWiseRoutingLegs {
  [ROUTING_TYPE_PRE_CARRIAGE]?: Array<RoutingLegValue>;
  [ROUTING_TYPE_MAIN_CARRIAGE]?: Array<RoutingLegValue>;
  [ROUTING_TYPE_ON_CARRIAGE]?: Array<RoutingLegValue>;
}

export const getCarriageWiseRoutingLegs = (
  routing_legs: Array<RoutingLegValue>
): CarriageWiseRoutingLegs => {
  const routingLegsObject = _groupBy(routing_legs, 'routing_type');
  const routingLegsCarriageWise = Object.keys(routingLegsObject).reduce(
    (result: { [key: string]: Array<RoutingLegValue> }, key: string) => {
      result[key] = _sortBy(routingLegsObject[key], 'sequnce_number');
      return result;
    },
    {}
  );
  return routingLegsCarriageWise;
};

export const getMainHopEtd = (routing_legs: RoutingLegValue[] | undefined) => {
  const carriageWiseRoutingLegs = routing_legs ? getCarriageWiseRoutingLegs(routing_legs) : {};
  return carriageWiseRoutingLegs?.[ROUTING_TYPE_MAIN_CARRIAGE]?.[0]?.estimated_time_of_departure;
};

export const getFirstMainHopIndex = (routing_legs: RoutingLegValue[] | undefined) => {
  const carriageWiseRoutingLegs = routing_legs ? getCarriageWiseRoutingLegs(routing_legs) : {};
  const mainCarriageHop = carriageWiseRoutingLegs?.[ROUTING_TYPE_MAIN_CARRIAGE]?.[0];
  const index = routing_legs?.findIndex(
    (rl: RoutingLegValue) => rl.sequence_number === mainCarriageHop?.sequence_number
  );
  return index !== undefined ? index : -1;
};

export const hasPrecarriageLeg = (routing_legs?: RoutingLegValue[]) => {
  const carriageWiseRoutingLegs = routing_legs ? getCarriageWiseRoutingLegs(routing_legs) : {};
  return (carriageWiseRoutingLegs?.[ROUTING_TYPE_PRE_CARRIAGE]?.length || 0) > 0;
};
