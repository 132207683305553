import { ApolloClient } from '@apollo/client';
import { pick as _pick, map as _map } from 'lodash';
import { CreatedDocumentWithPdf } from './SendDocumentForm';
import {
  UploadedDocumentType,
  AccountingDocumentType,
  AttachmentType,
  UploadDocumentPayload,
  uploadDocument,
  DocumentParentType,
} from '@shipmnts/pixel-hub';

import { SessionDataValue } from 'operations/models/SessionData';
import { generatePdfDocument } from 'operations/apis/pdfHelper';
import {
  fetchSourceData,
  getDocumentTitle,
  SourceDataType,
} from 'operations/helpers/documentPrintHelper';
import { CreatedDocument } from 'common/commonTypeDefs';

interface GetAttachmentProps {
  sessionData: SessionDataValue;
  document: CreatedDocument;
  client: ApolloClient<object>;
  config_data?: any;
}

interface GetGeneratedDocumentsPayloadProps {
  generatedDocuments?: CreatedDocument[];
  sessionData: SessionDataValue;
  client: ApolloClient<object>;
  config_data?: any;
}

const getAttachment = async (props: GetAttachmentProps) => {
  const { document, client, sessionData, config_data } = props;
  const source_data = await fetchSourceData({
    document_id: document.id,
    client,
    sessionData,
    config_data,
  });

  if (!!source_data.fetchError) return { error: source_data?.fetchError };

  const new_source_data = source_data as SourceDataType;
  const html = await new_source_data?.getHTML();
  if (!html) return {};

  const response = await generatePdfDocument({
    html,
    file_name: getDocumentTitle(new_source_data?.document, new_source_data?.shipment) || 'file',
  });
  if (response?.error) return { error: response.error };
  else {
    return { document: { ...document, attachment: response?.document } };
  }
};

export const getGeneratedDocumentsPdf = async (props: GetGeneratedDocumentsPayloadProps) => {
  const { generatedDocuments } = props;
  let fetchError = '';
  const documents: CreatedDocumentWithPdf[] = [];
  const promises: Promise<{
    document?: CreatedDocumentWithPdf;
    error?: any;
  }>[] = [];
  (generatedDocuments || []).forEach(async (document: CreatedDocument) => {
    promises.push(getAttachment({ document, ...props }));
  });
  const response = await Promise.all(promises);
  response.forEach((res) => {
    if (!fetchError && res.error) fetchError = res.error;
    else if (res.document) documents.push(res.document);
  });
  return { documents, error: fetchError };
};

export const getAttachmentPayload = (doc: UploadedDocumentType, shipment_id?: string) => {
  let parents: DocumentParentType[] = [];
  if (doc.parents) {
    parents = _map(doc.parents, (p) => ({
      parent_type: p.parent_type,
      parent_id: p.parent_id,
    }));
  } else if (shipment_id) {
    parents = [{ parent_type: 'shipment', parent_id: shipment_id }];
  }
  return {
    document_identifier: doc.id,
    document_url: doc.uploaded_document,
    parents: parents,
    ..._pick(doc, ['tags', 'file_name', 'file_size', 'content_type']),
  };
};

export const ACCOUNTING_VOUCHER_TAG_MAPPING = [
  {
    'Sales Invoice': 'Debit Note Agent',
    'Purchase Invoice': 'Credit Note Agent',
  },
  {
    'Sales Invoice': 'Credit Note Agent',
    'Purchase Invoice': 'Debit Note Agent',
  },
];

export const getAccountingAttachmentPayload = (
  doc: AccountingDocumentType,
  shipment_id: string
): AttachmentType[] => {
  const parents: DocumentParentType[] = [{ parent_type: 'shipment', parent_id: shipment_id }];
  let voucher_attachment: AttachmentType[] = [];
  const voucher_type = doc.voucher_type as 'Sales Invoice' | 'Purchase Invoice';
  const tags = [ACCOUNTING_VOUCHER_TAG_MAPPING[doc.is_return][voucher_type]];
  if (doc.voucher_pdf_link) {
    voucher_attachment = [
      {
        document_identifier: doc.voucher_name,
        document_url: encodeURI(doc.voucher_pdf_link),
        parents,
        tags,
        file_name: `${doc.voucher_name}.pdf`,
        content_type: 'application/pdf',
        is_private: doc.is_private,
      } as AttachmentType,
    ];
  }
  return voucher_attachment.concat(
    (doc.files || []).map((f) => {
      return {
        document_identifier: f.name,
        document_url: encodeURI(f.file_url),
        content_type: 'application/octet-stream',
        parents: parents,
        tags,
        ..._pick(f, ['file_name', 'file_size', 'is_private']),
      } as AttachmentType;
    })
  );
};

export const getAccountingEmailAttachmentPayload = (
  docs: AttachmentType[]
): UploadedDocumentType[] => {
  return docs.map((doc) => {
    return {
      id: doc.document_identifier,
      created_at: Date.now(),
      uploaded_document: doc.document_url,
      type: doc.content_type,
      ..._pick(doc, ['file_name', 'file_size', 'tags', 'parents', 'content_type', 'is_private']),
    } as UploadedDocumentType;
  });
};

export const uploadAccountingDocuments = async (
  documents: AccountingDocumentType[],
  shipment_id: string,
  sessionData: SessionDataValue,
  docgen_url: string
) => {
  const uploadAccountingDocsPayload: UploadDocumentPayload[] = getAccountingEmailAttachmentPayload(
    (documents || [])?.reduce((acc: AttachmentType[], doc: AccountingDocumentType) => {
      acc = acc.concat(getAccountingAttachmentPayload(doc, shipment_id));
      return acc;
    }, [])
  );
  let fetchError = '';
  const docs: UploadedDocumentType[] = [];
  const promises: Promise<{
    response?: { data?: { document?: UploadedDocumentType } };
    error?: any;
  }>[] = await getuploadAccountingDocsPromises(
    uploadAccountingDocsPayload,
    sessionData,
    docgen_url
  );

  const response = await Promise.all(promises);
  response.forEach((res) => {
    if (!fetchError && res.error) fetchError = res.error;
    else if (res?.response?.data?.document) docs.push(res?.response?.data?.document);
  });
  return { documents: docs, error: fetchError };
};

export const getuploadAccountingDocsPromises = async (
  uploadAccountingDocsPayload: UploadDocumentPayload[],
  sessionData: SessionDataValue,
  docgen_url: string
) => {
  const promises: Promise<{
    response?: { data?: { document?: UploadedDocumentType } };
    error?: any;
  }>[] = [];
  const subdomain = sessionData?.company_account?.subdomain;
  for (const doc of uploadAccountingDocsPayload) {
    let downloadURL = doc.uploaded_document as string;
    if (doc.is_private) downloadURL = `${subdomain}${doc.uploaded_document}`;

    promises.push(
      uploadDocument(
        {
          reference_id: String(Date.now()),
          created_by_id: sessionData.id,
          created_by_name: `${sessionData.first_name} ${sessionData.last_name || ''}`,
          company_id: sessionData?.company_account?.id || '',
          parents: [] as DocumentParentType[],
          ..._pick(doc, ['file_name', 'file_size', 'tags', 'type']),
          download_url: downloadURL,
        },
        docgen_url
      )
    );
  }
  return promises;
};
