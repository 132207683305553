import React, { useState } from 'react';
import { Card, Button, Descriptions, Tooltip, LinkRender } from '@shipmnts/pixel-hub';
import { BookingRequestValue } from 'operations/models/BookingRequest';
import BookingRequestOverviewCard from './BookingRequestOverviewCard';
import { observer } from 'mobx-react-lite';

export interface BookingRequestOverviewProps {
  bookingRequest: BookingRequestValue;
  onUpdate?: (BookingRequest: BookingRequestValue) => void;
  disabled?: boolean;
  disableReason?: string;
}

const BookingRequestOverview = observer(function BookingRequestOverview(
  props: BookingRequestOverviewProps
): JSX.Element {
  const { bookingRequest, onUpdate, disabled, disableReason } = props;

  const [brOverviewEdit, setBrOverviewEdit] = useState<boolean>(false);

  if (brOverviewEdit) {
    return (
      <BookingRequestOverviewCard
        bookingRequest={bookingRequest}
        onUpdate={onUpdate}
        setBrOverviewEdit={setBrOverviewEdit}
      />
    );
  }

  return (
    <Card
      id="overview"
      title="Overview"
      extra={
        <Tooltip title={disableReason}>
          <Button
            disabled={disabled}
            onClick={() => {
              setBrOverviewEdit(true);
            }}
          >
            Edit
          </Button>
        </Tooltip>
      }
    >
      <Descriptions
        className="left-align-description"
        column={{ xs: 2, sm: 4, md: 5 }}
        size="small"
        colon={false}
        layout="vertical"
      >
        <Descriptions.Item key="sales_person" label="Sales Person">
          {bookingRequest.salesPersonName}
        </Descriptions.Item>
        <Descriptions.Item key="created_by" label="Created By">
          {bookingRequest.createdByName}
        </Descriptions.Item>
        <Descriptions.Item key="shipper" label="Shipper">
          {bookingRequest.shipper?.party_company?.registered_name}
        </Descriptions.Item>
        <Descriptions.Item key="consignee" label="Consignee">
          {bookingRequest.consignee?.party_company?.registered_name}
        </Descriptions.Item>
        <Descriptions.Item key="booked_by" label="Booked By Branch">
          {bookingRequest.bookedByBranch?.name}
        </Descriptions.Item>
        <Descriptions.Item key="preferred_carriers" label="Preferred Carriers">
          {bookingRequest.preferred_carriers?.map((carrier) => carrier.name).join(', ')}
        </Descriptions.Item>
        <Descriptions.Item key="incoterms" label="Incoterms">
          {bookingRequest.incoterm}
        </Descriptions.Item>
        <Descriptions.Item key="quotation_number" label="Quotation Ref">
          <div style={{ width: '7rem' }}>
            {bookingRequest.is_external_quotation_number ? (
              <>{bookingRequest.quotation_number}</>
            ) : (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              <LinkRender
                value={bookingRequest?.inquiry_option}
                data={bookingRequest?.inquiry_option}
                value_field="quotation_number"
                id_field="id"
                doc_type_id="SalesHub::InquiryOption"
              />
            )}
          </div>
        </Descriptions.Item>
        <Descriptions.Item key="stuffing_location_type" label="Stuffing Location Type">
          {bookingRequest.stuffing_location_type?.toUpperCase()}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
});

export default BookingRequestOverview;
