import { Typography } from '@shipmnts/pixel-hub';
import CreatetShipmentCustomDetail from './CreateShipmentCustomDetail';
import { BaseTable } from '@shipmnts/pixel-hub';
import React from 'react';
import { TRADE_TYPE_EXPORT, TradeType } from 'operations/modules/shipment/constants';
import { ShipmentCustomDetailValue } from 'operations/models/ShipmentCustomDetail';
import { ActionRendererDetailReport } from 'operations';

export interface commonPropsCustomDetails {
  shipment: any;
  setActiveShipmentCustomDetail: (value: ShipmentCustomDetailValue) => void;
  onSuccess: () => void;
  tradeType?: TradeType;
  components: any;
  columns?: any;
  rowData?: any;
  height?: string;
}

const CustomDetailsTable = (props: commonPropsCustomDetails) => {
  const { tradeType, components, rowData, columns, ...commonProps } = props;

  const title = tradeType === TRADE_TYPE_EXPORT ? 'Export Custom Details' : 'Import Custom Details';
  const report = tradeType === TRADE_TYPE_EXPORT ? 'customs_tab_export' : 'customs_tab_import';

  return (
    <div
      className="customs-table"
      style={{ overflow: 'auto', display: 'flex', flexDirection: 'column' }}
    >
      <div style={{ display: 'block' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography.Title level={4} style={{ margin: 0 }}>
            {title}
          </Typography.Title>
          <CreatetShipmentCustomDetail tradeType={tradeType as TradeType} {...commonProps} />
        </div>
      </div>
      <div>
        <BaseTable
          height={'34vh'}
          reportName={report}
          rowData={rowData}
          columns={columns}
          reportConfig={{
            components: { ...(components || {}), ActionRendererDetailReport },
            groupDefaultExpanded: 1,
          }}
          allMapping={true}
        />
      </div>
    </div>
  );
};

export default CustomDetailsTable;
