import React from 'react';
import { CustomIcon, getDateTimeFromUnix } from '@shipmnts/pixel-hub';
import { WarehouseTransactionValue } from 'operations/models/WarehouseTransaction';
import { collapsibleCard } from 'operations/modules/shipment/components/DetailLayout/Common/common';

interface WMSPartiesProps {
  warehouseTransaction: WarehouseTransactionValue;
}

const WMSParties = (props: WMSPartiesProps) => {
  const { warehouseTransaction } = props;
  const partyDetails = [
    {
      Customer: warehouseTransaction?.shipment_parties?.find((e: any) => e.name === 'customer')
        ?.party_company?.registered_name,
    },
    {
      Shipper: warehouseTransaction?.shipment_parties?.find((e: any) => e.name === 'shipper')
        ?.party_company?.registered_name,
    },
    {
      Consignee: warehouseTransaction?.shipment_parties?.find((e: any) => e.name === 'consignee')
        ?.party_company?.registered_name,
    },
    {
      'Customer Ref#': warehouseTransaction?.customer_ref_code || null,
    },
    {
      'Customer Ref Date': warehouseTransaction?.customer_ref_date ? (
        <div>{getDateTimeFromUnix(warehouseTransaction?.customer_ref_date)}</div>
      ) : null,
    },
  ];

  return (
    <>
      {collapsibleCard({
        icon: <CustomIcon icon="User" />,
        header: 'Party Details',
        data: partyDetails,
      })}
    </>
  );
};

export default WMSParties;
