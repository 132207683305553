import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Row, Radio } from '@shipmnts/pixel-hub';
import { ContainerReportData } from 'operations/modules/reports/reportModels';
import { ApolloClient, OperationVariables, QueryLazyOptions } from '@apollo/client';
import {
  SHIPMENT_TYPE_CONSOL,
  SHIPMENT_TYPE_HOUSE,
  ShipmentType,
} from 'operations/modules/shipment/constants';
import {
  getShipmentCreationValidationDetails,
  showErrorMessages,
} from 'operations/modules/reports/helpers/containerActionsHelper';
import { OceanTransportOrderValue } from 'operations/models/OceanTransportOrder';
import { PropsForContainerAction } from './ContainerActionDrawer';
import { uniq as _uniq } from 'lodash';
import { QuestionCircleFilled } from '@shipmnts/pixel-hub';

interface ValidateShipmentCreationProps {
  containers: ContainerReportData[];
  shipmentType: ShipmentType;
  client: ApolloClient<object>;
  setLoading?: (value: boolean) => void;
  setValidateShipmentCreationVisible: (value: boolean) => void;
  setGetOtoByAction?: (value: string) => void;
  getOceanTransportOrder?: (options?: QueryLazyOptions<{ id: string }> | undefined) => void;
  setPropsForActiveAction: (value: PropsForContainerAction) => void;
  getBookingRequestAndRemarks: (options?: QueryLazyOptions<OperationVariables> | undefined) => void;
}

interface ValidateShipmentCreationResponseType {
  errors?: string[];
  warnings?: string[];
  ocean_transport_orders?: Array<OceanTransportOrderValue>;
}

const ValidateShipmentCreation = React.memo(function ValidateShipmentCreation(
  props: ValidateShipmentCreationProps
): JSX.Element {
  const {
    containers,
    shipmentType,
    client,
    setLoading,
    setGetOtoByAction,
    getOceanTransportOrder,
    setPropsForActiveAction,
    getBookingRequestAndRemarks,
    setValidateShipmentCreationVisible,
  } = props;
  const [modalVisible, setModalVisible] = useState(false);
  const [validations, setValidations] = useState<ValidateShipmentCreationResponseType>({});
  const [selectedOceanTransportOrder, setSelectedOceanTransportOrder] = useState<
    OceanTransportOrderValue | undefined
  >();

  const handleSubmit = useCallback(
    (oceanTransportOrder?: OceanTransportOrderValue) => {
      if (shipmentType === SHIPMENT_TYPE_CONSOL) {
        if (setGetOtoByAction) setGetOtoByAction('create_consol_shipment');
        if (oceanTransportOrder && getOceanTransportOrder) {
          getOceanTransportOrder({ variables: { id: oceanTransportOrder.id } });
        }
      } else {
        console.log('containers[0].sell_collaborations', containers[0].sell_collaborations);
        setPropsForActiveAction({
          shipmentType,
          customer: {
            party_company: containers[0].customer_company,
            party_address: containers[0].customer_address,
          },
          shipper: {
            party_company: containers[0].shipper_company,
            party_address: containers[0].shipper_address,
          },
          branch_account_id: containers[0].sell_collaborations?.[0]?.vendor_address?.id,
          carrier: containers[0].global_carrier,
          oceanTransportOrder: oceanTransportOrder,
        });
        const variables = {
          filters: [
            {
              col: 'booking_requests.id',
              condition: {
                filter_type: 'text',
                type: 'in',
                values: _uniq(containers.map((container) => container?.booking_request?.id)),
              },
            },
          ],
        };
        getBookingRequestAndRemarks({ variables });
      }
      setModalVisible(false);
      setValidateShipmentCreationVisible(false);
    },
    [
      containers,
      getBookingRequestAndRemarks,
      getOceanTransportOrder,
      setGetOtoByAction,
      setPropsForActiveAction,
      setValidateShipmentCreationVisible,
      shipmentType,
    ]
  );

  const getValidations = useCallback(async () => {
    setValidations({});
    if (setLoading) setLoading(true);
    const validation_details: ValidateShipmentCreationResponseType =
      await getShipmentCreationValidationDetails(containers, shipmentType, client);
    setValidations(validation_details);
    if (setLoading) setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containers, shipmentType]);

  useEffect(() => {
    getValidations();
  }, [getValidations]);

  useEffect(() => {
    if (validations?.errors && validations.errors.length > 0) {
      showErrorMessages(validations.errors, 'Cannot create shipment');
    } else if (shipmentType === SHIPMENT_TYPE_HOUSE) {
      handleSubmit();
    } else {
      if (validations?.ocean_transport_orders && validations?.ocean_transport_orders.length > 0) {
        setSelectedOceanTransportOrder(validations.ocean_transport_orders[0]);
        if (
          (validations.warnings && validations.warnings.length > 0) ||
          validations.ocean_transport_orders.length > 1
        ) {
          setModalVisible(true);
        } else {
          handleSubmit(validations.ocean_transport_orders[0]);
        }
      }
    }
    // Cannot keep onOk & shipmentType in dependencies in it, error message modal pops-up twice.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validations]);

  if (
    modalVisible &&
    validations &&
    selectedOceanTransportOrder &&
    ((validations.warnings && validations.warnings.length > 0) ||
      (validations.ocean_transport_orders && validations.ocean_transport_orders.length > 1))
  ) {
    return (
      <Modal
        title={
          <Row>
            <QuestionCircleFilled style={{ color: '#faad14' }} />
            <div style={{ marginLeft: '16px' }}>Do you want to proceed?</div>
          </Row>
        }
        open
        okText="Please Confirm"
        onOk={() => handleSubmit(selectedOceanTransportOrder)}
        onCancel={() => setModalVisible(false)}
      >
        <>
          {validations.warnings && validations.warnings.length > 0 && (
            <ul style={{ paddingLeft: 'inherit' }}>
              {validations.warnings.map((warning: string, index: number) => (
                <li key={index}>{warning}</li>
              ))}
            </ul>
          )}
          {validations.ocean_transport_orders && validations.ocean_transport_orders.length > 1 && (
            <div>
              <p>Select the Booking into which other bookings will be merged</p>
              <Radio.Group
                onChange={(e) => setSelectedOceanTransportOrder(e.target.value)}
                value={selectedOceanTransportOrder}
              >
                {validations.ocean_transport_orders.map((oto) => (
                  <Row key={oto.id}>
                    <Radio value={oto}>{oto.booking_number}</Radio>
                  </Row>
                ))}
              </Radio.Group>
            </div>
          )}
        </>
      </Modal>
    );
  }
  return <></>;
});

export default ValidateShipmentCreation;
