import React from 'react';
import { Card, Statistic, Divider } from '@shipmnts/pixel-hub';

export interface CardChildType {
  title: JSX.Element | string;
  key: string;
  suffix_key?: string;
  minWidth: number;
}
export interface CardType {
  title: JSX.Element | string;
  children: Array<CardChildType>;
}

export default function summarySection({
  cards,
  totalQuantities,
  loading,
}: {
  cards: { [key: string]: CardType };
  totalQuantities?: Record<string, number>;
  loading: boolean;
}) {
  return (
    <div style={{ margin: '0 0 0 12px', display: 'flex', flexWrap: 'wrap' }}>
      {Object.entries(cards).map(([key, card]) => {
        const lastIndex = card.children.length - 1;
        return (
          <Card size="small" key={key} title={card.title} className="dashboard-statistic-card">
            {card.children.map((child, index) => {
              const suffix = child.suffix_key
                ? `/ ${totalQuantities?.[child.suffix_key] || 0}`
                : '';
              const statisticProps = {
                title: child.title,
                value: totalQuantities?.[child.key],
                suffix,
                loading,
              };

              return (
                <Card.Grid
                  key={child.key}
                  style={{ width: `auto`, minWidth: `${child.minWidth}px` }}
                  hoverable={false}
                >
                  {index === lastIndex && (
                    <Statistic {...statisticProps} style={{ paddingRight: '10px' }} />
                  )}
                  {index !== lastIndex && (
                    <div className="divider-div">
                      <Statistic {...statisticProps} style={{ float: 'left' }} />
                      <Divider type="vertical" style={{ float: 'right' }} />
                    </div>
                  )}
                </Card.Grid>
              );
            })}
          </Card>
        );
      })}
    </div>
  );
}
