import { CreateVehicleDrawer } from 'common';
import { StatusUpdateVehicle } from 'common';

export const statusUpdateVehicleActionRender = ({ vehicle }: any) => {
  return {
    actionParams: {
      vehicle: vehicle,
    },
    component: StatusUpdateVehicle,
  };
};

export const editVehicleActionRender = ({ vehicle }: any) => {
  return {
    actionParams: {
      id: vehicle.id,
      type: 'edit',
    },
    component: CreateVehicleDrawer,
  };
};
