import React, { useMemo, useCallback, useState, forwardRef, useImperativeHandle } from 'react';
import { Select, Table, Typography, Form, DatePicker } from '@shipmnts/pixel-hub';
import { TableColumnType, Dayjs } from '@shipmnts/pixel-hub';
import { ShipmentDocumentFieldValue } from '../messageResourceDataModel';
import { FormInstance } from '@shipmnts/pixel-hub';
import {
  ALLOWED_DOCUMENT_STATUS_MAPPING_ON_CREATE,
  document_status_to_display_name_mapping,
  DOCUMENT_STATUS_RECEIVED_AT_DESTINATION,
  DOCUMENT_STATUS_SURRENDERED_AT_ORIGIN,
} from 'operations/modules/reports/constants';
import {
  BL_TYPE_ARRAY,
  FREIGHT_TYPE_AIR,
  FREIGHT_TYPE_OCEAN,
} from 'operations/modules/shipment/constants';
const { Text } = Typography;

interface KeyLabelMapping {
  [key: string]: { [key: string]: string };
}

interface SelectOptionProps {
  key: string;
  name: string;
}

export interface ShipmentDocumentFormValue {
  document_type: string;
  document_status: string;
  bl_type?: string;
  document_status_event_date?: Dayjs;
}

const DOCUMENT_NAME_MAPPING: KeyLabelMapping = {
  [FREIGHT_TYPE_OCEAN]: { master: 'Master Bill of Lading', house: 'House Bill of Lading' },
  [FREIGHT_TYPE_AIR]: { master: 'Master Airway Bill', house: 'House Airway Bill' },
};

const renderBLType = (index: number) => {
  return (
    <Form.Item
      noStyle
      required
      rules={[{ required: true }]}
      name={[`shipment_documents`, index, 'bl_type']}
    >
      <Select style={{ width: '90%' }}>
        {BL_TYPE_ARRAY.map((option: SelectOptionProps, index: number) => (
          <Select.Option key={index} value={option.key}>
            {option.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

const renderDocumentStatus = (
  freightType: string,
  index: number,
  onChange: (value: string) => void
) => {
  return (
    <Form.Item
      noStyle
      required
      rules={[{ required: true }]}
      name={[`shipment_documents`, index, 'document_status']}
    >
      <Select style={{ width: '90%' }} onChange={onChange}>
        {ALLOWED_DOCUMENT_STATUS_MAPPING_ON_CREATE[freightType].map(
          (option: string, index: number) => (
            <Select.Option key={index} value={option}>
              {document_status_to_display_name_mapping[option]}
            </Select.Option>
          )
        )}
      </Select>
    </Form.Item>
  );
};

const renderDocumentType = (index: number) => {
  return (
    <Form.Item noStyle name={[`shipment_documents`, index, 'document_type']}>
      <span />
    </Form.Item>
  );
};

const renderDocumentStatusEventDate = (
  index: number,
  errorsPresent: boolean,
  onChange: () => void
) => {
  return (
    <Form.Item
      noStyle
      shouldUpdate={(prevValues, currentValues) =>
        prevValues.shipment_documents[index]?.document_status !==
        currentValues.shipment_documents[index]?.document_status
      }
    >
      {({ getFieldValue }) => {
        const showDate = [
          DOCUMENT_STATUS_SURRENDERED_AT_ORIGIN,
          DOCUMENT_STATUS_RECEIVED_AT_DESTINATION,
        ].includes(getFieldValue('shipment_documents')?.[index]?.document_status);
        if (showDate) {
          const eventDateValue =
            getFieldValue('shipment_documents')?.[index]?.document_status_event_date;
          return (
            <Form.Item
              required
              name={[`shipment_documents`, index, 'document_status_event_date']}
              validateStatus={!eventDateValue && errorsPresent ? 'error' : 'success'}
              style={{ margin: '0px' }}
            >
              <DatePicker onChange={onChange} style={{ width: '100%' }} />
            </Form.Item>
          );
        }
        return <span />;
      }}
    </Form.Item>
  );
};

const isStatusEventDateRequired = (shipmentDocuments?: ShipmentDocumentFormValue[]) => {
  return (shipmentDocuments || [])?.some((sd: ShipmentDocumentFormValue) =>
    [DOCUMENT_STATUS_SURRENDERED_AT_ORIGIN, DOCUMENT_STATUS_RECEIVED_AT_DESTINATION].includes(
      sd.document_status
    )
  );
};

const isEventDateNotPresent = (shipmentDocuments?: ShipmentDocumentFormValue[]) => {
  return (shipmentDocuments || []).reduce((acc: boolean, sd: ShipmentDocumentFormValue) => {
    if (
      [DOCUMENT_STATUS_SURRENDERED_AT_ORIGIN, DOCUMENT_STATUS_RECEIVED_AT_DESTINATION].includes(
        sd.document_status
      ) &&
      !sd.document_status_event_date
    ) {
      acc = true;
    }
    return acc;
  }, false);
};

const MessageShipmentDocumentDetails = forwardRef(function MessageShipmentDocumentDetails(
  props: {
    shipmentDocuments: ShipmentDocumentFieldValue[];
    freightType: string;
    form?: FormInstance;
  },
  ref
): JSX.Element {
  const { shipmentDocuments, freightType, form } = props;
  const [errorsPresent, setErrorsPresent] = useState(false);
  const [showDocumentStatusEventDate, setShowDocumentStatusEventDate] = useState(
    isStatusEventDateRequired(form?.getFieldValue('shipment_documents'))
  );

  useImperativeHandle(ref, () => ({
    runValidation() {
      const foundError = isEventDateNotPresent(form?.getFieldValue('shipment_documents'));
      setErrorsPresent(foundError);
      return foundError;
    },
  }));

  const onDocumentStatusChanged = useCallback(() => {
    setShowDocumentStatusEventDate(
      isStatusEventDateRequired(form?.getFieldValue('shipment_documents'))
    );
  }, [form]);

  const onStatusEventDateChanged = useCallback(() => {
    setErrorsPresent(isEventDateNotPresent(form?.getFieldValue('shipment_documents')));
  }, [form]);

  const columns = useMemo(() => {
    let documentBlTypeCol: Array<TableColumnType<ShipmentDocumentFieldValue>> = [];
    if (freightType === FREIGHT_TYPE_OCEAN) {
      documentBlTypeCol = [
        {
          title: 'Type',
          key: 'type',
          render: function renderDocumentBlTypeCol(
            text: string,
            record: ShipmentDocumentFieldValue,
            index: number
          ) {
            return renderBLType(index);
          },
        },
      ];
    }
    let documentStatusEventDateCol: Array<TableColumnType<ShipmentDocumentFieldValue>> = [];
    if (showDocumentStatusEventDate) {
      documentStatusEventDateCol = [
        {
          title: 'Status Event Date',
          key: 'document_status_event_date',
          render: function renderDocumentStatusEventDateCol(
            text: string,
            record: ShipmentDocumentFieldValue,
            index: number
          ) {
            return renderDocumentStatusEventDate(index, errorsPresent, onStatusEventDateChanged);
          },
        },
      ];
    }
    return [
      {
        title: 'Name',
        key: 'name',
        render: function renderDocumentName(
          text: string,
          record: ShipmentDocumentFieldValue,
          index: number
        ) {
          if (record.document_type?.value) {
            const docName = DOCUMENT_NAME_MAPPING[freightType][record.document_type?.value];
            return (
              <>
                <span>{docName}</span>
                <span>{renderDocumentType(index)}</span>
              </>
            );
          }
          return '';
        },
      },
      {
        title: 'Reference No#',
        key: 'number',
        render: function renderDocumentNumberCol(text: string, record: ShipmentDocumentFieldValue) {
          return record.shipment_number?.value || '';
        },
      },
      ...documentBlTypeCol,
      {
        title: 'Status',
        key: 'status',
        render: function renderDocumentStatusCol(
          text: string,
          record: ShipmentDocumentFieldValue,
          index: number
        ) {
          return renderDocumentStatus(freightType, index, onDocumentStatusChanged);
        },
      },
      ...documentStatusEventDateCol,
    ];
  }, [
    freightType,
    onDocumentStatusChanged,
    showDocumentStatusEventDate,
    errorsPresent,
    onStatusEventDateChanged,
  ]);

  return (
    <>
      {errorsPresent && <Text type="danger">Please fill required fields</Text>}
      <Table
        columns={columns}
        dataSource={shipmentDocuments}
        pagination={false}
        size="small"
        rowKey={(row) => row.id.value || ''}
      />
    </>
  );
});

export default MessageShipmentDocumentDetails;
