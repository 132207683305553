import React, { useState } from 'react';
import { TeamOutlined } from '@ant-design/icons';

import { Card } from 'common';
import { useCompanyView } from '../CompanyView';
import { WithPermission } from '@shipmnts/pixel-hub';
import { PERMISSION_CUSTOMER_CREATE_EDIT, PERMISSION_CUSTOMER_VIEW } from 'network/permissions';
import TeamDetailsFormModal from 'network/components/TeamDetailsForm';
import { Button } from '@shipmnts/pixel-hub';
import { TeamFormTable } from 'common';

export const TeamCard = () => {
  const { company, loading, onCompanyUpdate } = useCompanyView();
  const [editMode, setEditMode] = useState(false);

  return (
    <WithPermission permission={{ name: PERMISSION_CUSTOMER_VIEW, docType: 'Network::Company' }}>
      <Card
        header={{ title: 'Teams', icon: <TeamOutlined /> }}
        style={{ width: '400px', height: '500px' }}
        loading={loading}
        Action={
          <WithPermission
            permission={{ name: PERMISSION_CUSTOMER_CREATE_EDIT, docType: 'Network::Company' }}
          >
            <Button
              disabled={company?.is_disabled}
              onClick={() => !!!company?.is_disabled && setEditMode(true)}
              size="small"
            >
              {'Edit'}
            </Button>
          </WithPermission>
        }
      >
        <div
          style={{
            display: 'grid',
            width: '100%',
            marginTop: '0.5em',
            overflow: 'scroll',
            overflowX: 'hidden',
            maxHeight: '90%',
          }}
          className="custom-scrollbar"
        >
          <TeamFormTable
            key={JSON.stringify(company)}
            value={company?.teams ?? []}
            reference_type="Network::Company"
            editable={false}
          />
        </div>
      </Card>
      {editMode && company && (
        <>
          <TeamDetailsFormModal
            key={JSON.stringify(company)}
            initialValues={{
              team: company.teams ?? [],
            }}
            open={editMode}
            setIsOpen={setEditMode}
            reference_id={company.id}
            reference_type="Network::Company"
            onSuccess={onCompanyUpdate as any} //todo-team fix this
            style={{ maxWidth: '900px', minWidth: '900px' }}
          />
        </>
      )}
    </WithPermission>
  );
};
