import React from 'react';
import DescriptionItem from './DescriptionItem';
import { ItemValue } from 'common/models/Item';
interface BasicDetailProps {
  product: ItemValue;
}
export default function BasicDetail(props: BasicDetailProps) {
  const product = props?.product;
  const data = [
    {
      label: 'Product Name',
      content: product?.product_name || '-',
    },
    {
      label: 'HSN/SAC Code',
      content: product?.gst_hsn_code || '-',
    },
    {
      label: 'Country Of Origin',
      content: product?.country_of_origin || '-',
    },
    { label: 'Customer Name', content: product?.customer?.registered_name || '-' },
    { label: `Customer's Product Ref Code`, content: product?.customer_ref_code || '-' },
  ];

  return (
    <div style={{ padding: '15px', borderBottom: '1px solid #9FB1BD' }}>
      <DescriptionItem title="Basic Detail" data={data} />
    </div>
  );
}
