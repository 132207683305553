import { PRIMARY_TYPE } from '../constants';
import { Action } from '../models';
import { editDriverActionRender } from './driverActionRenderer';

export const editDriver = (driver: any): Action => {
  return {
    type: PRIMARY_TYPE,
    displayComponent: 'Edit Driver',
    key: 'edit_driver',
    isEnable: true,
    performAction: editDriverActionRender,
  };
};
export const getDriverActions = (driver: any) => {
  return [editDriver(driver)];
};
