import React, { useEffect, useCallback, useState } from 'react';
import { Select, Row, Col, Typography, Spin } from '@shipmnts/pixel-hub';
import { omit as _omit } from 'lodash';
import { OptGroup } from 'rc-select';
import { DriverValue, shipmentFields, VehicleValue } from 'operations';
import { gql, useQuery } from '@apollo/client';
import { ShipmentValue } from 'operations/models/Shipment';
import { getVehicleData } from 'operations/modules/shipment/components/ShipmentForm/assignLoadHelpers';
const { Option } = Select;

export interface VehicleLoadType {
  shipmentId?: string;
  shipmentNumber?: string;
  loadCount?: number;
  vehicle?: VehicleValue;
  driver?: DriverValue | null;
  loadType?: string;
  value?: string;
}

export interface VehicleLoadSearchProps {
  value?: VehicleLoadType | undefined;
  onChange?: (value: VehicleLoadType | Array<VehicleLoadType> | undefined | null) => void;
  selectMode?: 'multiple';
  ownership_type?: string;
  disabled?: boolean;
  placeholder?: string;
  searchBy?: string;
  tripOrderNo?: string[];
  enableCreateVehicle?: boolean;
  selectProps?: any;
}

export const SEARCH_TRIPS = gql`
  query search_trips($split_from_order_ids: [ID!]!, $shipment_types: [String!]!) {
    search_trips(split_from_order_ids: $split_from_order_ids, shipment_types: $shipment_types) {
      ...shipmentFields
    }
  }
  ${shipmentFields}
`;

const getSelectValue = (value: VehicleLoadType | undefined | null) => {
  return value
    ? {
        value: value.shipmentId,
        label: value?.vehicle?.vehicle_license_plate_number,
        key: value.shipmentId,
      }
    : undefined;
};

const VehicleLoadSearch = React.memo(function VehicleLoadSearch(
  props: VehicleLoadSearchProps
): JSX.Element {
  const { value, onChange, selectMode, disabled, tripOrderNo, selectProps } = props;
  const [selectValue, setSelectValue] = useState(getSelectValue(value));
  const [vehicles, setVehicles] = useState<Array<VehicleLoadType>>([]);
  const { loading, data, error } = useQuery(SEARCH_TRIPS, {
    variables: { split_from_order_ids: tripOrderNo, shipment_types: ['back_to_back', 'house'] },
  });

  useEffect(() => {
    setSelectValue(getSelectValue(value));
  }, [value]);

  useEffect(() => {
    if (!loading && !error && data?.search_trips) {
      const vehicles = data?.search_trips
        .filter((trip: ShipmentValue) => !!trip.vehicle)
        .map((trip: ShipmentValue) => getVehicleData(trip));
      setVehicles(vehicles);
    }
  }, [loading, data, error]);

  const onVehicleChange = useCallback(
    (changeValue: any, data: any) => {
      const vehicle_value: any | undefined = data?.vehicle
        ? _omit(data?.vehicle, '__typename')
        : undefined;

      if (onChange) onChange(vehicle_value);
    },
    [onChange]
  );

  const onSearch = (query: string) => {
    if (!loading && !error && data?.search_trips) {
      const vehicles = data?.search_trips
        .filter((trip: ShipmentValue) => !!trip.vehicle)
        .map((trip: ShipmentValue) => getVehicleData(trip));
      if (query.length > 0) {
        const updatedVehicles = vehicles?.filter((vehicle: VehicleLoadType) => {
          return vehicle?.vehicle?.vehicle_license_plate_number
            ?.toLowerCase()
            ?.includes(query?.toLowerCase());
        });
        setVehicles(updatedVehicles || []);
      } else {
        setVehicles(vehicles || []);
      }
    }
  };

  const { Text } = Typography;
  const getLabelValueItem = (label: string, value: any) => {
    if (!value) return <></>;
    return (
      <small>
        <span style={{ color: '#586988', marginRight: '5px' }}>{label}:</span>
        <span style={{ fontWeight: 500, color: '#586988', marginRight: '10px' }}>{value}</span>
      </small>
    );
  };
  const getSelectOption = (vehicle: VehicleLoadType, style: any) => {
    return (
      <Option
        title={vehicle.vehicle?.vehicle_license_plate_number}
        key={vehicle?.shipmentId}
        value={vehicle?.shipmentId}
        vehicle={vehicle}
      >
        <Row style={{ width: '100%' }} className="flex">
          <Col span={18}>
            <Text>
              <div>
                <b>{vehicle?.vehicle?.vehicle_license_plate_number}</b>
              </div>
            </Text>
            <Text>
              <div>
                <Row>{getLabelValueItem('Shipment #', vehicle?.shipmentNumber)}</Row>
                <Row>{getLabelValueItem(vehicle?.loadType || '', vehicle?.loadCount)}</Row>
                <Row>{getLabelValueItem('Driver', vehicle?.driver?.driver_name)}</Row>
              </div>
            </Text>
          </Col>
        </Row>
      </Option>
    );
  };

  const getDropdown = (vehicles: VehicleLoadType[]) => {
    const vehicleByLoad: { [key: string]: VehicleLoadType[] } = {
      Unassigned: [],
      Assigned: [],
    };

    vehicles.forEach((vehicle) => {
      if (vehicle.loadCount) {
        vehicleByLoad['Assigned'] = [vehicle, ...(vehicleByLoad['Assigned'] || [])];
      } else {
        vehicleByLoad['Unassigned'] = [vehicle, ...(vehicleByLoad['Unassigned'] || [])];
      }
    });
    return Object.keys(vehicleByLoad).map((loadType) => {
      if (!vehicleByLoad[loadType].length) return <></>;
      return (
        <OptGroup title={loadType} label={loadType} key={loadType}>
          {vehicleByLoad[loadType].map((vehicle) => {
            return getSelectOption(vehicle, { background: '#CEDCF4', color: '#6C95DF' });
          })}
        </OptGroup>
      );
    });
  };

  let notFoundContent = undefined;
  if (loading) {
    notFoundContent = <Spin size="small" />;
  } else if (error) {
    notFoundContent = (
      <div className="text-color-secondary">Unable to fetch users. Try searching again</div>
    );
  }

  return (
    <>
      <Select
        mode={selectMode}
        value={selectValue}
        onChange={onVehicleChange}
        placeholder={props.placeholder || 'Search for vehicles...'}
        notFoundContent={notFoundContent}
        filterOption={false}
        showSearch
        onSearch={onSearch}
        allowClear
        optionLabelProp="title"
        labelInValue
        popupMatchSelectWidth={300}
        style={{ width: '100%' }}
        disabled={disabled}
        {...selectProps}
      >
        {!loading && !error && vehicles && vehicles.length > 0 && getDropdown(vehicles)}
      </Select>
    </>
  );
});

export default VehicleLoadSearch;
