import { useApolloClient } from '@apollo/client';
import { Drawer } from '@shipmnts/pixel-hub';
import { useEmailTemplatesContext } from 'common';
import { ShipmentValue } from 'operations/models/Shipment';
import SendDocumentForm from 'operations/modules/shipment/components/PreAlertAndDispatch/SendDocumentForm';
import React from 'react';

interface ShipmentActionsDrawerProps {
  shipment: ShipmentValue;
  actionName: string;
  defaultShipmentPartyType: string;
  emailModaltitle: string;
  emailTemplateActionName: string;
  onClose: () => void;
  onSuccess?: () => void;
}
export default function PreAlertAndDispatchDrawer(props: ShipmentActionsDrawerProps) {
  const {
    onClose,
    shipment,
    actionName,
    defaultShipmentPartyType,
    emailModaltitle,
    emailTemplateActionName,
    onSuccess,
  } = props;

  const client = useApolloClient();
  const { templates } = useEmailTemplatesContext();

  const job_number = shipment?.job_number;
  const shipment_id = shipment?.id;
  const shipment_type = shipment?.shipment_type;

  const titleMapping: { [key: string]: string } = {
    send_pre_alert: `Send Pre-alert To Agent For Job # ${
      shipment?.job_number ? shipment?.job_number : shipment?.id || ''
    }`,
    dispatch_document: `Dispatch Document For Job # ${job_number || shipment_id || ''}`,
  };

  const primaryActionNameMapping: { [key: string]: string } = {
    send_pre_alert: 'Send Pre alert',
    dispatch_document: `Dispatch`,
  };

  const onCloseEmailDrawer = () => {
    onClose();
    if (onSuccess) onSuccess();
  };
  return (
    <Drawer title={titleMapping[actionName]} open={true} width="60%" onClose={onClose}>
      <SendDocumentForm
        shipment_id={shipment_id}
        job_number={job_number || undefined}
        shipment_type={shipment_type ? shipment_type : undefined}
        templates={templates}
        docgen_url={process.env.DOCGEN_URL || ''}
        network_url={process.env.NETWORK_API_URL || ''}
        onClose={onCloseEmailDrawer}
        onSuccess={onSuccess}
        primaryActionName={primaryActionNameMapping[actionName]}
        emailTemplateActionName={emailTemplateActionName}
        emailModalTitle={emailModaltitle}
        defaultShipmentPartyType={defaultShipmentPartyType}
        showDispatchDetails={actionName === 'dispatch_document' ? true : undefined}
        client={client}
      />
    </Drawer>
  );
}
