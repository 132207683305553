/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree';
import Address from 'network/models/Address';
import EmailTemplate from 'network/models/EmailTemplate';

const CompanyEmailPreference = types.model({
  id: types.maybe(types.identifier),
  email_template_id: types.maybe(types.string),
  company_id: types.maybe(types.string),
  address_id: types.maybe(types.maybeNull(types.string)),
  to_emails: types.maybe(types.array(types.string)),
  cc_emails: types.maybe(types.array(types.string)),
  address: types.maybe(types.maybeNull(Address)),
  email_template: types.maybe(EmailTemplate),
});

export interface CompanyEmailPreferenceValue extends Instance<typeof CompanyEmailPreference> {}
export interface CompanyEmailPreferenceIn extends SnapshotIn<typeof CompanyEmailPreference> {}
export interface CompanyEmailPreferenceOut extends SnapshotOut<typeof CompanyEmailPreference> {}

export default CompanyEmailPreference;
