import { types, Instance, SnapshotIn, SnapshotOut, IAnyModelType } from 'mobx-state-tree';

import ContainerRequest from './ContainerRequest';
import UserAccount from './UserAccount';
import Company from './Company';
import Address from './Address';
import Location from './Location';
import Activity from './Activity';
import BranchAccount from './BranchAccount';
import ProductOrderItem from './ProductOrderItem';
import { PortObject } from './Shipment';
import { SessionDataValue } from './SessionData';
import SalesPerson from 'common/models/SalesPerson';

// import { DayJsDate } from '@shipmnts/pixel-hub';

const ProductOrder = types
  .model({
    id: types.identifier,
    branch: types.maybe(types.maybeNull(types.late((): IAnyModelType => BranchAccount))),

    business_through: types.maybe(types.maybeNull(types.string)),
    sales_person: types.maybe(types.maybeNull(types.late((): IAnyModelType => SalesPerson))),

    buyer: types.maybe(types.maybeNull(Company)),
    buyer_address: types.maybe(types.maybeNull(Address)),

    seller: types.maybe(types.maybeNull(Company)),
    seller_address: types.maybe(types.maybeNull(Address)),

    subagent: types.maybe(types.maybeNull(Company)),
    subagent_address: types.maybe(types.maybeNull(Address)),

    ship_from_company: types.maybe(types.maybeNull(Company)),
    ship_from_address: types.maybe(types.maybeNull(Address)),

    ship_to_company: types.maybe(types.maybeNull(Company)),
    ship_to_address: types.maybe(types.maybeNull(Address)),
    print_as: types.maybeNull(
      types.model({
        shipper: types.maybe(types.maybeNull(types.string)),
        consignee: types.maybe(types.maybeNull(types.string)),
        seller: types.maybe(types.maybeNull(types.string)),
        buyer: types.maybe(types.maybeNull(types.string)),
        subagent: types.maybe(types.maybeNull(types.string)),
      })
    ),
    incoterm: types.maybe(types.maybeNull(types.string)),
    order_currency: types.maybe(types.maybeNull(types.string)),
    payment_term: types.maybe(types.maybeNull(types.string)),
    priority: types.maybe(types.maybeNull(types.string)),
    exchange_rate: types.maybe(types.maybeNull(types.number)),

    documentation_instructions: types.maybe(types.maybeNull(types.string)),
    freight_terms: types.maybe(types.maybeNull(types.string)),
    freight_type: types.maybe(types.maybeNull(types.string)),
    load_type: types.maybe(types.maybeNull(types.string)),

    place_of_carrier_receipt: types.maybe(types.maybeNull(Location)),
    place_of_carrier_delivery: types.maybe(types.maybeNull(Location)),
    port_of_discharge: types.maybe(types.maybeNull(types.late((): IAnyModelType => PortObject))),
    port_of_loading: types.maybe(types.maybeNull(types.late((): IAnyModelType => PortObject))),

    created_at: types.maybe(types.maybeNull(types.number)),
    created_by: types.maybe(types.maybeNull(UserAccount)),

    seller_bank_account: types.maybe(types.maybeNull(types.string)),
    seller_bank_account_details: types.maybe(types.maybeNull(types.string)),

    split_shipment_allowed: types.maybe(types.maybeNull(types.boolean)),
    transhipment_allowed: types.maybe(types.maybeNull(types.boolean)),
    terms_and_conditions: types.maybe(types.maybeNull(types.string)),
    terms_and_conditions_details: types.maybe(types.maybeNull(types.string)),
    sales_order_number: types.maybe(types.maybeNull(types.string)),
    purchase_order_number: types.maybe(types.maybeNull(types.string)),
    type: types.maybe(types.maybeNull(types.string)),
    container_requests: types.maybe(types.array(ContainerRequest)),
    product_order_items: types.maybe(
      types.array(types.late((): IAnyModelType => ProductOrderItem))
    ),

    estimated_cargo_ready_date: types.maybe(types.maybeNull(types.number)),
    target_delivery_date: types.maybe(types.maybeNull(types.number)),
    target_shipping_date: types.maybe(types.maybeNull(types.number)),
    sales_order_date: types.maybe(types.maybeNull(types.number)),
    purchase_order_date: types.maybe(types.maybeNull(types.number)),
    is_target_delivery_date_appliable: types.maybe(types.maybeNull(types.boolean)),
    is_cargo_ready_date_appliable: types.maybe(types.maybeNull(types.boolean)),

    total_order_amount: types.maybe(types.maybeNull(types.number)),
    total_shipment_planned_qty: types.maybe(types.maybeNull(types.number)),
    status: types.maybe(types.maybeNull(types.string)),
    last_comment: types.maybe(types.maybeNull(Activity)),
    last_status_update: types.maybe(types.maybeNull(Activity)),
  })
  .views((self) => ({
    getAllPartiesRolesMapping: function (user: SessionDataValue) {
      const all_companies: any[] = [];
      if (self.buyer) {
        all_companies.push({
          id: self.buyer.id,
          registered_name: self.buyer.registered_name,
          address_id: self.buyer_address?.id,
          role: 'Buyer',
        });
      }
      if (self.seller) {
        all_companies.push({
          id: self.seller.id,
          registered_name: self.seller.registered_name,
          address_id: self.seller_address?.id,
          role: 'Seller',
        });
      }
      const default_company = user?.company_account?.default_company;
      if (default_company && default_company?.id) {
        all_companies.push({
          role: 'Your Company',
          id: default_company?.id,
          registered_name: default_company?.registered_name,
          address_id: self.branch?.default_address?.id,
        });
      }
      return all_companies;
    },
  }));
export type ProductOrderValue = Instance<typeof ProductOrder>;
export type ProductOrderIn = SnapshotIn<typeof ProductOrder>;
export type ProductOrderOut = SnapshotOut<typeof ProductOrder>;

export default ProductOrder;
