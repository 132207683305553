import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useMutation } from '@apollo/client';
import {
  Card,
  Col,
  Input,
  message,
  Drawer,
  Form,
  UploadedDocuments,
  Row,
  dayjsGenerateConfig,
} from '@shipmnts/pixel-hub';
import { useSession } from 'common';
import BookingOrderConfirmation from 'operations/modules/booking/components/BookingOrderForm/BookingOrderConfirmation';
import { DrawerFooter } from '@shipmnts/pixel-hub';
import { UPDATE_OCEAN_TRANSPORT_ORDER_STATUS } from 'operations/modules/booking/graphql/oceanTransportOrder';
import { OceanTransportOrderValue } from 'operations/models/OceanTransportOrder';
import { RoutingNodesHashValue } from 'operations/components/RoutingDetails';
import { RoutingLegValue } from 'operations/models/RoutingLeg';
import { RoutingNodeValue } from 'operations/models/RoutingNode';
import { LocationValue } from 'operations/models/Location';
import { RoutingDetailsValue } from 'operations/components/RoutingDetails';
import { convertDatesTounix, convertValuesToDayJs, Dayjs } from '@shipmnts/pixel-hub';
import { errorMessageHandlerGraphQL } from 'common';

import BookingRoutingDetails from 'operations/modules/booking/components/BookingOrderForm/BookingRoutingDetails';
import { getMainHopEtd } from 'operations/models/RoutingLeg';
import { BOOKING_TYPE_OCEAN_TRANSPORT_ORDER } from 'operations/modules/booking/constants';
import { BOOKING_TYPE_SHIPPING_LINE } from 'network/baseConstants';
import { ROW_GUTTER } from 'operations/modules/shipment/constants';
import BookingOrderNewDesign from 'operations/modules/booking/components/BookingOrderForm/BookingOrderNewDesign';
interface ConfirmBookingFormValues {
  booking_number?: string;
  empty_pickup_location?: LocationValue;
  routing_details?: RoutingDetailsValue;
  valid_till_date?: Dayjs;
  gate_open_date?: Dayjs;
  si_cutoff_date?: Dayjs;
  gate_close_date?: Dayjs;
  doc_cutoff_date?: Dayjs;
  voyage_schedule_id?: string;
}

const getConfirmBookingPayload = (values: ConfirmBookingFormValues) => {
  const { booking_number, empty_pickup_location, routing_details } = values;
  const payload = {
    booking_number: booking_number,
    empty_pickup_location_id: empty_pickup_location?.id,
    voyage_schedule_id: values?.voyage_schedule_id,
    ...convertDatesTounix(values, [
      'valid_till_date',
      'gate_open_date',
      'si_cutoff_date',
      'gate_close_date',
      'doc_cutoff_date',
    ]),
    routing_legs: (routing_details?.routing_legs || []).map((rl: RoutingLegValue) => {
      const {
        vessel,
        estimated_time_of_departure,
        estimated_time_of_arrival,
        global_carrier,
        ...restRL
      } = rl;
      return {
        ...restRL,
        global_carrier_id: global_carrier?.id,
        vessel_id: vessel?.imo,
        estimated_time_of_departure: estimated_time_of_departure?.unix(),
        estimated_time_of_arrival: estimated_time_of_arrival?.unix(),
        vessel: undefined,
        global_carrier: undefined,
      };
    }),
    routing_nodes: Object.values(routing_details?.routing_nodes || {}).map(
      (rn: RoutingNodeValue) => {
        const { location, terminal, company, address, ...restRN } = JSON.parse(JSON.stringify(rn));
        return {
          ...restRN,
          location_id: location?.id,
          location: undefined,
          terminal: undefined,
          company: undefined,
          address: undefined,
        };
      }
    ),
  };
  return payload;
};
const date_fields = [
  'valid_till_date',
  'gate_open_date',
  'si_cutoff_date',
  'gate_close_date',
  'doc_cutoff_date',
];

const ConfirmBookingForm = observer(function ConfirmBookingForm(props: {
  oto: OceanTransportOrderValue;
  onSuccess?: (oto: OceanTransportOrderValue) => void;
  onClose: () => void;
}): JSX.Element {
  const { oto, onSuccess, onClose } = props;
  const [confirmBooking, { data, loading: confirmLoading, error }] = useMutation(
    UPDATE_OCEAN_TRANSPORT_ORDER_STATUS
  );
  const sessionData = useSession();

  useEffect(() => {
    if (!error && data?.update_ocean_transport_order_status) {
      message.success('Booking Confirmed !');
      onClose();
      if (onSuccess) onSuccess(data?.update_ocean_transport_order_status);
    }
  }, [error, data, onSuccess, onClose]);

  const routingLegs = oto.routing_legs;
  const routing_nodes: RoutingNodesHashValue = {};
  const routing_legs = routingLegs.map((rl: RoutingLegValue) => {
    if (rl?.origin?.id) routing_nodes[rl.origin.id] = rl.origin;
    if (rl?.destination?.id) routing_nodes[rl.destination.id] = rl.destination;
    return {
      ...rl,
      origin: undefined,
      destination: undefined,
      origin_id: rl?.origin?.id,
      destination_id: rl?.destination?.id,
    };
  });
  const etd = getMainHopEtd(routing_legs);
  const [form] = Form.useForm();
  return (
    <Drawer
      title={`Confirm Booking`}
      width="60%"
      open={true}
      onClose={onClose}
      footer={
        <DrawerFooter
          saveText="Confirm Booking"
          loading={confirmLoading}
          onClose={onClose}
          onSave={form.submit}
        />
      }
    >
      <Form
        form={form}
        name="booking_confirmation"
        layout="vertical"
        onFinish={(values: ConfirmBookingFormValues) => {
          const payload = getConfirmBookingPayload(values);
          confirmBooking({
            variables: { id: oto.id, action: 'confirm', payload },
          });
        }}
        initialValues={{
          booking_number: oto.booking_number,
          routing_details: { routing_legs, routing_nodes },
          ...convertValuesToDayJs(oto, date_fields),
          ...(!oto.valid_till_date
            ? {
                valid_till_date: etd?.isBefore(dayjsGenerateConfig.getNow().add(3, 'day'))
                  ? etd.subtract(1, 'day')
                  : dayjsGenerateConfig.getNow().add(3, 'day'),
              }
            : {}),
          empty_pickup_location: oto.empty_pickup_location,
          origin_detention_free_days: oto.origin_detention_free_days,
          origin_demurrage_free_days: oto.origin_demurrage_free_days,
          destination_detention_free_days: oto.destination_detention_free_days,
          destination_demurrage_free_days: oto.destination_demurrage_free_days,
          voyage_schedule_id: oto.voyage_schedule_id,
        }}
      >
        {error && errorMessageHandlerGraphQL(error)}
        <Card title="Routing Details" className="custom-card" style={{ marginTop: '16px' }}>
          <Form.Item name="routing_details" noStyle>
            <BookingRoutingDetails
              disableNodes={true}
              validateVesselVoyage={true}
              globalCarrierId={oto?.global_carrier?.id}
              isReeferContainer={oto?.container_requests?.[0]?.is_reefer_container}
              bookingType={oto?.booking_type}
              allowVoyageScheduleSearch={!!oto?.voyage_schedule_id}
            />
          </Form.Item>
          <Form.Item noStyle name="voyage_schedule_id">
            <div />
          </Form.Item>
        </Card>
        <Card
          title="Booking Confirmation Details"
          className="custom-card"
          style={{ marginTop: '16px' }}
        >
          <Row gutter={ROW_GUTTER}>
            <BookingOrderNewDesign
              bookingType={oto.booking_type}
              loadType={oto.load_type}
              areCutoffRequired={oto?.isShippingLineBooking}
            />
          </Row>
        </Card>
        <Card title="Booking Cutoff Details" className="custom-card" style={{ marginTop: '16px' }}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.routing_details !== currentValues.routing_details
            }
          >
            {({ getFieldValue }) => {
              const routing_legs = getFieldValue('routing_details')?.routing_legs;
              const etd = getMainHopEtd(routing_legs);
              return (
                <BookingOrderConfirmation
                  etd={etd}
                  areCutoffRequired={oto?.isShippingLineBooking}
                  disableCutoff={oto?.getDisabledCutoffDateMapping()}
                  load_type={oto?.load_type}
                  booking_type={oto?.booking_type}
                />
              );
            }}
          </Form.Item>
          <Col span={12}>
            <Form.Item
              required={oto.booking_type === BOOKING_TYPE_SHIPPING_LINE}
              rules={[{ required: oto.booking_type === BOOKING_TYPE_SHIPPING_LINE }]}
              name="booking_number"
              label="Booking number"
            >
              <Input placeholder="Booking Number" />
            </Form.Item>
          </Col>
        </Card>

        <div style={{ margin: '16px 0 44px 0' }}>
          <UploadedDocuments
            sessionData={sessionData}
            parent_id={oto.id}
            parent_type={BOOKING_TYPE_OCEAN_TRANSPORT_ORDER}
            docgen_url={process.env.DOCGEN_URL || ''}
            initialUploadDocumentTags={['Booking confirmation']}
          />
        </div>
      </Form>
    </Drawer>
  );
});

export default ConfirmBookingForm;
