import React, { Fragment } from 'react';
import { useSession } from 'common';
import { BaseTable } from '@shipmnts/pixel-hub';
import { Column } from 'operations/models/Report';
import { COMMERICIAL_INVOICE_COLOR_HASH, COMMERICIAL_INVOICE_DISPLAY_HASH } from './constants';
import { CommercialInvoiceValue } from 'operations/models/CommercialInvoice';
import { ActionRendererDetailReport } from 'operations';
interface CommercialInvoiceListTableProps {
  commercialInvoices: CommercialInvoiceValue[] | undefined;
}

export const getHeight = (tableData: any, headerHeight?: number) => {
  if (!tableData) {
    return undefined;
  }
  let detailPanelHeight = 5 * 39 + 160;
  if ((tableData || []).length < 6) {
    detailPanelHeight = (tableData || []).length * 39 + (headerHeight || 100); // use headerHeight to adjust the height of the table, if needed
  }
  return detailPanelHeight;
};

function CommercialInvoiceListTable(props: CommercialInvoiceListTableProps) {
  const { commercialInvoices } = props;
  const session = useSession();

  const tableData: any[] = [];
  if (commercialInvoices) {
    commercialInvoices.forEach((element: any) => {
      const containerNumbers: any = [];
      element?.containers_commercial_invoices?.forEach((ele: any) => {
        containerNumbers.push(ele?.shipment_container?.container_number);
      });
      tableData.push({
        ...element,
        linked_containers: containerNumbers.join(', '),
        customs_document_number: element?.shipment_custom_detail?.custom_document_number,
      });
    });
  }

  const columnDefs: { [key: string]: Column } = {
    invoice_number: {
      headerName: 'Invoice #',
      field: 'invoice_number',
      colId: 'invoice_number',
      columnType: 'String',
      pinned: 'left',
      cellRenderer: (params: any) => {
        return (
          <a href={`/form/commercial_invoice/${params.data.id}`} target="_blank" rel="noreferrer">
            {params.value}
          </a>
        );
      },
    },
    invoice_date: {
      headerName: 'Invoice Date',
      field: 'invoice_date',
      colId: 'invoice_date',
      columnType: 'Date',
    },
    invoice_currency: {
      headerName: 'Invoice Currency',
      field: 'invoice_currency',
      colId: 'invoice_currency',
      editable: false,
      columnType: 'String',
    },
    exchange_rate: {
      headerName: 'Ex. Rate',
      field: 'exchange_rate',
      colId: 'exchange_rate',
      columnType: 'Float',
      valueGetter: (params: any) => {
        if (params.node?.isRowPinned()) return <></>;
        return params.data.exchange_rate;
      },
    },
    invoice_amount: {
      headerName: 'Invoice Value',
      field: 'invoice_amount',
      colId: 'invoice_amount',
      columnType: 'Float',
      valueGetter: (params: any) => {
        if (params.node?.isRowPinned()) return <></>;
        return params.data.invoice_amount;
      },
    },
    base_invoice_amount: {
      headerName: `Invoice Value (${session?.company_account?.default_currency})`,
      field: 'base_invoice_amount',
      colId: 'base_invoice_amount',
      columnType: 'Float',
    },
    fob_invoice_amount: {
      headerName: 'Invoice FOB Value',
      field: 'fob_invoice_amount',
      colId: 'fob_invoice_amount',
      columnType: 'Float',
    },
    cif_invoice_amount: {
      headerName: 'CIF Value',
      field: 'cif_invoice_amount',
      colId: 'cif_invoice_amount',
      columnType: 'Float',
    },
    payment_term: {
      headerName: 'Payment Term',
      field: 'payment_term',
      colId: 'payment_term',
      columnType: 'String',
    },

    linked_containers: {
      headerName: 'Linked Containers',
      field: 'linked_containers',
      colId: 'linked_containers',
      columnType: 'String',
    },
    customs_document_number: {
      headerName: 'Export Document Ref #',
      field: 'customs_document_number',
      colId: 'customs_document_number',
      columnType: 'String',
    },
    status: {
      headerName: 'Invoice Status',
      field: 'status',
      colId: 'status',
      columnType: 'String',
      pinned: 'right',
      lockPinned: true,
      cellRenderer: 'StatusRenderer',
      cellRendererParams: {
        filter_options: {
          colors: COMMERICIAL_INVOICE_COLOR_HASH,
          display_hash: COMMERICIAL_INVOICE_DISPLAY_HASH,
        },
      },
    },
    actions: {
      headerName: 'Actions',
      suppressMovable: true,
      suppressNavigable: true,
      colId: 'actions',
      field: 'actions',
      valueGetter: 'data',
      cellRenderer: 'ActionRendererDetailReport',
      cellRendererParams: {
        doc_type_id: 'Shipment::ShipmentInvoice',
      },
      pinned: 'right',
      lockPinned: true,
      headerComponent: 'StaticHeaderComponent',
    },
  };

  // const components = {

  // };

  return (
    <Fragment>
      <div style={{ paddingTop: '0px' }}>
        {
          <BaseTable
            reportName={'commercial_invoice_list'}
            columns={Object.values(columnDefs)}
            rowData={tableData}
            height={getHeight(tableData) || '200px'}
            reportConfig={{
              groupDefaultExpanded: 1,
              components: { ActionRendererDetailReport },
            }}
          />
        }
      </div>
    </Fragment>
  );
}

export default CommercialInvoiceListTable;
