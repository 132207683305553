import React, { useMemo, useState, useCallback } from 'react';
import { Card } from '../Card';
import { Button } from '@shipmnts/pixel-hub';
import { useCompanyView } from '../CompanyView';
import { TASK_COMPLETED, TASK_PENDING } from '../helpers';
import { useQuery } from '@apollo/client';
import { CustomIcon, TaskForm, TaskType, TaskListCard } from '@shipmnts/pixel-hub';
import { FETCH_TASKS } from 'network/graphql/task';

const CreateTask = ({ onClick }: { onClick?: () => void }) => {
  return (
    <Button size="small" onClick={onClick} className="action_button">
      {`${String.fromCodePoint(43)} Create`}
    </Button>
  );
};

export const TaskCard = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { company } = useCompanyView();

  const [completeCollapse, setCompleteCollapsed] = useState(false);

  const { data, loading, refetch } = useQuery(FETCH_TASKS, {
    variables: {
      resource: {
        resource_id: company?.id,
        resource_type: 'Network::Company',
      },
    },
    fetchPolicy: 'no-cache',
  });

  const upcomingTasks: TaskType[] = useMemo(
    () => data?.fetch_tasks?.filter((task: TaskType) => task.task_status === TASK_PENDING) || [],
    [data]
  );
  const completedTasks: TaskType[] = useMemo(
    () => data?.fetch_tasks?.filter((task: TaskType) => task.task_status === TASK_COMPLETED) || [],
    [data]
  );

  const onSuccess = useCallback(() => {
    setOpenDrawer(false);
    refetch();
  }, [setOpenDrawer, refetch]);

  return (
    <Card
      header={{
        title: `Upcoming Tasks ${upcomingTasks?.length ? '(' + upcomingTasks.length + ')' : ''}`,
        icon: <></>,
      }}
      loading={loading}
      Action={<CreateTask onClick={() => setOpenDrawer(true)} />}
      style={{
        width: '400px',
        maxHeight: '400px',
        overflow: 'scroll',
        scrollbarWidth: 'thin',
        scrollBehavior: 'smooth',
      }}
    >
      <>
        {data?.fetch_tasks?.length > 0 ? (
          <div>
            {upcomingTasks?.length > 0 && (
              <div style={{ padding: '.75em .25em' }}>
                {upcomingTasks
                  .sort((a, b) => ((a.due_date ?? 0) < (b.due_date ?? 0) ? -1 : 1))
                  ?.map((task) => (
                    <TaskListCard key={task?.id} data={task} refetch={refetch} />
                  ))}
              </div>
            )}
            {completedTasks.length > 0 && (
              <>
                <div
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                  <h4 style={{ margin: 0, color: '#586988', fontWeight: '600px' }}>
                    {`Completed Tasks (${completedTasks.length})`}
                  </h4>
                  <CustomIcon
                    icon={'Chevron'}
                    onClick={() => setCompleteCollapsed((prev) => !prev)}
                    style={{
                      transform: `scale(1.2) rotate(${completeCollapse ? '-180deg' : '0deg'})`,
                      transition: 'all 0.2s ease',
                      cursor: 'pointer',
                      color: '#586988',
                      fontWeight: '600px',
                    }}
                  />
                </div>
                {completeCollapse ? (
                  <div style={{ padding: '.75em .25em' }}>
                    {completedTasks
                      .sort((a, b) => ((a.closed_date ?? 0) < (b.closed_date ?? 0) ? 1 : -1))
                      .map((task) => (
                        <TaskListCard key={task?.id} data={task} refetch={refetch} />
                      ))}
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        ) : (
          <div
            style={{
              textAlign: 'center',
              opacity: 0.4,
              padding: '2em',
            }}
          >
            No Tasks Created Yet
          </div>
        )}
        {company && (
          <TaskForm
            visible={openDrawer}
            setVisible={setOpenDrawer}
            onSuccess={onSuccess}
            task={{
              primary_resource_id: company.id,
              primary_resource_type: 'Network::Company',
              task_resources: [
                {
                  resource_type: 'Network::Company',
                  resource_id: company.id,
                  resource: company,
                  disabled: true,
                },
              ],
            }}
          />
        )}
      </>
    </Card>
  );
};
