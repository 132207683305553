import { gql } from '@apollo/client';
import { orderCollaborationFields } from './orderCollaboration';

export const miscellaneousServiceOrderFields = gql`
  fragment miscellaneousServiceOrderFields on MiscellaneousServiceOrderObjectType {
    id
    name
    order_collaborations {
      ...orderCollaborationFields
    }
  }
  ${orderCollaborationFields}
`;

export const SAVE_MISCELLANEOUS_SERVICE_ORDER = gql`
  mutation save_miscellaneous_service_order(
    $miscellaneous_service_order: MiscellaneousServiceOrderInputType!
    $service_order_id: ID
    $booking_request_id: ID!
  ) {
    save_miscellaneous_service_order(
      miscellaneous_service_order: $miscellaneous_service_order
      service_order_id: $service_order_id
      booking_request_id: $booking_request_id
    ) {
      ...miscellaneousServiceOrderFields
    }
  }
  ${miscellaneousServiceOrderFields}
`;

export const DELETE_MISCELLANEOUS_SERVICE_ORDER = gql`
  mutation delete_miscellaneous_service_order($id: ID!) {
    delete_miscellaneous_service_order(id: $id) {
      message
    }
  }
`;
