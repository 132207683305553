import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Button, Layout, Result, Skeleton, Tabs } from '@shipmnts/pixel-hub';
import { Link, useLocation, useParams } from 'wouter';

import { ComponentWrapper, isCustomer, isLead, isVendor, useSession } from 'common';
import { CompanyAccountsData, CompanyValue } from 'network/models/Company';
import { GET_COMPANY } from 'network/graphql/company';
import { useErpNextConfig } from 'network/utils/ErpNextConfigDataWrapper';
import { getERPNextCompanyData } from 'network/erpnextApis';
import {
  hasPermission,
  ConditionalPermissions,
  AppHelmet,
  WithPermission,
  NoPermissionFallback,
} from '@shipmnts/pixel-hub';

import './index.less';

import { CompanyHeader } from './Header';
import { OverviewTab } from './Tabs/OverviewTab';
import { ContactsTab } from './Tabs/ContactsTab';
import { DocumentsTab } from './Tabs/DocumentsTab';
import { FinanceTab } from './Tabs/FinanceTab';
import { ActivityTab } from './Tabs/ActivityTab';

import {
  AccountDataStateType,
  CompanyViewContextType,
  CompanyViewType,
  TabBarOptionType,
  TabBarPropType,
} from './types';
import { PERMISSION_CUSTOMER_VIEW, PERMISSION_SUPPLIER_VIEW } from 'network/permissions';

import { NotificationTab } from './Tabs/NotificationTab';

// context: so that we can easily use different states across the tabs & cards
const CompanyViewContext = createContext<CompanyViewContextType | null>(null);

// components
const TabBar = ({ options, activeKey, setActiveKey }: TabBarPropType) => {
  const { permissions } = useSession();
  const items: any = [];
  // eslint-disable-next-line array-callback-return
  options.map((opt) => {
    if (!opt.permission) {
      return items.push({
        label: opt.label,
        key: opt.key.toString(),
      });
    } else if (hasPermission(permissions, opt?.permission)) {
      return items.push({
        label: opt.label,
        key: opt.key.toString(),
      });
    }
  });
  return (
    <Tabs
      className="view-tabs"
      tabBarStyle={{ background: 'white', paddingInline: '24px' }}
      activeKey={activeKey.toString()}
      onChange={(e) => setActiveKey(e)}
      items={items}
    />
  );
};

const getPermission = (
  is_customer: boolean,
  is_vendor: boolean
): { permission?: ConditionalPermissions; resource?: string } => {
  if (is_customer && is_vendor) {
    return {
      permission: {
        OR: [
          { name: PERMISSION_CUSTOMER_VIEW, docType: 'Network::Company' },
          { name: PERMISSION_SUPPLIER_VIEW, docType: 'Network::Company' },
        ],
      },
      resource: 'Customer/Supplier',
    };
  } else if (is_customer) {
    return {
      permission: { name: PERMISSION_CUSTOMER_VIEW, docType: 'Network::Company' },
      resource: 'Customer',
    };
  } else if (is_vendor) {
    return {
      permission: { name: PERMISSION_SUPPLIER_VIEW, docType: 'Network::Company' },
      resource: 'Supplier',
    };
  }

  return {};
};

const validateTabKey = (options: Array<TabBarOptionType>, key?: string) => {
  if (!key) return 'company_overview';
  const opt = options.find((op) => op.key === key);
  if (!!opt && !opt?.disable) return key;
  return 'company_overview';
};

const CompanyView = ({
  id,
  onClose,
  externalLink,
  internal,
  onError,
  tab: props_tab,
}: CompanyViewType): JSX.Element => {
  // hooks
  const params = useParams<{ company_id: string }>();
  const [location, navigate] = useLocation();
  const { permissions } = useSession();

  const company_data = window.history?.state?.company;
  const company_accounts_data = window.history?.state?.company_accounts_data;
  const refetchCompany = window.history?.state?.refetch;

  const searchParams = useMemo(() => new URLSearchParams(window.location.search), []);

  const company_id = (params?.company_id || id || '').toString();
  // contexts
  const { fetchConfigData } = useErpNextConfig();

  // states
  const [company, setCompany] = useState<CompanyValue | undefined>(company_data);
  const [{ accounts_data, accounts_error, accounts_loading }, setCompanyAccountsData] =
    useState<AccountDataStateType>({
      accounts_data: company_accounts_data,
      accounts_loading: false,
      accounts_error: false,
    });

  // queries & mutations
  const { loading, error, data, refetch } = useQuery(GET_COMPANY, {
    variables: { id: company_id },
    skip: !!company_data && !refetchCompany, // if we have company data & don't need to refetch
    fetchPolicy: 'network-only',
  });

  // callback to fetch accounts data
  const fetchAccountsData = useCallback(async (company_name?: string) => {
    setCompanyAccountsData((data) => ({ ...data, accounts_loading: true }));
    const { response, error } = await getERPNextCompanyData(company_name);
    if (response) {
      const accountsData = response.data?.message?.company;
      setCompanyAccountsData((prevData) => ({ ...prevData, accounts_data: accountsData }));
      setCompanyAccountsData((prevData) => ({ ...prevData, accounts_error: false }));
    } else if (error) {
      if (error instanceof Error)
        setCompanyAccountsData((prevData) => ({ ...prevData, accounts_error: error?.message }));
    }
    setCompanyAccountsData((data) => ({ ...data, accounts_loading: false }));
  }, []);

  // callback when company is refetched from actions
  const onRefetchCompany = useCallback(async () => {
    if (refetch) {
      const { data } = await refetch();
      if (data?.company) {
        setCompany(data?.company);
        fetchAccountsData(data?.company?.registered_name);
      }
    }
  }, [refetch, fetchAccountsData]);

  // effects
  // NOTE: change below two useEffect carefully, have designed it in such a way to prevent multiple api calls
  useEffect(() => {
    if (
      (!error && data?.company && (!company || data?.company?.id !== company?.id)) || // if we have data && it is different from company we already have (or if we don't have)
      (refetchCompany && data?.company) // if we have refetched data
    ) {
      setCompany(data?.company);
      // if it is customer or supplier then only we need accounts_data
      if (isCustomer(data?.company?.company_type) || isVendor(data?.company?.company_type)) {
        fetchAccountsData(data?.company?.registered_name);
      }
    } else if (error && onError) {
      onError();
    }
  }, [company_id, company, data, error, fetchAccountsData, accounts_data, refetchCompany, onError]);

  useEffect(() => {
    const state = { ...window.history.state };
    delete state?.company;
    delete state?.company_accounts_data;
    delete state?.refetch;
    navigate(location, { state: state, replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fetching config data ???
  useEffect(() => {
    if (fetchConfigData) fetchConfigData();
  }, [fetchConfigData]);

  useEffect(() => {
    // no loading + current company's id !== current company_id
    if (!loading && company_id !== company?.id && company_id !== data?.company?.id) {
      if (company_data) {
        setCompany(company_data);
        if (isCustomer(company_data?.company_type) || isVendor(company_data?.company_type)) {
          fetchAccountsData(company_data?.registered_name);
        }
      } else {
        refetch({ id: company_id });
      }
    }
  }, [company_id, company, company_data, loading, data, fetchAccountsData, refetch]);

  let fetchError: any = null;
  if (error || accounts_error) {
    const showLoading = (loading || accounts_loading) && !!!company;
    let errorCode: 500 | 404 | 'error' = 500,
      title = 'Something went wrong while fetching the company.',
      subTitle = error?.message,
      button = (
        <Button loading={showLoading} onClick={() => refetch()} type="primary">
          Retry
        </Button>
      );
    if (accounts_error) {
      errorCode = 'error';
      subTitle = accounts_error.toString();
      button = (
        <Button
          loading={accounts_loading}
          onClick={() => fetchAccountsData(company?.registered_name)}
          type="primary"
        >
          Retry
        </Button>
      );
    }
    if (error?.message.includes('Network::Company for given id Not found')) {
      errorCode = 404;
      title = `Company with ID: '${id}' does not exist`;
      subTitle = '';
      button = (
        <Button type="primary">
          <Link to="/">Go Home</Link>
        </Button>
      );
    }
    fetchError = <Result status={errorCode} title={title} subTitle={subTitle} extra={button} />;
  }

  // callback
  const onCompanyUpdate = useCallback(
    (company: CompanyValue, companyAccountsData?: CompanyAccountsData) => {
      setCompany(company);
      if (companyAccountsData)
        setCompanyAccountsData((prevData) => ({ ...prevData, accounts_data: companyAccountsData }));
    },
    []
  );

  const is_customer = isCustomer(company?.company_type);
  const is_vendor = isVendor(company?.company_type);
  const is_lead = isLead(company?.company_type);
  // just getting right permissions
  const permission = getPermission(is_customer, is_vendor);

  // options to show in tab bar
  const options: Array<TabBarOptionType> = useMemo(
    () => [
      {
        key: 'company_overview',
        label: 'Overview',
        content: OverviewTab,
        disable: false,
      },
      {
        key: 'company_activities',
        label: 'Activity Feed',
        content: ActivityTab,
        disable: false,
      },
      {
        key: 'company_finance',
        label: 'Finance',
        content: FinanceTab,
        disable:
          !((is_customer && is_lead) || is_vendor) && // either customer or vendor
          !hasPermission(permissions, {
            OR: [
              { name: PERMISSION_CUSTOMER_VIEW, docType: 'Network::Company' },
              { name: PERMISSION_SUPPLIER_VIEW, docType: 'Network::Company' },
            ],
          }),
      },
      {
        key: 'company_contacts',
        label: 'Contacts & Addresses',
        content: ContactsTab,
        disable: false,
      },
      {
        key: 'company_kyc_docs',
        label: 'KYC & Documents',
        content: DocumentsTab,
        disable: false,
      },
      {
        key: 'notification_preference',
        label: 'Notification Preferences',
        content: NotificationTab,
        disable: false,
      },
    ],
    [is_customer, is_lead, is_vendor, permissions]
  );

  // to access component mapped to option more easily
  const component_map: { [key: string]: { Component: any; props: any } } = options.reduce(
    (acc, option) => {
      if (option?.disable) return acc;
      acc[option.key] = { Component: option.content, props: option.props };
      return acc;
    },
    {} as { [key: string]: { Component: any; props: any } }
  );

  // Tab handling based on url query params
  const tab = searchParams.get('tab')
    ? validateTabKey(options, searchParams.get('tab') || '')
    : null;
  // for current active tab
  const [tabKey, setTabKey] = useState<string>(
    validateTabKey(options, props_tab) || tab || 'company_overview'
  );

  useEffect(() => {
    if (searchParams.has('tab')) {
      searchParams.delete('tab');
      navigate(`${location}${searchParams.toString()}`, { replace: true });
    }
  }, [searchParams, location, navigate]);

  const { Component, props } = component_map[tabKey];

  return (
    <ComponentWrapper loading={false} error={onError ? undefined : error} refetch={refetch}>
      <Layout style={{ backgroundColor: '#F5F5F5', height: 'content-fit', minHeight: '100%' }}>
        <AppHelmet>
          <title>{company?.registered_name}</title>
        </AppHelmet>
        {fetchError}
        {!fetchError && company && (
          <CompanyViewContext.Provider
            value={{
              company,
              accounts_data,
              accounts_loading,
              loading,
              fetchError,
              refetch: onRefetchCompany,
              setCompany,
              setCompanyAccountsData,
              onClose,
              onCompanyUpdate,
              is_customer,
              is_vendor,
              is_lead,
            }}
          >
            {!!company && (
              <WithPermission
                permission={permission?.permission}
                fallback={<NoPermissionFallback action="view" resource={permission?.resource} />}
              >
                <Skeleton avatar active style={{ padding: '1em' }} loading={loading}>
                  <CompanyHeader
                    showFooter={true}
                    externalLink={externalLink}
                    internal={internal}
                  />
                </Skeleton>
                <TabBar
                  options={options.filter((opt) => !opt.disable)}
                  activeKey={tabKey}
                  setActiveKey={setTabKey}
                />
                {Component && <Component {...props} />}
              </WithPermission>
            )}
          </CompanyViewContext.Provider>
        )}
      </Layout>
    </ComponentWrapper>
  );
};

export const useCompanyView = () => {
  const contextValue = useContext(CompanyViewContext);
  if (contextValue === null) {
    throw new Error('contextValue cannot be null, please add a context provider');
  }
  return contextValue;
};

export const CompanyViewWrapper = function CompanyViewWrapper(props: CompanyViewType): JSX.Element {
  const { fetchConfigData } = useErpNextConfig();

  useEffect(() => {
    if (fetchConfigData) fetchConfigData();
  }, [fetchConfigData]);

  return <CompanyView {...props} />;
};

export default CompanyView;
