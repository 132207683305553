import React, { useEffect, useState } from 'react';
import { Modal, Input, Checkbox, message, Form } from '@shipmnts/pixel-hub';
import { RENAME_COMPANY } from '../graphql/company';
import { useLocation } from 'wouter';
import { CompanyValue } from 'network/models/Company';
import { useMutation } from '@apollo/client';
import { errorMessageHandlerGraphQL } from 'common';

const RenameCompanyModal = React.memo(function RenameCompanyModal(props: {
  company: CompanyValue;
  visible: boolean;
  setVisible?: (value: boolean) => void;
  setCompany: (company: CompanyValue) => void;
  onClose?: () => void;
  onSuccess?: () => void;
}): JSX.Element {
  const { 1: navigate } = useLocation();
  const { company, visible, setVisible, setCompany, onClose, onSuccess } = props;

  const [open, setOpen] = useState(visible || false);
  const [renameCompany, { data: rename_company_data, loading, error: rename_company_error }] =
    useMutation(RENAME_COMPANY);
  const [form] = Form.useForm();

  useEffect(() => {
    if (rename_company_data?.rename_company) {
      message.success('Company renamed successfully!');
      if (setVisible) setVisible(false);
      if (onClose) onClose();
      setOpen(false);
      if (onSuccess) onSuccess();
      if (rename_company_data.rename_company.id !== company.id) {
        navigate(`~/view/company/${rename_company_data.rename_company.id}`, {
          replace: true,
          state: {
            company: rename_company_data.rename_company,
          },
        });
      } else {
        if (setCompany) setCompany(rename_company_data.rename_company);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rename_company_data, navigate, company]);

  useEffect(() => setOpen(visible), [visible, setOpen]);

  return (
    <Modal
      title={`Rename ${company.registered_name}`}
      open={open}
      okText="Rename"
      okButtonProps={{ loading }}
      onOk={() => {
        form.submit();
      }}
      onCancel={() => {
        if (setVisible) setVisible(false);
        if (onClose) onClose();
        setOpen(false);
      }}
    >
      {rename_company_error && errorMessageHandlerGraphQL(rename_company_error)}
      <Form
        form={form}
        name="rename_company"
        layout="vertical"
        initialValues={{
          new_name: company.registered_name,
          merge_existing: false,
        }}
        onFinish={(values) => {
          const payload = {
            ...values,
            company_id: company.id,
          };
          renameCompany({ variables: payload });
        }}
        scrollToFirstError
      >
        <Form.Item
          required
          rules={[{ required: true }, { max: 140, message: 'Max 140 characters allowed' }]}
          name="new_name"
          label="New Name"
        >
          <Input />
        </Form.Item>
        <Form.Item name="merge_existing" valuePropName="checked">
          <Checkbox>Merge with existing company, if any</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default RenameCompanyModal;
