import React, { useState } from 'react';
import { Select, Form, Input, Button, message, GlobalSearch } from '@shipmnts/pixel-hub';
import { erpNextAxiosCall } from 'network/utils/erpnextApiHelper';
import {
  CompanyAccountsData,
  CompanyValue,
  SupplierBankAccountsData,
} from 'network/models/Company';
import { CompanyValue as CommonCompanyValue } from 'common/models/Company';
import { get as _get } from 'lodash';
const { Option } = Select;

interface SupplierBankAccountFormProps {
  company: CompanyValue | CommonCompanyValue;
  companyAccountsData?: CompanyAccountsData;
  onCompanyUpdate?: (company: CompanyValue, companyAccountsData?: CompanyAccountsData) => void;
  accounts: SupplierBankAccountsData[];
  drawerAccountData: SupplierBankAccountsData;
  setAccounts: (accounts: SupplierBankAccountsData[]) => void;
  setShowDrawer: (showDrawer: boolean) => void;
}

const SupplierBankAccountForm = React.memo(function SupplierBankAccountForm(
  props: SupplierBankAccountFormProps
) {
  const [form] = Form.useForm();
  const { company, accounts, setAccounts, drawerAccountData, setShowDrawer } = props;
  const [loading, setLoading] = useState(false);

  const onFinishForm = async (values: SupplierBankAccountsData) => {
    setLoading(true);

    let new_supplier_bank_account = {};
    if (drawerAccountData?.actions) {
      new_supplier_bank_account = { ...values, name: drawerAccountData?.name };
    } else {
      new_supplier_bank_account = values;
    }

    try {
      const url = `/api/method/shipmnts.controllers.network_company.upsert_bank_accounts`;
      const response: any = await erpNextAxiosCall({
        action: 'post',
        url,
        payload: {
          supplier_name: _get(company, 'registered_name'),
          supplier_bank_account: new_supplier_bank_account,
        },
      });
      const response_data = response?.response?.data?.message;
      if (response_data.errorMsg) message.error(response_data.errorMsg);
      else if (drawerAccountData?.actions) {
        const updatedAccounts = accounts;
        const updatedAccount = {
          ...updatedAccounts[drawerAccountData.actions.idx],
          ...response_data.supplier_bank_account,
        };
        updatedAccounts[drawerAccountData.actions.idx] = updatedAccount;
        setAccounts([...updatedAccounts]);
        setShowDrawer(false);
      } else {
        const newAccount = {
          ...response_data.supplier_bank_account,
          actions: { idx: accounts.length },
        };
        setAccounts([...accounts, newAccount]);
        setShowDrawer(false);
      }
      setLoading(false);
    } catch (error) {
      message.error('Network Error');
      console.error(error);
    }
  };

  return (
    <>
      <Form form={form} onFinish={onFinishForm} layout="vertical" initialValues={drawerAccountData}>
        <Form.Item
          name={`bank`}
          label={`Beneficiary Bank`}
          required
          rules={[{ required: true, message: 'Please select a Bank' }]}
          extra="Name of the Bank of your Beneficiary"
        >
          <GlobalSearch doc_type="ERP::Bank" />
        </Form.Item>

        <Form.Item
          name="bank_account_no"
          label="Beneficiary Bank Account #"
          required
          rules={[{ required: true, message: 'Beneficiary Bank Account # is required' }]}
          extra="Bank Account # of your supplier / beneficiary"
        >
          <Input type={'text'} />
        </Form.Item>

        <Form.Item
          name="beneficiary_code"
          label="Beneficiary Code"
          extra="Unique code given by your bank for this supplier / beneficiary "
        >
          <Input type={'text'} />
        </Form.Item>

        <Form.Item
          name={`address_name`}
          label={`Link to Beneficiary Address`}
          extra="Link supplier's branch address with this account"
        >
          <Select allowClear showSearch>
            {(company.addresses || []).map((address) => (
              <Option
                key={address.accounts_address_link}
                value={address.accounts_address_link || ''}
              >
                {address.accounts_address_link}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="branch_code"
          label="Branch Code"
          tooltip="IFSC Code"
          extra="For Indian accounts enter IFSC Code"
        >
          <Input type={'text'} />
        </Form.Item>

        <Form.Item name="swift_number" label="Swift Code" tooltip="For International Payments">
          <Input type={'text'} />
        </Form.Item>

        <Form.Item name="iban" label="IBAN" tooltip="For International Payments">
          <Input type={'text'} />
        </Form.Item>

        <Button htmlType="submit" type="primary" key="save" loading={loading}>
          {drawerAccountData?.actions ? 'Update Bank Account' : 'Add Bank Account'}
        </Button>
      </Form>
    </>
  );
});

export default SupplierBankAccountForm;
