import React, { useState, useEffect } from 'react';

import { useMutation } from '@apollo/client';
import { Modal, Transfer, message } from '@shipmnts/pixel-hub';

import { SPLIT_STOCK_ORDER } from 'operations/graphql/StockOrder';

import { StockOrderItem } from './stockManagementModel';

interface SplitStockProps {
  stock_order_id: number;
  items: StockOrderItem[] | undefined;
  visible?: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
}
export default function SplitStock(props: SplitStockProps) {
  const { stock_order_id, items, visible, onClose, onSuccess } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [
    splitStockOrder,
    { data: splitStockOrderData, loading: splitStockOrderLoading, error: splitStockOrderError },
  ] = useMutation(SPLIT_STOCK_ORDER);

  useEffect(() => {
    if (splitStockOrderLoading) {
      setLoading(true);
      return;
    }
    if (splitStockOrderError) {
      message.error(splitStockOrderError.message);
      return;
    }
    if (splitStockOrderData?.split_stock_order) {
      message.success('successfully split stock order');
      setLoading(false);
      if (onClose) onClose();
      if (onSuccess) onSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [splitStockOrderData, splitStockOrderLoading, splitStockOrderError, onSuccess]);

  const handleUpdate = async () => {
    if (!items) return;
    if (selectedItems.length === 0) {
      message.error('You need to select atleast 1 AWB to split into new order');
      return;
    }
    if (selectedItems.length === items.length) {
      message.error(`Stock order ${stock_order_id} will have no AWBs left.`);
      return;
    }
    splitStockOrder({ variables: { id: stock_order_id, stock_order: selectedItems } });
  };

  if (!items) return null;

  return (
    <Modal
      title={`Split Stock Order #${stock_order_id}`}
      open={visible}
      onCancel={onClose}
      okText="Split Stock Order"
      onOk={handleUpdate}
      confirmLoading={loading}
      width="700px"
    >
      <Transfer
        rowKey={(item) => item.id.toString()}
        titles={[`Stock Order #${stock_order_id}`, 'New Order']}
        dataSource={items}
        targetKeys={selectedItems}
        onChange={setSelectedItems}
        showSearch
        listStyle={{
          width: 300,
          height: 300,
        }}
        render={(item: StockOrderItem) =>
          `${item.awb_number.substr(0, 3)}-${item.awb_number.substr(3)}`
        }
      />
    </Modal>
  );
}
