import React, { useState } from 'react';

import { MoreOutlined, TrackerContextProvider, useTrackerContext } from '@shipmnts/pixel-hub';

import { Badge, Button, CustomIcon } from '@shipmnts/pixel-hub';
import { NextActionProps, NextActionsComponent } from 'operations/utils/NextActionsComponent';
import { ACTION_STATUS_SHIPMENT_CANCELLED } from 'operations/modules/shipment/constants';

type Props = {
  status: string;
  actionProps?: NextActionProps;
  trackerProps?: { referenceId: string; referenceType: any; editable?: boolean };
  color: string; // should be enum?
};

const StatusRenderer = ({ status, actionProps, trackerProps }: Props) => {
  const { editable = true } = trackerProps || {}; // when editable is undefined, we want it to be true (default value)
  const [showNextActions, setshowNextActions] = useState(false);

  const handleNextShowAction = (val: boolean) => setshowNextActions(val);

  if (!trackerProps) return <></>;

  return (
    <TrackerContextProvider referenceType={trackerProps?.referenceType} editable={editable}>
      <div
        style={{ display: 'flex', alignItems: 'center' }}
        onMouseOver={() => handleNextShowAction(editable)}
        onMouseLeave={() => handleNextShowAction(false)}
      >
        <div
          className={
            status !== ACTION_STATUS_SHIPMENT_CANCELLED ? `status_color_green` : `status_color_red`
          }
          style={{ fontSize: '12px' }}
        >
          <Badge color={status !== ACTION_STATUS_SHIPMENT_CANCELLED ? `green` : `red`} />
          {status}
        </div>
        <Tracker id={trackerProps.referenceId} />
        <div style={{ visibility: showNextActions ? 'visible' : 'hidden' }}>
          {actionProps && (
            <NextActionsComponent
              {...actionProps}
              iconComponent={
                <Button size="small">
                  <MoreOutlined rotate={90} />
                </Button>
              }
            >
              <></>
            </NextActionsComponent>
          )}
        </div>
      </div>
    </TrackerContextProvider>
  );
};

export default StatusRenderer;

const Tracker = ({ id }: { id: string }) => {
  const { setReferenceId } = useTrackerContext();
  return (
    <CustomIcon
      icon="TrackerIcon"
      style={{ marginLeft: '5px' }}
      onClick={() => setReferenceId(id)}
    />
  );
};
