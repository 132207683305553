import React from 'react';
import { InputNumber, Form } from '@shipmnts/pixel-hub';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ContainerRequestValue } from 'operations/models/ContainerRequest';
import { areContainerSettingsSame } from 'operations/models/ShipmentContainer';
import _min from 'lodash/min';

const AllocateContainerInputNumber = React.memo(function AllocateContainerInputNumber(
  props: ICellRendererParams
): JSX.Element {
  const { data, colDef } = props;
  // console.log(data, colDef);
  if (!colDef) return <></>;
  const container_request_of_parent = colDef.cellEditorParams.container_request;
  const container_request_of_row = data.container_requests.find(
    (cr: ContainerRequestValue) =>
      cr.container_type === container_request_of_parent.container_type &&
      areContainerSettingsSame(
        cr.container_settings,
        container_request_of_parent?.container_settings
      )
  );
  const selectedVoyageNumber = colDef.cellEditorParams.selectedVoyageNumber;

  return (
    <Form.Item
      name={[
        'allocation_ocean_transport_order',
        data.id,
        container_request_of_parent?.id || 0,
        container_request_of_row?.id || 'un',
      ]}
    >
      <InputNumber
        max={_min([
          container_request_of_row?.quantity_unfulfilled || 0,
          container_request_of_parent?.quantity_unfulfilled || 0,
        ])}
        min={0}
        disabled={
          (selectedVoyageNumber && data?.voyage_number !== selectedVoyageNumber) ||
          !container_request_of_row
        }
        style={{ width: '100%' }}
        precision={0}
      />
    </Form.Item>
  );
});
export default AllocateContainerInputNumber;
