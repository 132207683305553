import React from 'react';
import DescriptionItem from './DescriptionItem';
import { ItemValue } from 'common/models/Item';
import { QtyUomTypeRenderer } from 'common/report_manager/components/Renderer/cellRenderer';

interface PrimaryPackagingProps {
  product: ItemValue;
}
export default function PrimaryPackaging(props: PrimaryPackagingProps) {
  const primary_packaging = (props?.product?.product_packaging_options || []).find(
    (e: any) => e?.is_primary_packaging
  );
  const formatDimensions = (
    length: number,
    breadth: number,
    height: number,
    dimensionUnit: string
  ) => {
    return length && breadth && height && length !== 0 && breadth !== 0 && height !== 0
      ? `${length}${dimensionUnit || ''} x ${breadth}${dimensionUnit || ''} x ${height}${
          dimensionUnit || ''
        }`
      : '-';
  };

  const data = [
    { label: 'Packing Type', content: primary_packaging?.packing_type || '-' },
    { label: 'Dimension Unit', content: primary_packaging?.dimension_unit || '-' },
    {
      label: 'Dimensions (L x B x H)',
      content: formatDimensions(
        primary_packaging?.length,
        primary_packaging?.breadth,
        primary_packaging?.height,
        primary_packaging?.dimension_unit
      ),
    },
    { label: 'Weight Unit', content: primary_packaging?.weight_unit || '-' },
    {
      label: 'Gross Weight',
      content: (
        <QtyUomTypeRenderer
          qty={primary_packaging?.gross_weight}
          uom={primary_packaging?.weight_unit}
        />
      ),
    },
    {
      label: 'Net Weight',
      content: (
        <QtyUomTypeRenderer
          qty={primary_packaging?.net_weight}
          uom={primary_packaging?.weight_unit}
        />
      ),
    },
    {
      label: 'Tare Weight',
      content: (
        <QtyUomTypeRenderer
          qty={primary_packaging?.tare_weight}
          uom={primary_packaging?.weight_unit}
        />
      ),
    },
  ];
  return (
    <div style={{ padding: '15px', borderBottom: '1px solid #9FB1BD' }}>
      <DescriptionItem title="Primary Packaging Details" data={data} />
    </div>
  );
}
