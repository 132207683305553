import { Avatar, Col, List, Modal, Row, Tag, Tooltip, message } from '@shipmnts/pixel-hub';
import { useMutation } from '@apollo/client';
import { CloseOutlined } from '@shipmnts/pixel-hub';
import React, { useEffect, useState } from 'react';
import { ShipmentValue } from 'operations/models/Shipment';
import { UserContactValue } from 'common/models/UserContact';
// import { BranchAccountValue } from 'network/models/BranchAccount';
import { REMOVE_COLLABORATOR } from 'network/graphql/collaborator';
import { map as _map } from 'lodash';
import { useSession } from 'common';

interface AddedCollaboratorListProps {
  collaborators: UserContactValue[];
  loading: boolean;
  setCollaborators: (collaborator: UserContactValue[]) => void;
  shipment?: ShipmentValue;
}

export default function AddedCollaboratorList(props: AddedCollaboratorListProps) {
  const { collaborators, setCollaborators } = props;
  const [groupedCollabs, setGroupedCollabs] = useState({});
  const currentUser = useSession();

  const [
    deleteCollab,
    { data: deleteCollabData, loading: deleteCollabLoading, error: deleteCollabError },
  ] = useMutation(REMOVE_COLLABORATOR);

  useEffect(() => {
    const groupedData = collaborators.reduce((acc: any, user: any) => {
      const company: string =
        user?.user_contact?.companies.length > 0
          ? _map(user.user_contact.companies, 'registered_name').join(', ')
          : 'Others';

      if (Object.keys(acc).includes(company)) {
        acc[company].push(user);
      } else {
        acc[company] = [user];
      }

      return acc;
    }, {});

    setGroupedCollabs(groupedData);
  }, [collaborators, setGroupedCollabs]);

  useEffect(() => {
    if (!deleteCollabLoading && !deleteCollabError && deleteCollabData) {
      message.success('Collaborator Removed Successfully');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteCollabData, deleteCollabLoading, deleteCollabError, setCollaborators]);

  const component = (
    <div
      style={{
        maxHeight: '20em',
        overflowX: 'hidden',
        overflowY: 'scroll',
      }}
    >
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <b>Added Collaborators</b>
      <br /> <br />
      {Object.keys(groupedCollabs).length > 0 &&
        (Object.keys(groupedCollabs).includes('Others')
          ? [...Object.keys(groupedCollabs).filter((key) => key !== 'Others'), 'Others']
          : Object.keys(groupedCollabs)
        ).map((company) => {
          return (
            <div key="">
              <h4 style={{ opacity: 0.4, marginBottom: 0 }}>{company}</h4>
              <List
                className="demo-loadmore-list"
                // loading={loading}
                itemLayout="horizontal"
                dataSource={(groupedCollabs as any)[company].filter((c: any) => !c._destroy)}
                renderItem={(user: any) => {
                  // let containsBranch = true;
                  // if (user.user_contact.branch_accounts) {
                  //   containsBranch = (user.user_contact.branch_accounts || []).find(
                  //     (ba: BranchAccountValue) => ba.id === shipment?.involved_branch_id
                  //   );
                  // }

                  const departments = user?.user_contact?.department;
                  const actions = [
                    <div
                      key=""
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '1em',
                        gap: '1em',
                      }}
                    >
                      <>
                        {departments?.length > 1 ? (
                          <Tooltip title={user?.user_contact?.department?.join(', ')}>
                            <Tag>
                              {departments.reduce((a: string, b: string) =>
                                a.length <= b.length ? a : b
                              )}
                            </Tag>
                            <b>...</b>
                          </Tooltip>
                        ) : (
                          <Tag> {departments && departments[0]} </Tag>
                        )}
                      </>
                      {currentUser.email !== user?.user_contact?.email && (
                        <CloseOutlined
                          onClick={() => {
                            Modal.confirm({
                              title: `Are you sure you want to remove ${
                                user?.user_contact?.first_name +
                                ' ' +
                                (user?.user_contact?.last_name || '')
                              } from collaborators?`,
                              okText: 'Remove Collaborator',
                              okType: 'danger',
                              centered: true,
                              onOk: () => {
                                setCollaborators(
                                  collaborators.filter((collab) => collab.id !== user.id)
                                );
                                deleteCollab({
                                  variables: {
                                    id: (collaborators || [])
                                      .filter((c: any) => c.id === user.id)
                                      .map((c: any) => c.id)[0],
                                  },
                                });
                              },
                            });
                          }}
                          key="2"
                        />
                      )}
                    </div>,
                  ];

                  return (
                    <List.Item actions={actions}>
                      <Row style={{ width: '70%' }} gutter={20} className="flex">
                        <Col
                          span={3}
                          style={{ display: 'grid', alignItems: 'center' }}
                          className="align-verically"
                        >
                          <Avatar
                            shape="circle"
                            size="default"
                            style={{ background: '#CEDCF4', color: '#6C95DF' }}
                          >
                            <b>{user?.user_contact?.first_name[0]}</b>
                          </Avatar>
                        </Col>
                        <Col span={21}>
                          <div>
                            <b>
                              {user?.user_contact?.first_name +
                                ' ' +
                                (user?.user_contact?.last_name || '')}
                            </b>
                          </div>
                          <div>{user?.user_contact?.email}</div>
                        </Col>
                      </Row>
                    </List.Item>
                  );
                }}
              />
            </div>
          );
        })}
    </div>
  );
  return collaborators.length ? component : <></>;
}
