import CreateShipmentFromProductOrder from '../CreateShipmentFromProductOrder';
import { PerformAction } from '../../actionHelper/models';
import ShareOrder from './ShareOrder';
import CancelProductOrderDrawer from '../CancelProductOrderDrawer';
import { SessionDataValue } from 'operations/models/SessionData';
import { ProductOrderValue } from 'operations/models/ProductOrder';
import { AddCollaborators, EmailDocType } from '@shipmnts/pixel-hub';
import DeLinkOrder from './DelinkOrder';

interface ProductOrderActionType {
  productOrder: ProductOrderValue;
  spoiId: any;
  setEmailProps: (emailProps: any) => void;
  setVisible: (visible: boolean) => void;
  sessionData: SessionDataValue;
  extraProps?: any;
  navigate: any;
}

export const performCreateShipmentAction = (payload: ProductOrderActionType): PerformAction => {
  const { productOrder, sessionData } = payload;
  return {
    actionParams: {
      id: productOrder.id,
      referenceType: 'OrderManagement::ProductOrder',
      session: sessionData,
      productOrder: productOrder,
      onSuccess: (sendEmail: boolean) => {
        // TODO
      },
      onClose: (props: any) => {
        // TODO
      },
      isVisible: true,
      setIsVisible: () => {
        //Todo
      },
    },
    component: CreateShipmentFromProductOrder,
  };
};

export const performEmailOrder = (payload: ProductOrderActionType): PerformAction => {
  const { productOrder, sessionData } = payload;
  return {
    actionParams: {
      resource_id: productOrder?.id,
      docType:
        productOrder.type === 'sales'
          ? 'OrderManagement::SalesOrder'
          : 'OrderManagement::PurchaseOrder',
      fetchDocumentParents: [{ parent_ids: [productOrder.id], parent_type: 'product_order' }],
      companies_roles_mapping: productOrder.getAllPartiesRolesMapping(sessionData),
    },
    component: EmailDocType,
  };
};

export const performPrintOrder = (payload: ProductOrderActionType): PerformAction => {
  const { productOrder, extraProps } = payload;
  return {
    actionParams: {
      docTypeId: productOrder?.id,
      docType:
        productOrder?.type === 'sales' || extraProps?.product_order?.type === 'sales'
          ? 'OrderManagement::SalesOrder'
          : 'OrderManagement::PurchaseOrder',
      doc: productOrder || extraProps?.product_order,
    },
    component: ShareOrder,
  };
};

export const cancelProductOrderRenderer = (payload: ProductOrderActionType): PerformAction => {
  const { productOrder } = payload;

  return {
    actionParams: {
      productOrder,
    },
    component: CancelProductOrderDrawer,
  };
};

export const delinkOrderRenderer = (payload: ProductOrderActionType): PerformAction => {
  const { extraProps } = payload;
  return {
    actionParams: {
      spoiId: extraProps?.spoi_id,
      parentId: extraProps?.parent_id,
    },
    component: DeLinkOrder,
  };
};
export const addCollaboratorsRenderer = (payload: ProductOrderActionType): PerformAction => {
  const { productOrder } = payload;
  return {
    actionParams: {
      referenceId: productOrder.id,
      referenceType: 'OrderManagement::ProductOrder',
      id: productOrder?.id,
    },
    component: AddCollaborators,
  };
};
