import {
  Alert,
  Button,
  Form,
  GlobalSearch,
  Modal,
  Skeleton,
  Spin,
  Tag,
  Tooltip,
  message,
} from '@shipmnts/pixel-hub';
import { PlusOutlined } from '@shipmnts/pixel-hub';
import React, { useEffect, useState } from 'react';
import { DOC_TYPE_METHODS } from 'common';
import {
  GET_INQUIRY_OPTION,
  LINK_INQUIRY_OPTION_TO_RESOURCE,
} from 'operations/graphql/inquiryOption';
import { useLazyQuery, useMutation } from '@apollo/client';
import ShipmentEstimateSummary from './ShipmentEstimateSummary';

interface LinkInquiryOptionToResourceProps {
  resourceId: string;
  resourceType: string;
  disabled?: boolean;
  onSucess?: () => void;
  searchProps: {
    customer_company_id?: string;
    involved_branch_id?: string;
    freight_type?: string;
    trade_type?: string;
  };
}

function LinkInquiryOptionToResource(props: LinkInquiryOptionToResourceProps) {
  const { resourceId, resourceType, onSucess, searchProps, disabled = false } = props;
  const resourceDispayName = DOC_TYPE_METHODS[resourceType]?.display_name;
  const [open, setOpen] = useState(false);
  const [link, { data, loading }] = useMutation(LINK_INQUIRY_OPTION_TO_RESOURCE);
  const [getInquiryOption, { data: inquiryOptionData, loading: inquiryOptionLoading }] =
    useLazyQuery(GET_INQUIRY_OPTION);
  useEffect(() => {
    if (data) {
      setOpen(false);
      if (onSucess) onSucess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  if (loading) return <Spin />;
  return (
    <>
      <Button
        disabled={disabled}
        onClick={() => setOpen(true)}
        style={{ padding: 0, margin: 0, background: 'transparent', border: 0 }}
      >
        <Tooltip title="Link Quotation">
          <Tag color="blue" style={{ background: 'transparent' }}>
            Link Quote &nbsp;
            <PlusOutlined />
          </Tag>
        </Tooltip>
      </Button>
      <Modal
        width={'50%'}
        title={`Search and Link Quotation to ${resourceDispayName}`}
        open={open}
        onCancel={() => setOpen(false)}
        footer={[
          <div key="link" style={{ width: '100%', display: 'flex' }}>
            <span style={{ flex: 1, textAlign: 'right' }}>
              <Button
                type="primary"
                onClick={() => {
                  if (!inquiryOptionData?.get_inquiry_option?.id) {
                    message.error('Please select quotation');
                    return;
                  }
                  link({
                    variables: {
                      resource_id: resourceId,
                      resource_type: resourceType,
                      add_charges: true,
                      inquiry_option_id: inquiryOptionData?.get_inquiry_option?.id,
                    },
                  });
                }}
              >
                Link Quote To {resourceDispayName}
              </Button>
            </span>
          </div>,
        ]}
      >
        <Form.Item name="inquiry_option">
          <GlobalSearch
            doc_type="SalesHub::InquiryOption"
            extraProps={{
              placeholder: 'Search Quotation',
            }}
            searchProps={searchProps}
            onChange={(value: any) =>
              getInquiryOption({
                variables: {
                  id: value.id,
                },
              })
            }
          />
        </Form.Item>

        {inquiryOptionLoading ? (
          <Skeleton />
        ) : (
          <ShipmentEstimateSummary
            shipment_estimates={inquiryOptionData?.get_inquiry_option?.shipment_estimates || []}
          />
        )}

        <span>
          <Alert
            type="warning"
            message="All charges from this quotation will get added into the Shipment once you link it."
          />
        </span>
      </Modal>
    </>
  );
}

export default LinkInquiryOptionToResource;
