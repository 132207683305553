import React, { useEffect, useRef, useState } from 'react';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  AppHelmet,
  Button,
  Checkbox,
  Form,
  Layout,
  message,
  Skeleton,
  Space,
} from '@shipmnts/pixel-hub';
import { CustomIcon, useActivityContext } from '@shipmnts/pixel-hub';

import { get, isNil } from 'lodash';
import { useParams } from 'wouter';
import { TagsOutlined } from '@ant-design/icons';
import { GET_INQUIRY_OPTION, UPSERT_INQUIRY_OPTION } from 'sales_hub/graphql/inquiryOption';
import Inquiry from 'sales_hub/models/inquiry';
import { DRAFT_STATUS, QuotationWithInquiryValue } from 'sales_hub/models/quotation';
import { ShipmentEstimateValue } from 'sales_hub/models/ShipmentEstimate';
import { convertDateToUnix } from '../Inquiry/InquiryLostLayout';
import { getInquiryOptionPayload, getUpsertInquiryOptionsPayload } from './InquiryOptionDrawer';
import InquiryOptionForm from './InquiryOptionForm';
import { useInquiryDetail } from '../Inquiry/InquiryDetail/InquiryDetailLayout';
import { FETCH_EVENT_AND_MILESTONES } from 'common/components/Tracking/graphql/queries';
import { UPDATE_TRACKING_EVENT } from 'common/components/Tracking/graphql/queries';
interface InquiryOptionDetailLayoutProp {
  onClose?: () => void;
  onSuccess?: () => void;
  id?: string;
  externalLink?: boolean;
  disabledResource?: boolean;
}

function InquiryOptionDetailLayout(props: InquiryOptionDetailLayoutProp) {
  const { onClose, onSuccess, externalLink, disabledResource } = props;
  const params = useParams<{ id: string }>();
  const id = props?.id || params?.id;
  const [getInquiryOption, { data, loading, error }] = useLazyQuery(GET_INQUIRY_OPTION, {
    fetchPolicy: 'no-cache',
  });
  const { inquiry: newInquiry, inquiry_global_id } = useInquiryDetail();
  const [isChecked, setIsChecked] = useState(
    newInquiry?.last_action_status === 'Pricing shared with Sales'
  );
  const [upsertInquiryOptions, { data: upsertData, error: upsertError, loading: upsertLoad }] =
    useMutation(UPSERT_INQUIRY_OPTION);
  const estimatesRef = useRef<{ getValue: () => ShipmentEstimateValue[] }>();
  const [inquiryOption, setInquiryOption] = useState<QuotationWithInquiryValue>();
  const [form] = Form.useForm();
  const { setActivityProps } = useActivityContext();
  const [isVisible, setIsVisible] = useState(true);
  let disable = inquiryOption?.status !== DRAFT_STATUS;
  const [updateEvent] = useMutation(UPDATE_TRACKING_EVENT);
  const { refetchInquiry } = useInquiryDetail();
  if (!isNil(disabledResource)) disable = disabledResource;
  useEffect(() => {
    if (id && !inquiryOption)
      getInquiryOption({
        variables: {
          id: id,
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInquiryOption, id]);

  useEffect(() => {
    if (error) message.error(error?.message);
    if (data) setInquiryOption(getInquiryOptionPayload(get(data, 'get_inquiry_option')));
  }, [error, data]);

  useEffect(() => {
    if (upsertError) message.error(upsertError?.message);
    if (upsertData) {
      const inquiryOptionData = getInquiryOptionPayload(get(upsertData, 'upsert_inquiry_option'));
      setInquiryOption(inquiryOptionData);
      form.setFieldsValue(inquiryOptionData);
      if (setIsVisible) setIsVisible(false);
      if (onSuccess) onSuccess();
      if (refetchInquiry) refetchInquiry();
    }
  }, [form, onClose, onSuccess, upsertData, upsertError, isVisible, refetchInquiry]);

  useEffect(() => {
    if (upsertData) {
      message.success('Quotation updated');
      onClose && onClose();
      onSuccess && onSuccess();
    }
  }, [upsertData, onClose, onSuccess]);

  if (!id) return <></>;
  const pageActions = [
    <Button
      key="shipment_header_activity"
      style={{ display: 'flex', alignItems: 'center' }}
      onClick={() => {
        inquiryOption?.id &&
          setActivityProps({
            visible: true,
            resource_id: inquiryOption?.id,
            resource_type: 'SalesHub::InquiryOption',
            showDrawer: externalLink,
          });
      }}
    >
      Activity
      <span style={{ marginLeft: '5px', display: 'flex' }}>
        <CustomIcon icon="ActivityIcon" />
      </span>
    </Button>,
    <Button
      disabled={disable}
      type="primary"
      loading={upsertLoad}
      key="save"
      onClick={() => form.submit()}
    >
      {id ? 'Save' : 'Create'}
    </Button>,
  ];
  const getTitle = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    return id === 'new'
      ? 'Create new quotation'
      : `Edit Quotation #${inquiryOption?.quotation_number || ''}`;
  };
  const handleChange = (e: any) => {
    setIsChecked(e.target.checked);
  };
  let disabled = !!error || (inquiryOption && inquiryOption?.status !== DRAFT_STATUS);
  if (!isNil(disabledResource)) disabled = disabledResource;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data: mileStoneData } = useQuery(FETCH_EVENT_AND_MILESTONES, {
    variables: {
      ids: Array.isArray(inquiry_global_id)
        ? inquiry_global_id?.map((i) => parseInt(i))
        : [parseInt(inquiry_global_id || '')],
      reference_type: 'SalesHub::InquiryGlobal',
      workflow_type: 'main',
    },
  });
  // eslint-disable-next-line react-hooks/rules-of-hooks

  if (externalLink)
    pageActions.push(
      <Button
        key="close"
        onClick={() => {
          if (onClose) onClose();
        }}
      >
        Close
      </Button>
    );
  const { Header, Content, Footer } = Layout;
  return (
    <>
      {loading && <Skeleton />}
      {inquiryOption && !loading && (
        <Layout style={{ minHeight: '100vh' }}>
          <AppHelmet
            titleTemplate={`#${
              inquiryOption?.quotation_number || ''
            } | Quotation | Shipmnts [Sales Hub]`}
          />
          <Header
            style={{
              backgroundColor: 'white',
              padding: '0 16px',
              display: 'flex',
              alignItems: 'center',
              zIndex: 1,
              width: '100%',
              position: 'fixed',
              borderBottom: '1px solid #ddd',
            }}
          >
            <div>
              <TagsOutlined style={{ fontSize: '18px' }} /> &nbsp;{' '}
              <span style={{ fontWeight: '24px', fontSize: '16px' }}>{getTitle()}</span>
            </div>
            {inquiryOption && (
              <Button
                key="shipment_header_activity" // updated
                size={'small'}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  position: 'fixed',
                  right: '16px',
                  zIndex: 1000,
                }}
                onClick={() => {
                  setActivityProps({
                    visible: true,
                    resource_id: inquiryOption?.id,
                    resource_type: 'SalesHub::InquiryOption',
                    showDrawer: true,
                  });
                }}
              >
                Activity
                <span style={{ marginLeft: '5px', display: 'flex' }}>
                  <CustomIcon icon="ActivityIcon" />
                </span>
              </Button>
            )}
          </Header>
          <Content
            style={{
              padding: '0 16px',
              marginTop: 74,
              marginBottom: 64,
            }}
          >
            {inquiryOption && !loading && (
              <InquiryOptionForm
                inquiry={Inquiry.create(inquiryOption?.inquiry)}
                inquiryOption={inquiryOption}
                form={form}
                estimatesRef={estimatesRef}
                disabledResource={disabledResource}
                onFinish={(formValues: any) => {
                  const upsertPayload = getUpsertInquiryOptionsPayload(
                    formValues,
                    estimatesRef,
                    inquiryOption,
                    id === 'new' ? undefined : id
                  );
                  const events = get(mileStoneData, 'fetch_tracking_events', []);
                  const eventsToBeEdited = events.filter(
                    (o: { name: string }) => o.name === 'Pricing shared with Sales'
                  );
                  isChecked &&
                    newInquiry?.last_action_status === 'Pricing Pending' &&
                    updateEvent({
                      variables: {
                        ids: [parseInt(eventsToBeEdited[0]?.id)],
                        input: {
                          actual_date: convertDateToUnix(new Date().toISOString()),
                          event_data: {},
                        },
                      },
                    });
                  upsertInquiryOptions({
                    variables: {
                      inquiry_id: inquiryOption?.inquiry?.id,
                      inquiry_option: upsertPayload,
                    },
                  });
                }}
              />
            )}
          </Content>
          <Footer
            style={{
              padding: '16px',
              position: 'fixed',
              bottom: 0,
              width: externalLink ? '85%' : '100%',
              zIndex: 1,
              display: 'flex',
              justifyContent: 'flex-end',
              borderTop: '1px solid #ddd',
            }}
          >
            <Space>
              <Checkbox
                checked={isChecked}
                disabled={newInquiry?.last_action_status !== 'Pricing Pending'}
                onChange={handleChange}
              >
                Mark as Pricing shared with sales
              </Checkbox>
              <Button
                type="primary"
                htmlType="submit"
                disabled={disabled}
                loading={loading}
                onClick={form.submit}
              >
                Save
              </Button>
              <Button type="default" onClick={onClose}>
                Cancel
              </Button>
            </Space>
          </Footer>
        </Layout>
      )}
    </>
  );
}

export default InquiryOptionDetailLayout;
