import React, { useEffect } from 'react';
import { Form, Drawer, message } from '@shipmnts/pixel-hub';
import ReuploadUploadSbBeFileForm from './ReuploadUploadSbBeFileForm';
import { DrawerFooter } from '@shipmnts/pixel-hub';
import { ShipmentValue } from 'operations/models/Shipment';
import { ShipmentCustomDetailValue } from 'operations/models/ShipmentCustomDetail';
import { useMutation } from '@apollo/client';
import { CUSTOM_FROM_SB_BE } from 'operations/graphql/shipmentCustomDetail';
import { convertToDayJs } from '@shipmnts/pixel-hub';
import { omit } from 'lodash';

const ReuploadUploadSbBeFile = (props: {
  title: string;
  shipment: ShipmentValue;
  shipmentCustomDetail?: ShipmentCustomDetailValue;
  api: string;
  onSuccess?: () => void;
  onClose?: () => void;
  isVisible: boolean;
}) => {
  const { shipmentCustomDetail, api, shipment, onSuccess, onClose, title } = props;
  const [form] = Form.useForm();

  const [customDetailFromSbBe, { loading, data }] = useMutation(CUSTOM_FROM_SB_BE);

  useEffect(() => {
    if (data?.upsert_custom_detail_from_sb_be) {
      message.success('Custom Detail updated successfully!');
      if (onSuccess) onSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onFinish = async (values: any) => {
    customDetailFromSbBe({
      variables: {
        id: shipmentCustomDetail?.id,
        shipment_id: shipment.id,
        port_of_discharge_id: values.port_of_discharge?.id,
        port_of_loading_id: values.port_of_loading?.id,
        ...omit(values, ['port_of_discharge', 'port_of_loading']),
      },
    });
  };

  const handleOnClose = () => {
    form.resetFields();
    if (onClose) onClose();
  };

  return (
    <Drawer
      title={<div>{title}</div>}
      placement="right"
      open={props.isVisible}
      onClose={handleOnClose}
      headerStyle={{ padding: '16px' }}
      width="50%"
      footer={
        <DrawerFooter
          loading={loading}
          saveText="Save"
          onSave={() => form.submit()}
          onClose={() => {
            if (onClose) onClose();
          }}
          disabled={loading}
        />
      }
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{
          custom_document_number: shipmentCustomDetail?.custom_document_number,
          custom_document_date: convertToDayJs(shipmentCustomDetail?.custom_document_date),
          // cargo_description: shipment.cargo_description,
        }}
      >
        <ReuploadUploadSbBeFileForm
          form={form}
          shipment={shipment}
          api={api}
          customDetails={shipmentCustomDetail}
        />
      </Form>
    </Drawer>
  );
};

export default ReuploadUploadSbBeFile;
