import React, { useState } from 'react';
import { Row, Col, Radio, Select, Form, DatePicker } from '@shipmnts/pixel-hub';
import { FormInstance } from '@shipmnts/pixel-hub';
import {
  ROW_GUTTER,
  DocumentType,
  DOCUMENT_TYPE_HOUSE,
  DOCUMENT_TYPE_MASTER,
  FreightType,
  TradeType,
  BL_TYPE_ARRAY,
  BL_TYPE_EXPRESS,
  RELEASE_TYPE_ARRAY,
  CHARGE_TERMS,
  INCOTERMS,
  CONSIGNEE_TYPE_TO_ORDER,
  CONSIGNEE_TYPE_TO_ORDER_BANK,
  ShipmentType,
  SHIPMENT_TYPE_DIRECT,
  SHIPMENT_TYPE_BACK_TO_BACK,
  SHIPMENT_TYPE_CONSOL,
  FREIGHT_TYPE_OCEAN,
  TRADE_TYPE_IMPORT,
  BL_TYPE_E_BL,
} from 'operations/modules/shipment/constants';
import { LABEL_JSON } from 'operations/modules/shipment/helpers/labelKeyHelper';
import ShipmentPartyDetails from './ShipmentPartyDetails';
import ShipmentNumber from './ShipmentNumber';
import { RadioOptionProps } from 'operations/commonTypeDefs';
import { CarrierValue } from 'operations/models/Carrier';
import {
  ALLOWED_DOCUMENT_STATUS_MAPPING_ON_CREATE,
  document_status_to_display_name_mapping,
} from 'operations/modules/reports/constants';
import {
  DOCUMENT_STATUS_RECEIVED_AT_DESTINATION,
  DOCUMENT_STATUS_SURRENDERED_AT_ORIGIN,
} from 'operations/modules/reports/constants';

interface ShipmentDocumentDetailsProps {
  form: FormInstance;
  shipmentType: ShipmentType;
  documentType: DocumentType;
  freightType: FreightType;
  tradeType: TradeType;
  carrier?: CarrierValue;
  disableShipmentNumber?: boolean;
}

const ShipmentDocumentDetails = React.memo(function ShipmentDocumentDetails(
  props: ShipmentDocumentDetailsProps
): JSX.Element {
  const {
    shipmentType,
    documentType,
    freightType,
    tradeType,
    form,
    carrier,
    disableShipmentNumber = false,
  } = props;
  const showIncoterms =
    documentType === DOCUMENT_TYPE_HOUSE || shipmentType === SHIPMENT_TYPE_DIRECT;
  const consginee_to_order_keys = [CONSIGNEE_TYPE_TO_ORDER.key, CONSIGNEE_TYPE_TO_ORDER_BANK.key];
  const [documentStatus, setDocumentStatus] = useState('');
  const onChange = (document_status: string) => {
    setDocumentStatus(document_status);
  };
  return (
    <Col>
      <Row gutter={ROW_GUTTER}>
        {!disableShipmentNumber && freightType === FREIGHT_TYPE_OCEAN && (
          <Col span={8}>
            <ShipmentNumber
              form={form}
              carrier={carrier}
              label={LABEL_JSON.shipment_number[documentType][freightType]}
              documentType={documentType}
            />
          </Col>
        )}

        {!disableShipmentNumber &&
          freightType === FREIGHT_TYPE_OCEAN &&
          tradeType === TRADE_TYPE_IMPORT && (
            <>
              <Col>
                <Form.Item
                  name={[documentType, 'shipment_document', 'shipment_date']}
                  label={LABEL_JSON.document_date[documentType][freightType]}
                >
                  <DatePicker style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="B/L Status"
                  name={[documentType, 'shipment_document', 'document_status']}
                >
                  <Select style={{ width: '100%' }} onChange={onChange}>
                    {ALLOWED_DOCUMENT_STATUS_MAPPING_ON_CREATE[freightType].map(
                      (option: string, index: number) => (
                        <Select.Option key={index} value={option}>
                          {document_status_to_display_name_mapping[option]}
                        </Select.Option>
                      )
                    )}
                  </Select>
                </Form.Item>
              </Col>
              {[
                DOCUMENT_STATUS_RECEIVED_AT_DESTINATION,
                DOCUMENT_STATUS_SURRENDERED_AT_ORIGIN,
              ].includes(documentStatus) && (
                <Col span={8}>
                  <Form.Item
                    required
                    rules={[{ required: true }]}
                    name={[documentType, 'shipment_document', 'document_status_event_date']}
                    label={
                      documentStatus === DOCUMENT_STATUS_SURRENDERED_AT_ORIGIN
                        ? 'Surrendered At Origin Date'
                        : 'Received At Destination Date'
                    }
                  >
                    <DatePicker style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              )}
            </>
          )}
      </Row>

      <Row gutter={ROW_GUTTER}>
        <Col span={8}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues[documentType]?.consignee_party_name !==
              currentValues[documentType]?.consignee_party_name
            }
          >
            {({ getFieldValue }) => {
              const consignee_party_name = getFieldValue([documentType, 'consignee_party_name']);
              const disabledKey =
                consginee_to_order_keys.includes(consignee_party_name) &&
                (BL_TYPE_EXPRESS || BL_TYPE_E_BL);
              return (
                <Form.Item
                  required
                  rules={[{ required: true }]}
                  label="B/L Type"
                  name={[documentType, 'shipment_document', 'bl_type']}
                >
                  <Radio.Group>
                    {BL_TYPE_ARRAY.map((option: RadioOptionProps, index: number) => (
                      <Radio key={index} value={option.key} disabled={option.key === disabledKey}>
                        {option.name}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            required
            rules={[{ required: true }]}
            label="Release Type"
            name={[documentType, 'shipment_document', 'release_type']}
          >
            <Radio.Group>
              {RELEASE_TYPE_ARRAY.map((option: RadioOptionProps, index: number) => (
                <Radio key={index} value={option.key}>
                  {option.name}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </Col>
        {showIncoterms && (
          // TODO: why are we not using incoterm search here???
          <Col span={8}>
            <Form.Item required rules={[{ required: true }]} label="Incoterms" name="incoterms">
              <Select
                showSearch
                filterOption={(input, option) => {
                  return (
                    `${option?.children || ''}`.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                  );
                }}
                allowClear
              >
                {INCOTERMS.map((opt: { key: string; display_name: string }) => (
                  <Select.Option key={opt.key} value={opt.key}>
                    {opt.display_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}
      </Row>

      <ShipmentPartyDetails
        form={form}
        freightType={freightType}
        tradeType={tradeType}
        shipmentType={shipmentType}
        documentType={documentType}
        notShowConsigneePartyTypes={shipmentType === SHIPMENT_TYPE_CONSOL}
        hideShipperConsignee={
          shipmentType === SHIPMENT_TYPE_BACK_TO_BACK && documentType === DOCUMENT_TYPE_MASTER
        }
        showDestinationAgent={
          shipmentType === SHIPMENT_TYPE_BACK_TO_BACK && documentType === DOCUMENT_TYPE_MASTER
        }
      />

      <Row gutter={ROW_GUTTER}>
        <Col span={8}>
          <Form.Item
            required
            rules={[{ required: true }]}
            label="Freight Charge Terms"
            name={[documentType, 'shipment_document', 'charge_terms', 'freight_terms']}
          >
            <Radio.Group>
              {CHARGE_TERMS.map((option: RadioOptionProps, index: number) => (
                <Radio key={index} value={option.key}>
                  {option.name}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            required
            rules={[{ required: true }]}
            label="Other Charge Terms"
            name={[documentType, 'shipment_document', 'charge_terms', 'other_charges_terms']}
          >
            <Radio.Group>
              {CHARGE_TERMS.map((option: RadioOptionProps, index: number) => (
                <Radio key={index} value={option.key}>
                  {option.name}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
    </Col>
  );
});

export default ShipmentDocumentDetails;
