import { gql } from '@apollo/client';
import {
  shipmentDocumentFields,
  shipmentDocumentWithShipmentFields,
} from 'operations/graphql/shipment';

export const FIND_DOCUMENT = gql`
  query find_document_signature($branch_account_id: ID!, $document_type: String!) {
    find_document_signature(branch_account_id: $branch_account_id, document_type: $document_type) {
      id
      signature
    }
  }
`;

export const FETCH_SHIPMENT_DOCUMENTS = gql`
  query fetchShipmentDocument($id: ID!) {
    fetch_document(id: $id) {
      ...shipmentDocumentFields
    }
  }
  ${shipmentDocumentFields}
`;

export const FETCH_SHIPMENT_DOCUMENT_WITH_SHIPMENT = gql`
  query fetchShipmentDocument($id: ID!) {
    fetch_document(id: $id) {
      ...shipmentDocumentWithShipmentFields
    }
  }
  ${shipmentDocumentWithShipmentFields}
`;
export const UPDATE_SHIPMENT_DOCUMENT = gql`
  mutation updateDocument($id: ID!, $shipment_document: ShipmentDocumentInput!) {
    update_shipment_document(id: $id, shipment_document: $shipment_document) {
      id
    }
  }
`;

export const MBL_RELEASE = gql`
  mutation mblRelease(
    $id: ID!
    $shipment: UpdateShipmentInput
    $shipment_document: ShipmentDocumentInput
    $cargo_details: [ContainerCargoDetailInputType!]
    $parties: [UpdateShipmentPartyInput!]
  ) {
    mbl_release(
      id: $id
      shipment: $shipment
      shipment_document: $shipment_document
      cargo_details: $cargo_details
      parties: $parties
    ) {
      message
    }
  }
`;

export const UPDATE_SHIPMENT_DOCUMENT_STATUS = gql`
  mutation updateDocumentStatus(
    $id: ID!
    $document_status: String!
    $document_status_event_date: Int!
    $document_status_event_data: JSON
  ) {
    update_shipment_document_status(
      id: $id
      document_status: $document_status
      document_status_event_date: $document_status_event_date
      document_status_event_data: $document_status_event_data
    ) {
      ...shipmentDocumentFields
    }
  }
  ${shipmentDocumentFields}
`;

export const UPDATE_SHIPMENT_ACCOUNTING_STATUS = gql`
  mutation updateShipmentaccountingStatus(
    $id: ID!
    $next_status: AccountingStatusEnum!
    $remarks: String
  ) {
    update_accounting_status(id: $id, next_status: $next_status, remarks: $remarks) {
      id
      accounting_status
    }
  }
`;
export const shipmentDocumentQuery = gql`
  query shipment_document($id: ID!) {
    shipment_document(id: $id) {
      id
      shipment_document_events {
        id
        event_by {
          id
          first_name
          last_name
        }
        event_date
        name
        event_data
        created_at
      }
    }
  }
`;
