import React from 'react';
import { Row, Col, Input, Form } from '@shipmnts/pixel-hub';
import { VesselSearch } from 'common';
import { GlobalSearch } from '@shipmnts/pixel-hub';

const ROW_GUTTER = 16;

const VoyageScheduleBasicDetails = React.memo(function VoyageScheduleBasicDetails(props: {
  disabled: boolean | undefined;
}): JSX.Element {
  const { disabled } = props;

  return (
    <React.Fragment>
      <Row gutter={ROW_GUTTER}>
        <Col span={12}>
          <Form.Item required rules={[{ required: true }]} name="vessel" label="Vessel">
            <VesselSearch disabled={disabled} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            required
            rules={[{ required: true }]}
            name="voyage_number"
            label="Voyage Number"
          >
            <Input placeholder="Enter Voyage number" disabled={disabled} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={ROW_GUTTER}>
        <Col span={12}>
          <Form.Item name="service_name" label="Service Name">
            <Input placeholder="Enter Service Name" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            required
            rules={[{ required: true }]}
            name="carriers"
            label="Carrier(s) Of This Voyage"
          >
            <GlobalSearch
              doc_type="Global::Carrier"
              searchProps={{ carrier_type: ['ocean', 'nvocc'] }}
              selectMode={'multiple'}
            />
          </Form.Item>
        </Col>
      </Row>
    </React.Fragment>
  );
});

export default VoyageScheduleBasicDetails;
