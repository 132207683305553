import { useMutation } from '@apollo/client';
import { message, Modal } from '@shipmnts/pixel-hub';
import React, { useEffect, useState } from 'react';
import { UPDATE_VIEW_SUBSCRIPTION } from 'operations/graphql/report';
import { erpNextAxiosCall } from 'src/packages/network/utils/erpnextApiHelper';

const disableFinanceAutoEmail = async (payload: any) => {
  try {
    const url =
      'api/method/shipmnts.custom_script.custom_auto_email_report.custom_auto_email_report.enable_disable_template';
    const response = await erpNextAxiosCall({
      action: 'post',
      url,
      payload: payload,
    });
    return { response };
  } catch (error) {
    console.error(error);
    return { error };
  }
};

interface StatusUpdateViewSubscriptionProps {
  viewSubscription: any;
  onClose?: () => void;
  onSuccess?: () => void;
}
function StatusUpdateViewSubscription(props: StatusUpdateViewSubscriptionProps) {
  const { onClose, viewSubscription, onSuccess } = props;
  const [disableVS, { data, error }] = useMutation(UPDATE_VIEW_SUBSCRIPTION);
  const [financeResponse, setFinanceResponse] = useState({ message: null, error: null });
  const activeSubscription = viewSubscription?.is_erpnext_auto_email
    ? viewSubscription?.enabled === 1
    : viewSubscription?.status === 'active';
  const actionName = activeSubscription ? 'Disable' : 'Enable';

  const [modal, contextHolder] = Modal.useModal();
  useEffect(() => {
    if (error) {
      message.error(error.message);
      return;
    }
    if (financeResponse?.error) {
      message.error(financeResponse.error);
      return;
    }
    if (data?.update_view_subscription || financeResponse?.message) {
      message.success(`Subscription ${actionName}d successfully!`);
      if (onSuccess) onSuccess();
      if (onClose) onClose();
    }
  }, [data, error, onSuccess, onClose, actionName, financeResponse]);

  const config = {
    title: `Are you sure to ${actionName} this Subscription?`,
    content: `Once you click on Confirm, It will ${actionName} Subscription`,
    okText: `Confirm ${actionName}`,
    cancelText: 'Cancel',
    onCancel: () => {
      if (onClose) onClose();
    },
    onOk: viewSubscription?.is_erpnext_auto_email
      ? async () => {
          const { response }: any = await disableFinanceAutoEmail({
            template_name: viewSubscription?.name,
          });
          setFinanceResponse((prevState) => ({
            ...prevState,
            ...response?.response?.data?.message,
          }));
        }
      : async () => {
          disableVS({
            variables: {
              id: viewSubscription?.id,
              view_subscription: {
                status: activeSubscription ? 'disabled' : 'active',
              },
            },
          });
        },
  };

  useEffect(() => {
    Modal.confirm(config);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  return <>{contextHolder}</>;
}

export default StatusUpdateViewSubscription;
