import React from 'react';
import { PRIMARY_TYPE } from '../constants';
import { Action } from '../models';
import { isEnableLoadAction, isEnableRoadAction } from '../ShipmentActions/shipmentActionHelper';
import Shipment from 'operations/models/Shipment';
import { CustomIcon } from '@shipmnts/pixel-hub';
import {
  assignLoadRender,
  CargoData,
  bulkCargoActionsRenderer,
  assignVehicleRender,
} from './cargoActionRenderer';
import { RowNode } from '@ag-grid-community/core';
import { get, startCase } from 'lodash';

//  get all cargo actions
export const getCargoActions = (cargo: CargoData) => {
  const actions: Action[] = [];

  const shipment = Shipment.create(cargo.shipment);
  const allocationPendingQuantity = get(cargo, 'allocation_pending_quantity') || 0;

  actions.push({
    type: PRIMARY_TYPE,
    key: 'assign_vehicle',
    displayComponent: 'Assign Vehicle',
    icon: <CustomIcon icon="TruckIcon" />,
    isEnable: isEnableRoadAction(shipment) && shipment.isRoadCustomerOrder(),
    performAction: assignVehicleRender,
  });

  actions.push({
    type: PRIMARY_TYPE,
    key: 'assign_load',
    displayComponent: 'Assign Load',
    icon: <CustomIcon icon="Package" />,
    isEnable:
      isEnableLoadAction(shipment) &&
      allocationPendingQuantity > 0 &&
      !!shipment.isRoadCustomerOrder(),
    performAction: assignLoadRender,
  });

  return actions;
};

const getBulkCargoAction = (action_name: string): Action => {
  return {
    key: action_name,
    type: PRIMARY_TYPE,
    displayComponent: startCase(action_name),
    icon: <CustomIcon icon="Package" />,
    description: startCase(action_name),
    performAction: bulkCargoActionsRenderer,
    isEnable: true,
  };
};

export const getCargoBulkActions = (selectedNodes: RowNode[]) => {
  if (selectedNodes.length === 0) return [];

  const actions: Action[] = [];
  actions.push(getBulkCargoAction('Assign Load'));
  return actions;
};
