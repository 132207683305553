import { get as _get, map as _map } from 'lodash';
import { Template } from 'operations/apis/document';

const escapeSpecialCharacters = (str: string, extra = false) => {
  let new_str = str.replace(/\$/g, '$$$');
  new_str = new_str.replace(/`/g, '');
  new_str = new_str.replace(/'/g, '′');
  new_str = new_str.replace(/</g, '&lt;');
  new_str = new_str.replace(/>/g, '&gt;');
  new_str = new_str.replace(/\\\d/g, '\\$&');
  if (extra) new_str = new_str.replace(/"/g, '\\"');
  return new_str;
};

const escapeJSSpecialCharacter = (str: string) => {
  const new_str = str.replace(/\\/g, '\\\\');
  return new_str;
};

const parse = (props: {
  key: string;
  htmlString: string;
  data: object;
  escapeJSChars: boolean;
}) => {
  const { key, htmlString, data, escapeJSChars = false } = props;
  const regex = new RegExp(`\\[\\[(${key})\\]\\]`, 'i');
  let parsedString = htmlString || '';
  try {
    const keys = key.split('.');
    const document_field_name = keys[0];
    const sub_fields = keys.slice(1, keys.length);
    const document_field_value = _get(data, document_field_name);
    let final_value = document_field_value;
    if (sub_fields && sub_fields.length > 0) {
      final_value = _get(final_value, sub_fields);
    }
    let newData;
    if (final_value && typeof final_value === 'object') {
      newData = escapeSpecialCharacters(JSON.stringify(final_value), true);
    } else {
      newData = escapeSpecialCharacters((final_value || '').toString());
      if (escapeJSChars) {
        newData = escapeJSSpecialCharacter(newData);
      }
    }
    parsedString = parsedString.replace(regex, newData);
    return parsedString;
  } catch (error) {
    console.error(`key: ${key} `, error);
    return parsedString.replace(regex, '');
  }
};

class TemplateGenerator {
  static toResolvedHtmlString = (props: {
    data: { [key: string]: any };
    htmlString: string;
    escapeJSChars?: boolean;
  }) => {
    const { data, htmlString = '', escapeJSChars = false } = props;
    try {
      let resolvedHtmlString = htmlString;
      const pattern = new RegExp(`\\[\\[([\\w\\.:,]+)\\]\\]`, 'g');
      const keyList = _map(htmlString.match(pattern), (str) => str.substr(2, str.length - 4));
      _map(keyList, (key) => {
        resolvedHtmlString = parse({ key, htmlString: resolvedHtmlString, data, escapeJSChars });
      });

      return resolvedHtmlString;
    } catch (error) {
      console.error(error);
      return htmlString;
    }
  };
  static getFinalHtml(props: { data: { [key: string]: any }; template: Template }) {
    const { data, template } = props;
    try {
      if (!data || !template) throw new Error('Template or data missing');
      let { scripts, body } = template;
      scripts = TemplateGenerator.toResolvedHtmlString({
        data,
        htmlString: scripts,
        escapeJSChars: true,
      });
      let finalHtml = `<html>
        <head>
          <meta content='text/html; charset=UTF-8' http-equiv='content-type'>
          <style>${template?.styles}</style>
          <script>${scripts}</script>
        </head>
        <body>`;
      if (_get(data, 'copies.length') > 0) {
        data.copies.forEach((copy: { copy_type: string; copy_number: string }) => {
          finalHtml =
            finalHtml +
            TemplateGenerator.toResolvedHtmlString({
              data: { ...data, copy },
              htmlString: body,
            });
        });
      } else {
        body = TemplateGenerator.toResolvedHtmlString({ data, htmlString: body });
        finalHtml = finalHtml + body;
      }
      finalHtml = finalHtml + '</body></html>';
      return finalHtml;
    } catch (error) {
      console.error(error);
      return '';
    }
  }
}

export default TemplateGenerator;
