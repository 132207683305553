import React from 'react';
import { GET_INQUIRY_OPTION_WITH_ESTIMATES } from 'operations/modules/booking/graphql/inquiryOption';
import { useQuery } from '@apollo/client';
import { Skeleton } from '@shipmnts/pixel-hub';
import ShipmentEstimateSummary from './ShipmentEstimateSummary';

export default function InquiryOptionSummary(props: { id: string }) {
  const { id } = props;
  const { loading, data } = useQuery(GET_INQUIRY_OPTION_WITH_ESTIMATES, {
    variables: { id: id },
  });
  if (!id) return <></>;
  if (loading) return <Skeleton />;
  return (
    <ShipmentEstimateSummary shipment_estimates={data?.get_inquiry_option?.shipment_estimates} />
  );
}
