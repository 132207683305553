import React, { useState, useCallback } from 'react';
import { Form, Row, Col, Select, Input, Checkbox, DatePicker } from '@shipmnts/pixel-hub';
import { CheckboxChangeEvent } from '@shipmnts/pixel-hub';
import { CompanySearch } from 'common';

interface DocumentDispatchDetailsProps {
  shareDocumentVisible: boolean;
  onShareDocumentVisibleChange: (val: boolean) => void;
}

export const DOCUMENT_DISPATCH_OPTIONS = [
  { label: 'Digital', value: 'digital' },
  { label: 'Courier', value: 'courier' },
  { label: 'Courier via Bank', value: 'courier_via_bank' },
];

const DocumentDispatchDetails = React.memo(function DocumentDispatchDetails(
  props: DocumentDispatchDetailsProps
): JSX.Element {
  const { shareDocumentVisible, onShareDocumentVisibleChange } = props;

  const [documentDispatchMode, setDocumentDispatchMode] = useState<string>(
    DOCUMENT_DISPATCH_OPTIONS[0].value
  );

  const onDispatchModeChanged = useCallback(
    (value: string) => {
      setDocumentDispatchMode(value);
      if (value === 'digital') onShareDocumentVisibleChange(true);
    },
    [onShareDocumentVisibleChange]
  );

  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="document_dispatch_mode" label="Dispatch Mode" required>
            <Select placeholder="Select Mode" onChange={onDispatchModeChanged}>
              {DOCUMENT_DISPATCH_OPTIONS.map((option) => {
                return (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="document_dispatch_date"
            label="Dispatch Date"
            required
            rules={[{ required: true, message: 'Required' }]}
          >
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
      {['courier', 'courier_via_bank'].includes(documentDispatchMode) && (
        <>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="courier_company"
                label="Courier Provider"
                required
                rules={[{ required: true, message: 'Required' }]}
              >
                <CompanySearch searchProps={{ is_vendor: true }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="courier_tracking_no"
                label="Courier Tracking No."
                required
                rules={[{ required: true, message: 'Required' }]}
              >
                <Input placeholder="Enter Tracking No." />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ marginBottom: '20px' }}>
            <Col span={12}>
              <Checkbox
                checked={shareDocumentVisible}
                onChange={(e: CheckboxChangeEvent) =>
                  onShareDocumentVisibleChange(e.target.checked)
                }
              >
                Notify via Email
              </Checkbox>
            </Col>
          </Row>
        </>
      )}
    </>
  );
});

export default DocumentDispatchDetails;
