import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Modal, Select, message, Input, Checkbox, BaseTable, dayjs } from '@shipmnts/pixel-hub';
import { erpnextApis } from 'network';
import { Column } from 'operations/models/Report';
import { GridOptions } from '@ag-grid-community/core';
import { defaultErrorMessage } from 'common';
import { ShipmentEstimateValue } from 'operations/models/ShipmentEstimate';
import { ShipmentValue } from 'operations/models/Shipment';
import { CompanySearch } from 'common';
import { CompanyValue } from 'common/models/Company';
import { DocumentRenderer } from '../Accounts/components/renderers';
import _debounce from 'lodash/debounce';
import { useShipmentEstimate } from './ShipmentEstimateLayout';
import { getFinalUoms } from './helper';
import { useShipmentEstimateStore } from 'operations/models/ShipmentEstimate';
import { ActionRendererDetailReport } from 'operations';

interface FilterType {
  searchTerm: string;
  selectedCustomer: string | undefined;
  selectedBranches: string[];
}

export const prepareQuotationForDataSource = (item: any) => {
  return {
    ...item,
    creation: dayjs(item.creation, 'YYYY-MM-DD').format('YYYY-MM-DD'),
    status: item.docstatus === 1 ? 'Submit' : item.docstatus === 2 ? 'Cancelled' : 'Draft',
  };
};

const _sortOnKeys = (keysArray: any) => (a: any, b: any) => {
  return keysArray.reduce((result: any, key: any) => {
    if (result) {
      return result;
    }
    if (a[key] > b[key]) return 1;
    else if (a[key] < b[key]) return -1;
    return 0;
  }, 0);
};

const ShipmentEstimatesQuotationsModal = (props: {
  shipment: ShipmentValue;
  onClose: () => void;
  addItemsToEstimates: (estimates: Partial<ShipmentEstimateValue>[]) => void;
}) => {
  const { shipment, onClose, addItemsToEstimates } = props;
  const { configData } = useShipmentEstimate();
  const { store } = useShipmentEstimateStore();
  const estimates = store.estimatesForShipment(shipment.id);
  const finalUoms = getFinalUoms(configData.unit_of_measurements, shipment, estimates);
  const accessible_branches: string[] = (shipment?.accessible_by_branches || []).map(
    (branchAccount) => branchAccount.erp_cost_center_id || ''
  );

  const [filters, setFilters] = useState<FilterType>({
    searchTerm: shipment.quotation_number || '',
    selectedCustomer: shipment.customer_company?.registered_name || '',
    selectedBranches: [],
  });

  const [prefillBuyRate, setPrefillBuyRate] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState<any>([]);

  const gridRef = useRef<GridOptions>();

  const columnDefs: Column[] = [
    {
      headerName: 'Quotation #',
      field: 'name',
      columnType: 'String',
      minWidth: 200,
      pinned: 'left',
    },
    {
      headerName: 'Name',
      field: 'title',
      columnType: 'String',
      minWidth: 200,
    },
    {
      headerName: 'Customer',
      field: 'customer_name',
      columnType: 'String',
      minWidth: 220,
    },
    {
      headerName: 'Branch',
      field: 'cost_center',
      columnType: 'String',
      minWidth: 200,
    },
    {
      headerName: 'Creation Date',
      field: 'creation',
      columnType: 'Date',
      minWidth: 100,
    },
    {
      headerName: 'Status',
      field: 'status',
      columnType: 'String',
      minWidth: 100,
      cellRenderer: 'DocumentRenderer',
    },
  ];

  const components = { DocumentRenderer, ActionRendererDetailReport };

  const fetchDataSource = useCallback(async () => {
    if (!loading) setLoading(false);
    const { data, error, status } = await erpnextApis.fetchErpNextQuotations(filters);

    if (data && data.data) {
      const dataSource = data.data.map((item: any) => prepareQuotationForDataSource(item));
      setDataSource(dataSource);
    } else {
      let errorMsg;
      if ([403, 401].includes(status)) {
        if (error._error_message) errorMsg = error._error_message.replace(/<[^>]+>/g, '');
        errorMsg = errorMsg || defaultErrorMessage(status, 'Quotations');
      } else {
        errorMsg = defaultErrorMessage(status, 'Quotations');
      }
      message.error(errorMsg);
    }
    setLoading(false);
  }, [filters, loading]);

  const onCustomerChange = useCallback(
    (value: CompanyValue | Array<CompanyValue> | undefined): void => {
      const newFilters = filters;
      newFilters['selectedCustomer'] = !Array.isArray(value) && value ? value.registered_name : '';
      setFilters(newFilters);
      fetchDataSource();
    },
    [filters, fetchDataSource]
  );

  const onSearchQuotationChange = _debounce((searchTerm: string) => {
    const newFilters = filters;
    newFilters['searchTerm'] = searchTerm;
    setFilters(newFilters);
    fetchDataSource();
  }, 1000);

  const onSelectedBranchesChange = (selectedBranches: string[]) => {
    const newFilters = filters;
    newFilters['selectedBranches'] = selectedBranches;
    setFilters(newFilters);
    fetchDataSource();
  };

  useEffect(() => {
    fetchDataSource();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const quotationsModalOkClickHandler = async (selectedQuotations: string[]) => {
    const { data, error } = await erpnextApis.fetchErpNextQuotationItems(selectedQuotations);
    let quotationItemList = [];
    if (error) message.error(error);
    if (data && data.data) quotationItemList = data.data.sort(_sortOnKeys(['parent', 'idx']));
    else message.error('We could not add charges from selected Quotations. Please try again.');
    return quotationItemList.map((quotationItem: any) => {
      const matchedUom = finalUoms.find((uom) => uom.value === quotationItem.uom);
      return {
        item: quotationItem.item_code,
        tag: quotationItem.shipment_tags === '' ? null : quotationItem.shipment_tags,
        uom: quotationItem.uom,
        quantity: matchedUom?.quantity || quotationItem.qty,
        buy_rate: prefillBuyRate ? quotationItem.rate_in_item_currency : 0,
        buy_exchange_rate: quotationItem.exchange_rate || 1,
        buy_currency: quotationItem.item_currency,
        sell_rate: quotationItem.rate_in_item_currency,
        sell_exchange_rate: quotationItem.exchange_rate || 1,
        sell_currency: quotationItem.item_currency,
      };
    });
  };

  const onOk = async () => {
    const selectedData = (gridRef.current?.api?.getSelectedNodes() || [])
      .filter((node) => !node.group)
      .map((node) => node.data);
    if (selectedData.length === 0) {
      message.error('Please select rows');
      return;
    }
    const draftSelected = selectedData.filter((row) => row.docstatus !== 1);
    if (draftSelected.length > 0) {
      message.error('You can not select Draft/Cancelled quotation rows. Please remove these rows.');
      return;
    }

    setLoading(true);
    const quotation_nos = selectedData.map((data) => data.name);
    const rowData: Partial<ShipmentEstimateValue>[] = await quotationsModalOkClickHandler(
      quotation_nos
    );
    await addItemsToEstimates(rowData);
    onClose();
    setLoading(false);
  };

  // filter renderer
  const renderQuotationsSearch = () => {
    return (
      <div style={{ display: 'flex' }}>
        <h4 style={{ paddingRight: '6px' }}>Search:</h4>
        <Input
          defaultValue={shipment.quotation_number || ''}
          placeholder="Search by Quotation Name"
          onChange={(event) => {
            onSearchQuotationChange(event.target.value);
          }}
          style={{ width: 250, marginBottom: '10px' }}
        />
      </div>
    );
  };

  const renderCustomerList = () => {
    return (
      <div style={{ marginLeft: '12px', display: 'flex' }}>
        <h4 style={{ paddingRight: '6px' }}>Customer: </h4>
        <CompanySearch
          searchProps={{
            is_customer: true,
          }}
          showAddCompanyAction={false}
          // FIX ME
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          value={shipment.customer_company as CompanyValue}
          // FIX ME
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          onChange={onCustomerChange}
        />
      </div>
    );
  };

  const renderBranchList = () => {
    return (
      <div style={{ marginLeft: '12px', display: 'flex' }}>
        <h4 style={{ paddingRight: '6px' }}>Branch:</h4>
        <Select
          onChange={(selectedBranches) => {
            onSelectedBranchesChange(
              selectedBranches.length > 0 ? selectedBranches : accessible_branches
            );
          }}
          style={{ width: 250 }}
          mode="multiple"
          allowClear={false}
          placeholder={'Select Branch'}
        >
          {accessible_branches.map((branchName: string) => (
            <Select.Option key={branchName} value={branchName}>
              {branchName}
            </Select.Option>
          ))}
        </Select>
      </div>
    );
  };

  const renderPrefillBuyRateCheckBox = () => {
    return (
      <div style={{ marginLeft: '12px', display: 'flex' }}>
        <Checkbox checked={prefillBuyRate} onChange={(e) => setPrefillBuyRate(e.target.checked)}>
          Prefill Buy Rate
        </Checkbox>
      </div>
    );
  };

  return (
    <div>
      <Modal
        title={'Select Quotation to Add Items'}
        open
        width="80vw"
        onOk={onOk}
        okText={'Add Items From Quotations'}
        cancelText={'Close'}
        confirmLoading={loading}
        onCancel={onClose}
        okButtonProps={{}}
        style={{ top: 50 }}
      >
        <div style={{ display: 'flex' }}>
          {renderQuotationsSearch()}
          {renderCustomerList()}
          {accessible_branches && accessible_branches.length > 1 ? renderBranchList() : null}
          {renderPrefillBuyRateCheckBox()}
        </div>

        <BaseTable
          reportName={'shipment_estimate_quoation'}
          rowSelection="multiple"
          gridRef={gridRef}
          columns={columnDefs}
          rowData={dataSource}
          reportConfig={{
            components,
          }}
        />
      </Modal>
    </div>
  );
};

export default ShipmentEstimatesQuotationsModal;
