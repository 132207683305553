import React, { useEffect, useState } from 'react';
import { Result, Button } from '@shipmnts/pixel-hub';
import Spinner from 'src/components/Spinner';
import { useLocation } from 'wouter';

const AuthCallback = (props) => {
  const { 1: navigate } = useLocation();
  const [error, setError] = useState();

  useEffect(() => {
    async function foo() {
      if (window.location.search.includes('error=')) {
        const params = new URLSearchParams(window.location.search);
        setError(params);
      }
    }
    foo();
  }, []);

  if (error) {
    return (
      <Result
        status="403"
        subTitle={'Error occurred when trying to log you in.'}
        title={error.get('error_description')}
        extra={[
          <Button key="logout" type="primary" onClick={() => navigate('/')}>
            Try Again
          </Button>,
          <Button key="support" type="default">
            <a
              href={`mailto:support@shipmnts.com?subject=Error%20occurred%20when%20trying%20to%20log%20you%20in&body=${error.get(
                'error_description'
              )}`}
            >
              Contact Support
            </a>
          </Button>,
        ]}
      />
    );
  }
  return (
    <div className="centered fullscreen">
      <Spinner tip="checking your credentials and logging you in..." />
    </div>
  );
};

export default AuthCallback;
