import React, { useCallback, useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Column } from 'operations/models/Report';
import { useShipmentDetail } from '../ShipmentDetailLayout';
import { FETCH_SHIPMENT_CUSTOM_DETAILS } from 'operations/graphql/shipmentCustomDetail';
import { ActionRendererDetailReport } from 'operations/modules/actionHelper/ActionRenderer';
import { findLast, get } from 'lodash';
import {
  Layout,
  ActivityRenderer,
  StaticHeaderComponent,
  TrackerContextProvider,
} from '@shipmnts/pixel-hub';
import {
  TradeType,
  TRADE_TYPE_CROSS_TRADE,
  TRADE_TYPE_DOMESTIC,
  TRADE_TYPE_EXPORT,
  TRADE_TYPE_IMPORT,
} from 'operations/modules/shipment/constants';
import { ShipmentCustomDetailValue } from 'operations/models/ShipmentCustomDetail';
import EditShipmentCustomDetail from './EditShipmentCustomDetail';
import EventRenderer from '../Containers/EventRenderer';
import DocTypeNumberRenderer from '../Common/DocTypeNumberRenderer';
import { fetchDocumentsCount } from '../../../helpers/shipmentHelper';
import { getWithCustomFieldValues, makeColumnForTab, useSession } from 'common';
import { getLastEvent } from '../Containers/ContainerDetails';
import { commonPropsCustomDetails } from './CustomDetailsTable';
import CustomDetailsTable from './CustomDetailsTable';
import { gql } from '@apollo/client';

const GET_CUSTOM_FIELDS_FROM_DOC_TYPE = gql`
  query get_custom_fields_from_doc_type($doc_type_id: String!) {
    get_custom_fields_from_doc_type(doc_type_id: $doc_type_id) {
      id
      db_column_name
      filterable
      width
      field_type
      label
      filter_options
      cell_renderer
      cell_editor
      type
    }
  }
`;

const export_columns = [
  'trade_type',
  'custom_document_number',
  'custom_clearance_agent',
  'custom_document_date',
  'custom_clearance_location',
  'cargo_gross_weight',
  'egm_no',
  'icd_egm_no',
  'reward_amount',
  'drawback_scroll_number',
  'last_action_status',
  'action',
  'last_comment',
  'last_status_update',
  'current_queue',
];

const import_columns = [
  'trade_type',
  'custom_document_number',
  'custom_clearance_agent',
  'custom_document_date',
  'custom_clearance_location',
  'cargo_gross_weight',
  'duty_amount',
  'duty_payment_by',
  'assessable_amount',
  'last_action_status',
  'action',
  'last_comment',
  'last_status_update',
  'current_queue',
];

const CustomsTab = (props: any) => {
  const { shipment, refetchShipments } = useShipmentDetail();
  const [activeShipmentCustomDetail, setActiveShipmentCustomDetail] = useState<
    ShipmentCustomDetailValue | undefined
  >(undefined);

  const [documentsCount, setDocumentsCount] = useState<any>(null);

  const tradeType = shipment?.trade_type || TRADE_TYPE_IMPORT;
  const originClearance = shipment?.services?.origin?.clearance;
  const destinationClearance = shipment?.services?.destination?.clearance;

  const [fetchCustomDetails, { data, loading, error, refetch }] = useLazyQuery(
    FETCH_SHIPMENT_CUSTOM_DETAILS,
    {
      variables: { shipment_ids: [shipment?.id] },
    }
  );

  const { externalLink } = props;
  const [getFieldsFromDocType, { data: fieldData }] = useLazyQuery(GET_CUSTOM_FIELDS_FROM_DOC_TYPE);
  const [customDetails, setCustomDetails] = useState([]);

  useEffect(() => {
    fetchCustomDetails();
  }, [fetchCustomDetails, shipment]);

  useEffect(() => {
    setCustomDetails(getWithCustomFieldValues(get(data, 'fetch_shipment_custom_details', [])));
  }, [data, setCustomDetails]);

  const sessionData = useSession();

  const onCloseEdit = useCallback(() => {
    setActiveShipmentCustomDetail(undefined);
  }, [setActiveShipmentCustomDetail]);

  useEffect(() => {
    const ids = customDetails.map((sc: any) => sc.id);
    fetchDocumentsCount(
      'shipment_custom_detail',
      ids,
      sessionData.id,
      sessionData.company_account.id
    ).then((res) => {
      const count = get(res, 'data.count');
      setDocumentsCount(count);
    });
  }, [customDetails, sessionData.company_account.id, sessionData.id]);

  useEffect(() => {
    getFieldsFromDocType({ variables: { doc_type_id: 'Shipment::ShipmentCustomDetail' } });
  }, [getFieldsFromDocType]);

  const columnDefs: { [key: string]: Column } = {
    trade_type: {
      headerName: 'Trade Type',
      field: 'trade_type',
      colId: 'trade_type',
      hide: true,
      suppressColumnsToolPanel: true,
      valueGetter: ({ data }) => `${data.trade_type} Custom Details`,
    },
    custom_document_number: {
      headerName: 'Document #',
      field: 'custom_document_number',
      colId: 'custom_document_number',
      filter: 'agTextColumnFilter',
      cellRenderer: 'render_tracker_drawer',
      pinned: 'left',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      width: 160,
    },
    custom_document_date: {
      headerName: 'Custom Document Date',
      field: 'custom_document_date',
      colId: 'custom_document_date',
      columnType: 'Date',
      width: 200,
      filter: false,
    },
    custom_clearance_agent: {
      headerName: 'CHA',
      field: 'custom_clearance_agent',
      colId: 'custom_clearance_agent',
      width: 200,
      filter: 'agSetColumnFilter',
    },
    custom_clearance_location: {
      headerName: 'Customs Clearance Location',
      field: 'custom_clearance_location.name',
      colId: 'custom_clearance_location.name',
      width: 200,
      filter: 'agSetColumnFilter',
    },
    cargo_gross_weight: {
      headerName: 'Gross Weight',
      field: 'cargo_gross_weight',
      colId: 'cargo_gross_weight',
      columnType: 'Float',
      width: 200,
      filter: 'agNumberColumnFilter',
    },
    reward_amount: {
      headerName: 'Reward Amt',
      field: 'reward_amount',
      colId: 'reward_amount',
      columnType: 'Float',
      width: 200,
      filter: 'agNumberColumnFilter',
    },
    icd_egm_no: {
      headerName: 'ICD EGM #',
      field: 'icd_egm_no',
      colId: 'icd_egm_no',
      width: 200,
      filter: 'agNumberColumnFilter',
    },
    egm_no: {
      headerName: 'EGM #',
      field: 'egm_no',
      colId: 'egm_no',
      width: 200,
      filter: 'agNumberColumnFilter',
    },
    drawback_scroll_number: {
      headerName: 'Drawback Scroll #',
      field: 'drawback_scroll_number',
      colId: 'drawback_scroll_number',
      width: 200,
      filter: 'agNumberColumnFilter',
    },
    current_queue: {
      headerName: 'Current Queue',
      field: 'current_queue',
      colId: 'current_queue',
      width: 200,
      filter: 'agNumberColumnFilter',
    },
    assessable_amount: {
      headerName: 'Assessable Value',
      field: 'assessable_value',
      colId: 'assessable_value',
      columnType: 'Float',
      width: 200,
    },
    duty_amount: {
      headerName: 'Duty Amt',
      field: 'duty_paid_amount',
      colId: 'duty_paid_amount',
      columnType: 'Float',
      width: 200,
    },
    duty_payment_by: {
      headerName: 'Duty Payment By',
      field: 'duty_payment_by',
      colId: 'duty_payment_by',
      width: 200,
      filter: 'agSetColumnFilter',
    },
    last_action_status: {
      headerName: 'Last Action',
      field: 'last_event',
      colId: 'last_event',
      width: 250,
      pinned: 'right',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      cellRenderer: 'render_event',
      cellRendererParams: {
        doc_type_id: 'Shipment::ShipmentCustomDetail',
        refetchData: refetch,
        workflow_type: 'main',
      },
      filter: 'agSetColumnFilter',
      keyCreator: (params: any) => params.value?.name,
      filterParams: {
        convertValuesToStrings: true,
      },
    },
    last_comment: {
      headerName: 'Comment',
      field: 'comment',
      colId: 'comment',
      width: 200,
      pinned: 'right',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      cellRenderer: 'render_activity',
      cellRendererParams: {
        type: 'Comment',
        doc_type_id: 'Shipment::ShipmentCustomDetail',
        refetchData: refetch,
        showDrawer: externalLink,
      },
    },
    last_status_update: {
      headerName: 'Status Update',
      field: 'status_update',
      colId: 'status_update',
      width: 200,
      pinned: 'right',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      cellRenderer: 'render_activity',
      cellRendererParams: {
        type: 'StatusUpdate',
        doc_type_id: 'Shipment::ShipmentCustomDetail',
        refetchData: refetch,
        showDrawer: externalLink,
      },
    },
    action: {
      headerName: 'Actions',
      colId: 'actions',
      field: 'actions',
      valueGetter: 'data',
      width: 100,
      pinned: 'right',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      headerComponent: 'static_header_component',
      cellRenderer: 'actions_render',
      cellRendererParams: {
        doc_type_id: 'Shipment::ShipmentCustomDetail',
        refetchData: refetch,
      },
    },
  };

  const [allColumnsDefs, setAllColumnsDefs] = useState<{ [key: string]: Column }>(columnDefs);

  useEffect(() => {
    if (fieldData) {
      const resultObject: { [key: string]: Column } =
        fieldData.get_custom_fields_from_doc_type.reduce((new_field: any, field: any) => {
          new_field[field.db_column_name] = makeColumnForTab(field);
          if (!export_columns.includes(field.db_column_name))
            export_columns.push(field.db_column_name);
          if (!import_columns.includes(field.db_column_name))
            import_columns.push(field.db_column_name);
          return new_field;
        }, {});
      setAllColumnsDefs({ ...allColumnsDefs, ...resultObject });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldData]);

  if (loading) {
    return <div>loading...</div>;
  }

  if (error) {
    return <div>error occured</div>;
  }

  const refetchData = async () => {
    if (refetch) {
      await refetch();
    }
    if (refetchShipments) {
      await refetchShipments();
    }
  };
  let importColDef: Column[] = [];
  let exportColDef: Column[] = [];

  exportColDef = export_columns.map((col: string) => {
    return allColumnsDefs[col];
  });

  importColDef = import_columns.map((col: string) => {
    return allColumnsDefs[col];
  });

  const custom_clearance_agent_party = shipment?.getShipmentPartyByName(
    shipment?.trade_type === TRADE_TYPE_EXPORT
      ? 'origin_clearance_agent'
      : 'destination_clearance_agent'
  );

  //adding additional details
  const custom_details = (t: TradeType) =>
    customDetails
      .filter((e: any) => e.trade_type === t)
      .map((element: any) => {
        const el = { ...element };

        // linked document counts
        el.doc_count =
          documentsCount?.find((doc: any) => doc.parent_id && doc.parent_id === element.id)
            ?.count || 0;

        if (el.tracking_events) {
          const last = findLast(el.tracking_events, (event) => event.actual_date);
          el.last_event = last
            ? { ...last, current_location: last?.location?.name }
            : { name: el.last_action_status };
        }
        if (el.tracking_events) {
          el.last_event = getLastEvent(el.tracking_events, el.last_action_status);
        }
        el.custom_clearance_agent = custom_clearance_agent_party?.party_company?.registered_name;
        el.refetch = refetchData;
        el.comment = element.last_comment;
        el.status_update = element.last_status_update;
        return el;
      });

  const onSuccessUpdate = () => {
    setActiveShipmentCustomDetail(undefined);
    refetchData();
  };

  const components = {
    actions_render: ActionRendererDetailReport,
    static_header_component: StaticHeaderComponent,
    render_event: EventRenderer,
    render_tracker_drawer: DocTypeNumberRenderer,
    render_activity: ActivityRenderer,
  };

  const commonProps: commonPropsCustomDetails = {
    shipment: shipment,
    setActiveShipmentCustomDetail: setActiveShipmentCustomDetail,
    onSuccess: refetchData,
    components: components,
    height: '34vh',
  };

  if (shipment && data) {
    return (
      <>
        <div className="customs-tab">
          <TrackerContextProvider referenceType="Shipment::ShipmentCustomDetail">
            <Layout>
              {(tradeType === TRADE_TYPE_EXPORT ||
                tradeType === TRADE_TYPE_CROSS_TRADE ||
                tradeType === TRADE_TYPE_DOMESTIC ||
                originClearance) && (
                <CustomDetailsTable
                  height={!destinationClearance ? '34vh' : '30vh'}
                  tradeType={TRADE_TYPE_EXPORT}
                  columns={Object.values(exportColDef)}
                  rowData={custom_details(TRADE_TYPE_EXPORT)}
                  {...commonProps}
                />
              )}
              {(tradeType === TRADE_TYPE_IMPORT || destinationClearance) && (
                <CustomDetailsTable
                  height={!originClearance ? '34vh' : '30vh'}
                  tradeType={TRADE_TYPE_IMPORT}
                  columns={Object.values(importColDef)}
                  rowData={custom_details(TRADE_TYPE_IMPORT)}
                  {...commonProps}
                />
              )}
            </Layout>
            {activeShipmentCustomDetail && (
              <EditShipmentCustomDetail
                shipment={shipment}
                shipmentCustomDetail={activeShipmentCustomDetail}
                onSuccess={onSuccessUpdate}
                onClose={onCloseEdit}
              />
            )}
          </TrackerContextProvider>
        </div>
      </>
    );
  }
  return <></>;
};

export default CustomsTab;
