import React from 'react';
import { Checkbox, Form, FormItemProps, Select, Tooltip, DatePicker } from '@shipmnts/pixel-hub';
import { CompanySearch } from 'common';
import { baseConstants } from 'common';

import { startCase } from 'lodash';
import {
  FREIGHT_TYPE_AIR,
  FREIGHT_TYPE_OCEAN,
  FREIGHT_TYPE_ROAD,
  SERVICE_CARGO_INSURANCE,
  SERVICE_EMPTY_CONTAINER_INSURANCE,
  SERVICE_EMPTY_LIVE_TRACKING,
  SERVICE_EMPTY_WAREHOUSE,
  SERVICE_FUMIGATION,
  SERVICE_PALLETIZATION,
  SERVICE_TYPE_DESTINATION_CLEARANCE,
  SERVICE_TYPE_DESTINATION_TRANSPORT,
  SERVICE_TYPE_FREIGHT,
  SERVICE_TYPE_ORIGIN_CLEARANCE,
  SERVICE_TYPE_ORIGIN_TRANSPORT,
} from 'sales_hub/utils/constants';

import { CarrierType, CarrierValue } from 'sales_hub/models/Carrier';
import { convertDateToUnix } from 'sales_hub/components/Inquiry/InquiryLostLayout';
import { getChargeableWeightCondition } from 'sales_hub/utils/utils';
import { RateExplorerResource } from 'sales_hub/components/Inquiry/RateExplorer/RateExplorer.type';
import { LocationType } from 'sales_hub/models/Location';
import { GlobalSearch } from '@shipmnts/pixel-hub';
const {
  CARRIER_TYPE_AIR,
  CARRIER_TYPE_COLOADER,
  CARRIER_TYPE_NVOCC,
  CARRIER_TYPE_OCEAN,
  containerTypesFlatArray,
  COMMODITY_TYPE_PERISHABLE,
  COMMODITY_TYPE_TEMP_CONTROLLED,
  COMMODITY_TYPE_HAZARDOUS,
  COMMODITY_TYPE_BATTERY,
} = baseConstants;
interface getFormItemProp {
  Komonent: any;
  formItemProp: FormItemProps;
  komponentProp?: any;
  tooltip?: string;
}

const { RangePicker } = DatePicker;

const getFormItem = (props: getFormItemProp) => {
  const { formItemProp, Komonent, komponentProp, tooltip } = props;
  return !!tooltip ? (
    <Tooltip title={tooltip}>
      <Form.Item {...formItemProp}>
        <Komonent {...komponentProp} />
      </Form.Item>
    </Tooltip>
  ) : (
    ((
      <Form.Item {...formItemProp}>
        <Komonent {...komponentProp} />
      </Form.Item>
    ) as React.JSX.Element)
  );
};

const getLoadTypeOptions = (freightType: string | undefined) => {
  if (!freightType) return [];
  if (freightType === FREIGHT_TYPE_OCEAN)
    return [
      {
        value: 'fcl',
        label: 'FCL',
      },
      {
        value: 'lcl',
        label: 'LCL',
      },
    ];
  if (freightType === FREIGHT_TYPE_AIR)
    return [
      {
        value: 'loose',
        label: 'Loose',
      },
      {
        value: 'uld',
        label: 'ULD',
      },
    ];

  return [
    {
      value: 'fcl',
      label: 'FCL',
    },
    {
      value: 'lcl',
      label: 'LCL',
    },
    {
      value: 'ftl',
      label: 'FTL',
    },
  ];
};

export function rateExplorerFiltersMapping(
  inquiry: RateExplorerResource
): Record<string, React.JSX.Element> {
  const freightType = inquiry.freight_type;
  const tradeType = inquiry.trade_type;
  const locationType: LocationType[] = ['ICD'];

  if (freightType === FREIGHT_TYPE_AIR) locationType.push('Airport');
  if (freightType === FREIGHT_TYPE_OCEAN) locationType.push('Seaport');

  const carrierSearchProp: {
    carrier_type?: Array<CarrierType>;
  } = {
    carrier_type:
      freightType === FREIGHT_TYPE_OCEAN
        ? [CARRIER_TYPE_OCEAN, CARRIER_TYPE_NVOCC, CARRIER_TYPE_COLOADER]
        : [CARRIER_TYPE_AIR],
  };

  return {
    quotation_ids: getFormItem({
      formItemProp: { name: 'quotation_ids', label: 'Quotation # in' },
      Komonent: GlobalSearch,
      komponentProp: {
        doc_type: 'SalesHub::InquiryOption',
        extraProps: {
          placeholder: 'Enter Quotation Numbers',
        },
        selectMode: 'multiple',
        searchProps: { freight_type: freightType, trade_type: tradeType },
      },
    }),

    port_of_loading: getFormItem({
      formItemProp: { name: 'port_of_loading', label: 'Port Of Loading' },
      Komonent: GlobalSearch,
      komponentProp: {
        searchProps: {
          type: freightType === FREIGHT_TYPE_AIR ? ['Airport'] : ['Seaport'],
        },
        doc_type: 'Global::Location',
      },
    }),

    port_of_discharge: getFormItem({
      formItemProp: { name: 'port_of_discharge', label: 'Port Of Discharge' },
      Komonent: GlobalSearch,
      komponentProp: {
        searchProps: {
          type: freightType === FREIGHT_TYPE_AIR ? ['Airport'] : ['Seaport'],
        },
        doc_type: 'Global::Location',
      },
    }),

    incoterms: getFormItem({
      formItemProp: { name: 'incoterms', label: 'Incoterms' },
      Komonent: GlobalSearch,
      komponentProp: {
        doc_type: 'Global::Incoterms',
      },
    }),

    load_type: getFormItem({
      formItemProp: { name: 'load_type', label: 'Load Type' },
      tooltip: 'Selection will be applied as OR',
      Komonent: Select,
      komponentProp: {
        allowClear: true,
        mode: 'multiple',
        options: getLoadTypeOptions(freightType),
      },
    }),
    ch_wt_slab: getFormItem({
      formItemProp: { name: 'ch_wt_slab', label: 'Ch Wt' },
      Komonent: Select,
      komponentProp: {
        allowClear: true,
        options: [
          { value: 'less_than_45', label: '-45' },
          { value: 'more_than_45', label: '+45' },
          { value: 'more_than_100', label: '+100' },
          { value: 'more_than_200', label: '+200' },
          { value: 'more_than_300', label: '+300' },
          { value: 'more_than_500', label: '+500' },
          { value: 'more_than_1000', label: '+1000' },
        ],
      },
    }),

    carrier: getFormItem({
      formItemProp: { name: 'carrier', label: 'Carrier' },
      Komonent: GlobalSearch,
      tooltip: 'Selection will be applied as OR',
      komponentProp: {
        doc_type: 'Global::Carrier',
        searchProps: carrierSearchProp,
        selectMode: 'multiple',
      },
    }),

    billing_party: getFormItem({
      formItemProp: { name: 'billing_party', label: 'Agent Company' },
      Komonent: CompanySearch,
      komponentProp: {
        searchProps: { is_lead: true, showAddCompanyAction: false, renderViewType: 'NewTab' },
      },
    }),

    services: getFormItem({
      formItemProp: { name: 'services', label: 'Services' },
      Komonent: Select,
      tooltip: 'Selection will be applied as OR',
      komponentProp: {
        allowClear: true,
        mode: 'multiple',
        options: [
          {
            value: SERVICE_TYPE_FREIGHT,
            label: startCase(SERVICE_TYPE_FREIGHT),
          },
          {
            value: SERVICE_TYPE_ORIGIN_CLEARANCE,
            label: startCase(SERVICE_TYPE_ORIGIN_CLEARANCE),
          },
          {
            value: SERVICE_TYPE_DESTINATION_CLEARANCE,
            label: startCase(SERVICE_TYPE_DESTINATION_CLEARANCE),
          },
          {
            value: SERVICE_TYPE_ORIGIN_TRANSPORT,
            label: startCase(SERVICE_TYPE_ORIGIN_TRANSPORT),
          },
          {
            value: SERVICE_TYPE_DESTINATION_TRANSPORT,
            label: startCase(SERVICE_TYPE_DESTINATION_TRANSPORT),
          },
          {
            value: SERVICE_PALLETIZATION,
            label: startCase(SERVICE_PALLETIZATION),
          },
          {
            value: SERVICE_FUMIGATION,
            label: startCase(SERVICE_FUMIGATION),
          },
          {
            value: SERVICE_CARGO_INSURANCE,
            label: startCase(SERVICE_CARGO_INSURANCE),
          },
          {
            value: SERVICE_EMPTY_CONTAINER_INSURANCE,
            label: startCase(SERVICE_EMPTY_CONTAINER_INSURANCE),
          },
          {
            value: SERVICE_EMPTY_WAREHOUSE,
            label: startCase(SERVICE_EMPTY_WAREHOUSE),
          },
          {
            value: SERVICE_EMPTY_LIVE_TRACKING,
            label: startCase(SERVICE_EMPTY_LIVE_TRACKING),
          },
        ],
      },
    }),
    cargo_properties: getFormItem({
      formItemProp: { name: 'cargo_properties', label: 'Commodity Type' },
      Komonent: Select,
      tooltip: 'Selection will be applied as OR',
      komponentProp: {
        allowClear: true,
        mode: 'multiple',
        options: [
          {
            value: COMMODITY_TYPE_PERISHABLE,
            label: COMMODITY_TYPE_PERISHABLE,
          },
          {
            value: COMMODITY_TYPE_TEMP_CONTROLLED,
            label: COMMODITY_TYPE_TEMP_CONTROLLED,
          },
          {
            value: COMMODITY_TYPE_HAZARDOUS,
            label: COMMODITY_TYPE_HAZARDOUS,
          },
          {
            value: COMMODITY_TYPE_BATTERY,
            label: COMMODITY_TYPE_BATTERY,
          },
        ],
      },
    }),

    created_at: getFormItem({
      formItemProp: { name: 'created_at', label: 'Date Range' },
      Komonent: RangePicker,
      tooltip: 'Date range based on Quote Creation date and Shipment Job Execution date.',
      komponentProp: {
        style: { width: '100%' },
        mode: ['date', 'date'],
      },
    }),

    all_customer: getFormItem({
      formItemProp: { name: 'all_customer', label: 'All Customer', valuePropName: 'checked' },
      Komonent: Checkbox,
    }),

    place_of_carrier_delivery: getFormItem({
      formItemProp: {
        name: 'place_of_carrier_delivery',
        label: 'Place Of Carrier Delivery',
      },
      Komonent: GlobalSearch,
      komponentProp: {
        searchProps: { type: locationType },
        doc_type: 'Global::Location',
      },
    }),

    origin_custom_clearance: getFormItem({
      formItemProp: {
        name: 'origin_custom_clearance',
        label: 'Origin Custom Clearance',
      },

      Komonent: GlobalSearch,
      komponentProp: {
        searchProps: { type: ['City'] },
        doc_type: 'Global::Location',
      },
    }),

    destination_custom_clearance: getFormItem({
      formItemProp: {
        name: 'destination_custom_clearance',
        label: 'Destination custom clearance',
      },

      Komonent: GlobalSearch,
      komponentProp: {
        searchProps: { type: ['City'] },
        doc_type: 'Global::Location',
      },
    }),

    place_of_carrier_receipt: getFormItem({
      formItemProp: {
        name: 'place_of_carrier_receipt',
        label: 'Place Of Carrier Receipt',
      },
      Komonent: GlobalSearch,
      komponentProp: {
        searchProps: { type: locationType },
        doc_type: 'Global::Location',
      },
    }),
    container_type_code: getFormItem({
      formItemProp: {
        name: 'container_type_code',
        label: 'Container Type',
      },
      Komonent: Select,
      tooltip: 'Selection will be applied as OR',
      komponentProp: {
        allowClear: true,
        mode: 'multiple',
        options: containerTypesFlatArray,
      },
    }),
  };
}

function getValueFromObject(field_name: string) {
  return (obj: any, resource: RateExplorerResource) => {
    const value: any = {};
    const field = field_name + (Array.isArray(obj) ? '_ids' : '_id');
    value[field] = Array.isArray(obj) ? obj.map((r) => r?.id) : obj?.id;
    return value;
  };
}

function getCarrierValue(carries: CarrierValue[], resource: RateExplorerResource) {
  const ids = (carries || []).map((ele) => ele.id);
  return {
    carrier_id: ids.length > 0 ? ids : undefined,
  };
}

function getQuoteValidTo(validTo: [string, string], resource: RateExplorerResource) {
  if (validTo?.[0] && validTo?.[1])
    return {
      created_at: [convertDateToUnix(validTo?.[0]), convertDateToUnix(validTo?.[1])],
    };
  return {};
}

function getCustomerValue(value: boolean, resource: RateExplorerResource) {
  return value
    ? { customer_company_id: undefined }
    : { customer_company_id: resource.customer_company?.id };
}

export const FILTERS: any = {
  [FREIGHT_TYPE_AIR]: [
    { field: 'quotation_ids', getValue: getValueFromObject('quotation') },
    { field: 'load_type' },
    {
      field: 'port_of_loading',
      getValue: getValueFromObject('port_of_loading'),
    },
    {
      field: 'port_of_discharge',
      getValue: getValueFromObject('port_of_discharge'),
    },
    { field: 'incoterms' },
    { field: 'ch_wt_slab', getValue: getChargeableWeightCondition },
    { field: 'carrier', getValue: getCarrierValue },
    {
      field: 'billing_party',
      getValue: getValueFromObject('billing_party'),
    },
    { field: 'services' },
    { field: 'cargo_properties' },
    { field: 'created_at', getValue: getQuoteValidTo },
    { field: 'all_customer', getValue: getCustomerValue },
  ],
  [FREIGHT_TYPE_OCEAN]: [
    { field: 'quotation_ids', getValue: getValueFromObject('quotation') },
    { field: 'load_type' },
    {
      field: 'port_of_loading',
      getValue: getValueFromObject('port_of_loading'),
    },
    {
      field: 'port_of_discharge',
      getValue: getValueFromObject('port_of_discharge'),
    },
    { field: 'incoterms' },
    { field: 'carrier', getValue: getCarrierValue },
    {
      field: 'place_of_carrier_receipt',
      getValue: getValueFromObject('place_of_carrier_receipt'),
    },
    {
      field: 'place_of_carrier_delivery',
      getValue: getValueFromObject('place_of_carrier_delivery'),
    },
    { field: 'services' },
    { field: 'cargo_properties' },
    {
      field: 'billing_party',
      getValue: getValueFromObject('billing_party'),
    },
    { field: 'created_at', getValue: getQuoteValidTo },
    { field: 'container_type_code' },
    { field: 'all_customer', getValue: getCustomerValue },
  ],
  [FREIGHT_TYPE_ROAD]: [
    { field: 'quotation_ids', getValue: getValueFromObject('quotation') },
    { field: 'load_type' },
    { field: 'created_at', getValue: getQuoteValidTo },
    { field: 'all_customer', getValue: getCustomerValue },
    { field: 'services' },
    { field: 'cargo_properties' },
  ],
  custom: [
    { field: 'quotation_ids', getValue: getValueFromObject('quotation') },
    { field: 'load_type' },
    {
      field: 'origin_custom_clearance',
      getValue: getValueFromObject('origin_custom_clearance_location'),
    },
    {
      field: 'destination_custom_clearance',
      getValue: getValueFromObject('destination_custom_clearance_location'),
    },
    { field: 'carrier', getValue: getCarrierValue },
    { field: 'incoterms' },
    { field: 'services' },
    { field: 'cargo_properties' },
    {
      field: 'billing_party',
      getValue: getValueFromObject('billing_party'),
    },
    { field: 'created_at', getValue: getQuoteValidTo },
    { field: 'all_customer', getValue: getCustomerValue },
  ],
};
