import { Drawer, DrawerFooter, Form } from '@shipmnts/pixel-hub';
import React, { useState } from 'react';
import SplitAndCreateBooking from './SplitAndCreateBooking';
import { ContainerReportData } from '../reports/reportModels';
import { ShipmentValue } from 'operations/models/Shipment';

interface SplitAndCreateBookingProps {
  ocean_transport_order?: any;
  containers?: ContainerReportData[];
  rolloverOrShutout?: 'rollover';
  onClose?: () => void;
  onSuccess?: (sendEmail?: boolean, rs?: string[]) => void;
  splitFromShipment?: boolean;
  shipment?: ShipmentValue;
}

export default function SplitAndCreateBookingDrawer(props: SplitAndCreateBookingProps) {
  const { onClose } = props;
  const [loading, setLoading] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [form] = Form.useForm();

  return (
    <Drawer
      title="Update Routing"
      open
      width={'60%'}
      onClose={onClose}
      footer={
        <DrawerFooter
          saveText="Update booking"
          onSave={() => form.submit()}
          loading={loading}
          onClose={() => {
            if (onClose) onClose();
          }}
          showSendEmail
          sendEmail={sendEmail}
          setSendEmail={setSendEmail}
          sendEmailText={`Send Split Confirmation`}
        />
      }
    >
      <SplitAndCreateBooking
        form={form}
        onlyUpdateRouting={true}
        {...props}
        setLoading={setLoading}
      />
    </Drawer>
  );
}
