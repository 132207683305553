import React from 'react';

import './style.css';
import { useMutation } from '@apollo/client';
import { Button, Drawer, Form, message, Space } from '@shipmnts/pixel-hub';
import { isSalesPersonPresentInTeam, useSession } from 'common';
import { CustomIcon } from '@shipmnts/pixel-hub';

import { CREATE_INQUIRY } from 'sales_hub/graphql/inquiry';

import { createInquiryParamsFromForm } from './common';
import InquiryForm from './InquiryForm';

type Props = {
  onSuccess?: () => void;
  data?: any;
  visible: boolean;
  setVisible: (isVisible: boolean) => void;
};

const CreateInquiryDrawer = (props: Props) => {
  const { onSuccess } = props;
  // Context
  const [form] = Form.useForm();

  // Queries/Mutations
  const [createInquiry, { loading }] = useMutation(CREATE_INQUIRY);

  // Constants and Methods
  const onClose = () => {
    if (props.setVisible) {
      form.resetFields();
      props.setVisible(false);
    }

    !!onSuccess && onSuccess();
  };

  const onFinish = (values: any, callback: (booking_order_id: string) => Promise<void>) => {
    const inquiry = createInquiryParamsFromForm(values);
    if (!isSalesPersonPresentInTeam(inquiry.teams)) {
      message.error('Please enter sales person details in team');
      return;
    }
    createInquiry({
      variables: {
        inquiry: inquiry,
      },
    }).then((res) => {
      if (res?.data && !res?.errors) {
        message.success({
          content: (
            <>
              {`Created Inquiry ${res.data?.create_inquiry?.inquiry_number} Successfully, `}
              <span style={{ color: 'blue', cursor: 'pointer' }}>
                <u>Click here to view</u>
              </span>
            </>
          ),
          duration: 5,
          onClick: () =>
            window.open(`/view/rfq/${res.data?.create_inquiry?.inquiry_global_id}`, '_blank'),
        });

        callback(res.data?.create_inquiry?.id).then((res) => {
          form.resetFields();
          props.setVisible(false);
          if (props.onSuccess) props.onSuccess();
        });
      }
    });
  };

  const session = useSession();
  const businessVertical = session.company_account?.business_verticals?.find((i: any) => {
    if (props?.data?.business_vertical) return i.name === props?.data?.business_vertical;
    // eslint-disable-next-line
    return i.id == props.data?.business_vertical_id;
  });

  return (
    <Drawer
      title={
        <div style={{ display: 'flex' }}>
          <CustomIcon icon="CompassIcon" height={24} width={24} style={{ paddingRight: '5px' }} />
          Log New Inquiry for {businessVertical?.name || 'Company'}
        </div>
      }
      width={1057}
      onClose={onClose}
      open={props.visible}
      styles={{ body: { paddingBottom: 80, paddingTop: 0 } }}
      footer={
        <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
          <Button onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            onClick={form.submit}
            loading={loading}
            disabled={loading}
          >
            Create Inquiry
          </Button>
        </Space>
      }
      destroyOnClose={true}
    >
      <InquiryForm form={form} data={props.data} onFinish={onFinish} />
    </Drawer>
  );
};

export default CreateInquiryDrawer;
