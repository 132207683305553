import React from 'react';
import { PrintPreviewDocType } from '@shipmnts/pixel-hub';
import { useSession } from 'common';
import { ProductOrderValue } from 'operations/models/ProductOrder';

interface ShareOrderProp {
  docTypeId: string;
  docType: string;
  doc: ProductOrderValue;
  onClose?: () => void;
}

export default function ShareOrder(props: ShareOrderProp) {
  const { docType, docTypeId, doc, onClose } = props;
  const sessionData = useSession();

  if (!doc) return <></>;

  return (
    <PrintPreviewDocType
      docType={docType}
      resource_id={docTypeId}
      onClose={onClose}
      emailProps={{
        templateActionName: `share_${doc?.type}_order`,
        fetchDocumentParents: [{ parent_ids: [doc.id], parent_type: 'product_order' }],
        companies_roles_mapping: doc.getAllPartiesRolesMapping(sessionData),
      }}
    />
  );
}
