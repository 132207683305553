import { Col, Form, Input, Row, Select, DatePicker, GlobalSearch } from '@shipmnts/pixel-hub';
import {
  REWARD_SCHEMES,
  ROW_GUTTER,
  SHIPPING_BILL_TYPE,
} from 'operations/modules/shipment/constants';
import React from 'react';
import {
  LOCATION_TYPE_AIRPORT,
  LOCATION_TYPE_CFS,
  LOCATION_TYPE_CITY,
  LOCATION_TYPE_ICD,
  LOCATION_TYPE_PORT_TERMINAL,
  LOCATION_TYPE_RAIL_TERMINAL,
  LOCATION_TYPE_SEAPORT,
} from 'operations/models/Location';
import { FREIGHT_TYPE_AIR } from 'operations/utils/constants';

export default function ShippingBillDetails() {
  return (
    <>
      <Row gutter={ROW_GUTTER}>
        <Col span={8}>
          <Form.Item noStyle hidden name={['shipping_bill_details', 0, 'id']}>
            <Input />
          </Form.Item>
          <Form.Item
            required
            rules={[{ required: true }]}
            name={['shipping_bill_details', 0, 'shipping_bill_type']}
            label="Shipping Bill Type"
          >
            <Select showSearch placeholder="Shipping Bill Type">
              {SHIPPING_BILL_TYPE.map((option, index) => (
                <Select.Option key={(option || '') + index} value={option}>
                  {option}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name={['shipping_bill_details', 0, 'document_number']} label="Shipping Bill #">
            <Input placeholder="Enter Shipping Bill #" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={['shipping_bill_details', 0, 'document_date']}
            label="Shipping Bill Date"
          >
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            className="custom-form-item"
            name={['shipping_bill_details', 0, 'reward_scheme']}
            label="Reward Scheme"
          >
            <Select mode="multiple" showSearch>
              {REWARD_SCHEMES.map((option) => (
                <Select.Option key={option.name} value={option.value}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item noStyle shouldUpdate={true}>
            {({ getFieldValue }) => {
              const freightType = getFieldValue('freight_type');
              return (
                <Form.Item
                  required
                  rules={[{ required: true }]}
                  className="custom-form-item"
                  name={['shipping_bill_details', 0, 'custom_clearance_location']}
                  label="Filing Location"
                >
                  <GlobalSearch
                    doc_type="Global::Location"
                    searchProps={{
                      is_customs_location: true,
                      type:
                        freightType === FREIGHT_TYPE_AIR
                          ? [LOCATION_TYPE_AIRPORT, LOCATION_TYPE_CITY]
                          : [
                              LOCATION_TYPE_SEAPORT,
                              LOCATION_TYPE_CITY,
                              LOCATION_TYPE_CFS,
                              LOCATION_TYPE_ICD,
                              LOCATION_TYPE_RAIL_TERMINAL,
                              LOCATION_TYPE_PORT_TERMINAL,
                            ],
                    }}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}
