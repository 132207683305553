import { VoyageScheduleValue } from 'operations/models/VoyageSchedule';
import { getRoutingDetailsFormValue } from 'operations/modules/booking/helpers/DuplicateBookingHelper';
import { cutoffDateFields, RoutingNodeValue } from 'operations/models/RoutingNode';
import { RoutingNodesHashValue } from 'operations/components/RoutingDetails';
import { RoutingLegValue } from 'operations/models/RoutingLeg';
import { CarrierValue } from 'operations/models/Carrier';
import { cast, getSnapshot } from 'mobx-state-tree';

export const duplicateVoyageScheduleData = (
  voyageScheduleData: VoyageScheduleValue,
  navigate: any
) => {
  const routingDetails = getRoutingDetailsFormValue(voyageScheduleData.routing_legs);
  const routing_nodes: RoutingNodesHashValue = {};
  Object.keys(routingDetails.routing_nodes).forEach((rn_id: string) => {
    const dateValues = cutoffDateFields.reduce((acc: RoutingNodeValue, f: string) => {
      return { ...acc, [f]: undefined };
    }, {} as RoutingNodeValue);
    routing_nodes[rn_id] = { ...routingDetails.routing_nodes[rn_id], ...dateValues };
  });
  const routing_legs = routingDetails.routing_legs.map((rl: RoutingLegValue) => {
    return { ...rl, estimated_time_of_departure: undefined, estimated_time_of_arrival: undefined };
  });
  const formValue = {
    vessel: voyageScheduleData.vessel,
    carriers: getSnapshot<CarrierValue[]>(cast(voyageScheduleData.carriers)).slice(),
    routing_details: { routing_legs, routing_nodes },
  };
  navigate(`~/form/voyage_schedule/new?`, {
    state: {
      voyage_schedule: formValue,
    },
  });
};
