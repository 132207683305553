/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree';

import Commodity, { CommodityType } from './Commodity';
import Package from './Package';
import ShipmentDocument from './ShipmentDocument';

export const CargoProperty = types.model({
  is_perishable: types.maybe(types.maybeNull(types.boolean)),
  is_temp_controlled: types.maybe(types.maybeNull(types.boolean)),
  is_hazardous: types.maybe(types.maybeNull(types.boolean)),
  is_battery: types.maybe(types.maybeNull(types.boolean)),
  un_number: types.maybe(types.maybeNull(types.string)),
  un_imo_class: types.maybe(types.maybeNull(types.string)),
  proper_shipping_name: types.maybe(types.maybeNull(types.string)),
  technical_name: types.maybe(types.maybeNull(types.string)),
  packaging_group: types.maybe(types.maybeNull(types.string)),
  net_weight: types.maybe(types.maybeNull(types.number)),
  net_weight_unit: types.maybe(types.maybeNull(types.string)),
  flash_point: types.maybe(types.maybeNull(types.number)),
  flash_point_unit: types.maybe(types.maybeNull(types.string)),
  ems_number: types.maybe(types.maybeNull(types.string)),
  emergency_contact_name: types.maybe(types.maybeNull(types.string)),
  emergency_contact_number: types.maybe(types.maybeNull(types.string)),
  aircraft_type: types.maybe(types.maybeNull(types.string)),
  is_radioactive: types.maybe(types.maybeNull(types.boolean)),
  temperature_unit: types.maybe(types.maybeNull(types.string)),
  temperature: types.maybe(types.maybeNull(types.number)),
  min_temperature: types.maybe(types.maybeNull(types.number)),
  max_temperature: types.maybe(types.maybeNull(types.number)),
  temperature_range: types.maybe(types.maybeNull(types.string)),
});

export const Trip = types.model({
  id: types.identifier,
  job_number: types.maybe(types.maybeNull(types.string)),
  shipment_documents: types.maybe(types.array(ShipmentDocument)),
});

const Cargo = types.model({
  id: types.maybe(types.identifier),
  _id: types.maybe(types.string),
  commodity: types.maybe(types.maybeNull(Commodity)),
  commodity_description: types.maybe(types.maybeNull(types.string)),
  // hs_code: types.maybe(types.maybeNull(types.string)),
  package_type: types.maybe(types.maybeNull(types.string)),
  allocation_pending_quantity: types.maybe(types.maybeNull(types.number)),
  total_packages: types.maybe(types.maybeNull(types.integer)),
  gross_weight: types.maybe(types.maybeNull(types.number)),
  weight_unit: types.maybe(types.maybeNull(types.string)),
  gross_volume: types.maybe(types.maybeNull(types.number)),
  volume_unit: types.maybe(types.maybeNull(types.string)),
  cargo_properties: types.maybe(types.maybeNull(CargoProperty)),
  shipment_packages: types.array(Package),
  net_weight: types.maybe(types.maybeNull(types.number)),
  net_weight_unit: types.maybe(types.maybeNull(types.string)),
  invoice_number: types.maybe(types.maybeNull(types.string)),
  invoice_date: types.maybe(types.maybeNull(types.number)),
  batch_number: types.maybe(types.maybeNull(types.string)),
  custom_ref: types.maybe(types.maybeNull(types.string)),
  serial_number: types.maybe(types.maybeNull(types.string)),
  eway_bill_no: types.maybe(types.maybeNull(types.string)),
  eway_bill_validity: types.maybe(types.maybeNull(types.number)),
});

export interface CargoValue extends Instance<typeof Cargo> {}
export interface CargoIn extends SnapshotIn<typeof Cargo> {}
export interface CargoOut extends SnapshotOut<typeof Cargo> {}

export interface CargoPropertyValue extends Instance<typeof CargoProperty> {}
export interface CargoPropertyIn extends SnapshotIn<typeof CargoProperty> {}
export interface CargoPropertyOut extends SnapshotOut<typeof CargoProperty> {}

export default Cargo;

export const isLiveReeferCargo = (cargos: CargoValue[]): boolean => {
  const cargoTypes: string[] = [];
  const commodityTypes: Array<CommodityType> = [
    'dry_cargo',
    'live_reefer_cargo',
    'dangerous_cargo',
    'oog_cargo',
  ];
  cargos.forEach((cargo) => {
    commodityTypes.forEach((commodityType: CommodityType) => {
      if (cargo?.commodity?.[commodityType] && !cargoTypes.includes(commodityType)) {
        cargoTypes.push(commodityType);
      }
    });
  });

  if (cargoTypes.length === 1 && cargoTypes[0] === 'live_reefer_cargo') return true;
  return false;
};

export const getCargoPackagesString = (cargos: CargoValue[]): string => {
  const packages_hash: { [key: string]: number } = {};
  cargos.forEach((cargo) => {
    if (cargo.total_packages && cargo.package_type) {
      if (!packages_hash[cargo.package_type])
        packages_hash[cargo.package_type] = cargo.total_packages;
      else packages_hash[cargo.package_type] += cargo.total_packages;
    }
  });
  return Object.keys(packages_hash)
    .map((pkg_type) => {
      return `${packages_hash[pkg_type]} ${pkg_type}`;
    })
    .join(', ');
};
