import React from 'react';

import { Card } from 'common';
import { isCustomer, isVendor, useSession } from 'common';
import { useCompanyView } from '../CompanyView';
import {
  WithPermission,
  ConditionalPermissions,
  hasPermission,
  UploadedDocuments,
} from '@shipmnts/pixel-hub';
import {
  PERMISSION_CUSTOMER_CREATE_EDIT,
  PERMISSION_CUSTOMER_VIEW,
  PERMISSION_SUPPLIER_CREATE_EDIT,
  PERMISSION_SUPPLIER_VIEW,
} from 'network/permissions';
import { RegulatoryDetailsCard } from '../Cards/RegulatoryDetailsCard';

export const DocumentsTab = () => {
  const { company, loading } = useCompanyView();
  const session = useSession();
  let viewPermission: ConditionalPermissions | undefined = undefined;
  let editPermission = true;

  // figure out permissions based on company type
  if (isCustomer(company?.company_type) && isVendor(company?.company_type)) {
    viewPermission = {
      OR: [
        { name: PERMISSION_CUSTOMER_VIEW, docType: 'Network::Company' },
        { name: PERMISSION_SUPPLIER_VIEW, docType: 'Network::Company' },
      ],
    };
    editPermission = hasPermission(session.permissions, {
      OR: [
        { name: PERMISSION_CUSTOMER_CREATE_EDIT, docType: 'Network::Company' },
        { name: PERMISSION_SUPPLIER_CREATE_EDIT, docType: 'Network::Company' },
      ],
    });
  } else if (isCustomer(company?.company_type)) {
    editPermission = hasPermission(session.permissions, {
      name: PERMISSION_CUSTOMER_CREATE_EDIT,
      docType: 'Network::Company',
    });
  } else if (isVendor(company?.company_type)) {
    editPermission = hasPermission(session.permissions, {
      name: PERMISSION_SUPPLIER_CREATE_EDIT,
      docType: 'Network::Company',
    });
  }

  return (
    <WithPermission permission={viewPermission}>
      <div
        style={{
          width: '70%',
          marginInline: 'auto',
          marginBlock: '1em 2em',
        }}
      >
        <RegulatoryDetailsCard />
        <Card
          loading={loading}
          header={{ title: 'Documents' }}
          style={{ marginInline: 'auto', width: '100%', marginTop: '1em' }}
          Action={<></>}
        >
          <div style={{ marginTop: '1.5em' }}>
            {company ? (
              <UploadedDocuments
                sessionData={session}
                parent_id={company.id}
                parent_type={'company'}
                docgen_url={process.env.DOCGEN_URL || ''}
                uploadDisabled={!editPermission}
                deleteDisabled={!editPermission}
              />
            ) : (
              <></>
            )}
          </div>
        </Card>
      </div>
    </WithPermission>
  );
};
