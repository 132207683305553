import React from 'react';
import { RowNode } from '@ag-grid-community/core';
import { MergeCellsOutlined } from '@shipmnts/pixel-hub';
import { PRIMARY_TYPE } from 'operations/modules/actionHelper/constants';
import { Action } from 'operations/modules/actionHelper/models';
import { mergeBookingRenderer } from './otoBulkActionRenderer';

export const mergeBooking = (selectedNodes: RowNode[]): Action => {
  return {
    key: 'merge_booking',
    type: PRIMARY_TYPE,
    displayComponent: 'Merge Booking',
    icon: <MergeCellsOutlined />,
    description: 'Merge Booking',
    performAction: mergeBookingRenderer,
    isEnable: true,
  };
};

export const getOTOBulkActions = (selectedNodes: RowNode[]) => {
  if (selectedNodes.length === 0) return [];
  return [mergeBooking(selectedNodes)];
};
