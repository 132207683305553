import { types, Instance, SnapshotIn, SnapshotOut, IAnyModelType } from 'mobx-state-tree';

import ProductOrder from './ProductOrder';
import Shipment from './Shipment';

const ProductOrderItem = types.model({
  id: types.identifier,
  product_order: types.maybe(types.maybeNull(types.late((): IAnyModelType => ProductOrder))),
  shipment: types.maybe(types.maybeNull(types.late((): IAnyModelType => Shipment))),
  parent_id: types.maybe(types.maybeNull(types.string)),
  parent_type: types.maybe(types.maybeNull(types.string)),
  seller_product_code: types.maybe(types.maybeNull(types.string)),
  buyer_product_code: types.maybe(types.maybeNull(types.string)),
  product_name: types.maybe(types.maybeNull(types.string)),
  country_of_manufacture: types.maybe(types.maybeNull(types.string)),

  hs_code: types.maybe(types.maybeNull(types.string)),
  sales_order_number: types.maybe(types.maybeNull(types.string)),
  purchase_order_number: types.maybe(types.maybeNull(types.string)),

  batch_no: types.maybe(types.maybeNull(types.string)),
  inner_package_type: types.maybe(types.maybeNull(types.string)),
  packing_remarks: types.maybe(types.maybeNull(types.string)),
  inner_per_packet_wt: types.maybe(types.maybeNull(types.number)),
  inner_package_qty: types.maybe(types.maybeNull(types.number)),

  outer_package_type: types.maybe(types.maybeNull(types.string)),
  outer_packing_remarks: types.maybe(types.maybeNull(types.string)),
  outer_per_packet_wt: types.maybe(types.maybeNull(types.number)),
  outer_package_qty: types.maybe(types.maybeNull(types.number)),

  uom: types.maybe(types.maybeNull(types.string)),

  order_qty: types.maybe(types.maybeNull(types.number)),
  prioritized_qty: types.maybe(types.maybeNull(types.number)),
  ready_qty: types.maybe(types.maybeNull(types.number)),
  shipment_planned_qty: types.maybe(types.maybeNull(types.number)),
  shipped_qty: types.maybe(types.maybeNull(types.number)),
  invoice_qty: types.maybe(types.maybeNull(types.number)),
  delivered_qty: types.maybe(types.maybeNull(types.number)),
  dispatched_qty: types.maybe(types.maybeNull(types.number)),

  amount: types.maybe(types.maybeNull(types.number)),
  unit_rate: types.maybe(types.maybeNull(types.number)),

  linked_product_order_items: types.maybe(
    types.maybeNull(types.array(types.late((): IAnyModelType => ProductOrderItem)))
  ),
  shipment_invoice_id: types.maybe(types.maybeNull(types.string)),
  tax_percentage: types.maybe(types.maybeNull(types.number)),
  marks_and_numbers: types.maybe(types.maybeNull(types.string)),
  linked_to_id: types.maybe(types.maybeNull(types.string)),
  linked_to: types.maybe(types.maybeNull(types.late((): IAnyModelType => ProductOrderItem))),
  base_amount: types.maybe(types.maybeNull(types.number)),
  gross_weight: types.maybe(types.maybeNull(types.number)),
  net_weight: types.maybe(types.maybeNull(types.number)),
  product_description: types.maybe(types.maybeNull(types.string)),
  additional_description: types.maybe(types.maybeNull(types.string)),
  _destroy: types.maybe(types.maybeNull(types.boolean)),

  cargo_ready_date: types.maybe(types.maybeNull(types.number)),
  target_shipping_date: types.maybe(types.maybeNull(types.number)),
  target_delivery_date: types.maybe(types.maybeNull(types.number)),

  currency: types.maybe(types.maybeNull(types.string)),
  exchange_rate: types.maybe(types.maybeNull(types.number)),
  custom_field_values: types.maybe(types.maybeNull(types.string)),
});

export type ProductOrderItemValue = Instance<typeof ProductOrderItem>;
export type ProductOrderItemIn = SnapshotIn<typeof ProductOrderItem>;
export type ProductOrderItemOut = SnapshotOut<typeof ProductOrderItem>;

export default ProductOrderItem;
