/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree';

const DocField = types.model({
  id: types.identifier,
  field_name: types.string,
  field_type: types.string,
  label: types.string,
  description: types.maybe(types.maybeNull(types.string)),
  default: types.maybe(types.maybeNull(types.string)),
  db_column_name: types.string,
  filter_options: types.maybe(types.maybeNull(types.string)),
  cell_renderer: types.maybe(types.maybeNull(types.string)),
  cell_editor: types.maybe(types.maybeNull(types.string)),
  section: types.maybe(types.maybeNull(types.string)),
  doc_type_id: types.maybe(types.maybeNull(types.string)),
  type: types.string,
});

export interface DocFieldValue extends Instance<typeof DocField> {}
export interface DocFieldIn extends SnapshotIn<typeof DocField> {}
export interface DocFieldOut extends SnapshotOut<typeof DocField> {}

export default DocField;
