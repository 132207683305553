import { useMutation } from '@apollo/client';
import { message, Modal } from '@shipmnts/pixel-hub';
import { DELETE_SHIPMENT } from 'operations/graphql/shipment';
import { ShipmentValue } from 'operations/models/Shipment';
import React, { useEffect } from 'react';

interface DeleteHouseShipmentProps {
  shipment: ShipmentValue;
  onClose?: () => void;
  onSuccess?: () => void;
}
export default function DeleteHouseShipment(props: DeleteHouseShipmentProps) {
  const { shipment, onClose, onSuccess } = props;
  const [modal, contextHolder] = Modal.useModal();

  const [deleteHouseShipment, { data, error }] = useMutation(DELETE_SHIPMENT);

  useEffect(() => {
    if (error) {
      message.error(error.message);
      return;
    }
    if (data?.delete_shipment?.message) {
      message.success('Deleted successfully!');
      if (onSuccess) onSuccess();
      if (onClose) onClose();
    }
  }, [data, error, onSuccess, onClose]);

  const config = {
    content: `Are you sure you want to delete the house shipment ${shipment.job_number}?`,
    onOk: () => {
      deleteHouseShipment({ variables: { id: shipment.id } });
    },
    okText: 'Yes',
    cancelText: 'No',
    onCancel: () => {
      if (onClose) onClose();
    },
  };

  useEffect(() => {
    Modal.confirm(config);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  return <>{contextHolder}</>;
}
