import { axiosInstance as axios } from 'common';

interface GeneratePdfPayload {
  html: string;
  file_name: string;
  timezone?: string;
}

export function downloadBlob(blob: Blob, fileName: string) {
  const blobURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = blobURL;
  tempLink.setAttribute('download', fileName);

  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }

  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
  window.URL.revokeObjectURL(blobURL);
  return blobURL;
}

export const generatePdfDocument = async (payload: GeneratePdfPayload) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.DOCGEN_URL}/generate_pdf`,
      timeout: 30000,
      data: payload,
    });

    return { document: response?.data?.document, error: false };
  } catch (error) {
    console.error(error);
    return { error };
  }
};

export async function downloadPDF(payload: GeneratePdfPayload) {
  try {
    const { document, error } = await generatePdfDocument(payload);

    if (document) {
      const fileUrl = document?.uploaded_document;
      const blob = await fetch(fileUrl).then((r) => r.blob());
      const blobURL = downloadBlob(blob, payload.file_name);
      return { document, error, file_url: blobURL };
    } else {
      return { error };
    }
  } catch (error) {
    console.error(error);
    return { error };
  }
}
