import React from 'react';
import { Checkbox } from '@shipmnts/pixel-hub';

interface PhOpenSelectOptionProps {
  value: any;
  selected?: boolean;
  disabled?: boolean;
  onSelect?: (val: any) => void;
  onDeselect?: (val: any) => void;
  children: React.JSX.Element;
}

function PhOpenSelectOption(props: PhOpenSelectOptionProps) {
  const { value, selected, onSelect, onDeselect, children, disabled } = props;

  const onChange = (checked: boolean) => {
    if (checked && onSelect) onSelect(value);
    if (!checked && onDeselect) onDeselect(value);
  };

  return (
    <div key={value} className="ant-select-item ant-select-item-option">
      <div className="ant-select-item-option-content">
        <Checkbox
          style={{ width: '100%' }}
          checked={selected}
          disabled={disabled}
          onChange={(e) => onChange(e.target.checked)}
        >
          {children}
        </Checkbox>
      </div>
    </div>
  );
}

export default PhOpenSelectOption;
