import React from 'react';

import { useErpNextConfig } from 'network/utils/ErpNextConfigDataWrapper';

import { useCompanyView } from '../CompanyView';

import { CustomerLedgerCard } from '../Cards/CustomerLedgerCard';
import { SupplierLedgerCard } from '../Cards/SupplierLedgerCard';
import { CreditControlCard } from '../Cards/CreditControlCard';
import { BankAccountsCard } from '../Cards/BankAccountsCard';
import { ExemptionCard } from '../Cards/ExemptionCard';

export const FinanceTab = () => {
  const { loading, accounts_loading, is_customer, is_vendor } = useCompanyView();
  const { erpnextConfigData, fetching } = useErpNextConfig();
  // const { erpnextConfigData, fetchConfigData, fetching, fetchError } = useErpNextConfig();
  return (
    <>
      <div
        style={{
          width: '95%',
          marginInline: 'auto',
          marginTop: '1em',
          display: 'flex',
          gap: '1em',
        }}
      >
        {erpnextConfigData && (
          <CustomerLedgerCard
            erpnextConfigData={erpnextConfigData}
            loading={loading || fetching || accounts_loading}
          />
        )}
        {is_customer && erpnextConfigData && (
          <CreditControlCard
            erpnextConfigData={erpnextConfigData}
            loading={loading || fetching || accounts_loading}
          />
        )}
        {erpnextConfigData && (
          <SupplierLedgerCard
            erpnextConfigData={erpnextConfigData}
            loading={loading || fetching || accounts_loading}
          />
        )}
      </div>
      <div
        style={{
          width: '95%',
          marginInline: 'auto',
          marginBlock: '1em 2em',
        }}
      >
        {is_customer && (
          <ExemptionCard type="receivable" loading={loading || fetching || accounts_loading} />
        )}
        {is_vendor && (
          <ExemptionCard type="payable" loading={loading || fetching || accounts_loading} />
        )}
        {erpnextConfigData && (
          <BankAccountsCard loading={loading || fetching || accounts_loading} />
        )}
      </div>
    </>
  );
};
