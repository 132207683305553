import React from 'react';
import { collapsibleCard } from '../../../shipment/components/DetailLayout/Common/common';
import { CustomIcon } from '@shipmnts/pixel-hub';
import { ProductOrderValue } from 'operations/models/ProductOrder';

interface ProductOrderPartiesProps {
  product_order: ProductOrderValue;
}

const ProductOrderParties = (props: ProductOrderPartiesProps) => {
  const { product_order } = props;

  const partyDetails = [];
  if (product_order.type === 'sales') {
    partyDetails.push({
      Buyer: <b>{product_order.buyer?.registered_name}</b>,
    });
  } else {
    partyDetails.push({
      Seller: product_order.seller?.registered_name,
    });
  }

  partyDetails.push({
    'Ship From': product_order.ship_from_company?.registered_name,
    'Ship To': product_order.ship_to_company?.registered_name,
  });

  return (
    <>
      {collapsibleCard({
        icon: <CustomIcon icon="Info" />,
        header: 'Party Details',
        data: partyDetails,
      })}
    </>
  );
};

export default ProductOrderParties;
