import { ICellRendererParams } from '@ag-grid-community/core';
import { startCase } from 'lodash';
import React from 'react';

const LastEventDetails = (props: ICellRendererParams) => {
  const { event_data } = props.value;

  if (!event_data) {
    return <></>;
  }

  const eventData = event_data.toJSON();

  const eventNames = Object.keys(eventData);

  return (
    <div style={{ lineHeight: '16px', fontSize: '12px' }}>
      {eventNames.map((e) => {
        return <div key={e}>{`${startCase(e)}: ${eventData[e] ? eventData[e] : '-'}`}</div>;
      })}
    </div>
  );
};

export default LastEventDetails;
