import React from 'react';
import { collapsibleCard } from '../../../shipment/components/DetailLayout/Common/common';
import { CustomIcon, getDateFromUnix } from '@shipmnts/pixel-hub';
import { ProductOrderValue } from 'operations/models/ProductOrder';

interface CreationDetailsProps {
  product_order: ProductOrderValue;
}

const CreationDetails = (props: CreationDetailsProps) => {
  const { product_order } = props;
  const creationDetailsData: { [key: string]: string | null | undefined | React.ReactNode } = {
    'Sales Person': product_order.sales_person
      ? [product_order.sales_person.name].filter(Boolean).join(' ')
      : '',
    'Created by': product_order.created_by
      ? [product_order.created_by.first_name, product_order.created_by.last_name]
          .filter(Boolean)
          .join(' ')
      : '',
    'Created at': product_order.created_at ? getDateFromUnix(product_order.created_at) : ' - ',
  };

  const creationDetails = {
    icon: <CustomIcon icon="Info" />,
    header: 'Creation Details',
    data: creationDetailsData,
  };
  return <div>{collapsibleCard({ ...creationDetails })}</div>;
};

export default CreationDetails;
