import { TERTIARY_TYPE, NEGATIVE_TYPE } from '../constants';
import { Action } from '../models';
import {
  DeleteOutlined,
  PlayCircleOutlined,
  AppstoreAddOutlined,
  CopyOutlined,
} from '@shipmnts/pixel-hub';
import React from 'react';
import { TemplateValue } from 'network/models/Template';
import {
  disableTemplateRenderer,
  enableTemplateRenderer,
  setDefaultTemplateRenderer,
} from './templateActionRenderer';
import { DuplicateTemplateHelper } from 'network';

export const disableTemplate = (template: TemplateValue): Action => {
  return {
    type: NEGATIVE_TYPE,
    key: 'disable',
    displayComponent: 'Disable',
    icon: <DeleteOutlined />,
    isEnable: !template?.is_disabled,
    performAction: disableTemplateRenderer,
  };
};

export const enableTemplate = (template: TemplateValue): Action => {
  return {
    type: TERTIARY_TYPE,
    key: 'enable',
    displayComponent: 'Enable',
    icon: <PlayCircleOutlined />,
    isEnable: template?.is_disabled || false,
    performAction: enableTemplateRenderer,
  };
};

export const setDefaultTemplate = (template: TemplateValue): Action => {
  return {
    type: TERTIARY_TYPE,
    key: 'set_default',
    displayComponent: 'Set Default',
    icon: <AppstoreAddOutlined />,
    isEnable: !template.is_disabled && !template.is_default,
    performAction: setDefaultTemplateRenderer,
  };
};

export const duplicateTemplate = (template: TemplateValue, navigate: any): Action => {
  return {
    type: TERTIARY_TYPE,
    key: 'duplicate_template',
    displayComponent: 'Duplicate Template',
    icon: <CopyOutlined />,
    isEnable: true,
    onClick: () => {
      DuplicateTemplateHelper(template, navigate);
    },
  };
};

export const getTemplateAction = (template: TemplateValue, navigate: any): Action[] => {
  return [
    enableTemplate(template),
    disableTemplate(template),
    setDefaultTemplate(template),
    duplicateTemplate(template, navigate),
  ];
};
