import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Badge, InputNumber, Table, Form } from '@shipmnts/pixel-hub';
import { TableColumnType } from '@shipmnts/pixel-hub';
import { OceanTransportOrderValue } from 'operations/models/OceanTransportOrder';
import { BookingRequestValue } from 'operations/models/BookingRequest';
import { renderContainerSettings } from 'operations/modules/reports/oldCellRenderers';
import { ContainerRequestValue } from 'operations/models/ContainerRequest';
import { areContainerSettingsSame } from 'operations/models/ShipmentContainer';
import { startCase as _startCase, max as _max, min as _min, find as _find } from 'lodash';
import { STATUSES_MAPPING } from 'operations/modules/reports/constants';
import { BOOKING_TYPE_SELF } from 'operations/baseConstants';

const renderContainerAllocation = (
  booking_request: BookingRequestValue,
  record: OceanTransportOrderValue,
  booking_request_cr: ContainerRequestValue
) => {
  const matched_container_request_of_oto = _find(
    record.container_requests,
    (cr: ContainerRequestValue) =>
      cr.container_type === booking_request_cr.container_type &&
      areContainerSettingsSame(cr.container_settings, booking_request_cr.container_settings)
  );

  const oto_containers_linked_with_br = record.parentBRShipmentContainers(booking_request.id);
  let containers_picked_up = 0,
    containers_allocated = 0;
  oto_containers_linked_with_br.forEach((sc) => {
    if (
      sc.container_type === booking_request_cr.container_type &&
      areContainerSettingsSame(sc.container_settings, booking_request_cr.container_settings)
    ) {
      containers_allocated++;
      if (sc.container_number) containers_picked_up++;
    }
  });

  const max_allocation = _min([
    containers_allocated + (booking_request_cr?.quantity_unfulfilled || 0),
    containers_allocated + (matched_container_request_of_oto?.quantity_unfulfilled || 0),
  ]);
  return (
    <Form.Item
      style={{ margin: 0 }}
      name={[record.id, booking_request_cr?.id || 0, matched_container_request_of_oto?.id || 'un']}
    >
      <InputNumber
        disabled={!matched_container_request_of_oto}
        min={_max([containers_picked_up, 1])}
        precision={0}
        max={max_allocation}
      />
    </Form.Item>
  );
};

const AllocatedOceanTransportOrderForm = observer(function AllocatedOceanTransportOrderForm(props: {
  booking_request: BookingRequestValue;
  oceanTransportOrders: OceanTransportOrderValue[];
}): JSX.Element {
  const { booking_request, oceanTransportOrders } = props;

  const getColumns = useCallback(() => {
    let columns: Array<TableColumnType<OceanTransportOrderValue>> = [
      {
        title: 'Shipping Line/Vendor',
        dataIndex: 'vendorName',
      },
    ];

    // Disable Booking Number Edit Field For 'SELF' type
    const is_self_booking = oceanTransportOrders?.some((e) => {
      return e.booking_type === BOOKING_TYPE_SELF;
    });
    if (!is_self_booking)
      columns = [
        {
          title: 'Booking Number',
          dataIndex: 'booking_number',
        },
        ...columns,
      ];

    booking_request.container_requests
      .filter((cr) => cr.quantity_fulfilled !== 0)
      .forEach((booking_request_cr) => {
        if (booking_request_cr.container_type_code) {
          const containerSetting = renderContainerSettings([booking_request_cr]);
          const title = containerSetting
            ? `${booking_request_cr.container_type} (${containerSetting})`
            : booking_request_cr.container_type;
          columns.push({
            title,
            dataIndex: title,
            render: function renderContainerColumn(text: string, record: OceanTransportOrderValue) {
              return renderContainerAllocation(booking_request, record, booking_request_cr);
            },
          });
        }
      });

    columns = columns.concat([
      {
        title: 'Valid till',
        dataIndex: 'valid_till_date',
        render: function render(text: string, record: OceanTransportOrderValue, index: number) {
          return (
            <span>{record?.valid_till_date && record.valid_till_date.format('DD/MM/YYYY')}</span>
          );
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: function render(text: string, record: OceanTransportOrderValue, index: number) {
          return (
            <Badge
              status={record.status ? STATUSES_MAPPING[record.status] : 'default'}
              text={_startCase(record.status)}
            />
          );
        },
      },
    ]);

    return columns;
  }, [booking_request, oceanTransportOrders]);

  return (
    <Table
      dataSource={oceanTransportOrders}
      columns={getColumns()}
      pagination={false}
      rowKey={(record) => record.id || ''}
      size="small"
      style={{ width: '100%' }}
    />
  );
});

export default AllocatedOceanTransportOrderForm;
