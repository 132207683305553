import { useMutation } from '@apollo/client';
import { message, Modal } from '@shipmnts/pixel-hub';
import React, { useEffect, useState } from 'react';
import { DELETE_VIEW_SUBSCRIPTION } from 'operations/graphql/report';
import { erpNextAxiosCall } from 'src/packages/network/utils/erpnextApiHelper';

const deleteFinanceAutoEmail = async (name: string) => {
  try {
    const url = `api/resource/Auto Email Report/${name}`;
    const response = await erpNextAxiosCall({
      action: 'delete',
      url,
    });
    return { response };
  } catch (error) {
    console.error(error);
    return { error };
  }
};

interface DeleteViewSubscriptionProps {
  onClose?: () => void;
  onSuccess?: () => void;
  viewSubscription: any;
}
export default function DeleteViewSubscription(props: DeleteViewSubscriptionProps) {
  const { onClose, viewSubscription, onSuccess } = props;
  const [deleteVS, { data, error }] = useMutation(DELETE_VIEW_SUBSCRIPTION);
  const [financeResponse, setFinanceResponse] = useState({ message: null, error: null });
  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    if (error) {
      message.error(error.message);
      if (onClose) onClose();
      return;
    }
    if (financeResponse?.error) {
      message.error(financeResponse.error);
      if (onClose) onClose();
      return;
    }
    if (data?.delete_view_subscription || financeResponse?.message) {
      message.success('Subscription deleted successfully!');
      if (onSuccess) onSuccess();
      if (onClose) onClose();
    }
  }, [data, error, onSuccess, onClose, financeResponse]);

  const config = {
    title: `Are you sure to Delete this Subscription?`,
    content: 'Once you click on Confirm, It will Delete Subscription',
    okText: 'Confirm Delete',
    cancelText: 'Cancel',
    onCancel: () => {
      if (onClose) onClose();
    },
    onOk: viewSubscription?.is_erpnext_auto_email
      ? async () => {
          const { response }: any = await deleteFinanceAutoEmail(viewSubscription?.name);
          setFinanceResponse((prevState) => ({
            ...prevState,
            ...(response?.response?.data ? response?.response?.data : response),
          }));
        }
      : async () => {
          deleteVS({
            variables: {
              id: viewSubscription?.id,
            },
          });
        },
  };

  useEffect(() => {
    Modal.confirm(config);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  return <>{contextHolder}</>;
}
