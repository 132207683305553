/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotIn, SnapshotOut, IAnyModelType } from 'mobx-state-tree';
import {
  TRADE_TYPE_CROSS_TRADE,
  TRADE_TYPE_DOMESTIC,
  TRADE_TYPE_EXPORT,
  TRADE_TYPE_IMPORT,
} from 'operations/modules/shipment/constants';
import Location from './Location';
import Shipment from './Shipment';

export const SHIPMENT_MANIFEST_LOCATION_TYPE_PORT = 'port';
export const SHIPMENT_MANIFEST_LOCATION_TYPE_ICD = 'icd';
const TrackingStatus = types.model({
  last_tracked_at: types.maybe(types.maybeNull(types.number)),
  enabled: types.maybe(types.maybeNull(types.boolean)),
  status: types.maybe(types.maybeNull(types.string)),
});
const ShipmentManifest = types.model({
  id: types.identifier,
  manifest_number: types.maybe(types.maybeNull(types.string)),
  document_number: types.maybe(types.maybeNull(types.string)),
  document_type: types.maybe(types.maybeNull(types.string)),
  manifest_date: types.maybe(types.maybeNull(types.number)),
  trade_type: types.maybe(
    types.maybeNull(
      types.enumeration([
        TRADE_TYPE_EXPORT,
        TRADE_TYPE_IMPORT,
        TRADE_TYPE_DOMESTIC,
        TRADE_TYPE_CROSS_TRADE,
      ])
    )
  ),
  location: types.maybe(types.maybeNull(Location)),
  estimated_time_of_arrival: types.maybe(types.maybeNull(types.number)),
  location_type: types.maybe(
    types.maybeNull(
      types.enumeration([SHIPMENT_MANIFEST_LOCATION_TYPE_PORT, SHIPMENT_MANIFEST_LOCATION_TYPE_ICD])
    )
  ),
  country: types.maybe(types.maybeNull(types.string)),
  tracking_statuses: types.maybe(
    types.maybeNull(
      types.model({
        indian_customs_igm_tracking: types.maybe(types.maybeNull(TrackingStatus)),
      })
    )
  ),
  shipment: types.maybe(types.maybeNull(types.late((): IAnyModelType => Shipment))),
  line_number: types.maybe(types.maybeNull(types.string)),
  subline_number: types.maybe(types.maybeNull(types.string)),
  inward_date: types.maybe(types.maybeNull(types.number)),
});

export interface ShipmentManifestValue extends Instance<typeof ShipmentManifest> {}
export interface ShipmentManifestIn extends SnapshotIn<typeof ShipmentManifest> {}
export interface ShipmentManifestOut extends SnapshotOut<typeof ShipmentManifest> {}

export default ShipmentManifest;
