import React, { useEffect, useState } from 'react';
import {
  CreateContactPopup,
  FormInstance,
  UserContactEmailSearch,
  emailValidator,
  handleEmailTokenization,
} from '@shipmnts/pixel-hub';
import { Row, Col, Typography, Button, Select, Form } from '@shipmnts/pixel-hub';
import { AddressValue } from 'network/models/Address';
import { MinusOutlined } from '@shipmnts/pixel-hub';
import { EmailTemplate } from 'common/commonTypeDefs';
import { CompanyValue } from 'common/models/Company';
import { useLazyQuery } from '@apollo/client';
import { VALIDATE_EMAIL_CONTACTS } from 'src/graphQL/emailTemplates';
import { unionBy as _uniqBy } from 'lodash';

const { Text } = Typography;

export interface CompanyEmailPreferencesFormValues {
  id?: string;
  email_template_id?: string;
  address_id?: string | null;
  to_emails?: Array<string>;
  cc_emails?: Array<string>;
}
interface CompanyEmailPreferencesFormProps {
  company?: CompanyValue;
  form: FormInstance;
  addresses?: AddressValue[];
  templates?: EmailTemplate[];
}

const ROW_GUTTER = 32;

const CompanyEmailPreferencesForm = React.memo(function CompanyEmailPreferencesForm(
  props: CompanyEmailPreferencesFormProps
): JSX.Element {
  const { addresses, templates, form, company } = props;
  const [newContacts, setNewContacts] = useState<any[]>([]);
  const [showAddContactPopup, setShowAddContactPopup] = useState(false);

  const [validateEmailContacts, { data: validateEmailContactsData }] =
    useLazyQuery(VALIDATE_EMAIL_CONTACTS);

  const validateEmails = (emails: string[]) => {
    if (!emails || emails.length === 0) {
      return;
    }
    validateEmailContacts({
      variables: {
        emails,
        looped_in_parties: [company?.id],
      },
    });
  };

  const handleTokenization_cover = (field_name: string, index: number, email_list: string[]) => {
    const new_values = handleEmailTokenization(email_list);
    const new_field_name = ['company_email_preferences', index, field_name];
    form.setFields([
      {
        name: new_field_name,
        value: new_values,
      },
    ]);

    // HACK-: using setTimeout to stop validation initially(when the values are not yet tokenised and format is not corrected)
    setTimeout(() => {
      form.validateFields([new_field_name]);
    }, 100);
  };

  useEffect(() => {
    if (validateEmailContactsData) {
      const newContactsResponse = validateEmailContactsData?.validate_email_contacts?.error_map
        ?.filter((error: any) => error.error_type === 'does_not_exist')
        .map((error: any) => {
          return {
            email: error.email,
          };
        });
      const finalContacts = [...newContactsResponse, ...newContacts];
      setNewContacts(_uniqBy(finalContacts, 'email'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateEmailContactsData]);

  useEffect(() => {
    if (newContacts.length > 0) {
      setShowAddContactPopup(true);
    }
  }, [newContacts]);

  return (
    <>
      <CreateContactPopup
        isOpen={showAddContactPopup}
        setIsOpen={setShowAddContactPopup}
        contacts={newContacts}
        loopedInParties={company && [company]}
        onSuccess={() => setNewContacts([])}
        onCancel={() => setNewContacts([])}
      />
      <Text type="secondary">
        Address specifies any shipping/billing address of this company for which the preference
        would be applied to. If kept blank then it will apply to all/remaining addresses.
      </Text>
      <Form.List name="company_email_preferences">
        {(fields, { add, remove }) => {
          // https://www.freecodecamp.org/news/css-unit-guide/
          return (
            <>
              <div style={{ maxHeight: '50vh', overflow: 'scroll' }}>
                {fields.map((field, index) => {
                  return (
                    <Row style={{ alignItems: 'center' }} key={index} gutter={ROW_GUTTER}>
                      <Col span={5}>
                        <Form.Item
                          label={index === 0 ? 'Email Template' : ''}
                          key={field.key}
                          name={[field.name, 'email_template_id']}
                        >
                          <Select
                            showSearch
                            filterOption={(input, option): boolean => {
                              return (
                                `${option?.children}`.toLowerCase().indexOf(input.toLowerCase()) >=
                                0
                              );
                            }}
                            popupMatchSelectWidth={false}
                          >
                            {templates?.map((template) => (
                              <Select.Option key={template.id} value={template.id}>
                                {template.template_name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={5}>
                        <Form.Item
                          label={index === 0 ? 'Address' : ''}
                          key={field.key}
                          name={[field.name, 'address_id']}
                        >
                          <Select
                            showSearch
                            filterOption={(input, option): boolean => {
                              return (
                                `${option?.children}`.toLowerCase().indexOf(input.toLowerCase()) >=
                                0
                              );
                            }}
                            popupMatchSelectWidth={false}
                            optionLabelProp="title"
                            allowClear
                          >
                            {addresses?.map((address) => (
                              <Select.Option
                                key={address.id}
                                value={address.id}
                                title={address.name || address.city_name || address.print_address}
                                disabled={address.is_disabled}
                              >
                                {`[${address.name || address.city_name}] - ${
                                  address.print_address
                                }`}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label={index === 0 ? 'To emails' : ''}
                          key={field.key}
                          name={[field.name, 'to_emails']}
                          rules={[
                            {
                              validator: (rule, value) => emailValidator(value),
                              message: 'Email is not valid',
                            },
                          ]}
                        >
                          <UserContactEmailSearch
                            selectMode="tags"
                            placeholder="to@shipmnts.com..."
                            onChange={(value) => {
                              handleTokenization_cover('to_emails', field.name, value || []);
                            }}
                            referenceIds={company && [company?.id]}
                            onBlur={() => {
                              const toEmails = form.getFieldValue('company_email_preferences')?.[
                                index
                              ]['to_emails'];
                              validateEmails(toEmails);
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label={index === 0 ? 'CC emails' : ''}
                          key={field.key}
                          name={[field.name, 'cc_emails']}
                          rules={[
                            {
                              validator: (rule, value) => emailValidator(value),
                              message: 'Email is not valid',
                            },
                          ]}
                        >
                          <UserContactEmailSearch
                            selectMode="tags"
                            placeholder="cc@shipmnts.com..."
                            onChange={(value) => {
                              handleTokenization_cover('cc_emails', field.name, value || []);
                            }}
                            referenceIds={company && [company?.id]}
                            onBlur={() => {
                              const ccEmails = form.getFieldValue('company_email_preferences')?.[
                                index
                              ]['cc_emails'];
                              validateEmails(ccEmails);
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item label={index === 0 ? ' ' : undefined}>
                          <Button
                            danger
                            onClick={() => remove(index)}
                            icon={<MinusOutlined />}
                            size="small"
                            shape="circle"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  );
                })}
              </div>
              <div style={{ paddingTop: '10px' }}>
                <Button size="small" onClick={() => add({})}>
                  Add Row
                </Button>
              </div>
            </>
          );
        }}
      </Form.List>
    </>
  );
});

export default CompanyEmailPreferencesForm;
