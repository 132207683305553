import { message, Modal, Radio, Form } from '@shipmnts/pixel-hub';
import React, { useEffect, useState } from 'react';
import { startCase } from 'lodash';
import { useMutation } from '@apollo/client';
import { ALLOCATE_JOB_NUMBER } from 'operations/graphql/shipment';
import {
  SHIPMENT_TYPE_BACK_TO_BACK,
  SHIPMENT_TYPE_DIRECT,
} from 'operations/modules/shipment/constants';

interface AllocateJobNumberProps {
  booking_id: string;
  setOnSuccess: (value: boolean) => void;
  onClose?: () => void;
  onSuccess?: () => void;
}

export default function AllocateJobNumber(props: AllocateJobNumberProps) {
  const [form] = Form.useForm();
  const { booking_id, setOnSuccess, onClose } = props;
  const [visible, setVisible] = useState<boolean>(true);

  const [
    allocate_job_number,
    {
      data: allocate_job_number_data,
      loading: allocate_job_number_loading,
      error: allocate_job_number_error,
    },
  ] = useMutation(ALLOCATE_JOB_NUMBER);

  useEffect(() => {
    if (allocate_job_number_error) {
      message.error(allocate_job_number_error.message);
      return;
    }
    if (allocate_job_number_data && allocate_job_number_data.allocate_job_number) {
      const { id } = allocate_job_number_data.allocate_job_number;
      message.success(`Sucessfully Created`);
      if (setOnSuccess) {
        setOnSuccess(true);
      } else {
        window.open(`${process.env.SHIPMNTS_WEB_URL}/shipment/${id}/edit`, '_self');
      }
      setVisible(false);
    }
  }, [
    allocate_job_number_data,
    allocate_job_number_loading,
    allocate_job_number_error,
    setOnSuccess,
  ]);

  const onCancel = () => {
    setVisible(false);
    if (onClose) onClose();
  };

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      onOk={form.submit}
      okText="Create Shipment"
      title="Create shipment from booking"
    >
      <Form
        form={form}
        onFinish={(values: any) => {
          allocate_job_number({ variables: { id: booking_id, ...values } });
        }}
        name="create_shipment"
      >
        <Form.Item
          initialValue={SHIPMENT_TYPE_DIRECT}
          required
          name="shipment_type"
          label="Shipment Type"
        >
          <Radio.Group
            options={[
              {
                value: SHIPMENT_TYPE_DIRECT,
                label: startCase(SHIPMENT_TYPE_DIRECT),
              },
              {
                value: SHIPMENT_TYPE_BACK_TO_BACK,
                label: startCase(SHIPMENT_TYPE_BACK_TO_BACK),
              },
            ]}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
