import React from 'react';
import { Collapse, Typography, Select, Row, Col, Button, Form } from '@shipmnts/pixel-hub';
import { ERPNextConfigData } from 'network/utils/ErpNextConfigDataWrapper';
import { MinusOutlined } from '@shipmnts/pixel-hub';

const { Panel } = Collapse;
const { Text } = Typography;
const { Option } = Select;

interface CompanyAccountsTableProps {
  account_type: 'receivable' | 'payable';
  erpnextConfigData: ERPNextConfigData;
}

const ROW_GUTTER = 32;

const CompanyAccountsTable = React.memo(function CompanyAccountsTable(
  props: CompanyAccountsTableProps
): JSX.Element {
  const { account_type, erpnextConfigData } = props;
  let accounts: Array<{ name: string }> = [];
  if (account_type === 'receivable') {
    accounts = erpnextConfigData?.receivable_accounts || [];
  } else {
    accounts = erpnextConfigData?.payable_accounts || [];
  }
  return (
    <Collapse accordion>
      <Panel header="Accounting" key="1">
        <Text type="secondary">Mention if non-standard {account_type} account</Text>
        <Form.List name={`${account_type}_accounts`}>
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, index) => {
                  return (
                    <Row style={{ alignItems: 'center' }} key={index} gutter={ROW_GUTTER}>
                      <Col span={10}>
                        <Form.Item
                          label={index === 0 ? 'Company' : ''}
                          required
                          rules={[{ required: true }]}
                          key={field.key}
                          name={[field.name, 'company']}
                        >
                          <Select allowClear showSearch>
                            {(erpnextConfigData.companies || []).map((c) => (
                              <Option key={c.name} value={c.name}>
                                {c.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={10}>
                        <Form.Item
                          label={index === 0 ? 'Account' : ''}
                          required
                          rules={[{ required: true }]}
                          key={field.key}
                          name={[field.name, 'account']}
                          style={{ width: '95%' }}
                        >
                          <Select allowClear showSearch>
                            {accounts.map((a) => (
                              <Option key={a.name} value={a.name}>
                                {a.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item label={index === 0 ? ' ' : undefined}>
                          <Button
                            danger
                            onClick={() => remove(index)}
                            icon={<MinusOutlined />}
                            size="small"
                            shape="circle"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  );
                })}
                <Button
                  disabled={fields.length === erpnextConfigData.companies?.length}
                  size="small"
                  onClick={() =>
                    add({
                      company:
                        erpnextConfigData.companies &&
                        erpnextConfigData.companies[fields.length]?.name,
                    })
                  }
                >
                  Add Row
                </Button>
              </div>
            );
          }}
        </Form.List>
      </Panel>
    </Collapse>
  );
});

export default CompanyAccountsTable;
