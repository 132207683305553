import React, { useEffect } from 'react';
import { Input, Checkbox, message, Form, DrawerFooter } from '@shipmnts/pixel-hub';
import { useMutation } from '@apollo/client';
import { DISCARD_MESSAGE } from '../graphql/message';
import { errorMessageHandlerGraphQL } from 'common';

const { TextArea } = Input;

const DiscardMessageForm = React.memo(function DiscardMessageForm(props: {
  messageId: string;
  onClose: () => void;
  onSuccess?: () => void;
}) {
  const { messageId, onClose, onSuccess } = props;
  const [form] = Form.useForm();
  const [discardMessage, { data, error, loading }] = useMutation(DISCARD_MESSAGE);
  useEffect(() => {
    if (!error && data?.discard_message) {
      message.success('Message Discarded Successfully!');
      onClose();
      if (onSuccess) onSuccess();
    }
  }, [data, error, onSuccess, onClose]);
  return (
    <Form
      form={form}
      name="discard_message"
      layout="vertical"
      initialValues={{ send_email: true }}
      onFinish={(values) => {
        discardMessage({ variables: { message_id: messageId, ...values } });
      }}
    >
      {error && errorMessageHandlerGraphQL(error)}
      <Form.Item name="remarks" required>
        <TextArea placeholder="Remarks" autoSize={{ minRows: 3, maxRows: 5 }} />
      </Form.Item>
      <Form.Item name="send_email" valuePropName="checked">
        <Checkbox>Notify via email</Checkbox>
      </Form.Item>
      <div
        style={{
          position: 'absolute',
          bottom: '22px',
          width: '98%',
          borderTop: '1px solid #e8e8e8',
          textAlign: 'right',
          background: '#fff',
          padding: '7px',
          margin: '-22px -20px',
        }}
      >
        <DrawerFooter saveText="Discard" onClose={onClose} loading={loading} />
      </div>
    </Form>
  );
});

export default DiscardMessageForm;
