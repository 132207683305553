import React, { useEffect, useState } from 'react';

import { Button, Col, Form, Row } from '@shipmnts/pixel-hub';
import { rateExplorerFiltersMapping } from 'sales_hub/utils/RateExplorerFiltersMapping';
import { FormInstance } from '@shipmnts/pixel-hub';

import { UpOutlined, DownOutlined } from '@shipmnts/pixel-hub';
import { RateExplorerResource } from './RateExplorer.type';

interface RateExplorerFilterProp {
  resource: RateExplorerResource;
  getRateExplorerData: (variables: any) => void;
  form: FormInstance;
  activeFilter: Record<string, any>;
  defaultFilter?: Record<string, any>;
  intialFormValue?: Record<string, any>;
}

interface FilterTypeProp {
  field: string;
  copy?: [string];
}

function RateExplorerFilter(props: RateExplorerFilterProp) {
  const {
    resource,
    getRateExplorerData,
    form,
    activeFilter,
    defaultFilter = {},
    intialFormValue,
  } = props;

  const [showMoreFilter, setShowMoreFilter] = useState(false);
  const is_custom = resource.is_custom;

  const getFilterCondition = (allFields: any, currentFilters: FilterTypeProp[]) => {
    const filters: any = {
      filters: { ...defaultFilter },
    };

    const updatedField = allFields.filter((ele: any) => ele.touched);
    const filterMap: any = {};
    currentFilters.forEach((filter) => (filterMap[filter.field] = filter));
    updatedField.forEach((field: any) => {
      const fieldMetaData = filterMap[field.name?.[0]];
      const getValue = fieldMetaData?.getValue;
      if (getValue) {
        const values = getValue(field.value, resource);
        (Object.keys(values) || []).forEach((value) => {
          filters['filters'][value] = values[value];
        });
      } else filters['filters'][field.name?.[0]] = field.value;
    });
    return filters;
  };

  const getFilterConditionObject = (
    filters: Record<string, any>,
    currentFilters: FilterTypeProp[],
    resource: RateExplorerResource
  ) => {
    const filterMap: any = {};

    const new_filter: any = {
      filters: { ...defaultFilter },
    };
    currentFilters.forEach((filter) => (filterMap[filter.field] = filter));
    (Object.keys(filters) || []).forEach((field_name) => {
      const fieldMetaData = filterMap[field_name];
      const field_value = filters[field_name];
      if (field_value !== undefined) {
        const getValue = fieldMetaData?.getValue;
        if (getValue) {
          const values = getValue(field_value, resource);
          (Object.keys(values) || []).forEach((value) => {
            new_filter['filters'][value] = values[value];
          });
        } else new_filter['filters'][field_name] = field_value;
      }
    });

    return new_filter;
  };

  useEffect(() => {
    if (form && intialFormValue) {
      form.setFieldsValue(intialFormValue);
      const filterPayload = getFilterConditionObject(
        form.getFieldsValue(),
        currentFilters,
        resource
      );

      getRateExplorerData({
        variables: {
          filters: filterPayload.filters,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const filtersMap: Record<string, React.JSX.Element> = rateExplorerFiltersMapping(resource);
  const freightType = is_custom ? 'custom' : resource.freight_type;
  const currentFilters: FilterTypeProp[] = activeFilter?.[freightType || ''] || [];
  const activeFilters = currentFilters.map((filter) => (
    <Col
      key={filter.field}
      span={8}
      xs={{ span: 24 }}
      sm={{ span: 12 }}
      md={{ span: 8 }}
      lg={{ span: 8 }}
    >
      {filtersMap[filter.field]}
    </Col>
  ));

  return (
    <Form
      form={form}
      onFieldsChange={(changeField, allFields) => {
        const filterPayload = getFilterCondition(allFields, currentFilters);
        getRateExplorerData({
          variables: {
            filters: filterPayload.filters,
          },
        });
      }}
    >
      <Row gutter={32}>{activeFilters.slice(0, 6)}</Row>
      <div style={{ display: showMoreFilter ? 'block' : 'none' }}>
        <Row gutter={32}> {activeFilters.slice(6, activeFilters.length)}</Row>
      </div>
      {activeFilters.length > 6 && (
        <div style={{ textAlign: 'center', padding: '3px' }}>
          <Button
            size="small"
            onClick={() => {
              setShowMoreFilter(!showMoreFilter);
            }}
          >
            {showMoreFilter ? (
              <>
                <UpOutlined /> Show Less
              </>
            ) : (
              <>
                <DownOutlined /> More Filters
              </>
            )}
          </Button>
        </div>
      )}
    </Form>
  );
}

export default RateExplorerFilter;
