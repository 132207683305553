import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Drawer, DrawerFooter, Form, Select, message } from '@shipmnts/pixel-hub';
import {
  containerTypes,
  containerSettingsMap,
  containerTypesFlatArray,
} from 'network/baseConstants';
import { BULK_UPDATE_CONTAINER_FIELDS } from 'operations/modules/shipment/components/DetailLayout/Containers/graphql';
import { FETCH_CONTAINERS_FOR_EDIT } from 'operations/modules/booking/graphql/shipmentContainer';
import { getIsContainerDisabled } from 'operations/modules/shipment/components/DetailLayout/Containers/EditContainer/EditContainerForm';

type Props = {
  id: string[];
  onClose?: () => void;
  onSuccess?: () => void;
};
const ChangeContainerTypeForm = (props: Props) => {
  const { onClose, onSuccess, id } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [bulkUpdateContainerFields, { data, error, loading }] = useMutation(
    BULK_UPDATE_CONTAINER_FIELDS
  );
  const [fetchContainers, { data: containersData }] = useLazyQuery(FETCH_CONTAINERS_FOR_EDIT, {
    fetchPolicy: 'no-cache',
  });

  const [form] = Form.useForm();

  useEffect(() => {
    if (id.length > 0) {
      fetchContainers({ variables: { ids: id } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (containersData?.fetch_basic_containers_details_by_ids) {
      for (const container of containersData.fetch_basic_containers_details_by_ids) {
        const is_reefer = containerSettingsMap.reefer[container.container_type_code];
        const is_disabled = getIsContainerDisabled(container);

        if (is_disabled || is_reefer) {
          let messageStr = '';
          if (is_disabled) {
            messageStr =
              'Not allowed to update container type as its linked order/shipment is in canceled/closed or document released stage';
          } else if (is_reefer) {
            messageStr = 'Not allowed to update container type for reefer containers';
          }
          message.error(messageStr);
          setIsOpen(false);
          onClose && onClose();
          return;
        }
      }
      setIsOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containersData]);

  useEffect(() => {
    if (data) {
      message.success('Container type updated successfully');
      onClose && onClose();
      onSuccess && onSuccess();
      setIsOpen(false);
    }
    if (error) {
      message.error('Failed to update container type');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);

  return (
    <Drawer
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
        onClose && onClose();
      }}
      footer={
        <DrawerFooter
          saveText="Update"
          onClose={() => onClose && onClose()}
          onSave={() => form.submit()}
          loading={loading}
        />
      }
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={(values: any) => {
          const containers = props.id.map((id) => {
            return {
              id: id,
              container_type_code: values.container_type_code,
              container_type: containerTypes[values.container_type_code],
            };
          });
          bulkUpdateContainerFields({
            variables: {
              containers: containers,
            },
          });
        }}
      >
        <Form.Item label="Container Type" name={'container_type_code'} required>
          <Select
            showSearch
            optionFilterProp="label"
            placeholder="select container type"
            options={containerTypesFlatArray.filter(
              (type) => !Object.keys(containerSettingsMap.reefer).includes(type.value)
            )}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default ChangeContainerTypeForm;
