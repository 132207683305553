import React, { useState, useCallback } from 'react';
import { Button, Row, Col, Input, InputNumber, Select, Form } from '@shipmnts/pixel-hub';
import {
  WEIGHT_UNIT_KGS,
  oceanPackageTypes,
  WEIGHT_UNIT_MTS,
  DIMENSION_CMS,
  DIMENTION_INCH,
} from 'operations/baseConstants';
import { Checkbox, CheckboxChangeEvent } from '@shipmnts/pixel-hub';
import { FormInstance } from '@shipmnts/pixel-hub';
import { FormListFieldData } from '@shipmnts/pixel-hub';
import { PackageValue } from 'operations/models/Package';
import { round } from 'lodash';
import { Unit } from 'convert-units';
import { DIMENSION_CBM, calculateVolumetricWeight } from 'sales_hub/utils/dimensionHelper';

const { Option } = Select;

// const ROW_GUTTER = 16;

interface PackageDetailsProps {
  form: FormInstance;
  cargoField: FormListFieldData;
  disabled?: boolean;
}

const dimensionConversion: { [key: string]: number } = {
  [DIMENSION_CMS]: 1,
  [DIMENTION_INCH]: 2.54,
};

const PackageDetails = function (props: PackageDetailsProps) {
  const { form, cargoField, disabled = false } = props;
  const weightUnit = Form.useWatch(['cargos', cargoField.name, 'weight_unit'], form);
  const [selectedKeys, setSelectedKeys] = useState<Array<number>>([]);
  const toggleSelectedKey = (e: CheckboxChangeEvent, index: number) => {
    if (e.target.checked) setSelectedKeys([index].concat(selectedKeys));
    else setSelectedKeys(selectedKeys.filter((key) => key !== index));
  };

  const updateCargo = useCallback(
    (fieldsToUpdate: string[]) => {
      const cargo = form.getFieldValue('cargos');
      if (cargo && fieldsToUpdate.includes('package_type')) {
        if (
          cargo[cargoField.name].shipment_packages &&
          cargo[cargoField.name].shipment_packages.length > 0
        )
          cargo[cargoField.name]['package_type'] =
            cargo[cargoField.name].shipment_packages[0].type_of_package;
        else cargo[cargoField.name]['package_type'] = undefined;
      }
      if (fieldsToUpdate.includes('total_packages')) {
        let packages_sum = 0;
        (cargo[cargoField.name].shipment_packages || []).map(
          (x: PackageValue) => (packages_sum += x.total_number_of_packages || 0)
        );
        cargo[cargoField.name]['total_packages'] = packages_sum;
      }
      if (fieldsToUpdate.includes('gross_weight')) {
        let sum = 0;
        (cargo[cargoField.name].shipment_packages || []).map(
          (x: PackageValue) =>
            (sum += (x.total_number_of_packages || 0) * (x.per_piece_weight || 0))
        );
        cargo[cargoField.name]['gross_weight'] = sum;
      }
      if (fieldsToUpdate.includes('gross_volume')) {
        let sum = 0;
        (cargo[cargoField.name].shipment_packages || []).forEach((x: PackageValue) => {
          const dimensionUnit = x.dimension_unit;
          if (dimensionUnit) {
            sum +=
              (x.total_number_of_packages || 0) *
              ((x.length || 0) * dimensionConversion[dimensionUnit]) *
              ((x.width || 0) * dimensionConversion[dimensionUnit]) *
              ((x.height || 0) * dimensionConversion[dimensionUnit]);
          }
        });
        cargo[cargoField.name]['gross_volume'] = sum / 1000000;
      }
      form.setFieldsValue({ cargo });
    },
    [cargoField.name, form]
  );

  const getRoundOffValue = (value: any, decimal = 4) => {
    if (!value) return 0;
    return round(value, decimal);
  };

  const renderSummaryText = (label: string, value: any) => {
    return (
      <Row gutter={8}>
        <Col span={15} className="align-right">
          <b className="margin-right-sm">{label}: </b>
        </Col>
        <Col span={8}>{value}</Col>
      </Row>
    );
  };

  return (
    <Form.List name={[cargoField.name, 'shipment_packages']}>
      {(fields, { add, remove }, { errors }) => {
        return (
          <>
            <div>
              {fields.map((field, index) => {
                return (
                  <Row style={{ alignItems: 'center' }} key={index} wrap={true} gutter={4}>
                    <Col span={1}>
                      <Form.Item label={index === 0 ? ' ' : undefined}>
                        <Checkbox
                          disabled={disabled}
                          checked={selectedKeys.includes(index)}
                          onChange={(e) => toggleSelectedKey(e, index)}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={4} md={10}>
                      <Form.Item
                        label={index === 0 ? 'Package Type' : ''}
                        key={field.key}
                        name={[field.name, 'type_of_package']}
                        required
                        rules={[{ required: true }]}
                      >
                        <Select
                          filterOption={(input, option): boolean => {
                            return (
                              `${option?.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                          showSearch
                          popupMatchSelectWidth={300}
                          style={{
                            width: '100%',
                          }}
                          disabled={disabled}
                          placeholder="Package Type"
                          onChange={() => updateCargo(['package_type'])}
                        >
                          {oceanPackageTypes.map((option: { key: string; name: string }) => (
                            <Option key={option.key} value={option.key}>
                              {option.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col lg={3} md={10}>
                      <Form.Item
                        label={index === 0 ? 'No. of pieces' : ''}
                        key={field.key}
                        name={[field.name, 'total_number_of_packages']}
                        required
                        rules={[{ required: true }]}
                      >
                        <InputNumber
                          min={1}
                          max={9999}
                          precision={0}
                          placeholder="Packages"
                          style={{
                            width: '100%',
                          }}
                          disabled={disabled}
                          onChange={() =>
                            updateCargo(['total_packages', 'gross_weight', 'gross_volume'])
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={2} md={10}>
                      <Form.Item
                        label={index === 0 ? 'Length' : ''}
                        key={field.key}
                        name={[field.name, 'length']}
                        required
                        rules={[{ required: true }]}
                      >
                        <InputNumber
                          min={0.001}
                          max={9999}
                          precision={3}
                          placeholder="Length"
                          style={{
                            width: '100%',
                          }}
                          disabled={disabled}
                          onChange={() => updateCargo(['gross_volume'])}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={2} md={10}>
                      <Form.Item
                        label={index === 0 ? 'Width' : ''}
                        key={field.key}
                        name={[field.name, 'width']}
                        required
                        rules={[{ required: true }]}
                      >
                        <InputNumber
                          min={0.001}
                          max={9999}
                          precision={3}
                          placeholder="Width"
                          style={{
                            width: '100%',
                          }}
                          disabled={disabled}
                          onChange={() => updateCargo(['gross_volume'])}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={2} md={10}>
                      <Form.Item
                        label={index === 0 ? 'Height' : ''}
                        key={field.key}
                        name={[field.name, 'height']}
                        required
                        rules={[{ required: true }]}
                      >
                        <InputNumber
                          min={0.001}
                          max={9999}
                          precision={3}
                          placeholder="Height"
                          style={{
                            width: '100%',
                          }}
                          disabled={disabled}
                          onChange={() => updateCargo(['gross_volume'])}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={3} md={10}>
                      <Form.Item
                        key={`${field.key}_dimension_unit`}
                        name={[field.name, 'dimension_unit']}
                        label={index === 0 ? 'Dimension Unit' : ''}
                        // style={{ width: '40%' }}
                      >
                        <Select disabled={disabled} onChange={() => updateCargo(['gross_volume'])}>
                          <Option key={DIMENSION_CMS} value={DIMENSION_CMS}>
                            {DIMENSION_CMS}
                          </Option>
                          <Option key={DIMENTION_INCH} value={DIMENTION_INCH}>
                            {DIMENTION_INCH}
                          </Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col lg={5} md={10}>
                      <Input.Group compact style={{ width: '100%' }}>
                        <Form.Item
                          label={index === 0 ? 'Per piece Wt.' : ''}
                          key={`${field.key}_per_piece_weight`}
                          name={[field.name, 'per_piece_weight']}
                          style={{ width: '60%' }}
                          required
                          rules={[{ required: true }]}
                        >
                          <InputNumber
                            disabled={disabled}
                            min={0}
                            precision={2}
                            style={{ width: '100%' }}
                            placeholder="Per piece Wt."
                            onChange={() => updateCargo(['gross_weight'])}
                          />
                        </Form.Item>
                        <Form.Item
                          key={`${field.key}_weight_unit`}
                          label={index === 0 ? 'Unit' : ''}
                          style={{ width: '40%' }}
                          initialValue={weightUnit}
                        >
                          <Select
                            disabled={disabled}
                            onChange={(e) => {
                              form.setFieldValue(['cargos', cargoField.name, 'weight_unit'], e);
                            }}
                            value={weightUnit}
                          >
                            <Option key={WEIGHT_UNIT_KGS} value={WEIGHT_UNIT_KGS}>
                              {WEIGHT_UNIT_KGS}
                            </Option>
                            <Option key={WEIGHT_UNIT_MTS} value={WEIGHT_UNIT_MTS}>
                              {WEIGHT_UNIT_MTS}
                            </Option>
                          </Select>
                        </Form.Item>
                      </Input.Group>
                    </Col>
                  </Row>
                );
              })}
              {form.getFieldValue('cargos')[cargoField.name]['total_packages'] > 0 && (
                <Row style={{ backgroundColor: 'none', padding: '0px 12px' }}>
                  <Col span={3} style={{ alignContent: 'center' }}>
                    <h4>Total:</h4>
                  </Col>
                  <Col span={21}>
                    <Row gutter={16} style={{ width: '100%' }}>
                      <Col
                        span={6}
                        className="align-verically padding-left-lg"
                        style={{ alignContent: 'center' }}
                      >
                        {form.getFieldValue('cargos')[cargoField.name]['total_packages'] > 0
                          ? form.getFieldValue('cargos')[cargoField.name]['total_packages']
                          : ''}
                      </Col>
                      <Col span={13} offset={5}>
                        {renderSummaryText(
                          'Gross Volume',
                          `${getRoundOffValue(
                            form.getFieldValue('cargos')[cargoField.name]['gross_volume'],
                            3
                          )} cbm`
                        )}
                        {renderSummaryText(
                          'Volumetric Weight',
                          `${getRoundOffValue(
                            calculateVolumetricWeight({
                              volume: form.getFieldValue('cargos')[cargoField.name]['gross_volume'],
                              volumeUnit: DIMENSION_CBM as Unit,
                              weightUnit: 'kg',
                              factor: 6000,
                            })
                          )} kgs`
                        )}
                        {renderSummaryText(
                          'Gross Weight',
                          `${getRoundOffValue(
                            form.getFieldValue('cargos')[cargoField.name]['gross_weight']
                          )} ${form.getFieldValue('cargos')[cargoField.name]['weight_unit']}`
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </div>
            <Form.ErrorList errors={errors} />
            <Row style={{ margin: '10px' }} gutter={16}>
              <Button
                style={{
                  marginRight: 16,
                }}
                disabled={disabled}
                size="small"
                onClick={() => {
                  add({
                    type_of_package: form.getFieldValue([
                      'cargos',
                      cargoField.name,
                      'package_type',
                    ]),
                  });
                  updateCargo(['package_type', 'total_packages', 'gross_weight', 'gross_volume']);
                }}
              >
                Add dimensions
              </Button>
              <Button
                danger
                size="small"
                style={{
                  marginRight: 16,
                }}
                disabled={selectedKeys.length === 0 || disabled}
                onClick={() => {
                  remove(selectedKeys);
                  setSelectedKeys([]);
                  updateCargo(['package_type', 'total_packages', 'gross_weight', 'gross_volume']);
                }}
              >
                Delete dimensions
              </Button>
            </Row>
          </>
        );
      }}
    </Form.List>
  );
};

export default PackageDetails;
