import { Descriptions, Typography, CustomerCreditStatusTag } from '@shipmnts/pixel-hub';
import React, { Suspense, useState } from 'react';
import { useShipmentDetail } from '../../ShipmentDetailLayout';
import { DownOutlined, RightOutlined } from '@shipmnts/pixel-hub';
import { descriptionsProps, getAllPartyDetails, getPanelHeader } from '../../Common/common';
import { SHIPMENT_TYPE_HOUSE } from '../../../../constants';
import ErrorBoundary from 'operations/utils/ErrorBoundary';
import { CustomIcon } from '@shipmnts/pixel-hub';
const { Paragraph } = Typography;

const ShipmentParties = () => {
  const { shipment } = useShipmentDetail();
  const [addressShow, setAddressShow] = useState<any>({});

  let partyDetails: ({ [key: string]: string | React.ReactNode } | null)[] = shipment
    ? getAllPartyDetails(shipment)
    : [];

  const shipmentType = shipment?.shipment_type;

  if (shipmentType === SHIPMENT_TYPE_HOUSE) {
    partyDetails = partyDetails.filter((val) => val?.role !== 'freight_forwarding_agent');
  }

  const partiesCard = {
    icon: <CustomIcon icon="User" />,
    header: 'Parties Involved',
    data: partyDetails,
  };

  // to keep party address default open
  // useEffect(() => {
  //   const obj: any = {};
  //   partiesCard.data.map((party, index) => {
  //     if (party && typeof party.role === 'string') obj[party.role] = true;
  //     return null;
  //   });
  //   setAddressShow(obj);
  // }, []);
  if (!shipment) return <></>;

  return (
    <Descriptions
      {...descriptionsProps}
      labelStyle={{ ...descriptionsProps.labelStyle, width: '40%' }}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      title={getPanelHeader(partiesCard.icon, partiesCard.header)}
      contentStyle={{ width: '60%' }}
    >
      {partiesCard.data.map((party, index) => {
        if (!party || !party.role) {
          return <></>;
        }
        let role = '';
        if (typeof party.role === 'string') role = party.role;

        return (
          <Descriptions.Item
            key={index}
            style={{ paddingBottom: '3px' }}
            label={party?.role || ''}
            contentStyle={{ display: 'block' }}
          >
            <div style={{ display: 'flex', fontSize: '12px' }}>
              <Paragraph style={{ marginBottom: '0', overflow: 'hidden' }}>
                <Paragraph ellipsis={{ tooltip: true }} style={{ marginBottom: '0' }}>
                  {party.name}
                </Paragraph>
                <ErrorBoundary fallback={<></>}>
                  {party.role === 'Customer' && shipment?.customer_company && (
                    <Suspense fallback={<></>}>
                      <CustomerCreditStatusTag
                        style={{ cursor: 'pointer' }}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        companyInternal={shipment.customer_company}
                      />
                    </Suspense>
                  )}
                </ErrorBoundary>
              </Paragraph>
              {party.address &&
                (addressShow[role] ? (
                  <DownOutlined
                    size={12}
                    style={{ fontSize: '12px', marginTop: '4px', marginLeft: 'auto' }}
                    onClick={() =>
                      setAddressShow({
                        ...addressShow,
                        [role || '']: false,
                      })
                    }
                  />
                ) : (
                  <RightOutlined
                    size={12}
                    style={{ fontSize: '12px', marginTop: '4px', marginLeft: 'auto' }}
                    onClick={() =>
                      setAddressShow({
                        ...addressShow,
                        [role || '']: true,
                      })
                    }
                  />
                ))}
            </div>
            {party.address && addressShow[role] && (
              <Paragraph
                style={{
                  color: '#586988',
                  fontSize: '10px',
                  lineHeight: '14px',
                }}
              >
                {party.address}
              </Paragraph>
            )}
          </Descriptions.Item>
        );
      })}
    </Descriptions>
  );
};

export default ShipmentParties;
