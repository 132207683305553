export const DOCUMENT_TAGS = {
  General: [
    'Proforma invoice',
    'Packing list',
    'Commercial invoice',
    'Custom invoice',
    'Excise invoice',
    'Purchase order',
    'Certificate of origin',
    'Freight certificate',
    'High sea sales invoice',
    'High sea sales purchase order',
    'High sea sales contract',
    'Miscellaneous charges',
    'Authorized dealer code',
    'ARE1',
    'ARE2',
    'ARE3',
    'Statutory declaration form sdf',
    'Form 9',
    'Cargo arrival notice',
    'Credit Note Agent',
    'Debit Note Agent',
    'Demurrage invoice',
    'Per diem invoice',
    'Proof of delivery',
    'POD Label',
    '7501',
    'Broker invoice',
    'Exam invoice',
  ],
  'Customs Compliance License': [
    'Shipping bill checklist',
    'Shipping bill',
    'Checklist',
    'Bill of entry',
    'EPCG',
    'DEPB',
    'DEEC',
  ],
  Carrier: [
    'Shipping instruction',
    'Master airway bill draft',
    'Master airway bill',
    'House airway bill',
    'Booking confirmation',
    'Booking document',
    'Export delivery order',
    'Import delivery order',
    'Master bill of lading',
    'House bill of lading',
    'Shipping line invoice',
    'Revised Shipping line invoice',
  ],
  Product: [
    'Technical writeup',
    'Product catalogue',
    'Mill test certificate',
    'Self declaration undertaking',
    'Material safety data sheet',
    'Inspection report',
    'Phytosanitary cetrificate',
    'Health certificate',
    'Fumigation certificate',
    'Chemical analysis certificate',
  ],
  'Banking And Insurance': [
    'Letter of credit',
    'Insurance policy',
    'Insurance certificate',
    'Letter of indemnity',
  ],
};

export const keyLabelMapping = {
  booking: 'Bookings',
  booking_request: 'Customer Orders',
  booking_order: 'Vendor Bookings',
  containers: 'Containers',
  outbound_containers: 'Outbound Containers',
  shipments: 'Shipments',
  voyage_schedule: 'Voyage Schedules',
};

export const FREIGHT_CHARGE_TAG = 'freight_charge';
export const DUE_CARRIER_TAG = 'due_carrier';
export const DUE_AGENT_TAG = 'due_agent';

export const FIELD_TYPE_BUY = 'buy';
export const FIELD_TYPE_SELL = 'sell';

export const STATUS_STUFFING_ORDER_NOT_YET_CARTED_IN = 'not_yet_carted_in';
export const STATUS_STUFFING_ORDER_CARTED_IN = 'cargo_carted_in';
export const STATUS_STUFFING_ORDER_STUFFING_DONE = 'stuffing_done';

export const DOCUMENT_FIELD_LOCAL = 'local';
export const DOCUMENT_FIELD_DIRECT = 'direct';
export const DOCUMENT_FIELD_COMPUTED = 'compute';
export const DOCUMENT_FIELD_TRANSFORM = 'transform';

export const DOCUMENT_TYPE_NEW_MAWB = 'new_mawb';
export const DOCUMENT_TYPE_NEW_HAWB = 'new_hawb';
export const DOCUMENT_TYPE_HBL = 'new_hbl';
export const DOCUMENT_TYPE_MBL = 'new_mbl';

class ExchangeCacheStore {
  SELL_EXCHANGE_RATE_MAPPING = {};
  BUY_EXCHANGE_RATE_MAPPING = {};
}
export const CacheStoreInstance = new ExchangeCacheStore();

// Tracking Events
export const EVENT_IN_IMPORT_CUSTOMS_RELEASED = 'OOC Done';
export const EVENT_IN_EXPORT_CUSTOMS_RELEASED = 'LEO Done';
export const EVENT_CUSTOMS_RELEASED = 'Customs Released';
export const EVENT_IN_EXPORT_CUSTOM_ENTRY_FILED = 'Shipping Bill Filed';

export const ROUTING_NODE_LABEL_ORIGIN = 'Port Of Loading';
export const ROUTING_NODE_LABEL_DESTINATION = 'Port Of Discharge';
//Eawb
export const FSU_CODES_MAPPING: { [key: string]: string } = {
  BKD: 'Booking Confirmed',
  NFD: 'Consignment arrived at destination and the consignee or agent has been informed',
  DEP: 'Consignment departed on a specific flight',
  RCF: 'Consignment received from a given flight',
  ARR: 'Consignment arrived at POD',
  RCS: 'Consignment received from shipper or agent',
  PRE: 'Consignment Prepared for Loading',
  DLV: 'Consignment delivered to the consignee or agent',
  AWD: 'Consignment arrival documents delivered to the consignee or agent',
  MAN: 'Consignment manifested on a specific flight',
  FOH: 'Freight Receipted in Carrier Warehouse',
  CCD: 'Consignment cleared by Customs',
  TFD: 'Consignment transferred to another airline',
  DIS: 'Consignment with a discrepancy',
  RCT: 'Consignment received from another airline',
  TRM: 'Consignment to be transferred to another airline',
  AWR: 'Arrival Documentation received',
  DDL: 'Door Delivery to Consignee',
  CRC: 'Consignment reported at customs',
  TGC: 'Consignment transferred to government / Customs',
  DOC: 'Documents received by Handling party',
};

export const DOCUMENT_PERMISSION_VIEW = 'view';
