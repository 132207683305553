import React from 'react';
import {
  EditOutlined,
  CopyOutlined,
  DeleteOutlined,
  PlusSquareOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import { PRIMARY_TYPE } from '../constants';
import { Action } from '../models';
import {
  performCompleteAction,
  performDeleteTaskAction,
  performDuplicateTaskAction,
  performEditTaskAction,
  performFollowUpTaskAction,
} from './taskActionRenderer';
import { TaskType } from '@shipmnts/pixel-hub';

const TASK_COMPLETED = 'Completed';
const TASK_PENDING = 'Pending';

export const getTaskActions = (task?: TaskType) => {
  const actions: Action[] = [];

  actions.push({
    key: 'edit_task',
    displayComponent: 'Edit',
    isEnable: !!task && task?.task_status?.toLowerCase() === TASK_PENDING.toLowerCase(),
    performAction: performEditTaskAction,
    type: PRIMARY_TYPE,
    icon: <EditOutlined />,
  });

  actions.push({
    key: 'complete_task',
    displayComponent: 'Complete Task',
    isEnable: !!task && task?.task_status?.toLowerCase() === TASK_PENDING.toLowerCase(),
    performAction: performCompleteAction,
    type: PRIMARY_TYPE,
    icon: <CheckOutlined />,
  });

  actions.push({
    key: 'duplicate_task',
    displayComponent: 'Duplicate',
    isEnable: true,
    performAction: performDuplicateTaskAction,
    type: PRIMARY_TYPE,
    icon: <CopyOutlined />,
  });

  actions.push({
    key: 'follow_up_task',
    displayComponent: 'Create Follow Up Task',
    isEnable: !!task && task?.task_status === TASK_COMPLETED,
    performAction: performFollowUpTaskAction,
    type: PRIMARY_TYPE,
    icon: <PlusSquareOutlined />,
  });

  actions.push({
    key: 'delete_task',
    displayComponent: <span className="color-red">{'Delete'}</span>,
    isEnable: true,
    performAction: performDeleteTaskAction,
    type: PRIMARY_TYPE,
    icon: <DeleteOutlined style={{ color: '#E94F08' }} />,
  });

  return actions;
};
