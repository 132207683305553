import React from 'react';
import { observer } from 'mobx-react-lite';
import { Card, Descriptions } from '@shipmnts/pixel-hub';
import { BookingRequestValue } from 'operations/models/BookingRequest';
import ContainerRequestsView from 'operations/modules/booking/components/BookingRequestView/ContainerRequestsView';
import {
  ROUTING_TYPE_PRE_CARRIAGE,
  ROUTING_TYPE_ON_CARRIAGE,
  ROUTING_TYPE_MAIN_CARRIAGE,
} from 'operations/models/RoutingLeg';
import {
  renderPreferredCarriers,
  renderCommodity,
} from 'operations/modules/reports/oldCellRenderers';
import { LOAD_TYPE_FCL, LOAD_TYPE_LCL } from 'operations/baseConstants';

const BookingRequestSummaryCard = observer(function OTOSummaryCard(props: {
  booking_request: BookingRequestValue;
}): JSX.Element {
  const { booking_request } = props;
  const main_carriage_routing_legs =
    booking_request.carriageWiseRoutingLegs[ROUTING_TYPE_MAIN_CARRIAGE];
  const pre_carriage_routing_legs =
    booking_request.carriageWiseRoutingLegs[ROUTING_TYPE_PRE_CARRIAGE];
  const on_carriage_routing_legs =
    booking_request.carriageWiseRoutingLegs[ROUTING_TYPE_ON_CARRIAGE];

  return (
    <Card style={{ marginBottom: '16px' }} className="no-padding-description custom-card ">
      <Descriptions column={4} colon={false} size="default" layout="vertical">
        <Descriptions.Item
          key="Booking_request_number"
          label="Customer Order #"
          className="description-content"
        >
          {booking_request.shipment_booking_number}
        </Descriptions.Item>
        {booking_request.load_type === LOAD_TYPE_FCL && (
          <Descriptions.Item key="preferred_carriers" label="Preferred Carriers">
            {renderPreferredCarriers(booking_request.preferred_carriers)}
          </Descriptions.Item>
        )}
        <Descriptions.Item key="commodity" label="Commodity">
          {renderCommodity(booking_request.cargos)}
        </Descriptions.Item>
        <Descriptions.Item key="vessel" label="Vessel">
          {main_carriage_routing_legs?.[0].vessel?.name}
        </Descriptions.Item>
        <Descriptions.Item key="voyage" label="Voyage">
          {main_carriage_routing_legs?.[0].voyage_number}
        </Descriptions.Item>
        <Descriptions.Item key="port_of_loading" label="Port Of Loading">
          {main_carriage_routing_legs?.[0].origin?.location?.name}
        </Descriptions.Item>
        <Descriptions.Item key="port_of_discharge" label="Port Of Discharge">
          {
            main_carriage_routing_legs?.[main_carriage_routing_legs.length - 1].destination
              ?.location?.name
          }
        </Descriptions.Item>
        {pre_carriage_routing_legs && (
          <Descriptions.Item key="port_of_carrier_receipt" label="Port Of Carrier Receipt">
            {pre_carriage_routing_legs[0].origin?.location?.name}
          </Descriptions.Item>
        )}
        {on_carriage_routing_legs && (
          <Descriptions.Item key="port_of_carrier_delivery" label="Port Of Carrier Delivery">
            {
              on_carriage_routing_legs[on_carriage_routing_legs.length - 1].destination?.location
                ?.name
            }
          </Descriptions.Item>
        )}
        {booking_request.load_type === LOAD_TYPE_LCL && (
          <>
            <Descriptions.Item key="gross_weight" label="Gross Weight">
              {booking_request.totalGrossWeight} {booking_request.cargos[0].weight_unit}
            </Descriptions.Item>
            <Descriptions.Item key="packages" label="Packages">
              {booking_request.totalPackagesString}
            </Descriptions.Item>
          </>
        )}
      </Descriptions>
      {booking_request.load_type === LOAD_TYPE_FCL && (
        <ContainerRequestsView
          containerRequests={booking_request.container_requests}
          columns={['container_type', 'quantity', 'unallocated_quantity', 'container_settings']}
        />
      )}
    </Card>
  );
});

export default BookingRequestSummaryCard;
