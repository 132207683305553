import React from 'react';
import { SECONDARY_TYPE, TERTIARY_TYPE } from '../constants';
import { PrinterOutlined, BankOutlined } from '@shipmnts/pixel-hub';
import { Action } from '../models';
import { SessionDataValue } from 'operations/models/SessionData';
import { VoucherType, renderShowPaymentsAction } from './AccountActionsRenderer';

export const showPayments = (voucher: VoucherType, user: SessionDataValue): Action => {
  return {
    type: TERTIARY_TYPE,
    key: 'show_payments',
    displayComponent: 'Show Payments',
    isEnable: voucher.status === 'Submit',
    componentProps: {
      voucher,
      user,
    },
    icon: <BankOutlined />,
    performAction: renderShowPaymentsAction,
    description: 'Show Payments',
  };
};

export const printVoucher = (voucher: VoucherType, user: SessionDataValue): Action => {
  const subdomain = user.company_account?.subdomain;
  const printURL = window.encodeURI(
    `${subdomain}/api/method/frappe.utils.print_format.download_pdf?doctype=${voucher.doctype}&name=${voucher.voucher_no}&no_letterhead=0&_lang=en`
  );
  return {
    type: SECONDARY_TYPE,
    key: 'print_voucher',
    displayComponent: 'Print Voucher',
    isEnable: !voucher.is_print_disabled && voucher.status !== 'Cancelled',
    icon: <PrinterOutlined />,
    onClick: () => window.open(printURL),
  };
};

export const getAccountsActions = (record: VoucherType, user: SessionDataValue) => {
  return [printVoucher(record, user), showPayments(record, user)];
};
