/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree';

const Company = types.model({
  id: types.identifier,
  registered_name: types.string,
  company_type: types.maybe(types.string),
  default_currency: types.maybe(types.maybeNull(types.string)),
  company_group: types.maybe(types.maybeNull(types.string)),
  status: types.maybe(types.maybeNull(types.string)),
});
export interface CompanyValue extends Instance<typeof Company> {}
export interface CompanyIn extends SnapshotIn<typeof Company> {}
export interface CompanyOut extends SnapshotOut<typeof Company> {}

export default Company;
