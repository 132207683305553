import React from 'react';
import { Row, Col, Form, DatePicker } from '@shipmnts/pixel-hub';
import { CompanySearch } from 'common';
import { LOCATION_TYPE_ICD, LOCATION_TYPE_CFS, LocationType } from 'operations/models/Location';
import { LOAD_TYPE_LCL } from 'operations/baseConstants';
import { STUFFING_LOCATION_TYPE_CFS, STUFFING_LOCATION_TYPE_ICD } from 'operations/baseConstants';
import { GlobalSearch } from '@shipmnts/pixel-hub';

interface StuffingServiceFormProps {
  field_name?: Array<string | number>;
  load_type?: string;
  buffer_only?: boolean;
  stuffing_location_type?: string;
}

const StuffingServiceForm = React.memo(function StuffingServiceForm(
  props: StuffingServiceFormProps
): JSX.Element {
  const { field_name = [], load_type, buffer_only, stuffing_location_type } = props;
  let location_types: LocationType[] = [LOCATION_TYPE_CFS, LOCATION_TYPE_ICD];
  if (stuffing_location_type === STUFFING_LOCATION_TYPE_CFS) {
    location_types = [LOCATION_TYPE_CFS];
  } else if (stuffing_location_type === STUFFING_LOCATION_TYPE_ICD) {
    location_types = [LOCATION_TYPE_ICD];
  }
  return (
    <Row gutter={16}>
      <Form.Item noStyle name={[...field_name, 'buffer_only']} initialValue={buffer_only}>
        <span />
      </Form.Item>
      <Col span={8}>
        <Form.Item
          required
          rules={[{ required: true }]}
          label="Provider"
          name={[...field_name, 'vendor', 'party_company']}
        >
          <CompanySearch searchProps={{ is_vendor: true }} />
        </Form.Item>
      </Col>
      <Col span={10}>
        <Form.Item
          label="Stuffing/Buffer Location"
          name={[...field_name, 'location_node', 'location']}
        >
          <GlobalSearch doc_type="Global::Location" searchProps={{ type: location_types }} />
        </Form.Item>
      </Col>
      {load_type === LOAD_TYPE_LCL && (
        <Col span={8}>
          <Form.Item
            rules={[{ required: false }]}
            label="Stuffing Cutoff Date"
            name={[...field_name, 'stuffing_cutoff_date']}
          >
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      )}
    </Row>
  );
});

export default StuffingServiceForm;
