import {
  FREIGHT_TYPE_AIR,
  FREIGHT_TYPE_OCEAN,
  DOCUMENT_TYPE_MASTER,
  DOCUMENT_TYPE_HOUSE,
  CONSIGNEE_TYPE_TO_ORDER,
  CONSIGNEE_TYPE_TO_ORDER_BANK,
} from 'operations/modules/shipment/constants';
import { FREIGHT_TYPE_ROAD } from 'operations/utils/constants';

export const LABEL_JSON: { [key: string]: { [key: string]: { [key: string]: string } } } = {
  document_name: {
    [DOCUMENT_TYPE_MASTER]: {
      [FREIGHT_TYPE_AIR]: 'MAWB',
      [FREIGHT_TYPE_OCEAN]: 'MBL',
    },
    [DOCUMENT_TYPE_HOUSE]: {
      [FREIGHT_TYPE_AIR]: 'HAWB',
      [FREIGHT_TYPE_OCEAN]: 'HBL',
      [FREIGHT_TYPE_ROAD]: 'Consignment Note',
    },
  },
  document_date: {
    [DOCUMENT_TYPE_MASTER]: {
      [FREIGHT_TYPE_AIR]: 'MAWB Date',
      [FREIGHT_TYPE_OCEAN]: 'MBL Date',
    },
    [DOCUMENT_TYPE_HOUSE]: {
      [FREIGHT_TYPE_AIR]: 'HAWB Date',
      [FREIGHT_TYPE_OCEAN]: 'HBL Date',
    },
  },
  consignee_party_name: {
    [DOCUMENT_TYPE_MASTER]: {
      [FREIGHT_TYPE_AIR]: 'MAWB Consignee Type',
      [FREIGHT_TYPE_OCEAN]: 'MBL Consignee Type',
    },
    [DOCUMENT_TYPE_HOUSE]: {
      [FREIGHT_TYPE_AIR]: 'HAWB Consignee Type',
      [FREIGHT_TYPE_OCEAN]: 'HBL Consignee Type',
    },
  },
  [CONSIGNEE_TYPE_TO_ORDER.key]: {
    [DOCUMENT_TYPE_MASTER]: { [FREIGHT_TYPE_AIR]: 'To Order', [FREIGHT_TYPE_OCEAN]: 'To Order' },
    [DOCUMENT_TYPE_HOUSE]: { [FREIGHT_TYPE_AIR]: 'To Order', [FREIGHT_TYPE_OCEAN]: 'To Order' },
  },
  [CONSIGNEE_TYPE_TO_ORDER_BANK.key]: {
    [DOCUMENT_TYPE_MASTER]: {
      [FREIGHT_TYPE_AIR]: 'Consignee on MAWB',
      [FREIGHT_TYPE_OCEAN]: 'Consignee on MBL',
    },
    [DOCUMENT_TYPE_HOUSE]: {
      [FREIGHT_TYPE_AIR]: 'Consignee on HAWB',
      [FREIGHT_TYPE_OCEAN]: 'Consignee on HBL',
    },
  },
  consignee_name: {
    [DOCUMENT_TYPE_MASTER]: {
      [FREIGHT_TYPE_AIR]: 'Consignee on MAWB',
      [FREIGHT_TYPE_OCEAN]: 'Consignee on MBL',
    },
    [DOCUMENT_TYPE_HOUSE]: {
      [FREIGHT_TYPE_AIR]: 'Consignee on HAWB',
      [FREIGHT_TYPE_OCEAN]: 'Consignee on HBL',
    },
  },
  shipper_name: {
    [DOCUMENT_TYPE_MASTER]: {
      [FREIGHT_TYPE_AIR]: 'Shipper on MAWB',
      [FREIGHT_TYPE_OCEAN]: 'Shipper on MBL',
    },
    [DOCUMENT_TYPE_HOUSE]: {
      [FREIGHT_TYPE_AIR]: 'Shipper on HAWB',
      [FREIGHT_TYPE_OCEAN]: 'Shipper on HBL',
    },
  },
  shipment_number: {
    [DOCUMENT_TYPE_MASTER]: {
      [FREIGHT_TYPE_AIR]: 'MAWB Number',
      [FREIGHT_TYPE_OCEAN]: 'MBL Number',
    },
    [DOCUMENT_TYPE_HOUSE]: {
      [FREIGHT_TYPE_AIR]: 'HAWB Number',
      [FREIGHT_TYPE_OCEAN]: 'HBL Number',
      [FREIGHT_TYPE_ROAD]: 'CN Number',
    },
  },
  document_status: {
    [DOCUMENT_TYPE_MASTER]: {
      [FREIGHT_TYPE_AIR]: 'MAWB Status',
      [FREIGHT_TYPE_OCEAN]: 'MBL Status',
    },
    [DOCUMENT_TYPE_HOUSE]: {
      [FREIGHT_TYPE_AIR]: 'HAWB Status',
      [FREIGHT_TYPE_OCEAN]: 'HBL Status',
    },
  },
};
