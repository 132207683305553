import React, { Fragment, useEffect, useState } from 'react';
import { Column } from 'operations/models/Report';
import {
  Button,
  Skeleton,
  Space,
  Typography,
  BaseTable,
  LinkRender,
  StringWithToolTip,
  NumberTypeRenderer,
  ActivityRenderer,
  FloatEditor,
} from '@shipmnts/pixel-hub';
import { GridReadyEvent } from '@ag-grid-community/core';
import {
  DocumentRenderer,
  PaymentStatusRenderer,
} from 'operations/modules/shipment/components/DetailLayout/Accounts/components/renderers';
import { useQuery } from '@apollo/client';
import { FETCH_PRODUCT_ORDER_ITEMS_FROM_SHIPMENT } from '../../graphql/productOrder';
import { useShipmentDetail } from 'operations/modules/shipment/components/DetailLayout/ShipmentDetailLayout';
import CommercialInvoiceListTable, {
  getHeight,
} from '../../CommercialInvoice/CommercialInvoiceListTable';
import { TRADE_TYPE_EXPORT } from 'operations/modules/shipment/constants';
import CreateShipmentFromProductOrder from '../../CreateShipmentFromProductOrder';
import { getShipmentItemActions } from '../../OMSActions/OMSActionHelper';
import { ProductOrderItemValue } from 'operations/models/ProductOrderItem';
import { ActionRendererDetailReport } from 'operations';

function ShipmentProductsTab() {
  const { shipment } = useShipmentDetail();
  const [commercialInvoice, setCommercialInvoice] = useState();
  const [isOpenProductDrawer, setIsOpenProductDrawer] = useState(false);
  // Queries
  const {
    data,
    loading,
    error: errorMessage,
    refetch,
  } = useQuery(FETCH_PRODUCT_ORDER_ITEMS_FROM_SHIPMENT, {
    variables: { id: shipment?.id },
  });

  useEffect(() => {
    if (data) {
      setCommercialInvoice(data?.shipment?.commercial_invoices);
    }
  }, [data]);

  const productOrderItem: ProductOrderItemValue[] = data?.shipment.shipment_product_order_items;
  const tableData: any[] = [];
  if (productOrderItem) {
    productOrderItem?.forEach((element: any) => {
      tableData.push({
        id: element?.linked_to?.product_order?.id,
        spoi_id: element?.id,
        unit_rate: element?.unit_rate,
        inner_package_qty: element?.inner_package_qty,
        inner_package_type: element?.inner_package_type,
        inner_per_packet_wt: element?.inner_per_packet_wt,
        product_name: element?.product_name,
        purchase_order_number: element?.linked_to?.product_order?.purchase_order_number,
        sales_order_number: element?.linked_to?.product_order?.sales_order_number,
        shipment_planned_qty: String(element?.shipment_planned_qty || 0) + ' ' + element?.uom,
        shipped_qty: String(element?.shipped_qty || 0) + ' ' + element?.uom,
        invoice_qty: String(element?.invoice_qty || 0) + ' ' + element?.uom,
        total_sell_amount: Number(element?.unit_rate * element?.shipment_planned_qty),
        status_update: element?.linked_to?.product_order?.last_status_update,
        comment: element?.linked_to?.product_order?.last_comment,
        parent_id: element?.parent_id,
        product_order: element?.linked_to?.product_order,
      });
    });
  }

  const columnDefs: { [key: string]: Column } = {
    sales_order_number: {
      headerName:
        productOrderItem && productOrderItem[0]?.linked_to?.product_order?.type === 'sales'
          ? 'Sales Order #'
          : 'Purchase Order #',
      field:
        productOrderItem && productOrderItem[0]?.linked_to?.product_order?.type === 'sales'
          ? 'sales_order_number'
          : 'purchase_order_number',
      colId:
        productOrderItem && productOrderItem[0]?.linked_to?.product_order?.type === 'sales'
          ? 'sales_order_number'
          : 'purchase_order_number',
      // hide: true,
      // rowGroup: true,
      getQuickFilterText: () => '',
      valueGetter: ({ data }) => {
        if (productOrderItem && productOrderItem[0]?.linked_to?.product_order?.type === 'sales') {
          return data.sales_order_number;
        } else {
          return data.purchase_order_number;
        }
      },
      suppressColumnsToolPanel: true,
      cellRenderer: (params: any) => {
        let link, orderNumber;
        tableData.forEach((value) => {
          if (
            productOrderItem &&
            productOrderItem[0]?.linked_to?.product_order?.type === 'sales' &&
            value.sales_order_number === params.value
          ) {
            link = `/view/product_order/${value.id}`;
            orderNumber = value.sales_order_number;
          }
          if (
            productOrderItem &&
            productOrderItem[0]?.linked_to?.product_order?.type === 'purchase' &&
            value.purchase_order_number === params.value
          ) {
            link = `/view/product_order/${value.id}`;
            orderNumber = value.purchase_order_number;
          }
        });
        return (
          <>
            <a href={link} target="_blank" rel="noreferrer">
              {orderNumber}
            </a>
          </>
        );
      },
      maxWidth: 150,
    },
    product_name: {
      headerName: 'Product Name',
      field: 'product_name',
      colId: 'product_name',
      columnType: 'String',
      width: 200,
    },
    shipment_planned_qty: {
      headerName: 'Shipment Planned Qty',
      field: 'shipment_planned_qty',
      colId: 'shipment_planned_qty',
      columnType: 'String',
      // width: 120,
      valueGetter: (params: any) => {
        if (params.node?.isRowPinned()) return '';
        return params?.data?.shipment_planned_qty;
      },
    },
    shipped_qty: {
      headerName: 'Shipped Qty',
      field: 'shipped_qty',
      colId: 'shipped_qty',
      columnType: 'String',
      // width: 120,
      valueGetter: (params: any) => {
        if (params.node?.isRowPinned()) return '';
        return params?.data?.shipped_qty;
      },
    },
    invoice_qty: {
      headerName: 'Invoiced Qty',
      field: 'invoice_qty',
      colId: 'invoice_qty',
      columnType: 'String',
      // width: 120,
    },
    unit_rate: {
      headerName: 'Sell Rate',
      field: 'unit_rate',
      colId: 'unit_rate',
      editable: false,
      cellEditor: 'FloatEditor',
      columnType: 'Float',
      cellEditorParams: {
        min: 0.00001,
        step: 0.00001,
      },
      // width: 180,
    },
    total_sell_amount: {
      headerName: 'Sell Amount',
      field: 'total_sell_amount',
      colId: 'total_sell_amount',
      editable: false,
      cellEditor: 'FloatEditor',
      columnType: 'Float',
      cellEditorParams: {
        min: 0.00001,
        step: 0.00001,
      },
      // width: 180,
    },
    inner_package_qty: {
      headerName: '# Packages',
      field: 'inner_package_qty',
      colId: 'inner_package_qty',
      columnType: 'Float',
      // width: 180,
    },
    inner_package_type: {
      headerName: 'Inner packaging Type',
      field: 'inner_package_type',
      colId: 'inner_package_type',
      columnType: 'String',
      // width: 200,
      cellRenderer: 'string_with_tool_tip',
    },
    inner_per_packet_wt: {
      headerName: 'Inner Packaging Weight',
      field: 'inner_per_packet_wt',
      colId: 'inner_per_packet_wt',
      columnType: 'Float',
      // width: 150,
    },
    comment: {
      headerName: 'Comment',
      field: 'comment',
      colId: 'comment',
      maxWidth: 150,
      pinned: 'right',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      cellRenderer: 'render_activity',
      cellRendererParams: {
        type: 'Comment',
        doc_type_id: 'OrderManagement::ProductOrder',
        showDrawer: true,
      },
    },
    status_update: {
      headerName: 'Status Update',
      field: 'status_update',
      colId: 'status_update',
      maxWidth: 150,
      pinned: 'right',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      cellRenderer: 'render_activity',
      cellRendererParams: {
        type: 'StatusUpdate',
        doc_type_id: 'OrderManagement::ProductOrder',
        showDrawer: true,
      },
      lockVisible: true,
    },
  };

  if (shipment?.status !== 'shipment_cancelled')
    columnDefs['actions'] = {
      headerName: 'Actions',
      suppressMovable: true,
      suppressNavigable: true,
      colId: 'actions',
      field: 'actions',
      valueGetter: 'data',
      cellRenderer: 'ActionRendererDetailReport',
      cellRendererParams: (params: any) => {
        const props = {
          spoi_id: params.data.spoi_id,
          parent_id: params.data.parent_id,
          shipment: shipment,
          product_order: params.data?.product_order,
        };
        return {
          refetchData: refetch,
          doc_type_id: 'OrderManagement::ProductOrder',
          selectedActions: getShipmentItemActions(props),
        };
      },
      // minWidth: 55,
      pinned: 'right',
      headerComponent: 'StaticHeaderComponent',
    };

  const setDefaultFilters = (grid: GridReadyEvent) => {
    const filter = {
      status: {
        type: 'set',
        values: ['Draft', 'Submit'],
      },
    };
    grid.api.setFilterModel(filter);
    grid.api.onFilterChanged();
  };

  const components = {
    accounts_link_render: LinkRender,
    document_status_renderer: DocumentRenderer,
    payment_status_renderer: PaymentStatusRenderer,
    string_with_tool_tip: StringWithToolTip,
    number_type_renderer: NumberTypeRenderer,
    render_activity: ActivityRenderer,
    FloatEditor,
    ActionRendererDetailReport,
  };

  if (loading) {
    return <Skeleton paragraph={{ rows: 4 }} loading={loading} active />;
  }

  return (
    <Fragment>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBlock: '.5em' }}>
        <Typography.Title level={4} style={{ color: '#0a4fca', marginTop: 0 }}>
          Product Order Details
        </Typography.Title>
        {productOrderItem[0]?.linked_to_id && shipment?.status !== 'shipment_cancelled' && (
          <Button
            type="primary"
            style={{ marginRight: '22px' }}
            // href={`/form/commercial_invoice/new?fromShipmentId=${shipment?.id}`}
            onClick={() => setIsOpenProductDrawer(true)}
            target="_blank"
            disabled={shipment?.status === 'shipment_cancelled'}
          >
            <Space>Add Products</Space>
          </Button>
        )}
      </div>
      {isOpenProductDrawer && (
        <CreateShipmentFromProductOrder
          spoi={productOrderItem}
          shipment={shipment}
          productOrder={productOrderItem[0]?.linked_to?.product_order}
          onClose={() => {
            setIsOpenProductDrawer(false);
          }}
          onSuccess={() => {
            setIsOpenProductDrawer(false);
            refetch();
          }}
        ></CreateShipmentFromProductOrder>
      )}
      <div style={{ paddingTop: '0px', paddingBottom: '30px' }}>
        {!errorMessage && (
          <BaseTable
            reportName={'shipment_products_tab'}
            columns={Object.values(columnDefs)}
            rowData={tableData}
            height={getHeight(tableData) || '200px'}
            onGridReady={setDefaultFilters}
            reportConfig={{
              // groupDefaultExpanded: 1,
              components: components,
              // autoGroupColumnDef: {
              //   headerName: 'SO #',
              //   pinned: 'left',
              //   field:
              //     productOrderItem &&
              //     productOrderItem[0]?.linked_to?.product_order?.type === 'sales'
              //       ? 'sales_order_number'
              //       : 'purchase_order_number',
              //   valueGetter: (params: any) => {
              //     if (
              //       productOrderItem &&
              //       productOrderItem[0]?.linked_to?.product_order?.type === 'sales'
              //     ) {
              //       return params.data?.sales_order_number;
              //     } else if (
              //       productOrderItem &&
              //       productOrderItem[0]?.linked_to?.product_order?.type === 'purchase'
              //     ) {
              //       return params.data?.purchase_order_number;
              //     }
              //   },
              // },
            }}
          />
        )}
        {errorMessage && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {errorMessage.message}
          </div>
        )}
      </div>
      {shipment?.trade_type === TRADE_TYPE_EXPORT && (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBlock: '0.5em' }}>
            <Typography.Title level={4} style={{ color: '#0a4fca', marginTop: 0 }}>
              Commercial Invoice Details
            </Typography.Title>
            <Button
              type="primary"
              style={{ marginRight: '22px' }}
              href={`/form/commercial_invoice/new?fromShipmentId=${shipment?.id}`}
              target="_blank"
            >
              <Space>Create Commercial Invoice</Space>
            </Button>
          </div>
          <CommercialInvoiceListTable commercialInvoices={commercialInvoice} />
        </>
      )}
    </Fragment>
  );
}

export default ShipmentProductsTab;
