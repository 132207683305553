import React, { useEffect } from 'react';
import { Modal, message } from '@shipmnts/pixel-hub';
import { useMutation } from '@apollo/client';
import { CREATE_SHIPMENT_FROM_CANCELLED_BOOKING_REQUEST } from 'operations/modules/booking/graphql/bookingRequest';
import { BookingRequestValue } from 'operations/models/BookingRequest';

const CreateShipmentFromCancelledBr = React.memo(function CreateShipmentFromCancelledBr(props: {
  booking_request_id: string;
  onClose: () => void;
  onSuccess?: (booking_request: BookingRequestValue) => void;
}) {
  const { booking_request_id, onClose, onSuccess } = props;
  const [createCancelledShipment, { data, loading, error }] = useMutation(
    CREATE_SHIPMENT_FROM_CANCELLED_BOOKING_REQUEST
  );

  useEffect(() => {
    if (!error && data?.create_shipment_from_cancelled_br) {
      message.success('Cancelled shipment created!');
      onClose();
      if (onSuccess) onSuccess(data?.create_shipment_from_cancelled_br);
    }
    if (error && error.message) {
      message.error(error?.message);
    }
  }, [data, error, onClose, onSuccess]);

  const handleOk = () => {
    createCancelledShipment({ variables: { booking_request_id } });
  };

  const handleCancel = () => {
    onClose && onClose();
  };

  return (
    <Modal
      title="Are you sure?"
      open
      onOk={handleOk}
      onCancel={handleCancel}
      confirmLoading={loading}
    >
      <p>Are you sure you want to create a new shipment from this cancelled Booking Request?</p>
    </Modal>
  );
});

export default CreateShipmentFromCancelledBr;
