import { Col, Form, FormInstance, Input, Row, Select, DatePicker } from '@shipmnts/pixel-hub';
import { useApplicationContentContext } from 'common';
import { get } from 'lodash';
import { convertToDayJs, convertValuesToDayJs } from '@shipmnts/pixel-hub';
import { ShipmentValue } from 'operations/models/Shipment';
import { ShipmentManifestValue } from 'operations/models/ShipmentManifest';
import {
  FREIGHT_TYPE_AIR,
  SHIPMENT_TYPE_BACK_TO_BACK,
  SHIPMENT_TYPE_HOUSE,
} from 'operations/modules/shipment/constants';
import React from 'react';
import { GlobalSearch } from '@shipmnts/pixel-hub';

type Props = {
  form: FormInstance;
  onSubmit: (val: any) => void;
  shipmentManifest?: ShipmentManifestValue;
  shipment?: ShipmentValue;
};

const ManifestForm = ({ shipmentManifest, onSubmit, form, shipment }: Props) => {
  const { config_data } = useApplicationContentContext();
  // Constants and methods
  const isTrackerEnabled =
    shipmentManifest?.tracking_statuses?.indian_customs_igm_tracking?.enabled;
  const manifestTranslation = get(config_data, 'field_country_translations');

  return (
    <div>
      <Form
        onFinish={onSubmit}
        form={form}
        layout="vertical"
        requiredMark={true}
        scrollToFirstError={true}
        initialValues={
          shipmentManifest
            ? {
                ...shipmentManifest,
                ...convertValuesToDayJs(shipmentManifest, [
                  'inward_date',
                  'estimated_time_of_arrival',
                  'manifest_date',
                ]),
              }
            : {
                document_number:
                  (shipment?.shipment_type === SHIPMENT_TYPE_BACK_TO_BACK ||
                    shipment?.shipment_type === SHIPMENT_TYPE_HOUSE) &&
                  shipment?.freight_type !== FREIGHT_TYPE_AIR
                    ? shipment?.getHouseDocumentNumber()
                    : shipment?.getMasterDocumentNumber(),
                document_type:
                  (shipment?.shipment_type === SHIPMENT_TYPE_BACK_TO_BACK ||
                    shipment?.shipment_type === SHIPMENT_TYPE_HOUSE) &&
                  shipment?.freight_type !== FREIGHT_TYPE_AIR
                    ? 'house'
                    : 'master',
                location: shipment?.port_of_discharge,
                estimated_time_of_arrival: convertToDayJs(shipment?.estimated_time_of_arrival),
              }
        }
        onValuesChange={(changedValues, _allValues) => {
          if (changedValues?.document_type) {
            if (changedValues?.document_type === 'house')
              form.setFieldsValue({
                document_number: shipment?.getHouseDocumentNumber(),
              });
            else if (changedValues?.document_type === 'master')
              form.setFieldsValue({
                document_number: shipment?.getMasterDocumentNumber(),
              });
            else
              form.setFieldsValue({
                document_number: null,
              });
          }
        }}
      >
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              name="location"
              label="Location"
              rules={[{ required: true }]} // Required for tracking
              extra="Search for a Port or Inland Container Depots"
            >
              <GlobalSearch
                doc_type="Global::Location"
                searchProps={{ type: ['Seaport', 'Airport', 'ICD'] }}
              />
            </Form.Item>
          </Col>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => {
              return (
                prevValues.location?.country_code !== currentValues.location?.country_code ||
                prevValues.location?.type !== currentValues.location?.type
              );
            }}
          >
            {({ getFieldValue }) => {
              const country = getFieldValue(['location'])?.country_code;
              const locationType = getFieldValue(['location'])?.type || [];
              let type = 'Manifest';
              const locationTypeValue = locationType.includes('port')
                ? 'port'
                : locationType.includes('ICD')
                ? 'icd'
                : undefined;
              if (country)
                if (locationTypeValue === 'icd') {
                  type = manifestTranslation?.icd_import_general_manifest[country] || 'Manifest';
                } else if (locationTypeValue === 'port') {
                  type = manifestTranslation?.import_general_manifest[country] || 'Manifest';
                }

              return (
                <>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      name="manifest_number"
                      label={`${type} Number`}
                      extra={isTrackerEnabled ? 'Will be prefilled from ICEGATE' : null}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  {country === 'IN' && (
                    <>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          name="line_number"
                          label="Line Number"
                          extra={isTrackerEnabled ? 'Will be prefilled from ICEGATE' : null}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      {!shipment?.isAirShipment() && (
                        <Col xs={24} sm={12}>
                          <Form.Item
                            name="subline_number"
                            label="Subline Number"
                            extra={isTrackerEnabled ? 'Will be prefilled from ICEGATE' : null}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      )}
                    </>
                  )}
                </>
              );
            }}
          </Form.Item>

          <Col xs={24} sm={12}>
            <Form.Item
              name="manifest_date"
              label="Manifest Date"
              extra={isTrackerEnabled ? 'Will be prefilled from ICEGATE' : null}
            >
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="inward_date"
              label="Inward Date"
              extra={isTrackerEnabled ? 'Will be prefilled from ICEGATE' : null}
            >
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          {!(shipment?.isAirShipment() && shipment?.isHouseShipment()) && (
            <Col xs={24} sm={12}>
              <Form.Item
                name="document_type"
                label="Document Type"
                tooltip="type of document can be house/master"
              >
                <Select placeholder="Please choose the type">
                  {shipment?.isMasterShipment() && (
                    <Select.Option key={'master'} value="master">
                      Master
                    </Select.Option>
                  )}

                  {!shipment?.isAirShipment() &&
                    (shipment?.isHouseShipment() || shipment?.isBackToBackShipment()) && (
                      <Select.Option key={'house'} value="house">
                        House
                      </Select.Option>
                    )}
                  <Select.Option key={'other'} value="other">
                    Other
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          )}
          <Col xs={24} sm={12}>
            <Form.Item
              name="estimated_time_of_arrival"
              label="ETA"
              rules={[{ required: isTrackerEnabled ? true : false }]} // Required for tracking
              extra={isTrackerEnabled ? 'Required for Automated IGM tracking' : null}
            >
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="document_number"
              label="BL / AWB#"
              rules={[{ required: isTrackerEnabled ? true : false }]} // Required for tracking
              extra={isTrackerEnabled ? 'Required for Automated IGM Tracking' : null}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ManifestForm;
