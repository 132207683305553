import React, { useState } from 'react';

import {
  blTypeMapping,
  DocumentType,
  FreightType,
  FREIGHT_TYPE_OCEAN,
} from 'operations/modules/shipment/constants';
import { descriptionsProps } from './common';
import { Badge, Descriptions, Drawer, Tag } from '@shipmnts/pixel-hub';
import ShipmentDocumentTimeline from '../AttachedHouse/ShipmentDocumentTimeline';
import { ShipmentDocumentValue } from 'operations/models/ShipmentDocument';
import { LABEL_JSON } from 'operations/modules/shipment/helpers/labelKeyHelper';
import { ActionRenderer } from 'operations/modules/actionHelper/ActionRenderer';
import { useShipmentDetail } from '../ShipmentDetailLayout';
import { ShipmentValue } from 'operations/models/Shipment';
import {
  DOCUMENT_STATUS_PENDING_CREATION,
  SHIPMENT_DOCUMENT_TYPE_MASTER,
} from 'operations/modules/reports/constants';
import { CustomIcon } from '@shipmnts/pixel-hub';

type Props = {
  shipment_document: ShipmentDocumentValue;
  shipment: ShipmentValue;
  number?: string | null;
  status: string;
  job_number?: string | null;
  documentType: DocumentType;
  freightType?: FreightType | null;
};

function BlStatusWithTracker({
  shipment_document,
  shipment,
  number,
  status,
  job_number,
  documentType,
  freightType = FREIGHT_TYPE_OCEAN,
}: Props) {
  const [collapsed, setCollapsed] = useState(true);
  const [showNextActions, setshowNextActions] = useState(false);

  const { refetchShipments } = useShipmentDetail();
  const isActionNotVisible =
    !shipment.isMasterShipment() && documentType === SHIPMENT_DOCUMENT_TYPE_MASTER;
  const handleNextShowAction = (val: boolean) => setshowNextActions(val);
  return (
    <div>
      <Descriptions
        {...descriptionsProps}
        labelStyle={{ ...descriptionsProps.labelStyle, width: '25%', alignItems: 'center' }}
        contentStyle={{ ...descriptionsProps.contentStyle, width: '75%' }}
      >
        <Descriptions.Item
          style={{ paddingBottom: '3px' }}
          label={(
            LABEL_JSON.shipment_number[documentType][freightType || FREIGHT_TYPE_OCEAN] || ''
          ).replace('Number', '#')}
        >
          <div>
            <b style={{ marginRight: '5px' }}>{number || '-'}</b>
            <span>
              {shipment_document.bl_type && (
                <Tag color="blue">{blTypeMapping[shipment_document.bl_type]}</Tag>
              )}
            </span>
          </div>
        </Descriptions.Item>

        {/* show mawb / mbl number only when job is created */}

        <Descriptions.Item>
          {(job_number || shipment_document.shipment_number) && status && (
            <div
              className="font-15"
              onMouseEnter={() => handleNextShowAction(true)}
              onMouseLeave={() => handleNextShowAction(false)}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <div className={`status_color_primary_blue`}>
                <Badge color={'blue'} /> {status}
              </div>

              {shipment_document?.document_status !== DOCUMENT_STATUS_PENDING_CREATION && (
                <span style={{ marginLeft: '2px' }}>
                  <CustomIcon icon="TrackerIcon" onClick={() => setCollapsed(!collapsed)} />
                </span>
              )}
              <span
                style={{ marginLeft: '2px', visibility: showNextActions ? 'visible' : 'hidden' }}
              >
                {!isActionNotVisible && (
                  <ActionRenderer
                    id={shipment_document.id}
                    doc_type_id={'Shipment::ShipmentDocument'}
                    refetchData={refetchShipments}
                  />
                )}
              </span>
            </div>
          )}
        </Descriptions.Item>
      </Descriptions>
      {!collapsed && shipment_document?.shipment_id && (
        <Drawer
          className="bl-status-drawer"
          open={true}
          onClose={() => setCollapsed(!collapsed)}
          title={`${
            LABEL_JSON.document_name[documentType][freightType || FREIGHT_TYPE_OCEAN]
          } Timeline`}
        >
          <ShipmentDocumentTimeline
            shipment_id={shipment_document?.shipment_id}
            shipment_document_id={shipment_document?.id}
          />
        </Drawer>
      )}
    </div>
  );
}

export default BlStatusWithTracker;
