/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotIn, SnapshotOut, IAnyModelType } from 'mobx-state-tree';

import Address from './Address';
import EmailTemplate from './EmailTemplate';

const CompanyEmailPreference = types.model({
  id: types.maybe(types.identifier),
  email_template_id: types.maybe(types.string),
  company_id: types.maybe(types.string),
  address_id: types.maybe(types.maybeNull(types.string)),
  to_emails: types.maybe(types.array(types.string)),
  cc_emails: types.maybe(types.array(types.string)),
  address: types.maybe(types.maybeNull(types.late((): IAnyModelType => Address))),
  email_template: types.maybe(types.late((): IAnyModelType => EmailTemplate)),
});

export interface CompanyEmailPreferenceValue extends Instance<typeof CompanyEmailPreference> {}
export interface CompanyEmailPreferenceIn extends SnapshotIn<typeof CompanyEmailPreference> {}
export interface CompanyEmailPreferenceOut extends SnapshotOut<typeof CompanyEmailPreference> {}

export default CompanyEmailPreference;
