import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Descriptions,
  Card,
  Table,
  message,
  Typography,
  Drawer,
  Form,
  Alert,
  DrawerFooter,
} from '@shipmnts/pixel-hub';
import ContainerDetailsForm, { ContainerDetailsRow } from './ContainerDetailsForm';
import { errorMessageHandlerGraphQL } from 'common';
import { OceanTransportOrderValue } from 'operations/models/OceanTransportOrder';
import { RoadTransportOrderValue } from 'operations/models/RoadTransportOrder';
import { useSession } from 'common';
import { useMutation } from '@apollo/client';
import { UPDATE_CONTAINER_PICKUP_DETAILS } from 'operations/modules/booking/graphql/shipmentContainer';
import {
  ShipmentContainerValue,
  validateContainerNumber,
} from 'operations/models/ShipmentContainer';
import { ContainerSettingValue } from 'operations/models/ContainerRequest';
import { getContainerSetting } from 'operations/modules/reports/oldCellRenderers';

import { ROUTING_TYPE_MAIN_CARRIAGE } from 'operations/models/RoutingLeg';
import { pick as _pick } from 'lodash';
const { Text } = Typography;

export interface AddContainerDetailsProps {
  oceanTransportOrders: OceanTransportOrderValue[];
  roadTransportOrder?: RoadTransportOrderValue;
  bookingRequestId: string;
  onSuccess?: (value: ShipmentContainerValue[], sendEmail: boolean) => void;
  onClose: () => void;
}

interface PickedupContainer {
  id: string;
  booking_number?: string | null;
  container_type?: string | null;
  container_type_code?: string | null;
  container_number?: string | null;
  carrier_seal_number?: string | null;
  container_settings?: ContainerSettingValue;
}

const getOtoContainers = (props: {
  oceanTransportOrders: OceanTransportOrderValue[];
  roadTransportOrderId?: string;
  bookingRequestId?: string;
}) => {
  const { oceanTransportOrders, roadTransportOrderId, bookingRequestId } = props;
  return oceanTransportOrders.reduce((containers: ContainerDetailsRow[], oto) => {
    const parentContainers = roadTransportOrderId
      ? oto.parentRTOShipmentContainers(roadTransportOrderId)
      : oto.parentBRShipmentContainers(bookingRequestId);
    return containers.concat(
      parentContainers
        .map((container) => ({
          ...container,
          booking_number: oto.booking_number || '',
        }))
        .filter((c) => !Boolean(c.container_number))
    );
  }, []);
};

const validateContainerDetails = (
  containers: Array<ContainerDetailsRow>,
  roadTransportOrder: RoadTransportOrderValue | undefined
) => {
  let validationError = false;
  const errorContainerNumber = containers
    .map((c: ContainerDetailsRow) => {
      if (!c.is_non_iso_container && c.container_number) {
        try {
          validateContainerNumber(c.container_number);
        } catch (e) {
          return e;
        }
      }
      return false;
    })
    // eslint-disable-next-line array-callback-return
    .filter((val) => {
      if (val) {
        return String(val);
      }
      return null;
    });
  if (errorContainerNumber[0]) {
    return 'Container Number Wrong';
  }
  let counter = 0;
  (containers || []).forEach((sc) => {
    const isPresent =
      sc.carrier_seal_number ||
      sc.verified_gross_mass ||
      sc.cargo_net_weight ||
      sc.commercial_invoice_number ||
      sc.purchase_order_number ||
      sc.remarks;
    if (isPresent && !sc.container_number) {
      validationError = true;
    } else if (!(sc.container_number || isPresent)) {
      counter++;
    }
  });
  if (counter === containers.length) {
    return 'Please enter atleast one container details';
  }
  return validationError ? 'Container Number Required' : '';
};

const pickedupContainersColumns = [
  {
    title: 'Booking Number',
    dataIndex: 'booking_number',
  },
  {
    title: 'Container Type',
    dataIndex: 'container_type',
    render: function renderContainerType(text: string, record: PickedupContainer) {
      const { container_type_code, container_settings, container_type } = record;
      const containerSettings = getContainerSetting({
        container_settings,
        container_type_code,
      });
      return containerSettings ? `${container_type} (${containerSettings})` : container_type;
    },
  },
  {
    title: 'Container Number',
    dataIndex: 'container_number',
  },
  {
    title: 'Booking Number',
    dataIndex: 'booking_number',
  },
  {
    title: 'S/L Seal Number',
    dataIndex: 'carrier_seal_number',
  },
];

const getPickedupContainers = (
  oceanTransportOrders: OceanTransportOrderValue[],
  bookingRequestId: string,
  roadTransportOrder?: RoadTransportOrderValue
) => {
  if (!oceanTransportOrders) return [];
  return oceanTransportOrders.reduce(
    (pickedupContainers: PickedupContainer[], oto: OceanTransportOrderValue) => {
      if (oto.shipment_containers) {
        oto.shipment_containers.forEach((sc: ShipmentContainerValue) => {
          let includeShipmentContainer = false;
          if (sc.container_number && sc.booking_request_id === bookingRequestId) {
            if (!roadTransportOrder) includeShipmentContainer = true;
            else if (
              roadTransportOrder &&
              sc.road_transport_order_ids?.includes(roadTransportOrder?.id)
            )
              includeShipmentContainer = true;
          }
          if (includeShipmentContainer)
            pickedupContainers.push({
              id: sc.id,
              booking_number: oto.booking_number,
              container_type: sc.container_type,
              container_type_code: sc.container_type_code,
              container_settings: sc.container_settings,
              container_number: sc.container_number,
              carrier_seal_number: sc.carrier_seal_number,
            });
        });
      }
      return pickedupContainers;
    },
    []
  );
};

const AddContainerDetails = observer(function AddContainerDetails(
  props: AddContainerDetailsProps
): JSX.Element {
  const [form] = Form.useForm();
  const { oceanTransportOrders, roadTransportOrder, onSuccess, bookingRequestId, onClose } = props;
  const sessionData = useSession();
  const default_company_id = sessionData.company_account.default_company.id;
  const [validationError, setValidationError] = useState<string | undefined>();
  const [sendEmail, setSendEmail] = useState<boolean>(true);
  const gridRef = useRef<any>();
  const [otoContainers, setOtoContainers] = useState<any>();

  useEffect(() => {
    setOtoContainers(
      getOtoContainers({
        oceanTransportOrders,
        roadTransportOrderId: roadTransportOrder?.id,
        bookingRequestId,
      })
    );
    if (roadTransportOrder && otoContainers) {
      setOtoContainers(
        (otoContainers || []).filter((container: any) =>
          roadTransportOrder.shipment_containers.find((c) => c.id === container.id)
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oceanTransportOrders, bookingRequestId, roadTransportOrder]);

  const [updateDetails, { data, loading, error }] = useMutation(UPDATE_CONTAINER_PICKUP_DETAILS);

  useEffect(() => {
    if (data && data.update_container_pickup_details) {
      message.success('Container Details updated successfully');
      form.resetFields();
      if (onSuccess) onSuccess(data.update_container_pickup_details.shipment_containers, sendEmail);
      onClose();
    }
  }, [data, form, onClose, onSuccess, sendEmail]);
  const main_carriage_routing_legs =
    oceanTransportOrders?.[0]?.carriageWiseRoutingLegs?.[ROUTING_TYPE_MAIN_CARRIAGE];

  const pickedupContainers = useMemo(
    () => getPickedupContainers(oceanTransportOrders, bookingRequestId, roadTransportOrder),
    [bookingRequestId, oceanTransportOrders, roadTransportOrder]
  );

  const closeDrawer = useCallback(() => {
    form.resetFields();
    onClose();
  }, [form, onClose]);
  return (
    <Drawer
      title={`Add Containers Details`}
      width={'80%'}
      open={true}
      onClose={onClose}
      footer={
        <DrawerFooter
          saveText="Submit"
          closeText="Cancel"
          showSendEmail
          sendEmail={sendEmail}
          setSendEmail={setSendEmail}
          sendEmailText="Send Pickup Confirmation"
          loading={loading}
          onClose={closeDrawer}
          onSave={form.submit}
        />
      }
    >
      <Alert
        message="Paste container details in bulk / Enter the details in the table"
        style={{ marginBottom: '20px' }}
      />
      {error && errorMessageHandlerGraphQL(error)}
      {roadTransportOrder && (
        <Card style={{ marginBottom: '10px' }} className="no-padding-description custom-card">
          <Descriptions column={4} colon={false} size="small" layout="vertical">
            <Descriptions.Item key="transporter" label="Transporter">
              {roadTransportOrder.getVendorCompany(default_company_id)?.registered_name}
            </Descriptions.Item>
            <Descriptions.Item key="empty_pickup" label="Empty Pickup Location">
              {oceanTransportOrders[0].empty_pickup_location?.name}
            </Descriptions.Item>
            <Descriptions.Item
              style={{ paddingRight: '8px' }}
              key="factory_location"
              label="Factory Location"
            >
              {roadTransportOrder.factory_location?.print_address}
            </Descriptions.Item>
            <Descriptions.Item key="port_of_loading" label="Port Of Loading">
              {main_carriage_routing_legs?.[0].origin?.location?.name}
            </Descriptions.Item>
          </Descriptions>
        </Card>
      )}
      {validationError && (
        <Text style={{ marginBottom: '10px' }} type="danger">
          {validationError}
        </Text>
      )}
      <Form
        form={form}
        layout="vertical"
        onFinish={() => {
          const containers: any = [];
          gridRef.current.api.forEachNode((node: any) => {
            containers.push({
              ...node.data,
              surveyor_address_id: oceanTransportOrders[0]?.surveyor_address?.id,
              surveyor_company_id: oceanTransportOrders[0]?.surveyor_company?.id,
            });
          });
          const errorMessage = validateContainerDetails(containers, roadTransportOrder);
          if (errorMessage) {
            setValidationError(errorMessage);
          } else {
            const payload = {
              shipment_containers: containers
                .filter((c: ContainerDetailsRow) => c.container_number && c.pickup_date)
                .map((c: ContainerDetailsRow) => ({
                  ..._pick(c, [
                    'id',
                    'is_non_iso_container',
                    'container_number',
                    'carrier_seal_number',
                    'cargo_gross_weight',
                    'verified_gross_mass',
                    'cargo_net_weight',
                    'pickup_date',
                    'commercial_invoice_number',
                    'purchase_order_number',
                    'remarks',
                    'surveyor_address_id',
                    'surveyor_company_id',
                  ]),
                  // pickup_date: c.pickup_date,
                })),
              road_transport_order_id: roadTransportOrder?.id,
            };
            updateDetails({ variables: payload });
          }
        }}
      >
        <div style={pickedupContainers.length > 0 ? {} : { margin: '16px 0 44px' }}>
          <ContainerDetailsForm
            otoContainers={otoContainers}
            roadTransportOrder={roadTransportOrder}
            gridRef={gridRef}
          />
        </div>
        {pickedupContainers.length > 0 && (
          <div style={{ margin: '16px 0 44px' }}>
            <Text style={{ fontSize: '16px', fontWeight: 500 }}>Pickedup Containers</Text>
            <Table
              style={{ marginTop: '16px' }}
              dataSource={pickedupContainers}
              columns={pickedupContainersColumns}
              pagination={false}
              rowKey={(record) => record.id || ''}
              size="small"
            />
          </div>
        )}
      </Form>
    </Drawer>
  );
});

export default AddContainerDetails;
