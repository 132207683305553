import { Button, Checkbox, Form, Modal, Skeleton, BaseTable } from '@shipmnts/pixel-hub';
import React, { useState } from 'react';
import RateExplorerFilter from './RateExplorerFilter';
import { GET_RATE_EXPLORER_DATA } from 'sales_hub/graphql/inquiry';
import { useLazyQuery } from '@apollo/client';
import { useSession } from 'common';
import { hasPermission } from '@shipmnts/pixel-hub';

import { getNewEstimates, getQuoteTableDate, getRateExplorerColumn } from 'sales_hub/utils/utils';
import { RateExplorerData, RateExplorerResource } from './RateExplorer.type';
import { FILTERS } from 'sales_hub/utils/RateExplorerFiltersMapping';
import { ShipmentEstimateValue } from 'sales_hub/models/ShipmentEstimate';
import RateTable from './RateTable';
import { PERMISSION_BUY_ESTIMATE, PERMISSION_SELL_ESTIMATE } from 'sales_hub/utils/constants';
import { ActionRendererDetailReport } from 'operations';

interface RateExplorerModelProp {
  resource: RateExplorerResource;
  addEstimate: (estimates: Partial<ShipmentEstimateValue>[]) => void;
  onSucess?: () => void;
  onClose?: () => void;
  defaultFilter?: Record<string, any>;
  intialFormValue?: Record<string, any>;
}

export default function RateExplorerModal(props: RateExplorerModelProp) {
  const { resource, addEstimate, onSucess, onClose, intialFormValue, defaultFilter } = props;
  const sessionData = useSession();
  const hasBuyPermission = hasPermission(sessionData?.permissions, {
    AND: [{ name: PERMISSION_BUY_ESTIMATE, docType: 'NewAccounting::ShipmentEstimate' }],
  });
  const hasSellPermission = hasPermission(sessionData?.permissions, {
    AND: [{ name: PERMISSION_SELL_ESTIMATE, docType: 'NewAccounting::ShipmentEstimate' }],
  });
  const [copyBuy, setCopyBuy] = useState(hasBuyPermission);
  const [copySell, setCopySell] = useState(hasSellPermission);
  const [open, setOpen] = useState(true);
  const [filterForm] = Form.useForm();
  const [getRateExplorerData, { data, loading: loadingData }] =
    useLazyQuery(GET_RATE_EXPLORER_DATA);
  const [selectedRow, updateSelectedRow] = useState<string[]>([]);
  const [rate, setRate] = useState<RateExplorerData | undefined>();

  const columns = getRateExplorerColumn(
    data?.get_rate_explorer_data,
    resource,
    sessionData,
    setRate
  );

  return (
    <Modal
      destroyOnClose
      open={open}
      width={'90%'}
      maskClosable={false}
      onCancel={() => {
        setOpen(false);
        if (onClose) onClose();
      }}
      closable={false}
      footer={[
        <div key={'actions'} style={{ textAlign: 'right' }}>
          {hasBuyPermission && (
            <Checkbox checked={copyBuy} onClick={() => setCopyBuy(!copyBuy)}>
              Copy Buy
            </Checkbox>
          )}
          {hasSellPermission && (
            <Checkbox checked={copySell} onClick={() => setCopySell(!copySell)}>
              Copy Sell
            </Checkbox>
          )}

          <Button
            key="cancel"
            onClick={() => {
              setOpen(false);
              if (onClose) onClose();
            }}
          >
            Cancel
          </Button>

          <Button
            key={'copy'}
            disabled={selectedRow.length === 0}
            onClick={() => {
              const rateExplorerDataMap: Record<string, RateExplorerData> = {};
              (data?.get_rate_explorer_data || []).forEach((rateExplorerData: RateExplorerData) => {
                if (rateExplorerData?.id)
                  rateExplorerDataMap[rateExplorerData?.id] = rateExplorerData;
              });
              let estimates: Partial<ShipmentEstimateValue>[] = [];
              selectedRow.forEach((key: string) => {
                const rateData = rateExplorerDataMap[key];
                estimates = [
                  ...estimates,
                  ...getNewEstimates(rateData.shipment_estimates, copyBuy, copySell),
                ];
              });
              addEstimate(estimates);
              setOpen(false);
              if (onSucess) onSucess();
              if (onClose) onClose();
            }}
            type="primary"
          >
            Copy
          </Button>
        </div>,
      ]}
    >
      <RateExplorerFilter
        activeFilter={FILTERS}
        form={filterForm}
        getRateExplorerData={getRateExplorerData}
        resource={resource}
        intialFormValue={intialFormValue}
        defaultFilter={defaultFilter}
      />

      {!loadingData && (
        <BaseTable
          rowData={getQuoteTableDate(data?.get_rate_explorer_data)}
          columns={columns}
          height={'50vh'}
          reportName="inquiry_options"
          checkbox_always_visible={false}
          showCheckBoxOnHeader
          rowSelection="multiple"
          onSelectionChanged={(params: any) => {
            const selectedRows =
              params.api?.getSelectedRows()?.map((ele: RateExplorerData) => ele.id) || [];
            updateSelectedRow(selectedRows);
          }}
          reportConfig={{
            rowMultiSelectWithClick: true,
            defaultColDef: {
              suppressMovable: true,
            },
            components: { ActionRendererDetailReport },
          }}
        />
      )}
      {loadingData && <Skeleton paragraph={{ rows: 10 }} />}
      {!!rate && <RateTable onClose={() => setRate(undefined)} rate={rate} />}
    </Modal>
  );
}
