import { gql } from '@apollo/client';
import { shipmentContainerFields } from 'operations/modules/booking/graphql/shipmentContainer';
import { userContactFields } from 'operations/graphql/user';
import { branchAccountFields } from 'operations/graphql/shipment';
import { salesPersonFields } from 'operations/graphql/salesPerson';
import { shipmentFields } from 'operations/graphql/shipment';

export const productOrderItemFields = gql`
  fragment productOrderItemFields on ProductOrderItemObjectType {
    id
    product_name
    seller_product_code
    buyer_product_code
    country_of_manufacture
    hs_code
    inner_package_type
    inner_per_packet_wt
    inner_package_qty
    packing_remarks
    outer_package_type
    outer_per_packet_wt
    outer_package_qty
    outer_packing_remarks
    uom
    order_qty
    prioritized_qty
    ready_qty
    dispatched_qty
    delivered_qty
    shipment_planned_qty
    shipped_qty
    invoice_qty
    amount
    unit_rate
    currency
    exchange_rate
    tax_percentage
    linked_to_id
    shipment_invoice_id
    base_amount
    product_description
    additional_description
    gross_weight
    net_weight
    marks_and_numbers
    sales_order_number
    purchase_order_number
    cargo_ready_date
    target_delivery_date
    target_shipping_date
    parent_id
    parent_type
    linked_product_order_items {
      id
      shipment_planned_qty
      shipped_qty
      delivered_qty
      dispatched_qty
      prioritized_qty
      ready_qty
      invoice_qty
      unit_rate
      product_name
      uom
      shipment {
        ...shipmentFields
      }
      product_order {
        id
        order_currency
        payment_term
        type
      }
    }
    linked_to {
      product_name
      shipment_planned_qty
      product_order {
        id
        sales_order_number
        purchase_order_number
        last_status_update
        last_comment
        type
        split_shipment_allowed
        buyer {
          id
          registered_name
        }
        ship_from_company {
          id
          registered_name
        }
        ship_from_address {
          id
          name
          print_address
        }
        ship_to_company {
          id
          registered_name
        }
        ship_to_address {
          id
          name
          print_address
        }
        port_of_discharge {
          id
          name
          unlocode
          country {
            name
          }
        }
        port_of_loading {
          id
          name
          unlocode
          country {
            name
          }
        }
        freight_type
        incoterm
        product_order_items {
          id
          product_name
          seller_product_code
          buyer_product_code
          country_of_manufacture
          hs_code
          inner_package_type
          inner_per_packet_wt
          inner_package_qty
          packing_remarks
          outer_package_type
          outer_per_packet_wt
          outer_package_qty
          outer_packing_remarks
          uom
          order_qty
          prioritized_qty
          ready_qty
          dispatched_qty
          delivered_qty
          shipment_planned_qty
          shipped_qty
          invoice_qty
          amount
          unit_rate
          tax_percentage
          linked_to_id
          shipment_invoice_id
          base_amount
          product_description
          additional_description
          gross_weight
          net_weight
          marks_and_numbers
          sales_order_number
          purchase_order_number
        }
      }
    }
    custom_field_values
  }
  ${shipmentFields}
`;

export const productOrderFields = gql`
  fragment productOrderFields on ProductOrderObjectType {
    id
    sales_person {
      ...salesPersonFields
    }
    business_through
    type
    documentation_instructions
    estimated_cargo_ready_date
    total_order_amount
    exchange_rate
    freight_terms
    freight_type
    incoterm
    load_type
    order_currency
    payment_term
    priority
    sales_order_date
    sales_order_number
    purchase_order_date
    purchase_order_number
    seller_bank_account
    seller_bank_account_details
    split_shipment_allowed
    target_delivery_date
    target_shipping_date
    is_cargo_ready_date_appliable
    is_target_delivery_date_appliable
    terms_and_conditions
    terms_and_conditions_details
    transhipment_allowed
    total_shipment_planned_qty
    buyer {
      id
      registered_name
    }
    buyer_address {
      id
      name
      print_address
    }
    seller {
      id
      registered_name
    }
    seller_address {
      id
      name
      print_address
    }
    subagent {
      id
      registered_name
    }
    subagent_address {
      id
      name
      print_address
    }
    ship_from_company {
      id
      registered_name
    }
    ship_from_address {
      id
      name
      print_address
    }
    ship_to_company {
      id
      registered_name
    }
    ship_to_address {
      id
      name
      print_address
    }
    print_as
    place_of_carrier_receipt {
      id
      name
      unlocode
    }
    place_of_carrier_delivery {
      id
      name
      unlocode
    }
    port_of_discharge {
      id
      name
      unlocode
      country {
        name
      }
    }
    port_of_loading {
      id
      name
      unlocode
      country {
        name
      }
    }
    created_at
    created_by {
      id
      first_name
      last_name
    }
    branch {
      ...branchAccountFields
    }
    status
    product_order_items {
      ...productOrderItemFields
    }
    container_requests {
      id
      container_type
      container_type_code
      is_shipper_owned
      quantity
      quantity_fulfilled
      quantity_unfulfilled
      quantity_picked_up
      quantity_origin_port_gated_in
      quantity_loaded_on_vessel
      quantity_shutout
      quantity_cancelled
      quantity_offloaded
      weight_per_container
      weight_unit
    }
  }
  ${salesPersonFields}
  ${branchAccountFields}
  ${productOrderItemFields}
`;

export const commercialInvoicesFields = gql`
  fragment commercialInvoicesFields on CommercialInvoiceObjectType {
    id
    shipment {
      ...shipmentFields
    }
    print_shipment_details
    product_order_items {
      ...productOrderItemFields
    }
    service_items {
      id
      uom
      sell_currency
      sell_exchange_rate
      invoice_selling_amount
      item
      is_add_to_invoice
      quantity
      total_sell_amount
      sell_rate
      reference_type
      reference_id
    }
    containers_commercial_invoices {
      id
      shipment_container {
        ...shipmentContainerFields
      }
      shipment_invoice {
        product_order_items {
          gross_weight
          net_weight
        }
      }
    }
    shipment_custom_detail {
      id
      custom_document_number
      custom_document_date
      custom_clearance_location {
        id
        name
        type
        country_code
        state_code
        is_customs_location
      }
      shipping_bill_type
      invoice_value
      fob_value
      cif_value
    }
    invoice_number
    invoice_date
    payment_term
    letter_of_credit_number
    letter_of_credit_date
    importer_exporter_lic_code
    tax_name_type
    insured_amount
    invoice_currency
    exchange_rate
    base_invoice_amount
    invoice_amount
    bank_account
    bank_account_details
    terms_and_conditions
    terms_and_conditions_details
    remarks
    remarks_details
    declarations
    declarations_details
    other_reference
    marine_insurance_policy_no
    marine_insurance_policy_date
    fob_invoice_amount
    cif_invoice_amount
    revision_count
    status
    auto_invoice_number
    user_contact {
      ...userContactFields
    }
  }
  ${userContactFields}
  ${productOrderItemFields}
  ${shipmentContainerFields}
  ${shipmentFields}
`;

export const CREATE_PRODUCT_ORDER = gql`
  mutation create_product_order($product_order: ProductOrderInputType!) {
    create_product_order(product_order: $product_order) {
      id
    }
  }
`;

export const UPDATE_PRODUCT_ORDER = gql`
  mutation update_product_order($id: ID!, $product_order: ProductOrderInputType!) {
    update_product_order(id: $id, product_order: $product_order) {
      id
    }
  }
`;

export const CREATE_SHIPMENT_FROM_PO = gql`
  mutation create_shipment(
    $shipment: CreateShipmentInput
    $shipment_document_master: ShipmentDocumentInput!
  ) {
    create_shipment(shipment: $shipment, shipment_document_master: $shipment_document_master) {
      shipment {
        ...shipmentFields
      }
    }
    ${shipmentFields}
  }
`;

export const CANCEL_PRODUCT_ORDER = gql`
  mutation cancel_product_order($id: ID!, $remarks: String!) {
    cancel_product_order(id: $id, remarks: $remarks) {
      id
      status
    }
  }
`;

export const UPSERT_COMMERCIAL_INVOICE = gql`
  mutation upsert_commercial_invoice(
    $commercial_invoice: CommercialInvoiceInputType!
    $shipment_containers: [ShipmentContainerInputType!]
  ) {
    upsert_commercial_invoice(
      commercial_invoice: $commercial_invoice
      shipment_containers: $shipment_containers
    ) {
      ...commercialInvoicesFields
    }
  }
  ${commercialInvoicesFields}
`;

export const SHIPMENT_INVOICE_ACTIONS = gql`
  mutation shipment_invoice_actions($id: ID!, $action: ShipmentInvoiceActionEnum!) {
    shipment_invoice_actions(id: $id, action: $action) {
      ...commercialInvoicesFields
    }
  }
  ${commercialInvoicesFields}
`;

export const FETCH_COMMERCIAL_INVOICE = gql`
  query get_commercial_invoice($id: ID!) {
    get_commercial_invoice(id: $id) {
      ...commercialInvoicesFields
    }
  }
  ${commercialInvoicesFields}
`;

export const FETCH_PRODUCT_ORDER = gql`
  query get_product_order($id: ID!) {
    get_product_order(id: $id) {
      ...productOrderFields
    }
  }
  ${productOrderFields}
`;

export const FETCH_PRODUCT_ORDER_ITEMS_FROM_SHIPMENT = gql`
  query shipment($id: ID!) {
    shipment(id: $id) {
      id
      shipment_product_order_items {
        ...productOrderItemFields
        product_order {
          ...productOrderFields
        }
      }
      commercial_invoices {
        ...commercialInvoicesFields
      }
    }
  }
  ${productOrderItemFields}
  ${productOrderFields}
  ${commercialInvoicesFields}
`;

export const PRODUCT_ORDERS_SEARCH = gql`
  query product_orders_search(
    $search_text: String!
    $buyer_id: ID
    $seller_id: ID
    $ship_to_company_id: ID!
    $ship_from_company_id: ID!
    $port_of_loading_id: ID
    $port_of_discharge_id: ID
    $type: String!
  ) {
    product_orders_search(
      search_text: $search_text
      buyer_id: $buyer_id
      seller_id: $seller_id
      ship_to_company_id: $ship_to_company_id
      ship_from_company_id: $ship_from_company_id
      port_of_loading_id: $port_of_loading_id
      port_of_discharge_id: $port_of_discharge_id
      type: $type
    ) {
      ...productOrderFields
    }
  }
  ${productOrderFields}
`;

export const UPDATE_POI = gql`
  mutation update_poi($product_order_items: [ProductOrderItemInputType!]!) {
    update_poi(product_order_items: $product_order_items) {
      id
      shipped_qty
      invoice_qty
    }
  }
`;
