import React from 'react';
import { Icon } from '@shipmnts/pixel-hub';
import { EventStateType } from '../../types';

type TimeLineHeaderProps = {
  title: string; // title to be diplayed
  icon: any;
  state?: EventStateType;
};

const TimeLineHeader = (props: TimeLineHeaderProps): JSX.Element => {
  const { title, icon, state } = props;
  return (
    <div className={`time-line-header ${state}`}>
      <div className={`icon ${state}`}>
        <Icon component={icon} style={{ fontSize: '20px' }} size={20} />
      </div>
      <div className={`title ${state}`}>{title}</div>
    </div>
  );
};

export default TimeLineHeader;
