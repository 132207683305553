import React from 'react';
import { Alert, Button } from '@shipmnts/pixel-hub';
import { SyncOutlined } from '@shipmnts/pixel-hub';
import { useShipmentEstimateStore } from 'operations/models/ShipmentEstimate';
import { ShipmentValue } from 'operations/models/Shipment';
import { observer } from 'mobx-react-lite';
import { useShipmentEstimate } from './ShipmentEstimateLayout';
import { getFinalUoms } from './helper';

interface EstimatesQuantityMisMatchBannerType {
  shipment: ShipmentValue;
  refreshGrid?: () => void;
}

const EstimatesQuantityMisMatchBanner = observer(function EstimatesQuantityMisMatchBanner(
  props: EstimatesQuantityMisMatchBannerType
) {
  const { shipment, refreshGrid } = props;
  const { store } = useShipmentEstimateStore();
  const estimates = store.estimatesForShipment(shipment.id);
  const { configData } = useShipmentEstimate();
  const finalUoms = getFinalUoms(configData.unit_of_measurements, shipment, estimates);
  const mismatchedEstimates = estimates.filter((est) => {
    if (!est.uom || !est.quantity) return false;
    const matchedUom = finalUoms.find((uom) => uom.value === est.uom_string);
    if (!matchedUom) return false;
    return est.quantity !== matchedUom.quantity;
  });

  if (mismatchedEstimates.length === 0) return <></>;

  const updateQuantities = () => {
    mismatchedEstimates.forEach((est) => {
      const matchedUom = finalUoms.find((uom) => uom.value === est.uom_string);
      est.updateQuantity(matchedUom?.quantity || 1);
    });
    if (refreshGrid) refreshGrid();
  };

  return (
    <Alert
      message="Quantity Mismatch for below Charges"
      type="warning"
      showIcon
      action={
        <Button
          size="small"
          icon={<SyncOutlined />}
          onClick={updateQuantities}
          type="primary"
          ghost
        >
          Update
        </Button>
      }
      closable
    />
  );
});

export default EstimatesQuantityMisMatchBanner;
