import React from 'react';
import { ContainerRequestValue } from 'operations/models/ContainerRequest';
import { Table, Typography } from '@shipmnts/pixel-hub';

import { observer } from 'mobx-react-lite';
import _pick from 'lodash/pick';
import { TableColumnType } from '@shipmnts/pixel-hub';
import { renderContainerSettings } from 'operations/modules/reports/oldCellRenderers';
const { Text } = Typography;

interface ContainerRequestsViewProps {
  containerRequests?: Array<ContainerRequestValue>;
  columns: Array<string>;
}

const fields = {
  container_type: {
    title: 'Container Type',
    dataIndex: 'container_type',
    render: function render(text: string) {
      return <Text className="primary-color">{text}</Text>;
    },
  },
  quantity: {
    title: 'Quantity',
    dataIndex: 'quantity',
  },
  unallocated_quantity: {
    title: 'Unallocated Quantity',
    dataIndex: 'quantity_unfulfilled',
  },
  weight_per_container: {
    title: 'Gross Weight / Container',
    dataIndex: 'weight_per_container',
  },
  weight_unit: {
    title: 'Weight unit',
    dataIndex: 'weight_unit',
  },
  is_shipper_owned: {
    title: 'Shipper Owned',
    dataIndex: 'is_shipper_owned',
    render: function render(text: string, record: ContainerRequestValue, index: number) {
      return <span>{record?.is_shipper_owned ? 'Yes' : ''}</span>;
    },
  },
  container_settings: {
    title: 'Settings',
    dataIndex: 'container_settings',
    // editable: true,
    // renderComponent: RENDER_INPUT,
    render: function render(text: string, record: ContainerRequestValue, index: number) {
      return <span>{renderContainerSettings([record])}</span>;
    },
  },
};

const ContainerRequestsView = observer(function ContainerRequestsView(
  props: ContainerRequestsViewProps
): JSX.Element {
  const { containerRequests } = props;
  const columns = Object.values(_pick(fields, props.columns)) as Array<
    TableColumnType<ContainerRequestValue>
  >;
  return (
    <Table
      dataSource={containerRequests}
      columns={columns}
      pagination={false}
      rowKey={(record) => record.id || ''}
      size="small"
    />
  );
});

export default ContainerRequestsView;
