import {
  Col,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
} from '@shipmnts/pixel-hub';
import { CompanySearch, useSession, PhoneNumberInput } from 'common';

import { RadioOptionProps } from 'operations/commonTypeDefs';
import { ShipmentValue } from 'operations/models/Shipment';
import { VEHICLE_CATEGORY } from 'operations/modules/vehicle/constants';
import React, { useEffect } from 'react';

import { ROW_GUTTER } from '../../constants';
import { ASSET_TYPES } from './constants';
import { GlobalSearch } from '@shipmnts/pixel-hub';

interface VehicleDetailsProps {
  shipment?: ShipmentValue;
  form: FormInstance;
}
export default function VehicleDetails(props: VehicleDetailsProps) {
  const { form } = props;
  const sessionData = useSession();
  const vehicle = Form.useWatch(['vehicle', 'vehicle_details'], form);
  const driver = Form.useWatch(['driver', 'driver_details'], form);
  const ownershipType = Form.useWatch(['vehicle', 'ownership_type']);
  const driverContactNumber = Form.useWatch(['driver', 'driver_contact_number'], form);

  useEffect(() => {
    if (ownershipType === 'own') {
      form.setFieldValue(['vehicle', 'company'], sessionData?.company_account?.default_company);
    }
  }, [ownershipType, form, sessionData]);

  useEffect(() => {
    if (vehicle) {
      form.setFieldsValue({
        vehicle: { ...vehicle },
      });
    }
  }, [vehicle, form]);

  useEffect(() => {
    if (driver) {
      let driver_contact_number = driver?.driver_contact_number;
      // eslint-disable-next-line eqeqeq
      if (driver_contact_number && driver?.driver_contact_number?.dialCode == undefined) {
        const driver_number = (driver?.driver_contact_number || '').split(' ');
        driver_contact_number =
          driver_number.length > 1
            ? {
                dialCode: driver_number[0],
                number: driver_number[1],
              }
            : undefined;
      }
      form.setFieldsValue({
        driver: { ...driver, driver_contact_number: driver_contact_number || driverContactNumber },
      });
    }
  }, [driver, driverContactNumber, form]);

  return (
    <Row gutter={ROW_GUTTER}>
      <Col span={8}>
        <Form.Item
          required
          rules={[{ required: true }]}
          name={['vehicle', 'ownership_type']}
          label="Ownership Type"
        >
          <Radio.Group
            onChange={() => {
              form.setFieldsValue({
                vehicle: {
                  vehicle_category: undefined,
                  vehicle_chassis_number: undefined,
                  vehicle_details: undefined,
                },
              });
            }}
          >
            {ASSET_TYPES.map((option: RadioOptionProps, index: number) => (
              <Radio key={index} value={option.key}>
                {option.name}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="Transporter" name={['vehicle', 'company']}>
          <CompanySearch
            disabled={vehicle?.company && ownershipType === 'own'}
            searchProps={{ is_vendor: true }}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="Trip Distance" name={'trip_distance'}>
          <InputNumber placeholder="Enter Trip Distance" style={{ width: '100%' }} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="Vehicle License Plate #" name={['vehicle', 'vehicle_details']}>
          <GlobalSearch
            doc_type="Shipment::Vehicle"
            searchProps={{ ownership_type: ownershipType }}
            showCreation={true}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="Vehicle Type" name={['vehicle', 'vehicle_category']}>
          <Select
            disabled={vehicle?.vehicle_category}
            placeholder="Select Vehicle Category"
            style={{ width: '100%' }}
          >
            {VEHICLE_CATEGORY.map((ct) => (
              <Select.Option key={ct.name} value={ct.name}>
                {ct.value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="Vehicle Chassis #" name={['vehicle', 'vehicle_chassis_number']}>
          <Select
            disabled={(vehicle?.vehicle_chassis_number || []).length > 0}
            mode="tags"
            style={{ width: '100%' }}
            options={[]}
            placeholder="Enter Vehicle Chassis #"
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="Driver Name" name={['driver', 'driver_details']}>
          <GlobalSearch doc_type="Shipment::Driver" />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="Driver License #" name={['driver', 'driver_license_number']}>
          <Input disabled={driver?.driver_license_number} placeholder="Enter Driver License #" />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          label="Driver Contact #"
          required={driver?.driver_name}
          rules={[{ required: driver?.driver_name }]}
          name={['driver', 'driver_contact_number']}
        >
          <PhoneNumberInput disabled={driver?.driver_contact_number} />
        </Form.Item>
      </Col>
    </Row>
  );
}
