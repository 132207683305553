import { gql } from '@apollo/client';
import { orderCollaborationFields } from './orderCollaboration';

export const stuffingBufferServiceOrderFields = gql`
  fragment stuffingBufferServiceOrderFields on StuffingBufferServiceObjectType {
    id
    buffer_only
    location_node {
      id
      tags
      location {
        id
        name
        unlocode
        city_code
        state_code
        country_code
      }
    }
    order_collaborations {
      ...orderCollaborationFields
    }
    status
    stuffing_provider {
      id
      registered_name
      logo
    }
    email_activities {
      id
      purpose
    }
    stuffing_cutoff_date
  }
  ${orderCollaborationFields}
`;

export const SAVE_STUFFING_BUFFER_SERVICE_ORDER = gql`
  mutation save_stuffing_buffer_service_order(
    $booking_request_id: ID!
    $stuffing_buffer_service_order: StuffingBookingServiceOrderInputType!
  ) {
    save_stuffing_buffer_service_order(
      booking_request_id: $booking_request_id
      stuffing_buffer_service_order: $stuffing_buffer_service_order
    ) {
      ...stuffingBufferServiceOrderFields
    }
  }
  ${stuffingBufferServiceOrderFields}
`;
