import { ExemptionAccountsData } from 'common/models/Company';
import { PlusOutlined, FolderViewOutlined } from '@ant-design/icons';
import React from 'react';
import { Card } from 'common';
import { Button } from '@shipmnts/pixel-hub';
import { get as _get, capitalize as _capitalize } from 'lodash';

import { WithPermission, dayjs } from '@shipmnts/pixel-hub';
import { useCompanyView } from '../CompanyView';
import {
  PERMISSION_CUSTOMER_CREATE_EDIT,
  PERMISSION_SUPPLIER_CREATE_EDIT,
} from 'network/permissions';
import { useSession } from 'common';

const AddExemption = ({ type, category }: { type?: string; category?: string }) => {
  const session = useSession();
  const { company } = useCompanyView();
  return !!company ? (
    <Button
      disabled={company?.is_disabled}
      onClick={() => {
        let params = `party_type=${type === 'receivable' ? 'Customer' : 'Supplier'}`;
        params += `&party=${encodeURIComponent(company.registered_name)}`;
        if (category) params += `&tax_withholding_category=${encodeURIComponent(category)}`;
        window.open(
          `${session?.company_account?.subdomain}/desk#Form/Tax Withholding Exemption/New Taxwithholding Exemption 1?${params}`
        );
      }}
      icon={<PlusOutlined />}
      size="small"
    >
      {'Add New Exemption'}
    </Button>
  ) : (
    <></>
  );
};

export const ExemptionCard = ({
  loading,
  type,
}: {
  type: 'receivable' | 'payable';
  loading?: boolean;
  erpnextConfigData?: any;
}) => {
  const { accounts_data } = useCompanyView();
  // states
  const headingStyle = { display: 'block', opacity: 0.6, fontSize: '12px', fontWeight: '600' };
  const category = _get(accounts_data, `${type}_tax_withholding_category`);
  const exemptions: Array<ExemptionAccountsData> = _get(accounts_data, `${type}_exemptions`, []);
  const session = useSession();

  const permissions = {
    receivable: { name: PERMISSION_CUSTOMER_CREATE_EDIT, docType: 'Network::Company' },
    payable: { name: PERMISSION_SUPPLIER_CREATE_EDIT, docType: 'Network::Company' },
  };

  return (
    <WithPermission permission={permissions[type]}>
      <Card
        header={{ title: `${_capitalize(type)} Exemptions` }}
        loading={loading}
        style={{ width: '100%', marginBottom: '1em' }}
        Action={
          <WithPermission permission={permissions[type]}>
            <AddExemption type={type} category={category} />
          </WithPermission>
        }
      >
        {exemptions && exemptions.length > 0 ? (
          <div
            style={{
              display: 'grid',
              width: '100%',
              marginTop: '2em',
            }}
          >
            {exemptions?.map((exemption, idx) => {
              // data to show
              const data = {
                'Certificate #': exemption?.certificate_number || '-',
                'Exemption Limit': exemption?.exemption_limit || '-',
                'Exemption Rate': exemption?.exemption_rate
                  ? `${exemption?.exemption_rate} %`
                  : '-',
                'Valid From': exemption?.valid_from
                  ? dayjs(exemption?.valid_from).format('DD-MM-YYYY')
                  : '-',
                'Valid To': exemption?.valid_to
                  ? dayjs(exemption?.valid_to).format('DD-MM-YYYY')
                  : '-',
              };

              return (
                <div
                  key={''}
                  style={{
                    display: 'grid',
                    gridAutoFlow: 'column',
                    alignItems: 'start',
                    gridTemplateColumns: 'repeat(5, 16%)',
                    borderBottom:
                      idx !== exemptions.length - 1 ? '1px solid rgba(0, 0, 0, .1)' : '',
                    padding: '1em',
                    gap: '1em',
                  }}
                >
                  {Object.entries(data).map(([label, value]) => {
                    return (
                      <section key={label} style={{ wordWrap: 'break-word' }}>
                        <span style={headingStyle}>{label}</span>
                        <span>{value}</span>
                      </section>
                    );
                  })}
                  <WithPermission permission={permissions[type]}>
                    <section style={{ textAlign: 'right', alignSelf: 'center' }}>
                      <Button
                        type="link"
                        icon={<FolderViewOutlined />}
                        onClick={() =>
                          window.open(
                            `${session?.company_account?.subdomain}/desk#Form/Tax Withholding Exemption/${exemption.erpnext_name}`
                          )
                        }
                        size="small"
                      >
                        View
                      </Button>
                    </section>
                  </WithPermission>
                </div>
              );
            })}
          </div>
        ) : (
          <div
            style={{ textAlign: 'center', padding: '1em', fontSize: '16px', opacity: '0.4' }}
          >{`No ${_capitalize(type)} Exemptions Added Yet.`}</div>
        )}
      </Card>
    </WithPermission>
  );
};
