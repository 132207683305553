import { invert as _invert } from 'lodash';

export interface StringHash {
  [key: string]: string;
}

export const keyLabelMapping: StringHash = {
  company: 'Companies',
  address: 'Addresses',
  booking_request: 'Customer Orders',
  booking_order: 'Vendor Bookings',
  voyage_schedule: 'Voyage Schedules',
  shipment: 'Shipments',
  contact: 'Contacts',
};

export const findDocTypeId: StringHash = {
  booking_request: 'Shipment::BookingRequest',
  booking_order: 'Shipment::OceanTransportOrder',
  shipment: 'Shipment::Shipment',
  voyage_schedule: 'Network::VoyageSchedule',
  company: 'Network::Company',
  address: 'Network::Address',
  contact: 'Network::UserContact',
};
export const linkMap: StringHash = _invert(findDocTypeId);
