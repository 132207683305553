import {
  Drawer,
  Form,
  Input,
  message,
  Radio,
  CreditLimitCheckWrapper,
  CreditLimitCheckContext,
} from '@shipmnts/pixel-hub';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { DOCUMENT_STATUS_GIVEN_TO_CUSTOMER, SHIPMENT_DOCUMENT_TYPE_MASTER } from '../../constants';
import { useMutation } from '@apollo/client';
import { UPDATE_SHIPMENT_DOCUMENT_STATUS } from 'operations/graphql/shipmentDocument';
import { DrawerFooter, dayjs } from '@shipmnts/pixel-hub';
import { ShipmentValue } from 'operations/models/Shipment';
import { ShipmentDocumentValue } from 'operations/models/ShipmentDocument';
import { useSession } from 'common';
import { CompanySearch } from 'common';
import { BL_TYPE_ORIGINAL } from 'operations/modules/shipment/constants';
import { startCase } from 'lodash';

interface BLReleaseToCustomerProps {
  shipment: ShipmentValue;
  nextStatus: string;
  shipmentDocument?: ShipmentDocumentValue;
  onSuccess?: (sendEmail?: boolean) => void;
  onClose?: () => void;
}

export default function BLReleaseToCustomerWrapper(props: BLReleaseToCustomerProps) {
  const sessionData = useSession();
  return (
    <CreditLimitCheckWrapper sessionData={sessionData}>
      <BLReleaseToCustomer {...props} />
    </CreditLimitCheckWrapper>
  );
}

function BLReleaseToCustomer(props: BLReleaseToCustomerProps) {
  const { shipmentDocument, shipment, nextStatus, onSuccess, onClose } = props;
  const [modeOfRelease, setModeOfRelease] = useState<string>('person');
  const [visible, setVisible] = useState(true);
  const [sendEmail, setSendEmail] = useState(true);
  const [showForm, setShowForm] = useState(false);

  const { runCreditRules } = useContext(CreditLimitCheckContext);

  const [
    updateDocumentStatus,
    { loading: updateDocumentStatusLoading, data: updateStatusData, error: updateStatusError },
  ] = useMutation(UPDATE_SHIPMENT_DOCUMENT_STATUS);

  useEffect(() => {
    if (updateDocumentStatusLoading) {
      return;
    }
    if (updateStatusError) {
      message.error(updateStatusError.message);
    }
    if (updateStatusData?.update_shipment_document_status) {
      message.success('MBL marked as given to customer');
      if (nextStatus === DOCUMENT_STATUS_GIVEN_TO_CUSTOMER) {
        if (onSuccess) onSuccess(sendEmail);
      }
      onClose && onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatusData, updateDocumentStatusLoading, updateStatusError]);

  const updateDocument = useCallback(
    async (values?: any) => {
      const shipment_document = shipment?.shipment_documents?.find(
        (document: ShipmentDocumentValue) =>
          document.document_type === SHIPMENT_DOCUMENT_TYPE_MASTER
      );
      let passed = true;
      passed = await runCreditRules({
        actor_id: shipment_document?.id || '',
        trigger: 'bl_release',
        actor_type: 'ShipmentDocument',
        customer_name: shipment.customer_company?.registered_name || '',
      });
      if (values?.courier_provider) {
        values.courier_provider = startCase(values?.courier_provider?.registered_name);
      }
      if (passed) {
        updateDocumentStatus({
          variables: {
            id: shipment_document?.id,
            document_status: nextStatus,
            document_status_event_data: values,
            document_status_event_date: dayjs().unix(),
          },
        });
      }
    },
    [nextStatus, runCreditRules, shipment, updateDocumentStatus]
  );

  useEffect(() => {
    if (shipmentDocument?.bl_type === BL_TYPE_ORIGINAL) {
      setShowForm(true);
    } else {
      updateDocument();
    }
  }, [shipmentDocument, updateDocument]);

  const [form] = Form.useForm();

  return (
    <>
      {showForm ? (
        <Drawer
          open={visible}
          title={'Given to Customer'}
          width={'30%'}
          onClose={onClose}
          footer={
            <DrawerFooter
              showSendEmail
              sendEmail={sendEmail}
              setSendEmail={setSendEmail}
              sendEmailText="Send Confirmation"
              saveText="Save"
              onClose={() => {
                setVisible(false);
                onClose && onClose();
              }}
              onSave={form.submit}
            />
          }
        >
          <Form
            form={form}
            layout="vertical"
            initialValues={{ mode_of_release: 'person' }}
            onFinish={(values) => {
              updateDocument(values);
            }}
          >
            <Form.Item name={'mode_of_release'} label="Mode of Release">
              <Radio.Group
                onChange={(e) => {
                  setModeOfRelease(e.target.value);
                }}
              >
                <Radio value={'person'}>Person</Radio>
                <Radio value={'courier'}>Courier</Radio>
              </Radio.Group>
            </Form.Item>
            {modeOfRelease === 'person' && (
              <>
                <Form.Item name="bl_given_to" label="Bl Given To">
                  <Input />
                </Form.Item>
                <Form.Item name="bl_given_by" label="Bl Given By">
                  <Input />
                </Form.Item>
              </>
            )}
            {modeOfRelease === 'courier' && (
              <>
                <Form.Item name="courier_provider" label="Courier Provider">
                  <CompanySearch searchProps={{ is_vendor: true }} />
                </Form.Item>
                <Form.Item name="courier_tracking_number" label="Courier Tracking Number">
                  <Input />
                </Form.Item>
              </>
            )}
          </Form>
        </Drawer>
      ) : (
        <></>
      )}
    </>
  );
}
