import React, { MutableRefObject, useCallback, useEffect, useState } from 'react';
// TODO: replace AG Grid react with Base Table
import { GridOptions, SelectionChangedEvent } from '@ag-grid-community/core';
import { Column } from 'operations/models/Report';
import { pick as _pick, omit as _omit } from 'lodash';
import { ProductOrderValue } from 'operations/models/ProductOrder';
import { BaseTable, FloatEditor, dayjs } from '@shipmnts/pixel-hub';

export const renderContainerData = (productOrders: any) => {
  if (!productOrders.length) return [];

  const product_data: any[] = [];
  productOrders.forEach((products: any) => {
    if (products?.split_shipment_allowed) {
      (products.product_order_items || []).forEach((poi: any) => {
        if (poi.order_qty - poi.shipment_planned_qty !== 0) {
          product_data.push({
            ..._omit(poi, [
              'shipment',
              'product_order',
              'linked_product_order_items',
              'linked_to',
              'linked_to_id',
              '_destroy',
            ]),
            product_name: poi.product_name,
            product_description: poi?.product_description,
            buyer_product_code: poi?.buyer_product_code,
            uom: poi.uom,
            purchase_order_number: products?.purchase_order_number,
            sales_order_number: products?.sales_order_number,
            target_delivery_date: poi?.target_delivery_date
              ? dayjs.unix(poi?.target_delivery_date).format('ddd, DD MMM YYYY')
              : null,
            order_qty: poi.order_qty,
            shipment_planned_qty: poi.shipment_planned_qty || 0,
            balance: Number(
              (poi.order_qty?.toFixed(3) - poi.shipment_planned_qty?.toFixed(3))?.toFixed(3)
            ),
            booking_qty:
              Number(
                (poi.order_qty?.toFixed(3) - poi.shipment_planned_qty?.toFixed(3))?.toFixed(3)
              ) || 0,
            id: poi.id,
            split_shipment_allowed: products.split_shipment_allowed,
          });
        }
      });
    } else {
      (products?.product_order_items || []).forEach((poi: any) => {
        if (poi.order_qty - poi.shipment_planned_qty !== 0) {
          product_data.push({
            ..._omit(poi, [
              'shipment',
              'product_order',
              'linked_product_order_items',
              'linked_to',
              'linked_to_id',
              '_destroy',
            ]),
            product_name: poi.product_name,
            product_description: poi?.product_description,
            buyer_product_code: poi?.buyer_product_code,
            uom: poi.uom,
            purchase_order_number: products?.purchase_order_number,
            sales_order_number: products?.sales_order_number,
            target_delivery_date: poi?.target_delivery_date
              ? dayjs.unix(poi?.target_delivery_date).format('ddd, DD MMM YYYY')
              : null,
            order_qty: poi.order_qty,
            shipment_planned_qty: poi.shipment_planned_qty || 0,
            balance: Number(
              (poi.order_qty?.toFixed(3) - poi.shipment_planned_qty?.toFixed(3))?.toFixed(3)
            ),
            booking_qty:
              Number(
                (poi.order_qty?.toFixed(3) - poi.shipment_planned_qty?.toFixed(3))?.toFixed(3)
              ) || 0,
            id: poi.id,
            split_shipment_allowed: products.split_shipment_allowed,
          });
        }
      });
    }
  });
  return product_data;
};

const ProductGrid = React.memo(function ContainerGrid(props: {
  tableHeading: string;
  productOrder?: ProductOrderValue[];
  columnNames: string[];
  gridRef: MutableRefObject<GridOptions | undefined>;
  onSelectionChanged?: () => void;
  renderFormatedData?: boolean;
}): JSX.Element {
  const {
    tableHeading,
    productOrder,
    columnNames,
    gridRef,
    onSelectionChanged,
    renderFormatedData,
  } = props;
  const [rowData, setRowData] = useState<any[]>([]);

  useEffect(() => {
    if (productOrder) {
      if (renderFormatedData) {
        setRowData(renderContainerData(productOrder));
      } else setRowData(productOrder);
    }

    gridRef?.current?.api?.forEachNode((node) => node.setSelected(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productOrder]);

  const fields: { [key: string]: Column } = {
    product_name: {
      headerName: 'Product Name',
      field: 'product_name',
      colId: 'product_name',
      filter: 'agTextColumnFilter',
      width: 160,
      pinned: 'left',
    },
    product_description: {
      headerName: 'Product Description',
      field: 'product_description',
      colId: 'product_description',
      filter: 'agTextColumnFilter',
      width: 160,
    },
    buyer_product_code: {
      headerName: 'Buyer Product Code',
      field: 'buyer_product_code',
      colId: 'buyer_product_code',
      filter: 'agTextColumnFilter',
      width: 160,
    },
    sales_order_number: {
      headerName: 'Sales Order #',
      field: 'sales_order_number',
      colId: 'sales_order_number',
      minWidth: 100,
    },
    purchase_order_number: {
      headerName: 'Purchase Order #',
      field: 'purchase_order_number',
      colId: 'purchase_order_number',
      minWidth: 100,
    },
    order_qty: {
      headerName: 'Ordered Qty',
      field: 'order_qty',
      colId: '.ag',
      filter: 'agTextColumnFilter',
      width: 150,
    },
    uom: {
      headerName: 'Basis',
      field: 'uom',
      colId: 'uom',
      filter: 'agTextColumnFilter',
      width: 150,
    },
    shipment_planned_qty: {
      headerName: 'Shipment Planned Qty',
      field: 'shipment_planned_qty',
      colId: 'shipment_planned_qty',
      filter: 'agTextColumnFilter',
      width: 150,
    },
    balance: {
      headerName: 'Balance',
      field: 'balance',
      colId: 'balance',
      filter: 'agTextColumnFilter',
      width: 150,
    },
    target_delivery_date: {
      headerName: 'Target Delivery Date',
      field: 'target_delivery_date',
      colId: 'target_delivery_date',
      columnType: 'Date',
      filter: false,
    },
    booking_qty: {
      headerName: 'Booking Qty',
      field: 'booking_qty',
      colId: 'booking_qty',
      columnType: 'Float',
      cellEditor: 'FloatEditor',
      filter: 'agTextColumnFilter',
      width: 150,
      pinned: 'right',
      lockPosition: true,
      cellStyle: (params) => {
        if (params?.node?.isSelected() && params.data.booking_qty <= 0)
          return {
            'background-color': '#ffffff',
            border: '1px solid #fa9d9b',
            // 'border-color': '#FFCCCB',
          };
        return {
          'background-color': '#ffffff',
          border: '0px solid #e2e2e2',
          // 'border-color': '#e2e2e2',
        };
      },
      editable: (params) => {
        return params.data.split_shipment_allowed;
      },
      valueSetter: (params) => {
        if (params.newValue <= params.data.order_qty - params.data.shipment_planned_qty) {
          params.data.booking_qty = Number(params.newValue?.toFixed(3));
        } else return false;
        return true;
      },
      valueGetter: (params) => {
        if (params.data.booking_qty) return Number(params.data.booking_qty);
        return 0.0;
      },
    },
  };

  const columns = [...Object.values(_pick(fields, columnNames))];

  const onSelectionChange = useCallback(
    (event: SelectionChangedEvent) => {
      if (onSelectionChanged) onSelectionChanged();
    },
    [onSelectionChanged]
  );

  return (
    <>
      <p style={{ fontWeight: 500 }}>{tableHeading}</p>
      <div
        id="myGrid"
        style={{
          height: 360,
          width: '100%',
        }}
        className="ag-theme-material  custom-filter-style"
      >
        <BaseTable
          reportName="product_grid_render"
          columns={columns}
          rowSelection="multiple"
          reportConfig={{
            rowHeight: 30,
            rowMultiSelectWithClick: true,
            defaultColDef: {
              resizable: true,
            },
            onCellValueChanged: onSelectionChange,
            components: {
              FloatEditor: FloatEditor,
            },
          }}
          gridRef={gridRef}
          rowData={rowData}
          onSelectionChanged={(params: any) => {
            onSelectionChange && onSelectionChange(params);
          }}
        />
      </div>
    </>
  );
});

export default ProductGrid;
