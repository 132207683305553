import React, { useState } from 'react';
import {
  Layout,
  Tabs,
  ActivityFeed,
  Row,
  Col,
  Typography,
  TabsProps,
  SelectStateInput,
  useCustomSiderContext,
  UploadedDocuments,
  SelectPipelineModal,
  getDateFromUnix,
} from '@shipmnts/pixel-hub';

import './ticket.css';
import { useSession } from 'common';
import { TicketValue } from 'service_management/models/Ticket';
const { Content } = Layout;
const { Title, Text } = Typography;

interface TicketDetailSummaryProps {
  ticketData: TicketValue;
  ticket_id: string;
}
const TicketDetailSummary = (props: TicketDetailSummaryProps) => {
  const { ticketData, ticket_id } = props;

  const session = useSession();
  const { collapsed, setCollapsed, refetchData } = useCustomSiderContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const labelStyle = {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '16px',
    color: '#586988',
  };
  const renderColumn = (label: string, value: string | number | undefined) => (
    <Col span={6}>
      <Text type="secondary" style={labelStyle}>
        {label}
      </Text>
      <div>
        <Text>{value ?? '-'}</Text>
      </div>
    </Col>
  );

  const items: TabsProps['items'] = [
    {
      key: 'activity',
      label: 'Activity Feed',
      children: (
        <div
          style={{
            width: '100%',
            marginInline: 'auto',
            marginBottom: '2em',
          }}
        >
          <ActivityFeed
            resource_id={ticket_id}
            resource_type={'ServiceManagement::Ticket::Ticket'}
            extraClassname={'tab'}
            resource={{ ...ticketData, displayName: ticketData?.ticket_number }}
          />
        </div>
      ),
    },
    {
      key: 'documents',
      label: 'Documents',
      children: (
        <div
          style={{
            width: '70%',
            marginInline: 'auto',
            marginBlock: '1em 2em',
          }}
        >
          <UploadedDocuments
            sessionData={session}
            parent_id={ticket_id}
            parent_type={'ticket'}
            docgen_url={process.env.DOCGEN_URL || ''}
          />
        </div>
      ),
    },
  ];

  return (
    <Content style={{ height: '100vh' }}>
      <div
        style={{
          backgroundColor: 'white',
          padding: '16px 24px',
          borderBottom: '1px solid rgba(0, 0, 0, 0.067)',
        }}
      >
        <Title level={4} style={{ color: '#545454', marginTop: '0px', marginBottom: '0px' }}>
          {ticketData?.title}
        </Title>
        <Row>
          <Col span={24}>
            {ticketData?.description && (
              <div
                className="ticket-description"
                dangerouslySetInnerHTML={{
                  __html: ticketData?.description,
                }}
              />
            )}
          </Col>
        </Row>
        <Row gutter={12} style={{ marginTop: '16px' }}>
          {renderColumn('ASSIGNEE', ticketData?.assigned_to && ticketData?.assigned_to?.name)}
          {renderColumn(
            'RAISED BY',
            ticketData?.raised_by_user &&
              `${ticketData?.raised_by_user?.name}, ${ticketData?.raised_by_company?.registered_name}`
          )}
          {/* {renderColumn('PIPELINE', ticketData?.pipeline?.name)} */}
          <Col span={6}>
            <Text type="secondary" style={labelStyle}>
              PIPELINE
            </Text>
            <div>
              <SelectPipelineModal
                pipeline_id={ticketData?.pipeline?.id}
                pipeline_name={ticketData?.pipeline?.name}
                doctype={'ServiceManagement::Ticket::Ticket'}
                resource_id={ticket_id}
                current_state_id={ticketData?.current_state?.id}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                onSuccess={refetchData}
              />
            </div>
          </Col>
          <Col span={6}>
            <Text type="secondary" style={labelStyle}>
              LINKED RECORDS
            </Text>
            <div>
              <Text
                className="trigger"
                onClick={() => {
                  if (collapsed) setCollapsed(false);
                }}
                style={{
                  cursor: 'pointer',
                  color: '#0A4FCA',
                }}
              >
                {ticketData?.references.length}
              </Text>
            </div>
          </Col>
          {/* {renderColumn('LINKED RECORDS', ticketData?.references.length)} */}
          {/* {renderColumn('PRIORITY', ticketData?.priority)} */}
          {renderColumn(
            'RESOLUTION BY',
            ticketData?.resolution_by_user &&
              `${ticketData?.resolution_by_user?.name}, ${ticketData?.resolution_by_company?.registered_name}`
          )}
          {/* {renderColumn('CURRENT STATUS', ticketData?.current_state?.name)} */}
          <Col span={6}>
            <Text type="secondary" style={labelStyle}>
              PIPELINE STATUS
            </Text>
            <div style={{ position: 'absolute', left: '-5px' }}>
              <SelectStateInput
                doctype={'ServiceManagement::Ticket::Ticket'}
                pipeline_id={ticketData?.pipeline?.id}
                current_state_id={ticketData?.current_state?.id}
                resource_id={ticket_id}
                onSuccess={refetchData}
              />
            </div>
          </Col>
          {renderColumn(
            'TARGET RESOLUTION DATE',
            ticketData?.target_resolution_date
              ? getDateFromUnix(ticketData?.target_resolution_date)
              : undefined
          )}
        </Row>
      </div>
      <Tabs
        defaultActiveKey="1"
        items={items}
        tabBarStyle={{ background: 'white', paddingInline: '24px' }}
      />
    </Content>
  );
};

export default TicketDetailSummary;
