import { TemplateValue } from 'network';
import { PerformAction } from '../models';
import { TemplateActions } from 'network';

interface TemplateActionType {
  template: TemplateValue;
}

export const disableTemplateRenderer = (payload: TemplateActionType): PerformAction => {
  const { template } = payload;

  return {
    actionParams: {
      template: template,
      payload: {
        is_disabled: true,
      },
      onSuccess: () => {
        // TODO: on success
      },
    },
    component: TemplateActions,
  };
};

export const enableTemplateRenderer = (payload: TemplateActionType): PerformAction => {
  const { template } = payload;

  return {
    actionParams: {
      template: template,
      payload: {
        is_disabled: false,
      },
      onSuccess: () => {
        // TODO: on success
      },
    },
    component: TemplateActions,
  };
};

export const setDefaultTemplateRenderer = (payload: TemplateActionType): PerformAction => {
  const { template } = payload;

  return {
    actionParams: {
      template: template,
      payload: {
        is_default: true,
      },
      onSuccess: () => {
        // TODO: on success
      },
    },
    component: TemplateActions,
  };
};
