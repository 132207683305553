import { useLazyQuery } from '@apollo/client';
import { Alert, Button, Modal } from '@shipmnts/pixel-hub';
import React, { useEffect, useState } from 'react';
import { GET_INQUIRY_OPTION_LINKED_RESOURCE } from 'sales_hub/graphql/inquiry';
import { ExclamationCircleOutlined } from '@shipmnts/pixel-hub';
import { InquiryOptionValue } from 'operations/models/InquiryOption';
import { INQUIRY_OPTION_WON_STATUS } from 'sales_hub/utils/constants';
import { INQUIRY_WON_STATUS } from 'sales_hub/models/inquiry';
import { Link_map } from 'common';

interface InquiryLinkedResourcesData {
  id: string;
  inquiry_option_id: string;
  quotation_number: string;
  type: string;
  resource_number: string;
}

function InquiryLinkedResources(props: any) {
  const { inquiry, visible, ChildrenComponent, onClose } = props;
  const [open, setOpen] = useState(visible || false);
  const [getLinkedResources, { data }] = useLazyQuery(GET_INQUIRY_OPTION_LINKED_RESOURCE, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    const ids =
      inquiry?.inquiry_options
        ?.filter((io: InquiryOptionValue) => io.status === INQUIRY_OPTION_WON_STATUS)
        ?.map((io: InquiryOptionValue) => io.id) || [];
    if (visible && ids && inquiry.last_action_status === INQUIRY_WON_STATUS)
      getLinkedResources({
        variables: { ids: ids },
      });
  }, [getLinkedResources, inquiry, visible]);

  if (
    inquiry.last_action_status !== INQUIRY_WON_STATUS ||
    (data?.get_inquiry_option_linked_resources?.length === 0 && visible)
  )
    return <ChildrenComponent {...props} />;

  if (data?.get_inquiry_option_linked_resources?.length > 0 && visible)
    return (
      <Modal
        title={
          <p style={{ display: 'flex', alignItems: 'center' }}>
            <span className="color-red" style={{ fontSize: 24 }}>
              <ExclamationCircleOutlined />
            </span>
            <span style={{ marginLeft: '20px' }}>Quotation Linked Order/Shipment</span>
          </p>
        }
        open={open}
        width={500}
        closable={false}
        footer={
          <Button
            onClick={() => {
              setOpen(false);
              if (onClose) onClose();
            }}
          >
            Dismiss
          </Button>
        }
      >
        <Alert
          description="The following active Order/Shipment are linked to Quotations from the Inquiry. Kindly de-link the quotation or cancel the Order/Shipment."
          type="error"
        />
        <br />

        <ul>
          {data?.get_inquiry_option_linked_resources
            ?.slice(0, 4)
            ?.map((item: InquiryLinkedResourcesData) => (
              <li key={item.id}>
                <a href={Link_map(item.type, item.id) || ''} target="_blank" rel="noreferrer">
                  {item.resource_number}
                </a>
              </li>
            ))}

          {data?.get_inquiry_option_linked_resources?.length > 4 && (
            <li>+{data.get_inquiry_option_linked_resources.length - 4} more</li>
          )}
        </ul>
      </Modal>
    );

  return <></>;
}

export default InquiryLinkedResources;
