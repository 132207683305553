import React from 'react';
import { NEGATIVE_TYPE, SECONDARY_TYPE } from '../constants';
import { Action } from '../models';
import { EyeOutlined, FilePdfOutlined, DeleteOutlined } from '@shipmnts/pixel-hub';
import {
  performDocumentPreviewAction,
  performDocumentPdfAction,
  performDocumentDeleteAction,
} from './generatedDocumentActionRenderer';

const getDocumentPrintPreview = (): Action => {
  return {
    key: 'print_preview',
    type: SECONDARY_TYPE,
    displayComponent: 'Open Preview',
    icon: <EyeOutlined />,
    performAction: performDocumentPreviewAction,
    isEnable: true,
    description: 'See the preview of the generated document',
  };
};

const getDocumentPdf = (): Action => {
  return {
    key: 'pdf',
    type: SECONDARY_TYPE,
    displayComponent: 'PDF',
    icon: <FilePdfOutlined />,
    performAction: performDocumentPdfAction,
    isEnable: true,
    description: 'get the PDF of the generated document',
  };
};

const deleteDocument = (): Action => {
  return {
    key: 'delete',
    type: NEGATIVE_TYPE,
    displayComponent: 'Delete',
    icon: <DeleteOutlined />,
    performAction: performDocumentDeleteAction,
    isEnable: true,
    description: 'delete a generated document',
  };
};

export const getGeneratedDocumentActions = () => {
  return [getDocumentPrintPreview(), getDocumentPdf(), deleteDocument()];
};
