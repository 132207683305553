import React, { useState } from 'react';

import { Card } from 'common';
import { isCustomer } from 'common';
import { WithPermission } from '@shipmnts/pixel-hub';
import { Button, Form } from '@shipmnts/pixel-hub';
import { useCompanyView } from '../CompanyView';
import { PERMISSION_CUSTOMER_CREATE_EDIT, PERMISSION_CUSTOMER_VIEW } from 'network/permissions';
import { SectionEditorModal } from '../SectionEditorModal';
import CustomerLedgerDetailsForm from 'network/components/CustomerLedgerDetailsForm';
import { getFormInitialValues } from 'network/components/companyDetailView/CustomerLedgerDetails';

export const CustomerLedgerCard = ({
  loading,
  erpnextConfigData,
}: {
  loading?: boolean;
  erpnextConfigData?: any;
}) => {
  const [editMode, setEditMode] = useState(false);
  const { company, accounts_data } = useCompanyView();
  const data = {
    'Tax Receivables Category': accounts_data?.receivable_tax_withholding_category || '-',
    'Billing Currency': accounts_data?.default_currency || '-',
    MSME: accounts_data?.is_msme ? 'Yes' : 'No',
  };

  const is_customer = isCustomer(company?.company_type);

  return (
    <WithPermission permission={{ name: PERMISSION_CUSTOMER_VIEW, docType: 'Network::Company' }}>
      {is_customer ? (
        <Card
          header={{ title: 'Customer Ledger' }}
          loading={loading}
          style={{ width: '33%' }}
          Action={
            <WithPermission
              permission={{ name: PERMISSION_CUSTOMER_CREATE_EDIT, docType: 'Network::Company' }}
            >
              <Button
                disabled={company?.is_disabled}
                onClick={() => !!!company?.is_disabled && setEditMode(true)}
                size="small"
              >
                {'Edit'}
              </Button>
            </WithPermission>
          }
        >
          <>
            <div
              style={{
                display: 'grid',
                width: '100%',
                marginTop: '2em',
              }}
            >
              {Object.entries(data)
                .filter(([_, value]) => value)
                .map(([label, value]) => {
                  return (
                    <div
                      key={label}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '1em',
                        alignItems: 'center',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: '800px',
                          opacity: '.6',
                          width: '50%',
                        }}
                      >
                        {label}
                      </span>
                      <span
                        style={{
                          width: '40%',
                        }}
                      >
                        {value}
                      </span>
                    </div>
                  );
                })}
            </div>
            {accounts_data?.receivable_accounts &&
              accounts_data?.receivable_accounts?.length > 0 && (
                <>
                  <div
                    style={{
                      fontSize: '12px',
                      fontWeight: '600',
                      marginBlock: '1em .5em',
                      opacity: 0.6,
                    }}
                  >
                    Non-standard receivable accounts
                  </div>
                  <div
                    style={{
                      display: 'grid',
                      width: '100%',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        gap: '10%',
                        marginBottom: '.25em',
                        alignItems: 'center',
                      }}
                    >
                      <span
                        style={{
                          display: 'block',
                          opacity: 0.6,
                          fontSize: '12px',
                          fontWeight: '600',
                          width: '45%',
                        }}
                      >
                        Company
                      </span>
                      <span
                        style={{
                          display: 'block',
                          opacity: 0.6,
                          fontSize: '12px',
                          fontWeight: '600',
                          width: '45%',
                        }}
                      >
                        Account
                      </span>
                    </div>
                    {accounts_data?.receivable_accounts?.map((account) => {
                      return (
                        <div
                          key={account?.company}
                          style={{
                            display: 'flex',
                            gap: '10%',
                            marginBottom: '1em',
                            alignItems: 'center',
                          }}
                        >
                          <span
                            style={{
                              fontWeight: '800px',
                              opacity: '.6',
                              width: '45%',
                            }}
                          >
                            {account?.company}
                          </span>
                          <span
                            style={{
                              width: '45%',
                            }}
                          >
                            {account?.account}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
          </>
        </Card>
      ) : (
        <WithPermission
          permission={{ name: PERMISSION_CUSTOMER_CREATE_EDIT, docType: 'Network::Company' }}
        >
          <Card
            header={{ title: 'Customer Ledger' }}
            loading={loading}
            style={{ width: '33%' }}
            Action={<></>}
          >
            <div style={{ marginInline: 'auto', textAlign: 'center' }}>
              <Button
                size="small"
                disabled={company?.is_disabled}
                style={{ marginBlock: '2em 1em' }}
                onClick={() => setEditMode(true)}
              >
                {'Create Ledger'}
              </Button>
            </div>
          </Card>
        </WithPermission>
      )}
      {editMode && company && (
        <SectionEditorModal
          title="Customer Ledger"
          show={editMode}
          setShow={setEditMode}
          initialValues={getFormInitialValues(company, accounts_data)}
        >
          {({ form }) => {
            form.setFieldsValue({
              id: company.id,
              is_customer: true,
              is_lead: true,
            });
            return (
              <>
                <Form.Item noStyle name="is_customer">
                  <span />
                </Form.Item>
                <CustomerLedgerDetailsForm
                  company={company}
                  erpnextConfigData={erpnextConfigData}
                />
              </>
            );
          }}
        </SectionEditorModal>
      )}
    </WithPermission>
  );
};
