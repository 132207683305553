/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree';
import UserContact from 'common/models/UserContact';

const SalesPerson = types.model({
  id: types.identifier,
  name: types.string,
  employee: types.maybe(types.maybeNull(types.string)),
  enable: types.maybe(types.maybeNull(types.boolean)),
  user_contact: types.maybe(types.maybeNull(UserContact)),
});

export interface SalesPersonValue extends Instance<typeof SalesPerson> {}
export interface SalesPersonIn extends SnapshotIn<typeof SalesPerson> {}
export interface SalesPersonOut extends SnapshotOut<typeof SalesPerson> {}

export default SalesPerson;
