import React, { forwardRef, useEffect, useState } from 'react';
import { ApplicationPropsType } from '../../../types';
import { Button, message, Tag, getDateTimeFromUnix } from '@shipmnts/pixel-hub';
import { useTrackerContext } from '../../../TrackingContext';
import { useQuery, useMutation } from '@apollo/client';
import { Alert, TrackingAppCredits } from '@shipmnts/pixel-hub';
import { canTrack } from 'common/components/Tracking/trackingHelper';
import { SyncOutlined } from '@shipmnts/pixel-hub';
import {
  INDIAN_CUSTOMS_TRACKING_REQUEST_STATUS_ACTIVE,
  INDIAN_CUSTOMS_TRACKING_REQUEST_STATUS_ACTIVE_AFTER_LEO_DATE,
  INDIAN_CUSTOMS_TRACKING_REQUEST_STATUS_INACTIVE,
  INDIAN_CUSTOMS_TRACKING_REQUEST_STATUS_DISABLED,
  INDIAN_CUSTOMS_TRACKING_REQUEST_STATUS_ERROR,
  INDIAN_CUSTOMS_TRACKING_REQUEST_STATUS_NOT_APPLICABLE,
} from '../../../constants';

import {
  GET_INDIAN_IGM_TRACKING_REQUEST,
  REFRESH_IMG_TRACKING,
} from 'operations/graphql/shipmentManifest';
import { START_INDIAN_CUSTOMS_TRACKING } from 'common/components/Tracking/graphql/queries';
import Spinner from 'src/components/Spinner';

const getStatusAndColor: { [key: string]: { color: string; status: string } } = {
  [INDIAN_CUSTOMS_TRACKING_REQUEST_STATUS_ACTIVE]: { color: 'blue', status: 'Active' },
  [INDIAN_CUSTOMS_TRACKING_REQUEST_STATUS_ACTIVE_AFTER_LEO_DATE]: {
    color: 'blue',
    status: 'Active',
  },
  [INDIAN_CUSTOMS_TRACKING_REQUEST_STATUS_INACTIVE]: { color: 'green', status: 'Completed' },
  [INDIAN_CUSTOMS_TRACKING_REQUEST_STATUS_ERROR]: { color: 'red', status: 'Error' },
  [INDIAN_CUSTOMS_TRACKING_REQUEST_STATUS_NOT_APPLICABLE]: {
    color: 'yellow',
    status: 'Not Applicable',
  },
  [INDIAN_CUSTOMS_TRACKING_REQUEST_STATUS_DISABLED]: { color: 'red', status: 'Disabled' },
  '': { color: 'yellow', status: 'Not Applicable' },
};

const IndianCustomsIgmTracker = forwardRef<HTMLDivElement, ApplicationPropsType>(
  ({ container, app, subscription }, ref) => {
    const { id } = useTrackerContext();
    const subscriptionData = JSON.parse(subscription.subscription_data);
    const [startTrack, { data: startTrackingData, error: startTrackingError }] = useMutation(
      START_INDIAN_CUSTOMS_TRACKING
    );

    const [refreshTrack, { data: refreshTrackingData, loading: refreshTrackingLoading }] =
      useMutation(REFRESH_IMG_TRACKING);

    const { data, loading, error } = useQuery(GET_INDIAN_IGM_TRACKING_REQUEST, {
      variables: {
        shipment_manifest_id: id,
      },
    });
    const [isTrackingStarted, setIsTrackingStarted] = useState<boolean>(false);
    const startTracking = () => {
      startTrack({ variables: { reference_id: id, reference_type: 'Shipment::Manifest' } });
    };

    useEffect(() => {
      if (refreshTrackingData?.indian_custom_igm_tracker_refresh_tracking) {
        setIsTrackingStarted(true);
        message.success('IGM tracking successfully updated');
      }
    }, [refreshTrackingData]);

    useEffect(() => {
      if (startTrackingData?.indian_custom_tracker_start_tracking) {
        setIsTrackingStarted(true);
        message.success('IGM tracking successfully updated');
      }
    }, [startTrackingError, startTrackingData]);

    const autoTrackingOff = canTrack(subscription) && !subscriptionData['auto_tracking'];

    if (loading) {
      return (
        <div className="activity-box" ref={ref}>
          <div className="title" style={{ fontWeight: 600 }}>
            {app?.label}
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '10px',
            }}
          >
            <Spinner></Spinner>
          </div>
        </div>
      );
    }
    if (error) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '10px',
          }}
        >
          Error occured while fetching data
        </div>
      );
    }
    const tracking_request = data?.indian_customs_igm_tracking_request_by_manifest_id;

    const retry_count = tracking_request?.last_event?.retry_count
      ? tracking_request.last_event.retry_count
      : 0;
    const date = tracking_request?.last_tracked_at
      ? getDateTimeFromUnix(tracking_request?.last_tracked_at)
      : '-';

    const statusColor = getStatusAndColor[tracking_request?.status || ''].color;
    const status = getStatusAndColor[tracking_request?.status || ''].status;

    const refreshTracking = () => {
      refreshTrack({ variables: { tracking_request_id: tracking_request?.id } });
    };

    if (container?.country === 'IN' && date === '-') {
      return (
        <>
          <div className="activity-box" ref={ref}>
            <div className="title" style={{ fontWeight: 600 }}>
              {app?.label}
              {autoTrackingOff && !isTrackingStarted && (
                <Button onClick={startTracking} style={{ marginLeft: '15px' }}>
                  Start Tracking
                </Button>
              )}
            </div>
            <div style={{ fontWeight: 500, fontSize: '14px', margin: '10px 0px' }}>
              <TrackingAppCredits subscription={subscription} />
              {isTrackingStarted ? (
                <Tag color="green">
                  Tracking has started, will be visible once we receive updates from icegate.
                </Tag>
              ) : (
                <>
                  {autoTrackingOff && (
                    <>
                      {startTrackingError ? (
                        <Tag color="error">{startTrackingError?.message}</Tag>
                      ) : (
                        <Tag color="error">
                          Tracking has not started, Please click on Start Tracking.
                        </Tag>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      );
    }

    return (
      <div className="activity-box" ref={ref}>
        <div className="title" style={{ fontWeight: 600 }}>
          {app?.label}
          {
            <span style={{ marginLeft: '25px' }}>
              <Tag color={statusColor}>{status}</Tag>
            </span>
          }
        </div>
        <TrackingAppCredits subscription={subscription} />
        <div className="field-name" style={{ marginTop: '8px' }}>
          {date && <>Last Tracked at: {date}</>}
          {status === 'Active' && (
            <Button
              size="small"
              onClick={refreshTracking}
              style={{ marginLeft: '15px' }}
              loading={refreshTrackingLoading}
              icon={<SyncOutlined />}
            >
              Refresh
            </Button>
          )}
        </div>
        <div className="field-name" style={{ marginTop: '8px' }}>
          {retry_count > 0 && status === 'Active' && !isTrackingStarted && (
            <Alert
              message={`The last attempt to track with Indian Customs was not successful. 
                We will keep attempting to get the updated status.`}
              type="warning"
              showIcon
            />
          )}
          {status === 'Error' && (
            <Alert
              message={`The last five attempts to track with Indian Customs were not successful. 
            We have stopped tracking this activity now. Kindly update the details and try again.`}
              type="error"
              showIcon
            />
          )}
        </div>
      </div>
    );
  }
);

IndianCustomsIgmTracker.displayName = 'IndianCustomsIgmTracker';
export default IndianCustomsIgmTracker;
