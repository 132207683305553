import React, { useState, useEffect } from 'react';
import { RowNode } from '@ag-grid-community/core';
import { useMutation } from '@apollo/client';
import { List, Modal } from '@shipmnts/pixel-hub';
import ShipmentActionDrawer, {
  actionTypeForShipment,
  PropsForShipmentAction,
} from './ShipmentActionDrawer';
import { VALIDATE_CONSOL_FROM_HOUSES } from 'operations/modules/reports/graphql/shipmentReport';
import { ShipmentValue } from 'operations/models/Shipment';
import _uniq from 'lodash/uniq';
import { FREIGHT_TYPE_AIR, FREIGHT_TYPE_OCEAN } from 'operations/modules/shipment/constants';

export const showErrorMessages = (errorMessages: string[], title: string) => {
  Modal.error({
    title,
    content: (
      <List
        size="small"
        style={{ marginTop: '20px' }}
        bordered
        dataSource={errorMessages}
        renderItem={(item: string) => <List.Item>{item}</List.Item>}
      />
    ),
    width: 500,
  });
};

export const ValidateShipments = (rows: any[]): string[] => {
  const errors: string[] = [];
  const ShipmentLoadTypes = _uniq((rows || []).map((data) => data.load_type));
  if (!rows || rows.length === 0) errors.push('Please select rows first');

  if ((rows || []).filter((row) => row?.shipment_type !== 'house').length > 0)
    errors.push('All Selected shipments are not house shipment. Please select independent houses.');

  if (ShipmentLoadTypes.length > 1)
    errors.push(
      `Selected houses's load type is different. Please select ${rows[0]?.load_type} house shipments.`
    );

  return errors;
};

const CreateConsolFromHouses = React.memo(function CreateConsolFromHouses(props: {
  selectedNodes: RowNode[];
  selectedNodeCounts: number;
  onClose: () => void;
  onSuccess: () => void;
  consolType: string;
}): JSX.Element {
  const { selectedNodes, selectedNodeCounts, consolType, onSuccess } = props;
  const [activeAction, setActiveAction] = useState<actionTypeForShipment>();
  const [propsForActiveAction, setPropsForActiveAction] = useState<PropsForShipmentAction>();
  const selectedData = (selectedNodes || []).map((node) => node.data);
  const freight_type = selectedData.length > 0 ? selectedData[0]?.freight_type : null;
  const load_type = selectedData.length > 0 ? selectedData[0]?.load_type : null;
  const [validateConsolFromHouses, { data, loading, error }] = useMutation(
    VALIDATE_CONSOL_FROM_HOUSES
  );

  useEffect(() => {
    if (data?.validate_consol_from_houses && !error) {
      if (data?.validate_consol_from_houses?.messages?.length > 0) {
        Modal.error({
          title: 'Cannot create consol from selected houses',
          content: (
            <List
              size="small"
              style={{ marginTop: '20px' }}
              bordered
              dataSource={data?.validate_consol_from_houses?.messages}
              renderItem={(item: string) => <List.Item>{item}</List.Item>}
            />
          ),
          width: 500,
        });
        setPropsForActiveAction(undefined);
      } else {
        if (
          freight_type === FREIGHT_TYPE_AIR &&
          data?.validate_consol_from_houses?.house_shipments.length > 0
        ) {
          const house_shipment_ids = (data?.validate_consol_from_houses?.house_shipments || [])
            .map((shp: any) => shp.id)
            .join(',');
          const create_air_consol_link = `${process.env.SHIPMNTS_WEB_URL}/shipment/create/from_air_house_shipments?shipment_ids=${house_shipment_ids}&&consol_type=${propsForActiveAction?.consol_type}`;
          window.open(create_air_consol_link, '_self');
        } else {
          const houseShipmentPol =
            data?.validate_consol_from_houses?.house_shipments[0]?.port_of_loading;
          const port_of_loading = {
            ...houseShipmentPol,
            city: houseShipmentPol?.city?.name,
            city_id: houseShipmentPol?.city?.id,
            city_code: houseShipmentPol?.city?.code,
            state: houseShipmentPol?.city?.state_name,
            state_code: houseShipmentPol?.city?.state_code,
            country: houseShipmentPol?.country?.name,
            country_code: houseShipmentPol?.country?.code,
          };

          setPropsForActiveAction((state) => ({
            ...state,
            house_shipments: data?.validate_consol_from_houses?.house_shipments.map(
              (hs: ShipmentValue) => ({
                ...hs,
                load_type: load_type,
              })
            ),
            port_of_loading,
          }));
          setActiveAction('create_consol_shipment');
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, loading]);

  useEffect(() => {
    const default_errors: string[] = ValidateShipments(selectedData);
    if (default_errors.length > 0) {
      showErrorMessages(default_errors, 'Cannot create consol from selected shipments');
    } else {
      const variables = {
        consol_type: consolType,
        house_shipment_ids: selectedData?.map((data) => data?.id),
      };
      setPropsForActiveAction(variables);
      validateConsolFromHouses({ variables });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNodeCounts]);

  return (
    <>
      {freight_type === FREIGHT_TYPE_OCEAN && (
        <ShipmentActionDrawer
          activeAction={activeAction as actionTypeForShipment}
          setActiveAction={setActiveAction}
          propsForActiveAction={propsForActiveAction}
          refetchReport={onSuccess}
        />
      )}
    </>
  );
});

export default CreateConsolFromHouses;
