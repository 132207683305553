import { gql } from '@apollo/client';

export const templateFields = gql`
  fragment templateFields on TemplateObjectType {
    id
    name
    template_type
    content
    is_default
    template_type
    print_option {
      field_order
      fields {
        field_name
        field_type
        label
        description
        default
      }
    }
  }
`;

export const templatePdfDocumentFields = gql`
  fragment templatePdfDocumentFields on TemplatePdfObjectType {
    document_identifier
    document_url
    file_size
    content_type
    file_name
  }
`;

export const SEARCH_TEMPLATE = gql`
  query search_template(
    $name: String
    $template_types: [String!]
    $resource_type: String
    $is_default: Boolean
    $is_disabled: Boolean
    $is_standard: Boolean
    $type: String
  ) {
    search_template(
      name: $name
      template_types: $template_types
      is_disabled: $is_disabled
      resource_type: $resource_type
      is_default: $is_default
      is_standard: $is_standard
      type: $type
    ) {
      ...templateFields
    }
  }
  ${templateFields}
`;

export const GET_PDF_FROM_TEMPLATE = gql`
  query get_pdf_from_template(
    $template_id: ID!
    $resource_id: ID!
    $letter_head_id: ID
    $context: String
  ) {
    get_pdf_from_template(
      template_id: $template_id
      letter_head_id: $letter_head_id
      context: $context
      resource_id: $resource_id
    ) {
      ...templatePdfDocumentFields
    }
  }
  ${templatePdfDocumentFields}
`;

export const GET_HTML_FROM_TEMPLATE = gql`
  query get_html_from_template(
    $template_id: ID!
    $resource_id: ID!
    $letter_head_id: ID
    $context: String
  ) {
    get_html_from_template(
      template_id: $template_id
      letter_head_id: $letter_head_id
      context: $context
      resource_id: $resource_id
    ) {
      message
    }
  }
`;
