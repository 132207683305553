import React from 'react';
import {
  FilePdfOutlined,
  TagsOutlined,
  CopyOutlined,
  MailOutlined,
  ShareAltOutlined,
} from '@shipmnts/pixel-hub';
import {
  addCollaboratorsRenderer,
  cancelProductOrderRenderer,
  delinkOrderRenderer,
  performCreateShipmentAction,
  performEmailOrder,
  performPrintOrder,
} from './OMSActionRenderer';
import { NEGATIVE_TYPE, PRIMARY_TYPE, SECONDARY_TYPE } from '../../actionHelper/constants';

import { Action } from '../../actionHelper/models';
import { SessionDataValue } from 'operations/models/SessionData';
import DuplicateProductOrder from '../DuplicateProductOrder';
import { CustomIcon } from '@shipmnts/pixel-hub';
import { ProductOrderValue } from 'operations/models/ProductOrder';
import { ShipmentValue } from 'operations/models/Shipment';

interface ShipmentActionProps {
  spoi_id?: string;
  parent_id?: string;
  shipment?: ShipmentValue;
  product_order?: ProductOrderValue;
}

const createShipment = (productOrder: ProductOrderValue): Action => {
  const isEnable = !!productOrder.product_order_items?.find(
    (item: any) => item.order_qty > item.shipment_planned_qty
  );
  return {
    key: 'create_shipment',
    type: PRIMARY_TYPE,
    displayComponent: 'Create Shipment',
    icon: <TagsOutlined />,
    isEnable,
    performAction: performCreateShipmentAction,
    description: 'Create Shipment',
  };
};

const printOrder = (props?: ShipmentActionProps): Action => {
  return {
    key: 'print_order',
    type: SECONDARY_TYPE,
    displayComponent: 'Share',
    isEnable: true,
    icon: <FilePdfOutlined />,
    extraProps: props,
    performAction: performPrintOrder,
    description: 'Share Order',
  };
};

const emailOrder = (): Action => {
  return {
    key: 'email_order',
    type: SECONDARY_TYPE,
    displayComponent: 'Notify via Email',
    isEnable: true,
    icon: <MailOutlined />,
    performAction: performEmailOrder,
    description: 'Email Order',
  };
};

const duplicateOrder = (): Action => {
  return {
    key: 'duplicate_product_order',
    type: SECONDARY_TYPE,
    displayComponent: 'Duplicate Order',
    icon: <CopyOutlined />,
    isEnable: true,
    performAction: DuplicateProductOrder,
    description: 'duplicate_product_order',
  };
};

export const cancelProductOrder = (productOrder: any): Action => {
  return {
    type: NEGATIVE_TYPE,
    key: 'cancel_shipment',
    icon: <CustomIcon icon="FileTimesIcon" />,
    displayComponent: 'Cancel Order',
    isEnable:
      productOrder.total_shipment_planned_qty !== 0 || productOrder.status === 'cancelled'
        ? false
        : true,
    performAction: cancelProductOrderRenderer,
  };
};

export const delinkOrder = (props: ShipmentActionProps): Action => {
  return {
    type: NEGATIVE_TYPE,
    key: 'delink_order',
    icon: <CustomIcon icon="FileTimesIcon" />,
    displayComponent: 'Delete Product',
    isEnable: !props.shipment?.shipment_invoices?.length,
    extraProps: props,
    performAction: delinkOrderRenderer,
  };
};

export const addCollaborators = (productOrder: any): Action => {
  return {
    type: PRIMARY_TYPE,
    key: 'add_collaborators',
    icon: <ShareAltOutlined />,
    displayComponent: 'Add Collaborators',
    isEnable: productOrder.status !== 'cancelled',
    performAction: addCollaboratorsRenderer,
  };
};

export const getShipmentItemActions = (props: any) => {
  return [printOrder(props), delinkOrder(props)];
};

export const getProductOrderActions = (
  productOrder: ProductOrderValue,
  sessionData: SessionDataValue
) => {
  return [
    createShipment(productOrder),
    printOrder({ product_order: productOrder }),
    emailOrder(),
    duplicateOrder(),
    cancelProductOrder(productOrder),
  ];
};
