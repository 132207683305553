import { Drawer, DrawerFooter, FormTable } from '@shipmnts/pixel-hub';
import { Column } from 'common/models/Report';
import React, { useRef } from 'react';
import { GridOptions } from '@ag-grid-community/core';
import { omit } from 'lodash';
interface GetProductFromShipmentProps {
  linkedItems?: any;
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
  setSelectedProductItems?: (productItems: any[]) => void;
  orderCurrency?: string;
  type: string;
}
export default function GetProductFromShipmentDrawer(props: GetProductFromShipmentProps) {
  const { isVisible, setIsVisible, linkedItems, setSelectedProductItems, orderCurrency, type } =
    props;
  const gridRef = useRef<GridOptions>();

  const productOrderItems = (linkedItems || []).map((spoi: any) => {
    // product_cod, hs_code, basis

    return {
      ...spoi,
      product_name: spoi?.product_name,
      product_description: spoi?.product_description,
      buyer_product_code: spoi?.buyer_product_code,
      order_number:
        spoi?.linked_to?.product_order?.sales_order_number ||
        spoi?.product_order?.sales_order_number,
      uom: spoi?.uom,
      total_quantity:
        type === 'commercial_invoice'
          ? spoi?.shipment_planned_qty
          : type === 'shipment'
          ? spoi?.order_qty
          : 0,
      // invoiced_qty: spoi?.invoiced_qty,
      // selected_quantity: spoi.invoiced_qty,
      pending_quantity:
        type === 'commercial_invoice'
          ? (spoi?.shipment_planned_qty || 0) - (spoi?.invoice_qty || 0)
          : type === 'shipment'
          ? (spoi?.order_qty || 0) -
            (spoi?.existing_shipment_planned_qty ||
              spoi?.linked_to?.shipment_planned_qty - spoi?.shipment_planned_qty ||
              0)
          : 0,
      amount:
        type === 'commercial_invoice'
          ? (spoi?.unit_rate || 0) * (spoi?.shipment_planned_qty || 0)
          : type === 'shipment'
          ? (spoi?.unit_rate || 0) * (spoi?.order_qty || 0)
          : 0,
      unit_rate: spoi?.unit_rate,
      linked_to_id:
        type === 'commercial_invoice'
          ? Number(spoi?.id)
          : type === 'shipment'
          ? spoi?.linked_to_id
          : null,
    };
  });

  const columnDefs: Column[] = [
    {
      headerName: 'Product Name',
      field: 'product_name',
      colId: 'product_name',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      minWidth: 80,
      editable: false,
      cellStyle: { 'background-color': '#f2f2f2' },
    },
    {
      headerName: 'Product Description',
      field: 'product_description',
      colId: 'product_description',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      minWidth: 80,
      editable: false,
      cellStyle: { 'background-color': '#f2f2f2' },
    },
    {
      headerName: 'Buyer Product Code',
      field: 'buyer_product_code',
      colId: 'buyer_product_code',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      minWidth: 80,
      editable: false,
      cellStyle: { 'background-color': '#f2f2f2' },
    },
    {
      headerName: 'Order #',
      field: 'order_number',
      colId: 'cc_emails',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      minWidth: 80,
      editable: false,
      cellStyle: { 'background-color': '#f2f2f2' },
    },
    {
      headerName: 'Basis',
      field: 'uom',
      colId: 'uom',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      minWidth: 80,
      editable: false,
      cellStyle: { 'background-color': '#f2f2f2' },
    },
    {
      headerName: 'Total Quantity',
      field: 'total_quantity',
      colId: 'total_quantity',
      columnType: 'Float',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      minWidth: 80,
      editable: false,
      cellStyle: { 'background-color': '#f2f2f2' },
    },
    // {
    //   headerName: 'Invoiced Qty',
    //   field: 'invoiced_qty',
    //   colId: 'invoiced_qty',
    //   lockPosition: true,
    //   suppressMovable: true,
    //   suppressNavigable: true,
    //   minWidth: 80,
    //   editable: false,
    //   cellStyle: { 'background-color': '#f2f2f2' },
    // },
    {
      headerName: 'Pending Qty',
      field: 'pending_quantity',
      colId: 'pending_quantity',
      columnType: 'Float',
      lockPosition: true,
      pinned: 'right',
      suppressMovable: true,
      suppressNavigable: true,
      minWidth: 80,
      editable: false,
      cellStyle: { 'background-color': '#f2f2f2' },
    },
    {
      headerName: `Sell Rate(${orderCurrency || 'Cur'})`,
      field: 'unit_rate',
      colId: 'unit_rate',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      minWidth: 80,
      editable: false,
      cellStyle: { 'background-color': '#f2f2f2' },
      cellRendererSelector: (params) => {
        return {
          component: 'NumberTypeRenderer',
          params: {
            precision: 2,
          },
        };
      },
    },
    {
      headerName: `Sell Amount(${orderCurrency || 'Cur'})`,
      field: 'amount',
      colId: 'amount',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      minWidth: 80,
      editable: false,
      cellStyle: { 'background-color': '#f2f2f2' },
      cellRendererSelector: (params) => {
        return {
          component: 'NumberTypeRenderer',
          params: {
            precision: 2,
          },
        };
      },
    },
    {
      headerName: 'Quantity',
      field: 'selected_quantity',
      pinned: 'right',
      colId: 'selected_quantity',
      cellEditor: 'FloatEditor',
      columnType: 'Float',
      valueSetter: (params) => {
        if (params.newValue <= params.data.pending_quantity) {
          params.data.selected_quantity = Number(params.newValue) || 0;
        }
        return true;
      },
      valueGetter: (params) => {
        return params.data.selected_quantity;
      },
      editable: (o) => !o.node.isRowPinned(),
      minWidth: 80,
    },
  ];

  const onSaveDrawer = () => {
    const selectedNodes: any[] = [];
    gridRef?.current?.api?.forEachNode((node) => {
      const qty = node?.data?.selected_quantity;
      // const orderQty = node?.data?.order_qty;
      const data = omit(node.data, [
        'product_order',
        'order_number',
        'total_quantity',
        'pending_quantity',
        'selected_quantity',
        'id',
      ]);

      if (node.data.selected_quantity > 0) {
        selectedNodes.push({
          ...data,
          product_order_item_id: node?.data?.id,
          invoice_qty: type === 'commercial_invoice' ? qty : data.invoice_qty,
          shipment_planned_qty: type === 'shipment' ? qty : data.shipment_planned_qty,
          // order_qty: orderQty,
          amount: Number(qty) * Number(data.unit_rate),
        });
      }
    });
    setSelectedProductItems && setSelectedProductItems(selectedNodes);
    setIsVisible(false);
  };

  return (
    <Drawer
      open={isVisible}
      onClose={() => setIsVisible(false)}
      title="Add Product From Shipment"
      width={1000}
      footer={
        <DrawerFooter
          saveText="Add Product Items"
          onSave={onSaveDrawer}
          onClose={() => setIsVisible(false)}
        />
      }
    >
      <FormTable
        reportName="add_product_from_shipment"
        columns={columnDefs}
        rowData={productOrderItems}
        reportConfig={{
          rowHeight: 40,
          stopEditingWhenCellsLoseFocus: true,
        }}
        gridRef={gridRef}
      />
    </Drawer>
  );
}
