import React, { useEffect, useRef, useState } from 'react';

import { useParams, Link } from 'wouter';
import { useQuery } from '@apollo/client';
import {
  AppHelmet,
  Button,
  Layout,
  Skeleton,
  Tabs,
  Result,
  Divider,
  TabsProps,
} from '@shipmnts/pixel-hub';
import ProductsTab from './Tabs/ProductsTab';
import WMSDetailHeader from './WMSDetailHeader';
import WMSSummary from './WMSSummary';
import { FETCH_WMS_TRANSACTION } from './graphql/warehouseTransaction';
import LinkedTransactionTab from './Tabs/LinkedTransactionTab';
import { TRANSACTION_TYPE_GDN } from 'operations/models/WarehouseTransaction';
import DocumentTab from './Tabs/DocumentTab';
const { Content } = Layout;

const TabComponentMap = new Map();
TabComponentMap.set('products', ProductsTab);
TabComponentMap.set('linked_transactions', LinkedTransactionTab);
TabComponentMap.set('documents', DocumentTab);

export type WMSTabType = 'products' | 'linked_transactions' | 'documents' | undefined;

const WMSDetailLayoutWrapper: React.FC = (props: any) => {
  const params = useParams<{ id: string }>();
  const { id } = params;

  return <WMSDetailLayout id={id || props.id} {...props}></WMSDetailLayout>;
};

interface WMSDetailLayoutProps {
  id: string;
  tab?: WMSTabType;
  onClose?: () => void;
  externalLink?: boolean;
}

export const WMSDetailLayout = (props: WMSDetailLayoutProps) => {
  const { tab, id, onClose, externalLink } = props;
  const summaryRef = useRef<null | HTMLDivElement>(null);
  const [activeTab, setActivetab] = useState<WMSTabType>(tab || 'products');

  // Queries / Mutations
  const { loading, error, data, refetch } = useQuery(FETCH_WMS_TRANSACTION, {
    variables: { id: id },
  });

  useEffect(() => {
    const height = summaryRef?.current?.scrollHeight;
    if (height) window.scrollBy(0, height);
  }, [activeTab]);

  useEffect(() => {
    setActivetab('products');
  }, [id]);

  if (loading) {
    return <Skeleton avatar paragraph={{ rows: 3 }} loading={loading} active></Skeleton>;
  }

  if (error) {
    let errorCode: 500 | 404 = 500,
      title = 'Something went wrong while fetching the Transaction.',
      subTitle = error ? error.message : '',
      button = (
        <Button
          onClick={() => {
            if (refetch) {
              refetch();
            }
          }}
          type="primary"
        >
          Retry
        </Button>
      );
    if (error && error.message.includes('Wms::WarehouseTransaction for given id Not found')) {
      errorCode = 404;
      title = `Warehouse Transaction #${id} does not exist`;
      subTitle = '';
      button = (
        <Button type="primary">
          <Link to="/">Go Home</Link>
        </Button>
      );
    }
    return <Result status={errorCode} title={title} subTitle={subTitle} extra={button} />;
  }

  if (!data?.get_wms_transaction) return <div>Data not found</div>;

  const TabComponent = TabComponentMap.get(activeTab);

  const transaction_number = data?.get_wms_transaction?.transaction_number;

  const getTab = () => {
    const tabs: TabsProps['items'] = [];
    tabs.push({ label: 'Products', key: 'products' });
    if (data?.get_wms_transaction?.type !== TRANSACTION_TYPE_GDN)
      tabs.push({ label: 'Linked Transactions', key: 'linked_transactions' });
    tabs.push({ label: 'Documents', key: 'documents' });

    return tabs;
  };

  return (
    <div style={{ height: '94vh', overflowY: 'scroll' }} className="shipment-detail-layout">
      <AppHelmet>
        <title>{`#${transaction_number || ''} | Shipment`}</title>
      </AppHelmet>
      <Layout style={{ paddingBottom: '24px' }}>
        <WMSDetailHeader
          onClose={onClose}
          externalLink={externalLink}
          warehouseTransaction={data?.get_wms_transaction}
        />
        <Content style={{ padding: '16px 24px', paddingTop: externalLink ? '12px' : '16px' }}>
          <div ref={summaryRef}>
            <WMSSummary warehouseTransaction={data?.get_wms_transaction} />
            <Divider style={{ marginBottom: '0px' }} />
          </div>
          <Tabs
            activeKey={activeTab}
            onTabClick={(key: string) => {
              setActivetab(key as WMSTabType);
            }}
            tabBarStyle={{
              background: '#fafafa',
              paddingLeft: '10px',
            }}
            items={getTab()}
          ></Tabs>
          <Content style={{ minHeight: '500px' }}>
            <div style={{ height: '100%', overflow: 'auto' }}>
              {(TabComponent && (
                <TabComponent
                  externalLink={externalLink}
                  warehouseTransaction={data?.get_wms_transaction}
                />
              )) || <></>}
            </div>
          </Content>
        </Content>
      </Layout>
    </div>
  );
};

export default WMSDetailLayoutWrapper;
