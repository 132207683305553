/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree';
import DocField from './DocField';

const DocForm = types.model({
  field_order: types.array(types.string),
  fields: types.array(DocField),
});

export interface DocFormValue extends Instance<typeof DocForm> {}
export interface DocFormIn extends SnapshotIn<typeof DocForm> {}
export interface DocFormOut extends SnapshotOut<typeof DocForm> {}

export default DocForm;
