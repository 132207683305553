import { gql } from '@apollo/client';
import { salesPersonFields } from 'operations/graphql/salesPerson';

export const GET_SALES_PERSON = gql`
  query sales_person($id: ID!) {
    sales_person(id: $id) {
      ...salesPersonFields
    }
  }
  ${salesPersonFields}
`;

export const DISABLE_SALES_PERSON = gql`
  mutation disable_sales_person($id: ID!, $enable: Boolean!) {
    disable_sales_person(id: $id, enable: $enable) {
      ...salesPersonFields
    }
  }
  ${salesPersonFields}
`;

export const SEARCH_SALES_PERSON = gql`
  query sales_person_search($query: String!) {
    sales_person_search(query: $query) {
      ...salesPersonFields
    }
  }
  ${salesPersonFields}
`;

export const UPSERT_SALES_PERRSON = gql`
  mutation upsert_sales_person($sales_person: SalesPersonInputType) {
    upsert_sales_person(sales_person: $sales_person) {
      ...salesPersonFields
    }
  }
  ${salesPersonFields}
`;
