import React, { useState } from 'react';

import { EditOutlined } from '@shipmnts/pixel-hub';
import { Button, Divider, Tag, Tooltip } from '@shipmnts/pixel-hub';
import {
  PageHeader,
  CustomIcon,
  ColorPickerTool,
  getLighterColour,
  RenderCollaborators,
  useActivityContext,
} from '@shipmnts/pixel-hub';

import { ActionRenderer } from 'operations';
import { get as _get, startCase, upperCase as _upperCase } from 'lodash';

import {
  INQUIRY_CANCELED_STATUS,
  INQUIRY_LOST_STATUS,
  INQUIRY_NEGATIVE_STATUS,
  INQUIRY_WON_STATUS,
} from 'sales_hub/models/inquiry';

import { dividerStyles } from './common';
import { useInquiryDetail } from './InquiryDetailLayout';

type Props = { setShowFormDrawer: (val: boolean) => void; externalLink?: boolean };

const InquiryDetailHeader = ({ setShowFormDrawer, externalLink }: Props) => {
  // Contexts
  const { inquiry, refetchInquiry, inquiry_global_id } = useInquiryDetail();
  const { setActivityProps } = useActivityContext();
  const [colorCode, setColorCode] = useState(inquiry?.color_code || '#FFFFFF');

  if (!inquiry) {
    return <></>;
  }
  const EditEnabled = (): boolean => {
    if (!inquiry) return false;

    if (
      inquiry.last_action_status === INQUIRY_WON_STATUS ||
      inquiry.last_action_status === INQUIRY_CANCELED_STATUS
    )
      return false;
    return true;
  };
  const showStatus =
    inquiry?.last_action_status === INQUIRY_WON_STATUS ||
    inquiry?.last_action_status === INQUIRY_LOST_STATUS ||
    inquiry?.last_action_status === INQUIRY_CANCELED_STATUS;
  const handleOnClose = () => {
    // if (onClose) onClose();
    // else {
    window.open(`/workspace?doc_type=SalesHub::Inquiry&resource_id=${inquiry_global_id}`, '_self');
    // }
  };

  const isShipmentHazardous = () => {
    const hazardousCargo = inquiry?.cargos.some((cargo: any) => {
      return cargo?.cargo_properties?.is_hazardous;
    });
    return !!hazardousCargo;
  };

  const isShipmentPerishable = () => {
    // For Ocean Shipments, hazardous cargo does not reflect to shipment_properties while make service order
    const hazardousCargo = inquiry?.cargos.some((cargo: any) => {
      return cargo?.cargo_properties?.is_perishable;
    });
    return !!hazardousCargo;
  };

  const isTempControlled = () => {
    const hazardousCargo = inquiry?.cargos.some((cargo: any) => {
      return cargo?.cargo_properties?.is_temp_controlled;
    });
    return !!hazardousCargo;
  };
  const tagsToDisplay: { value: string; tooltipData: string }[] = [];
  // Coppied from shipmentWeb
  if (inquiry.trade_type || inquiry.freight_type)
    tagsToDisplay.push({
      value: `${startCase(_get(inquiry, 'freight_type', '') || '')} ${startCase(
        _get(inquiry, 'trade_type', '') || ''
      )} `,
      tooltipData: 'Trade Type',
    });

  if (inquiry.load_type)
    tagsToDisplay.push({
      value: `${_upperCase(_get(inquiry, 'load_type', '') || '')}`,
      tooltipData: 'Load Type',
    });

  const extras = [
    <RenderCollaborators
      id={inquiry?.inquiry_number}
      referenceId={inquiry?.id}
      referenceType={'SalesHub::Inquiry'}
      key=""
    />,
    <Button
      key="shipment_header_activity" // updated
      size={'small'}
      style={{ display: 'flex', alignItems: 'center' }}
      onClick={() => {
        setActivityProps({
          visible: true,
          resource_id: inquiry.inquiry_global_id || inquiry.id,
          resource_type: 'SalesHub::InquiryGlobal',
          email_resource_id: inquiry.id,
          email_resource_type: 'SalesHub::Inquiry',
          showDrawer: externalLink,
        });
      }}
    >
      Activity
      <span style={{ marginLeft: '5px', display: 'flex' }}>
        <CustomIcon icon="ActivityIcon" />
      </span>
    </Button>,
    <Button
      key={'edit'}
      size={'small'}
      onClick={() => {
        setShowFormDrawer(true);
      }}
      disabled={!EditEnabled()}
    >
      Edit
      <span style={{ marginLeft: '5px' }}>
        <EditOutlined />
      </span>
    </Button>,
    <ActionRenderer
      id={inquiry.id}
      doc_type_id="SalesHub::Inquiry"
      isDetailScreen={true}
      data={inquiry}
      refetchData={refetchInquiry}
      key="actions"
    />,
  ];

  const titleAndBackButton = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          paddingLeft: '0px',
        }}
      >
        <ColorPickerTool
          onChange={setColorCode}
          value={colorCode}
          docType={'SalesHub::InquiryGlobal'}
          docId={inquiry?.inquiry_global_id}
        />
        <div
          style={{
            fontSize: '18px',
            fontWeight: 600,
            color: '#0A4FCA', // Todo: constants
            marginLeft: '14px',
          }}
        >
          {inquiry?.inquiry_number}

          {externalLink && (
            <span>
              <Tooltip title="Open Detail Screen">
                <a href={`/view/rfq/${inquiry.inquiry_global_id}`} target="_blank" rel="noreferrer">
                  <CustomIcon icon="ExternalLinkIcon" />
                </a>
              </Tooltip>
            </span>
          )}
        </div>
        {showStatus && (
          <div style={{ fontSize: '12px', color: '#586988' }}>
            <Tag
              color={
                inquiry.last_action_status &&
                INQUIRY_NEGATIVE_STATUS.includes(inquiry.last_action_status)
                  ? 'pink'
                  : 'green'
              }
              style={{ marginLeft: '5px' }}
            >
              {startCase(inquiry.last_action_status || '')}
            </Tag>
          </div>
        )}

        <div style={{ display: 'flex', marginLeft: '5px' }}>
          {tagsToDisplay.map((tag) => (
            <Tooltip title={tag.tooltipData} key={tag.value}>
              <Tag color="blue">{_upperCase(tag.value)}</Tag>
            </Tooltip>
          ))}
          {isShipmentHazardous() && (
            <Tooltip title={<div>Contains Hazardous Cargo</div>}>
              <CustomIcon icon="HazardIcon" />
            </Tooltip>
          )}
          {isShipmentPerishable() && (
            <div style={{ marginLeft: '3px', display: 'inline-flex' }}>
              <Tooltip title={<div>Perishable</div>}>
                <CustomIcon icon="MdiLeafIcon" />
              </Tooltip>
            </div>
          )}
          {isTempControlled() && (
            <Tooltip title={<div>Temperature Controlled</div>}>
              <CustomIcon icon="NormalTemperatureIcon" />
            </Tooltip>
          )}
        </div>
      </div>
    );
  };
  return (
    <PageHeader
      ghost={false}
      onBack={handleOnClose}
      title={titleAndBackButton()}
      extra={extras}
      className={'inquiry-detail-header'}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      style={{ background: getLighterColour(colorCode) || '#ffffff' }}
    >
      <Divider style={dividerStyles} />
    </PageHeader>
  );
};

export default InquiryDetailHeader;
