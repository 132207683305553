import React, { useCallback, useEffect, useRef, useState } from 'react';
import { GridOptions } from '@ag-grid-community/core';
import { Col, Drawer, message, Row, Steps, DrawerFooter, Form } from '@shipmnts/pixel-hub';
import { BookingRequestValue } from 'operations/models/BookingRequest';
import { BookingRequestBasicInfo } from '../booking/components/BookingRequestView/BookingRequestBasicInfo';
import ContainerGrid, {
  renderContainerData,
} from '../booking/components/MergeSplitBooking/ContainerGridRender';
import { useMutation } from '@apollo/client';
import { VALIDATE_ROLLOVER_SHUTOUT } from 'operations/modules/booking/graphql/shipmentContainer';
import { OceanTransportOrderValue } from 'operations/models/OceanTransportOrder';
import SplitAndCreateBooking from './SplitAndCreateBooking';
import UpdateOtoForRollover from './UpdateOtoForRollover';
import OTOBasicInfo from '../booking/components/MergeSplitBooking/OTOBasicInfo';
import { ContainerReportData } from 'operations/modules/reports/reportModels';
import { CustomIcon } from '@shipmnts/pixel-hub';

interface UpdateOrSplitOtoProps {
  onClose: () => void;
  onSuccess: (sendEmail?: boolean, resource_ids?: string[]) => void;
  bookingRequest?: BookingRequestValue;
  oceanTransportOrder?: OceanTransportOrderValue;
}
export default function UpdateOrSplitOto(props: UpdateOrSplitOtoProps) {
  const { onClose, bookingRequest, onSuccess, oceanTransportOrder } = props;
  const gridRef = useRef<GridOptions>();
  const [current, setCurrent] = React.useState(0);
  const tableContainers: any[] = (
    bookingRequest?.shipmentContainers ||
    oceanTransportOrder?.shipment_containers ||
    []
  )
    .filter((sc) => Boolean(sc.container_number))
    .map((sc) => ({
      ...sc,
      total_gross_weight: sc.cargo_gross_weight,
      booking_number: sc.booking_number ? sc.booking_number : oceanTransportOrder?.booking_number,
    }));

  const [oto, setOto] = useState<OceanTransportOrderValue | undefined>(oceanTransportOrder);
  const [actionName, setActionName] = useState('');
  const [selectedContainers, setSelectedContainers] = useState<ContainerReportData[]>([]);
  const containerColumnNames = [
    'container_number',
    'booking_number',
    'total_gross_weight',
    'last_action_status',
  ];
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const [
    validateRollover,
    { data: validateRolloverData, loading: validateRolloverLoading, error: validateRolloverError },
  ] = useMutation(VALIDATE_ROLLOVER_SHUTOUT);

  useEffect(() => {
    if (validateRolloverError) {
      message.error(validateRolloverError.message);
    } else if (validateRolloverData?.validate_rollover_shutout) {
      if (validateRolloverData.validate_rollover_shutout?.action_to_be_done) {
        setActionName(validateRolloverData.validate_rollover_shutout.action_to_be_done);
        setCurrent(1);
      }
      if (validateRolloverData?.validate_rollover_shutout?.messages?.length) {
        message.error(validateRolloverData?.validate_rollover_shutout?.messages);
      }
    }
  }, [validateRolloverData, validateRolloverLoading, validateRolloverError]);

  const onSelectionChanged = useCallback(
    () => {
      const selectedContainersIds =
        gridRef?.current?.api?.getSelectedNodes().map((node) => node.data.id) || [];
      const selectedBookingNumbers = gridRef?.current?.api
        ?.getSelectedNodes()
        .map((node) => node.data.booking_number);

      if (bookingRequest) {
        setOto(
          bookingRequest?.ocean_transport_orders.find((oto) =>
            selectedBookingNumbers?.includes(oto.booking_number)
          )
        );
      }
      setSelectedContainers(
        tableContainers.filter((container) => selectedContainersIds.includes(container.id))
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const validateRollOverForUpdateOrSplit = () => {
    validateRollover({
      variables: { shipment_container_ids: selectedContainers.map((c) => c.id) },
    });
  };
  const RenderFooter = () => {
    if (current === 0) {
      return (
        <DrawerFooter
          saveText={'Next Step'}
          disabled={selectedContainers.length === 0}
          onClose={onClose}
          loading={loading}
          onSave={validateRollOverForUpdateOrSplit}
        />
      );
    }
    return <></>;
  };

  const RenderSteps = useCallback(() => {
    if (current === 0) {
      return (
        <>
          {oceanTransportOrder ? (
            <OTOBasicInfo oto={oceanTransportOrder} />
          ) : (
            <BookingRequestBasicInfo bookingRequest={bookingRequest} />
          )}
          <ContainerGrid
            tableHeading="Select container(s) to update routing details"
            containers={renderContainerData(tableContainers)}
            columnNames={containerColumnNames}
            gridRef={gridRef}
            onSelectionChanged={onSelectionChanged}
            renderFormatedData
          />
        </>
      );
    } else {
      return (
        <>
          {actionName === 'split_booking' && (
            <SplitAndCreateBooking
              ocean_transport_order={oto}
              containers={selectedContainers}
              rolloverOrShutout={oceanTransportOrder ? undefined : 'rollover'}
              onClose={onClose}
              onSuccess={onSuccess}
              setLoading={setLoading}
              form={form}
            />
          )}
          {actionName === 'update_booking' && oto && (
            <UpdateOtoForRollover
              containers={selectedContainers}
              oceanTransportOrder={oto}
              onClose={onClose}
              actionName="rollover_and_update_booking"
              onSuccess={onSuccess}
            />
          )}
        </>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);
  const items = [
    {
      title: 'Step 1',
      description: 'Select Containers',
      onClick: () => {
        setSelectedContainers([]);
      },
    },
    {
      title: 'Step 2',
      description: 'Update Routing details or Split booking',
      onClick: validateRollOverForUpdateOrSplit,
    },
  ];

  return (
    <Drawer
      placement="right"
      footer={<RenderFooter />}
      title={
        <div>
          <Row>
            <Col span={16}>
              <div
                style={{
                  color: '#353F52',
                  fontSize: '18px',
                  fontWeight: 500,
                  marginBottom: '10px',
                }}
              >
                <span style={{ marginLeft: '5px' }}>
                  <b>
                    <CustomIcon icon="EditRoadIcon" /> Update Rollover Details or Split booking
                  </b>
                </span>
              </div>
            </Col>
          </Row>
          <Row>
            {tableContainers.length > 0 && (
              <Col span={16}>
                <Steps
                  items={items}
                  current={current}
                  size="small"
                  type="navigation"
                  onChange={(key: number) => setCurrent(key)}
                />
              </Col>
            )}
          </Row>
        </div>
      }
      onClose={onClose}
      open={true}
      styles={{ header: { padding: '16px 16px 0px 24px' } }}
      width="60%"
    >
      <RenderSteps />
    </Drawer>
  );
}
