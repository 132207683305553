import React from 'react';
import { PrintPreviewDocType } from '@shipmnts/pixel-hub';

type PrintProp = {
  docTypeId: string;
  docType: string;
  onClose?: () => void;
};

const PrintContainer = (props: PrintProp) => {
  const { docType, docTypeId, onClose } = props;
  return <PrintPreviewDocType docType={docType} resource_id={docTypeId} onClose={onClose} />;
};

export default PrintContainer;
