import React, { useEffect, useState } from 'react';
import { CargoValue } from 'operations/models/Cargo';
import Shipment, { ShipmentValue } from 'operations/models/Shipment';
import { FETCH_BULK_CONTAINER, FETCH_BULK_SHIPMENT } from 'operations/graphql/shipment';
import { ShipmentContainerValue } from 'operations/models/ShipmentContainer';
import { message } from '@shipmnts/pixel-hub';
import CreateAndAssignLoad from './CreateAndAssignLoad';
import { RoutingLegValue } from 'operations/models/RoutingLeg';
import { RowNode } from '@ag-grid-community/core';
import { RoutingNodeValue } from 'operations/models/RoutingNode';
import { useLazyQuery } from '@apollo/client';
import { get } from 'lodash';

export interface CargoData extends CargoValue {
  shipment: ShipmentValue;
}

export interface AssignLoadWrapperProps {
  selectedNodes: RowNode[];
  selectedIds?: string[];
  onClose: () => void;
  onSuccess: () => void;
  shipmentIds?: string;
  isFcl?: boolean;
}

const isCargosPending = (shipments: ShipmentValue[], selectedIds: string[]) => {
  return shipments.every((shipment) => {
    return (
      shipment.isRoadCustomerOrder() &&
      (shipment.cargos || [])
        .filter((cargo) => selectedIds.includes(cargo.id || ''))
        .every((cargo) => (get(cargo, 'allocation_pending_quantity') || 0) > 0)
    );
  });
};

const isSameRoutingNode = (node1: RoutingNodeValue, node2: RoutingNodeValue) => {
  return (
    node1.location?.id === node2.location?.id &&
    node1.address?.id === node2.address?.id &&
    node1.company?.id === node2.company?.id
  );
};

const isSameRoutingLegs = (routing_legs1: RoutingLegValue[], routing_legs2: RoutingLegValue[]) => {
  return routing_legs1.every(
    (routing_leg, index) =>
      isSameRoutingNode(routing_legs2[index].origin, routing_leg.origin) &&
      isSameRoutingNode(routing_legs2[index].destination, routing_leg.destination)
  );
};

const isSameCustomer = (shipment1: ShipmentValue, shipment2: ShipmentValue) => {
  return (
    shipment1.customer_company?.id === shipment2.customer_company?.id &&
    shipment1.trade_type === shipment2.trade_type &&
    shipment1.load_type === shipment2.load_type &&
    shipment1.shipment_type === shipment2.shipment_type
  );
};

const checkSameCustomterValidation = (shipments: ShipmentValue[]) => {
  if (!shipments?.length) return false;
  const routing_legs = shipments[0].routing_legs.sort(
    (a, b) => a.sequence_number - b.sequence_number
  );
  for (let i = 1; i < shipments.length; i++) {
    if (
      !isSameCustomer(shipments[0], shipments[i]) ||
      !isSameRoutingLegs(
        routing_legs,
        shipments[i].routing_legs.sort((a, b) => a.sequence_number - b.sequence_number)
      )
    )
      return false;
  }
  return true;
};

const AssignLoadWrapper = (props: AssignLoadWrapperProps) => {
  const { selectedIds = [], onClose, onSuccess, shipmentIds = [], isFcl = false } = props;
  const [matchingOrders, setMatchingOrders] = useState<ShipmentValue[]>([]);
  const [fetchBuklShipments, { data, loading, error }] = useLazyQuery(FETCH_BULK_SHIPMENT, {
    fetchPolicy: 'no-cache',
  });
  const [
    fetchBuklContainers,
    { data: containerData, loading: containerLoading, error: containerError },
  ] = useLazyQuery(FETCH_BULK_CONTAINER, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (isFcl) fetchBuklContainers({ variables: { id: selectedIds } });
    else fetchBuklShipments({ variables: { id: shipmentIds } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFcl]);

  useEffect(() => {
    if (!containerLoading && !containerError && containerData?.containers) {
      const shipments: ShipmentValue[] = [];
      (containerData.containers || []).forEach((container: ShipmentContainerValue) => {
        if (
          container.container_cargo_details?.length === 1 &&
          !container.container_cargo_details?.[0]?.shipment?.job_number
        ) {
          const shipment: ShipmentValue = container.container_cargo_details?.[0].shipment;
          if (!shipments.find((sp) => sp.id === shipment.id))
            shipments.push(Shipment.create(shipment));
        } else {
          message.error('Select pending quantity container to assign the load');
          onClose();
        }
      });
      if (shipments.length > 0) {
        if (!checkSameCustomterValidation(shipments)) {
          message.error(`Select loads from same customer's order`);
          onClose();
        } else {
          setMatchingOrders(shipments);
        }
      }
    }
  }, [containerData, containerLoading, containerError, onClose]);

  useEffect(() => {
    if (!loading && !error && data?.shipments) {
      const shipments = (data.shipments || []).map((shipment: ShipmentValue) =>
        Shipment.create(shipment)
      );
      if (!isCargosPending(shipments, selectedIds)) {
        message.error('Select pending quantity cargo to assign the load');
        onClose();
      } else if (!checkSameCustomterValidation(shipments)) {
        message.error(`Select loads from same customer's order`);
        onClose();
      } else {
        setMatchingOrders(shipments);
      }
    }
  }, [data, loading, error, onClose, selectedIds]);

  return matchingOrders.length && !loading && !error ? (
    <CreateAndAssignLoad
      shipment={matchingOrders[0]}
      selectedIds={selectedIds}
      onClose={onClose}
      onSuccess={onSuccess}
      matchingOrders={matchingOrders}
    />
  ) : (
    <></>
  );
};

export default AssignLoadWrapper;
