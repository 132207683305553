import React, { useState, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Descriptions,
  Card,
  message,
  Drawer,
  Form,
  dayjsGenerateConfig,
  DatePicker,
} from '@shipmnts/pixel-hub';
import { errorMessageHandlerGraphQL } from 'common';
import { DrawerFooter } from '@shipmnts/pixel-hub';

import { disableFutureDate } from '@shipmnts/pixel-hub';
import { RoadTransportOrderValue } from 'operations/models/RoadTransportOrder';
import { useSession } from 'common';
import { useMutation } from '@apollo/client';
import { UPDATE_CARGO_CARTED_IN } from 'operations/modules/booking/graphql/roadTransportOrder';
import { StuffingBufferServiceOrderValue } from 'operations/models/StuffingBufferServiceOrder';
import { BookingRequestValue } from 'operations/models/BookingRequest';
import { WEIGHT_UNIT_KGS } from 'operations/baseConstants';

interface AddCartingDetailsProps {
  roadTransportOrder?: RoadTransportOrderValue;
  stuffingBufferServiceOrder?: StuffingBufferServiceOrderValue;
  bookingRequest: BookingRequestValue;
  onSuccess?: (value: RoadTransportOrderValue, sendEmail: boolean) => void;
  onClose: () => void;
}

const AddCartingDetails = observer(function AddCartingDetails(
  props: AddCartingDetailsProps
): JSX.Element {
  const [form] = Form.useForm();
  const { roadTransportOrder, stuffingBufferServiceOrder, bookingRequest, onSuccess, onClose } =
    props;
  const sessionData = useSession();
  const default_company_id = sessionData.company_account.default_company.id;
  const [sendEmail, setSendEmail] = useState<boolean>(true);

  const [updateDetails, { data, loading, error }] = useMutation(UPDATE_CARGO_CARTED_IN);

  useEffect(() => {
    if (data && data.update_cargo_carted_in) {
      message.success('Cargo carted in successfully');
      form.resetFields();
      if (onSuccess)
        onSuccess(
          stuffingBufferServiceOrder
            ? data.update_cargo_carted_in.stuffing_buffer_service_order
            : data.update_cargo_carted_in.road_transport_order,
          sendEmail
        );
      onClose();
    }
  }, [data, form, onClose, onSuccess, sendEmail, stuffingBufferServiceOrder]);

  const closeDrawer = useCallback(() => {
    form.resetFields();
    onClose();
  }, [form, onClose]);

  return (
    <Drawer
      width={'60%'}
      title={'Add Carting Details'}
      open={true}
      onClose={onClose}
      footer={
        <DrawerFooter
          saveText="Submit"
          closeText="Cancel"
          showSendEmail
          sendEmail={sendEmail}
          setSendEmail={setSendEmail}
          sendEmailText="Send Carting Confirmation"
          loading={loading}
          onClose={closeDrawer}
          onSave={form.submit}
        />
      }
    >
      {error && errorMessageHandlerGraphQL(error)}
      {roadTransportOrder && (
        <Card style={{ marginBottom: '10px' }} className="no-padding-description custom-card">
          <Descriptions column={4} colon={false} size="small" layout="vertical">
            <Descriptions.Item key="transporter" label="Transporter">
              {roadTransportOrder.getVendorCompany(default_company_id)?.registered_name}
            </Descriptions.Item>
            <Descriptions.Item
              style={{ paddingRight: '8px' }}
              key="factory_location"
              label="Factory Location"
            >
              {roadTransportOrder.factory_location?.print_address}
            </Descriptions.Item>
            <Descriptions.Item key="gross_weight" label="Gross Weight">
              {roadTransportOrder.cargos.reduce(
                (sum, cargo) => (sum += cargo.gross_weight || 0),
                0
              )}{' '}
              {WEIGHT_UNIT_KGS}
            </Descriptions.Item>
          </Descriptions>
        </Card>
      )}
      {stuffingBufferServiceOrder && (
        <Card style={{ marginBottom: '10px' }} className="no-padding-description custom-card">
          <Descriptions column={4} colon={false} size="small" layout="vertical">
            <Descriptions.Item key="vendor" label="Vendor">
              {stuffingBufferServiceOrder.getVendorCompany(default_company_id)?.registered_name}
            </Descriptions.Item>
            <Descriptions.Item
              style={{ paddingRight: '8px' }}
              key="carting_location"
              label="Carting Location"
            >
              {stuffingBufferServiceOrder.location_node?.location?.name}
            </Descriptions.Item>
            <Descriptions.Item key="gross_weight" label="Gross Weight">
              {bookingRequest.cargos.reduce((sum, cargo) => (sum += cargo.gross_weight || 0), 0)}{' '}
              {WEIGHT_UNIT_KGS}
            </Descriptions.Item>
          </Descriptions>
        </Card>
      )}
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => {
          const payload = {
            actual_time_of_arrival: values.actual_time_of_arrival.unix(),
            rto_id: roadTransportOrder?.id,
            br_id: bookingRequest.id,
          };
          updateDetails({ variables: payload });
        }}
        initialValues={{
          actual_time_of_arrival: dayjsGenerateConfig.getNow(),
        }}
      >
        <Form.Item
          required
          rules={[{ required: true }]}
          name="actual_time_of_arrival"
          label="Carting Date"
        >
          <DatePicker showTime style={{ width: '100%' }} disabledDate={disableFutureDate} />
        </Form.Item>
      </Form>
    </Drawer>
  );
});

export default AddCartingDetails;
