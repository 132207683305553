import { PerformAction } from '../../actionHelper/models';
// import ShareCommercialInvoice from './ShareCommercialInvoice';
import { SessionDataValue } from 'operations/models/SessionData';
import UpdateCommercialInvoiceStatus from './UpdateCommercialInvoiceStatus';
import { PrintPreviewDocType } from '@shipmnts/pixel-hub';
import LinkContainerDrawer from '../CommercialInvoice/LinkContainerDrawer';
import LinkCustomDrawer from '../CommercialInvoice/LinkCustomDrawer';
import { CommercialInvoiceValue } from 'operations/models/CommercialInvoice';

interface ProductOrderActionType {
  commercialInvoice: CommercialInvoiceValue;
  setEmailProps: (emailProps: any) => void;
  setVisible: (visible: boolean) => void;
  sessionData: SessionDataValue;
  extraProps?: any;
  navigate: any;
  status: string;
  invoice_id: number;
}

export const amendInvoiceAction = (payload: ProductOrderActionType): PerformAction => {
  const { commercialInvoice } = payload;

  return {
    actionParams: {
      id: commercialInvoice?.id,
      status: 'amend',
    },
    component: UpdateCommercialInvoiceStatus,
  };
};

export const cancelInvoiceAction = (payload: ProductOrderActionType): PerformAction => {
  const { commercialInvoice } = payload;
  return {
    actionParams: {
      id: commercialInvoice?.id,
      commercialInvoice: commercialInvoice,
      status: 'cancel',
    },
    component: UpdateCommercialInvoiceStatus,
  };
};

export const performPrintCommercialInvoice = (payload: ProductOrderActionType): PerformAction => {
  const { commercialInvoice } = payload;
  return {
    actionParams: {
      resource_id: commercialInvoice?.id,
      docType: 'Shipment::ShipmentInvoice',
    },
    component: PrintPreviewDocType,
  };
};

export const performLinkContainers = (payload: ProductOrderActionType): PerformAction => {
  const { commercialInvoice } = payload;
  return {
    actionParams: {
      commercialInvoice: commercialInvoice,
    },
    component: LinkContainerDrawer,
  };
};

export const performLinkCustom = (payload: ProductOrderActionType): PerformAction => {
  const { commercialInvoice } = payload;
  return {
    actionParams: {
      commercialInvoice: commercialInvoice,
    },
    component: LinkCustomDrawer,
  };
};
