import { useMutation } from '@apollo/client';
import { Modal, message } from '@shipmnts/pixel-hub';
import { ConnectionRequestValue } from 'network/models/ConectionRequest';
import React, { useEffect } from 'react';
import { DELETE_CONNECTION_REQUEST, UPDATE_CONNECTION_REQUEST } from './queries';

type Props = {
  actionName: 'ACCEPT' | 'REJECT' | 'BLOCK' | 'UNBLOCK' | 'REMOVE';
  connectionRequest: ConnectionRequestValue;
  onClose?: () => void;
  onSuccess?: () => void;
  onCancel?: () => void;
};

const PerformActionComponenent = (props: Props) => {
  const [updateConnectionRequest, { data, loading: updateConnectionRequestLoading, error }] =
    useMutation(UPDATE_CONNECTION_REQUEST);

  const [
    deleteConnectionRequest,
    {
      data: deleteConnectionRequestData,
      loading: deleteConnectionRequestLoading,
      error: deleteConnectionRequestError,
    },
  ] = useMutation(DELETE_CONNECTION_REQUEST);

  const loading = updateConnectionRequestLoading || deleteConnectionRequestLoading;

  const updateStatus = (status: string) => {
    updateConnectionRequest({
      variables: {
        id: props.connectionRequest.id,
        connection_request: {
          status: status,
        },
      },
    });
  };
  const deleteConnection = () => {
    deleteConnectionRequest({
      variables: {
        id: props.connectionRequest.id,
      },
    });
  };

  useEffect(() => {
    if (
      (data || (deleteConnectionRequestData && (!error || !deleteConnectionRequestError))) &&
      props.onSuccess
    ) {
      message.success('Connection request updated successfully');
      props.onSuccess();
    }
  }, [data, deleteConnectionRequestData, error, deleteConnectionRequestError, props]);

  const modalHash = {
    ACCEPT: () =>
      Modal.info({
        title: 'Accept Connection Request',
        content: (
          <div>
            You are Accepting Connection Request
            <br /> By Accepting this connection request you are approving this customer to access
            your Digital Forwarding Platform
          </div>
        ),
        onOk() {
          updateStatus('accepted');
        },
        okText: 'Accept',
        closable: true,
        onCancel() {
          if (props.onCancel) props.onCancel();
        },
        cancelText: 'Cancel',
        okButtonProps: {
          loading: loading,
        },
        cancelButtonProps: {
          loading: loading,
        },
      }),
    REJECT: () =>
      Modal.warning({
        title: 'Reject Connection Request',
        content: (
          <div>
            You are Rejecting Connection Request <br />
            By Rejecting this connection request you are denying this customer to access your
            Digital Forwarding Platform
          </div>
        ),
        onOk() {
          deleteConnection();
        },
        okButtonProps: {
          style: {
            background: '#CC933E',
            border: 'none',
          },
          content: 'Reject',
          loading: loading,
        },
        closable: true,
        onCancel() {
          if (props.onCancel) props.onCancel();
        },
        cancelText: 'Cancel',
        cancelButtonProps: {
          loading: loading,
        },
      }),
    REMOVE: () =>
      Modal.warning({
        title: 'Remove Connection',
        content: (
          <div>
            You are Removing Connection.
            <br /> By Removing this connection you are disabling this customer to access your
            Digital Forwarding Platform
          </div>
        ),
        onOk() {
          deleteConnection();
        },
        okButtonProps: {
          style: { background: '#CC933E', border: 'none' },
          content: 'Remove',
          loading: loading,
        },
        closable: true,
        onCancel() {
          if (props.onCancel) props.onCancel();
        },
        cancelText: 'Cancel',
        cancelButtonProps: {
          loading: loading,
        },
      }),
    BLOCK: () =>
      Modal.error({
        title: 'Block Connection',
        content: (
          <div>
            You are Blocking Connection
            <br /> By Blocking this connection you are disabling this customer to access your
            Digital Forwarding Platform and in future they wont be able to send you are request too
          </div>
        ),
        onOk() {
          updateStatus('blocked');
        },
        okType: 'danger',
        okText: 'Block',
        closable: true,
        onCancel() {
          if (props.onCancel) props.onCancel();
        },
        cancelText: 'Cancel',
        okButtonProps: {
          loading: loading,
        },
        cancelButtonProps: {
          loading: loading,
        },
      }),
    UNBLOCK: () =>
      Modal.info({
        title: 'Un-block Connection',
        content: (
          <div>
            You are Un-blocking Connection
            <br /> By Un blocking this connection you are approving this customer to access your
            Digital Forwarding Platform
          </div>
        ),
        onOk() {
          updateStatus('accepted');
        },
        okText: 'Unblock',
        closable: true,
        onCancel() {
          if (props.onCancel) props.onCancel();
        },
        cancelText: 'Cancel',
        okButtonProps: {
          loading: loading,
        },
        cancelButtonProps: {
          loading: loading,
        },
      }),
  };

  useEffect(() => {
    modalHash[props.actionName]();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.actionName]);

  return <></>;
};

export default PerformActionComponenent;
