import { Button, Modal, EnumEditor, FloatEditor, SearchDocTypeEditor } from '@shipmnts/pixel-hub';
import { CompanySearch } from 'common';
import { PlusOutlined, DeleteOutlined, FormTable } from '@shipmnts/pixel-hub';
import React, { MutableRefObject } from 'react';
import { GridOptions, RowNode } from '@ag-grid-community/core';
import { PARTY_NAME } from '../constants';
import { Column } from 'operations/models/Report';
import { getHeight } from './CommercialInvoiceListTable';
import { ILargeTextEditorParams } from '@ag-grid-community/core/dist/cjs/es5/rendering/cellEditors/largeTextCellEditor';
import { GlobalSearch } from '@shipmnts/pixel-hub';

interface PartyDetailsProps {
  onChange?: (data: any[]) => void;
  autoFillParty?: (data: any[], index: any) => void;
  value?: any[];
  invoiceStatus?: string;
  setInvoiceStatus?: (value: string) => void;
  editable: boolean;
  addDeleteButton?: boolean;
  gridRef: MutableRefObject<GridOptions | undefined>;
  partyEnable?: boolean;
}

export default function PartyDetails(props: PartyDetailsProps) {
  const {
    value,
    onChange,
    invoiceStatus,
    setInvoiceStatus,
    editable,
    addDeleteButton = true,
    gridRef,
    autoFillParty,
    partyEnable = true,
  } = props;

  const partyTypeList = PARTY_NAME.filter(
    (party) => !value?.map((val) => val.name).includes(party.value)
  );

  const columnDefs: Column[] = [
    {
      headerName: 'Party Type',
      field: 'name',
      colId: 'name',
      editable: partyEnable,
      valueFormatter: (params: any) =>
        PARTY_NAME.find((e) => e.value === params.data.name)?.label || '',
      suppressKeyboardEvent: (params: any) => {
        return params.event.key === 'Enter' && params.editing;
      },
      cellEditor: 'EnumEditor',
      cellEditorParams: (params: any) => {
        // options: PARTY_NAME.filter((party) => !value?.map((val) => val.name).includes(party.value)),
        return {
          options: partyTypeList,
        };
      },
      valueSetter: (params: any) => {
        if (!params.newValue) return false;
        params.data.name = params.newValue;
        params.api.refreshCells({
          force: true,
          columns: ['party_company', 'party_address'],
          rowNodes: [params.node],
        });
        return true;
      },
      lockVisible: true,
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      minWidth: 150,
      maxWidth: 150,
    },
    {
      headerName: 'Company Name',
      field: 'party_company',
      columnType: 'String',
      valueFormatter: (params: any) => params.value?.registered_name,
      minWidth: 200,
      maxWidth: 250,
      editable: (params: any) => {
        return editable && !['to_order', 'to_order_bank'].includes(params.data.name);
      },
      suppressKeyboardEvent: (params: any) => {
        return params.event.key === 'Enter' && params.editing;
      },
      cellEditor: 'SearchDocTypeEditor',
      cellEditorParams: (params: any) => {
        return {
          CustomComponent: CompanySearch,
          componentProps: {
            selectMode: 'single',
            renderViewType: 'NewTab',
            searchProps: {
              ...PARTY_NAME.find((e) => e.value === params.data.name)?.searchProps,
            },
          },
        };
      },
      valueSetter: (params: any) => {
        if (!params.newValue) params.data.party_details = undefined;
        params.data.party_company = params.newValue;
        params.data.party_address =
          params?.newValue?.addresses?.length === 1 ? params.newValue.addresses[0] : undefined;
        return true;
      },
      keyCreator: (params: any) => params?.party_company?.id,
      lockVisible: true,
      cellStyle: (params: any) => {
        if (['to_order', 'to_order_bank'].includes(params.data.name))
          return { 'background-color': '#f2f2f2' };
        return;
      },
    },
    {
      headerName: 'Company Address',
      field: 'party_address',
      columnType: 'String',
      valueFormatter: (params: any) => {
        return params.value?.print_address || '';
      },
      editable: (params: any) => {
        return editable && !['to_order', 'to_order_bank'].includes(params.data.name);
      },
      suppressKeyboardEvent: (params: any) => {
        return params.event.key === 'Enter' && params.editing;
      },
      cellEditor: 'SearchDocTypeEditor',
      cellEditorParams: (params: any) => {
        return {
          CustomComponent: GlobalSearch,
          componentProps: {
            selectMode: 'single',
            searchProps: {
              company_id: params?.data?.party_company?.id,
            },
          },
        };
      },
      valueSetter: (params: any) => {
        params.data.party_address = params.newValue;
        params.data.party_details =
          params.data.party_company?.registered_name +
          '\n' +
          (params.newValue?.print_address || '');
        return true;
      },
      cellStyle: (params: any) => {
        if (['to_order', 'to_order_bank'].includes(params.data.name))
          return { 'background-color': '#f2f2f2' };
        return;
      },
      keyCreator: (params: any) => params?.party_address?.id,
      lockVisible: true,
      minWidth: 450,
      maxWidth: 500,
    },
    {
      headerName: 'Print As',
      field: 'party_details',
      colId: 'party_details',
      // cellEditor: 'StringEditor',
      columnType: 'String',
      cellEditorPopup: true,
      cellEditor: 'agLargeTextCellEditor',
      cellEditorParams: {
        maxLength: 600,
        rows: 10,
        cols: 54,
      } as ILargeTextEditorParams,
      valueSetter: (params: any) => {
        params.data.party_details = params.newValue;
        return true;
      },
      valueGetter: (params: any) => {
        return params.data.party_details;
      },
      editable: (o: any) => editable && !o.node.isRowPinned(),
      minWidth: 450,
      maxWidth: 500,
    },
  ];

  const components = {
    FloatEditor: FloatEditor,
    EnumEditor: EnumEditor,
    SearchDocTypeEditor: SearchDocTypeEditor,
  };

  const addNewRow = () => {
    const newRow = {};
    // setRowData([...rowData, newRow]);\
    if (value) {
      onChange && onChange([...value, newRow]);
      setTimeout(() => {
        gridRef?.current?.api?.startEditingCell({
          rowIndex: value.length,
          colKey: 'name',
        });
      }, 500);
    }
  };

  const newDeleteRow = (selectedRows: RowNode[]) => {
    // const selectedRow = gridRef?.current?.api?.getSelectedNodes();
    // const id = rowData[selectedRow.rowIndex].id;
    const selectedIndexes = (selectedRows || []).map((element: any) => element.rowIndex);
    const result = (value || []).filter((_: any, index: any) => !selectedIndexes.includes(index));
    // setRowData(result);
    onChange && onChange(result);
    gridRef?.current?.api?.setRowData(result);
  };

  const isRowSelectable = (params: any) => {
    const party = ['shipper', 'consignee', 'buyer', 'seller'];
    return editable && !party.includes(params.data.name);
  };

  const onCellValueChanged = (cell: any) => {
    const data = value || [];
    data[cell.rowIndex] = {
      ...cell.data,
    };
    onChange && onChange(data);

    autoFillParty && autoFillParty(data, cell.rowIndex);
    if (invoiceStatus === 'draft' && cell.oldValue !== cell.newValue) {
      setInvoiceStatus && setInvoiceStatus('not_saved');
    }
  };

  return (
    <div>
      <FormTable
        reportName="party_selection"
        columns={columnDefs}
        gridRef={gridRef}
        rowData={value || []}
        height={getHeight(value || []) || '35vh'}
        rowSelection={'multiple'}
        checkbox_always_visible={false}
        reportConfig={{
          components,
          suppressLastEmptyLineOnPaste: true,
          rowHeight: 50,
          stopEditingWhenCellsLoseFocus: true,
          suppressClipboardApi: true,
          isRowSelectable: isRowSelectable,
          defaultColDef: {
            flex: 1,
            minWidth: 100,
            resizable: true,
          },
          enableRangeSelection: true,
          singleClickEdit: true,
          enableCellChangeFlash: true,
          animateRows: true,
          onCellValueChanged: onCellValueChanged,
        }}
      />
      {addDeleteButton && (
        <>
          <Button size="small" disabled={partyTypeList.length === 1} onClick={addNewRow}>
            <PlusOutlined />
            Add Party
          </Button>
          <Button
            icon={<DeleteOutlined />}
            size="small"
            ghost
            type="primary"
            danger
            style={{ marginLeft: '10px' }}
            // icon={<DeleteOutlined />}
            onClick={() => {
              const selectedRows = gridRef?.current?.api?.getSelectedNodes();
              if (selectedRows && selectedRows.length > 0) {
                Modal.confirm({
                  title: 'Confirm',
                  content: 'Are you sure you want to delete this party details?',
                  okText: 'Ok',
                  okType: 'danger',
                  onOk: () => {
                    newDeleteRow(selectedRows);
                  },
                  onCancel: () => {
                    gridRef?.current?.api?.deselectAll();
                  },
                  cancelText: 'Cancel',
                });
              }
            }}
          ></Button>
        </>
      )}
    </div>
  );
}
