import { map as _map } from 'lodash';
import { Document } from 'operations/apis/document';

const getResolveValueForKey = (
  data: object,
  resolveFunc: (key: string, options?: object) => any,
  result: { [key: string]: any },
  options?: object
) => {
  _map(data, (value: object, key: string) => {
    const resolvedValue = resolveFunc(key, options);
    result[key] = resolvedValue;
  });

  return result;
};

export const getDocumentJson = (
  doc: Document,
  resolveFunc: (key: string, options?: object) => any,
  options?: object
): { [key: string]: any } => {
  if (!doc) return {};
  let output: { [key: string]: any } = {};

  output = getResolveValueForKey(doc.document_field, resolveFunc, output, options);
  output = getResolveValueForKey(doc.print_option, resolveFunc, output, options);
  output.shipment_id = doc && doc.shipment_id;
  output.document_type = doc && doc.document_type;
  return output;
};
