import React from 'react';

import { Col, Row, Typography, UploadedDocuments } from '@shipmnts/pixel-hub';
import { useSession } from 'common';
import { ProductOrderValue } from 'operations/models/ProductOrder';
interface DocumentProps {
  productOrder: ProductOrderValue;
}
const DocumentTab = (props: DocumentProps) => {
  const { productOrder } = props;
  const session = useSession();
  if (!productOrder) return <></>;
  return (
    <div style={{ height: '300px' }}>
      <Typography.Title level={4} style={{ color: '#0a4fca' }}>
        Uploaded Documents
      </Typography.Title>
      <Row>
        <Col sm={24} md={12}>
          <UploadedDocuments
            sessionData={session}
            parent_id={productOrder?.id}
            parent_type="product_order"
            docgen_url={`${process.env.DOCGEN_URL}`}
          />
        </Col>
      </Row>
    </div>
  );
};

export default DocumentTab;
