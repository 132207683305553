import React from 'react';
import { useQuery } from '@apollo/client';
import { Button } from '@shipmnts/pixel-hub';
import ChangeEventsTimeline from './ChangeEventTimeline';
import { ShipmentDocumentValue } from 'operations/models/ShipmentDocument';
import { FETCH_SHIPMENT_DOCUMENTS } from './query';
import Spinner from 'src/components/Spinner';

interface ShipmentDocumentTimelineProp {
  shipment_id: string;
  shipment_document_id?: string;
}

const ShipmentDocumentTimeline = React.memo(function ShipmentDocumentTimeline(
  props: ShipmentDocumentTimelineProp
) {
  const { shipment_id, shipment_document_id } = props;

  const { error, data, loading, refetch } = useQuery(FETCH_SHIPMENT_DOCUMENTS, {
    variables: { shipment_ids: [shipment_id] },
  });

  if (!data && error) {
    return (
      <div className="align-center">
        Unable to fetch timeline. &nbsp;
        <Button type="primary" onClick={() => refetch()}>
          Retry
        </Button>
      </div>
    );
  }
  if (loading) {
    return <div>loading...</div>;
  }

  const shipment_document_events = data?.fetch_shipment_documents?.find(
    (doc: ShipmentDocumentValue) => doc.id === shipment_document_id
  )?.shipment_document_events;

  if (data?.fetch_shipment_documents)
    return <ChangeEventsTimeline change_events={shipment_document_events} />;

  return <Spinner size="small" tip="Fetching Timeline" />;
});

export default ShipmentDocumentTimeline;
